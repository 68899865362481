import React from 'react';

import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import './YoutubeEmbedded.scss';

const propTypes = {
  youtubeId: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function YoutubeEmbedded({ youtubeId }) {
  if (_isEmpty(youtubeId) || typeof youtubeId !== 'string') {
    return null;
  }

  return (
    <div className="youtube-video-responsive">
      <iframe
        src={`https://www.youtube.com/embed/${youtubeId}?rel=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

YoutubeEmbedded.propTypes = propTypes;
YoutubeEmbedded.defaultProps = defaultProps;
