export const customPagesTabName = 'PAGES';
export const customHeroTabName = 'CUSTOM HERO';

export const curatedHeroTabName = 'CURATED HERO';

export const redirectTabName = 'REDIRECTS';

export const customPagesKeyName = 'customPages';
export const customHeroKeyName = 'customHero';
export const curatedHeroKeyName = 'curatedHero';
export const redirectTabKeyName = 'redirectTab';
