import React, { useState } from 'react';

import { Loader } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { TeamsSectionProvider } from './TeamMembersTable.context';
import TeamsTable from './components/TeamsTable';
import API from '../../../../../libs/api';
import ErrorHandler from '../../../../../libs/errors/errors';
import { selectBusiness } from '../../../../../selectors';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';

import './TeamMembersTable.scss';

export default function TeamMembersTable() {
  const business = useSelector(selectBusiness);

  const [teamMembers, setTeamMembers] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id: businessId, type: businessType } = business;

  useAsyncEffect(async () => {
    try {
      const { data: locationsData } = await API.getBusinessTypeLocations({
        businessId,
        businessType,
      });
      const { data: teamMemberData } = await API.getTeamMembers({ businessId, businessType });
      setAllLocations(locationsData);
      setTeamMembers(teamMemberData);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <Loader active />;
  }

  const context = { teamMembers, setTeamMembers, business, allLocations };

  return (
    <TeamsSectionProvider value={context}>
      <div className="teams-table-container">
        <TeamsTable />
      </div>
    </TeamsSectionProvider>
  );
}
