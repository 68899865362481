/* eslint-disable no-unused-vars */
import { useState } from 'react';

import _ from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _set from 'lodash/set';
import { useDispatch, useSelector } from 'react-redux';

import {
  getCuratedHeroNameOptions,
  getCuratedHeroSettings,
  getParentAndChildConfigurations,
  getComponentConfigurationSetting,
  getComponentConfigurationSettings,
  getComponentConfiguration,
  getInitialConfigurationsForSetting,
  getInitialConfiguration,
} from './services';
import useMultipleHeros from './useMultipleHeros';
import { flagBusinessSaved } from '../../../../../actions/business';
import { selectEntityId } from '../../../../../libs/slices';
import { selectWebsiteId } from '../../../../../selectors/website';
import useComponentConfigurations from '../../../component-configuration/hooks/use-component-configurations';
import { selectComponentConfigurationsByParams } from '../../../component-configuration/selectors/component-configuration';
import { HOME_PAGE_TYPE } from '../../../pages/constants';
import { selectPageByType } from '../../../pages/selectors/page';
import { CURATED_HERO_COMPONENT } from '../../constants/component';

export default function useCuratedHero(recipeName) {
  const dispatch = useDispatch();
  const homePage = useSelector(selectPageByType(HOME_PAGE_TYPE));
  const curatedHeroConfigurations = useSelector((state) =>
    selectComponentConfigurationsByParams(state, { fastId: CURATED_HERO_COMPONENT }),
  );

  const websiteId = useSelector(selectWebsiteId);

  const {
    isLoading: isLoadingComponentConfigurations,
    upsertComponentConfiguration,
    deleteComponentConfiguration,
    isSubmitting,
  } = useComponentConfigurations(websiteId, null, homePage.id);

  const curatedHeroOptions = getCuratedHeroNameOptions(recipeName);

  const [initialParentCuratedConfiguration, initialChildCuratedConfigurations] =
    getParentAndChildConfigurations(curatedHeroConfigurations);

  const [childConfigurations, setChildConfigurations] = useState(initialChildCuratedConfigurations);
  const [parentConfiguration, setParentConfiguration] = useState(
    getInitialConfiguration(initialParentCuratedConfiguration, homePage.id),
  );
  const { name, enabled } = getCuratedHeroSettings(parentConfiguration);

  const {
    getHeroConfigurationsForName,
    setHeroConfigurationsForName,
    reset: resetMultipleHeros,
  } = useMultipleHeros(curatedHeroOptions, name, childConfigurations);

  function resetState(savedParentConfiguration, savedChildConfigurations) {
    setParentConfiguration(savedParentConfiguration);
    setChildConfigurations(savedChildConfigurations);
    resetMultipleHeros();
  }

  function onChangeName(nextName) {
    setParentConfiguration((prevParentConfiguration) => {
      const currentName = _get(prevParentConfiguration, 'data.name');
      if (nextName !== currentName) {
        setHeroConfigurationsForName(currentName, childConfigurations);

        const existingConfigurations = getHeroConfigurationsForName(nextName);
        if (_isEmpty(existingConfigurations)) {
          setChildConfigurations(getInitialConfigurationsForSetting(nextName, homePage.id));
        } else {
          setChildConfigurations(existingConfigurations);
        }
        return { ...prevParentConfiguration, data: { name: nextName } };
      }
      return prevParentConfiguration;
    });
  }

  function onChangeEnabled(nextEnabled) {
    setParentConfiguration((prevParentConfiguration) => ({
      ...prevParentConfiguration,
      enabled: nextEnabled,
    }));
  }

  function updateComponentConfiguration(componentConfigurationId, path, value) {
    // set images, content, and interactions on component configurations
    setChildConfigurations((prevChildConfigurations) =>
      prevChildConfigurations.map((componentConfiguration) => {
        if (selectEntityId(componentConfiguration) === componentConfigurationId) {
          const component = _cloneDeep(componentConfiguration);
          return _isEmpty(path) ? value : _set(component, path, value);
        }
        return componentConfiguration;
      }),
    );
  }

  function getConfigurationIdsToDelete() {
    const { name: currentName } = getCuratedHeroSettings(parentConfiguration);
    const unusedConfigurations = curatedHeroOptions.reduce(
      (acc, curatedHeroName) =>
        curatedHeroName === currentName
          ? acc
          : [...acc, ...getHeroConfigurationsForName(curatedHeroName)],
      [],
    );
    return unusedConfigurations.map((configuration) => configuration.id).filter(Boolean);
  }

  async function saveCuratedHero() {
    // delete component configurations
    const configurationIdsToDelete = getConfigurationIdsToDelete();
    const deletePromises = configurationIdsToDelete.map((id) => deleteComponentConfiguration(id));
    await Promise.all(deletePromises);

    // update parent component configuration
    const savedParentConfiguration = await upsertComponentConfiguration(parentConfiguration);

    // upsert component configurations in childConfigurations
    const upsertChildConfigurationPromises = childConfigurations.map((componentConfiguration) =>
      upsertComponentConfiguration({
        ...componentConfiguration,
        parentComponentConfigurationId: savedParentConfiguration.id,
      }),
    );
    const savedChildConfigurations = await Promise.all(upsertChildConfigurationPromises);
    dispatch(flagBusinessSaved(true));
    resetState(savedParentConfiguration, savedChildConfigurations);
  }

  return {
    isLoading: isLoadingComponentConfigurations,
    isSubmitting,
    name,
    enabled,
    onChangeName,
    onChangeEnabled,
    childConfigurations,
    parentConfiguration,
    setChildConfigurations,
    curatedHeroOptions,
    updateComponentConfiguration,
    saveCuratedHero,
    getComponentConfigurationSetting: (componentConfiguration) =>
      getComponentConfigurationSetting(name, componentConfiguration),
    getComponentConfigurationSettings: () => getComponentConfigurationSettings(name),
    getComponentConfiguration,
  };
}
