import { useState } from 'react';

import useAsyncEffect from '../../components/modules/foundation/hooks/use-async-effect';
import API from '../../libs/api';

export default function useOrderCustomerReports(initialFilters) {
  const [report, setReport] = useState({
    filters: initialFilters,
    customers: [],
    currentPage: 1,
    totalPages: 1,
    loading: false,
    errorMessage: '',
  });

  function setLoading(nextLoadingValue) {
    setReport((previousReport) => ({ ...previousReport, loading: nextLoadingValue }));
  }

  function setErrorMessage(nextErrorMessageValue) {
    setReport((previousReport) => ({ ...previousReport, errorMessage: nextErrorMessageValue }));
  }

  function setFilters(nextQueryParamsValue) {
    setReport((previousReport) => ({ ...previousReport, filters: nextQueryParamsValue }));
  }

  function setCurrentPage(nextCurrentPageValue) {
    setReport((previousReport) => ({ ...previousReport, currentPage: nextCurrentPageValue }));
  }

  const { filters, customers, currentPage, totalPages, loading, errorMessage } = report;

  async function fetchReport() {
    const customerFilters = {
      ...filters,
      page: currentPage,
    };

    try {
      setLoading(true);
      setErrorMessage('');
      const paginatedOrders = await API.getOrderCustomerReports(customerFilters);

      const { total_pages: nextTotalPages, results: nextCustomers } = paginatedOrders;

      setReport((previousReport) => ({
        ...previousReport,
        customers: nextCustomers,
        totalPages: nextTotalPages,
      }));
    } catch (e) {
      setReport((previousReport) => ({
        ...previousReport,
        errorMessage: 'Unexpected issue fetching customers',
      }));
    } finally {
      setLoading(false);
    }
  }

  useAsyncEffect(async () => {
    await fetchReport();
  }, [filters, currentPage]);

  return {
    customers,
    currentPage,
    totalPages,
    loading,
    errorMessage,
    filters,
    setFilters,
    setCurrentPage,
  };
}
