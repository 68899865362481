import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { createOptionsFromTags } from './TagsField.utils';

const propTypes = {
  id: PropTypes.string,
  currentTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableTags: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func,
  allowAdditions: PropTypes.bool,
  placeholder: PropTypes.string,
  allowMultiple: PropTypes.bool,
};
const defaultProps = {
  id: undefined,
  availableTags: [],
  onUpdate: () => {},
  allowAdditions: true,
  allowMultiple: true,
  placeholder: 'Enter a campaign tag',
};

export default function TagsField({
  id,
  currentTags,
  availableTags,
  onUpdate,
  placeholder,
  allowAdditions,
  allowMultiple,
}) {
  const options = createOptionsFromTags(availableTags);

  function search(availableOptions, query) {
    // lowercase search in text value of each option
    const regex = new RegExp(query, 'i');
    return availableOptions.filter((option) => regex.test(option.text));
  }

  function handleChange(e, { value: selectedTags }) {
    onUpdate(selectedTags);
  }

  return (
    <Dropdown
      id={id}
      allowAdditions={allowAdditions}
      search={search}
      options={options}
      value={currentTags}
      placeholder={placeholder}
      onChange={handleChange}
      fluid
      multiple={allowMultiple}
      selection
    />
  );
}

TagsField.propTypes = propTypes;
TagsField.defaultProps = defaultProps;
