/* eslint-disable camelcase */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Message, Icon, List, Button } from 'semantic-ui-react';

import _clone from 'lodash/clone';
import _compact from 'lodash/compact';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isNaN from 'lodash/isNaN';
import _reject from 'lodash/reject';
import _toNumber from 'lodash/toNumber';
import { useSelector } from 'react-redux';

import {
  SERVER_ERROR_MESSAGE_SAVING,
  FORM_ERROR_MESSAGE_HEADER,
  FORM_ERROR_MESSAGE_NAME,
  FROM_ERROR_MESSAGE_MINIMUM,
  FROM_ERROR_MESSAGE_MAXIMUM,
  NAME_INPUT_HELP_MESSAGE,
  LIMITS_INPUT_HELP_MESSAGE,
  MODIFIERS_INPUT_HELP_MESSAGE,
  NAME_INPUT_PLACEHOLDER,
  MODIFIER_NAME_INPUT_PLACEHOLDER,
  MODIFIER_PRICE_INPUT_PLACEHOLDER,
  FORM_DELETING_STATUS,
  FORM_SAVING_STATUS,
  CONFIRMATION_DELETE_MODIFIER_SET_MESSAGE,
  CONFIRMATION_DELETE_MODIFIER_MESSAGE,
} from './ModifierSetForm.constants';
import API from '../../../../../../../libs/api';
import { selectActiveProduct } from '../../../../../../../selectors';
import ConfirmModal from '../../../../../../common/ConfirmModal';
import FormInputInline from '../../../../../../common/FormInputInline';
import PriceField from '../../../../../../fields/PriceField';
import {
  BUSINESS_TYPE_MERCHANTS,
  CATALOG_MAX_ORDINAL,
  CATALOG_MIN_ORDINAL,
} from '../../../constants';
import {
  CatalogItemModifierListInfoPropType,
  CatalogModifierListPropType,
  CatalogModifierPropType,
} from '../../../proptypes';
import { getCatalogObjectId, sortCatalogObjects } from '../../../utils';
import {
  SERVER_ERROR_MESSAGE_DELETING,
  DELETE_BUTTON_HELP_MESSAGE,
} from '../../CatalogModifiers.constants';
import {
  createNewModifier,
  getModifierListSelectionRange,
  castModifierListSelectionRange,
  saveModifierList,
  saveModifier,
} from '../../CatalogModifiers.utils';
import ModifierSetFormWrapper from '../ModifierSetFormWrapper/ModifierSetFormWrapper';

import './ModifierSetForm.scss';

const propTypes = {
  initialItemModifierListInfo: CatalogItemModifierListInfoPropType,
  initialModifierList: CatalogModifierListPropType.isRequired,
  initialModifiers: PropTypes.arrayOf(CatalogModifierPropType),
  businessType: PropTypes.string,
  businessId: PropTypes.number.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  isNestedForm: PropTypes.bool,
};

const defaultProps = {
  initialItemModifierListInfo: null,
  initialModifiers: [],
  businessType: BUSINESS_TYPE_MERCHANTS,
  onSave: () => null,
  onDelete: () => null,
  isNestedForm: false,
};

function ModifierSetForm({
  initialItemModifierListInfo,
  initialModifierList,
  initialModifiers,
  businessType,
  businessId,
  onSave,
  onDelete,
  isNestedForm,
}) {
  const {
    object_id: initialModifierListObjectId,
    modifier_list_data: {
      name: initialName,
      default_min_selected_modifiers,
      default_max_selected_modifiers,
    },
  } = initialModifierList;
  const initialModifierListInfoFields = getModifierListSelectionRange(
    initialModifierList,
    initialItemModifierListInfo,
  );
  if (initialModifierListInfoFields.minSelectedModifiers === null) {
    initialModifierListInfoFields.minSelectedModifiers = '';
  }
  if (initialModifierListInfoFields.maxSelectedModifiers === null) {
    initialModifierListInfoFields.maxSelectedModifiers = '';
  }
  const sortedInitialModifiers = sortCatalogObjects(initialModifiers, 'modifier_data');
  const [name, setName] = useState(initialName);
  const [modifierListInfoFields, setModifierListInfoFields] = useState(
    initialModifierListInfoFields,
  );
  const [modifiers, setModifiers] = useState(sortedInitialModifiers);
  const [modifiersToRemove, setModifiersToRemove] = useState([]);
  const [formErrorMessage, setFormErrorMessage] = useState({ name: '', limits: '' });
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(null);
  const currentActiveProduct = useSelector(selectActiveProduct);
  const [isConfirmDeleteModalModifierSetOpened, setIsConfirmDeleteModifierSetModalOpened] =
    useState(false);
  const [isConfirmDeleteModalModifierOpened, setIsConfirmDeleteModalModifierOpened] =
    useState(false);

  function handleChangeName(e, { value }) {
    if (value === '') {
      setFormErrorMessage((prevFormErrorMessage) => ({
        ...prevFormErrorMessage,
        name: FORM_ERROR_MESSAGE_NAME,
      }));
    } else if (formErrorMessage.name) {
      setFormErrorMessage((prevFormErrorMessage) => ({
        ...prevFormErrorMessage,
        name: '',
      }));
    }
    setName(value);
  }

  function handleChangeModifierListInfoField(e, { name: fieldName, value }) {
    let formError = '';
    if (!/^[0-9]*$/.test(value)) return;
    const parsedValue = _toNumber(value);
    if (_isNaN(parsedValue)) return;
    if (parsedValue < 0) return;
    if (value !== '') {
      if (
        fieldName === 'minSelectedModifiers' &&
        modifierListInfoFields.maxSelectedModifiers !== ''
      ) {
        const parsedMax = _toNumber(modifierListInfoFields.maxSelectedModifiers);
        if (parsedValue > parsedMax) {
          formError = FROM_ERROR_MESSAGE_MINIMUM;
        }
      }
      if (
        fieldName === 'maxSelectedModifiers' &&
        modifierListInfoFields.minSelectedModifiers !== ''
      ) {
        const parsedMin = _toNumber(modifierListInfoFields.minSelectedModifiers);
        if (parsedValue < parsedMin) {
          formError = FROM_ERROR_MESSAGE_MAXIMUM;
        }
      }
    }
    if (formError || formErrorMessage.limits) {
      setFormErrorMessage((prevFormErrorMessage) => ({
        ...prevFormErrorMessage,
        limits: formError,
      }));
    }
    setModifierListInfoFields((prevModifierListInfoFields) => ({
      ...prevModifierListInfoFields,
      [fieldName]: value,
    }));
  }

  function curriedHandleChangeModifierField(id) {
    return function handleChangeModifierField(e, { name: fieldName, value }) {
      const newModifiers = _clone(modifiers);
      const index = _findIndex(newModifiers, (modifier) => getCatalogObjectId(modifier) === id);
      newModifiers[index].modifier_data[fieldName] = value;
      setModifiers(newModifiers);
    };
  }

  function handleDeleteModifier(id) {
    let newModifiers = _clone(modifiers);
    const modifierToRemove = _find(newModifiers, (modifier) => getCatalogObjectId(modifier) === id);
    if (_has(modifierToRemove, 'object_id')) {
      setModifiersToRemove((prevModifiersToRemove) => [...prevModifiersToRemove, modifierToRemove]);
    }
    newModifiers = _reject(newModifiers, (modifier) => getCatalogObjectId(modifier) === id);
    newModifiers = newModifiers.map((modifier, index) => {
      const modifier_data = {
        ...modifier.modifier_data,
        ordinal: index,
      };
      return {
        ...modifier,
        modifier_data,
      };
    });
    setModifiers(newModifiers);
    setIsConfirmDeleteModalModifierOpened(false);
  }

  function handleAddModifier() {
    setModifiers((prevModifiers) => {
      const prevOrdinal =
        prevModifiers.length === 0
          ? CATALOG_MIN_ORDINAL
          : _get(prevModifiers, `[${prevModifiers.length - 1}].modifier_data.ordinal`, undefined);

      const newModifier = createNewModifier(initialModifierListObjectId, {
        previousOrdinal: prevOrdinal,
        nextOrdinal: CATALOG_MAX_ORDINAL,
      });
      return [...prevModifiers, newModifier];
    });
  }

  async function handleDelete() {
    setServerErrorMessage('');
    setIsSubmitting(FORM_DELETING_STATUS);
    try {
      // NOTE: the logic for deleting occuring in the parent component since it is common to both the form and the detail
      await onDelete();
    } catch (err) {
      setServerErrorMessage(SERVER_ERROR_MESSAGE_DELETING);
      setIsSubmitting(null);
    }
    setIsConfirmDeleteModifierSetModalOpened(false);
  }

  async function handleSave() {
    setServerErrorMessage('');
    setIsSubmitting(FORM_SAVING_STATUS);
    try {
      // TODO: refactor to a util function
      let defaultMinSelectedModifiers = default_min_selected_modifiers;
      let defaultMaxSelectedModifiers = default_max_selected_modifiers;
      const castedModifierListInfoFields = castModifierListSelectionRange(modifierListInfoFields);
      if (!initialItemModifierListInfo || !initialItemModifierListInfo.modifier_list) {
        defaultMinSelectedModifiers = castedModifierListInfoFields.minSelectedModifiers;
        defaultMaxSelectedModifiers = castedModifierListInfoFields.maxSelectedModifiers;
      }
      const savedModifierListData = await saveModifierList(
        businessType,
        businessId,
        currentActiveProduct,
        initialModifierListObjectId,
        name,
        defaultMinSelectedModifiers,
        defaultMaxSelectedModifiers,
      );

      await Promise.all(
        modifiersToRemove.map((modifierToRemove) =>
          API.removeCatalogObject(businessType, businessId, modifierToRemove.object_id),
        ),
      );

      const nullOrdinal = null;
      const nullNextOrdinal = null;

      const savedModifiersData = await modifiers.reduce(
        async (previousSavedModifiersDataPromise, modifier) => {
          const previousSavedModifiersData = await previousSavedModifiersDataPromise;

          const previousOrdinal = _get(
            previousSavedModifiersData,
            `[${previousSavedModifiersData.length - 1}].modifier_data.ordinal`,
            CATALOG_MIN_ORDINAL,
          );

          const modfierObjectId = _has(modifier, 'object_id') ? modifier.object_id : null;
          const { name: modifierName, price } = modifier.modifier_data;
          return saveModifier(
            businessType,
            businessId,
            currentActiveProduct,
            modfierObjectId,
            savedModifierListData.object_id,
            modifierName,
            price,
            nullOrdinal,
            previousOrdinal,
            nullNextOrdinal,
          ).then((savedModifier) => [...previousSavedModifiersData, savedModifier]);
        },
        Promise.resolve([]),
      );

      onSave(savedModifierListData, savedModifiersData, castedModifierListInfoFields);
    } catch (err) {
      setServerErrorMessage(SERVER_ERROR_MESSAGE_SAVING);
    } finally {
      setIsSubmitting(null);
    }
  }

  return (
    <section>
      <ModifierSetFormWrapper isNestedForm={isNestedForm} onSubmit={handleSave}>
        <Form.Group className="modifier-set-form-top-row">
          <Form.Group className="left-column">
            <div className="name-input-container">
              <FormInputInline title="Name" helpMessage={NAME_INPUT_HELP_MESSAGE}>
                {(fieldWidth) => (
                  <Form.Input
                    width={fieldWidth}
                    name="name"
                    value={name}
                    placeholder={NAME_INPUT_PLACEHOLDER}
                    onChange={handleChangeName}
                  />
                )}
              </FormInputInline>
            </div>
            <div className="limits-input-container">
              <FormInputInline title="Limits" helpMessage={LIMITS_INPUT_HELP_MESSAGE}>
                {(fieldWidth) => (
                  <>
                    <Form.Input
                      width={fieldWidth}
                      name="minSelectedModifiers"
                      label="Minimum"
                      type="number"
                      value={modifierListInfoFields.minSelectedModifiers}
                      onChange={handleChangeModifierListInfoField}
                    />
                    <Form.Input
                      width={fieldWidth}
                      name="maxSelectedModifiers"
                      label="Maximum"
                      type="number"
                      value={modifierListInfoFields.maxSelectedModifiers}
                      onChange={handleChangeModifierListInfoField}
                    />
                  </>
                )}
              </FormInputInline>
            </div>
          </Form.Group>
          <Form.Group className="right-column">
            <ConfirmModal
              onOpen={() => setIsConfirmDeleteModifierSetModalOpened(true)}
              open={isConfirmDeleteModalModifierSetOpened}
              trigger={
                <Form.Button
                  className="modifier-set-delete-button modifier-set-button"
                  disabled={isSubmitting === FORM_SAVING_STATUS}
                  loading={isSubmitting === FORM_DELETING_STATUS}
                >
                  <Icon name="trash alternate outline" title={DELETE_BUTTON_HELP_MESSAGE} />
                </Form.Button>
              }
              header="Delete Modifier Set"
              content={CONFIRMATION_DELETE_MODIFIER_SET_MESSAGE}
              confirmButton={<Button>Delete</Button>}
              onConfirm={handleDelete}
              onCancel={() => setIsConfirmDeleteModifierSetModalOpened(false)}
            />
            <Form.Button
              className="modifier-set-save-button modifier-set-button"
              onClick={handleSave}
              disabled={
                !!formErrorMessage.name ||
                !!formErrorMessage.limits ||
                isSubmitting === FORM_DELETING_STATUS
              }
              loading={isSubmitting === FORM_SAVING_STATUS}
            >
              <Icon name="save outline" title="Save changes" />
              Save Modifier Set
            </Form.Button>
          </Form.Group>
        </Form.Group>
        <Form.Group className="modifier-set-form-bottom-row">
          <FormInputInline title="Modifiers" helpMessage={MODIFIERS_INPUT_HELP_MESSAGE}>
            {(fieldWidth) => (
              <List>
                {modifiers.map((modifier) => (
                  <List.Item className="modifier-input-row" key={getCatalogObjectId(modifier)}>
                    <Form.Input
                      width={fieldWidth}
                      name="name"
                      className="modifier-name-input"
                      value={modifier.modifier_data.name}
                      placeholder={MODIFIER_NAME_INPUT_PLACEHOLDER}
                      onChange={curriedHandleChangeModifierField(getCatalogObjectId(modifier))}
                    />
                    <Form.Group className="price-column">
                      <PriceField
                        name="price"
                        priceInitialValue={modifier.modifier_data.price}
                        placeholder={MODIFIER_PRICE_INPUT_PLACEHOLDER}
                        allowNegativePrices
                        onChange={curriedHandleChangeModifierField(getCatalogObjectId(modifier))}
                      />
                      <ConfirmModal
                        onOpen={() => setIsConfirmDeleteModalModifierOpened(true)}
                        open={isConfirmDeleteModalModifierOpened}
                        trigger={
                          <Form.Button className="modifier-set-delete-button modifier-set-button">
                            <Icon
                              name="trash alternate outline"
                              title="Delete modifier from list."
                            />
                          </Form.Button>
                        }
                        header="Delete Modifier"
                        content={CONFIRMATION_DELETE_MODIFIER_MESSAGE}
                        confirmButton={<Button>Delete</Button>}
                        onConfirm={() => handleDeleteModifier(getCatalogObjectId(modifier))}
                        onCancel={() => setIsConfirmDeleteModalModifierOpened(false)}
                      />
                    </Form.Group>
                  </List.Item>
                ))}
                <List.Item>
                  <Form.Button
                    className="add-another-modifier-button modifier-set-button"
                    onClick={handleAddModifier}
                  >
                    ADD ANOTHER MODIFIER
                  </Form.Button>
                </List.Item>
                {(formErrorMessage.name || formErrorMessage.limits) && (
                  <List.Item>
                    <Message className="modifer-set-form-error-message" error>
                      <Message.Header>{FORM_ERROR_MESSAGE_HEADER}</Message.Header>
                      <Message.List
                        items={_compact([formErrorMessage.name, formErrorMessage.limits])}
                      />
                    </Message>
                  </List.Item>
                )}
                {serverErrorMessage && (
                  <List.Item>
                    <Message
                      className="modifer-set-form-error-message"
                      error
                      header={serverErrorMessage}
                    />
                  </List.Item>
                )}
              </List>
            )}
          </FormInputInline>
        </Form.Group>
      </ModifierSetFormWrapper>
    </section>
  );
}

ModifierSetForm.propTypes = propTypes;
ModifierSetForm.defaultProps = defaultProps;

export default ModifierSetForm;
