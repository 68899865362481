import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import MarkdownField from '../../../../../../fields/MarkdownField';
import { getTileClassName, getTileContent } from '../../../../services/tile';
import { TilePropType } from '../../../../types/Tile';
import { CustomHeroFormContext } from '../../CustomHeroForm.context';
import TileBackgroundConfiguration from '../TileBackgroundConfiguration';
import TileInteractionsConfiguration from '../TileInteractionsConfiguration';

import './TileConfiguration.scss';

const propTypes = {
  tileIndex: PropTypes.number.isRequired,
  tile: TilePropType.isRequired,
};

const defaultProps = {};

export default function TileConfiguration({ tileIndex, tile }) {
  const { setTileContent, setClassName } = useContext(CustomHeroFormContext);

  const contentComponentConfiguration = getTileContent(tile);
  const className = getTileClassName(tile);

  const { content = '' } = contentComponentConfiguration;

  function onContentChange(newContent) {
    setTileContent({ tileIndex }, { ...contentComponentConfiguration, content: newContent });
  }

  function onClassNameChange(e, { value: newClassName }) {
    setClassName({ tileIndex }, { className: newClassName });
  }

  return (
    <div className="tile-configuration">
      <Form.Field>
        <label htmlFor={`content-${tileIndex}`}>Content</label>
        <MarkdownField
          id={`content-${tileIndex}`}
          value={content}
          onChange={onContentChange}
          options={{
            placeholder: 'Enter a tile content here',
            minHeight: '200px',
          }}
        />
      </Form.Field>
      <Form.Input
        className="small"
        label="Class Name"
        placeholder="Enter a class name here"
        value={className}
        onChange={onClassNameChange}
      />
      <TileBackgroundConfiguration tileIndex={tileIndex} tile={tile} />
      <TileInteractionsConfiguration tileIndex={tileIndex} tile={tile} />
    </div>
  );
}

TileConfiguration.propTypes = propTypes;
TileConfiguration.defaultProps = defaultProps;
