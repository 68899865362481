import React, { Component } from 'react';

import { Message } from 'semantic-ui-react';

import _ from 'lodash';
import { connect } from 'react-redux';

import {
  DOC_UPLOAD_MAX_SIZE,
  MAX_SIZE_EXCEEDED_MESSAGE,
  MAX_SIZE_EXCEEDED_SUGGESTION_MESSAGE,
  MAX_DOC_SIZE_EXCEEDED_SUGGESTION_URL,
} from './MenuFiles.constants';
import {
  updateGalleryImage as updateGalleryImageConnect,
  addGalleryImage as addGalleryImageConnect,
  deleteGalleryImage as deleteGalleryImageConnect,
} from '../../../../actions/business';
import { isMenuFile } from '../../../../libs/menu';
import Utils from '../../../../libs/utils';
import ExternalAnchor from '../../../common/ExternalAnchor/ExternalAnchor';
import FormContainer from '../../../common/FormContainer';
import SortableMenuCard from '../SortableMenuCard';

import './MenuFiles.scss';

class MenuFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFileSizeError: false,
      filename: null,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleFilenameEdit = this.handleFilenameEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleHideFileSizeError = this.handleHideFileSizeError.bind(this);
  }

  handleDelete(index) {
    const { deleteGalleryImage } = this.props;
    deleteGalleryImage(index);
  }

  handleShowFileSizeError(filename) {
    this.setState({
      showFileSizeError: !!filename,
      filename,
    });
  }

  handleHideFileSizeError() {
    this.setState({
      showFileSizeError: false,
    });
  }

  async handleImageChange(e, index, append = false) {
    e.stopPropagation();
    const { images } = this.props;
    const files = Array.from(_.get(e, 'target.files', []) || []);

    if (files.length > 0) {
      files.forEach((file, i) => {
        if (file) {
          if (file.size > DOC_UPLOAD_MAX_SIZE) {
            this.handleShowFileSizeError(file.name);
            return;
          }

          this.handleShowFileSizeError();

          if (i === 0 && !append) {
            this.uploadFile(file, index, 0);
          } else {
            this.uploadFile(file, images.length, i, false);
          }
        }
      });
    }
  }

  handleFilenameEdit(e, index) {
    const { updateGalleryImage } = this.props;
    const filename = e.target.value;
    updateGalleryImage({ field: 'filename', fieldValue: filename, index });
  }

  handleSort(menuItems) {
    const { updateGalleryImage } = this.props;
    return ({ oldIndex, newIndex }) => {
      const copy = true;
      const sortedItems = Utils.moveListItem(menuItems, oldIndex, newIndex, copy);
      const itemsToUpdate = Utils.itemsToUpdateOrder(sortedItems);

      itemsToUpdate.forEach((item) => {
        updateGalleryImage({
          field: 'order',
          fieldValue: item.order,
          index: item.globalIndex,
        });
      });
    };
  }

  getImageFiles() {
    const { images } = this.props;
    return images
      .map((image, index) => ({ ...image, globalIndex: index }))
      .filter((image) => isMenuFile(image))
      .sort(Utils.sortByOrder)
      .map((image, index) => ({ ...image, order: index }));
  }

  uploadFile(file, index, offset, update = true) {
    const { updateGalleryImage, addGalleryImage } = this.props;
    const url = window.URL.createObjectURL(file);
    const newIndex = index + offset;

    if (update) {
      updateGalleryImage({ field: 'url', fieldValue: url, index: newIndex });
      updateGalleryImage({
        field: 'source',
        fieldValue: 'LOCAL',
        index: newIndex,
      });
      updateGalleryImage({ field: 'file', fieldValue: file, index: newIndex });
    } else {
      addGalleryImage({
        url,
        source: 'LOCAL',
        file,
        tags: ['menu'],
        order: newIndex,
        _touchedFields: ['url', 'source', 'file', 'tags', 'order'],
      });
    }
  }

  render() {
    const { images } = this.props;
    const { filename, showFileSizeError } = this.state;
    const menuItems = this.getImageFiles();

    return (
      <>
        <FormContainer loadedKeyPath={['business', 'galleryImages']}>
          <SortableMenuCard
            axis="x"
            useDragHandle
            onSortEnd={this.handleSort(menuItems)}
            menuItems={menuItems}
            handleEditFilename={this.handleFilenameEdit}
            handleChange={this.handleImageChange}
            handleDelete={this.handleDelete}
            handleAdd={(e) => this.handleImageChange(e, images.length, true)}
          />
        </FormContainer>
        {showFileSizeError && (
          <Message negative onDismiss={this.handleHideFileSizeError}>
            <Message.Header>{MAX_SIZE_EXCEEDED_MESSAGE}</Message.Header>
            <Message.List>
              <li>{filename}</li>
            </Message.List>
            <Message.Content className="menu-upload-error-message-footer">
              <>
                {MAX_SIZE_EXCEEDED_SUGGESTION_MESSAGE}
                &nbsp;
                <ExternalAnchor href={MAX_DOC_SIZE_EXCEEDED_SUGGESTION_URL}>
                  {MAX_DOC_SIZE_EXCEEDED_SUGGESTION_URL}
                </ExternalAnchor>
              </>
            </Message.Content>
          </Message>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateGalleryImage: (payload) => dispatch(updateGalleryImageConnect(payload)),
  addGalleryImage: (payload) => dispatch(addGalleryImageConnect(payload)),
  deleteGalleryImage: (payload) => dispatch(deleteGalleryImageConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  loaded: _.get(business, 'galleryImages._loaded') || false,
  images: _.get(business, 'galleryImages.value') || [],
  businessType: _.get(business, 'core.value.type'),
  businessId: _.get(business, 'core.value.id'),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuFiles);
