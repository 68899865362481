import React, { useState } from 'react';

import { Button } from 'semantic-ui-react';

import EditRedirectModal from '../EditRedirectModal';

import './AddRedirectButton.scss';

const propTypes = {};

const defaultProps = {};

export default function AddRedirectButton() {
  const [showModal, setShowModal] = useState(false);

  function displayModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      {showModal && <EditRedirectModal open={showModal} onClose={closeModal} />}
      <Button
        content="Add Redirect"
        className="add-redirect-button secondary-navy"
        onClick={displayModal}
      />
    </>
  );
}

AddRedirectButton.propTypes = propTypes;
AddRedirectButton.defaultProps = defaultProps;
