import React, { useState, useEffect, useContext } from 'react';

import _isEmpty from 'lodash/isEmpty';

import {
  DEFAULT_WIDGET_TYPE,
  SINGLE_IMAGE_MAX_FILES_ALLOWED,
  MULTIPLE_IMAGE_MAX_FILES_ALLOWED,
} from './EditImageBlock.constants';
import {
  updateImageConfiguration,
  createImageConfiguration,
  updateImageElementFromSave,
  isSingleImageWidgetType,
} from './EditImageBlock.utils';
import { ImageWidgetSelector, UploadedImageList, ImageUploadWell } from './components';
import { useLatestRefValue } from '../../../../../utils/hooks';
import { IMAGE_BLOCK_ELEMENT_TYPE } from '../../../../constants/types';
import { ImageBlockPropType } from '../../../../types/image-block.types';
import { EditPageElementModalContext } from '../../EditPageElementModal.context';

import './EditImageBlock.scss';

const propTypes = {
  element: ImageBlockPropType.isRequired,
};

const defaultProps = {};

export default function EditImageBlock({ element }) {
  const { images: initialImages = [], widgetType: initialWidgetType = DEFAULT_WIDGET_TYPE } =
    element;
  const {
    page,
    registerCallback,
    onUpdateElementState,
    addValidationError,
    clearValidationErrors,
  } = useContext(EditPageElementModalContext);

  const [widgetType, setWidgetType] = useState(initialWidgetType);
  const [images, setImages] = useState(initialImages);

  const latestElementRef = useLatestRefValue(element);
  const latestWidgetTypeRef = useLatestRefValue(widgetType);
  const latestImagesRef = useLatestRefValue(images);

  function onDeleteImage(imageId) {
    const currentElement = latestElementRef.current;
    const nextImages = images.filter(({ id }) => id !== imageId);

    setImages(nextImages);
    onUpdateElementState(IMAGE_BLOCK_ELEMENT_TYPE, { ...currentElement, images: nextImages });
  }

  function onSelectImages(nextImages) {
    const currentElement = latestElementRef.current;

    setImages(nextImages);
    onUpdateElementState(IMAGE_BLOCK_ELEMENT_TYPE, { ...currentElement, images: nextImages });
  }

  function onSelectWidget(nextWidgetType) {
    const currentElement = latestElementRef.current;
    const { images: currentImages = [] } = currentElement;

    setWidgetType(nextWidgetType);
    onUpdateElementState(IMAGE_BLOCK_ELEMENT_TYPE, {
      ...currentElement,
      widgetType: nextWidgetType,
    });

    if (isSingleImageWidgetType(nextWidgetType) && !_isEmpty(currentImages)) {
      onSelectImages([currentImages[0]]);
    }
  }

  async function onSaveContent() {
    const currentElement = latestElementRef.current;
    const currentImages = latestImagesRef.current;
    const currentWidgetType = latestWidgetTypeRef.current;
    const { componentConfigurationId } = currentElement;
    const { id: pageId } = page;
    if (componentConfigurationId) {
      const result = await updateImageConfiguration(
        componentConfigurationId,
        currentImages,
        currentWidgetType,
      );

      return updateImageElementFromSave(currentElement, result);
    }

    const result = await createImageConfiguration(currentImages, currentWidgetType, pageId);
    return updateImageElementFromSave(currentElement, result);
  }

  useEffect(() => {
    clearValidationErrors();
    if (_isEmpty(images)) {
      addValidationError('At least one image is required');
    }
  }, [images]);

  useEffect(() => {
    registerCallback(IMAGE_BLOCK_ELEMENT_TYPE, onSaveContent);
  }, []);

  const oneImageAllowed = widgetType === DEFAULT_WIDGET_TYPE;

  return (
    <div className="edit-image-block">
      <ImageWidgetSelector selectedType={widgetType} onSelect={onSelectWidget} />
      <UploadedImageList
        images={images}
        numDisplay={oneImageAllowed ? 1 : null}
        onDeleteImage={onDeleteImage}
      />
      <ImageUploadWell
        images={images}
        onSelectImages={onSelectImages}
        maxFilesAllowed={
          oneImageAllowed ? SINGLE_IMAGE_MAX_FILES_ALLOWED : MULTIPLE_IMAGE_MAX_FILES_ALLOWED
        }
      />
    </div>
  );
}

EditImageBlock.propTypes = propTypes;
EditImageBlock.defaultProps = defaultProps;
