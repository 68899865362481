import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Container, Header, Segment } from 'semantic-ui-react';

import clsx from 'clsx';
import _get from 'lodash/get';
import { connect } from 'react-redux';

import ConfirmDeleteIcon from './components/ConfirmDeleteIcon';
import InlineFieldContainerTitle from './components/InlineFieldContainerTitle';
import { hasWebsiteFeatureSet, isAuthorized } from '../../../libs/auth';
import { selectFeatureSets } from '../../../selectors/subscriptions';

import './InlineFieldContainer.scss';

const InlineFieldContainer = ({
  title,
  enableDelete,
  onDelete,
  children,
  tooltipTitle,
  tooltipDescription,
  user,
  business,
  roles,
  permissions,
  featureSets,
  websiteFeatureSets,
  confirmDelete,
  className,
}) => {
  const authorized = isAuthorized(user, business, roles, permissions, {});
  const hasFeatures = hasWebsiteFeatureSet(websiteFeatureSets, featureSets);
  const showPlanGating = !authorized || !hasFeatures;

  const finalClassName = clsx({ 'form-inline-container': true, [className]: className });

  return (
    <Container className={finalClassName}>
      <Header>
        <Grid columns={2} style={{ width: '100%' }}>
          <Grid.Column floated="left" width={15}>
            {title && (
              <InlineFieldContainerTitle
                title={title}
                tooltipTitle={tooltipTitle}
                tooltipDescription={tooltipDescription}
                showPlanGating={showPlanGating}
                requiredFeatureSets={featureSets}
              />
            )}
          </Grid.Column>
          <Grid.Column width={1}>
            {enableDelete && (
              <ConfirmDeleteIcon confirmDelete={confirmDelete} onDelete={onDelete} />
            )}
          </Grid.Column>
        </Grid>
      </Header>

      {showPlanGating ? (
        <Segment
          className="disabled-segment"
          disabled
          basic
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        >
          {children}
        </Segment>
      ) : (
        children
      )}
    </Container>
  );
};

InlineFieldContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  enableDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
  roles: PropTypes.arrayOf(PropTypes.string),
  featureSets: PropTypes.arrayOf(PropTypes.string),
  tooltipTitle: PropTypes.string,
  tooltipDescription: PropTypes.string,
  confirmDelete: PropTypes.bool,
};

InlineFieldContainer.defaultProps = {
  title: '',
  enableDelete: true,
  onDelete: () => null,
  permissions: [],
  roles: [],
  featureSets: [],
  tooltipTitle: '',
  tooltipDescription: '',
  confirmDelete: false,
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  user: _get(state, 'user.core.value'),
  business: _get(state, 'business.core.value'),
  website: _get(state, 'website'),
  websiteFeatureSets: selectFeatureSets(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(InlineFieldContainer);
