import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox, Grid, Header, Select, TextArea } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  DESTINATION_ENABLED_FOR_RECIPES,
  PUBLISH_AT_HELP,
  VISIBLE_AT_HELP,
} from './ExtraDetails.constants';
import {
  getDestinationOptions,
  getDestinationValue,
  getPublishDateDisplay,
  getAuthorOptions,
  getAuthorValue,
  getAuthor,
  createMinSchedulingDate,
} from './ExtraDetails.utils';
import { isAdminUser } from '../../../../../libs/auth';
import { DATE_DISPLAY_FORMAT, DATE_UTC_FORMAT } from '../../../../../libs/date-utils/constants';
import { DateTimeField } from '../../../../common';
import HelpTooltip from '../../../../common/HelpTooltip/HelpTooltip';
import ImagePlaceholderField from '../../../../common/ImagePlaceholderField';
import useRecipe from '../../../../modules/foundation/hooks/use-recipe';
import { ImageAltTextField } from '../../../../modules/seo/components';
import { DRAFT } from '../../BlogContentFormModal.constants';

import './ExtraDetails.scss';

const propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  summary: PropTypes.string.isRequired,
  slug: PropTypes.string,
  publishedAt: PropTypes.string,
  publishAt: PropTypes.string,
  visibleAt: PropTypes.string,
  author: PropTypes.shape({
    email: PropTypes.string,
  }),
  heroImage: PropTypes.shape({
    url: PropTypes.string,
    file: PropTypes.string,
  }).isRequired,
  heroImageAltText: PropTypes.string,
  authorVisible: PropTypes.bool,
  dateVisible: PropTypes.bool,
  updateValue: PropTypes.func.isRequired,
  getSelectedImage: PropTypes.func.isRequired,
  removeSelectedImage: PropTypes.func.isRequired,
};
const defaultProps = {
  publishedAt: '',
  publishAt: '',
  visibleAt: '',
  slug: '',
  author: {},
  heroImageAltText: '',
  authorVisible: false,
  dateVisible: false,
};

export default function ExtraDetails({
  status,
  summary,
  destination,
  slug,
  publishedAt,
  publishAt,
  visibleAt,
  author,
  heroImage,
  heroImageAltText,
  authorVisible,
  dateVisible,
  updateValue,
  getSelectedImage,
  removeSelectedImage,
}) {
  const { websiteUsesRecipe } = useRecipe();
  const user = useSelector((state) => _get(state, 'user.core.value'));
  const merchantUsers = useSelector((state) => _get(state, 'website.websiteUsers', []));

  const isAdmin = isAdminUser(user);

  const publishDisplayDate = getPublishDateDisplay(publishedAt);
  const authorOptions = getAuthorOptions(merchantUsers);
  const destinationOptions = getDestinationOptions();

  function onCheckboxChange(e, { checked }, name) {
    const target = { ...e.target, value: checked };

    updateValue({ ...e, target }, name);
  }

  function onAuthorChange(e, { value: userId }) {
    const newAuthor = getAuthor(authorOptions, userId);
    const target = { ...e.target, value: newAuthor };
    updateValue({ ...e, target }, 'author');
  }

  function onHeroImageAltTextChange(e) {
    updateValue(e, 'heroImageAltText');
  }

  function onDestinationChange(e, { value: newDestination }) {
    const target = { ...e.target, value: newDestination };
    updateValue({ ...e, target }, 'destination');
  }

  const isDraft = status === DRAFT;
  const futureDate = createMinSchedulingDate();

  const canSetAuthor = isAdmin;
  const canSetDateVisible = isAdmin;
  const canSetPublishAt = isDraft;
  const canSetVisibleAt = isAdmin && isDraft;
  const canSetDestination = websiteUsesRecipe(DESTINATION_ENABLED_FOR_RECIPES);

  return (
    <div className="extra-details">
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header>Summary</Header>
            <TextArea onChange={(e) => updateValue(e, 'summary')} value={summary} />

            {canSetDestination && (
              <>
                <Header>
                  Destination
                  <HelpTooltip
                    title="Blog Post Destination"
                    content="Determines the page where the blog post will be displayed."
                  />
                </Header>
                <Select
                  className="destination-select"
                  placeholder="Select destination"
                  options={destinationOptions}
                  value={getDestinationValue(destinationOptions, destination)}
                  onChange={onDestinationChange}
                />
              </>
            )}

            {canSetAuthor && (
              <>
                <Header>Author</Header>
                <Checkbox
                  checked={authorVisible}
                  toggle
                  label="Show on website"
                  onClick={(e, target) => {
                    onCheckboxChange(e, target, 'authorVisible');
                  }}
                />
                <Select
                  className="authors-select"
                  placeholder="Select author"
                  options={authorOptions}
                  value={getAuthorValue(authorOptions, author)}
                  onChange={onAuthorChange}
                />
              </>
            )}

            {!_isEmpty(slug) && (
              <>
                <Header>Slug</Header>
                <p>{slug}</p>
              </>
            )}

            <Header>Published At</Header>
            {canSetDateVisible && (
              <Checkbox
                checked={dateVisible}
                toggle
                label="Show on website"
                onClick={(e, target) => {
                  onCheckboxChange(e, target, 'dateVisible');
                }}
              />
            )}
            <p>{publishDisplayDate}</p>

            {canSetPublishAt && (
              <>
                <Header>
                  Publish Date
                  <HelpTooltip
                    title="Publish Date"
                    content={PUBLISH_AT_HELP}
                    position="right center"
                  />
                </Header>
                <DateTimeField
                  datetime={publishAt}
                  className="date-time-field"
                  dateTimeFormat={DATE_DISPLAY_FORMAT}
                  externalDateTimeFormat={DATE_UTC_FORMAT}
                  placeholder="Publish date"
                  onChange={(e) => {
                    updateValue(e, 'publishAt');
                  }}
                  disableMinute
                  clearable
                  minDate={futureDate}
                />
              </>
            )}

            {canSetVisibleAt && (
              <>
                <Header>
                  Visible At
                  <HelpTooltip
                    title="Visible At"
                    content={VISIBLE_AT_HELP}
                    position="right center"
                  />
                </Header>
                <DateTimeField
                  datetime={visibleAt}
                  className="date-time-field"
                  dateTimeFormat={DATE_DISPLAY_FORMAT}
                  externalDateTimeFormat={DATE_UTC_FORMAT}
                  placeholder="Publish date"
                  onChange={(e) => {
                    updateValue(e, 'visibleAt');
                  }}
                  disableMinute
                  clearable
                  minDate={futureDate}
                />
              </>
            )}
          </Grid.Column>

          <Grid.Column width={6}>
            <Header>Hero Image</Header>
            <ImagePlaceholderField
              className="blog-post-hero-image"
              url={heroImage.url || heroImage.file}
              onChange={getSelectedImage}
              onDelete={removeSelectedImage}
              enableDelete
            />
            <ImageAltTextField
              altText={heroImageAltText}
              imageUrl={heroImage.url || heroImage.file}
              onChangeAltText={onHeroImageAltTextChange}
              title={<Header>Hero Image Alt Text</Header>}
              fluid
              disabled={_isEmpty(heroImage.url || heroImage.file)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

ExtraDetails.propTypes = propTypes;
ExtraDetails.defaultProps = defaultProps;
