import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon, Message, Modal } from 'semantic-ui-react';

import {
  DELETE_CATEGORY_MODAL_TITLE,
  DELETE_CATEGORY_MODAL_DESCRIPTION,
  DELETE_CONFIRMATION_BUTTON_TEXT,
} from './DeleteCategoryConfirmationButton.constants';
import { getErrorMessage } from '../../../../../../../libs/errors';
import ConfirmModal from '../../../../../../common/ConfirmModal';

import './DeleteCategoryConfirmationButton.scss';

const propTypes = {
  onDeleteCategory: PropTypes.func.isRequired,
};

function DeleteCategoryConfirmationButton({ onDeleteCategory }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  function showConfirmationModal() {
    setIsOpen(true);
  }

  function hideConfirmationModal() {
    setIsOpen(false);
  }

  async function handleDeleteCategory() {
    try {
      setLoading(true);
      setError('');
      onDeleteCategory();
      hideConfirmationModal();
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        className="delete-category-confirmation-modal-button"
        onClick={showConfirmationModal}
        inverted
      >
        <Icon name="trash" title="Delete category" /> Delete
      </Button>
      <ConfirmModal
        className="delete-category-confirmation-modal"
        open={isOpen}
        header={DELETE_CATEGORY_MODAL_TITLE}
        content={
          <Modal.Content>
            <p>{DELETE_CATEGORY_MODAL_DESCRIPTION}</p>
            {error && <Message error>{error}</Message>}
          </Modal.Content>
        }
        confirmButton={
          <Button disabled={loading} loading={loading}>
            {DELETE_CONFIRMATION_BUTTON_TEXT}
          </Button>
        }
        onCancel={hideConfirmationModal}
        onConfirm={handleDeleteCategory}
      />
    </>
  );
}

DeleteCategoryConfirmationButton.propTypes = propTypes;

export default DeleteCategoryConfirmationButton;
