import React from 'react';

export const CustomPagesContext = React.createContext({
  activePage: null,
  setActivePage: () => null,
  setActivePageId: () => null,
  savePageNode: () => null,
});

export const CustomPagesProvider = CustomPagesContext.Provider;
