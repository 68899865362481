import React from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import './ReportsDownload.scss';

const propTypes = {
  downloadUrl: PropTypes.string.isRequired,
};

const defaultProps = {};

function ReportsDownload({ downloadUrl }) {
  return (
    <Button className="customer-report-download-btn" as="a" href={downloadUrl}>
      <Icon name="file outline" />
      Download Report
    </Button>
  );
}

ReportsDownload.propTypes = propTypes;
ReportsDownload.defaultProps = defaultProps;

export default ReportsDownload;
