import React from 'react';

import { Image } from 'semantic-ui-react';

import { logoFromLeadSource, heightFromLeadSource } from '../../../libs/partners';

export default ({ leadSource }) => (
  <Image
    src={logoFromLeadSource(leadSource)}
    style={{ height: heightFromLeadSource(leadSource) }}
    centered
  />
);
