import clsx from 'clsx';

export function getAppliedFilterCount(placement, pages, websitePages) {
  const placementCount = placement ? 1 : 0;
  const pageFilterCount = pages.length === websitePages.length + 1 ? 0 : pages.length;
  const pagesCount = pages.length === 0 ? websitePages.length + 1 : pageFilterCount;
  return placementCount + pagesCount;
}

export function composeButtonClasses(filterCount) {
  return clsx({
    'toolbar-filter-html-element-button': true,
    'filter-active': filterCount > 0,
  });
}

export function composeButtonText(filterCount) {
  return filterCount > 0 ? `Filtering (${filterCount})` : 'Viewing All';
}
