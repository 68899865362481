import React, { useEffect, useContext } from 'react';

import { Form } from 'semantic-ui-react';

import { imagePositionOptions } from './ImagePosition.constants';
import { isBackgroundPositionAvailable, isBackgroundSelected } from './ImagePosition.utils';
import { IMAGE_POSITION_BACKGROUND, IMAGE_POSITION_TOP } from '../../constants';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

export default function ImagePosition() {
  const { imagePosition, images = [], setValue } = useContext(AnnouncementFormContext);

  const finalImagePositionOptions = imagePositionOptions.map((option) => {
    const { value, text } = option;
    if (images.length > 1 && value === IMAGE_POSITION_BACKGROUND) {
      const newText = `${text} (only available with 1 image. Defaults to top)`;
      return {
        ...option,
        text: newText,
        disabled: true,
      };
    }
    return option;
  });

  useEffect(() => {
    if (
      !isBackgroundPositionAvailable(images, imagePosition) &&
      isBackgroundSelected(imagePosition)
    ) {
      setValue('imagePosition', IMAGE_POSITION_TOP);
    }
  }, [...images, imagePosition]);

  return (
    <Form.Dropdown
      label="Image Position"
      placeholder="Image Position"
      fluid
      selection
      value={imagePosition}
      options={finalImagePositionOptions}
      onChange={(e, { value }) => setValue('imagePosition', value)}
    />
  );
}
