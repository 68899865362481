import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import { TeamPropType } from '../../../types/team';
import CreateTeamModal from '../CreateTeamModal';

import './EditTeamButton.scss';

const propTypes = {
  team: TeamPropType.isRequired,
  onPostSave: PropTypes.func,
  onPostDelete: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  onPostSave: null,
  onPostDelete: null,
  disabled: false,
};

export default function EditTeamButton({ team, onPostSave, onPostDelete, disabled }) {
  const [showModal, setShowModal] = useState(false);

  function displayModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      {showModal && (
        <CreateTeamModal
          initialFormData={team}
          open={showModal}
          onClose={closeModal}
          onPostSave={onPostSave}
          onPostDelete={onPostDelete}
        />
      )}
      <Button icon className="edit-team-button " onClick={displayModal} disabled={disabled}>
        <Icon name="edit" />
      </Button>
    </>
  );
}

EditTeamButton.propTypes = propTypes;
EditTeamButton.defaultProps = defaultProps;
