import { useEffect, useState } from 'react';

import _get from 'lodash/get';
import _head from 'lodash/head';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  announcementConfigurationToForm,
  announcementFormToConfiguration,
  announcementFormToCustomFormConfiguration,
  createAnnouncementForm,
  getCustomFormComponentConfiguration,
} from './AnnouncementForm.utils';
import { selectLayouts, selectWebsiteId } from '../../../../../selectors/website';
import useComponentConfigurations from '../../../component-configuration/hooks/use-component-configurations';
import { selectComponentConfigurationsByFastId } from '../../../component-configuration/selectors/component-configuration';
import { updateOneComponentConfiguration } from '../../../component-configuration/slices/ComponentConfiguration.slice';
import { ANNOUNCEMENT_FAST_ID } from '../../constants';

export function useAnnouncementForm() {
  const websiteId = useSelector(selectWebsiteId);
  const layouts = useSelector(selectLayouts);
  const defaultLayoutId = _get(layouts, '[0].id');
  const dispatch = useDispatch();

  const {
    isLoading: isLoadingComponentConfigurations,
    upsertComponentConfiguration,
    isSubmitting,
  } = useComponentConfigurations(websiteId, defaultLayoutId);

  const announcementComponentConfigurations = useSelector((state) =>
    selectComponentConfigurationsByFastId(state, ANNOUNCEMENT_FAST_ID),
  );
  const announcementComponentConfiguration = _head(announcementComponentConfigurations) || {};

  const initialState = announcementConfigurationToForm(announcementComponentConfiguration);
  const [formData, setFormData] = useState(createAnnouncementForm(initialState));

  useEffect(() => {
    const newAnnouncementComponentConfiguration = _head(announcementComponentConfigurations) || {};
    const newState = announcementConfigurationToForm(newAnnouncementComponentConfiguration);
    setFormData(createAnnouncementForm(newState));
  }, [announcementComponentConfigurations]);

  function setValue(field, value) {
    setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
  }

  async function submit() {
    const newReferenceId = uuidv4();
    const announcementConfiguration = announcementFormToConfiguration(
      { ...formData, referenceId: newReferenceId },
      announcementComponentConfiguration,
    );

    const savedAnnouncementComponentConfiguration = await upsertComponentConfiguration(
      announcementConfiguration,
    );
    const { id: announcementComponentConfigurationId } = savedAnnouncementComponentConfiguration;

    const existingCustomFormComponentConfiguration = getCustomFormComponentConfiguration(
      announcementComponentConfiguration,
    );

    const newCustomFormComponentConfiguration = announcementFormToCustomFormConfiguration(
      formData,
      announcementComponentConfigurationId,
      existingCustomFormComponentConfiguration,
    );
    const savedCustomForm = await upsertComponentConfiguration(newCustomFormComponentConfiguration);

    dispatch(
      updateOneComponentConfiguration({
        id: announcementComponentConfigurationId,
        changes: { childConfigurations: [savedCustomForm] },
      }),
    );
  }

  return {
    isLoading: isLoadingComponentConfigurations,
    isSubmitting,
    formData,
    setValue,
    submit,
  };
}
