import _get from 'lodash/get';

export function getWoflowJobsData(providerServiceSettings) {
  return providerServiceSettings.map((providerSetting) => {
    const { id, service_data: serviceData, status } = providerSetting;
    return {
      id,
      jobId: _get(serviceData, 'job_id'),
      status,
      fileCount: _get(serviceData, 'urls.length') || _get(serviceData, 'file_ids.length') || 0,
      createdAt: _get(serviceData, 'created_at'),
      completedAt: _get(serviceData, 'completed_at'),
    };
  });
}

export function isWoflowJob({
  service_data: serviceData,
  service_type: serviceType,
  provider,
} = {}) {
  if (!serviceData) return false;

  const isWoflowProvider = provider === 'WoFlow';
  const isMenuType = serviceType === 'Menu';
  const hasJobId = 'job_id' in serviceData;

  return isWoflowProvider && isMenuType && hasJobId;
}
