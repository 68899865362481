import React from 'react';

import { Button, Segment, Checkbox, Table, Container } from 'semantic-ui-react';

import _get from 'lodash/get';

import HelpTooltip from '../../../../common/HelpTooltip';
import { locationCanUploadMenu, getLocationSettingForGMBLocation } from '../GoogleMyBusiness.utils';

class GMBAccountSettings extends React.PureComponent {
  getHourSettingValue(location) {
    const { locationSettings = {} } = this.props;
    const setting = getLocationSettingForGMBLocation(locationSettings, location);
    return _get(setting, 'service_data.sync_settings.hours', null);
  }

  render() {
    const {
      loading,
      handleDisconnect,
      associatedLocations,
      profileSetting = {},
      handleGlobalSettingChange,
      handleLocationSettingChange,
    } = this.props;

    // We don't allow multiple menus for different locations so we don't allow
    // uploading a menu if one location can't upload a menu to GMB
    const canUploadMenu = (associatedLocations || []).every((location) =>
      locationCanUploadMenu(location),
    );
    const syncSettings = _get(profileSetting, 'service_data.sync_settings', {});
    const { website, menu } = syncSettings;

    return (
      <Segment attached="bottom" loading={loading}>
        <Segment attached="top">
          <Table>
            <Table.Header>
              <Table.HeaderCell>Global Settings</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Enabled</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  Push Fisherman Website URL to Google
                  <HelpTooltip
                    title="Website URL"
                    content="We will automatically push your website URL to Google when you Publish your site."
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <Checkbox name="website" checked={website} toggle disabled />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  Push Menu updates to Google
                  <HelpTooltip
                    title="Menu Updates"
                    content="When enabled we will push your digital menu updates to Google when you Publish your site."
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {!canUploadMenu && (
                    <>
                      <span>GMB location category is not eligible for a food menu. </span>
                      <br />
                      <span>
                        Please review your GMB location main category in Google Business Profile
                        site
                      </span>
                    </>
                  )}
                  {canUploadMenu && (
                    <Checkbox
                      name="menu"
                      checked={menu}
                      toggle
                      onChange={handleGlobalSettingChange}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
        <Segment attached="middle">
          {associatedLocations !== null &&
            associatedLocations.map((location) => (
              <Table>
                <Table.Header>
                  <Table.HeaderCell>{`Location Settings - ${location.title}, ${_get(
                    location,
                    'storefrontAddress.addressLines[0]',
                  )}, ${_get(location, 'storefrontAddress.locality')}`}</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Enabled</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  <Table.Row key={location.location_id}>
                    <Table.Cell>
                      Push Hours updates to Google
                      <HelpTooltip
                        title="Hours Updates"
                        content="When enabled we will push updates to your hours to Google when you Publish your site."
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Checkbox
                        name="hours"
                        toggle
                        checked={this.getHourSettingValue(location)}
                        onChange={(_, data) => handleLocationSettingChange(location, data)}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            ))}
        </Segment>
        <Segment attached="bottom">
          <Container textAlign="right">
            <Button negative onClick={handleDisconnect}>
              Disconnect
            </Button>
          </Container>
        </Segment>
      </Segment>
    );
  }
}

export default GMBAccountSettings;
