import React from 'react';

import { Grid } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import CustomPageGridRow from './components/CustomPageGridRow';
import { filterGridComponents } from '../../services/page-state';
import { PageElementPropType } from '../../types/page.types';

import './CustomPageGrid.scss';

const propTypes = { pageState: PageElementPropType.isRequired };

const defaultProps = {};

export default function CustomPageGrid({ pageState }) {
  const { components = [] } = pageState;
  const grids = filterGridComponents(components);

  if (_isEmpty(grids)) {
    return null;
  }

  return (
    <div className="custom-page-grid">
      {grids.map((grid) => {
        const { id: gridId, stackable, rows = [] } = grid;
        return (
          <Grid key={gridId} stackable={stackable}>
            {rows.map((row) => (
              <CustomPageGridRow key={row.id} row={row} />
            ))}
          </Grid>
        );
      })}
    </div>
  );
}

CustomPageGrid.propTypes = propTypes;
CustomPageGrid.defaultProps = defaultProps;
