import _get from 'lodash/get';

import { recipeSubTitleAvailabilityMap } from './MessageDisplayForm.constants';
import { selectActiveRecipeName } from '../../../selectors';

export function getRecipeSubTitleAvailability(websitePatches) {
  const defaultAvailability = false;
  const recipeName = selectActiveRecipeName(websitePatches);

  return _get(recipeSubTitleAvailabilityMap, recipeName, defaultAvailability);
}
