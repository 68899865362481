/* Roles */
export const ROLE_BUSINESS_OWNER = 'OWN';
export const ROLE_SALES = 'SALES';

/* Payment Plans */
export const FREE_PLAN_NAME = 'Free';
export const STANDARD_PLAN_NAME = 'Standard';
export const PROFESSIONAL_PLAN_NAME = 'Professional';
export const ADMIN_GROUP_NAME = 'admin';

/* Groups */
export const BUSINESS_OWNER_GROUP_NAME = 'business_owner';
export const CHOWNOW_SALES_GROUP_NAME = 'chownow_sales';
export const BBOT_SALES_GROUP_NAME = 'bbot_sales';
export const EZCATER_SALES_GROUP_NAME = 'ezcater_sales';
export const CLOVER_SALES_GROUP_NAME = 'clover_sales';
export const TOAST_SALES_GROUP_NAME = 'toast_sales';
export const ZUPPLER_SALES_GROUP_NAME = 'zuppler_sales';
export const DAY_SMART_SALES_GROUP_NAME = 'day_smart_sales';

export const SALES_GROUPS = [
  CHOWNOW_SALES_GROUP_NAME,
  CLOVER_SALES_GROUP_NAME,
  BBOT_SALES_GROUP_NAME,
  EZCATER_SALES_GROUP_NAME,
  TOAST_SALES_GROUP_NAME,
  ZUPPLER_SALES_GROUP_NAME,
  DAY_SMART_SALES_GROUP_NAME,
];

export const DISABLED_STACK_STATUS = 'Disabled';
