export const INTEGRATION_PROVIDER = 'Zuppler';
export const INTEGRATION_PROVIDER_SERVICE_TYPE = 'Menu';
export const INTEGRATION_COLLECTION_METHOD = 'Zuppler';
export const DATA_CHANNEL_URL_KEY = 'data_channel_url';
export const DATA_INTEGRATION_KEY = 'data_integration';
export const DATA_RESTAURANT_KEY = 'data_restaurant_id';
export const DATA_LOCALE_KEY = 'data_locale';
export const MULTIPLE_RESTAURANT_KEY = 'is_multiple_restaurant';
export const DATA_PORTAL_SLICES_KEY = 'data_portal_slices';
export const DEFAULT_SERVICE_DATA = {
  [MULTIPLE_RESTAURANT_KEY]: false,
  [DATA_PORTAL_SLICES_KEY]: 'Restaurants[12,12](Large)',
  [DATA_LOCALE_KEY]: 'en-US',
};
