import {
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_CALENDULA,
} from '../../../../../constants/recipes';

export const PUBLISH_AT_HELP =
  'This field indicates the specific date and time when your blog post is scheduled to be published. Your post will automatically go live according to the set schedule, allowing for timely and planned content updates. Datetime value should be in the primary location timezone.';
export const VISIBLE_AT_HELP =
  'This field allows you to control when your blog post becomes visible to customers. By setting a specific date and time, you can precisely manage the availability of your content. Datetime value should be in the primary location timezone.';

export const DESTINATION_ENABLED_FOR_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
