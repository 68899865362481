import React from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import { getBaseURLNoSlash } from '../../../../../../../constants/urls';
import API from '../../../../../../../libs/api';
import { BusinessPropType } from '../../../../../../../libs/proptypes';

function getCVSHref(businessId, businessType, locationId, filterValue) {
  const paramsParser = new URLSearchParams({
    format: 'csv',
    filter: filterValue,
  });
  const cvsHref = `${getBaseURLNoSlash()}${API.getCustomerContactsReportUrl(
    businessId,
    businessType,
    locationId,
  )}?${paramsParser.toString()}`;
  return cvsHref;
}

const CustomerContactDownloadButton = ({ business, locationId, filterValue }) => {
  const { id: businessId, type: businessType } = business;
  const csvUrl = getCVSHref(businessId, businessType, locationId, filterValue);

  return (
    <a href={csvUrl}>
      <Button icon labelPosition="left">
        <Icon name="download" />
        Download Report
      </Button>
    </a>
  );
};

export default CustomerContactDownloadButton;

CustomerContactDownloadButton.propTypes = {
  business: BusinessPropType.isRequired,
  locationId: PropTypes.number.isRequired,
  filterValue: PropTypes.string,
};

CustomerContactDownloadButton.defaultProps = {
  filterValue: '',
};
