import _has from 'lodash/has';

import { PAGE_COMPONENT_KEY, COMPONENT_CHILDREN_KEY } from './constants';

export function getNodeId(node) {
  const { id } = node;
  return id;
}

/**
 * Retrieves the children of a node. If the node contains page components, they are returned;
 * otherwise, the component's children are returned.
 *
 * @param {Object} node - The node from which to retrieve the children.
 * @return {Array} An array of children nodes.
 */
export function getChildren(node) {
  const pageComponents = node[PAGE_COMPONENT_KEY];
  const componentChildren = node[COMPONENT_CHILDREN_KEY] || [];

  return pageComponents || componentChildren;
}

/**
 * Determines the correct key to use when accessing a node's children. If the node contains
 * page components, the PAGE_COMPONENT_KEY is used; otherwise, the COMPONENT_CHILDREN_KEY is used.
 *
 * @param {Object} node - The node from which to determine the key.
 * @return {string} The correct key to use when accessing a node's children.
 */
export function getChildrenKey(node) {
  return _has(node, PAGE_COMPONENT_KEY) ? PAGE_COMPONENT_KEY : COMPONENT_CHILDREN_KEY;
}

/**
 * Recursively traverses a node hierarchy until a node with a matching ID is found. Once a matching
 * node is found, the provided callback is executed with the found node and its path.
 *
 * @param {string} nodeId - The ID of the node to search for.
 * @param {Object} currentNode - The node currently being inspected.
 * @param {Array} path - The path taken to reach the current node.
 * @param {Function} nodeFoundCallback - The callback to execute when a matching node is found.
 * @return {Object|null} The found node, or null if no node was found.
 */
export function traverseNode(nodeId, currentNode, path = [], nodeFoundCallback) {
  if (!currentNode) {
    return null;
  }

  const currentNodeId = getNodeId(currentNode);
  const children = getChildren(currentNode);

  if (currentNodeId === nodeId) {
    return nodeFoundCallback(currentNode, path);
  }

  return children.reduce((foundNode, childNode) => {
    if (foundNode) {
      return foundNode;
    }
    return traverseNode(nodeId, childNode, [...path, currentNodeId], nodeFoundCallback);
  }, null);
}

/**
 * Recursively reconstructs a node hierarchy. When a node with a matching ID is found, a callback
 * is executed to create an updated version of the node.
 *
 * @param {string} nodeId - The ID of the node to update.
 * @param {Object} currentNode - The node currently being inspected.
 * @param {Function} nodeUpdateCallback - The callback to execute when a matching node is found.
 * @return {Object} The reconstructed node hierarchy.
 */
export function reconstructNode(nodeId, currentNode, nodeUpdateCallback) {
  const currentNodeId = getNodeId(currentNode);
  const children = getChildren(currentNode);

  if (currentNodeId === nodeId) {
    return nodeUpdateCallback(currentNode);
  }

  return {
    ...currentNode,
    [getChildrenKey(currentNode)]: children.map((childNode) =>
      reconstructNode(nodeId, childNode, nodeUpdateCallback),
    ),
  };
}

/**
 * Finds a child node within a list of children based on its ID.
 *
 * @param {Array} children - An array of child nodes.
 * @param {string} nodeId - The ID of the child node to find.
 * @return {number} The index of the child node in the list, or -1 if not found.
 */
export function findChild(children, nodeId) {
  return children.findIndex((node) => getNodeId(node) === nodeId);
}
