import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { DEFAULT_DISMISSIBLE_MESSAGE_DELAY } from './CustomHeroForm.constants';
import NumberOfTiles from './components/NumberOfTiles';
import TileConfigurationTabs from './components/TileConfigurationTabs';
import TileWidthInputs from './components/TileWidthInputs';
import TilesPerRow from './components/TilesPerRow';
import { DismissibleMessage } from '../../../utils/components';

import './CustomHeroForm.scss';

const propTypes = {
  errorMessage: PropTypes.string,
};

const defaultProps = {
  errorMessage: '',
};

export default function CustomHeroForm({ errorMessage }) {
  return (
    <>
      <Form className="custom-hero-form">
        <NumberOfTiles />
        <TilesPerRow />
        <TileWidthInputs />
        <TileConfigurationTabs />
        {errorMessage && (
          <DismissibleMessage
            initialVisible
            delay={DEFAULT_DISMISSIBLE_MESSAGE_DELAY}
            error
            header="Error Creating Custom Hero"
            content={errorMessage}
          />
        )}
      </Form>
    </>
  );
}

CustomHeroForm.propTypes = propTypes;
CustomHeroForm.defaultProps = defaultProps;
