import React from 'react';

import PropTypes from 'prop-types';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Deck from './components/Deck';
import DeckItem from './components/DeckItem';
import Utils from '../../../../../libs/utils';
import { BusinessFilePropType } from '../../types/business-file.types';

import './ImagesDeck.scss';

const SortableBusinessFile = SortableElement(({ file }) => {
  return <DeckItem file={file} />;
});

const SortableBusinessFileList = SortableContainer(({ files }) => {
  return (
    <Deck>
      {files.map((file, idx) => (
        <SortableBusinessFile key={file.file} file={file} index={idx} />
      ))}
    </Deck>
  );
});

const propTypes = {
  files: PropTypes.arrayOf(BusinessFilePropType).isRequired,
  onUpdateFilesOrdering: PropTypes.func.isRequired,
};

function ImagesDeck({ files, onUpdateFilesOrdering }) {
  const onSortImages = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    onUpdateFilesOrdering(Utils.moveListItem(files, oldIndex, newIndex, true));
  };

  return (
    <div className="images-deck">
      <SortableBusinessFileList
        helperClass="images-deck-modal-sorting"
        axis="x"
        lockAxis="x"
        files={files}
        onSortEnd={onSortImages}
      />
    </div>
  );
}

ImagesDeck.propTypes = propTypes;
export default ImagesDeck;
