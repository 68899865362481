import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Form } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import {
  getWebsiteLocationOptions,
  getWebsiteLocationValue,
} from './WebsiteLocationDropdown.utils';
import { selectWebsitePages } from '../../../../../../../../../../../selectors/website';
import useResponsive from '../../../../../../../../../foundation/hooks/use-responsive';

import './WebsiteLocationDropdown.scss';

const propTypes = {
  page: PropTypes.number,
  websiteLocation: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  page: null,
};

export default function WebsiteLocationDropdown({ page, websiteLocation, onChange }) {
  const { isMobile } = useResponsive();
  const websitePages = useSelector(selectWebsitePages);

  return (
    <Form.Field>
      <Dropdown
        upward={isMobile}
        name="websiteLocation"
        options={getWebsiteLocationOptions(websitePages)}
        value={getWebsiteLocationValue(
          getWebsiteLocationOptions(websitePages),
          page,
          websiteLocation,
        )}
        onChange={onChange}
        className="basic label item-dropdown-website-location"
      />
    </Form.Field>
  );
}

WebsiteLocationDropdown.propTypes = propTypes;
WebsiteLocationDropdown.defaultProps = defaultProps;
