import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import { STATUS_DRAFT, STATUS_HIDDEN, STATUS_PUBLISHED } from './ItemStatusDropdown.constants';

export function getStatusClassName(status, baseClassName = 'custom-page-status-dropdown') {
  return clsx({
    [baseClassName]: !_isEmpty(baseClassName),
    draft: status === STATUS_DRAFT,
    published: status === STATUS_PUBLISHED,
    hidden: status === STATUS_HIDDEN,
  });
}
