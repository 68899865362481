import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  DEFAULT_NAME,
  DEFAULT_PLACEHOLDER,
  HELP_TOOLTIP_MESSAGE,
  HIDE_WARNINGS_TIMEOUT,
  NO_APPENDIX_VALUE,
} from './SeoTitle.constants';
import {
  getAppendixOptions,
  getTitleDisplayValue,
  getMaxLength,
  getNewTitleValue,
  getWarningMessage,
  getAiGeneratedSeoTitle,
  getPromptParams,
  createBusinessNameValue,
  getWritingSections,
} from './SeoTitle.utils';
import ErrorHandler from '../../../../../../../libs/errors/errors';
import { formatLabel } from '../../../../../../../libs/strings/strings';
import {
  selectBusinessId,
  selectBusinessName,
  selectBusinessType,
} from '../../../../../../../selectors/business';
import HelpTooltip from '../../../../../../common/HelpTooltip';
import ActionsInput from '../../../../../content/components/ActionsInput';
import WritingAssistantButton from '../../../../../content/components/WritingAssistantButton';
import { SeoMetadataFieldsContext } from '../../SeoMetadata.context';

import './SeoTitle.scss';

const propTypes = {
  title: PropTypes.string,
  defaultTitle: PropTypes.string,
  placeholder: PropTypes.string,
  showWarnings: PropTypes.bool,
  required: PropTypes.bool,
  additionalRequirements: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  title: undefined,
  defaultTitle: '',
  placeholder: DEFAULT_PLACEHOLDER,
  showWarnings: false,
  required: false,
  additionalRequirements: '',
  name: DEFAULT_NAME,
};

export default function SeoTitle({
  title: titleProp,
  defaultTitle,
  placeholder,
  required,
  showWarnings: showWarningsProp,
  additionalRequirements,
  name,
}) {
  const { onChange: onChangeContext, pageType } = useContext(SeoMetadataFieldsContext);

  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const businessName = useSelector(selectBusinessName);
  const businessNameValue = createBusinessNameValue(businessName);

  const [showWarnings, setShowWarnings] = useState(true);
  const warningTimeoutId = useRef(null);

  const [title, setTitle] = useState(defaultTitle);
  const isControlled = titleProp !== undefined;
  const finalTitle = (isControlled ? titleProp : title) || '';
  const isAppendingBusinessName = finalTitle.endsWith(businessNameValue);
  const finalTitleDisplayValue = getTitleDisplayValue(
    finalTitle,
    isAppendingBusinessName,
    businessNameValue,
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    clearTimeout(warningTimeoutId.current);
    setShowWarnings(true);
    warningTimeoutId.current = setTimeout(() => {
      setShowWarnings(false);
    }, HIDE_WARNINGS_TIMEOUT);

    return () => {
      clearTimeout(warningTimeoutId.current);
    };
  }, [finalTitle]);

  function setNewTitle(newTitle) {
    if (!isControlled) {
      setTitle(newTitle || '');
    }
  }

  async function onMakeSuggestion() {
    try {
      setLoading(true);
      const params = getPromptParams({
        pageType,
        additionalRequirements,
        isAppendingBusinessName,
        businessName,
      });
      const { text_completion: generatedTitle } = await getAiGeneratedSeoTitle(
        businessId,
        businessType,
        params,
      );
      return generatedTitle;
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
    return '';
  }

  function onWriteDefault() {
    return '';
  }

  function onInputChange(e, target) {
    const { value: newTitle } = target;
    const finalNewTitle = getNewTitleValue(newTitle, isAppendingBusinessName, businessNameValue);
    setNewTitle(finalNewTitle);
    const newTarget = { ...target, value: finalNewTitle, name };
    onChangeContext({ ...e, target: newTarget }, newTarget);
  }

  function onAppendixChange(e, target) {
    const { value: newAppendix } = target;
    const finalNewTitleValue = getNewTitleValue(
      finalTitle,
      newAppendix === businessNameValue,
      businessNameValue,
    );
    setNewTitle(finalNewTitleValue);
    const newTarget = { ...target, value: finalNewTitleValue, name };
    onChangeContext({ ...e, target: newTarget }, newTarget);
  }

  const appendixOptions = getAppendixOptions(businessNameValue);
  const maxLength = getMaxLength(isAppendingBusinessName, businessName);

  return (
    <div className="seo-metadata seo-title">
      <div className="label-container">
        <label htmlFor="seo-metadata-seo-title"> {formatLabel('SEO Title', required)}</label>
        <HelpTooltip title="SEO Title" content={HELP_TOOLTIP_MESSAGE} />
      </div>
      <ActionsInput>
        <ActionsInput.Input
          id="seo-metadata-seo-title"
          input={Input}
          onChange={onInputChange}
          placeholder={placeholder}
          disabled={loading}
          loading={loading}
          value={finalTitleDisplayValue}
          maxLength={maxLength}
          name={name}
          label={
            <Dropdown
              value={isAppendingBusinessName ? businessNameValue : NO_APPENDIX_VALUE}
              options={appendixOptions}
              onChange={onAppendixChange}
              disabled={loading}
            />
          }
          labelPosition="right"
          required={required}
        />
        <ActionsInput.Message
          hide={!showWarningsProp || !showWarnings || _isEmpty(finalTitle)}
          warning
          message={getWarningMessage(finalTitle)}
        />
        <ActionsInput.Actions
          trigger={WritingAssistantButton}
          actionSections={getWritingSections({
            onMakeSuggestion,
            placeholder,
            onWriteDefault,
          })}
          adminOnly
        />
      </ActionsInput>
    </div>
  );
}

SeoTitle.propTypes = propTypes;
SeoTitle.defaultProps = defaultProps;
