import _isEmpty from 'lodash/isEmpty';

import { PLUGINS_KEY } from './RecipeNavigation.constants';
import { PAGES_KEY } from '../../constants';
import getRecipeNodeNavigationDisplayComponent from '../../services/get-node-navigation-display-component';
import { removeNodeMetadata } from '../../services/recipe-utils';

function dropdownOptionsMapping(values) {
  if (_isEmpty(values)) {
    return [];
  }

  return values.map((value) => ({
    key: value,
    value,
    text: value,
  }));
}

export function mapNodeToComponent(node, selectedNodePath = []) {
  if (!node) {
    return null;
  }

  return Object.keys(node).map((recipeObjectKey, i) =>
    getRecipeNodeNavigationDisplayComponent(recipeObjectKey, null, node, i, {
      selectedNodePath,
      path: [recipeObjectKey],
    }),
  );
}

export function getRootNavigationOptions(recipe) {
  if (_isEmpty(recipe)) {
    return [];
  }
  const recipeWithoutMeta = removeNodeMetadata(recipe);
  const recipeRootKeys = Object.keys(recipeWithoutMeta).filter(
    (rootKey) => typeof recipe[rootKey] === 'object',
  );
  return dropdownOptionsMapping(recipeRootKeys);
}

export function getChildNavigationOptions(recipe, key) {
  if (_isEmpty(recipe) || !(key in recipe)) {
    return [];
  }

  const cleanRecipe = removeNodeMetadata(recipe);
  const node = cleanRecipe[key];
  let recipeRootChildrenKeys = [];

  if (key === PAGES_KEY) {
    recipeRootChildrenKeys = Object.keys(node).map((childkey) => cleanRecipe[key][childkey].name);
  } else {
    recipeRootChildrenKeys = Object.keys(node).filter(
      (childkey) => typeof cleanRecipe[key][childkey] === 'object',
    );
  }

  return dropdownOptionsMapping(recipeRootChildrenKeys);
}

export function getActiveRecipeNavigation(recipe, rootKey, childKey) {
  return childKey !== PLUGINS_KEY ? recipe[rootKey][childKey] : recipe[rootKey][childKey].slice(1); // because plugin metadata is in index 0
}
