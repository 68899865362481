import React from 'react';

import PropTypes from 'prop-types';
import { GridColumn, Button, Divider, Grid, Segment, Label } from 'semantic-ui-react';

import {
  LOCAL_FILE_UPLOAD_MODE,
  STOCK_IMAGE_UPLOAD_MODE,
} from 'components/modules/files/constants/mode';

import './FileUploadMode.scss';

const propTypes = {
  onSelectMode: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function FileUploadMode({ onSelectMode }) {
  function selectLocalFileUploadMode() {
    onSelectMode(LOCAL_FILE_UPLOAD_MODE);
  }

  function selectStockImageUploadMode() {
    onSelectMode(STOCK_IMAGE_UPLOAD_MODE);
  }

  return (
    <Segment placeholder className="file-upload-mode">
      <Label attached="top" color="grey">
        File Upload Options
      </Label>
      <Grid relaxed="very" stackable>
        <GridColumn width={8}>
          <Button
            onClick={selectLocalFileUploadMode}
            basic
            fluid
            content="From Computer"
            icon="computer"
            size="huge"
          />
        </GridColumn>
        <GridColumn width={8} verticalAlign="middle">
          <Button
            onClick={selectStockImageUploadMode}
            basic
            fluid
            content="From Stock Photos"
            icon="images"
            size="huge"
          />
        </GridColumn>
      </Grid>

      <Divider vertical>Or</Divider>
    </Segment>
  );
}

FileUploadMode.propTypes = propTypes;
FileUploadMode.defaultProps = defaultProps;
