import React from 'react';

import { Table } from 'semantic-ui-react';

export default function TeamsTableHeader() {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={3}>Name</Table.HeaderCell>
        <Table.HeaderCell width={3}>Role</Table.HeaderCell>
        <Table.HeaderCell width={3}>Phone</Table.HeaderCell>
        <Table.HeaderCell width={3}>Email</Table.HeaderCell>
        <Table.HeaderCell width={1} />
      </Table.Row>
    </Table.Header>
  );
}
