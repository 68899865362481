import React from 'react';

import PropTypes from 'prop-types';
import { Container, Grid } from 'semantic-ui-react';

import ImageCard from 'components/fields/ImageCard';
import { ChildrenPropType } from 'libs/proptypes';

import { getImageCardExtraParams } from './GridViewColumn.utils';
import SelectedBox from '../SelectedBox/SelectedBox';

const propTypes = {
  isFileDisabled: PropTypes.func,
  isFileSelected: PropTypes.func,
  actionsComponentRight: ChildrenPropType,
};

const defaultProps = {
  isFileDisabled: () => false,
  isFileSelected: () => false,
  actionsComponentRight: null,
};

export default function GridViewColumn({
  record,
  onClick,
  isFileDisabled,
  isFileSelected,
  getItemExtraParams,
  showSourceTags,
  showFileNames,
  actionsComponentRight,
}) {
  const selected = isFileSelected(record);

  return (
    <>
      <Grid.Column>
        <Container {...getItemExtraParams(record)}>
          <ImageCard
            showSourceTags={showSourceTags}
            showFileNames={showFileNames}
            {...getImageCardExtraParams(record, onClick, isFileDisabled)}
            actionsComponentLeft={<SelectedBox selected={selected} />}
            actionsComponentRight={actionsComponentRight}
          />
        </Container>
      </Grid.Column>
    </>
  );
}

GridViewColumn.propTypes = propTypes;
GridViewColumn.defaultProps = defaultProps;
