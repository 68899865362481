import React from 'react';

import PropTypes from 'prop-types';
import { Form, Message } from 'semantic-ui-react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import WithLoading from '../../../../common/WithLoading';
import GridView from '../../../FileManagement/GridView';
import EditFilePopup from '../EditFilePopup';
import SearchBar from '../SearchBar';

import './SaveSelection.scss';

const propTypes = {
  searchValue: PropTypes.string.isRequired,
  isSearching: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onFileSearch: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  disabledButtons: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onFileClick: PropTypes.func.isRequired,
  onFileEdit: PropTypes.func.isRequired,
  minFilesAllowed: PropTypes.number,
  maxFilesAllowed: PropTypes.number,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  showSourceTags: PropTypes.bool.isRequired,
  showFileNames: PropTypes.bool.isRequired,
  sourceFilter: PropTypes.string.isRequired,
  onSourceFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: false,
  files: [],
  error: null,
  minFilesAllowed: null,
  maxFilesAllowed: null,
  selectedFiles: [],
};

const LoadingGridView = WithLoading(GridView);

export default function SaveSelection({
  searchValue,
  isSearching,
  isLoading,
  onFileSearch,
  onUpload,
  disabledButtons,
  setError,
  error,
  files,
  onFileClick,
  onFileEdit,
  minFilesAllowed,
  maxFilesAllowed,
  selectedFiles,
  showSourceTags,
  showFileNames,
  sourceFilter,
  onSearchFocus,
  onSearchBlur,
  onSourceFilterChange,
}) {
  const hasFiles = !_isEmpty(files);

  function isFileSelected(businessFile) {
    return selectedFiles.some((file) => file.id === businessFile.id);
  }

  function isFileDisabled(businessFile) {
    if (!minFilesAllowed && !maxFilesAllowed) {
      return false;
    }

    return selectedFiles.length >= maxFilesAllowed && !isFileSelected(businessFile);
  }

  function getItemExtraParams(businessFile) {
    const className = clsx({
      selected: isFileSelected(businessFile),
      disabled: isFileDisabled(businessFile),
    });

    return { className };
  }

  return (
    <div className="save-selection">
      <Form className="search-bar-container">
        <p className="instructions">Click to select an image to include. </p>
        <SearchBar
          searchValue={searchValue}
          isSearching={isSearching}
          isUploading={isLoading}
          handleSearchChange={onFileSearch}
          onUpload={onUpload}
          disabledUploadButton={disabledButtons}
          setError={setError}
          initialError={error}
          sourceFilter={sourceFilter}
          onSearchFocus={onSearchFocus}
          onSearchBlur={onSearchBlur}
          onSourceFilterChange={onSourceFilterChange}
        />
      </Form>

      {hasFiles && (
        <LoadingGridView
          files={files}
          onClick={onFileClick}
          getItemExtraParams={getItemExtraParams}
          isFileDisabled={isFileDisabled}
          isFileSelected={isFileSelected}
          isLoading={isLoading}
          showSourceTags={showSourceTags}
          showFileNames={showFileNames}
          actionsComponentRight={(file) => {
            return <EditFilePopup file={file} onFileEdit={onFileEdit} />;
          }}
        />
      )}
      {!hasFiles && (
        <Message>
          <Message.Header>There are no files uploaded</Message.Header>
          <p>
            You can click on the <b>Upload</b> button in this modal and upload files to use them
            here.
          </p>
        </Message>
      )}
    </div>
  );
}

SaveSelection.propTypes = propTypes;
SaveSelection.defaultProps = defaultProps;
