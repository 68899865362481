import React from 'react';

import PropTypes from 'prop-types';
import { Table, Label } from 'semantic-ui-react';

import PartialProptypes from '../../Partial.propTypes';

const PartialTableItem = ({ partial, actions }) => {
  const data = partial || {};
  const { name, description, type, schemaVersion, tags } = data;

  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell width="4">{description}</Table.Cell>
      <Table.Cell>
        <Label>{type}</Label>
      </Table.Cell>
      <Table.Cell>{tags && tags.map((tag) => <Label key={tag}>{tag}</Label>)}</Table.Cell>
      <Table.Cell>
        <Label>{schemaVersion}</Label>
      </Table.Cell>
      <Table.Cell>{actions}</Table.Cell>
    </Table.Row>
  );
};

PartialTableItem.propTypes = {
  partial: PartialProptypes.isRequired,
  actions: PropTypes.element,
};

PartialTableItem.defaultProps = {
  actions: undefined,
};

export default PartialTableItem;
