import React, { useContext } from 'react';

import { Input } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_SENTENCE } from '../../constants';
import { TypographyContext } from '../../contexts/Typography.context';

import './SentenceInput.scss';

const propTypes = {};

const defaultProps = {};

export default function SentenceInput() {
  const { sentence, setSentence } = useContext(TypographyContext);

  function onChange(event, { value }) {
    if (!_isEmpty(value)) {
      setSentence(value);
    } else {
      setSentence(DEFAULT_SENTENCE);
    }
  }

  return (
    <Input
      label="Sentence"
      className="sentence-input"
      onChange={onChange}
      value={sentence === DEFAULT_SENTENCE ? '' : sentence}
      placeholder="Type something"
    />
  );
}

SentenceInput.propTypes = propTypes;
SentenceInput.defaultProps = defaultProps;
