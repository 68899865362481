import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

import { MenuItemsTypes } from './SideMenuNavigation.constants';
import useAnchor from '../../../../../hooks/useAnchor/use-anchor';
import MainContentContainer from '../MainContentContainer';

import './SideMenuNavigation.scss';

const propTypes = {
  menuItems: PropTypes.arrayOf(MenuItemsTypes).isRequired,
  hideMenuIfOnlyOne: PropTypes.bool,
};

const defaultProps = {
  hideMenuIfOnlyOne: false,
};

export default function SideMenuNavigation({ menuItems, hideMenuIfOnlyOne }) {
  const { anchor, setAnchor, loaded: anchorLoaded } = useAnchor();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const activeItem = menuItems[activeItemIndex];
  const { name, component: TargetComponent, props } = activeItem;

  const hideNavigation = menuItems.length === 1 && hideMenuIfOnlyOne;

  useEffect(() => {
    if (anchorLoaded && !hideNavigation) {
      const index = menuItems.findIndex((item) => item.name.toLowerCase() === anchor);
      if (index >= 0) {
        setActiveItemIndex(index);
      }
    }
  }, [anchorLoaded]);

  if (hideNavigation) {
    return <TargetComponent {...props} />;
  }

  function handleItemClick(index) {
    const { name: newActiveName } = menuItems[index];
    setActiveItemIndex(index);
    setAnchor(newActiveName.toLowerCase());
  }

  return (
    <div className="side-menu-navigation-ui menu-items-sidebar">
      <Menu pointing secondary vertical>
        {menuItems.map(({ name: targetName }, index) => (
          <Menu.Item
            key={targetName}
            name={targetName}
            active={name === targetName}
            onClick={() => handleItemClick(index)}
          />
        ))}
      </Menu>
      <MainContentContainer>{anchorLoaded && <TargetComponent {...props} />}</MainContentContainer>
    </div>
  );
}

SideMenuNavigation.propTypes = propTypes;
SideMenuNavigation.defaultProps = defaultProps;
