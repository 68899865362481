export const WEBSITE_ROOT_PATH = '/core/profile';
export const ECOMMERCE_ROOT_PATH = '/ecommerce/settings';

export const PRODUCT_NAVIGATION_OPTIONS = [
  {
    key: 'Website',
    text: 'Website',
    value: WEBSITE_ROOT_PATH,
  },
  {
    key: 'eCommerce',
    text: 'eCommerce',
    value: ECOMMERCE_ROOT_PATH,
  },
];
