import React from 'react';

import { Button, Header, Icon, Modal } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { resetBusiness } from '../../../../actions/business';
import { resetUser } from '../../../../actions/user';
import { resetWebsite } from '../../../../actions/website';
import { MAIL_TO_SUPPORT_EMAIL, SUPPORT_EMAIL } from '../../../../constants/constants';
import API from '../../../../libs/api';
import { logOut } from '../../../../libs/auth';
import ErrorHandler from '../../../../libs/errors';
import { selectUserRoleForBusiness } from '../../../../selectors/business';
import { selectWebsiteId } from '../../../../selectors/website';
import ExternalAnchor from '../../../common/ExternalAnchor/ExternalAnchor';
import WithRouter from '../../../modules/foundation/components/WithRouter/WithRouter';
import { isBusinessOwner } from '../OnboardingWizard/OnboardingWizard.utils';

const mapDispatchToProps = (dispatch) => ({
  resetUser: (payload) => dispatch(resetUser(payload)),
  resetBusiness: (payload) => dispatch(resetBusiness(payload)),
  resetWebsite: (payload) => dispatch(resetWebsite(payload)),
});

const mapStateToProps = (state) => ({
  userRole: selectUserRoleForBusiness(state),
  businessesCount: _get(state, 'user.core.value.businessesCount', 0),
  websiteId: selectWebsiteId(state),
});

class BillingIssueModal extends React.Component {
  signOut = async () => {
    const { navigate } = this.props;

    resetUser();
    resetBusiness();
    resetWebsite();
    await logOut(navigate, '/');
  };

  changeBusiness = () => {
    const { navigate } = this.props;
    navigate('/');
  };

  hasExternalAccountToManage = () => {
    const { stripeCustomerId, business } = this.props;

    if (business.collection_method === 'CloverMarketplace') {
      return true;
    }
    if (business.collection_method === 'Stripe') {
      return !!stripeCustomerId;
    }

    return false;
  };

  manageBilling = async () => {
    const { business, websiteId } = this.props;

    if (business.collection_method === 'Stripe') {
      try {
        const returnUrl = window.location;
        const { data } = await API.getStripeSession(websiteId, returnUrl);
        const { url } = data;

        window.location.replace(url);
      } catch (e) {
        ErrorHandler.capture(e);
      }
    }

    if (business.collection_method === 'CloverMarketplace') {
      window.location.replace('https://www.clover.com/dashboard');
    }
  };

  getModalActions = () => {
    const { businessesCount, userRole } = this.props;

    return (
      <>
        {isBusinessOwner(userRole) && this.hasExternalAccountToManage() && (
          <Button inverted onClick={this.manageBilling}>
            <Icon name="credit card" /> Manage Billing
          </Button>
        )}
        {businessesCount > 1 && (
          <Button color="green" inverted onClick={this.changeBusiness}>
            <Icon name="undo" /> Change Business
          </Button>
        )}
        <Button basic color="red" inverted onClick={this.signOut}>
          <Icon name="remove" /> Logout
        </Button>
      </>
    );
  };

  render() {
    const { isOpen = false } = this.props;

    return (
      <Modal basic open={isOpen} closeOnEscape={false} closeOnDimmerClick={false} size="small">
        <Header icon>
          <Icon name="ban" />
          Your Website is Disabled
        </Header>
        <Modal.Content>
          <p>
            There was an issue collecting payment for your website. If you wish to reactivate the
            website you will need to contact{' '}
            <ExternalAnchor href={MAIL_TO_SUPPORT_EMAIL}>{SUPPORT_EMAIL}</ExternalAnchor>.
          </p>
        </Modal.Content>
        <Modal.Actions>{this.getModalActions()}</Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(BillingIssueModal));
