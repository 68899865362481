import PropTypes from 'prop-types';

import { FULFILLMENT_PROVIDER_SHIPPO } from '../../../../../constants/fulfillments';

import ShippoLogo from './assets/shippo-logo.png';

export const TIME_UNIT_MINUTES = 'MINUTES';
export const TIME_UNIT_DAYS = 'DAYS';

export const FIELD_NAMES = {
  FULFILLMENT_TYPE: 'fulfillment_type',
  ACCEPTABLE_FULFILLMENT_TYPES: 'acceptable_fulfillment_types',
  ORDER_NOTIFICATION_EMAILS: 'order_notification_emails',
  ORDER_RECEIPT_MESSAGE: 'order_receipt_message',
  ORDER_TURNAROUND_TIME: 'order_turnaround_time',
  TURNAROUND_TIME: 'turnaround_time',
  TURNAROUND_TIME_UNIT: 'turnaround_time_unit',
  ACCEPT_ORDERS_UNTIL: 'accepts_orders_until',
  ORDER_CUTOFF_SWITCH: 'order_cutoff_switch',
  MINIMUM_FULFILLMENT_PRICE: 'minimum_fulfillment_price',
  FLAT_FULFILLMENT_PRICE: 'flat_fulfillment_price',
  LOCATION: 'location',
  DISCOUNTS: 'discounts',
};

export const {
  FULFILLMENT_TYPE,
  ACCEPTABLE_FULFILLMENT_TYPES,
  ORDER_CUTOFF_SWITCH,
  ACCEPT_ORDERS_UNTIL,
  ORDER_NOTIFICATION_EMAILS,
  ORDER_RECEIPT_MESSAGE,
  ORDER_TURNAROUND_TIME,
  TURNAROUND_TIME,
  TURNAROUND_TIME_UNIT,
  MINIMUM_FULFILLMENT_PRICE,
  FLAT_FULFILLMENT_PRICE,
  LOCATION,
  DISCOUNTS,
} = FIELD_NAMES;

export const orderSettingsFieldShape = {
  value: PropTypes.any,
  isValid: PropTypes.bool,
};

export const SUCCESSFUL_SAVE_MESSAGE = 'Order Settings has been successfully updated';

export const SHIPPING_PROVIDER_LOGO_PATH = {
  [FULFILLMENT_PROVIDER_SHIPPO]: ShippoLogo,
};
