import {
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_BEDS_THAI,
  RECIPE_NAME_COLD_CUTZ,
  RECIPE_NAME_COMB_AND_INK,
  RECIPE_NAME_CALENDULA,
} from '../../../constants/recipes';
import { hasAnyRecipe } from '../../modules/foundation/hooks/use-recipe';

export function isButtonLinksGroupingSupported(patch) {
  return hasAnyRecipe(patch, [
    RECIPE_NAME_CUTILIER,
    RECIPE_NAME_MUSE,
    RECIPE_NAME_HIBISCUS,
    RECIPE_NAME_BEDS_THAI,
    RECIPE_NAME_COLD_CUTZ,
    RECIPE_NAME_COMB_AND_INK,
    RECIPE_NAME_CALENDULA,
  ]);
}
