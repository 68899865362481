import * as Babel from '@babel/standalone';

export function validateHtmlElement(item = {}) {
  const { contents = '' } = item;

  try {
    Babel.transform(contents, { presets: ['react'] });
    return true;
  } catch (error) {
    throw new Error('Invalid JSX, please fix syntax errors.');
  }
}
