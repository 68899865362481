import React from 'react';

import PropTypes from 'prop-types';
import { Input, Container, Form, Header } from 'semantic-ui-react';

import { cleanDomainName } from '../../../../../libs/domains';
import { ChildrenPropType } from '../../../../../libs/proptypes';
import { DEFAULT_DOMAIN_PLACEHOLDER } from '../../DomainSelection.constants';

import './CustomDomainForm.scss';

const propTypes = {
  children: ChildrenPropType,
  show: PropTypes.bool,
  domainName: PropTypes.string.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
};

const defaultProps = {
  children: null,
  show: false,
};

export default function CustomDomainForm({ children, show, domainName, handleDomainChange }) {
  function onDomainChange(value) {
    const cleanValue = cleanDomainName(value);
    handleDomainChange(cleanValue, 'external');
  }

  if (!show) return null;

  return (
    <Container className="custom-domain-form">
      <div className="header-container">
        <Header as="h1">
          Enter your custom domain
          <Header.Subheader>Connect your custom domain to your new site</Header.Subheader>
        </Header>
      </div>

      <Form>
        <Input
          labelPosition="left"
          value={domainName}
          onChange={(e, { value }) => onDomainChange(value)}
          placeholder={DEFAULT_DOMAIN_PLACEHOLDER}
          label="https://"
          fluid
        />
      </Form>
      <p className="description" style={{ color: 'black' }}>
        Please allow 2-3 business days to process and connect your custom domain to your new
        published site
      </p>
      {children}
    </Container>
  );
}

CustomDomainForm.propTypes = propTypes;
CustomDomainForm.defaultProps = defaultProps;
