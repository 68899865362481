import _get from 'lodash/get';

import { DEFAULT_IMAGE_BLOCK_CLASS_NAME, IMAGE_BLOCK_PAGE_CONTENT_QUERY } from './constants';
import {
  CAROUSEL_IMAGE_COMPONENT_NAME,
  PAGINATED_GRID_IMAGE_COMPONENT_NAME,
  IMAGE_BLOCK_ELEMENT_TYPE,
} from '../../../../constants/types';
import { createFishermanComponent, getComponentName, getComponentId } from '../utils/components';
import { getFunctionArgValue } from '../utils/functions';
import {
  createComponentBaseProps,
  createFinalClassName,
  getCollectionPropValue,
  getComponentClassName,
} from '../utils/props';
import { createPageComponentQuery, getComponentFiles } from '../utils/queries';

export function createCarouselImagesBlockElement(data = {}, context = {}) {
  const { id, style, className, componentName, componentIdentifier } = data;
  const { page = {}, isTemplate = false } = context;
  const { id: pageId } = page || {};

  const finalClassName = createFinalClassName(className, DEFAULT_IMAGE_BLOCK_CLASS_NAME);
  const finalPageId = isTemplate ? null : pageId;

  return createFishermanComponent(CAROUSEL_IMAGE_COMPONENT_NAME, id, [
    ...createComponentBaseProps(finalClassName, style),
    {
      name: 'items',
      resolve: {
        type: 'FUNCTION',
        value: {
          name: 'componentIterator',
          requires: '@bluefin/components',
          importType: 'OBJECT',
          args: [
            {
              name: 'iterator',
              value: {
                type: 'FUNCTION',
                value: {
                  name: 'getComponentFiles',
                  requires: '../utils/utils',
                  importType: 'OBJECT',
                  args: [
                    {
                      name: 'components',
                      value: createPageComponentQuery(finalPageId, IMAGE_BLOCK_PAGE_CONTENT_QUERY),
                    },
                    {
                      name: 'componentName',
                      value: componentName,
                    },
                    {
                      name: 'componentIdentifier',
                      value: componentIdentifier || null,
                    },
                  ],
                },
              },
            },
            {
              name: 'component',
              value: {
                type: 'COMPONENT',
                value: {
                  name: 'Image',
                  type: 'FISHERMAN',
                  props: [
                    {
                      name: 'className',
                      resolve: 'image',
                    },
                  ],
                },
              },
            },
            {
              name: 'propMap',
              value: {
                type: 'COLLECTION',
                value: {
                  src: '__all__',
                },
              },
            },
          ],
        },
      },
    },
  ]);
}

export function createPaginatedGridImagesBlockElement(data = {}, context = {}) {
  const { id, style, className, componentName, componentIdentifier } = data;
  const { page = {}, isTemplate = false } = context;
  const { id: pageId } = page || {};

  const finalClassName = createFinalClassName(className, DEFAULT_IMAGE_BLOCK_CLASS_NAME);
  const finalPageId = isTemplate ? null : pageId;

  return createFishermanComponent(PAGINATED_GRID_IMAGE_COMPONENT_NAME, id, [
    ...createComponentBaseProps(finalClassName, style),
    {
      name: 'columns',
      resolve: 3,
    },
    {
      name: 'rows',
      resolve: 3,
    },
    {
      name: 'mobile',
      resolve: {
        type: 'COLLECTION',
        value: {
          columns: 2,
          rows: 4,
          stackable: false,
        },
      },
    },
    {
      name: 'items',
      resolve: {
        type: 'FUNCTION',
        value: {
          name: 'componentIterator',
          requires: '@bluefin/components',
          importType: 'OBJECT',
          args: [
            {
              name: 'iterator',
              value: {
                type: 'FUNCTION',
                value: {
                  name: 'getComponentFiles',
                  requires: '../utils/utils',
                  importType: 'OBJECT',
                  args: [
                    {
                      name: 'components',
                      value: createPageComponentQuery(finalPageId, IMAGE_BLOCK_PAGE_CONTENT_QUERY),
                    },
                    {
                      name: 'componentName',
                      value: componentName,
                    },
                    {
                      name: 'componentIdentifier',
                      value: componentIdentifier || null,
                    },
                  ],
                },
              },
            },
            {
              name: 'component',
              value: {
                type: 'COMPONENT',
                value: {
                  name: 'Image',
                  type: 'FISHERMAN',
                  props: [
                    {
                      name: 'background',
                      resolve: true,
                    },
                    {
                      name: 'className',
                      resolve: 'gallery-image-item',
                    },
                  ],
                },
              },
            },
            {
              name: 'propMap',
              value: {
                type: 'COLLECTION',
                value: {
                  src: '__all__',
                },
              },
            },
          ],
        },
      },
    },
  ]);
}

export function createMultipleImagesBlockElement(data = {}, context = {}) {
  const { widgetType } = data;

  const multipleImagesMap = {
    [CAROUSEL_IMAGE_COMPONENT_NAME]: createCarouselImagesBlockElement,
    [PAGINATED_GRID_IMAGE_COMPONENT_NAME]: createPaginatedGridImagesBlockElement,
  };

  return multipleImagesMap[widgetType](data, context);
}

export function createCarouselImagesBlockState(recipeSnippet = {}, context = {}) {
  const { props } = recipeSnippet || {};
  const { state, page = {} } = context;
  const { id: pageId } = page || {};

  const type = IMAGE_BLOCK_ELEMENT_TYPE;
  const widgetType = CAROUSEL_IMAGE_COMPONENT_NAME;
  const id = getComponentId(recipeSnippet);
  const className = getComponentClassName(props, DEFAULT_IMAGE_BLOCK_CLASS_NAME);
  const style = getCollectionPropValue(props, 'style');

  const items = getCollectionPropValue(props, 'items');
  const iterator = _get(getFunctionArgValue(items, 'iterator'), 'value');
  const componentName = getFunctionArgValue(iterator, 'componentName');
  const componentIdentifier = getFunctionArgValue(iterator, 'componentIdentifier');
  const options = { componentName, componentIdentifier, pageId };
  const [component, files] = getComponentFiles(options, state);
  const componentConfigurationId = _get(component, 'id', null);

  return {
    id,
    className,
    style,
    type,
    widgetType,
    componentConfigurationId,
    componentName,
    componentIdentifier,
    images: files,
  };
}

export function createPaginatedGridImagesBlockState(recipeSnippet = {}, context = {}) {
  const { props } = recipeSnippet || {};
  const { state, page = {} } = context;
  const { id: pageId } = page || {};

  const type = IMAGE_BLOCK_ELEMENT_TYPE;
  const widgetType = PAGINATED_GRID_IMAGE_COMPONENT_NAME;
  const id = getComponentId(recipeSnippet);
  const className = getComponentClassName(props, DEFAULT_IMAGE_BLOCK_CLASS_NAME);
  const style = getCollectionPropValue(props, 'style');

  const items = getCollectionPropValue(props, 'items');
  const iterator = _get(getFunctionArgValue(items, 'iterator'), 'value');
  const componentName = getFunctionArgValue(iterator, 'componentName');
  const componentIdentifier = getFunctionArgValue(iterator, 'componentIdentifier');
  const options = { componentName, componentIdentifier, pageId };
  const [component, files] = getComponentFiles(options, state);
  const componentConfigurationId = _get(component, 'id', null);

  return {
    id,
    className,
    style,
    type,
    widgetType,
    componentConfigurationId,
    componentName,
    componentIdentifier,
    images: files,
  };
}

export function createMultipleImagesBlockState(recipeSnippet = {}, context = {}) {
  const componentName = getComponentName(recipeSnippet);

  const multipleImagesMap = {
    [CAROUSEL_IMAGE_COMPONENT_NAME]: createCarouselImagesBlockState,
    [PAGINATED_GRID_IMAGE_COMPONENT_NAME]: createPaginatedGridImagesBlockState,
  };

  return multipleImagesMap[componentName](recipeSnippet, context);
}
