import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';

export function getNodeSearchOptions(results) {
  if (_isEmpty(results)) {
    return [];
  }

  const groupedResults = _groupBy(
    results.map(({ __path__, __label__, __type__ }) => ({
      title: __label__,
      text: __label__,
      description: __path__,
      category: __type__,
      key: __path__,
      value: __path__,
    })),
    'category',
  );

  return _keys(groupedResults).reduce(
    (categories, key) => ({
      ...categories,
      [key]: { name: key, results: groupedResults[key] },
    }),
    {},
  );
}
