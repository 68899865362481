import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { SECTION_HEADER, TOOLTIP_DESCRIPTION } from './ProductDescriptionPageWidget.constants';
import { generateProductDescriptionPageInteraction } from './ProductDescriptionPageWidget.utils';
import HelpTooltip from '../../../common/HelpTooltip';

import './ProductDescriptionPageWidget.scss';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
};

export default function ProductDescriptionPageWidget({ value, onChange }) {
  function onUpdate(e) {
    const {
      target: { value: newValue },
    } = e;

    const interactions = !_isEmpty(newValue)
      ? generateProductDescriptionPageInteraction(newValue)
      : [];
    onChange({ values: { interactions } });
  }

  return (
    <>
      <h2>
        <span>{SECTION_HEADER}</span>
        <HelpTooltip title={SECTION_HEADER} content={TOOLTIP_DESCRIPTION} />
      </h2>

      <div className="product-description-page-header">Route to external URL (optional)</div>
      <Input
        className="product-description-page-url"
        defaultValue={value}
        placeholder="e.g. https://example.com"
        onChange={onUpdate}
      />
    </>
  );
}

ProductDescriptionPageWidget.propTypes = propTypes;
ProductDescriptionPageWidget.defaultProps = defaultProps;
