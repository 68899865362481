import _isEqual from 'lodash/isEqual';

export function didReviewsUpdate(oldReviews, newReviews) {
  const oldIds = oldReviews.map((review) => review.id || review.localId);
  const newIds = newReviews.map((review) => review.id || review.localId);
  return !_isEqual(new Set(oldIds), new Set(newIds));
}

export function areAllReviewsSelected(reviews, checkedReviewIds) {
  return reviews.length === checkedReviewIds.size;
}

export function isSomeReviewSelected(checkedReviewIds) {
  return checkedReviewIds.size > 0;
}
