import React, { useState } from 'react';

import { Button, Checkbox, Form, Header, Segment, Message } from 'semantic-ui-react';

import { useDispatch } from 'react-redux';

import { useAnnouncementForm } from './AnnouncementForm.hooks';
import { flagBusinessSaved } from '../../../../../actions/business';
import { getErrorMessage } from '../../../../../libs/errors';
import { useBusinessFiles } from '../../../files/hooks';
import { AnnouncementFormProvider } from '../../context';
import AnnouncementCustomForm from '../AnnouncementCustomForm';
import AnnouncementDates from '../AnnouncementDates';
import AnnouncementDismissalType from '../AnnouncementDismissalType';
import AnnouncementInteractions from '../AnnouncementInteractions/AnnouncementInteractions';
import AnnouncementTiming from '../AnnouncementTiming';
import BannerButtonText from '../BannerButtonText';
import BannerColor from '../BannerColor';
import BannerTitle from '../BannerTitle';
import ImagePosition from '../ImagePosition';
import PopupContent from '../PopupContent/PopupContent';
import PopupImages from '../PopupImages/PopupImages';

import './AnnouncementForm.scss';

export default function AnnouncementForm() {
  const [errorMessage, setErrorMessage] = useState('');
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const { isLoading, formData, setValue, submit, isSubmitting } = useAnnouncementForm();
  const { isLoading: isLoadingFiles } = useBusinessFiles();
  const dispatch = useDispatch();

  async function submitForm() {
    setErrorMessage('');
    setSubmitSuccessful(false);
    try {
      await submit();
      dispatch(flagBusinessSaved(true));
      setSubmitSuccessful(true);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
    }
  }

  const contextValue = {
    ...formData,
    setValue,
  };
  const { bannerEnabled, popupEnabled } = formData;

  return (
    <div>
      <AnnouncementFormProvider value={contextValue}>
        <Segment loading={isLoading || isLoadingFiles}>
          <Form className="announcement-form" loading={isSubmitting}>
            <Header as="h2">Announcement Settings</Header>
            <AnnouncementDates />
            <Segment>
              <Header as="h2">Banner Settings</Header>
              <Checkbox
                toggle
                label="Enable Banner"
                checked={bannerEnabled}
                onChange={(e, { checked }) => setValue('bannerEnabled', checked)}
              />
              <Segment className="no-padding" basic disabled={!bannerEnabled}>
                <BannerTitle />
                <BannerColor />
                <BannerButtonText />
              </Segment>
            </Segment>

            <Segment>
              <Header as="h2">Popup Settings</Header>
              <Checkbox
                toggle
                label="Enable Popup"
                checked={popupEnabled}
                onChange={(e, { checked }) => setValue('popupEnabled', checked)}
              />
              <Segment className="no-padding" basic disabled={!popupEnabled}>
                <AnnouncementTiming />
                <AnnouncementDismissalType />
                <ImagePosition />
                <PopupImages />
                <PopupContent />
                <AnnouncementCustomForm />
                <AnnouncementInteractions />
              </Segment>
            </Segment>
            {errorMessage && <Message negative>{errorMessage}</Message>}
            {submitSuccessful && (
              <Message positive onDismiss={() => setSubmitSuccessful(false)}>
                Announcement was saved successfully!
              </Message>
            )}
            <Button
              className="action"
              onClick={submitForm}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </Form>
        </Segment>
      </AnnouncementFormProvider>
    </div>
  );
}
