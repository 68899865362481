import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';

import Validate from '../../../../../libs/validate';
import { groupConfigurationsByCategory } from '../../services/configuration';
import {
  formatStyleRuleName,
  formatStyleRuleValue,
  formatStyleRulesValue,
} from '../../services/format';

export function getErrors(name, value) {
  const fields = { name, value };

  const errorTemplate = (key) => `Please enter a style rule ${key}`;

  return _keys(fields).reduce((acc, fieldName) => {
    if (!fields[fieldName].length) {
      return { ...acc, [fieldName]: errorTemplate(fieldName) };
    }
    return undefined;
  }, {});
}

function createOption(name, { className = '', disabled = false } = {}) {
  return {
    key: formatStyleRuleValue(name),
    value: formatStyleRuleValue(name),
    text: formatStyleRuleName(name),
    className,
    disabled,
  };
}

function createHeaderOption(name) {
  return { ...createOption(name, { disabled: true, className: 'header' }) };
}

export function getOptions(currentStyleRule, existingStyleRules, styleRulesConfiguration) {
  let grouping = groupConfigurationsByCategory(styleRulesConfiguration, {
    existingStyleRules: formatStyleRulesValue(existingStyleRules),
  });

  if (_isEmpty(grouping)) {
    grouping.Color = ['primaryColor'];
  }

  if (
    !_isEmpty(currentStyleRule) &&
    !_flatten(Object.values(grouping)).includes(currentStyleRule)
  ) {
    grouping = { ...grouping, Selected: [...(grouping.Selected || []), currentStyleRule] };
  }

  return _flatten(
    Object.keys(grouping)
      .map((category) => {
        const items = grouping[category];
        if (_isEmpty(items)) {
          return null;
        }

        const itemsOptions = items.map((name) => createOption(name));
        return [createHeaderOption(category), ...itemsOptions];
      })
      .filter(Boolean),
  );
}

export function shouldHideColorPicker(value) {
  if (_isEmpty(value) || value.length < 2) {
    return false;
  }
  return !Validate.isHexColor(value);
}
