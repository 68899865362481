export const NEW_VALUE_PREFIX = 'Add';
export const INVALID_EMAIL_ERROR_MESSAGE = 'Please, provide a valid email address.';
export const EMAIL_ADD_ERROR_MESSAGE =
  'There was an error when adding the email. Please, try again.';

export const DEFAULT_DESTINATION_EMAIL_MESSAGE =
  'The default email destination is either the location email (if one exists) or the user owner email.';

export const ERROR_GETTING_DESTINATION_EMAILS_MESSAGE =
  'There was an error getting available destination emails. Please reload the page or try again later.';
