import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Label, Grid } from 'semantic-ui-react';

import { getSuggestedQueries } from './StockSearchSuggestions.utils';
import { StockImagesPickerContext } from '../../StockImagesPicker.context';

import './StockSearchSuggestions.scss';

const propTypes = {
  columns: PropTypes.number,
};

const defaultProps = {
  columns: 4,
};

export default function StockSearchSuggestions({ columns }) {
  const { setSearchValue } = useContext(StockImagesPickerContext);

  function onSuggestionClick(query) {
    setSearchValue(query);
  }

  return (
    <Grid padded stackable columns={columns} className="stock-search-suggestions">
      {getSuggestedQueries().map((query) => (
        <Label
          key={query}
          as="a"
          basic
          onClick={() => {
            onSuggestionClick(query);
          }}
        >
          {query}
        </Label>
      ))}
    </Grid>
  );
}

StockSearchSuggestions.propTypes = propTypes;
StockSearchSuggestions.defaultProps = defaultProps;
