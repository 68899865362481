import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { updateWebsitePages } from '../../../../../actions/website';
import API from '../../../../../libs/api';
import { objectToCamelCase } from '../../../../../libs/format';
import { selectWebsite } from '../../../../../selectors/website';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';
import useFetch from '../../../foundation/hooks/use-fetch/use-fetch';
import { filterPagesByType } from '../../services/pages';

export default function usePages({ pageType } = {}, { fetchRecipeSettingsOnMount = true } = {}) {
  const website = useSelector(selectWebsite);

  const dispatch = useDispatch();

  const [pages, setPages] = useState([]);
  const [settings, setSettings] = useState([]);

  const { fetching: loading, fetch, withFetch } = useFetch();

  const { id: websiteId } = website;

  async function fetchPages() {
    const { data } = await API.getPages(websiteId);
    setPages(filterPagesByType(objectToCamelCase(data), pageType));
    dispatch(updateWebsitePages(data));
  }

  async function fetchPagesSettings() {
    const { data } = await API.getWebsiteRecipe(website.id);
    const { component_configuration_settings: configurationSettings } = data;
    setSettings(objectToCamelCase(configurationSettings));
  }

  useAsyncEffect(async () => {
    let asyncFunctions = [fetchPages];
    if (fetchRecipeSettingsOnMount) {
      asyncFunctions = [...asyncFunctions, fetchPagesSettings];
    }

    await fetch(asyncFunctions, { throwError: false });
  }, [websiteId]);

  return {
    pages,
    settings,
    loading,
    refreshPages: withFetch(fetchPages, { throwError: false }),
    refreshSettings: withFetch(fetchPagesSettings, { throwError: false }),
  };
}
