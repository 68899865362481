import PickupOrders from './components/PickupOrders';
import ShippingOrders from './components/ShippingOrders';

export const menuItems = [
  {
    name: 'PICKUP',
    component: PickupOrders,
  },
  {
    name: 'SHIPPING',
    component: ShippingOrders,
  },
];
