import {
  RECIPE_NAME_BEDS_THAI,
  RECIPE_NAME_COLD_CUTZ,
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
} from '../../../../../constants/recipes';

export const INITIAL_FORM_DATA_STATE = {
  title: '',
  seoTitle: '',
  asButton: false,
  url: '',
  description: '',
};

export const ADVANCED_SEO_FEATURES_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_BEDS_THAI,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_COLD_CUTZ,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
