import _isEmpty from 'lodash/isEmpty';

export function shouldHighlightItems(collectionName, sortingContext) {
  const {
    focusedCollection,
    initialFocusedCollection,
    initialFocusedGroupCollection,
    initialFocusedGroupChildCollection,
    focusedGroupCollection,
    dragging,
  } = sortingContext;

  if (!dragging) {
    return false;
  }

  const draggingChildPage = !_isEmpty(initialFocusedGroupChildCollection);
  const droppingBetweenCollections = initialFocusedCollection !== focusedCollection;
  const draggingIntoMe = focusedGroupCollection === collectionName;
  const draggingFromMe = initialFocusedGroupCollection === collectionName;

  if (droppingBetweenCollections) {
    return false;
  }

  if (draggingChildPage && draggingIntoMe && draggingFromMe) {
    return true;
  }

  return !draggingChildPage && draggingIntoMe && !draggingFromMe;
}
