import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';

import { addStyleRule, updateStyleRule, deleteStyleRule } from '../../../../../actions/website';
import { formatStyleRuleValue } from '../../services/format';

export default function useStyleRule(name) {
  const dispatch = useDispatch();
  const styleRules = useSelector((state) => _get(state, 'website.styleRules.value', []));

  const finalName = formatStyleRuleValue(name);

  function getStyleRuleIndex(rules) {
    return rules.findIndex((rule) => rule.name === finalName);
  }

  const initialStyleRuleIndex = getStyleRuleIndex(styleRules);
  const styleRule = initialStyleRuleIndex === -1 ? null : styleRules[initialStyleRuleIndex];
  const value = _get(styleRule, 'value', null);

  function setValue(fieldValue) {
    const styleRuleIndex = getStyleRuleIndex(styleRules);
    if (styleRuleIndex === -1) {
      dispatch(addStyleRule({ name: finalName, value: fieldValue }));
    } else {
      dispatch(updateStyleRule({ index: styleRuleIndex, field: 'value', fieldValue }));
    }
  }

  function deleteRule() {
    const styleRuleIndex = getStyleRuleIndex(styleRules);
    dispatch(deleteStyleRule(styleRuleIndex));
  }

  return { value, styleRule, setValue, deleteRule };
}
