import React, { useContext } from 'react';

import { Form } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { convertBusinessFilesToImageData } from './PopupImages.utils';
import { sortItemsByList } from '../../../../../libs/array';
import { selectBusinessFiles } from '../../../../../selectors/business';
import FileSelectionWell from '../../../files/components/FileSelectionWell';
import { SOURCE_TYPE_DEFAULT } from '../../../files/constants/sources';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

import './PopupImages.scss';

export default function PopupImages() {
  const { images = [], setValue } = useContext(AnnouncementFormContext);
  const businessFiles = useSelector(selectBusinessFiles);
  const popupBusinessFiles = sortItemsByList(businessFiles, images, 'id', 'id');

  function onUpdateFiles(newFiles) {
    const imagesData = convertBusinessFilesToImageData(newFiles, businessFiles);
    setValue('images', imagesData);
  }

  return (
    <Form.Field className="popup-images">
      <div className="form-label">Images</div>
      <FileSelectionWell
        files={popupBusinessFiles}
        onUpdateFiles={onUpdateFiles}
        uploadSourceType={SOURCE_TYPE_DEFAULT}
      />
    </Form.Field>
  );
}
