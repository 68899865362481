import React from 'react';

import PropTypes from 'prop-types';

import { FileManagementModalStep } from '../../FilesManagementModal.constants';
import BackButton from '../BackButton';

const propTypes = {
  setSelectedImageSource: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function StockSearchResultsHeader({ setSelectedImageSource }) {
  function navigateBack() {
    setSelectedImageSource(FileManagementModalStep.IMAGE_SELECT);
  }

  return (
    <div>
      <BackButton onClick={() => navigateBack()} />
      <span>Add Stock Imagery</span>
    </div>
  );
}

StockSearchResultsHeader.propTypes = propTypes;
StockSearchResultsHeader.defaultProps = defaultProps;
