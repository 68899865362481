import { useState } from 'react';

import _uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';

import API from '../../../../../libs/api';
import { mergeArrayProperties } from '../../../../../libs/array';
import { selectBusinessId } from '../../../../../selectors';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';

export default function useCustomerTags(initial = { tags: [], campaigns: [] }) {
  const businessId = useSelector(selectBusinessId);
  const [customerTags, setCustomerTags] = useState(initial);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddTag = (type, tag) => {
    setCustomerTags((prevTags) => mergeArrayProperties(prevTags, { [type]: [tag] }));
  };

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const data = await API.getCustomerTags(businessId);
      setCustomerTags((prevTags) => mergeArrayProperties(prevTags, data));
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    customerTags: { tags: _uniq(customerTags.tags), campaigns: _uniq(customerTags.campaigns) },
    handleAddTag,
    isLoading,
  };
}
