import { createSlice } from '@reduxjs/toolkit';

import { objectToCamelCase } from '../../../../libs/format';
import { buildGenericAdapter } from '../../../../libs/slices';

const teamsAdapter = buildGenericAdapter();

const initialState = teamsAdapter.getInitialState();

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    updateOneTeam: (state, action) => {
      const { id, changes } = action.payload;
      const newChanges = objectToCamelCase(changes);
      teamsAdapter.updateOne(state, { id, changes: newChanges });
    },
    removeOneTeam: teamsAdapter.removeOne,
    setAllTeams: (state, action) => {
      const teams = objectToCamelCase(action.payload);
      teamsAdapter.setAll(state, teams);
    },
    addOneTeam: (state, action) => {
      const team = objectToCamelCase(action.payload);
      teamsAdapter.addOne(state, team);
    },
  },
});

export const { addOneTeam, setAllTeams, updateOneTeam, removeOneTeam } = teamsSlice.actions;

export default teamsSlice.reducer;

export const {
  selectAll: selectAllTeams,
  selectById: selectTeamById,
  selectIds: selectTeamIds,
} = teamsAdapter.getSelectors((state) => state.teams);
