import {
  BUSINESS_CATEGORY_LABELS,
  BUSINESS_CATEGORY_LABEL_DEFAULT,
  PRODUCT_TYPE_LABELS,
  PRODUCT_TYPE_LABEL_DEFAULT,
} from './BusinessSelectForm.constants';

export function getProductTypeLabels(productType) {
  return PRODUCT_TYPE_LABELS[productType] || PRODUCT_TYPE_LABEL_DEFAULT;
}

export function getBusinessCategoryLabels(category) {
  const categoryLowered = category.toLowerCase();
  return BUSINESS_CATEGORY_LABELS[categoryLowered] || BUSINESS_CATEGORY_LABEL_DEFAULT;
}
