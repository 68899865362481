import {
  BASE_FILE_SOURCE_FILTER_CLASS_NAME,
  ALL_FILE_SOURCE_FILTER_CLASS_NAME,
  DEFAULT_SOURCE_FILTER_VALUE,
} from './FileSourceFilter.constants';

export function getFileSourceFilterClassName(filter) {
  return filter === DEFAULT_SOURCE_FILTER_VALUE
    ? [BASE_FILE_SOURCE_FILTER_CLASS_NAME, ALL_FILE_SOURCE_FILTER_CLASS_NAME].join(' ')
    : BASE_FILE_SOURCE_FILTER_CLASS_NAME;
}
