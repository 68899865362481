import {
  faSquareCheck,
  faSquareCaretDown,
  faBarsStaggered,
  faBars,
  faEnvelope,
  faPhone,
  faFile,
} from '@fortawesome/free-solid-svg-icons';

import {
  INTERNAL_FORM_FIELD_TYPES,
  RECIPE_FORM_FIELD_TYPES,
} from '../../../../../../../../constants/form';

export const DEFAULT_EMAIL_LABEL = 'Email';
export const DEFAULT_EMAIL_PLACEHOLDER = 'example@gmail.com';
export const DEFAULT_PHONE_LABEL = 'Phone Number';
export const DEFAULT_PHONE_PLACEHOLDER = '(123) 456-7890';
export const DEFAULT_NAME_LABEL = 'Name';
export const DEFAULT_NAME_PLACEHOLDER = 'Your Name';

export const IconByType = {
  [INTERNAL_FORM_FIELD_TYPES.Email]: faEnvelope,
  [INTERNAL_FORM_FIELD_TYPES.PhoneNumber]: faPhone,
  [INTERNAL_FORM_FIELD_TYPES.ShortText]: faBars,
  [INTERNAL_FORM_FIELD_TYPES.LongText]: faBarsStaggered,
  [INTERNAL_FORM_FIELD_TYPES.SingleChoice]: faSquareCaretDown,
  [INTERNAL_FORM_FIELD_TYPES.MultipleChoice]: faSquareCheck,
  [INTERNAL_FORM_FIELD_TYPES.FileUpload]: faFile,
};

export const FormFieldTypes = [
  {
    label: 'Email',
    value: RECIPE_FORM_FIELD_TYPES.Email,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.Email],
    initialData: {
      displayLabel: DEFAULT_EMAIL_LABEL,
      placeholder: DEFAULT_EMAIL_PLACEHOLDER,
    },
  },
  {
    label: 'Phone',
    value: RECIPE_FORM_FIELD_TYPES.PhoneNumber,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.PhoneNumber],
    initialData: {
      displayLabel: DEFAULT_PHONE_LABEL,
      placeholder: DEFAULT_PHONE_PLACEHOLDER,
    },
  },
  {
    label: 'Name',
    value: RECIPE_FORM_FIELD_TYPES.ShortText,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.ShortText],
    initialData: {
      displayLabel: DEFAULT_NAME_LABEL,
      placeholder: DEFAULT_NAME_PLACEHOLDER,
    },
  },
  {
    label: 'Short Text',
    value: RECIPE_FORM_FIELD_TYPES.ShortText,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.ShortText],
  },
  {
    label: 'Long Text',
    value: RECIPE_FORM_FIELD_TYPES.LongText,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.LongText],
  },
  {
    label: 'Single Select',
    value: RECIPE_FORM_FIELD_TYPES.SingleChoice,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.SingleChoice],
  },
  {
    label: 'Multi Select',
    value: RECIPE_FORM_FIELD_TYPES.MultipleChoice,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.MultipleChoice],
  },
  {
    label: 'File',
    value: RECIPE_FORM_FIELD_TYPES.FileUpload,
    icon: IconByType[INTERNAL_FORM_FIELD_TYPES.FileUpload],
  },
];
