import PropTypes from 'prop-types';

export const BusinessFileFromPageDataPropType = PropTypes.shape({
  business_file_configuration_id: PropTypes.number,
  metadata: PropTypes.shape({}),
  order: PropTypes.number,
  id: PropTypes.number,
  file: PropTypes.string,
  s3_file: PropTypes.string,
  alt_text: PropTypes.string,
  filename: PropTypes.string,
});
