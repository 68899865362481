import React from 'react';

import { Tab } from 'semantic-ui-react';

import isString from 'lodash/isString';

import SortableMenu from './SortableMenu';
import SortableMenuItem from './SortableMenuItem';

import './SortableTab.scss';

const SortableTabMenu = ({ helperContainer, ...passThroughProps }) => {
  const props = {
    attached: true,
    tabular: false,
    ...passThroughProps,
  };

  return <SortableMenu helperContainer={helperContainer} {...props} />;
};

class SortableTab extends React.Component {
  constructor(props) {
    super(props);
    this.helperContainer = React.createRef();
  }

  render() {
    const {
      panes,
      onSortEnd,
      axis = 'x',
      lockAxis = 'x',
      pressDelay = 0,
      useDragHandle = false,
      ...tabProps
    } = this.props;

    const sortablePanes = panes.map((pane, idx) => {
      if (isString(pane.menuItem)) {
        return {
          ...pane,
          menuItem: (
            <SortableMenuItem
              key={pane.menuItem}
              index={idx}
              menuItemIndex={idx}
              className="tab-item"
            >
              {pane.menuItem}
            </SortableMenuItem>
          ),
        };
      }
      const itemProps = {
        ...pane.menuItem.props,
        className: 'tab-item',
        index: pane.menuItem.props.index || idx,
        menuItemIndex: pane.menuItem.props.index || idx,
      };
      return {
        ...pane,
        menuItem: <SortableMenuItem key={`menu_item_${itemProps.menuItemIndex}`} {...itemProps} />,
      };
    });

    return (
      <>
        <Tab
          panes={sortablePanes}
          {...tabProps}
          menu={{
            as: SortableTabMenu,
            panes,
            onSortEnd,
            axis,
            lockAxis,
            pressDelay,
            useDragHandle,
            helperContainer: () => this.helperContainer.current,
          }}
        />
        <div ref={this.helperContainer} className="ui tabular menu container" />
      </>
    );
  }
}

export default SortableTab;
