import React from 'react';

import PropTypes from 'prop-types';
import { Table, Label, Icon } from 'semantic-ui-react';

import RecipeProptypes from '../../Recipe.propTypes';

const propTypes = {
  recipe: RecipeProptypes.isRequired,
  actions: PropTypes.element,
};

const defaultProps = {
  actions: undefined,
};

const RecipeTableItem = ({ recipe, actions }) => {
  const data = recipe || {};
  const { name, recipeVersion, schemaVersion, tags } = data;

  return (
    <Table.Row>
      <Table.Cell>
        <Icon name="folder" /> {name}
      </Table.Cell>
      <Table.Cell>
        <Label>{recipeVersion}</Label>
      </Table.Cell>
      <Table.Cell>
        <Label>{schemaVersion}</Label>
      </Table.Cell>
      <Table.Cell>{tags && tags.map((tag) => <Label key={tag}>{tag}</Label>)}</Table.Cell>
      <Table.Cell>{actions}</Table.Cell>
    </Table.Row>
  );
};

RecipeTableItem.propTypes = propTypes;

RecipeTableItem.defaultProps = defaultProps;

export default RecipeTableItem;
