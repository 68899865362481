import _startCase from 'lodash/startCase';

import { RECIPE_CURATED_HERO_OPTIONS } from '../../../../../../modules/hero/constants/curated-hero';

export function getOptions(recipeName) {
  const options = RECIPE_CURATED_HERO_OPTIONS[recipeName] || [];

  return options.map((option) => ({
    key: option,
    text: _startCase(option),
    value: option,
  }));
}
