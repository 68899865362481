import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { STAGE_INITIAL, STAGE_INTERNAL_SELECT } from './BehaviorSelector.constants';
import { getDisplayType } from './BehaviorSelector.utils';
import InternalLinkOptions from './components/InternalLinkOptions/InternalLinkOptions';
import { BEHAVIOR_EXTERNAL_LINK, BEHAVIOR_INTERNAL_LINK, BEHAVIOR_LABEL } from '../constants';
import { ensureSlashes } from '../utils';

import './BehaviorSelector.scss';

const propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  extraBehaviors: PropTypes.arrayOf(PropTypes.string),
};
const defaultProps = {
  text: '',
  url: '',
  disabled: false,
  value: undefined,
  extraBehaviors: [],
};

function BehaviorSelector({ value, text, onSelect, disabled, url, extraBehaviors }) {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [stage, setStage] = useState(STAGE_INITIAL);
  const prevValue = useRef(value);

  function close(selectedBehavior) {
    setIsDropdownActive(false);
    setStage(STAGE_INITIAL);
    prevValue.current = selectedBehavior;
  }

  function calculateInitialStageUrl(selectedBehavior) {
    if (selectedBehavior === BEHAVIOR_INTERNAL_LINK) {
      return url;
    }
    return prevValue.current === selectedBehavior ? url : '';
  }

  function handleSelectInitialOption(e, { value: selectedBehavior }) {
    e.stopPropagation();
    onSelect({
      behavior: selectedBehavior,
      url: calculateInitialStageUrl(selectedBehavior),
      displayType: getDisplayType(selectedBehavior),
    });
    if (selectedBehavior === BEHAVIOR_INTERNAL_LINK) {
      setStage(STAGE_INTERNAL_SELECT);
    } else {
      close(selectedBehavior);
    }
  }

  function handleInternalSelect(e, selectedUrl) {
    e.stopPropagation();
    onSelect({
      behavior: value,
      url: ensureSlashes(selectedUrl),
      displayType: getDisplayType(BEHAVIOR_INTERNAL_LINK),
    });
    close(value);
  }

  function backToInitialStage(e) {
    e.stopPropagation();
    onSelect({ behavior: prevValue.current, url, displayType: getDisplayType(prevValue.current) });
    setStage(STAGE_INITIAL);
  }

  function handleBlur(e) {
    backToInitialStage(e);
    close(prevValue.current);
  }

  return (
    <Dropdown
      disabled={disabled}
      className="selection behavior-selector"
      text={text}
      onClick={() => setIsDropdownActive(true)}
      open={isDropdownActive}
      onBlur={handleBlur}
    >
      <Dropdown.Menu>
        {stage === STAGE_INITIAL && (
          <>
            <Dropdown.Item onClick={handleSelectInitialOption} value={BEHAVIOR_INTERNAL_LINK}>
              {BEHAVIOR_LABEL[BEHAVIOR_INTERNAL_LINK]}
              <Icon className="internal-page-submenu" name="angle right" />
            </Dropdown.Item>
            <Dropdown.Item onClick={handleSelectInitialOption} value={BEHAVIOR_EXTERNAL_LINK}>
              {BEHAVIOR_LABEL[BEHAVIOR_EXTERNAL_LINK]}
            </Dropdown.Item>
            {!_isEmpty(extraBehaviors) &&
              extraBehaviors.map((behavior) => (
                <Dropdown.Item key={behavior} onClick={handleSelectInitialOption} value={behavior}>
                  {BEHAVIOR_LABEL[behavior]}
                </Dropdown.Item>
              ))}
          </>
        )}
        {stage === STAGE_INTERNAL_SELECT && (
          <>
            <Dropdown.Item onClick={backToInitialStage}>
              <Icon name="angle left" />
              Go Back
            </Dropdown.Item>
            <InternalLinkOptions onSelect={handleInternalSelect} />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

BehaviorSelector.propTypes = propTypes;
BehaviorSelector.defaultProps = defaultProps;
export default BehaviorSelector;
