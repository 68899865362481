import { multiCaseGet } from '../../../libs/format';

export function getProviderService(providerServices, provider, serviceType) {
  return providerServices.find((service) => {
    return service.provider === provider && service.service_type === serviceType;
  });
}

export function getProviderServiceSettingById(providerServiceSettings, providerServiceId) {
  return providerServiceSettings
    .map((settings, index) => ({ ...settings, globalIndex: index }))
    .find((settings) => settings.provider_service === providerServiceId);
}

export function getProviderServiceSetting(
  providerServiceSettings,
  provider,
  serviceType,
  serviceStatus,
) {
  const serviceSettingsWithGlobalIndex = providerServiceSettings.map((settings, index) => ({
    ...settings,
    globalIndex: index,
  }));

  return serviceSettingsWithGlobalIndex.find((settings) => {
    return (
      settings.provider === provider &&
      multiCaseGet(settings, 'service_type') === serviceType &&
      (serviceStatus ? settings.status === serviceStatus : true)
    );
  });
}
