import _startCase from 'lodash/startCase';

import { INTERNAL_FORM_FIELD_TYPES } from '../../../../../../constants/form';

export function isPlaceholderShown({ type } = {}) {
  return ![INTERNAL_FORM_FIELD_TYPES.FileUpload].includes(type);
}

export function formatFieldType(type) {
  return `${_startCase(type)} Field`;
}

export function isFileUploadType(type) {
  return type === INTERNAL_FORM_FIELD_TYPES.FileUpload;
}
