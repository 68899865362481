import React from 'react';

import { Table } from 'semantic-ui-react';

export default function CustomerSubmissionsTableHeader() {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={1}>ID</Table.HeaderCell>
        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
        <Table.HeaderCell width={3}>Email</Table.HeaderCell>
        <Table.HeaderCell width={3}>Phone</Table.HeaderCell>
        <Table.HeaderCell width={2}>Submitted On</Table.HeaderCell>
        <Table.HeaderCell width={2}>Channel</Table.HeaderCell>
        <Table.HeaderCell width={1}>File Count</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}

CustomerSubmissionsTableHeader.propTypes = {};
CustomerSubmissionsTableHeader.defaultProps = {};
