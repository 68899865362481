import React from 'react';

import PropTypes from 'prop-types';

import ObjectOptionsHeader from '../../ObjectOptionsHeader';

import '../recipe-type.scss';

const propTypes = {
  recipeObjectKey: PropTypes.string.isRequired,
  recipePart: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
};

/**
 * This component is used for recipe node of type object
 *
 * @param  {string}  recipeObjectKey  Recipe Object key
 * @param  {string}  recipePart Value of the style recipe property
 * @param  {string}  parentId  Id of the parent node
 */

const RecipeStyle = ({ recipeObjectKey, recipePart, parentId }) => {
  return (
    <div className="recipe-object">
      <ObjectOptionsHeader
        recipeObjectKey={recipeObjectKey}
        recipePart={recipePart}
        parentId={parentId}
        isObject={false}
      />
    </div>
  );
};

RecipeStyle.propTypes = propTypes;

export default RecipeStyle;
