import { useState } from 'react';

import { STATUS_DRAFT } from '../../components/ItemStatusDropdown/ItemStatusDropdown.constants';
import { DEFAULT_COLUMN_PADDING, DEFAULT_ROW_PADDING } from '../../constants/page';

const DEFAULT_INITIAL_VALUES = {
  title: '',
  numCols: 1,
  paddingCols: DEFAULT_COLUMN_PADDING,
  defineRows: false,
  numRows: 1,
  paddingRows: DEFAULT_ROW_PADDING,
  status: STATUS_DRAFT,
  style: '',
  className: '',
};

function useCustomPageForm({ initialValues = DEFAULT_INITIAL_VALUES } = {}) {
  function getInitialValues() {
    return { ...DEFAULT_INITIAL_VALUES, ...initialValues };
  }

  const [values, setValues] = useState(getInitialValues());

  function setCustomPageField(field, value) {
    setValues((prevValues) => ({ ...prevValues, [field]: value }));
  }

  function resetCustomPageForm() {
    setValues(getInitialValues());
  }

  return { customPageData: values, setCustomPageField, resetCustomPageForm };
}

export default useCustomPageForm;
