import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Input, Button, Container, Form, Header } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { Message } from './DomainSearchForm.constants';
import API from '../../../../../libs/api';
import {
  addDomainExtensionIfMissing,
  cleanDomainName,
  isValidDomain,
} from '../../../../../libs/domains';
import ErrorHandler from '../../../../../libs/errors';
import { ChildrenPropType } from '../../../../../libs/proptypes';
import { DEFAULT_DOMAIN_PLACEHOLDER } from '../../DomainSelection.constants';
import DomainList from '../DomainList/DomainList';

import './DomainSearchForm.scss';

const propTypes = {
  children: ChildrenPropType,
  show: PropTypes.bool,
  domainName: PropTypes.string.isRequired,
  handleDomainChange: PropTypes.func.isRequired,
};

const defaultProps = {
  children: null,
  show: false,
};

export default function DomainSearchForm({ children, show, domainName, handleDomainChange }) {
  const [query, setQuery] = useState('');
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  async function searchDomains(domainToSearch) {
    if (!isValidDomain(domainToSearch)) {
      setMessage(Message.DOMAIN_VALIDATION_ERROR);
      setDomains([]);
      return;
    }
    setLoading(true);
    setMessage('');
    try {
      const response = await API.searchDomains(addDomainExtensionIfMissing(domainToSearch));
      const { data } = response;
      if (_isEmpty(data)) setMessage(Message.DOMAIN_NOT_FOUND);
      setDomains(data);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setLoading(false);
    }
  }

  if (!show) return null;

  return (
    <Container className="domain-search-form">
      <div className="header-container">
        <Header as="h1">
          Search for a custom domain for your site
          <Header.Subheader>Your domain will be your website’s URL address</Header.Subheader>
        </Header>
      </div>

      <Form>
        <Input
          labelPosition="left"
          value={query}
          onChange={(e, { value }) => setQuery(cleanDomainName(value))}
          placeholder={DEFAULT_DOMAIN_PLACEHOLDER}
          label="https://"
          fluid
          action={
            <Button
              onClick={() => searchDomains(query)}
              disabled={!query}
              loading={loading}
              content="Search"
            />
          }
        />
      </Form>
      {message && (
        <div className="message">
          <p>{message}</p>
        </div>
      )}
      <p className="description" />
      {children}
      <DomainList
        domains={domains}
        selectedDomain={domainName}
        onDomainClick={(domain) => handleDomainChange(domain, 'internal')}
      />
    </Container>
  );
}

DomainSearchForm.propTypes = propTypes;
DomainSearchForm.defaultProps = defaultProps;
