import { multiCaseGet } from '../format';

export function getLocationDisplay(location) {
  const locationName = multiCaseGet(location, 'locationName');
  if (locationName) {
    return locationName;
  }
  if (location.street || location.city) {
    return [location.street, location.city].filter((l) => !!l).join(', ');
  }
  return `Location ${location.id}`;
}

export function getAddressDisplay(address) {
  const { streetAddress, locality, state, postalCode, country } = address;
  const addressParts = [
    `${streetAddress} ${locality}`.trim(),
    `${state} ${postalCode}`.trim(),
    country,
  ];
  return addressParts
    .filter((part) => !!part)
    .join(', ')
    .trim();
}
