import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import _startCase from 'lodash/startCase';

import './StyleRulesTableHeader.scss';

const propTypes = {
  columnConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      showName: PropTypes.bool,
      className: PropTypes.string,
    }),
  ).isRequired,
};

const defaultProps = {};

export default function StyleRulesTableHeader({ columnConfigs }) {
  return (
    <Table.Header fullWidth className="style-rules-table-header">
      <Table.Row>
        {columnConfigs.map(({ name, width, showName, className }) => (
          <Table.HeaderCell key={name} width={width} className={className || ''}>
            {showName && _startCase(name)}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
}

StyleRulesTableHeader.propTypes = propTypes;
StyleRulesTableHeader.defaultProps = defaultProps;
