import { useEffect } from 'react';

import _isNil from 'lodash/isNil';

export default function useAsyncEffect(effect, dependencies, destroy) {
  useEffect(() => {
    let result;
    let mounted = true;

    const maybePromise = effect(() => {
      return mounted;
    });

    Promise.resolve(maybePromise).then((value) => {
      result = value;
    });

    return () => {
      mounted = false;

      if (!_isNil(destroy)) {
        destroy(result);
      }
    };
  }, dependencies || []);
}
