import React from 'react';

import PropTypes from 'prop-types';
import { List, Input, Button } from 'semantic-ui-react';

import { faChevronUp, faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addChoice, deleteChoice, updateChoice, moveChoice } from './FormFieldSelectOptions.utils';
import { useLatestRefValue } from '../../../../../../../../../utils/hooks';
import { FormFieldPropType } from '../../../../../../../../types/form-block.types';

import './FormFieldSelectOptions.scss';

const propTypes = {
  field: FormFieldPropType.isRequired,
  onUpdateFieldValue: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function FormFieldSelectOptions({ field, onUpdateFieldValue }) {
  const { settings = {} } = field;
  const { choices = [] } = settings;

  const latestChoices = useLatestRefValue(choices);

  function onUpdateChoices(nextChoices) {
    onUpdateFieldValue('settings.choices', nextChoices);
  }

  function onAddChoice() {
    const currentChoices = latestChoices.current;

    onUpdateChoices(addChoice(currentChoices));
  }

  function onDeleteChoice(choiceIndex) {
    const currentChoices = latestChoices.current;

    onUpdateChoices(deleteChoice(currentChoices, { choiceIndex }));
  }

  function onUpdateChoiceValue(choiceIndex, nextChoiceValue) {
    const currentChoices = latestChoices.current;

    onUpdateChoices(updateChoice(currentChoices, { choiceIndex, nextChoiceValue }));
  }

  function onMoveChoice(choiceIndex, direction) {
    const currentChoices = latestChoices.current;

    onUpdateChoices(moveChoice(currentChoices, { choiceIndex, direction }));
  }

  function isMoveUpDisable(choiceIndex) {
    return choiceIndex === 0;
  }

  function isMoveDownDisable(choiceIndex) {
    return choiceIndex === choices.length - 1;
  }

  function onUpdateChoiceValueWrapper(choiceIndex) {
    return (e, { value: nextChoice }) => onUpdateChoiceValue(choiceIndex, nextChoice);
  }

  return (
    <div className="form-field-select-options">
      <div>Options</div>
      <List>
        {choices.map((choice, index) => (
          /* eslint-disable react/no-array-index-key */
          <List.Item key={index}>
            <Input value={choice} onChange={onUpdateChoiceValueWrapper(index)} />
            <Button
              basic
              className="move-up"
              disabled={isMoveUpDisable(index)}
              onClick={() => onMoveChoice(index, -1)}
            >
              <FontAwesomeIcon className="icon" icon={faChevronUp} />
            </Button>
            <Button
              basic
              className="move-down"
              disabled={isMoveDownDisable(index)}
              onClick={() => onMoveChoice(index, 1)}
            >
              <FontAwesomeIcon className="icon" icon={faChevronDown} />
            </Button>
            <Button basic className="delete" onClick={() => onDeleteChoice(index)}>
              <FontAwesomeIcon className="icon" icon={faXmark} />
            </Button>
          </List.Item>
        ))}
      </List>
      <Button basic className="add" onClick={onAddChoice}>
        Add option
      </Button>
    </div>
  );
}

FormFieldSelectOptions.propTypes = propTypes;
FormFieldSelectOptions.defaultProps = defaultProps;
