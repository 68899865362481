import _indexOf from 'lodash/indexOf';

import {
  FORM_BLOCK_ELEMENT_TYPE,
  LEGACY_FORM_BLOCK_ELEMENT_TYPE,
} from '../../../../constants/types';

export function getActiveTabIndex(elementTypes, type) {
  if (type === LEGACY_FORM_BLOCK_ELEMENT_TYPE) {
    return _indexOf(elementTypes, FORM_BLOCK_ELEMENT_TYPE);
  }
  return _indexOf(elementTypes, type);
}
