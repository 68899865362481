import React from 'react';

import PropTypes from 'prop-types';
import { Input, Label } from 'semantic-ui-react';

import MaskedInput from 'react-text-mask';

import Field from './Field';
import { PHONE_NUMBER_MASK } from '../../constants/input';

class PhoneField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { target } = e;
    const { value } = target;
    const { onChange } = this.props;

    onChange(e, target, value.replace(/[^0-9.]/g, ''));
  }

  render() {
    const { value, placeholder, name, disabled, label, ...rest } = this.props;

    return (
      <Field {...rest}>
        {label && <label>{label}</label>}
        <Input labelPosition="left">
          <Label basic>+1</Label>
          <MaskedInput
            mask={PHONE_NUMBER_MASK}
            guide
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={this.handleChange}
            disabled={disabled}
          />
        </Input>
      </Field>
    );
  }
}

PhoneField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

PhoneField.defaultProps = {
  value: '',
  title: '',
  placeholder: '',
  name: '',
  label: '',
};

export default PhoneField;
