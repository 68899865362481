import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import _ from 'lodash';

import Field from './Field';
import { StylePropType } from '../../libs/proptypes';

const DropdownField = ({
  value,
  onChange,
  onValidate,
  error,
  options,
  optionStyles,
  selectedStyle,
  style,
  placeholder,
  fluid,
  title,
  disabled,
  className,
}) => {
  const computedOptions = options.map((o, index) => ({
    key: o,
    text: _.upperFirst(o),
    value: o,
    disabled,
    style: optionStyles[index],
  }));

  return (
    <Field title={title} style={style}>
      <Dropdown
        className={className}
        onChange={(e, { value: newValue }) => onChange(newValue)}
        options={computedOptions}
        placeholder={placeholder}
        selection
        fluid={fluid}
        value={value}
        disabled={disabled}
        style={selectedStyle}
        onBlur={onValidate}
        error={error}
      />
    </Field>
  );
};

DropdownField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  optionStyles: PropTypes.arrayOf(StylePropType),
  selectedStyle: StylePropType,
  style: StylePropType,
  placeholder: PropTypes.string,
  fluid: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

DropdownField.defaultProps = {
  value: '',
  optionStyles: [],
  selectedStyle: {},
  style: {},
  placeholder: '',
  fluid: true,
  title: '',
  disabled: false,
  className: '',
};

export default DropdownField;
