import PropTypes from 'prop-types';

export const InternalWebsiteComponentInteractionPropType = PropTypes.shape({
  id: PropTypes.number,
  display_type: PropTypes.string,
  behavior_type: PropTypes.string,
  url: PropTypes.string,
  enabled: PropTypes.bool,
  style: PropTypes.string,
  label: PropTypes.string,
});

export const WebsiteComponentInteractionShape = {
  id: PropTypes.number,
  displayType: PropTypes.string,
  behaviorType: PropTypes.string,
  url: PropTypes.string,
  enabled: PropTypes.bool,
  style: PropTypes.string,
  label: PropTypes.string,
};

export const WebsiteComponentInteraction = PropTypes.shape(WebsiteComponentInteractionShape);

export const WebsiteComponentInteractionConfigurationPropType = PropTypes.shape({
  ...WebsiteComponentInteractionShape,
  websiteInteractionConfigurationId: PropTypes.number,
  order: PropTypes.number,
});
