import PropTypes from 'prop-types';

import { BaseBlockType, ComponentConfigurationBlockType } from './common.types';

export const TextBlockPropType = PropTypes.shape({
  ...BaseBlockType,
  ...ComponentConfigurationBlockType,
  content: PropTypes.string,
  contentName: PropTypes.string,
  contentNodeId: PropTypes.number,
});
