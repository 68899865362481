function useQueryParams(query) {
  if (typeof window === 'undefined') {
    return null;
  }

  const url = new URL(window.location);
  return url.searchParams.get(query);
}

export default useQueryParams;
