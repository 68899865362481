import React, { useContext } from 'react';

import { Item } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { filterHtmlElements } from './HtmlElementList.utils';
import { HtmlElementCard } from './components';
import { selectWebsitePages } from '../../../../../../../selectors/website';
import { HtmlElementPageContext } from '../../../../context';
import { selectHtmlElements } from '../../../../selectors';

import './HtmlElementsList.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsList() {
  const {
    filter: { placement, pages },
    itemEditingId,
    setItemEditingId,
    setItem,
  } = useContext(HtmlElementPageContext);
  const htmlElements = useSelector(selectHtmlElements);
  const websitePages = useSelector(selectWebsitePages);
  const filtered = filterHtmlElements(htmlElements, websitePages, placement, pages);

  function onClickToEdit(id) {
    const exitEditor = itemEditingId === id;
    setItemEditingId(exitEditor ? null : id);
    if (exitEditor) {
      setItem(null);
    }
  }

  return (
    <div className="html-element-list">
      <Item.Group>
        {(filtered || []).map((item) => (
          <HtmlElementCard
            key={item.id || item.localId}
            onClickToEdit={onClickToEdit}
            item={item}
          />
        ))}
      </Item.Group>
    </div>
  );
}

HtmlElementsList.propTypes = propTypes;
HtmlElementsList.defaultProps = defaultProps;
