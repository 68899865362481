export const DEFAULT_HTML_ELEMENT = {
  name: '',
  domLocation: 'HEAD',
  websiteLocation: 'GLOBAL',
  contents: '',
  enabled: true,
  website: null,
  page: null,
  createdBy: null,
};
