export const STYLE_RULES_TABLE_COLUMNS_FIELDS = ['name', 'value', 'elementType', 'actions'];

export const STYLE_RULES_TABLE_COLUMN_NAMES_MAP = {
  name: 'Name',
  value: 'Value',
  elementType: 'Type',
  actions: '',
};

export const STYLE_RULES_TABLE_COLUMN_WIDTHS_MAP = {
  name: 4,
  value: 8,
  elementType: 3,
  actions: 2,
};

export const STYLE_RULES_TABLE_COLUMN_TO_DISPLAY_NAME_MAP = {
  name: true,
  value: true,
  elementType: true,
  actions: false,
};

export const STYLE_RULES_TABLE_COLUMN_TO_CLASS_NAME_MAP = {
  actions: 'actions',
};
