import React from 'react';

import { Header, Select } from 'semantic-ui-react';

import { FULFILLMENT_STATUS_OPTIONS } from './FulfillmentStatusFilter.constants';

import './FulfillmentStatusFilter.scss';

function FulfillmentStatusFilter({ currentValue, onChange }) {
  function onChangeWrapper(e, { value }) {
    if (value === 'ALL') {
      return onChange(e, { value: '' });
    }
    return onChange(e, { value });
  }
  return (
    <div>
      <Header as="h5">Fulfillment Status</Header>
      <Select
        className="fulfillment-status-filter"
        value={currentValue || 'ALL'}
        options={FULFILLMENT_STATUS_OPTIONS}
        onChange={onChangeWrapper}
      />
    </div>
  );
}

export default FulfillmentStatusFilter;
