import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _reduce from 'lodash/reduce';

export function removeObjectLikeValues(obj) {
  return Object.keys(obj).reduce((attrs, key) => {
    const value = obj[key];
    if (!_isObject(value)) {
      return { ...attrs, [key]: value };
    }
    return attrs;
  }, {});
}

/**
 * Find the property of the difference between two objects
 * @param  {object} obj1 - Source object to compare
 * @param  {object} obj2  - New object with potential changes
 * @return {object} differences
 */
export function diff(obj1, obj2) {
  const dict = {};
  const diffKeys = _reduce(
    obj1,
    (result, value, key) => {
      return _isEqual(value, obj2[key]) ? result : result.concat(key);
    },
    [],
  );

  _forEach(diffKeys, (key) => {
    dict[key] = obj2[key];
  });
  return dict;
}
