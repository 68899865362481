import React from 'react';

import { Tab } from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  curatedHeroKeyName,
  curatedHeroTabName,
  customHeroKeyName,
  customHeroTabName,
  customPagesKeyName,
  customPagesTabName,
  redirectTabKeyName,
  redirectTabName,
} from './CustomSettingsPage.constants';
import CuratedHeroTab from './components/CuratedHeroTab';
import CustomHeroTab from './components/CustomHeroTab';
import CustomPagesTab from './components/CustomPagesTab';
import RedirectTab from './components/RedirectTab';
import { DEFAULT_ERROR_MESSAGE } from '../../../libs/errors/constants';
import useRecipe from '../../modules/foundation/hooks/use-recipe';
import { isCuratedHeroSupported } from '../../modules/hero/services/curated-hero';
import { isCustomHeroSupported } from '../../modules/hero/services/custom-hero';
import { DismissibleMessage, ErrorBoundary } from '../../modules/utils/components';

import './CustomSettingsPage.scss';

export default function CustomSettingsPage() {
  const { redirectTab } = useFlags();

  const { activePatch } = useRecipe();

  const displayCustomHeroTab = isCustomHeroSupported(activePatch);
  const displayCuratedHeroTab = isCuratedHeroSupported(activePatch);
  const displayRedirectTab = redirectTab;

  function createTabPane(menuItem, keyName, Component) {
    return {
      menuItem,
      render: () => (
        <Tab.Pane attached={false} key={keyName}>
          <Component />
        </Tab.Pane>
      ),
    };
  }

  const panes = [createTabPane(customPagesTabName, customPagesKeyName, CustomPagesTab)];

  if (displayCustomHeroTab) {
    panes.push(createTabPane(customHeroTabName, customHeroKeyName, CustomHeroTab));
  }

  if (displayCuratedHeroTab) {
    panes.push(createTabPane(curatedHeroTabName, curatedHeroKeyName, CuratedHeroTab));
  }

  if (displayRedirectTab) {
    panes.push(createTabPane(redirectTabName, redirectTabKeyName, RedirectTab));
  }

  return (
    <ErrorBoundary
      fallback={<DismissibleMessage initialVisible content={DEFAULT_ERROR_MESSAGE} error />}
    >
      <div className="custom-settings-page">
        <Tab className="main-tab" menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
    </ErrorBoundary>
  );
}
