import PropTypes from 'prop-types';

const CustomerContactPropType = PropTypes.shape({
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

export default CustomerContactPropType;
