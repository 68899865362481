import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  setActiveProduct as setGlobalActiveProduct,
  setProducts as setGlobalProducts,
} from '../../actions/products';
import useAsyncEffect from '../../components/modules/foundation/hooks/use-async-effect';
import API from '../../libs/api';
import {
  selectBusinessId,
  selectBusinessType,
  selectProducts,
  selectActiveProduct,
} from '../../selectors';

export default function useMerchantProducts() {
  const dispatch = useDispatch();
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const currentProducts = useSelector(selectProducts);
  const currentActiveProduct = useSelector(selectActiveProduct);

  if (businessType !== 'merchant') {
    return {};
  }
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(currentProducts);
  const [activeProduct, setActiveProduct] = useState(currentActiveProduct);

  function hasProducts() {
    return products.length;
  }

  async function fetchProducts() {
    setLoading(true);
    let localProducts = [];
    try {
      localProducts = await API.getMerchantProduct({ merchant: businessId });
    } finally {
      setLoading(false);
    }
    setProducts(localProducts);
    dispatch(setGlobalProducts(localProducts));
  }

  useAsyncEffect(async () => {
    if (!hasProducts()) {
      await fetchProducts();
    }
  }, []);

  useEffect(() => {
    const nextActiveProduct = hasProducts() ? products[0] : {};
    setActiveProduct(nextActiveProduct);
    dispatch(setGlobalActiveProduct(nextActiveProduct));
  }, [products]);

  useAsyncEffect(async () => {
    await fetchProducts();
  }, [businessId, businessType]);

  return {
    loading,
    products,
    activeProduct,
  };
}
