import { SUPPORTED_ELEMENT_TYPES } from './EditPageElementModal.constants';
import { isLegacyFormBlock } from './components/EditFormBlock/EditFormBlock.utils';
import { FORM_BLOCK_ELEMENT_TYPE } from '../../constants/types';

export function createElementStates(defaultElement, createStateFunc) {
  const { type: defaultElementType } = defaultElement;

  const isLegacyForm = isLegacyFormBlock(defaultElement);

  return SUPPORTED_ELEMENT_TYPES.reduce((state, elementType) => {
    if (
      elementType === defaultElementType ||
      (isLegacyForm && elementType === FORM_BLOCK_ELEMENT_TYPE)
    ) {
      return { ...state, [elementType]: defaultElement };
    }

    return { ...state, [elementType]: createStateFunc(elementType, {}) };
  }, {});
}
