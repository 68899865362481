export const createWebsite = (payload) => (dispatch) => {
  dispatch({
    type: 'CREATE_WEBSITE',
    payload,
  });
};

export const resetWebsite = (payload) => (dispatch) => {
  dispatch({
    type: 'RESET_WEBSITE',
    payload,
  });
};

export const initializeLayouts = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_LAYOUTS',
    payload,
  });
};

export const updateWebsiteLayoutComponent = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WEBSITE_LAYOUT_COMPONENT',
    payload,
  });
};

export const initializePages = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_PAGES',
    payload,
  });
};

export const updateWebsitePages = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WEBSITE_PAGES',
    payload,
  });
};

export const updateWebsitePage = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_WEBSITE_PAGE',
    payload,
  });
};

export const addWebsitePage = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_WEBSITE_PAGE',
    payload,
  });
};

export const removeWebsitePage = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_WEBSITE_PAGE',
    payload,
  });
};

export const initializePatches = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_PATCHES',
    payload,
  });
};

export const setColorPalette = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_COLOR_PALETTE',
    payload,
  });
};

export const initializeStyleRules = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_STYLE_RULES',
    payload,
  });
};

export const updateStyleRule = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_STYLE_RULE',
    payload,
  });
};

export const addStyleRule = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_STYLE_RULE',
    payload,
  });
};

export const deleteStyleRule = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_STYLE_RULE',
    payload,
  });
};

export const initializePublications = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_PUBLICATIONS',
    payload,
  });
};

export const initializeWebsiteUsers = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_WEBSITE_USERS',
    payload,
  });
};

export const initializeWebsiteSubscription = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_WEBSITE_SUBSCRIPTION',
    payload,
  });
};

export const addWebsiteUser = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_WEBSITE_USER',
    payload,
  });
};

export const removeWebsiteUser = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_WEBSITE_USER',
    payload,
  });
};

export const clearWebsiteTouchedFields = (payload) => (dispatch) => {
  dispatch({
    type: 'CLEAR_WEBSITE_TOUCHED_FIELD',
    payload,
  });
};

export const removeWebsiteAddedItem = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_WEBSITE_ADDED_ITEM',
    payload,
  });
};

export const removeWebsiteDeletedItem = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_WEBSITE_DELETED_ITEM',
    payload,
  });
};

export const initializeDomains = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_DOMAINS',
    payload,
  });
};

export const setIsNextWebsite = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_IS_NEXT_WEBSITE',
    payload,
  });
};
