export const discreteFieldOptions = [
  {
    nodeType: 'COMPONENT',
    field: 'type',
    options: ['FISHERMAN', 'SEMANTIC', 'JSX', 'LOCAL', 'CUSTOM'],
  },
  {
    nodeType: 'COMPONENT',
    field: 'importType',
    options: ['OBJECT', 'DEFAULT'],
  },
  {
    nodeType: 'COMPONENT',
    field: 'layout',
    getOptions: ({ recipe: { layouts } }) =>
      Object.keys(layouts).filter((key) => !['__type__', '__id__'].includes(key)),
  },
  {
    nodeType: 'CONDITION',
    field: 'operator',
    options: ['AND', 'OR'],
  },
  {
    nodeType: 'PAGE',
    field: 'layout',
    getOptions: ({ recipe: { layouts } }) =>
      Object.keys(layouts).filter((key) => !['__type__', '__id__'].includes(key)),
  },
  {
    nodeType: 'VALUE',
    field: 'type',
    options: ['COMPONENT', 'QUERY', 'FUNCTION', 'COLLECTION', 'VARIABLE_REFERENCE'],
  },
  {
    nodeType: 'FUNCTION',
    field: 'importType',
    options: ['OBJECT', 'DEFAULT'],
  },
];

export const codeFieldOptions = [
  {
    nodeType: 'PAGE',
    field: 'style',
    editorMode: 'css',
  },
  {
    nodeType: 'COMPONENT',
    field: 'content',
    editorMode: 'javascript',
  },
  {
    nodeType: 'LAYOUT',
    field: 'style',
    editorMode: 'css',
  },
];
