import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';

import RepRegistrationLogo from './RepRegistrationLogo';
import API from '../../../libs/api';
import Validate from '../../../libs/validate';
import LoginBanner from '../../core/LoginBanner';
import ConfirmationPage from '../ConfirmationPage';

import '../../../styles/core/pages/login.scss';

const FORM_MESSAGE_TIMEOUT = 5000;

class RegistrationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      errorMessage: '',
      loading: false,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.validateState = this.validateState.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.toggleSuccess = this.toggleSuccess.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }

  async onSubmit() {
    const { leadSource } = this.props;
    const { email, firstName, lastName } = this.state;

    this.toggleLoading(async () => {
      try {
        const payload = {
          email,
          firstName,
          lastName,
          leadSource,
          sendMagicLink: true,
        };

        await API.registerUser(payload);

        this.setState({
          success: true,
        });
      } catch (e) {
        const message = _get(e, 'response.data.message', e.message);
        this.setFormMessage('errorMessage', message);
      } finally {
        this.toggleLoading();
      }
    });
  }

  setFormMessage(messageField, message, resetEventually = true) {
    if (resetEventually) {
      this.setState({ [messageField]: message }, () =>
        setTimeout(() => this.setFormMessage(messageField, '', false), FORM_MESSAGE_TIMEOUT),
      );
    }

    this.setState({ [messageField]: message });
  }

  toggleLoading(cb = null) {
    this.setState(
      ({ loading }) => ({ loading: !loading }),
      () => cb && cb(),
    );
  }

  toggleSuccess() {
    const { success } = this.state;
    this.setState({ success: !success });
  }

  validateState() {
    const { email, firstName, lastName } = this.state;

    return (
      Validate.REGEX.EMAIL.test(email) &&
      // eslint-disable-next-line no-underscore-dangle
      Validate._validateDefinement(firstName) &&
      // eslint-disable-next-line no-underscore-dangle
      Validate._validateDefinement(lastName)
    );
  }

  render() {
    const { leadSource } = this.props;
    const { firstName, lastName, loading, email, success, errorMessage } = this.state;

    return (
      <div className="login-page">
        <LoginBanner />
        <div className="login-form-container">
          {success ? (
            <div className="login-form">
              <ConfirmationPage
                icon="mail outline"
                subject="Check your email"
                content={
                  <p>
                    We just emailed you a link that will allow you to sign in without your password.
                    <br />
                    Please check your inbox and tap the link to sign in.
                  </p>
                }
                actionContent={
                  <div>
                    <p>You may close this window</p>
                  </div>
                }
              />
            </div>
          ) : (
            <div className="login-form">
              <Segment>
                <RepRegistrationLogo leadSource={leadSource} />
                <Header as="h2" textAlign="center">
                  Rep Registration
                </Header>
                <Message content="Completing registration will grant you the ability to create & edit preview websites" />
                <Form onSubmit={this.onSubmit} loading={loading}>
                  <Form.Input
                    onChange={this.handleChange}
                    placeholder="Email"
                    name="email"
                    label="Email"
                    error={errorMessage.length !== 0}
                    value={email}
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    placeholder="First Name"
                    name="firstName"
                    label="First Name"
                    error={errorMessage.length !== 0}
                    value={firstName}
                  />
                  <Form.Input
                    onChange={this.handleChange}
                    placeholder="Last Name"
                    name="lastName"
                    label="Last Name"
                    error={errorMessage.length !== 0}
                    value={lastName}
                  />
                  <Button type="submit" primary fluid disabled={!this.validateState()}>
                    Register
                  </Button>
                </Form>
                {errorMessage && <Message error content={errorMessage} />}
              </Segment>
            </div>
          )}
        </div>
      </div>
    );
  }
}

RegistrationPage.propTypes = {
  leadSource: PropTypes.string.isRequired,
};

export default RegistrationPage;
