import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Header, Icon, Segment } from 'semantic-ui-react';

import FilesManagementModalProvider from '../../../../../../../files/components/FilesManagementModalProvider';
import { ImagePropType } from '../../../../../../types/image-block.types';

import './ImageUploadWell.scss';

const propTypes = {
  images: PropTypes.arrayOf(ImagePropType),
  onSelectImages: PropTypes.func.isRequired,
  maxFilesAllowed: PropTypes.number,
};

const defaultProps = {
  images: [],
  maxFilesAllowed: 1,
};

export default function ImageUploadWell({ onSelectImages, images, maxFilesAllowed }) {
  const [fileModalOpen, setFileModalOpen] = useState(false);

  function openFileModal() {
    setFileModalOpen(true);
  }

  function closeFileModal() {
    setFileModalOpen(false);
  }

  function onSubmit(nextImages) {
    onSelectImages(nextImages);
    closeFileModal();
  }

  return (
    <>
      <Segment onClick={openFileModal} placeholder className="image-upload-well">
        <Header icon>
          <Icon name="images" size="small" />
          Add photo(s)
        </Header>
      </Segment>
      {fileModalOpen && (
        <FilesManagementModalProvider
          open={fileModalOpen}
          setOpen={setFileModalOpen}
          initialSelectedFiles={images}
          onSubmit={onSubmit}
          minFilesAllowed={1}
          maxFilesAllowed={maxFilesAllowed}
          actionButtonText="Add Images"
        />
      )}
    </>
  );
}

ImageUploadWell.propTypes = propTypes;
ImageUploadWell.defaultProps = defaultProps;
