import _isFinite from 'lodash/isFinite';

import {
  FILE_SIZE_MB_DIVISOR,
  FILE_SIZE_KB_DIVISOR,
} from 'components/modules/files/constants/size';

import {
  FILE_ICON_DEFAULT,
  FILE_ICON_FOR_IMAGE,
  FILE_ICON_FOR_PDF,
  FILE_ICON_FOR_VIDEO,
} from './CustomerSubmissionsModal.constants';

export function getFormattedFileSize(size, precision = 1) {
  if (!_isFinite(size)) {
    return '';
  }

  if (size >= FILE_SIZE_MB_DIVISOR) {
    const formattedSize = (size / FILE_SIZE_MB_DIVISOR).toFixed(precision);
    return `${formattedSize} MB`;
  }

  const formattedSize = (size / FILE_SIZE_KB_DIVISOR).toFixed(precision);
  return `${formattedSize} KB`;
}

export function getIconNameForFile(isImage, mimeType) {
  if (isImage || (mimeType && mimeType.startsWith('image/'))) {
    return FILE_ICON_FOR_IMAGE;
  }
  if (mimeType === 'application/pdf') {
    return FILE_ICON_FOR_PDF;
  }
  if (mimeType && mimeType.startsWith('video/')) {
    return FILE_ICON_FOR_VIDEO;
  }

  return FILE_ICON_DEFAULT;
}
