import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import {
  COLOR_PALETTE_LABEL,
  COLOR_PALETTE_PLACEHOLDER,
  DEFAULT_NUM_DISPLAY_COLORS,
} from './ColorPaletteField.constants';
import Color from './components/Color';
import useColorPalettes from '../../../hooks/useColorPalettes';

import './ColorPaletteField.scss';

const propTypes = {
  onPaletteChange: PropTypes.func.isRequired,
  selectedPaletteId: PropTypes.number,
  numDisplayColors: PropTypes.number,
  recipeName: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

const defaultProps = {
  selectedPaletteId: null,
  numDisplayColors: DEFAULT_NUM_DISPLAY_COLORS,
  recipeName: '',
  required: false,
  label: COLOR_PALETTE_LABEL,
  placeholder: COLOR_PALETTE_PLACEHOLDER,
};

function ColorPaletteField({
  selectedPaletteId,
  onPaletteChange,
  numDisplayColors,
  recipeName,
  required,
  label,
  placeholder,
}) {
  const { palettes, isLoading } = useColorPalettes(null, { recipeName });

  return (
    <Form.Select
      loading={isLoading}
      required={required}
      label={label}
      placeholder={placeholder}
      value={selectedPaletteId}
      onChange={onPaletteChange}
      options={palettes.map(({ colors, name, id: paletteId }) => ({
        key: `palette-${paletteId}`,
        value: paletteId,
        text: name,
        content: (
          <div className="color-palette">
            {colors.slice(0, numDisplayColors).map(({ color, role }) => (
              <Color color={color} key={`color-${color}-${role}`} />
            ))}
          </div>
        ),
      }))}
    />
  );
}

ColorPaletteField.propTypes = propTypes;
ColorPaletteField.defaultProps = defaultProps;

export default ColorPaletteField;
