import React from 'react';

import { Modal, Message } from 'semantic-ui-react';

import CloseableModal from '../../CloseableModal';

import './NotificationModal.scss';

export default class NotificationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: null };

    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    const { timeout = 5000 } = this.props;

    if (timeout) {
      setTimeout(this.closeModal, timeout);
    }
  }

  closeModal() {
    const { callback } = this.props;
    this.setState({ open: false }, callback);
  }

  render() {
    const { initialOpen, type, title, primaryMessage, secondaryMessage, additionalComponent } =
      this.props;
    const { open } = this.state;

    return (
      <CloseableModal
        onClose={this.closeModal}
        open={open === null ? initialOpen : open}
        className="notification-modal"
        header={title}
      >
        <Modal.Content>
          <Message negative={type === 'negative'} positive={type === 'positive'}>
            <Message.Header>{primaryMessage}</Message.Header>
            <>{secondaryMessage}</>
            {additionalComponent}
          </Message>
        </Modal.Content>
      </CloseableModal>
    );
  }
}
