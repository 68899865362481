import { Children } from 'react';

import _isNil from 'lodash/isNil';

export function childHasName(child, componentName) {
  if (_isNil(child)) {
    return false;
  }

  const childType = child.type;
  const childName = childType.displayName || childType.name;

  return childName === componentName || childType === componentName;
}

export function findChildElement(children, desiredValue) {
  let childElement = null;

  Children.forEach(children, (child) => {
    if (childHasName(child, desiredValue)) {
      childElement = child;
    }
  });

  return childElement;
}
