import _get from 'lodash/get';

import { getFormattedAmount } from '../../../libs/stripe';

export function getPrice(price) {
  return getFormattedAmount(price);
}

export function getActivePlan(availablePlans) {
  return availablePlans.find((plan) => _get(plan, 'active', false));
}

export function toDisplayablePlan(plan, index) {
  const { monthlyDescription, annualDescription, active } = plan;

  return {
    ...plan,
    index,
    active,
    annualDescription,
    monthlyDescription,
  };
}

export function isCustomPlan(plan) {
  const { isCustom } = plan;
  return isCustom;
}
