import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

import {
  COMPONENT_NAME_BY_ELEMENT_TYPE,
  FISHERMAN_COMPONENT_TYPE,
  JSX_COMPONENT_TYPE,
  SEMANTIC_COMPONENT_TYPE,
} from '../../../../constants/types';

export function addComponentChild(recipeSnippet, child) {
  return {
    ...recipeSnippet,
    children: [...(recipeSnippet.children || []), child],
  };
}

export function addComponentChildren(recipeSnippet, children) {
  return {
    ...recipeSnippet,
    children: [...(recipeSnippet.children || []), ...children],
  };
}

export function createComponent(type, id, name, props = [], children = []) {
  return {
    name,
    type,
    props,
    children,
    id: id || uuidv4(),
  };
}

export function createFishermanComponent(name, id, props, children) {
  return createComponent(FISHERMAN_COMPONENT_TYPE, id, name, props, children);
}

export function createSemanticComponent(name, id, props, children) {
  return createComponent(SEMANTIC_COMPONENT_TYPE, id, name, props, children);
}

export function createJSXComponent(name, id, props, children) {
  return createComponent(JSX_COMPONENT_TYPE, id, name, props, children);
}

export function getComponentName(component) {
  return _get(component, 'name');
}

export function getComponentId(component) {
  return _get(component, 'id');
}

export function createChildComponentElements(
  children,
  recipeStateToSnippet,
  { context = {} } = {},
) {
  return children.map((child) => {
    const { type } = child;
    return recipeStateToSnippet(type, child, { context });
  });
}

export function createChildComponentStates(children, recipeSnippetToState) {
  return children
    .map((child) => {
      const { name: componentName } = child;
      const componentType = COMPONENT_NAME_BY_ELEMENT_TYPE[componentName];

      if (!componentType) {
        return null;
      }

      return recipeSnippetToState(componentType, child);
    })
    .filter(Boolean);
}
