import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  BUTTON_PROPERTY,
  EXTERNAL_PAGE_TYPE,
  INTERNAL_PAGE_TYPE,
  RECIPE_NODE_PAGE_TYPE,
} from '../constants';

export function isButtonPage(page = {}) {
  const { isGroup, asButton } = page;
  if (!isGroup) {
    return _get(page, 'metadata.as', '') === BUTTON_PROPERTY;
  }
  return !!asButton;
}

export function isCustomPage(page = {}) {
  if (_isEmpty(page)) {
    return false;
  }
  return page.pageType === RECIPE_NODE_PAGE_TYPE;
}

export function isExternalPage(page = {}) {
  if (_isEmpty(page)) {
    return false;
  }
  return page.pageType === EXTERNAL_PAGE_TYPE;
}

export function isInternalPage(page = {}) {
  return !isExternalPage(page);
}

export function filterPagesByType(pages, pageType) {
  if (_isEmpty(pageType)) {
    return pages;
  }
  if (pageType === INTERNAL_PAGE_TYPE) {
    return pages.filter((page) => isInternalPage(page));
  }
  return pages.filter((page) => isExternalPage(page));
}

export function filterButtonPages(pages) {
  return pages.filter((page) => isButtonPage(page));
}

export function filterNonButtonPages(pages) {
  return pages.filter((page) => !isButtonPage(page));
}

export function getNavigationPriority(page) {
  const { isGroup, pages = [] } = page;
  const navigationPriority = _get(page, 'navigationPriority', 0);
  if (isGroup === true) {
    if (!_isEmpty(pages)) {
      // if the group has pages, return the highest navigation priority of the pages
      return Math.max(...pages.map((currentPage) => getNavigationPriority(currentPage)));
    }
  }
  return navigationPriority;
}

export function getPagesFrom(pages, navigationPriority) {
  return pages.filter((page) => {
    const { isGroup } = page;
    if (isGroup === true) {
      return false;
    }
    return getNavigationPriority(page) >= navigationPriority;
  });
}

export function getNextNavigationPriority(pages) {
  const pagesPriority = pages.map((page) => getNavigationPriority(page) + 1);
  return Math.max(pages.length, ...pagesPriority);
}

export function getMinNavigationPriority(pages) {
  const pagesPriority = pages.map((page) => getNavigationPriority(page));
  return Math.min(...pagesPriority);
}

export function sortByNavigationPriority(pages) {
  return pages.sort((page1, page2) => getNavigationPriority(page1) - getNavigationPriority(page2));
}

export function isHomePage(page) {
  const { slug = '', title = '' } = page || {};

  return slug === '/' || title.toLowerCase() === 'home';
}
