import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Message, Modal } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _range from 'lodash/range';
import { useSelector } from 'react-redux';

import {
  WOFLOW_UPLOAD_TYPE_FILE,
  WOFLOW_UPLOAD_TYPE_URL,
} from './WoflowFilesUploadModal.constants';
import { useWoflowFileUpload } from './WoflowFilesUploadModal.hooks';
import EmptyFileUploads from './components/EmptyFileUploads/EmptyFileUploads';
import WoflowFilesInputs from './components/WoflowFilesInputs';
import WoflowUploadDropdown from './components/WoflowUploadDropdown';
import WoflowUrlsInputs from './components/WoflowUrlsInputs';
import ErrorHandler, { getErrorMessage } from '../../../../../../libs/errors';
import { selectBusinessType, selectBusinessId } from '../../../../../../selectors/business';
import CloseableModal from '../../../../../common/CloseableModal';
import InputFile from '../../../../../common/InputFile/InputFile';

import './WoflowFilesUploadModal.scss';

const propTypes = {
  handleSuccessMessage: PropTypes.func.isRequired,
  trigger: PropTypes.element,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const defaultProps = {
  trigger: undefined,
  isOpen: undefined,
  onOpen: undefined,
  onClose: undefined,
};

function WoflowFilesUploadModal({
  trigger,
  isOpen: isOpenProp,
  onOpen,
  onClose,
  handleSuccessMessage,
  jobDestination,
}) {
  const businessType = useSelector(selectBusinessType);
  const businessId = useSelector(selectBusinessId);
  const [isOpen, setIsOpen] = useState(isOpenProp);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    isLoading,
    isValidData,
    addUrl,
    addFile,
    removeFile,
    removeUrl,
    changeUrl,
    createWoflowMenuJob,
    resetWoflowState,
    files,
    urls,
  } = useWoflowFileUpload();

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  const isTriggered = trigger !== undefined;
  const isManaged =
    !isTriggered && isOpen !== undefined && onOpen !== undefined && onClose !== undefined;

  if (!isTriggered && !isManaged) {
    throw new Error('trigger or isOpen/onOpen/onClose must be defined');
  }

  function hasData() {
    return !_isEmpty(urls) || !_isEmpty(files);
  }

  function handleMessageDismiss() {
    setErrorMessage(null);
  }

  function handleOpen() {
    if (isTriggered) {
      setIsOpen(true);
    } else {
      onOpen();
    }
    setErrorMessage(null);
    resetWoflowState();
  }

  function handleClose() {
    if (isTriggered) {
      setIsOpen(false);
    } else {
      onClose();
    }
    setErrorMessage(null);
    resetWoflowState();
  }

  async function handleSubmitJob() {
    setErrorMessage(null);
    try {
      await createWoflowMenuJob(businessType, businessId, jobDestination);
      handleSuccessMessage('Job has been successfully created');
      handleClose();
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    }
  }

  function handleDropdownSelect(event, value, triggerFileInputClick) {
    if (value === WOFLOW_UPLOAD_TYPE_FILE) {
      triggerFileInputClick(event);
    } else if (value === WOFLOW_UPLOAD_TYPE_URL) {
      addUrl('');
    }
  }

  async function handleInputFileChange(event) {
    const inputFiles = _get(event, 'target.files', []) || [];
    _range(inputFiles.length).forEach(async (index) => {
      const file = inputFiles[index];
      try {
        await addFile(businessType, businessId, file);
      } catch (e) {
        ErrorHandler.capture(e);
        setErrorMessage(getErrorMessage(e));
      }
    });
  }

  async function handleInputFileRemove(file) {
    try {
      await removeFile(businessType, businessId, file);
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    }
  }

  return (
    <InputFile accept="image/*,.csv,.pdf" onChange={handleInputFileChange}>
      {({ onClick }) => (
        <CloseableModal
          onClose={handleClose}
          onOpen={handleOpen}
          open={isOpen}
          trigger={trigger}
          header={
            <div className="woflow-files-upload-modal-header">
              <div>Select Menu files</div>
              <WoflowUploadDropdown
                onSelect={(event, value) => handleDropdownSelect(event, value, onClick)}
              />
            </div>
          }
          className="woflow-files-upload-modal"
        >
          <Modal.Content scrolling>
            {!hasData() && <EmptyFileUploads />}
            <WoflowFilesInputs
              className="woflow-upload-input"
              files={files}
              onRemove={handleInputFileRemove}
            />
            <WoflowUrlsInputs
              className="woflow-upload-input"
              urls={urls}
              onChange={changeUrl}
              onRemove={removeUrl}
            />
          </Modal.Content>
          <Modal.Actions>
            <div className="modal-actions">
              <Button
                disabled={isLoading || !isValidData}
                loading={isLoading}
                className="woflow-job-submit-button secondary-navy"
                content="Submit"
                onClick={() => handleSubmitJob()}
              />

              {errorMessage && (
                <div className="woflow-error-message">
                  <Message error header={errorMessage} onDismiss={handleMessageDismiss} />
                </div>
              )}
            </div>
          </Modal.Actions>
        </CloseableModal>
      )}
    </InputFile>
  );
}

WoflowFilesUploadModal.propTypes = propTypes;
WoflowFilesUploadModal.defaultProps = defaultProps;

export default WoflowFilesUploadModal;
