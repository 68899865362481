import React, { useContext } from 'react';

import { Card, Loader, Pagination } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { ITEMS_PER_PAGE } from './FontCards.constants';
import usePagination from '../../../foundation/hooks/use-pagination';
import useResponsive from '../../../foundation/hooks/use-responsive';
import { TypographyContext } from '../../contexts/Typography.context';
import { getDefaultSelectedWeights } from '../../services/font';
import FontCard from '../FontCard';

import './FontCards.scss';

const propTypes = {};
const defaultProps = {};

export default function FontCards() {
  const { allFonts, fonts, sentence, setSelectedFont, selectedFont } =
    useContext(TypographyContext);

  const { getActivePageItems, activePage, totalPages, onPageChange } = usePagination(
    fonts,
    ITEMS_PER_PAGE,
    {
      carousel: true,
    },
  );

  const { isDesktop } = useResponsive();

  if (!_isEmpty(selectedFont)) {
    return null;
  }

  if (_isEmpty(allFonts)) {
    return <Loader active />;
  }

  if (_isEmpty(fonts)) {
    return <div className="font-cards no-fonts">Can’t find any fonts.</div>;
  }

  function onCardClick({ family, variants }) {
    setSelectedFont({ family, variants, selectedWeights: getDefaultSelectedWeights(variants) });
  }

  const activeFonts = getActivePageItems();
  const itemsPerRow = isDesktop ? 3 : 2;

  return (
    <div className="font-cards">
      <Card.Group itemsPerRow={itemsPerRow}>
        {activeFonts.map(({ family, variants }) => (
          <FontCard
            key={family}
            family={family}
            variants={variants}
            sentence={sentence}
            onClick={() => onCardClick({ family, variants })}
          />
        ))}
      </Card.Group>
      <div className="controls">
        {totalPages > 1 && (
          <Pagination activePage={activePage} onPageChange={onPageChange} totalPages={totalPages} />
        )}
      </div>
    </div>
  );
}

FontCards.propTypes = propTypes;
FontCards.defaultProps = defaultProps;
