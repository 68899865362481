import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Icon, Accordion } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { getComponentSetting } from 'components/modules/component-configuration/services/component-configuration';

import ErrorHandler from '../../../../libs/errors/errors';
import { PagePropType } from '../../../modules/pages/types';
import {
  createComponentKey,
  getLegacyDisplayName,
  isImageComponentConfigurationSetting,
} from '../ImagesPage.utils';
import PageEditGalleryImages from '../PageEditGalleryImages';

import './PageItem.scss';

const propTypes = {
  page: PagePropType.isRequired,
  configurationSettings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  componentConfigurations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEditImagesClick: PropTypes.func.isRequired,
  onSortImages: PropTypes.func.isRequired,
  onPostConfigurationSave: PropTypes.func,
};

const defaultProps = {
  onPostConfigurationSave: () => {},
};

function PageItem({
  page,
  configurationSettings,
  componentConfigurations,
  onEditImagesClick,
  onSortImages,
  onPostConfigurationSave,
}) {
  const websiteVersion = useSelector((state) =>
    _get(state, 'website.core.value.workspace_version'),
  );

  const [showContent, setShowContent] = useState(true);
  const [componentToLoading, setComponentToLoading] = useState({});

  async function handleSort({ oldIndex, newIndex }, componentKey) {
    if (oldIndex !== newIndex) {
      await setComponentToLoading((state) => ({
        ...state,
        [componentKey]: true,
      }));

      try {
        await onSortImages({
          oldIndex,
          newIndex,
          componentKey,
          currentPage: page,
        });
      } catch (error) {
        ErrorHandler.captureException(error);
      } finally {
        setComponentToLoading((state) => ({ ...state, [componentKey]: false }));
      }
    }
  }

  return (
    <Accordion className="page-item">
      <Accordion.Title
        index={0}
        active={showContent}
        onClick={() => {
          setShowContent(!showContent);
        }}
        className="page-item-header"
      >
        <Icon name="dropdown" size="large" />

        <div className="title">
          {page.group_name && `${page.group_name} > `}
          {page.title}
        </div>

        <div className="separator" />
      </Accordion.Title>

      <Accordion.Content className="images-section" active={showContent}>
        {componentConfigurations.map((component) => {
          const { files, fast_id: componentName } = component;
          const componentSetting = getComponentSetting(component, configurationSettings, {
            pageType: page.type,
          });
          const {
            display_name: sectionDisplayName,
            min_files_allowed: minFilesAllowed,
            max_files_allowed: maxFilesAllowed,
            help_text: helpText,
          } = componentSetting;
          if (!isImageComponentConfigurationSetting(componentSetting) && websiteVersion === 'v_1') {
            return undefined;
          }

          const sectionName = !_isEmpty(componentSetting)
            ? sectionDisplayName || componentName
            : getLegacyDisplayName(componentName);
          const componentKey = createComponentKey(component);

          return (
            <PageEditGalleryImages
              key={sectionName}
              title={sectionName}
              titleHelpText={helpText}
              businessFiles={files}
              minFilesAllowed={minFilesAllowed}
              maxFilesAllowed={maxFilesAllowed}
              onEditImagesClick={() => onEditImagesClick(page, componentKey)}
              onSortImages={(params) => handleSort(params, componentKey)}
              loading={componentToLoading[componentKey]}
              onPostConfigurationSave={onPostConfigurationSave}
            />
          );
        })}
      </Accordion.Content>
    </Accordion>
  );
}

PageItem.propTypes = propTypes;
PageItem.defaultProps = defaultProps;

export default PageItem;
