import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Input, Button, Icon, Message } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import MaskedInput from 'react-text-mask';

import { DEFAULT_CLEANUP_MESSAGE_TIMEOUT, DEFAULT_RECIPE_NAME } from './SignUpForm.constants';
import {
  wrapFieldCallback,
  getLeadSourceOptions,
  getFormValidationMessage,
} from './SignUpForm.utils';
import SignUpFormConfirmation from './components/SignUpFormConfirmation';
import { PHONE_NUMBER_MASK } from '../../../constants/input';
import API from '../../../libs/api';
import ErrorHandler, { getErrorMessage } from '../../../libs/errors';
import {
  LeadChannelPropTypes,
  INTERNAL_SIGN_UP_LEAD_CHANEL,
} from '../../../libs/lead-source/constants';
import BusinessSearchField from '../../fields/BusinessSearchField';
import BusinessSubtypeField from '../../fields/BusinessSubtypeField';
import ColorPaletteField from '../../fields/ColorPaletteField';
import RecipeNameSelectField from '../../fields/RecipeNameSelectField';

import './SignUpForm.scss';

const propTypes = {
  leadChannel: LeadChannelPropTypes.isRequired,
  onPostSubmit: PropTypes.func,
  onPostReset: PropTypes.func,
};

const defaultProps = {
  onPostSubmit: () => null,
  onPostReset: () => null,
};

export default function SignUpForm({
  leadChannel,
  leadSource: initialLeadSource,
  onPostSubmit,
  onPostReset,
}) {
  const isInternalChannel = leadChannel === INTERNAL_SIGN_UP_LEAD_CHANEL;
  const defaultRecipeName = isInternalChannel ? DEFAULT_RECIPE_NAME : '';

  const [useManualAddress, setUseManualAddress] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [businessOwnerName, setBusinessOwnerName] = useState('');
  const [businessOwnerEmail, setBusinessOwnerEmail] = useState('');
  const [businessOwnerPhone, setBusinessOwnerPhone] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [businessSubtype, setBusinessSubtype] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [paletteId, setPaletteId] = useState('');
  const [previousWebsiteURL, setPreviousWebsiteURL] = useState('');
  const [leadSource, setLeadSource] = useState(initialLeadSource || '');
  const [referralEmail, setReferralEmail] = useState('');
  const [recipeName, setRecipeName] = useState(defaultRecipeName);
  const [placeId, setPlaceId] = useState('');
  const [lead, setLead] = useState({});

  const [sendPreviewNotification, setSendPreviewNotification] = useState(true);
  const [syncToCrm, setSyncToCrm] = useState(true);
  const [selfService, setSelfService] = useState(false);

  function resetForm() {
    setUseManualAddress(false);
    setSubmitted(false);
    setLoading(false);
    setPlaceId('');
    setBusinessOwnerName('');
    setBusinessOwnerEmail('');
    setBusinessOwnerPhone('');
    setBusinessSubtype('');
    setBusinessName('');
    setStreet('');
    setCity('');
    setState('');
    setPostalCode('');
    setPaletteId();
    setPreviousWebsiteURL('');
    setLeadSource(initialLeadSource || '');
    setReferralEmail('');
    setLead({});
    setSendPreviewNotification(true);
    setSelfService(false);

    if (onPostReset) {
      onPostReset();
    }
  }

  function onSearchResultSelected(result) {
    const { title, place_id: resultPlaceId } = result;

    setBusinessName(title.trim());
    setPlaceId(resultPlaceId);
  }

  function toggleManualAddress(isManual) {
    setUseManualAddress(isManual);

    if (isManual) {
      setPlaceId('');
    } else {
      setBusinessSubtype('');
      setBusinessName('');
      setStreet('');
      setCity('');
      setState('');
      setPostalCode('');
    }
  }

  async function onSubmitForm() {
    const message = getFormValidationMessage(
      { businessName, businessSubtype, businessOwnerPhone, placeId, businessOwnerEmail },
      useManualAddress,
    );

    if (message) {
      setErrorMessage(message);
      setTimeout(() => {
        setErrorMessage('');
      }, DEFAULT_CLEANUP_MESSAGE_TIMEOUT);
      return;
    }
    setErrorMessage('');

    let payload = {
      business_name: businessName,
      business_subtype: businessSubtype,
      business_owner: {
        owner_name: businessOwnerName,
        owner_email: businessOwnerEmail,
        owner_phone: businessOwnerPhone,
      },
      business_address: {
        street,
        city,
        state,
        postal_code: postalCode,
      },
      metadata: {
        recipe_name: recipeName,
        place_id: placeId,
        lead_source: leadSource,
        lead_channel: leadChannel,
        referral_email: referralEmail,
        palette_id: paletteId,
        previous_website_url: previousWebsiteURL,
      },
      preview_notification: sendPreviewNotification,
      sync_to_crm: syncToCrm,
      self_service: selfService,
    };

    if (!_isNil(lead.id)) {
      payload = { ...payload, lead_id: lead.id };
    }

    try {
      setLoading(true);
      await API.submitSignup(payload);
      setSubmitted(true);

      if (onPostSubmit) {
        onPostSubmit();
      }
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  if (submitted) {
    return <SignUpFormConfirmation onConfirmationAction={resetForm} />;
  }

  const displayError = !_isEmpty(errorMessage);

  return (
    <Form className="sign-up-form-container" size="large" onSubmit={onSubmitForm} loading={loading}>
      {isInternalChannel && (
        <>
          <Form.Dropdown
            required
            label="Lead Source"
            fluid
            search
            selection
            options={getLeadSourceOptions()}
            value={leadSource}
            name="leadSource"
            placeholder="Choose Lead Source"
            onChange={wrapFieldCallback((value) => {
              setLeadSource(value);
              setBusinessSubtype('');
            })}
          />
        </>
      )}
      {useManualAddress ? (
        <>
          <Form.Input
            required
            label="Business Name"
            placeholder="Name"
            value={businessName}
            icon={
              <Icon
                name="arrow alternate circle left outline"
                link
                onClick={() => toggleManualAddress(false)}
              />
            }
            onChange={wrapFieldCallback(setBusinessName)}
          />
          <Form.Input
            required
            label="Street"
            placeholder="Street"
            value={street}
            onChange={wrapFieldCallback(setStreet)}
          />
          <Form.Group>
            <Form.Input
              required
              width={6}
              label="City"
              placeholder="City"
              value={city}
              onChange={wrapFieldCallback(setCity)}
            />
            <Form.Input
              required
              width={4}
              label="State"
              placeholder="MA"
              value={state}
              onChange={wrapFieldCallback(setState)}
            />
            <Form.Input
              required
              width={6}
              label="Postal Code"
              placeholder="000000"
              value={postalCode}
              onChange={wrapFieldCallback(setPostalCode)}
            />
          </Form.Group>
          {!isInternalChannel && (
            <BusinessSubtypeField
              leadSource={leadSource}
              value={businessSubtype}
              onChange={wrapFieldCallback(setBusinessSubtype)}
            />
          )}
        </>
      ) : (
        <BusinessSearchField
          placeId={placeId}
          onResultSelected={onSearchResultSelected}
          setNoResultsFound={toggleManualAddress}
          required
          disabled={!_isEmpty(lead) && !_isEmpty(lead.business_validation_id)}
          businessSearchQuery={businessName}
          onChange={setBusinessName}
        />
      )}
      {isInternalChannel && (
        <BusinessSubtypeField
          required={useManualAddress}
          leadSource={leadSource}
          value={businessSubtype}
          onChange={wrapFieldCallback(setBusinessSubtype)}
        />
      )}
      <Form.Input
        required
        label="Business Owner Name"
        placeholder="Name"
        value={businessOwnerName}
        onChange={wrapFieldCallback(setBusinessOwnerName)}
      />
      <Form.Group widths="equal">
        <Form.Input
          label="Business Owner Email"
          placeholder="email@gmail.com"
          type="email"
          value={businessOwnerEmail}
          onChange={wrapFieldCallback(setBusinessOwnerEmail)}
        />
        <Form.Field>
          <label htmlFor="phone-number-input">Business Owner Mobile Phone</label>
          <Input id="phone-number-input">
            <MaskedInput
              mask={PHONE_NUMBER_MASK}
              value={businessOwnerPhone}
              onChange={wrapFieldCallback(setBusinessOwnerPhone)}
              guide
              type="tel"
              autoComplete="tel"
              placeholder="(123) 456-7890"
            />
          </Input>
        </Form.Field>
      </Form.Group>
      {isInternalChannel && (
        <Form.Group widths="equal">
          <Form.Input
            type="email"
            label="Referral Email"
            onChange={wrapFieldCallback(setReferralEmail)}
            value={referralEmail}
            placeholder="lead-source@business.com"
          />
          <RecipeNameSelectField
            recipeName={recipeName}
            onRecipeSelected={wrapFieldCallback(setRecipeName)}
          />
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <Form.Field>
          <ColorPaletteField
            selectedPaletteId={paletteId}
            onPaletteChange={wrapFieldCallback(setPaletteId)}
            recipeName={recipeName}
          />
        </Form.Field>
        <Form.Input
          type="url"
          label="Existing Website URL"
          placeholder="https://www.mywebsite.com"
          value={previousWebsiteURL}
          onChange={wrapFieldCallback(setPreviousWebsiteURL)}
        />
      </Form.Group>

      {isInternalChannel && (
        <div className="configuration-container">
          <Form.Checkbox
            label="Send website preview email to business owner"
            checked={sendPreviewNotification}
            onChange={wrapFieldCallback(setSendPreviewNotification)}
          />
          <Form.Checkbox
            label="Save merchant to CRM"
            checked={syncToCrm}
            onChange={wrapFieldCallback(setSyncToCrm)}
          />

          <Form.Checkbox
            label="Self-Service"
            checked={selfService}
            onChange={(event, { checked } = {}) => {
              setSelfService(checked);
              if (checked) {
                setSendPreviewNotification(false);
              }
            }}
          />
        </div>
      )}

      <Message
        error
        size="tiny"
        header={errorMessage}
        visible={displayError}
        onDismiss={() => {
          setErrorMessage('');
        }}
      />

      <Button size="big" type="submit" disabled={loading}>
        Submit
      </Button>
    </Form>
  );
}

SignUpForm.propTypes = propTypes;
SignUpForm.defaultProps = defaultProps;
