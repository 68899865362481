import React from 'react';

import { Grid, Message } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import {
  NO_ORDERS_MESSAGE_TITLE,
  NO_ORDERS_MESSAGE_DESCRIPTION,
  ERROR_FETCHING_ORDERS_MESSAGE_TITLE,
} from './OrderReports.constants';
import { getDefaultPastOrderReportFilters } from './OrderReports.utils';
import OrderReportsHeader from './components/OrderReportsHeader';
import OrderReportsSummary from './components/OrderReportsSummary';
import OrderReportsTable from './components/OrderReportsTable';
import useMerchantProducts from '../../../../../hooks/useMerchantProducts';
import { selectActiveLocation } from '../../../../../selectors/ecommerce';
import OrderReportsContainer from '../../common/OrderReportsContainer';

import './OrderReports.scss';

export default function OrderReports() {
  const { id: locationId } = useSelector(selectActiveLocation);
  const {
    activeProduct: { id: productId },
    loading,
  } = useMerchantProducts();
  const initialFilters = getDefaultPastOrderReportFilters({ productId, locationId });

  return (
    <OrderReportsContainer initialFilters={initialFilters} loadingFilters={loading}>
      {({
        orders,
        summary,
        currentPage,
        totalPages,
        filters,
        onPageChange,
        onChangeFilter,
        errorMessage,
      }) => {
        const {
          subtotal,
          taxes,
          tips,
          fulfillment_fees: fulfillmentFees,
          grand_total: grandTotal,
          refunds,
          net_total: netTotal,
        } = summary;

        function hasOrders() {
          return !loading && orders.length > 0;
        }

        function shouldDisplayNoOrdersMessage() {
          return !loading && !hasOrders() && !errorMessage;
        }

        function shouldDisplayErrorMessage() {
          return !loading && errorMessage;
        }

        function shouldDisplayReports() {
          return !shouldDisplayNoOrdersMessage() && !shouldDisplayErrorMessage();
        }

        return (
          <Grid className="past-order-reports-container">
            <OrderReportsHeader
              filters={filters}
              onChangeFilter={onChangeFilter}
              locationId={locationId}
            />
            {shouldDisplayErrorMessage() && (
              <Message error>
                <Message.Header>{ERROR_FETCHING_ORDERS_MESSAGE_TITLE}</Message.Header>
                <Message.Content>{errorMessage}</Message.Content>
              </Message>
            )}
            {shouldDisplayNoOrdersMessage() && (
              <Message>
                <Message.Header>{NO_ORDERS_MESSAGE_TITLE}</Message.Header>
                <Message.Content>{NO_ORDERS_MESSAGE_DESCRIPTION}</Message.Content>
              </Message>
            )}
            {shouldDisplayReports() && (
              <>
                <Grid.Row>
                  <OrderReportsTable
                    orders={orders}
                    onPageChange={onPageChange}
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                </Grid.Row>
                <Grid.Row>
                  <OrderReportsSummary
                    subtotal={subtotal}
                    taxes={taxes}
                    tips={tips}
                    fulfillmentFees={fulfillmentFees}
                    grandTotal={grandTotal}
                    refunds={refunds}
                    netTotal={netTotal}
                  />
                </Grid.Row>
              </>
            )}
          </Grid>
        );
      }}
    </OrderReportsContainer>
  );
}
