import React from 'react';

import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { getPagesWithImageComponentConfigurations } from './PagesList.utils';
import {
  getPageComponentConfigurationSettings,
  isImageComponentConfigurationSetting,
} from '../ImagesPage.utils';
import PageItem from '../PageItem';

const propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({})),
  configurationSettings: PropTypes.arrayOf(PropTypes.shape({})),
  onEditImagesClick: PropTypes.func.isRequired,
  onSortImages: PropTypes.func.isRequired,
  displayEditPageButton: PropTypes.bool,
  onPostConfigurationSave: PropTypes.func,
};

const defaultTypes = {
  pages: [],
  configurationSettings: [],
  displayEditPageButton: false,
  onPostConfigurationSave: () => {},
};

export default function PageList({
  pages,
  configurationSettings,
  onEditImagesClick,
  onSortImages,
  onPostConfigurationSave,
}) {
  const pagesWithComponents = getPagesWithImageComponentConfigurations(
    pages,
    configurationSettings,
  );

  if (_isEmpty(pagesWithComponents)) {
    return (
      <Message>
        <Message.Header>There are currently no configurable website pages</Message.Header>
        <p>Please reach out to support to configure website pages</p>
      </Message>
    );
  }

  return (
    <>
      {pagesWithComponents.map(({ page, componentConfigurations }) => {
        const { page_type: pageType } = page;

        const pageConfigurationSettings = getPageComponentConfigurationSettings(
          configurationSettings,
          pageType,
        );
        const imagePageConfigurationSettings = pageConfigurationSettings.filter(
          isImageComponentConfigurationSetting,
        );

        return (
          <PageItem
            key={page.id}
            page={page}
            configurationSettings={imagePageConfigurationSettings}
            componentConfigurations={componentConfigurations}
            onSortImages={onSortImages}
            onEditImagesClick={onEditImagesClick}
            onPostConfigurationSave={onPostConfigurationSave}
          />
        );
      })}
    </>
  );
}

PageItem.propTypes = propTypes;
PageItem.defaultTypes = defaultTypes;
