import React from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import './AddPhotoButton.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

function AddPhotoButton({ as, className, ...buttonProps }) {
  const finalClassName = [className, 'add-photo-button'].join(' ');

  return (
    <Button className={finalClassName} {...buttonProps}>
      <Icon name="image outline" />
      Add photo
    </Button>
  );
}

AddPhotoButton.propTypes = propTypes;
AddPhotoButton.defaultProps = defaultProps;

export default AddPhotoButton;
