import { RECIPE_NAME_HIBISCUS } from '../../../../constants/recipes';
import { TOOLBAR_VALUES } from '../../../fields/MarkdownField/MarkdownField.constants';

export const TRANQUIL_TOUCH_CURATED_HERO = 'TranquilTouch';
export const ELEGANT_ENHANCEMENTS_CURATED_HERO = 'ElegantEnhancements';
export const SOOTHING_SERENITY_CURATED_HERO = 'SoothingSerenity';
export const WIDGET_TYPE_MARKDOWN = 'Markdown';
export const WIDGET_TYPE_INPUT = 'Input';

export const CONTENT_TYPE_TEXT = 'Text';
export const CONTENT_TYPE_IMAGE = 'Image';
export const CONTENT_TYPE_CTA = 'CTA';

export const SETTING_KEY_TEXT = 'text';
export const SETTING_KEY_IMAGE = 'image';
export const SETTING_KEY_CTA = 'cta';

export const CURATED_HERO_OPTIONS_TOOLBAR = [
  TOOLBAR_VALUES.bold,
  TOOLBAR_VALUES.italic,
  TOOLBAR_VALUES.heading1,
  TOOLBAR_VALUES.heading2,
  TOOLBAR_VALUES.heading3,
  TOOLBAR_VALUES.separator,
  TOOLBAR_VALUES.quote,
  TOOLBAR_VALUES.unorderedList,
  TOOLBAR_VALUES.orderedList,
  TOOLBAR_VALUES.separator,
  TOOLBAR_VALUES.link,
  TOOLBAR_VALUES.separator,
  TOOLBAR_VALUES.preview,
  TOOLBAR_VALUES.sideBySide,
];

export const CONTENT_TYPE_SETTINGS_KEY = {
  [CONTENT_TYPE_TEXT]: SETTING_KEY_TEXT,
  [CONTENT_TYPE_IMAGE]: SETTING_KEY_IMAGE,
  [CONTENT_TYPE_CTA]: SETTING_KEY_CTA,
};

export const RECIPE_CURATED_HERO_OPTIONS = {
  [RECIPE_NAME_HIBISCUS]: [
    TRANQUIL_TOUCH_CURATED_HERO,
    ELEGANT_ENHANCEMENTS_CURATED_HERO,
    SOOTHING_SERENITY_CURATED_HERO,
  ],
};

export const CURATED_HERO_SETTINGS = {
  [TRANQUIL_TOUCH_CURATED_HERO]: {
    componentConfigurations: [
      {
        component: 'CuratedHero',
        componentId: 'hero_image',
        sectionLabel: 'Hero Image',
        contentTypes: [CONTENT_TYPE_IMAGE],
        contentSettings: {
          [SETTING_KEY_IMAGE]: {
            minFilesAllowed: 1,
            maxFilesAllowed: 6,
          },
        },
      },
      {
        component: 'CuratedHero',
        componentId: 'hero_content',
        sectionLabel: 'Hero Main Content',
        contentTypes: [CONTENT_TYPE_TEXT, CONTENT_TYPE_CTA],
        contentSettings: {
          [SETTING_KEY_TEXT]: {
            widgetType: WIDGET_TYPE_MARKDOWN,
            widgetExtraProps: {
              toolbarGenerator: () => CURATED_HERO_OPTIONS_TOOLBAR,
            },
          },
          [SETTING_KEY_CTA]: {
            maxCtasAllowed: 1,
          },
        },
      },
    ],
  },
  [ELEGANT_ENHANCEMENTS_CURATED_HERO]: {
    componentConfigurations: [
      {
        component: 'CuratedHero',
        componentId: 'hero_image',
        sectionLabel: 'Hero Images',
        contentTypes: [CONTENT_TYPE_IMAGE],
        contentSettings: {
          [SETTING_KEY_IMAGE]: {
            minFilesAllowed: 1,
            maxFilesAllowed: 6,
          },
        },
      },
      {
        component: 'CuratedHero',
        componentId: 'hero_content',
        sectionLabel: 'Hero Main Content',
        contentTypes: [CONTENT_TYPE_TEXT, CONTENT_TYPE_CTA],
        contentSettings: {
          [SETTING_KEY_TEXT]: {
            widgetType: WIDGET_TYPE_MARKDOWN,
            widgetExtraProps: {
              toolbarGenerator: () => CURATED_HERO_OPTIONS_TOOLBAR,
            },
          },
          [SETTING_KEY_CTA]: {
            maxCtasAllowed: 1,
          },
        },
      },
    ],
  },
  [SOOTHING_SERENITY_CURATED_HERO]: {
    componentConfigurations: [
      {
        component: 'CuratedHero',
        componentId: 'hero_image',
        sectionLabel: 'Hero Image',
        contentTypes: [CONTENT_TYPE_IMAGE],
        contentSettings: {
          [SETTING_KEY_IMAGE]: {
            minFilesAllowed: 1,
            maxFilesAllowed: 1,
          },
        },
      },
      {
        component: 'CuratedHero',
        componentId: 'hero_content',
        sectionLabel: 'Hero Main Content',
        contentTypes: [CONTENT_TYPE_TEXT, CONTENT_TYPE_CTA],
        contentSettings: {
          [SETTING_KEY_TEXT]: {
            widgetType: WIDGET_TYPE_MARKDOWN,
            widgetExtraProps: {
              toolbarGenerator: () => CURATED_HERO_OPTIONS_TOOLBAR,
            },
          },
          [SETTING_KEY_CTA]: {
            maxCtasAllowed: 1,
          },
        },
      },
      {
        component: 'CuratedHero',
        componentId: 'hero_featured_content',
        sectionLabel: 'Hero Featured Content',
        contentTypes: [CONTENT_TYPE_TEXT],
        contentSettings: {
          [SETTING_KEY_TEXT]: {
            widgetType: WIDGET_TYPE_MARKDOWN,
            widgetExtraProps: {
              toolbarGenerator: () => CURATED_HERO_OPTIONS_TOOLBAR,
            },
          },
        },
      },
    ],
  },
};
