import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import './CustomPageTemplateForm.scss';

const propTypes = {
  title: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  title: '',
  className: '',
};

function CustomPageTemplateForm({ title, onUpdate, className }) {
  return (
    <Form className={className}>
      <Form.Input
        label="Page Name"
        name="title"
        placeholder="About"
        value={title}
        onChange={(e) => onUpdate('title', e.target.value)}
      />
    </Form>
  );
}

CustomPageTemplateForm.propTypes = propTypes;
CustomPageTemplateForm.defaultProps = defaultProps;

export default CustomPageTemplateForm;
