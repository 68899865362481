import React from 'react';

import PropTypes from 'prop-types';
import { Form, FormGroup } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { isAdminUser } from 'libs/auth';
import { selectUser } from 'selectors/user';

import {
  ASPECT_RATIO_TYPES,
  CROP_TYPES,
  DEFAULT_MAX_HEIGHT,
  DEFAULT_MAX_WIDTH,
  DESKTOP_DEVICE,
  FILL_TYPES,
  MASK_TYPES,
  MODE_TYPES,
  MODE_TYPE_CROP,
  MODE_TYPE_FILL,
} from './ImageQueryParamBuilder.constants';
import { ImageQueryParamBuilderFormDataPropType } from './ImageQueryParamBuilder.types';
import { getOptions } from './ImageQueryParamBuilder.utils';
import QueryParamsDisplay from './components/QueryParamsDisplay';

import './ImageQueryParamBuilder.scss';

const propTypes = {
  formData: ImageQueryParamBuilderFormDataPropType,
  onChange: PropTypes.func,
};

const defaultProps = {
  formData: {},
  onChange: () => {},
};

export default function ImageQueryParamBuilder({ formData, onChange: onChangeProp }) {
  const user = useSelector(selectUser);
  const isAdmin = isAdminUser(user);

  const activeDevice = DESKTOP_DEVICE;

  const activeFormData = _get(formData, activeDevice, {});
  const {
    width,
    height,
    mode = '',
    fillType = '',
    cropType = '',
    mask = '',
    aspectRatio = '',
  } = activeFormData;

  function onChange(e, oldTarget) {
    const { name, value } = oldTarget;

    const newFormData = { ...formData, [activeDevice]: { ...activeFormData, [name]: value } };
    const newTarget = { ...oldTarget, value: newFormData };
    onChangeProp({ ...e, target: newTarget }, newTarget);
  }

  function onWidthChange(e, target) {
    const { value } = target;
    let newValue = value;

    if (+newValue > +DEFAULT_MAX_WIDTH) {
      newValue = `${DEFAULT_MAX_WIDTH}`;
    }
    if (+newValue < 0) {
      newValue = '1';
    }

    const newFormData = { ...formData, [activeDevice]: { ...activeFormData, width: newValue } };
    const newTarget = { ...target, value: newFormData };
    onChangeProp({ ...e, target: newTarget }, newTarget);
  }

  function onHeightChange(e, target) {
    const { value } = target;
    let newValue = value;

    if (+newValue > +DEFAULT_MAX_HEIGHT) {
      newValue = `${DEFAULT_MAX_HEIGHT}`;
    }
    if (+newValue < 0) {
      newValue = '1';
    }

    const newFormData = { ...formData, [activeDevice]: { ...activeFormData, height: newValue } };
    const newTarget = { ...target, value: newFormData };
    onChangeProp({ ...e, target: newTarget }, newTarget);
  }

  function onModeChange(e, target) {
    const { value } = target;
    let extraData = {};

    if (value !== MODE_TYPE_FILL) {
      extraData = { ...extraData, fillType: '' };
    }
    if (value !== MODE_TYPE_CROP) {
      extraData = { ...extraData, cropType: '', aspectRatio: '' };
    }

    const newFormData = {
      ...formData,
      [activeDevice]: { ...activeFormData, ...extraData, mode: value },
    };
    const newTarget = { ...target, value: newFormData };
    onChangeProp({ ...e, target: newTarget }, newTarget);
  }

  function onAspectRatioChange(e, target) {
    const { value } = target;
    const extraData = { height: '' };

    const newFormData = {
      ...formData,
      [activeDevice]: { ...activeFormData, ...extraData, aspectRatio: value },
    };

    const newTarget = { ...target, value: newFormData };
    onChangeProp({ ...e, target: newTarget }, newTarget);
  }

  const modeOptions = getOptions(MODE_TYPES);
  const fillTypeOptions = getOptions(FILL_TYPES, { allowEmpty: true });
  const cropTypeOptions = getOptions(CROP_TYPES, { allowEmpty: true });
  const maskTypeOptions = getOptions(MASK_TYPES);
  const aspectRatioOptions = getOptions(ASPECT_RATIO_TYPES);

  return (
    <Form className="image-query-param-builder">
      <FormGroup widths="equal">
        <Form.Input
          label="Width"
          type="number"
          name="width"
          value={width}
          onChange={onWidthChange}
          min={1}
          max={DEFAULT_MAX_WIDTH}
        />
        <Form.Input
          label="Height"
          type="number"
          name="height"
          value={height}
          onChange={onHeightChange}
          min={1}
          max={DEFAULT_MAX_HEIGHT}
          disabled={!_isEmpty(aspectRatio)}
        />
      </FormGroup>

      <FormGroup className="mode" widths="equal">
        <Form.Select
          label="Mode"
          name="mode"
          value={mode}
          options={modeOptions}
          onChange={onModeChange}
          fluid
        />
        {MODE_TYPES.includes(MODE_TYPE_FILL) && (
          <Form.Select
            label="Fill Type"
            name="fillType"
            value={fillType}
            options={fillTypeOptions}
            onChange={onChange}
            disabled={mode !== MODE_TYPE_FILL}
            fluid
          />
        )}
        <Form.Select
          label="Crop Type"
          name="cropType"
          value={cropType}
          options={cropTypeOptions}
          onChange={onChange}
          disabled={mode !== MODE_TYPE_CROP}
          fluid
        />
      </FormGroup>

      <FormGroup widths="equal">
        <Form.Select
          label="Mask"
          name="mask"
          value={mask}
          options={maskTypeOptions}
          onChange={onChange}
        />
        <Form.Select
          label="Aspect Ratio"
          name="aspectRatio"
          value={aspectRatio}
          options={aspectRatioOptions}
          onChange={onAspectRatioChange}
          disabled={mode !== MODE_TYPE_CROP}
        />
      </FormGroup>

      {isAdmin && (
        <FormGroup widths="equal">
          <QueryParamsDisplay formData={formData} />
        </FormGroup>
      )}
    </Form>
  );
}

ImageQueryParamBuilder.propTypes = propTypes;
ImageQueryParamBuilder.defaultProps = defaultProps;
