import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import ErrorHandler from '../../../../../../../../../libs/errors/errors';
import ConfirmModal from '../../../../../../../../common/ConfirmModal';
import { TeamMemberServiceVariationPropType } from '../../../../../types/team-member-service';

import './DeleteTeamMemberServiceButton.scss';

const propTypes = {
  teamMemberService: TeamMemberServiceVariationPropType.isRequired,
  onDelete: PropTypes.func,
};

const defaultProps = {
  onDelete: () => null,
};

export default function DeleteTeamMemberServiceButton({
  teamMemberService,
  onDelete: onDeleteProp,
}) {
  const [loading, setLoading] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  function showConfirmModal() {
    setShowDeleteConfirmModal(true);
  }

  function hideConfirmModal() {
    setShowDeleteConfirmModal(false);
  }

  async function onDeleteConfirm() {
    try {
      setLoading(true);
      if (onDeleteProp) {
        await onDeleteProp(teamMemberService);
      }
      setShowDeleteConfirmModal(false);
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ConfirmModal
        open={showDeleteConfirmModal}
        onClose={hideConfirmModal}
        header="Delete Team Member Service"
        content="Are you sure you want to remove team this service from the team member?"
        onCancel={hideConfirmModal}
        onConfirm={onDeleteConfirm}
        confirmButton={
          <Button
            className="delete-red"
            content="Yes"
            disabled={loading}
            loading={loading}
            primary={false}
          />
        }
        cancelButton={<Button className="action-button-gray" content="Cancel" disabled={loading} />}
      />
      <Button
        className="delete-team-member-service-button"
        icon
        onClick={showConfirmModal}
        loading={loading}
        disabled={loading}
      >
        <Icon name="trash" />
      </Button>
    </>
  );
}

DeleteTeamMemberServiceButton.propTypes = propTypes;
DeleteTeamMemberServiceButton.defaultProps = defaultProps;
