import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import _get from 'lodash/get';

import { selectEntityId } from '../../../../../../../libs/slices';
import MarkdownField from '../../../../../../fields/MarkdownField';
import { GenericComponentConfigurationPropType } from '../../../../../../modules/component-configuration/proptypes';
import { CuratedHeroContext } from '../../../../../../modules/custom-pages/contexts/CuratedHero.context';
import {
  WIDGET_TYPE_INPUT,
  WIDGET_TYPE_MARKDOWN,
} from '../../../../../../modules/hero/constants/curated-hero';

const propTypes = {
  componentConfiguration: GenericComponentConfigurationPropType.isRequired,
  settings: PropTypes.shape({
    widgetType: PropTypes.string,
  }),
};
const defaultProps = {
  settings: { widgetType: WIDGET_TYPE_INPUT },
};

export default function CuratedHeroTextInput({ componentConfiguration, settings }) {
  const { updateComponentConfiguration } = useContext(CuratedHeroContext);
  const { widgetType = WIDGET_TYPE_INPUT, widgetExtraProps = {} } = settings;
  const contentValue = _get(componentConfiguration, 'contentNodes[0].content');

  function onUpdate(textValue) {
    const componentConfigurationId = selectEntityId(componentConfiguration);
    updateComponentConfiguration(componentConfigurationId, 'contentNodes[0].content', textValue);
  }

  const formComponent =
    widgetType === WIDGET_TYPE_MARKDOWN ? (
      <MarkdownField onChange={onUpdate} value={contentValue} {...widgetExtraProps} />
    ) : (
      <Form.Input value={contentValue} onChange={onUpdate} />
    );
  return <div className="form-item">{formComponent}</div>;
}

CuratedHeroTextInput.propTypes = propTypes;
CuratedHeroTextInput.defaultProps = defaultProps;
