/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

const stateUtils = {
  types: {
    LIST: 'LIST',
    OBJECT: 'OBJECT',
  },

  defaults: {
    _INTERNAL: { _touchedFields: [] },
    _NEW_ITEMS_IDS: { _newItemIds: [] },
    _DELETED_ITEM_IDS: { _deletedItemIds: [] },
    BUSINESS_TYPES: { type: '', subtypes: [] },
    SOCIAL_MEDIA: { type: '', link: '' },
    FORM_FIELD: { label: '', placeholder: '', required: false, type: '' },
    IMAGE: { url: '', source: '', file: null },
    FILE: { url: '', file: null, filename: '', description: '' },
    CONTACT_FORM: {
      title: '',
      description: '',
      fields: [],
    },
    COLOR_PALETTE: {
      id: null,
    },
    TEAM_MEMBER: {
      name: '',
      role: '',
      description: '',
      image: { url: '', source: '', file: null },
    },
    PAGE: {
      title: '',
      description: '',
      slug: '',
      page_type: '',
    },
    STYLE_RULE: {
      name: '',
      value: '',
      override: false,
      element_type: 'global',
    },
    LOCATION: {
      location_name: '',
      phone_number: '',
      email: '',
      street: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
      primary_location: false,
      legal_location: false,
      timezone: 'UTC',
      verified_address: false,
    },
    TAX: {
      tax_rate_percent: '0.00',
      tax_label: '',
    },
    PROVIDER_SERVICE: {
      id: '',
      provider: '',
      service_type: '',
    },
    PROVIDER_SERVICE_SETTING: {
      provider: '',
      service_type: '',
      service_data: {},
      status_data: {},
      status: '',
    },
    SUBSCRIPTION_ITEM: {
      id: '',
    },
    HOURS_OF_OPERATION: { day: null, open: '', close: '' },
    ONLINE_ORDER: { link: '' },
    RESERVATIONS: { link: '' },
    CATERING: { link: '' },
    EVENT: { title: '', description: '', start: '', end: '', image: '' },
    SPECIAL: { title: '', description: '', start: '', end: '' },
    REVIEW: { author: '', text: '', source: 'CUSTOM', to_display: true },
    PROP: { component_name: '', prop_name: '', prop_value: '' },
    MENU: {
      __saved: false,
      schedules: [],
      categories: [],
      items: [],
      modifier_sets: [],
      modifiers: [],
    },
    MENU_SCHEDULE: {
      name: '',
      available_days: ['ALL'],
      start_time: null,
      end_time: null,
      categories: [],
    },
    MENU_CATEGORY: {
      name: '',
      description: '',
      items: [],
    },
    MENU_ITEM: {
      name: '',
      description: '',
      price: '',
      image: { url: '', source: '', file: null },
      modifier_sets: [],
      variations: [{ name: 'Regular', price: 0, price_display: '' }],
    },
    MENU_ITEM_MODIFIER_SET: {
      name: '',
      description: '',
      min_allowed: 0,
      max_allowed: 0,
      modifiers: [],
    },
    MENU_ITEM_MODIFIER: {
      name: '',
      description: '',
      min_allowed: null,
      max_allowed: null,
      price: 0,
      price_display: '',
    },
  },

  /* INTERNAL RECORD KEEPING */
  touchObject(obj, field) {
    const { _touchedFields } = obj;
    return {
      ...obj,
      _touchedFields: Array.from(new Set(_touchedFields).add(field)),
    };
  },

  _shiftListAdditions(additions, index) {
    return additions.map((a) => (a > index ? a - 1 : a));
  },

  recordListAddition(obj, index) {
    const { _newItemIds } = obj;
    return {
      ...obj,
      _newItemIds: Array.from(new Set(_newItemIds).add(index)),
    };
  },

  recordListDeletion(obj, index) {
    const { _newItemIds, _deletedItemIds } = obj;
    if (obj.value[index] && Object.keys(obj.value[index]).includes('id')) {
      const itemRemoteId = obj.value[index].id;
      return {
        ...obj,
        _deletedItemIds: Array.from(new Set(_deletedItemIds).add(itemRemoteId)),
        _newItemIds: stateUtils._shiftListAdditions(_newItemIds, index),
      };
    }
    if (_newItemIds.includes(index)) {
      return {
        ...obj,
        _newItemIds: stateUtils._shiftListAdditions(
          _newItemIds.filter((id) => id !== index),
          index,
        ),
      };
    }
    return obj;
  },

  /* OBJECTS */
  createDefaultObject(type, defaultValue = null, loaded = false) {
    if (type === stateUtils.types.LIST) {
      return {
        _loaded: loaded,
        _type: type,
        value: defaultValue || [],
        ...stateUtils.defaults._NEW_ITEMS_IDS,
        ...stateUtils.defaults._DELETED_ITEM_IDS,
      };
    }

    return {
      _loaded: loaded,
      _type: type,
      value: defaultValue || {},
      ...stateUtils.defaults._INTERNAL,
    };
  },

  createDefaultType(type, additionalValues = {}) {
    return {
      ...stateUtils.defaults._INTERNAL,
      ..._.cloneDeep(stateUtils.defaults[type]),
      ...additionalValues,
    };
  },

  flagAsLoaded(keyPath, obj) {
    return stateUtils.updateObject(obj, [...keyPath, '_loaded'], true);
  },

  _updateObject(obj, keyPath, value) {
    const key = keyPath[0];
    const keyObj = obj[key];
    const newKeyPath = keyPath.slice(1);

    if (newKeyPath.length === 0) return { ...obj, [key]: value };

    return {
      ...obj,
      [key]: { ...stateUtils._updateObject(keyObj, newKeyPath, value) },
    };
  },

  updateObject(obj, keyPath, value, touchDepth = 0) {
    if (touchDepth === 0) return stateUtils._updateObject(obj, keyPath, value);
    const updatedObject = stateUtils._updateObject(obj, keyPath, value);
    const touchKeyPath = keyPath.slice(0, touchDepth);
    const keyToTouch = keyPath[keyPath.length - 1];
    const objToTouch = stateUtils.getObject(updatedObject, touchKeyPath);
    const touchedObj = stateUtils.touchObject(objToTouch, keyToTouch);
    return stateUtils._updateObject(updatedObject, touchKeyPath, touchedObj);
  },

  getObject(state, keyPath) {
    return keyPath.reduce((acc, key) => acc[key], state);
  },

  /* LISTS */
  initializeList(state, data, key, defaultType, startEmpty = false) {
    let list;
    if (data.length > 0) {
      list = stateUtils.createList(data);
    } else {
      list = startEmpty ? [] : [stateUtils.createDefaultType(defaultType)];
    }
    const isBlank = data.length === 0;

    const withData = stateUtils.flagAsLoaded(
      [key],
      stateUtils.updateObject(state, [key, 'value'], list),
    );

    if (isBlank)
      return {
        ...withData,
        [key]: {
          ...withData[key],
          _newItemIds: [0],
        },
      };
    return withData;
  },

  createList(items, preferExistingFields = false) {
    if (preferExistingFields)
      return items.map((item) => ({
        ...stateUtils.defaults._INTERNAL,
        ...item,
      }));
    return items.map((item) => ({ ...item, ...stateUtils.defaults._INTERNAL }));
  },

  replaceListItem(arr, index, item) {
    return [...arr.slice(0, index), item, ...arr.slice(index + 1, arr.length)];
  },

  updateListItemFields(fields, index, arr, bypassTouch = false) {
    if (Number.isNaN(index) || index < 0) return arr;

    const keys = Object.keys(fields);
    let newItem = { ...arr[index], ...fields };

    keys.forEach((key) => {
      if (!bypassTouch) {
        newItem = stateUtils.touchObject(newItem, key);
      }
    });
    return [...arr.slice(0, index), newItem, ...arr.slice(index + 1, arr.length)];
  },

  getItemIndex(arr, field, value) {
    return _.findIndex(arr, (item) => item[field] === value);
  },

  removeFromList(item, arr) {
    let newList = arr;

    if (arr.includes(item)) {
      const indexOfItem = arr.indexOf(item);
      newList = [...arr.slice(0, indexOfItem), ...arr.slice(indexOfItem + 1, arr.length)];
    }

    return newList;
  },

  removeFromListByIndex(arr, index) {
    return [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];
  },

  insertIntoList(arr, index, item) {
    if (index === 0) return [item, ...arr];
    if (index === arr.length) return [...arr, item];
    return [...arr.slice(0, index), item, ...arr.slice(index, arr.length)];
  },

  addOrRemoveFromList(item, arr) {
    let newList = [];

    if (arr.includes(item)) {
      const indexOfItem = arr.indexOf(item);
      newList = [...arr.slice(0, indexOfItem), ...arr.slice(indexOfItem + 1, arr.length)];
    } else {
      newList = [...arr, item];
    }

    return newList;
  },

  addItem(state, keyPath, item, ignoreTouch = false) {
    const newList = [...stateUtils.getObject(state, keyPath), item];

    if (!ignoreTouch) {
      const updatedObj = stateUtils._updateObject(state, keyPath, newList);
      const touchKeyPath = keyPath.slice(0, keyPath.length - 1);
      const objToTouch = stateUtils.getObject(updatedObj, touchKeyPath);
      const touchedObj = stateUtils.recordListAddition(objToTouch, newList.length - 1);
      return stateUtils._updateObject(updatedObj, touchKeyPath, touchedObj);
    }
    return stateUtils._updateObject(state, keyPath, newList);
  },

  insertItem(state, keyPath, item, index, ignoreTouch = false) {
    const newList = this.insertIntoList([...stateUtils.getObject(state, keyPath)], index, item);

    if (!ignoreTouch) {
      const updatedObj = stateUtils._updateObject(state, keyPath, newList);
      const touchKeyPath = keyPath.slice(0, keyPath.length - 1);
      const objToTouch = stateUtils.getObject(updatedObj, touchKeyPath);
      const touchedObj = stateUtils.recordListAddition(objToTouch, index + 1);
      return stateUtils._updateObject(updatedObj, touchKeyPath, touchedObj);
    }
    return stateUtils._updateObject(state, keyPath, newList);
  },

  deleteItem(state, keyPath, index, ignoreTouch = false) {
    if (!ignoreTouch) {
      const touchKeyPath = keyPath.slice(0, keyPath.length - 1);
      const objToTouch = stateUtils.getObject(state, touchKeyPath);
      const touchedObj = stateUtils.recordListDeletion(objToTouch, index);
      const touchedState = stateUtils._updateObject(state, touchKeyPath, touchedObj);
      const newList = stateUtils.removeFromListByIndex(
        stateUtils.getObject(touchedState, keyPath),
        index,
      );
      return stateUtils._updateObject(touchedState, keyPath, newList);
    }

    const newList = stateUtils.removeFromListByIndex(stateUtils.getObject(state, keyPath), index);
    return stateUtils._updateObject(state, keyPath, newList);
  },

  updateListItem(state, keyPath, index, fields, bypassTouch = false) {
    return stateUtils.updateObject(
      state,
      keyPath,
      stateUtils.updateListItemFields(
        fields,
        index,
        stateUtils.getObject(state, keyPath),
        bypassTouch,
      ),
    );
  },

  formatTimezones(state, { timezones }) {
    return {
      ...state,
      timezones: {
        ...state.timezones,
        value: timezones,
      },
    };
  },

  formatProps(props) {
    const requiredProps = ['header', 'subheader', 'fields'];
    const existingProps = props.filter(
      (prop) => requiredProps.includes(prop.prop_name) && prop.component_name === 'ContactForm',
    );
    const existingPropsSet = new Set(existingProps.map(({ prop_name: propName }) => propName));
    const requirePropsSet = new Set(requiredProps);
    const neededProps = [...new Set([...requirePropsSet].filter((x) => !existingPropsSet.has(x)))];
    const formattedProps = [
      ...neededProps.map((name) => ({
        ...stateUtils.createDefaultType('PROP'),
        prop_name: name,
        prop_value: name === 'fields' ? JSON.stringify([stateUtils.defaults.FORM_FIELD]) : '',
        component_name: 'ContactForm',
      })),
      ...props,
    ];
    return [_.range(neededProps.length), formattedProps];
  },

  formatMenu(state, menu) {
    return stateUtils.flagAsLoaded(
      ['menu'],
      stateUtils.updateObject(state, ['menu', 'value'], menu),
    );
  },
};

export default stateUtils;
