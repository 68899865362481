import React from 'react';

import { Button, Input, Icon, List } from 'semantic-ui-react';

import _isNaN from 'lodash/isNaN';
import _toNumber from 'lodash/toNumber';

import './OrderDiscounts.scss';

function OrderDiscounts({ discounts, editDiscount, addDiscount, removeDiscount }) {
  function handleNumberChange(index, { name, value }) {
    const parsedNumber = _toNumber(value);
    if (_isNaN(parsedNumber)) return;
    editDiscount(index, { [name]: value });
  }

  return (
    <div className="order-discounts">
      <List>
        {discounts.map((discount, index) => (
          <List.Item key={discount.key} className="order-discount-item">
            Orders greater than
            <Input
              className="number-input price"
              onChange={(e, { name, value }) => handleNumberChange(index, { name, value })}
              value={discount.order_minimum}
              name="order_minimum"
            />
            get a
            <Input
              className="number-input percentage"
              onChange={(e, { name, value }) => handleNumberChange(index, { name, value })}
              value={discount.amount_percentage}
              name="amount_percentage"
            />
            shipping discount
            <Icon
              className="remove-discount"
              name="trash alternate outline"
              link
              onClick={() => removeDiscount(index)}
            />
          </List.Item>
        ))}
      </List>
      <Button className="add-discount" onClick={() => addDiscount()}>
        <span className="plus-sign">+</span>
        <span>Add new discount</span>
      </Button>
    </div>
  );
}

export default OrderDiscounts;
