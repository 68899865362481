import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { DEFAULT_WIDTH, MAX_ROWS, MAX_TILES_PER_ROW, MAX_WIDTH } from '../constants/tile';
import {
  TILE_CONTENT_NAME,
  SUBHEADER_CONTENT_NAME,
} from '../hooks/use-custom-hero-manager/services/constants';

export function getTileRowNumber(tileIndex, numberOfTilesPerRow) {
  return Math.floor(tileIndex / numberOfTilesPerRow);
}

export function getTotalNumberOfRows(numberOfTiles, numberOfTilesPerRow) {
  return Math.ceil(numberOfTiles / numberOfTilesPerRow);
}

export function getTilesForRow(tiles, rowNumber, numberOfTilesPerRow) {
  const startIndex = rowNumber * numberOfTilesPerRow;
  const endIndex = startIndex + numberOfTilesPerRow;

  return tiles.slice(startIndex, endIndex);
}

export function getTileIndex(rowIndex, columnIndex, tilesPerRow) {
  return rowIndex * tilesPerRow + columnIndex;
}

export function isValidHeroTileLayout(numberOfTiles, numberOfTilesPerRow) {
  if (_isNil(numberOfTiles) || _isNil(numberOfTilesPerRow)) {
    return false;
  }

  if (numberOfTilesPerRow === 0 || numberOfTiles === 0) {
    return false;
  }

  const numberOfRows = getTotalNumberOfRows(numberOfTiles, numberOfTilesPerRow);
  return (
    numberOfTiles % numberOfTilesPerRow === 0 &&
    numberOfRows <= MAX_ROWS &&
    numberOfTilesPerRow <= MAX_TILES_PER_ROW
  );
}

export function getContentIndexByContentName(contentNodes = [], contentName = TILE_CONTENT_NAME) {
  return contentNodes.findIndex(({ contentName: currentName }) => currentName === contentName);
}

export function getTileWidth(tile) {
  return _get(tile, 'componentConfiguration.data.width', DEFAULT_WIDTH);
}

export function getTilesWidths(tiles) {
  return tiles.map(getTileWidth);
}

export function getTileId(tile) {
  return (
    _get(tile, 'componentConfiguration.componentConfigurationId') ||
    _get(tile, 'componentConfiguration.componentIdentifier') ||
    _get(tile, 'componentConfiguration.order')
  );
}

export function getTileShowOnHover(tile) {
  return _get(tile, 'componentConfiguration.data.showOnHover', false);
}

export function getTileClassName(tile) {
  return _get(tile, 'componentConfiguration.data.className', '');
}

export function getTileInteractions(tile) {
  return _get(tile, 'interactions', []);
}

export function getTileImages(tile) {
  return _get(tile, 'images', []);
}

export function getTileContent(tile) {
  const { contentNodes = [] } = tile;

  const tileContentIndex = getContentIndexByContentName(contentNodes, TILE_CONTENT_NAME);

  return _get(contentNodes, `[${tileContentIndex}]`, {});
}

export function getTileSubheader(tile) {
  const { contentNodes = [] } = tile;

  const subheaderIndex = getContentIndexByContentName(contentNodes, SUBHEADER_CONTENT_NAME);

  return _get(contentNodes, `[${subheaderIndex}]`, {});
}

export function getWidthForAdjacentTile(firstTileWidth) {
  return MAX_WIDTH - firstTileWidth;
}

export function calculateTilesPerRow(tiles = []) {
  if (tiles.length === 0) {
    return 1;
  }
  const widthSum = tiles.reduce(
    (sum, tile) => sum + _get(tile, 'componentConfiguration.data.width', 16),
    0,
  );
  return widthSum / tiles.length >= 16 ? 1 : 2;
}
