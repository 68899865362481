import _includes from 'lodash/includes';

import {
  selectActiveRecipeName,
  selectActiveWebsitePatch,
  selectWebsitePatches,
} from '../../../../../selectors/website';
import useDebounceSelector from '../use-debounce-selector';

function useRecipe({ debouncePatches = true } = {}) {
  const patches = useDebounceSelector(selectWebsitePatches, { disabled: !debouncePatches });
  const activePatch = selectActiveWebsitePatch(patches);
  const activeRecipeName = selectActiveRecipeName(patches);

  function websiteUsesRecipe(recipeNames) {
    return _includes(recipeNames, activeRecipeName);
  }

  return {
    websiteUsesRecipe,
    allPatches: patches,
    activePatch,
    activeRecipeName,
  };
}

export default useRecipe;
