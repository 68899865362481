import React, { useContext } from 'react';

import { Form, Label, Input } from 'semantic-ui-react';

import { millisecondsToTimingInput } from './AnnouncementTiming.utils';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

import './AnnouncementTiming.scss';

export default function AnnouncementTiming() {
  const {
    delay: delayMilliseconds,
    duration: durationMilliseconds,
    setValue,
  } = useContext(AnnouncementFormContext);

  const delay = millisecondsToTimingInput(delayMilliseconds);
  const duration = millisecondsToTimingInput(durationMilliseconds);

  function setValueInMilliseconds(fieldName, valueInSeconds) {
    const valueInMilliseconds = valueInSeconds === 0 ? null : valueInSeconds * 1000;
    setValue(fieldName, valueInMilliseconds);
  }

  return (
    <Form.Group className="announcement-timing" widths="equal">
      <Form.Input label="Message Delay">
        <Label basic pointing="right" style={{ textAlign: 'center' }} width={4}>
          {delay === 0 ? <>Delay Disabled</> : <>{delay} Seconds</>}
        </Label>
        <Input
          min={0}
          max={30}
          width={12}
          value={delay}
          onChange={(e, { value }) => setValueInMilliseconds('delay', parseInt(value, 10))}
          type="range"
        />
      </Form.Input>
      <Form.Input label="Message Duration">
        <Label basic pointing="right" style={{ textAlign: 'center' }} width={4}>
          {duration === 0 ? <>Duration Disabled</> : <>{duration} Seconds</>}
        </Label>
        <Input
          min={0}
          max={60}
          width={12}
          value={duration}
          onChange={(e, { value }) => setValueInMilliseconds('duration', parseInt(value, 10))}
          type="range"
        />
      </Form.Input>
    </Form.Group>
  );
}
