import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { CONSTRUCT_MESSAGE_PROP_VALUE } from './constants';
import {
  createEmailField,
  createEmailFieldState,
  createFileUploadFieldState,
  createFileUploadFile,
  createLongTextField,
  createLongTextFieldState,
  createMultiSelectFieldState,
  createMultipleSelectField,
  createPhoneNumberField,
  createPhoneNumberFieldState,
  createShortTextField,
  createSingleSelectField,
  createSingleSelectFieldState,
  createTextFieldState,
  transformNewFieldIntoLegacy,
} from './fields';
import { selectBusinessId, selectBusinessType } from '../../../../../../../selectors';
import { selectBusinessInfoId } from '../../../../../../../selectors/business';
import { selectPrimaryLocation } from '../../../../../../../selectors/locations';
import { selectWebsiteId } from '../../../../../../../selectors/website';
import {
  INTERNAL_FORM_FIELD_TYPES,
  RECIPE_FORM_FIELD_TYPES,
  FIELD_WIDGET_TO_TYPE,
  INPUT_TYPE_TO_RECIPE_FIELD_TYPE,
} from '../../../../constants/form';
import {
  FORM_BLOCK_COMPONENT_NAME,
  FORM_BLOCK_ELEMENT_TYPE,
  FORM_BLOCK_FAST_COMPONENT_NAME,
  LEGACY_FORM_BLOCK_COMPONENT_NAME,
  LEGACY_FORM_BLOCK_ELEMENT_TYPE,
} from '../../../../constants/types';
import { createFishermanComponent, getComponentId } from '../utils/components';
import {
  addStaticPropIfDefined,
  addCollectionPropIfDefined,
  extractComponentBaseProps,
  getCollectionPropValue,
  createComponentBaseProps,
  getStaticPropValue,
  addComponentConfigurationDataGraphQlQuery,
} from '../utils/props';
import { getPageComponent, getQueryArgValue } from '../utils/queries';

export function createFieldElement(field = {}) {
  const { type } = field;

  const GENERATOR_BY_TYPE = {
    [INTERNAL_FORM_FIELD_TYPES.ShortText]: createShortTextField,
    [INTERNAL_FORM_FIELD_TYPES.LongText]: createLongTextField,
    [INTERNAL_FORM_FIELD_TYPES.SingleChoice]: createSingleSelectField,
    [INTERNAL_FORM_FIELD_TYPES.MultipleChoice]: createMultipleSelectField,
    [INTERNAL_FORM_FIELD_TYPES.Email]: createEmailField,
    [INTERNAL_FORM_FIELD_TYPES.PhoneNumber]: createPhoneNumberField,
    [INTERNAL_FORM_FIELD_TYPES.FileUpload]: createFileUploadFile,
  };

  const generatorFunction = GENERATOR_BY_TYPE[type] || (() => null);
  return generatorFunction(field);
}

export function createLegacyFormBlockElement(data = {}) {
  const { id, className, style, businessId, businessType, locationId, title, fields = [] } = data;

  let element = createFishermanComponent(
    LEGACY_FORM_BLOCK_COMPONENT_NAME,
    id,
    createComponentBaseProps(className, style),
  );

  const fieldElements = (fields || []).map(createFieldElement).filter(Boolean);

  element = addStaticPropIfDefined(element, 'businessId', businessId);
  element = addStaticPropIfDefined(element, 'businessType', businessType);
  element = addStaticPropIfDefined(element, 'locationId', locationId);
  element = addStaticPropIfDefined(element, 'subheader', title);
  element = addStaticPropIfDefined(element, 'showLabels', true);
  element = addStaticPropIfDefined(element, 'emailSubject', title);
  element = addStaticPropIfDefined(element, 'constructMessage', CONSTRUCT_MESSAGE_PROP_VALUE);
  element = addCollectionPropIfDefined(element, 'fields', fieldElements);

  return element;
}

export function createFormBlockElement(data = {}) {
  const {
    id,
    className,
    style,
    businessId,
    businessInfoId,
    websiteId,
    locationId,
    componentConfigurationId,
    componentName,
    componentIdentifier,
    additionalProps,
  } = data;

  let element = createFishermanComponent(
    FORM_BLOCK_FAST_COMPONENT_NAME,
    id,
    createComponentBaseProps(className, style),
  );

  function addFormConfigurationField(fieldName, dataShape, { propName } = {}) {
    return addComponentConfigurationDataGraphQlQuery(
      element,
      componentName,
      componentIdentifier,
      fieldName,
      {
        data: dataShape,
        propName,
      },
    );
  }

  element = addStaticPropIfDefined(element, 'businessId', businessId);
  element = addStaticPropIfDefined(element, 'businessInfoId', businessInfoId);
  element = addStaticPropIfDefined(element, 'websiteId', websiteId);
  element = addStaticPropIfDefined(element, 'locationId', locationId);
  element = addStaticPropIfDefined(element, 'componentConfigurationId', componentConfigurationId);
  element = addFormConfigurationField('header');
  element = addFormConfigurationField(
    'dynamicFormFields',
    {
      dynamicFormFields: {
        widgetType: true,
        name: true,
        label: true,
        required: true,
        placeholder: true,
        inputType: true,
        options: {
          label: true,
          value: true,
        },
      },
    },
    { propName: 'fields' },
  );
  element = addFormConfigurationField('consentSettings', {
    consentSettings: {
      show: true,
      required: false,
    },
  });

  if (!_isEmpty(additionalProps)) {
    additionalProps.forEach((prop) => {
      element = addStaticPropIfDefined(element, prop.name, prop.resolve);
    });
  }

  return element;
}

export function createFieldState(field = {}) {
  const { value } = field;
  const { type = '', inputType = '' } = !_isEmpty(value) ? value : field;

  const finalType =
    INPUT_TYPE_TO_RECIPE_FIELD_TYPE[inputType] || FIELD_WIDGET_TO_TYPE[type] || type;

  const GENERATOR_BY_TYPE = {
    [RECIPE_FORM_FIELD_TYPES.ShortText]: createTextFieldState,
    [RECIPE_FORM_FIELD_TYPES.LongText]: createLongTextFieldState,
    [RECIPE_FORM_FIELD_TYPES.SingleChoice]: createSingleSelectFieldState,
    [RECIPE_FORM_FIELD_TYPES.MultipleChoice]: createMultiSelectFieldState,
    [RECIPE_FORM_FIELD_TYPES.Email]: createEmailFieldState,
    [RECIPE_FORM_FIELD_TYPES.PhoneNumber]: createPhoneNumberFieldState,
    [RECIPE_FORM_FIELD_TYPES.FileUpload]: createFileUploadFieldState,
  };

  const generatorFunction = GENERATOR_BY_TYPE[finalType] || (() => null);
  return generatorFunction(field);
}

export function createLegacyFormBlockState(recipeSnippet = {}, context = {}) {
  const { state } = context;
  const type = LEGACY_FORM_BLOCK_ELEMENT_TYPE;
  const { props } = recipeSnippet || {};
  const businessId = selectBusinessId(state);
  const businessType = selectBusinessType(state);
  const locationId = _get(selectPrimaryLocation(state), 'id');

  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  const fields = getCollectionPropValue(props, 'fields');
  const title = getStaticPropValue(props, 'subheader');

  const fieldStates = (fields || []).map(createFieldState).filter(Boolean);

  return {
    type,
    id,
    className,
    style,
    businessId,
    businessType,
    locationId,
    title,
    fields: fieldStates,
  };
}

export function createFormBlockState(recipeSnippet = {}, context = {}) {
  const { props } = recipeSnippet || {};
  const { state, page = {} } = context;
  const { id: pageId } = page;

  const type = FORM_BLOCK_ELEMENT_TYPE;

  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  const businessId = selectBusinessId(state);
  const businessType = selectBusinessType(state);
  const websiteId = selectWebsiteId(state);
  const businessInfoId = selectBusinessInfoId(state);
  const locationId = _get(selectPrimaryLocation(state), 'id');

  const query = getCollectionPropValue(props, 'fields');

  let componentName = FORM_BLOCK_COMPONENT_NAME;
  let componentIdentifier = null;

  if (query) {
    componentName = getQueryArgValue(query, 'fishermanWebsiteComponent', 'fastId');
    componentIdentifier = getQueryArgValue(
      query,
      'fishermanWebsiteComponent',
      'componentIdentifier',
    );
  }

  const componentConfiguration = getPageComponent(
    { pageId, componentName, componentIdentifier },
    state,
  );

  const componentConfigurationId = _get(componentConfiguration, 'id', null);
  const componentConfigurationData = _get(componentConfiguration, 'data', {});
  const fields = _get(componentConfigurationData, 'dynamicFormFields', []);
  const header = _get(componentConfigurationData, 'header', '');
  const emailSubject = _get(componentConfigurationData, 'emailSubject', '');
  const channel = _get(componentConfigurationData, 'channel', '');
  const consentSettings = _get(componentConfigurationData, 'consentSettings', {});
  const contactMapping = _get(componentConfigurationData, 'contactMapping', {});
  const destinationEmail = _get(componentConfigurationData, 'destinationEmail', []);

  const fieldStates = (fields || [])
    .map(transformNewFieldIntoLegacy)
    .map(createFieldState)
    .filter(Boolean);

  return {
    id,
    type,
    className,
    style,
    businessId,
    businessInfoId,
    businessType,
    websiteId,
    locationId,
    componentConfigurationId,
    componentName,
    componentIdentifier,
    fields: fieldStates,
    channel,
    header,
    emailSubject,
    consentSettings,
    contactMapping,
    destinationEmail,
    additionalProps: props,
  };
}

export function createLegacyFormBlockTemplateState(data = {}) {
  const { businessId, businessType, locationId, ...restFormBlock } = data;

  return restFormBlock;
}

export function createFormBlockTemplateState(data = {}) {
  const {
    businessId,
    businessType,
    componentIdentifier,
    componentConfigurationId,
    businessInfoId,
    websiteId,
    locationId,
    ...restFormBlock
  } = data;

  return restFormBlock;
}
