import React from 'react';

import MessageDisplayForm from './MessageDisplayForm';
import { InlineFieldContainer } from '../../common';

import '../../../styles/core/components/integration-form.scss';

class LayoutComponentList extends React.Component {
  static getFormForComponent(component) {
    const { index, layoutIndex, id } = component;

    if (component && component.fast_id === 'MessageDisplay') {
      return (
        <InlineFieldContainer key={id} title="Message Banner" enableDelete={false}>
          <MessageDisplayForm index={index} layoutIndex={layoutIndex} component={component} />
        </InlineFieldContainer>
      );
    }
    return <></>;
  }

  getLayoutComponents() {
    const { layouts = [] } = this.props;
    const supportedLayoutConfigurations = ['MessageDisplay'];

    if (!layouts || !layouts.length) return [];

    // Add layout index, component index, and reduce across layouts
    return layouts
      .map((layout, layoutIndex) => {
        const newLayout = {
          ...layout,
          components: layout.components || [],
        };
        newLayout.components = newLayout.components.map((component, index) => {
          return {
            ...component,
            layoutIndex,
            index,
          };
        });
        return newLayout;
      })
      .reduce((acc, layout) => [...acc, ...layout.components], [])
      .filter((component) => {
        return supportedLayoutConfigurations.includes(component.fast_id);
      });
  }

  render() {
    const { layouts } = this.props;
    const layoutComponents = this.getLayoutComponents(layouts);

    return (
      <>
        {layoutComponents &&
          !!layoutComponents.length &&
          layoutComponents.map((component) => LayoutComponentList.getFormForComponent(component))}
      </>
    );
  }
}

export default LayoutComponentList;
