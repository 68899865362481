import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Select, Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { TeamMemberServicesTablePropType } from './TeamMemberServicesTableRow.types';
import {
  getNextPriceState,
  getPriceTypeOptions,
  getPriceTypeValue,
} from './TeamMemberServicesTableRow.utils';
import DeleteTeamMemberServiceButton from '../DeleteTeamMemberServiceButton';
import PriceOverrideToggle from '../PriceOverrideToggle';

import './TeamMemberServicesTableRow.scss';

const propTypes = {
  row: TeamMemberServicesTablePropType.isRequired,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

const defaultProps = {
  onDelete: () => null,
  onUpdate: () => null,
};

export default function TeamMemberServicesTableRow({ row, onDelete, onUpdate: onUpdateProp }) {
  const [priceType, setPriceType] = useState(getPriceTypeValue(row));

  const {
    id,
    itemVariation,
    categoryName,
    categoryRowSpan,
    itemName,
    itemRowSpan,
    variationName,
    price,
    priceDisplay,
    priceOverride,
    priceDisplayOverride,
  } = row;

  async function onUpdatePrice(changedData) {
    const payload = { id, itemVariation, ...changedData };

    if (onUpdateProp) {
      await onUpdateProp(payload);
    }
  }

  async function onPriceTypeChange(e, { value: newPriceType }) {
    setPriceType(newPriceType);
    await onUpdatePrice(getNextPriceState(row, newPriceType));
  }

  return (
    <Table.Row>
      {!_isEmpty(categoryName) && <Table.Cell rowSpan={categoryRowSpan}>{categoryName}</Table.Cell>}
      {!_isEmpty(itemName) && <Table.Cell rowSpan={itemRowSpan}>{itemName}</Table.Cell>}
      <Table.Cell>{variationName}</Table.Cell>
      <Table.Cell>
        <Select value={priceType} options={getPriceTypeOptions()} onChange={onPriceTypeChange} />
      </Table.Cell>
      {!_isNil(onUpdateProp) && (
        <Table.Cell>
          <PriceOverrideToggle
            priceType={priceType}
            price={price}
            priceDisplay={priceDisplay}
            priceOverride={priceOverride}
            priceDisplayOverride={priceDisplayOverride}
            onUpdate={onUpdatePrice}
          />
        </Table.Cell>
      )}
      {!_isNil(onDelete) && (
        <Table.Cell textAlign="center">
          <DeleteTeamMemberServiceButton teamMemberService={row} onDelete={onDelete} />
        </Table.Cell>
      )}
    </Table.Row>
  );
}

TeamMemberServicesTableRow.propTypes = propTypes;
TeamMemberServicesTableRow.defaultProps = defaultProps;
