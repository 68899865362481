import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

export function zendeskAvailable() {
  return typeof window.zE !== 'undefined';
}

export function zendeskOpen() {
  window.zE('messenger', 'open');
}

export function zendeskClose() {
  window.zE('messenger', 'close');
}

export function createZendeskScript({ onLoad = () => null } = {}) {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.id = 'ze-snippet';
  script.src =
    'https://static.zdassets.com/ekr/snippet.js?key=b920253d-6161-46c8-8f01-c3259ae5f149';
  script.async = true;
  script.onload = onLoad;

  document.body.appendChild(script);
}

// eslint-disable-next-line no-unused-vars
function zendeskIdentifyUsernameAndData(username, data = {}) {}

export function zendeskIdentify(user = {}, business = {}) {
  if (zendeskAvailable()) {
    const {
      id: userId,
      email,
      firstName = '',
      lastName = '',
      phoneNumber = '',
      groups = [],
    } = user;

    if (!_isNil(userId) && !_isEmpty(email)) {
      let payload = {
        email,
        name: `${firstName} ${lastName}`,
        phone_number: phoneNumber,
        groups: groups ? groups.toString() : '',
      };

      if (!_isEmpty(business)) {
        const { id: businessId, name: businessName, type: businessType } = business;
        payload = {
          ...payload,
          business_id: businessId,
          business_type: businessType,
          business_name: businessName,
        };
      }

      const { website = {} } = business;

      if (!_isEmpty(website)) {
        const { id: websiteId, cloudformationStackName } = website;
        payload = {
          ...payload,
          website_id: websiteId,
          cloudformation_stack_name: cloudformationStackName,
        };
      }

      zendeskIdentifyUsernameAndData(email, payload);
    }
  }
}
