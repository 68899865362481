import React, { useContext, useState } from 'react';

import { Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import API from '../../../../../../../libs/api';
import ErrorHandler from '../../../../../../../libs/errors';
import { onTableRowSortingStart } from '../../../../../../../libs/sorting';
import Utils from '../../../../../../../libs/utils';
import Dots from '../../../../../../common/sortables/Dots';
import { TeamsSectionContext } from '../../TeamMembersTable.context';
import TeamMemberActions from '../TeamMemberActions';

import './TeamsTableBody.scss';

const DragHandle = SortableHandle(({ disabled }) => <Dots disabled={disabled} />);

const SortableTableBodyContainer = SortableContainer(({ children }) => {
  return children;
});

const SortableTableRow = SortableElement(({ children }) => {
  return children;
});

export default function TeamsTableBody() {
  const { teamMembers, setTeamMembers } = useContext(TeamsSectionContext);
  const [loading, setLoading] = useState(false);

  if (_isEmpty(teamMembers)) {
    return null;
  }

  async function onSortTeamMembers({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      const sortedTeam = Utils.moveListItem(teamMembers, oldIndex, newIndex, true).map(
        (member, index) => ({ ...member, order: index, needsUpdate: member.order !== index }),
      );
      setTeamMembers(sortedTeam);
      try {
        setLoading(true);
        // TODO this is not very efficient, create an endpoint that let update a team in bulk
        await Promise.all(
          sortedTeam.map(async ({ id, order, needsUpdate }) => {
            if (needsUpdate) {
              return API.updateTeamMemberAttribute(id, { order });
            }
            return Promise.resolve();
          }),
        );
      } catch (e) {
        ErrorHandler.capture(e);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <SortableTableBodyContainer
      useDragHandle
      onSortStart={onTableRowSortingStart}
      onSortEnd={onSortTeamMembers}
      helperClass="table-row-sorting"
    >
      <Table.Body className="teams-table-body">
        {teamMembers.map((teamMember, index) => {
          const { id, name, role, email, phone } = teamMember;
          return (
            <SortableTableRow key={id + name} index={index}>
              <Table.Row className="teams-table-body-row">
                <Table.Cell>
                  <div className="drag-handle-container">
                    <DragHandle disabled={loading} />
                    <span>{name}</span>
                  </div>
                </Table.Cell>
                <Table.Cell>{role}</Table.Cell>
                <Table.Cell>{phone}</Table.Cell>
                <Table.Cell className="email-cell">{email}</Table.Cell>
                <Table.Cell>
                  <TeamMemberActions teamMember={teamMember} />
                </Table.Cell>
              </Table.Row>
            </SortableTableRow>
          );
        })}
      </Table.Body>
    </SortableTableBodyContainer>
  );
}
