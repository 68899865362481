import {
  SUPPORTED_LOCATION_IMAGES_RECIPES,
  SUPPORTED_LOCATION_SEO_METADATA_RECIPES,
} from '../../../../constants/recipes';
import { getNumeric } from '../../../../libs/strings';
import { hasAnyRecipe } from '../../../modules/foundation/hooks/use-recipe';

export function formatPhoneNumber(phoneNumber = '') {
  const numbers = getNumeric(phoneNumber);
  return numbers.slice(-10);
}

export function isLocationSeoMetadataSupported(patch) {
  return hasAnyRecipe(patch, SUPPORTED_LOCATION_SEO_METADATA_RECIPES);
}

export function areLocationImagesSupported(patch) {
  return hasAnyRecipe(patch, SUPPORTED_LOCATION_IMAGES_RECIPES);
}

export function getFeaturedBusinessFile(businessFiles, featuredImageId) {
  return businessFiles.find((file) => file.id === featuredImageId);
}

export function getLocationBusinessFiles(businessFiles, locationImages) {
  const businessFilesMap = new Map(businessFiles.map((file) => [file.id, file]));

  return locationImages
    .map(({ id: locationImageId }) => businessFilesMap.get(locationImageId))
    .filter(Boolean);
}

export function getSeoMetadataConfiguration(location = {}) {
  const { city = '' } = location;

  const additionalRequirements = `Ensure that the locality of ${city} is mentioned.`;
  const descriptionPlaceholder = `Discover our prime location in ${city}. Offering a range of services and amenities to cater to your needs. Visit us in ${city} today!`;

  return { additionalRequirements, descriptionPlaceholder };
}
