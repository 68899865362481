import React, { Component } from 'react';

import { Button, Radio, Input, Modal, Form } from 'semantic-ui-react';

import CloseableModal from '../../../../common/CloseableModal';

class ModalCreatePatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      draft: true,
      version: '',
      description: '',
    };

    this.setValues = this.setValues.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
    this.toggleDraft = this.toggleDraft.bind(this);
  }

  componentDidMount() {
    const { version } = this.props;
    this.setState({ version });
  }

  setValues() {
    const { saveMode, description = '', version = '', active = false, draft = true } = this.props;

    if (saveMode) {
      this.setState({ description, version, active, draft });
    } else {
      this.setState({ version });
    }
  }

  toggleActive() {
    const { active } = this.state;
    this.setState({ active: !active, draft: false });
  }

  toggleDraft() {
    const { draft } = this.state;
    this.setState({ active: false, draft: !draft });
  }

  render() {
    const {
      createPatch,
      close,
      open,
      isCreatingPatch,
      patchId,
      saveMode,
      savePatch,
      isSavingPatch,
      trigger,
    } = this.props;
    const { active, draft, version, description } = this.state;

    return (
      <CloseableModal
        trigger={trigger}
        open={open}
        onOpen={this.setValues}
        className="developer-modal-create-patch"
        header={saveMode ? 'Save Patch' : 'Create Patch'}
        onClose={close}
        closeIconDisabled={isCreatingPatch}
      >
        <Modal.Content>
          <div className="developer-modal-create-patch-wrapper">
            <Form>
              <Form.Field>
                <label htmlFor="description-input">Description</label>
                <Input
                  id="description-input"
                  type="text"
                  placeholder="Description"
                  value={description}
                  onChange={(e, { value }) => this.setState({ description: value })}
                />
              </Form.Field>
              <Form.Field disabled>
                <label htmlFor="path-version-input">Patch version</label>
                <Input
                  id="path-version-input"
                  type="text"
                  placeholder="Patch version"
                  value={version}
                  onChange={(e, { value }) => this.setState({ version: value })}
                />
              </Form.Field>
              <Radio slider checked={draft} label="Draft" onChange={this.toggleDraft} />
              <Radio slider checked={active} label="Active" onChange={this.toggleActive} />
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            loading={saveMode ? isSavingPatch : isCreatingPatch}
            primary
            content={saveMode ? 'Save Patch' : 'Create Patch'}
            onClick={() =>
              saveMode
                ? savePatch(patchId, description, active, draft)
                : createPatch(description, version, active, draft)
            }
          />
        </Modal.Actions>
      </CloseableModal>
    );
  }
}

export default ModalCreatePatch;
