import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import HelpTooltip from 'components/common/HelpTooltip';
import Dots from 'components/common/sortables/Dots';
import { FileEditorModal } from 'components/modules/files/components';
import { getPlaceholderImage } from 'components/modules/files/services/business-files';
import useResponsive from 'components/modules/foundation/hooks/use-responsive';
import { objectToCamelCase } from 'libs/format';

import { BusinessFileFromPageDataPropType } from './PageEditGalleryImage.types';
import { createNumFilesAllowedDescription } from '../ImagesPage.utils';

import './PageEditGalleryImages.scss';

const SortableBusinessFile = SortableElement(({ file: businessFileFromPageData, onFileEdit }) => {
  const { advancedImageEditing } = useFlags();
  const { isTouchScreenDevice } = useResponsive();
  const [showEditButton, setShowEditButton] = useState(false);

  const { file, url, metadata } = businessFileFromPageData;
  const imageUrl = getPlaceholderImage({ file, url, metadata });

  const styles = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${imageUrl})`,
  };

  useEffect(() => {
    setShowEditButton(isTouchScreenDevice);
  }, [isTouchScreenDevice]);

  function handleOnFileEdit(e, fileToEdit) {
    e.stopPropagation();
    e.preventDefault();
    onFileEdit(fileToEdit);
  }

  return (
    <div
      className="gallery-item"
      style={styles}
      onMouseEnter={() => setShowEditButton(true)}
      onMouseLeave={() => setShowEditButton(false)}
    >
      <Dots size="5px" />
      {advancedImageEditing && showEditButton && (
        <Button
          icon="pencil"
          className="image-edit-button"
          onClick={(e) => handleOnFileEdit(e, businessFileFromPageData)}
          onMouseDown={(e) => handleOnFileEdit(e, businessFileFromPageData)}
        />
      )}
    </div>
  );
});

const SortableBusinessFileList = SortableContainer(({ files, onFileEdit }) => {
  return (
    <div className="gallery-list">
      {files.map((file, idx) => (
        <SortableBusinessFile key={file.file} file={file} index={idx} onFileEdit={onFileEdit} />
      ))}
    </div>
  );
});

const propTypes = {
  title: PropTypes.string.isRequired,
  titleHelpText: PropTypes.string,
  businessFiles: PropTypes.arrayOf(BusinessFileFromPageDataPropType),
  minFilesAllowed: PropTypes.number,
  maxFilesAllowed: PropTypes.number,
  onEditImagesClick: PropTypes.func.isRequired,
  onSortImages: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onPostConfigurationSave: PropTypes.func,
};
const defaultProps = {
  titleHelpText: '',
  businessFiles: [],
  minFilesAllowed: null,
  maxFilesAllowed: null,
  loading: false,
  onPostConfigurationSave: () => {},
};

export default function PageEditGalleryImages({
  title,
  titleHelpText,
  businessFiles: businessFilesProp,
  minFilesAllowed,
  maxFilesAllowed,
  onEditImagesClick,
  onSortImages,
  loading,
  onPostConfigurationSave,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBusinessFile, setSelectedBusinessFile] = useState(null);
  const [selectedBusinessFileConfiguration, setSelectedBusinessFileConfiguration] = useState(null);

  const numFilesAllowedDescription = createNumFilesAllowedDescription(
    minFilesAllowed,
    maxFilesAllowed,
  );

  const businessFiles = objectToCamelCase(businessFilesProp);

  function onClick() {
    onEditImagesClick();
  }
  function onFileEdit(file) {
    const { id, file: fileUrl, businessFileConfigurationId, metadata, order } = file;
    const businessFile = { id, file: fileUrl };
    const businessFileConfiguration = { id: businessFileConfigurationId, metadata, order };

    setSelectedBusinessFile(businessFile);
    setSelectedBusinessFileConfiguration(businessFileConfiguration);
    setModalIsOpen(true);
  }

  return (
    <div className="page-edit-images">
      <header className="header">
        <h3>
          {title}
          {titleHelpText && <HelpTooltip title={title} description={titleHelpText} />}
        </h3>
        <h6 className="num-files-allowed">{numFilesAllowedDescription}</h6>
        <Button
          content="Edit Images"
          className="action"
          icon="add"
          labelPosition="left"
          onClick={onClick}
        />
      </header>
      <Segment loading={loading}>
        <SortableBusinessFileList
          axis="x"
          lockAxis="x"
          files={businessFiles}
          helperClass="page-edit-gallery-images-helper"
          onSortEnd={onSortImages}
          onFileEdit={onFileEdit}
        />
      </Segment>
      {modalIsOpen && (
        <FileEditorModal
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          businessFile={selectedBusinessFile}
          businessFileConfiguration={selectedBusinessFileConfiguration}
          onPostSave={onPostConfigurationSave}
        />
      )}
    </div>
  );
}

PageEditGalleryImages.propTypes = propTypes;
PageEditGalleryImages.defaultProps = defaultProps;
