import React from 'react';

import { Segment, Container, Header, Icon, Button } from 'semantic-ui-react';

import { COMPLETE_INTEGRATION_DESCRIPTION } from './StripeConnectOnboardingComplete.constants';

import './StripeConnectOnboardingComplete.scss';

export default function StripeConnectOnboardingComplete() {
  const stripeUrl = 'https://dashboard.stripe.com/dashboard';
  return (
    <Segment className="stripe-connect-onboarding-complete" attached>
      <div>
        <Header as="h4">
          <b>
            Fisherman Connected <Icon size="large" name="check" />
          </b>
        </Header>
        <p>{COMPLETE_INTEGRATION_DESCRIPTION}</p>
      </div>
      <Container textAlign="center">
        <Button as="a" href={stripeUrl} size="big" primary content="Manage Account" />
      </Container>
    </Segment>
  );
}
