import React from 'react';

import { Segment, Form, Button, Header, Message, Icon, Divider, Loader } from 'semantic-ui-react';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import BusinessSelectForm from './BusinessSelectForm';
import ConfirmationPage from './ConfirmationPage';
import PasswordInput from './PasswordInput';
import SocialLoginButton from './SocialLoginButton';
import {
  resetBusiness as resetBusinessConnect,
  createBusiness as createBusinessConnect,
  createBusinessCore as createBusinessCoreConnect,
  initializeTimezones as initializeTimezonesConnect,
  initializeLocations as initializeLocationsConnect,
  initializeLocationTaxes as initializeLocationTaxesConnect,
  initializeMenu as initializeMenuConnect,
  initializeProviderServices as initializeProviderServicesConnect,
  initializeProviderServiceSettings as initializeProviderServiceSettingsConnect,
  initializeGalleryImages as initializeGalleryImagesConnect,
  initializeBusinessSocialMedia as initializeBusinessSocialMediaConnect,
} from '../../actions/business';
import { setContentNodes } from '../../actions/content-nodes';
import { setActiveLocation } from '../../actions/ecommerce';
import { createNotifications as createNotificationsConnect } from '../../actions/notificatons';
import {
  setProducts as setGlobalProducts,
  setActiveProduct as setGlobalActiveProduct,
} from '../../actions/products';
import {
  resetUser as resetUserConnect,
  createUser as createUserConnect,
  updateUser as updateUserConnect,
} from '../../actions/user';
import { createInputErrors as createInputErrorsConnect } from '../../actions/validation-errors';
import {
  createWebsite as createWebsiteConnect,
  initializePublications as initializePublicationsConnect,
  initializeWebsiteUsers as initializeWebsiteUsersConnect,
  initializeWebsiteSubscription as initializeWebsiteSubscriptionConnect,
  initializeDomains as initializeDomainsConnect,
  resetWebsite as resetWebsiteConnect,
  initializeLayouts as initializeLayoutsConnect,
  initializePages as initializePagesConnect,
  initializePatches as initializePatchesConnect,
  initializeStyleRules as initializeStyleRulesConnect,
  setIsNextWebsite as setIsNextWebsiteConnect,
} from '../../actions/website';
import { MERCHANT_BUSINESS_TYPE } from '../../constants/constants';
import { SOCIAL_AUTH_CALLBACK_PATH } from '../../constants/urls';
import API from '../../libs/api';
import {
  logOut,
  saveTokenExpirationTime,
  isValidUser,
  isAuthorizedForSaleViews,
  signupPathFromRole,
  hasToChangePassword,
  identifyUsername,
} from '../../libs/auth';
import ErrorHandler from '../../libs/errors';
import GoogleAnalytics from '../../libs/google-analytics';
import { identifyLaunchDarklyUser } from '../../libs/launch-darkly';
import { removeObjectLikeValues } from '../../libs/objects';
import Routing from '../../libs/routing';
import Storage from '../../libs/storage';
import Validate from '../../libs/validate';
import { getBusinessWebsite } from '../../libs/website';
import { selectUserRoleForBusiness } from '../../selectors/business';
import WithRouter from '../modules/foundation/components/WithRouter';
import { initializeHtmlElements as initializeHtmlElementsConnect } from '../modules/html-elements/actions/html-elements';
import { setAllOrderSettings } from '../pages/ecommerce/settings/OrderSettings/OrderSettings.slice';
import { getRedirectPath } from '../routes/DashboardRoute/DashboardRoute.utils';

const firstPage = 1;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    const search = _get(props, 'location.search', '');
    const { businessId } = API.parseSearch(search);

    this.state = {
      username: '',
      password: '',
      errorMessage: '',
      loading: true,
      businesses: [],
      selectedFilters: ['v_1', 'v_legacy'],
      query: '',
      totalPages: 0,
      page: firstPage,
      businessLoading: false,
      businessesInitialized: false,
      usePassword: false,
      confirmationVisible: false,
      magicLinkErrorRedirect: null,
      businessIdQuery: businessId || '',
    };

    this.login = this.login.bind(this);
    this.signUp = this.signUp.bind(this);
    this.signOut = this.signOut.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleBusinessClick = this.handleBusinessClick.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onBusinessSearchChange = this.onBusinessSearchChange.bind(this);
    this.onBusinessSearchChange = _debounce(this.onBusinessSearchChange, 500);
    this.onBusinessFilterChange = this.onBusinessFilterChange.bind(this);
  }

  async componentDidMount() {
    const { user, resetBusiness, resetWebsite, resetUser, location, navigate } = this.props;
    const errorMessage = _get(location, 'state.errorMessage');

    // On mount, clear out the business, website, and session storage
    resetBusiness();
    resetWebsite();
    sessionStorage.clear();

    if (errorMessage) {
      this.setState({ magicLinkErrorRedirect: errorMessage });
    }

    if (window && window.location && window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('email')) {
        this.setState({ username: searchParams.get('email') });
      }
    }

    if (location.pathname === SOCIAL_AUTH_CALLBACK_PATH) {
      await this.socialLogin();
      navigate('/');
      return;
    }

    if (isValidUser(user)) {
      try {
        await this.loadBusinesses(user);
      } catch {
        this.setState({ errorMessage: 'There was an error loading your business.' });
      } finally {
        this.setState({ businessesInitialized: true });
      }
    } else {
      resetUser();
    }

    this.setState({ loading: false });
  }

  async handleBusinessClick(e, target) {
    this.setState({ businessLoading: true }, async () => {
      const { index } = target;
      await this.loadBusinessIndex(index);
      this.setState({ businessLoading: false });
    });
  }

  handleChange({ target: { name, value } }) {
    let cleanValue = value;
    if (name === 'username') {
      // TODO Make a better email validator
      //  https://stackoverflow.com/a/2049510/975915
      cleanValue = value.replace(/([^a-z0-9@!#$%&'*+-/=?^_`{|}~]+)/gi, '');
    }

    this.setState({
      [name]: cleanValue,
    });
  }

  async onBusinessSearchChange(e, target) {
    const { value: query } = target;
    await this.setState(
      {
        query,
        page: firstPage,
      },
      this.loadBusinesses,
    );
  }

  async onBusinessFilterChange(filterKey) {
    const { setIsNextWebsite } = this.props;
    const { selectedFilters } = this.state;

    let nextSelectedFilters = selectedFilters;
    if (selectedFilters.includes(filterKey)) {
      nextSelectedFilters = selectedFilters.filter((key) => key !== filterKey);
    } else {
      nextSelectedFilters = [...selectedFilters, filterKey];
    }

    if (nextSelectedFilters.includes('next_website')) {
      setIsNextWebsite(true);
      this.setState({ page: firstPage });
    } else {
      setIsNextWebsite(false);
    }

    this.setState({ selectedFilters: nextSelectedFilters }, this.loadBusinesses);
  }

  onPageChange(fulfilled, activePage) {
    this.setState({ page: activePage }, () => this.loadBusinesses());
  }

  getLoginForm() {
    const { username, password, errorMessage, loading, usePassword, magicLinkErrorRedirect } =
      this.state;

    const isFormValid = !(
      !Validate.validate(Validate.TYPES.EMAIL, username) ||
      (usePassword && !password)
    );

    return (
      <div className="login-form">
        <Segment>
          <Header as="h2" textAlign="center">
            Login
          </Header>
          {magicLinkErrorRedirect && <Message visible>{magicLinkErrorRedirect}</Message>}

          {!usePassword && (
            <>
              <div className="social-login">
                <SocialLoginButton
                  socialNetworkName="google"
                  onClick={() => this.setState({ loading: true })}
                />
              </div>

              <Divider horizontal>Or</Divider>
            </>
          )}
          <Form onSubmit={() => this.login()} error={errorMessage.length !== 0} loading={loading}>
            <Form.Input
              onChange={this.handleChange}
              error={errorMessage.length !== 0}
              placeholder="your@email.com"
              name="username"
              value={username}
            />
            {usePassword && (
              <Form.Field>
                <label htmlFor="password">Password</label>
                <PasswordInput
                  id="password"
                  onChange={this.handleChange}
                  error={errorMessage.length !== 0}
                  placeholder="Password"
                  name="password"
                  value={password}
                />
              </Form.Field>
            )}
            {usePassword && (
              <Form.Field>
                <Link to="/login/forgot" className="forgot-password">
                  <span>Forgot password?</span>
                </Link>
              </Form.Field>
            )}
            <Button type="submit" color="fisherman" fluid disabled={!isFormValid}>
              {usePassword ? (
                'Sign in'
              ) : (
                <>
                  <Icon name="mail" /> Sign In With Email
                </>
              )}
            </Button>
            <Form.Field className="login-instruction-text">
              <Button as="a" onClick={this.toggleFormPassword} className="forgot-password">
                {usePassword ? 'Use email sign-in link instead' : 'Use password instead'}
              </Button>
            </Form.Field>
            <Message error header={errorMessage} />
          </Form>
        </Segment>
      </div>
    );
  }

  setFormMessage(messageField, message, resetEventually = true) {
    if (resetEventually) {
      this.setState({ [messageField]: message }, () =>
        setTimeout(() => this.setFormMessage(messageField, '', false), 3000),
      );
    }

    this.setState({ [messageField]: message });
  }

  toggleFormPassword = () => {
    this.setState((prevState) => ({
      usePassword: !prevState.usePassword,
    }));
  };

  toggleConfirmation = (isVisible) => {
    this.setState({ confirmationVisible: isVisible });
  };

  toggleLoading(cb = null) {
    this.setState(
      ({ loading }) => ({ loading: !loading }),
      () => cb && cb(),
    );
  }

  loadLocations(data) {
    const { initializeLocations } = this.props;

    initializeLocations(data);
  }

  loadSocialMedia(mediaData) {
    const { initializeBusinessSocialMedia } = this.props;
    const newData = mediaData.map((media) => {
      return {
        ...media,
        type: _startCase(media.type),
      };
    });
    initializeBusinessSocialMedia(newData);
  }

  initializeBusiness(business) {
    const { createBusinessCore } = this.props;
    createBusinessCore(business);
  }

  async initializeBusinessAttributes(business) {
    const {
      createWebsite,
      initializeTimezones,
      initializeLocationTaxes,
      initializeMenu,
      initializeDomains,
      initializePublications,
      initializeWebsiteUsers,
      initializeWebsiteSubscription,
      initializeLayouts,
      initializePages,
      initializeEcommerce,
      initializeOrderSettings,
      initializePatches,
      initializeStyleRules,
      initializeGalleryImages,
      initializeProviderServices,
      initializeProviderServiceSettings,
      initializeProducts,
      initializeActiveProduct,
      isNextWebsite,
      initializeContentNodes,
      initializeHtmlElements,
    } = this.props;
    this.initInputErrors();

    const { data: businessInitData } = await API.getBusinessInitializationData(
      business.type,
      business.id,
    );
    const currentWebsite = getBusinessWebsite(businessInitData, isNextWebsite);
    const coreWebsiteValues = removeObjectLikeValues(currentWebsite);
    createWebsite(coreWebsiteValues);

    const { type: businessType } = business;
    const statusContext =
      businessType === MERCHANT_BUSINESS_TYPE
        ? { business_files_created: true }
        : businessInitData.status_context || {};

    const coreBusinessValues = removeObjectLikeValues(businessInitData);
    this.initializeBusiness({
      ...coreBusinessValues,
      ...business,
      status_context: statusContext,
    });

    initializeTimezones({ timezones: businessInitData.timezones });
    initializeLocationTaxes(businessInitData.location_taxes);
    initializeMenu({
      schedules: businessInitData.schedules,
      categories: businessInitData.categories,
      items: businessInitData.items,
      modifier_sets: businessInitData.modifier_sets,
      modifiers: businessInitData.modifiers,
    });
    initializeDomains(businessInitData.website.domains);
    initializePublications(businessInitData.website.publications);
    initializeWebsiteUsers(currentWebsite.website_users);
    initializeWebsiteSubscription(currentWebsite.subscription_data);
    initializeLayouts(currentWebsite.layouts);
    initializePages(currentWebsite.pages);
    initializePatches(currentWebsite.patches);
    initializeStyleRules(currentWebsite.style_rules);
    initializeGalleryImages(businessInitData.gallery_images);
    initializeProviderServices(businessInitData.provider_services);
    initializeProviderServiceSettings(businessInitData.provider_service_settings);
    initializeEcommerce();
    initializeOrderSettings();
    initializeProducts(businessInitData.products);
    initializeActiveProduct(_get(businessInitData, 'products[0]'));
    initializeContentNodes(businessInitData.content_nodes);
    initializeHtmlElements(currentWebsite.html_elements);

    this.loadLocations(businessInitData.locations);
    this.loadSocialMedia(businessInitData.social_media);
  }

  async loadBusinesses(userParam) {
    const { user, userRole, updateUser } = this.props;
    const { query, page, businessIdQuery, selectedFilters } = this.state;

    const finalUser = userParam || user;
    if (!isValidUser(finalUser)) return;

    this.setState({ businessLoading: true });

    try {
      const result = await API.getUserBusinesses(
        query || businessIdQuery,
        page,
        'Website',
        selectedFilters.reduce((filters, filterKey) => ({ ...filters, [filterKey]: 1 }), {}),
      );
      const {
        data: { total_pages: totalPages, results = [] },
      } = result;

      if (!_isEmpty(results)) {
        this.setState({
          businesses: results,
          totalPages,
        });

        // Only set businesses count once for the user
        // TODO move to serializer and set on backend
        if (!finalUser.businessesCount) {
          updateUser({
            field: 'businessesCount',
            value: results.length,
            bypassTouch: true,
          });
        }
      } else {
        this.setState({
          businesses: [],
          showLogout: true,
        });
      }

      const alwaysShowSelect = isAuthorizedForSaleViews(userRole);
      // If not searching and only one business, auto load business
      if (!alwaysShowSelect && results.length === 1 && totalPages === 1 && !query) {
        await this.loadBusinessIndex();
      }
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      this.setState({
        businessLoading: false,
      });
    }
  }

  initInputErrors() {
    const { createInputErrors } = this.props;
    createInputErrors();
  }

  async loadBusinessIndex(index = 0) {
    const { navigate, location, createBusiness, createNotifications } = this.props;
    const business = _get(this.state, `businesses[${index}]`);

    if (!business) return;

    try {
      /* Gather business information */
      const { id, type } = business;

      /* Initialize business and website attributes */
      createBusiness({ id, type });
      createNotifications();

      await this.initializeBusinessAttributes(business);

      const pathName = _get(location, 'state.from.pathname');
      if (pathName) {
        navigate(pathName);
        return;
      }

      const next = getRedirectPath();
      if (next) {
        navigate(next);
        return;
      }

      navigate(Routing.getFirstRoute(['core']));
    } catch (e) {
      ErrorHandler.capture(e);
    }
  }

  resetForm() {
    this.setState({
      username: '',
      password: '',
    });
  }

  validateParams() {
    const { username, password, usePassword } = this.state;

    if (username.length === 0) {
      this.setFormMessage('errorMessage', 'Please enter your username');
      return false;
    }

    if (password.length === 0 && usePassword) {
      this.setFormMessage('errorMessage', 'Please enter your password');
      return false;
    }

    return true;
  }

  async postLogin(loginResponse) {
    const { createUser, createNotifications, ldClient } = this.props;

    // Save JWT
    const { data } = loginResponse;
    const { user, expiration_time: expirationTime = 0, token: jwtToken } = data;
    if (jwtToken) Storage.setJWTToken(jwtToken);
    saveTokenExpirationTime(expirationTime);

    /* Initialize local state modules */
    await identifyLaunchDarklyUser(user, ldClient, { clear: true });
    GoogleAnalytics.identifyUser(user.id);
    createUser(user);
    createNotifications();
    await this.loadBusinesses(user);
    this.setState({ businessesInitialized: true });
  }

  async socialLogin() {
    try {
      this.setState({ loading: true });
      Storage.clearJWTToken();

      const loginResult = await API.socialLogin();

      await this.postLogin(loginResult);
    } catch (e) {
      if (e.response && [400, 401].includes(e.response.status)) {
        this.setFormMessage('errorMessage', 'You are not logged in');
      } else {
        this.setFormMessage('errorMessage', 'Error logging in, please try again');
        ErrorHandler.capture(e, {
          extra: {
            brandingType: process.env.REACT_APP_BRANDING || 'core',
          },
        });
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  async login() {
    const { username, password, usePassword } = this.state;
    const paramsAreValid = this.validateParams();
    identifyUsername(username);

    if (paramsAreValid) {
      this.toggleLoading(async () => {
        try {
          // Login
          if (usePassword) {
            Storage.clearJWTToken();

            const loginResult = await API.login(username, password);

            await this.postLogin(loginResult);
          } else {
            await API.loginMagicLink(username);

            // We should render magic link response even if the user was not found.
            // Don't give an indication of whether the email is registered with us.
            this.toggleConfirmation(true);
          }
        } catch (e) {
          if (e.response && [400, 401].includes(e.response.status)) {
            this.setFormMessage('errorMessage', 'Invalid username or password');
          } else {
            this.setFormMessage('errorMessage', 'Error logging in, please try again');
            ErrorHandler.capture(e, {
              extra: {
                brandingType: process.env.REACT_APP_BRANDING || 'core',
              },
            });
          }
        } finally {
          this.toggleLoading();
          this.resetForm();
        }
      });
    }
  }

  renderConfirmation = () => {
    return (
      <div className="login-form">
        <ConfirmationPage
          icon="mail outline"
          subject="Check your email"
          content={
            <p>
              We just emailed you a link that will allow you to sign in without your password.
              <br />
              Please check your inbox and tap the link to sign in.
            </p>
          }
          actionContent={
            <div>
              <p>Didn&apos;t receive the email?</p>
              <Button
                as="a"
                className="forgot-password"
                onClick={() => this.toggleConfirmation(false)}
              >
                Please re-enter your email address
              </Button>
            </div>
          }
        />
      </div>
    );
  };

  signOut() {
    const { navigate, resetUser, resetBusiness, resetWebsite } = this.props;
    logOut(navigate);
    resetUser();
    resetBusiness();
    resetWebsite();
  }

  signUp() {
    const { user, userRole, navigate } = this.props;
    const signupPath = signupPathFromRole(user, userRole);
    if (signupPath) {
      navigate(signupPath);
    }
  }

  renderLogout() {
    return (
      <Button className="logout-button" onClick={() => this.signOut()}>
        Logout
      </Button>
    );
  }

  render() {
    const { user } = this.props;
    const {
      businesses,
      businessLoading,
      totalPages,
      page,
      loading,
      usePassword,
      confirmationVisible,
      selectedFilters,
      businessesInitialized,
    } = this.state;

    if (!usePassword && confirmationVisible) {
      return this.renderConfirmation();
    }

    if (!isValidUser(user)) {
      return this.getLoginForm();
    }

    if (loading || !businessesInitialized) {
      return <Loader active>Reeling in your data...Please wait!</Loader>;
    }

    if (hasToChangePassword(user) && !user.skip_change_password) {
      return <Navigate to="/create-password" push />;
    }

    return (
      <BusinessSelectForm
        onBusinessSearchChange={this.onBusinessSearchChange}
        onBusinessFilterChange={this.onBusinessFilterChange}
        handleBusinessClick={this.handleBusinessClick}
        onPageChange={this.onPageChange}
        signOut={this.signOut}
        signUp={this.signUp}
        user={user}
        businessLoading={businessLoading}
        businesses={businesses}
        totalPages={totalPages}
        page={page}
        selectedFilters={selectedFilters}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createUser: (payload) => dispatch(createUserConnect(payload)),
  updateUser: (payload) => dispatch(updateUserConnect(payload)),
  createWebsite: (payload) => dispatch(createWebsiteConnect(payload)),
  createBusiness: (payload) => dispatch(createBusinessConnect(payload)),
  createNotifications: (payload) => dispatch(createNotificationsConnect(payload)),
  createBusinessCore: (payload) => dispatch(createBusinessCoreConnect(payload)),
  createInputErrors: (payload) => dispatch(createInputErrorsConnect(payload)),
  initializeTimezones: (payload) => dispatch(initializeTimezonesConnect(payload)),
  initializeLocations: (payload) => dispatch(initializeLocationsConnect(payload)),
  initializeProviderServices: (payload) => dispatch(initializeProviderServicesConnect(payload)),
  initializeProviderServiceSettings: (payload) =>
    dispatch(initializeProviderServiceSettingsConnect(payload)),
  initializeBusinessSocialMedia: (payload) =>
    dispatch(initializeBusinessSocialMediaConnect(payload)),
  initializeLocationTaxes: (payload) => dispatch(initializeLocationTaxesConnect(payload)),
  initializeMenu: (payload) => dispatch(initializeMenuConnect(payload)),
  initializeDomains: (payload) => dispatch(initializeDomainsConnect(payload)),
  initializePublications: (payload) => dispatch(initializePublicationsConnect(payload)),
  initializeWebsiteUsers: (payload) => dispatch(initializeWebsiteUsersConnect(payload)),
  initializeWebsiteSubscription: (payload) =>
    dispatch(initializeWebsiteSubscriptionConnect(payload)),
  initializeLayouts: (payload) => dispatch(initializeLayoutsConnect(payload)),
  initializePages: (payload) => dispatch(initializePagesConnect(payload)),
  initializeEcommerce: () => dispatch(setActiveLocation(undefined)),
  initializeOrderSettings: () => dispatch(setAllOrderSettings([])),
  initializePatches: (payload) => dispatch(initializePatchesConnect(payload)),
  initializeStyleRules: (payload) => dispatch(initializeStyleRulesConnect(payload)),
  initializeGalleryImages: (payload) => dispatch(initializeGalleryImagesConnect(payload)),
  initializeProducts: (payload) => dispatch(setGlobalProducts(payload)),
  initializeActiveProduct: (payload) => dispatch(setGlobalActiveProduct(payload)),
  resetUser: (payload) => dispatch(resetUserConnect(payload)),
  resetBusiness: (payload) => dispatch(resetBusinessConnect(payload)),
  resetWebsite: (payload) => dispatch(resetWebsiteConnect(payload)),
  setIsNextWebsite: (payload) => dispatch(setIsNextWebsiteConnect(payload)),
  initializeContentNodes: (payload) => dispatch(setContentNodes(payload)),
  initializeHtmlElements: (payload) => dispatch(initializeHtmlElementsConnect(payload)),
});

const mapStateToProps = ({ business, website, user }) => ({
  business: _get(business, 'core.value', {}),
  userRole: selectUserRoleForBusiness({ business }),
  user: _get(user, 'core.value', {}),
  isNextWebsite: _get(website, 'isNextWebsite'),
});

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(WithRouter(LoginForm)),
);
