import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Input, Form, Message } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  MAX_SIZE_EXCEEDED_MESSAGE,
  MAX_SIZE_EXCEEDED_SUGGESTION_MESSAGE,
  MAX_IMG_SIZE_EXCEEDED_SUGGESTION_URL,
} from './SearchBar.constants';
import useErrorMessages from '../../../../../hooks/useErrorMessages';
import ExternalAnchor from '../../../../common/ExternalAnchor/ExternalAnchor';
import FileSourceFilter from '../../../FileManagement/components/FileSourceFilter';

import './SearchBar.scss';

const propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  isSearching: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  initialError: PropTypes.string,
  sourceFilter: PropTypes.string.isRequired,
  onSourceFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {
  initialError: null,
};

export default function SearchBar({
  handleSearchChange,
  searchValue,
  isSearching,
  setError,
  initialError = null,
  onSearchFocus,
  onSearchBlur,
  sourceFilter,
  onSourceFilterChange,
}) {
  const [errorMessages, setErrorMessage, clearErrorMessages] = useErrorMessages(initialError);

  useEffect(() => {
    clearErrorMessages();
    setErrorMessage(initialError);
  }, [initialError]);

  function removeErrorMessage() {
    clearErrorMessages();

    if (setError) {
      setError('');
    }
  }

  function errorMessageToDisplayMap(errorKey) {
    const defaultMessage = (
      <Message
        key={errorKey}
        negative
        onDismiss={removeErrorMessage}
        header={errorKey}
        list={errorMessages[errorKey]}
      />
    );

    const maxSizeExceededErrorMessages = errorMessages[MAX_SIZE_EXCEEDED_MESSAGE];
    const messageMap = {
      [MAX_SIZE_EXCEEDED_MESSAGE]: (
        <Message key={MAX_SIZE_EXCEEDED_MESSAGE} negative onDismiss={removeErrorMessage}>
          <Message.Header>{MAX_SIZE_EXCEEDED_MESSAGE}</Message.Header>
          <Message.List>
            {!_isEmpty(maxSizeExceededErrorMessages) &&
              maxSizeExceededErrorMessages.map((e) => <li key={e}>{e}</li>)}
          </Message.List>
          <Message.Content className="image-upload-error-message-footer">
            <>
              {MAX_SIZE_EXCEEDED_SUGGESTION_MESSAGE}
              &nbsp;
              <ExternalAnchor href={MAX_IMG_SIZE_EXCEEDED_SUGGESTION_URL}>
                {MAX_IMG_SIZE_EXCEEDED_SUGGESTION_URL}
              </ExternalAnchor>
            </>
          </Message.Content>
        </Message>
      ),
    };

    return _get(messageMap, errorKey, defaultMessage);
  }

  return (
    <>
      <Form.Group unstackable className="file-search-bar">
        <Form.Field width={4}>
          <FileSourceFilter filter={sourceFilter} onFilterChange={onSourceFilterChange} />
        </Form.Field>
        <Form.Field width={12}>
          <Input
            placeholder="Search"
            icon="search"
            value={searchValue}
            onChange={handleSearchChange}
            className="business-file-search-bar"
            loading={isSearching}
            onFocus={onSearchFocus}
            onBlur={onSearchBlur}
          />
        </Form.Field>
      </Form.Group>
      {errorMessages &&
        Object.keys(errorMessages).map((errorTitle) => errorMessageToDisplayMap(errorTitle))}
    </>
  );
}

SearchBar.propTypes = propTypes;
SearchBar.defaultProps = defaultProps;
