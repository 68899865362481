export const DEFAULT_SCHEMA_VERSION = '0.0.0';

export const SEMVER_REGX = /([0-9]+)\.([0-9]+)\.([0-9]+)/;

export const OBJECT_TYPE_NAMES = [
  'Layout',
  'Page',
  'Component',
  'Prop',
  'Value',
  'Plugin',
  'PluginOption',
  'Function',
  'FunctionArgument',
  'Condition',
  'CSS',
];

export const PARTIAL_TABLE_HEADER_NAMES = ['Name', 'Description', 'Object Type', 'Tags', 'Version'];

export const PARTIAL_FIELDS_DEFAULT_SHAPE = {
  name: '',
  schemaVersion: DEFAULT_SCHEMA_VERSION,
  type: '',
  tags: [],
  description: '',
};

export const MESSAGE_TIMEOUT_SECONDS = 4000;
export const JSON_MIME_TYPE = 'application/json';
export const JSON_EXTENSION = 'json';
export const JSON_FILE_ERROR_MESSAGE = 'Please upload a valid json file';
export const UPLOAD_FILE_LABEL = 'Upload File';
export const DEF_ERROR_MESSAGE = 'An unexpected error has occurred';
export const CSS_KEY = 'css';
export const JSON_KEY = 'json';
