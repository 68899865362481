import { useState } from 'react';

import useAsyncEffect from '../../components/modules/foundation/hooks/use-async-effect';
import API from '../../libs/api';

export default function useOrderReports(initialFilters) {
  const [filters, setFilters] = useState(initialFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [report, setReport] = useState({
    orders: [],
    summary: {},
  });

  const { orders, summary } = report;

  async function fetchReport() {
    const orderFilters = {
      ...filters,
      page: currentPage,
    };

    try {
      setLoading(true);
      setErrorMessage('');
      const paginatedOrders = await API.getOrderReports(orderFilters);

      const { total_pages: nextTotalPages, results } = paginatedOrders;
      const { orders: nextOrders, summary: nextSummary } = results;

      setTotalPages(nextTotalPages);
      setReport({
        orders: nextOrders,
        summary: nextSummary,
      });
    } catch (e) {
      setErrorMessage('Unexpected issue fetching orders');
    } finally {
      setLoading(false);
    }
  }

  useAsyncEffect(async () => {
    await fetchReport();
  }, [filters, currentPage]);

  return {
    orders,
    summary,
    currentPage,
    totalPages,
    loading,
    errorMessage,
    filters,
    setFilters,
    setCurrentPage,
    fetchReport,
  };
}
