import React from 'react';

import { Container, Input, Pagination, Segment, List, Button, Message } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import BusinessListItem from './components/BusinessListItem';
import { isAuthorizedForSaleViews, isAuthorizedForWaaS, isAdminUser } from '../../../libs/auth';
import isSmallScreen from '../../../libs/screens';
import { selectUserRoleForBusiness } from '../../../selectors/business';
import BusinessSelectFilter from '../BusinessSelectFilter';

import './BusinessSelectForm.scss';

class BusinessSelectForm extends React.Component {
  renderLogout() {
    const { user, userRole, signOut, signUp, totalPages } = this.props;
    const canCreate = isAuthorizedForSaleViews(userRole) || isAdminUser(user);

    if (totalPages > 0) {
      return (
        <>
          <Button floated="left" negative onClick={() => signOut()}>
            Logout
          </Button>
          {isAuthorizedForWaaS(user) && (
            <Button floated="right" primary as={Link} to="/sign-up">
              Create Website
            </Button>
          )}
        </>
      );
    }
    if (canCreate) {
      return (
        <>
          <Message attached="top">
            You can{' '}
            <Link to="/" onClick={() => signOut()}>
              Logout
            </Link>{' '}
            or create a new Website.
          </Message>
          <Button onClick={() => signUp()} attached="bottom" primary>
            Create Website
          </Button>
        </>
      );
    }

    return (
      <>
        <Button fluid negative onClick={() => signOut()}>
          Logout
        </Button>
      </>
    );
  }

  render() {
    const {
      user,
      businessLoading,
      onBusinessSearchChange,
      onBusinessFilterChange,
      handleBusinessClick,
      businesses,
      totalPages = 0,
      page = 1,
      onPageChange,
      selectedFilters,
      isNextWebsite,
    } = this.props;

    const isAdmin = isAdminUser(user);

    return (
      <div className="dispatch-form business-select-form">
        <Segment loading={businessLoading}>
          {totalPages > 0 ? (
            <>
              <Container as="h2" textAlign="center">
                Select Business
              </Container>
              <div className="search-container">
                <Input
                  fluid
                  icon="search"
                  placeholder="Search..."
                  onChange={onBusinessSearchChange}
                  loading={businessLoading}
                />
                {isAdmin && (
                  <BusinessSelectFilter
                    selectedFilters={selectedFilters}
                    onFilterChange={onBusinessFilterChange}
                  />
                )}
              </div>
              <List selection divided>
                {_isEmpty(businesses) && <Message color="red">No businesses found</Message>}
                {!_isEmpty(businesses) &&
                  businesses.map((business, index) => {
                    const { id, processing, website } = business;
                    const { cloudformation_stack_name: stackName } = website;

                    let listItemStyle = { cursor: 'pointer' };

                    if (processing) {
                      listItemStyle = { ...listItemStyle, opacity: 0.5 };
                    }

                    return (
                      <BusinessListItem
                        key={`${id}_${stackName}`}
                        user={user}
                        isAdmin={isAdmin}
                        business={business}
                        website={website}
                        isNextWebsite={isNextWebsite}
                        index={index}
                        listItemStyle={listItemStyle}
                        handleBusinessClick={handleBusinessClick}
                      />
                    );
                  })}
              </List>
              <Pagination
                onPageChange={(e, { activePage }) => onPageChange(false, activePage)}
                activePage={page}
                totalPages={totalPages}
                siblingRange={1}
                boundaryRange={isSmallScreen() ? 0 : 1}
                ellipsisItem={isSmallScreen() ? null : '...'}
              />
              <Container className="container-logout">{this.renderLogout()}</Container>
            </>
          ) : (
            <Container textAlign="center" style={{ padding: '1rem' }}>
              <h3 style={{ marginBottom: '1.5rem' }}>
                Sorry, we could not find any businesses for this account.
              </h3>
              {this.renderLogout()}
            </Container>
          )}
        </Segment>
      </div>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ business, website }) => ({
  isNextWebsite: _get(website, 'isNextWebsite'),
  userRole: selectUserRoleForBusiness({ business }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSelectForm);
