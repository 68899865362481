import _isEmpty from 'lodash/isEmpty';

export function getPagesCopy(page) {
  const { pages } = page;
  if (_isEmpty(pages)) {
    return '0 items';
  }

  if (pages.length === 1) {
    return `${pages.length} item`;
  }

  return `${pages.length} items`;
}
