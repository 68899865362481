import PropTypes from 'prop-types';

import {
  CHOWNOW_SALES_GROUP_NAME,
  EZCATER_SALES_GROUP_NAME,
  CLOVER_SALES_GROUP_NAME,
  BBOT_SALES_GROUP_NAME,
  TOAST_SALES_GROUP_NAME,
  ZUPPLER_SALES_GROUP_NAME,
  DAY_SMART_SALES_GROUP_NAME,
} from '../../constants/auth';

export const INBOUND_LEAD_SOURCE = 'Inbound';
export const INBOUND_ADS_LEAD_SOURCE = 'InboundAds';
export const INBOUND_FISHERMAN_LEAD_SOURCE = 'InboundFisherman';
export const INBOUND_GLAMMATIC_LEAD_SOURCE = 'InboundGlammatic';
export const INBOUND_GLAMMATIC_SOLO_LEAD_SOURCE = 'InboundGlammaticSolo';
export const INBOUND_SOCIAL_LEAD_SOURCE = 'InboundSocial';
export const CHOWNOW_LEAD_SOURCE = 'ChowNow';
export const EZCATER_LEAD_SOURCE = 'ezCater';
export const SHIFT4_LEAD_SOURCE = 'Shift4';
export const CLOVER_LEAD_SOURCE = 'Clover';
export const ORDERING_APP_LEAD_SOURCE = 'The Ordering.app';
export const HELLO_ALICE_LEAD_SOURCE = 'Hello Alice';
export const TOAST_LEAD_SOURCE = 'Toast';
export const ONE_VALLEY_LEAD_SOURCE = 'OneValley/SAMCEDA';
export const US_FOODS_LEAD_SOURCE = 'US Foods';
export const HARBOR_TOUCH_LEAD_SOURCE = 'Harbor Touch';
export const TABLE_TAB_LEAD_SOURCE = 'Table Tab';
export const BBOT_LEAD_SOURCE = 'Bbot';
export const RESTO_LABS_LEAD_SOURCE = 'Resto Labs';
export const UNTAPPD_LEAD_SOURCE = 'Untappd';
export const TRIP_ADVISOR_LEAD_SOURCE = 'TripAdvisor';
export const ALLIANCE_TEXAS_LEAD_SOURCE = 'The Alliance Texas';
export const ZUPPLER_LEAD_SOURCE = 'Zuppler';
export const SYSCO_LEAD_SOURCE = 'Sysco';
export const DAY_SMART_LEAD_SOURCE = 'DaySmart';
export const DAY_SMART_REFERRAL_LEAD_SOURCE = 'DaySmart Referral';
export const SALON_INTERACTIVE_LEAD_SOURCE = 'SalonInteractive';
export const STX_LEAD_SOURCE = 'STX';
export const PHENIX_LEAD_SOURCE = 'Phenix';
export const CANVAS_ME_LEAD_SOURCE = 'CanvasMe';
export const ALIGNABLE_LEAD_SOURCE = 'Alignable';
export const CONFERENCE_OR_EVENT_LEAD_SOURCE = 'ConferenceOrEvent';
export const BOULEVARD_LEAD_SOURCE = 'Boulevard';
export const COLD_LEAD_SOURCE = 'Cold';
export const CRA_LEAD_SOURCE = 'CRA';
export const MENUFY_LEAD_SOURCE = 'Menufy';
export const KATALYST_LEAD_SOURCE = 'Katalyst';
export const LOREAL_LEAD_SOURCE = "L'Oreal";
export const COACH_CONSULTANT_LEAD_SOURCE = 'CoachConsult';
export const BEAUTY_SCHOOL_LEAD_SOURCE = 'BeautySchool';
export const BEAUTY_ASSOC_LEAD_SOURCE = 'BeautyAssociation';
export const PHOREST_LEAD_SOURCE = 'Phorest';
export const MEEVO_LEAD_SOURCE = 'Meevo';
export const OTHER_LEAD_SOURCE = 'Other';
export const SQUARE_FISHERMAN_LEAD_SOURCE = 'SquareFisherman';
export const SQUARE_GLAMMATIC_LEAD_SOURCE = 'SquareGlammatic';

// New constants to define for GLAMMATIC SOURCES
export const COLD_GLAMMATIC_LEAD_SOURCE = 'ColdGlammatic';
export const EVENT_GLAMMATIC_LEAD_SOURCE = 'EventGlammatic';
export const ADS_GLAMMATIC_LEAD_SOURCE = 'AdsGlammatic';
export const OTHER_GLAMMATIC_LEAD_SOURCE = 'OtherGlammatic';

// New constants to define for FISHERMAN SOURCES
export const EVENT_FISHERMAN_LEAD_SOURCE = 'EventFisherman';
export const CONSULTANT_FISHERMAN_LEAD_SOURCE = 'ConsultantFisherman';

export const LEAD_SOURCES_ALTERNATIVE_NAME_MAP = {
  [INBOUND_FISHERMAN_LEAD_SOURCE]: 'Inbound - Fisherman',
  [INBOUND_GLAMMATIC_LEAD_SOURCE]: 'Inbound - Glammatic',
  [INBOUND_GLAMMATIC_SOLO_LEAD_SOURCE]: 'Inbound - Glammatic (Solopreneur)',
  [LOREAL_LEAD_SOURCE]: "L'Oreal (LOreal)",
  [DAY_SMART_REFERRAL_LEAD_SOURCE]: 'DaySmart',
  [CONFERENCE_OR_EVENT_LEAD_SOURCE]: 'Conference Or Event',
  [BEAUTY_SCHOOL_LEAD_SOURCE]: 'Beauty Schools',
  [BEAUTY_ASSOC_LEAD_SOURCE]: 'Beauty Associations',
  [COLD_GLAMMATIC_LEAD_SOURCE]: 'Cold - Glammatic',
  [COLD_LEAD_SOURCE]: 'Cold - Fisherman',
  [EVENT_GLAMMATIC_LEAD_SOURCE]: 'Event - Glammatic',
  [EVENT_FISHERMAN_LEAD_SOURCE]: 'Event - Fisherman',
  [COACH_CONSULTANT_LEAD_SOURCE]: 'Consultant - Glammatic',
  [CONSULTANT_FISHERMAN_LEAD_SOURCE]: 'Consultant - Fisherman',
  [ADS_GLAMMATIC_LEAD_SOURCE]: 'Ads - Glammatic',
  [INBOUND_ADS_LEAD_SOURCE]: 'Ads - Fisherman',
  [OTHER_GLAMMATIC_LEAD_SOURCE]: 'Other - Glammatic',
  [OTHER_LEAD_SOURCE]: 'Other - Fisherman',
  [SQUARE_FISHERMAN_LEAD_SOURCE]: 'Square - Fisherman',
  [SQUARE_GLAMMATIC_LEAD_SOURCE]: 'Square - Glammatic',
};

export const LEAD_SOURCES = [
  INBOUND_GLAMMATIC_LEAD_SOURCE,
  INBOUND_GLAMMATIC_SOLO_LEAD_SOURCE,
  INBOUND_FISHERMAN_LEAD_SOURCE, // Replacing INBOUND_LEAD_SOURCE
  COLD_GLAMMATIC_LEAD_SOURCE,
  COLD_LEAD_SOURCE,
  EVENT_GLAMMATIC_LEAD_SOURCE,
  EVENT_FISHERMAN_LEAD_SOURCE,
  COACH_CONSULTANT_LEAD_SOURCE,
  CONSULTANT_FISHERMAN_LEAD_SOURCE,
  CHOWNOW_LEAD_SOURCE,
  DAY_SMART_REFERRAL_LEAD_SOURCE,
  SALON_INTERACTIVE_LEAD_SOURCE,
  STX_LEAD_SOURCE,
  PHENIX_LEAD_SOURCE,
  CANVAS_ME_LEAD_SOURCE,
  SHIFT4_LEAD_SOURCE,
  CLOVER_LEAD_SOURCE,
  TOAST_LEAD_SOURCE,
  LOREAL_LEAD_SOURCE,
  BEAUTY_SCHOOL_LEAD_SOURCE,
  BEAUTY_ASSOC_LEAD_SOURCE,
  PHOREST_LEAD_SOURCE,
  MEEVO_LEAD_SOURCE,
  BOULEVARD_LEAD_SOURCE,
  US_FOODS_LEAD_SOURCE,
  EZCATER_LEAD_SOURCE,
  ALIGNABLE_LEAD_SOURCE,
  CRA_LEAD_SOURCE,
  ZUPPLER_LEAD_SOURCE,
  SYSCO_LEAD_SOURCE,
  MENUFY_LEAD_SOURCE,
  OTHER_GLAMMATIC_LEAD_SOURCE,
  OTHER_LEAD_SOURCE,
  SQUARE_FISHERMAN_LEAD_SOURCE,
  SQUARE_GLAMMATIC_LEAD_SOURCE,
];

export const INTERNAL_SIGN_UP_LEAD_CHANEL = 'InternalSignUp';
export const REP_SIGN_UP_LEAD_CHANNEL = 'RepSignUp';
export const SIGN_UP_LEAD_CHANNEL = 'SignUp';

export const LEAD_CHANNELS = [
  INTERNAL_SIGN_UP_LEAD_CHANEL,
  REP_SIGN_UP_LEAD_CHANNEL,
  SIGN_UP_LEAD_CHANNEL,
];

export const LeadChannelPropTypes = PropTypes.oneOf(LEAD_CHANNELS);

export const SALES_GROUP_LEAD_SOURCE_MAP = {
  [CHOWNOW_SALES_GROUP_NAME]: CHOWNOW_LEAD_SOURCE,
  [EZCATER_SALES_GROUP_NAME]: EZCATER_LEAD_SOURCE,
  [CLOVER_SALES_GROUP_NAME]: CLOVER_LEAD_SOURCE,
  [BBOT_SALES_GROUP_NAME]: BBOT_LEAD_SOURCE,
  [TOAST_SALES_GROUP_NAME]: TOAST_LEAD_SOURCE,
  [ZUPPLER_SALES_GROUP_NAME]: ZUPPLER_LEAD_SOURCE,
  [DAY_SMART_SALES_GROUP_NAME]: DAY_SMART_REFERRAL_LEAD_SOURCE,
};
