import React from 'react';

import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';

import { PALETTES_PER_ROW } from './ColorPaletteGroup.constants';
import { ColorPalettePropType } from '../../ColorPaletteSelection.proptypes';
import ColorColumns from '../ColorColumns';
import SelectableCard from '../SelectableCard';

import './ColorPaletteGroup.scss';

const propTypes = {
  colorPalettes: PropTypes.arrayOf(ColorPalettePropType),
  selectedPaletteId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

const defaultProps = {
  colorPalettes: [],
  selectedPaletteId: null,
};

function ColorPaletteGroup({ colorPalettes, selectedPaletteId, onSelect }) {
  return (
    <Card.Group className="color-palette-group" itemsPerRow={PALETTES_PER_ROW}>
      {colorPalettes.map(({ id, name, colors = [] }) => {
        return (
          <SelectableCard
            key={id}
            onSelect={() => onSelect(id)}
            isSelected={selectedPaletteId === id}
          >
            <ColorColumns colors={colors} />
            <Card.Content extra>{name || `Palette #${id}`}</Card.Content>
          </SelectableCard>
        );
      })}
    </Card.Group>
  );
}

ColorPaletteGroup.propTypes = propTypes;
ColorPaletteGroup.defaultProps = defaultProps;

export default ColorPaletteGroup;
