import { SUPPORT_EMAIL } from '../../../constants/constants';

export const MIN_DAYS_TO_DISPLAY_ANALYTICS = 14;
export const ONLY_LIVE_WEBSITES_MESSAGE = 'Analytics are only available for Live websites';
export const NOT_ENOUGH_DATA_MESSAGE = `We don’t have enough data yet to display website analytics. Once the website has been live for ${MIN_DAYS_TO_DISPLAY_ANALYTICS} days, analytics will be available for viewing`;
export const ANALYTICS_NOT_AVAILABLE_MESSAGE = 'Analytics Not Currently Available';
export const ANALYTICS_NOT_AVAILABLE_FOR_WEBSITE =
  'This website does not currently support website analytics';
export const ANALYTICS_DEFAULT_MESSAGE = `Analytics is unavailable for this website. Please reach out to ${SUPPORT_EMAIL} for help.`;

export const EMBED_TITLE = 'Website Analytics';
export const EMBED_FRAME_BORDER = 0;
