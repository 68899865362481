import _startCase from 'lodash/startCase';

import { RECIPE_DISPLAY_NAME_MAP } from '../../../constants/recipes';

export function createRecipeNameDropdownOptions(recipes) {
  return [...new Set(recipes.map(({ name }) => name))].map((name) => ({
    text: RECIPE_DISPLAY_NAME_MAP[name] || _startCase(name),
    value: name,
    key: name,
  }));
}
