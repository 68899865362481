import { selectWebsitePages } from '../../../../selectors/website';
import { isExternalPage, isInternalPage } from '../services/pages';

export function selectExternalWebsitePages(state) {
  return selectWebsitePages(state).filter((page) => isExternalPage(page));
}

export function selectInternalWebsitePages(state) {
  return selectWebsitePages(state).filter((page) => isInternalPage(page));
}

export function selectPageByType(pageType) {
  return (state) =>
    selectWebsitePages(state).find(
      (page) => page.pageType.toLowerCase() === pageType.toLowerCase(),
    );
}
