import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const propTypes = {
  query: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  query: '',
  placeholder: '',
};

const RecipeSearch = ({ query, placeholder, onChange }) => {
  return <Input onChange={onChange} value={query} placeholder={placeholder} />;
};

RecipeSearch.propTypes = propTypes;

RecipeSearch.defaultProps = defaultProps;

export default RecipeSearch;
