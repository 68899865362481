import _isEmpty from 'lodash/isEmpty';

import { TAB_INDEX_DISPLAY_SETTINGS } from './FileEditorModal.constants';

export function getActiveIndex(businessFileConfiguration, newActiveIndex) {
  if (!_isEmpty(businessFileConfiguration)) {
    // We don't want to allow the user to change the tab
    return TAB_INDEX_DISPLAY_SETTINGS;
  }
  return newActiveIndex;
}
