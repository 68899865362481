import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Icon, Loader, Message } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  ALWAYS_OPEN,
  CUSTOM_HOURS,
  HOUR_GROUP_ORDER,
  USE_LOCATION,
  FULFILLMENT_TYPE_PICKUP,
} from './LocationSettingForm.constants';
import { replaceLocationHours as replaceLocationHoursConnect } from '../../../../../../../../../actions/business';
import {
  FULFILLMENT_PROVIDER_MERCHANT,
  FULFILLMENT_TYPE_INSTORE,
} from '../../../../../../../../../constants/fulfillments';
import API from '../../../../../../../../../libs/api';
import { selectBusiness, selectLocationByIndex } from '../../../../../../../../../selectors';
import useAsyncEffect from '../../../../../../../../modules/foundation/hooks/use-async-effect';
import OrderSettingsContext from '../../../../../OrderSettings.context';
import LocationSettingHoursForm from '../LocationSettingHoursForm';

import './LocationSettingForm.scss';

const propTypes = {
  locationIndex: PropTypes.number.isRequired,
};

function LocationSettingForm({ locationIndex }) {
  const business = useSelector(selectBusiness);
  const location = useSelector(selectLocationByIndex(locationIndex));
  const { updateSettings, getSettingsForLocation } = useContext(OrderSettingsContext);
  const [locationOrderHourGroup, setLocationOrderHourGroup] = useState(null);
  const [isSubmittingOrderHourGroup, setIsSubmittingOrderHourGroup] = useState(false);
  const [errorCreatingOrderHourGroup, setErrorCreatingOrderHourGroup] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const dispatch = useDispatch();
  const replaceLocationHours = (payload) => dispatch(replaceLocationHoursConnect(payload));

  const locationOrderSettings = getSettingsForLocation(
    location.id,
    FULFILLMENT_PROVIDER_MERCHANT,
    FULFILLMENT_TYPE_INSTORE,
  );
  const locationOrderSettingsExists = !_isEmpty(locationOrderSettings);
  const operatingHoursConfig = locationOrderSettings?.operating_hours_config;

  function updateLocationOperatingHoursConfig(locationId, operatingHoursConfigValue) {
    setIsTouched(true);
    setHasErrors(false);
    updateSettings({ location: locationId, operating_hours_config: operatingHoursConfigValue });
  }

  useAsyncEffect(async () => {
    const locationHourGroups = await API.getMerchantLocationHourGroups(location.id);
    const orderHourGroup = locationHourGroups.find(
      (hourGroup) => hourGroup.group_name === HOUR_GROUP_ORDER,
    );
    if (orderHourGroup) {
      setLocationOrderHourGroup(orderHourGroup);
    }

    if (!operatingHoursConfig) {
      updateLocationOperatingHoursConfig(location.id, USE_LOCATION);
    }
  }, []);

  function onHourEdit() {
    setIsTouched(true);
    setHasErrors(false);
  }

  function handleOnChange(e, { value }) {
    updateLocationOperatingHoursConfig(location.id, value);
  }

  async function handleSelectCustomHours() {
    updateLocationOperatingHoursConfig(location.id, CUSTOM_HOURS);
    if (!locationOrderHourGroup) {
      setIsSubmittingOrderHourGroup(true);
      setErrorCreatingOrderHourGroup(false);
      try {
        const orderHourGroup = await API.createMerchantLocationHourGroups(
          location.id,
          HOUR_GROUP_ORDER,
        );
        setLocationOrderHourGroup(orderHourGroup);
      } catch (e) {
        setErrorCreatingOrderHourGroup(true);
      } finally {
        setIsSubmittingOrderHourGroup(false);
      }
    }
  }

  async function handleSave() {
    setIsSubmitting(true);
    if (!locationOrderSettings.id) {
      locationOrderSettings.fulfillment_type = FULFILLMENT_TYPE_PICKUP;
    }
    try {
      const orderSettingsSavedData = await API.saveOrderSettings(locationOrderSettings);
      updateSettings(orderSettingsSavedData);
    } catch (e) {
      setHasErrors(true);
    }
    try {
      await API.updateLocation(business.id, business.type, location.id, {
        timezone: location.timezone,
      });
    } catch (e) {
      setHasErrors(true);
    }
    try {
      const completeHours = location.hours.filter((hour) => hour.open && hour.close);
      const locationHoursSavedData = await API.batchProcessMerchantLocationHourGroups(
        completeHours,
      );
      replaceLocationHours({
        index: locationIndex,
        location,
        hours: locationHoursSavedData,
        bypassTouch: true,
      });
    } catch (e) {
      setHasErrors(true);
    }
    setIsSubmitting(false);
    setIsTouched(false);
  }

  if (!locationOrderSettingsExists) {
    return (
      <Message>
        There is no order settings available for this location. Visit fulfillment section to add
        pickup settings for this location before configuring ordering hours
      </Message>
    );
  }

  if (!operatingHoursConfig) {
    return null;
  }

  const isCustomHoursSelected = operatingHoursConfig === CUSTOM_HOURS;

  return (
    <section className="location-setting-form">
      <Form>
        <Form.Group grouped widths="equal">
          <Form.Field className="location-setting-input">
            <Checkbox
              radio
              label={{ children: 'Always Open' }}
              value={ALWAYS_OPEN}
              checked={operatingHoursConfig === ALWAYS_OPEN}
              onChange={handleOnChange}
            />
          </Form.Field>
          <Form.Field className="location-setting-input">
            <Checkbox
              radio
              label={{
                children: (
                  <p>
                    Use hours set from <Link to="/core/locations">website locations</Link>
                  </p>
                ),
              }}
              value={USE_LOCATION}
              checked={operatingHoursConfig === USE_LOCATION}
              onChange={handleOnChange}
            />
          </Form.Field>
          <Form.Radio
            className="location-setting-input"
            label="Custom hours"
            value={CUSTOM_HOURS}
            checked={operatingHoursConfig === CUSTOM_HOURS}
            onChange={handleSelectCustomHours}
          />
        </Form.Group>
      </Form>
      {isCustomHoursSelected && !isSubmittingOrderHourGroup && (
        <LocationSettingHoursForm
          locationIndex={locationIndex}
          orderHourGroup={locationOrderHourGroup}
          onEdit={onHourEdit}
        />
      )}
      {isCustomHoursSelected && isSubmittingOrderHourGroup && <Loader active />}
      {!isCustomHoursSelected && errorCreatingOrderHourGroup && (
        <Message
          negative
          content="There was an issue setting custom hours, please try again later or contact the support team."
        />
      )}
      {hasErrors && (
        <Message
          negative
          content="There was an error saving information. Check your changes and try again."
        />
      )}
      <Button
        disabled={!hasErrors && (!isTouched || isSubmitting)}
        loading={isSubmitting}
        className="submit-button"
        onClick={handleSave}
      >
        <Icon name="save" />
        Save
      </Button>
    </section>
  );
}

LocationSettingForm.propTypes = propTypes;

export default LocationSettingForm;
