import { useState } from 'react';

export default function useErrorMessages(initialError) {
  const [errorMessages, setErrorMessages] = useState(initialError ? { initialError: null } : {});

  const setErrorMessage = (errorTitle, errorList) => {
    if (!errorTitle) return;
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [errorTitle]: [...(prevErrorMessages[errorTitle] || []), ...(errorList || [])],
    }));
  };

  const clearErrorMessages = () => setErrorMessages({});

  return [errorMessages, setErrorMessage, clearErrorMessages];
}
