import React from 'react';

import { Button, Select, Table, Segment, Container, Message } from 'semantic-ui-react';

import _get from 'lodash/get';

import '../../styles/GMBLocationList.scss';

class GMBLocationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gmbLocationMap: {},
      isDirty: false,
    };

    this.handleFishermanLocationSelect = this.handleFishermanLocationSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getLocationOptions(locations) {
    const locationOptions = locations.map((l) => {
      const locationDisplay = l.location_name
        ? `${l.location_name} (${l.street}, ${l.city})`
        : `${l.street}, ${l.city}`;
      return {
        value: l.id,
        key: l.id,
        text: locationDisplay,
      };
    });

    // Don't allow selection of 'none' option
    locationOptions.unshift({
      value: null,
      key: -1,
      text: 'None',
    });

    return locationOptions;
  }

  componentDidMount() {
    this.attemptLocationMapping();
  }

  handleFishermanLocationSelect(gmbLocationId, fishermanLocationId) {
    const { gmbLocationMap } = this.state;
    const isDirty =
      fishermanLocationId !== null ||
      Object.keys(gmbLocationMap).reduce(
        (accumIsDirty, key) =>
          (key !== gmbLocationId && gmbLocationMap[key] !== null) || accumIsDirty,
        false,
      );

    this.setState({
      gmbLocationMap: {
        ...gmbLocationMap,
        [gmbLocationId]: fishermanLocationId,
      },
      isDirty,
    });
  }

  handleSubmit() {
    const { onLocationsAssociation, gmbAccountId } = this.props;
    const { gmbLocationMap } = this.state;
    const data = Object.keys(gmbLocationMap)
      .map((gmbLocationId) => {
        return {
          gmb_location_id: gmbLocationId,
          gmb_account_id: gmbAccountId,
          fisherman_location_id: gmbLocationMap[gmbLocationId],
        };
      })
      .filter((d) => d.fisherman_location_id !== null);

    onLocationsAssociation(data);
  }

  getSelectableLocations(gmbLocation) {
    // Given a gmbLocation it returns valid location options for a select component
    const { fishermanLocations } = this.props;
    const { gmbLocationMap } = this.state;
    const pairedLocationIds = new Set(
      Object.values(gmbLocationMap).reduce(
        (locationIds, locationId) =>
          locationId !== gmbLocationMap[gmbLocation.location_id]
            ? [...locationIds, locationId]
            : locationIds,
        [],
      ),
    );
    const availableLocations = fishermanLocations.filter(
      (location) => !pairedLocationIds.has(location.id),
    );
    return GMBLocationList.getLocationOptions(availableLocations);
  }

  attemptLocationMapping() {
    const { gmbLocations, fishermanLocations } = this.props;

    gmbLocations.forEach((location) => {
      const match = fishermanLocations.find((fishermanLocation) => {
        const street = _get(location, 'storefrontAddress.addressLines[0]');
        const locality = _get(location, 'storefrontAddress.locality');
        const postalCode = _get(location, 'storefrontAddress.postalCode');

        return (
          fishermanLocation.city === locality &&
          fishermanLocation.street === street &&
          fishermanLocation.zip_code === postalCode
        );
      });

      if (match) {
        this.handleFishermanLocationSelect(location.location_id, match.id);
      }
    });
  }

  render() {
    const { gmbLocations, handleDisconnect } = this.props;
    const { gmbLocationMap, isDirty } = this.state;

    if (gmbLocations && gmbLocations.length === 0) {
      return (
        <Segment attached="bottom">
          <Message negative>
            <Message.Header>No Google Business Profile locations associated</Message.Header>
            The selected Google account doesn&apos;t have any Google Business Profile locations
            associated. Please disconnect and try another Google account.
          </Message>

          <Container textAlign="right">
            <Button negative onClick={handleDisconnect}>
              Disconnect
            </Button>
          </Container>
        </Segment>
      );
    }

    return (
      <Segment attached="bottom">
        <Table singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Business Location</Table.HeaderCell>
              <Table.HeaderCell>Confirm Location</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {gmbLocations.map((gmbLocation) => (
              <Table.Row key={gmbLocation.location_id}>
                <Table.Cell className="location-detail">
                  <p>{gmbLocation.title}</p>
                  <p className="location-address">
                    {gmbLocation.storefrontAddress &&
                      (gmbLocation.storefrontAddress.addressLines.join(' '),
                      gmbLocation.storefrontAddress.locality)}
                    {!gmbLocation.storefrontAddress && 'No address'}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <Select
                    placeholder="Select a location"
                    value={gmbLocationMap[gmbLocation.location_id]}
                    onChange={(e, { value }) =>
                      this.handleFishermanLocationSelect(gmbLocation.location_id, value)
                    }
                    options={this.getSelectableLocations(gmbLocation)}
                    disabled={false}
                    fluid={false}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Container textAlign="right">
          <Button disabled={!isDirty} onClick={this.handleSubmit} primary>
            Confirm
          </Button>
        </Container>
      </Segment>
    );
  }
}

export default GMBLocationList;
