import React, { forwardRef, useRef, useImperativeHandle } from 'react';

import PropTypes from 'prop-types';

import ToastUIImageEditor from '@toast-ui/react-image-editor';

// eslint-disable-next-line import/order
import 'tui-image-editor/dist/tui-image-editor.css';

import { THEME_WHITE, THEME_BLACK, whiteTheme, blackTheme } from './ImageEditor.constants';

import './ImageEditor.scss';

const propTypes = {
  imageUrl: PropTypes.string.isRequired,
  theme: PropTypes.oneOf([THEME_WHITE, THEME_BLACK]),
};
const defaultProps = {
  theme: THEME_WHITE,
};

const ImageEditor = forwardRef(function ImageEditor({ imageUrl, theme }, ref) {
  const imageEditorRef = useRef(null);
  const themeMap = theme === THEME_WHITE ? whiteTheme : blackTheme;

  useImperativeHandle(
    ref,
    () => ({
      getImage: async (options) =>
        new Promise((resolve) => {
          setTimeout(() => {
            const dataUrl = imageEditorRef.current.getInstance().toDataURL(options);
            resolve(dataUrl);
          }, 0);
        }),
      loadImageFromURL: (url, filename) =>
        imageEditorRef.current.getInstance().loadImageFromURL(url, filename),
    }),
    [],
  );

  return (
    <div className={`image-editor-container ${theme}`}>
      <ToastUIImageEditor
        ref={imageEditorRef}
        usageStatistics={false}
        includeUI={{
          theme: themeMap,
          loadImage: {
            path: imageUrl,
            name: 'Image',
          },
          uiSize: {
            width: '100%',
            height: '100%',
          },
        }}
      />
    </div>
  );
});

export default ImageEditor;

ImageEditor.propTypes = propTypes;
ImageEditor.defaultProps = defaultProps;
