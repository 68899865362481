import React from 'react';

import PropTypes from 'prop-types';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import CloseableModal from './CloseableModal';
import InformationModal from './InformationModal/InformationModal';
import {
  initializeMenu as initializeMenuConnect,
  flagMenuSaved as flagMenuSavedConnect,
} from '../../actions/business';
import { DESTINATION_MENU } from '../../constants/menu';
import API from '../../libs/api';
import { isAuthorized } from '../../libs/auth';
import { UserPropType } from '../../libs/proptypes';
import { InventoryConsumer } from '../core/MenuForm/Menu.context';
import { loadMenu } from '../core/menu/libs/menu-helpers';
import { CatalogPropType } from '../modules/catalog/proptypes/catalog';
import { CatalogObjectGenericPropType } from '../pages/ecommerce/catalog/proptypes';

import '../../styles/core/components/menu-upload-confirm-delete.scss';

const propTypes = {
  initializeMenu: PropTypes.func.isRequired,
  flagMenuSaved: PropTypes.func.isRequired,
  user: UserPropType.isRequired,
  menu: CatalogPropType.isRequired,
  businessId: PropTypes.number.isRequired,
  businessType: PropTypes.string.isRequired,
  destination: PropTypes.string,
  onClose: PropTypes.func,
  catalog: PropTypes.arrayOf(CatalogObjectGenericPropType),
  children: PropTypes.func.isRequired,
  isLoadingCatalog: PropTypes.bool,
};

const defaultProps = {
  destination: DESTINATION_MENU,
  onClose: null,
  catalog: [],
  isLoadingCatalog: false,
};

class MenuUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileInput: null,
      displayingConfirmModal: false,
      displayingCompleteModal: false,
      uploadingFile: false,
      uploadSuccess: false,
    };

    this.fileInput = React.createRef();
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setFileInput = this.setFileInput.bind(this);
    this.openFileSelectDialog = this.openFileSelectDialog.bind(this);
    this.startFileSelectProcess = this.startFileSelectProcess.bind(this);
    this.menuHasSchedules = this.menuHasSchedules.bind(this);
    this.onCloseInformationModal = this.onCloseInformationModal.bind(this);
  }

  async handleUpdate(event) {
    const {
      businessId,
      businessType,
      initializeMenu,
      flagMenuSaved,
      destination = DESTINATION_MENU,
    } = this.props;

    const { fileInput, replaceMenu } = this.state;
    const fileReader = new FileReader();

    this.setState({
      uploadingFile: true,
      displayingConfirmModal: false,
    });

    fileReader.onloadend = async () => {
      try {
        fileInput.value = '';
        await API.uploadMenuJsonFile(businessId, businessType, {
          data: fileReader.result,
          replaceMenu,
          destination,
        });

        await loadMenu({
          businessType,
          businessId,
          successCb: initializeMenu,
        });
        this.setState({
          uploadSuccess: true,
          uploadErrorMsg: null,
        });
      } catch (error) {
        const uploadErrorMsg = _get(
          error,
          'response.data.message',
          'There was an issue Uploading your File',
        );

        this.setState({
          uploadSuccess: false,
          uploadErrorMsg,
        });
      } finally {
        this.setState({
          uploadingFile: false,
          displayingCompleteModal: true,
        });
        flagMenuSaved(true);
      }
    };

    fileReader.readAsText(event.target.files[0]);
  }

  async onCloseInformationModal() {
    const { onClose } = this.props;
    this.setState({
      displayingCompleteModal: false,
      uploadErrorMsg: null,
    });
    if (onClose) {
      await onClose();
    }
  }

  setFileInput(e) {
    this.setState({
      fileInput: e,
    });
  }

  menuHasSchedules() {
    const { destination, menu, catalog } = this.props;
    if (destination === DESTINATION_MENU) {
      return !_isEmpty(menu.schedules);
    }
    return !_isEmpty(catalog);
  }

  startFileSelectProcess() {
    if (this.menuHasSchedules()) {
      this.setState({
        displayingConfirmModal: true,
      });
    } else {
      this.openFileSelectDialog();
    }
  }

  openFileSelectDialog(replaceMenu = true) {
    const { fileInput } = this.state;
    this.setState({
      displayingConfirmModal: false,
      replaceMenu,
    });

    fileInput.click();
  }

  render() {
    const {
      displayingConfirmModal,
      uploadingFile,
      displayingCompleteModal,
      uploadSuccess,
      uploadErrorMsg,
    } = this.state;
    const { user, children, isLoadingCatalog } = this.props;
    const canUploadMenuJson = isAuthorized(user, {}, ['admin']);

    if (!canUploadMenuJson) {
      return null;
    }

    return (
      <InventoryConsumer>
        {({ entityName }) => (
          <>
            <input
              style={{ display: 'none' }}
              ref={this.setFileInput}
              type="file"
              onChange={this.handleUpdate}
              accept="application/json"
              multiple={false}
            />

            {children({
              entityName,
              isLoading: uploadingFile || isLoadingCatalog,
              onClick: this.startFileSelectProcess,
            })}

            <CloseableModal
              open={displayingConfirmModal}
              onClose={() => this.setState({ displayingConfirmModal: false })}
              className="menu-upload-confirm-delete"
              header={`Replace Existing ${entityName}`}
              size="tiny"
            >
              <Modal.Content>
                <p>Do you want to replace the existing {entityName}?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button negative onClick={() => this.openFileSelectDialog(true)}>
                  Replace {entityName}
                </Button>
                <Button positive onClick={() => this.openFileSelectDialog(false)}>
                  Add to {entityName}
                </Button>
              </Modal.Actions>
            </CloseableModal>

            <InformationModal
              open={displayingCompleteModal}
              onClose={this.onCloseInformationModal}
              className="menu-upload-confirm-delete"
              header={uploadSuccess ? 'Success!' : 'Error!'}
            >
              <p>
                {uploadSuccess
                  ? `Your ${entityName} was uploaded successfully!`
                  : `Error uploading your ${entityName}. Please check your file and try again.`}
              </p>
              {!uploadSuccess && <p style={{ color: 'gray' }}>Message: {uploadErrorMsg}</p>}
            </InformationModal>

            <Modal open={uploadingFile} basic>
              <Dimmer active inverted>
                <Loader inverted>Uploading {entityName}...</Loader>
              </Dimmer>
            </Modal>
          </>
        )}
      </InventoryConsumer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  initializeMenu: (payload) => dispatch(initializeMenuConnect(payload)),
  flagMenuSaved: (payload) => dispatch(flagMenuSavedConnect(payload)),
});

const mapStateToProps = (state) => ({
  user: _get(state, 'user.core.value'),
  menu: _get(state, 'business.menu.value'),
  businessId: _get(state, 'business.core.value.id'),
  businessType: _get(state, 'business.core.value.type'),
});

MenuUpload.propTypes = propTypes;
MenuUpload.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(MenuUpload);
