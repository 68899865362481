import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import '../../styles/core/forms/generic.scss';

const AddItemField = ({ description, onAdd }) => (
  <div className="add-item" role="button" onClick={onAdd} onKeyDown={onAdd} tabIndex={-1}>
    <Icon name="plus square outline" color="green" size="big" />
    <h3>{description}</h3>
  </div>
);

AddItemField.propTypes = {
  description: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddItemField;
