import React, { useContext } from 'react';

import { Label } from 'semantic-ui-react';

import { getPagesCopy } from './NavigationItemTitle.utils';
import { isButtonPage, isExternalPage } from '../../../../../pages/services/pages';
import { PagePropType } from '../../../../../pages/types/page';
import { NavigationContext } from '../../../../context/Navigation.context';

import './NavigationItemTitle.scss';

const propTypes = { page: PagePropType.isRequired };
const defaultProps = {};

export default function NavigationItemTitle({ page }) {
  const { navigation } = useContext(NavigationContext);
  const { getPageUrl } = navigation;

  const { title, name, isGroup } = page;

  const { protocol, pathname, host } = getPageUrl(page);

  const isExternal = isExternalPage(page);
  const isButton = isButtonPage(page);
  const pagesCopy = getPagesCopy(page);

  const url = host + pathname;

  return (
    <div className="navigation-item-title">
      <div className="title">
        {title || name} {isExternal && <Label content="External link" />}
        {isButton && <Label content="Button" />}
      </div>
      {!isGroup && (
        <div className="url">
          <span className="protocol">{`${protocol}//`}</span>
          {url}
        </div>
      )}
      {isGroup && <div className="items">{pagesCopy}</div>}
    </div>
  );
}

NavigationItemTitle.propTypes = propTypes;
NavigationItemTitle.defaultProps = defaultProps;
