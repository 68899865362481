import React from 'react';

import { logOut } from '../../libs/auth';
import Storage from '../../libs/storage';
import WithRouter from '../modules/foundation/components/WithRouter/WithRouter';

class TokenValidator extends React.Component {
  componentDidMount() {
    this.timerId = this.willTokenExpireSoon();
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  willTokenExpireSoon = () => {
    const { navigate } = this.props;
    return setInterval(async () => {
      const expirationSeconds = Storage.tokenExpirationSeconds();

      if (expirationSeconds < Storage.SECONDS_FOR_NOTIFICATION) {
        await logOut(navigate, '/');
      }
    }, 1000); // every second
  };

  render() {
    return null;
  }
}

export default WithRouter(TokenValidator);
