import React, { useState } from 'react';

import PropTypes from 'prop-types';

import _get from 'lodash/get';

import { CatalogCategoryPropType } from '../../../proptypes';
import { updateCatalogObjectData } from '../../../utils';
import CategoryDetail from '../CategoryDetail';
import CategoryForm from '../CategoryForm';

import './CategoryToggle.scss';

const propTypes = {
  category: CatalogCategoryPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancelSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function CategoryToggle({ category, onSubmit, onCancelSubmit, onDelete }) {
  const hasName = !!_get(category, 'category_data.name');
  const [shouldShowDetails, setShouldShowDetails] = useState(hasName);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState(false);

  function onToggleShowDetails() {
    setShouldShowDetails((prevShouldShowDetails) => !prevShouldShowDetails);
    onCancelSubmit(category);
  }

  async function onCategoryFormSubmit(categoryName, categoryDescription) {
    setIsSubmitting(true);
    setErrors(false);
    const data = updateCatalogObjectData(category, 'category_data', {
      name: categoryName,
      description: categoryDescription,
    });
    try {
      await onSubmit(data);
      onToggleShowDetails();
    } catch (e) {
      setErrors(true);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onCategoryDelete() {
    onDelete(category);
  }

  return (
    <section>
      {shouldShowDetails ? (
        <CategoryDetail category={category} onClick={onToggleShowDetails} />
      ) : (
        <CategoryForm
          category={category}
          isSubmitting={isSubmitting}
          onSubmit={onCategoryFormSubmit}
          onCancel={onToggleShowDetails}
          onDelete={onCategoryDelete}
          errors={errors}
        />
      )}
    </section>
  );
}

CategoryToggle.propTypes = propTypes;

export default CategoryToggle;
