import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import { isJsonFile } from './PartialFileInput.utils';
import {
  JSON_FILE_ERROR_MESSAGE,
  UPLOAD_FILE_LABEL,
} from '../../../PartialPage/PartialPage.constants';

const PartialFileInput = ({ onChange, onError, ...rest }) => {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if (isJsonFile(file)) {
      onChange(file);
    } else {
      onError(JSON_FILE_ERROR_MESSAGE);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  return (
    <>
      <Button {...rest} content={UPLOAD_FILE_LABEL} onClick={handleClick} />
      <input
        ref={fileInput}
        type="file"
        hidden
        onChange={handleFileInput}
        accept=".json,application/json"
      />
    </>
  );
};

PartialFileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default PartialFileInput;
