import { useState } from 'react';

import { FULFILLMENT_TYPE } from './OrderSettings/OrderSettings.constants';

export function useOrderSettings(initialMerchantProduct, initialValue = []) {
  const [settings, setSettings] = useState(initialValue);
  const [merchantProduct, setMerchantProduct] = useState(initialMerchantProduct);

  function getSettingsForLocation(locationId, methodProvider, methodType) {
    return (
      settings.find(
        (settingsItem) =>
          settingsItem.location === locationId &&
          settingsItem.method?.provider === methodProvider &&
          settingsItem.method?.type === methodType,
      ) || null
    );
  }

  function updateSettings(settingsData) {
    const finalSettingsData = {
      ...settingsData,
      product: merchantProduct.id,
    };

    setSettings((prevSettings) => {
      const settingsDataIndex = prevSettings.findIndex(
        (settingsItem) => settingsItem.location === finalSettingsData.location,
      );
      if (settingsDataIndex >= 0) {
        return [
          ...prevSettings.slice(0, settingsDataIndex),
          {
            ...prevSettings[settingsDataIndex],
            ...finalSettingsData,
          },
          ...prevSettings.slice(settingsDataIndex + 1),
        ];
      }
      return [...prevSettings, finalSettingsData];
    });
  }

  return { settings, setSettings, updateSettings, getSettingsForLocation, setMerchantProduct };
}

export function useOrderSettingsFormValidation(initialValue = []) {
  const [formsValidation, setFormsValidation] = useState(initialValue);

  function getFormIndex(forms, locationId) {
    return forms.findIndex((validationForm) => validationForm.location === locationId);
  }

  function updateFormValidation(
    locationId = null,
    fieldName,
    isValid,
    initialValidation = { [FULFILLMENT_TYPE]: false },
  ) {
    setFormsValidation((prevValidation) => {
      const validationFormIndex = getFormIndex(prevValidation, locationId);
      if (validationFormIndex >= 0) {
        return [
          ...prevValidation.slice(0, validationFormIndex),
          {
            ...prevValidation[validationFormIndex],
            [fieldName]: isValid,
          },
          ...prevValidation.slice(validationFormIndex + 1),
        ];
      }
      return [
        ...prevValidation,
        { location: locationId, ...initialValidation, [fieldName]: isValid },
      ];
    });
  }

  function isOrderSettingsFormValid(locationId) {
    const validationFormIndex = getFormIndex(formsValidation, locationId);
    if (validationFormIndex < 0) {
      return false;
    }
    const form = formsValidation[validationFormIndex];
    return Object.keys(form).every((key) => key === 'location' || !!form[key]);
  }

  return { isOrderSettingsFormValid, updateFormValidation };
}

export function useShippingSettings() {
  const [shippingSettings, setShippingSettings] = useState({
    shippingProvider: '',
    orderTurnaroundTime: '',
  });
  const { shippingProvider, orderTurnaroundTime } = shippingSettings;

  function setSettings(settings) {
    setShippingSettings((prevSettings) => ({ ...prevSettings, ...settings }));
  }

  return {
    shippingProvider,
    orderTurnaroundTime,
    setShippingSettings: setSettings,
  };
}

export function useProviders(initialSelectedProviders = []) {
  const [selectedProviders, setSelectedProviders] = useState(initialSelectedProviders);

  function isProviderSelected(provider) {
    return selectedProviders.includes(provider);
  }

  function toggleProvider(provider) {
    if (isProviderSelected(provider)) {
      setSelectedProviders((prevProviders) =>
        prevProviders.filter((existingProvider) => existingProvider !== provider),
      );
    } else {
      setSelectedProviders((prevProviders) => [...prevProviders, provider]);
    }
  }

  return { isProviderSelected, toggleProvider, setProviders: setSelectedProviders };
}
