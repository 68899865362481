import React, { useContext } from 'react';

import { Button, Grid } from 'semantic-ui-react';

import clsx from 'clsx';
import _includes from 'lodash/includes';

import { ValidateButton } from './components';
import { HtmlElementPageContext } from '../../context';
import HtmlElementsErrorMessage from '../HtmlElementsErrorMessage';

import './HtmlElementsActionBar.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsActionBar() {
  const {
    dirtyIds,
    errorMessage,
    itemEditingId,
    setItemEditingId,
    removeDirtyId,
    setItem,
    isSavingItemToDb,
  } = useContext(HtmlElementPageContext);

  if (!_includes(dirtyIds, itemEditingId)) {
    return null;
  }

  function onCancel() {
    removeDirtyId(itemEditingId);
    setItemEditingId(null);
    setItem(null);
  }

  const finalClasses = clsx({ 'html-elements-grid-action-bar': true, 'has-error': !!errorMessage });

  return (
    <Grid stackable className={finalClasses}>
      <Grid.Row columns={2} className="html-elements-action-bar-row" verticalAlign="bottom">
        {errorMessage && (
          <Grid.Column width={8}>
            <HtmlElementsErrorMessage />
          </Grid.Column>
        )}
        <Grid.Column width={errorMessage ? 8 : 16}>
          <div className="html-elements-action-bar">
            <Button
              className="cancel-html-element-changes action-button-gray"
              onClick={onCancel}
              loading={isSavingItemToDb}
            >
              Cancel
            </Button>
            <ValidateButton />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

HtmlElementsActionBar.propTypes = propTypes;
HtmlElementsActionBar.defaultProps = defaultProps;
