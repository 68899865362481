import _ from 'lodash';

import { isMetadataOnlyNode } from './recipe-utils';
import { typeRequiredKeys, getNodeLabel } from './types';

function pipe(...fns) {
  return (x) => fns.reduce((v, f) => f(v), x);
}

function collectNodes(node, path = []) {
  if (_.isArray(node)) {
    return node.reduce(
      (nodes, childNode, index) => [...nodes, ...collectNodes(childNode, [...path, index - 1])],
      [],
    );
  }

  return _.keys(node).reduce(
    (nodes, key) => {
      const childNode = node[key];

      if (_.isObject(childNode)) {
        return [...nodes, ...collectNodes(childNode, [...path, key])];
      }

      return nodes;
    },
    [{ ...node, __path__: path.join('.') }],
  );
}

function removeMetadataNodes(nodes) {
  return nodes.filter((node) => !isMetadataOnlyNode(node));
}

function removeUnresolvedTypeNodes(nodes) {
  return nodes.filter(({ __type__ }) => __type__ !== 'UNRESOLVED');
}

function filterByTypes(nodes, types = []) {
  if (types.length === 0) {
    return nodes;
  }

  return nodes.filter(({ __type__ }) => types.includes(__type__));
}

function isMatch(value, query) {
  const re = new RegExp(_.escapeRegExp(query), 'i');
  return re.test(value);
}

function nodeIsMatch(node, query) {
  return _.keys(node).some((key) => isMatch(key, query) || isMatch(node[key], query));
}

function findNodeMatches(nodes, query) {
  return nodes.filter((node) => nodeIsMatch(node, query));
}

function stripObjectLikeFields(node) {
  if (!_.isObject(node)) {
    return node;
  }

  return _.keys(node).reduce((acc, key) => {
    const childNode = node[key];

    if (_.isObject(childNode)) {
      return acc;
    }

    return { ...acc, [key]: childNode };
  }, {});
}

export function searchNodes(node, query, typeFilters = _.keys(typeRequiredKeys)) {
  const allNodes = collectNodes(node);

  return pipe(
    removeMetadataNodes,
    removeUnresolvedTypeNodes,
    (nodes) => filterByTypes(nodes, typeFilters),
    (nodes) =>
      nodes.map((currentNode) => ({ ...currentNode, __label__: getNodeLabel(currentNode) })),
    (nodes) => nodes.map((currentNode) => stripObjectLikeFields(currentNode)),
    (nodes) => findNodeMatches(nodes, query),
  )(allNodes);
}
