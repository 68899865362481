export const DRAFT = 0;
export const PUBLISHED = 1;

export const DESTINATION_BLOG = 'BLOG';
export const DESTINATION_FACEBOOK = 'FACE';
export const DESTINATION_PRESS = 'PRESS';
export const DESTINATION_TWITTER = 'TWIT';

export const destinationMap = {
  [DESTINATION_BLOG]: 'Blog',
  [DESTINATION_PRESS]: 'Press',
};
