import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon, Loader, Menu, Popup } from 'semantic-ui-react';

import { useBusinessFiles } from 'components/modules/files/hooks';
import { BusinessFilePropType } from 'components/modules/images/types/business-file.types';

import './EditFilePopup.scss';

const propTypes = { file: BusinessFilePropType.isRequired, onFileEdit: PropTypes.func.isRequired };

const defaultProps = {};

export default function EditFilePopup({ file, onFileEdit }) {
  const { duplicateBusinessFile, duplicating } = useBusinessFiles({
    forceInitialFetch: false,
    initializeFiles: false,
  });
  const { id } = file;

  const [open, setOpen] = useState(false);

  function closePopup() {
    setOpen(false);
  }

  function togglePopup() {
    setOpen((previousOpen) => !previousOpen);
  }

  function onTogglePopup(e) {
    e.stopPropagation();
    togglePopup();
  }

  function onEditFile(e) {
    e.stopPropagation();
    onFileEdit(file);

    closePopup();
  }

  async function onDuplicateFile(e) {
    e.stopPropagation();
    await duplicateBusinessFile(id);

    closePopup();
  }

  return (
    <Popup
      className="save-selection-edit-file"
      open={open}
      trigger={
        <Button
          icon
          onClick={onTogglePopup}
          className="edit-file-popup-trigger"
          onMouseEnter={togglePopup}
        >
          <Icon name="ellipsis horizontal" />
        </Button>
      }
      content={
        <Menu vertical>
          <Menu.Item onClick={onEditFile}>
            Edit
            <Icon name="pencil" />
          </Menu.Item>
          <Menu.Item onClick={onDuplicateFile} disabled={duplicating}>
            Duplicate
            {duplicating && <Loader inline size="mini" />}
            {!duplicating && <Icon name="copy" />}
          </Menu.Item>
        </Menu>
      }
      position="bottom right"
      basic
    />
  );
}

EditFilePopup.propTypes = propTypes;
EditFilePopup.defaultProps = defaultProps;
