import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, Grid } from 'semantic-ui-react';

import _startCase from 'lodash/startCase';

import {
  TEXT_ALIGNMENT_OPTIONS,
  VERTICAL_ALIGNMENT_OPTIONS,
  WIDTH_OPTIONS,
  ELEMENT_TYPE_DISPLAY_NAME,
} from './EditGridElementModal.constants';
import { RadioSelect, WidthInput } from './components';
import CloseableModal from '../../../../common/CloseableModal';
import { useLatestRefValue } from '../../../utils/hooks';
import { COLUMN_ELEMENT_TYPE, ROW_ELEMENT_TYPE } from '../../constants/types';
import { ColumnPropType } from '../../types/column.types';
import { RowPropType } from '../../types/row.types';
import PaddingInput from '../PaddingInput';

import './EditGridElementModal.scss';

const propTypes = {
  element: PropTypes.oneOfType([ColumnPropType, RowPropType]).isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editWidthSettings: PropTypes.shape({
    disabled: PropTypes.bool,
  }),
};

const defaultProps = {
  editWidthSettings: {
    disabled: false,
    maxColumnWidth: null,
  },
};

function EditGridElementModal({ element, open, onCancel, onSave, editWidthSettings }) {
  const [loading, setLoading] = useState(false);
  const [internalElement, setInternalElement] = useState(element);
  const latestElementRef = useLatestRefValue(internalElement);

  function createChangeHandler(key) {
    return (value) => {
      const currentElement = latestElementRef.current;
      setInternalElement({ ...currentElement, [key]: value });
    };
  }

  function onPaddingUpdate(value) {
    const currentElement = latestElementRef.current;
    setInternalElement({ ...currentElement, style: { ...currentElement.style, padding: value } });
  }

  async function onSaveInternal() {
    setLoading(true);
    await onSave(internalElement);
    setLoading(false);
  }

  const { width, textAlign, verticalAlign, columnSizingMode, style = {}, type } = internalElement;
  const { padding } = style;
  const { disabled: editWidthSettingsDisabled, maxColumnWidth } = editWidthSettings;

  const isColumn = type === COLUMN_ELEMENT_TYPE;
  const isRow = type === ROW_ELEMENT_TYPE;

  return (
    <CloseableModal
      open={open}
      onClose={onCancel}
      size="small"
      className="edit-grid-element-modal"
      header={`Edit ${ELEMENT_TYPE_DISPLAY_NAME[type]}`}
    >
      <Modal.Content>
        <Grid columns={3}>
          <Grid.Column className="grid-element-setting">
            {isRow && (
              <>
                <div>Column Widths</div>
                <RadioSelect
                  value={columnSizingMode}
                  onSelect={createChangeHandler('columnSizingMode')}
                  options={WIDTH_OPTIONS}
                />
              </>
            )}
            {isColumn && (
              <>
                <div>Width</div>
                <WidthInput
                  width={width}
                  onChange={createChangeHandler('width')}
                  disabled={editWidthSettingsDisabled}
                  maxAllowedWidth={maxColumnWidth}
                />
              </>
            )}
            <PaddingInput padding={padding} onChange={onPaddingUpdate} />
          </Grid.Column>
          <Grid.Column className="grid-element-setting">
            <div>Horizontal Alignment</div>
            <RadioSelect
              value={textAlign}
              onSelect={createChangeHandler('textAlign')}
              options={TEXT_ALIGNMENT_OPTIONS}
              formatLabel={_startCase}
            />
          </Grid.Column>
          <Grid.Column className="grid-element-setting">
            <div>Vertical Alignment</div>
            <RadioSelect
              value={verticalAlign}
              onSelect={createChangeHandler('verticalAlign')}
              options={VERTICAL_ALIGNMENT_OPTIONS}
              formatLabel={_startCase}
            />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel} basic className="cancel">
          Cancel
        </Button>
        <Button onClick={onSaveInternal} basic className="save" loading={loading}>
          Save
        </Button>
      </Modal.Actions>
    </CloseableModal>
  );
}

EditGridElementModal.propTypes = propTypes;
EditGridElementModal.defaultProps = defaultProps;

export default EditGridElementModal;
