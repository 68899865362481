import React from 'react';

export const SortingContext = React.createContext({
  initialFocusedCollection: '',
  setInitialFocusedCollection: () => null,
  initialFocusedGroupCollection: '',
  setInitialFocusedGroupCollection: () => null,
  focusedCollection: '',
  setFocusedCollection: () => null,
  focusedGroupCollection: '',
  setFocusedGroupCollection: () => null,
  dragging: false,
  setDragging: () => null,
});

export const SortingProvider = SortingContext.Provider;
