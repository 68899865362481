import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import HelpTooltip from 'components/common/HelpTooltip';
import InputField from 'components/common/InputField';
import ImageCard from 'components/fields/ImageCard';
import SuggestionInput from 'components/modules/fields/components/SuggestionInput';
import {
  IMAGE_ALT_TEXT_HELP_DESCRIPTION,
  IMAGE_ALT_TEXT_HELP_TITLE,
} from 'components/modules/seo/components/ImageAltTextField/ImageAltTextField.constants';
import { getAiGeneratedAltText } from 'components/modules/seo/components/ImageAltTextField/ImageAltTextField.utils';
import Validate from 'libs/validate';
import { selectBusinessId, selectBusinessType } from 'selectors';

import ErrorHandler from '../../../../../../../libs/errors';

import './FileDetailsForm.scss';

const propTypes = {
  url: PropTypes.string,
  onChange: PropTypes.func,
  description: PropTypes.string,
  filename: PropTypes.string,
};
const defaultProps = {
  url: '',
  onChange: () => null,
  description: '',
  filename: '',
};

export default function FileDetailsForm({ url, onChange, description: altText, filename }) {
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const isValidImageUrl = Validate.validate(Validate.TYPES.LINK, url);

  async function onSuggest() {
    try {
      const response = await getAiGeneratedAltText(businessId, businessType, { imageUrl: url });
      const { text_completion: generatedDescription } = response;
      return generatedDescription;
    } catch (e) {
      ErrorHandler.capture(e);
      return '';
    }
  }

  return (
    <Form className="file-details-form">
      <div className="image-card-container">
        <ImageCard url={url} width={null} />
      </div>
      <Form.Field className="file-details-inputs">
        <Form.Group inline>
          <Form.Field width={5}>
            <label htmlFor="alt-text-input">Alternative Text</label>
            <HelpTooltip
              title={IMAGE_ALT_TEXT_HELP_TITLE}
              description={IMAGE_ALT_TEXT_HELP_DESCRIPTION}
            />
          </Form.Field>
          <Form.Field width={11}>
            <SuggestionInput
              value={altText}
              onChange={(value) => onChange('description', value)}
              onSuggest={onSuggest}
              showActions={isValidImageUrl}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group inline>
          <Form.Field width={5}>
            <label htmlFor="file-name-input">File Name</label>
            <HelpTooltip title="File name" description="The name of this file." />
          </Form.Field>
          <Form.Field width={11}>
            <InputField
              id="file-name-input"
              width={11}
              name="filename"
              value={filename}
              placeholder="Add Filename"
              onChange={(e, { value }) => onChange('filename', value)}
              onValidate={() => true}
              validationType="noSpecialCharacters"
            />
          </Form.Field>
        </Form.Group>
      </Form.Field>
    </Form>
  );
}

FileDetailsForm.propTypes = propTypes;
FileDetailsForm.defaultProps = defaultProps;
