const Currency = {
  format(originValue, locale = 'en-US', currency = 'USD') {
    let formatter;
    const value = originValue || '0';
    try {
      formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      });
    } catch (e) {
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }
    return formatter.format(value);
  },
};

export default Currency;
