import React from 'react';

import { Button, Label, Table } from 'semantic-ui-react';

import _get from 'lodash/get';

import {
  CONTACT_EMAIL_FIELD,
  CONTACT_NAME_FIELD,
  CONTACT_PHONE_FIELD,
} from './CustomerSubmissionsTableRow.constants';
import { getFieldValueByName, getFileCount } from './CustomerSubmissionsTableRow.utils';
import { formatDatetime } from '../CustomerSubmissionsTableBody/CustomerSubmissionsTableBody.utils';

export default function CustomerSubmissionsTableRow({ submission, onDetailsButtonClick }) {
  const fileCount = getFileCount(_get(submission, 'fileFields', {}));

  const contactFields = _get(submission, 'contactFields', []);
  return (
    <Table.Row>
      <Table.Cell>{submission.id}</Table.Cell>
      <Table.Cell>{getFieldValueByName(contactFields, CONTACT_NAME_FIELD)}</Table.Cell>
      <Table.Cell>{getFieldValueByName(contactFields, CONTACT_EMAIL_FIELD)}</Table.Cell>
      <Table.Cell>{getFieldValueByName(contactFields, CONTACT_PHONE_FIELD)}</Table.Cell>
      <Table.Cell>{formatDatetime(submission.createdAt)}</Table.Cell>
      <Table.Cell>
        <Label>{submission.channel}</Label>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Label circular>{fileCount}</Label>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Button
          basic
          content="See Details"
          onClick={() => {
            onDetailsButtonClick(submission);
          }}
        />
      </Table.Cell>
    </Table.Row>
  );
}
