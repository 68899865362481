import _isNumber from 'lodash/isNumber';

export function convertBusinessFilesToImageData(newFiles, businessFiles) {
  const fileIds = newFiles.map((file) => (_isNumber(file) ? file : file.id));

  const imagesData = fileIds.map((id, index) => {
    const businessFile = businessFiles.find((file) => file.id === id) || {};
    const { metadata = {} } = businessFile;

    return {
      order: index,
      id,
      metadata,
    };
  });

  return imagesData;
}
