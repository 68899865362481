import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import {
  MAX_AMOUNT_COLUMNS,
  MAX_AMOUNT_ROWS,
  MIN_AMOUNT_COLUMNS,
  MIN_AMOUNT_ROWS,
  DEFAULT_COLUMN_PADDING,
  DEFAULT_ROW_PADDING,
} from '../../constants/page';
import CssCodeEditor from '../CssCodeEditor/CssCodeEditor';
import ItemStatusDropdown from '../ItemStatusDropdown/ItemStatusDropdown';
import { STATUS_DRAFT } from '../ItemStatusDropdown/ItemStatusDropdown.constants';
import PaddingInput from '../PaddingInput';

import './CustomPageConfigForm.scss';

const propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  numCols: PropTypes.number,
  paddingCols: PropTypes.number,
  defineRows: PropTypes.bool,
  numRows: PropTypes.number,
  paddingRows: PropTypes.number,
  loading: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  hideLayoutControls: PropTypes.bool,
  disableStatus: PropTypes.bool,
  style: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  title: '',
  status: STATUS_DRAFT,
  numCols: 1,
  paddingCols: DEFAULT_COLUMN_PADDING,
  defineRows: false,
  numRows: 1,
  paddingRows: DEFAULT_ROW_PADDING,
  loading: false,
  hideLayoutControls: false,
  disableStatus: false,
  style: '',
  className: '',
};

export default function CustomPageConfigForm({
  title,
  status,
  numCols,
  paddingCols,
  defineRows,
  numRows,
  paddingRows,
  loading,
  onUpdate,
  hideLayoutControls,
  style,
  className,
  disableStatus,
}) {
  function ensureGridCellRange(value, minValid, maxValid) {
    return Math.max(minValid, Math.min(maxValid, +value));
  }

  function onStatusChange(e, { value }) {
    onUpdate('status', value);
  }

  function onInputChange(e, { name, value }) {
    onUpdate(name, value);
  }

  function onEditorChange(value) {
    onUpdate('style', value);
  }

  return (
    <>
      <Form loading={loading} className="custom-page-config-form">
        <Form.Input
          name="title"
          label="Page Name"
          placeholder="Enter the name"
          value={title}
          onChange={onInputChange}
        />

        <div className="status-container">
          <span>Page Status</span>
          <ItemStatusDropdown status={status} onChange={onStatusChange} disabled={disableStatus} />
        </div>

        {!hideLayoutControls && (
          <>
            <p className="layout-note">
              <span>Define the underlying grid and its behavior.</span>{' '}
              <span>
                <strong>Note: </strong>a header and a footer is added automatically afterwards
              </span>
            </p>

            <Form.Group widths="equal">
              <Form.Input
                name="numCols"
                type="number"
                min={MIN_AMOUNT_COLUMNS}
                max={MAX_AMOUNT_COLUMNS}
                label="# of Columns"
                value={numCols}
                disabled={hideLayoutControls}
                onChange={(e) =>
                  onUpdate(
                    'numCols',
                    ensureGridCellRange(e.target.value, MIN_AMOUNT_COLUMNS, MAX_AMOUNT_COLUMNS),
                  )
                }
              />
              <Form.Field>
                <PaddingInput
                  name="paddingCols"
                  padding={paddingCols}
                  onChange={(value) => onUpdate('paddingCols', value)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Checkbox
              name="defineRows"
              label="Also define rows"
              checked={defineRows}
              onChange={(e, data) => {
                onUpdate('defineRows', data.checked);
              }}
            />
          </>
        )}
        <Form.Input
          className="class-name-input"
          name="className"
          label="Class Name"
          placeholder="class-name"
          value={className}
          onChange={onInputChange}
        />
        {defineRows && (
          <Form.Group widths="equal">
            <Form.Input
              name="numRows"
              type="number"
              min={MIN_AMOUNT_ROWS}
              max={MAX_AMOUNT_ROWS}
              label="# of Rows"
              value={numRows}
              disabled={hideLayoutControls}
              onChange={(e) =>
                onUpdate(
                  'numRows',
                  ensureGridCellRange(e.target.value, MIN_AMOUNT_ROWS, MAX_AMOUNT_ROWS),
                )
              }
            />
            <Form.Field>
              <PaddingInput
                name="paddingRows"
                padding={paddingRows}
                onChange={(value) => onUpdate('paddingRows', value)}
              />
            </Form.Field>
          </Form.Group>
        )}
      </Form>
      <div className="css-style-container">
        <div>Style</div>
        <CssCodeEditor value={style} onUpdate={onEditorChange} />
      </div>
    </>
  );
}

CustomPageConfigForm.propTypes = propTypes;
CustomPageConfigForm.defaultProps = defaultProps;
