import React from 'react';

import PropTypes from 'prop-types';
import { Icon as SemanticIcon } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';

import {
  getLibraryFromIconName,
  getIconClassFromIconName,
  getContainerClassNames,
  iconLibrary,
} from './Icon.utils';
import useSemanticUiProps from '../../../../../hooks/useSemanticUiProps';

import './Icon.scss';

const propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: null,
};

export default function Icon({ name, onClick, ...props }) {
  if (_isEmpty(name)) {
    return null;
  }

  const library = getLibraryFromIconName(name);
  const { getIconProps } = useSemanticUiProps();
  const extraProps = getIconProps(props);
  const { className } = extraProps;

  function buildFontAwesomeIcon() {
    const containerClassNames = getContainerClassNames(className);
    const icon = _isString(name) ? getIconClassFromIconName(name) : name;

    return (
      <SemanticIcon onClick={onClick} {...extraProps} className={containerClassNames}>
        <FontAwesomeIcon icon={icon} />
      </SemanticIcon>
    );
  }

  return library === iconLibrary.SemanticUI ? (
    <SemanticIcon
      name={name.toLowerCase()}
      {...extraProps}
      onClick={onClick}
      className={className}
    />
  ) : (
    buildFontAwesomeIcon()
  );
}

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;
