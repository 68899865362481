import PropTypes from 'prop-types';

import { ObjectPropType } from '../../../../libs/proptypes';

export const CustomPageTemplatePropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  node: ObjectPropType,
});

export const CustomPageTemplatesPropType = PropTypes.arrayOf(CustomPageTemplatePropType);
