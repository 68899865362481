import _isEmpty from 'lodash/isEmpty';

import {
  MIN_DAYS_TO_DISPLAY_ANALYTICS,
  NOT_ENOUGH_DATA_MESSAGE,
  ONLY_LIVE_WEBSITES_MESSAGE,
} from './WebsiteAnalytics.constants';
import { STACK_STATUS_LIVE } from '../../../constants/constants';
import { isAdminUser } from '../../../libs/auth';
import DateUtils from '../../../libs/date-utils';

export function getPrimaryDomain(domains = []) {
  if (!domains) {
    return {};
  }

  return domains.find(({ primary_domain: primaryDomain }) => primaryDomain) || {};
}

export function enoughAnalyticsData(domain = {}) {
  const { created_at: createdAt } = domain || {};

  if (!createdAt) {
    return false;
  }

  const months = DateUtils.daysDifference(DateUtils.getCurrentDate(), createdAt);

  return months >= MIN_DAYS_TO_DISPLAY_ANALYTICS;
}

export function analyticsIsAvailable(website, user) {
  if (_isEmpty(website) || _isEmpty(user)) {
    return {
      available: false,
      reason: '',
    };
  }

  const { stackStatus, domains = [] } = website;

  const availablePayload = {
    available: true,
    reason: '',
  };
  const isLive = stackStatus === STACK_STATUS_LIVE;

  if (!isLive) {
    return {
      available: false,
      reason: ONLY_LIVE_WEBSITES_MESSAGE,
    };
  }

  if (isAdminUser(user)) {
    return availablePayload;
  }

  const primaryDomain = getPrimaryDomain(domains);
  const minimumLiveTimeReached = enoughAnalyticsData(primaryDomain);

  if (!minimumLiveTimeReached) {
    return {
      available: false,
      reason: NOT_ENOUGH_DATA_MESSAGE,
    };
  }

  return availablePayload;
}
