import _findIndex from 'lodash/findIndex';

import { getComponentSetting } from 'components/modules/component-configuration/services/component-configuration';

import {
  HERO_ELEMENT,
  IMAGE_COLLECTION,
  LEGACY_HERO_ELEMENT_DISPLAY_NAME,
  LEGACY_IMAGE_COLLECTION_DISPLAY_NAME,
} from './ImagesPage.constants';
import API from '../../../libs/api';

export function createComponentKey(component) {
  const { fast_id: componentName, component_identifier: componentId } = component;
  return `${componentName}_${componentId}`;
}

export function getPageComponent(page, componentKey) {
  const { components = [] } = page || {};

  const foundComponents = components.filter((component) => {
    const componentKeyToCompare = createComponentKey(component);
    return componentKey === componentKeyToCompare;
  });

  if (foundComponents.length) {
    return foundComponents[0];
  }

  return {};
}

export function getSelectedFiles(page, componentName) {
  const component = getPageComponent(page, componentName);
  const { files } = component;

  return files;
}

export function getComponentNameFromKey(componentKey) {
  return componentKey.split('_')[0];
}

export function getPageComponentConfigurationSettings(settings, pageType) {
  const pageComponentConfigurations = settings.filter(({ page_type: settingPageType }) => {
    return settingPageType === pageType;
  });

  return pageComponentConfigurations.sort(
    ({ display_order: displayOrderA }, { display_order: displayOrderB }) => {
      return displayOrderA - displayOrderB;
    },
  );
}

export function isImageComponentConfigurationSetting(setting = {}) {
  const { min_files_allowed: minFilesAllowed, max_files_allowed: maxFilesAllowed } = setting;

  if (!minFilesAllowed && !maxFilesAllowed) {
    return false;
  }
  return true;
}

export function isImageComponentConfiguration(component, settings) {
  const componentSetting = getComponentSetting(component, settings);

  return isImageComponentConfigurationSetting(componentSetting);
}

export async function associateSelectedFiles(website, page, componentKey) {
  const componentConfiguration = getPageComponent(page, componentKey);

  const { id: websiteId } = website;
  const { id: pageId } = page;
  const { files, id } = componentConfiguration;

  const businessFileIds = files.map(({ id: fileId }) => fileId);
  const componentName = getComponentNameFromKey(componentKey);

  const payload = {
    component_fast_id: componentName,
    business_file_ids: businessFileIds,
  };

  if (componentConfiguration) {
    return API.updatePageComponentConfiguration(websiteId, pageId, id, payload);
  }

  return API.createPageComponentConfiguration(websiteId, pageId, payload);
}

export function refreshPages(pages, page) {
  const indexOfPage = _findIndex(pages, ({ id: pageId }) => {
    return pageId === page.id;
  });

  return [...pages.slice(0, indexOfPage), page, ...pages.slice(indexOfPage + 1)];
}

export function updatePageWithNewComponent(page, component) {
  const { components } = page;
  const componentToFindKey = createComponentKey(component);

  const indexOfComponent = _findIndex(components, (comp) => {
    return componentToFindKey === createComponentKey(comp);
  });

  const newComponents = [
    ...components.slice(0, indexOfComponent),
    component,
    ...components.slice(indexOfComponent + 1),
  ];

  return {
    ...page,
    components: newComponents,
  };
}

export function updatePageComponentFiles(page, componentKey, newFiles) {
  const pageComponent = getPageComponent(page, componentKey);

  const newComponent = {
    ...pageComponent,
    files: newFiles,
  };

  return updatePageWithNewComponent(page, newComponent);
}

export function createNumFilesAllowedDescription(minFilesAllowed, maxFilesAllowed) {
  if (minFilesAllowed === 0 && !maxFilesAllowed) {
    return '';
  }

  if (minFilesAllowed === 0 && maxFilesAllowed) {
    return `Select up to ${maxFilesAllowed}`;
  }

  if (minFilesAllowed > 0 && !maxFilesAllowed) {
    return `Select at least ${minFilesAllowed}`;
  }

  if (minFilesAllowed > 0 && maxFilesAllowed) {
    return `Select ${minFilesAllowed} to ${maxFilesAllowed}`;
  }

  return '';
}

export function getLegacyDisplayName(componentName) {
  const legacyNameMap = {
    [HERO_ELEMENT]: LEGACY_HERO_ELEMENT_DISPLAY_NAME,
    [IMAGE_COLLECTION]: LEGACY_IMAGE_COLLECTION_DISPLAY_NAME,
  };

  return legacyNameMap[componentName] || componentName;
}

export function isLegacyWebsite(website) {
  const { workspace_version: websiteVersion } = website;

  return websiteVersion === 'v_legacy';
}
