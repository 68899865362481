export const PUBLISH_ERROR_MODAL_TITLE = 'Update in Progress';
export const PUBLISH_CONFLICT_TITLE = 'Your last publish is still processing';
export const PUBLISH_CONFLICT_MESSAGE =
  'We recommend making all your changes first and publishing everything at once. You will be able to publish again in 2-3 minutes.';
export const IS_PUBLISHING_LIVE_CONFLICT_TITLE = 'We are currently publishing your website';
export const IS_PUBLISHING_LIVE_CONFLICT_MESSAGE =
  'You will be able to publish changes within the next 15-20 minutes';

export const SAVE_ERROR_MODAL_TITLE = 'Error Saving';
export const SAVE_ERROR_MESSAGE = 'There was a problem saving the following information:';

export const VALIDATION_ERROR_MODAL_TITLE = 'Unable to Save';
export const VALIDATION_ERROR_MESSAGE = 'Click below to fix the invalid values and try again.';

export const PUBLISH_SUCCESS_MODAL_TITLE = 'Website Publication';
export const PUBLISH_SUCCESS_PRIMARY_MESSAGE = 'Your website is being published!';
export const PUBLISH_SUCCESS_SECONDARY_MESSAGE =
  'Your changes will be available in about 2-3 minutes';

export const WEBSITE_PUBLISH_TOOLTIP_MESSAGE_V1 =
  'This will update your Fisherman Website. Changes may take a few minutes to become active.';

export const WEBSITE_PUBLISH_TOOLTIP_MESSAGE_LEGACY =
  'We are unable to publish your site at the moment. Please reach out to support for further assistance.';

export const PRODUCT_TYPE_PUBLISH_TOOLTIP_MESSAGE_MAP = {
  Webiste_V1: WEBSITE_PUBLISH_TOOLTIP_MESSAGE_V1,
  Website_Legacy: WEBSITE_PUBLISH_TOOLTIP_MESSAGE_LEGACY,
};

export const SAVE_TOOLTIP_MESSAGE = 'You can save your progress once you make changes.';
