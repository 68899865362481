import React from 'react';

import PropTypes from 'prop-types';
import { Form, Header } from 'semantic-ui-react';

import './ItemHeader.scss';

const propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function ItemHeader({ isEditing, isDirty, name, onChange }) {
  return (
    <>
      {isEditing ? (
        <Form.Field className="html-element-item-header-input">
          <Form.Input
            name="name"
            defaultValue={name}
            onChange={onChange}
            onClick={(e) => e.stopPropagation()}
            placeholder="New Script Name"
          />
        </Form.Field>
      ) : (
        <Header className="html-element-item-header">
          {name}
          {isDirty && <span className="html-element-item-dirty"> *</span>}
        </Header>
      )}
    </>
  );
}

ItemHeader.propTypes = propTypes;
ItemHeader.defaultProps = defaultProps;
