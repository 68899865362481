import React from 'react';

import { Checkbox } from 'semantic-ui-react';

import TimePicker from 'react-time-picker';

const availableDaysOptions = [
  {
    value: 'ALL',
    abbreviation: null,
  },
  {
    value: 'MONDAY',
    abbreviation: 'M',
  },
  {
    value: 'TUESDAY',
    abbreviation: 'Tu',
  },
  {
    value: 'WEDNESDAY',
    abbreviation: 'W',
  },
  {
    value: 'THURSDAY',
    abbreviation: 'Th',
  },
  {
    value: 'FRIDAY',
    abbreviation: 'F',
  },
  {
    value: 'SATURDAY',
    abbreviation: 'Sa',
  },
  {
    value: 'SUNDAY',
    abbreviation: 'Su',
  },
];

class MenuScheduleAvailabilityPicker extends React.Component {
  static addOrRemoveAvailabilityDay(availableDays, day) {
    const index = availableDays.indexOf(day);

    if (index > -1) {
      return [...availableDays.slice(0, index), ...availableDays.slice(index + 1, availableDays)];
    }

    return [...availableDays, day];
  }

  constructor(props) {
    super(props);

    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  handleTimeChange(name, time) {
    const { index, updateMenuSchedule } = this.props;

    updateMenuSchedule({
      index,
      values: { [name]: time },
    });
  }

  render() {
    const { isCustomAvailability, availableDays, startTime, endTime, updateMenuSchedule, index } =
      this.props;

    return (
      <div
        className={`menu-schedule-availability ${
          !isCustomAvailability ? 'menu-schedule-availability-disabled' : ''
        }`}
      >
        <div>
          <span>Days</span>
          <div>
            {availableDaysOptions.slice(1).map(({ abbreviation, value }) => (
              <span>
                <Checkbox
                  label={abbreviation}
                  checked={availableDays.includes(value)}
                  onChange={() =>
                    updateMenuSchedule({
                      index,
                      values: {
                        available_days: MenuScheduleAvailabilityPicker.addOrRemoveAvailabilityDay(
                          availableDays,
                          value,
                        ),
                      },
                    })
                  }
                />
              </span>
            ))}
          </div>
        </div>
        <div>
          <span>Hours</span>
          <TimePicker
            className="menu-schedule-availability-time"
            clearAriaLabel="Clear Time"
            onChange={(time) => this.handleTimeChange('start_time', time)}
            format="hh:mm a"
            value={startTime}
            disableClock
            use12Hours
            inputReadOnly
          />
          <div className="menu-schedule-availability-time-divider">TO</div>
          <TimePicker
            className="menu-schedule-availability-time"
            clearAriaLabel="Clear Time"
            onChange={(time) => this.handleTimeChange('end_time', time)}
            format="hh:mm a"
            value={endTime}
            disableClock
            use12Hours
            inputReadOnly
          />
        </div>
      </div>
    );
  }
}

export default MenuScheduleAvailabilityPicker;
