import React, { useContext } from 'react';

import { Form, Label, Input } from 'semantic-ui-react';

import { HuePicker } from 'react-color';

import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

import './BannerColor.css';

export default function BannerColor() {
  const { bannerBackgroundColor, setValue } = useContext(AnnouncementFormContext);

  function handleChange(color) {
    let finalColor = color;
    if (!color.startsWith('#')) {
      finalColor = `#${color}`;
    }
    setValue('bannerBackgroundColor', finalColor);
  }

  return (
    <Form.Group className="color-picker" widths="equal">
      <Form.Field>
        <label htmlFor="color-picker-span">Color Picker</label>
        <span id="color-picker-span" className="color-span">
          <HuePicker
            width="100%"
            color={bannerBackgroundColor}
            onChange={({ hex: value }) => handleChange(value)}
          />
        </span>
      </Form.Field>
      <Form.Field>
        <span id="color-picker-input">
          <Form.Input label="Hex Color" width={4}>
            <Input
              label={<Label style={{ backgroundColor: bannerBackgroundColor }} />}
              labelPosition="left"
              value={bannerBackgroundColor}
              onChange={(e, { value }) => handleChange(value)}
            />
          </Form.Input>
        </span>
      </Form.Field>
    </Form.Group>
  );
}
