import React from 'react';

import { Dropdown, Grid } from 'semantic-ui-react';

import PlusIcon from '../static/add.png';

export default class AddModifierSet extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showSelectExisting: false };

    this.toggleShowSelectExisting = this.toggleShowSelectExisting.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    this.toggleShowSelectExisting();
  }

  getDropdownOptions() {
    const { allModifierSets, itemModifierSets, onAddExistingSet } = this.props;
    const itemModifierSetNames = itemModifierSets.map(({ name }) => name);

    const dropdownOptions = allModifierSets
      .filter(({ name }) => !itemModifierSetNames.includes(name))
      .map(({ name, id }) => ({
        key: id,
        value: name,
        text: name,
        onClick: () => {
          onAddExistingSet(id);
          this.toggleShowSelectExisting();
        },
      }));

    return dropdownOptions;
  }

  toggleShowSelectExisting() {
    this.setState(({ showSelectExisting }) => ({
      showSelectExisting: !showSelectExisting,
    }));
  }

  render() {
    const { onAddNewSet, showCreateNew } = this.props;
    const { showSelectExisting } = this.state;

    return (
      <div className="add-modifier-set">
        {showSelectExisting && (
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Dropdown
                placeholder="Select Modifier Set"
                search
                selection
                options={this.getDropdownOptions()}
              />
            </Grid.Column>
            <Grid.Column width={2} verticalAlign="middle" textAlign="right">
              <span
                onClick={this.handleCancel}
                onKeyDown={this.handleCancel}
                role="button"
                tabIndex={-1}
                style={{ textDecoration: 'underline' }}
              >
                Cancel
              </span>
            </Grid.Column>
          </Grid>
        )}
        <div className="add-modifier-set-options">
          {showCreateNew && (
            <div
              className="add-modifier-set-option"
              onClick={onAddNewSet}
              onKeyDown={onAddNewSet}
              role="button"
              tabIndex={-1}
            >
              <img src={PlusIcon} alt="plus-icon" />
              Create Modifier Set
            </div>
          )}
          {!showSelectExisting && (
            <div
              className="add-modifier-set-option"
              role="button"
              tabIndex={-1}
              onKeyDown={this.toggleShowSelectExisting}
              onClick={this.toggleShowSelectExisting}
            >
              <img src={PlusIcon} alt="plus-icon" />
              Add Existing Modifier Set
            </div>
          )}
        </div>
      </div>
    );
  }
}
