import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import _isNumber from 'lodash/isNumber';

import useTeam from '../../../../hooks/use-team';

const propTypes = {
  onUpdate: PropTypes.func.isRequired,
  selectedTeams: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})])),
};

const defaultProps = {
  selectedTeams: [],
};

function TeamMemberTeamField({ selectedTeams, onUpdate }) {
  const { teams } = useTeam();

  function getOptions() {
    return (teams || []).map(({ id, name }) => ({ key: id, text: name, value: id }));
  }

  function onChange(e, { value: newSelectedTeams }) {
    const payload = newSelectedTeams.map((id, index) => ({ id, team: id, order: index }));
    onUpdate(payload);
  }

  function search(options, query) {
    const regex = new RegExp(query, 'i');
    return options.filter((opt) => regex.test(opt.text));
  }

  const options = getOptions();

  const value = selectedTeams.map((team) => {
    if (_isNumber(team)) {
      return team;
    }

    return team.team || team.id;
  });

  return (
    <Dropdown
      fluid
      multiple
      selection
      search={search}
      options={options}
      value={value}
      placeholder="Enter a team"
      onChange={onChange}
    />
  );
}

TeamMemberTeamField.propTypes = propTypes;
TeamMemberTeamField.defaultProps = defaultProps;

export default TeamMemberTeamField;
