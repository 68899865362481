import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Form, Message, Header, Icon, Input } from 'semantic-ui-react';

import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import _pullAt from 'lodash/pullAt';
import _remove from 'lodash/remove';
import _uniq from 'lodash/uniq';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import {
  INTERACTIONS_RECIPES,
  TEAM_MEMBER_ADVANCED_SEO_RECIPES,
  TEAM_MEMBER_MULTIPLE_IMAGES_RECIPES,
  emptyHours,
} from './EditTeamMemberModal.constants';
import {
  formatHoursForSave,
  formatHours,
  formatLocations,
  formatLocationsForSave,
  getAvailableSocialMediaAccounts,
  getDescriptionRequirements,
  getTitleRequirements,
} from './EditTeamMemberModal.utils';
import { flagBusinessSaved } from '../../../../../../../actions/business';
import options from '../../../../../../../constants/options';
import { sortItemsByList } from '../../../../../../../libs/array';
import ErrorHandler, { getErrorMessage } from '../../../../../../../libs/errors';
import Validate from '../../../../../../../libs/validate';
import { selectBusinessName } from '../../../../../../../selectors/business';
import { selectWebsitePages } from '../../../../../../../selectors/website';
import { ImagePlaceholderField, DropdownField } from '../../../../../../common';
import CloseableModal from '../../../../../../common/CloseableModal';
import Hours from '../../../../../../common/Hours';
import PhoneField from '../../../../../../common/PhoneField';
import { getImageUrl } from '../../../../../../core/menu/item/MenuItem/MenuItem.utils';
import MarkdownField from '../../../../../../fields/MarkdownField';
import FileSelectionWell from '../../../../../files/components/FileSelectionWell/FileSelectionWell';
import { SOURCE_TYPE_TEAM_MEMBER } from '../../../../../files/constants/sources';
import { useBusinessFiles } from '../../../../../files/hooks';
import CustomIcon from '../../../../../foundation/components/Icon';
import useRecipe from '../../../../../foundation/hooks/use-recipe';
import { TEAM_MEMBER_PAGE_TYPE, TEAM_PAGE_TYPE } from '../../../../../pages/constants';
import SeoMetadataFields from '../../../../../seo/components/SeoMetadataFields';
import { createSeoTitlePlaceholder } from '../../../../../seo/components/SeoMetadataFields/components/SeoTitle/SeoTitle.utils';
import WebsiteComponentInteractionContainer from '../../../../../website-component-interaction/WebsiteComponentInteractionContainer';
import useTeam from '../../../../hooks/use-team';
import {
  TEAM_MEMBER_SERVICES_FEATURES,
  TEAM_MEMBER_TEAM_FEATURES,
} from '../../../../services/team';
import { TeamsSectionContext } from '../../TeamMembersTable.context';
import TeamMemberPropType from '../../TeamMembersTable.propTypes';
import TeamMemberEditFormGroup from '../EditTeamMemberFormGroup';
import TeamMemberLocationField from '../TeamMemberLocationField';
import TeamMemberServiceAssociation from '../TeamMemberServiceAssociation';
import TeamMemberTeamField from '../TeamMemberTeamField';

import './EditTeamMemberModal.scss';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func,
  open: PropTypes.bool,
  teamMember: TeamMemberPropType,
  reset: PropTypes.bool,
};

const defaultProps = {
  open: false,
  teamMember: {},
  setShowDeleteModal: undefined,
  reset: false,
};

export default function EditTeamMemberModal({
  open,
  onClose,
  onSave,
  teamMember,
  onChange,
  setShowDeleteModal,
  reset,
}) {
  const {
    name = '',
    role = '',
    description = '',
    image,
    email = '',
    phone = '',
    locations = [],
    teams = [],
    social_media_accounts: socialMediaAccounts = [],
    hours = [],
    interactions = [],
    files = [],
    associated_item_variations: services = [],
    seo_title: seoTitle = '',
    seo_description: seoDescription = '',
  } = teamMember;

  const dispatch = useDispatch();
  const websitePages = useSelector(selectWebsitePages);
  const teamPage = _isEmpty(websitePages)
    ? {}
    : websitePages.find(({ pageType }) => pageType === TEAM_PAGE_TYPE);
  const teamPageName = _get(teamPage, 'title', 'Team');
  const [isSaving, setIsSaving] = useState(false);
  const [touched, setTouched] = useState(false);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedSocialMediaAccounts, setSelectedSocialMediaAccounts] = useState([]);
  const [selectedHours, setSelectedHours] = useState([]);
  const [editorState, setEditorState] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);

  const { websiteUsesRecipe } = useRecipe();
  const { teams: allTeams } = useTeam();
  const selectedTeamNames = selectedTeams
    .map(({ team: teamId }) => allTeams.find(({ id }) => id === teamId))
    .filter(Boolean)
    .map(({ name: teamName }) => teamName);

  const isInteractionsEnabled = websiteUsesRecipe(INTERACTIONS_RECIPES);
  const isMultipleImagesEnabled = websiteUsesRecipe(TEAM_MEMBER_MULTIPLE_IMAGES_RECIPES);
  const areTeamsEnabled = websiteUsesRecipe(TEAM_MEMBER_TEAM_FEATURES);
  const areTeamServicesEnabled = websiteUsesRecipe(TEAM_MEMBER_SERVICES_FEATURES);
  const areAdvancedSeoFeaturesEnabled = websiteUsesRecipe(TEAM_MEMBER_ADVANCED_SEO_RECIPES);

  const { business, allLocations } = useContext(TeamsSectionContext);
  const businessName = useSelector(selectBusinessName);
  const { businessFiles } = useBusinessFiles();
  const { SOCIAL_MEDIA: socialMediaOptions, SOCIAL_MEDIA_PROFILE_URL_TEMPLATE: socialMediaUrls } =
    options;
  const isAddMember = !teamMember.id;
  const teamMemberBusinessFiles = sortItemsByList(businessFiles || [], files || [], 'id', 'id');

  useEffect(() => {
    formatHours(hours, selectedHours, setSelectedHours);
    setSelectedTeams(teams);
    setSelectedServices(services);
    setEditorState(description);
    setSelectedSocialMediaAccounts(socialMediaAccounts);
    formatLocations(business, allLocations, setAvailableLocations, locations, setSelectedLocations);
  }, []);

  useEffect(() => {
    const preparedHours = formatHoursForSave(selectedHours);
    onChange(null, {
      name: 'hours',
      value: preparedHours,
    });
  }, [selectedHours]);

  useEffect(() => {
    onChange(null, {
      name: 'socialMediaAccounts',
      value: selectedSocialMediaAccounts,
    });
  }, [selectedSocialMediaAccounts]);

  function isValidEmail(emailString) {
    return (emailString || '').trim() === '' || Validate.validate('email', emailString);
  }

  function isValidPhone(phoneString) {
    return (phoneString || '').trim() === '' || Validate.validate('phone', phoneString);
  }

  function isValidSocialUsername(username) {
    return (username || '').trim() !== '';
  }

  function isFormValid() {
    const validSocialMediaAccounts = selectedSocialMediaAccounts.reduce(
      (isValid, { username }) => isValid && isValidSocialUsername(username),
      true,
    );
    const validEmail = isValidEmail(teamMember.email);
    const validPhone = isValidPhone(teamMember.phone);

    return teamMember.name && validEmail && validPhone && validSocialMediaAccounts;
  }

  function clearModal() {
    setTouched(false);
    setErrorMessage(null);
    if (reset) {
      setSelectedLocations([]);
      setSelectedTeams([]);
      setSelectedSocialMediaAccounts([]);
      setSelectedHours(emptyHours);
      setEditorState('');
      setSelectedServices([]);
    }
  }

  async function handleSave() {
    setTouched(true);
    if (!isFormValid()) return;
    setIsSaving(true);
    setErrorMessage(null);
    try {
      await onSave();
      clearModal();
      dispatch(flagBusinessSaved(true));
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
  }

  async function handleDelete() {
    setShowDeleteModal(true);
  }

  function handleOnClose() {
    clearModal();
    onClose();
  }

  function onImageChange(e, { files: imageFiles, ...rest }) {
    const file = imageFiles[0];
    const url = window.URL.createObjectURL(file);
    const newImage = { url, file };

    onChange(e, { ...rest, value: newImage, name: 'image' });
  }

  function onImageDelete(e, target) {
    const newImage = { url: null, file: null };

    onChange(e, { ...target, value: newImage, name: 'image' });
  }

  function onUpdateInteraction(data) {
    const payload = data.map((interaction) => {
      return {
        url: '',
        ...interaction,
      };
    });

    onChange(null, {
      name: 'interactions',
      value: payload,
    });
  }

  function onDescriptionChange(newEditorState) {
    setEditorState(newEditorState);
    onChange(null, {
      name: 'description',
      value: newEditorState,
    });
  }

  function handleUpdateLocations(data) {
    if (allLocations) {
      const userSelectedLocationPartials = formatLocationsForSave(
        business,
        data,
        allLocations,
        setSelectedLocations,
      );
      onChange(null, {
        name: 'locations',
        value: userSelectedLocationPartials,
      });
    }
  }

  function onUpdateTeams(newTeams) {
    setSelectedTeams(newTeams);
    onChange(null, {
      name: 'teams',
      value: newTeams,
    });
  }

  function handleAddSocialMediaAccount(value) {
    const existingSocialPlatform = selectedSocialMediaAccounts.filter(
      ({ platform }) => platform === value,
    );
    if (existingSocialPlatform.length) {
      return;
    }

    const newSocial = {
      platform: value,
      url: socialMediaUrls[value],
      username: '',
    };
    const localSocialMediaAccounts = _cloneDeep(selectedSocialMediaAccounts);
    const newSocialMediaAccountsList = [...localSocialMediaAccounts, newSocial];
    setSelectedSocialMediaAccounts(newSocialMediaAccountsList);
  }

  function handleUpdateSocialMediaAccount(event, index) {
    const {
      target: { value },
    } = event;
    const localSocialMediaAccounts = _cloneDeep(selectedSocialMediaAccounts);
    const updated = localSocialMediaAccounts[index];
    updated.username = value;
    updated.url = socialMediaUrls[updated.platform].replace('<username>', value);
    setSelectedSocialMediaAccounts(localSocialMediaAccounts);
  }

  function handleDeleteSocialMediaAccount(e, index) {
    if (e.detail > 0) {
      const localSocialMediaAccounts = _cloneDeep(selectedSocialMediaAccounts);
      _pullAt(localSocialMediaAccounts, index);
      setSelectedSocialMediaAccounts(localSocialMediaAccounts);
    }
  }

  function hoursCopy(sourceDay, targetDays) {
    const localMemberHours = _cloneDeep(selectedHours);
    const sourceHours = localMemberHours.filter(({ day }) => day === sourceDay);
    const copiedHours = [];
    sourceHours.forEach(({ open: sourceOpen, close: sourceClose }) => {
      if (sourceOpen || sourceClose) {
        targetDays.forEach((day) => {
          _remove(localMemberHours, ({ day: dayToRemove }) => dayToRemove === day);
          copiedHours.push({
            day,
            open: sourceOpen,
            close: sourceClose,
            localId: uuid(),
            id: null,
          });
        });
      }
    });
    setSelectedHours(() => _uniq([...localMemberHours, ...copiedHours]));
  }
  function hoursAdd(day) {
    const localMemberHours = _cloneDeep(selectedHours);
    const baseHour = { day, open: '', close: '', localId: uuid(), id: null };
    setSelectedHours(() => [...localMemberHours, baseHour]);
  }
  function hoursDelete(hourId) {
    const localMemberHours = _cloneDeep(selectedHours);
    _remove(localMemberHours, ({ id, localId }) => id === hourId || localId === hourId);
    setSelectedHours([...localMemberHours]);
  }
  function hoursUpdate(hourId, payload) {
    const localMemberHours = _cloneDeep(selectedHours);
    const index = _findIndex(
      localMemberHours,
      ({ id, localId }) => id === hourId || localId === hourId,
    );
    const { field, fieldValue } = payload;
    const updatedHour = localMemberHours[index];
    updatedHour[field] = fieldValue;
    setSelectedHours(() => _uniq([...localMemberHours, updatedHour]));
  }

  function onUpdateFiles(newFiles) {
    const selectedMemberFiles = newFiles.map((file, index) => ({
      id: _isNumber(file) ? file : file.id,
      order: index,
    }));
    onChange(null, { value: selectedMemberFiles, name: 'files' });
  }

  function onUpdateServices(newServices) {
    setSelectedServices(newServices);
    onChange(null, {
      name: 'associatedItemVariations',
      value: newServices,
    });
  }

  function onSeoMetadataChange(e, target) {
    onChange(e, target);
  }

  return (
    <CloseableModal
      open={open}
      onClose={handleOnClose}
      size="large"
      className="team-member-edit-modal"
      header={isAddMember ? 'Add Member' : 'Edit Member'}
    >
      <Modal.Content scrolling>
        <Form>
          <TeamMemberEditFormGroup
            keyName="name"
            value={name}
            onChange={onChange}
            error={!name && touched}
            required
          />
          <TeamMemberEditFormGroup keyName="role" value={role} onChange={onChange} />

          <TeamMemberEditFormGroup
            keyName="locations"
            customInput={
              <TeamMemberLocationField
                currentLocations={selectedLocations}
                availableLocations={availableLocations}
                onUpdateLocations={handleUpdateLocations}
              />
            }
          />

          {areTeamsEnabled && (
            <TeamMemberEditFormGroup
              keyName="teams"
              customInput={
                <TeamMemberTeamField selectedTeams={selectedTeams} onUpdate={onUpdateTeams} />
              }
            />
          )}

          <TeamMemberEditFormGroup
            keyName="email"
            value={email}
            onChange={onChange}
            error={!isValidEmail(email) && touched}
          />

          <TeamMemberEditFormGroup
            keyName="phone"
            value={phone}
            error={!isValidPhone(phone) && touched}
            customInput={
              <PhoneField
                placeholder="(123) 456-7890"
                name="phone"
                value={phone}
                onChange={onChange}
              />
            }
          />

          {selectedSocialMediaAccounts &&
            selectedSocialMediaAccounts.map(({ username, platform }, index) => (
              <TeamMemberEditFormGroup
                key={platform + username}
                keyName={index === 0 ? 'social' : 'socialDetail'}
                onChange={onChange}
                value={username}
                error={!isValidSocialUsername(username) && touched}
                customInput={
                  <>
                    <Input
                      icon={<CustomIcon name={platform} />}
                      iconPosition="left"
                      action={{
                        icon: 'trash',
                        onClick: (e) => handleDeleteSocialMediaAccount(e, index),
                      }}
                      onBlur={(e) => handleUpdateSocialMediaAccount(e, index)}
                      labelPosition="right"
                      placeholder={`${platform} user name`}
                      className="social-item"
                      defaultValue={username}
                    />
                  </>
                }
              />
            ))}
          <TeamMemberEditFormGroup
            keyName={selectedSocialMediaAccounts.length === 0 ? 'social' : 'socialAdd'}
            customInput={
              <DropdownField
                className="social-item"
                fluid
                onChange={handleAddSocialMediaAccount}
                placeholder="Add social media account"
                options={getAvailableSocialMediaAccounts(
                  socialMediaOptions,
                  selectedSocialMediaAccounts,
                )}
              />
            }
          />

          <TeamMemberEditFormGroup
            keyName="description"
            customInput={<MarkdownField value={editorState} onChange={onDescriptionChange} />}
          />

          {isInteractionsEnabled && (
            <TeamMemberEditFormGroup
              keyName="interactions"
              fullWidth
              customInput={
                <WebsiteComponentInteractionContainer
                  interactions={interactions}
                  onUpdate={onUpdateInteraction}
                />
              }
            />
          )}

          <TeamMemberEditFormGroup
            keyName="hours"
            fullWidth
            customInput={
              <Hours
                populateEmptyDays
                hideHourLabels
                hours={selectedHours}
                hourLabels={[]}
                onCopyHours={(sourceDay, targetDays) => hoursCopy(sourceDay, targetDays)}
                onAddHour={(day) => hoursAdd(day)}
                onDeleteHour={(hourId) => hoursDelete(hourId)}
                onUpdateHour={(hourId, payload) => hoursUpdate(hourId, payload)}
              />
            }
          />

          {areTeamServicesEnabled && (
            <TeamMemberEditFormGroup
              keyName="associatedItemVariations"
              fullWidth
              customInput={
                <TeamMemberServiceAssociation
                  selectedServices={selectedServices}
                  onUpdate={onUpdateServices}
                />
              }
            />
          )}

          <Header as="h4">Headshot</Header>
          <ImagePlaceholderField
            url={getImageUrl(image, null)}
            enableDelete
            onDelete={onImageDelete}
            onChange={onImageChange}
          />

          {isMultipleImagesEnabled && (
            <>
              <Header as="h4">Photos</Header>
              <FileSelectionWell
                files={teamMemberBusinessFiles}
                onUpdateFiles={onUpdateFiles}
                uploadSourceType={SOURCE_TYPE_TEAM_MEMBER}
              />
            </>
          )}

          {areAdvancedSeoFeaturesEnabled && (
            <SeoMetadataFields onChange={onSeoMetadataChange} pageType={TEAM_MEMBER_PAGE_TYPE}>
              <SeoMetadataFields.SeoTitle
                showWarnings
                title={seoTitle}
                additionalRequirements={getTitleRequirements({ name })}
                placeholder={createSeoTitlePlaceholder(`${teamPageName} - ${name}`, businessName)}
              />
              <SeoMetadataFields.SeoDescription
                showWarnings
                description={seoDescription}
                additionalRequirements={getDescriptionRequirements({
                  name,
                  role,
                  teams: selectedTeamNames,
                })}
                placeholder={description}
              />
            </SeoMetadataFields>
          )}

          {errorMessage && (
            <Message
              className="errorMessage"
              negative
              onDismiss={() => {
                setErrorMessage(null);
              }}
              header={errorMessage}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {setShowDeleteModal && (
          <Button
            content="Delete member"
            className="delete-red button-delete-team-member"
            onClick={handleDelete}
            icon={<Icon name="trash" />}
          />
        )}
        <Button content="Cancel" className="action-button-gray" onClick={handleOnClose} />
        <Button
          content="Save"
          className="secondary-navy"
          onClick={handleSave}
          loading={isSaving}
          disabled={(isSaving || !isFormValid()) && touched}
        />
      </Modal.Actions>
    </CloseableModal>
  );
}

EditTeamMemberModal.propTypes = propTypes;
EditTeamMemberModal.defaultProps = defaultProps;
