import React, { useContext } from 'react';

import { Checkbox, Table } from 'semantic-ui-react';

import { EMAIL_KEY } from './AnnouncementFormFieldsSettings.constants';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

import './AnnouncementFormFieldsSettings.scss';

export default function AnnouncementFormFieldsSettings() {
  const { formFieldsSettings, setValue } = useContext(AnnouncementFormContext);

  function onChangeHandler(fieldName, checked) {
    const settings = formFieldsSettings.map((field) => {
      if (field.name !== EMAIL_KEY && field.name === fieldName) {
        return { ...field, enabled: checked };
      }
      return field;
    });

    setValue('formFieldsSettings', settings);
  }

  return (
    <Table className="form-fields-settings">
      <Table.Body>
        {formFieldsSettings.map(({ name, enabled }) => (
          <Table.Row key={name}>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell textAlign="right">
              <Checkbox
                name={name}
                onChange={(e, { checked }) => onChangeHandler(name, checked)}
                toggle
                checked={enabled}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
