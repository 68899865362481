import React from 'react';

import PropTypes from 'prop-types';
import { Button, Grid, Table, Label } from 'semantic-ui-react';

import TimePicker from 'react-time-picker';
import { v4 as uuid } from 'uuid';

import { OperatingHourPropType } from '../../../../../libs/proptypes';
import StateUtils from '../../../../../libs/state';
import HourLabel from '../HourLabel';

import './HoursForDay.scss';

const propTypes = {
  day: PropTypes.number.isRequired,
  populateEmptyDays: PropTypes.bool,
  weekDayLabel: PropTypes.string.isRequired,
  hours: PropTypes.arrayOf(OperatingHourPropType).isRequired,
  hourLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateHour: PropTypes.func.isRequired,
  addHour: PropTypes.func.isRequired,
  deleteHour: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  showHourLabels: PropTypes.bool,
  allowEmptyHours: PropTypes.bool,
};

const defaultProps = {
  populateEmptyDays: false,
  showHourLabels: false,
  allowEmptyHours: false,
};

function HoursForDay({
  day,
  populateEmptyDays,
  weekDayLabel,
  hours,
  hourLabels,
  updateHour,
  addHour,
  deleteHour,
  onCopy,
  showHourLabels,
  allowEmptyHours,
}) {
  function getStatusLabel(workingHours) {
    const anyOpen = workingHours.some(({ open, close }) => open && close);
    if (anyOpen) {
      return <Label color="green">Open</Label>;
    }
    return <Label>Closed</Label>;
  }

  function handleChange(hour, fieldName, value, allowNull = true) {
    const hourId = hour.id || hour.localId;
    if (value !== null || allowNull) {
      updateHour(hourId, {
        field: fieldName,
        fieldValue: value,
      });
    }
  }

  function handleDelete(hour) {
    if (hours.length === 1) {
      handleChange(hour, 'open', null, allowEmptyHours);
      handleChange(hour, 'close', null, allowEmptyHours);
      if ((hour.id || hour.localId) && !allowEmptyHours) {
        deleteHour(hour.id || hour.localId);
      }
    } else {
      deleteHour(hour.id || hour.localId);
    }
  }

  function ensureDefaultHours() {
    if (!populateEmptyDays || (hours && hours.length)) {
      return hours;
    }

    return [StateUtils.createDefaultType('HOURS_OF_OPERATION', { day, id: null, localId: uuid() })];
  }

  return (
    <Table.Row className="hours-for-day" verticalAlign="top">
      <Table.Cell width={1} textAlign="left" verticalAlign="middle">
        {weekDayLabel}
      </Table.Cell>
      <Table.Cell width={1} textAlign="center" verticalAlign="middle" className="mobile-align-left">
        {getStatusLabel(hours)}
      </Table.Cell>
      <Table.Cell width={8} textAlign="center" verticalAlign="middle">
        <Grid className="hours" verticalAlign="middle" centered>
          {ensureDefaultHours().map((workingHour) => (
            <Grid.Row
              key={workingHour.id || workingHour.localId}
              centered
              className="single-input-row"
            >
              {showHourLabels && (
                <Grid.Column
                  computer={4}
                  tablet={15}
                  mobile={15}
                  textAlign="center"
                  className="mobile-align-left"
                >
                  <HourLabel
                    labels={hourLabels}
                    value={workingHour.label}
                    onSelect={(label) => handleChange(workingHour, 'label', label)}
                  />
                </Grid.Column>
              )}
              <Grid.Column computer={4} tablet={6} mobile={7} textAlign="center">
                <TimePicker
                  className="time-picker"
                  clearIcon={null}
                  disableClock
                  onChange={(value) => handleChange(workingHour, 'open', value)}
                  format="hh:mm a"
                  use12Hours
                  value={workingHour.open}
                />
              </Grid.Column>
              <Grid.Column computer={1} tablet={1} mobile={1} textAlign="center">
                -
              </Grid.Column>
              <Grid.Column computer={4} tablet={6} mobile={7} textAlign="center">
                <TimePicker
                  className="time-picker"
                  clearIcon={null}
                  disableClock
                  onChange={(value) => handleChange(workingHour, 'close', value)}
                  format="hh:mm a"
                  use12Hours
                  value={workingHour.close}
                />
              </Grid.Column>
              <Grid.Column computer={1} tablet={1} mobile={16} textAlign="center">
                <Button
                  className="borderless delete-button"
                  basic
                  size="large"
                  icon="trash"
                  disabled={hours.length === 1 && !hours[0].open && !hours[0].close}
                  onClick={() => handleDelete(workingHour)}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Table.Cell>
      <Table.Cell width={2} textAlign="center" verticalAlign="middle">
        <Button
          className="borderless add-button"
          basic
          size="large"
          icon="plus"
          onClick={addHour}
        />
        <Button
          className="borderless copy-button"
          basic
          size="large"
          icon="copy"
          onClick={onCopy}
        />
      </Table.Cell>
    </Table.Row>
  );
}

HoursForDay.propTypes = propTypes;
HoursForDay.defaultProps = defaultProps;

export default HoursForDay;
