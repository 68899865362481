import React from 'react';

import PropTypes from 'prop-types';
import { Segment, Form, Button, Header, Message } from 'semantic-ui-react';

import _ from 'lodash';
import { Link } from 'react-router-dom';

import { DEFAULT_ERROR_MESSAGE_TIMEOUT_SECONDS } from '../../constants/constants';
import API from '../../libs/api';
import { PasswordInput } from '../common';
import ConfirmationPage from '../common/ConfirmationPage';

class ForgotConfirmForm extends React.Component {
  constructor(props) {
    super(props);

    const { uid, token } = props;

    this.state = {
      uid,
      token,
      newPassword1: '',
      newPassword2: '',
      errorMessage: '',
      infoMessage: '',
      confirmationVisible: false,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }

  setFormMessage(messageField, message, resetEventually = true) {
    if (resetEventually) {
      this.setState({ [messageField]: message }, () =>
        setTimeout(
          () => this.setFormMessage(messageField, '', false),
          DEFAULT_ERROR_MESSAGE_TIMEOUT_SECONDS * 1000,
        ),
      );
    }

    this.setState({ [messageField]: message });
  }

  resetForm() {
    this.setState({
      newPassword1: '',
      newPassword2: '',
    });
  }

  toggleLoading(cb = null) {
    this.setState(
      ({ loading }) => ({ loading: !loading }),
      () => cb && cb(),
    );
  }

  validateParams() {
    const { newPassword1, newPassword2 } = this.state;

    if (newPassword1 !== newPassword2) {
      this.setFormMessage('errorMessage', 'Passwords do not match');
      return false;
    }

    return true;
  }

  async submit() {
    const { newPassword1, newPassword2, uid, token } = this.state;
    const { isFirstSignIn, onSuccess } = this.props;
    const paramsAreValid = this.validateParams();

    if (paramsAreValid) {
      this.toggleLoading(async () => {
        try {
          let loginResult = null;
          // Submit Confirmation
          if (isFirstSignIn) {
            loginResult = await API.createPassword(newPassword1, newPassword2);
          } else {
            loginResult = await API.confirmPasswordReset(newPassword1, newPassword2, uid, token);
          }

          // Get result
          const { data } = loginResult;
          const { detail } = data;

          if (detail) {
            this.setFormMessage('infoMessage', detail);
          }
          if (onSuccess) {
            onSuccess(data);
          }

          this.setState({
            confirmationVisible: true,
          });
        } catch (e) {
          const passwordIssue =
            _.get(e, 'response.data.new_password2[0]') ||
            _.get(e, 'response.data.new_password1[0]');
          const detailInfo = _.get(e, 'response.data.detail');
          const errorDetail = passwordIssue || detailInfo || 'Unable to reset password';
          this.setFormMessage('errorMessage', errorDetail);
        } finally {
          this.toggleLoading();
          this.resetForm();
        }
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderConfirmation() {
    return (
      <div className="forgot-confirm-form">
        <ConfirmationPage
          icon="check circle"
          iconColor="green"
          subject="Password Reset"
          content={<p>You have successfully reset your password.</p>}
          actionContent={
            <div>
              <Link to="/login" className="forgot-password">
                <span>Login Now</span>
              </Link>
            </div>
          }
        />
      </div>
    );
  }

  render() {
    const { newPassword1, newPassword2, errorMessage, infoMessage, confirmationVisible, loading } =
      this.state;
    const { isFirstSignIn, onSkip } = this.props;

    if (confirmationVisible) {
      return this.renderConfirmation();
    }

    return (
      <div className="forgot-confirm-form">
        <Segment>
          <Form
            onSubmit={() => this.submit()}
            error={errorMessage.length !== 0}
            success={infoMessage.length !== 0}
            loading={loading}
          >
            <Header as="h2" textAlign="center">
              {isFirstSignIn ? 'Set Your Password' : 'Reset Password'}
            </Header>
            <Form.Field>
              <PasswordInput
                onChange={this.handleChange}
                placeholder="Password"
                name="newPassword1"
                value={newPassword1}
              />
            </Form.Field>
            <Form.Input
              onChange={this.handleChange}
              placeholder="Confirm New Password"
              name="newPassword2"
              value={newPassword2}
              type="password"
            />
            {!isFirstSignIn && (
              <Form.Field>
                <Link to="/login" className="login">
                  <span>Return to Login</span>
                </Link>
              </Form.Field>
            )}
            <Form.Field>
              <Button type="submit" primary fluid>
                {isFirstSignIn ? 'Set Password' : 'Reset My Password'}
              </Button>
            </Form.Field>
            {isFirstSignIn && onSkip && (
              <Form.Field>
                <Button secondary fluid onClick={onSkip}>
                  Skip for now
                </Button>
              </Form.Field>
            )}
            <Message error header={errorMessage} />
            <Message success header={infoMessage} />
          </Form>
        </Segment>
      </div>
    );
  }
}

export default ForgotConfirmForm;

ForgotConfirmForm.propTypes = {
  onSuccess: PropTypes.func,
  onSkip: PropTypes.func,
  isFirstSignIn: PropTypes.bool,
  match: PropTypes.shape({}),
};

ForgotConfirmForm.defaultProps = {
  onSuccess: null,
  onSkip: null,
  isFirstSignIn: false,
  match: {
    params: {
      token: null,
      uid: null,
    },
  },
};
