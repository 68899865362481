import { ELEMENT_TYPE } from '../../../../constants/types';
import EditFormBlock, { displayName as formDisplayName } from '../EditFormBlock';
import EditImageBlock, { displayName as imageDisplayName } from '../EditImageBlock';
import EditJsonBlock, { displayName as jsonDisplayName } from '../EditJsonBlock';
import EditTextBlock, { displayName as textDisplayName } from '../EditTextBlock';

export const ELEMENT_TYPE_EDIT_COMPONENT = {
  [ELEMENT_TYPE.FormBlock]: EditFormBlock, // legacy
  [ELEMENT_TYPE.CustomFormBlock]: EditFormBlock,
  [ELEMENT_TYPE.ImageBlock]: EditImageBlock,
  [ELEMENT_TYPE.TextBlock]: EditTextBlock,
  [ELEMENT_TYPE.JsonBlock]: EditJsonBlock,
};

export const ELEMENT_TYPE_DISPLAY_NAME = {
  [ELEMENT_TYPE.FormBlock]: formDisplayName, // legacy
  [ELEMENT_TYPE.CustomFormBlock]: formDisplayName,
  [ELEMENT_TYPE.ImageBlock]: imageDisplayName,
  [ELEMENT_TYPE.TextBlock]: textDisplayName,
  [ELEMENT_TYPE.JsonBlock]: jsonDisplayName,
};

export const ELEMENT_TYPE_DISPLAY_ORDER = [
  ELEMENT_TYPE.TextBlock,
  ELEMENT_TYPE.ImageBlock,
  ELEMENT_TYPE.CustomFormBlock,
  ELEMENT_TYPE.JsonBlock,
];

export const ELEMENT_COMPONENT_NOT_FOUND_MESSAGE =
  'There is no registered component available for this type.';
