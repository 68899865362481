import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';

export function formatStyleRuleName(styleRule) {
  return _startCase(styleRule.replace('@', ''));
}

export function formatStyleRuleValue(styleRuleValue) {
  return _camelCase(styleRuleValue.replace('@', '').replace(' ', ''));
}

export function formatStyleRulesValue(styleRules) {
  return (styleRules || []).map(({ name } = {}) => formatStyleRuleValue(name));
}
