import React from 'react';

import { Header } from 'semantic-ui-react';

import { DateTimeField } from '../../../../../../../../../../common';
import {
  EXTERNAL_DATE_FORMAT,
  ORDER_AT_DATE_FORMAT,
} from '../../../../../../OrderReports.constants';

import './StartDate.scss';

function StartDate({ currentValue, onChange }) {
  return (
    <div>
      <Header as="h5">Ordered After</Header>
      <DateTimeField
        datetime={currentValue}
        dateTimeFormat={ORDER_AT_DATE_FORMAT}
        externalDateTimeFormat={EXTERNAL_DATE_FORMAT}
        onChange={onChange}
        className="order-reports-end-date"
      />
    </div>
  );
}

export default StartDate;
