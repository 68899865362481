import React from 'react';

import { Button, Container, Image, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import CloverIntegrationSettings from './CloverIntegrationSettings';
import { SUPPORT_EMAIL } from '../../../../constants/constants';
import { InlineFieldContainer } from '../../../common';
import FormContainer from '../../../common/FormContainer';
import IntegrationMessage from '../IntegrationMessage';

import CloverLogo from '../../../../static/logos/clover-logo.png';

import './CloverMerchantForm.scss';

const ERROR_MESSAGE_CONTENT = `Please try again or contact ${SUPPORT_EMAIL}`;

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ business, user }) => ({
  user: _get(user, 'core.value'),
  businessId: _get(business, 'core.value.id'),
  businessType: _get(business, 'core.value.type'),
  businessCollectionMethod: _get(business, 'core.value.collection_method'),
  providerServices: _get(business, 'provider_services.value'),
  providerServiceSettings: _get(business, 'provider_service_settings.value'),
});

const CloverMerchantForm = (props) => {
  const { serviceSetting, onAuthorize, onSubmitSettings, isSubmitting, errorMessage, showTitle } =
    props;

  const hasInstalled = _get(serviceSetting, 'service_data.owner.isOwner', false);
  const importHours = _get(serviceSetting, 'status_data.import_hours', true);
  const importMenu = _get(serviceSetting, 'status_data.import_menu', true);
  const importCOLO = _get(serviceSetting, 'status_data.import_colo', true);

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
      <InlineFieldContainer title={showTitle && <h2>Clover</h2>} enableDelete={false}>
        <Segment.Group>
          <Segment>
            <Container>
              <Container textAlign="center">
                <Image src={CloverLogo} className="clover-logo" />
              </Container>
            </Container>
          </Segment>
          {hasInstalled ? (
            <CloverIntegrationSettings
              importHours={importHours}
              importMenu={importMenu}
              importCOLO={importCOLO}
              isSubmitting={isSubmitting}
              onSubmitSettings={onSubmitSettings}
            />
          ) : (
            <>
              <Segment attached>
                <Container textAlign="center" className="clover-onboarding-welcome">
                  This will allow Fisherman to synchronize your Clover POS with your website menu,
                  hours, and online ordering.
                </Container>
              </Segment>
              <Segment attached="top">
                <Container textAlign="center">
                  <Button size="big" onClick={onAuthorize} primary>
                    Authorize Fisherman
                  </Button>
                </Container>
              </Segment>
            </>
          )}
          <IntegrationMessage header={errorMessage} content={ERROR_MESSAGE_CONTENT} />
        </Segment.Group>
      </InlineFieldContainer>
    </FormContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CloverMerchantForm);
