import _ from 'lodash';

export function capitalize(word) {
  return _.startCase(_.toLower(word));
}

export function capitalizeAll(words) {
  return _.startCase(_.toLower(words));
}

export function camelToSnakeCase(str) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function keysToCamelCase(obj) {
  return _.transform(obj, function convertCase(result, val, key) {
    // eslint-disable-next-line no-param-reassign
    result[_.camelCase(key)] = _.isObject(val) ? keysToCamelCase(val) : val;
  });
}

export function keysToSnakeCase(obj) {
  return Object.keys(obj).reduce((data, key) => ({ ...data, [_.snakeCase(key)]: obj[key] }), {});
}

export function getNumeric(string = '') {
  return string.replace(/\D/g, '');
}

export function transformObjectKeyToSnakeCase(obj) {
  if (typeof obj !== 'object' || obj === null) return undefined;
  const newObj = {};
  Object.keys(obj).forEach((property) => {
    newObj[camelToSnakeCase(property)] = obj[property];
  });
  return newObj;
}

export function getValidNumberString(string = '') {
  const numericString = getNumeric(string);
  if (numericString) {
    return Number(numericString).toString();
  }
  return '';
}

export function formatLabel(label, required) {
  return required ? `${label}*` : label;
}
