import React, { useEffect, useState } from 'react';

import { Button, Message, List, Loader, Icon } from 'semantic-ui-react';

import _get from 'lodash/get';
import _last from 'lodash/last';
import { useSelector } from 'react-redux';

import { newCategoryData, getOrdinalsForCategory } from './CatalogItemLibrary.utils';
import AddCategoryButton from './components/AddCategoryButton';
import CatalogItem from './components/CatalogItem';
import CategoryToggle from './components/CategoryToggle';
import { selectBusiness, selectActiveProduct } from '../../../../../selectors';
import CatalogItemForm from '../CatalogItemForm';
import ImportLibraryButton from '../ImportLibraryButton';
import {
  CATALOG_OBJECT_TYPE_CATEGORY,
  CATALOG_OBJECT_TYPE_ITEM,
  CATALOG_OBJECT_TYPE_TAX,
  CATALOG_OBJECT_TYPE_ITEM_VARIATION,
  EMPTY_CATALOG_ITEM,
  CATALOG_MAX_ORDINAL,
  CATALOG_MIN_ORDINAL,
} from '../constants';
import { useCatalogItemLibrary } from '../hooks';
import {
  getCatalogObjectId,
  filterCatalogObjectsByType,
  filterObjectsByRelatedId,
  updateCatalogObjectField,
  filterCatalogObjectsByIds,
} from '../utils';

import './CatalogItemLibrary.scss';

export default function CatalogItemLibrary() {
  const { id: businessId, type: businessType } = useSelector(selectBusiness);
  const currentActiveProduct = useSelector(selectActiveProduct);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [categoryOfNewItem, setCategoryOfNewItem] = useState(null);

  const {
    catalog,
    loadingCatalog,
    fetchingCatalogErrorMessage,
    addObjectAfter,
    removeEmptyCategory,
    saveCategory,
    deleteCategory,
    deleteItem,
    reloadCatalog,
  } = useCatalogItemLibrary(businessType, businessId, currentActiveProduct.id);

  const [categories, setCategories] = useState([]);
  const [taxes, setTaxes] = useState([]);

  useEffect(() => {
    setCategories(filterCatalogObjectsByType(catalog, CATALOG_OBJECT_TYPE_CATEGORY));
    setTaxes(filterCatalogObjectsByType(catalog, CATALOG_OBJECT_TYPE_TAX));
  }, [catalog]);

  function getItemsForCategory(category) {
    return filterObjectsByRelatedId(
      catalog,
      CATALOG_OBJECT_TYPE_ITEM,
      'item_data',
      'category',
      category.object_id,
    );
  }

  function getImagesForItem(item) {
    const imageIds = _get(item, 'item_data.images', []);
    return filterCatalogObjectsByIds(catalog, imageIds);
  }

  function getVariationsForItem(item) {
    return filterObjectsByRelatedId(
      catalog,
      CATALOG_OBJECT_TYPE_ITEM_VARIATION,
      'item_variation_data',
      'item',
      item.object_id,
    );
  }

  async function onSubmitItemForm() {
    await reloadCatalog();
    setItemToEdit(null);
  }

  function onDeleteItemForm(itemData) {
    setItemToEdit(null);
    deleteItem(itemData);
  }

  function onCreateNewItemInitialData(category) {
    const itemWithOrdinal = updateCatalogObjectField(
      EMPTY_CATALOG_ITEM,
      'item_data',
      'previous_ordinal',
      _get(_last(getItemsForCategory(category)), `item_data.ordinal`, CATALOG_MIN_ORDINAL),
    );

    return updateCatalogObjectField(
      itemWithOrdinal,
      'item_data',
      'taxes',
      taxes.map(({ object_id: taxObjectId }) => taxObjectId),
    );
  }

  async function onAddItem() {
    await reloadCatalog();
    setCategoryOfNewItem(null);
  }

  if (loadingCatalog) {
    return <Loader active />;
  }

  if (fetchingCatalogErrorMessage) {
    return <Message error header={fetchingCatalogErrorMessage} />;
  }

  return (
    <>
      <ImportLibraryButton
        catalog={catalog}
        loadingCatalog={loadingCatalog}
        onUpload={async () => reloadCatalog()}
      />
      <AddCategoryButton
        onClick={() =>
          addObjectAfter(
            newCategoryData({
              nextOrdinal: _get(categories, '[0].category_data.ordinal', CATALOG_MAX_ORDINAL),
            }),
          )
        }
      />
      {categories.map((category, categoryIndex) => (
        <section className="category-section" key={getCatalogObjectId(category)}>
          <CategoryToggle
            category={category}
            onSubmit={saveCategory}
            onCancelSubmit={removeEmptyCategory}
            onDelete={deleteCategory}
          />
          {category.object_id && (
            <>
              <List>
                {getItemsForCategory(category).map((item) => (
                  <List.Item key={getCatalogObjectId(item)} className="catalog-item-list-item">
                    <CatalogItem
                      className="catalog-item-list-item-item"
                      item={item}
                      images={getImagesForItem(item)}
                      variations={getVariationsForItem(item)}
                      onEdit={() => setItemToEdit(item)}
                    />
                    <CatalogItemForm
                      open={getCatalogObjectId(itemToEdit) === getCatalogObjectId(item)}
                      onClose={(hasSavedItem) =>
                        hasSavedItem ? onSubmitItemForm() : setItemToEdit(null)
                      }
                      trigger={
                        <Button
                          icon
                          onClick={() => setItemToEdit(item)}
                          className="catalog-item-list-edit-button"
                        >
                          <Icon name="pencil" size="big" />
                        </Button>
                      }
                      categoryCatalog={category}
                      businessId={businessId}
                      initialData={item}
                      catalog={catalog}
                      taxes={taxes}
                      images={getImagesForItem(item)}
                      variations={getVariationsForItem(item)}
                      onSubmit={onSubmitItemForm}
                      onDelete={onDeleteItemForm}
                    />
                  </List.Item>
                ))}
              </List>
              <CatalogItemForm
                trigger={
                  <Button
                    className="new-item-button"
                    onClick={() => setCategoryOfNewItem(category.object_id)}
                  >
                    <Icon name="plus" />
                    New Item
                  </Button>
                }
                open={categoryOfNewItem === category.object_id}
                onClose={(hasSavedItem) =>
                  hasSavedItem ? onAddItem() : setCategoryOfNewItem(null)
                }
                categoryCatalog={category}
                initialData={onCreateNewItemInitialData(category)}
                catalog={catalog}
                taxes={taxes}
                businessId={businessId}
                onSubmit={onAddItem}
              />
              <AddCategoryButton
                onClick={() =>
                  addObjectAfter(
                    newCategoryData(getOrdinalsForCategory(category, categoryIndex, categories)),
                    category,
                  )
                }
              />
            </>
          )}
        </section>
      ))}
    </>
  );
}
