import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Modal, Table } from 'semantic-ui-react';

import { daysOfWeekIndex, hoursLabels } from './Hours.constants';
import HoursForDay from './components/HoursForDay';
import { OperatingHourPropType } from '../../../libs/proptypes';
import CloseableModal from '../CloseableModal';

const propTypes = {
  onCopyHours: PropTypes.func.isRequired,
  hours: PropTypes.arrayOf(OperatingHourPropType),
  hourLabels: PropTypes.arrayOf(PropTypes.string),
  onAddHour: PropTypes.func.isRequired,
  onUpdateHour: PropTypes.func.isRequired,
  onDeleteHour: PropTypes.func.isRequired,
  showHourLabels: PropTypes.bool,
  allowEmptyHours: PropTypes.bool,
  populateEmptyDays: PropTypes.bool,
};

const defaultProps = {
  hours: [],
  hourLabels: [],
  showHourLabels: false,
  allowEmptyHours: false,
  populateEmptyDays: false,
};

function Hours({
  onCopyHours,
  hours,
  hourLabels,
  onAddHour,
  onUpdateHour,
  onDeleteHour,
  showHourLabels,
  allowEmptyHours,
  populateEmptyDays,
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [targetCopyDays, setTargetCopyDays] = useState([]);
  const [sourceCopyDay, setSourceCopyDay] = useState(null);
  const [availableTargetDays, setAvailableTargetDays] = useState([1, 2, 3, 4, 5, 6, 7]);

  useEffect(() => {
    setAvailableTargetDays(() => {
      const allDays = [1, 2, 3, 4, 5, 6, 7];
      if (sourceCopyDay === null) {
        return allDays;
      }

      allDays.splice(sourceCopyDay - 1, 1);
      return allDays;
    });
  }, [targetCopyDays, sourceCopyDay]);

  function labelForDay(day) {
    return hoursLabels[day - 1];
  }

  function handleOpenModal() {
    setIsDropdownOpen(true);
  }

  function handleCloseModal() {
    setIsDropdownOpen(false);
    setTargetCopyDays([]);
    setSourceCopyDay(null);
  }

  function handleClickOnCopy(sourceDay) {
    setSourceCopyDay(sourceDay);
    handleOpenModal();
  }

  function handleDayCopyTarget(targetDay) {
    setTargetCopyDays((prevState) => {
      const newTargetCopyDays = [...prevState];
      const targetDayIndex = newTargetCopyDays.indexOf(targetDay);

      if (targetDayIndex === -1) {
        newTargetCopyDays.push(targetDay);
      } else {
        newTargetCopyDays.splice(targetDayIndex, 1);
      }
      return newTargetCopyDays;
    });
  }

  function handleCopyHours() {
    onCopyHours(sourceCopyDay, targetCopyDays);
    handleCloseModal();
  }

  function hoursForDay(day) {
    return (hours || []).filter((hour) => hour.day === day);
  }

  return (
    <>
      <CloseableModal
        className="hours-copy-modal"
        size="mini"
        open={isDropdownOpen}
        onClose={handleCloseModal}
        header={`Copy ${labelForDay(sourceCopyDay)} hours to`}
      >
        <Modal.Content>
          <Form>
            {availableTargetDays.map((day) => (
              <Form.Field key={day}>
                <Checkbox
                  label={labelForDay(day)}
                  onChange={() => handleDayCopyTarget(day)}
                  checked={targetCopyDays.includes(day)}
                />
              </Form.Field>
            ))}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={handleCopyHours} content="Apply" className="action" />
        </Modal.Actions>
      </CloseableModal>
      <Table celled definition className="hours-table">
        <Table.Body>
          {daysOfWeekIndex.map((day) => (
            <HoursForDay
              key={day}
              day={day}
              populateEmptyDays={populateEmptyDays}
              weekDayLabel={labelForDay(day)}
              hours={hoursForDay(day)}
              hourLabels={hourLabels}
              updateHour={(hourId, payload) => onUpdateHour(hourId, payload)}
              deleteHour={(hourId) => onDeleteHour(hourId)}
              addHour={() => onAddHour(day)}
              onCopy={() => handleClickOnCopy(day)}
              showHourLabels={showHourLabels}
              allowEmptyHours={allowEmptyHours}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
}

Hours.propTypes = propTypes;
Hours.defaultProps = defaultProps;

export default Hours;
