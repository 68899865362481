import _defaults from 'lodash/defaults';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';

import { objectToCamelCase } from '../../../../../libs/format';
import {
  MARKDOWN_CONTENT_TYPE,
  TEXT_CONTENT_TYPE,
} from '../../../component-configuration/constants/content';
import { createContentComponentConfiguration } from '../../../component-configuration/services/content-node-configuration';
import { ensureInteractions } from '../../../website-component-interaction/WebsiteComponentInteractionContainer/WebsiteComponentInteractionContainer.utils';
import {
  ANNOUNCEMENT_FAST_ID,
  CUSTOM_FORM_FAST_ID,
  INITIAL_ANNOUNCEMENT_FORM_STATE,
} from '../../constants';
import {
  ANNOUNCEMENT_DEFAULT_FORM_FIELDS_MAP,
  ANNOUNCEMENT_FORM_FIELDS_DEFAULT_SETTINGS,
} from '../AnnouncementFormFieldsSettings/AnnouncementFormFieldsSettings.constants';

export function getFormFieldSettings(fields) {
  const defaultSettings = ANNOUNCEMENT_FORM_FIELDS_DEFAULT_SETTINGS;
  const updatedSettings = defaultSettings.map((setting) => {
    const field = fields.find((x) => x.name === setting.name);
    return {
      ...setting,
      enabled: field ? true : setting.enabled,
    };
  });

  return updatedSettings;
}

export function getAnnouncementFormFields(isFormEnabled, fields) {
  const formFields = [];

  if (!isFormEnabled) {
    return [];
  }

  fields.forEach((field) => {
    const { name, enabled } = field;
    if (enabled) {
      formFields.push(ANNOUNCEMENT_DEFAULT_FORM_FIELDS_MAP[name]);
    }
  });

  return formFields;
}

export function customFormConfigurationToAnnouncementForm(customFormConfiguration) {
  const { data: { submitButtonText = '', subscriptionTags = [], fields = [] } = {} } =
    customFormConfiguration || {};

  const formEnabled = !_isEmpty(fields);

  const formFieldsSettings = getFormFieldSettings(fields);

  return {
    formEnabled,
    submitButtonText,
    subscriptionTags,
    formFieldsSettings,
  };
}

export function announcementFormToCustomFormConfiguration(
  announcementForm,
  announcementComponentConfigurationId,
  customFormComponentConfiguration = {},
) {
  const {
    formEnabled = false,
    submitButtonText = '',
    subscriptionTags = [],
    formFieldsSettings,
  } = announcementForm;

  const {
    id = null,
    order = 0,
    fastId = CUSTOM_FORM_FAST_ID,
    layout = null,
  } = customFormComponentConfiguration;

  const data = {
    submitButtonText,
    subscriptionTags,
    fields: getAnnouncementFormFields(formEnabled, formFieldsSettings),
  };

  const newCustomFormComponentConfiguration = {
    id,
    order,
    fastId,
    layout,
    parentComponentConfigurationId: announcementComponentConfigurationId,
    data,
    files: [],
    interactions: [],
    contentNodes: [],
    enabled: formEnabled,
  };

  return newCustomFormComponentConfiguration;
}

export function createAnnouncementForm(initialAnnouncementForm) {
  const formData = _defaults(initialAnnouncementForm, INITIAL_ANNOUNCEMENT_FORM_STATE, {
    title: '',
    images: [],
    content: '',
    websiteComponentInteractions: [],
  });

  const {
    startTime,
    endTime,
    referenceId,
    bannerEnabled,
    bannerBackgroundColor,
    bannerButtonText,
    bannerPosition,
    title,
    popupEnabled,
    dismissalType,
    delay,
    duration,
    imagePosition,
    formEnabled,
    submitButtonText,
    subscriptionTags,
    images,
    content,
    websiteComponentInteractions,
    formFieldsSettings,
  } = formData;

  return {
    startTime,
    endTime,
    referenceId,
    bannerEnabled,
    bannerBackgroundColor,
    bannerButtonText,
    bannerPosition,
    title,
    popupEnabled,
    dismissalType,
    delay,
    duration,
    imagePosition,
    formEnabled,
    submitButtonText,
    subscriptionTags,
    images,
    content,
    websiteComponentInteractions,
    formFieldsSettings,
  };
}

export function getCustomFormComponentConfiguration(announcementComponentConfiguration) {
  const { childConfigurations = [] } = announcementComponentConfiguration;
  const customFormConfiguration = childConfigurations.find(
    (configuration) => configuration.fastId === CUSTOM_FORM_FAST_ID,
  );
  return customFormConfiguration || {};
}

export function announcementConfigurationToForm(announcementComponentConfiguration) {
  if (!announcementComponentConfiguration) {
    return {};
  }

  const {
    data: {
      startTime,
      endTime,
      referenceId,
      bannerEnabled,
      bannerBackgroundColor,
      bannerButtonText,
      bannerPosition,
      popupEnabled,
      dismissalType,
      delay,
      duration,
      imagePosition,
    } = {},
    files = [],
    interactions = [],
    contentNodes = [],
  } = announcementComponentConfiguration;

  const bannerContent = _get(
    contentNodes.filter(({ contentType }) => contentType === TEXT_CONTENT_TYPE),
    '[0].content',
    '',
  );

  const popupContent = _get(
    contentNodes.filter(({ contentType }) => contentType === MARKDOWN_CONTENT_TYPE),
    '[0].content',
    '',
  );

  const websiteInteractions = objectToCamelCase(ensureInteractions(interactions));

  const images = files.map(({ id, order, metadata }) => ({ id, order, metadata }));

  const customFormConfiguration = getCustomFormComponentConfiguration(
    announcementComponentConfiguration,
  );

  const customFormConfigurationData =
    customFormConfigurationToAnnouncementForm(customFormConfiguration);

  return {
    startTime,
    endTime,
    referenceId,
    bannerEnabled,
    bannerBackgroundColor,
    bannerButtonText,
    bannerPosition,
    title: bannerContent,
    popupEnabled,
    dismissalType,
    delay,
    duration,
    imagePosition,
    images,
    content: popupContent,
    websiteComponentInteractions: websiteInteractions,
    ...customFormConfigurationData,
  };
}

export function announcementFormToConfiguration(
  announcementForm,
  announcementComponentConfiguration = {},
) {
  const {
    startTime,
    endTime,
    referenceId,
    bannerEnabled,
    bannerBackgroundColor,
    bannerButtonText,
    bannerPosition,
    title,
    popupEnabled,
    dismissalType,
    delay,
    duration,
    imagePosition,
    images,
    content,
    websiteComponentInteractions,
  } = announcementForm;

  const {
    contentNodes = [],
    id = null,
    order = 0,
    fastId = ANNOUNCEMENT_FAST_ID,
    layout = null,
  } = announcementComponentConfiguration;

  const bannerContentConfiguration = _head(
    contentNodes.filter(({ contentType }) => contentType === TEXT_CONTENT_TYPE),
    {},
  );
  const newBannerContentConfiguration = createContentComponentConfiguration({
    ...bannerContentConfiguration,
    contentName: 'banner',
    contentType: TEXT_CONTENT_TYPE,
    content: title,
  });
  const popupContentConfiguration = _head(
    contentNodes.filter(({ contentType }) => contentType === MARKDOWN_CONTENT_TYPE),
    {},
  );
  const newPopupContentConfiguration = createContentComponentConfiguration({
    ...popupContentConfiguration,
    contentName: 'popup',
    contentType: MARKDOWN_CONTENT_TYPE,
    content,
  });

  const newAnnouncementComponentConfiguration = {
    id,
    order,
    fastId,
    layout,
    data: {
      startTime,
      endTime,
      referenceId,
      bannerEnabled,
      bannerBackgroundColor,
      bannerButtonText,
      bannerPosition,
      popupEnabled,
      dismissalType,
      delay,
      duration,
      imagePosition,
    },
    files: images,
    interactions: websiteComponentInteractions,
    contentNodes: [newBannerContentConfiguration, newPopupContentConfiguration],
  };

  return newAnnouncementComponentConfiguration;
}
