import React from 'react';

import PropTypes from 'prop-types';

import { StylePropType } from '../../libs/proptypes';
import Validate from '../../libs/validate';

import '../../styles/core/forms/generic.scss';

class Field extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: '' };

    this.setError = this.setError.bind(this);
    this.clearError = this.clearError.bind(this);
    this.onValidate = this.onValidate.bind(this);
  }

  onValidate(val) {
    const { validationType } = this.props;

    const isValid = Validate.validate(validationType, val);

    if (!isValid) {
      this.setError();
    } else {
      this.clearError();
    }
  }

  setError() {
    const { validationType } = this.props;

    this.setState({ error: Validate.getErrorMessage(validationType) });
  }

  clearError() {
    this.setState({ error: '' });
  }

  render() {
    const { title, children, className, style, required } = this.props;
    const { error } = this.state;

    return (
      <div className={`field-container ${className}`} style={style}>
        {title && (
          <h4>
            {title}
            {required && <span className="field-required">*</span>}
          </h4>
        )}
        {typeof children === 'function'
          ? children({ onValidate: (val) => this.onValidate(val) })
          : children}
        <div className="field-error">{error}</div>
      </div>
    );
  }
}

Field.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  style: StylePropType,
  required: PropTypes.bool,
  validationType: PropTypes.string,
};

Field.defaultProps = {
  title: '',
  className: '',
  style: {},
  required: false,
  validationType: '',
};

export default Field;
