import _isEmpty from 'lodash/isEmpty';

import {
  MAX_INPUT_LENGTH,
  NO_APPENDIX_VALUE,
  MAX_INPUT_LENGTH_WARNING,
  MIN_INPUT_LENGTH_WARNING,
  TITLE_TOO_SHORT_WARNING_MESSAGE,
  TITLE_TOO_LONG_WARNING_MESSAGE,
  DEFAULT_PLACEHOLDER,
  LENGTH_BUFFER,
} from './SeoTitle.constants';
import API from '../../../../../../../libs/api';
import {
  getDefaultWritingActions,
  getWritingAssistantActions,
} from '../../../../../content/services/prompts';

export async function getAiGeneratedSeoTitle(businessId, businessType, params = {}) {
  return API.textCompletion(businessType, businessId, 'SEOPageTitlePrompt', params);
}

export function createBusinessNameValue(businessName = '') {
  return ` | ${businessName}`;
}

export function getPromptParams({
  pageType,
  additionalRequirements = '',
  isAppendingBusinessName,
  businessName = '',
}) {
  const businessNameValue = createBusinessNameValue(businessName);
  const finalAdditionalRequirements = isAppendingBusinessName
    ? `Do not include the business name value of ${businessName} in the output. ${additionalRequirements}`
    : additionalRequirements;

  const maxLength = isAppendingBusinessName
    ? Math.abs(MAX_INPUT_LENGTH_WARNING - businessNameValue.length - LENGTH_BUFFER)
    : MAX_INPUT_LENGTH_WARNING - LENGTH_BUFFER;

  return {
    additionalRequirements: finalAdditionalRequirements,
    pageType,
    maxLength,
  };
}

export function getAppendixOptions(businessNameValue) {
  return [
    { key: businessNameValue, text: businessNameValue, value: businessNameValue },
    { key: NO_APPENDIX_VALUE, text: NO_APPENDIX_VALUE, value: NO_APPENDIX_VALUE },
  ];
}

export function getMaxLength(isAppendingBusinessName, businessName) {
  if (!isAppendingBusinessName) {
    return MAX_INPUT_LENGTH;
  }
  return Math.abs(MAX_INPUT_LENGTH - businessName.length);
}

export function getTitleDisplayValue(title = '', isAppendingBusinessName, businessName = '') {
  if (isAppendingBusinessName) {
    return title.replace(businessName, '');
  }
  return title;
}

export function getNewTitleValue(title = '', isAppendingBusinessName, businessName = '') {
  if (isAppendingBusinessName) {
    return title + businessName;
  }
  return title.replace(businessName, '');
}

export function getWarningMessage(title) {
  if (_isEmpty(title)) {
    return '';
  }
  if (title.length > MAX_INPUT_LENGTH_WARNING) {
    return TITLE_TOO_LONG_WARNING_MESSAGE;
  }
  if (title.length < MIN_INPUT_LENGTH_WARNING) {
    return TITLE_TOO_SHORT_WARNING_MESSAGE;
  }
  return '';
}

export function getWritingSections({ onMakeSuggestion, placeholder = '', onWriteDefault } = {}) {
  let finalActions = [...getWritingAssistantActions({ onMakeSuggestion })];
  if (
    !_isEmpty(placeholder) &&
    placeholder.length < MAX_INPUT_LENGTH &&
    placeholder !== DEFAULT_PLACEHOLDER
  ) {
    finalActions = [...getDefaultWritingActions({ onWriteDefault }), ...finalActions];
  }
  return finalActions;
}

export function createSeoTitlePlaceholder(title, businessName) {
  return businessName ? `${title} | ${businessName}` : title;
}
