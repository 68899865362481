import React from 'react';

import PropTypes from 'prop-types';
import { Label, Icon } from 'semantic-ui-react';

const propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
};

const defaultProps = {
  icon: '',
  label: '',
  size: '',
};

export default function BusinessLabel({ icon, label, size }) {
  if (!label) {
    return null;
  }

  return (
    <Label className="business-label" size={size}>
      <Icon name={icon} /> {label}
    </Label>
  );
}

BusinessLabel.propTypes = propTypes;
BusinessLabel.defaultProps = defaultProps;
