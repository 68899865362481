import React from 'react';

import { Button } from 'semantic-ui-react';

import ConfirmModal from '../../../../common/ConfirmModal';
import useStyleRule from '../../hooks/useStyleRule';
import { formatStyleRuleValue, formatStyleRuleName } from '../../services/format';

export default function DeleteStyleRuleModal({ styleRuleName, open, trigger, onCancel }) {
  const { value, deleteRule } = useStyleRule(formatStyleRuleValue(styleRuleName));

  function onConfirm() {
    deleteRule();
    onCancel();
  }

  return (
    <ConfirmModal
      trigger={trigger}
      header="Delete Style Rule"
      content={
        <div className="content">
          Are you sure you want to remove the style rule{' '}
          <b>
            {formatStyleRuleName(styleRuleName)}: {value}
          </b>{' '}
          ?
        </div>
      }
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className="delete-style-rule-confirm-modal"
      confirmButton={<Button className="red" primary={false} content="Yes" />}
      cancelButton="Cancel"
    />
  );
}
