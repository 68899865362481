import React, { useState } from 'react';

import { Icon, Input } from 'semantic-ui-react';

import './PasswordInput.scss';

const PasswordInput = ({ ...passThroughProps }) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShow = () => setShowPassword(!showPassword);

  const inputType = showPassword ? 'text' : 'password';
  const iconName = showPassword ? 'eye slash' : 'eye';
  const inputProps = {
    ...passThroughProps,
    type: inputType,
    icon: <Icon name={iconName} link onClick={toggleShow} />,
  };
  return <Input {...inputProps} data-private />;
};

export default PasswordInput;
