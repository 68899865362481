import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';

import DateUtils from '../../../../../../../libs/date-utils';
import { ORDER_TURNAROUND_TIME } from '../../OrderSettings.constants';

import './OrderTurnAroundTimeField.scss';

const propTypes = {
  handleOnChangeForm: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

const defaultProps = {
  initialValue: '00:00',
};

export default function OrderTurnaroundTimeField({ handleOnChangeForm, initialValue }) {
  const initialValueParsed = DateUtils.convertTimeToMinutes(initialValue);
  const [time, setTime] = useState(initialValueParsed.toString());

  function handleOnchange(e, { value: valueString, name }) {
    const valueNumber = Math.abs(valueString);
    setTime(valueNumber.toString());
    const convertedValue = DateUtils.convertMinsToHrsMins(Number(valueNumber));
    const isValid = true;
    handleOnChangeForm(e, { value: convertedValue, name }, isValid);
  }

  useEffect(() => {
    setTime(DateUtils.convertTimeToMinutes(initialValue).toString());
  }, [initialValue]);

  return (
    <Form.Field id="order-turn-around" className="order-turn-around form-field">
      <label htmlFor="order-turn-around">Order Turnaround Time</label>
      <Input
        name={ORDER_TURNAROUND_TIME}
        onChange={handleOnchange}
        label={{ basic: true, content: 'minutes' }}
        labelPosition="right"
        value={time}
        min={0}
        type="number"
      />
      <p>Time that appears on the customer receipt</p>
    </Form.Field>
  );
}

OrderTurnaroundTimeField.propTypes = propTypes;
OrderTurnaroundTimeField.defaultProps = defaultProps;
