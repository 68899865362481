import { NEW_LABEL_DESCRIPTION } from './HourLabel.constants';
import { capitalizeAll } from '../../../../../libs/strings';

export function mapLabels(inputLabels = []) {
  return inputLabels.map((label) => ({ title: label, description: label }));
}

export function filterAndMapLabels(inputLabels = [], search) {
  const newLabel = {
    title: capitalizeAll(search || ''),
    description: NEW_LABEL_DESCRIPTION,
  };

  const filteredLabels = inputLabels.filter((label) =>
    label.toLowerCase().includes(search.toLowerCase()),
  );
  const objLabels = mapLabels(filteredLabels);
  return search ? objLabels.concat([newLabel]) : objLabels;
}
