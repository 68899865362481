import React from 'react';

import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';

import { CatalogItemVariationPropType } from '../../../proptypes';
import ItemVariationForm from '../ItemVariationForm';

import './ItemVariationFormList.scss';

const propTypes = {
  variations: PropTypes.arrayOf(CatalogItemVariationPropType).isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function ItemVariationFormList({ variations, onChange, onAdd, onRemove }) {
  const addVariationButton = (
    <Button className="item-variation-form-submit" basic onClick={onAdd}>
      Add variation
    </Button>
  );

  if (!variations || !variations.length) return addVariationButton;

  return (
    <>
      <Table className="item-variation-form" basic="very" columns={variations.length > 0 ? 3 : 2}>
        {variations.length > 1 && (
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={10}>Variation</Table.HeaderCell>
              <Table.HeaderCell width={4}>Total</Table.HeaderCell>
              <Table.HeaderCell width={2} />
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>
          {variations.map((variation) => (
            <ItemVariationForm
              key={variation.object_id}
              variation={variation}
              variationsCount={variations.length}
              onChange={onChange}
              onRemove={onRemove}
            />
          ))}
        </Table.Body>
      </Table>
      {addVariationButton}
    </>
  );
}

ItemVariationFormList.propTypes = propTypes;

export default ItemVariationFormList;
