import React from 'react';

import { Table, Input } from 'semantic-ui-react';

import PriceField from '../../../fields/PriceField/PriceField';

export default function MenuItemVariant({
  index,
  name: variationName,
  price: variationPrice,
  isMoney,
  onUpdate: onUpdateProp,
  onDelete: onDeleteProp,
}) {
  function onChange({ target: { name, value } }) {
    onUpdateProp(index, name, value);
  }

  function onDelete() {
    onDeleteProp(index);
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Input
          className="red asterisk after-big"
          placeholder="Variant Name"
          defaultValue={variationName}
          name="name"
          onChange={onChange}
          width={10}
        />
      </Table.Cell>
      <Table.Cell>
        <PriceField
          placeholder="1.99"
          priceInitialValue={variationPrice}
          name="price"
          onChange={onChange}
          isMoney={isMoney}
        />
      </Table.Cell>
      <Table.Cell>
        {index > 0 && (
          // TODO implement the following rule
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" onClick={onDelete}>
            Delete
          </a>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
