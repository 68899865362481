const pdfFilePlaceholder = 'https://www.apkmirror.com/wp-content/uploads/2020/04/5e997a02e4382.png';

export function getFileUrl(file) {
  let fileURL;
  if (file && typeof file === 'object') {
    if (file.type === 'application/pdf') {
      fileURL = pdfFilePlaceholder;
    } else {
      fileURL = URL.createObjectURL(file);
    }
  } else if (file && file.toLowerCase().endsWith('.pdf')) {
    fileURL = pdfFilePlaceholder;
  } else {
    fileURL = file;
  }

  return fileURL;
}
