import React from 'react';

export const StockImagesPickerContext = React.createContext({
  onSearch: () => undefined,
  onShowMore: () => undefined,
  searchValue: '',
  setSearchValue: () => undefined,
  stockImages: [],
  setStockImages: () => undefined,
  searchPage: 1,
  setSearchPage: () => undefined,
});

export const StockImagesPickerProvider = StockImagesPickerContext.Provider;
