import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import {
  getRecipePatchSelectionOptions,
  getCurrentPatchSelectionValue,
} from './RecipePatchVersionDropdown.utils';
import { ObjectPropType } from '../../../../../libs/proptypes';

import './RecipePatchVersionDropdown.scss';

const propTypes = {
  selectedValue: ObjectPropType.isRequired,
  data: ObjectPropType.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onVersionChange: PropTypes.func.isRequired,
};

const defaultProps = {
  disabled: false,
  loading: false,
  error: false,
};

export function RecipePatchVersionDropdown({
  selectedValue,
  data,
  onVersionChange,
  disabled,
  loading,
  error,
}) {
  return (
    <div>
      <Dropdown
        className="recipe-patch-dropdown"
        selection
        placeholder="Select a patch"
        value={getCurrentPatchSelectionValue(selectedValue, data).value}
        onChange={onVersionChange}
        disabled={disabled}
        loading={loading}
        error={error}
        options={getRecipePatchSelectionOptions(data)}
      />
    </div>
  );
}

RecipePatchVersionDropdown.propTypes = propTypes;
RecipePatchVersionDropdown.defaultProps = defaultProps;

export default RecipePatchVersionDropdown;
