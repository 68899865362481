import React from 'react';

import { Grid } from 'semantic-ui-react';

import { ColumnPropType } from '../../../../types/column.types';
import { RowPropType } from '../../../../types/row.types';
import CustomPageElement from '../../../CustomPageElement/CustomPageElement';

import './CustomPageGridColumn.scss';

const propTypes = {
  column: ColumnPropType.isRequired,
  row: RowPropType.isRequired,
};

const defaultProps = {};

export default function CustomPageGridColumn({ column, row }) {
  const { width, segments = [] } = column;

  return (
    <Grid.Column width={width} className="custom-page-grid-column">
      {segments.map((segment) => (
        <CustomPageElement key={segment.id} segment={segment} row={row} column={column} />
      ))}
    </Grid.Column>
  );
}

CustomPageGridColumn.propTypes = propTypes;
CustomPageGridColumn.defaultProps = defaultProps;
