import _isNil from 'lodash/isNil';

export function createWrappedInputChangeHandler(callback, input) {
  const { props = {} } = input;
  const { name, onChange, value: currentValue } = props;

  return async (e = {}, target = {}) => {
    const value = await callback(currentValue);

    if (_isNil(value)) {
      return;
    }

    const newTarget = { ...target, name, value };
    onChange({ ...e, target: newTarget }, newTarget);
  };
}

export function wrapActionChangeHandlers(actionSections, input) {
  return actionSections.map((actionSection) => ({
    ...actionSection,
    actions: actionSection.actions.map((action) => ({
      ...action,
      onClick: createWrappedInputChangeHandler(action.onClick, input),
    })),
  }));
}
