import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { getWritingAssistantActions } from 'components/modules/content/services/prompts';
import API from 'libs/api';

import { PROMPT_NAME } from './ContentNodeForm.constants';

export async function getContentNodeCompletion(businessType, businessId, params) {
  return API.textCompletion(businessType, businessId, PROMPT_NAME, params);
}

export function getWritingActions(
  { setting, value },
  { onHideText, onMakeSuggestion, onRestoreDefaultValue } = {},
) {
  const { contentApplicationOptions = {} } = setting || {};
  const {
    promptParams = {},
    required = false,
    default: defaultValue = '',
  } = contentApplicationOptions || {};

  let actions = [];
  let basicActions = [];

  const addRestoreDefault =
    !_isNil(onRestoreDefaultValue) && !_isEmpty(defaultValue) && value !== defaultValue;
  const addHideText = !_isNil(onHideText) && !required && !_isEmpty(value);
  const addMakeSuggestion = !_isNil(onMakeSuggestion) && !_isEmpty(promptParams);

  if (addRestoreDefault) {
    basicActions = [
      ...basicActions,
      {
        title: 'Restore Default',
        icon: 'pencil square',
        onClick: onRestoreDefaultValue,
      },
    ];
  }

  if (addHideText) {
    basicActions = [
      ...basicActions,
      {
        title: 'Hide Text',
        icon: 'eye slash',
        onClick: onHideText,
      },
    ];
  }

  if (addMakeSuggestion) {
    actions = [...actions, ...getWritingAssistantActions({ onMakeSuggestion })];
  }

  if (!_isEmpty(basicActions)) {
    actions = [
      {
        title: 'Basic Actions',
        actions: basicActions,
      },
      ...actions,
    ];
  }

  return actions;
}
