import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import ImageCard from 'components/fields/ImageCard';
import { METADATA_KEY_GUMLET } from 'components/modules/files/constants/business-file-configuration';
import { createQueryParams } from 'components/modules/files/services/gumlet';
import {
  BusinessFilePropType,
  BusinessFileConfigurationPropType,
} from 'components/modules/files/types/files';
import useDebounce from 'hooks/useDebounce';

import { DEBOUNCE_TIMER } from './DisplaySettingsForm.constants';
import ImageQueryParamBuilder from '../../../ImageQueryParamBuilder';
import { DESKTOP_DEVICE } from '../../../ImageQueryParamBuilder/ImageQueryParamBuilder.constants';

import './DisplaySettingsForm.scss';

const propTypes = {
  businessFile: BusinessFilePropType.isRequired,
  businessFileConfiguration: BusinessFileConfigurationPropType.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function DisplaySettingsForm({ businessFile, businessFileConfiguration, onChange }) {
  const [imageLoading, setImageLoading] = useState(true);

  const { file } = businessFile || {};
  const formData = _get(businessFileConfiguration, `metadata.${METADATA_KEY_GUMLET}`, {});
  const device = DESKTOP_DEVICE;
  const queryParamsUrl = createQueryParams(_get(formData, device, {}));
  const fileUrl = _isEmpty(file) || _isNil(file) ? null : file + queryParamsUrl;
  const debouncedUrl = useDebounce(fileUrl, DEBOUNCE_TIMER);

  function onImageLoad() {
    setTimeout(() => {
      setImageLoading(false);
    }, DEBOUNCE_TIMER / 2);
  }

  useEffect(() => {
    if (!_isEmpty(debouncedUrl)) {
      setImageLoading(true);
    }
  }, [debouncedUrl]);

  const imageCardContainerClassName = clsx({ 'image-card-container': true, loading: imageLoading });

  return (
    <div className="display-settings-form">
      <div className={imageCardContainerClassName}>
        <ImageCard url={debouncedUrl} width={null} onLoad={onImageLoad} />
        {imageLoading && <Loader active inline />}
      </div>
      <ImageQueryParamBuilder formData={formData} onChange={onChange} />
    </div>
  );
}

DisplaySettingsForm.propTypes = propTypes;
DisplaySettingsForm.defaultProps = defaultProps;
