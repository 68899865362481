import React from 'react';

import { Button } from 'semantic-ui-react';

import useSemanticUiProps from '../../../../../hooks/useSemanticUiProps';

import './WritingAssistantButton.scss';

export default function WritingAssistantButton(props) {
  const { getButtonProps } = useSemanticUiProps();

  return (
    <Button
      className="writing-assistant-button"
      basic
      content="Need help writing?"
      icon="chevron down"
      labelPosition="right"
      {...getButtonProps(props)}
    />
  );
}
