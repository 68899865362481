import React, { useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import ConfirmModal from 'components/common/ConfirmModal';
import ErrorHandler from 'libs/errors/errors';

import useRedirect from '../../hooks/use-redirect';
import { RedirectPropType } from '../../types/redirect';

import './DeleteRedirectButton.scss';

const propTypes = {
  redirect: RedirectPropType.isRequired,
};

const defaultProps = {};

export default function DeleteRedirectButton({ redirect }) {
  const { deleteRedirect, loading } = useRedirect({ initialFetch: false });

  const [showModal, setShowModal] = useState(false);

  const { id: redirectId } = redirect || {};

  function displayModal() {
    setShowModal(true);
  }

  function hideModal() {
    setShowModal(false);
  }

  async function onDeleteConfirm() {
    try {
      await deleteRedirect(redirectId);
      hideModal();
    } catch (error) {
      ErrorHandler.capture(error);
    }
  }

  return (
    <>
      <ConfirmModal
        open={showModal}
        onClose={hideModal}
        header="Delete Redirect"
        content="Are you sure you want to remove this redirect?"
        onCancel={hideModal}
        onConfirm={onDeleteConfirm}
        className="delete-redirect-modal"
        confirmButton={
          <Button className="delete-red" content="YES" disabled={loading} loading={loading} />
        }
        cancelButton={<Button className="action-button-gray" content="CANCEL" disabled={loading} />}
      />

      <Button
        icon
        className="delete-redirect-button delete-red"
        content="Delete"
        onClick={displayModal}
      >
        <Icon name="trash" />
      </Button>
    </>
  );
}

DeleteRedirectButton.propTypes = propTypes;
DeleteRedirectButton.defaultProps = defaultProps;
