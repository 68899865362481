export const GOOGLE_PROVIDER = 'Google';
export const GOOGLE_SERVICE_TYPE = 'Profile';
export const CLOVER_PROVIDER = 'Clover';
export const CLOVER_SERVICE_TYPE = 'Merchant';
export const CLOVER_COLLECTION_METHOD = 'CloverMarketplace';
export const ZUPPLER_COLLECTION_METHOD = 'Zuppler';
export const DAYSMART_COLLECTION_METHOD = 'DaySmart';

export const ProviderServiceStatus = {
  Complete: 'Complete',
  Approved: 'Approved',
  Pending: 'Pending',
  Blocked: 'Blocked',
};
