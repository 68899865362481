import { useCallback } from 'react';

function useCopyToClipboard() {
  function isClipboardAvailable() {
    return navigator.clipboard !== undefined;
  }

  const handleCopy = useCallback((data, onSuccess, onError) => {
    if (!isClipboardAvailable()) {
      onError('Copy to clipboard not supported!');
      return;
    }

    const str = typeof data === 'object' ? JSON.stringify(data, null, 2) : data;

    navigator.clipboard.writeText(str).then(
      () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      () => {
        if (onError) {
          onError();
        }
      },
    );
  }, []);

  return [handleCopy];
}

export default useCopyToClipboard;
