export function getOptions(values, { allowEmpty = false } = {}) {
  const options = (values || []).map((value) => ({
    key: value,
    text: value,
    value,
  }));

  if (allowEmpty) {
    options.unshift({
      key: 'empty',
      text: '',
      value: '',
    });
  }
  return options;
}
