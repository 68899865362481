import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { flagBusinessSaved } from '../../../../../actions/business';
import API from '../../../../../libs/api';
import ErrorHandler from '../../../../../libs/errors/errors';
import Utils from '../../../../../libs/utils';
import { selectBusiness } from '../../../../../selectors';
import { selectTeams } from '../../selectors/team';
import { setAllTeams, addOneTeam, updateOneTeam, removeOneTeam } from '../../slices/team.slice';

export default function useTeam() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const teams = useSelector(selectTeams);
  const business = useSelector(selectBusiness);
  const { businessInfo: businessInfoId } = business;

  async function executeAsyncFunction(asyncFunction) {
    try {
      setLoading(true);
      setError(null);
      const result = await asyncFunction();
      dispatch(flagBusinessSaved(true));
      return result;
    } catch (err) {
      ErrorHandler.capture(err);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }

  function getNextOrder() {
    return Math.max(teams.length, ...teams.map((team) => (team.order || 0) + 1));
  }

  function setTeams(newTeams) {
    dispatch(setAllTeams(newTeams));
  }

  async function refreshTeams() {
    return executeAsyncFunction(async () => {
      const { data: response } = await API.getTeams(businessInfoId);
      setTeams(response);
      return response;
    });
  }

  async function addTeam({ name, description }) {
    return executeAsyncFunction(async () => {
      const order = getNextOrder();
      const { data: createdTeam } = await API.addTeam(businessInfoId, {
        name,
        description,
        order,
      });
      dispatch(addOneTeam(createdTeam));
      return createdTeam;
    });
  }

  async function updateTeam(teamId, { name, description, order }) {
    return executeAsyncFunction(async () => {
      const { data: updatedTeam } = await API.updateTeam(teamId, { name, description, order });
      dispatch(updateOneTeam({ id: teamId, changes: updatedTeam }));
      return updatedTeam;
    });
  }

  async function deleteTeam(teamId) {
    return executeAsyncFunction(async () => {
      const { data: response } = await API.deleteTeam(teamId);
      dispatch(removeOneTeam(teamId));
      return response;
    });
  }

  const sortedTeams = teams.sort(Utils.sortByOrder);

  return {
    teams: sortedTeams,
    loading,
    error,
    setTeams,
    refreshTeams,
    addTeam,
    updateTeam,
    deleteTeam,
  };
}
