import _isEmpty from 'lodash/isEmpty';

import {
  DEFAULT_PLACEHOLDER,
  DESCRIPTION_TOO_LONG_MESSAGE,
  DESCRIPTION_TOO_SHORT_MESSAGE,
  MAX_INPUT_LENGTH_WARNING,
  MAX_LENGTH,
  MIN_INPUT_LENGTH_WARNING,
} from './SeoDescription.constants';
import API from '../../../../../../../libs/api';
import {
  getDefaultWritingActions,
  getWritingAssistantActions,
} from '../../../../../content/services/prompts';

export async function getAiGeneratedSeoDescription(businessId, businessType, params = {}) {
  return API.textCompletion(businessType, businessId, 'SEOPageDescriptionPrompt', params);
}

export function getWarningMessage(description) {
  if (_isEmpty(description)) {
    return '';
  }
  if (description.length > MAX_INPUT_LENGTH_WARNING) {
    return DESCRIPTION_TOO_LONG_MESSAGE;
  }
  if (description.length < MIN_INPUT_LENGTH_WARNING) {
    return DESCRIPTION_TOO_SHORT_MESSAGE;
  }
  return '';
}

export function getWritingSections({ onMakeSuggestion, placeholder = '', onWriteDefault } = {}) {
  let finalActions = [...getWritingAssistantActions({ onMakeSuggestion })];
  if (
    !_isEmpty(placeholder) &&
    placeholder.length < MAX_LENGTH &&
    placeholder !== DEFAULT_PLACEHOLDER
  ) {
    finalActions = [...getDefaultWritingActions({ onWriteDefault }), ...finalActions];
  }
  return finalActions;
}
