import React from 'react';

import PropTypes from 'prop-types';

import ErrorHandler from '../../../../../libs/errors';

const propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node.isRequired,
  onError: PropTypes.func,
};

const defaultProps = {
  onError: null,
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { onError } = this.props;

    ErrorHandler.capture(error, { errorInfo });

    if (onError) {
      onError(error, errorInfo);
    }
  }

  render() {
    const { fallback, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

ErrorBoundary.propTypes = propTypes;
ErrorBoundary.defaultProps = defaultProps;
