import React from 'react';

import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';

import Field from './Field';
import { StylePropType } from '../../libs/proptypes';

const TextareaField = ({ value, onChange, placeholder, disabled, onValidate, error, ...rest }) => (
  <Field {...rest}>
    <TextArea
      value={value}
      error={error}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onValidate}
    />
  </Field>
);

TextareaField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  style: StylePropType,
};

TextareaField.defaultProps = {
  value: '',
  title: '',
  placeholder: '',
  disabled: false,
  style: {},
};

export default TextareaField;
