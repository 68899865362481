export const TOOLBAR_VALUES = {
  bold: 'bold',
  italic: 'italic',
  strikethrough: 'strikethrough',
  heading: 'heading',
  headingSmaller: 'heading-smaller',
  headingBigger: 'heading-bigger',
  heading1: 'heading-1',
  heading2: 'heading-2',
  heading3: 'heading-3',
  code: 'code',
  quote: 'quote',
  unorderedList: 'unordered-list',
  orderedList: 'ordered-list',
  cleanBlock: 'clean-block',
  link: 'link',
  image: 'image',
  uploadImage: 'upload-image',
  table: 'table',
  horizontalRule: 'horizontal-rule',
  preview: 'preview',
  sideBySide: 'side-by-side',
  fullscreen: 'fullscreen',
  guide: 'guide',
  undo: 'undo',
  redo: 'redo',
  separator: '|',
};

export const DEFAULT_OPTIONS_TOOLBAR = [
  TOOLBAR_VALUES.bold,
  TOOLBAR_VALUES.italic,
  TOOLBAR_VALUES.heading2,
  TOOLBAR_VALUES.heading3,
  TOOLBAR_VALUES.separator,
  TOOLBAR_VALUES.quote,
  TOOLBAR_VALUES.unorderedList,
  TOOLBAR_VALUES.orderedList,
  TOOLBAR_VALUES.separator,
  TOOLBAR_VALUES.link,
  TOOLBAR_VALUES.separator,
  TOOLBAR_VALUES.preview,
  TOOLBAR_VALUES.sideBySide,
];

export const DEFAULT_OPTIONS_STATUS = [];

export const DEFAULT_OPTIONS = {
  placeholder: '',
  status: DEFAULT_OPTIONS_STATUS,
  toolbar: DEFAULT_OPTIONS_TOOLBAR,
  sideBySideFullscreen: false,
  minHeight: '240px',
  // Custom options, not in easymde
  autoDisplaySideBySidePreview: true,
};

export const INVALID_HTML_TAGS = ['script', 'style'];
