import React from 'react';

import PropTypes from 'prop-types';

import {
  createNewVariations,
  removeVariation,
  updateVariation,
} from './TeamMemberServiceAssociation.utils';
import { objectToCamelCase } from '../../../../../../../libs/format';
import CatalogItemSearch from '../../../../../catalog/components/CatalogItemSearch';
import { TeamMemberServiceVariationPropType } from '../../../types/team-member-service';
import TeamMemberServicesTable from '../TeamMemberServicesTable';

import './TeamMemberServiceAssociation.scss';

const propTypes = {
  selectedServices: PropTypes.arrayOf(TeamMemberServiceVariationPropType).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function TeamMemberServiceAssociation({ selectedServices, onUpdate: onUpdateProp }) {
  const services = objectToCamelCase(selectedServices);

  function onResultSelect(selectedService) {
    const newVariations = createNewVariations(selectedService, services);
    onUpdateProp([...(services || []), ...newVariations]);
  }

  function onVariationUpdate(variationToUpdate) {
    const newServices = updateVariation(variationToUpdate, services);
    onUpdateProp(newServices);
  }

  function onVariationRemoved(variationToRemove) {
    const newServices = removeVariation(variationToRemove, services);
    onUpdateProp(newServices);
  }

  const selectedVariationIds = services.map(({ itemVariation }) => itemVariation);

  return (
    <div className="team-member-service-association">
      <CatalogItemSearch
        onResultSelect={onResultSelect}
        excludeVariationIds={selectedVariationIds}
      />
      <TeamMemberServicesTable
        services={services}
        onUpdate={onVariationUpdate}
        onDelete={onVariationRemoved}
      />
    </div>
  );
}

TeamMemberServiceAssociation.propTypes = propTypes;
TeamMemberServiceAssociation.defaultProps = defaultProps;
