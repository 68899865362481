import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import {
  TOGGLE_PRICE_CHECKBOX_TEXT,
  TOGGLE_PRICE_HELP_DESCRIPTION,
} from './PriceLabelToggle.constants';
import HelpTooltip from '../../../../common/HelpTooltip/HelpTooltip';

import './PriceLabelToggle.scss';

const propTypes = {
  defaultIsMoney: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  hideHelpTooltip: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  defaultIsMoney: false,
  hideHelpTooltip: false,
  disabled: false,
};

export default function PriceLabelToggle({ defaultIsMoney, onChange, hideHelpTooltip, disabled }) {
  return (
    <div className="price-label-toggle">
      <Checkbox
        toggle
        onChange={onChange}
        className="price-toggle"
        label={TOGGLE_PRICE_CHECKBOX_TEXT}
        defaultChecked={!defaultIsMoney}
        disabled={disabled}
      />
      {!hideHelpTooltip && (
        <HelpTooltip
          title={TOGGLE_PRICE_CHECKBOX_TEXT}
          description={TOGGLE_PRICE_HELP_DESCRIPTION}
          position="bottom left"
        />
      )}
    </div>
  );
}

PriceLabelToggle.propTypes = propTypes;
PriceLabelToggle.defaultProps = defaultProps;
