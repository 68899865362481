import PropTypes from 'prop-types';

const RecipeProptypes = PropTypes.shape({
  id: PropTypes.id,
  name: PropTypes.string,
  recipeVersion: PropTypes.string,
  schemaVersion: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

export default RecipeProptypes;
