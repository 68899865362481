const ROOT_REQUIRED_KEYS = ['name', 'version', 'metadata', 'layouts', 'pages'];
const ROOT_PARENT_KEY_PATH_OPTIONS = [];

const LAYOUT_REQUIRED_KEYS = ['displayName', 'components'];
const LAYOUT_PARENT_KEY_PATH_OPTIONS = [['layouts', '*']];

const PAGE_REQUIRED_KEYS = ['name', 'slug', 'components'];
const PAGE_PARENT_KEY_PATH_OPTIONS = [['pages']];

const COMPONENT_REQUIRED_KEYS = ['name', 'type'];
const COMPONENT_PARENT_KEY_PATH_OPTIONS = [
  ['children'],
  ['pages', 'components'],
  ['layouts', '*', 'components'],
  ['resolve', 'value'],
  ['resolve', 'value', '*'],
];

const PROP_REQUIRED_KEYS = ['name', 'resolve'];
const PROP_PARENT_KEY_PATH_OPTIONS = [['props']];

const NON_PRIMITIVE_VALUE_REQUIRED_KEYS = ['type', 'value'];
const NON_PRIMITIVE_PARENT_KEY_PATH_OPTIONS = [
  ['resolve'],
  ['content'],
  ['args', 'value'],
  ['options', 'value'],
  ['value', '*'],
];

const PLUGIN_REQUIRED_KEYS = ['resolve'];
const PLUGIN_PARENT_KEY_PATH_OPTIONS = [['metadata', 'plugins']];

const PLUGIN_OPTION_REQUIRED_KEYS = ['name'];
const PLUGIN_OPTION_PARENT_KEY_PATH_OPTIONS = [['metadata', 'plugins', 'options']];

const FUNCTION_REQUIRED_KEYS = [['body'], ['name'], ['name', 'requires', 'importType']];
const FUNCTION_PARENT_KEY_PATH_OPTIONS = [
  ['conditions', 'func'],
  ['resolve', 'value'],
  ['resolve', 'value', '*'],
];

const FUNCTION_ARGUMENT_REQUIRED_KEYS = ['name', 'value'];
const FUNCTION_ARGUMENT_PARENT_KEY_PATH_OPTIONS = [['args']];

const CONDITION_REQUIRED_KEYS = ['func'];
const CONDITION_PARENT_KEY_PATH_OPTIONS = [
  ['metadata', 'siteMetadata', '*', 'conditions'],
  ['metadata', 'pathPrefix', 'conditions'],
  ['pages', 'conditions'],
  ['components', 'conditions'],
];

export const VALUE_TYPE_OBJECT = 'object';

export const VALUE_TYPE_ARRAY = 'array';

export const NODE_TYPES = {
  ROOT: 'ROOT',
  LAYOUT: 'LAYOUT',
  PAGE: 'PAGE',
  COMPONENT: 'COMPONENT',
  PROP: 'PROP',
  VALUE: 'VALUE',
  PLUGIN: 'PLUGIN',
  PLUGIN_OPTION: 'PLUGIN_OPTION',
  FUNCTION: 'FUNCTION',
  FUNCTION_ARGUMENT: 'FUNCTION_ARGUMENT',
  CONDITION: 'CONDITION',
  UNRESOLVED: 'UNRESOLVED',
};

export const typeRequiredKeys = {
  ROOT: ROOT_REQUIRED_KEYS,
  LAYOUT: LAYOUT_REQUIRED_KEYS,
  PAGE: PAGE_REQUIRED_KEYS,
  COMPONENT: COMPONENT_REQUIRED_KEYS,
  PROP: PROP_REQUIRED_KEYS,
  VALUE: NON_PRIMITIVE_VALUE_REQUIRED_KEYS,
  PLUGIN: PLUGIN_REQUIRED_KEYS,
  PLUGIN_OPTION: PLUGIN_OPTION_REQUIRED_KEYS,
  FUNCTION: FUNCTION_REQUIRED_KEYS,
  FUNCTION_ARGUMENT: FUNCTION_ARGUMENT_REQUIRED_KEYS,
  CONDITION: CONDITION_REQUIRED_KEYS,
};

export const typeParentKeyPathOptions = {
  ROOT: ROOT_PARENT_KEY_PATH_OPTIONS,
  LAYOUT: LAYOUT_PARENT_KEY_PATH_OPTIONS,
  PAGE: PAGE_PARENT_KEY_PATH_OPTIONS,
  COMPONENT: COMPONENT_PARENT_KEY_PATH_OPTIONS,
  PROP: PROP_PARENT_KEY_PATH_OPTIONS,
  VALUE: NON_PRIMITIVE_PARENT_KEY_PATH_OPTIONS,
  PLUGIN: PLUGIN_PARENT_KEY_PATH_OPTIONS,
  PLUGIN_OPTION: PLUGIN_OPTION_PARENT_KEY_PATH_OPTIONS,
  FUNCTION: FUNCTION_PARENT_KEY_PATH_OPTIONS,
  FUNCTION_ARGUMENT: FUNCTION_ARGUMENT_PARENT_KEY_PATH_OPTIONS,
  CONDITION: CONDITION_PARENT_KEY_PATH_OPTIONS,
};

export const typeLabelKey = {
  ROOT: '@',
  LAYOUT: 'displayName',
  PAGE: 'name',
  COMPONENT: 'name',
  PROP: 'name',
  VALUE: '',
  PLUGIN: 'resolve',
  PLUGIN_OPTION: 'name',
  FUNCTION: 'name',
  FUNCTION_ARGUMENT: 'name',
  CONDITION: ({ operator, func }) => `(${operator}) - ${func.name}`,
};
