import React, { useState } from 'react';

import { Loader, Message } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { CUSTOMER_TAGS_FIELD_NAME } from './CustomersTab.constants';
import API from '../../../../../libs/api';
import {
  selectBusinessId,
  selectBusinessLocations,
  selectBusinessType,
} from '../../../../../selectors';
import CustomerContactsTableBody from '../../../../modules/customers/components/CustomerContactsTableBody';
import CustomerContactsTableHeader from '../../../../modules/customers/components/CustomerContactsTableHeader';
import CustomerEditModal from '../../../../modules/customers/components/CustomerEditModal';
import CustomersPageHeader from '../../../../modules/customers/components/CustomersPageHeader';
import useCustomerTags from '../../../../modules/customers/hooks/use-customer-tags';
import {
  getDefaultLocation,
  updateCustomer,
} from '../../../../modules/customers/services/customers';
import useServerTableControls from '../../../../modules/foundation/hooks/use-server-table-controls/use-server-table-controls';
import { ASCENDING } from '../../../../modules/foundation/hooks/use-server-table-controls/use-server-table-controls.constants';
import PaginatedTable from '../../../../modules/tables/components/PaginatedTable';

const propTypes = {};

const defaultProps = {};

export default function CustomersTab() {
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const locations = useSelector(selectBusinessLocations);
  const defaultLocation = getDefaultLocation(locations) || {};

  const [locationId, setLocationId] = useState(defaultLocation.id);
  const [showCustomerEditModal, setShowCustomerEditModal] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({});
  const { customerTags, handleAddTag } = useCustomerTags();

  async function getCustomers({ searchValue, sortedColumn, sortDirection, currentPage }) {
    if (locationId) {
      const sort = sortDirection === ASCENDING ? '' : '-';
      const ordering = sortedColumn ? `${sort}${sortedColumn}` : '';

      const { data } = await API.getCustomerContacts(businessId, businessType, locationId, {
        page: currentPage,
        filter: searchValue,
        ordering,
      });

      return data;
    }
    return null;
  }

  const {
    fetchData,
    data: customers,
    setData: setCustomers,
    loading,
    errorMessage,
    currentPage,
    totalPages,
    searchValue,
    sortedColumn,
    sortDirection,
    setCurrentPage,
    setSearchValue,
    onSort,
    reset,
  } = useServerTableControls(getCustomers, [locationId]);

  function clearValues() {
    reset();
    setLocationId(undefined);
  }

  function changeLocation(location) {
    const { id: newLocationId } = location;
    clearValues();
    setLocationId(newLocationId);
  }

  function onPageChange(e, { activePage }) {
    setCurrentPage(activePage);
  }

  function onFilterChange(e, { value }) {
    setSearchValue(value);
  }

  function displayCustomerEditModal(show = true, customer) {
    setCurrentCustomer(customer);
    setShowCustomerEditModal(show);
  }

  async function handleUpdateCustomer(customerId, data) {
    const { data: customerData } = await API.updateCustomerContact(
      businessId,
      businessType,
      locationId,
      customerId,
      data,
    );
    const updatedCustomers = updateCustomer(customers, customerId, customerData);
    setCustomers(updatedCustomers);

    const { tags } = data;
    if (tags) {
      tags.forEach((tag) => handleAddTag(CUSTOMER_TAGS_FIELD_NAME, tag));
    }
  }

  async function onCustomerSave(customer) {
    if (customer.id) {
      await handleUpdateCustomer(customer.id, customer);
    } else {
      await API.createCustomerContact(businessId, businessType, locationId, customer);
      await fetchData();
    }
    displayCustomerEditModal(false, {});
  }

  function onEditCustomerChange(field, value) {
    setCurrentCustomer((previousCustomer) => ({
      ...previousCustomer,
      [field]: value,
    }));
    if (field === CUSTOMER_TAGS_FIELD_NAME) {
      value.forEach((tag) => handleAddTag(CUSTOMER_TAGS_FIELD_NAME, tag));
    }
  }

  return (
    <div className="customers-tab">
      <CustomersPageHeader
        locationId={locationId}
        onChangeLocation={changeLocation}
        onFilterCustomer={onFilterChange}
        loading={loading}
        filterValue={searchValue}
        onAddCustomerClick={() => {
          displayCustomerEditModal(true, {});
        }}
      />

      <CustomerEditModal
        open={showCustomerEditModal}
        onClose={() => {
          displayCustomerEditModal(false, {});
        }}
        onSave={onCustomerSave}
        customer={currentCustomer}
        onChange={onEditCustomerChange}
        availableTags={customerTags.tags}
      />

      {loading && <Loader active inline="centered" />}
      {!loading && (
        <PaginatedTable
          tableHeader={
            <CustomerContactsTableHeader
              handleSort={onSort}
              sortedColumn={sortedColumn}
              directionSort={sortDirection}
            />
          }
          tableBody={
            <CustomerContactsTableBody
              customers={customers}
              onUpdateCustomer={handleUpdateCustomer}
              onEditButtonClick={(customer) => {
                displayCustomerEditModal(true, customer);
              }}
              columns={6}
              availableTags={customerTags.tags}
            />
          }
          onPageChange={onPageChange}
          activePage={currentPage}
          totalPages={totalPages}
          columns={6}
        />
      )}

      {errorMessage && <Message header={errorMessage} negative />}
    </div>
  );
}

CustomersTab.propTypes = propTypes;
CustomersTab.defaultProps = defaultProps;
