import React, { useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { addMenuItem as addMenuItemConnect, updateMenuItem } from '../../../../actions/business';
import API from '../../../../libs/api';
import Utils from '../../../../libs/utils';
import { selectBusinessId, selectBusinessType } from '../../../../selectors';
import {
  selectBusinessCatalogCategories,
  selectBusinessCatalogItems,
} from '../../../../selectors/catalog';
import Dots from '../../../common/sortables/Dots';
import AddMenuItem from '../item/AddMenuItem';
import MenuItem from '../item/MenuItem';

const DragHandle = SortableHandle(() => <Dots rowGap="1em" style={{ border: '0' }} />);

const SortableMenuItem = SortableElement(({ item, itemIndex, categoryIndex, categoryId }) => {
  return (
    <MenuItem
      item={item}
      categoryId={categoryId}
      categoryIndex={categoryIndex}
      leftContent={<DragHandle />}
      itemIndex={itemIndex}
    />
  );
});

const SortableMenuItemList = SortableContainer(
  ({ categoryId, items, categoryIndex, addMenuItem }) => {
    return (
      <div className="menu-items">
        {items.map((item, index) => (
          <SortableMenuItem
            index={index} // used for drag-and-drop feature
            itemIndex={item.globalIndex}
            key={item.id || item.localId}
            item={item}
            categoryId={categoryId}
            categoryIndex={categoryIndex}
          />
        ))}
        <AddMenuItem onClick={() => addMenuItem({ categoryIndex })} />
      </div>
    );
  },
);

export default function MenuCategory({ categoryId, categoryIndex }) {
  const dispatch = useDispatch();

  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);

  const allCategories = useSelector(selectBusinessCatalogCategories);
  const category = allCategories[categoryIndex] || {};
  const { items: categoryItemIds } = category;
  const categoryItems = useSelector((state) =>
    selectBusinessCatalogItems(state, { itemIds: categoryItemIds }),
  );

  const helperContainer = useRef(null);

  async function updateItems(items) {
    const promises = items.map((item) =>
      API.updateMenuItem(businessId, businessType, item.id, { order: item.order }),
    );
    return Promise.all(promises);
  }

  async function updateItemOrder({ oldIndex, newIndex }) {
    if (oldIndex === newIndex) {
      return undefined;
    }

    Utils.moveListItem(categoryItems, oldIndex, newIndex);
    const itemsToUpdate = Utils.itemsToUpdateOrder(categoryItems);

    itemsToUpdate.forEach((item) => {
      dispatch(
        updateMenuItem({
          index: item.globalIndex,
          values: item,
        }),
      );
    });
    return updateItems(itemsToUpdate);
  }

  function addMenuItem(payload) {
    dispatch(addMenuItemConnect(payload));
  }

  return (
    <div className="menu-category">
      <div className="ui form sortable-menu-item-list-helper" ref={helperContainer} />
      <SortableMenuItemList
        axis="y"
        lockAxis="y"
        useDragHandle
        addMenuItem={addMenuItem}
        categoryId={categoryId}
        categoryIndex={categoryIndex}
        items={categoryItems}
        onSortEnd={updateItemOrder}
        helperContainer={() => helperContainer.current}
      />
    </div>
  );
}
