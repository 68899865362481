import _get from 'lodash/get';

export function getFunctionArgValue(func, argName) {
  if (!func || !argName) {
    return undefined;
  }

  const { args = [] } = func;

  const arg = args.find(({ name: aArgName }) => aArgName === argName);
  return _get(arg, 'value');
}
