import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import './RadioSelect.scss';

const propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  formatLabel: PropTypes.func,
};

const defaultProps = {
  value: '',
  formatLabel: (value) => value,
};

export default function RadioSelect({ value, onSelect, formatLabel, options }) {
  return (
    <Form className="radio-selector">
      <Form.Group>
        {options.map((option, index) => (
          <Form.Radio
            key={option}
            label={<label htmlFor={`${option}-${index}`}>{formatLabel(option)}</label>}
            value={value}
            checked={value === option}
            onChange={() => onSelect(option)}
            id={`${option}-${index}`}
          />
        ))}
      </Form.Group>
    </Form>
  );
}

RadioSelect.propTypes = propTypes;
RadioSelect.defaultProps = defaultProps;
