import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Dropdown, Icon } from 'semantic-ui-react';

import { DESTINATION_CATALOG } from '../../../../../constants/menu';
import MenuUpload from '../../../../common/MenuUpload';
import { InventoryProvider } from '../../../../core/MenuForm/Menu.context';
import ImportWithWoflowModal from '../ImportWithWoflowModal';
import { CatalogObjectGenericPropType } from '../proptypes';

import './ImportLibraryButton.scss';

const propTypes = {
  onUpload: PropTypes.func,
  catalog: PropTypes.arrayOf(CatalogObjectGenericPropType),
  isLoadingCatalog: PropTypes.bool,
};

const defaultProps = {
  onUpload: () => {},
  catalog: [],
  isLoadingCatalog: false,
};

function ImportLibraryButton({ onUpload, catalog, isLoadingCatalog }) {
  const [woflowModalOpen, setWoflowModalOpen] = useState(false);
  const context = { entityName: 'Catalog' };
  return (
    <InventoryProvider value={context}>
      <ImportWithWoflowModal open={woflowModalOpen} onClose={() => setWoflowModalOpen(false)} />
      <MenuUpload
        destination={DESTINATION_CATALOG}
        catalog={catalog}
        isLoadingCatalog={isLoadingCatalog}
        onClose={onUpload}
      >
        {({ onClick: onFromFileClick, isLoading }) => (
          <Dropdown
            className="import-library-button"
            trigger={
              <Button className="action-button" loading={isLoading}>
                <Icon name="download" />
                Import library
                <Icon name="chevron down" />
              </Button>
            }
            icon=""
          >
            <Dropdown.Menu>
              <Dropdown.Item onClick={onFromFileClick}>From File</Dropdown.Item>
              <Dropdown.Item onClick={() => setWoflowModalOpen(true)}>With Woflow</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </MenuUpload>
    </InventoryProvider>
  );
}

ImportLibraryButton.propTypes = propTypes;
ImportLibraryButton.defaultProps = defaultProps;
export default ImportLibraryButton;
