import { useRef } from 'react';

import useAsyncEffect from '../../components/modules/foundation/hooks/use-async-effect';

export default function onUpdate(func, dependencies) {
  const didMount = useRef(false);

  useAsyncEffect(async () => {
    if (didMount.current) {
      await func();
    } else {
      didMount.current = true;
    }
  }, dependencies);
}
