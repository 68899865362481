import React, { useState } from 'react';

import { Dimmer, Loader } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import { CREATE_PATCH_DRAFT_KEY, SAVE_PATCH_KEY } from './RecipesEditForm.constants';
import { getNextRecipePatchVersion } from './RecipesEditForm.utils';
import RecipeControlPanes from './components/RecipeControlPanes';
import RecipeEditor from './components/RecipeEditor';
import RecipeNavigation from './components/RecipeNavigation';
import RecipePatchActionButton from './components/RecipePatchActionButton';
import RecipePatchVersionDropdown from './components/RecipePatchVersionDropdown';
import RecipeToolbar from './components/RecipeToolbar';
import CreateModalPatch from './components/modals/CreateModalPatch';
import { RecipeContextProvider } from './context/RecipeContext';
import {
  resortArray,
  addNodeMetadata,
  removeNodeMetadata,
  insertKeyValueIntoObject,
  deleteValueFromObject,
  updateValueInObject,
  getParentNode,
  sortPatchesByVersion,
} from './services/recipe-utils';
import { validateRecipe } from './services/validation';
import API from '../../../libs/api';
import ErrorHandler from '../../../libs/errors';
import useAsyncEffect from '../../modules/foundation/hooks/use-async-effect';
import useCopyToClipboard from '../hooks/use-copy-to-clipboard';

import './RecipesEditForm.scss';

const RecipesEditForm = () => {
  const websiteId = useSelector((state) => state.website.core.value.id);
  /* eslint-disable no-unused-vars */
  const [patchesData, setPatchesData] = useState([]);
  const [selectedPatchData, setSelectedPatchData] = useState({});
  const [selectedPatch, setSelectedPatch] = useState(null);
  const [baseRecipe, setBaseRecipe] = useState(null);
  const [recipe, setRecipe] = useState(null);
  const [noPatchesFound, setNoPatchesFound] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [activeNode, setActiveNode] = useState({});
  const [editorMode, setEditorMode] = useState('json');
  const [validationErrors, setValidationErrors] = useState([]);
  const [displayCreatePatchModal, setDisplayCreatePatchModal] = useState(false);
  const [isSavingOrCreatingPatch, setIsSavingOrCreatingPatch] = useState(false);
  const [handleCopy] = useCopyToClipboard();
  /* eslint-enable no-unused-vars */

  function clearState() {
    setSelectedPatchData({});
    setSelectedPatch(null);
    setActiveNode({});
    setSelectedPatch(null);
    setBaseRecipe(null);
    setRecipe(null);
    setNoPatchesFound(false);
    setIsFetchingData(true);
    setIsLoadingData(false);
    setNoPatchesFound(false);
    setIsSavingOrCreatingPatch(false);
    setDisplayCreatePatchModal(false);
  }

  async function getPatchesData(siteId) {
    setIsFetchingData(true);

    try {
      const { data } = await API.getPatches(siteId);
      return data;
    } catch (e) {
      ErrorHandler.capture(e);
      return [];
    } finally {
      setIsFetchingData(false);
    }
  }

  async function setSelectedRecipePatchFile(selectedRecipePatchId) {
    setIsLoadingData(true);
    try {
      const {
        data: { recipe: _recipe, patch, base_recipe: _baseRecipe },
      } = await API.getPatchFiles(selectedRecipePatchId);
      setBaseRecipe(_baseRecipe);
      setSelectedPatch(patch);
      setRecipe(addNodeMetadata(_recipe));
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setIsLoadingData(false);
    }
  }

  async function fetchData(id = null) {
    clearState();
    const patchesCollection = await getPatchesData(websiteId);

    let selectedPatchObj = patchesCollection.find((p) => p.active);

    if (!_isNil(id)) {
      selectedPatchObj = patchesCollection.find((p) => p.id === id);
    }
    const noPatches = patchesCollection.length === 0;

    setPatchesData(sortPatchesByVersion(patchesCollection));
    setNoPatchesFound(noPatches);
    setSelectedPatchData(selectedPatchObj);

    if (!noPatches) {
      setSelectedRecipePatchFile(selectedPatchObj.id);
    }
  }

  async function handleRecipePatchVersionChange(e, { value: patchId }) {
    const newRecipePatch = patchesData.find((p) => p.id === patchId);
    setSelectedPatchData(newRecipePatch);
    setSelectedRecipePatchFile(patchId);
    fetchData(patchId);
  }

  function updateAndValidateRecipe(newRecipe) {
    const errors = validateRecipe(newRecipe);
    setValidationErrors(errors);
    if (_isEmpty(errors)) {
      setRecipe(newRecipe);
    }
  }

  function getRecipeContextObject() {
    return {
      recipe,
      activeNode,
      setActiveNode,
      editorMode,
      setEditorMode,
      getParentNode: (parentId) => getParentNode(recipe, parentId),
      copyToClipboard: (node) => {
        const nodeWithoutMetada = removeNodeMetadata(node);
        handleCopy(nodeWithoutMetada);
      },
      insertKeyValueIntoObject: (recipeObjectKey, value, parentId) => {
        const newRecipe = insertKeyValueIntoObject(recipe, recipeObjectKey, value, parentId);
        updateAndValidateRecipe(addNodeMetadata(newRecipe));
      },
      updateValueInObject: (recipeObjectKey, value, parentId) => {
        const newRecipe = updateValueInObject(recipe, recipeObjectKey, value, parentId);
        updateAndValidateRecipe(addNodeMetadata(newRecipe));
      },
      deleteValueFromObject: (recipeObjectKey, parentId) => {
        const newRecipe = deleteValueFromObject(recipe, recipeObjectKey, parentId);
        updateAndValidateRecipe(addNodeMetadata(newRecipe));
      },
      resortArray: (key, parentId, oldIndex, newIndex) => {
        const newRecipe = resortArray(
          { ...recipe },
          key,
          parentId,
          oldIndex + 1, // +1 because metadata is in index 0
          newIndex + 1,
        );
        setRecipe(newRecipe);
      },
    };
  }

  useAsyncEffect(async () => {
    await fetchData();
  }, []);

  function toggleCreatePatchModal() {
    setDisplayCreatePatchModal(!displayCreatePatchModal);
  }

  function getRecipePatchActionLabel() {
    return selectedPatchData && !selectedPatchData.draft ? CREATE_PATCH_DRAFT_KEY : SAVE_PATCH_KEY;
  }

  async function savePatchHanlder(updatedRecipePatch) {
    const { id: patchId, patchDescription, active, draft } = updatedRecipePatch;
    const { base_recipe: selectedPatchBaseRecipe } = selectedPatchData;
    setIsSavingOrCreatingPatch(true);
    try {
      const { data: patch } = await API.getRecipeDiff(
        selectedPatchBaseRecipe.id,
        removeNodeMetadata(recipe),
      );
      const patchAsFile = new Blob([JSON.stringify(patch)], {
        type: 'text/json',
      });
      await API.updatePatch(websiteId, patchId, {
        patch: patchAsFile,
        description: patchDescription,
        active,
        draft,
      });
      fetchData(patchId);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setIsSavingOrCreatingPatch(false);
    }
  }

  async function createPatchHandler(newRecipePatch) {
    const { patchDescription, patchVersion, active, draft } = newRecipePatch;
    const { base_recipe: selectedPatchBaseRecipe, theme } = selectedPatchData;

    setIsSavingOrCreatingPatch(true);
    try {
      const { data: patch } = await API.getRecipeDiff(
        selectedPatchBaseRecipe.id,
        removeNodeMetadata(recipe),
      );
      const patchAsFile = new Blob([JSON.stringify(patch)], {
        type: 'text/json',
      });
      const { data: patchObj } = await API.createPatch({
        websiteId,
        patch: patchAsFile,
        baseRecipeId: selectedPatchBaseRecipe.id,
        description: patchDescription,
        patchVersion,
        themeId: theme.id,
        active,
        draft,
      });
      fetchData(patchObj.id);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setIsSavingOrCreatingPatch(false);
    }
  }

  function getDisplayVersion(nextVersion) {
    return selectedPatchData && selectedPatchData.draft
      ? selectedPatchData.patch_version
      : nextVersion;
  }

  const nextRecipePatchVersion = getNextRecipePatchVersion(baseRecipe, patchesData);

  return (
    <div className="recipev2-wrapper">
      <RecipeContextProvider value={getRecipeContextObject()}>
        <Dimmer.Dimmable dimmed={isLoadingData || isFetchingData}>
          <Dimmer active={isLoadingData || isFetchingData} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <RecipeToolbar>
            <RecipePatchVersionDropdown
              data={patchesData}
              selectedValue={selectedPatchData}
              description={selectedPatchData.description}
              onVersionChange={handleRecipePatchVersionChange}
              disabled={noPatchesFound}
              loading={isFetchingData}
              error={noPatchesFound}
            />
            <CreateModalPatch
              patch={{
                id: selectedPatchData.id,
                description: selectedPatchData.description,
                version: getDisplayVersion(nextRecipePatchVersion),
              }}
              open={displayCreatePatchModal}
              onClose={toggleCreatePatchModal}
              createPatch={createPatchHandler}
              savePatch={savePatchHanlder}
              saveMode={selectedPatchData && selectedPatchData.draft}
              loading={isSavingOrCreatingPatch}
              trigger={
                <RecipePatchActionButton
                  disabled={!_isEmpty(validationErrors)}
                  onClick={toggleCreatePatchModal}
                  label={getRecipePatchActionLabel()}
                  isLoading={isLoadingData}
                  version={getDisplayVersion(nextRecipePatchVersion)}
                />
              }
            />
          </RecipeToolbar>
          <div className="recipev2-main-container">
            <div className="json-tree">
              <RecipeNavigation />
            </div>
            <div className="recipe-editor-container">
              <RecipeEditor />
            </div>
            <div className="object-controls">
              <RecipeControlPanes />
            </div>
          </div>
        </Dimmer.Dimmable>
      </RecipeContextProvider>
    </div>
  );
};

export default RecipesEditForm;
