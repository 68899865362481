import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const RecipeManagerTableHeader = ({ headerNames }) => {
  return (
    <Table.Header>
      <Table.Row>
        {headerNames &&
          headerNames.map((headerName) => (
            <Table.HeaderCell key={headerName}>{headerName}</Table.HeaderCell>
          ))}
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  );
};

RecipeManagerTableHeader.propTypes = {
  headerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RecipeManagerTableHeader;
