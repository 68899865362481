import React from 'react';

import PropTypes from 'prop-types';

import {
  FORM_DESCRIPTIONS,
  FORM_INVALID_FIELDS,
  FORM_PLACEHOLDERS,
  FORM_TITLES,
} from './EditTeamMemberFormGroup.constants';
import InputFormGroup from '../../../../../../common/InputFormGroup';

const propTypes = {
  keyName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.bool,
  customInput: PropTypes.node,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

const defaultProps = {
  value: '',
  name: null,
  onChange: null,
  error: false,
  customInput: null,
  required: false,
  fullWidth: false,
};

export default function EditTeamMemberFormGroup({
  keyName,
  value,
  onChange,
  name,
  error,
  customInput,
  required,
  fullWidth,
}) {
  const helpMessage = FORM_DESCRIPTIONS[keyName];
  const title = FORM_TITLES[keyName];
  const placeholder = FORM_PLACEHOLDERS[keyName];
  const errorMessage = error ? FORM_INVALID_FIELDS[keyName] : '';

  return (
    <InputFormGroup
      title={title}
      name={name || keyName}
      onChange={onChange}
      value={value}
      customInput={customInput}
      errorMessage={errorMessage}
      placeholder={placeholder}
      helpMessage={helpMessage}
      required={required}
      fullWidth={fullWidth}
    />
  );
}

EditTeamMemberFormGroup.propTypes = propTypes;
EditTeamMemberFormGroup.defaultProps = defaultProps;
