import React, { useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { RedirectPropType } from '../../types/redirect';
import EditRedirectModal from '../EditRedirectModal';

import './EditRedirectButton.scss';

const propTypes = {
  redirect: RedirectPropType.isRequired,
};

const defaultProps = {};

export default function EditRedirectButton({ redirect }) {
  const [showModal, setShowModal] = useState(false);

  function displayModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      {showModal && <EditRedirectModal redirect={redirect} open={showModal} onClose={closeModal} />}

      <Button icon className="action-button-gray edit-redirect-button" onClick={displayModal}>
        <Icon name="edit" />
      </Button>
    </>
  );
}

EditRedirectButton.propTypes = propTypes;
EditRedirectButton.defaultProps = defaultProps;
