import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Icon, Label, Search } from 'semantic-ui-react';

import { filterAndMapLabels } from './HourLabel.utils';

import './HourLabel.scss';

const propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

function HourLabel({ labels, value, onSelect }) {
  const [searchValue, setSearchValue] = useState('');

  function handleSearch(e, data) {
    setSearchValue(data.value);
  }

  function handleSelect(e, data) {
    setSearchValue('');
    onSelect(data.result.title);
  }

  function handleClear() {
    setSearchValue('');
    onSelect('');
  }

  const filteredLabels = filterAndMapLabels(labels, searchValue);

  if (value) {
    return (
      <Label color="blue">
        {value}
        <Icon name="delete" onClick={() => handleClear()} />
      </Label>
    );
  }

  return (
    <Search
      placeholder="Label"
      className="hour-label-search"
      onResultSelect={(e, data) => handleSelect(e, data)}
      onSearchChange={(e, data) => handleSearch(e, data)}
      results={filteredLabels}
      value={searchValue}
    />
  );
}

HourLabel.propTypes = propTypes;

export default HourLabel;
