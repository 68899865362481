export const DEFAULT_IMAGE_BLOCK_CLASS_NAME = 'image-block';

export const IMAGE_BLOCK_PAGE_CONTENT_QUERY = {
  files: {
    altText: true,
    file: true,
    gatsbyFile: {
      childImageSharp: {
        gatsbyImageData: {
          __args: {
            layout: {
              __enum: true,
              enumValue: 'FULL_WIDTH',
            },
          },
        },
      },
    },
  },
};
