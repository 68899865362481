import {
  GLAMMATIC_MAIL_TO_SUPPORT_EMAIL,
  GLAMMATIC_SUPPORT_EMAIL,
  MAIL_TO_SUPPORT_EMAIL,
  SUPPORT_EMAIL,
} from '../../../../../../constants/constants';
import { BRAND_IDENTITIES } from '../../../../../modules/foundation/hooks/use-branding/useBranding.constants';

export function getSupportEmail(brandIdentity) {
  return brandIdentity === BRAND_IDENTITIES.GLAMMATIC ? GLAMMATIC_SUPPORT_EMAIL : SUPPORT_EMAIL;
}

export function getMailToSupportEmail(brandIdentity) {
  return brandIdentity === BRAND_IDENTITIES.GLAMMATIC
    ? GLAMMATIC_MAIL_TO_SUPPORT_EMAIL
    : MAIL_TO_SUPPORT_EMAIL;
}
