import LogRocket from 'logrocket';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';

const middleware = applyMiddleware(thunk, LogRocket.reduxMiddleware());

export default function configureStore(initialState) {
  return createStore(rootReducer, initialState, middleware);
}
