import _get from 'lodash/get';
import ReactGA from 'react-ga';

import storage from './storage';

const environment = process.env.REACT_APP_FISHERMAN_ENV;

export default class GoogleAnalytics {
  static isEnabled() {
    return ['production'].includes(environment);
  }

  static isDebugEnabled() {
    return ['sandbox'].includes(environment);
  }

  static init() {
    const isEnabled = this.isEnabled();
    const id = isEnabled && process.env.REACT_APP_GA_TRACKING_ID;

    const debug = this.isDebugEnabled();

    const store = storage.get('fisherman') || {};
    // identify user if logged in
    const userId = _get(store, 'user.core.value.id');

    const gaOptions = userId ? { userId } : {};
    ReactGA.initialize(id, { debug, gaOptions });
  }

  static identifyUser(userId) {
    ReactGA.set({ userId });
  }

  static event(category, action, args = {}) {
    const options = {
      ...args,
      category,
      action,
    };

    ReactGA.event(options);
  }

  static pageView(route) {
    ReactGA.pageview(route);
  }
}
