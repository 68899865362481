import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { CHANNEL_HELP_TOOLTIP_MESSAGE } from './FormChannel.constants';
import { getChannelOptions } from './FormChannel.utils';
import HelpTooltip from '../../../../../../../../common/HelpTooltip';

import './FormChannel.scss';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
};

export default function FormChannel({ value, onChange: onChangeProp }) {
  function onChange(e, { value: newValue }) {
    onChangeProp('channel', newValue);
  }

  const options = getChannelOptions(value);

  return (
    <div className="form-element channel">
      <label htmlFor="form-element-channel-input">Channel*</label>
      <HelpTooltip title="Channel" description={CHANNEL_HELP_TOOLTIP_MESSAGE} />

      <Dropdown
        id="form-element-channel-input"
        options={options}
        placeholder="Hiring"
        search
        selection
        fluid
        allowAdditions
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

FormChannel.propTypes = propTypes;
FormChannel.defaultProps = defaultProps;
