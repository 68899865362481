export const FILE_SOURCE_LABEL_MAP = {
  Upload: 'user upload',
  Google: 'google',
  Facebook: 'facebook',
  Yelp: 'yelp',
  ExternalWebsite: 'external website',
  Stock: 'stock photos',
};

export const UNKNOWN_FILE_SOURCE = 'Unknown';
