import React from 'react';

import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import { ChildrenPropType } from '../../../libs/proptypes';
import CloseableModal from '../CloseableModal';

import './InformationModal.scss';

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.node,
  header: PropTypes.node,
  children: ChildrenPropType,
  extraButtons: PropTypes.arrayOf(PropTypes.node),
};

const defaultProps = {
  text: '',
  open: false,
  header: '',
  children: null,
  extraButtons: [],
};

export default function InformationModal({ open, text, onClose, header, children, extraButtons }) {
  return (
    <CloseableModal
      size="tiny"
      onClose={null}
      open={open}
      className="information-modal"
      header={header}
    >
      <Modal.Content>{children || text}</Modal.Content>
      <Modal.Actions>
        <Button primary content="Close" onClick={onClose} />
        {extraButtons.map((button) => button)}
      </Modal.Actions>
    </CloseableModal>
  );
}

InformationModal.propTypes = propTypes;
InformationModal.defaultProps = defaultProps;
