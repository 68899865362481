import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import './EditPhotoButton.scss';

const propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
};

const defaultProps = {
  className: '',
};

function EditPhotoButton({ as, className, imageUrl, ...buttonProps }) {
  const finalClassName = [className, 'edit-photo-button'].join(' ');
  const buttonStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return <Button className={finalClassName} style={buttonStyle} {...buttonProps} />;
}

EditPhotoButton.propTypes = propTypes;
EditPhotoButton.defaultProps = defaultProps;

export default EditPhotoButton;
