import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useDispatch, useSelector } from 'react-redux';

import {
  addWebsitePage,
  removeWebsitePage,
  updateWebsitePage,
} from '../../../../../actions/website';
import API from '../../../../../libs/api';
import { objectToSnakeCase } from '../../../../../libs/format/case';
import { getWebsiteUrl } from '../../../../../libs/website';
import { selectBusiness } from '../../../../../selectors/business';
import {
  selectDomains,
  selectIsNextWebsite,
  selectWebsite,
  selectWebsitePages,
} from '../../../../../selectors/website';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect/use-async-effect';
import { EXTERNAL_PAGE_TYPE, INTERNAL_PAGE_TYPE } from '../../../pages/constants';
import usePages from '../../../pages/hooks/use-pages/use-pages';
import {
  filterButtonPages,
  filterNonButtonPages,
  filterPagesByType,
  getPagesFrom,
  sortByNavigationPriority,
} from '../../../pages/services/pages';

export default function useNavigation() {
  const dispatch = useDispatch();

  const business = useSelector(selectBusiness);
  const website = useSelector(selectWebsite);
  const domains = useSelector(selectDomains);
  const isNextWebsite = useSelector(selectIsNextWebsite);

  const { id: websiteId } = website;

  const { refreshPages } = usePages({}, { fetchRecipeSettingsOnMount: false });

  const pages = useSelector(selectWebsitePages).map((page, i) => ({ ...page, reduxIndex: i }));
  const sortedPages = sortByNavigationPriority(pages);
  const buttonPages = filterButtonPages(sortedPages);
  const nonButtonPages = filterNonButtonPages(sortedPages);
  const internalPages = filterPagesByType(sortedPages, INTERNAL_PAGE_TYPE);
  const externalPages = filterPagesByType(sortedPages, EXTERNAL_PAGE_TYPE);

  useAsyncEffect(async () => {
    await refreshPages();
  }, []);

  function getPageUrl(page) {
    const { url, slug } = page;
    const websiteUrl = getWebsiteUrl(business, website, domains, isNextWebsite);
    const pageUrl = url || `${websiteUrl}${slug}`;
    const { protocol, href, pathname, origin, host } = new URL(pageUrl);
    return { protocol, href, pathname, origin, host };
  }

  async function updatePage(page, data = {}, { updateRemote = true } = {}) {
    const { reduxIndex, id: pageId } = page || {};
    if (!_isNil(reduxIndex) && !_isNil(pageId) && !_isEmpty(data)) {
      if (updateRemote) {
        await API.updatePage(websiteId, pageId, objectToSnakeCase(data));
      }
      dispatch(updateWebsitePage({ index: reduxIndex, data: objectToSnakeCase(data) }));
    }
  }

  function deletePage(page) {
    const { reduxIndex } = page || {};
    if (!_isNil(reduxIndex)) {
      dispatch(removeWebsitePage({ index: reduxIndex }));
    }
  }

  async function reOrderNavigationPriority(
    navigationPriority,
    { updateRemote = true, avoidIds = [], increaseFactor = 1 } = {},
  ) {
    const pagesToUpdate = getPagesFrom(sortedPages, navigationPriority);

    // need to update the navigation priority of all pages after the new page
    await Promise.all(
      pagesToUpdate.map(async (page) => {
        const { id: pageId, navigationPriority: currentPriority } = page;
        const newPriority = currentPriority + increaseFactor;
        if (avoidIds.includes(pageId)) {
          return;
        }
        if (updateRemote) {
          await API.updatePage(websiteId, pageId, { navigation_priority: newPriority });
        }
        updatePage(page, { navigation_priority: newPriority });
      }),
    );
  }

  async function addExternalPage({ title, url, metadata = {}, navigationPriority }) {
    const payload = {
      title,
      url,
      metadata,
      pageType: EXTERNAL_PAGE_TYPE,
      navigationPriority,
    };
    const { data: createdPage } = await API.savePage(websiteId, objectToSnakeCase(payload));
    dispatch(addWebsitePage(createdPage));

    await reOrderNavigationPriority(navigationPriority, { avoidIds: [createdPage.id] });
  }

  return {
    all: sortedPages,
    internal: internalPages,
    external: externalPages,
    buttonPages,
    nonButtonPages,
    getPageUrl,
    updatePage,
    reOrderNavigationPriority,
    deletePage,
    addExternalPage,
  };
}
