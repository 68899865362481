import _get from 'lodash/get';

import {
  ROLE_BUSINESS_OWNER,
  ROLE_SALES,
  ZUPPLER_SALES_GROUP_NAME,
} from '../../../../constants/auth';
import { isAuthorizedForRole, isUserInGroup, isWebsiteDisabled } from '../../../../libs/auth';
import {
  GOOGLE_SERVICE_TYPE,
  GOOGLE_PROVIDER,
  CLOVER_PROVIDER,
  ZUPPLER_COLLECTION_METHOD,
  CLOVER_COLLECTION_METHOD,
  CLOVER_SERVICE_TYPE,
  DAYSMART_COLLECTION_METHOD,
} from '../../Integrations/constants';
import { getProviderServiceSetting } from '../../Integrations/utils';

export function hasWebsiteDisabled(business, website, user) {
  return isWebsiteDisabled(business, website) && !user.is_superuser;
}

export function getCloverProviderServiceSetting(providerServiceSettings) {
  return getProviderServiceSetting(providerServiceSettings, CLOVER_PROVIDER, CLOVER_SERVICE_TYPE);
}

export function getGMBProviderServiceSetting(providerServiceSettings) {
  return getProviderServiceSetting(providerServiceSettings, GOOGLE_PROVIDER, GOOGLE_SERVICE_TYPE);
}

export function isBusinessOwner(userRole) {
  return isAuthorizedForRole(userRole, ROLE_BUSINESS_OWNER);
}

export function isZupplerSales(user, userRole) {
  return isAuthorizedForRole(userRole, ROLE_SALES) && isUserInGroup(user, ZUPPLER_SALES_GROUP_NAME);
}

export function hasCloverStatusData(serviceSetting) {
  const statusData = _get(serviceSetting, 'status_data', {}) || {};
  const importColo = 'import_colo' in statusData;
  const importHours = 'import_hours' in statusData;
  const importMenu = 'import_menu' in statusData;
  return importColo && importHours && importMenu;
}

export function isCloverMarketplaceBusiness(business) {
  const collectionMethod = _get(business, 'collection_method');
  return collectionMethod === CLOVER_COLLECTION_METHOD;
}

export function isZupplerBusiness(business) {
  const collectionMethod = _get(business, 'collection_method');
  return collectionMethod === ZUPPLER_COLLECTION_METHOD;
}

export function isDaySmartBusiness(business) {
  const collectionMethod = _get(business, 'collection_method');
  return collectionMethod === DAYSMART_COLLECTION_METHOD;
}

export function hasDomains(domains = []) {
  const activeDomains = domains.filter((domain) => {
    return domain.id && domain.url;
  });
  return !!activeDomains.length;
}
