import _head from 'lodash/head';

export function matchStyleRulesToColorPalette(styleRules, colorPalettes) {
  const matchedColorPalettes = colorPalettes.filter((colorPalette) => {
    const { colors } = colorPalette;

    return colors.every(({ color, role }) => {
      const styleRule = styleRules.find(({ name }) => name === role);

      return styleRule && styleRule.value === `#${color}`;
    });
  });

  return _head(matchedColorPalettes);
}
