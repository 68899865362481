import { createEntityAdapter } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import { v4 as uuid } from 'uuid';

export function generateLocalId() {
  return uuid();
}

export function selectEntityId(entity) {
  return entity.id || entity.localId;
}

export function buildGenericAdapter() {
  return createEntityAdapter({
    sortComparer: (a, b) => a.id < b.id,
    selectId: (entity) => entity.id || entity.localId,
  });
}

export function upsertEntityWrapper(callback, adapter, entityName) {
  return (state, action) => {
    callback(state, action, adapter, entityName);
  };
}

export function upsertEntity(state, action, adapter, entityName) {
  const entity = _get(action, `payload.${entityName}`, {});
  const data = _get(action, 'payload.data', {});
  const defaultData = _get(action, 'payload.defaultData', {});

  if (entity.id || entity.localId) {
    adapter.updateOne(state, {
      id: entity.id || entity.localId,
      changes: { ...data },
    });
  } else {
    const localId = uuid();
    adapter.addOne(state, {
      localId,
      ...defaultData,
      ...data,
    });
  }
}

export function replaceLocalEntityWrapper(callback, adapter, entityName) {
  return (state, action) => {
    callback(state, action, adapter, entityName);
  };
}

export function replaceLocalEntity(state, action, adapter, entityName) {
  // This is used to replace a local entity with a server one,
  //  after saved to the backend, hence the removal of the localId.
  const entity = _get(action, `payload.${entityName}`, {});
  const data = _get(action, 'payload.data', {});

  if (entity.localId) {
    adapter.removeOne(state, entity.localId);
    adapter.addOne(state, data);
  } else {
    adapter.updateOne(state, { id: entity.id, changes: data });
  }
}
