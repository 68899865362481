import React, { useState } from 'react';

import { Button, Icon, Popup } from 'semantic-ui-react';

import clsx from 'clsx';

import { StyleRulePropType } from '../../types';
import DeleteStyleRuleModal from '../DeleteStyleRuleModal';
import EditStyleRuleModal from '../EditStyleRuleModal';

import './EditStyleRulePopup.scss';

const propTypes = { styleRule: StyleRulePropType.isRequired };
const defaultProps = {};

export default function EditStyleRulePopup({ styleRule }) {
  const { name } = styleRule;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  function showDeleteModal() {
    setDeleteModalOpen(true);
  }

  function showEditModal() {
    setEditModalOpen(true);
  }

  function closeModals() {
    setDeleteModalOpen(false);
    setEditModalOpen(false);
  }

  return (
    <>
      {editModalOpen && (
        <EditStyleRuleModal initialName={name} open onClose={closeModals} add={false} />
      )}
      {deleteModalOpen && <DeleteStyleRuleModal styleRuleName={name} open onCancel={closeModals} />}

      <Popup
        trigger={<Icon className="edit-style-rule-popup-trigger" name="ellipsis horizontal" />}
        content={
          <div className="content-container">
            <Button className="edit" content="Edit" onClick={showEditModal} />
            <Button className="delete" content="Delete" onClick={showDeleteModal} />
          </div>
        }
        on={['click']}
        position="bottom center"
        className={clsx({
          'edit-style-rule-popup': true,
          active: deleteModalOpen || editModalOpen,
        })}
      />
    </>
  );
}

EditStyleRulePopup.propTypes = propTypes;
EditStyleRulePopup.defaultProps = defaultProps;
