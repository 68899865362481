import React from 'react';

import { useParams } from 'react-router-dom';

import ForgotConfirmForm from './ForgotConfirmForm';
import LoginBanner from './LoginBanner';

import '../../styles/core/pages/login-forgot-confirm.scss';

export default function ForgotConfirmPage() {
  const params = useParams();

  const { token, uid } = params;

  return (
    <div className="forgot-confirm-page">
      <LoginBanner />
      <div className="login-form-container">
        <ForgotConfirmForm token={token} uid={uid} />
      </div>
    </div>
  );
}
