import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Button, Icon } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import CustomerContactDownloadButton from './components/CustomerContactDownloadButton';
import CustomerContactSearch from './components/CustomerContactSearch';
import { MERCHANT_BUSINESS_TYPE } from '../../../../../constants/constants';
import { selectBusiness, selectBusinessLocations } from '../../../../../selectors';
import LocationSelect from '../../../../pages/ecommerce/common/LocationSelect';

import './CustomersPageHeader.scss';

const propTypes = {
  locationId: PropTypes.number.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  filterValue: PropTypes.string.isRequired,
  onFilterCustomer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onAddCustomerClick: PropTypes.func.isRequired,
};

const defaultProps = {
  loading: false,
};

export default function CustomersPageHeader({
  locationId,
  loading,
  onChangeLocation,
  onFilterCustomer,
  filterValue,
  onAddCustomerClick,
}) {
  const business = useSelector(selectBusiness);
  const locations = useSelector(selectBusinessLocations);
  const { type: businessType } = business;

  const displayLocationSelect = !(businessType === MERCHANT_BUSINESS_TYPE);

  return (
    <Grid className="customers-page-header" verticalAlign="bottom">
      <Grid.Column computer={9} mobile={16} tablet={16} className="left-section spaced-children">
        {displayLocationSelect && (
          <LocationSelect
            activeLocation={locationId}
            locations={locations}
            onChangeLocation={onChangeLocation}
            global={false}
            hideAllLocationsOption
          />
        )}
        <CustomerContactSearch
          filterValue={filterValue}
          onFilterCustomer={onFilterCustomer}
          loading={loading}
        />
      </Grid.Column>

      <Grid.Column
        computer={7}
        mobile={16}
        tablet={16}
        textAlign="right"
        className="right-section spaced-children"
      >
        <CustomerContactDownloadButton
          business={business}
          locationId={locationId}
          filterValue={filterValue}
        />
        <Button icon primary labelPosition="left" onClick={onAddCustomerClick}>
          <Icon name="add" />
          Add Customer
        </Button>
      </Grid.Column>
    </Grid>
  );
}

CustomersPageHeader.propTypes = propTypes;
CustomersPageHeader.defaultProps = defaultProps;
