export const RECIPE_NAME_CUTILIER = 'cutilier';
export const RECIPE_NAME_COLD_CUTZ = 'agnostic';
export const RECIPE_NAME_COMB_AND_INK = 'comb-and-ink';
export const RECIPE_NAME_GOLDFISH = 'default';
export const RECIPE_NAME_BLUE_TANG = 'default-2';
export const RECIPE_NAME_BEDS_THAI = 'restaurant-3';
export const RECIPE_NAME_DAYSMART = 'daysmart';
export const RECIPE_NAME_MUSE = 'muse';
export const RECIPE_NAME_URBANISTA = 'urbanista';
export const RECIPE_NAME_HIBISCUS = 'hibiscus';
export const RECIPE_NAME_CALENDULA = 'calendula';

export const RECIPE_DISPLAY_NAME_MAP = {
  [RECIPE_NAME_COLD_CUTZ]: 'Cold Cutz',
  [RECIPE_NAME_GOLDFISH]: 'Goldfish',
  [RECIPE_NAME_BLUE_TANG]: 'Blue Tang',
  [RECIPE_NAME_BEDS_THAI]: 'Beds Thai',
  [RECIPE_NAME_COMB_AND_INK]: 'Comb & Ink',
  [RECIPE_NAME_DAYSMART]: 'DaySmart',
  [RECIPE_NAME_CUTILIER]: 'Cutilier',
  [RECIPE_NAME_MUSE]: 'Muse',
  [RECIPE_NAME_URBANISTA]: 'Urbanista',
  [RECIPE_NAME_HIBISCUS]: 'Hibiscus',
  [RECIPE_NAME_CALENDULA]: 'Calendula',
};

export const SUPPORTED_FEATURED_CATALOG_OBJECT_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const SUPPORTED_LOCATION_IMAGES_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const SUPPORTED_LOCATION_SEO_METADATA_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const SUPPORTED_ANNOUNCEMENT_FORM_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_COMB_AND_INK,
  RECIPE_NAME_COLD_CUTZ,
  RECIPE_NAME_BEDS_THAI,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
