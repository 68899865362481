import { useDispatch, useSelector } from 'react-redux';

import { initializeProviderServiceSettings } from '../../../../../actions/business';
import API from '../../../../../libs/api';
import { objectToCamelCase } from '../../../../../libs/format';
import {
  selectAllProviderServiceSettings,
  selectBusinessId,
  selectBusinessType,
} from '../../../../../selectors';

export default function useProviderServiceSettings() {
  const dispatch = useDispatch();

  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);

  const providerServiceSettings = objectToCamelCase(useSelector(selectAllProviderServiceSettings));

  async function refreshSettings() {
    const { data } = await API.getProviderServiceSettings(businessId, businessType);
    dispatch(initializeProviderServiceSettings(data));
  }

  return { providerServiceSettings, refreshSettings };
}
