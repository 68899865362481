import React from 'react';

import PropTypes from 'prop-types';
import { Popup, Image } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import InfoIcon from '../../../static/icons/info-tooltip.svg';

import './HelpTooltip.scss';

const propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  content: PropTypes.node,
  trigger: PropTypes.node,
  position: PropTypes.string,
};

const defaultProps = {
  description: null,
  content: null,
  trigger: null,
  position: 'bottom right',
};

export default function HelpTooltip({ title, description, content, trigger, position }) {
  return (
    <Popup
      trigger={
        trigger || (
          <Image
            src={InfoIcon}
            style={{
              display: 'inline',
              paddingLeft: '4px',
              margin: 0,
            }}
            className="help-tooltip-icon"
          />
        )
      }
      position={position}
      className="help-tooltip"
    >
      <Popup.Header>{title}</Popup.Header>
      <Popup.Content>
        {!_isEmpty(description) && <p>{description}</p>}
        {!_isEmpty(content) && content}
      </Popup.Content>
    </Popup>
  );
}

HelpTooltip.propTypes = propTypes;
HelpTooltip.defaultProps = defaultProps;
