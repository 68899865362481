import {
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_COLD_CUTZ,
  RECIPE_NAME_COMB_AND_INK,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_URBANISTA,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
} from '../../../../constants/recipes';

export const CTAS_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_COLD_CUTZ,
  RECIPE_NAME_COMB_AND_INK,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_URBANISTA,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];

export const CLICK_THROUGH_URL_RECIPES = [
  RECIPE_NAME_COLD_CUTZ,
  RECIPE_NAME_COMB_AND_INK,
  RECIPE_NAME_URBANISTA,
];

export const MULTIPLE_IMAGES_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const DETAILED_DESCRIPTION_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const ADVANCED_SEO_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];

export const HOOK_PROP_NAME = 'recipe';
export const IS_SPECIAL_FIELD = 'isSpecial';
export const IS_FEATURED_FIELD = 'isFeatured';
