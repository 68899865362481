import React, { useContext, useMemo } from 'react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { SortableContainer } from 'react-sortable-hoc';

import { useSortingState } from './NavigationContainer.hooks';
import {
  fillIndex,
  sortPagesOnIndex,
  dragPageIntoGroup,
  dragPageOutOfGroup,
  togglePageToButtons,
  dragPageWithinAGroup,
} from './NavigationContainer.utils';
import onTableRowSortingStart from '../../../../../libs/sorting/on-table-row-sorting-start';
import { SortingProvider } from '../../../foundation/context/sorting/SortingContext.context';
import { isGroupCollectionName } from '../../../pages/services/page-groups';
import { getNextNavigationPriority } from '../../../pages/services/pages';
import {
  ACTION_BUTTONS_COLLECTION,
  MAIN_NAVIGATION_COLLECTION,
  MAX_AMOUNT_OF_BUTTONS,
} from '../../constants/constants';
import { NavigationContext } from '../../context/Navigation.context';
import NavigationItemList from '../NavigationItemList';

import './NavigationContainer.scss';

const propTypes = {};
const defaultProps = {};

const SortableContent = SortableContainer(({ children }) => {
  return <div>{children}</div>;
});

function NavigationContainer() {
  const sortingState = useSortingState();
  const { setInitialFocusedCollection, setDragging, resetSorting, getRefValue } = sortingState;

  const { navigation, navigationGroup, isButtonGroupingSupported } = useContext(NavigationContext);
  const { nonButtonPages, buttonPages } = navigationGroup;
  const pages = [...nonButtonPages, ...buttonPages];

  function updateBeforeSortStart({ collection, node }) {
    const finalCollection = collection || _get(node, 'sortableInfo.collection');
    setDragging(true);
    if (!isGroupCollectionName(finalCollection)) {
      setInitialFocusedCollection(finalCollection);
    }
  }

  function onSortEnd({ oldIndex, newIndex, collection }) {
    const focused = getRefValue('focusedCollection');
    const initialFocused = getRefValue('initialFocusedCollection');
    const focusedGroup = getRefValue('focusedGroupCollection');
    const initialFocusedGroup = getRefValue('initialFocusedGroupCollection');

    const draggingIntoGroup = isGroupCollectionName(focusedGroup);
    const draggingAChildPage = isGroupCollectionName(collection);
    const draggingAGroupPage = !_isEmpty(initialFocusedGroup);
    const droppingBetweenCollections = initialFocused !== focused;

    let finalNewIndex = newIndex;
    if (focused !== collection && !draggingAChildPage) {
      const movedToButtons = focused === ACTION_BUTTONS_COLLECTION;

      if (movedToButtons && buttonPages.length >= MAX_AMOUNT_OF_BUTTONS) {
        resetSorting();
        return;
      }

      if (!isButtonGroupingSupported && draggingAGroupPage) {
        resetSorting();
        return;
      }

      togglePageToButtons(pages, oldIndex, movedToButtons, navigation, navigationGroup);

      finalNewIndex = movedToButtons
        ? getNextNavigationPriority(pages)
        : getNextNavigationPriority(nonButtonPages);
    }

    if (draggingIntoGroup && !draggingAChildPage && !droppingBetweenCollections) {
      dragPageIntoGroup(pages, focusedGroup, oldIndex, navigation, navigationGroup);
    }

    if (draggingAChildPage && !draggingIntoGroup) {
      dragPageOutOfGroup(initialFocusedGroup, oldIndex, navigation, navigationGroup);
    }

    if (draggingAChildPage && draggingIntoGroup && oldIndex !== newIndex) {
      dragPageWithinAGroup(initialFocusedGroup, oldIndex, newIndex, navigation, navigationGroup);
    }

    if (oldIndex !== finalNewIndex && !draggingIntoGroup && !draggingAChildPage) {
      sortPagesOnIndex(pages, oldIndex, finalNewIndex, navigation, navigationGroup);
    }

    resetSorting();
  }

  const mainNavigationPages = fillIndex(nonButtonPages);
  const actionButtonsPages = fillIndex(buttonPages, { startIndex: mainNavigationPages.length });

  return (
    <SortingProvider value={sortingState}>
      {useMemo(
        () => (
          <div className="navigation-container">
            <SortableContent
              useDragHandle
              helperClass="table-row-sorting navigation-container-sorting-helper"
              onSortStart={onTableRowSortingStart}
              onSortEnd={onSortEnd}
              updateBeforeSortStart={updateBeforeSortStart}
            >
              <NavigationItemList
                collectionName={MAIN_NAVIGATION_COLLECTION}
                pages={mainNavigationPages}
              />
              <NavigationItemList
                collectionName={ACTION_BUTTONS_COLLECTION}
                pages={actionButtonsPages}
              />
            </SortableContent>
          </div>
        ),
        [navigation],
      )}
    </SortingProvider>
  );
}

NavigationContainer.propTypes = propTypes;
NavigationContainer.defaultProps = defaultProps;

export default NavigationContainer;
