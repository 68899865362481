export const SERVER_ERROR_MESSAGE =
  'There was an unexpected error. Please try again and contact customer support if the error continues';
export const REQUEST_ERROR_MESSAGE =
  'There was a problem with the given data. Check your form data for errors, correct them and submit again.';

export const CATALOG_ITEM_FORM_NEW_TITLE = 'New Item';
export const CATALOG_ITEM_FORM_EDIT_TITLE = 'Edit Item';
export const FORM_DELETING_STATUS = 'DELETE';
export const FORM_SAVING_STATUS = 'SAVE';
export const DEFAULT_VARIATION_NAME = 'Regular';
export const CONFIRMATION_DELETE_MESSAGE = 'Are you sure you want to delete this item?';
