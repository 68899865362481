export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_CONTENT_NODES':
      return {
        ...state,
        value: payload,
      };

    default:
      return state;
  }
};
