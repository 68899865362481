import MarkdownField from '../../../../fields/MarkdownField';
import InputField from '../InputField';

export const TEXT_FIELD_TYPE = 'text';
export const MARKDOWN_FIELD_TYPE = 'markdown';
export const FIELD_TYPES = [TEXT_FIELD_TYPE, MARKDOWN_FIELD_TYPE];

export const FIELD_TYPE_COMPONENTS = {
  [TEXT_FIELD_TYPE]: InputField,
  [MARKDOWN_FIELD_TYPE]: MarkdownField,
};
