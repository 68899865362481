import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { FILE_SOURCE_FILTER_OPTIONS } from './FileSourceFilter.constants';
import { getFileSourceFilterClassName } from './FileSourceFilter.utils';

import './FileSourceFilter.scss';

const propTypes = {
  filter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function FileSourceFilter({ filter, onFilterChange }) {
  return (
    <Dropdown
      className={getFileSourceFilterClassName(filter)}
      icon="tags"
      value={filter}
      onChange={onFilterChange}
      floating
      options={FILE_SOURCE_FILTER_OPTIONS}
    />
  );
}

FileSourceFilter.propTypes = propTypes;
FileSourceFilter.defaultProps = defaultProps;
