import {
  createEmptyBlockElement,
  createEmptyBlockState,
  createEmptyBlockTemplateState,
} from './empty-block';
import {
  createLegacyFormBlockElement,
  createLegacyFormBlockState,
  createLegacyFormBlockTemplateState,
  createFieldState,
  createFormBlockElement,
  createFormBlockState,
  createFormBlockTemplateState,
} from './form-block';
import { createColumnElement, createColumnState, createColumnTemplateState } from './grid/column';
import { createGridElement, createGridState, createGridTemplateState } from './grid/grid';
import { createRowElement, createRowState, createRowTemplateState } from './grid/row';
import {
  createSegmentElement,
  createSegmentState,
  createSegmentTemplateState,
} from './grid/segment';
import {
  createImageBlockElement,
  createImageBlockState,
  createImageBlockTemplateState,
} from './image-block';
import {
  createJsonBlockElement,
  createJsonBlockState,
  createJsonBlockTemplateState,
} from './json-block';
import { createPageElement, createPageState, createPageTemplateState } from './page';
import {
  createTextBlockElement,
  createTextBlockState,
  createTextBlockTemplateState,
} from './text-block';
import { ELEMENT_TYPE } from '../../../constants/types';

export function getRecipeSnippetGeneratorFunction(type) {
  const GENERATOR_BY_TYPE = {
    [ELEMENT_TYPE.TextBlock]: createTextBlockElement,
    [ELEMENT_TYPE.ImageBlock]: createImageBlockElement,
    [ELEMENT_TYPE.Grid]: createGridElement,
    [ELEMENT_TYPE.Row]: createRowElement,
    [ELEMENT_TYPE.Column]: createColumnElement,
    [ELEMENT_TYPE.Segment]: createSegmentElement,
    [ELEMENT_TYPE.EmptyBlock]: createEmptyBlockElement,
    [ELEMENT_TYPE.Page]: createPageElement,
    [ELEMENT_TYPE.FormBlock]: createLegacyFormBlockElement, // deprecated
    [ELEMENT_TYPE.CustomFormBlock]: createFormBlockElement,
    [ELEMENT_TYPE.JsonBlock]: createJsonBlockElement,
  };

  return GENERATOR_BY_TYPE[type];
}

export function getRecipeStateGeneratorFunction(type) {
  const GENERATOR_BY_TYPE = {
    [ELEMENT_TYPE.TextBlock]: createTextBlockState,
    [ELEMENT_TYPE.ImageBlock]: createImageBlockState,
    [ELEMENT_TYPE.Grid]: createGridState,
    [ELEMENT_TYPE.Row]: createRowState,
    [ELEMENT_TYPE.Column]: createColumnState,
    [ELEMENT_TYPE.Segment]: createSegmentState,
    [ELEMENT_TYPE.EmptyBlock]: createEmptyBlockState,
    [ELEMENT_TYPE.Page]: createPageState,
    [ELEMENT_TYPE.FormBlock]: createLegacyFormBlockState, // deprecated
    [ELEMENT_TYPE.CustomFormBlock]: createFormBlockState,
    [ELEMENT_TYPE.FormField]: createFieldState,
    [ELEMENT_TYPE.JsonBlock]: createJsonBlockState,
  };

  return GENERATOR_BY_TYPE[type];
}

export function getRecipeStateToTemplateStateFunction(type) {
  const GENERATOR_BY_TYPE = {
    [ELEMENT_TYPE.TextBlock]: createTextBlockTemplateState,
    [ELEMENT_TYPE.ImageBlock]: createImageBlockTemplateState,
    [ELEMENT_TYPE.Grid]: createGridTemplateState,
    [ELEMENT_TYPE.Row]: createRowTemplateState,
    [ELEMENT_TYPE.Column]: createColumnTemplateState,
    [ELEMENT_TYPE.Segment]: createSegmentTemplateState,
    [ELEMENT_TYPE.EmptyBlock]: createEmptyBlockTemplateState,
    [ELEMENT_TYPE.Page]: createPageTemplateState,
    [ELEMENT_TYPE.FormBlock]: createLegacyFormBlockTemplateState,
    [ELEMENT_TYPE.CustomFormBlock]: createFormBlockTemplateState,
    [ELEMENT_TYPE.JsonBlock]: createJsonBlockTemplateState,
  };

  return GENERATOR_BY_TYPE[type];
}
