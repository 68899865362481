import semver from 'semver';

import { sortPatchesByVersion } from './services/recipe-utils';

export function getNextRecipePatchVersion(baseRecipe, patchesData) {
  if (!baseRecipe) {
    return null;
  }
  const { patch_version: version } = sortPatchesByVersion(patchesData)[0];
  return semver.inc(version, 'patch');
}
