import React, { useState } from 'react';

import { Loader, Message } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import useDebounce from '../../../../../hooks/useDebounce';
import API from '../../../../../libs/api';
import { selectBusinessInfoId } from '../../../../../selectors/business';
import { selectWebsiteId } from '../../../../../selectors/website';
import CustomerSubmissionsModal from '../../../../modules/customers/components/CustomerSubmissionsModal';
import CustomerSubmissionsTableBody from '../../../../modules/customers/components/CustomerSubmissionsTableBody';
import CustomerSubmissionsTableHeader from '../../../../modules/customers/components/CustomerSubmissionsTableHeader';
import SubmissionsSearchBar from '../../../../modules/customers/components/SubmissionsSearchBar';
import { FormSubmissionsProvider } from '../../../../modules/customers/contexts/FormSubmissions.context';
import useServerTableControls from '../../../../modules/foundation/hooks/use-server-table-controls';
import PaginatedTable from '../../../../modules/tables/components/PaginatedTable';

import './SubmissionsTab.scss';

const propTypes = {};

const defaultProps = {};

export default function SubmissionsTab() {
  const [currentSubmission, setCurrentSubmission] = useState({});
  const [showSubmissionDetailModal, setShowSubmissionDetailModal] = useState(false);
  const [queryParams, setQueryParams] = useState({});

  const debouncedQueryParams = useDebounce(queryParams, 500);
  const businessInfoId = useSelector(selectBusinessInfoId);
  const websiteId = useSelector(selectWebsiteId);

  async function getSubmissions({ searchValue, currentPage }) {
    return API.getWebsiteEvents({
      page: currentPage,
      search: searchValue,
      businessInfo: businessInfoId,
      website: websiteId,
      ...debouncedQueryParams,
    });
  }

  const {
    data: submissions,
    loading,
    errorMessage,
    currentPage,
    totalPages,
    searchValue,
    sortedColumn,
    sortDirection,
    setCurrentPage,
    setSearchValue,
    onSort,
  } = useServerTableControls(getSubmissions, [debouncedQueryParams]);

  const context = {};

  function onPageChange(e, { activePage }) {
    setCurrentPage(activePage);
  }

  function displaySubmissionDetailModal(show = true, submission) {
    setCurrentSubmission(submission);
    setShowSubmissionDetailModal(show);
  }

  return (
    <FormSubmissionsProvider value={context}>
      <div className="form-submissions-tab">
        <SubmissionsSearchBar
          filterValue={searchValue}
          setFilterValue={setSearchValue}
          setQueryParam={(key, value) =>
            setQueryParams((prevValue) => ({ ...prevValue, [key]: value }))
          }
        />
        {loading && <Loader active inline="centered" />}
        {!loading && (
          <PaginatedTable
            tableHeader={
              <CustomerSubmissionsTableHeader
                handleSort={onSort}
                sortedColumn={sortedColumn}
                directionSort={sortDirection}
              />
            }
            tableBody={
              <CustomerSubmissionsTableBody
                submissions={submissions}
                onDetailsButtonClick={(submission) => {
                  displaySubmissionDetailModal(true, submission);
                }}
                columns={8}
              />
            }
            onPageChange={onPageChange}
            activePage={currentPage}
            totalPages={totalPages}
            columns={8}
          />
        )}

        <CustomerSubmissionsModal
          open={showSubmissionDetailModal}
          setOpen={setShowSubmissionDetailModal}
          submission={currentSubmission}
        />

        {errorMessage && <Message header={errorMessage} negative />}
      </div>
    </FormSubmissionsProvider>
  );
}

SubmissionsTab.propTypes = propTypes;
SubmissionsTab.defaultProps = defaultProps;
