import _get from 'lodash/get';

export function sortItemsByList(itemsToSort, sortedList, itemKey = 'id', listKey = '') {
  const listGetter = listKey ? (item) => item[listKey] : (item) => item;
  return sortedList
    .map((listElement) =>
      itemsToSort.find((item) => _get(item, itemKey) === listGetter(listElement)),
    )
    .filter(Boolean);
}

export function mergeArrayProperties(prev, newData) {
  return Object.keys(newData).reduce(
    (acc, key) => ({ ...acc, [key]: [...(acc[key] || []), ...newData[key]] }),
    prev,
  );
}
