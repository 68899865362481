import React from 'react';

import { isButtonLinksGroupingSupported } from './NavigationPage.utils';
import useRecipe from '../../modules/foundation/hooks/use-recipe';
import NavigationContainer from '../../modules/navigation/components/NavigationContainer';
import NavigationToolbar from '../../modules/navigation/components/NavigationToolbar';
import { NavigationProvider } from '../../modules/navigation/context/Navigation.context';
import useNavigation from '../../modules/navigation/hooks/use-navigation';
import useNavigationGroup from '../../modules/navigation/hooks/use-navigation-group';

import './NavigationPage.scss';

const propTypes = {};
const defaultProps = {};

export default function NavigationPage() {
  const { activePatch } = useRecipe();

  const isButtonGroupingSupported = isButtonLinksGroupingSupported(activePatch);

  const navigation = useNavigation();
  const navigationGroup = useNavigationGroup(navigation);

  const context = { navigation, navigationGroup, isButtonGroupingSupported };

  return (
    <div className="navigation-page">
      <NavigationProvider value={context}>
        <NavigationToolbar />
        <NavigationContainer />
      </NavigationProvider>
    </div>
  );
}

NavigationPage.propTypes = propTypes;
NavigationPage.defaultProps = defaultProps;
