import React from 'react';

import PropTypes from 'prop-types';
import { Button, Input } from 'semantic-ui-react';

const propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  className: undefined,
  files: [],
};

function WoflowFilesInputs({ className, files, onRemove }) {
  return (
    <>
      {files.map((file) => (
        <Input
          className={className}
          key={file.id}
          value={file.filename}
          disabled
          action={<Button icon="trash" onClick={() => onRemove(file)} />}
        />
      ))}
    </>
  );
}

WoflowFilesInputs.propTypes = propTypes;
WoflowFilesInputs.defaultProps = defaultProps;
export default WoflowFilesInputs;
