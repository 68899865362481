import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';

import {
  SEMANTIC_BUTTON_PROPS,
  SEMANTIC_GRID_COLUMN_PROPS,
  SEMANTIC_MENU_PROPS,
  SEMANTIC_MENU_ITEM_PROPS,
  SEMANTIC_ACCORDION_PROPS,
  SEMANTIC_ACCORDION_CONTENT_PROPS,
  SEMANTIC_INPUT_PROPS,
  SEMANTIC_ICON_PROPS,
  SEMANTIC_MESSAGE_PROPS,
  SEMANTIC_MODAL_PROPS,
} from './use-semantic-ui-props.constants';

export default function useSemanticUiProps() {
  function getSemanticProps(props, validPropOptions) {
    if (!props || !_isObject(props) || _isEmpty(validPropOptions)) {
      return {};
    }

    const validProps = {};

    Object.keys(props || {}).forEach((key) => {
      if (validPropOptions.includes(key)) {
        validProps[key] = props[key];
      }
    });

    return validProps;
  }

  function getButtonProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_BUTTON_PROPS);
  }

  function getColumnProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_GRID_COLUMN_PROPS);
  }

  function getMenuProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_MENU_PROPS);
  }

  function getMenuItemProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_MENU_ITEM_PROPS);
  }

  function getAccordionProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_ACCORDION_PROPS);
  }

  function getAccordionContentProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_ACCORDION_CONTENT_PROPS);
  }

  function getInputProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_INPUT_PROPS);
  }

  function getIconProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_ICON_PROPS);
  }

  function getMessageProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_MESSAGE_PROPS);
  }

  function getModalProps(props = {}) {
    return getSemanticProps(props, SEMANTIC_MODAL_PROPS);
  }

  return {
    getButtonProps,
    getMenuProps,
    getMenuItemProps,
    getSemanticProps,
    getColumnProps,
    getAccordionProps,
    getAccordionContentProps,
    getInputProps,
    getIconProps,
    getMessageProps,
    getModalProps,
  };
}
