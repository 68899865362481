import React from 'react';

import PropTypes from 'prop-types';
import { Header, Modal } from 'semantic-ui-react';

import { WHITESPACE } from './CloseableModal.constants';
import { ChildrenPropType } from '../../../libs/proptypes';
import CancelImage from '../CancelImage';

import './CloseableModal.scss';

const propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  children: ChildrenPropType,
  className: PropTypes.string,
  header: PropTypes.node,
  closeIconDisabled: PropTypes.bool,
};

const defaultProps = {
  onClose: null,
  open: undefined,
  className: '',
  children: null,
  header: null,
  closeIconDisabled: false,
};

// ...rest props can correspond to any of the props of the semantic ui modal component
// https://react.semantic-ui.com/modules/modal/

export default function CloseableModal({
  open,
  onClose,
  closeIconDisabled,
  className,
  header,
  children,
  ...rest
}) {
  return (
    <Modal
      dimmer="inverted"
      open={open}
      onClose={onClose}
      closeOnEscape
      closeOnDimmerClick={false}
      className={`closeable-modal ${className}`}
      {...rest}
    >
      <Modal.Header>
        <Header as="h3" className="inner-header">
          {header || WHITESPACE}
          {onClose && <CancelImage onClick={onClose} disabled={closeIconDisabled} />}
        </Header>
      </Modal.Header>
      {children}
    </Modal>
  );
}

CloseableModal.propTypes = propTypes;
CloseableModal.defaultProps = defaultProps;
