import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';

export function createQueryParams(formData) {
  const queryNameByKey = {
    aspectRatio: 'ar',
    cropType: 'crop',
    fillType: 'fill',
  };

  const filteredEntries = Object.entries(formData)
    .filter(([, value]) => !_isEmpty(value) || _isNumber(value))
    .map(([key, value]) => {
      const queryName = _get(queryNameByKey, key, key);
      return [queryName, value];
    });

  const queryParams = new URLSearchParams(filteredEntries).toString();

  return !_isEmpty(queryParams) ? `?${queryParams}` : queryParams;
}
