/* eslint-disable camelcase */
import _difference from 'lodash/difference';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import { v4 as uuidv4 } from 'uuid';

import {
  SERVER_ERROR_MESSAGE,
  REQUEST_ERROR_MESSAGE,
  DEFAULT_VARIATION_NAME,
} from './CatalogItemForm.constants';
import API from '../../../../../libs/api';
import {
  CATALOG_MAX_ORDINAL,
  CATALOG_MIN_ORDINAL,
  CATALOG_OBJECT_TYPE_ITEM,
  CATALOG_OBJECT_TYPE_ITEM_VARIATION,
  PRICING_TYPE_FIXED,
} from '../constants';
import { getCatalogObjectId, updateCatalogObjectField, updateCatalogObject } from '../utils';

export function createNewItemVariationData(
  itemObjectId = null,
  defaultName = DEFAULT_VARIATION_NAME,
  extraData = {},
) {
  const { ordinal, previousOrdinal, nextOrdinal } = extraData;
  return {
    localId: uuidv4(),
    item_variation_data: {
      item: itemObjectId,
      images: [],
      name: defaultName,
      pricing_type: PRICING_TYPE_FIXED,
      price: '0',
      ordinal,
      previous_ordinal: previousOrdinal,
      next_ordinal: nextOrdinal,
    },
    type: CATALOG_OBJECT_TYPE_ITEM_VARIATION,
  };
}

export function getInitialItemVariations(itemObjectId, variations = []) {
  return variations && variations.length !== 0
    ? variations
    : [
        createNewItemVariationData(itemObjectId, DEFAULT_VARIATION_NAME, {
          nextOrdinal: CATALOG_MAX_ORDINAL,
        }),
      ];
}

export async function saveCatalogItem(
  businessType,
  businessId,
  merchantProduct,
  initialData,
  itemObjectId,
  categoryObjectId,
  mainItemFields,
) {
  const newItemData = {
    item_data: {
      ...mainItemFields,
      category: categoryObjectId,
    },
    type: CATALOG_OBJECT_TYPE_ITEM,
  };
  if (itemObjectId) {
    newItemData.object_id = itemObjectId;
  }
  const itemPayload = updateCatalogObject(initialData, newItemData);

  const { data } = await API.saveCatalogObject(businessType, businessId, {
    ...itemPayload,
    merchant_product: merchantProduct.id,
  });

  return data;
}

export async function saveCatalogImage(
  businessType,
  businessId,
  merchantProduct,
  itemObjectId,
  image,
  images,
  shouldUpdateFromObjects,
) {
  let imageObjectId = image.objectId;
  if (image.file) {
    const payload = new FormData();
    payload.append('url', image.file);
    payload.append('merchant_product', merchantProduct.id);
    const { data: catalogImageData } = await API.saveCatalogImage(
      businessType,
      businessId,
      payload,
    );
    imageObjectId = catalogImageData.object_id;
  }
  if (imageObjectId) {
    const imageIdsToDisable = _map(images, 'object_id').filter(
      (imageId) => imageId !== imageObjectId,
    );
    return API.saveItemImages(
      itemObjectId,
      [imageObjectId],
      imageIdsToDisable,
      shouldUpdateFromObjects,
    );
  }
  return null;
}

export async function saveItemTaxes(
  itemObjectId,
  initialItemTaxes,
  itemTaxesToSave,
  shouldUpdateFromObjects,
) {
  const taxesToDisableRelation = _difference(initialItemTaxes, itemTaxesToSave);
  const taxesToEnableRelation = itemTaxesToSave;
  if (taxesToDisableRelation.length > 0 || taxesToEnableRelation.length > 0) {
    await API.saveItemTaxes(
      itemObjectId,
      taxesToEnableRelation,
      taxesToDisableRelation,
      shouldUpdateFromObjects,
    );
    return itemTaxesToSave;
  }
  return [];
}

export async function saveItemVariations(
  businessType,
  businessId,
  merchantProduct,
  itemObjectId,
  itemVariationsToSave,
  itemVariationsToRemove,
) {
  // Remove Item Variations
  if (itemVariationsToRemove.length > 0) {
    await Promise.all(
      itemVariationsToRemove.map((itemVariation) =>
        API.removeCatalogObject(businessType, businessId, itemVariation.object_id),
      ),
    );
  }

  // Save Item Variations
  const nonEmptyItemVariationsToSave = itemVariationsToSave.filter((itemVariation) => {
    const hasData =
      (!!_get(itemVariation, 'item_variation_data.item') || !!_get(itemVariation, 'localId')) &&
      !!_get(itemVariation, 'item_variation_data.name') &&
      !_isNil(_get(itemVariation, 'item_variation_data.price')) &&
      _get(itemVariation, 'item_variation_data.price') !== '';
    return hasData;
  });
  const payloads = nonEmptyItemVariationsToSave.map((itemVariation) => {
    return updateCatalogObjectField(itemVariation, 'item_variation_data', 'item', itemObjectId);
  });

  const savedItemVariations = await payloads.reduce(
    async (previousSavedItemVariationsPromise, itemVariationPayload) => {
      const previousSavedItemVariations = await previousSavedItemVariationsPromise;

      const previousOrdinal = _get(
        previousSavedItemVariations,
        `${[previousSavedItemVariations.length - 1]}.item_variation_data.ordinal`,
        CATALOG_MIN_ORDINAL,
      );

      const payload = updateCatalogObjectField(
        {
          ...itemVariationPayload,
          merchant_product: merchantProduct.id,
        },
        'item_variation_data',
        'previous_ordinal',
        previousOrdinal,
      );
      return API.saveCatalogObject(businessType, businessId, payload).then(({ data }) => [
        ...previousSavedItemVariations,
        data,
      ]);
    },
    Promise.resolve([]),
  );

  return { savedItemVariations, removedItemVariations: itemVariationsToRemove };
}

export async function saveItemModifierSets(itemObjectId, modifierSets, shouldUpdateFromObjects) {
  await Promise.all(
    modifierSets.map((modifierSet) => {
      const { min_selected_modifiers, max_selected_modifiers } =
        modifierSet.itemModifierListInfo || {};
      const relationData = {
        min_selected_modifiers,
        max_selected_modifiers,
      };
      return API.saveItemModifierListInfo(
        itemObjectId,
        [_get(modifierSet, 'modifierList.object_id')],
        [],
        relationData,
        shouldUpdateFromObjects,
      );
    }),
  );
}

export function getSearchableModifierSets(catalogModifierSets, itemModifierListIds) {
  const searchableModifierSets = _map(catalogModifierSets, (modifierSet) => ({
    ...modifierSet,
    itemModifierListInfo: {
      item: null,
      modifier_list: getCatalogObjectId(modifierSet.modifierList),
      min_selected_modifiers: _get(
        modifierSet,
        'modifierList.modifier_list_data.default_min_selected_modifiers',
      ),
      max_selected_modifiers: _get(
        modifierSet,
        'modifierList.modifier_list_data.default_max_selected_modifiers',
      ),
    },
  }));
  return _filter(
    searchableModifierSets,
    (searchableModifierSet) =>
      !_includes(itemModifierListIds, getCatalogObjectId(searchableModifierSet.modifierList)),
  );
}

export function getModifierSetsToDelete(modifierSets, initialModifierSets) {
  const modifierSetModifierListIds = modifierSets.map((modifierSet) =>
    getCatalogObjectId(modifierSet.modifierList),
  );
  return _filter(
    initialModifierSets,
    (initialModifierSet) =>
      !_includes(modifierSetModifierListIds, getCatalogObjectId(initialModifierSet.modifierList)),
  );
}

export function getModifierSetsToCreateOrUpdate(modifierSets, initialModifierSets) {
  return _filter(modifierSets, (modifierSet) => {
    const matchingInitialModifierSet = _find(
      initialModifierSets,
      (initialModifierSet) =>
        getCatalogObjectId(initialModifierSet.modifierList) ===
        getCatalogObjectId(modifierSet.modifierList),
    );
    if (!matchingInitialModifierSet) {
      return true;
    }
    if (
      _get(modifierSet, 'itemModifierListInfo.min_selected_modifiers') !==
        _get(matchingInitialModifierSet, 'itemModifierListInfo.min_selected_modifiers') ||
      _get(modifierSet, 'itemModifierListInfo.min_selected_modifiers') !==
        _get(matchingInitialModifierSet, 'itemModifierListInfo.min_selected_modifiers')
    ) {
      return true;
    }
    return false;
  });
}

export function handleCatalogItemFormError(e, setError) {
  const statusCode = _get(e, 'response.status', 500);
  if (statusCode === 400) {
    setError(REQUEST_ERROR_MESSAGE);
  } else {
    setError(SERVER_ERROR_MESSAGE);
  }
}
