import React from 'react';

import PropTypes from 'prop-types';

import {
  SUBTOTAL_LABEL,
  TAX_LABEL,
  TIP_LABEL,
  FEES_LABEL,
  FULFILLMENT_LABEL,
  DISCOUNTS_LABEL,
  TOTAL_LABEL,
} from './SummaryDetails.constants';
import SummaryItem from './components/SummaryItem';

import './SummaryDetails.scss';

const propTypes = {
  subtotal: PropTypes.string.isRequired,
  taxes: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  tip: PropTypes.string,
  processingFees: PropTypes.string,
  fulfillmentFee: PropTypes.string,
  discounts: PropTypes.string,
};

const defaultProps = {
  tip: '',
  processingFees: '',
  fulfillmentFee: '',
  discounts: '',
};

export default function SummaryDetails({
  subtotal,
  taxes,
  total,
  tip,
  processingFees,
  fulfillmentFee,
  discounts,
}) {
  return (
    <div className="order-summary">
      <SummaryItem label={SUBTOTAL_LABEL} amount={subtotal} />
      <SummaryItem label={FEES_LABEL} amount={processingFees} />
      <SummaryItem label={TAX_LABEL} amount={taxes} />
      <SummaryItem label={TIP_LABEL} amount={tip} />
      <SummaryItem label={DISCOUNTS_LABEL} amount={discounts} />
      <SummaryItem label={FULFILLMENT_LABEL} amount={fulfillmentFee} />
      <SummaryItem label={TOTAL_LABEL} amount={total} content={<div />} />
    </div>
  );
}

SummaryDetails.propTypes = propTypes;
SummaryDetails.defaultProps = defaultProps;
