import React, { useContext } from 'react';

import { Message, Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import RedirectTableRow from './components/RedirectTableRow';
import { RedirectContext } from '../../contexts/Redirect.context';

import './RedirectTable.scss';

const propTypes = {};

const defaultProps = {};

export default function RedirectTable() {
  const { redirects } = useContext(RedirectContext);

  return (
    <div className="redirect-table-container">
      {_isEmpty(redirects) && <Message>No redirects found</Message>}
      {!_isEmpty(redirects) && (
        <Table className="redirect-table" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>From</Table.HeaderCell>
              <Table.HeaderCell width={3}>To</Table.HeaderCell>
              <Table.HeaderCell width={5}>Description</Table.HeaderCell>
              <Table.HeaderCell width={3}>Last Update</Table.HeaderCell>
              <Table.HeaderCell width={2} />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {redirects.map((redirect) => {
              return <RedirectTableRow key={redirect.id} redirect={redirect} />;
            })}
          </Table.Body>
        </Table>
      )}
    </div>
  );
}

RedirectTable.propTypes = propTypes;
RedirectTable.defaultProps = defaultProps;
