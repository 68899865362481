import React from 'react';

import PropTypes from 'prop-types';
import { Header, Segment } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { SeoMetadataFieldsProvider } from './SeoMetadata.context';
import { SeoTitle, SeoDescription } from './components';
import { isAdminUser } from '../../../../../libs/auth';
import { ChildrenPropType } from '../../../../../libs/proptypes';
import { selectUser } from '../../../../../selectors/user';

import './SeoMetadataFields.scss';

const propTypes = {
  children: ChildrenPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  pageType: PropTypes.string.isRequired,
  header: PropTypes.string,
};

const defaultProps = {
  header: 'SEO Information',
};

export default function SeoMetadataFields({ children, onChange: onChangeProp, pageType, header }) {
  const user = useSelector(selectUser);
  const isAdmin = isAdminUser(user);

  function onChange(e, target) {
    if (onChangeProp) {
      onChangeProp(e, target);
    }
  }

  const context = { onChange, pageType };

  if (!isAdmin) {
    return null;
  }

  return (
    <SeoMetadataFieldsProvider value={context}>
      <Segment className="seo-metadata-fields">
        {!_isEmpty(header) && <Header>{header}</Header>}
        <div className="content">{children}</div>
      </Segment>
    </SeoMetadataFieldsProvider>
  );
}

SeoMetadataFields.propTypes = propTypes;
SeoMetadataFields.defaultProps = defaultProps;

SeoMetadataFields.SeoTitle = SeoTitle;
SeoMetadataFields.SeoDescription = SeoDescription;
