import { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { validateUrls, postAddFile, postDeleteFile } from './WoflowFilesUploadModal.utils';
import { addNewProviderServiceSetting } from '../../../../../../actions/business';
import API from '../../../../../../libs/api';

export function useWoflowFileUpload() {
  const dispatch = useDispatch();
  const [externalUrls, setExternalUrls] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidData, setIsValidData] = useState(false);

  useEffect(() => {
    const urlsAreValid = validateUrls(externalUrls.map((url) => url.url));
    const dataAvailable = externalUrls.length > 0 || files.length > 0;
    setIsValidData(urlsAreValid && dataAvailable);
  }, [externalUrls, files]);

  function resetState() {
    setExternalUrls([]);
    setFiles([]);
    setIsLoading(false);
    setIsValidData(false);
  }

  function addUrl(url) {
    setExternalUrls((prevState) => [...prevState, { url, key: uuid() }]);
  }

  async function addFile(businessType, businessId, file) {
    setIsLoading(true);
    try {
      const { id, url, filename } = await postAddFile(businessId, businessType, file);
      setFiles((prevState) => [...prevState, { id, url, filename }]);
    } finally {
      setIsLoading(false);
    }
  }

  async function removeFile(businessType, businessId, file) {
    setIsLoading(true);
    try {
      await postDeleteFile(businessType, businessId, file);
      setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.id !== file.id));
    } finally {
      setIsLoading(false);
    }
  }

  function removeUrl(url) {
    setExternalUrls((prevUrls) => prevUrls.filter((prevUrl) => prevUrl.url !== url));
  }

  function changeUrl(urlKey, urlValue) {
    setExternalUrls((prevUrls) =>
      prevUrls.map((url) => {
        if (url.key === urlKey) {
          return { ...url, url: urlValue };
        }
        return url;
      }),
    );
  }

  function getUrls() {
    return [
      ...externalUrls.map((externalUrl) => externalUrl.url),
      ...files.map((file) => file.url),
    ];
  }
  function getFileIds() {
    return files.map((file) => file.id);
  }

  async function createWoflowMenuJob(businessType, businessId, jobDestination) {
    setIsLoading(true);
    try {
      const payload = {
        urls: getUrls(),
        file_ids: getFileIds(),
        job_destination: jobDestination,
      };
      const { data } = await API.createWoflowMenuJob(businessType, businessId, payload);

      dispatch(addNewProviderServiceSetting(data));
    } finally {
      setIsLoading(false);
    }
  }

  return {
    urls: externalUrls,
    files,
    isLoading,
    isValidData,
    addUrl,
    addFile,
    removeFile,
    removeUrl,
    changeUrl,
    createWoflowMenuJob,
    resetWoflowState: resetState,
  };
}
