import React from 'react';

import { Segment, Container, Image } from 'semantic-ui-react';

import FacebookLogo from '../../data/facebook-logo.png';

import './FacebookIntegrationHeader.scss';

export default function FacebookIntegrationHeader() {
  return (
    <Segment className="facebook-integration-header">
      <Container className="logo-container" textAlign="center">
        <Image
          src={FacebookLogo}
          style={{
            display: 'inline',
            margin: 0,
            height: '75px',
          }}
        />
      </Container>

      <Container>
        The Facebook Integration allows Fisherman to pull information from your Facebook Page and
        add it to your website.
      </Container>
    </Segment>
  );
}
