import PropTypes from 'prop-types';

export const ActionsWidgetActionType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
});

export const ActionsWidgetSectionType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(ActionsWidgetActionType).isRequired,
});

export const ActionsWidgetSectionsType = PropTypes.arrayOf(ActionsWidgetSectionType);
