import { useState } from 'react';

import API from '../../../libs/api';
import useAsyncEffect from '../../modules/foundation/hooks/use-async-effect';

export function useRecipes() {
  const [recipes, setRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useAsyncEffect(async () => {
    setIsLoading(true);

    try {
      const { data: recipesResponseData } = await API.getRecipes();
      setRecipes(recipesResponseData);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { recipes, isLoading };
}
