import { CANT_FIND_BUSINESS_TITLE } from './BusinessSearchField.constants';

import Google from './data/PoweredByGoogle.png';

export function formatSearchResults(results, noResultsDescription) {
  if (!results && !results.length) {
    return [];
  }

  return [
    ...results.map(({ description, place_id: placeId }) => {
      const name = description.split(',')[0];
      const address = description.split(',').slice(1).join(',');

      return {
        title: name,
        description: address,
        key: placeId,
        place_id: placeId,
      };
    }),
    {
      title: CANT_FIND_BUSINESS_TITLE,
      description: noResultsDescription,
      key: 'no_results',
    },
    { image: Google, disabled: true, key: 'attribution', title: '' },
  ];
}

export function createResultFromPlaceDetails(placeDetails) {
  const { name: businessName, place_id: placeId } = placeDetails;

  return { title: businessName, key: placeId, place_id: placeId };
}

export function getResultTitle(result) {
  if (result && Object.keys(result).length) {
    const { title } = result;
    return title.trim();
  }

  return '';
}
