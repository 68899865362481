import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Radio, Input, Modal, Form } from 'semantic-ui-react';

import CloseableModal from '../../../../../common/CloseableModal';
import useAsyncEffect from '../../../../../modules/foundation/hooks/use-async-effect';
import {
  CREATE_PATCH_KEY,
  SAVE_PATCH_KEY,
  RECIPE_PATCH_STATUS,
} from '../../../RecipesEditForm.constants';

const propTypes = {
  patch: PropTypes.shape({
    id: PropTypes.number.isRequired,
    version: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  createPatch: PropTypes.func.isRequired,
  savePatch: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  trigger: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  saveMode: PropTypes.bool.isRequired,
};

function ModalCreatePatch({
  patch,
  saveMode,
  createPatch,
  onClose,
  open,
  savePatch,
  trigger,
  loading,
}) {
  const [active, setActive] = useState(false);
  const [draft, setDraft] = useState(true);
  const [patchVersion, setPatchVersion] = useState('');
  const [patchDescription, setPatchDescription] = useState('');

  const { id, description, version } = patch;

  function toggleActive() {
    setActive(!active);
    setDraft(false);
  }

  function clearState() {
    setPatchDescription('');
    setDraft(true);
    setActive(false);
  }

  function toggleDraft() {
    setDraft(!draft);
    setActive(false);
  }

  function setValues() {
    if (saveMode) {
      setPatchDescription(description);
      setPatchVersion(version);
      setDraft(true);
      setActive(false);
    } else {
      clearState();
      setPatchVersion(version);
    }
  }

  useAsyncEffect(async () => {
    setPatchVersion(version);
  }, []);

  return (
    <CloseableModal
      trigger={trigger}
      open={open}
      onOpen={setValues}
      className="developer-modal-create-patch"
      header={saveMode ? SAVE_PATCH_KEY : CREATE_PATCH_KEY}
      onClose={onClose}
      closeIconDisabled={loading}
    >
      <Modal.Content>
        <div className="developer-modal-create-patch-wrapper">
          <Form>
            <Form.Field>
              <label htmlFor="description-input">Description</label>
              <Input
                id="description-input"
                type="text"
                placeholder="Description"
                value={patchDescription}
                onChange={(e, { value }) => setPatchDescription(value)}
              />
            </Form.Field>
            <Form.Field disabled>
              <label htmlFor="path-version-input">Patch version</label>
              <Input
                id="path-version-input"
                type="text"
                placeholder="Patch version"
                value={patchVersion}
                onChange={(e, { value }) => setPatchVersion(value)}
              />
            </Form.Field>
            <Radio
              slider
              checked={draft}
              label={RECIPE_PATCH_STATUS.DRAFT}
              onChange={toggleDraft}
            />
            <Radio
              slider
              checked={active}
              label={RECIPE_PATCH_STATUS.ACTIVE}
              onChange={toggleActive}
            />
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          loading={loading}
          primary
          content={saveMode ? SAVE_PATCH_KEY : CREATE_PATCH_KEY}
          onClick={() =>
            saveMode
              ? savePatch({ id, patchDescription, active, draft })
              : createPatch({ patchDescription, patchVersion, active, draft })
          }
        />
      </Modal.Actions>
    </CloseableModal>
  );
}

ModalCreatePatch.propTypes = propTypes;

export default ModalCreatePatch;
