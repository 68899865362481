import Ajv from 'ajv';
import _ from 'lodash';

import { removeNodeMetadata } from './recipe-utils';

import schema from '../constants/schema.json';

export function validateRecipe(recipe) {
  const ajv = new Ajv();
  const validate = ajv.compile(schema);
  validate(removeNodeMetadata(recipe));

  return validate.errors || [];
}

export function valueAtPathIsValid(path, validationErrors) {
  const formattedErrorPaths = validationErrors.map(({ dataPath }) => {
    return _.toPath(dataPath).slice(1).join('.');
  });
  return !formattedErrorPaths.some((dataPath) => dataPath === path);
}

export function pathInValidationErrors(path, validationErrors) {
  const formattedErrorPaths = validationErrors.map(({ dataPath }) => {
    return _.toPath(dataPath).slice(1).join('.');
  });
  return formattedErrorPaths.some((dataPath) => dataPath.includes(path));
}
