import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Table, Loader } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import PartialTableItem from './PartialTableItem';
import API from '../../../../../libs/api';
import { transformObjectKeyToSnakeCase } from '../../../../../libs/strings/strings';
import PartialProptypes from '../../Partial.propTypes';
import { CSS_KEY, JSON_KEY } from '../../PartialPage/PartialPage.constants';
import { getPartialFileContent } from '../../PartialPage/PartialPage.utils';
import PartialActions from '../PartialActions';
import PartialJsonEditor from '../modals/PartialJsonEditor';
import ModalEditPartialMeta from '../modals/RecipePartialModal';

const PartialTableBody = ({ partials, isFetching, onEditMeta, onCopyToCliboard, onError }) => {
  const [modalEditRawOpened, setModalEditRawOpened] = useState(false);
  const [jsonValue, setJsonValue] = useState(null);
  const [isPartialJsonSaving, setIsPartialJsonSaving] = useState(false);
  const [isPartialJsonLoading, setIsPartialJsonLoading] = useState(false);
  const [modalEditMetaOpened, setModalEditMetaOpened] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedPartial, setSelectedPartial] = useState({
    meta: {},
    id: null,
  });

  const handleSavingRawJson = async (content) => {
    const { id } = selectedPartial;
    const partialBlob = new Blob([JSON.stringify(content)], {
      type: 'text/json',
    });

    setIsPartialJsonSaving(true);
    try {
      await API.updateRecipePartial(id, {
        partial: partialBlob,
      });
    } catch (err) {
      onError(err);
    } finally {
      setIsPartialJsonSaving(false);
      setModalEditRawOpened(false);
    }
  };

  const loadPartialJson = async (partialId) => {
    setModalEditRawOpened(true);
    setIsPartialJsonLoading(true);
    await getPartialFileContent(
      partialId,
      (partial) => {
        setIsPartialJsonLoading(false);
        setJsonValue(partial);
      },
      (err) => {
        setModalEditRawOpened(false);
        setIsPartialJsonLoading(false);
        onError(err);
      },
    );
  };

  const handleSavePartialMeta = async (updatedPartial) => {
    setIsSaving(true);
    try {
      const payload = transformObjectKeyToSnakeCase(updatedPartial);

      const { status } = await API.updateRecipePartial(selectedPartial.id, payload);
      if (status === 200) onEditMeta(selectedPartial.id, updatedPartial);
    } catch (error) {
      const err = _get(error, 'response.statusText') || error;
      onError(err);
    } finally {
      setIsSaving(false);
      setModalEditMetaOpened(false);
    }
  };

  const handleEditPartialMeta = (id, meta) => {
    setSelectedPartial({
      id,
      meta,
    });
    setModalEditMetaOpened(true);
  };

  const handleEditRaw = (id, meta) => {
    setSelectedPartial({
      id,
      meta,
    });
    loadPartialJson(id);
  };

  function getEditorMode() {
    const { meta } = selectedPartial;

    if (_isEmpty(meta)) {
      return JSON_KEY;
    }

    return meta.type.toLowerCase() !== CSS_KEY ? JSON_KEY : CSS_KEY;
  }

  return (
    <>
      <Table.Body>
        {partials.map(({ name, description, type, schemaVersion, tags, id }) => (
          <PartialTableItem
            key={id}
            partial={{
              name,
              description,
              type,
              schemaVersion,
              tags,
              id,
            }}
            actions={
              <PartialActions>
                <PartialActions.Item
                  icon="copy"
                  text="Copy to clipboard..."
                  onClick={() => onCopyToCliboard(id)}
                />

                <PartialActions.Item
                  icon="pencil"
                  text="Edit Raw..."
                  onClick={() => {
                    handleEditRaw(id, {
                      name,
                      description,
                      type,
                      schemaVersion,
                      tags,
                    });
                  }}
                />

                <PartialActions.Item
                  icon="pencil"
                  text="Edit Partial Meta..."
                  onClick={() => {
                    handleEditPartialMeta(id, {
                      name,
                      description,
                      type,
                      schemaVersion,
                      tags,
                    });
                  }}
                />
              </PartialActions>
            }
          />
        ))}

        <ModalEditPartialMeta
          isNewPartial={false}
          open={modalEditMetaOpened}
          isSaving={isSaving}
          header="Update Partial Meta"
          onSave={handleSavePartialMeta}
          onCancel={() => setModalEditMetaOpened(false)}
          initialValues={selectedPartial.meta}
        />

        <PartialJsonEditor
          open={modalEditRawOpened}
          header="JSON Editor"
          mode={getEditorMode()}
          onSave={(content) => handleSavingRawJson(content)}
          isLoading={isPartialJsonLoading}
          onCancel={() => setModalEditRawOpened(false)}
          isSaving={isPartialJsonSaving}
          partialInitialValue={jsonValue}
        />
      </Table.Body>
      <Loader active={isFetching}>Loading</Loader>
    </>
  );
};

PartialTableBody.propTypes = {
  partials: PropTypes.arrayOf(PartialProptypes),
  isFetching: PropTypes.bool,
  onEditMeta: PropTypes.func,
  onCopyToCliboard: PropTypes.func,
  onError: PropTypes.func,
};

PartialTableBody.defaultProps = {
  partials: [],
  isFetching: false,
  onEditMeta: undefined,
  onCopyToCliboard: undefined,
  onError: undefined,
};

export default PartialTableBody;
