import {
  SOURCE_TYPE_DEFAULT,
  SOURCE_TYPE_GOOGLE,
  SOURCE_TYPE_FACEBOOK,
  SOURCE_TYPE_YELP,
  SOURCE_TYPE_EXTERNAL_WEBSITE,
  SOURCE_TYPE_STOCK,
  SOURCE_TYPE_MENU,
  SOURCE_TYPE_TEAM_MEMBER,
} from '../../../../modules/files/constants/sources';

export const DEFAULT_SOURCE_FILTER_VALUE = 'All';

export const BASE_FILE_SOURCE_FILTER_CLASS_NAME = 'file-source-filter';

export const ALL_FILE_SOURCE_FILTER_CLASS_NAME = `${BASE_FILE_SOURCE_FILTER_CLASS_NAME}-all`;

export const FILE_SOURCE_FILTER_OPTIONS = [
  {
    key: DEFAULT_SOURCE_FILTER_VALUE,
    text: 'All tags',
    value: DEFAULT_SOURCE_FILTER_VALUE,
  },
  {
    key: SOURCE_TYPE_DEFAULT,
    text: 'From user upload',
    value: SOURCE_TYPE_DEFAULT,
  },
  {
    key: SOURCE_TYPE_GOOGLE,
    text: 'From google',
    value: SOURCE_TYPE_GOOGLE,
  },
  {
    key: SOURCE_TYPE_FACEBOOK,
    text: 'From facebook',
    value: SOURCE_TYPE_FACEBOOK,
  },
  {
    key: SOURCE_TYPE_YELP,
    text: 'From yelp',
    value: SOURCE_TYPE_YELP,
  },
  {
    key: SOURCE_TYPE_EXTERNAL_WEBSITE,
    text: 'From other website',
    value: SOURCE_TYPE_EXTERNAL_WEBSITE,
  },
  {
    key: SOURCE_TYPE_MENU,
    text: 'From menu uploads',
    value: SOURCE_TYPE_MENU,
  },
  {
    key: SOURCE_TYPE_STOCK,
    text: 'From stock photos',
    value: SOURCE_TYPE_STOCK,
  },
  {
    key: SOURCE_TYPE_TEAM_MEMBER,
    text: 'From team member',
    value: SOURCE_TYPE_TEAM_MEMBER,
  },
];
