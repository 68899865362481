import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import { SEMANTIC_LOCK_ICON, SEMANTIC_UNLOCK_ICON } from '../../../RecipeEditor.constants';

const propTypes = {
  isLocked: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onToogleLock: PropTypes.func.isRequired,
  message: PropTypes.node,
};

const defaultProps = {
  message: '',
};

function EditorHeader({ isLocked, onToogleLock, mode, message }) {
  return (
    <div className="topbar">
      <div className="btn-lock">
        <Icon
          name={isLocked ? SEMANTIC_LOCK_ICON : SEMANTIC_UNLOCK_ICON}
          onClick={() => onToogleLock(isLocked)}
        />
      </div>
      <div>{message}</div>
      <div className="mode">
        <span>{mode}</span>
      </div>
    </div>
  );
}

EditorHeader.propTypes = propTypes;
EditorHeader.defaultProps = defaultProps;

export default EditorHeader;
