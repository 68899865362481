export const MESSAGE_TIMEOUT_SECONDS = 4000;
export const STRING_KEY = 'string';
export const STYLE_KEY = 'style';
export const CREATE_PATCH_DRAFT_KEY = 'Create Patch Draft';
export const SAVE_PATCH_KEY = 'Save Patch';
export const CREATE_PATCH_KEY = 'Create Patch';
export const RECIPE_PATCH_STATUS = {
  ACTIVE: 'active',
  DRAFT: 'draft',
};
