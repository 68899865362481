// TODO Cleanup after we resolve how we use
//  business type in the rest of the app

export const BUSINESS_TYPE_LABELS = {
  restaurant: {
    label: 'Restaurant',
    colorClass: 'label-green',
    icon: 'food',
  },
  merchant: {
    label: 'Merchant',
    colorClass: 'label-green',
    icon: 'building',
  },
};

export const BUSINESS_CATEGORY_LABELS = {
  restaurant: {
    label: 'Restaurant',
    colorClass: 'label-green',
    icon: 'food',
  },
  spa: {
    label: 'Spa',
    colorClass: 'label-green',
    icon: 'cut',
  },
  salon: {
    label: 'Salon',
    colorClass: 'label-green',
    icon: 'cut',
  },
  'hair care': {
    label: 'Hair Care',
    colorClass: 'label-green',
    icon: 'cut',
  },
  'tattoo parlor': {
    label: 'Tattoo Parlor',
    colorClass: 'label-green',
    icon: 'cut',
  },
};

export const PRODUCT_TYPE_LABELS = {
  OnlineOrdering: {
    label: 'OLO',
    colorClass: 'business-select-form-label-green',
    icon: 'food',
  },
  // Legacy value for restaurant websites
  Website: {
    label: 'Web',
    colorClass: 'business-select-form-label-blue',
    icon: 'browser',
  },
  'DaySmart Website': {
    label: 'Web',
    colorClass: 'business-select-form-label-blue',
    icon: 'browser',
  },
  'Salon Conference Website': {
    label: 'Web',
    colorClass: 'business-select-form-label-blue',
    icon: 'browser',
  },
};

export const PRODUCT_TYPE_LABEL_DEFAULT = {
  label: null,
};

export const BUSINESS_CATEGORY_LABEL_DEFAULT = {
  label: 'Merchant',
  colorClass: 'label-green',
  icon: 'hourglass half',
};
