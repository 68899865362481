import React from 'react';

import { Label, Table } from 'semantic-ui-react';

import _isArray from 'lodash/isArray';
import _isBoolean from 'lodash/isBoolean';
import _isNil from 'lodash/isNil';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _startCase from 'lodash/startCase';

import { NOT_PROVIDED_VALUE } from '../CustomerSubmissionsModal.constants';

export default function CustomerSubmissionsModalTableRow({
  fieldName,
  fieldValue,
  fieldType,
  showFieldType,
}) {
  const label = _startCase(fieldName);

  const renderMap = [
    {
      check: _isNil,
      render: () => NOT_PROVIDED_VALUE,
    },
    {
      check: _isBoolean,
      render: () => (fieldValue ? 'True' : 'False'),
    },
    {
      check: (value) => _isString(value),
      render: () => {
        return fieldValue.split('\n').map((part, index, array) => {
          return (
            <>
              {part}
              {index < array.length - 1 && <br />}
            </>
          );
        });
      },
    },
    {
      check: (value) => _isNumber(value),
      render: () => fieldValue.toString(),
    },
    {
      check: _isArray,
      render: () =>
        fieldValue.map((item) => {
          const itemValue = _isString(item) ? item : JSON.stringify(item);
          return (
            <Label basic key={`${fieldName}-${itemValue}`}>
              {itemValue}
            </Label>
          );
        }),
    },
    {
      check: _isObject,
      render: () =>
        Object.entries(fieldValue).map(([subfieldName, subValue]) => (
          <CustomerSubmissionsModalTableRow
            key={subfieldName}
            fieldName={subfieldName}
            fieldValue={subValue}
          />
        )),
    },
  ];

  // Find the first matching render function based on the conditions
  const renderFieldValue = () => {
    const { render } = renderMap.find(({ check }) => check(fieldValue)) || {};
    return render ? render() : '';
  };

  return (
    <Table.Row>
      <Table.Cell width={4}>{label}</Table.Cell>
      <Table.Cell>{renderFieldValue()}</Table.Cell>
      {showFieldType && <Table.Cell>{fieldType || ''}</Table.Cell>}
    </Table.Row>
  );
}
