import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { getOptions } from '../PartialPage/PartialPage.utils';

function PartialTypeSelect({ filters, selectedType, onChange, ...rest }) {
  return (
    <Form.Select
      value={selectedType}
      placeholder="Select An Object Type"
      onChange={(e) => onChange(e.target.textContent)}
      options={getOptions(filters)}
      {...rest}
    />
  );
}

PartialTypeSelect.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  selectedType: PropTypes.string,
};

PartialTypeSelect.defaultProps = {
  filters: [],
  onChange: () => null,
  selectedType: undefined,
};

export default PartialTypeSelect;
