import React from 'react';

import PropTypes from 'prop-types';

const propTypes = {
  input: PropTypes.elementType.isRequired,
};

const defaultProps = {};

export default function Input({ input, ...rest }) {
  return React.createElement(input, rest);
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;
