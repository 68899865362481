import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Table, Loader } from 'semantic-ui-react';

import RecipeManagerTableItem from './RecipeManagerTableItem';
import RecipeProptypes from '../../Recipe.propTypes';
import RecipeActions from '../RecipeActions';
import RecipeMetaEditModal from '../RecipeMetaEditModal';

const propTypes = {
  recipes: PropTypes.arrayOf(RecipeProptypes),
  isFetching: PropTypes.bool,
  onEditSuccess: PropTypes.func.isRequired,
  onEditError: PropTypes.func.isRequired,
};

const defaultProps = {
  recipes: [],
  isFetching: false,
};

const RecipeManagerTableBody = ({ recipes, isFetching, onEditSuccess, onEditError }) => {
  const [selectedRecipe, setSelectedRecipe] = useState({});

  return (
    <>
      <Table.Body>
        {recipes.map(({ id, name, recipeVersion, schemaVersion, tags }) => (
          <RecipeManagerTableItem
            key={id}
            recipe={{
              id,
              name,
              recipeVersion,
              schemaVersion,
              tags,
            }}
            actions={
              <RecipeActions>
                <RecipeActions.Item icon="edit" text="Open in editor..." onClick={() => null} />
                <RecipeMetaEditModal
                  header="Edit Recipe Meta"
                  initialValues={selectedRecipe}
                  onEditSuccess={onEditSuccess}
                  onEditError={onEditError}
                  trigger={
                    <RecipeActions.Item
                      icon="pencil"
                      text="Edit Recipe Meta..."
                      onClick={() => {
                        setSelectedRecipe({
                          id,
                          name,
                          recipeVersion,
                          schemaVersion,
                          tags,
                        });
                      }}
                    />
                  }
                />
              </RecipeActions>
            }
          />
        ))}
      </Table.Body>
      <Loader active={isFetching}>Loading</Loader>
    </>
  );
};

RecipeManagerTableBody.propTypes = propTypes;

RecipeManagerTableBody.defaultProps = defaultProps;

export default RecipeManagerTableBody;
