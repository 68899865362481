import PropTypes from 'prop-types';

const HEX_REGEX = /^#?([a-f0-9]{3}|[a-f0-9]{6})$/i;

export const HexColorPropType = (props, propName, componentName) => {
  if (!HEX_REGEX.test(props[propName])) {
    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}'. Validation failed.`,
    );
  }
  return undefined;
};

export const ColorPropType = PropTypes.shape({
  role: PropTypes.string.isRequired,
  color: HexColorPropType.isRequired,
});
