import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { IMAGE_CONTAINER_CLASS_NAME } from './MenuItem.constants';
import Currency from '../../../../../libs/currency';
import { multiCaseGet } from '../../../../../libs/format/case';
import Utils from '../../../../../libs/utils';
import { isMultipleImagesSupported } from '../../libs/menu-helpers';

import PlaceholderMenuItemPhoto from './PlaceholderMenuItemPhoto.png';

const ITEM_IMAGE_WIDTH = 200;

export function getPriceFromVariation(variation) {
  return (
    multiCaseGet(variation, 'priceDisplay') || Currency.format(_get(variation, 'price', 0)) || 0
  );
}

export function getPrice(item) {
  const variation = _get(item, 'variations.[0]', {});
  return getPriceFromVariation(variation);
}

export function getImageUrl(image = {}, defaultValue = PlaceholderMenuItemPhoto) {
  let url = defaultValue;
  if (!_isEmpty(image) && image && image.url) {
    url = image.url;
  } else if (typeof image === 'string') {
    url = image;
  }
  return url;
}

export function getBackgroundImageUrl(image = {}) {
  const url = getImageUrl(image);
  return `url(${Utils.addImageQueryParam(url, 'width', ITEM_IMAGE_WIDTH)})`;
}

export function getImage(item, patch, businessFiles) {
  const { image } = item;
  const isMultipleImagesEnabled = isMultipleImagesSupported(patch);
  if (isMultipleImagesEnabled) {
    const firstItemBusinessFileId = _get(item, 'files[0]');
    const itemBusinessFile = businessFiles.find(
      (businessFile) => businessFile.id === firstItemBusinessFileId,
    );
    return _get(itemBusinessFile, 'file');
  }
  return image;
}

export function isImageClickedEvent(e) {
  const className = _get(e, 'target.className', '');
  const type = _get(e, 'type', '');

  return className.includes(IMAGE_CONTAINER_CLASS_NAME) && type === 'click';
}
