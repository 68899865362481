import React, { useContext } from 'react';

import _isEmpty from 'lodash/isEmpty';

import { TypographyContext } from '../../contexts/Typography.context';

import './FamilyBreakdown.scss';

const propTypes = {};
const defaultProps = {};

export default function FamilyBreakdown() {
  const { allFonts, fonts } = useContext(TypographyContext);

  if (_isEmpty(allFonts) || _isEmpty(fonts)) {
    return null;
  }

  return (
    <span className="family-breakdown">
      {fonts.length} of {allFonts.length} families
    </span>
  );
}

FamilyBreakdown.propTypes = propTypes;
FamilyBreakdown.defaultProps = defaultProps;
