import React from 'react';

import { childrenPropTypes } from './Toolbar.constants';

import './Toolbar.scss';

const toolbarPropTypes = {
  children: childrenPropTypes.isRequired,
};

const toolbarActionPropTypes = {
  children: childrenPropTypes.isRequired,
};
function Toolbar({ children }) {
  return <div className="ecommerce-toolbar-ui toolbar-wrapper">{children}</div>;
}

Toolbar.Actions = function ToolbarActions({ children }) {
  return children;
};

Toolbar.propTypes = toolbarPropTypes;
Toolbar.Actions.propTypes = toolbarActionPropTypes;

export default Toolbar;
