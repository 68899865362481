import jwt from 'jsonwebtoken';
import moment from 'moment';

import ErrorHandler from './errors';

const { parse, stringify } = JSON;

export function parseStorageValue(value) {
  try {
    return parse(value);
  } catch (e) {
    return value;
  }
}

const Storage = {
  DEFAULT_STORAGE: 'localStorage',
  SESSION_STORAGE: 'sessionStorage',
  LOCAL: 'localStorage',
  COOKIE: 'cookie',
  COOKIES: null,
  COOKIE_STORAGE_OPTIONS: { secure: true },
  SESSION_EXPIRATION_TIME: 'sessionExpirationTime',
  USER_INFO: 'userInfo',
  JWT_TOKEN_KEY: 'jwtToken',
  SECONDS_FOR_NOTIFICATION: 60,

  get(key, storageMechanism = Storage.DEFAULT_STORAGE) {
    try {
      if (storageMechanism === Storage.LOCAL && localStorage && localStorage.getItem(key)) {
        const value = localStorage.getItem(key);
        return parseStorageValue(value);
      }

      if (storageMechanism === Storage.SESSION && sessionStorage && sessionStorage.getItem(key)) {
        const value = sessionStorage.getItem(key);
        return parseStorageValue(value);
      }

      if (storageMechanism === Storage.COOKIE && Storage.COOKIES && Storage.COOKIES.get(key)) {
        const value = Storage.COOKIES.get(key);
        return parseStorageValue(value);
      }
    } catch (e) {
      ErrorHandler.capture(e);
    }

    return null;
  },

  set(value, key, storageMechanism = Storage.DEFAULT_STORAGE) {
    if (value === null || value === undefined || value.length === 0) {
      return null;
    }

    if (storageMechanism === Storage.LOCAL && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (storageMechanism === Storage.SESSION && sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    if (storageMechanism === Storage.COOKIE) {
      Storage.COOKIES.set(key, stringify(value), {
        ...Storage.COOKIE_STORAGE_OPTIONS,
      });
    }

    return null;
  },

  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  destroy() {
    localStorage.clear();
    sessionStorage.clear();
  },

  getJWTToken(tokenKey = Storage.JWT_TOKEN_KEY) {
    return Storage.get(tokenKey);
  },

  setJWTToken(
    value = '',
    storageMechanism = Storage.DEFAULT_STORAGE,
    tokenKey = Storage.JWT_TOKEN_KEY,
  ) {
    return Storage.set(value, tokenKey, storageMechanism);
  },

  clearJWTToken(tokenKey = Storage.JWT_TOKEN_KEY) {
    return Storage.clear(tokenKey);
  },

  clearTokenExpirationTime(
    tokenExpirationTimeKey = Storage.SESSION_EXPIRATION_TIME,
    storageMechanism = Storage.LOCAL,
  ) {
    return Storage.clear(tokenExpirationTimeKey, storageMechanism);
  },

  saveTokenExpirationTime(
    value,
    tokenExpirationTimeKey = Storage.SESSION_EXPIRATION_TIME,
    storageMechanism = Storage.LOCAL,
  ) {
    return Storage.set(value, tokenExpirationTimeKey, storageMechanism);
  },

  tokenExpirationUnixTime(
    tokenExpirationTimeKey = Storage.SESSION_EXPIRATION_TIME,
    storageMechanism = Storage.LOCAL,
  ) {
    const token = Storage.getJWTToken();

    if (token) {
      try {
        const decodedToken = jwt.decode(token);
        return decodedToken.exp * 1000;
      } catch (error) {
        return 0;
      }
    }

    return Storage.get(tokenExpirationTimeKey, storageMechanism);
  },

  isValidAuthSession() {
    try {
      const secondsUntilExpiration = this.tokenExpirationSeconds();
      if (secondsUntilExpiration > 0) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  },

  tokenExpirationSeconds() {
    const tokenExpirationUnixTime = Storage.tokenExpirationUnixTime();
    if (!tokenExpirationUnixTime) return -1;

    try {
      return moment.utc(tokenExpirationUnixTime).diff(Date.now(), 'seconds');
    } catch (error) {
      return -1;
    }
  },

  setCookies(cookies) {
    Storage.COOKIES = cookies;
  },
};

export default Storage;
