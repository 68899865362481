import _isEmpty from 'lodash/isEmpty';

export function getStepNumber(settings = {}) {
  const { status = '' } = settings;
  if (_isEmpty(status)) {
    return 0;
  }
  const STEP_BY_STATUS = {
    Pending: 1,
    Rejected: 2,
    Complete: 3,
  };
  return STEP_BY_STATUS[status];
}
