import React from 'react';

import EndDate from './components/EndDate';
import FulfillmentStatusFilter from './components/FulfillmentStatusFilter';
import StartDate from './components/StartDate';

import './OrderReportsFilter.scss';

function OrderReportsFilter({ filters, onChangeFilter }) {
  function onChangeStartDate(e, { value }) {
    onChangeFilter('start_date', value);
  }

  function onChangeEndDate(e, { value }) {
    onChangeFilter('end_date', value);
  }

  function onChangeFulfillmentStatus(e, { value }) {
    onChangeFilter('fulfillment_status', value);
  }

  const {
    start_date: startDate,
    end_date: endDate,
    fulfillment_status: fulfillmentStatus,
  } = filters;

  return (
    <div className="order-reports-filter">
      <StartDate currentValue={startDate} onChange={onChangeStartDate} />
      <EndDate currentValue={endDate} onChange={onChangeEndDate} />
      <FulfillmentStatusFilter
        currentValue={fulfillmentStatus}
        onChange={onChangeFulfillmentStatus}
      />
    </div>
  );
}

export default OrderReportsFilter;
