/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

import FieldToPath from './field-to-path';

const Extract = {
  INSTRUCTION_TYPES: {
    POST: 'POST',
    PUT: 'PUT',
    DEL: 'DELETE',
  },

  PATH_TYPE: {
    CURRENT: null,
    BUSINESS: 'business',
    WEBSITE: 'website',
    USER: 'user',
  },

  DEPENDENCIES: {
    menu_categories: 'menu_items',
  },

  // Path fields
  BUSINESS_FIELDS: { TYPE: null, ID: null },
  WEBSITE_FIELDS: { ID: null },
  USER_FIELDS: { ID: null },

  extractApiInstructions(state) {
    // Set values needed to create API paths
    Extract.__setPathFields(state);

    let apiInstructions = [];
    const stateComponents = Extract._getStateComponents(state);

    Object.keys(stateComponents).forEach((key) => {
      Extract._setCurrentPathType(key);
      apiInstructions = [
        ...apiInstructions,
        ...Extract._extractComponentInstructions(key, stateComponents[key]),
      ];
    });

    const [instructions, dependencies] = Extract._createDependencies(apiInstructions);

    return [instructions, dependencies];
  },

  extractDependencyInstructions(state, dependencies) {
    let apiInstructions = [];

    dependencies.forEach((dep) => {
      Extract._setCurrentPathType(dep[0]);
      const componentKey = dep[0];
      const component = state[componentKey];
      const name = dep[1];
      apiInstructions = [
        ...apiInstructions,
        ...Extract._extractFieldInstructions(component[name], name, componentKey),
      ];
    });

    return apiInstructions;
  },

  _extractComponentInstructions(key, component) {
    let apiInstructions = [];
    const componentFieldNames = Extract._getStateComponentFieldNames(component);

    componentFieldNames.forEach((name) => {
      apiInstructions = [
        ...apiInstructions,
        ...Extract._extractFieldInstructions(component[name], name, key),
      ];
    });

    return apiInstructions;
  },

  _extractFieldInstructions(field, name, parentName) {
    if (!field) return [];

    const type = Extract._getFieldType(field);

    if (type === 'OBJECT') return Extract._extractFromObject(field, name, type, parentName);
    if (type === 'LIST') return Extract._extractFromList(field, name, type, parentName);
    return [];
  },

  _extractFromObject(obj, name, stateType, parentName) {
    const touchedValues = Extract._getTouchedValues(obj);

    if (touchedValues.length > 0) {
      const touchedFieldKeyToOperationMap = {
        colorPalette: Extract.INSTRUCTION_TYPES.POST,
      };
      const type = touchedFieldKeyToOperationMap[name] || Extract.INSTRUCTION_TYPES.PUT;
      const data = Extract._createInstructionData(obj.value, touchedValues);
      const pathData = Extract._createPathData();
      const path = FieldToPath.getPath(Extract.PATH_TYPE.CURRENT, name, type, pathData);
      return [Extract._createInstruction(type, stateType, [parentName, name], path, data)];
    }

    return [];
  },

  _extractFromList(obj, name, stateType, parentName) {
    return [
      ...Extract._extractPutsFromList(obj, name, stateType, parentName),
      ...Extract._extractAdditionsFromList(obj, name, stateType, parentName),
      ...Extract._extractDeletionsFromList(obj, name, stateType, parentName),
    ];
  },

  _extractAdditionsFromList(obj, name, stateType, parentName) {
    const additionIds = Extract._getAdditionIds(obj);

    if (additionIds.length > 0) {
      const type = Extract.INSTRUCTION_TYPES.POST;

      return additionIds
        .filter((id) => obj.value[id] && obj.value[id]._touchedFields.length > 0)
        .map((id) => {
          const value = obj.value[id];
          const data = Extract._cleanListItem(value);
          const pathData = Extract._createPathData(Extract._createPathIds(value.id, name, value));
          const path = FieldToPath.getPath(Extract.PATH_TYPE.CURRENT, name, type, pathData);
          return Extract._createInstruction(type, stateType, [parentName, name, id], path, data);
        });
    }

    return [];
  },

  _extractDeletionsFromList(obj, name, stateType, parentName) {
    const deletionIds = Extract._getDeletionIds(obj);

    if (deletionIds.length > 0) {
      const type = Extract.INSTRUCTION_TYPES.DEL;

      return deletionIds.map((id) => {
        const pathData = Extract._createPathData(Extract._createPathIds(id));
        const path = FieldToPath.getPath(Extract.PATH_TYPE.CURRENT, name, type, pathData);
        return Extract._createInstruction(type, stateType, [parentName, name, id], path);
      });
    }

    return [];
  },

  _extractPutsFromList(obj, name, stateType, parentName) {
    const type = Extract.INSTRUCTION_TYPES.PUT;
    const listItems = obj.value;
    return listItems
      .map((val, index) => ({ ...val, originalIndex: index }))
      .filter(({ id, _touchedFields }) => id && _touchedFields && _touchedFields.length > 0)
      .map((val) => {
        const data = Extract._createInstructionData(val, Extract._getTouchedValues(val));
        const pathData = Extract._createPathData(Extract._createPathIds(val.id, name, val));
        const path = FieldToPath.getPath(Extract.PATH_TYPE.CURRENT, name, type, pathData);
        return Extract._createInstruction(
          type,
          stateType,
          [parentName, name, val.originalIndex],
          path,
          data,
        );
      });
  },

  _createInstruction(type, stateType, stateKeyPath, path = '', data = false) {
    if (data) return { type, path, data, stateKeyPath, stateType };
    return { type, path, stateKeyPath, stateType };
  },

  _createInstructionData(values, valuesToExtract) {
    return valuesToExtract.reduce((acc, key) => ({ ...acc, [key]: values[key] }), {});
  },

  _createPathData(itemIds = []) {
    if (Extract.PATH_TYPE.CURRENT === Extract.PATH_TYPE.BUSINESS) {
      return {
        businessType: Extract.BUSINESS_FIELDS.TYPE,
        businessId: Extract.BUSINESS_FIELDS.ID,
        itemIds,
      };
    }
    if (Extract.PATH_TYPE.CURRENT === Extract.PATH_TYPE.WEBSITE) {
      return {
        websiteId: Extract.WEBSITE_FIELDS.ID,
        itemIds,
      };
    }
    if (Extract.PATH_TYPE.CURRENT === Extract.PATH_TYPE.USER) {
      return {
        userId: Extract.USER_FIELDS.ID,
      };
    }

    return {};
  },

  _createPathIds(id, name = '', obj = {}) {
    if (name.length > 0) {
      if (name === 'menu_items') return [obj.category_id, id];
      return [id];
    }
    return [id];
  },

  _cleanListItem(item) {
    const { _touchedFields, ...rest } = item;
    return Object.keys(rest).reduce((acc, key) => {
      const value = rest[key];
      const isDefined = Extract._isDefined(value, key);
      return isDefined ? { ...acc, [key]: value } : acc;
    }, {});
  },

  _createDependencies(instructions) {
    // Get instructions for independents with dependencies
    const independents = Object.keys(Extract.DEPENDENCIES);
    const instructionKeys = instructions.map(({ stateKeyPath }) => stateKeyPath[1]);
    const independentsWithInstructions = independents.filter((key) =>
      instructionKeys.includes(key),
    );
    const dependents = independentsWithInstructions.map((key) => Extract.DEPENDENCIES[key]);

    const dependencies = instructions
      .filter(({ stateKeyPath }) => dependents.includes(stateKeyPath[1]))
      .map(({ stateKeyPath }) => stateKeyPath.slice(0, 2));
    const uniqueDependencies = [...new Set(dependencies.map((v) => JSON.stringify(v)))].map((v) =>
      JSON.parse(v),
    );

    const independentInstructions = instructions.filter(
      ({ stateKeyPath }) => !dependents.includes(stateKeyPath[1]),
    );

    return [independentInstructions, uniqueDependencies];
  },

  _getStateComponents(state) {
    const { website, business, user } = state;
    return { business, website, user };
  },

  _getStateComponentFieldNames(component) {
    return Object.keys(component);
  },

  _getFieldType(field) {
    return field._type;
  },

  _getTouchedValues(obj) {
    return obj._touchedFields || [];
  },

  _getAdditionIds(obj) {
    return obj._newItemIds || [];
  },

  _getDeletionIds(obj) {
    return obj._deletedItemIds || [];
  },

  __setPathFields(state) {
    const { id: businessId, type } = state.business.core.value;
    const { id: websiteId } = state.website.core.value;
    const { id: userId } = state.user.core.value;

    Extract.BUSINESS_FIELDS.TYPE = type;
    Extract.BUSINESS_FIELDS.ID = businessId;
    Extract.WEBSITE_FIELDS.ID = websiteId;
    Extract.USER_FIELDS.ID = userId;
  },

  _setCurrentPathType(type) {
    Extract.PATH_TYPE.CURRENT = type;
  },

  _isDefined: (value, name = '') => {
    if (name === 'file' || name === 'logo' || name === 'avatar') return value;
    if (name === 'image') return value.url || value.file;
    if (_.isObjectLike(value)) return Object.keys(value).length > 0;
    if (_.isArray(value) || _.isString(value)) return value.length > 0;
    if (_.isNumber(value)) return value;
    if (_.isBoolean(value)) return true;
    return value === true;
  },
};

export default Extract;
