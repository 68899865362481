import React from 'react';

import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import NodeSearch from './components/NodeSearch';

import './NodeControls.scss';

const propTypes = {
  recipePartial: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  setSelectedNodePath: PropTypes.func.isRequired,
  selectedNodePath: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  selectedNodePath: [],
};

function NodeControls({ recipePartial, setSelectedNodePath, selectedNodePath }) {
  return (
    <div className="node-controls-container">
      <NodeSearch recipePartial={recipePartial} onResultSelect={setSelectedNodePath} />
      {!_isEmpty(selectedNodePath) && (
        <div className="btn-clear-selection">
          <button
            onClick={() => setSelectedNodePath(null, { result: { value: '' } })}
            type="button"
          >
            clear selection
          </button>
        </div>
      )}
    </div>
  );
}

NodeControls.propTypes = propTypes;
NodeControls.defaultProps = defaultProps;

export default NodeControls;
