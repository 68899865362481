import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Icon, Confirm, Button } from 'semantic-ui-react';

import { CONFIRM_DELETE_ICON_HEADER } from './ConfirmDeleteIcon.constants';
import { getErrorMessage } from '../../../../../libs/errors';

import './ConfirmDeleteIcon.scss';

const propTypes = {
  onDelete: PropTypes.func,
  confirmDelete: PropTypes.bool,
  confirmDeleteHeader: PropTypes.string,
};

const defaultProps = {
  onDelete: null,
  confirmDelete: false,
  confirmDeleteHeader: CONFIRM_DELETE_ICON_HEADER,
};

export default function ConfirmDeleteIcon({ onDelete, confirmDelete, confirmDeleteHeader }) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  if (!onDelete) {
    return null;
  }

  async function handleOnDelete() {
    setLoading(true);
    setErrorMessage(null);
    try {
      await onDelete();
      setShowConfirmDelete(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrorMessage(getErrorMessage(e));
    }
  }

  async function handleIconClick() {
    setShowConfirmDelete(true);
    if (confirmDelete) {
      setShowConfirmDelete(true);
    } else {
      await handleOnDelete();
    }
  }

  return (
    <>
      <Icon
        onClick={handleIconClick}
        name="trash alternate outline"
        size="large"
        className="delete-icon"
      />
      {confirmDelete && (
        <Confirm
          className="confirm-delete-icon-modal"
          open={showConfirmDelete}
          onCancel={() => {
            setShowConfirmDelete(false);
          }}
          onConfirm={handleOnDelete}
          dimmer="inverted"
          header={confirmDeleteHeader}
          content={errorMessage}
          cancelButton={<Button disabled={loading}>Cancel</Button>}
          confirmButton={<Button loading={loading}>Delete</Button>}
        />
      )}
    </>
  );
}

ConfirmDeleteIcon.propTypes = propTypes;
ConfirmDeleteIcon.defaultProps = defaultProps;
