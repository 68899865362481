import React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { selectActiveLocation } from '../../../../../../../../../../../selectors';
import CloseableModal from '../../../../../../../../../../common/CloseableModal';

import './OrderCutoffSwitchModal.scss';

const propTypes = {
  handleConfirmation: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
};

export default function OrderCutoffSwitchModal({ handleConfirmation, onClose, isOpen }) {
  const globalActiveLocation = useSelector(selectActiveLocation);
  const isLocationSelected = !_isEmpty(globalActiveLocation);

  return (
    <CloseableModal
      className="order-cutoff-switch-modal"
      onClose={onClose}
      open={isOpen}
      header="Stop accepting orders?"
      size="tiny"
    >
      <Modal.Content scrolling>
        <div>
          {!isLocationSelected ? (
            <p>
              By selecting this option, ordering will be <b>disabled at all of your locations</b>.
              Are you sure you want to do this?
            </p>
          ) : (
            <p>
              By selecting this option, no orders will be accepted at{' '}
              <b>
                {globalActiveLocation.street}, {globalActiveLocation.state}
              </b>
              . Are you sure you want to do this?
            </p>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button floated="left" basic color="red" onClick={onClose} content="Never Mind" />
        <Button
          className="order-cutoff-switch-confirm-button"
          floated="right"
          onClick={handleConfirmation}
          content="Confirm"
        />
      </Modal.Actions>
    </CloseableModal>
  );
}

OrderCutoffSwitchModal.propTypes = propTypes;
OrderCutoffSwitchModal.defaultProps = defaultProps;
