import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useFlags } from 'launchdarkly-react-client-sdk';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import { useDispatch, useSelector } from 'react-redux';

import { selectBusinessFiles } from 'selectors/business';

import {
  updateBusinessFile,
  addBusinessFile,
  deleteBusinessFile,
  flagBusinessSaved,
  initializeBusinessFiles,
} from '../../../../../actions/business';
import API from '../../../../../libs/api';
import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors';
import { selectBusiness } from '../../../../../selectors';
import FileModal from '../../../../core/FileManagement/FileModal';
import FilesManagementModal from '../../../../core/FilesManagementModal/FilesManagementModal';
import { ImagePropType } from '../../../custom-pages/types/image-block.types';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';
import FileEditorModal from '../FileEditorModal/FileEditorModal';

const propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  initialSelectedFiles: PropTypes.arrayOf(ImagePropType),
  minFilesAllowed: PropTypes.number,
  maxFilesAllowed: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  actionButtonText: PropTypes.string,
  uploadSourceType: PropTypes.string,
};

const defaultProps = {
  minFilesAllowed: null,
  initialSelectedFiles: [],
  actionButtonText: 'Save',
  uploadSourceType: '',
};

export default function FilesManagementModalProvider({
  open,
  setOpen,
  initialSelectedFiles,
  minFilesAllowed,
  maxFilesAllowed,
  onSubmit,
  actionButtonText,
  uploadSourceType,
}) {
  const { advancedImageEditing } = useFlags();

  const businessFiles = useSelector(selectBusinessFiles);
  const [openFileModal, setOpenFileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isImageSaving, setIsImageSaving] = useState(false);
  const [isImageDeleting, setIsImageDeleting] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(initialSelectedFiles);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState();

  const { id: businessId, type: businessType } = useSelector(selectBusiness);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedFiles(initialSelectedFiles);
  }, [initialSelectedFiles]);

  function toggleImagesModalOpen() {
    setOpen(!open);
  }

  function toggleFileModalOpen() {
    setOpenFileModal(!openFileModal);
  }

  async function getBusinessFiles() {
    try {
      setIsLoading(true);
      const { data } = await API.getBusinessFiles(businessType, businessId, {
        search: searchValue,
        order_by: '-created_at',
      });
      dispatch(initializeBusinessFiles(data));
    } catch (e) {
      ErrorHandler.capture(e);
      setError(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  }

  function onFileClick(file) {
    const { id: fileId } = file;

    if (_isEmpty(selectedFiles)) {
      setSelectedFiles([file]);
      return;
    }

    const fileIndex = selectedFiles.findIndex(({ id: currentId }) => currentId === fileId);

    if (fileIndex === -1) {
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
      return;
    }

    setSelectedFiles((prevSelectedFiles) => {
      return prevSelectedFiles.filter(({ id: currentId }) => currentId !== fileId);
    });
  }

  async function onFileSearch(e, target) {
    const { value = '' } = target || {};
    setSearchValue(value);
    setIsSearching(true);
    await getBusinessFiles();
    setIsSearching(false);
  }

  function onFileEdit(file) {
    const { id: fileId } = file;
    const index =
      businessFiles && businessFiles.findIndex(({ id: currentId }) => currentId === fileId);
    const businessFile = index !== false && index >= 0 && { ...businessFiles[index], index };

    setSelectedFile(businessFile);
    toggleFileModalOpen();
    dispatch(flagBusinessSaved(true));
  }

  async function addBusinessFiles(filesPayload) {
    const filesResults = await Promise.all(
      filesPayload.map((payload) => API.createBusinessFile(businessType, businessId, payload)),
    );
    filesResults.map(({ data }) => dispatch(addBusinessFile(data)));
    await getBusinessFiles();
    dispatch(flagBusinessSaved(true));
  }

  async function onFileUpload(files) {
    setIsLoading(true);

    const filesPayload = files.map((file) => ({
      file,
      filename: file.name,
      description: '',
      source: uploadSourceType,
    }));

    try {
      await addBusinessFiles(filesPayload);
      dispatch(flagBusinessSaved(true));
    } catch (e) {
      ErrorHandler.capture(e);
      setError(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  }

  async function onSaveFile(file) {
    setIsImageSaving(true);

    try {
      const { id, index } = file;
      const { data } = await API.updateBusinessFile(
        businessType,
        businessId,
        id,
        _pick(file, ['filename', 'description', 'file']),
      );
      dispatch(
        updateBusinessFile({
          index,
          fields: data,
        }),
      );

      setOpenFileModal(false);
      dispatch(flagBusinessSaved(true));
    } catch (e) {
      ErrorHandler.capture(e);
      setError(getErrorMessage(e));
    } finally {
      setIsImageSaving(false);
    }
  }

  async function onDeleteFile(file) {
    setIsImageDeleting(true);

    try {
      const { id, index } = file;
      await API.deleteBusinessFile(businessType, businessId, id);
      dispatch(deleteBusinessFile(index));

      await getBusinessFiles();
      setOpenFileModal(false);
      dispatch(flagBusinessSaved(true));
    } catch (e) {
      ErrorHandler.capture(e);
      setError(getErrorMessage(e));
    } finally {
      setIsImageDeleting(false);
    }
  }

  useAsyncEffect(getBusinessFiles, []);

  async function handleSubmit() {
    setIsSubmitting(true);
    try {
      await onSubmit(selectedFiles);
      setSelectedFiles([]);
      toggleImagesModalOpen();
    } catch (e) {
      ErrorHandler.capture(e);
      setError(getErrorMessage(e));
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <FilesManagementModal
        open={open}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        onClose={toggleImagesModalOpen}
        files={businessFiles}
        selectedFiles={selectedFiles}
        minFilesAllowed={minFilesAllowed}
        maxFilesAllowed={maxFilesAllowed}
        onFileClick={onFileClick}
        onFileSearch={onFileSearch}
        onFileEdit={onFileEdit}
        onSubmit={handleSubmit}
        searchValue={searchValue}
        isSearching={isSearching}
        onUpload={onFileUpload}
        setError={setError}
        error={error}
        actionButtonText={actionButtonText}
      />
      {openFileModal && !advancedImageEditing && (
        <FileModal
          open={openFileModal}
          selectedFile={selectedFile}
          onCloseModal={toggleFileModalOpen}
          onSave={onSaveFile}
          onDelete={onDeleteFile}
          isSaving={isImageSaving}
          isDeleting={isImageDeleting}
        />
      )}
      {openFileModal && advancedImageEditing && selectedFile && (
        <FileEditorModal
          open={openFileModal}
          onClose={toggleFileModalOpen}
          businessFile={selectedFile}
        />
      )}
    </>
  );
}

FilesManagementModalProvider.propTypes = propTypes;
FilesManagementModalProvider.defaultProps = defaultProps;
