import { v4 as uuid } from 'uuid';

import { DEFAULT_HTML_ELEMENT } from './AddHtlElementButton.constants';

export function createDefaultItem(websiteId) {
  const localId = uuid();
  const item = {
    ...DEFAULT_HTML_ELEMENT,
    localId,
    website: websiteId,
  };
  return item;
}
