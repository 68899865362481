import React, { useContext } from 'react';

import { Tab } from 'semantic-ui-react';

import { getTileId } from '../../../../services/tile';
import { CustomHeroFormContext } from '../../CustomHeroForm.context';
import TileConfiguration from '../TileConfiguration';

import './TileConfigurationTabs.scss';

const propTypes = {};

const defaultProps = {};

export default function TileConfigurationTabs() {
  const { customHero, activeTileIndex, setActiveTileIndex } = useContext(CustomHeroFormContext);
  const { tiles = [] } = customHero;

  function handleTabChange(e, { activeIndex }) {
    setActiveTileIndex(activeIndex);
  }

  const panes = tiles.map((tile, index) => ({
    menuItem: `Tile ${index + 1}`,
    render: () => (
      <Tab.Pane attached={false} key="hero">
        <TileConfiguration key={getTileId(tile)} tile={tile} tileIndex={index} />
      </Tab.Pane>
    ),
  }));

  return (
    <Tab
      className="tile-configuration-tabs"
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      activeIndex={activeTileIndex}
      onTabChange={handleTabChange}
    />
  );
}

TileConfigurationTabs.propTypes = propTypes;
TileConfigurationTabs.defaultProps = defaultProps;
