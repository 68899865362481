import React from 'react';

import PropTypes from 'prop-types';

import './MainContentContainer.scss';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]).isRequired,
};

export default function MainContentContainer({ children }) {
  return <div className="ecommerce-main-content-container">{children}</div>;
}

MainContentContainer.propTypes = propTypes;
