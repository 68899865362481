import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { ChildrenPropType } from 'libs/proptypes';

import { ActionsInputContextProvider } from './ActionsInput.context';
import { Actions, Input, Message } from './components';
import { findChildElement } from '../../../foundation/services/children';

import './ActionsInput.scss';

const propTypes = {
  children: ChildrenPropType.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

export default function ActionsInput({ children, className }) {
  const InputElement = findChildElement(children, Input.name);
  const MessageElement = findChildElement(children, Message.name);
  const ActionsElement = findChildElement(children, Actions.name);

  const classNames = clsx('actions-input', className);

  return (
    <ActionsInputContextProvider value={{ input: InputElement }}>
      <div className={classNames}>
        {InputElement && <div className="input-container">{InputElement}</div>}
        {MessageElement && <div className="message-container">{MessageElement}</div>}
        {ActionsElement && <div className="actions-container">{ActionsElement}</div>}
      </div>
    </ActionsInputContextProvider>
  );
}

ActionsInput.propTypes = propTypes;
ActionsInput.defaultProps = defaultProps;

ActionsInput.Actions = Actions;
ActionsInput.Input = Input;
ActionsInput.Message = Message;
