import React from 'react';

import PropTypes from 'prop-types';

import { DEFAULT_MESSAGE } from './MaintenanceWindowPage.constants';
import LoginBanner from '../../core/LoginBanner';

import './MaintenanceWindowPage.scss';

const propTypes = {
  message: PropTypes.string,
};

const defaultProps = {
  message: DEFAULT_MESSAGE,
};

export default function MaintenanceWindowPage({ message }) {
  return (
    <div className="maintenance-window-page">
      <LoginBanner />
      <div className="maintenance-window-container">
        <p>{message}</p>
      </div>
    </div>
  );
}

MaintenanceWindowPage.propTypes = propTypes;
MaintenanceWindowPage.defaultProps = defaultProps;
