import Utils from '../../../../../../../libs/utils';

export function sortTeamByIndex(teams, oldIndex, newIndex) {
  if (oldIndex === newIndex) {
    return teams.map((team) => ({ ...team, needsUpdate: false }));
  }

  return Utils.moveListItem(teams, oldIndex, newIndex, true).map((team, index) => ({
    ...team,
    order: index,
    needsUpdate: team.order !== index,
  }));
}

export async function updateTeamsOrder(teams, updateTeamFunction) {
  await Promise.all(
    teams.map(async ({ id: teamId, order, needsUpdate }) => {
      if (needsUpdate) {
        return updateTeamFunction(teamId, { order });
      }
      return Promise.resolve();
    }),
  );
}
