import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Segment, Label, Grid } from 'semantic-ui-react';

import ErrorHandler from '../../../../../../../libs/errors';
import { ObjectPropType } from '../../../../../../../libs/proptypes';
import CodeEditorModal from '../../../../../components/CodeEditorModal';
import {
  JSON_KEY,
  CSS_KEY,
} from '../../../../../components/CodeEditorModal/CodeEditorModal.constants';
import { getPartialFileContent } from '../../../../../partials/PartialPage/PartialPage.utils';
import PartialActions from '../../../../../partials/components/PartialActions';

import './PartialCard.scss';

const propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tags: ObjectPropType,
  onCopyToClipboard: PropTypes.func.isRequired,
};

const defaultProps = {
  tags: [],
};

function PartialCard({ id, type, name, tags, onCopyToClipboard }) {
  const [modalEditorOpened, setModalEditorOpened] = useState(false);
  const [isPartialLoading, setIsPartialLoading] = useState(false);
  const [partialContent, setPartialContent] = useState({});

  function getEditorMode() {
    return type.toLowerCase() !== CSS_KEY ? JSON_KEY : CSS_KEY;
  }

  async function handleViewPartial() {
    setModalEditorOpened(true);
    setIsPartialLoading(true);
    await getPartialFileContent(
      id,
      (partial) => {
        setIsPartialLoading(false);
        setPartialContent(partial);
      },
      (err) => {
        setIsPartialLoading(false);
        setModalEditorOpened(false);
        ErrorHandler.capture(err);
      },
    );
  }

  function handleModalCanceled() {
    setPartialContent(null);
    setModalEditorOpened(false);
  }

  return (
    <>
      <Segment padded raised className="card-container" onClick={() => handleViewPartial()}>
        <div className="card-type">
          <Label size="tiny">{type}</Label>
        </div>
        <Grid columns="equal" verticalAlign="middle">
          <Grid.Column width={8} floated="left">
            <div className="card-title">{name}</div>
            {tags && tags.map((tag) => <Label size="tiny">{tag}</Label>)}
          </Grid.Column>
          <Grid.Column width={3} floated="right">
            <PartialActions>
              <PartialActions.Item
                icon="copy"
                text="Copy to clipboard..."
                onClick={onCopyToClipboard}
              />
            </PartialActions>
          </Grid.Column>
        </Grid>
      </Segment>

      {partialContent && (
        <CodeEditorModal
          open={modalEditorOpened}
          mode={getEditorMode()}
          onSave={() => {}}
          isLoading={isPartialLoading}
          initialValue={partialContent}
          onCancel={handleModalCanceled}
          readOnly
        />
      )}
    </>
  );
}

PartialCard.propTypes = propTypes;
PartialCard.defaultProps = defaultProps;

export default PartialCard;
