import React, { useContext } from 'react';

import { Icon } from 'semantic-ui-react';

import Validate from '../../../../../../../libs/validate';
import { PagePropType } from '../../../../../pages/types/page';
import { NavigationContext } from '../../../../context/Navigation.context';

import './NavigationItemUrlCheck.scss';

const propTypes = { page: PagePropType.isRequired };
const defaultProps = {};

export default function NavigationItemUrlCheck({ page }) {
  const { navigation } = useContext(NavigationContext);
  const { getPageUrl } = navigation;

  const { href } = getPageUrl(page);

  const isValidUrl = Validate.validate(Validate.TYPES.LINK, href);
  const copy = isValidUrl ? 'Valid URL' : 'Invalid URL';

  return (
    <div className="navigation-item-url-check">
      <span>{copy}</span>
      <Icon name={isValidUrl ? 'check circle' : 'remove circle'} />
    </div>
  );
}

NavigationItemUrlCheck.propTypes = propTypes;
NavigationItemUrlCheck.defaultProps = defaultProps;
