export const MAX_INPUT_LENGTH = 65;
export const MAX_INPUT_LENGTH_WARNING = 60;
export const MIN_INPUT_LENGTH_WARNING = 30;
export const LENGTH_BUFFER = 5;

export const HELP_TOOLTIP_MESSAGE = `A concise headline with the main keywords within ${MAX_INPUT_LENGTH} characters. If left empty, the default placeholder value is used.`;

export const NO_APPENDIX_VALUE = 'No Appendix';

export const TITLE_TOO_SHORT_WARNING_MESSAGE = `Your title is too short according to best practices. Min length is ${MIN_INPUT_LENGTH_WARNING} characters.`;
export const TITLE_TOO_LONG_WARNING_MESSAGE = `Your title is too long according to best practices. Max length is ${MAX_INPUT_LENGTH_WARNING} characters.`;

export const HIDE_WARNINGS_TIMEOUT = 8000;
export const DEFAULT_PLACEHOLDER = 'SEO Title';
export const DEFAULT_NAME = 'seoTitle';
