import React from 'react';

import PropTypes from 'prop-types';
import { Image, List } from 'semantic-ui-react';

import { SPECIAL_INSTRUCTIONS_LABEL, MODIFIERS_LABEL } from './LineItemDetails.constants';
import { calculateUnitPriceWithQuantity, formatModifiers } from './LineItemDetails.utils';
import Currency from '../../../../../../../libs/currency';

import './LineItemDetails.scss';

const propTypes = {
  name: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  price: PropTypes.string.isRequired,
  instructions: PropTypes.string,
  photo: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  instructions: '',
  photo: '',
  modifiers: [],
};

export default function LineItemDetails({ name, quantity, price, instructions, photo, modifiers }) {
  return (
    <List.Item className="order-detail-line-item">
      {photo && <Image src={photo} />}
      <List.Content>
        <List.Header>
          <div>
            {quantity}x - {name}
          </div>
          <div>{Currency.format(calculateUnitPriceWithQuantity(price, quantity))}</div>
        </List.Header>
        <List.Description>
          {modifiers.length > 0 && (
            <div className="modifiers-detail">
              {MODIFIERS_LABEL}: {formatModifiers(modifiers)}
            </div>
          )}
          {instructions && (
            <div className="special-instructions">
              {SPECIAL_INSTRUCTIONS_LABEL}: {instructions}
            </div>
          )}
        </List.Description>
      </List.Content>
    </List.Item>
  );
}

LineItemDetails.propTypes = propTypes;
LineItemDetails.defaultProps = defaultProps;
