import React, { useState } from 'react';

import { Segment, Container, Message, Button } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _without from 'lodash/without';
import { useDispatch } from 'react-redux';

import { REAUTHORIZE_MESSAGE } from './FacebookIntegrationSync.constants';
import FacebookIntegrationSettings from './components/FacebookIntegrationSettings/FacebookIntegrationSettings';
import {
  createBusinessCore,
  initializeLocations,
  flagBusinessSaved,
} from '../../../../../../actions/business';
import API from '../../../../../../libs/api';
import { authRedirectFor } from '../../../../../../libs/auth';
import { getErrorMessage } from '../../../../../../libs/errors';
import { BusinessPropType } from '../../../../../../libs/proptypes';
import InformationModal from '../../../../../common/InformationModal/InformationModal';
import useAsyncEffect from '../../../../../modules/foundation/hooks/use-async-effect';

import './FacebookIntegrationSync.scss';

const propTypes = {
  business: BusinessPropType.isRequired,
};

export default function FacebookIntegrationSync({ business }) {
  const [error, setError] = useState(false);
  const [modalText, setModalText] = useState('');
  const [displayModal, setDisplayModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);

  const dispatch = useDispatch();

  const { id: businessId, type: businessType } = business;

  useAsyncEffect(async () => {
    const { data = {} } = await API.getFacebookIntegrationSettings(businessId, businessType);
    const { features = [] } = data;
    setFields(features);
  }, []);

  function closeModal() {
    setDisplayModal(false);
  }

  function connect(event) {
    event.preventDefault();
    authRedirectFor('facebook', { queryParams: `?auth_params=${businessType}_${businessId}` });
  }

  async function sync() {
    setError(false);
    setLoading(true);
    try {
      const { data: newBusiness } = await API.facebookIntegrationSync(businessId, businessType, {
        fields,
      });
      setModalText(
        'We’ve successfully imported your information from Facebook! ' +
          'In order for the changes to appear on your website you’ll need to click on the Publish.',
      );
      const { locations } = newBusiness;

      dispatch(createBusinessCore(newBusiness));
      dispatch(initializeLocations(locations));
      dispatch(flagBusinessSaved(true));
    } catch (e) {
      const reauthorize = _get(e, 'response.data.reauthorize', false);
      const message = reauthorize ? REAUTHORIZE_MESSAGE : getErrorMessage(e);
      setModalText(message);
      setError(true);
    }
    setDisplayModal(true);
    setLoading(false);
  }

  function onSettingFieldChange(event, { name, checked }) {
    setFields((previousFields) => {
      if (checked) {
        return [...previousFields, name];
      }
      return _without(previousFields, name);
    });
  }

  return (
    <Segment className="facebook-integration-authorize">
      <InformationModal
        text={modalText}
        open={displayModal}
        onClose={closeModal}
        header={error ? 'Error' : 'Success!'}
        extraButtons={
          modalText === REAUTHORIZE_MESSAGE
            ? [<Button onClick={connect} primary content="Reauthorize" />]
            : []
        }
      />
      <Message>
        <Message.Header>Sync your Facebook Page with your Fisherman Website</Message.Header>
        <p>
          Click the button below to import information from your Facebook Page to your Fisherman
          Website. Choose which information to pull such as your Business Description, Phone Number,
          and more to maintain consistency across your online presence.
        </p>
      </Message>
      <FacebookIntegrationSettings onChange={onSettingFieldChange} checkedFields={fields} />
      <Container textAlign="center">
        <Button
          size="big"
          primary
          onClick={sync}
          content="Save & Import"
          loading={loading}
          disabled={loading || _isEmpty(fields)}
        />
      </Container>
    </Segment>
  );
}

FacebookIntegrationSync.propTypes = propTypes;
