import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import './BusinessPlanBody.scss';

export default function BusinessPlanBody({ children }) {
  return (
    <div>
      <Grid columns={children.length} stackable>
        <Grid.Row>
          {children.map((child) => {
            return (
              <Grid.Column className="business-plan-body-column" key={child.key}>
                {child}
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    </div>
  );
}

BusinessPlanBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
