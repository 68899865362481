import React, { useContext } from 'react';

import { Form } from 'semantic-ui-react';

import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

export default function BannerTitle() {
  const { title, setValue } = useContext(AnnouncementFormContext);

  return (
    <Form.Input label="Title" value={title} onChange={(e, { value }) => setValue('title', value)} />
  );
}
