import gridPartial from '../../__tests__/__mocks__/grid-partial.json';
import pagePartial from '../../__tests__/__mocks__/page-partial.json';
import partialRules from '../../__tests__/__mocks__/partial-rules.json';
import componentTemplate from '../../templates/component-template.json';
import conditionTemplate from '../../templates/condition-template.json';
import functionTemplate from '../../templates/function-template.json';
import functionargumentTemplate from '../../templates/functionargument-template.json';
import layoutTemplate from '../../templates/layout-template.json';
import pageTemplate from '../../templates/page-template.json';
import pluginTemplate from '../../templates/plugin-template.json';
import pluginoptionTemplate from '../../templates/pluginoption-template.json';
import propTemplate from '../../templates/prop-template.json';
import templateRules from '../../templates/template-rules.json';

export function getPartial(partialName) {
  const partialMap = {
    'page-partial.json': pagePartial,
    'grid-partial.json': gridPartial,
  };
  return partialMap[partialName];
}

export function getPartialTypes(parentFieldName) {
  return Object.keys(partialRules)
    .filter((objectType) => {
      const { parentFieldNames } = partialRules[objectType];
      return parentFieldNames.includes(parentFieldName);
    })
    .map((objectType) => objectType);
}

export function getPartialOptions(partialTypes) {
  return partialTypes.reduce((partials, objectType) => {
    return [...partials, ...partialRules[objectType].partials];
  }, []);
}

export function getTemplate(templateName) {
  const templateMap = {
    'component-template.json': componentTemplate,
    'condition-template.json': conditionTemplate,
    'function-template.json': functionTemplate,
    'functionargument-template.json': functionargumentTemplate,
    'layout-template.json': layoutTemplate,
    'page-template.json': pageTemplate,
    'plugin-template.json': pluginTemplate,
    'pluginoption-template.json': pluginoptionTemplate,
    'prop-template.json': propTemplate,
  };
  return templateMap[templateName];
}

export function getTemplateTypes(parentFieldName) {
  return Object.keys(templateRules)
    .filter((objectType) => {
      const { parentFieldNames } = templateRules[objectType];
      return parentFieldNames.includes(parentFieldName);
    })
    .map((objectType) => objectType);
}

export function getTemplateOptions(templateTypes) {
  return templateTypes.reduce((templates, objectType) => {
    return [...templates, ...templateRules[objectType].templates];
  }, []);
}
