import _isEmpty from 'lodash/isEmpty';

import {
  REQUIRED_PERMISSIONS,
  PERMISSIONS_AUTHORIZE_DESCRIPTION,
  REAUTHORIZE,
  NUMBER_OF_PAGES_AUTHORIZE_DESCRIPTION,
} from './FacebookIntegration.constants';

export function arePermissionsValid(permissions = []) {
  return REQUIRED_PERMISSIONS.every((permission) => permissions.includes(permission));
}

export function getStepNumber(socialToken) {
  if (_isEmpty(socialToken)) {
    return 0;
  }
  const { created = false, number_of_pages: numberOfPages = 0, permissions = [] } = socialToken;
  if (!created || numberOfPages !== 1 || !arePermissionsValid(permissions)) {
    return 0;
  }
  return 1;
}

export function getAuthorizeTexts(socialToken = {}) {
  const { created = false } = socialToken || {};
  if (!_isEmpty(socialToken) && created) {
    const { number_of_pages: numberOfPages = 0, permissions = [] } = socialToken;
    if (numberOfPages !== 1) {
      return [REAUTHORIZE, NUMBER_OF_PAGES_AUTHORIZE_DESCRIPTION];
    }
    if (!arePermissionsValid(permissions)) {
      return [REAUTHORIZE, PERMISSIONS_AUTHORIZE_DESCRIPTION];
    }
  }
  return [undefined, undefined];
}
