import {
  TEXT_BLOCK_ELEMENT_TYPE,
  IMAGE_BLOCK_ELEMENT_TYPE,
  EMPTY_BLOCK_ELEMENT_TYPE,
  LEGACY_FORM_BLOCK_ELEMENT_TYPE,
  JSON_BLOCK_ELEMENT_TYPE,
  FORM_BLOCK_ELEMENT_TYPE,
} from '../../constants/types';

export const ELEMENT_TYPE_ICON = {
  [TEXT_BLOCK_ELEMENT_TYPE]: 'file alternate outline',
  [IMAGE_BLOCK_ELEMENT_TYPE]: 'images outline',
  [EMPTY_BLOCK_ELEMENT_TYPE]: 'question circle outline',
  [LEGACY_FORM_BLOCK_ELEMENT_TYPE]: 'wpforms',
  [FORM_BLOCK_ELEMENT_TYPE]: 'wpforms',
  [JSON_BLOCK_ELEMENT_TYPE]: 'file code outline',
};

export const ELEMENT_TYPE_LABEL = {
  [TEXT_BLOCK_ELEMENT_TYPE]: 'Rich-text Block',
  [IMAGE_BLOCK_ELEMENT_TYPE]: 'Image(s) Block',
  [EMPTY_BLOCK_ELEMENT_TYPE]: 'Empty Block',
  [LEGACY_FORM_BLOCK_ELEMENT_TYPE]: 'Form Block',
  [FORM_BLOCK_ELEMENT_TYPE]: 'Form Block',
  [JSON_BLOCK_ELEMENT_TYPE]: 'JSON Block',
};

export const ACTION_NAMES = {
  onAddColumnLeft: 'onAddColumnLeft',
  onAddColumnRight: 'onAddColumnRight',
  onAddRowAbove: 'onAddRowAbove',
  onAddRowBelow: 'onAddRowBelow',
  onDeleteColumn: 'onDeleteColumn',
  onDeleteRow: 'onDeleteRow',
};
