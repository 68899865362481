export const CONFIRMATION_MESSAGE = 'Are you sure?';
export const EMAIL_TRIGGERS = [
  { name: 'preview', iconName: 'eye', triggerText: 'Send Preview Email' },
  {
    name: 'billing_link',
    iconName: 'money bill alternate outline',
    triggerText: 'Send Billing Email',
  },
  {
    name: 'welcome',
    iconName: 'smile outline',
    triggerText: 'Send Welcome Email',
  },
  {
    name: 'domain_instructions',
    iconName: 'book',
    triggerText: 'Send Domain Instructions Email',
  },
  {
    name: 'google_business_profile',
    iconName: 'briefcase',
    triggerText: 'Send Google Business Profile Email',
  },
];
