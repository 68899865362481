import React, { useContext, useState, useEffect } from 'react';

import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import AceEditor from 'react-ace';

import { JSON_BLOCK_ELEMENT_TYPE } from '../../../../constants/types';
import { JsonBlockPropType } from '../../../../types/json.types';
import { EditPageElementModalContext } from '../../EditPageElementModal.context';

import './EditJsonBlock.scss';

const propTypes = {
  element: JsonBlockPropType.isRequired,
};

const defaultProps = {};

export default function EditJsonBlock({ element }) {
  const { snippet = {} } = element;
  const { onUpdateElementState, addValidationError, clearValidationErrors } = useContext(
    EditPageElementModalContext,
  );

  const [parsingError, setParsingError] = useState(false);

  useEffect(() => {
    clearValidationErrors();
    if (_isEmpty(Object.keys(snippet))) {
      addValidationError('Snippet must not be empty.');
    }
  }, [snippet]);

  function onChange(value) {
    setParsingError(false);

    try {
      const parsedJSON = JSON.parse(value);
      onUpdateElementState(JSON_BLOCK_ELEMENT_TYPE, {
        ...element,
        snippet: parsedJSON,
      });
    } catch {
      setParsingError(true);
    }
  }

  return (
    <div className="custom-pages-edit-json-block">
      <AceEditor
        mode="json"
        theme="solarized_light"
        className="json-block-editor"
        onChange={_debounce(onChange, 1000)}
        fontSize={14}
        showPrintMargin
        showGutter
        highlightActiveLine
        value={JSON.stringify(snippet, null, 2)}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      {parsingError && (
        <div className="parsing-error">
          There was an error parsing your JSON. Please check your syntax and try again.
        </div>
      )}
    </div>
  );
}

EditJsonBlock.propTypes = propTypes;
EditJsonBlock.defaultProps = defaultProps;
