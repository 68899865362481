import React from 'react';

import { Header, Icon, Segment } from 'semantic-ui-react';

import './ConfirmationPage.scss';

export default function ConfirmationPage({ icon, subject, content, actionContent, iconColor }) {
  return (
    <Segment className="confirmation-container">
      <div className="confirmation-page">
        {iconColor ? (
          <Icon name={icon} size="huge" className="email-icon" color={iconColor} />
        ) : (
          <Icon name={icon} size="huge" className="email-icon" />
        )}
        <Header as="h2" textAlign="center">
          {subject}
        </Header>
        <div className="instruction">{content}</div>
        <div>{actionContent}</div>
      </div>
    </Segment>
  );
}
