import React from 'react';

import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';

import ExternalAnchor from '../../../../../../../../common/ExternalAnchor';
import { ImagePropType } from '../../../../../../types/image-block.types';

import './UploadedImageList.scss';

const propTypes = {
  images: PropTypes.arrayOf(ImagePropType),
  onDeleteImage: PropTypes.func,
  numDisplay: PropTypes.number,
};

const defaultProps = {
  images: [],
  onDeleteImage: null,
  numDisplay: null,
};

export default function UploadedImageList({ images, onDeleteImage, numDisplay }) {
  if (_isEmpty(images)) {
    return null;
  }

  const finalNumDisplay = numDisplay || images.length;

  return (
    <List className="uploaded-image-list">
      {images.slice(0, finalNumDisplay).map(({ id, file, filename }) => (
        <List.Item key={id}>
          <div>
            <ExternalAnchor href={file}>{filename}</ExternalAnchor>
          </div>
          {onDeleteImage && (
            <div>
              <FontAwesomeIcon
                className="icon"
                icon={faXmark}
                size="lg"
                onClick={() => onDeleteImage(id)}
              />
            </div>
          )}
        </List.Item>
      ))}
    </List>
  );
}

UploadedImageList.propTypes = propTypes;
UploadedImageList.defaultProps = defaultProps;
