import React from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Icon, Message } from 'semantic-ui-react';

import _ from 'lodash';

import AddressVerificationModal from './AddressVerificationModal';
import API from '../../../libs/api';
import ErrorHandler from '../../../libs/errors';
import { capitalizeAll } from '../../../libs/strings';
import HelpTooltip from '../HelpTooltip';

import './AddressWithVerification.scss';
import '../../../styles/core/forms/generic.scss';

class AddressWithVerification extends React.Component {
  /**
   * The idea of this component is to let the user verify an address using a
   * backend service. It handles an internal state to let the user write an
   * address and it has a verify method to get a verified address from backend
   * and use it to bubble the verified address to a parent component or a
   * global state like redux.
   */

  static getDerivedStateFromProps(props, state) {
    if (props.addressVerified !== state.isVerified) {
      return { isVerified: props.addressVerified };
    }
    return null;
  }

  static upsDataToFishermanData(upsData) {
    const { address: street, city, state, zip: zipCode, timezone } = upsData;
    return { street, city, state, zip_code: zipCode, timezone };
  }

  constructor(props) {
    super(props);
    const { street, city, state, zip_code: zipCode, addressVerified } = props;
    this.state = {
      isVerified: addressVerified,
      noResultsFound: false,
      isDirty: false,
      modalOpen: false,
      loading: false,
      street,
      city,
      state,
      zip_code: zipCode,
      candidates: [],
      candidateId: null,
    };

    this.handleChange.bind(this);
    this.submitAddressChoice.bind(this);
    this.handleVerify.bind(this);
    this.onModalClose.bind(this);
    this.onModalSelect.bind(this);
    this.onModalSubmit.bind(this);
  }

  handleChange(statePropName) {
    /**
     * This method is used to handle internal state only
     */
    const { onChange, callOnChangeAlways } = this.props;

    return (evt) => {
      const { value } = evt.target;
      this.setState({
        [statePropName]: value,
        isDirty: true,
        isVerified: false,
      });

      if (callOnChangeAlways) {
        const { street, city, state, zip_code: zipCode } = this.state;
        onChange({
          street,
          city,
          state,
          zip_code: zipCode,
          verified_address: false,
          [statePropName]: value,
        });
      }
    };
  }

  handleVerify = async (evt) => {
    evt.preventDefault();
    const { street, city, state, zip_code: zipCode } = this.state;

    const data = {
      address: street,
      city,
      state,
      zip: zipCode,
    };
    this.setState({ loading: true, noResultsFound: false });

    try {
      const response = await API.verifyAddress(data);
      const candidates = _.get(response, 'data.candidates');

      if (response.status === 200 && candidates && candidates.length > 0) {
        const indexedCandidates = candidates.map((candidate, index) => ({
          ...candidate,
          id: index,
        }));

        return this.setState({
          candidates: indexedCandidates,
          modalOpen: true,
        });
      }

      this.setState({
        noResultsFound: true,
      });
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      this.setState({ loading: false });
    }
    return undefined;
  };

  onModalClose = () => this.setState({ modalOpen: false });

  onModalSelect = (id) => this.setState({ candidateId: id });

  onModalSubmit = async (upsData) => {
    try {
      const data = AddressWithVerification.upsDataToFishermanData(upsData);
      await this.submitAddressChoice(data);
    } finally {
      this.setState({ loading: false });
    }
  };

  async submitAddressChoice(selection) {
    /**
     * Call this method with validated values only. It bubbles the validated
     * information using onChange callback
     */
    const { onChange, callOnChangeAlways } = this.props;
    const street = capitalizeAll(selection.street);
    const city = capitalizeAll(selection.city);

    this.setState({ loading: true });
    try {
      if (callOnChangeAlways) {
        const { data } = await API.verifyTimezone(selection);
        // eslint-disable-next-line no-param-reassign
        if (data && data.timezone) selection.timezone = data.timezone;

        await onChange(
          {
            ...selection,
            street,
            city,
            verified_address: true,
          },
          true, // bypass touch on selection
        );
      }

      this.setState({
        ...selection,
        street,
        city,
        isVerified: true,
      });
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const {
      candidates,
      modalOpen,
      candidateId,
      street,
      city,
      state,
      zip_code: zipCode,
      loading,
      noResultsFound,
      isVerified,
      isDirty,
    } = this.state;

    const { getLabel, formError, invalidateOnChange, disableVerify } = this.props;

    const fieldError = (isDirty && !isVerified && invalidateOnChange) || formError;

    return (
      <div className="address-with-verification">
        <AddressVerificationModal
          candidates={candidates}
          isOpen={modalOpen}
          onClose={this.onModalClose}
          onSelect={this.onModalSelect}
          onSubmit={this.onModalSubmit}
          index={candidateId}
        />
        <Form.Group className="align-items-end">
          <Form.Input
            width={16}
            onChange={this.handleChange('street')}
            required
            name="street"
            label={getLabel('Address')}
            placeholder="15 North Ave."
            value={street}
            error={fieldError}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input
            width={6}
            onChange={this.handleChange('city')}
            required
            name="city"
            label={getLabel('City')}
            placeholder="New York"
            value={city}
            error={fieldError}
          />
          <Form.Input
            width={4}
            onChange={this.handleChange('state')}
            required
            type="text"
            pattern="[A-Z]*"
            minLength={2}
            maxLength={2}
            name="state"
            label={getLabel('State')}
            placeholder="NY"
            value={state}
            error={fieldError}
          />
          <Form.Input
            width={6}
            onChange={this.handleChange('zip_code')}
            required
            type="text"
            maxLength={30}
            name="zip_code"
            label={getLabel('Zip Code')}
            placeholder="01234"
            value={zipCode}
            error={fieldError}
          />
        </Form.Group>
        <Form.Group inline>
          <Form.Field>
            {!isVerified && (
              <Button
                primary
                content="Verify"
                loading={loading}
                onClick={this.handleVerify}
                disabled={disableVerify}
              />
            )}
            {isVerified && <Icon color="green" name="check" size="big" />}
          </Form.Field>
          <Form.Field>
            <HelpTooltip
              title="Verify Address"
              content="Validate your address and timezone to ensure accurate operating hours"
            />
          </Form.Field>
        </Form.Group>
        {noResultsFound && (
          <Message
            error
            header="No address found"
            content="Please try again using a valid address"
          />
        )}
      </div>
    );
  }
}

AddressWithVerification.propTypes = {
  street: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip_code: PropTypes.string.isRequired,
  formError: PropTypes.bool,
  addressVerified: PropTypes.bool,
  invalidateOnChange: PropTypes.bool,
  onChange: PropTypes.func,
  callOnChangeAlways: PropTypes.bool,
  getLabel: PropTypes.func,
  disableVerify: PropTypes.bool,
};

AddressWithVerification.defaultProps = {
  addressVerified: false,
  invalidateOnChange: true,
  callOnChangeAlways: false,
  formError: false,
  onChange: () => undefined,
  getLabel: (text) => text,
  disableVerify: false,
};

export default AddressWithVerification;
