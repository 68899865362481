import React from 'react';

import PropTypes from 'prop-types';
import { Card, Form, Button, Icon, Input, Image } from 'semantic-ui-react';

import { SortableHandle } from 'react-sortable-hoc';

import Utils from '../../../../libs/utils';
import Dots from '../../../common/sortables/Dots';

const DragHandle = SortableHandle(() => (
  <span
    style={{
      position: 'absolute',
      border: '0px',
      borderRight: '1px solid #ced6e0',
      borderBottom: '1px solid #ced6e0',
      padding: '0.5em',
      backgroundColor: 'white',
      borderRadius: '0.28571429rem 0 0.28571429rem 0',
    }}
  >
    <Dots />
  </span>
));

class DraggableFileField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileInput: null,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setFileInput = this.setFileInput.bind(this);
  }

  handleUpdate(e, target) {
    const { onChange } = this.props;
    onChange(e, target);
  }

  handleDelete(e) {
    e.stopPropagation();
    const { onDelete } = this.props;
    onDelete();
  }

  setFileInput(e) {
    this.setState({
      fileInput: e,
    });
  }

  getFileField() {
    const { multiple = false, accept } = this.props;

    return (
      <input
        ref={this.setFileInput}
        hidden
        type="file"
        onChange={this.handleUpdate}
        accept={accept}
        multiple={multiple}
      />
    );
  }

  getDoubleButtons() {
    const { enableDelete, loading } = this.props;
    const { fileInput } = this.state;

    return (
      <div className="ui two buttons">
        <Button loading={loading} basic color="green" onClick={() => fileInput.click()}>
          <Icon name="edit" />
        </Button>
        {enableDelete && (
          <Button loading={loading} basic color="red" onClick={this.handleDelete}>
            <Icon name="delete" />
          </Button>
        )}
      </div>
    );
  }

  getSingleButton() {
    const { fileInput } = this.state;

    return <Button basic color="green" fluid icon="plus" onClick={() => fileInput.click()} />;
  }

  render() {
    const { url, title, onClick, onChangeFilename, addOnly, placeholder, loading } = this.props;
    const { fileInput } = this.state;

    if (addOnly) {
      return (
        <Card className="card-add-only" raised={false}>
          <Button
            loading={loading}
            icon="upload"
            size="huge"
            content="Upload"
            labelPosition="left"
            basic
            onClick={() => fileInput.click()}
          />
          {this.getFileField()}
        </Card>
      );
    }
    if (url) {
      return (
        <Card raised={false}>
          {url.toLowerCase().endsWith('.pdf') ? (
            <div
              className="card-pdf"
              onClick={onClick}
              role="button"
              tabIndex={-1}
              onKeyDown={onClick}
            >
              <Icon name="file pdf outline" size="huge" disabled />
            </div>
          ) : (
            <Image
              className="card-image"
              onClick={onClick}
              size="small"
              src={Utils.addImageQueryParam(url, 'width', 500)}
            />
          )}
          <DragHandle />
          <Card.Content>
            <Card.Description className="card-description">
              <Form>
                <Form.Field>
                  <label htmlFor="file-name-input">Filename</label>
                  <Input
                    id="file-name-input"
                    placeholder={placeholder || Utils.fileNameFromURL(0, url)}
                    onChange={onChangeFilename}
                    onClick={(e) => e.stopPropagation()}
                    value={title}
                  />
                </Form.Field>
              </Form>
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            {this.getDoubleButtons()}
            {this.getFileField()}
          </Card.Content>
        </Card>
      );
    }
    return (
      <Card raised={false}>
        <div className="card-empty">
          <Icon name="image outline" size="huge" disabled />
        </div>
        <Card.Content>
          <Card.Description />
        </Card.Content>
        <Card.Content extra>
          {this.getSingleButton()}
          {this.getFileField()}
        </Card.Content>
      </Card>
    );
  }
}

DraggableFileField.propTypes = {
  url: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeFilename: PropTypes.func.isRequired,
  addOnly: PropTypes.bool,
  title: PropTypes.string,
  enableDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  accept: PropTypes.string,
};

DraggableFileField.defaultProps = {
  url: '',
  title: '',
  enableDelete: false,
  addOnly: false,
  onDelete: () => null,
  onClick: () => null,
  accept: '*',
};

export default DraggableFileField;
