import CatalogItemLibrary from '../CatalogItemLibrary';
import CatalogModifiers from '../CatalogModifiers';
import CatalogTaxes from '../CatalogTaxes';

export const MENU_ITEMS = [
  {
    name: 'ITEM LIBRARY',
    component: CatalogItemLibrary,
  },
  {
    name: 'MODIFIERS',
    component: CatalogModifiers,
  },
  {
    name: 'TAXES',
    component: CatalogTaxes,
  },
];
