import React from 'react';

import { Label } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { deleteFeatureNotification as deleteFeatureNotificationConnect } from '../../../../../../actions/notificatons';

import './SideNavigationSubItem.scss';

const mapDispatchToProps = (dispatch) => ({
  deleteFeatureNotification: (payload) => dispatch(deleteFeatureNotificationConnect(payload)),
});

const mapStateToProps = (state) => ({
  featureNotifications: state.notifications.features,
});

const SideNavigationSubItem = ({
  name,
  active,
  path,
  isHidden,
  deleteFeatureNotification,
  featureNotifications,
}) => (
  <>
    {!isHidden && (
      <Link
        onClick={() => deleteFeatureNotification(name.toLowerCase())}
        style={isHidden ? { display: 'none' } : {}}
        to={path}
        className={`side-navigation-sub-item ${active && 'active-sub-item'}`}
      >
        <span>{name}</span>
        {featureNotifications.includes(name.toLowerCase()) && (
          <Label className="side-navigation-notification" circular color="red" />
        )}
      </Link>
    )}
  </>
);

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigationSubItem);
