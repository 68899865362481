import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';

import Sortable from '../../../../../common/Sortable';
import { RecipeContext } from '../../../context/RecipeContext';
// eslint-disable-next-line import/no-cycle
import getRecipeNodeNavigationDisplayComponent from '../../../services/get-node-navigation-display-component';
import { isMetadataOnlyNode, nodeInPath } from '../../../services/recipe-utils';
import ObjectOptionsHeader from '../../ObjectOptionsHeader';
import { isNodePathSelected } from '../types.utils';

import './RecipeArray.scss';
import '../recipe-type.scss';

const propTypes = {
  recipeObjectKey: PropTypes.string.isRequired,
  recipePart: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  path: PropTypes.arrayOf(PropTypes.string),
  parentId: PropTypes.string.isRequired,
  selectedNodePath: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  path: [],
  selectedNodePath: [],
};

/**
 * This component is used for recipe nodes of type array
 *
 * @param  {string}  recipeObjectKey  Recipe Object key
 * @param  {array}   recipePart  Value of the recipe node
 * @param  {string}  parentId  Id of the parent node
 * @param  {array}   path  Path of the current recipe node
 * @param  {array}   selectedNodePath  Path of a node searched by the user
 */

const RecipeArray = ({ recipeObjectKey, recipePart, path, selectedNodePath, parentId }) => {
  const { resortArray } = useContext(RecipeContext);
  const [isOpen, setIsOpen] = useState(false);
  const arrayParentIdItem = recipePart.find(isMetadataOnlyNode);
  const arrayWithoutIds = recipePart.filter((obj) => obj !== arrayParentIdItem);

  function isOptionsHeaderOpen() {
    return nodeInPath(selectedNodePath, path) || isOpen;
  }

  function onSortEnd(oldIndex, newIndex) {
    resortArray(recipeObjectKey, parentId, oldIndex, newIndex);
  }

  return (
    <div className="recipe-object">
      <ObjectOptionsHeader
        recipeObjectKey={recipeObjectKey}
        recipePart={arrayWithoutIds}
        isOpen={isOptionsHeaderOpen()}
        toggleOpen={() => setIsOpen(!isOpen)}
        parentId={parentId}
        isNodePathSelected={isNodePathSelected(selectedNodePath, path)}
      />

      {isOptionsHeaderOpen() && (
        <div className="recipe-indented-group recipe-array">
          <Sortable>
            <Sortable.List
              pressDelay={300}
              onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)}
            >
              {Object.keys(arrayWithoutIds).map((childkey, index) => {
                const component = getRecipeNodeNavigationDisplayComponent(
                  childkey,
                  /*  eslint-disable-next-line no-underscore-dangle */
                  arrayParentIdItem.__id__,
                  arrayWithoutIds,
                  index,
                  {
                    selectedNodePath,
                    path: [...path, childkey],
                  },
                );

                return (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <Sortable.Item key={`item-${index}`} index={index}>
                    {component}
                  </Sortable.Item>
                );
              })}
            </Sortable.List>
          </Sortable>
        </div>
      )}
    </div>
  );
};

RecipeArray.propTypes = propTypes;
RecipeArray.defaultProps = defaultProps;

export default RecipeArray;
