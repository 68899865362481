import React from 'react';

import { Table } from 'semantic-ui-react';

import { RedirectPropType } from 'components/modules/redirect/types/redirect';
import { DATE_DISPLAY_FORMAT_AT } from 'libs/date-utils/constants';
import DateUtils from 'libs/date-utils/date-utils';

import DeleteRedirectButton from '../../../DeleteRedirectButton';
import EditRedirectButton from '../../../EditRedirectButton';

import './RedirectTableRow.scss';

const propTypes = {
  redirect: RedirectPropType.isRequired,
};

const defaultProps = {};

export default function RedirectTableRow({ redirect }) {
  const { oldPath, newPath, description, updatedAt } = redirect;
  return (
    <Table.Row className="redirect-table-row">
      <Table.Cell className="from">{oldPath}</Table.Cell>
      <Table.Cell className="to">{newPath}</Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <Table.Cell>{DateUtils.format(updatedAt, DATE_DISPLAY_FORMAT_AT)}</Table.Cell>
      <Table.Cell textAlign="center">
        <DeleteRedirectButton redirect={redirect} />
        <EditRedirectButton redirect={redirect} />
      </Table.Cell>
    </Table.Row>
  );
}

RedirectTableRow.propTypes = propTypes;
RedirectTableRow.defaultProps = defaultProps;
