import React from 'react';

import PropTypes from 'prop-types';
import { Form, Header, Input } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import HelpTooltip from '../HelpTooltip';

import './InputFormGroup.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  customInput: PropTypes.node,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

const defaultProps = {
  value: '',
  name: null,
  onChange: null,
  customInput: null,
  placeholder: '',
  errorMessage: '',
  required: false,
  fullWidth: false,
};

function getColumnWidthSetting(fullWidth, index) {
  const columnWidths = [
    { full: 16, standard: 3 },
    { full: 16, standard: 13 },
  ];

  return columnWidths[index][fullWidth ? 'full' : 'standard'];
}

function getErrorColumnWidthSetting(fullWidth, index) {
  const columnWidths = [
    { full: 16, standard: 0 },
    { full: 16, standard: 3 },
  ];

  return columnWidths[index][fullWidth ? 'full' : 'standard'];
}

export default function InputFormGroup({
  title,
  helpMessage,
  value,
  onChange,
  name,
  customInput,
  errorMessage,
  placeholder,
  required,
  fullWidth,
}) {
  const error = !_isEmpty(errorMessage);

  const headerContent = (
    <Form.Field width={getColumnWidthSetting(fullWidth, 0)}>
      <Header as="h4">{`${title}${required ? ' *' : ''}`}</Header>
      {helpMessage && <HelpTooltip title={title} description={helpMessage} />}
    </Form.Field>
  );
  const inputContent = (
    <Form.Field width={getColumnWidthSetting(fullWidth, 1)} error={error}>
      {customInput || (
        <Input name={name} value={value} onChange={onChange} placeholder={placeholder} />
      )}
    </Form.Field>
  );

  return (
    <>
      {fullWidth && (
        <>
          <Form.Group className="input-from-group">{headerContent}</Form.Group>
          <Form.Group className="input-from-group">{inputContent}</Form.Group>
        </>
      )}
      {!fullWidth && (
        <>
          <Form.Group className="input-from-group">
            {headerContent}
            {inputContent}
          </Form.Group>
        </>
      )}

      {error && (
        <Form.Group inline className="input-from-group error-message-container">
          <Form.Field width={getErrorColumnWidthSetting(fullWidth, 1)} />
          <Form.Field width={5}>
            <p>{errorMessage}</p>
          </Form.Field>
        </Form.Group>
      )}
    </>
  );
}

InputFormGroup.propTypes = propTypes;
InputFormGroup.defaultProps = defaultProps;
