import React from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Icon, Image } from 'semantic-ui-react';

import './ProviderButton.scss';

const propTypes = {
  as: PropTypes.oneOf([Form.Button, Button]),
  logo: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};
const defaultProps = {
  as: Form.Button,
  isSelected: false,
  onClick: () => null,
};

function ProviderButton({ as, logo, providerName, isSelected, onClick }) {
  const iconName = isSelected ? 'check square outline' : 'square outline';
  const buttonChildren = (
    <>
      <Icon name={iconName} />
      <Image src={logo} size="tiny" alt={providerName} />
    </>
  );
  const button = React.createElement(
    as,
    { className: `provider-button ${isSelected ? 'checked' : ''}`, onClick },
    buttonChildren,
  );
  return button;
}

ProviderButton.propTypes = propTypes;
ProviderButton.defaultProps = defaultProps;

export default ProviderButton;
