import React from 'react';

import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

const defaultProps = {
  title: '',
  value: '',
};

const NodeResultTemplate = ({ title, value }) => (
  <div>
    <div>{title}</div>
    <div className="node-path">{value}</div>
  </div>
);

NodeResultTemplate.propTypes = propTypes;
NodeResultTemplate.defaultProps = defaultProps;
export default NodeResultTemplate;
