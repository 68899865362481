import React from 'react';

import PropTypes from 'prop-types';
import { Card, Icon, Button, Label, Message } from 'semantic-ui-react';

import { getMailToSupportEmail, getSupportEmail } from './BusinessPlanBodyCard.services';
import ExternalAnchor from '../../../../../common/ExternalAnchor';

import './BusinessPlanBodyCard.scss';

function getLabelForCard(active, highlight) {
  if (active) {
    return (
      <Label attached="top right" color="green">
        Current Plan
      </Label>
    );
  }
  if (highlight) {
    return (
      <Label attached="top right" color="blue">
        Required Plan
      </Label>
    );
  }

  return null;
}

export default function BusinessPlanBodyCard({
  priceName,
  priceDescription,
  annualDescription,
  monthlyDescription,
  active,
  disabled,
  qualities,
  qualitiesTitle,
  onSelectPlan,
  highlight,
  buttonText,
  buttonColor,
  brandIdentity,
  hideButton,
}) {
  const supportEmail = getSupportEmail(brandIdentity);
  const mailToSupportEmail = getMailToSupportEmail(brandIdentity);

  return (
    <Card
      className={`${highlight && 'business-plan-body-card-highlight'} ${
        active && 'business-plan-body-card-active'
      }`}
    >
      <Card.Content className="business-plan-body-card-title-container">
        <Card.Header>{priceName}</Card.Header>
        {!!priceDescription && <Card.Description>{priceDescription}</Card.Description>}
      </Card.Content>

      <Card.Content className="business-plan-body-card-price-container">
        <Card.Header>
          {/* eslint-disable-next-line react/no-danger */}
          <span dangerouslySetInnerHTML={{ __html: annualDescription }} />
        </Card.Header>
        {!!monthlyDescription && <Card.Meta>{monthlyDescription}</Card.Meta>}
      </Card.Content>

      <Card.Content className="business-plan-body-card-qualities-container">
        {!!qualitiesTitle && (
          <div>
            <b>{qualitiesTitle}</b>
          </div>
        )}
        {qualities.map((quality) => {
          return (
            <div key={quality}>
              <Icon name="check" color="green" />
              {quality}
            </div>
          );
        })}
      </Card.Content>
      {getLabelForCard(active, highlight)}

      {hideButton ? (
        <Card.Content className="business-plan-body-card-select-container">
          <Message info>
            <p>
              To choose another plan, please contact support{' '}
              <ExternalAnchor href={mailToSupportEmail} target="_blank">
                {supportEmail}
              </ExternalAnchor>
            </p>
          </Message>
        </Card.Content>
      ) : (
        <Card.Content className="business-plan-body-card-select-container">
          <Button color={buttonColor} disabled={disabled} onClick={() => onSelectPlan(priceName)}>
            {buttonText}
          </Button>
        </Card.Content>
      )}
    </Card>
  );
}

BusinessPlanBodyCard.propTypes = {
  priceName: PropTypes.string.isRequired,
  priceDescription: PropTypes.string,
  active: PropTypes.bool,
  qualitiesTitle: PropTypes.string,
  qualities: PropTypes.arrayOf(PropTypes.string),
  onSelectPlan: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  highlight: PropTypes.bool,
};

BusinessPlanBodyCard.defaultProps = {
  priceDescription: undefined,
  active: false,
  qualitiesTitle: undefined,
  qualities: [],
  disabled: false,
  highlight: false,
};
