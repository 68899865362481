import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import _debounce from 'lodash/debounce';
import _isObject from 'lodash/isObject';
import AceEditor from 'react-ace';

import { editorUpdate } from './AceEditorWrapper.utils';
import { RecipeContext } from '../../../../../../context/RecipeContext';
import { removeNodeMetadata } from '../../../../../../services/recipe-utils';

const propTypes = {
  readOnly: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onEditorChange: PropTypes.func.isRequired,
};

function AceEditorWrapper({ readOnly, mode, onEditorChange }) {
  const { activeNode } = useContext(RecipeContext);

  const [editorState, setEditorState] = useState({
    value: {},
    parseError: false,
  });

  async function handleEditorUpdate(updatedValue) {
    const obj = await editorUpdate(mode, updatedValue);

    setEditorState({
      value: obj.value,
      parseError: obj.parseError,
    });
    onEditorChange(obj);
  }

  function getEditorValue() {
    const { value, parseError } = editorState;
    const editorValue = {
      json: () => (parseError ? value : JSON.stringify(value, null, 2)),
      css: () => (_isObject(value) ? '' : value),
    };
    return editorValue[mode]();
  }

  useEffect(() => {
    setEditorState({ value: removeNodeMetadata(activeNode.value), parseError: false });
  }, [activeNode]);

  return (
    <AceEditor
      readOnly={readOnly}
      mode={mode}
      theme="solarized_light"
      name="recipe-editor"
      onChange={_debounce(handleEditorUpdate, 1000)}
      fontSize={14}
      showPrintMargin
      showGutter
      highlightActiveLine
      value={getEditorValue()}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
}

AceEditorWrapper.propTypes = propTypes;

export default AceEditorWrapper;
