import {
  ADMIN_CONFIRMATION_MESSAGE,
  EXTERNAL_SALES_CONFIRMATION_MESSAGE,
} from './SignUpFormConfirmation.constants';
import { isAdminUser, isAuthorizedForSaleViews } from '../../../../../libs/auth';

export function getConfirmationMessage(user, userRole, confirmationMessage) {
  if (isAdminUser(user)) return ADMIN_CONFIRMATION_MESSAGE;
  if (isAuthorizedForSaleViews(userRole)) return EXTERNAL_SALES_CONFIRMATION_MESSAGE;
  return confirmationMessage;
}
