import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';

import { getNodeSearchOptions } from './NodeSearch.util';
import NodeResultCategoryHeader from './components/NodeResultCategoryHeader';
import NodeResultLayout from './components/NodeResultLayout';
import NodeResultTemplate from './components/NodeResultTemplate';
import useDebounce from '../../../../../../../../../hooks/useDebounce/use-debounce';
import { searchNodes } from '../../../../../../services/search';

import './NodeSearch.scss';

const propTypes = {
  recipePartial: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onResultSelect: PropTypes.func.isRequired,
};

function NodeSearch({ recipePartial, onResultSelect }) {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  function getSearchResults() {
    setLoading(true);
    const res = searchNodes(recipePartial, searchQuery);
    setResults(res);
    setLoading(false);
  }

  const debouncedQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    getSearchResults();
  }, [debouncedQuery]);

  return (
    <div className="node-search">
      <Search
        fluid
        category
        categoryLayoutRenderer={NodeResultLayout}
        categoryRenderer={NodeResultCategoryHeader}
        resultRenderer={NodeResultTemplate}
        placeholder="Search Nodes"
        loading={loading}
        onResultSelect={onResultSelect}
        onSearchChange={(e, { value }) => setSearchQuery(value)}
        results={getNodeSearchOptions(results)}
      />
    </div>
  );
}

NodeSearch.propTypes = propTypes;

export default NodeSearch;
