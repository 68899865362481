import _ from 'lodash';

import { NODE_TYPES, typeRequiredKeys, typeParentKeyPathOptions, typeLabelKey } from './constants';

function nodeHasRequiredKeys(node, requiredKeys) {
  if (requiredKeys.length && _.isArray(requiredKeys[0])) {
    return requiredKeys.some((keySet) => nodeHasRequiredKeys(node, keySet));
  }
  return requiredKeys.every((key) => !_.isNil(node[key]));
}

function nodeSatisfiesParentPath(nodeParentKeys, parentPathOptions) {
  return parentPathOptions.some((path) => {
    if (nodeParentKeys.length < path.length) {
      return false;
    }

    const partialParentKeyPath = nodeParentKeys.slice(nodeParentKeys.length - path.length);
    const wildcardPlacementPath = path.map((key, index) =>
      key === '*' ? partialParentKeyPath[index] : key,
    );

    const partialPath = partialParentKeyPath.join('.');
    const wildcardPath = wildcardPlacementPath.join('.');

    return partialPath === wildcardPath;
  });
}

export function resolveType(node, parentKeys) {
  if (parentKeys.length === 0) {
    return NODE_TYPES.ROOT;
  }

  const typeMatches = _.keys(typeRequiredKeys).filter((type) => {
    const requiredKeys = typeRequiredKeys[type];
    const parentKeyPathOptions = typeParentKeyPathOptions[type];

    return (
      nodeHasRequiredKeys(node, requiredKeys) &&
      nodeSatisfiesParentPath(parentKeys, parentKeyPathOptions)
    );
  });

  if (typeMatches.length) {
    // Take first match
    return typeMatches[0];
  }

  return NODE_TYPES.UNRESOLVED;
}

export function getNodeLabel(node) {
  const { __type__ } = node;
  // eslint-disable-next-line no-underscore-dangle
  const labelKeyOrResolver = typeLabelKey[__type__];

  if (!labelKeyOrResolver || labelKeyOrResolver.length === 0) {
    return '';
  }

  if (_.isString(labelKeyOrResolver)) {
    return node[labelKeyOrResolver];
  }

  return labelKeyOrResolver(node);
}
