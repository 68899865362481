import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { multiCaseGet, objectToCamelCase } from '../libs/format/case';

export function selectBusiness(state) {
  return objectToCamelCase(_get(state, 'business.core.value', {}));
}

export function selectBusinessName(state) {
  return (
    multiCaseGet(state, 'business.core.value.displayName') ||
    multiCaseGet(state, 'business.core.value.name')
  );
}

export function selectBusinessId(state) {
  return _get(state, 'business.core.value.id');
}

export function selectBusinessType(state) {
  return _get(state, 'business.core.value.type');
}

export function selectBusinessCategory(state) {
  return _get(state, 'business.core.value.category');
}

export function selectBusinessInfoId(state) {
  return multiCaseGet(state, 'business.core.value.businessInfo');
}

export function selectUserRoleForBusiness(state) {
  return _get(state, 'business.core.value.user_role');
}

export function selectIsSingleLocation(state) {
  return (_get(state, 'business.locations.value.length', 0, []) || 0) === 1;
}

export function selectBusinessLocations(state) {
  return _get(state, 'business.locations.value', []).map(({ _touchedFields, ...rest }) => rest, []);
}

export function selectBusinessContentNodes(state) {
  return objectToCamelCase(_get(state, 'contentNodes.value', []));
}

export function selectBusinessFiles(state, { fileIds } = {}) {
  let businessFiles = _get(state, 'business.businessFiles.value', []);

  if (!_isNil(fileIds)) {
    const fileIdsSet = new Set(fileIds);
    businessFiles = businessFiles.filter(({ id }) => fileIdsSet.has(id));
  }

  return objectToCamelCase(businessFiles);
}

export function selectBusinessBrandIdentity(state) {
  return _get(state, 'business.core.value.brand_identity');
}
