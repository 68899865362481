import React from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';

import { isValidUser } from '../../libs/auth';
import Routing from '../../libs/routing';
import WithRouter from '../modules/foundation/components/WithRouter';

class GMBPage extends React.PureComponent {
  componentDidMount() {
    const { user, navigate } = this.props;

    if (isValidUser(user)) {
      return navigate(Routing.getFirstRoute(['core', 'integrations']));
    }

    return navigate('/', { from: { pathname: '/core/integrations' } });
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ user }) => ({
  user: _.get(user, 'core.value', {}),
});

export default connect(mapStateToProps)(WithRouter(GMBPage));
