import { SORT_MECHANISMS } from '../../constants';

export function getSortOptionLabel(sortMechanism) {
  const sortMechanismToLabelMap = {
    trending: 'Trending',
    popularity: 'Most popular',
    date: 'Newest',
    alpha: 'A-Z',
    style: 'Most styles',
  };
  return sortMechanismToLabelMap[sortMechanism] || sortMechanism;
}

export function getSortOptions() {
  return SORT_MECHANISMS.map((sortMechanism) => ({
    key: sortMechanism,
    value: sortMechanism,
    text: getSortOptionLabel(sortMechanism),
  }));
}
