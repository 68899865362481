import React from 'react';

import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string.isRequired,
};

const NodeResultCategoryHeader = ({ name }) => <span>{name}</span>;

NodeResultCategoryHeader.propTypes = propTypes;

export default NodeResultCategoryHeader;
