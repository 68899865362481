import _isEmpty from 'lodash/isEmpty';
import _range from 'lodash/range';

import { createPage } from './page';
import { createTile, setOrder, setWidth } from './tile';
import { generateId, setItemInList } from './utils';
import { DYNAMIC_TILES_COMPONENT } from '../../../constants/custom-hero';
import { DEFAULT_WIDTH, MAX_WIDTH } from '../../../constants/tile';
import {
  getWidthForAdjacentTile,
  isValidHeroTileLayout,
  calculateTilesPerRow,
} from '../../../services/tile';

export function createHeroComponentConfiguration({ componentConfiguration = {} } = {}) {
  const {
    componentName = DYNAMIC_TILES_COMPONENT,
    componentConfigurationId,
    componentIdentifier = generateId(),
    order = 1,
  } = componentConfiguration;

  return {
    componentName,
    componentConfigurationId,
    componentIdentifier,
    order,
  };
}

export function createCustomHero(page, initialCustomHero = {}) {
  const { componentConfiguration: initialComponentConfiguration = {}, tiles = [] } =
    initialCustomHero;
  const newTiles = _isEmpty(tiles)
    ? [createTile({ data: { width: DEFAULT_WIDTH } })]
    : tiles.map((tile) => createTile(tile));
  return {
    page: createPage(page),
    componentConfiguration: createHeroComponentConfiguration({
      componentConfiguration: initialComponentConfiguration,
    }),
    tiles: newTiles,
    tilesPerRow: calculateTilesPerRow(newTiles),
  };
}

export function setNumberOfTiles(
  customHero,
  { numberOfTiles: newNumberOfTiles, tilesPerRow: newTilesPerRow } = {},
) {
  const { tiles: previousTiles = [], tilesPerRow: previousTilesPerRow } = customHero;

  if (!isValidHeroTileLayout(newNumberOfTiles, newTilesPerRow)) {
    return [previousTiles, previousTilesPerRow];
  }

  const previousNumberOfTiles = previousTiles.length;

  if (previousNumberOfTiles === newNumberOfTiles && previousTilesPerRow === newTilesPerRow) {
    // layout hasn't changed, return the original tiles
    return [previousTiles, previousTilesPerRow];
  }

  const newColumnWidth = MAX_WIDTH / newTilesPerRow;

  let newTiles = previousTiles.map((tile, index) => {
    let newTile = setOrder(tile, { order: index + 1 });
    newTile = setWidth(newTile, { width: newColumnWidth });
    return newTile;
  });
  if (previousNumberOfTiles < newNumberOfTiles) {
    const newAmountOfTiles = newNumberOfTiles - previousNumberOfTiles;
    const extraNewTiles = _range(newAmountOfTiles).map((index) =>
      createTile({ width: newColumnWidth, order: previousNumberOfTiles + index }),
    );
    newTiles = [...newTiles, ...extraNewTiles];
  } else {
    newTiles = newTiles.slice(0, newNumberOfTiles);
  }

  return [newTiles, newTilesPerRow];
}

export function setNewTileWidth(customHero, { tileIndex, width } = {}) {
  const { tiles: previousTiles = [], tilesPerRow } = customHero;
  const previousTile = previousTiles[tileIndex];

  if (tilesPerRow === 1) {
    const newTile = setWidth(previousTile, { width: MAX_WIDTH });
    return setItemInList(previousTiles, tileIndex, newTile);
  }
  if (tilesPerRow === 2) {
    const newTile = setWidth(previousTile, { width });
    const adjacentTileWidth = getWidthForAdjacentTile(width);
    const adjacentIndex = tileIndex % 2 === 0 ? tileIndex + 1 : tileIndex - 1;
    const newAdjacentTile = setWidth(previousTiles[adjacentIndex], { width: adjacentTileWidth });

    const newTiles = setItemInList(previousTiles, tileIndex, newTile);
    return setItemInList(newTiles, adjacentIndex, newAdjacentTile);
  }
  // no more than 2 tiles per row is supported
  return [...previousTiles];
}

export function getComponentConfigurationIdsToRemove(previousCustomHero = {}, newCustomHero = {}) {
  const { tiles: previousTiles = [], componentConfiguration: previousComponentConfiguration = {} } =
    previousCustomHero;
  const previousComponentConfigurationId = previousComponentConfiguration.componentConfigurationId;

  const { tiles: newTiles = [] } = newCustomHero;

  const previousTileIds = previousTiles
    .map(({ componentConfiguration = {} }) => {
      const { componentConfigurationId } = componentConfiguration;
      return componentConfigurationId;
    })
    .filter(Boolean);
  const newTileIds = newTiles
    .map(({ componentConfiguration = {} }) => {
      const { componentConfigurationId } = componentConfiguration;
      return componentConfigurationId;
    })
    .filter(Boolean);

  if (_isEmpty(previousTileIds)) {
    return { tilesToRemove: [], customHeroToRemove: undefined };
  }

  const tilesToRemove = previousTileIds.filter((id) => !newTileIds.includes(id));

  if (tilesToRemove.length === previousTiles.length) {
    return { tilesToRemove, customHeroToRemove: previousComponentConfigurationId };
  }

  return { tilesToRemove, customHeroToRemove: undefined };
}
