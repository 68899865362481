import PropTypes from 'prop-types';

import { BaseBlockType } from './common.types';
import { ElementBlockPropType } from './element.types';

export const ColumnSegmentPropType = PropTypes.shape({
  ...BaseBlockType,
  elements: PropTypes.arrayOf(ElementBlockPropType),
});

export const ColumnPropType = PropTypes.shape({
  ...BaseBlockType,
  segments: PropTypes.arrayOf(ColumnSegmentPropType),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stretched: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'center', 'right', 'justified']),
  verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
});
