import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Message } from 'semantic-ui-react';

import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import {
  DEFAULT_ERROR_STATE,
  DEFAULT_DELETE_ERROR_HEADER,
  DEFAULT_SAVE_ERROR_HEADER,
  EMPTY_BLOCK_MESSAGE_HEADER,
  EMPTY_BLOCK_MESSAGE_DESCRIPTION,
} from './EditPageElementModal.constants';
import { EditPageElementModalContextProvider } from './EditPageElementModal.context';
import { createElementStates } from './EditPageElementModal.utils';
import EditElementTabs from './components/EditElementTabs';
import API from '../../../../../libs/api';
import { getErrorMessage } from '../../../../../libs/errors';
import ErrorHandler from '../../../../../libs/errors/errors';
import { selectBusinessId, selectBusinessType } from '../../../../../selectors';
import { selectWebsiteId } from '../../../../../selectors/website';
import CloseableModal from '../../../../common/CloseableModal';
import { PagePropType } from '../../../pages/types';
import { DismissibleMessage } from '../../../utils/components';
import { EMPTY_BLOCK_ELEMENT_TYPE } from '../../constants/types';
import useRecipeNodeTranslator from '../../hooks/use-recipe-node-translator';
import { ElementBlockPropType } from '../../types/element.types';

import './EditPageElementModal.scss';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  element: ElementBlockPropType.isRequired,
  page: PagePropType.isRequired,
};

const defaultProps = {};

export default function EditPageElementModal({ open, onSave, onDelete, onCancel, element, page }) {
  const { recipeSnippetToState } = useRecipeNodeTranslator({ page });
  const { type: elementType } = element;
  const websiteId = useSelector(selectWebsiteId);
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);

  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState(DEFAULT_ERROR_STATE);
  const [validationErrorMessages, setValidationErrorMessages] = useState([]);
  const [activeElementType, setActiveElementType] = useState('');
  const [elementStates, setElementStates] = useState({});
  const [callbacks, setCallbacks] = useState({});
  const [previousElement, setPreviousElement] = useState(_cloneDeep(element));
  const [touched, setTouched] = useState(false);

  const { header: errorHeader = '', message: errorMessage = '' } = error || {};
  const hasError = !_isEmpty(errorMessage);
  const haveValidationErrors = !_isEmpty(validationErrorMessages);

  function addValidationError(newError) {
    setValidationErrorMessages((prevMessages) => {
      if (!prevMessages.includes(newError)) {
        return [...prevMessages, newError];
      }
      return prevMessages;
    });
  }

  function clearValidationErrors() {
    setValidationErrorMessages([]);
  }

  function registerCallback(cbElementType, callback) {
    setCallbacks((prevCallbacks) => ({
      ...prevCallbacks,
      [cbElementType]: callback,
    }));
  }

  function onUpdateActiveElementType(nextElement) {
    clearValidationErrors();
    setTouched(false);
    setActiveElementType(nextElement);
  }

  function onUpdateElementState(type, nextElementState) {
    setElementStates((prevElementStates) => ({
      ...prevElementStates,
      [type]: nextElementState,
    }));
  }

  async function cleanPreviousConfiguration(currentElement) {
    const { componentConfigurationId: current } = currentElement;
    const { componentConfigurationId: previous } = previousElement;

    if (!_isNil(previous) && current !== previous) {
      await API.removeCustomPageContent(previous);
    }
    setPreviousElement(currentElement);
  }

  async function onSaveInternal() {
    setTouched(true);
    if (haveValidationErrors) {
      return;
    }

    try {
      setSaveLoading(true);
      const callback = callbacks[activeElementType] || (() => elementStates[activeElementType]);
      const result = await callback();
      onUpdateElementState(activeElementType, result);
      await onSave(result);
      await cleanPreviousConfiguration(result);
    } catch (e) {
      ErrorHandler.capture(e);
      setError({ header: DEFAULT_SAVE_ERROR_HEADER, message: getErrorMessage(e) });
    } finally {
      setSaveLoading(false);
    }
  }

  async function onDeleteInternal() {
    setDeleteLoading(true);

    try {
      const { componentConfigurationId } = element;

      if (componentConfigurationId) {
        await API.removeCustomPageContent(componentConfigurationId);
      }

      await onDelete();
    } catch (e) {
      ErrorHandler.capture(e);
      setError({ header: DEFAULT_DELETE_ERROR_HEADER, message: getErrorMessage(e) });
    } finally {
      setDeleteLoading(false);
    }
  }

  useEffect(() => {
    const elementStateCreator = (type) => {
      return recipeSnippetToState(type, {});
    };
    const elementState = createElementStates(element, elementStateCreator);
    setActiveElementType(elementType);
    setElementStates(elementState);
  }, [elementType]);

  const contextValue = {
    page,
    websiteId,
    businessId,
    businessType,
    registerCallback,
    onUpdateElementState,
    addValidationError,
    clearValidationErrors,
  };

  return (
    <EditPageElementModalContextProvider value={contextValue}>
      <CloseableModal
        open={open}
        onClose={onCancel}
        header="Edit content"
        size="small"
        className="edit-page-element-modal"
      >
        <Modal.Content>
          <EditElementTabs
            elementStates={elementStates}
            activeElementType={activeElementType}
            onUpdateActiveElementType={onUpdateActiveElementType}
          />
          {activeElementType === EMPTY_BLOCK_ELEMENT_TYPE && (
            <Message
              className="empty-block-message"
              header={EMPTY_BLOCK_MESSAGE_HEADER}
              content={EMPTY_BLOCK_MESSAGE_DESCRIPTION}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          {hasError && touched && (
            <DismissibleMessage
              header={errorHeader || ''}
              content={errorMessage}
              delay={8}
              initialVisible
              error
              onDismiss={() => setError(DEFAULT_ERROR_STATE)}
            />
          )}
          {haveValidationErrors && touched && (
            <Message list={validationErrorMessages} visible error />
          )}
          <Button onClick={onDeleteInternal} basic className="delete" loading={deleteLoading}>
            Delete
          </Button>
          <Button onClick={onCancel} basic className="cancel" content="Cancel" />
          <Button
            onClick={onSaveInternal}
            basic
            className="save"
            loading={saveLoading}
            disabled={saveLoading || (touched && haveValidationErrors)}
            content="Save"
          />
        </Modal.Actions>
      </CloseableModal>
    </EditPageElementModalContextProvider>
  );
}

EditPageElementModal.propTypes = propTypes;
EditPageElementModal.defaultProps = defaultProps;
