import React, { useState } from 'react';

import { Button, Container, Header, Image, Segment } from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { ERROR_MESSAGE_CONTENT } from './SquareIntegration.constants';
import { formatHeaderMessage, getSquareProviderServiceSetting } from './SquareIntegration.utils';
import SquareIntegrationSettings from './components/SquareIntegrationSettings/SquareIntegrationSettings';
import API from '../../../../libs/api';
import ErrorHandler, { getErrorMessage } from '../../../../libs/errors';
import { selectBusinessId } from '../../../../selectors';
import { FormContainer, InlineFieldContainer } from '../../../common';
import useBranding from '../../../modules/foundation/hooks/use-branding/useBranding';
import useProviderServiceSettings from '../../../modules/integrations/hooks/use-provider-service-settings';
import IntegrationMessage from '../IntegrationMessage';
import { ProviderServiceStatus } from '../constants';

import SquareLogo from '../../../../static/logos/square-logo.png';

import './SquareIntegration.scss';

const propTypes = {};

const defaultProps = {};

export default function SquareIntegration() {
  const { squareIntegrationEnabled } = useFlags();

  const businessId = useSelector(selectBusinessId);

  const { providerServiceSettings, refreshSettings } = useProviderServiceSettings();

  const { brandName } = useBranding();

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState({});

  if (!squareIntegrationEnabled) {
    return null;
  }

  async function onAuthorize(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data } = await API.getSquareAuthUrl(businessId);
      const { url: authUrl } = data;
      window.location.href = authUrl;
    } catch (e) {
      setLoading(false);
      setErrorMessage(getErrorMessage(e));
    }
  }

  async function onDisconnect() {
    try {
      await API.disconnectSquare(businessId);
      await refreshSettings();
    } catch (e) {
      setErrorMessage(getErrorMessage(e));
    }
  }

  async function onPullMerchantInfo() {
    try {
      const { data } = await API.getSquareMerchantInfo(businessId);
      setCurrentData(data);
    } catch (e) {
      try {
        await refreshSettings();
      } catch (err) {
        setErrorMessage(getErrorMessage(e));
        ErrorHandler.capture(err);
      }
    }
  }

  async function onPullMerchantLocationsInfo() {
    try {
      const { data } = await API.getSquareMerchantLocationsInfo(businessId);
      setCurrentData(data);
    } catch (e) {
      try {
        await refreshSettings();
      } catch (err) {
        setErrorMessage(getErrorMessage(e));
        ErrorHandler.capture(err);
      }
    }
  }

  const squareSettings = getSquareProviderServiceSetting(providerServiceSettings);
  const status = _get(squareSettings, 'status', '');
  const settingsErrorMessage = _get(squareSettings, 'statusData.errorMessage', '');

  const hasAuthorized = status === ProviderServiceStatus.Complete;

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
      <InlineFieldContainer
        title={<Header as="h2">Square</Header>}
        enableDelete={false}
        className="square-integration"
      >
        <Segment.Group>
          <Segment>
            <Container>
              <Container textAlign="center">
                <Image src={SquareLogo} className="square-logo" />
              </Container>
            </Container>
          </Segment>
          {hasAuthorized && (
            <SquareIntegrationSettings
              currentData={currentData}
              onDisconnect={onDisconnect}
              onPullMerchantInfo={onPullMerchantInfo}
              onPullMerchantLocationsInfo={onPullMerchantLocationsInfo}
            />
          )}
          {!hasAuthorized && (
            <>
              <Segment attached>
                <Container className="square-onboarding-welcome">
                  By connecting your Square account, you will enable the {brandName} team to pull
                  the following information easily:
                  <ul>
                    <li>Pull your Square Merchant details</li>
                    <li>Pull your Square Location details</li>
                  </ul>
                  <p>
                    Clicking &quot;Authorize {brandName}&quot; process will start an OAuth
                    connection flow, giving us permission to access and manage this info securely.
                    You will be required to login to your Square account and authorize the
                    connection. You can revoke these permissions anytime from this panel or your
                    Square account settings.
                  </p>
                </Container>
              </Segment>
              <Segment attached>
                <Container textAlign="center">
                  <Button
                    size="big"
                    onClick={onAuthorize}
                    primary
                    loading={loading}
                    disabled={loading}
                  >
                    Authorize {brandName}
                  </Button>
                </Container>
                <IntegrationMessage
                  isError
                  header={formatHeaderMessage(errorMessage || settingsErrorMessage)}
                  content={ERROR_MESSAGE_CONTENT}
                  icon="exclamation triangle"
                />
              </Segment>
            </>
          )}
        </Segment.Group>
      </InlineFieldContainer>
    </FormContainer>
  );
}

SquareIntegration.propTypes = propTypes;
SquareIntegration.defaultProps = defaultProps;
