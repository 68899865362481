import _filter from 'lodash/filter';
import _remove from 'lodash/remove';
import _some from 'lodash/some';
import _sortBy from 'lodash/sortBy';

import { DOM_LOCATION_OPTIONS, PAGE_LOCATION_OPTIONS } from '../../../../constants';

export function shouldFilterForAll(websitePages, placement, pages) {
  const allPagesSelected = websitePages.length + 1 === pages.length;
  return placement === DOM_LOCATION_OPTIONS.All && allPagesSelected;
}

export function filterForDomLocation(htmlElements, placement) {
  const filtered = [...htmlElements];

  if (placement !== DOM_LOCATION_OPTIONS.All) {
    _remove(filtered, ({ domLocation }) => domLocation !== placement);
  }

  return filtered;
}

export function filterForWebsiteLocation(filtered, pages) {
  if (_some(pages)) {
    if (_some(_filter(pages, (page) => page === PAGE_LOCATION_OPTIONS.Global))) {
      return _sortBy(filtered, ['id']);
    }

    const filteredGlobals = _filter(
      filtered,
      ({ websiteLocation }) => websiteLocation === PAGE_LOCATION_OPTIONS.Page,
    );
    return _sortBy(filteredGlobals, ['id']);
  }

  return [];
}

export function filterForWebsitePages(filtered, pages) {
  if (_some(pages)) {
    const filteredPages = [];
    (filtered || []).forEach((websitePage) => {
      const { websiteLocation, page } = websitePage;
      const isGlobal = websiteLocation === PAGE_LOCATION_OPTIONS.Global;
      const isFilteredPage = _some(pages, (p) => p === page);
      if (isGlobal || isFilteredPage) {
        filteredPages.push(websitePage);
      }
    });

    return _sortBy([...filteredPages], ['id']);
  }

  return [];
}

export function filterHtmlElements(htmlElements, websitePages, placement, pages) {
  const filterForAll = shouldFilterForAll(websitePages, placement, pages);

  if (filterForAll) {
    return htmlElements;
  }

  const filteredByDom = filterForDomLocation(htmlElements, placement);
  const filteredByWebsiteLocation = filterForWebsiteLocation(filteredByDom, pages);
  const filteredByWebsitePages = filterForWebsitePages(filteredByWebsiteLocation, pages);
  return filteredByWebsitePages;
}
