/* eslint-disable no-underscore-dangle */
import _ from 'lodash';

import API from './api';
import Extract from './extract';
import Store from '../store/store';

async function executeSequentialInstructions(instructions, clearCbs, navigate) {
  return instructions.reduce(async (previousInstructionPromise, currentInstruction) => {
    return [
      ...(await previousInstructionPromise),
      // TODO fix the following rule
      // eslint-disable-next-line no-use-before-define
      await Save._executeInstruction(currentInstruction, clearCbs, navigate),
    ];
  }, Promise.resolve([]));
}

const Save = {
  async save(state, clearCbs, navigate) {
    const [instructions, dependencies] = Extract.extractApiInstructions(state);

    const independentResponses = await executeSequentialInstructions(
      instructions,
      clearCbs,
      navigate,
    );
    const independentErrorResponses = independentResponses.filter(
      ([isSuccessful]) => !isSuccessful,
    );

    const updatedState = Store.getState();
    const dependencyInstructions = Extract.extractDependencyInstructions(
      updatedState,
      dependencies,
    );

    const dependentResponses = await executeSequentialInstructions(
      dependencyInstructions,
      clearCbs,
      navigate,
    );
    const dependentErrorResponses = dependentResponses.filter(([isSuccessful]) => !isSuccessful);

    return [...independentErrorResponses, ...dependentErrorResponses].map((errorResponse) =>
      Save._formatErrorResponse(errorResponse),
    );
  },

  async _executeInstruction(i, clearCbs, navigate) {
    const { type, data, path } = i;
    let resp = null;

    try {
      if (type === 'POST') resp = await API.post(path, data);
      else if (type === 'PUT') resp = await API.patch(path, data);
      else if (type === 'DELETE') resp = await API.delete(path);

      const { data: respData } = resp;
      Save._clearInstruction(i, clearCbs, (type === 'POST' || type === 'PUT') && respData);
      return [true, null];
    } catch (e) {
      const responseStatus = _.get(e, 'response.status');
      if (responseStatus === 401) {
        navigate('/');
      }

      let errors = null;
      if (responseStatus === 413) {
        errors = {
          [i.stateKeyPath[1]]: 'File is larger then maximum allowed size, please try again.',
        };
      } else {
        errors = _.get(e, 'response.data');
      }

      if (errors) {
        return [false, { keyPath: i.stateKeyPath, errors }];
      }

      return [
        false,
        {
          keyPath: i.stateKeyPath,
          errors: { [i.stateKeyPath[1]]: 'Unexpected Error' },
        },
      ];
    }
  },

  _clearInstruction(i, clearCbs, data = null) {
    const { stateKeyPath, stateType, type } = i;
    const clearFuncs = clearCbs[stateKeyPath[0]];
    clearFuncs[type]({
      stateKeyPath: stateKeyPath.slice(1),
      stateType,
      data,
    });
  },

  _formatErrorResponse(errorResponse) {
    const [, { keyPath, errors }] = errorResponse;
    return {
      model: keyPath[1],
      errors,
    };
  },
};

export default Save;
