import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';

import { multiCaseGet } from '../../../../../libs/format/case';

export function hasAnyRecipe(patch, recipeNames) {
  try {
    if (_isEmpty(patch) || _isEmpty(recipeNames)) {
      return false;
    }

    const baseRecipe = multiCaseGet(patch, 'baseRecipe');
    const { name = '' } = baseRecipe || {};
    return _includes(recipeNames, name);
  } catch {
    return false;
  }
}
