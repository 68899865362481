import React from 'react';

import ForgotForm from './ForgotForm';
import LoginBanner from './LoginBanner';

import '../../styles/core/pages/login-forgot.scss';

export default function ForgotPage() {
  return (
    <div className="login-forgot-page">
      <LoginBanner />
      <div className="login-forgot-form-container">
        <ForgotForm />
      </div>
    </div>
  );
}
