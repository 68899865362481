import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  IMAGE_ALT_TEXT_MAX_LENGTH,
  IMAGE_ALT_TEXT_DEFAULT_PLACEHOLDER,
  IMAGE_ALT_TEXT_HELP_TITLE,
  IMAGE_ALT_TEXT_HELP_DESCRIPTION,
} from './ImageAltTextField.constants';
import { getAiGeneratedAltText } from './ImageAltTextField.utils';
import useSemanticUiProps from '../../../../../hooks/useSemanticUiProps';
import ErrorHandler from '../../../../../libs/errors/errors';
import Validate from '../../../../../libs/validate';
import { selectBusinessId, selectBusinessType } from '../../../../../selectors';
import HelpTooltip from '../../../../common/HelpTooltip';
import ActionsInput from '../../../content/components/ActionsInput';
import WritingAssistantButton from '../../../content/components/WritingAssistantButton';
import { getWritingAssistantActions } from '../../../content/services/prompts';

import './ImageAltTextField.scss';

const propTypes = {
  onChangeAltText: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  title: PropTypes.element,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

const defaultProps = {
  altText: '',
  imageUrl: '',
  maxLength: IMAGE_ALT_TEXT_MAX_LENGTH,
  placeholder: IMAGE_ALT_TEXT_DEFAULT_PLACEHOLDER,
  title: null,
  disabled: false,
  required: false,
};

export default function ImageAltTextField({
  imageUrl,
  altText,
  onChangeAltText,
  maxLength,
  placeholder,
  title,
  disabled,
  required,
  ...rest
}) {
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const [loading, setLoading] = useState(false);

  const semanticUiProps = useSemanticUiProps(rest);
  const inputProps = semanticUiProps.getInputProps(rest);

  const isValidImageUrl = Validate.validate(Validate.TYPES.LINK, imageUrl);

  async function onMakeSuggestion() {
    try {
      setLoading(true);
      const params = { imageUrl };
      const { text_completion: generatedDescription } = await getAiGeneratedAltText(
        businessId,
        businessType,
        params,
      );

      return generatedDescription;
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
    return '';
  }

  return (
    <div className="image-alt-text-field-container">
      {!_isEmpty(title) && (
        <div className="image-alt-text-field-title">
          {title}
          <HelpTooltip
            title={IMAGE_ALT_TEXT_HELP_TITLE}
            description={IMAGE_ALT_TEXT_HELP_DESCRIPTION}
          />
        </div>
      )}

      <ActionsInput>
        <ActionsInput.Input
          id="seo-metadata-seo-description"
          input={Input}
          onChange={onChangeAltText}
          placeholder={placeholder}
          disabled={disabled}
          loading={loading}
          value={altText}
          maxLength={maxLength}
          required={required}
          {...inputProps}
        />

        {isValidImageUrl && (
          <ActionsInput.Actions
            trigger={WritingAssistantButton}
            actionSections={getWritingAssistantActions({ onMakeSuggestion })}
            adminOnly
          />
        )}
      </ActionsInput>
    </div>
  );
}

ImageAltTextField.propTypes = propTypes;
ImageAltTextField.defaultProps = defaultProps;
