import _isEmpty from 'lodash/isEmpty';

export function getPathValidationMessage(path = '', otherPath = '', name = '') {
  if (_isEmpty(path)) {
    return `${name} Path is required`;
  }
  if (path === otherPath) {
    return `Paths must be different`;
  }
  if (!path.startsWith('/')) {
    return `${name} Path must start with '/'`;
  }
  if (path.includes(' ')) {
    return `${name} Path cannot have empty spaces`;
  }
  return '';
}
