import React from 'react';

import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';

import clsx from 'clsx';

import { CustomPageTemplatePropType } from '../../../../types/custom-page-template.type';

import './CustomPageTemplateItem.scss';

const propTypes = {
  template: CustomPageTemplatePropType.isRequired,
  onSelectTemplate: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

const defaultProps = {
  selected: false,
};

export default function CustomPageTemplateItem({ template, onSelectTemplate, selected }) {
  const { name, description } = template;

  return (
    <List.Item
      className={clsx({ 'custom-page-template-item': true, selected })}
      onClick={() => onSelectTemplate(template)}
    >
      <List.Content>
        <List.Header>{name}</List.Header>
        <List.Description>{description || 'No description'}</List.Description>
      </List.Content>
    </List.Item>
  );
}

CustomPageTemplateItem.propTypes = propTypes;
CustomPageTemplateItem.defaultProps = defaultProps;
