import React, { useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import EditPageGroupModal from '../EditPageGroupModal';

import './AddPageGroup.scss';

const propTypes = {};
const defaultProps = {};

export default function AddPageGroup() {
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      <Button className="add-page-group-trigger action-button-gray" icon onClick={openModal}>
        <Icon name="plus" /> ADD LINK GROUP
      </Button>
      {showModal && <EditPageGroupModal open={showModal} onClose={closeModal} add />}
    </>
  );
}

AddPageGroup.propTypes = propTypes;
AddPageGroup.defaultProps = defaultProps;
