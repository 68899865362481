import React from 'react';

export const TEST_DATA_DESCRIPTION = (
  // eslint-disable-next-line react/jsx-filename-extension
  <>
    Data displayed in this page is <b>Test Data</b>. Real life data is only displayed in
    production-like environments
  </>
);
export const TEST_DATA_TITLE = 'Not Real Data';
