import React from 'react';

import PropTypes from 'prop-types';
import { Icon, Message } from 'semantic-ui-react';

import './LoadingMessage.scss';

const propTypes = {
  message: PropTypes.string,
};

const defaultProps = {
  message: 'Loading...',
};

export default function LoadingMessage({ message }) {
  return (
    <Message icon className="custom-pages-loading-message">
      <Icon name="circle notched" loading />
      <Message.Content>
        <Message.Header>{message}</Message.Header>
      </Message.Content>
    </Message>
  );
}

LoadingMessage.propTypes = propTypes;
LoadingMessage.defaultProps = defaultProps;
