import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { getTileBusinessFiles } from './TileBackgroundConfiguration.utils';
import { selectBusinessFiles } from '../../../../../../../selectors/business';
import FileSelectionWell from '../../../../../files/components/FileSelectionWell';
import { MAX_ALLOWED_TILE_IMAGES } from '../../../../constants/tile';
import { getTileImages } from '../../../../services/tile';
import { TilePropType } from '../../../../types/Tile';
import { CustomHeroFormContext } from '../../CustomHeroForm.context';

import './TileBackgroundConfiguration.scss';

const propTypes = {
  tileIndex: PropTypes.number.isRequired,
  tile: TilePropType.isRequired,
};

const defaultProps = {};

export default function TileBackgroundConfiguration({ tileIndex, tile }) {
  const businessFiles = useSelector(selectBusinessFiles);

  const { setImages } = useContext(CustomHeroFormContext);

  const images = getTileImages(tile);

  function onImagesChange(newImages = []) {
    const newImagesPayload = newImages.map(({ id, url }) => ({ businessFileId: id, url }));
    setImages({ tileIndex }, newImagesPayload);
  }

  const tileBusinessFiles = getTileBusinessFiles(images, businessFiles);

  return (
    <Form.Group className="tile-background-configuration">
      <span className="label">Background</span>
      <FileSelectionWell
        files={tileBusinessFiles}
        onUpdateFiles={onImagesChange}
        maxAllowedImages={MAX_ALLOWED_TILE_IMAGES}
      />
    </Form.Group>
  );
}

TileBackgroundConfiguration.propTypes = propTypes;
TileBackgroundConfiguration.defaultProps = defaultProps;
