import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import useSubmissionChannels from '../../../../hooks/use-submission-channels';

import './ChannelSelector.scss';

const propTypes = {
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {};

function ChannelSelector({ onChange }) {
  const { channels: availableChannels } = useSubmissionChannels();

  const handleDropdownChange = (event, data) => {
    onChange(data.value);
  };

  const dropdownOptions = availableChannels.map((channel) => ({
    key: channel,
    text: channel,
    value: channel,
  }));

  return (
    <Dropdown
      multiple
      search
      selection
      options={dropdownOptions}
      disabled={availableChannels.length === 0}
      onChange={handleDropdownChange}
      placeholder="Filter by Channels"
    />
  );
}

ChannelSelector.propTypes = propTypes;
ChannelSelector.defaultProps = defaultProps;
export default ChannelSelector;
