import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Divider } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { CuratedHeroComponentConfigurationPropType } from './CuratedHeroForm.types';
import { sortByIndex } from './CuratedHeroForm.utils';
import { CuratedHeroContext } from '../../../../../../modules/custom-pages/contexts/CuratedHero.context';
import {
  CONTENT_TYPE_CTA,
  CONTENT_TYPE_IMAGE,
  CONTENT_TYPE_SETTINGS_KEY,
  CONTENT_TYPE_TEXT,
} from '../../../../../../modules/hero/constants/curated-hero';
import { DismissibleMessage } from '../../../../../../modules/utils/components';
import CuratedHeroCtaInput from '../CuratedHeroCtaInput';
import CuratedHeroImagesInput from '../CuratedHeroImagesInput';
import CuratedHeroTextInput from '../CuratedHeroTextInput';

const propTypes = PropTypes.shape({
  heroComponentOptions: PropTypes.arrayOf(CuratedHeroComponentConfigurationPropType),
  onSave: PropTypes.func.isRequired,
});
const defaultProps = {
  heroComponentOptions: [],
};

export default function CuratedHeroForm({ heroComponentOptions, onSave }) {
  const { getComponentConfiguration, childConfigurations, isSubmitting, isFormTouched } =
    useContext(CuratedHeroContext);

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  async function handleSave() {
    setError(null);
    setSuccessMessage(null);
    try {
      await onSave();
      setSuccessMessage('Curated Hero saved successfully.');
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setError(
          'There was an error with data. Please check the form values and try saving again.',
        );
      } else {
        setError('There was an error when saving. Please try again later.');
      }
    }
  }

  useEffect(() => {
    if (isFormTouched) {
      setError(null);
      setSuccessMessage(null);
    }
  }, [isFormTouched]);

  const contentTypePriority = [CONTENT_TYPE_TEXT, CONTENT_TYPE_IMAGE, CONTENT_TYPE_CTA];

  const formInputs = heroComponentOptions.reduce((accFormInputs, heroComponentConfiguration) => {
    const { contentTypes, sectionLabel } = heroComponentConfiguration;
    const componentConfiguration =
      getComponentConfiguration(heroComponentConfiguration, childConfigurations) || {};
    const keyId = componentConfiguration.id || componentConfiguration.localId;
    const sectionFormInputs = sortByIndex(contentTypes, contentTypePriority).map((contentType) => {
      if (contentType === CONTENT_TYPE_CTA) {
        return (
          <CuratedHeroCtaInput
            key={`cta-${keyId}`}
            componentConfiguration={componentConfiguration}
            settings={
              heroComponentConfiguration.contentSettings[CONTENT_TYPE_SETTINGS_KEY[contentType]]
            }
          />
        );
      }
      if (contentType === CONTENT_TYPE_IMAGE) {
        return (
          <CuratedHeroImagesInput
            key={`image-${keyId}`}
            componentConfiguration={componentConfiguration}
            settings={
              heroComponentConfiguration.contentSettings[CONTENT_TYPE_SETTINGS_KEY[contentType]]
            }
          />
        );
      }
      return (
        <CuratedHeroTextInput
          key={`text-${keyId}`}
          componentConfiguration={componentConfiguration}
          settings={
            heroComponentConfiguration.contentSettings[CONTENT_TYPE_SETTINGS_KEY[contentType]]
          }
        />
      );
    });

    return [
      ...accFormInputs,
      !_isEmpty(accFormInputs) ? <Divider key={`${sectionLabel}-divider`} /> : null,
      <div key={sectionLabel} className="form-section">
        <h3>{sectionLabel}</h3>
        {sectionFormInputs}
      </div>,
    ];
  }, []);

  return (
    <div className="curated-hero-form">
      {formInputs}
      {error && (
        <DismissibleMessage initialVisible negative delay={5000} onDismiss={() => setError(null)}>
          {error}
        </DismissibleMessage>
      )}
      {successMessage && (
        <DismissibleMessage
          initialVisible
          positive
          delay={5000}
          onDismiss={() => setSuccessMessage(null)}
        >
          {successMessage}
        </DismissibleMessage>
      )}
      <Button
        className="secondary-navy"
        onClick={handleSave}
        disabled={isSubmitting || !isFormTouched}
        loading={isSubmitting}
      >
        Save
      </Button>
    </div>
  );
}

CuratedHeroForm.propTypes = propTypes;
CuratedHeroForm.defaultProps = defaultProps;
