import React from 'react';

import PropTypes from 'prop-types';
import { Form, Header } from 'semantic-ui-react';

import HelpTooltip from '../HelpTooltip';

import './FormInputInline.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  titleWidth: PropTypes.number,
  titleAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  helpMessage: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  required: PropTypes.bool,
};

const defaultProps = {
  titleWidth: 3,
  titleAlign: 'middle',
  helpMessage: '',
  error: null,
  required: false,
};

export default function FormInputInline({
  title,
  titleWidth,
  titleAlign,
  error,
  required,
  helpMessage,
  children,
}) {
  const hasError = !!error;
  const fieldWidth = 16 - titleWidth;
  return (
    <>
      <Form.Group inline className={`form-input-inline ${titleAlign}`}>
        <Form.Field className="label" width={titleWidth}>
          <Header as="h4">{`${title}${required ? ' *' : ''}`}</Header>
          {helpMessage && <HelpTooltip title={title} description={helpMessage} />}
        </Form.Field>
        {children(fieldWidth)}
      </Form.Group>

      {hasError && (
        <Form.Group inline className="input-from-group error-message-container">
          <Form.Field width={5} />
          <Form.Field width={5}>{error}</Form.Field>
        </Form.Group>
      )}
    </>
  );
}

FormInputInline.propTypes = propTypes;
FormInputInline.defaultProps = defaultProps;
