import { DOMAIN_REGEX } from './constants';

export function cleanDomainName(domain = '') {
  const sideHyphensRegex = /(^-+)/g;
  const consecutiveHyphens = /-+/g;
  const invalidChars = /[^a-z0-9-]/g;
  return domain
    .split('.')
    .map((domainPart) =>
      domainPart
        .replaceAll(sideHyphensRegex, '')
        .replaceAll(consecutiveHyphens, '-')
        .replaceAll(invalidChars, ''),
    )
    .join('.');
}

export function addDomainExtensionIfMissing(domain, extension = '.com') {
  const re = /[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/;
  const match = domain.match(re);
  return !match ? `${domain}${extension}` : match[0];
}

export function isValidDomain(domain) {
  const match = domain.search(DOMAIN_REGEX);
  return match > -1;
}
