import { v4 as uuid } from 'uuid';

export function createNewVariations(service = {}, existingServices = []) {
  const { variations } = service;
  const newVariations = variations.map(({ id }) => ({
    id: uuid(),
    itemVariation: id,
    priceOverride: 0,
    priceDisplayOverride: '',
  }));

  return newVariations.filter(({ itemVariation }) => {
    return !existingServices.some(
      (existingService) => existingService.itemVariation === itemVariation,
    );
  });
}

export function updateVariation(variationToUpdate, existingServices) {
  const { itemVariation } = variationToUpdate;
  const newServices = existingServices.map((currentService) => {
    return currentService.itemVariation === itemVariation
      ? { ...currentService, ...variationToUpdate }
      : currentService;
  });
  return newServices;
}

export function removeVariation(variationToRemove, existingServices) {
  const { itemVariation: variationIdToRemove } = variationToRemove;
  const newServices = existingServices.filter((currentService) => {
    return currentService.itemVariation !== variationIdToRemove;
  });

  return newServices;
}
