import React, { useState } from 'react';

import { Icon, Table, Button, Checkbox } from 'semantic-ui-react';

import ConfirmModal from '../../../../common/ConfirmModal/ConfirmModal';

function ReviewsTableHeader({
  isIndeterminate,
  isChecked,
  selectAll,
  deselectAll,
  onDelete,
  approveReviews,
  disapproveReviews,
  amountSelected,
}) {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  return (
    <>
      <ConfirmModal
        open={showDeleteConfirmModal}
        onConfirm={() => {
          onDelete();
          setShowDeleteConfirmModal(false);
        }}
        onCancel={() => setShowDeleteConfirmModal(false)}
        content="Are you sure you want to delete the selected reviews?"
        confirmButton="Delete selected reviews"
      />
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Checkbox
              indeterminate={isIndeterminate}
              checked={isChecked}
              onClick={() => {
                if (isChecked || isIndeterminate) {
                  deselectAll();
                } else {
                  selectAll();
                }
              }}
            />
          </Table.HeaderCell>
          <Table.HeaderCell className="reviews-action-buttons" colSpan={3}>
            {amountSelected > 0 && (
              <>
                <Button
                  className="action-button"
                  size="small"
                  onClick={() => setShowDeleteConfirmModal(true)}
                >
                  <Icon name="trash" /> Delete
                </Button>
                <Button className="action-button" onClick={disapproveReviews} size="small">
                  <Icon name="eye slash" /> Disapprove
                </Button>
                <Button className="action-button" onClick={approveReviews} size="small">
                  <Icon name="eye" /> Approve
                </Button>
              </>
            )}
          </Table.HeaderCell>
          <Table.HeaderCell>{amountSelected > 0 && `${amountSelected} selected`}</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell singleLine>Author</Table.HeaderCell>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell>Comments</Table.HeaderCell>
          <Table.HeaderCell>Show On Website</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </>
  );
}

export default ReviewsTableHeader;
