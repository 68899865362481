import { stringifyBytes } from 'components/modules/files/services/files';

export const IMAGE_UPLOAD_MAX_SIZE = 5242880; // 5 MB default
export const MAX_SIZE_EXCEEDED_MESSAGE = `Max image size of ${stringifyBytes(
  IMAGE_UPLOAD_MAX_SIZE,
)} was exceeded by files:`;
export const MAX_SIZE_EXCEEDED_SUGGESTION_MESSAGE = `We suggest compressing and re-uploading these files using a tool like`;
export const MAX_FILENAME_LENGTH = 100;
export const MAX_FILENAME_LENGTH_EXCEEDED_MESSAGE = `Max filename length of ${MAX_FILENAME_LENGTH} characters was exceeded by files:`;
export const MAX_IMG_SIZE_EXCEEDED_SUGGESTION_URL = `https://compressjpeg.com/`;
