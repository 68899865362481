import React, { useState } from 'react';

import PropTypes from 'prop-types';

import _debounce from 'lodash/debounce';
import AceEditor from 'react-ace';

import { CSS_PARSING_ERROR_MESSAGE } from './CssCodeEditor.constant';
import { isValidCss } from './CssCodeEditor.utils';

import './CssCodeEditor.scss';

const propTypes = {
  value: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
};

export default function CssCodeEditor({ value, onUpdate }) {
  const [parsingError, setParsingError] = useState(false);

  async function onChange(updatedValue) {
    const valid = await isValidCss(updatedValue);
    setParsingError(!valid);
    onUpdate(updatedValue);
  }

  return (
    <div className="css-code-editor-container">
      <AceEditor
        mode="css"
        name="css-code-editor"
        theme="solarized_light"
        className="css-code-editor"
        onChange={_debounce(onChange, 1000)}
        fontSize={14}
        showPrintMargin
        showGutter
        highlightActiveLine
        value={value}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      {parsingError && <div className="parsing-error">{CSS_PARSING_ERROR_MESSAGE}</div>}
    </div>
  );
}

CssCodeEditor.propTypes = propTypes;
CssCodeEditor.defaultProps = defaultProps;
