import React from 'react';

import { Button, Popup, Header, List, Image } from 'semantic-ui-react';

import _get from 'lodash/get';
import _pull from 'lodash/pull';
import _startCase from 'lodash/startCase';
import { connect } from 'react-redux';

import {
  PUBLISH_ERROR_MODAL_TITLE,
  PUBLISH_CONFLICT_TITLE,
  PUBLISH_CONFLICT_MESSAGE,
  IS_PUBLISHING_LIVE_CONFLICT_TITLE,
  IS_PUBLISHING_LIVE_CONFLICT_MESSAGE,
  SAVE_ERROR_MODAL_TITLE,
  SAVE_ERROR_MESSAGE,
  VALIDATION_ERROR_MODAL_TITLE,
  VALIDATION_ERROR_MESSAGE,
  PUBLISH_SUCCESS_MODAL_TITLE,
  PUBLISH_SUCCESS_PRIMARY_MESSAGE,
  PUBLISH_SUCCESS_SECONDARY_MESSAGE,
  PRODUCT_TYPE_PUBLISH_TOOLTIP_MESSAGE_MAP,
  SAVE_TOOLTIP_MESSAGE,
} from './Toolbar.constant';
import { updateAllInputValidationAndReturnIfValid } from './Toolbar.utils';
import {
  clearBusinessTouchedFields as clearBusinessTouchedFieldsConnect,
  removeBusinessAddedItem as removeBusinessAddedItemConnect,
  removeBusinessDeletedItem as removeBusinessDeletedItemConnect,
  flagMenuSaved as flagMenuSavedConnect,
  flagBusinessSaved as flagBusinessSavedConnect,
  updateRemoteItemUpdated as updateRemoteItemUpdatedConnect,
} from '../../../../../actions/business';
import { clearUserTouchedField } from '../../../../../actions/user';
import { validateOnBlur as validateOnBlurConnect } from '../../../../../actions/validation-errors';
import {
  clearWebsiteTouchedFields,
  removeWebsiteAddedItem,
  removeWebsiteDeletedItem,
} from '../../../../../actions/website';
import { V_LEGACY_KEY } from '../../../../../constants/constants';
import API from '../../../../../libs/api';
import { isAuthorizedByIDRange } from '../../../../../libs/auth';
import Extract from '../../../../../libs/extract';
import GoogleAnalytics from '../../../../../libs/google-analytics';
import Save from '../../../../../libs/save';
import Validate from '../../../../../libs/validate';
import { isLegacyWebsite } from '../../../../../libs/website';
import WithRouter from '../../../../modules/foundation/components/WithRouter';
import YoutubeEmbeddedModal from '../../../YoutubeEmbeddedModal/YoutubeEmbeddedModal';
import NotificationMessage from '../NotificationModal';

import InfoIcon from '../../../../../static/icons/info-tooltip.svg';

import './Toolbar.scss';

class Toolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingStates: [],
      saveErrorMessage: null,
      showSaveErrorModal: false,
      showPublicationErrorModal: false,
      publicationErrorModalTitle: '',
      publicationErrorModalMessage: '',
      validationErrorMessage: null,
      showValidationErrorModal: false,
      showSuccessModal: false,
      published: false,
      openHelpVideoModal: false,
    };

    this.toggleLoading = this.toggleLoading.bind(this);
    this.resetModalState = this.resetModalState.bind(this);
    this.save = this.save.bind(this);
    this.publish = this.publish.bind(this);
  }

  getLoadingState(name) {
    const { loadingStates } = this.state;
    return loadingStates.includes(name);
  }

  getValidationErrorMessageView() {
    const { state, navigate } = this.props;
    const {
      inputerrors: { inputErrors },
    } = state;

    return Object.keys(inputErrors).map((route) => {
      return inputErrors[route].map((error, index) => (
        // TODO apply the following rules
        // eslint-disable-next-line react/no-array-index-key
        <p key={index}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid  */}
          <a
            href="#"
            style={{ color: 'inherit', textDecoration: 'underline' }}
            onClick={() => {
              this.resetModalState('showValidationErrorModal');
              navigate(route);
            }}
          >
            {error.message}
          </a>
        </p>
      ));
    });
  }

  getPublishTooltipMsg() {
    const { websiteVersion } = this.props;

    if (isLegacyWebsite(websiteVersion)) {
      return PRODUCT_TYPE_PUBLISH_TOOLTIP_MESSAGE_MAP.Website_Legacy;
    }

    return PRODUCT_TYPE_PUBLISH_TOOLTIP_MESSAGE_MAP.Webiste_V1;
  }

  toggleLoading(loadingState) {
    const { loadingStates } = this.state;
    let newLoadingStates = [];

    if (loadingStates.includes(loadingState)) {
      newLoadingStates = _pull(loadingStates, loadingState);
    } else {
      newLoadingStates = [...loadingStates, loadingState];
    }

    this.setState({ loadingStates: newLoadingStates });
  }

  resetModalState(name) {
    this.setState({ [name]: false });
  }

  async save() {
    const { state, clearCbs, navigate, validateOnBlur } = this.props;

    const allInputsValid = updateAllInputValidationAndReturnIfValid(validateOnBlur);

    if (allInputsValid) {
      this.toggleLoading('save');
      const errors = await Save.save(state, clearCbs, navigate);
      this.toggleLoading('save');

      if (errors.length) {
        this.setState({
          showSaveErrorModal: true,
          saveErrorMessage: this.createSaveErrorMessage(errors),
        });
      }

      this.setState({ published: false });
    } else {
      this.setState({ showValidationErrorModal: true });
    }
  }

  async publish() {
    const { websiteId, flagMenuSaved, flagBusinessSaved, isNextWebsite, updateRemoteItemUpdated } =
      this.props;

    this.toggleLoading('publish');

    try {
      await API.createPublication(websiteId, true, isNextWebsite);
      GoogleAnalytics.event('Toolbar', 'Clicked Publish', {
        label: `WebsiteId: ${websiteId}`,
      });
      this.setState({ showSuccessModal: true, published: true });
      flagMenuSaved(false);
      flagBusinessSaved(false);
      updateRemoteItemUpdated(false);
    } catch (error) {
      const status = _get(error, 'response.status');
      const data = _get(error, 'response.data');

      if (status === 409) {
        this.setState({
          showPublicationErrorModal: true,
          publicationErrorModalTitle: PUBLISH_CONFLICT_TITLE,
          publicationErrorModalMessage: PUBLISH_CONFLICT_MESSAGE,
        });
      }

      if (status === 400 && _get(data, 'code') === 'PUBLISHING') {
        this.setState({
          showPublicationErrorModal: true,
          publicationErrorModalTitle: IS_PUBLISHING_LIVE_CONFLICT_TITLE,
          publicationErrorModalMessage: IS_PUBLISHING_LIVE_CONFLICT_MESSAGE,
        });
      }
    }
    this.toggleLoading('publish');
  }

  canSave() {
    const { state } = this.props;
    const [independents, dependents] = Extract.extractApiInstructions(state);

    return independents.length !== 0 || dependents.length !== 0;
  }

  isPublishDisabled() {
    const {
      state,
      business,
      menuSaved = false,
      businessSaved = false,
      websiteVersion,
      remoteItemUpdated,
    } = this.props;
    const { published } = this.state;

    // If can save or is loading
    const loadingOrCanSave =
      this.getLoadingState('save') || this.getLoadingState('publish') || this.canSave();

    // If id is not in range
    const isNotAuthorized = !isAuthorizedByIDRange(
      business,
      process.env.REACT_APP_PUBLISH_ID_LOW,
      process.env.REACT_APP_PUBLISH_ID_HIGH,
    );
    if (isNotAuthorized && websiteVersion !== 'v_1') return true;
    // Allow publish if a remote item (not in local state) was updated
    if (remoteItemUpdated) return false;
    // If data set is not complete, disable publish
    if (!Validate.validateAllSections(state)) return true;
    // If menu is not saved and there was just a publish, disable publish
    if (!menuSaved && !businessSaved && published) return true;
    // If legacy site, disable publish
    if (websiteVersion === V_LEGACY_KEY) return true;
    // If can save, disable publish
    return !!loadingOrCanSave;
  }

  // eslint-disable-next-line class-methods-use-this
  createSaveErrorMessage(response) {
    return (
      <>
        {response.map(({ model, errors }) => (
          <div key={`${model}`}>
            <br />
            <Header as="h4">{_startCase(model)}</Header>
            <List>
              {typeof errors === 'string' ? (
                <List.Item key="server-error">Server Error</List.Item>
              ) : (
                <>
                  {Object.keys(errors).map((errorKey) => (
                    <List.Item key={errorKey}>
                      {_startCase(errorKey)}: {errors[errorKey]}
                    </List.Item>
                  ))}
                </>
              )}
            </List>
          </div>
        ))}
      </>
    );
  }

  render() {
    const { name, youtubeId } = this.props;
    const {
      saveErrorMessage,
      showSaveErrorModal,
      showPublicationErrorModal,
      publicationErrorModalTitle,
      publicationErrorModalMessage,
      validationErrorMessage,
      showValidationErrorModal,
      showSuccessModal,
      openHelpVideoModal,
    } = this.state;

    return (
      <div className="toolbar">
        {showSaveErrorModal && (
          <NotificationMessage
            callback={() => this.resetModalState('showSaveErrorModal')}
            type="negative"
            initialOpen={showSaveErrorModal}
            title={SAVE_ERROR_MODAL_TITLE}
            primaryMessage={SAVE_ERROR_MESSAGE}
            secondaryMessage={saveErrorMessage}
          />
        )}
        {showPublicationErrorModal && (
          <NotificationMessage
            callback={() => this.resetModalState('showPublicationErrorModal')}
            type="negative"
            initialOpen={showPublicationErrorModal}
            title={PUBLISH_ERROR_MODAL_TITLE}
            primaryMessage={publicationErrorModalTitle}
            secondaryMessage={publicationErrorModalMessage}
            timeout={8000}
          />
        )}
        {showValidationErrorModal && (
          <NotificationMessage
            callback={() => this.resetModalState('showValidationErrorModal')}
            type="negative"
            initialOpen={showValidationErrorModal}
            title={VALIDATION_ERROR_MODAL_TITLE}
            primaryMessage={VALIDATION_ERROR_MESSAGE}
            secondaryMessage={validationErrorMessage}
            additionalComponent={this.getValidationErrorMessageView()}
            timeout={0}
          />
        )}
        {showSuccessModal && (
          <NotificationMessage
            callback={() => this.resetModalState('showSuccessModal')}
            type="positive"
            initialOpen={showSuccessModal}
            title={PUBLISH_SUCCESS_MODAL_TITLE}
            primaryMessage={PUBLISH_SUCCESS_PRIMARY_MESSAGE}
            secondaryMessage={PUBLISH_SUCCESS_SECONDARY_MESSAGE}
            timeout={8000}
          />
        )}
        <div>
          <h2 className="title">
            {name}
            {youtubeId && (
              <>
                <Image
                  src={InfoIcon}
                  className="open-help-video-modal-button"
                  onClick={() => {
                    this.setState({ openHelpVideoModal: true });
                  }}
                />
                <YoutubeEmbeddedModal
                  youtubeId={youtubeId}
                  open={openHelpVideoModal}
                  onClose={() => {
                    this.setState({ openHelpVideoModal: false });
                  }}
                />
              </>
            )}
          </h2>
        </div>
        <Popup
          content={SAVE_TOOLTIP_MESSAGE}
          key="save"
          header="Save"
          className="toolbar-save-tooltip-popup"
          trigger={
            <Button
              onClick={() => this.save()}
              loading={this.getLoadingState('save')}
              disabled={
                this.getLoadingState('save') || this.getLoadingState('publish') || !this.canSave()
              }
              primary
              content="Save"
              icon="save outline"
              labelPosition="left"
            />
          }
        />
        <Popup
          content={this.getPublishTooltipMsg()}
          key="publish"
          header="Publish"
          trigger={
            <div>
              <Button
                onClick={() => this.publish()}
                loading={this.getLoadingState('publish')}
                disabled={this.isPublishDisabled()}
                primary
                content="Publish"
                icon="cloud upload"
                labelPosition="left"
              />
            </div>
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  flagMenuSaved: (payload) => dispatch(flagMenuSavedConnect(payload)),
  flagBusinessSaved: (payload) => dispatch(flagBusinessSavedConnect(payload)),
  clearCbs: {
    business: {
      PUT: (payload) => dispatch(clearBusinessTouchedFieldsConnect(payload)),
      DELETE: (payload) => dispatch(removeBusinessDeletedItemConnect(payload)),
      POST: (payload) => dispatch(removeBusinessAddedItemConnect(payload)),
    },
    website: {
      PUT: (payload) => dispatch(clearWebsiteTouchedFields(payload)),
      DELETE: (payload) => dispatch(removeWebsiteDeletedItem(payload)),
      POST: (payload) => {
        dispatch(removeWebsiteAddedItem(payload));
        dispatch(clearWebsiteTouchedFields(payload));
      },
    },
    user: {
      PUT: (payload) => dispatch(clearUserTouchedField(payload)),
    },
  },
  validateOnBlur: (payload) => dispatch(validateOnBlurConnect(payload)),
  updateRemoteItemUpdated: (payload) => dispatch(updateRemoteItemUpdatedConnect(payload)),
});

const mapStateToProps = (state) => ({
  business: _get(state, 'business.core.value'),
  websiteId: _get(state, 'website.core.value.id'),
  websiteVersion: _get(state, 'website.core.value.workspace_version'),
  selectedFeatures: _get(state, 'website.core.value.features'),
  menuSaved: _get(state, 'business.menu.value.__saved'),
  businessSaved: _get(state, 'business.core.value.__saved'),
  isNextWebsite: _get(state, 'website.isNextWebsite'),
  remoteItemUpdated: _get(state, 'business.remoteItemUpdated'),
  state,
});

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Toolbar));
