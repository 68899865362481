import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, Message } from 'semantic-ui-react';

import AceEditor from 'react-ace';

import { PARSING_ERROR_MESSAGES } from './ModalInsertHere.constants';
import CloseableModal from '../../../../../common/CloseableModal';

import './ModalInsertHere.scss';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onInsert: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ModalInsertHere({ open, onInsert, onClose }) {
  const [editorState, setEditorState] = useState({
    value: {},
    parsingError: false,
  });

  function clearState() {
    setEditorState({
      value: {},
      parsingError: false,
    });
  }

  function handleInsertJson() {
    onInsert(editorState.value);
    clearState();
    onClose();
  }

  function handleEditorChanges(value) {
    try {
      const parsedJSON = JSON.parse(value);
      setEditorState({
        value: parsedJSON,
        parsingError: false,
      });
    } catch (e) {
      setEditorState({
        value,
        parsingError: true,
      });
    }
  }

  const { value, parsingError } = editorState;

  return (
    <CloseableModal
      open={open}
      className="recipe-insert-here-modal"
      header="Insert here..."
      onClose={onClose}
      size="small"
    >
      <Modal.Content>
        <AceEditor
          readOnly={false}
          mode="json"
          name="insert-here-editor"
          theme="solarized_light"
          onChange={handleEditorChanges}
          fontSize={14}
          showPrintMargin
          showGutter
          highlightActiveLine
          value={parsingError ? value : JSON.stringify(value, null, 2)}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        {parsingError && <Message error header="Parsing Error" list={PARSING_ERROR_MESSAGES} />}
        <Button className="action" content="Insert" onClick={() => handleInsertJson()} />
      </Modal.Actions>
    </CloseableModal>
  );
}

ModalInsertHere.propTypes = propTypes;

export default ModalInsertHere;
