import React from 'react';

import Add from '../static/add.png';

const PlusIcon = (props) => {
  const { style } = props;

  if (style) {
    return <img src={Add} alt="Add Schedule" style={style} />;
  }

  return <img src={Add} alt="Add Schedule" />;
};

export default PlusIcon;
