import React from 'react';

import { Tab } from 'semantic-ui-react';

import useRecipe from 'components/modules/foundation/hooks/use-recipe';
import { areTeamsSupported } from 'components/modules/team/services/team';
import { DismissibleMessage, ErrorBoundary } from 'components/modules/utils/components';
import { DEFAULT_ERROR_MESSAGE } from 'libs/errors/constants';

import TeamMembersTab from './components/TeamMembersTab';
import TeamsTab from './components/TeamsTab';

import './TeamPage.scss';

export default function TeamPage() {
  const { activePatch } = useRecipe();

  const displayTeamTab = areTeamsSupported(activePatch);

  let panes = [
    {
      menuItem: 'STAFF',
      pane: (
        <Tab.Pane attached={false} key="staff">
          <TeamMembersTab />
        </Tab.Pane>
      ),
    },
  ];

  if (displayTeamTab) {
    panes = [
      ...panes,
      {
        menuItem: 'TEAMS',
        pane: (
          <Tab.Pane attached={false} key="teams">
            <TeamsTab />
          </Tab.Pane>
        ),
      },
    ];
  }

  return (
    <ErrorBoundary
      fallback={<DismissibleMessage initialVisible content={DEFAULT_ERROR_MESSAGE} error />}
    >
      <div className="team-page">
        <Tab
          className="main-tab"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          renderActiveOnly={false}
        />
      </div>
    </ErrorBoundary>
  );
}
