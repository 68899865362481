import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { SOURCE_TYPE_DEFAULT } from '../constants/sources';

export function isPdfFile(url = '') {
  const extension = (url || '').slice(-4);
  return extension.toLowerCase() === '.pdf';
}

export function stringifyBytes(bytes, { decimals = 2, marker = 1024 } = {}) {
  if (bytes === 0) return '0 Bytes';

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(marker));

  return `${parseFloat((bytes / marker ** i).toFixed(decimals))} ${sizes[i]}`;
}

export function createLocalFileObject(file = {}) {
  const finalFile = _get(file, 'file', file);
  return {
    name: finalFile.name,
    size: finalFile.size,
    url: window.URL.createObjectURL(finalFile),
    file: finalFile,
    source: finalFile.source || file.source || SOURCE_TYPE_DEFAULT,
  };
}

export function createFilePayload(file = {}) {
  return {
    file: file.file,
    filename: file.name,
    description: '',
    source: file.source || SOURCE_TYPE_DEFAULT,
  };
}

export function getFileNameAndExtension(url = '') {
  const { pathname } = new URL(url);
  const fileNameWithExtension = pathname.split('/').pop() || 'default.png';
  const [fileName, extension = 'png'] = fileNameWithExtension.split(/\.(?=[^.]+$)/);

  return { fileName, extension };
}

export async function downloadImage(url) {
  if (_isEmpty(url)) {
    return null;
  }
  const { fileName, extension } = getFileNameAndExtension(url);
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.crossOrigin = 'Anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => {
        canvas.remove();
        const file = new File([blob], `${fileName}.${extension}`, { type: blob.type });
        resolve(file);
      }, `image/${extension}`);
    };

    img.onerror = (err) => reject(err);
  });
}

export async function fileToBase64Url(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export function imageMimeTypeToExtension(mimeType) {
  const mimeTypes = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/webp': 'webp',
  };

  return mimeTypes[mimeType];
}

export function dataUriToMimeType(dataUri) {
  return dataUri.split(',')[0].split(':')[1].split(';')[0];
}

export function dataUriToBlob(dataUri) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUri.split(',')[1]);

  // separate out the mime component
  const mimeString = dataUriToMimeType(dataUri);

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mimeString });
}

export function dataUriToFile(dataUri, filename = 'file') {
  const mimeType = dataUriToMimeType(dataUri);
  let finalFilename = filename;
  if (filename.indexOf('.') === -1) {
    finalFilename = `${filename}.${imageMimeTypeToExtension(mimeType)}`;
  }
  return new File([dataUriToBlob(dataUri)], finalFilename, { type: mimeType });
}
