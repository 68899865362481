import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { splitSchemaAndURL } from './URISchemeField.utils';

import './URISchemeField.css';

const propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  placeholder: 'https://www.example.com',
  disabled: false,
};

function URISchemeField({ value, placeholder, onChange, disabled }) {
  const [localValue, setLocalValue] = useState('');
  const [scheme, setScheme] = useState('');

  function updateStateWithValue(newValue) {
    const { scheme: newScheme, url: newURL } = splitSchemaAndURL(newValue);
    setLocalValue(newURL);
    setScheme((oldScheme) => {
      if (newScheme !== oldScheme && newScheme !== null) {
        return newScheme;
      }
      return oldScheme;
    });
  }

  useEffect(() => {
    updateStateWithValue(value);
  }, [value]);

  function handleInputChange(e, data) {
    const { value: targetValue } = data;

    updateStateWithValue(targetValue);
    const { scheme: currentScheme, url: currentURL } = splitSchemaAndURL(targetValue);

    const finalValue = currentScheme + currentURL;

    if (onChange) {
      onChange(e, { ...data, value: finalValue });
    }
  }

  return (
    <Input
      label={scheme}
      type="text"
      value={localValue}
      onChange={handleInputChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}

export default URISchemeField;

URISchemeField.propTypes = propTypes;
URISchemeField.defaultProps = defaultProps;
