import React from 'react';

import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { FULFILLMENT_DETAILS_HEADER } from './FulfillmentDetails.constants';
import { getAddressDisplay } from '../../../../../../../libs/location';
import { formatFullName } from '../../OrderDetailsModal.utils';

import './FulfillmentDetails.scss';

const propTypes = {
  fulfillmentType: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  address: PropTypes.shape({
    streetAddress: PropTypes.string.isRequired,
    locality: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    country: PropTypes.string,
  }),
};

const defaultProps = {
  address: {},
};

export default function FulfillmentDetails({ fulfillmentType, customer, address }) {
  const { firstName, lastName } = customer;

  return (
    <>
      <Header as="h3">{FULFILLMENT_DETAILS_HEADER}</Header>
      {fulfillmentType}
      <br />
      {formatFullName(firstName, lastName)}
      <br />
      {getAddressDisplay(address) || 'N/A'}
    </>
  );
}

FulfillmentDetails.propTypes = propTypes;
FulfillmentDetails.defaultProps = defaultProps;
