import React from 'react';

import { Table } from 'semantic-ui-react';

import { getStyleRulesTableColumnConfigs } from './StyleRulesTable.utils';
import { StyleRulesTableHeader, StyleRulesTableRow } from './components';
import { StyleRulesPropType } from '../../types';

import './StyleRulesTable.scss';

const propTypes = {
  styleRules: StyleRulesPropType.isRequired,
};

const defaultProps = {};

export default function StyleRulesTable({ styleRules }) {
  return (
    <Table compact celled className="style-rules-table">
      <StyleRulesTableHeader columnConfigs={getStyleRulesTableColumnConfigs()} />
      <Table.Body>
        {styleRules.map((styleRule) => (
          <StyleRulesTableRow key={styleRule.id} styleRule={styleRule} />
        ))}
      </Table.Body>
    </Table>
  );
}

StyleRulesTable.propTypes = propTypes;
StyleRulesTable.defaultProps = defaultProps;
