import { CHANNELS } from '../../../../../../../../../constants/custom-forms';

export function getChannelOptions(currentValue) {
  const channels = [...CHANNELS];
  if (!channels.includes(currentValue)) {
    channels.push(currentValue);
  }

  return channels.map((channel) => ({
    key: channel,
    text: channel,
    value: channel,
  }));
}
