export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_ACTIVE_LOCATION':
      return {
        ...state,
        activeLocation: payload,
      };

    default:
      return state;
  }
};
