import React from 'react';

import { Tab, Form, Radio, Container, Button, Table } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { InventoryConsumer, InventoryProvider } from './Menu.context';
import { createInitialMenuContext, shouldDisplayQRCode } from './MenuForm.utils';
import { chooseMenuSource as chooseMenuSourceConnect } from '../../../actions/business';
import { STACK_STATUS_LIVE, SUPPORT_EMAIL } from '../../../constants/constants';
import { DESTINATION_MENU } from '../../../constants/menu';
import { getBaseURLNoSlash } from '../../../constants/urls';
import { isAdminUser } from '../../../libs/auth';
import { isDigitalMenuConfigured, isFileMenuConfigured, isMenuFile } from '../../../libs/menu';
import { selectActiveWebsitePatch, selectWebsitePatches } from '../../../selectors/website';
import { InlineFieldContainer } from '../../common';
import ExternalAnchor from '../../common/ExternalAnchor/ExternalAnchor';
import FormContainer from '../../common/FormContainer';
import HelpTooltip from '../../common/HelpTooltip';
import MenuUpload from '../../common/MenuUpload';
import MenuEditor from '../menu/MenuEditor';
import MenuFiles from '../menu/MenuFiles/MenuFiles';
import WoflowMenuActions from '../menu/WoflowMenuActions';

import '../../../styles/core/forms/menu.scss';

class MenuForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      activeIndex: this.getDefaultIndex(),
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.handleMenuChange = this.handleMenuChange.bind(this);
    this.uploadComplete = this.uploadComplete.bind(this);
    this.generatePanes = this.generatePanes.bind(this);
    this.getMenuTypeSelector = this.getMenuTypeSelector.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;

    if (isAdminUser(user)) {
      this.setState({ isAdmin: true });
    }
  }

  handleMenuChange(e, target) {
    const { chooseMenuSource } = this.props;
    const { value } = target;
    chooseMenuSource({ value });
  }

  onTabChange(event, data) {
    this.setState({ activeIndex: data.activeIndex });
  }

  getQRCode() {
    const { businessId, businessType } = this.props;
    const qrCodePath = `${getBaseURLNoSlash()}/businesses/${businessType}s/${businessId}/qrcode/`;

    return (
      <InventoryConsumer>
        {({ entityName }) => (
          <InlineFieldContainer
            title="QR Code"
            tooltipTitle="What is a QR Code?"
            tooltipDescription={`A QR Code is a type of barcode that allows users to quickly access your website using the camera on their phone. Download, print, and place the QR Code in your Business for customers to quickly access your ${entityName} from their phone.`}
            enableDelete={false}
          >
            <ExternalAnchor href={qrCodePath} download>
              <Button primary>Download QR Code</Button>
            </ExternalAnchor>
          </InlineFieldContainer>
        )}
      </InventoryConsumer>
    );
  }

  getMenuTypeSelector() {
    const { menuSourceType, menuFiles, digitalMenu } = this.props;

    return (
      <InventoryConsumer>
        {({
          entityName,
          copies: {
            filesOptionsName,
            itemsOptionsName,
            bothOptionsName,
            integrationOptionName,
            noneOptionsName,
          },
        }) => (
          <InlineFieldContainer title={`${entityName} Display`} enableDelete={false}>
            <Form>
              <Form.Field>
                <Radio
                  label={filesOptionsName}
                  name="menuRadio"
                  value="Files"
                  checked={menuSourceType.toLowerCase() === 'files'}
                  onChange={this.handleMenuChange}
                />
                <HelpTooltip
                  title={filesOptionsName}
                  content={`Choosing this will add your uploaded files to the ${entityName} page`}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={itemsOptionsName}
                  name="menuRadio"
                  value="Items"
                  checked={menuSourceType.toLowerCase() === 'items'}
                  onChange={this.handleMenuChange}
                />
                <HelpTooltip
                  title={itemsOptionsName}
                  content={`Choosing this will add a Search Engine Optimized (SEO) ${entityName} to the ${entityName} page`}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={bothOptionsName}
                  name="menuRadio"
                  value="FilesAndItems"
                  checked={menuSourceType.toLowerCase() === 'filesanditems'}
                  disabled={
                    !(isDigitalMenuConfigured(digitalMenu) && isFileMenuConfigured(menuFiles))
                  }
                  onChange={this.handleMenuChange}
                />
                <HelpTooltip
                  title={bothOptionsName}
                  content={`Display your ${entityName} as both a PDF and a digitized version`}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={integrationOptionName}
                  name="menuRadio"
                  value="Integration"
                  checked={menuSourceType.toLowerCase() === 'integration'}
                  onChange={this.handleMenuChange}
                  disabled
                />
                <HelpTooltip
                  title={`Integration based ${entityName}`}
                  content="This will be automatically selected if you have an integration that supports this"
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={noneOptionsName}
                  name="menuRadio"
                  value=""
                  checked={menuSourceType === ''}
                  onChange={this.handleMenuChange}
                />
                <HelpTooltip
                  title={`No ${entityName}`}
                  content={`If you have previously added a ${entityName} page, and need it removed, please contact ${SUPPORT_EMAIL}`}
                />
              </Form.Field>
            </Form>
          </InlineFieldContainer>
        )}
      </InventoryConsumer>
    );
  }

  getDefaultIndex() {
    const { menuSourceType } = this.props;
    if (menuSourceType === 'Items') return 1;
    return 0;
  }

  uploadComplete() {
    // Go to Digital Menu when an upload has completed
    this.setState({ activeIndex: 1 });
  }

  generatePanes(filesTabName, itemsTabName) {
    const { isAdmin } = this.state;

    const panes = [
      {
        menuItem: `Upload ${filesTabName}`,
        render: () => (
          <Container style={{ position: 'relative ' }}>
            <MenuFiles />
          </Container>
        ),
      },
      {
        menuItem: `Edit ${itemsTabName}`,
        render: () => (
          <Container style={{ position: 'relative ' }}>
            <MenuEditor />
          </Container>
        ),
      },
    ];

    if (isAdmin) {
      panes.push(
        {
          menuItem: 'JSON Upload',
          render: () => (
            <Container style={{ position: 'relative ' }}>
              <MenuUpload onClose={this.uploadComplete}>
                {({ entityName, isLoading, onClick }) => (
                  <InlineFieldContainer
                    title={`${entityName} Upload`}
                    enableDelete={false}
                    tooltipTitle={`${entityName} Upload`}
                    tooltipDescription={`From here you can upload a ${entityName} JSON file, you will be asked if you want to replace previous ${entityName}s or not`}
                  >
                    <Table>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell collapsing>Upload {entityName} JSON File</Table.Cell>
                          <Table.Cell collapsing textAlign="right">
                            <Button
                              icon="plus"
                              content="Upload"
                              loading={isLoading}
                              onClick={onClick}
                            />
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </InlineFieldContainer>
                )}
              </MenuUpload>
            </Container>
          ),
        },
        {
          menuItem: 'Upload Woflow Menu',
          render: () => (
            <Container className="menu-item-container-pane">
              <WoflowMenuActions jobDestination={DESTINATION_MENU} />
            </Container>
          ),
        },
      );
    }

    return panes;
  }

  render() {
    const { menuSourceType, stackStatus, businessCategory, activePatch } = this.props;
    const { activeIndex } = this.state;
    const isLive = stackStatus === STACK_STATUS_LIVE;
    const isDisplayQRCode = shouldDisplayQRCode(menuSourceType);

    const context = { ...createInitialMenuContext(businessCategory), activePatch };
    const {
      copies: { filesOptionsName, itemsOptionsName },
    } = context;
    return (
      <InventoryProvider value={context}>
        <FormContainer loadedKeyPath={['business', 'core']}>
          {this.getMenuTypeSelector()}
          {isLive && isDisplayQRCode && this.getQRCode()}
          <Tab
            activeIndex={activeIndex}
            menu={{ secondary: true, pointing: true }}
            panes={this.generatePanes(filesOptionsName, itemsOptionsName)}
            onTabChange={this.onTabChange}
          />
        </FormContainer>
      </InventoryProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  chooseMenuSource: (payload) => dispatch(chooseMenuSourceConnect(payload)),
});

const mapStateToProps = ({ business, user, website }) => ({
  user: _get(user, 'core.value'),
  businessId: _get(business, 'core.value.id'),
  businessType: _get(business, 'core.value.type'),
  businessCategory: _get(business, 'core.value.category'),
  businessLogo: _get(business, 'core.value.logo'),
  menuSourceType: _get(business, 'core.value.menu_source_type'),
  menuFiles: (_get(business, 'galleryImages.value') || []).filter(isMenuFile),
  digitalMenu: business.menu.value,
  stackStatus: _get(business, 'core.value.website.stack_status'),
  activePatch: selectActiveWebsitePatch(selectWebsitePatches({ website })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuForm);
