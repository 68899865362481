import { isTouchScreenDevice } from './use-responsive.utils';
import useWindowSize from '../use-window-size';

export default function useResponsive() {
  const { width, loaded } = useWindowSize();

  return {
    isMobile: !width || width <= 767,
    isTablet: width >= 768 && width <= 991,
    isSmallMonitor: width >= 992 && width <= 1199,
    isLargeMonitor: width >= 1200 && width <= 1919,
    isWideScreenMonitor: width >= 1920,

    isSmallScreen: !width || width <= 991,
    isDesktop: width >= 992,
    isTouchScreenDevice: isTouchScreenDevice(),
    loaded,
  };
}
