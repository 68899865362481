import {
  ROW_ELEMENT_TYPE,
  COLUMN_ELEMENT_TYPE,
  FIXED_COLUMN_SIZE_MODE,
  AUTO_ADJUSTED_COLUMN_SIZE_MODE,
} from '../../constants/types';

export const TEXT_ALIGNMENT_OPTIONS = ['left', 'center', 'right', 'justified'];

export const VERTICAL_ALIGNMENT_OPTIONS = ['top', 'middle', 'bottom'];

export const WIDTH_OPTIONS = [AUTO_ADJUSTED_COLUMN_SIZE_MODE, FIXED_COLUMN_SIZE_MODE];

export const ELEMENT_TYPE_DISPLAY_NAME = {
  [ROW_ELEMENT_TYPE]: 'row',
  [COLUMN_ELEMENT_TYPE]: 'column',
};
