import React, { useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Message, Segment, Select } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  PULL_MERCHANT_INFO,
  PULL_MERCHANT_LOCATIONS_INFO,
} from './SquareIntegrationSettings.constants';
import { getOptions } from './SquareIntegrationSettings.utils';
import { isAdminUser } from '../../../../../../libs/auth';
import { ObjectPropType } from '../../../../../../libs/proptypes';
import { selectBusinessBrandIdentity } from '../../../../../../selectors/business';
import { selectUser } from '../../../../../../selectors/user';
import AdminRequiredMessage from '../../../components/AdminRequiredMessage';

import './SquareIntegrationSettings.scss';

const propTypes = {
  currentData: ObjectPropType,
  onDisconnect: PropTypes.func.isRequired,
  onPullMerchantInfo: PropTypes.func.isRequired,
  onPullMerchantLocationsInfo: PropTypes.func.isRequired,
};

const defaultProps = {
  currentData: {},
};

export default function SquareIntegrationSettings({
  currentData,
  onDisconnect: onDisconnectProp,
  onPullMerchantInfo,
  onPullMerchantLocationsInfo,
}) {
  const dataDisplayContainerRef = useRef(null);
  const brandIdentity = useSelector(selectBusinessBrandIdentity);
  const [selectedOption, setSelectedOption] = useState(PULL_MERCHANT_INFO);
  const [disconnectLoading, setDisconnectLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    if (dataDisplayContainerRef.current) {
      dataDisplayContainerRef.current.innerText = JSON.stringify(currentData, null, 2);
    }
  }, [currentData]);

  const user = useSelector(selectUser);
  const isAdmin = isAdminUser(user);

  const options = getOptions();

  function onSelectedOptionChange(event, { value: newOption }) {
    setSelectedOption(newOption);
  }

  async function executeAction() {
    try {
      setActionLoading(true);
      const dispatchAction = {
        [PULL_MERCHANT_INFO]: onPullMerchantInfo,
        [PULL_MERCHANT_LOCATIONS_INFO]: onPullMerchantLocationsInfo,
      };

      await dispatchAction[selectedOption]();
    } finally {
      setActionLoading(false);
    }
  }

  async function onDisconnect() {
    try {
      setDisconnectLoading(true);
      await onDisconnectProp();
    } finally {
      setDisconnectLoading(false);
    }
  }

  return (
    <div className="square-integration-settings">
      <Segment attached>
        {!isAdmin && <AdminRequiredMessage brandIdentity={brandIdentity} />}
        {isAdmin && (
          <>
            <div className="actions">
              <Message>
                <Message.Header>Pull Information From Your Square Profile</Message.Header>
                <p>
                  Select the dropdown option below and then click on the &quot;Execute&quot; button
                  to pull information from your Square Profile. Choose which information to pull
                  such as your Merchant Information and Merchant Location Information. Pulled data
                  will be shown as a JSON structure below.
                </p>
              </Message>
              <div className="sync-actions">
                <Select
                  placeholder="Select action"
                  options={options}
                  value={selectedOption}
                  onChange={onSelectedOptionChange}
                />
                <Button
                  primary
                  onClick={executeAction}
                  content="Execute"
                  loading={actionLoading}
                  disabled={actionLoading || disconnectLoading}
                />
              </div>
            </div>
            {!_isEmpty(currentData) && !actionLoading && (
              <div className="data">
                <pre ref={dataDisplayContainerRef} />
              </div>
            )}
          </>
        )}
      </Segment>
      <Segment attached className="disconnect-container" textAlign="center">
        <Button
          size="big"
          negative
          onClick={onDisconnect}
          content="Disconnect"
          loading={disconnectLoading}
          disabled={disconnectLoading || actionLoading}
        />
      </Segment>
    </div>
  );
}

SquareIntegrationSettings.propTypes = propTypes;
SquareIntegrationSettings.defaultProps = defaultProps;
