import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { objectToCamelCase, objectToSnakeCase } from '../../../../../../../libs/format';
import { selectEntityId } from '../../../../../../../libs/slices';
import { GenericComponentConfigurationPropType } from '../../../../../../modules/component-configuration/proptypes';
import { CuratedHeroContext } from '../../../../../../modules/custom-pages/contexts/CuratedHero.context';
import WebsiteComponentInteractionContainer from '../../../../../../modules/website-component-interaction/WebsiteComponentInteractionContainer';

const propTypes = {
  componentConfiguration: GenericComponentConfigurationPropType.isRequired,
  settings: PropTypes.shape({
    maxCtasAllowed: PropTypes.number,
  }),
};
const defaultProps = {
  settings: { maxCtasAllowed: 1 },
};

export default function CuratedHeroCtaInput({ componentConfiguration, settings }) {
  const { updateComponentConfiguration } = useContext(CuratedHeroContext);
  const { maxCtasAllowed = 1 } = settings;

  function onUpdate(interactions) {
    const componentConfigurationId = selectEntityId(componentConfiguration);
    updateComponentConfiguration(componentConfigurationId, 'interactions', interactions);
  }

  return (
    <div className="form-item">
      <WebsiteComponentInteractionContainer
        interactions={objectToSnakeCase(componentConfiguration.interactions)}
        maxAllowedInteractions={maxCtasAllowed}
        onUpdate={(interactions) => onUpdate(objectToCamelCase(interactions))}
      />
    </div>
  );
}

CuratedHeroCtaInput.propTypes = propTypes;
CuratedHeroCtaInput.defaultProps = defaultProps;
