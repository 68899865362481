import React from 'react';

import moment from 'moment';

import { InventoryConsumer } from '../../MenuForm/Menu.context';

export default class ViewMenuScheduleAvailability extends React.Component {
  static formatAvailableDay(day) {
    return `${day[0] + day.slice(1).toLowerCase()}`;
  }

  isAvailableAllWeek() {
    const { availableDays } = this.props;

    return availableDays.length === 1 && availableDays[0] === 'ALL';
  }

  renderAvailableDays() {
    const { availableDays } = this.props;

    return (
      <>
        {availableDays.map((day, index) => (
          <span key={day}>
            {ViewMenuScheduleAvailability.formatAvailableDay(day)}
            {index < availableDays.length - 1 && ', '}
          </span>
        ))}
      </>
    );
  }

  renderAvailableTimes() {
    const { startTime, endTime } = this.props;

    if (startTime && endTime) {
      return (
        <>
          <span>{moment(startTime, 'HH:mm').format('h:mm A')}</span>
          <span> to </span>
          <span>{moment(endTime, 'HH:mm').format('h:mm A')}</span>
        </>
      );
    }

    return 'All business hours';
  }

  render() {
    return (
      <InventoryConsumer>
        {({ entityName }) => (
          <div>
            <h3>Availability</h3>
            <div>Customers can order from this {entityName} during these times:</div>
            <br />
            <div>
              <i>Days: </i>
              {this.isAvailableAllWeek() ? 'All business hours' : this.renderAvailableDays()}
            </div>
            <div>
              <i>Times: </i>
              {this.isAvailableAllWeek() ? 'All business hours' : this.renderAvailableTimes()}
            </div>
          </div>
        )}
      </InventoryConsumer>
    );
  }
}
