import { COLUMN_COMPONENT_NAME, COLUMN_ELEMENT_TYPE } from '../../../../constants/types';
import {
  lookupElementType,
  lookupElementTypeByComponentName,
  translateChildren,
} from '../utils/children';
import { addComponentChildren, createSemanticComponent, getComponentId } from '../utils/components';
import {
  addStaticPropIfDefined,
  createComponentBaseProps,
  extractComponentBaseProps,
  getStaticPropValue,
} from '../utils/props';

export function createColumnElement(data = {}, context = {}) {
  const { id, className, style, width, stretched, textAlign, verticalAlign, segments = [] } = data;
  const { recipeStateToSnippet } = context;

  let element = createSemanticComponent(
    COLUMN_COMPONENT_NAME,
    id,
    createComponentBaseProps(className, style),
  );

  element = addStaticPropIfDefined(element, 'width', width);
  element = addStaticPropIfDefined(element, 'stretched', stretched);
  element = addStaticPropIfDefined(element, 'textAlign', textAlign);
  element = addStaticPropIfDefined(element, 'verticalAlign', verticalAlign);

  const segmentSnippets = translateChildren(segments, recipeStateToSnippet, lookupElementType, {
    context,
  });

  return addComponentChildren(element, segmentSnippets);
}

export function createColumnState(recipeSnippet = {}, context = {}) {
  const { props, children = [] } = recipeSnippet || {};
  const { recipeSnippetToState } = context;

  const type = COLUMN_ELEMENT_TYPE;
  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  const width = getStaticPropValue(props, 'width');
  const stretched = getStaticPropValue(props, 'stretched');
  const textAlign = getStaticPropValue(props, 'textAlign');
  const verticalAlign = getStaticPropValue(props, 'verticalAlign');

  const segments = translateChildren(
    children,
    recipeSnippetToState,
    lookupElementTypeByComponentName,
    { context },
  );

  return {
    type,
    id,
    className,
    style,
    width,
    stretched,
    textAlign,
    verticalAlign,
    segments,
  };
}

export function createColumnTemplateState(data = {}, context = {}) {
  const { recipeStateToTemplateState } = context;
  const { segments = [], ...restColumn } = data;

  const segmentStates = translateChildren(segments, recipeStateToTemplateState, lookupElementType, {
    context,
  });

  return {
    ...restColumn,
    segments: segmentStates,
  };
}
