import React, { useState } from 'react';

import { Header } from 'semantic-ui-react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { MENU_ITEMS } from './CatalogContainer.constants';
import LocationSelect from '../../common/LocationSelect/LocationSelect';
import SideMenuNavigation from '../../common/SideMenuNavigation';
import Toolbar from '../../common/Toolbar';
import { getDefaultLocation } from '../../settings/SettingsContainer/SettingsContainer.utils';

import './CatalogContainer.scss';

function CatalogContainer() {
  const business = useSelector((state) => _get(state, 'business'));
  const locations = _get(business, 'locations.value');

  const defaultLocationValue = getDefaultLocation(locations);
  const [location, setLocation] = useState(defaultLocationValue);

  function handleLocationOnchange(value) {
    setLocation(value);
  }

  return (
    <div className="catalog-container-ui">
      <Header as="h1" className="ecomm-section-header">
        Catalog
      </Header>
      <Toolbar>
        <Toolbar.Actions>
          <LocationSelect
            locations={locations}
            activeLocation={location}
            onChangeLocation={handleLocationOnchange}
          />
        </Toolbar.Actions>
      </Toolbar>
      <SideMenuNavigation menuItems={MENU_ITEMS} />
    </div>
  );
}

export default CatalogContainer;
