import React, { useState } from 'react';

import useAsyncEffect from '../../modules/foundation/hooks/use-async-effect';
import FontCards from '../../modules/typography/components/FontCards';
import FontVariantsList from '../../modules/typography/components/FontVariantsList';
import GoBack from '../../modules/typography/components/GoBack';
import SelectedTypography from '../../modules/typography/components/SelectedTypography';
import TypographyControls from '../../modules/typography/components/TypographyControls';
import {
  DEFAULT_CATEGORIES,
  DEFAULT_FONT_SIZE,
  DEFAULT_SENTENCE,
  DEFAULT_SORT,
} from '../../modules/typography/constants';
import { TypographyProvider } from '../../modules/typography/contexts/Typography.context';
import { getGoogleFonts } from '../../modules/typography/services/font';

import './Typography.scss';

const propTypes = {};
const defaultProps = {};

export default function TypographyPage() {
  const [allFonts, setAllFonts] = useState([]);
  const [fonts, setFonts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sentence, setSentence] = useState(DEFAULT_SENTENCE);
  const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
  const [selectedFont, setSelectedFont] = useState({});
  const [categories, setCategories] = useState(DEFAULT_CATEGORIES);
  const [sort, setSort] = useState(DEFAULT_SORT);

  useAsyncEffect(async () => {
    const googleFonts = await getGoogleFonts({ sort });
    setAllFonts(googleFonts);
    setFonts(googleFonts);
  }, []);

  const context = {
    allFonts,
    setAllFonts,
    fonts,
    setFonts,
    selectedFont,
    setSelectedFont,
    searchQuery,
    setSearchQuery,
    sentence,
    setSentence,
    fontSize,
    setFontSize,
    categories,
    setCategories,
    sort,
    setSort,
  };

  return (
    <div className="typography-page">
      <TypographyProvider value={context}>
        <GoBack />
        <SelectedTypography />
        <TypographyControls />
        <FontCards />
        <FontVariantsList />
      </TypographyProvider>
    </div>
  );
}

TypographyPage.propTypes = propTypes;
TypographyPage.defaultProps = defaultProps;
