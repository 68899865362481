import _get from 'lodash/get';
import _has from 'lodash/has';

import { getFileUrl } from '../../utils';

export function getURLToDisplay(businessFile) {
  const { file, url } = businessFile;
  return file && file.startsWith('/') ? url : file;
}

export function getImageCardExtraParams(record, onClick, isFileDisabled) {
  const fileId = _get(record, 'id');
  const isStockUrl = _has(record, 'stock_url');

  if (isStockUrl) {
    const stockUrl = _get(record, 'stock_url');

    return {
      fileId,
      url: stockUrl,
      onClick: async () => {
        await onClick(record);
      },
    };
  }
  const { filename, source } = record;
  const urlToDisplay = getURLToDisplay(record);
  const isDisabled = isFileDisabled(record);

  return {
    fileId,
    url: getFileUrl(urlToDisplay),
    title: filename,
    source,
    onClick: async () => !isDisabled && onClick(record),
  };
}
