import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _size from 'lodash/size';

export function getFileCount(filesData) {
  if (_isNil(filesData)) {
    return 0;
  }

  return Object.values(filesData).reduce((acc, fieldFiles) => acc + _size(fieldFiles), 0);
}

export function getFieldValueByName(fields, fieldName) {
  return _get(fields, fieldName, '');
}
