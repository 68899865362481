import _camelCase from 'lodash/camelCase';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _snakeCase from 'lodash/snakeCase';
import _startsWith from 'lodash/startsWith';
import _transform from 'lodash/transform';

function isFormData(obj) {
  if (!_isObject(obj)) {
    return false;
  }
  const name = _get(obj, 'constructor.name');

  return name === 'FormData';
}

export function objectToCamelCase(obj) {
  if (!_isObject(obj) || isFormData(obj)) {
    return obj;
  }

  return _transform(_cloneDeep(obj), function convertCase(result, val, key) {
    const convertedKey = _startsWith(key, '_') ? key : _camelCase(key);
    // eslint-disable-next-line no-param-reassign
    result[convertedKey] = _isObject(val) ? objectToCamelCase(val) : val;
  });
}

export function objectToSnakeCase(obj) {
  if (!_isObject(obj) || isFormData(obj)) {
    return obj;
  }

  return _transform(_cloneDeep(obj), function convertCase(result, val, key) {
    // eslint-disable-next-line no-param-reassign
    result[_snakeCase(key)] = _isObject(val) ? objectToSnakeCase(val) : val;
  });
}

function getSnakeCaseKey(key) {
  if (!_isString(key)) {
    return _snakeCase(key);
  }
  return key.split('.').map(_snakeCase).join('.');
}

function getCamelCaseKey(key) {
  if (!_isString(key)) {
    return _camelCase(key);
  }
  return key.split('.').map(_camelCase).join('.');
}

export function multiCaseHas(obj = {}, key = '') {
  return _has(obj, key) || _has(obj, getSnakeCaseKey(key)) || _has(obj, getCamelCaseKey(key));
}

export function multiCaseGet(obj = {}, key = '') {
  return _get(obj, key) || _get(obj, getSnakeCaseKey(key)) || _get(obj, getCamelCaseKey(key));
}
