import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import CloseableModal from 'components/common/CloseableModal';
import { DismissibleMessage } from 'components/modules/utils/components';
import ErrorHandler from 'libs/errors/errors';

import {
  ADD_REDIRECT_HEADER_TITLE,
  EDIT_REDIRECT_HEADER_TITLE,
} from './EditRedirectModal.constants';
import { getPathValidationMessage } from './EditRedirectModal.utils';
import useRedirect from '../../hooks/use-redirect';
import { RedirectPropType } from '../../types/redirect';

import './EditRedirectModal.scss';

const propTypes = {
  redirect: RedirectPropType,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  trigger: PropTypes.node,
};

const defaultProps = {
  redirect: {},
  trigger: null,
};

export default function EditRedirectModal({
  redirect: initialFormData,
  open,
  onClose: onCloseProp,
  trigger,
}) {
  const { id: redirectId } = initialFormData || {};

  const { createRedirect, updateRedirect, loading, errorMessage } = useRedirect({
    initialFetch: false,
  });

  const [touched, setTouched] = useState(false);
  const [formData, setFormData] = useState({ ...(initialFormData || {}) });
  const [formValidationMessage, setFormValidationMessage] = useState('');

  const { oldPath = '', newPath = '', description = '' } = formData || {};

  const isEdit = !_isNil(redirectId);

  function resetFormValues() {
    setTouched(false);
    setFormData({});
    setFormValidationMessage('');
  }

  async function onClose() {
    resetFormValues();
    if (onCloseProp) {
      await onCloseProp();
    }
  }

  function onInputChange(e, { value, name: inputName }) {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [inputName]: value,
    }));
  }

  function isOldPathValid() {
    return getPathValidationMessage(oldPath, newPath, 'From') === '';
  }

  function isNewPathValid() {
    return getPathValidationMessage(newPath, oldPath, 'To') === '';
  }

  function getFormValidationMessage() {
    const fromPathValidationMessage = getPathValidationMessage(oldPath, newPath, 'From');
    const toPathValidationMessage = getPathValidationMessage(newPath, oldPath, 'To');
    return fromPathValidationMessage || toPathValidationMessage || '';
  }

  useEffect(() => {
    setFormValidationMessage(getFormValidationMessage());
  }, [formData]);

  async function onSave() {
    setTouched(true);

    try {
      const formErrorMessage = getFormValidationMessage();
      if (!_isEmpty(formErrorMessage)) {
        setFormValidationMessage(formErrorMessage);
        return;
      }

      if (isEdit) {
        await updateRedirect(redirectId, formData);
      } else {
        await createRedirect(formData);
      }

      await onClose();
    } catch (error) {
      ErrorHandler.capture(error);
    }
  }

  const finalErrorMessage = errorMessage || formValidationMessage;

  return (
    <CloseableModal
      className="edit-redirect-modal"
      open={open}
      onClose={onClose}
      trigger={trigger}
      header={isEdit ? EDIT_REDIRECT_HEADER_TITLE : ADD_REDIRECT_HEADER_TITLE}
    >
      <Modal.Content>
        <Form>
          <Form.Input
            label="From"
            value={oldPath}
            className="from"
            name="oldPath"
            required
            onChange={onInputChange}
            error={touched && !isOldPathValid()}
          />

          <Form.Input
            label="To"
            value={newPath}
            className="to"
            name="newPath"
            required
            onChange={onInputChange}
            error={touched && !isNewPathValid()}
          />

          <Form.TextArea
            label="Description"
            value={description}
            name="description"
            onChange={onInputChange}
          />

          {!_isEmpty(finalErrorMessage) && touched && (
            <DismissibleMessage initialVisible error content={finalErrorMessage} />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="action-button-gray"
          content="Cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          className="secondary-navy"
          content="Save"
          onClick={onSave}
          disabled={loading || (touched && !_isEmpty(formValidationMessage))}
          loading={loading}
        />
      </Modal.Actions>
    </CloseableModal>
  );
}

EditRedirectModal.propTypes = propTypes;
EditRedirectModal.defaultProps = defaultProps;
