import React from 'react';

import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';

import './FulfillmentConfiguration.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

function FulfillmentConfiguration({ className, ...accordionProps }) {
  const finalClassName = `${className} fulfillment-configuration`;
  return <Accordion className={finalClassName} {...accordionProps} />;
}

FulfillmentConfiguration.propTypes = propTypes;
FulfillmentConfiguration.defaultProps = defaultProps;

export default FulfillmentConfiguration;
FulfillmentConfiguration.Title = Accordion.Title;
FulfillmentConfiguration.Content = Accordion.Content;
