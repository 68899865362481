import _isEmpty from 'lodash/isEmpty';

import { CUSTOM_PRICE, CUSTOM_PRICE_LABEL } from '../../TeamMemberServicesTable.constants';

export function getIsMoney(priceType, { priceDisplay } = {}) {
  if (priceType === CUSTOM_PRICE) {
    return true;
  }
  if (priceType === CUSTOM_PRICE_LABEL) {
    return false;
  }
  return _isEmpty(priceDisplay);
}
