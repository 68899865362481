import React, { useState, useEffect } from 'react';

import { Message, Pagination, Button, Icon } from 'semantic-ui-react';

import _get from 'lodash/get';

import {
  PARTIAL_TABLE_HEADER_NAMES,
  OBJECT_TYPE_NAMES,
  MESSAGE_TIMEOUT_SECONDS,
  DEF_ERROR_MESSAGE,
} from './PartialPage.constants';
import { updatePartial, getPartialFileContent } from './PartialPage.utils';
import useDebounce from '../../../../hooks/useDebounce/use-debounce';
import API from '../../../../libs/api';
import { getErrorMessage } from '../../../../libs/errors';
import { keysToCamelCase, transformObjectKeyToSnakeCase } from '../../../../libs/strings';
import useCopyToClipboard from '../../hooks/use-copy-to-clipboard';
import PartialSearch from '../components/PartialSearch';
import PartialTypeSelect from '../components/PartialTypeSelect';
import RecipePartialModal from '../components/modals/RecipePartialModal';
import PartialTable from '../components/table/PartialTable';

import './PartialPage.scss';

const PartialsPage = () => {
  const [recipePartials, setRecipePartials] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [querySearch, setQuerySearch] = useState('');
  const [filterType, setFilterType] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [handleCopy] = useCopyToClipboard();

  const clearNotification = () => setNotification({ type: '', message: '' });

  const handleMessageNotification = (message, type = 'error') => {
    setNotification({ type, message });
    setTimeout(() => clearNotification(), MESSAGE_TIMEOUT_SECONDS);
  };

  const getPartials = async () => {
    const params = {
      query: querySearch,
      page: currentPage,
      type: filterType === 'All' ? null : filterType,
    };
    setIsFetching(true);
    try {
      const { data } = await API.getRecipePartials(params);
      const { results, total_pages: _totalPages } = data;
      if (results && _totalPages) {
        const fileredResults = keysToCamelCase(results);
        setRecipePartials(fileredResults);
        setTotalPages(_totalPages);
      }
    } catch (error) {
      const err = _get(error, 'response.statusText') || 'An error occurred';
      const status = _get(error, 'response.status');
      if (status !== 404) {
        handleMessageNotification(err);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const debouncedQuery = useDebounce(querySearch, 500);

  useEffect(() => {
    getPartials();
  }, [currentPage, debouncedQuery, filterType]);

  const handleAddPartial = async (recipePartial) => {
    const { partial, ...rest } = recipePartial;
    const partialMeta = rest;
    let err = null;
    setIsSaving(true);
    try {
      const { data } = await API.createRecipePartial(transformObjectKeyToSnakeCase(partialMeta));
      await API.updateRecipePartial(data.id, {
        partial,
      });
      await getPartials();
    } catch (error) {
      err = _get(error, 'response.data.partial') || getErrorMessage(error);
      handleMessageNotification(err);
    } finally {
      setIsSaving(false);
      setModalOpened(false);
    }
  };

  const handleEditPartialMeta = async (partialId, updatedPartial) => {
    const partials = updatePartial(partialId, recipePartials, updatedPartial);
    setRecipePartials(partials);
  };

  const handleSearchChange = (e, { value }) => setQuerySearch(value);

  const handleCopyToClipboard = async (partialId) => {
    let partial = null;

    await getPartialFileContent(
      partialId,
      (data) => {
        partial = data;
      },
      () => {
        handleMessageNotification(DEF_ERROR_MESSAGE, 'error');
      },
    );

    if (!partial) return;

    handleCopy(
      partial,
      () => handleMessageNotification('Copied Succefully!', 'success'),
      () => handleMessageNotification('Could not copy data!', 'error'),
    );
  };

  return (
    <>
      <div className="partial-filters-container">
        <PartialTypeSelect
          onChange={(value) => setFilterType(value)}
          filters={['All', ...OBJECT_TYPE_NAMES]}
        />
        <PartialSearch
          placeholder="Search Partials..."
          onChange={handleSearchChange}
          q={querySearch}
        />
      </div>
      <PartialTable>
        <PartialTable.Header headerNames={PARTIAL_TABLE_HEADER_NAMES} />
        <PartialTable.Body
          isFetching={isFetching}
          partials={recipePartials}
          onEditMeta={handleEditPartialMeta}
          onCopyToCliboard={handleCopyToClipboard}
          onError={() => handleMessageNotification(DEF_ERROR_MESSAGE, 'error')}
        />
        <PartialTable.Footer
          pagination={
            <Pagination
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              totalPages={totalPages}
              onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
              activePage={currentPage}
            />
          }
          action={
            <RecipePartialModal
              open={modalOpened}
              header="Add New Partial"
              onSave={handleAddPartial}
              onCancel={() => setModalOpened(false)}
              isSaving={isSaving}
              trigger={
                <Button
                  onClick={() => setModalOpened(true)}
                  primary
                  size="small"
                  floated="right"
                  labelPosition="left"
                  icon
                >
                  <Icon name="add" /> Add New Partial
                </Button>
              }
              newPartial
            />
          }
        />
      </PartialTable>
      {notification.message && (
        <div className="notif-box">
          <Message
            compact
            header={notification.message}
            error={notification.type === 'error'}
            success={!(notification.type === 'error')}
          />
        </div>
      )}
    </>
  );
};

export default PartialsPage;
