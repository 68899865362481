import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const propTypes = {
  pagination: PropTypes.element,
  action: PropTypes.element,
};

const defaultProps = {
  pagination: undefined,
  action: undefined,
};

const RecipeManagerTableFooter = ({ pagination, action }) => {
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell>{pagination}</Table.HeaderCell>
        <Table.HeaderCell colSpan="5">{action}</Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

RecipeManagerTableFooter.propTypes = propTypes;

RecipeManagerTableFooter.defaultProps = defaultProps;

export default RecipeManagerTableFooter;
