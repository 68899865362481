import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import HelpTooltip from '../../../../../../../../common/HelpTooltip';

import './FormEmailSubject.scss';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
};

export default function FormEmailSubject({ value, onChange: onChangeProp }) {
  function onChange(e, { value: newValue }) {
    onChangeProp('emailSubject', newValue);
  }
  return (
    <div className="form-element email-subject">
      <label htmlFor="form-element-email-subject-input">Notification Subject*</label>
      <HelpTooltip
        title="Notification Subject"
        description="This will be the subject of the notification we send to you when we receive a submission."
      />

      <Input
        id="form-element-email-subject-input"
        fluid
        value={value}
        onChange={onChange}
        placeholder="New Website Submission"
      />
    </div>
  );
}

FormEmailSubject.propTypes = propTypes;
FormEmailSubject.defaultProps = defaultProps;
