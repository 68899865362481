import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import './BackButton.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

export default function BackButton({ onClick, disabled }) {
  return (
    <Icon
      name="arrow left"
      className="navigate-back-button"
      disabled={disabled}
      onClick={disabled ? null : onClick}
    />
  );
}

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;
