export const GOOGLE_FONTS_API = 'https://fonts.googleapis.com/css2';

export const ITALIC = 'italic';
export const REGULAR_VARIANT = 'regular';

export const DEFAULT_SENTENCE = 'Whereas recognition of the inherent dignity';
export const DEFAULT_FONT_SIZE = '32px';

export const DEFAULT_CATEGORIES = ['sans-serif', 'serif', 'display', 'handwriting', 'monospace'];

export const DEFAULT_SORT = 'popularity';
export const SORT_MECHANISMS = ['trending', 'popularity', 'date', 'alpha', 'style'];

export const DEFAULT_FONT_WEIGHT = '400';
export const DEFAULT_BOLD_FONT_WEIGHT = '700';
export const DEFAULT_WEIGHTS = [DEFAULT_FONT_WEIGHT, DEFAULT_BOLD_FONT_WEIGHT];
