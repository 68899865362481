import _isEmpty from 'lodash/isEmpty';

import {
  CUSTOM_PRICE,
  CUSTOM_PRICE_LABEL,
  INITIAL_PRICE_OPTION,
  PRICE_TYPE_OPTIONS,
} from '../../TeamMemberServicesTable.constants';

export function getPriceTypeOptions() {
  return PRICE_TYPE_OPTIONS.map((option) => {
    return {
      key: option,
      value: option,
      text: option,
    };
  });
}

export function getPriceTypeValue(teamMemberService) {
  const { priceOverride, priceDisplayOverride } = teamMemberService;

  if (+priceOverride === 0 && _isEmpty(priceDisplayOverride)) {
    return INITIAL_PRICE_OPTION;
  }
  if (!_isEmpty(priceDisplayOverride)) {
    return CUSTOM_PRICE_LABEL;
  }
  return CUSTOM_PRICE;
}

export function getNextPriceState(teamMemberService, priceType) {
  const { price, priceDisplay } = teamMemberService;

  if (priceType === INITIAL_PRICE_OPTION) {
    return { priceOverride: 0, priceDisplayOverride: '' };
  }
  if (priceType === CUSTOM_PRICE) {
    return { priceOverride: price, priceDisplayOverride: '' };
  }
  // custom price label case
  return { priceOverride: 0, priceDisplayOverride: priceDisplay || price };
}
