import React from 'react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import WebsiteUsersForm from './WebsiteUsersForm';
import {
  addWebsiteUser as addWebsiteUserConnect,
  removeWebsiteUser as removeWebsiteUserConnect,
} from '../../actions/website';
import { getUserGroupOptions } from '../../constants/user';
import API from '../../libs/api';
import { InlineFieldContainer, FormContainer } from '../common';

class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleRemoveUser = this.handleRemoveUser.bind(this);
  }

  async handleRemoveUser(websiteUser) {
    const { website, websiteUsers, removeWebsiteUser } = this.props;

    await API.removeUserFromWebsite(website.id, websiteUser.id);

    const userIndex = websiteUsers.findIndex((wUser) => {
      return wUser.id === websiteUser.id;
    });

    if (userIndex > -1) {
      removeWebsiteUser({
        index: userIndex,
      });
    }
  }

  async handleAddUser(firstName, lastName, email, role) {
    const { website, websiteUsers, addWebsiteUser } = this.props;
    const payload = {
      firstName,
      lastName,
      email,
      role,
    };

    const { data: websiteUser } = await API.addUserToWebsite(website.id, payload);

    const currentUser = websiteUsers.find((wUser) => {
      return wUser.id === websiteUser.id;
    });

    if (!currentUser) {
      addWebsiteUser({
        value: websiteUser,
        index: websiteUsers.length,
      });
    }
  }

  getRolesForUser() {
    const { user } = this.props;
    return getUserGroupOptions(user);
  }

  render() {
    const { user, websiteUsers } = this.props;

    return (
      <>
        <FormContainer loadedKeyPath={['business', 'core']}>
          <InlineFieldContainer title={<h2>Users</h2>} enableDelete={false}>
            <WebsiteUsersForm
              user={user}
              websiteUsers={websiteUsers}
              onAddUser={this.handleAddUser}
              onRemoveUser={this.handleRemoveUser}
              roleOptions={this.getRolesForUser()}
            />
          </InlineFieldContainer>
        </FormContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addWebsiteUser: (payload) => dispatch(addWebsiteUserConnect(payload)),
  removeWebsiteUser: (payload) => dispatch(removeWebsiteUserConnect(payload)),
});

const mapStateToProps = ({ business, user, website }) => ({
  website: _get(website, 'core.value'),
  websiteUsers: _get(website, 'websiteUsers'),
  businessName: _get(business, 'core.value.name'),
  businessLogo: _get(business, 'core.value.logo'),
  about: _get(business, 'core.value.about[0]'),
  about_markdown: _get(business, 'core.value.about_markdown'),
  layouts: _get(website, 'layouts.value'),
  user: _get(user, 'core.value'),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
