import React from 'react';

import PropTypes from 'prop-types';
import { Label, Message } from 'semantic-ui-react';

import {
  FormFieldWidgets,
  UPLOAD_FILE_NOTICE,
  UPLOAD_FILE_NOTICE_HEADER,
} from './FormFieldContainer.constants';
import { formatFieldType, isPlaceholderShown, isFileUploadType } from './FormFieldContainer.utils';
import { FormFieldLabel, FormFieldPlaceholder, FormFieldToolbar } from './components';
import Icon from '../../../../../../../foundation/components/Icon';
import { FormFieldPropType } from '../../../../../../types/form-block.types';
import { IconByType } from '../InsertFormField/components/SelectFormFieldType/SelectFormFieldType.constants';

import './FormFieldContainer.scss';

const propTypes = {
  field: FormFieldPropType.isRequired,
  onUpdateFieldValue: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onMoveField: PropTypes.func.isRequired,
  moveDownDisabled: PropTypes.bool,
  moveUpDisabled: PropTypes.bool,
};

const defaultProps = {
  moveDownDisabled: false,
  moveUpDisabled: false,
};

export default function FormFieldContainer({
  field,
  onUpdateFieldValue,
  onDeleteField,
  onMoveField,
  moveDownDisabled,
  moveUpDisabled,
}) {
  const { label = '', type = '', placeholder = '', required = false } = field;

  function getFormFieldWidget() {
    return FormFieldWidgets[type] || [];
  }

  const showPlaceHolder = isPlaceholderShown(field);
  const icon = IconByType[type];

  return (
    <div className="form-field-container">
      <Label className="type-label">
        <Icon name={icon} /> {formatFieldType(type)}
      </Label>
      {isFileUploadType(type) && (
        <Message header={UPLOAD_FILE_NOTICE_HEADER} content={UPLOAD_FILE_NOTICE} />
      )}
      <FormFieldLabel
        label={label}
        onChange={(nextLabel) => onUpdateFieldValue('label', nextLabel)}
      />
      {showPlaceHolder && (
        <FormFieldPlaceholder
          placeholder={placeholder}
          onChange={(nextPlaceholder) => onUpdateFieldValue('placeholder', nextPlaceholder)}
        />
      )}
      {getFormFieldWidget().map((Widget) => (
        <Widget key={Widget.name} field={field} onUpdateFieldValue={onUpdateFieldValue} />
      ))}
      <FormFieldToolbar
        moveDownDisabled={moveDownDisabled}
        moveUpDisabled={moveUpDisabled}
        isRequired={required}
        onRequiredChange={(nextIsRequired) => onUpdateFieldValue('required', nextIsRequired)}
        onDeleteField={onDeleteField}
        onMoveFieldUp={() => onMoveField(-1)}
        onMoveFieldDown={() => onMoveField(1)}
      />
    </div>
  );
}

FormFieldContainer.propTypes = propTypes;
FormFieldContainer.defaultProps = defaultProps;
