import React, { useContext } from 'react';

import { Button, Input, Form } from 'semantic-ui-react';

import { StockImagesPickerContext } from '../../StockImagesPicker.context';

import './StockSearchField.scss';

const propTypes = {};

const defaultProps = {};

export default function StockSearchField() {
  const { onSearch, searchValue, setSearchValue, loading } = useContext(StockImagesPickerContext);

  return (
    <Form>
      <Form.Group unstackable className="stock-search-field">
        <Form.Field width={14}>
          <Input
            placeholder="Search Stock Images"
            icon="search"
            value={searchValue}
            onChange={(_, { value }) => {
              setSearchValue(value);
            }}
            loading={loading}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Button
            circular
            onClick={onSearch}
            loading={loading}
            disabled={loading}
            className="search-button"
            content="Search"
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

StockSearchField.propTypes = propTypes;
StockSearchField.defaultProps = defaultProps;
