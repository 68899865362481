import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Form, Message } from 'semantic-ui-react';

import { areEmailsValid } from '../../../../../../../libs/validation-utils';
import { ORDER_NOTIFICATION_EMAILS } from '../../OrderSettings.constants';

import './OrderNotificationEmailField.scss';

const propTypes = {
  handleOnchangeForm: PropTypes.func.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  initialValue: [],
};

export default function OrderNotificationEmailField({ handleOnchangeForm, initialValue }) {
  const [emails, setEmails] = useState(initialValue);
  const [options, setOptions] = useState([]);
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);

  useEffect(() => {
    if (options.length === 0) {
      setOptions(initialValue.map((email) => ({ text: email, value: email })));
    }
    setEmails(initialValue);
  }, [initialValue]);

  function handleAddition(e, { value }) {
    const isValueValid = areEmailsValid([value]);
    setShowInvalidMessage(!isValueValid);
    if (isValueValid) {
      setOptions((prevOptions) => [...prevOptions, { text: value, value }]);
    } else {
      setTimeout(() => setShowInvalidMessage(false), 3000);
    }
  }

  function handleChange(e, { value }) {
    const isValueValid = areEmailsValid(value);
    if (isValueValid) {
      setEmails(value);
      handleOnchangeForm(e, { value, name: ORDER_NOTIFICATION_EMAILS });
    }
  }
  return (
    <Form.Field
      id="order-notification-email-field"
      className="order-notification-email-field form-field"
    >
      <label htmlFor="order-notification-email-field">Order Notification Emails</label>
      <Dropdown
        options={options}
        placeholder="email@example.com"
        noResultsMessage="No emails found. You can type any email you want and add it."
        closeOnChange
        search
        selection
        fluid
        multiple
        allowAdditions
        value={emails}
        onAddItem={handleAddition}
        onChange={handleChange}
      />
      {showInvalidMessage && <Message negative content="You can't add an invalid email" />}
    </Form.Field>
  );
}

OrderNotificationEmailField.propTypes = propTypes;
OrderNotificationEmailField.defaultProps = defaultProps;
