import Utils from '../../../../../../../libs/utils';

export function getImageUrl(file, params) {
  const { file: fileLocation, url, s3File } = file;
  let finalUrl = fileLocation.startsWith('/') ? url || s3File : fileLocation;
  if (params && Object.keys(params)) {
    finalUrl = Object.keys(params).reduce((intermediateUrl, paramName) => {
      return Utils.addImageQueryParam(intermediateUrl, paramName, params[paramName]);
    }, finalUrl);
  }

  return finalUrl;
}
