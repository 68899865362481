import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Form, Message } from 'semantic-ui-react';

import CustomerEditFormGroup from './components/CustomerEditFormGroup/CustomerEditFormGroup';
import { FORM_PLACEHOLDERS } from './components/CustomerEditModal.constants';
import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors';
import { camelToSnakeCase } from '../../../../../libs/strings';
import Validate from '../../../../../libs/validate';
import CloseableModal from '../../../../common/CloseableModal';
import PhoneField from '../../../../common/PhoneField';
import TagsField from '../../../fields/components/TagsField';
import CustomerContactPropType from '../../types/customer';

import './CustomerEditModal.scss';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  customer: CustomerContactPropType,
  availableTags: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  open: false,
  customer: {},
  availableTags: [],
};

export default function CustomerEditModal({
  onChange,
  open,
  onClose,
  onSave,
  customer,
  availableTags,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [touched, setTouched] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const isAddCustomer = !customer.id;

  function handleChange({ target }) {
    onChange(camelToSnakeCase(target.name), target.value);
  }

  function handleUpdateTags(tags) {
    onChange('tags', tags);
  }

  function isEmailValid() {
    return Validate.validate('email', customer.email);
  }

  function isPhoneNumberValid() {
    if (!customer.phone) {
      return true;
    }
    return Validate.validate('phone', customer.phone);
  }

  function isFormValid() {
    return customer.first_name && customer.last_name && isEmailValid() && isPhoneNumberValid();
  }

  function clearModal() {
    setTouched(false);
    setErrorMessage(null);
  }

  async function handleSave() {
    setTouched(true);
    if (!isFormValid()) return;
    setIsSaving(true);
    setErrorMessage(null);
    try {
      await onSave(customer);
      clearModal();
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
  }

  function handleOnClose() {
    clearModal();
    onClose();
  }

  return (
    <CloseableModal
      open={open}
      onClose={handleOnClose}
      className="customer-edit-modal"
      header={isAddCustomer ? 'Add Customer' : 'Edit Customer'}
    >
      <Modal.Content scrolling>
        <Form>
          <CustomerEditFormGroup
            keyName="firstName"
            value={customer.first_name}
            onChange={handleChange}
            name="firstName"
            error={!customer.first_name && touched}
          />
          <CustomerEditFormGroup
            keyName="lastName"
            value={customer.last_name}
            onChange={handleChange}
            name="lastName"
            error={!customer.last_name && touched}
          />
          <CustomerEditFormGroup
            keyName="email"
            value={customer.email}
            onChange={handleChange}
            name="email"
            error={!isEmailValid() && touched}
          />
          <CustomerEditFormGroup
            keyName="phone"
            error={!isPhoneNumberValid() && touched}
            customInput={
              <PhoneField
                placeholder={FORM_PLACEHOLDERS.phone}
                name="phone"
                value={customer.phone}
                onChange={handleChange}
                style={{ width: '100%' }}
              />
            }
          />
          <CustomerEditFormGroup
            keyName="tags"
            customInput={
              <TagsField
                currentTags={customer.tags || []}
                availableTags={availableTags}
                onUpdate={handleUpdateTags}
              />
            }
          />

          {errorMessage && (
            <Message
              className="errorMessage"
              negative
              onDismiss={() => {
                setErrorMessage(null);
              }}
              header={errorMessage}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Save"
          className="action"
          onClick={handleSave}
          loading={isSaving}
          disabled={(isSaving || !isFormValid()) && touched}
        />
      </Modal.Actions>
    </CloseableModal>
  );
}

CustomerEditModal.propTypes = propTypes;
CustomerEditModal.defaultProps = defaultProps;
