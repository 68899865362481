import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';

import { INITIAL_FORM_DATA_STATE } from './EditPageModal.constants';
import Validate from '../../../../../libs/validate';
import { EXTERNAL_PAGE_TITLE_OPTIONS } from '../../../pages/constants';
import { getNextNavigationPriority, isButtonPage, isHomePage } from '../../../pages/services/pages';
import { MAX_AMOUNT_OF_BUTTONS } from '../../constants/constants';

export function getPageTileOptions(selectedTitle, pages) {
  const pageTitles = pages.map((page) => _startCase(page.title));

  const baseOptions = [...new Set([...EXTERNAL_PAGE_TITLE_OPTIONS, selectedTitle])];

  return baseOptions
    .filter((title) => !_isEmpty(title) && !pageTitles.includes(_startCase(title)))
    .map((title) => ({ key: title, text: title, value: title }));
}

export function getFieldsToValidate(external) {
  if (external) {
    return ['title', 'url'];
  }
  return ['title'];
}

export function getFormErrors(formData, external) {
  const requiredFields = getFieldsToValidate(external);
  const errors = {};

  Object.keys(formData).forEach((key) => {
    const value = formData[key];

    if (!requiredFields.includes(key)) {
      return;
    }

    if (_isEmpty(value)) {
      errors[key] = `${_startCase(key)} is required.`;
    } else if (key === 'url' && !Validate.validate(Validate.TYPES.LINK, value)) {
      errors[key] = Validate.getErrorMessage(Validate.TYPES.LINK);
    }
  });

  return errors;
}

export function getModalHeader(add, external) {
  if (add && external) {
    return 'Add External Link';
  }
  return 'Edit Link';
}

export function getInitialData(add, page) {
  if (add) {
    return INITIAL_FORM_DATA_STATE;
  }
  const { title = '', url = '', description = '', seoTitle = '' } = page || {};
  return { title, url, seoTitle, description: description || '', asButton: isButtonPage(page) };
}

export function getSubmitButtonText(add) {
  if (add) {
    return 'ADD';
  }
  return 'SAVE';
}

export function getNavigationPriority(page, initialAsButton, asButton, pages, nonButtonPages) {
  if (!_isEmpty(page)) {
    if (initialAsButton === asButton) {
      return _get(page, 'navigationPriority');
    }
  }
  return asButton ? getNextNavigationPriority(pages) : getNextNavigationPriority(nonButtonPages);
}

export function isAsButtonDisabled(buttonPages, page, initialAsButton) {
  const { isGroup, groupName } = page || {};

  if (isHomePage(page) || isGroup || !_isEmpty(groupName)) {
    return true;
  }
  return buttonPages.length >= MAX_AMOUNT_OF_BUTTONS && !initialAsButton;
}

export function getTitleRequirements({ title }) {
  return `Ensure the page title of ${title} is mentioned.`;
}

export function getDescriptionRequirements({ title }) {
  return `Keep in mind that the title of the page is: ${title}.`;
}
