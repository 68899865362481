import React, { useRef, useState } from 'react';

import { Accordion, Button } from 'semantic-ui-react';

import { SortableContainer } from 'react-sortable-hoc';

import SortableScheduleCategory from '../SortableScheduleCategory';

import './SortableScheduleCategories.scss';

const SortableMenuCategoriesContainer = SortableContainer(({ elements, ...passAccordionProps }) => {
  const accordionProps = {
    fluid: true,
    styled: true,
    ...passAccordionProps,
  };
  return <Accordion {...accordionProps}>{elements}</Accordion>;
});

export default function SortableScheduleCategories({
  categories,
  scheduleIndex,
  scheduleId,
  ...sortableContainerProps
}) {
  const initialOpenedIndexes = new Set(Array(categories.length).keys());

  const [accordionOpenedIndexes, setAccordionOpenedIndexes] = useState(initialOpenedIndexes);
  const helperContainer = useRef();

  const allItemsAreCollapsed = accordionOpenedIndexes.size === 0;

  function toggleAllAccordionItems() {
    let newIdxs = null;
    if (allItemsAreCollapsed) {
      newIdxs = new Set(Array(categories.length).keys());
    } else {
      newIdxs = new Set();
    }
    setAccordionOpenedIndexes(newIdxs);
  }

  const toggleAccordionIndexOpen = (index) => {
    setAccordionOpenedIndexes((prevIndexes) => {
      const newIndexes = new Set(prevIndexes);
      if (newIndexes.has(index)) {
        newIndexes.delete(index);
      } else {
        newIndexes.add(index);
      }
      return newIndexes;
    });
  };

  if (categories.length === 0) {
    return null;
  }

  const categoryPanels = categories.map((category, localIndex) => {
    return (
      <SortableScheduleCategory
        category={category}
        localIndex={localIndex}
        scheduleId={scheduleId}
        scheduleIndex={scheduleIndex}
        toggleAccordionIndexOpen={toggleAccordionIndexOpen}
        active={accordionOpenedIndexes.has(localIndex)}
        showDragHandle={allItemsAreCollapsed}
      />
    );
  });

  return (
    <div className="menu-categories-wrapper">
      <Button.Group size="medium">
        <Button basic onClick={toggleAllAccordionItems}>
          {allItemsAreCollapsed ? 'Expand' : 'Collapse'} All
        </Button>
      </Button.Group>
      <div className="ui form sortable-menu-categories-container-helper" ref={helperContainer} />
      <SortableMenuCategoriesContainer
        {...sortableContainerProps}
        useDragHandle
        elements={categoryPanels}
        helperContainer={() => helperContainer.current}
      />
    </div>
  );
}
