import React, { useState } from 'react';

import { Button, Form, Message, Modal } from 'semantic-ui-react';

import { DEFAULT_COLOR, PRIMARY_COLOR_ERROR, SERVER_ERROR } from './NewColorPalette.constants';
import CloseableModal from '../../../../../../common/CloseableModal';
import ColorPicker from '../ColorPicker';

function NewColorPalette({ palettesParadigms, onSubmit }) {
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [name, setName] = useState('');
  const [paradigm, setParadigm] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  function resetForm() {
    setColor(DEFAULT_COLOR);
    setName('');
    setParadigm(null);
  }

  function validateForm() {
    let isValid = true;
    if (!color) {
      setErrors((prevErrors) => [...prevErrors, PRIMARY_COLOR_ERROR]);
      isValid = false;
    }
    return isValid;
  }

  function onModalOpen() {
    resetForm();
    setIsOpen(true);
  }

  async function handleSubmit() {
    const isValid = validateForm();
    if (isValid) {
      setIsSubmitting(true);
      try {
        await onSubmit({
          red: color.rgb.r,
          green: color.rgb.g,
          blue: color.rgb.b,
          name,
          paradigm,
        });
        setIsOpen(false);
        setErrors([]);
      } catch (err) {
        if (err.response.status === 500) {
          setErrors([SERVER_ERROR]);
        } else {
          setErrors([
            Object.keys(err.response.data).map(
              (key) => `${key}: ${err.response.data[key].join(', ')}`,
            ),
          ]);
        }
      }

      setIsSubmitting(false);
    }
  }

  return (
    <CloseableModal
      open={isOpen}
      trigger={<Button className="action">Add Custom Palette</Button>}
      onOpen={onModalOpen}
      onClose={() => setIsOpen(false)}
      header="Create new color palette"
    >
      <Modal.Content>
        <Form
          id="new-color-palette-form"
          onSubmit={handleSubmit}
          loading={isSubmitting}
          error={errors.length > 0}
        >
          {errors.length > 0 && (
            <Message error>
              <Message.Header>Fix errors and submit again</Message.Header>
              <Message.Content>
                {errors.map((error) => (
                  <>
                    <span key={error}>{error}</span>
                    <br />
                  </>
                ))}
              </Message.Content>
            </Message>
          )}
          <Form.Input label="Primary Color" required>
            <ColorPicker
              withPopUpWrapper
              color={color}
              onSelect={(newColor) => setColor(newColor)}
              disableAlpha
            />
          </Form.Input>
          <Form.Input
            required
            name="name"
            label="Color Palette Name"
            placeholder="My nice color palette"
            onChange={(e, { value }) => setName(value)}
            value={name}
          />
          <Form.Select
            required
            name="paradigm"
            label="Select palette paradigm"
            placeholder="Paradigm"
            selected={paradigm}
            onChange={(event, { value }) => setParadigm(value)}
            options={palettesParadigms.map(({ name: text, value }) => ({ text, value }))}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button form="new-color-palette-form" primary>
          Save
        </Button>
      </Modal.Actions>
    </CloseableModal>
  );
}

export default NewColorPalette;
