import API from '../../../../../../../libs/api';
import { TEXT_BLOCK_COMPONENT_NAME } from '../../../../constants/types';

export async function updateContentConfiguration(
  componentConfigurationId,
  contentNodeId,
  { content, contentName },
) {
  const componentConfigurationData = { id: componentConfigurationId };
  const contentNodeConfigurationData = [{ id: contentNodeId, content, content_name: contentName }];
  return API.updateCustomPageContent(componentConfigurationData, contentNodeConfigurationData);
}

export async function createContentConfiguration(contentName, content, pageId) {
  const contentNodeOptions = {
    content_type: 'MARKDOWN',
    content_name: contentName,
    content,
  };
  const payload = {
    content_node_configurations: [contentNodeOptions],
    business_file_configurations: [],
    component: TEXT_BLOCK_COMPONENT_NAME,
    page: pageId,
  };
  const { data: results } = await API.createCustomPageContent(payload);

  return results[0];
}

export function updateContentElementFromSave(currentElement, result) {
  const {
    fast_id: componentName,
    component_identifier: componentIdentifier,
    id: newComponentConfigurationId,
    content_nodes: contentNodes = [],
  } = result;
  const { id: nextContentNodeId } = contentNodes[0];

  return {
    ...currentElement,
    contentNodeId: nextContentNodeId,
    componentName,
    componentIdentifier,
    componentConfigurationId: newComponentConfigurationId,
  };
}
