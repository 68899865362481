import React from 'react';

import { Header } from 'semantic-ui-react';

const PlanNavigationPopupDescription = () => {
  return (
    <Header.Subheader>
      In order to get access to this feature, you will need to upgrade your plan
    </Header.Subheader>
  );
};

PlanNavigationPopupDescription.propTypes = {};

PlanNavigationPopupDescription.defaultProps = {};

export default PlanNavigationPopupDescription;
