import { EMPTY_BLOCK_COMPONENT_NAME, EMPTY_BLOCK_ELEMENT_TYPE } from '../../../../constants/types';
import { createJSXComponent, getComponentId } from '../utils/components';
import { createComponentBaseProps, extractComponentBaseProps } from '../utils/props';

export function createEmptyBlockElement(data = {}) {
  const { id, className, style } = data;

  return createJSXComponent(
    EMPTY_BLOCK_COMPONENT_NAME,
    id,
    createComponentBaseProps(className, style),
  );
}

export function createEmptyBlockState(recipeSnippet = {}) {
  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  return {
    id,
    className,
    style,
    type: EMPTY_BLOCK_ELEMENT_TYPE,
  };
}

export function createEmptyBlockTemplateState(data = {}) {
  return data;
}
