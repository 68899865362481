import _isEmpty from 'lodash/isEmpty';

import { getPrice } from '../../../../core/menu/item/MenuItem/MenuItem.utils';
import { getCategoriesWithItems } from '../../services/catalog';

function sanitizeString(str = '') {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
}

export function searchItems(searchQuery = '', searchOptions = []) {
  const sanitizedValue = sanitizeString(searchQuery);

  const filteredResults = searchOptions.filter(({ title, description }) => {
    const sanitizedTitle = sanitizeString(title || '');
    const sanitizedDescription = sanitizeString(description || '');
    return sanitizedTitle.includes(sanitizedValue) || sanitizedDescription.includes(sanitizedValue);
  });

  return filteredResults;
}

export function createSearchOptions(
  categories = [],
  items = [],
  { excludeVariationIds = [] } = {},
) {
  const categoriesWithItems = getCategoriesWithItems(categories, items);

  const options = categoriesWithItems.reduce((acc, category) => {
    const { items: categoryItems = [], name: categoryName } = category;
    const categoryOptions = categoryItems.map((item) => {
      const { id: itemId, name: itemName, variations } = item;
      const filteredVariations = variations
        .filter(({ id }) => !excludeVariationIds.includes(id))
        .map(({ id }) => ({ id }));

      return {
        id: itemId,
        title: itemName || '',
        description: categoryName || '',
        price: getPrice(item),
        variations: filteredVariations,
      };
    });
    return [...acc, ...categoryOptions];
  }, []);

  return options.filter(({ variations }) => !_isEmpty(variations));
}
