import React from 'react';

import { Button, Container, Segment } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { addNewProviderServiceSetting } from '../../../../../../actions/business';
import { SUPPORT_EMAIL } from '../../../../../../constants/constants';
import API from '../../../../../../libs/api';
import ErrorHandler from '../../../../../../libs/errors';
import IntegrationMessage from '../../../IntegrationMessage';
import { getToastProviderService } from '../../ToastIntegration.utils';

const ERROR_MESSAGE_CONTENT = `Please try again or contact ${SUPPORT_EMAIL}`;

const ToastInstallForm = () => {
  const dispatch = useDispatch();
  const providerServices = useSelector((state) => state.business.provider_services.value);
  const businessId = useSelector((state) => state.business.core.value.id);
  const businessType = useSelector((state) => state.business.core.value.type);
  const providerService = getToastProviderService(providerServices);

  async function addIntegration() {
    const payload = {
      provider_service: providerService.id,
      provider: providerService.provider,
      service_data: {
        verification_code: uuid(),
      },
    };

    try {
      const { data } = await API.createToastMerchant(businessId, businessType, payload);
      dispatch(addNewProviderServiceSetting(data));
    } catch (e) {
      ErrorHandler.capture(e);
    }
  }

  return (
    <>
      <Segment attached>
        <Container textAlign="center" className="toast-merchant-segment-text">
          Connect Fisherman Website with your Toast POS to import & synchronize your menu, hours,
          and location data with no effort!
        </Container>
      </Segment>
      <Segment attached="top">
        <Container textAlign="center">
          <Button size="big" primary onClick={addIntegration}>
            Begin Setup
          </Button>
        </Container>
      </Segment>
      <IntegrationMessage content={ERROR_MESSAGE_CONTENT} />
    </>
  );
};

export default ToastInstallForm;
