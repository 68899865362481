import _isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

import { FRAGMENT_COMPONENT_NAME, JSON_BLOCK_ELEMENT_TYPE } from '../../../../constants/types';
import { createJSXComponent } from '../utils/components';
import { createProp } from '../utils/props';

export function createJsonBlockElement(data = {}) {
  const { id, snippet = {} } = data;
  const finalId = id || uuidv4();
  const children = _isEmpty(snippet) ? [] : [snippet];
  const ketPrefix = 'JSON';
  const key = `${ketPrefix}__${finalId}`;

  return createJSXComponent(FRAGMENT_COMPONENT_NAME, finalId, [createProp('key', key)], children);
}

export function createJsonBlockState(recipeSnippet = {}) {
  const { id, children = [] } = recipeSnippet;

  return { type: JSON_BLOCK_ELEMENT_TYPE, id, snippet: _isEmpty(children) ? {} : children[0] };
}

export function createJsonBlockTemplateState(data = {}) {
  return data;
}
