import React from 'react';

import { Step, Icon } from 'semantic-ui-react';

const GMBSteps = ({ currentStep, ...stepGroupProps }) => {
  if (currentStep > 3) {
    return (
      <Step.Group {...stepGroupProps} stackable="tablet">
        <Step completed>
          <Icon name="cogs" />
          <Step.Content>
            <Step.Title>Connected</Step.Title>
            <Step.Description>You are connected to Google Business Profile</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }

  return (
    <Step.Group {...stepGroupProps} stackable="tablet">
      <Step active={currentStep === 1 || currentStep === 2} completed={currentStep > 2}>
        <Icon name="plug" />
        <Step.Content>
          <Step.Title>Connect</Step.Title>
          <Step.Description>
            Authorize Fisherman to manage your Google Business Profile
          </Step.Description>
        </Step.Content>
      </Step>

      <Step active={currentStep === 3} completed={currentStep > 3}>
        <Icon name="cogs" />
        <Step.Content>
          <Step.Title>Confirm</Step.Title>
          <Step.Description>Confirm we have everything correct</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
};

export default GMBSteps;
