import { useState } from 'react';

import {
  ANALYTICS_NOT_AVAILABLE_FOR_WEBSITE,
  ANALYTICS_DEFAULT_MESSAGE,
} from './WebsiteAnalytics.constants';
import { analyticsIsAvailable } from './WebsiteAnalytics.utils';
import API from '../../../libs/api';
import useAsyncEffect from '../../modules/foundation/hooks/use-async-effect';

export default function useAnalytics(website, user) {
  const { id: websiteId } = website;

  const [embedUrl, setEmbedUrl] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [available, setAvailable] = useState(false);
  const [reason, setReason] = useState('');

  useAsyncEffect(async () => {
    const isAnalyticsAvailable = analyticsIsAvailable(website, user);

    const { available: initialAvailable, reason: initialReason } = isAnalyticsAvailable;

    if (!initialAvailable) {
      setLoaded(true);
      setAvailable(initialAvailable);
      setReason(initialReason);

      return;
    }

    try {
      const { data } = await API.getWebsiteAnalyticsEmbed(websiteId);
      const { url } = data;

      setEmbedUrl(url);
      setAvailable(true);
    } catch (e) {
      const { response = {} } = e;
      const { status = '' } = response;

      if (status === 400) {
        setReason(ANALYTICS_NOT_AVAILABLE_FOR_WEBSITE);
      } else {
        setReason(ANALYTICS_DEFAULT_MESSAGE);
      }
    } finally {
      setLoaded(true);
    }
  }, []);

  return {
    embedUrl,
    loaded,
    available,
    reason,
  };
}
