import PropTypes from 'prop-types';

import { ContentNodeConfigurationPropType } from '../content/types/content';
import { BusinessFilePropType } from '../images/types/business-file.types';
import { WebsiteComponentInteractionConfigurationPropType } from '../website-component-interaction/proptypes';

const GenericComponentConfigurationShape = {
  componentConfigurationId: PropTypes.number,
  componentName: PropTypes.string, // same as fastId
  componentIdentifier: PropTypes.string,
  order: PropTypes.number,
  data: PropTypes.shape({}),
  files: PropTypes.arrayOf(BusinessFilePropType),
  contentNodes: PropTypes.arrayOf(ContentNodeConfigurationPropType),
  interactions: PropTypes.arrayOf(WebsiteComponentInteractionConfigurationPropType),
};

GenericComponentConfigurationShape.childConfigurations = PropTypes.arrayOf(
  PropTypes.shape(GenericComponentConfigurationShape),
);

export const GenericComponentConfigurationPropType = PropTypes.shape(
  GenericComponentConfigurationShape,
);
