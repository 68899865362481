import { useState, useRef, useEffect } from 'react';

export function useSortingState() {
  const [initialFocusedCollection, setInitialFocusedCollection] = useState('');
  const [initialFocusedGroupCollection, setInitialFocusedGroupCollection] = useState('');
  const [initialFocusedGroupChildCollection, setInitialFocusedGroupChildCollection] = useState('');
  const [focusedCollection, setFocusedCollection] = useState('');
  const [focusedGroupCollection, setFocusedGroupCollection] = useState('');
  const [focusedGroupChildCollection, setFocusedGroupChildCollection] = useState('');
  const [dragging, setDragging] = useState(false);

  const initialFocusedCollectionRef = useRef(initialFocusedCollection);
  const initialFocusedGroupCollectionRef = useRef(initialFocusedGroupCollection);
  const initialFocusedGroupChildCollectionRef = useRef(initialFocusedGroupChildCollection);
  const focusedCollectionRef = useRef(focusedCollection);
  const focusedGroupCollectionRef = useRef(focusedGroupCollection);
  const focusedGroupChildCollectionRef = useRef(focusedGroupChildCollection);
  const draggingRef = useRef(dragging);

  useEffect(() => {
    initialFocusedCollectionRef.current = initialFocusedCollection;
  }, [initialFocusedCollection]);

  useEffect(() => {
    initialFocusedGroupCollectionRef.current = initialFocusedGroupCollection;
  }, [initialFocusedGroupCollection]);

  useEffect(() => {
    initialFocusedGroupChildCollectionRef.current = initialFocusedGroupChildCollection;
  }, [initialFocusedGroupChildCollection]);

  useEffect(() => {
    focusedCollectionRef.current = focusedCollection;
  }, [focusedCollection]);

  useEffect(() => {
    focusedGroupCollectionRef.current = focusedGroupCollection;
  }, [focusedGroupCollection]);

  useEffect(() => {
    focusedGroupChildCollectionRef.current = focusedGroupChildCollection;
  }, [focusedGroupChildCollection]);

  useEffect(() => {
    draggingRef.current = dragging;
  }, [dragging]);

  function resetSorting() {
    setDragging(false);
    setInitialFocusedCollection(focusedCollectionRef.current);
    setInitialFocusedGroupCollection(focusedGroupCollectionRef.current);
    setInitialFocusedGroupChildCollection(focusedGroupChildCollectionRef.current);
  }

  function getRefValue(refName) {
    const refByName = {
      initialFocusedCollection: initialFocusedCollectionRef,
      initialFocusedGroupCollection: initialFocusedGroupCollectionRef,
      initialFocusedGroupChildCollection: initialFocusedGroupChildCollectionRef,
      focusedCollection: focusedCollectionRef,
      focusedGroupCollection: focusedGroupCollectionRef,
      focusedGroupChildCollection: focusedGroupChildCollectionRef,
    };

    const value = refByName[refName];
    return value ? value.current : null;
  }

  return {
    initialFocusedCollection,
    setInitialFocusedCollection,
    initialFocusedGroupCollection,
    setInitialFocusedGroupCollection,
    initialFocusedGroupChildCollection,
    setInitialFocusedGroupChildCollection,
    focusedCollection,
    setFocusedCollection,
    focusedGroupCollection,
    setFocusedGroupCollection,
    focusedGroupChildCollection,
    setFocusedGroupChildCollection,
    dragging,
    setDragging,
    resetSorting,
    getRefValue,
  };
}
