export const suggestedQueries = {
  '*': [
    'abstract',
    'animal',
    'architecture',
    'art',
    'business',
    'colorful',
    'food',
    'interior',
    'minimal',
    'nature',
    'plant',
    'portrait',
    'space',
    'technology',
    'texture',
    'wallpaper',
  ],
};

export function getSuggestedQueries(businessType = '*') {
  return suggestedQueries[businessType];
}
