import PropTypes from 'prop-types';

export const ContentNodeType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
});

export const ContentNodeConfigurationPropType = PropTypes.shape({
  contentNodeConfigurationId: PropTypes.number,
  id: PropTypes.number,
  content: PropTypes.string,
  contentType: PropTypes.string,
  contentName: PropTypes.string,
  maxLength: PropTypes.number,
  order: PropTypes.number,
  metadata: PropTypes.shape({}),
});
