export const DEFAULT_ERROR_MESSAGE_TIMEOUT_SECONDS = 10;
export const STACK_STATUS_LIVE = 'Live';
export const STACK_STATUS_PREVIEW = 'Preview';

export const SUPPORT_EMAIL = 'support@gofisherman.com';
export const MAIL_TO_SUPPORT_EMAIL = `mailto:${SUPPORT_EMAIL}`;

export const GLAMMATIC_SUPPORT_EMAIL = 'help@glammatic.com';
export const GLAMMATIC_MAIL_TO_SUPPORT_EMAIL = `mailto:${GLAMMATIC_SUPPORT_EMAIL}`;

export const MENU_FILE_TYPES = 'image/png, image/jpeg, application/pdf';
export const IMAGE_FILE_TYPES = 'image/png, image/jpeg';

export const RESTAURANT_BUSINESS_TYPE = 'restaurant';
export const GENERIC_BUSINESS_TYPE = 'generic';
export const MERCHANT_BUSINESS_TYPE = 'merchant';

export const HELP_CENTER_URL = 'https://gofisherman.zendesk.com/hc/en-us';

export const DEFAULT_EXTERNAL_ANCHOR_REL = 'noopener noreferrer';
export const DEFAULT_EXTERNAL_ANCHOR_TARGET = '_blank';
export const DEFAULT_EXTERNAL_OPEN_FEATURES = 'noopener,noreferrer';

export const V_LEGACY_KEY = 'v_legacy';

// Merchant Categories
export const RESTAURANT_CATEGORY = 'Restaurant';
export const SPA_CATEGORY = 'Spa';
export const SALON_CATEGORY = 'Salon';
export const HAIR_CATEGORY = 'Hair Care';
export const BUSINESS_CATEGORY = 'Business';
export const ENTERTAINMENT_CATEGORY = 'Entertainment';
export const TATTOO_PARLOR_CATEGORY = 'Tattoo Parlor';
