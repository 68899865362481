import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';

import { getActivePage } from './PageSideNavigation.utils';
import { getPageSettings } from '../../services/components';
import { PagePropType } from '../../types';
import { PageComponentSettingType } from '../../types/components';

import './PageSideNavigation.scss';

const propTypes = {
  pages: PropTypes.arrayOf(PagePropType).isRequired,
  settings: PropTypes.arrayOf(PageComponentSettingType).isRequired,
  displayComponent: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function PageSideNavigation({ pages, settings, displayComponent }) {
  const [activePage, setActivePage] = useState(getActivePage(pages, null));

  useEffect(() => {
    setActivePage(getActivePage(pages, activePage));
  }, [pages]);

  function handlePageChange(pageIndex) {
    const page = pages[pageIndex];
    setActivePage(page);
  }

  const pageSettings = getPageSettings(activePage, settings);

  return (
    <div className="page-side-navigation">
      <div className="navigation">
        <Menu pointing secondary vertical>
          {pages.map(({ title }, index) => (
            <Menu.Item
              key={title}
              name={title}
              active={title === activePage.title}
              onClick={() => handlePageChange(index)}
            />
          ))}
        </Menu>
      </div>
      <div className="content">
        {displayComponent({ page: activePage, settings: pageSettings })}
      </div>
    </div>
  );
}

PageSideNavigation.propTypes = propTypes;
PageSideNavigation.defaultProps = defaultProps;
