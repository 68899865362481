import _get from 'lodash/get';

import { selectBusinessLocations } from './business';
import { objectToCamelCase } from '../libs/format';

export function selectLocationByIndex(index) {
  return (state) => _get(selectBusinessLocations(state), `[${index}]`);
}

export function selectPrimaryLocation(state) {
  const primaryLocation =
    selectBusinessLocations(state).find((location) => location.primary_location) ||
    _get(selectBusinessLocations(state), '[0]', {});

  return objectToCamelCase(primaryLocation);
}

export function selectPrimaryLocationId(state) {
  const primaryLocation =
    selectBusinessLocations(state).find((location) => location.primary_location) ||
    _get(selectBusinessLocations(state), '[0]', {});

  return _get(primaryLocation, 'id');
}
