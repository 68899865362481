import React from 'react';

import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

import CancelIcon from '../../../static/icons/cancel.svg';

import './CancelImage.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

export default function CancelImage({ onClick, disabled }) {
  return (
    <Image
      src={CancelIcon}
      className="cancel-image"
      onClick={disabled ? null : onClick}
      disabled={disabled}
    />
  );
}

CancelImage.propTypes = propTypes;
CancelImage.defaultProps = defaultProps;
