import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Loader } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ObjectPropType } from '../../../../../../../libs/proptypes';

import './ElementOptionDropdownItem.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.oneOfType([ObjectPropType, PropTypes.string]),
};

const defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  icon: '',
};

export default function ElementOptionDropdownItem({
  onClick,
  className,
  disabled,
  loading,
  icon,
  label,
}) {
  return (
    <Dropdown.Item onClick={onClick} className={className} disabled={disabled}>
      {loading && <Loader inline active size="tiny" />}
      {!loading && icon && <FontAwesomeIcon className="icon" icon={icon} />}
      <div className="item-label">{label}</div>
    </Dropdown.Item>
  );
}

ElementOptionDropdownItem.propTypes = propTypes;
ElementOptionDropdownItem.defaultProps = defaultProps;
