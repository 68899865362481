import React, { useContext, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import Markdown from 'markdown-to-jsx';
import { useDispatch, useSelector } from 'react-redux';

import { IMAGE_CONTAINER_CLASS_NAME } from './MenuItem.constants';
import { getImage, getPrice, getBackgroundImageUrl, isImageClickedEvent } from './MenuItem.utils';
import { flagMenuSaved, updateMenuItem } from '../../../../../actions/business';
import API from '../../../../../libs/api';
import { sortItemsByList } from '../../../../../libs/array';
import ErrorHandler from '../../../../../libs/errors/errors';
import { multiCaseGet, objectToSnakeCase } from '../../../../../libs/format';
import {
  selectBusinessFiles,
  selectBusinessId,
  selectBusinessType,
} from '../../../../../selectors/business';
import ImageField from '../../../../common/ImageField';
import { CatalogItemPropType } from '../../../../modules/catalog/proptypes/catalog';
import FileSelectionWell from '../../../../modules/files/components/FileSelectionWell';
import { SOURCE_TYPE_MENU } from '../../../../modules/files/constants/sources';
import { InventoryContext } from '../../../MenuForm/Menu.context';
import { isMultipleImagesSupported } from '../../libs/menu-helpers';
import EditMenuItem from '../EditMenuItem';

import './MenuItem.scss';

const propTypes = {
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  categoryIndex: PropTypes.number.isRequired,

  itemIndex: PropTypes.number.isRequired,
  item: CatalogItemPropType.isRequired,

  leftContent: PropTypes.node,
};

const defaultProps = {
  leftContent: null,
};

export default function MenuItem({ categoryId, categoryIndex, itemIndex, item, leftContent }) {
  const { id: itemId, name = '', description = '', files = [], file } = item;

  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const businessFiles = useSelector((state) => selectBusinessFiles(state, { fileIds: files }));
  const { activePatch } = useContext(InventoryContext);

  const editButtonRef = useRef(null);

  const dispatch = useDispatch();

  const [open, setOpen] = useState(!itemId);
  const [loading, setLoading] = useState(false);

  const multipleImagesSupported = useMemo(
    () => isMultipleImagesSupported(activePatch),
    [multiCaseGet(activePatch, 'baseRecipe.name')],
  );

  const displayPrice = getPrice(item);
  const imageUrl = useMemo(
    () => getBackgroundImageUrl(getImage(item, activePatch, businessFiles)),
    [files, file],
  );
  const itemBusinessFiles = useMemo(
    () => sortItemsByList(businessFiles, files || [], 'id'),
    [files],
  );

  if (_isEmpty(item)) {
    return null;
  }

  async function onUpdateImages({ newFiles = [], newImage } = {}) {
    try {
      let updatedItem = null;
      if (!multipleImagesSupported && newImage && typeof newImage === 'object') {
        setLoading(true);
        const imagePayload = new FormData();
        imagePayload.append('image', newImage.file || '');
        const { data } = await API.updateMenuItem(businessId, businessType, itemId, imagePayload);
        updatedItem = data;
      }
      if (multipleImagesSupported) {
        const newFileIds = newFiles.map((newFile) => (_isNumber(newFile) ? newFile : newFile.id));
        const payload = objectToSnakeCase({ files: newFileIds });
        const { data } = await API.updateMenuItem(businessId, businessType, itemId, payload);
        updatedItem = data;
      }

      dispatch(updateMenuItem({ index: itemIndex, values: { ...updatedItem, index: itemIndex } }));
      dispatch(flagMenuSaved(true));
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setLoading(false);
    }
  }

  async function onUpdateFiles(newFiles) {
    await onUpdateImages({ newFiles });
  }

  async function onUpdateImage(e, data = []) {
    const [newImage] = data;
    await onUpdateImages({ newImage });
  }

  function onItemImageClick(e) {
    if (editButtonRef.current && isImageClickedEvent(e)) {
      const button = _get(editButtonRef, 'current.ref.current');
      button.click();
    }
  }

  return (
    <div className="menu-item-container">
      <div className="menu-item">
        {leftContent && <div className="menu-item-left-content">{leftContent}</div>}

        <div
          className={IMAGE_CONTAINER_CLASS_NAME}
          style={{ backgroundImage: imageUrl }}
          onClick={onItemImageClick}
          onKeyDown={onItemImageClick}
          role="button"
          tabIndex={0}
        >
          {multipleImagesSupported && (
            <FileSelectionWell
              files={itemBusinessFiles}
              onUpdateFiles={onUpdateFiles}
              uploadSourceType={SOURCE_TYPE_MENU}
              hideImagesDeck
              editButtonText=""
              editButtonRef={editButtonRef}
            />
          )}
          {!multipleImagesSupported && (
            <ImageField onChange={onUpdateImage}>
              {(onClick) => (
                <Button
                  className="edit-images"
                  onClick={onClick}
                  disabled={loading}
                  ref={editButtonRef}
                >
                  <Icon name="pencil" />
                </Button>
              )}
            </ImageField>
          )}
        </div>

        <div className="menu-item-content">
          <div>
            <h5>{name}</h5>
            <div>{displayPrice}</div>
          </div>
          <div>
            <div>
              <Markdown className="markdown">{description || ''}</Markdown>
            </div>
            <Button
              onClick={() => setOpen(true)}
              className="menu-item-edit-button"
              content="edit"
            />
          </div>

          {open && (
            <EditMenuItem
              itemIndex={itemIndex}
              categoryId={categoryId}
              categoryIndex={categoryIndex}
              initialItem={item}
              onCloseModal={() => setOpen(false)}
              open={open}
            />
          )}
        </div>
      </div>
    </div>
  );
}

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;
