import React, { useContext, useState } from 'react';

import { Dropdown, Menu, Message } from 'semantic-ui-react';

import { FULFILLMENT_STATUS_COMPLETE } from '../../../../../../../constants/fulfillments';
import API from '../../../../../../../libs/api';
import { SelectedOrdersContext } from '../../OrdersContainer.context';

import './OrderActionsDropdown.scss';

const propTypes = {};

const defaultProps = {};

export default function OrderActionsDropdown() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { selectedOrderIds, setRefreshOrders, setSelectedOrderIds } =
    useContext(SelectedOrdersContext);

  // TODO check parent state loading performance issue with order is marked as fulfilled
  async function markOrdersAsFulfilled() {
    try {
      setLoading(true);
      setErrorMessage('');

      await Promise.all(
        selectedOrderIds.map((orderId) => {
          return API.updateOrderFulfillmentStatus(orderId, FULFILLMENT_STATUS_COMPLETE);
        }),
      );
      setRefreshOrders(true);
      setSelectedOrderIds([]);
    } catch ({ message = '' }) {
      setErrorMessage(message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Menu compact>
        <Dropdown
          simple
          item
          direction="left"
          text={`${selectedOrderIds.length} Order(s) Selected`}
          loading={loading}
          error={!!errorMessage}
          className="order-actions-dropdown"
        >
          <Dropdown.Menu>
            <Dropdown.Header content="Select An Action" />
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={markOrdersAsFulfilled}
              label={{ color: 'green', empty: true, circular: true }}
              text="Mark As Fulfilled"
              disabled={loading}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
      {errorMessage && <Message error>{errorMessage}</Message>}
    </>
  );
}

OrderActionsDropdown.propTypes = propTypes;
OrderActionsDropdown.defaultProps = defaultProps;
