import React from 'react';

import { Table } from 'semantic-ui-react';

import OrdersTableBody from './components/OrdersTableBody';
import OrdersTableHeader from './components/OrdersTableHeader';

function OrdersTable({ orders, extraHeaders = null, extraTableCells = [] }) {
  return (
    <Table sortable compact celled>
      <OrdersTableHeader orders={orders} extraHeaders={extraHeaders} />
      <OrdersTableBody orders={orders} extraTableCells={extraTableCells} />
    </Table>
  );
}

export default OrdersTable;
