import React, { useCallback, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import SimpleMdeReact from 'react-simplemde-editor';

import onUpdate from 'hooks/useOnUpdate/use-on-update';

import { DEFAULT_OPTIONS } from './MarkdownField.constants';
import {
  generateMarkdownFieldImageToolbar,
  createToolbarPlugins,
  getPluginButtons,
  getPluginComponents,
  getMarkdownValidationMessage,
} from './MarkdownField.utils';

import './MarkdownField.scss';

const propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({}),
  toolbarPlugins: PropTypes.arrayOf(PropTypes.func),
  toolbarGenerator: PropTypes.func,
  disabled: PropTypes.bool,
  onValidationError: PropTypes.func,
};

const defaultProps = {
  id: undefined,
  value: '',
  options: {},
  toolbarPlugins: [],
  toolbarGenerator: generateMarkdownFieldImageToolbar,
  disabled: false,
  onValidationError: () => {},
};

export default function MarkdownField({
  id,
  value,
  onChange: onChangeProp,
  options,
  toolbarPlugins,
  toolbarGenerator,
  disabled,
  onValidationError,
}) {
  const prevValue = useRef(value);

  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  const onChange = useCallback(async (newValue) => {
    if (prevValue.current !== newValue) {
      onChangeProp(newValue);
      prevValue.current = newValue;

      const message = await getMarkdownValidationMessage(newValue);
      setValidationErrorMessage(message);
    }
  }, []);

  onUpdate(() => {
    onValidationError({ message: validationErrorMessage });
  }, [validationErrorMessage]);

  function activateSideBySidePreview(instance) {
    const { autoDisplaySideBySidePreview } = { ...DEFAULT_OPTIONS, ...options };
    if (autoDisplaySideBySidePreview) {
      instance?.toggleSideBySide();
    }
  }

  const toolbarPluginsToRender = createToolbarPlugins(toolbarPlugins);
  const toolbarWithPlugins = toolbarGenerator(getPluginButtons(toolbarPluginsToRender));
  const pluginComponents = getPluginComponents(toolbarPluginsToRender);

  const finalClassName = clsx({
    'markdown-field': true,
    disabled,
    error: !_isEmpty(validationErrorMessage),
  });

  return (
    <div className={finalClassName}>
      <SimpleMdeReact
        id={id}
        value={value}
        onChange={onChange}
        getMdeInstance={activateSideBySidePreview}
        options={{
          ...DEFAULT_OPTIONS,
          ...options,
          toolbar: toolbarWithPlugins,
        }}
      />
      {pluginComponents}
      {!_isEmpty(validationErrorMessage) && (
        <Message negative show>
          {validationErrorMessage}
        </Message>
      )}
    </div>
  );
}

MarkdownField.propTypes = propTypes;
MarkdownField.defaultProps = defaultProps;
