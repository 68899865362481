import React from 'react';

import PropTypes from 'prop-types';

import Currency from '../../../../../../../../../libs/currency';

import './SummaryItem.scss';

const propTypes = {
  label: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  content: PropTypes.element,
};

const defaultProps = {
  content: null,
};

export default function SummaryItem({ label, amount, content }) {
  return (
    <div className="order-summary-item">
      <div>{label}</div>
      {content}
      <div>{Currency.format(amount)}</div>
    </div>
  );
}

SummaryItem.propTypes = propTypes;
SummaryItem.defaultProps = defaultProps;
