import {
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_CALENDULA,
} from 'constants/recipes';

export const DYNAMIC_TILES_COMPONENT = 'DynamicTiles';
export const DYNAMIC_TILES_TILE_COMPONENT = 'DynamicTiles.Tile';

export const CUTILIER_HERO_HEADER = 'home_section_header';
export const CUTILIER_HERO_CONTENT = 'home_section_description';

export const MUSE_HERO_HEADER = 'home_hero_header';
export const MUSE_HERO_HEADER_HIGHLIGHTED = 'home_hero_header_highlighted';
export const MUSE_HERO_CONTENT = 'home_hero_description';

export const HIBISCUS_HERO_HEADER = 'home_hero_header';
export const HIBISCUS_HERO_CONTENT = 'home_hero_description';

export const CALENDULA_HERO_HEADER = 'home_hero_header';
export const CALENDULA_HERO_CONTENT = 'home_hero_description';

export const HERO_DEFAULT_COMPONENT_IDENTIFIERS = {
  [RECIPE_NAME_CUTILIER]: [CUTILIER_HERO_HEADER, CUTILIER_HERO_CONTENT],
  [RECIPE_NAME_MUSE]: [MUSE_HERO_HEADER, MUSE_HERO_HEADER_HIGHLIGHTED, MUSE_HERO_CONTENT],
  [RECIPE_NAME_HIBISCUS]: [HIBISCUS_HERO_HEADER, HIBISCUS_HERO_CONTENT],
  [RECIPE_NAME_CALENDULA]: [CALENDULA_HERO_HEADER, CALENDULA_HERO_CONTENT],
};
