import Ajv from 'ajv';

import { removeNodeMetadata } from './recipe-utils';

import schema from '../constants/schema.json';

export function validateRecipe(recipe) {
  const ajv = new Ajv();
  const validate = ajv.compile(schema);
  validate(removeNodeMetadata(recipe));
  return validate.errors || [];
}
