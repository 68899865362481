import { combineReducers } from 'redux';

import business from './business';
import contentNodes from './content-nodes';
import eCommerce from './ecommerce';
import notifications from './notifications';
import products from './products';
import user from './user';
import inputerrors from './validation-errors';
import website from './website';
import ComponentConfigurationSlice from '../components/modules/component-configuration/slices/ComponentConfiguration.slice';
import customPagesSlice from '../components/modules/custom-pages/slices/custom-pages';
import htmlElementsSlice from '../components/modules/html-elements/slices';
import redirectsSlice from '../components/modules/redirect/slices/Redirect.slice';
import teamsSlice from '../components/modules/team/slices/team.slice';
import orderSettingsSlice from '../components/pages/ecommerce/settings/OrderSettings/OrderSettings.slice';

export default combineReducers({
  user,
  website,
  business,
  notifications,
  inputerrors,
  eCommerce,
  products,
  orderSettings: orderSettingsSlice,
  contentNodes,
  htmlElements: htmlElementsSlice,
  customPages: customPagesSlice,
  componentConfigurations: ComponentConfigurationSlice,
  teams: teamsSlice,
  redirects: redirectsSlice,
});
