import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { UNKNOWN_FILE_SOURCE, FILE_SOURCE_LABEL_MAP } from './FileSourceLabel.constants';

import './FileSourceLabel.scss';

const propTypes = {
  source: PropTypes.string,
};

const defaultProps = {
  source: '',
};

export default function FileSourceLabel({ source }) {
  if (_isEmpty(source) || source === UNKNOWN_FILE_SOURCE) {
    return null;
  }

  const sourceDisplay = FILE_SOURCE_LABEL_MAP[source];

  if (!sourceDisplay) {
    return null;
  }

  return (
    <div className="file-source-label">
      From {sourceDisplay}
      <Icon name="tag" />
    </div>
  );
}

FileSourceLabel.propTypes = propTypes;
FileSourceLabel.defaultProps = defaultProps;
