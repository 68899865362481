import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import {
  getNewPage,
  getNewPageAssociation,
  getNewRecipeNode,
  getPageClassName,
} from './EditCustomPageModal.utils';
import { flagBusinessSaved } from '../../../../../actions/business';
import { getErrorMessage } from '../../../../../libs/errors';
import ErrorHandler from '../../../../../libs/errors/errors';
import CloseableModal from '../../../../common/CloseableModal';
import ConfirmModal from '../../../../common/ConfirmModal';
import { DismissibleMessage } from '../../../utils/components';
import { CustomPagesContext } from '../../contexts/CustomPages.context';
import { useCustomPages } from '../../hooks';
import useCustomPageForm from '../../hooks/use-custom-page-form';
import { CustomPagePropType } from '../../types/custom-page.type';
import CustomPageConfigForm from '../CustomPageConfigForm';

import './EditCustomPageModal.scss';

const propTypes = {
  customPage: CustomPagePropType.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveAsTemplate: PropTypes.func.isRequired,
};
const defaultProps = {};

export default function EditCustomPageModal({
  customPage,
  open,
  onClose: onCloseProp,
  onSaveAsTemplate,
}) {
  const dispatch = useDispatch();

  const { setActivePageId, isValidPage, updateGridClassName } = useContext(CustomPagesContext);

  const { id: pageAssociationId, status, page, recipeNode } = customPage || {};
  const { title } = page || {};
  const { style } = recipeNode.node || '';

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { removeCustomPage, createOrUpdateCustomPage } = useCustomPages();

  const { customPageData, setCustomPageField, resetCustomPageForm } = useCustomPageForm({
    initialValues: { title, status, style, className: getPageClassName(recipeNode.node) },
  });

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  function onClose() {
    resetCustomPageForm();
    onCloseProp();
  }

  async function onDeleteConfirm() {
    try {
      setLoading(true);
      setErrorMessage('');
      await removeCustomPage(pageAssociationId);
      setActivePageId(null);
      onClose();
    } catch (error) {
      ErrorHandler.capture(error);
      setErrorMessage(getErrorMessage(error));
    } finally {
      setLoading(false);
      setShowDeleteConfirmModal(false);
    }
  }

  async function onSave() {
    try {
      setLoading(true);
      setErrorMessage('');
      const pageAssociation = getNewPageAssociation(pageAssociationId, customPageData);
      const newPage = getNewPage(page, customPageData);
      const oldClassName = getPageClassName(recipeNode.node);
      const { className: newClassName } = customPageData;
      const newNode = updateGridClassName(oldClassName, newClassName);
      const newRecipeNode = getNewRecipeNode(newNode, customPageData);
      await createOrUpdateCustomPage(newPage, { node: newRecipeNode }, pageAssociation);
      dispatch(flagBusinessSaved(true));
      if (onClose) {
        onClose();
      }
    } catch (error) {
      ErrorHandler.capture(error);
      setErrorMessage(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }

  function hideDeleteConfirmModal() {
    setShowDeleteConfirmModal(false);
  }

  function onDelete() {
    setShowDeleteConfirmModal(true);
  }

  return (
    <CloseableModal
      className="edit-custom-page-modal"
      open={open}
      onClose={onClose}
      size="tiny"
      header="Edit page"
    >
      <Modal.Content>
        <CustomPageConfigForm
          hideLayoutControls
          onUpdate={setCustomPageField}
          disableStatus={!isValidPage()}
          {...customPageData}
        />
      </Modal.Content>
      <Modal.Actions>
        {!_isEmpty(errorMessage) && (
          <DismissibleMessage initialVisible delay={8} error content={errorMessage} />
        )}
        <Button className="delete" basic onClick={onDelete} disabled={loading}>
          Delete
        </Button>
        <Button className="secondary-action" basic onClick={onSaveAsTemplate} disabled={loading}>
          Save As Template
        </Button>
        <Button className="save" basic onClick={onSave} disabled={loading} loading={loading}>
          Save
        </Button>
      </Modal.Actions>
      <ConfirmModal
        open={showDeleteConfirmModal}
        onClose={hideDeleteConfirmModal}
        header="Delete Custom Page"
        content={`Are you sure you want to remove custom page: ${title}`}
        onCancel={hideDeleteConfirmModal}
        onConfirm={onDeleteConfirm}
        className="delete-custom-page-modal"
        confirmButton={
          <Button className="delete-red" content="YES" disabled={loading} loading={loading} />
        }
        cancelButton={<Button className="action-button-gray" content="CANCEL" disabled={loading} />}
      />
    </CloseableModal>
  );
}

EditCustomPageModal.propTypes = propTypes;
EditCustomPageModal.defaultProps = defaultProps;
