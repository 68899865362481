import React, { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getBusinessProperty } from './SideNavigationContent.utils';
import { isAuthorized, showPlanGatingForWebsite } from '../../../../../../libs/auth';
import Routing from '../../../../../../libs/routing';
import { hasDisabledFeatureFlag } from '../../../../../../libs/routing/flag';
import { selectFeatureSets } from '../../../../../../selectors/subscriptions';
import useBranding from '../../../../../modules/foundation/hooks/use-branding';
import useRecipe from '../../../../../modules/foundation/hooks/use-recipe';
import SideNavigationItem from '../SideNavigationItem';
import SideNavigationSubItem from '../SideNavigationSubItem';

export default function SideNavigationContent({ rootRoutePath }) {
  const location = useLocation();
  const flags = useFlags();

  const { activeRecipeName } = useRecipe();
  const user = useSelector((state) => _get(state, 'user.core.value'));
  const business = useSelector((state) => _get(state, 'business.core.value'));
  const websiteFeatureSets = useSelector(selectFeatureSets);

  const { category, type: businessType } = business;

  const recipeRoutes = useMemo(() => {
    const baseRoutes = Routing.getPathRoutes([rootRoutePath]);
    const filteredRoutes = Routing.filterRoutesByDisplayRules(baseRoutes);
    const businessTypeRoutes = Routing.getBusinessTypeRoutes(filteredRoutes, businessType);
    const categoryRoutes = Routing.getCategoryRoutes(businessTypeRoutes, category);
    return Routing.getRecipeRoutes(categoryRoutes, activeRecipeName);
  }, [rootRoutePath, activeRecipeName, businessType, category]);

  const { brandName } = useBranding();

  return recipeRoutes.map(
    ({
      name,
      path,
      divider,
      icon,
      routes,
      isHidden,
      roles,
      permissions,
      statusContext,
      featureSets,
      featureFlags,
      redirectTo,
    }) => {
      const businessRouteName = getBusinessProperty(name, category);
      const businessRouteIcon = icon ? getBusinessProperty(icon, category) : '';
      const pathChildRoutes = Routing.getCategoryRoutes(routes, category);

      if (hasDisabledFeatureFlag(flags, featureFlags)) {
        return null;
      }

      return (
        <SideNavigationItem
          key={businessRouteName}
          icon={businessRouteIcon}
          divider={divider}
          location={location}
          name={businessRouteName}
          isHidden={isHidden || !isAuthorized(user, business, roles, permissions, statusContext)}
          path={Routing.getFirstRoute([rootRoutePath, path])}
          redirectTo={redirectTo}
          active={location.pathname.includes(Routing.createPath([rootRoutePath, path]))}
          showPlanGating={showPlanGatingForWebsite(
            user,
            websiteFeatureSets,
            featureSets,
            brandName,
          )}
          requiredFeatureSets={featureSets}
        >
          {pathChildRoutes.map(
            ({
              name: childName,
              path: childPath,
              roles: childRoles,
              shouldDisplay: childShouldDisplay,
              featureFlags: childFeatureFlags,
            }) => {
              const completePath = Routing.createPath([rootRoutePath, path, childPath]);

              if (hasDisabledFeatureFlag(flags, childFeatureFlags)) {
                return null;
              }

              return (
                <SideNavigationSubItem
                  key={childName}
                  name={childName}
                  isHidden={
                    isHidden ||
                    !isAuthorized(user, business, childRoles, permissions, statusContext) ||
                    !Routing.routeShouldDisplay({ shouldDisplay: childShouldDisplay })
                  }
                  path={completePath}
                  active={location.pathname.includes(completePath)}
                />
              );
            },
          )}
        </SideNavigationItem>
      );
    },
  );
}
