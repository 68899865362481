import {
  IMAGE_POSITION_LEFT,
  IMAGE_POSITION_RIGHT,
  IMAGE_POSITION_TOP,
  IMAGE_POSITION_BOTTOM,
  IMAGE_POSITION_BACKGROUND,
} from '../../constants';

export const imagePositionOptions = [
  { key: IMAGE_POSITION_TOP, text: 'Top', value: IMAGE_POSITION_TOP },
  { key: IMAGE_POSITION_LEFT, text: 'Left', value: IMAGE_POSITION_LEFT },
  { key: IMAGE_POSITION_BOTTOM, text: 'Bottom', value: IMAGE_POSITION_BOTTOM },
  { key: IMAGE_POSITION_RIGHT, text: 'Right', value: IMAGE_POSITION_RIGHT },
  { key: IMAGE_POSITION_BACKGROUND, text: 'Background', value: IMAGE_POSITION_BACKGROUND },
];
