import React, { useState, useEffect } from 'react';

import { Message, Button, Icon } from 'semantic-ui-react';

import _get from 'lodash/get';

import {
  RECIPE_TABLE_HEADER_NAMES,
  MESSAGE_TIMEOUT_SECONDS,
  DEF_ERROR_MESSAGE,
} from './RecipeManager.constants';
import useDebounce from '../../../../hooks/useDebounce/use-debounce';
import API from '../../../../libs/api';
import { keysToCamelCase } from '../../../../libs/strings';
import RecipeSearch from '../components/RecipeSearch';
import RecipeTable from '../components/table/RecipeManagerTable';

import './RecipeManager.scss';

const RecipeManagerPage = () => {
  const [recipes, setRecipes] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [querySearch, setQuerySearch] = useState('');
  const [notification, setNotification] = useState({ type: '', message: '' });

  function clearNotification() {
    setNotification({ type: '', message: '' });
  }

  function handleMessageNotification(message, type = 'error') {
    setNotification({ type, message });
    setTimeout(() => clearNotification(), MESSAGE_TIMEOUT_SECONDS);
  }

  async function getRecipes() {
    const params = { query: querySearch };
    setIsFetching(true);
    try {
      const { data } = await API.getRecipes(params);
      const results = keysToCamelCase(data);
      setRecipes(results);
    } catch (error) {
      const err = _get(error, 'response.statusText') || 'An error occurred';
      const status = _get(error, 'response.status');
      if (status !== 404) {
        handleMessageNotification(err);
      }
    } finally {
      setIsFetching(false);
    }
  }

  const debouncedQuery = useDebounce(querySearch, 500);

  useEffect(() => {
    getRecipes();
  }, [debouncedQuery]);

  function handleSearchRecipe(e, { value }) {
    setQuerySearch(value);
  }

  return (
    <>
      <div className="recipe-search-container">
        <RecipeSearch
          placeholder="Search Recipe..."
          onChange={handleSearchRecipe}
          query={querySearch}
        />
      </div>
      <RecipeTable>
        <RecipeTable.Header headerNames={RECIPE_TABLE_HEADER_NAMES} />
        <RecipeTable.Body
          isFetching={isFetching}
          recipes={recipes}
          onEditSuccess={getRecipes}
          onEditError={() => handleMessageNotification(DEF_ERROR_MESSAGE, 'error')}
        />
        <RecipeTable.Footer
          pagination={null}
          action={
            <Button
              onClick={() => null}
              primary
              size="small"
              floated="right"
              labelPosition="left"
              icon
            >
              <Icon name="add" /> Add New Recipe
            </Button>
          }
        />
      </RecipeTable>
      {notification.message && (
        <div className="notif-box">
          <Message
            compact
            header={notification.message}
            error={notification.type === 'error'}
            success={!(notification.type === 'error')}
          />
        </div>
      )}
    </>
  );
};

export default RecipeManagerPage;
