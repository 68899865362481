import React, { useContext, useEffect, useState } from 'react';

import _find from 'lodash/find';
import AceEditor from 'react-ace';
import { useSelector } from 'react-redux';

import { HtmlElementPageContext } from '../../context';
import { selectHtmlElements } from '../../selectors/html-elements';

import './HtmlElementsCodeEditor.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsCodeEditor() {
  const htmlElements = useSelector(selectHtmlElements);
  const { item, setItem, saveItemState, itemEditingId, addDirtyId } =
    useContext(HtmlElementPageContext);
  const [contentsValue, setContentsValue] = useState('');

  function saveItem() {
    if (item) {
      const itemState = _find(htmlElements, ({ id, localId }) => itemEditingId === (id || localId));
      saveItemState(itemState, {
        contents: contentsValue,
      });
    }
  }

  function onChange(value) {
    setContentsValue(value);
    addDirtyId(itemEditingId);
  }

  useEffect(() => {
    if (!itemEditingId) {
      setContentsValue('');
      setItem(null);
    } else {
      const { contents = '' } = item || {};
      setContentsValue(contents);
    }
  }, [itemEditingId]);

  useEffect(() => {
    saveItem();
  }, [contentsValue]);

  return (
    <AceEditor
      readOnly={!item}
      mode="jsx"
      name="html-element-editor"
      theme="solarized_dark"
      onChange={onChange}
      fontSize={16}
      showPrintMargin
      showGutter
      highlightActiveLine
      value={contentsValue}
      placeholder="Select a script to edit here"
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}
    />
  );
}

HtmlElementsCodeEditor.propTypes = propTypes;
HtmlElementsCodeEditor.defaultProps = defaultProps;
