import React from 'react';

import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

import clsx from 'clsx';

import { LABEL_ACTIVE, LABEL_INACTIVE } from '../../HtmlElementCard.constants';

import './ActiveIndicator.scss';

const propTypes = {
  enabled: PropTypes.bool.isRequired,
};

const defaultProps = {};

export default function ActiveIndicator({ enabled }) {
  const finalClasses = clsx({ 'item-enabled': true, inactive: !enabled });

  return (
    <Label basic className={finalClasses}>
      Script is <strong>{(enabled ? LABEL_ACTIVE : LABEL_INACTIVE).toLowerCase()}</strong>
    </Label>
  );
}

ActiveIndicator.propTypes = propTypes;
ActiveIndicator.defaultProps = defaultProps;
