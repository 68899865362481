import React from 'react';

import { Link } from 'react-router-dom';

import useBranding from '../modules/foundation/hooks/use-branding';

export default ({ backActions, nextActions }) => {
  const { brandLogoURL, brandName } = useBranding();

  return (
    <div className="login-banner">
      <div>{backActions}</div>
      <Link to="/" className="home-link">
        <img src={brandLogoURL} alt={`${brandName} Logo`} style={{ maxHeight: '36px' }} />
      </Link>
      <div>{nextActions}</div>
    </div>
  );
};
