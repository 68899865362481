export const HTML_VALIDATION_URL = 'https://validator.w3.org/nu/?out=json';
export const DOM_LOCATION_HEADER = 'HEAD';
export const DOM_LOCATION_BODY = 'BODY';
export const DOM_LOCATION_FOOTER = 'FOOTER';
export const WEBSITE_LOCATION_PAGE = 'PAGE';
export const WEBSITE_LOCATION_GLOBAL = 'GLOBAL';

export const DEFAULT_STATE = {
  errorMessage: '',
  setErrorMessage: () => {},
  filter: {
    placement: '',
    pages: [],
  },
  setFilter: () => {},
  itemEditingId: null,
  setItemEditingId: () => {},
  item: null,
  setItem: () => {},
  saveItemState: () => {},
  undoItemState: () => {},
  dirtyIds: [],
  addDirtyId: () => {},
  removeDirtyId: () => {},
  isSavingItemToDb: false,
  setIsSavingItemToDb: () => {},
};

export const DOM_LOCATION_OPTIONS = {
  All: '',
  Header: DOM_LOCATION_HEADER,
  Body: DOM_LOCATION_BODY,
};

export const PAGE_LOCATION_OPTIONS = {
  Page: WEBSITE_LOCATION_PAGE,
  Global: WEBSITE_LOCATION_GLOBAL,
};

export const USER_FRIENDLY_FIELD_MAP = {
  name: 'Name',
  contents: 'Script',
  domLocation: 'Document Location',
  websiteLocation: 'Website Page',
};
