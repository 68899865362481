import React, { useState } from 'react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { getStepNumber } from './StripeConnectOnboarding.utils';
import StripeConnectOnboardingComplete from './components/StripeConnectOnboardingComplete';
import StripeConnectOnboardingHeader from './components/StripeConnectOnboardingHeader';
import StripeConnectOnboardingStart from './components/StripeConnectOnboardingStart';
import {
  BUTTON_TEXT_BY_STEP,
  DESCRIPTION_BY_STEP,
} from './components/StripeConnectOnboardingStart/StripeConnectOnboardingStart.constants';
import API from '../../../../libs/api';
import ErrorHandler from '../../../../libs/errors/errors';
import { FormContainer, InlineFieldContainer } from '../../../common';
import useAsyncEffect from '../../../modules/foundation/hooks/use-async-effect';

import './StripeConnectOnboarding.scss';

export default function StripeConnectOnboarding() {
  const business = useSelector((state) => _get(state, 'business.core.value'));

  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});

  const { id: businessId, type: businessType } = business;

  useAsyncEffect(async () => {
    try {
      const { data } = await API.getStripeConnectSettings(businessId, businessType);
      setSettings(data);
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const stepNumber = getStepNumber(settings);

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']} loading={loading}>
      <InlineFieldContainer enableDelete={false}>
        <StripeConnectOnboardingHeader />
        {[0, 1, 2].includes(stepNumber) && (
          <StripeConnectOnboardingStart
            business={business}
            description={DESCRIPTION_BY_STEP[stepNumber]}
            buttonContent={BUTTON_TEXT_BY_STEP[stepNumber]}
          />
        )}
        {stepNumber === 3 && <StripeConnectOnboardingComplete />}
      </InlineFieldContainer>
    </FormContainer>
  );
}
