import React from 'react';

import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import { CUSTOMER_DETAILS_HEADER } from './CustomerDetails.constants';
import { formatCustomerEmail } from './CustomerDetails.utils';
import { formatFullName } from '../../OrderDetailsModal.utils';

import './CustomerDetails.scss';

const propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string,
};

const defaultProps = {
  phoneNumber: '',
};

export default function CustomerDetails({ firstName, lastName, email, phoneNumber }) {
  return (
    <>
      <Header as="h3">{CUSTOMER_DETAILS_HEADER}</Header>
      {formatFullName(firstName, lastName)}
      <br />
      {formatCustomerEmail(email)}
      <br />
      {phoneNumber && (
        <>
          {phoneNumber}
          <br />
        </>
      )}
    </>
  );
}

CustomerDetails.propTypes = propTypes;
CustomerDetails.defaultProps = defaultProps;
