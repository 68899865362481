import React, { useEffect, useState } from 'react';

import { Header, Loader } from 'semantic-ui-react';

import { useSelector, useDispatch } from 'react-redux';

import { matchStyleRulesToColorPalette } from './ColorPaletteSelection.utils';
import ColorPaletteGroup from './components/ColorPaletteGroup';
import NewColorPalette from './components/NewColorPalette';
import { setColorPalette as setColorPaletteAction } from '../../../../../actions/website';
import useColorPalettes from '../../../../../hooks/useColorPalettes/useColorPalettes';
import {
  selectWebsiteId,
  selectWebsitePatches,
  selectActiveRecipeName,
  selectWebsiteStyleRules,
  selectWebsiteColorPaletteId,
} from '../../../../../selectors/website';

import './ColorPaletteSelection.scss';

function ColorPaletteSelection() {
  const dispatch = useDispatch();
  const selectedColorPaletteId = useSelector(selectWebsiteColorPaletteId);
  const websiteId = useSelector(selectWebsiteId);
  const websitePatches = useSelector(selectWebsitePatches);
  const activeRecipeName = selectActiveRecipeName(websitePatches);
  const styleRules = useSelector(selectWebsiteStyleRules);

  const [paletteIdFromStyleRules, setPaletteIdFromStyleRules] = useState(null);

  const {
    palettes: colorPalettes,
    isLoading: isLoadingColorPalettes,
    palettesParadigms,
    generatePalette,
  } = useColorPalettes(websiteId, { recipeName: activeRecipeName });

  useEffect(() => {
    if (colorPalettes && !selectedColorPaletteId) {
      const matchingColorPalette = matchStyleRulesToColorPalette(styleRules, colorPalettes);

      if (matchingColorPalette) {
        setPaletteIdFromStyleRules(matchingColorPalette.id);
      }
    }
  }, [colorPalettes]);

  function setColorPalette(payload) {
    dispatch(setColorPaletteAction(payload));
  }

  function onSelect(colorPaletteId) {
    if (colorPaletteId === selectedColorPaletteId) {
      setColorPalette({ id: null });
      return;
    }

    setColorPalette({ id: colorPaletteId });
  }

  const customColorPalettes = colorPalettes.filter((palette) => palette.type === 'CUSTOM');
  const curatedColorPalettes = colorPalettes.filter((palette) => palette.type === 'FISHERMAN');

  if (isLoadingColorPalettes) {
    return <Loader active>Loading</Loader>;
  }

  const selectedPaletteId = selectedColorPaletteId || paletteIdFromStyleRules;

  return (
    <>
      <header className="color-palette-selection-header">
        <NewColorPalette
          palettesParadigms={palettesParadigms}
          onSubmit={(params) => generatePalette({ ...params, website: websiteId })}
        />
      </header>
      <div id="color-palette-selection" className="color-palette-selection">
        {customColorPalettes && customColorPalettes.length > 0 && (
          <div id="custom-color-palettes">
            <Header as="h2">Custom Color Palettes</Header>
            <ColorPaletteGroup
              colorPalettes={customColorPalettes}
              onSelect={onSelect}
              selectedPaletteId={selectedPaletteId}
            />
          </div>
        )}
        <div id="curated-color-palettes">
          <Header as="h2">Curated Color Palettes</Header>
          <ColorPaletteGroup
            colorPalettes={curatedColorPalettes}
            onSelect={onSelect}
            selectedPaletteId={selectedPaletteId}
          />
        </div>
      </div>
    </>
  );
}

export default ColorPaletteSelection;
