import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import './CustomPageTemplateSearch.scss';

const propTypes = {
  query: PropTypes.string,
  onQueryChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

const defaultProps = {
  query: '',
  loading: false,
  error: '',
};

export default function CustomPageTemplateSearch({ query, onQueryChange, loading, error }) {
  return (
    <div className="custom-page-template-search">
      <Input
        value={query}
        onChange={(e, { value }) => onQueryChange(value)}
        loading={loading}
        icon="search"
        placeholder="Search templates..."
        className="custom-page-template-search-input"
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
}

CustomPageTemplateSearch.propTypes = propTypes;
CustomPageTemplateSearch.defaultProps = defaultProps;
