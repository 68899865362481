import React from 'react';

import { Segment, Container, Image } from 'semantic-ui-react';

import StripeLogo from '../../data/stripe-logo.png';

import './StripeConnectOnboardingHeader.scss';

export default function StripeConnectOnboardingHeader() {
  return (
    <Segment className="stripe-connect-onboarding-header" attached>
      <Container className="logo-container" textAlign="center">
        <Image
          src={StripeLogo}
          style={{
            display: 'inline',
            margin: 0,
            height: '75px',
          }}
        />
      </Container>
      <p>
        The Stripe Integration allows allows your Fisherman site to accept payments and move funds
        to your bank account.
      </p>
    </Segment>
  );
}
