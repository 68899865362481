import React, { useState } from 'react';

import { Button, Segment, Checkbox, Table, Container } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { isAdminUser } from '../../../../libs/auth';
import { selectBusinessBrandIdentity } from '../../../../selectors/business';
import { selectUser } from '../../../../selectors/user';
import AdminRequiredMessage from '../components/AdminRequiredMessage';

import './CloverIntegrationSettings.scss';

const CloverIntegrationSettings = (props) => {
  const { isSubmitting, onSubmitSettings } = props;
  const { loading, importHours = true, importMenu = true, importCOLO = true } = props;
  const [hoursEnabled, setHoursEnabled] = useState(importHours);
  const [menuEnabled, setMenuEnabled] = useState(importMenu);
  const [oloEnabled, setOLOEnabled] = useState(importCOLO);
  const [hasChanged, setIsChanged] = useState(true);
  const user = useSelector(selectUser) || {};
  const isAdmin = isAdminUser(user);
  const brandIdentity = useSelector(selectBusinessBrandIdentity);

  const onSubmit = () => {
    onSubmitSettings({
      import_hours: hoursEnabled,
      import_menu: menuEnabled,
      import_colo: oloEnabled,
    });
    setIsChanged(false);
  };

  function getFormSegment() {
    if (!isAdmin) {
      return (
        <Segment attached>
          <AdminRequiredMessage brandIdentity={brandIdentity} />
        </Segment>
      );
    }

    return (
      <Segment
        attached="bottom"
        loading={loading}
        className="borderless-segment clover-settings-segment"
      >
        <Segment attached="top">
          <Table className="clover-settings-table" unstackable>
            <Table.Header>
              <Table.HeaderCell textAlign="left">Clover Sync Settings</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Enabled</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell textAlign="left">Import Hours from Clover</Table.Cell>
                <Table.Cell textAlign="right">
                  <Checkbox
                    name="hoursEnabled"
                    checked={hoursEnabled}
                    onChange={(_, { checked }) => {
                      setIsChanged(true);
                      setHoursEnabled(checked);
                    }}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">Import Menu from Clover</Table.Cell>
                <Table.Cell textAlign="right">
                  <Checkbox
                    name="menuEnabled"
                    checked={menuEnabled}
                    onChange={(_, { checked }) => {
                      setIsChanged(true);
                      setMenuEnabled(checked);
                    }}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="left">Use Clover Online Ordering</Table.Cell>
                <Table.Cell textAlign="right">
                  <Checkbox
                    name="oloEnabled"
                    checked={oloEnabled}
                    onChange={(_, { checked }) => {
                      setIsChanged(true);
                      setOLOEnabled(checked);
                    }}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
        <Segment attached="bottom">
          <Container textAlign="center">
            <Button
              primary
              size="big"
              onClick={onSubmit}
              disabled={isSubmitting || !hasChanged}
              loading={isSubmitting}
            >
              Save and Import
            </Button>
          </Container>
        </Segment>
      </Segment>
    );
  }

  return getFormSegment();
};

export default CloverIntegrationSettings;
