import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Message } from 'semantic-ui-react';

import API from '../../libs/api';
import { Field, InlineFieldContainer, InputField, PasswordInput } from '../common';
import FormContainer from '../common/FormContainer';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
      errorMessage: '',
      infoMessage: '',
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }

  setFormMessage(messageField, message, resetEventually = true) {
    if (resetEventually) {
      this.setState({ [messageField]: message }, () =>
        setTimeout(() => this.setFormMessage(messageField, '', false), 3000),
      );
    }

    this.setState({ [messageField]: message });
  }

  resetForm() {
    this.setState({
      oldPassword: '',
      newPassword1: '',
      newPassword2: '',
    });
  }

  toggleLoading(cb = null) {
    this.setState(
      ({ loading }) => ({ loading: !loading }),
      () => cb && cb(),
    );
  }

  validateParams() {
    const { newPassword1, newPassword2 } = this.state;

    if (newPassword1 !== newPassword2) {
      this.setFormMessage('errorMessage', 'Passwords do not match');
      return false;
    }

    return true;
  }

  async submit() {
    const { oldPassword, newPassword1, newPassword2 } = this.state;
    const paramsAreValid = this.validateParams();

    if (paramsAreValid) {
      this.toggleLoading(async () => {
        try {
          // Login
          const loginResult = await API.changePassword(oldPassword, newPassword1, newPassword2);

          // Get result
          const { data } = loginResult;
          const { detail } = data;

          if (detail) {
            this.setFormMessage('infoMessage', detail);
          }
        } catch (e) {
          this.setFormMessage('errorMessage', 'Failed to set new Password');
        } finally {
          this.toggleLoading();
          this.resetForm();
        }
      });
    }
  }

  render() {
    const { oldPassword, newPassword1, newPassword2, errorMessage, infoMessage, loading } =
      this.state;

    const { title } = this.props;

    return (
      <FormContainer loadedKeyPath={['user', 'core']}>
        <InlineFieldContainer title={<h2>{title}</h2>} enableDelete={false}>
          <Form
            onSubmit={() => this.submit()}
            error={errorMessage.length !== 0}
            success={infoMessage.length !== 0}
            loading={loading}
          >
            <Form.Field>
              <Field title="Old Password" style={{ width: '50%' }}>
                <PasswordInput
                  value={oldPassword}
                  placeholder="Old Password"
                  name="oldPassword"
                  onChange={this.handleChange}
                />
              </Field>
            </Form.Field>
            <Form.Field>
              <Field title="New Password" style={{ width: '50%' }}>
                <PasswordInput
                  value={newPassword1}
                  placeholder="New Password"
                  name="newPassword1"
                  onChange={this.handleChange}
                />
              </Field>
            </Form.Field>
            <Form.Field>
              <InputField
                title="New Password Confirmation"
                value={newPassword2}
                placeholder="New Password Confirmation"
                name="newPassword2"
                onChange={this.handleChange}
                type="password"
                style={{ width: '50%' }}
              />
            </Form.Field>
            <Form.Field>
              <Button type="submit" primary>
                Change Password
              </Button>
            </Form.Field>
            <Message error header={errorMessage} />
            <Message success header={infoMessage} />
          </Form>
        </InlineFieldContainer>
      </FormContainer>
    );
  }
}

ChangePasswordForm.propTypes = {
  title: PropTypes.string,
};

ChangePasswordForm.defaultProps = {
  title: 'Security',
};

export default ChangePasswordForm;
