import clsx from 'clsx';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

export function findPropByName(props, propName) {
  return (props || []).find((prop) => prop.name === propName);
}

export function findPropIndexByName(props, propName) {
  return props.findIndex((prop) => prop.name === propName);
}

export function getStaticPropValue(props, propName, defaultValue = undefined) {
  return _get(findPropByName(props, propName), 'resolve', defaultValue);
}

export function getCollectionPropValue(props, propName) {
  return _get(findPropByName(props, propName), 'resolve.value');
}

export function removeClassName(className, classNameToRemove = '') {
  if (_isEmpty(className)) {
    return '';
  }

  return className
    .split(' ')
    .filter((name) => name !== classNameToRemove)
    .join(' ');
}

export function createComponentBaseProps(className, style) {
  return [
    {
      name: 'className',
      resolve: className || '',
    },
    {
      name: 'style',
      resolve: {
        type: 'COLLECTION',
        value: style || {},
      },
    },
  ];
}

export function extractComponentBaseProps(recipeSnippet) {
  const { props } = recipeSnippet || {};

  const className = _get(findPropByName(props, 'className'), 'resolve', '');
  const style = _get(findPropByName(props, 'style'), 'resolve.value', {});

  return { className, style };
}

export function createProp(name, value, required = false) {
  const baseProps = {
    name,
    resolve: value,
  };

  if (required) {
    return { ...baseProps, required: true };
  }

  return baseProps;
}

export function createCollectionValue(value) {
  return {
    type: 'COLLECTION',
    value,
  };
}

export function replaceProp(recipeSnippet, index, propName, value) {
  const newProps = [...recipeSnippet.props];
  newProps[index] = createProp(propName, value);
  return newProps;
}

export function addStaticPropIfDefined(recipeSnippet, propName, value, { override = false } = {}) {
  if (!_isNil(value) && !_isNil(propName)) {
    const index = findPropIndexByName(recipeSnippet.props, propName);

    if (index !== -1) {
      if (!override) {
        return recipeSnippet;
      }
      return {
        ...recipeSnippet,
        props: replaceProp(recipeSnippet, index, propName, value),
      };
    }

    return {
      ...recipeSnippet,
      props: [...(recipeSnippet.props || []), createProp(propName, value)],
    };
  }

  return recipeSnippet;
}

export function addCollectionPropIfDefined(recipeSnippet, propName, value) {
  if (!_isNil(value) && !_isNil(propName)) {
    const prop = {
      type: 'COLLECTION',
      value: value || {},
    };
    return addStaticPropIfDefined(recipeSnippet, propName, prop);
  }
  return recipeSnippet;
}

export function addGraphqlQueryPropIfDefined(
  recipeSnippet,
  propName,
  queryName,
  data,
  { payload = '', args = {} } = {},
) {
  if (!_isNil(queryName) && !_isNil(propName) && !_isNil(data)) {
    const query = {
      type: 'QUERY',
      value: {
        query: {
          [queryName]: {
            __args: args || {},
            ...(data || {}),
          },
        },
        payload: payload || queryName,
      },
    };

    return {
      ...recipeSnippet,
      props: [...(recipeSnippet.props || []), createProp(propName, query)],
    };
  }
  return recipeSnippet;
}

export function addComponentConfigurationDataGraphQlQuery(
  element,
  componentName,
  componentIdentifier,
  fieldName,
  { propName, data } = {},
) {
  const queryArgs = {
    fastId: {
      eq: componentName,
    },
    componentIdentifier: {
      eq: componentIdentifier,
    },
  };

  const finalData = !_isEmpty(data) ? data : { [fieldName]: true };

  return addGraphqlQueryPropIfDefined(
    element,
    propName || fieldName,
    'fishermanWebsiteComponent',
    {
      data: finalData,
    },
    {
      payload: `fishermanWebsiteComponent.data.${fieldName}`,
      args: queryArgs,
    },
  );
}

export function createFinalClassName(className, defaultClassName) {
  if (className === defaultClassName) {
    return className || '';
  }

  return clsx(className, defaultClassName) || '';
}

export function getComponentClassName(props, removeDefaultName) {
  const className = getStaticPropValue(props, 'className');

  return removeClassName(className, removeDefaultName);
}
