import React, { useState } from 'react';

import PropTypes from 'prop-types';

import _has from 'lodash/has';

import API from '../../../../../../../libs/api';
import { BUSINESS_TYPE_MERCHANTS } from '../../../constants';
import {
  CatalogItemModifierListInfoPropType,
  CatalogModifierListPropType,
  CatalogModifierPropType,
} from '../../../proptypes';
import { getCatalogObjectId } from '../../../utils';
import ModifierSetDetail from '../ModifierSetDetail';
import ModifierSetForm from '../ModifierSetForm';

const propTypes = {
  initialItemModifierListInfo: CatalogItemModifierListInfoPropType,
  initialModifierList: CatalogModifierListPropType.isRequired,
  initialModifiers: PropTypes.arrayOf(CatalogModifierPropType).isRequired,
  itemsAppliedTo: PropTypes.number,
  businessType: PropTypes.string,
  businessId: PropTypes.number.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  isNestedForm: PropTypes.bool,
};

const defaultProps = {
  initialItemModifierListInfo: null,
  itemsAppliedTo: null,
  businessType: BUSINESS_TYPE_MERCHANTS,
  onSave: () => null,
  onDelete: () => null,
  isNestedForm: false,
};

function ModifierSetToggle({
  initialItemModifierListInfo,
  initialModifierList,
  initialModifiers,
  itemsAppliedTo,
  businessType,
  businessId,
  onSave,
  onDelete,
  isNestedForm,
}) {
  const isNewModifierSet = !_has(initialModifierList, 'object_id');
  const [shouldShowDetails, setShouldShowDetails] = useState(!isNewModifierSet);
  const [itemModifierListInfo, setItemModifierListInfo] = useState(initialItemModifierListInfo);
  const [modifierList, setModifierList] = useState(initialModifierList);
  const [modifiers, setModifiers] = useState(initialModifiers);

  function handleSave(savedModifierListData, savedModifiersData, modifierListInfoFields) {
    const oldModifierListId = getCatalogObjectId(modifierList);
    let newItemModifierListInfo = null;
    setModifierList(savedModifierListData);
    setModifiers(savedModifiersData);
    if (itemModifierListInfo) {
      newItemModifierListInfo = {
        item: itemModifierListInfo.item,
        modifier_list: savedModifierListData.object_id,
        min_selected_modifiers: modifierListInfoFields.minSelectedModifiers,
        max_selected_modifiers: modifierListInfoFields.maxSelectedModifiers,
      };
      setItemModifierListInfo(newItemModifierListInfo);
    }
    const updatedModifierSet = {
      itemModifierListInfo: newItemModifierListInfo,
      modifierList: savedModifierListData,
      modifiers: savedModifiersData,
    };
    onSave(oldModifierListId, updatedModifierSet);
    setShouldShowDetails(true);
  }

  async function handleDelete() {
    if (!modifierList.object_id) {
      onDelete({ localId: modifierList.localId });
      return;
    }
    if (isNestedForm) {
      // don't remove the modifier set from the backend if this is in the item form
      onDelete({ object_id: modifierList.object_id });
      return;
    }
    // NOTE: any server errors will be caught in the child form or detail components and dealt with there
    await Promise.all(
      modifiers.map((modifier) => {
        if (modifier.object_id) {
          return API.removeCatalogObject(businessType, businessId, modifier.object_id);
        }
        return Promise.resolve();
      }),
    );

    await API.removeCatalogObject(businessType, businessId, modifierList.object_id);

    onDelete({ object_id: modifierList.object_id });
  }

  return (
    <section>
      {shouldShowDetails ? (
        <ModifierSetDetail
          itemModifierListInfo={itemModifierListInfo}
          modifierList={modifierList}
          modifiers={modifiers}
          itemsAppliedTo={itemsAppliedTo}
          onEdit={() => setShouldShowDetails(false)}
          onDelete={handleDelete}
        />
      ) : (
        <ModifierSetForm
          initialItemModifierListInfo={itemModifierListInfo}
          initialModifierList={modifierList}
          initialModifiers={modifiers}
          businessType={businessType}
          businessId={businessId}
          onSave={handleSave}
          onDelete={handleDelete}
          isNestedForm={isNestedForm}
        />
      )}
    </section>
  );
}

ModifierSetToggle.propTypes = propTypes;
ModifierSetToggle.defaultProps = defaultProps;

export default ModifierSetToggle;
