import PropTypes from 'prop-types';

import { ColumnPropType } from './column.types';
import { BaseBlockType } from './common.types';
import { FIXED_COLUMN_SIZE_MODE, AUTO_ADJUSTED_COLUMN_SIZE_MODE } from '../constants/types';

export const RowPropType = PropTypes.shape({
  ...BaseBlockType,
  columns: PropTypes.arrayOf(ColumnPropType),
  columnSizingMode: PropTypes.oneOf([FIXED_COLUMN_SIZE_MODE, AUTO_ADJUSTED_COLUMN_SIZE_MODE]),
  stretched: PropTypes.bool,
  textAlign: PropTypes.oneOf(['left', 'center', 'right', 'justified']),
  verticalAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
});
