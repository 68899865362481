import React, { useState } from 'react';

import _isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import { LOADING_MESSAGE } from './CustomPagesTab.constants';
import { flagBusinessSaved } from '../../../../../actions/business';
import ErrorHandler from '../../../../../libs/errors/errors';
import CustomPageGrid from '../../../../modules/custom-pages/components/CustomPageGrid/CustomPageGrid';
import CustomPageToolbar from '../../../../modules/custom-pages/components/CustomPageToolbar/CustomPageToolbar';
import CustomPagesList from '../../../../modules/custom-pages/components/CustomPagesList/CustomPagesList';
import { CustomPagesProvider } from '../../../../modules/custom-pages/contexts/CustomPages.context';
import { useCustomPages, useRecipeNodePage } from '../../../../modules/custom-pages/hooks';
import LoadingMessage from '../../../../modules/foundation/components/LoadingMessage/LoadingMessage';
import useAsyncEffect from '../../../../modules/foundation/hooks/use-async-effect';
import { useLatestRefValue } from '../../../../modules/utils/hooks';

import './CustomPagesTab.scss';

const propTypes = {};
const defaultProps = {};

export default function CustomPagesTab() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [activePageId, setActivePageId] = useState(null);

  const { customPages, refreshCustomPages, createOrUpdateCustomPage } = useCustomPages();

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await refreshCustomPages();
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const activePage = customPages.find((page) => page.id === activePageId) || {};
  const latestActivePageRef = useLatestRefValue(activePage);

  const { page, recipeNode = {} } = activePage;
  const { node } = recipeNode;
  const { pageState, pageNode, ...rest } = useRecipeNodePage({ page, node });

  async function savePageNode(updatedNode) {
    const currentActivePage = latestActivePageRef.current;
    const { page: currentPage, recipeNode: currentRecipeNode = {} } = currentActivePage;

    await createOrUpdateCustomPage(
      currentPage,
      { ...currentRecipeNode, node: updatedNode },
      currentActivePage,
    );
    dispatch(flagBusinessSaved(true));
  }

  const context = {
    activePage,
    activePageState: pageState,
    setActivePageId,
    savePageNode,
    ...rest,
  };

  const displayPageList = !loading && _isEmpty(activePage);
  const displayPageGrid = !loading && !_isEmpty(activePage);

  return (
    <CustomPagesProvider value={context}>
      <div className="custom-pages-tab">
        {loading && <LoadingMessage message={LOADING_MESSAGE} />}
        {displayPageList && <CustomPagesList />}
        {displayPageGrid && (
          <div className="main-content">
            <CustomPageToolbar customPage={activePage} />
            <CustomPageGrid pageState={pageState} />
          </div>
        )}
      </div>
    </CustomPagesProvider>
  );
}

CustomPagesTab.propTypes = propTypes;
CustomPagesTab.defaultProps = defaultProps;
