import _get from 'lodash/get';

import { DEFAULT_TEXT_BLOCK_CLASS_NAME, TEXT_BLOCK_PAGE_CONTENT_QUERY } from './constants';
import { TEXT_BLOCK_ELEMENT_TYPE, TEXT_BLOCK_COMPONENT_NAME } from '../../../../constants/types';
import { createFishermanComponent, getComponentId } from '../utils/components';
import { getFunctionArgValue } from '../utils/functions';
import {
  createFinalClassName,
  extractComponentBaseProps,
  getCollectionPropValue,
  createProp,
  createComponentBaseProps,
} from '../utils/props';
import { createPageComponentQuery, getComponentContentNodes } from '../utils/queries';

export function createTextBlockElement(data = {}, context = {}) {
  const { id, style, className, componentName, componentIdentifier } = data;
  const { page = {}, isTemplate = false } = context;
  const { id: pageId } = page || {};

  const finalClassName = createFinalClassName(className, DEFAULT_TEXT_BLOCK_CLASS_NAME);
  const finalPageId = isTemplate ? null : pageId;

  return createFishermanComponent(TEXT_BLOCK_COMPONENT_NAME, id, [
    ...createComponentBaseProps(finalClassName, style),
    createProp('content', {
      type: 'FUNCTION',
      value: {
        name: 'getComponentContentNodeContent',
        requires: '../utils/utils',
        importType: 'OBJECT',
        args: [
          {
            name: 'components',
            value: createPageComponentQuery(finalPageId, TEXT_BLOCK_PAGE_CONTENT_QUERY),
          },
          {
            name: 'componentName',
            value: componentName,
          },
          {
            name: 'componentId',
            value: componentIdentifier || null,
          },
          {
            name: 'defaultValue',
            value: '',
          },
        ],
      },
    }),
  ]);
}

export function createTextBlockState(recipeSnippet = {}, context = {}) {
  const { props } = recipeSnippet || {};
  const { state, page = {} } = context;
  const { id: pageId } = page;

  const type = TEXT_BLOCK_ELEMENT_TYPE;
  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  const contentFunc = getCollectionPropValue(props, 'content');

  let componentName = TEXT_BLOCK_COMPONENT_NAME;
  let componentIdentifier = null;

  if (contentFunc) {
    componentName = getFunctionArgValue(contentFunc, 'componentName');
    componentIdentifier = getFunctionArgValue(contentFunc, 'componentId');
  }

  const contentNodeOptions = {
    componentName,
    componentIdentifier,
    pageId,
  };
  const [component, contentNodes] = getComponentContentNodes(contentNodeOptions, state);
  const componentConfigurationId = _get(component, 'id', null);
  const content = _get(contentNodes, '[0].content', '');
  const contentNodeId = _get(contentNodes, '[0].id', null);
  const contentName = _get(contentNodes, '[0].contentName', '');

  return {
    id,
    className,
    style,
    type,
    componentConfigurationId,
    componentName,
    componentIdentifier,
    content,
    contentName,
    contentNodeId,
  };
}

export function createTextBlockTemplateState(data = {}) {
  const {
    componentIdentifier,
    componentConfigurationId,
    content,
    contentName,
    contentNodeId,
    ...restTextBlock
  } = data;

  return restTextBlock;
}
