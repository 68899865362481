import PropTypes from 'prop-types';

import { BaseBlockType } from './common.types';
import { RowPropType } from './row.types';

export const GridPropType = PropTypes.shape({
  ...BaseBlockType,
  stackable: PropTypes.bool,
  rows: PropTypes.arrayOf(RowPropType),
});
