import { useState, useEffect } from 'react';

export default function useMultipleHeros(
  recipeOptions,
  initialName = '',
  initialConfigurations = [],
) {
  const [heroComponentConfigurations, setHeroComponentConfigurations] = useState({});

  function initializeHeros(options) {
    const heros = options.reduce((acc, curatedHeroName) => {
      acc[curatedHeroName] = curatedHeroName === initialName ? initialConfigurations : [];
      return acc;
    }, {});
    setHeroComponentConfigurations(heros);
  }

  useEffect(() => initializeHeros(recipeOptions), [recipeOptions]);

  function setHeroConfigurationsForName(curatedHeroName, componentConfigurations) {
    setHeroComponentConfigurations((prevHeros) => ({
      ...prevHeros,
      [curatedHeroName]: componentConfigurations,
    }));
  }

  function getHeroConfigurationsForName(curatedHeroName) {
    return heroComponentConfigurations[curatedHeroName];
  }

  function reset() {
    initializeHeros(recipeOptions);
  }

  return { getHeroConfigurationsForName, setHeroConfigurationsForName, reset };
}
