import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Form } from 'semantic-ui-react';

import { getDomLocationOptions } from './DomLocationDropdown.utils';
import useResponsive from '../../../../../../../../../foundation/hooks/use-responsive';

import './DomLocationDropdown.scss';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
};

export default function DomLocationDropdown({ value, onChange }) {
  const { isMobile } = useResponsive();

  return (
    <Form.Field>
      <Dropdown
        upward={isMobile}
        name="domLocation"
        options={getDomLocationOptions()}
        value={value}
        onChange={onChange}
        className="basic label item-dropdown-dom-location"
      />
    </Form.Field>
  );
}

DomLocationDropdown.propTypes = propTypes;
DomLocationDropdown.defaultProps = defaultProps;
