import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Message, Loader } from 'semantic-ui-react';

import _debounce from 'lodash/debounce';
import AceEditor from 'react-ace';

import { EDITOR_MESSAGE_ERROR } from './CodeEditorModal.constants';
import { parseCssCode, parseJsonCode } from './CodeEditorModal.utils';
import ErrorHandler from '../../../../libs/errors';
import { ObjectPropType } from '../../../../libs/proptypes';
import CloseableModal from '../../../common/CloseableModal';
import { editorUpdate } from '../../recipes-editor-v2/components/RecipeEditor/components/Editor/components/AceEditorWrapper/AceEditorWrapper.utils';

import './CodeEditorModal.scss';

const propTypes = {
  open: PropTypes.bool,
  isSaving: PropTypes.bool,
  trigger: PropTypes.element,
  mode: PropTypes.string,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  initialValue: ObjectPropType,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const defaultProps = {
  open: undefined,
  isSaving: false,
  readOnly: false,
  trigger: null,
  mode: 'json',
  isLoading: false,
  initialValue: null,
};

const CodeEditorModal = ({
  open,
  isSaving,
  trigger,
  onCancel,
  onSave,
  mode,
  isLoading,
  readOnly,
  initialValue,
}) => {
  const [code, setCode] = useState({});
  const [parsingError, setParsingError] = useState();

  useEffect(() => {
    setCode(initialValue);
    setParsingError(false);
  }, [initialValue]);

  async function handleEditorUpdate(updatedValue) {
    try {
      const obj = await editorUpdate(mode, updatedValue);
      const { value: nextValue, parsingError: nextParsingError } = obj;
      setCode(nextValue);
      setParsingError(nextParsingError);
    } catch (error) {
      ErrorHandler.capture(error);
    }
  }

  function getEditorValue() {
    const editorValue = {
      json: () => parseJsonCode(code, parsingError),
      css: () => parseCssCode(code),
    };

    return editorValue[mode]();
  }

  function handleCancel() {
    setCode({});
    setParsingError(false);
    onCancel();
  }

  function handleSave() {
    if (parsingError) {
      return;
    }
    onSave(code);
  }

  return (
    <CloseableModal
      className="developer-modal raw-json-edit"
      onClose={handleCancel}
      open={open}
      size="small"
      trigger={trigger}
      dimmer="inverted"
      header={
        <>
          <span>{mode}</span> Editor
        </>
      }
    >
      <Modal.Content>
        <AceEditor
          readOnly={readOnly}
          placeholder="Code Editor"
          mode={mode}
          theme="solarized_light"
          name="Edit Raw JSON"
          onChange={_debounce(handleEditorUpdate, 1000)}
          fontSize={14}
          showPrintMargin
          showGutter
          highlightActiveLine
          style={{ width: '100%', height: '100%' }}
          value={getEditorValue()}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
        {isLoading && <Loader />}
      </Modal.Content>
      <Modal.Actions>
        {parsingError && (
          <Message error>
            <Message.Header>Parsing Error</Message.Header>
            <Message.List>
              {EDITOR_MESSAGE_ERROR[mode].map((errorMessage) => (
                <Message.Item>{errorMessage}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}

        {readOnly && (
          <Button loading={isSaving} primary onClick={handleSave}>
            Save
          </Button>
        )}
      </Modal.Actions>
    </CloseableModal>
  );
};

export default CodeEditorModal;

CodeEditorModal.propTypes = propTypes;
CodeEditorModal.defaultProps = defaultProps;
