import _includes from 'lodash/includes';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';

import { dayMapByDay, dayMapByValue, emptyHours } from './EditTeamMemberModal.constants';
import { getLocationDisplay } from '../../../../../../../libs/location';

export function formatHoursForSave(memberHours) {
  const formatTime = (time) => (time.length === 5 ? `${time}:00` : time);
  const preparedHours = memberHours
    .filter(({ open: hourOpen, close: hourClose }) => hourOpen && hourClose)
    .map(({ id, open: hourOpen, close: hourClose, day }) => {
      let baseHour = {
        open_time: formatTime(hourOpen),
        close_time: formatTime(hourClose),
        start_day: dayMapByDay[day],
        end_day: dayMapByDay[day],
      };
      if (id) {
        baseHour = { ...baseHour, id };
      }
      const hourOpenAsNum = parseInt(hourOpen.replace(':', ''), 10);
      const hourCloseAsNum = parseInt(hourClose.replace(':', ''), 10);
      if (hourCloseAsNum < hourOpenAsNum) {
        baseHour = {
          ...baseHour,
          end_day:
            day === Object.keys(dayMapByValue).length ? dayMapByDay[1] : dayMapByDay[day + 1],
        };
      }
      return baseHour;
    });

  return preparedHours;
}

export function createTemplateHoursForMissingDays(emptyHoursTemplate, teamMemberHours) {
  const emptyDaysToAdd = emptyHoursTemplate.reduce((daysToAdd, currentDay) => {
    const { day } = currentDay;
    if (!teamMemberHours.map(({ start_day: startDay }) => dayMapByValue[startDay]).includes(day)) {
      daysToAdd.push(day);
    }
    return daysToAdd;
  }, []);
  return emptyHoursTemplate.filter(({ day }) => emptyDaysToAdd.includes(day));
}

export function formatHours(hours, selectedHours, setSelectedHours) {
  if (_isEmpty(hours)) {
    setSelectedHours(emptyHours);
  } else {
    const formattedHours = (hours || [])
      .map(({ id, start_day: startDay, open_time: openTime, close_time: closeTime }) => {
        if (
          !_includes(
            selectedHours.map(({ id: existingId }) => existingId),
            id,
          )
        ) {
          const baseHour = {
            id,
            localId: null,
            day: dayMapByValue[startDay],
            open: openTime,
            close: closeTime,
          };
          return baseHour;
        }
        return null;
      })
      .filter(Boolean);
    const emptyDays = createTemplateHoursForMissingDays(emptyHours, hours);
    setSelectedHours((prev) => _uniq([...prev, ...formattedHours, ...emptyDays]));
  }
}

export function formatLocations(
  business,
  allLocations,
  setAvailableLocations,
  locations,
  setSelectedLocations,
) {
  const { type: businessType } = business;
  const isMerchant = businessType === 'merchant';
  if (allLocations) {
    setAvailableLocations(allLocations.map((location) => getLocationDisplay(location)));
    const ids = (locations || []).map(({ merchant_location: merchantLocation, location }) =>
      isMerchant ? merchantLocation : location,
    );
    const selectedLocations = allLocations
      .filter((location) => ids.includes(location.id))
      .map((location) => getLocationDisplay(location));
    setSelectedLocations(selectedLocations);
  }
}

export function formatLocationsForSave(business, data, allLocations, setSelectedLocations) {
  const { type: businessType } = business;
  const isMerchant = businessType === 'merchant';

  const userSelectedLocations = allLocations
    .filter((location) => data.includes(getLocationDisplay(location)))
    .map(({ id, ...rest }) => ({ id, name: getLocationDisplay({ ...rest }) }));
  setSelectedLocations(userSelectedLocations.map(({ name }) => name));

  const userSelectedLocationPartials = userSelectedLocations.map(({ id }) => ({
    location: isMerchant ? null : id,
    merchant_location: isMerchant ? id : null,
  }));
  return userSelectedLocationPartials;
}

export function getAvailableSocialMediaAccounts(
  socialMediaOptions = [],
  selectedSocialMediaAccounts = [],
) {
  return socialMediaOptions.reduce((availableSocialMediaAccounts, currentSocialMediaAccount) => {
    if (
      !selectedSocialMediaAccounts
        .map(({ platform }) => platform)
        .includes(currentSocialMediaAccount)
    ) {
      availableSocialMediaAccounts.push(currentSocialMediaAccount);
    }

    return availableSocialMediaAccounts;
  }, []);
}

export function getTitleRequirements({ name }) {
  return `Ensure you mention the team member name: ${name}.`;
}

export function getDescriptionRequirements({ name, role, teams }) {
  let additionalRequirements = `Keep in mind that name of the team member is ${name}`;

  if (!_isEmpty(role)) {
    additionalRequirements += `, with a role of ${role}`;
  }
  if (!_isEmpty(teams)) {
    additionalRequirements += `, and is part of the team(s): ${teams.join(', ')}`;
  }

  additionalRequirements += '.';
  return additionalRequirements;
}
