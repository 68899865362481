import React, { useContext, useState, useEffect } from 'react';

import { Search } from 'semantic-ui-react';

import useDebounce from '../../../../../hooks/useDebounce/use-debounce';
import { TypographyContext } from '../../contexts/Typography.context';

import './SearchFonts.scss';

const propTypes = {};
const defaultProps = {};

export default function SearchFonts() {
  const { searchQuery, setSearchQuery } = useContext(TypographyContext);

  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounce(query);

  function onSearchChange(_, { value }) {
    setQuery(value);
  }

  useEffect(() => {
    setSearchQuery(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  return (
    <Search
      className="search-fonts"
      placeholder="Search fonts"
      onSearchChange={onSearchChange}
      value={query}
      aligned="left"
      fluid
    />
  );
}

SearchFonts.propTypes = propTypes;
SearchFonts.defaultProps = defaultProps;
