import PropTypes from 'prop-types';

import { ObjectPropType } from '../../../../libs/proptypes';
import { PagePropType } from '../../pages/types';

export const CustomPagePropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  page: PagePropType,
  recipeNode: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    node: ObjectPropType,
  }),
});
