import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Button, Header, Grid } from 'semantic-ui-react';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormFieldTypes } from './SelectFormFieldType.constants';
import { RECIPE_FORM_FIELD_TYPES } from '../../../../../../../../constants/form';
import { EditFormBlockContext } from '../../../../EditFormBlock.context';

import './SelectFormFieldType.scss';

const propTypes = {
  onSelectFieldType: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function SelectFormFieldType({ onSelectFieldType, onCancelSelect }) {
  const { legacy } = useContext(EditFormBlockContext);

  const formFields = legacy
    ? FormFieldTypes.filter(({ value }) => value !== RECIPE_FORM_FIELD_TYPES.FileUpload)
    : FormFieldTypes;

  return (
    <div className="select-form-field-type">
      <Header as="h3">
        Insert content
        <FontAwesomeIcon className="close-icon" icon={faXmark} onClick={onCancelSelect} />
      </Header>
      <Grid columns={3}>
        {formFields.map(({ label, value, icon, initialData = {} }) => (
          <Grid.Column key={label + value} className="field-type-selector">
            <Button basic onClick={() => onSelectFieldType(value, { initialData })}>
              <FontAwesomeIcon className="icon" icon={icon} />
              {label}
            </Button>
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
}

SelectFormFieldType.propTypes = propTypes;
SelectFormFieldType.defaultProps = defaultProps;
