import React, { useState } from 'react';

import { Pagination } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import PartialCard from './components/PartialCard';
import useDebounce from '../../../../../hooks/useDebounce/use-debounce';
import API from '../../../../../libs/api';
import ErrorHandler from '../../../../../libs/errors';
import useCopyToClipboard from '../../../hooks/use-copy-to-clipboard';
import usePartials from '../../../hooks/use-partials';
import PartialSearch from '../../../partials/components/PartialSearch';

import './RecipeDirectories.scss';

function RecipeDirectories() {
  const [querySearch, setQuerySearch] = useState('');
  const debouncedQuery = useDebounce(querySearch, 500);
  const [handleCopy] = useCopyToClipboard();
  const { partials, isLoading, totalPages, currentPage, setCurrentPage } = usePartials(
    debouncedQuery,
    1,
  );

  function handleSearchChange(e, { value }) {
    setQuerySearch(value);
  }

  async function handleCopyToClipboard(partialId) {
    try {
      const resp = await API.getRecipePartialFile(partialId);
      const {
        data: { partial },
      } = resp;
      if (!partial) {
        return;
      }
      handleCopy(partial);
    } catch (error) {
      ErrorHandler.capture(error);
    }
  }

  return (
    <div className="recipe-directories">
      <div className="search-input">
        <PartialSearch
          placeholder="Search Partials..."
          onChange={handleSearchChange}
          q={querySearch}
          fluid
          loading={isLoading}
        />
      </div>
      <div className="search-results">
        {!_isEmpty(partials) &&
          partials.map(({ id, name, type, tags }) => (
            <PartialCard
              key={id}
              id={id}
              name={name}
              type={type}
              tags={tags}
              onCopyToClipboard={() => handleCopyToClipboard(id)}
            />
          ))}
      </div>
      <div className="pagination">
        <Pagination
          size="small"
          firstItem={null}
          lastItem={null}
          siblingRange={1}
          totalPages={totalPages}
          onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
          activePage={currentPage}
        />
      </div>
    </div>
  );
}

export default RecipeDirectories;
