import React from 'react';

import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';

import { initializeFontAwesomeLibrary } from '../components/modules/foundation/services/font-awesome-library';
import Routes from '../components/routes/Routes';
import Storage from '../libs/storage';

// eslint-disable-next-line import/order, import/imports-first
import 'easymde/dist/easymde.min.css';
// eslint-disable-next-line import/order, import/imports-first
import 'semantic-ui-css/semantic.min.css';
import '../styles/core/components/buttons.scss';
import '../styles/core/components/checkboxes.scss';
import '../styles/core/components/inputs.scss';
import '../styles/global.scss';

class App extends React.Component {
  static getFavIconUrl(size = 16) {
    return `/core/favicon-${size}.png`;
  }

  componentDidMount() {
    const { cookies } = this.props;
    Storage.setCookies(cookies);
    initializeFontAwesomeLibrary();
  }

  render() {
    return (
      <div className="app-container">
        <Helmet>
          <title>Fisherman Dashboard</title>
          <meta charSet="utf-8" />
          <meta name="description" content="Fisherman content management system" />
          <link rel="apple-touch-icon" sizes="180x180" href={App.getFavIconUrl(180)} />
          <link
            rel="shortcut icon"
            type="image/png"
            sizes="512x512"
            href={App.getFavIconUrl(512)}
          />
          <link
            rel="shortcut icon"
            type="image/png"
            sizes="192x192"
            href={App.getFavIconUrl(192)}
          />
          <link rel="shortcut icon" type="image/png" sizes="32x32" href={App.getFavIconUrl(32)} />
          <link rel="shortcut icon" type="image/png" sizes="16x16" href={App.getFavIconUrl(16)} />
        </Helmet>

        <Routes />
      </div>
    );
  }
}

export default Sentry.withProfiler(App);
