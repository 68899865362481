import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Search } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { MIN_CHARACTERS } from './CatalogItemSearch.constants';
import { searchItems, createSearchOptions } from './CatalogItemSearch.utils';
import {
  selectBusinessCatalogCategories,
  selectBusinessCatalogItems,
} from '../../../../../selectors/catalog';
import { CatalogCategoryPropType, CatalogItemPropType } from '../../proptypes/catalog';

import './CatalogItemSearch.scss';

const propTypes = {
  onResultSelect: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(CatalogCategoryPropType),
  items: PropTypes.arrayOf(CatalogItemPropType),
  excludeVariationIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  minCharacters: PropTypes.number,
};

const defaultProps = {
  categories: null,
  items: null,
  excludeVariationIds: [],
  minCharacters: MIN_CHARACTERS,
};

export default function CatalogItemSearch({
  onResultSelect: onResultSelectProp,
  categories: categoriesProp,
  items: itemsProps,
  excludeVariationIds,
  minCharacters,
}) {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  const storeCategories = useSelector(selectBusinessCatalogCategories);
  const storeItems = useSelector(selectBusinessCatalogItems);

  const categories = categoriesProp || storeCategories || [];
  const items = itemsProps || storeItems || [];

  const options = useMemo(
    () => createSearchOptions(categories, items, { excludeVariationIds }),
    [categories, items, excludeVariationIds],
  );

  function onResultSelect(e, { result }) {
    if (onResultSelectProp) {
      onResultSelectProp(result);
      setSearchQuery('');
    }
  }

  function onSearchChange(e, { value: newSearchQuery = '' }) {
    setLoading(true);
    setSearchQuery(newSearchQuery);
    setFilteredResults(searchItems(newSearchQuery, options));
    setLoading(false);
  }

  return (
    <Search
      className="catalog-item-search"
      loading={loading}
      placeholder="Search..."
      onResultSelect={onResultSelect}
      onSearchChange={onSearchChange}
      results={filteredResults}
      value={searchQuery}
      minCharacters={minCharacters}
    />
  );
}

CatalogItemSearch.propTypes = propTypes;
CatalogItemSearch.defaultProps = defaultProps;
