import React from 'react';

import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import StateUtils from '../../libs/state';

export default function FormContainer(props) {
  const { loadedKeyPath, children, loading } = props;

  if (loading) {
    return <Loader active inline />;
  }

  const state = useSelector((stateConnect) => stateConnect);
  const loaded = StateUtils.getObject(state, [...loadedKeyPath, '_loaded']);

  return _isEmpty(loadedKeyPath) || loaded ? children : <Loader active />;
}

FormContainer.propTypes = {
  loadedKeyPath: PropTypes.arrayOf(PropTypes.string),
};

FormContainer.defaultProps = {
  loadedKeyPath: [],
};
