import React from 'react';

import LoginBanner from './LoginBanner';
import LoginForm from '../common/LoginForm';

import '../../styles/core/pages/login.scss';

export default function LoginPage() {
  return (
    <div className="login-page">
      <LoginBanner />
      <div className="login-form-container">
        <LoginForm />
      </div>
    </div>
  );
}
