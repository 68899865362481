import { useState } from 'react';

import API from '../../../libs/api';
import { keysToCamelCase } from '../../../libs/strings';
import useAsyncEffect from '../../modules/foundation/hooks/use-async-effect';

function usePartials(querySearch, defaultPage) {
  const [partials, setPartials] = useState([]);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(defaultPage);

  async function getPartials() {
    const params = {
      query: querySearch,
      page: querySearch ? defaultPage : currentPage,
    };

    setError(null);
    setIsLoading(true);
    try {
      const { data } = await API.getRecipePartials(params);
      const { results, total_pages: pages } = data;
      if (results && pages) {
        const filteredResults = keysToCamelCase(results);
        setPartials(filteredResults);
        setTotalPages(pages);
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }

  useAsyncEffect(async () => {
    await getPartials();
  }, [querySearch, currentPage]);

  return {
    partials,
    isLoading,
    totalPages,
    currentPage,
    setCurrentPage,
    error,
  };
}

export default usePartials;
