import React from 'react';

import PropTypes from 'prop-types';
import { Loader, Button, Label } from 'semantic-ui-react';

import './RecipePatchActionButton.scss';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  version: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function RecipePatchActionButton({ disabled, onClick, label, isLoading, version }) {
  return (
    <Button disabled={disabled || isLoading} as="div" labelPosition="right" onClick={onClick}>
      <Button>{label}</Button>
      <Label color="grey" basic>
        {isLoading && <Loader size="mini" active inline />}
        {!isLoading && `v${version}`}
      </Label>
    </Button>
  );
}

RecipePatchActionButton.propTypes = propTypes;

export default RecipePatchActionButton;
