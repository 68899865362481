import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';

export function getColorString(color = {}) {
  if (_isString(color)) {
    return color;
  }

  const { hex = '', rgb = {} } = color || {};
  const { r = 0, g = 0, b = 0, a = 0 } = rgb || {};

  if (+a !== 1 && !_isEmpty(rgb)) {
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
  return hex;
}

export function constructColorFromString(color = '') {
  if (_isEmpty(color)) {
    return {};
  }
  if (color.startsWith('#')) {
    return { hex: color };
  }
  if (color.startsWith('rgb')) {
    const [r, g, b, a] = color
      .replace('rgba(', '')
      .replace('rgb(', '')
      .replace(')', '')
      .split(',')
      .map((c) => +c.trim());
    return { rgb: { r, g, b, a: a || 1 } };
  }
  return {};
}

export function getColorValue(color = {}) {
  if (_isString(color)) {
    return color;
  }

  const { hex = '', rgb = {} } = color || {};

  if (!_isEmpty(rgb)) {
    return rgb;
  }
  if (!_isEmpty(hex)) {
    return { hex };
  }
  return color;
}
