import React from 'react';

import { MenuItem } from 'semantic-ui-react';

import { SortableElement } from 'react-sortable-hoc';

const SortableMenuItem = SortableElement(({ menuItemIndex, ...passThroughProps }) => {
  return <MenuItem index={menuItemIndex} {...passThroughProps} />;
});

export default SortableMenuItem;
