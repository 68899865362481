import React from 'react';

import { Button, Checkbox, Form, Modal, Table } from 'semantic-ui-react';

import hoursLabels from '../../../constants/hours-labels';
import CloseableModal from '../CloseableModal';
import HoursForDay from '../Hours/components/HoursForDay';

import './Hours.scss';

class Hours extends React.Component {
  static labelForDay(day) {
    return hoursLabels[day - 1];
  }

  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      targetCopyDays: [],
      sourceCopyDay: null,
    };
    this.handleCopyHours = this.handleCopyHours.bind(this);
    this.handleClickOnCopy = this.handleClickOnCopy.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDayCopyTarget = this.handleDayCopyTarget.bind(this);
  }

  handleClickOnCopy(sourceDay) {
    this.setState({ sourceCopyDay: sourceDay });
    this.handleOpenModal();
  }

  handleOpenModal() {
    this.setState({ isDropdownOpen: true });
  }

  handleCloseModal() {
    this.setState({
      isDropdownOpen: false,
      targetCopyDays: [],
      sourceCopyDay: null,
    });
  }

  handleDayCopyTarget(targetDay) {
    this.setState((prevState) => {
      const newTargetCopyDays = prevState.targetCopyDays;
      const targetDayIndex = newTargetCopyDays.indexOf(targetDay);

      if (targetDayIndex === -1) {
        newTargetCopyDays.push(targetDay);
      } else {
        newTargetCopyDays.splice(targetDayIndex, 1);
      }
      return { targetCopyDays: newTargetCopyDays };
    });
  }

  handleCopyHours() {
    const { onCopyHours } = this.props;
    const { sourceCopyDay: sourceDay, targetCopyDays: targetDays } = this.state;
    onCopyHours(sourceDay, targetDays);
    this.handleCloseModal();
  }

  hoursForDay(day) {
    const { location } = this.props;
    return (location.hours || []).filter((hour) => hour.day === day && !hour.hour_group);
  }

  availableTargetDays() {
    const { sourceCopyDay } = this.state;
    const allDays = [1, 2, 3, 4, 5, 6, 7];
    if (sourceCopyDay === null) {
      return allDays;
    }

    allDays.splice(sourceCopyDay - 1, 1);
    return allDays;
  }

  render() {
    const { isDropdownOpen, targetCopyDays, sourceCopyDay } = this.state;
    const { onAddHour, onUpdateHour, onDeleteHour, location } = this.props;
    return (
      <>
        <CloseableModal
          className="hours-copy-modal"
          size="mini"
          open={isDropdownOpen}
          onClose={this.handleCloseModal}
          header={`Copy ${Hours.labelForDay(sourceCopyDay)} hours to`}
        >
          <Modal.Content>
            <Form>
              {this.availableTargetDays().map((day) => (
                <Form.Field key={day}>
                  <Checkbox
                    label={Hours.labelForDay(day)}
                    onChange={() => this.handleDayCopyTarget(day)}
                    checked={targetCopyDays.includes(day)}
                  />
                </Form.Field>
              ))}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={this.handleCopyHours} content="Apply" className="action" />
          </Modal.Actions>
        </CloseableModal>
        <Table celled definition className="hours-table">
          <Table.Body>
            {[1, 2, 3, 4, 5, 6, 7].map((day) => (
              <HoursForDay
                key={day}
                weekDayLabel={Hours.labelForDay(day)}
                hours={this.hoursForDay(day)}
                hourLabels={location.hour_labels}
                setHours={(dayHours) => this.handleSetHours(day, dayHours)}
                updateHour={onUpdateHour}
                deleteHour={onDeleteHour}
                addHour={() => onAddHour(day)}
                onCopy={() => this.handleClickOnCopy(day)}
                showHourLabels
                allowEmptyHours
              />
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}

export default Hours;
