import React from 'react';

import PropTypes from 'prop-types';
import { Button, Input } from 'semantic-ui-react';

const propTypes = {
  className: PropTypes.string,
  urls: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
const defaultProps = {
  className: undefined,
  urls: [],
};

function WoflowUrlsInputs({ className, urls, onChange, onRemove }) {
  return (
    <>
      {urls.map(({ url, key }) => (
        <Input
          className={className}
          key={key}
          value={url}
          onChange={(event, { value }) => onChange(key, value)}
          action={<Button icon="trash" onClick={() => onRemove(url)} />}
        />
      ))}
    </>
  );
}

WoflowUrlsInputs.propTypes = propTypes;
WoflowUrlsInputs.defaultProps = defaultProps;
export default WoflowUrlsInputs;
