import React from 'react';

import _ from 'lodash';
import AceEditor from 'react-ace';

export default class CodeBlock extends React.PureComponent {
  render() {
    const { name, mode, value, onChange, style = { height: 200 }, className = '' } = this.props;

    return (
      <AceEditor
        className={className}
        mode={mode}
        theme="solarized_light"
        name={name}
        onChange={_.debounce(onChange, 1000)}
        fontSize={14}
        showPrintMargin
        showGutter
        highlightActiveLine
        value={value}
        style={style}
        setOptions={{
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    );
  }
}
