import _isEmpty from 'lodash/isEmpty';

import { INTERACTION_LABEL } from './ProductDescriptionPageWidget.constants';
import { BEHAVIOR_EXTERNAL_CLICKTHRU, DISPLAY_TYPE_ICON } from '../constants';

export function generateProductDescriptionPageInteraction(url) {
  if (_isEmpty(url)) {
    return [];
  }

  return [
    {
      displayType: DISPLAY_TYPE_ICON,
      behaviorType: BEHAVIOR_EXTERNAL_CLICKTHRU,
      label: INTERACTION_LABEL,
      url,
    },
  ];
}
