import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

import OptionHeaderItem from './components/OptionHeaderItem';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const OptionHeader = ({ children }) => {
  return (
    <Dropdown icon={<Icon name="ellipsis horizontal" size="large" />} floating direction="left">
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

OptionHeader.propTypes = propTypes;

OptionHeader.Item = OptionHeaderItem;

export default OptionHeader;
