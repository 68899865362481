import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import './ButtonSubmit.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

function ButtonSubmit({ className, ...buttonProps }) {
  const finalClassName = `${className} button-submit`;
  return <Button type="submit" className={finalClassName} {...buttonProps} />;
}

ButtonSubmit.propTypes = propTypes;
ButtonSubmit.defaultProps = defaultProps;
export default ButtonSubmit;
