import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Search } from 'semantic-ui-react';

import {
  CANT_FIND_BUSINESS_TITLE,
  BUSINESS_SEARCH_LABEL,
  BUSINESS_SEARCH_PLACEHOLDER,
} from './BusinessSearchField.constants';
import {
  formatSearchResults,
  getResultTitle,
  createResultFromPlaceDetails,
} from './BusinessSearchField.utils';
import EnterAddressManuallyPrompt from './components/EnterAddressManuallyPrompt';
import useDebounce from '../../../hooks/useDebounce/use-debounce';
import API from '../../../libs/api';
import useAsyncEffect from '../../modules/foundation/hooks/use-async-effect';

import './BusinessSearchField.scss';

const propTypes = {
  onResultSelected: PropTypes.func.isRequired,
  setNoResultsFound: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  placeId: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  required: false,
  label: BUSINESS_SEARCH_LABEL,
  placeholder: BUSINESS_SEARCH_PLACEHOLDER,
  placeId: '',
  disabled: false,
};

export default function BusinessSearchField({
  onResultSelected,
  setNoResultsFound,
  required,
  label,
  placeholder,
  placeId,
  disabled,
  businessSearchQuery,
  onChange,
}) {
  const [results, setResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState({});

  const [loading, setLoading] = useState(false);

  const debouncedQuery = useDebounce(businessSearchQuery, 300);

  function onSearchChange(_, { value }) {
    onChange(value);
  }

  function onLocalResultSelected(_, { result }) {
    const { title } = result;

    if (title === CANT_FIND_BUSINESS_TITLE) {
      return;
    }

    onChange(getResultTitle(result));
    setSelectedResult(result);
    onResultSelected(result);
  }

  async function searchBusinesses() {
    if (!debouncedQuery) {
      return;
    }

    if (businessSearchQuery === getResultTitle(selectedResult)) {
      return;
    }

    setLoading(true);

    try {
      const resultsResponse = await API.searchBusiness(businessSearchQuery);
      const formattedResults = formatSearchResults(
        resultsResponse,
        <EnterAddressManuallyPrompt onSelectNoResults={() => setNoResultsFound(true)} />,
      );
      setResults(formattedResults);
    } finally {
      setLoading(false);
    }
  }

  async function getBusinessByPlaceId() {
    if (!placeId) {
      return;
    }

    const placeDetails = await API.getPlaceDetails(placeId);
    const result = createResultFromPlaceDetails(placeDetails);

    setResults([result]);
    onLocalResultSelected(null, { result });
  }

  useAsyncEffect(async () => {
    await searchBusinesses();
  }, [debouncedQuery]);

  useAsyncEffect(async () => {
    await getBusinessByPlaceId();
  }, [placeId]);

  return (
    <Form.Field className="business-search-input" required={required}>
      {label && <label>{label}</label>}
      <Search
        placeholder={placeholder}
        loading={loading}
        onSearchChange={onSearchChange}
        onResultSelect={onLocalResultSelected}
        value={businessSearchQuery}
        results={results}
        aligned="left"
        disabled={disabled}
      />
    </Form.Field>
  );
}

BusinessSearchField.propTypes = propTypes;
BusinessSearchField.defaultProps = defaultProps;
