import React, { useState } from 'react';

import { Tab } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import CustomersTab from './components/CustomersTab/CustomersTab';
import SubmissionsTab from './components/SubmissionsTab/SubmissionsTab';
import { DEFAULT_ERROR_MESSAGE } from '../../../libs/errors/constants';
import { selectBusiness } from '../../../selectors';
import { areFormSubmissionsEnabled } from '../../modules/customers/services/form-submissions';
import { DismissibleMessage, ErrorBoundary } from '../../modules/utils/components';

const propTypes = {};

const defaultProps = {};

export default function CustomersPage() {
  const business = useSelector(selectBusiness);
  const [activeIndex, setActiveIndex] = useState(0);

  const displaySubmissionsTab = areFormSubmissionsEnabled(business);

  function onTabChange(event, { activeIndex: index }) {
    setActiveIndex(index);
  }

  let panes = [
    {
      menuItem: 'CUSTOMERS',
      render: () => (
        <Tab.Pane attached={false} key="customers">
          <CustomersTab />
        </Tab.Pane>
      ),
    },
  ];

  if (displaySubmissionsTab) {
    panes = [
      ...panes,
      {
        menuItem: 'FORM SUBMISSIONS',
        render: () => (
          <Tab.Pane attached={false} key="form-submissions">
            <SubmissionsTab />
          </Tab.Pane>
        ),
      },
    ];
  }

  return (
    <ErrorBoundary
      fallback={<DismissibleMessage initialVisible content={DEFAULT_ERROR_MESSAGE} error />}
    >
      <div className="customers-page">
        <Tab
          className="main-tab"
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={onTabChange}
        />
      </div>
    </ErrorBoundary>
  );
}

CustomersPage.propTypes = propTypes;
CustomersPage.defaultProps = defaultProps;
