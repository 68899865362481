import React from 'react';

import { Dropdown } from 'semantic-ui-react';

import { useNavigate, useLocation } from 'react-router-dom';

import { PRODUCT_NAVIGATION_OPTIONS } from './SectionSelect.constants';
import { getInitialRoute } from './SectionSelect.utils';

import './SectionSelect.scss';

export default function SectionSelect() {
  const location = useLocation();
  const navigate = useNavigate();

  const defaultValue = getInitialRoute(location);

  function onProductSelected(e, { value }) {
    navigate(value);
  }

  return (
    <Dropdown
      className="product-selector"
      placeholder="Select Product"
      fluid
      selection
      options={PRODUCT_NAVIGATION_OPTIONS}
      defaultValue={defaultValue}
      onChange={onProductSelected}
    />
  );
}
