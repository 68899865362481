import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_SEGMENT_STYLING } from './constants';
import {
  EMPTY_BLOCK_ELEMENT_TYPE,
  SEGMENT_COMPONENT_NAME,
  SEGMENT_ELEMENT_TYPE,
} from '../../../../constants/types';
import {
  lookupElementType,
  lookupElementTypeByComponentName,
  translateChildren,
} from '../utils/children';
import { createSemanticComponent, getComponentId } from '../utils/components';
import { createComponentBaseProps, extractComponentBaseProps } from '../utils/props';

export function createSegmentElement(data = {}, context = {}) {
  const { id, className, style, elements } = data;
  const { recipeStateToSnippet } = context;

  const finalStyle = { ...DEFAULT_SEGMENT_STYLING, ...style };

  const children = translateChildren(elements, recipeStateToSnippet, lookupElementType, {
    context,
  });

  return createSemanticComponent(
    SEGMENT_COMPONENT_NAME,
    id,
    createComponentBaseProps(className, finalStyle),
    children,
  );
}

export function createSegmentState(recipeSnippet = {}, context = {}) {
  const { children = [] } = recipeSnippet || {};
  const { recipeSnippetToState } = context;

  const type = SEGMENT_ELEMENT_TYPE;
  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  let elements = translateChildren(
    children,
    recipeSnippetToState,
    lookupElementTypeByComponentName,
    { context },
  );

  if (_isEmpty(elements)) {
    elements = [recipeSnippetToState(EMPTY_BLOCK_ELEMENT_TYPE, {})];
  }

  return { type, id, className, style, elements };
}

export function createSegmentTemplateState(data = {}, context = {}) {
  const { recipeStateToTemplateState } = context;
  const { elements = [], ...restSegment } = data;

  const elementStates = translateChildren(elements, recipeStateToTemplateState, lookupElementType, {
    context,
  });

  return {
    ...restSegment,
    elements: elementStates,
  };
}
