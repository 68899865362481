/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';

import { Form, Select, Input } from 'semantic-ui-react';

import _ from 'lodash';
import _isFunction from 'lodash/isFunction';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { discreteFieldOptions, codeFieldOptions } from '../../constants/keyToSelectOptionsMap';
import {
  isMetadataOnlyNode,
  nodeInPath,
  getValueType,
  isMetadataKey,
  isReadonly,
} from '../../utils/recipe-utils';
import ObjectOptionsHeader from '../ObjectOptionsHeader';
import { RecipeContextConsumer } from '../context/RecipeContext';
import CodeBlock from '../fields/CodeBlock';

const SortableItem = SortableElement(({ value }) => <li>{value}</li>);

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((value, index) => (
        // TODO check ignoring the following rule is safe here
        // eslint-disable-next-line react/no-array-index-key
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

class SortableComponent extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { resortArray, ky, parentId } = this.props;
    resortArray(ky, parentId, oldIndex, newIndex);
  };

  render() {
    const {
      recipePart,
      parentId,
      pathProps: { path, selectedNodePath },
    } = this.props;

    return (
      <SortableList
        items={Object.keys(recipePart).map((ky, i) =>
          // eslint-disable-next-line no-use-before-define
          getComponentForRecipeObject(ky, parentId, recipePart, i, {
            selectedNodePath,
            path: [...path, ky],
          }),
        )}
        onSortEnd={this.onSortEnd}
        pressDelay={200}
      />
    );
  }
}

class RecipeArray extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  isOpen() {
    const { path, selectedNodePath } = this.props;
    const { isOpen } = this.state;

    return nodeInPath(selectedNodePath, path) || isOpen;
  }

  render() {
    const { ky, recipePart, parentId, path, selectedNodePath } = this.props;

    const arrayParentIdItem = recipePart.find(isMetadataOnlyNode);
    const arrayWithoutIds = recipePart.filter((r) => r !== arrayParentIdItem);

    return (
      <div className="recipearray">
        <ObjectOptionsHeader
          ky={ky}
          recipePart={arrayWithoutIds}
          parentId={parentId}
          type="array"
          isOpen={this.isOpen()}
          toggleOpen={this.toggleOpen}
        />
        {this.isOpen() && (
          <RecipeContextConsumer>
            {({ resortArray, nodeInInvalidPath }) => (
              <div
                className="recipeindentedgroup recipearray"
                style={nodeInInvalidPath(parentId, ky) ? { background: '#ff00001f' } : {}}
              >
                <SortableComponent
                  ky={ky}
                  // eslint-disable-next-line no-underscore-dangle
                  parentId={arrayParentIdItem.__id__}
                  recipePart={arrayWithoutIds}
                  resortArray={resortArray}
                  pathProps={{ path, selectedNodePath }}
                />
              </div>
            )}
          </RecipeContextConsumer>
        )}
      </div>
    );
  }
}

class RecipeBoolean extends React.Component {
  constructor(props) {
    super(props);
    this.renderSelect = this.renderSelect.bind(this);
  }

  renderSelect() {
    const { ky, recipePart, parentId } = this.props;
    return (
      <RecipeContextConsumer>
        {(valueContext) => {
          const { recipe: fullRecipe, updateValueInObject } = valueContext;
          return (
            <Form>
              <Select
                placeholder="Select true or false"
                value={recipePart === true ? 'true' : 'false'}
                options={[
                  { key: 'true', value: 'true', text: 'true' },
                  { key: 'false', value: 'false', text: 'false' },
                ]}
                onChange={(event, data) => updateValueInObject(ky, data.value === 'true', parentId)}
                disabled={isReadonly(fullRecipe, ky, parentId)}
              />
            </Form>
          );
        }}
      </RecipeContextConsumer>
    );
  }

  render() {
    const { ky } = this.props;

    return (
      <div className="recipeboolean">
        <span>{ky}</span>
        {this.renderSelect()}
      </div>
    );
  }
}

class RecipeObject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
  }

  toggleOpen() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  isOpen() {
    const { path, selectedNodePath } = this.props;
    const { isOpen } = this.state;

    return nodeInPath(selectedNodePath, path) || isOpen;
  }

  render() {
    const { ky, recipePart, parentId, path, selectedNodePath } = this.props;

    return (
      <div className="recipeobject">
        <ObjectOptionsHeader
          ky={ky}
          recipePart={recipePart}
          parentId={parentId}
          type="object"
          isOpen={this.isOpen()}
          toggleOpen={this.toggleOpen}
        />
        {this.isOpen() && (
          <div className="recipeindentedgroup">
            {Object.keys(recipePart).map((childKey, i) =>
              // eslint-disable-next-line no-use-before-define
              getComponentForRecipeObject(childKey, null, recipePart, i, {
                selectedNodePath,
                path: [...path, childKey],
              }),
            )}
          </div>
        )}
      </div>
    );
  }
}

class RecipeString extends React.Component {
  constructor(props) {
    super(props);

    this.getSelectionOptions = this.getSelectionOptions.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderSelect = this.renderSelect.bind(this);
  }

  getSelectionOptions({ recipe, getParentNode }) {
    const { parentId, ky } = this.props;
    const { __type__ } = getParentNode(parentId);

    const fieldConfig = _.find(
      discreteFieldOptions,
      ({ nodeType, field }) => nodeType === __type__ && field === ky,
    );

    if (!fieldConfig) {
      return [];
    }

    const { options, getOptions } = fieldConfig;
    const finalOptions = options || getOptions({ recipe });

    return finalOptions.map((option) => ({
      key: option,
      value: option,
      text: option,
    }));
  }

  getCodeEditorMode({ getParentNode }) {
    const { parentId, ky } = this.props;
    const { __type__ } = getParentNode(parentId);

    const fieldConfig = _.find(
      codeFieldOptions,
      ({ nodeType, field }) => nodeType === __type__ && field === ky,
    );

    if (!fieldConfig) {
      return null;
    }

    const { editorMode } = fieldConfig;
    return editorMode;
  }

  shouldRenderAsSelect(getParentNode) {
    const { parentId, ky } = this.props;
    const { __type__ } = getParentNode(parentId);

    return discreteFieldOptions.some(
      ({ nodeType, field }) => nodeType === __type__ && field === ky,
    );
  }

  shouldRenderAsCodeEditor(getParentNode) {
    const { parentId, ky } = this.props;
    const { __type__ } = getParentNode(parentId);

    return codeFieldOptions.some(({ nodeType, field }) => nodeType === __type__ && field === ky);
  }

  renderInput() {
    const { ky, recipePart, parentId } = this.props;
    return (
      <RecipeContextConsumer>
        {(value) => {
          const { recipe: fullRecipe, updateValueInObject, isValid } = value;
          return (
            <Input
              value={recipePart}
              onChange={(e) => {
                updateValueInObject(ky, e.target.value, parentId);
              }}
              disabled={isReadonly(fullRecipe, ky, parentId)}
              error={!isValid(parentId)}
            />
          );
        }}
      </RecipeContextConsumer>
    );
  }

  renderSelect() {
    const { ky, recipePart, parentId } = this.props;

    return (
      <RecipeContextConsumer>
        {(value) => {
          const { recipe: fullRecipe, updateValueInObject, getParentNode } = value;

          return (
            <Form>
              <Select
                options={this.getSelectionOptions({
                  recipe: fullRecipe,
                  getParentNode,
                })}
                placeholder="Select an option"
                value={recipePart}
                onChange={(event, data) => {
                  updateValueInObject(ky, data.value, parentId);
                }}
                disabled={isReadonly(fullRecipe, ky, parentId)}
              />
            </Form>
          );
        }}
      </RecipeContextConsumer>
    );
  }

  renderCodeEditor() {
    const { ky, recipePart, parentId } = this.props;
    return (
      <RecipeContextConsumer>
        {(value) => {
          const { recipe: fullRecipe, updateValueInObject, getParentNode } = value;
          return (
            <CodeBlock
              value={recipePart}
              mode={this.getCodeEditorMode({ getParentNode })}
              onChange={(newValue) => updateValueInObject(ky, newValue, parentId)}
              disabled={isReadonly(fullRecipe, ky, parentId)}
            />
          );
        }}
      </RecipeContextConsumer>
    );
  }

  render() {
    const { ky } = this.props;

    return (
      <RecipeContextConsumer>
        {(value) => {
          const { getParentNode } = value;
          const shouldRenderAsSelect = this.shouldRenderAsSelect(getParentNode);
          const shouldRenderAsCodeEditor = this.shouldRenderAsCodeEditor(getParentNode);

          return (
            <div className="recipestring">
              <span>{ky}</span>
              {!shouldRenderAsSelect && !shouldRenderAsCodeEditor && this.renderInput()}
              {shouldRenderAsSelect && this.renderSelect()}
              {shouldRenderAsCodeEditor && this.renderCodeEditor()}
            </div>
          );
        }}
      </RecipeContextConsumer>
    );
  }
}

const fieldTypesMap = {
  string: (props) => <RecipeString {...props} />,
  boolean: (props) => <RecipeBoolean {...props} />,
  object: (props) => <RecipeObject {...props} />,
  array: (props) => <RecipeArray {...props} />,
};

export default function getComponentForRecipeObject(
  ky,
  parentId,
  recipePart,
  i = null,
  additionalProps = {},
) {
  if (isMetadataKey(ky)) {
    return null;
  }

  const type = getValueType(recipePart[ky]);

  const typeToComponentFunction = fieldTypesMap[type];
  const pId = parentId || recipePart.__id__;

  const props = {
    key: i,
    ky,
    recipePart: recipePart[ky],
    parentId: pId,
    ...additionalProps,
  };

  if (_isFunction(typeToComponentFunction)) {
    return typeToComponentFunction(props);
  }

  return null;
}
