import React, { useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import EditPageModal from '../EditPageModal';

import './AddExternalPage.scss';

const propTypes = {};
const defaultProps = {};

export default function AddExternalPage() {
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      <Button className="add-external-page-trigger action-button-gray" icon onClick={openModal}>
        <Icon name="plus" /> ADD EXTERNAL LINK
      </Button>
      {showModal && <EditPageModal open={showModal} onClose={closeModal} external add />}
    </>
  );
}

AddExternalPage.propTypes = propTypes;
AddExternalPage.defaultProps = defaultProps;
