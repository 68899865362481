import React from 'react';

import PropTypes from 'prop-types';

const propTypes = {
  color: PropTypes.string.isRequired,
};

export default function Color({ color }) {
  return <div className="color" style={{ background: `#${color}` }} />;
}

Color.propTypes = propTypes;
