import React from 'react';

import { Popup } from 'semantic-ui-react';

import './CopyToClipboard.scss';

function withCopyToClipboard(WrappedComponent) {
  return class extends React.Component {
    constructor() {
      super();
      this.state = {
        isOpen: false,
      };
      this.timeout = null;
      this.handleClick = this.handleClick.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }

    handleOpen() {
      this.setState({ isOpen: true });

      this.timeout = setTimeout(() => {
        this.setState({ isOpen: false });
      }, 1000);
    }

    handleClose() {
      this.setState({ isOpen: false });
      clearTimeout(this.timeout);
    }

    handleClick(e) {
      const { onClick } = this.props;
      this.copyTextToClipboard();
      if (onClick) {
        onClick(e);
      }
    }

    copyTextToClipboard() {
      const { value } = this.props;
      navigator.clipboard.writeText(value);
    }

    render() {
      const { isOpen } = this.state;
      return (
        <Popup
          basic
          inverted
          style={{ opacity: 0.7 }}
          size="mini"
          trigger={
            <WrappedComponent
              className="clipboard-wrapped"
              {...this.props}
              onClick={this.handleClick}
            />
          }
          content="Copied to clipboard!"
          on="click"
          open={isOpen}
          onClose={this.handleClose}
          onOpen={this.handleOpen}
          position="bottom center"
        />
      );
    }
  };
}

export default withCopyToClipboard;
