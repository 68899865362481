import PropTypes from 'prop-types';

import { BaseBlockType, ComponentConfigurationBlockType } from './common.types';

const ShortTextFieldPropType = PropTypes.shape({
  maxLength: PropTypes.number,
});

const LongTextFieldPropType = PropTypes.shape({
  maxLength: PropTypes.number,
});

export const ScaleFieldPropType = PropTypes.shape({
  from: PropTypes.number,
  to: PropTypes.number,
  leftLabel: PropTypes.string,
  middleLabel: PropTypes.string,
  rightLabel: PropTypes.string,
});

const MultipleChoiceFieldPropType = PropTypes.shape({
  choices: PropTypes.arrayOf(PropTypes.string),
});

const SingleChoiceFieldPropType = PropTypes.shape({
  choices: PropTypes.arrayOf(PropTypes.string),
});

const FileUploadFieldPropType = PropTypes.shape({});

export const FormFieldPropType = PropTypes.shape({
  id: PropTypes.string,

  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  isFieldContactMapped: PropTypes.bool,

  settings: PropTypes.oneOfType([
    MultipleChoiceFieldPropType,
    SingleChoiceFieldPropType,
    ScaleFieldPropType,
    ShortTextFieldPropType,
    LongTextFieldPropType,
    FileUploadFieldPropType,
  ]),
});

export const LegacyFormBlockPropType = PropTypes.shape({
  ...BaseBlockType,
  businessId: PropTypes.number,
  businessType: PropTypes.string,
  locationId: PropTypes.number,
  title: PropTypes.string,
  fields: PropTypes.arrayOf(FormFieldPropType),
});

export const FormBlockContactMappingPropType = PropTypes.shape({
  email: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
});

export const FormBlockPropType = PropTypes.shape({
  ...BaseBlockType,
  ...ComponentConfigurationBlockType,
  businessId: PropTypes.number,
  businessInfoId: PropTypes.number,
  businessType: PropTypes.string,
  websiteId: PropTypes.number,
  locationId: PropTypes.number,
  header: PropTypes.string,
  channel: PropTypes.string,
  fields: PropTypes.arrayOf(FormFieldPropType),
  emailSubject: PropTypes.string,
  consentSettings: PropTypes.shape({
    show: PropTypes.bool,
    required: PropTypes.bool,
  }),
  contactMapping: FormBlockContactMappingPropType,
  destinationEmail: PropTypes.arrayOf(PropTypes.string),
  additionalProps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      resolve: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  ),
});
