export const BEHAVIOR_INTERNAL_LINK = 'INT_LINK';
export const BEHAVIOR_EXTERNAL_LINK = 'EXT_LINK';
export const BEHAVIOR_INTERNAL_CLICKTHRU = 'INT_THRU';
export const BEHAVIOR_EXTERNAL_CLICKTHRU = 'EXT_THRU';
export const BEHAVIOR_CLOSE_WINDOW = 'CLOSE';
export const BEHAVIOR_LABEL = {
  [BEHAVIOR_INTERNAL_LINK]: 'Internal Link',
  [BEHAVIOR_EXTERNAL_LINK]: 'External Link',
  [BEHAVIOR_INTERNAL_CLICKTHRU]: 'Internal Link Clickthrough',
  [BEHAVIOR_EXTERNAL_CLICKTHRU]: 'External Link Clickthrough',
  [BEHAVIOR_CLOSE_WINDOW]: 'Close Window',
};

export const PAGE_TYPE_EXTERNAL = 'External';

export const BEHAVIOR_CLICKTHRU_TYPES = [BEHAVIOR_INTERNAL_CLICKTHRU, BEHAVIOR_EXTERNAL_CLICKTHRU];
export const BEHAVIOR_CTA_TYPES = [BEHAVIOR_INTERNAL_LINK, BEHAVIOR_EXTERNAL_LINK];

export const DISPLAY_TYPE_BUTTON = 'BUTTON';
export const DISPLAY_TYPE_ICON = 'ICON';

export const INTERACTION_STYLE_PRIMARY = 'PRIMARY';
export const INTERACTION_STYLE_SECONDARY = 'SECONDARY';
export const INTERACTION_STYLE_TEXT = 'TEXT';

export const INTERACTION_STYLE_LABEL = {
  [INTERACTION_STYLE_PRIMARY]: 'Primary',
  [INTERACTION_STYLE_SECONDARY]: 'Secondary',
  [INTERACTION_STYLE_TEXT]: 'Text',
};

export const DEFAULT_INTERACTION = {
  display_type: DISPLAY_TYPE_BUTTON,
  behavior_type: BEHAVIOR_INTERNAL_LINK,
  label: '',
  enabled: false,
  style: INTERACTION_STYLE_PRIMARY,
};
