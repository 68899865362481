import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { List, Segment } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { NO_CUSTOM_PAGES_COPY } from './CustomPages.constants';
import useCustomPages from '../../hooks/use-custom-pages';
import { CustomPagePropType } from '../../types/custom-page.type';
import CustomPagesItem from '../CustomPagesItem';
import NewCustomPageButton from '../NewCustomPageButton';
import NewCustomPageModal from '../NewCustomPageModal';

import './CustomPagesList.scss';

const propTypes = {
  customPages: PropTypes.arrayOf(CustomPagePropType),
};
const defaultProps = {
  customPages: null,
};

export default function CustomPagesList({ customPages: customPagesProp }) {
  const { customPages } = useCustomPages();
  const pages = customPagesProp || customPages;
  const [isCustomPageModalOpened, setIsCustomPageModalOpened] = useState(false);

  return (
    <div className="custom-pages-list">
      <div className="header-container">
        <NewCustomPageModal
          open={isCustomPageModalOpened}
          onClose={() => setIsCustomPageModalOpened(false)}
          trigger={<NewCustomPageButton onClick={() => setIsCustomPageModalOpened(true)} />}
        />
      </div>
      {_isEmpty(pages) && (
        <Segment className="no-content borderless-segment">
          <p>{NO_CUSTOM_PAGES_COPY}</p>
        </Segment>
      )}
      {!_isEmpty(pages) && (
        <List>
          {pages.map((customPage) => {
            const { id } = customPage;
            return <CustomPagesItem key={id} customPage={customPage} />;
          })}
        </List>
      )}
    </div>
  );
}

CustomPagesList.propTypes = propTypes;
CustomPagesList.defaultProps = defaultProps;
