import PropTypes from 'prop-types';

export const StyleRulePropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  override: PropTypes.bool.isRequired,
  elementType: PropTypes.string.isRequired,
});

export const StyleRulesPropType = PropTypes.arrayOf(StyleRulePropType);
