import React, { Component } from 'react';

import { Button } from 'semantic-ui-react';

import NodeSearch from './NodeSearch';
import NodeTypeFilter from './NodeTypeFilter';

export default class NodeControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTypeFilters: [],
    };

    this.onFilterValueChange = this.onFilterValueChange.bind(this);
  }

  onFilterValueChange(e, { value }) {
    this.setState({ selectedTypeFilters: value });
  }

  render() {
    const { recipe, setSelectedNodePath, selectedNodePath } = this.props;
    const { selectedTypeFilters } = this.state;

    return (
      <div className="node-controls">
        {selectedNodePath.length > 0 && (
          <Button size="small" onClick={() => setSelectedNodePath(null, { result: { value: '' } })}>
            Clear Selection
          </Button>
        )}
        <NodeTypeFilter
          onFilterValueChange={this.onFilterValueChange}
          selectedTypes={selectedTypeFilters}
        />
        <NodeSearch
          recipe={recipe}
          selectedTypeFilters={selectedTypeFilters}
          onResultSelect={setSelectedNodePath}
        />
      </div>
    );
  }
}
