export const SHIPPO_PROVIDER = 'Shippo';
export const SHIPPING_SERVICE_TYPE = 'Shipping';
export const SERVICE_SETTINGS_COMPLETE = 'Complete';

export const FULFILLMENT_TYPE_INSTORE = 'INSTORE';
export const FULFILLMENT_TYPE_CURBSIDE = 'CURBSIDE';
export const FULFILLMENT_TYPE_SHIPPING = 'SHIPPING';
export const FULFILLMENT_TYPE_DELIVERY = 'DELIVERY';

export const FULFILLMENT_TYPE_TO_LABEL = {
  [FULFILLMENT_TYPE_INSTORE]: 'Pickup',
  [FULFILLMENT_TYPE_CURBSIDE]: 'Curbside',
  [FULFILLMENT_TYPE_SHIPPING]: 'Shipping',
  [FULFILLMENT_TYPE_DELIVERY]: 'Delivery',
};

export const FULFILLMENT_PROVIDER_SHIPPO = 'SHIPPO';
export const FULFILLMENT_PROVIDER_MERCHANT = 'MERCHANT';
export const FULFILLMENT_PROVIDER_DOORDASH = 'DOORDASH';

export const FULFILLMENT_PROVIDER_TO_LABEL = {
  [FULFILLMENT_PROVIDER_SHIPPO]: 'Shippo',
  [FULFILLMENT_PROVIDER_MERCHANT]: 'Merchant',
  [FULFILLMENT_PROVIDER_DOORDASH]: 'Doordash',
};

export const FULFILLMENT_STATUS_RECEIVED = 'RECEIVED';
export const FULFILLMENT_STATUS_PREPARING = 'PREPARING';
export const FULFILLMENT_STATUS_READY = 'READY';
export const FULFILLMENT_STATUS_IN_TRANSIT = 'IN_TRANSIT';
export const FULFILLMENT_STATUS_COMPLETE = 'COMPLETE';
export const FULFILLMENT_STATUS_CANCELED = 'CANCELED';
export const FULFILLMENT_STATUS_BLOCKED = 'BLOCKED';

export const FULFILLMENT_STATUS_TO_LABEL = {
  [FULFILLMENT_STATUS_RECEIVED]: 'Received',
  [FULFILLMENT_STATUS_PREPARING]: 'Preparing',
  [FULFILLMENT_STATUS_READY]: 'Ready',
  [FULFILLMENT_STATUS_IN_TRANSIT]: 'In Transit',
  [FULFILLMENT_STATUS_COMPLETE]: 'Complete',
  [FULFILLMENT_STATUS_CANCELED]: 'Canceled',
  [FULFILLMENT_STATUS_BLOCKED]: 'Blocked',
};
