import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { getIsSorted } from './CustomerContactsTableHeader.utils';

const propTypes = {
  handleSort: PropTypes.func.isRequired,
  sortedColumn: PropTypes.string.isRequired,
  directionSort: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function CustomerContactsTableHeader({ handleSort, sortedColumn, directionSort }) {
  function onEmailClick() {
    handleSort('email');
  }

  const isSorted = getIsSorted(sortedColumn, directionSort);

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={2}>First Name</Table.HeaderCell>
        <Table.HeaderCell width={2}>Last Name</Table.HeaderCell>
        <Table.HeaderCell width={3} sorted={isSorted} onClick={onEmailClick}>
          Email
        </Table.HeaderCell>
        <Table.HeaderCell width={3}>Phone</Table.HeaderCell>
        <Table.HeaderCell width={4}>Tags</Table.HeaderCell>
        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}

CustomerContactsTableHeader.propTypes = propTypes;
CustomerContactsTableHeader.defaultProps = defaultProps;
