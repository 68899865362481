import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { GRID_KEY } from './EditCustomPageModal.constants';
import { DEFAULT_GRID_CLASS_NAME } from '../../hooks/use-recipe-node-translator/generators/grid/constants';

export function getNewPage(page, customPageData) {
  const { id: pageId } = page;
  const { title } = customPageData;

  return { id: pageId, title };
}

export function getNewPageAssociation(pageAssociationId, customPageData) {
  const { status } = customPageData;

  return { id: pageAssociationId, status };
}

export function getNewRecipeNode(recipeNode, customPageData) {
  const { title, style } = customPageData;
  return { ...recipeNode, name: title, style };
}

export function getPageClassName(recipeNode) {
  if (_isEmpty(recipeNode)) {
    return '';
  }

  const gridComponent = recipeNode.components.find(
    (component) => _get(component, 'name') === GRID_KEY,
  );
  const classNameProp = gridComponent.props.find((prop) => _get(prop, 'name') === 'className');
  return classNameProp.resolve
    .split(' ')
    .filter((className) => className !== DEFAULT_GRID_CLASS_NAME)
    .join(' ');
}
