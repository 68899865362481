import React, { useEffect, useState } from 'react';

import { Button, Icon, Message, Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

import { NO_TAXES_MESSAGE_HEADER, NO_TAXES_MESSAGE_MESSAGE } from './TaxesForm.constants';
import { EMPTY_CATALOG_TAX } from '../../../constants';
import {
  getCatalogObjectId,
  updateCatalogObject,
  findCatalogObjectIndex,
  updateCatalogObjectField,
} from '../../../utils';
import TaxForm from '../TaxForm';

import './TaxesForm.scss';

function TaxesForm({ initialTaxes, onSubmit }) {
  const [taxes, setTaxes] = useState(initialTaxes);
  const [taxesToRemove, setTaxesToRemove] = useState([]);
  const [isTouched, setIsTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    setTaxes(initialTaxes);
    setTaxesToRemove([]);
    setIsTouched(false);
  }, [initialTaxes]);

  function handleNewTax() {
    setTaxes((prevTaxes) => [
      ...prevTaxes,
      updateCatalogObject(EMPTY_CATALOG_TAX, {
        localId: uuidv4(),
        tax_data: { ordinal: prevTaxes.length },
      }),
    ]);
  }

  function deleteTax(tax) {
    setTaxes((prevTaxes) => {
      if (tax.object_id) {
        setTaxesToRemove((prevTaxesToRemove) => [...prevTaxesToRemove, tax]);
        setIsTouched(true);
      }
      const taxIndex = findCatalogObjectIndex(prevTaxes, tax);
      if (taxIndex < 0) {
        return prevTaxes;
      }
      return [...prevTaxes.slice(0, taxIndex), ...prevTaxes.slice(taxIndex + 1)];
    });
    setSubmitError(false);
  }

  function onChangeTax(tax, field, value) {
    setTaxes((prevTaxes) => {
      const taxIndex = findCatalogObjectIndex(prevTaxes, tax);
      if (taxIndex < 0) {
        return prevTaxes;
      }
      const changedTax = updateCatalogObjectField(tax, 'tax_data', field, value);
      changedTax.touched = true;
      setIsTouched(true);
      return [...prevTaxes.slice(0, taxIndex), changedTax, ...prevTaxes.slice(taxIndex + 1)];
    });

    setSubmitError(false);
  }

  async function handleSubmit() {
    setSubmitError(false);
    setIsSubmitting(true);
    const taxesToSave = taxes.filter((tax) => tax.touched);
    try {
      await onSubmit({ taxesToSave, taxesToRemove });
    } catch (e) {
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <Button className="new-tax-button" onClick={handleNewTax}>
        Create New Tax
      </Button>
      {_isEmpty(taxes) && (
        <Message header={NO_TAXES_MESSAGE_HEADER} content={NO_TAXES_MESSAGE_MESSAGE} />
      )}
      {!_isEmpty(taxes) && (
        <>
          <Table className="taxes-form" basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6}>Tax Name</Table.HeaderCell>
                <Table.HeaderCell width={6}>Percentage</Table.HeaderCell>
                <Table.HeaderCell width={4} />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {taxes.map((tax) => (
                <TaxForm
                  key={getCatalogObjectId(tax)}
                  tax={tax}
                  onRemove={deleteTax}
                  onChange={onChangeTax}
                />
              ))}
            </Table.Body>
          </Table>
          {submitError && (
            <Message negative>
              There was an error when trying to save data. Please, check your form data and try
              again.
            </Message>
          )}
          <Button
            disabled={!isTouched}
            loading={isSubmitting}
            className="save-taxes-button"
            onClick={handleSubmit}
          >
            <Icon name="save outline" />
            Save
          </Button>
        </>
      )}
    </>
  );
}

export default TaxesForm;
