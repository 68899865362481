import { useMemo, useEffect } from 'react';

import _debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';

export default function useDebounceSelector(selector, { disabled = false, delay = 1000 } = {}) {
  const debouncedSelector = useMemo(
    () => _debounce(selector, delay, { leading: true }),
    [selector, delay],
  );
  const result = useSelector(disabled ? selector : debouncedSelector);

  useEffect(() => {
    return () => {
      debouncedSelector.cancel();
    };
  }, [debouncedSelector, selector]);

  return result;
}
