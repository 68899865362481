import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_SIZE } from './Column.constants';
import { getFontFamily } from '../../../../services/font';
import FontVariantText from '../../../FontVariantText';

import './Column.scss';

const propTypes = {
  text: PropTypes.string.isRequired,
  font: PropTypes.string,
  displayFont: PropTypes.string,
  extra: PropTypes.node,
};
const defaultProps = {
  font: '',
  displayFont: '',
  extra: null,
};

export default function Column({ text, font, extra, displayFont: displayPropFont }) {
  const displayFont = displayPropFont || font.replace(/['"]+/g, '');

  return (
    <Grid.Column>
      {_isEmpty(font) ? (
        <>
          <div className="no-font">No custom font selected for the {text.toLowerCase()}</div>
          {!_isEmpty(extra) && extra}
        </>
      ) : (
        <>
          <FontVariantText
            text={text}
            family={getFontFamily(font)}
            size={DEFAULT_SIZE}
            lineHeight={DEFAULT_SIZE}
          />
          <div className="font-family">{displayFont}</div>
          {!_isEmpty(extra) && extra}
        </>
      )}
    </Grid.Column>
  );
}

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;
