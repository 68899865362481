import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import API from '../../../../../../libs/api';

export function createTilePayload(tile = {}, { parentComponentConfigurationId } = {}) {
  const {
    componentConfiguration = {},
    interactions = [],
    images = [],
    contentNodes = [],
  } = tile || {};

  const { componentIdentifier, order, data } = componentConfiguration;

  const filesPayload = images.map(({ businessFileId, ...rest }) => ({
    id: businessFileId,
    ...rest,
  }));
  const interactionsPayload = interactions;
  const contentNodesPayload = contentNodes.map(({ contentNodeId, ...rest }) => ({
    id: contentNodeId,
    ...rest,
  }));

  return {
    tilePayload: {
      componentIdentifier,
      order,
      parentComponentConfigurationId,
      data,
    },
    tileContentPayload: {
      files: filesPayload,
      interactions: interactionsPayload,
      contentNodes: contentNodesPayload,
    },
  };
}

export async function createDynamicTilesIfNotExists(customHero = {}) {
  const { componentConfiguration = {}, page = {} } = customHero || {};
  const { id: pageId } = page;
  const { componentConfigurationId, componentName, componentIdentifier, order } =
    componentConfiguration;
  if (_isNil(componentConfigurationId)) {
    const layoutId = null;
    const { data: createdComponentConfiguration } = await API.createComponentConfigurationWithData(
      pageId,
      layoutId,
      componentName,
      {
        componentIdentifier,
        order,
      },
    );

    return _get(createdComponentConfiguration, 'id');
  }

  return componentConfigurationId;
}

export async function createOrUpdateDynamicTilesTile(
  pageId,
  parentComponentConfigurationId,
  tiles = [],
) {
  await Promise.all(
    tiles.map((tile) => {
      const { componentConfiguration: tileComponentConfiguration } = tile;
      const {
        componentConfigurationId: tileComponentConfigurationId,
        componentName: tileComponentName,
      } = tileComponentConfiguration;

      const { tilePayload, tileContentPayload } = createTilePayload(tile, {
        parentComponentConfigurationId,
      });

      if (_isNil(tileComponentConfigurationId)) {
        const layoutId = null;
        return API.createComponentConfigurationWithData(
          pageId,
          layoutId,
          tileComponentName,
          tilePayload,
          tileContentPayload,
        );
      }

      return API.updateComponentConfigurationWithData(
        tileComponentConfigurationId,
        tilePayload,
        tileContentPayload,
      );
    }),
  );
}

export async function deleteCustomHero(
  dynamicTilesComponentConfigurationId,
  dynamicTilesTileComponentConfigurationIds = [],
) {
  if (dynamicTilesComponentConfigurationId) {
    await API.deleteComponentConfiguration(dynamicTilesComponentConfigurationId);
  }
  // remove DynamicTiles.Tile if the number decreased
  await Promise.all(
    dynamicTilesTileComponentConfigurationIds.map((tileId) =>
      API.deleteComponentConfiguration(tileId),
    ),
  );
}
