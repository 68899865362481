import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import RecipeManagerTableBody from './RecipeManagerTableBody';
import RecipeManagerTableFooter from './RecipeManagerTableFooter';
import RecipeManagerTableHeader from './RecipeManagerTableHeader';
import RecipeManagerTableItem from './RecipeManagerTableItem';

const RecipeManagerTable = ({ children }) => {
  return (
    <>
      <Table padded striped>
        {children}
      </Table>
    </>
  );
};

RecipeManagerTable.propTypes = {
  children: PropTypes.node,
};

RecipeManagerTable.defaultProps = {
  children: undefined,
};

RecipeManagerTable.Header = RecipeManagerTableHeader;
RecipeManagerTable.Body = RecipeManagerTableBody;
RecipeManagerTable.Item = RecipeManagerTableItem;
RecipeManagerTable.Footer = RecipeManagerTableFooter;

export default RecipeManagerTable;
