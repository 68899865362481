import CustomerReports from '../CustomerReports';
import OrderReports from '../OrderReports';

export const menuItems = [
  {
    name: 'ORDERS',
    component: OrderReports,
  },

  {
    name: 'CUSTOMERS',
    component: CustomerReports,
  },
];
