import React from 'react';

import PropTypes from 'prop-types';

import _kebabCase from 'lodash/kebabCase';

import Toolbar from './Toolbar';

const propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]).isRequired,
  youtubeId: PropTypes.string,
  name: PropTypes.string.isRequired,
};

const defaultProps = {
  youtubeId: null,
};

export default function MainContentContainer({
  path,
  component,
  hasToolbar = true,
  youtubeId,
  name,
}) {
  const shouldUseNoPadding = path.split('/').pop() === 'recipes-v2';
  const Component = component;

  return (
    <div className={`main-route-content content-${_kebabCase(name)}`}>
      {hasToolbar && (
        <Toolbar showFeatureToggle={path.includes('edit')} name={name} youtubeId={youtubeId} />
      )}
      <div className={`main-route-page ${shouldUseNoPadding ? 'main-route-page-no-padding' : ''}`}>
        <div className="content-editing-section">
          <Component />
        </div>
      </div>
    </div>
  );
}

MainContentContainer.propTypes = propTypes;
MainContentContainer.defaultProps = defaultProps;
