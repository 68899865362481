import _get from 'lodash/get';
import { v4 as uuid } from 'uuid';

import StateUtils from '../libs/state';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'REPLACE_LOCATION_HOURS': {
      return StateUtils.updateListItem(
        state,
        ['locations', 'value'],
        payload.index,
        {
          ...payload.location,
          hours: payload.hours,
        },
        payload.bypassTouch,
      );
    }

    case 'UPDATE_LOCATION_HOURS': {
      const location = _get(state, `locations.value[${payload.index}]`);
      const locationHours = _get(location, 'hours');
      locationHours[payload.hoursIndex] = {
        ...locationHours[payload.hoursIndex],
        [payload.field]: payload.fieldValue,
      };

      return StateUtils.updateListItem(state, ['locations', 'value'], payload.index, {
        ...location,
        hours: locationHours,
      });
    }

    case 'UPDATE_LOCATION_HOUR_BY_ID': {
      const location = _get(state, `locations.value[${payload.index}]`);
      const locationHours = _get(location, 'hours', []);
      let locationLabels = _get(location, 'hour_labels', []);
      const hoursIndex = locationHours.findIndex(
        (hour) => hour.id === payload.hourId || hour.localId === payload.hourId,
      );
      locationHours[hoursIndex] = {
        ...locationHours[hoursIndex],
        [payload.field]: payload.fieldValue,
      };

      if (payload.field === 'label') {
        locationLabels = Array.from(new Set(locationLabels.concat([payload.fieldValue])));
      }

      return StateUtils.updateListItem(
        state,
        ['locations', 'value'],
        payload.index,
        {
          ...location,
          hours: locationHours,
          hour_labels: locationLabels,
        },
        payload.bypassTouch,
      );
    }

    case 'REMOVE_LOCATION_HOUR_BY_ID': {
      const location = _get(state, `locations.value[${payload.index}]`);
      const locationHours = _get(location, 'hours', []);
      const hourToDeleteIndex = locationHours.findIndex(
        (hour) => hour.id === payload.hourId || hour.localId === payload.hourId,
      );
      const newHours = [
        ...locationHours.slice(0, hourToDeleteIndex),
        ...locationHours.slice(hourToDeleteIndex + 1),
      ];

      return StateUtils.updateListItem(
        state,
        ['locations', 'value'],
        payload.index,
        {
          ...location,
          hours: newHours,
        },
        payload.bypassTouch,
      );
    }

    case 'COPY_LOCATION_HOURS': {
      const location = _get(state, `locations.value[${payload.locationIndex}]`);
      const locationHours = _get(location, 'hours', []);

      // Filter hours of sourceDay and remove id, localId and day prop
      const sourceHours = locationHours
        .filter((hour) => hour.day === payload.sourceDay)
        .map(({ id, localId, day, ...hourProps }) => ({ ...hourProps }));

      // Copy source hours to targetDays hours and set unique localId
      const targetHours = payload.targetDays
        .map((targetDay) =>
          sourceHours.map((hour) => ({
            ...hour,
            localId: uuid(),
            day: targetDay,
          })),
        )
        .reduce((acc, list) => acc.concat(list), []);

      // Filter untouched hours
      const untouchedHours = locationHours.filter((hour) => !payload.targetDays.includes(hour.day));

      return StateUtils.updateListItem(
        state,
        ['locations', 'value'],
        payload.locationIndex,
        {
          ...location,
          hours: untouchedHours.concat(targetHours),
        },
        payload.bypassTouch,
      );
    }

    case 'ADD_LOCATION_HOURS': {
      const locationIndex = payload.index;
      const location = _get(state, `locations.value[${payload.index}]`);
      const locationHours = _get(location, 'hours', []);
      const newLocationHoursAdd = locationHours.concat([
        StateUtils.createDefaultType('HOURS_OF_OPERATION', {
          day: payload.day,
          localId: uuid(),
          hour_group: payload.hour_group,
          location: location.id,
        }),
      ]);

      return StateUtils.updateListItem(
        state,
        ['locations', 'value'],
        locationIndex,
        {
          ...location,
          hours: newLocationHoursAdd,
        },
        payload.bypassTouch,
      );
    }

    default:
      return state;
  }
};
