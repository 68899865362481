import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import useAsyncEffect from 'components/modules/foundation/hooks/use-async-effect';
import useFetch from 'components/modules/foundation/hooks/use-fetch';
import API from 'libs/api';
import { selectPrimaryDomain } from 'selectors/website';

import {
  setAllRedirects,
  addOneRedirect,
  updateOneRedirect,
  removeOneRedirect,
  selectAllRedirects,
} from '../../slices/Redirect.slice';

export default function useRedirect({ initialFetch = false } = {}) {
  const primaryDomain = useSelector(selectPrimaryDomain);
  const primaryDomainId = _get(primaryDomain, 'id');

  const redirects = useSelector(selectAllRedirects);

  const dispatch = useDispatch();

  const {
    fetch,
    fetching: loading,
    errorMessage,
  } = useFetch({ initialFetching: initialFetch, throwError: true });

  async function fetchRedirects() {
    await fetch(
      async () => {
        const data = await API.getRedirects({ domain: primaryDomainId });
        dispatch(setAllRedirects(data));
      },
      { throwError: false },
    );
  }

  async function createRedirect(payload = {}) {
    await fetch(async () => {
      const newRedirect = await API.createRedirect(primaryDomainId, payload);
      dispatch(addOneRedirect(newRedirect));
    });
  }

  async function updateRedirect(redirectId, payload = {}) {
    await fetch(async () => {
      const updatedRedirect = await API.updateRedirect(redirectId, payload);
      dispatch(updateOneRedirect({ id: redirectId, changes: updatedRedirect }));
    });
  }

  async function deleteRedirect(redirectId) {
    await fetch(async () => {
      await API.deleteRedirect(redirectId);
      dispatch(removeOneRedirect(redirectId));
    });
  }

  useAsyncEffect(async () => {
    if (initialFetch) {
      await fetchRedirects();
    }
  }, []);

  return {
    primaryDomain,
    redirects,
    loading,
    errorMessage,
    fetchRedirects,
    createRedirect,
    updateRedirect,
    deleteRedirect,
  };
}
