import React, { useState } from 'react';

import PropTypes from 'prop-types';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { MARKDOWN_OPTIONS } from './CatalogObjectDescriptionInput.constants';
import {
  getItemOrServiceDescriptionPrompt,
  createPriceString,
} from './CatalogObjectDescriptionInput.utils';
import { selectBusinessId, selectBusinessType } from '../../../selectors';
import { selectBusinessCategory } from '../../../selectors/business';
import { CatalogItemVariationPropType } from '../../modules/catalog/proptypes/catalog';
import ActionsInput from '../../modules/content/components/ActionsInput';
import WritingAssistantButton from '../../modules/content/components/WritingAssistantButton/WritingAssistantButton';
import { getWritingAssistantActions } from '../../modules/content/services/prompts';
import MarkdownField from '../MarkdownField';

import './CatalogObjectDescriptionInput.scss';

const propTypes = {
  field: PropTypes.shape({
    width: PropTypes.number,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
  }),
  catalogObject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    parentName: PropTypes.string,
    description: PropTypes.string,
    prices: PropTypes.arrayOf(CatalogItemVariationPropType),
  }),
  prompt: PropTypes.shape({
    minOutputLength: PropTypes.number,
    maxOutputLength: PropTypes.number,
    additionalRequirements: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  field: {
    name: '',
    width: undefined,
    placeholder: '',
  },
  catalogObject: {
    parentName: '',
    description: '',
    prices: [],
  },
  prompt: {
    minOutputLength: undefined,
    maxOutputLength: undefined,
    additionalRequirements: '',
  },
};

export default function CatalogObjectDescriptionInput({
  field: { width: fieldWidth, name: fieldName, placeholder },
  catalogObject: {
    name: catalogObjectName,
    type: categoryObjectType,
    parentName: catalogObjectParentName,
    description: catalogObjectDescription,
    prices: catalogObjectPrices,
  },
  prompt: { minOutputLength, maxOutputLength, additionalRequirements },
  onChange,
}) {
  const businessType = useSelector(selectBusinessType);
  const businessId = useSelector(selectBusinessId);
  const businessCategory = useSelector(selectBusinessCategory);

  const [editorState, setEditorState] = useState(catalogObjectDescription);

  async function onMakeSuggestion() {
    const promptParams = {
      catalog_object_name: catalogObjectName,
      catalog_object_type: categoryObjectType,
      catalog_object_price: createPriceString(catalogObjectPrices),
      catalog_object_parent_descriptor: catalogObjectParentName,
      business_category: businessCategory,
      min_length: minOutputLength,
      max_length: maxOutputLength,
      additional_requirements: additionalRequirements,
    };

    const { text_completion: generatedDescription } = await getItemOrServiceDescriptionPrompt(
      businessType,
      businessId,
      promptParams,
    );

    return generatedDescription;
  }

  function onDescriptionChange(event, target) {
    const value = _get(target, 'value', target || event);
    const payload = {
      name: fieldName,
      value,
      type: 'text',
    };
    const newTarget = { ...target, ...payload };

    setEditorState(value);
    onChange({ ...event, target: newTarget }, newTarget);
  }

  return (
    <ActionsInput className="catalog-object-description-input">
      <ActionsInput.Input
        input={MarkdownField}
        value={editorState}
        options={{ ...MARKDOWN_OPTIONS, placeholder }}
        onChange={onDescriptionChange}
        width={fieldWidth}
      />
      <ActionsInput.Actions
        trigger={WritingAssistantButton}
        actionSections={getWritingAssistantActions({ onMakeSuggestion })}
        adminOnly
      />
    </ActionsInput>
  );
}

CatalogObjectDescriptionInput.propTypes = propTypes;
CatalogObjectDescriptionInput.defaultProps = defaultProps;
