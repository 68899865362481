import _isFunction from 'lodash/isFunction';
import _matches from 'lodash/matches';

export function getPageSettings(page = {}, settings = []) {
  const { pageType } = page;
  const pageComponentConfigurations = settings.filter(({ pageType: settingPageType }) => {
    return settingPageType === pageType;
  });

  return pageComponentConfigurations.sort(
    ({ displayOrder: firstDisplayOrder }, { displayOrder: secondDisplayOrder }) => {
      return firstDisplayOrder - secondDisplayOrder;
    },
  );
}

export function filterComponentConfigurations(componentConfigurations = [], conditions = []) {
  return componentConfigurations.filter((componentConfiguration) => {
    return conditions.every(({ paramsFilter = [], shouldApplyFilters = true, negate = false }) => {
      if (!shouldApplyFilters) {
        return true;
      }
      const someParamFilterMatches = paramsFilter.some((paramFilter) =>
        _isFunction(paramFilter)
          ? paramFilter(componentConfiguration)
          : _matches(paramFilter)(componentConfiguration),
      );
      return negate ? !someParamFilterMatches : someParamFilterMatches;
    });
  });
}
