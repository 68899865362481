import React from 'react';

import PropTypes from 'prop-types';

import { ObjectPropType } from 'libs/proptypes';

import { StockImagesPickerProvider } from './StockImagesPicker.context';
import StockSearchField from './components/StockSearchField';
import { StockSearchResults } from './components/StockSearchResults';
import StockSearchSuggestions from './components/StockSearchSuggestions';
import { useStockFiles } from '../../hooks';

import './StockImagesPicker.scss';

const propTypes = {
  onSave: PropTypes.func,
  onPostSave: PropTypes.func,
  saveButtonProps: ObjectPropType,
};

const defaultProps = {
  onSave: null,
  onPostSave: null,
  saveButtonProps: {},
};

export default function StockImagesPicker({ onSave, onPostSave, saveButtonProps }) {
  const {
    searchValue,
    searched,
    stockImages,
    loading,
    error,
    onSearch,
    onShowMore,
    setSearchValue,
    createBusinessFiles,
  } = useStockFiles();

  const contextValue = {
    searchValue,
    searched,
    stockImages,
    loading,
    error,
    onSearch,
    onShowMore,
    setSearchValue,
    createBusinessFiles,
  };
  return (
    <StockImagesPickerProvider value={contextValue}>
      <div className="stock-images-picker">
        <StockSearchField />
        <StockSearchSuggestions />
        <StockSearchResults
          onSave={onSave}
          onPostSave={onPostSave}
          saveButtonProps={saveButtonProps}
        />
      </div>
    </StockImagesPickerProvider>
  );
}

StockImagesPicker.propTypes = propTypes;
StockImagesPicker.defaultProps = defaultProps;
