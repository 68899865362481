import React, { useContext } from 'react';

import { Button, List } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { DATE_UTC_FORMAT } from '../../../../../libs/date-utils/constants';
import DateUtils from '../../../../../libs/date-utils/date-utils';
import { CustomPagesContext } from '../../contexts/CustomPages.context';
import { CustomPagePropType } from '../../types/custom-page.type';
import { getStatusClassName } from '../ItemStatusDropdown/ItemStatusDropdown.utils';

import './CustomPagesItem.scss';

const propTypes = {
  customPage: CustomPagePropType.isRequired,
};

const defaultProps = {};

export default function CustomPagesItem({ customPage }) {
  const { setActivePageId } = useContext(CustomPagesContext);

  const { page, status, lastUpdated } = customPage;
  const { title } = page;
  const statusClass = getStatusClassName(status, '');

  function onEditClick() {
    setActivePageId(customPage.id);
  }

  const displayDate = DateUtils.format(lastUpdated, 'MMM D, YYYY', DATE_UTC_FORMAT);

  return (
    <List.Item className="custom-pages-item" onClick={onEditClick}>
      <div className="main">
        <List.Content className="title">
          <List.Header>{title}</List.Header>
          {!_isEmpty(displayDate) && (
            <List.Description>Last updated: {displayDate}</List.Description>
          )}
        </List.Content>
        <List.Content className="status">
          <span className={statusClass}>{status}</span>
        </List.Content>
        <List.Content className="button">
          <Button icon="angle right" />
        </List.Content>
      </div>
    </List.Item>
  );
}

CustomPagesItem.propTypes = propTypes;
CustomPagesItem.defaultProps = defaultProps;
