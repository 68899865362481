import { useState, useEffect } from 'react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { v4 as uuid } from 'uuid';

import { EXTERNAL_PAGE_TYPE, INTERNAL_PAGE_TYPE } from '../../../pages/constants';
import {
  fillPagesForGroups,
  removeGroupPages,
  getGroupPages,
} from '../../../pages/services/page-groups';
import {
  filterButtonPages,
  filterNonButtonPages,
  filterPagesByType,
  isButtonPage,
  sortByNavigationPriority,
} from '../../../pages/services/pages';

export default function useNavigationGroup(navigation) {
  const { all: pages, reOrderNavigationPriority, updatePage } = navigation;
  const [groups, setGroups] = useState([]);

  const groupPages = getGroupPages(pages);
  const groupWithPages = fillPagesForGroups(groups, groupPages);
  const sortedPages = sortByNavigationPriority([...removeGroupPages(pages), ...groupWithPages]);
  const buttonPages = filterButtonPages(sortedPages);
  const nonButtonPages = filterNonButtonPages(sortedPages);
  const internalPages = filterPagesByType(sortedPages, INTERNAL_PAGE_TYPE);
  const externalPages = filterPagesByType(sortedPages, EXTERNAL_PAGE_TYPE);

  function getGroupByName(groupName) {
    return groupWithPages.find(({ name }) => name === groupName);
  }

  async function addGroup(
    { name, navigationPriority, asButton },
    { updateRemote = true, reOrderPriority = true } = {},
  ) {
    const existingGroup = getGroupByName(name);
    if (existingGroup) {
      return;
    }

    setGroups((previousGroups) => {
      const group = {
        id: uuid(),
        name,
        title: name,
        navigationPriority,
        isGroup: true,
        metadata: {},
        pages: [],
        reduxIndex: previousGroups.length,
        pageType: INTERNAL_PAGE_TYPE,
        asButton: !!asButton,
      };

      const newGroups = [...previousGroups, group];
      return sortByNavigationPriority(newGroups);
    });

    if (reOrderPriority) {
      await reOrderNavigationPriority(navigationPriority, { updateRemote });
    }
  }

  async function updateGroup(page, data = {}, { updateRemote = true } = {}) {
    const { reduxIndex, id: pageId } = page || {};

    if (!_isNil(reduxIndex) && !_isNil(pageId) && !_isEmpty(data)) {
      const { name: groupName, navigationPriority, asButton } = data;
      setGroups((previousGroups) => {
        const newGroups = [...previousGroups];
        newGroups[reduxIndex] = { ...newGroups[reduxIndex], ...data };
        return sortByNavigationPriority(newGroups);
      });

      const { pages: childPages = [] } = page;

      if (!_isNil(groupName)) {
        await Promise.all(
          (childPages || []).map(async (childPage) => {
            return updatePage(childPage, { groupName }, { updateRemote });
          }),
        );
      }

      if (!_isNil(navigationPriority)) {
        await Promise.all(
          (childPages || []).map(async (childPage, i) => {
            const newNavigationPriority = navigationPriority + i;
            if (childPage.navigationPriority !== newNavigationPriority) {
              updatePage(
                childPage,
                { navigation_priority: newNavigationPriority },
                { updateRemote },
              );
            }
          }),
        );
      }

      if (!_isNil(asButton)) {
        await Promise.all(
          (childPages || []).map(async (childPage) => {
            const { metadata = {} } = childPage;
            return updatePage(
              childPage,
              { metadata: { ...metadata, as: asButton ? 'Button' : null } },
              { updateRemote },
            );
          }),
        );
      }
    }
  }

  function deleteGroup(page) {
    const { reduxIndex, id: pageId, pages: childPages } = page || {};
    if (!_isNil(reduxIndex) && !_isNil(pageId) && _isEmpty(childPages)) {
      setGroups((previousGroups) => {
        const newGroups = [...previousGroups];
        newGroups.splice(reduxIndex, 1);
        return newGroups;
      });
    }
  }

  useEffect(() => {
    const createdGroups = {};
    groupPages.forEach((page) => {
      const { groupName, navigationPriority } = page;
      if (!createdGroups[groupName] && _isEmpty(getGroupByName(groupName))) {
        const asButton = isButtonPage(page);
        addGroup(
          { name: groupName, navigationPriority, asButton },
          { updateRemote: false, reOrderPriority: false },
        );
      }
      createdGroups[groupName] = true;
    });
  }, []);

  return {
    all: sortedPages,
    internal: internalPages,
    external: externalPages,
    buttonPages,
    nonButtonPages,
    groups: groupWithPages,
    groupPages,
    getGroupByName,
    addGroup,
    updateGroup,
    deleteGroup,
  };
}
