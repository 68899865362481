import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import ErrorHandler from '../../../../../libs/errors/errors';
import ConfirmModal from '../../../../common/ConfirmModal/ConfirmModal';
import { PagePropType } from '../../../pages/types/page';
import { NavigationContext } from '../../context/Navigation.context';

import './DeletePageGroupModal.scss';

const propTypes = {
  page: PagePropType,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
};
const defaultProps = {
  page: null,
  disabled: false,
  onDelete: () => null,
};

export default function DeletePageGroupModal({
  page,
  disabled: disabledProp,
  onDelete: onDeleteProp,
}) {
  const { navigationGroup } = useContext(NavigationContext);
  const { deleteGroup } = navigationGroup;

  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { pages } = page || {};

  function openDeleteModal() {
    setShowDeleteModal(true);
  }

  function closeDeleteModal() {
    setShowDeleteModal(false);
  }

  async function onDelete() {
    try {
      setLoading(true);

      await deleteGroup(page);

      await onDeleteProp();
      closeDeleteModal();
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setLoading(false);
    }
  }

  const disableDeleteButton = loading || disabledProp || !_isEmpty(pages);

  return (
    <ConfirmModal
      trigger={
        <Button
          content="DELETE"
          className="delete-red delete"
          onClick={openDeleteModal}
          disabled={disableDeleteButton}
        />
      }
      header="Delete Link Group"
      content={<Modal.Content>Are you sure you want to remove this group?</Modal.Content>}
      open={showDeleteModal}
      onCancel={closeDeleteModal}
      onConfirm={onDelete}
      className="delete-style-rule-confirm-modal"
      confirmButton={<Button className="delete-red" primary={false} content="YES" />}
      cancelButton={<Button className="action-button" primary={false} content="NO" />}
    />
  );
}

DeletePageGroupModal.propTypes = propTypes;
DeletePageGroupModal.defaultProps = defaultProps;
