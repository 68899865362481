import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Segment, Label, Button, Container, Card, Modal, Message } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import BusinessPlan from './BusinessPlan';
import {
  CTA_BUTTON_COLOR,
  ACTIVE_BUTTON_COLOR,
  MONTHLY_CADENCE,
  ANNUAL_CADENCE,
  CLOVER_COLLECTION_METHOD,
  CURRENT_PLAN_BUTTON,
  SELECT_BUTTON,
  PRICING_URL,
  GLAMMATIC_PRICING_URL,
} from './BusinessPlanPage.constants';
import { toDisplayablePlan, getPrice, getActivePlan, isCustomPlan } from './BusinessPlanPage.utils';
import CloverPlanForm from './CloverPlanForm';
import SubscriptionsNotFound from './SubscriptionsNotFound';
import { initializeWebsiteSubscription as initializeWebsiteSubscriptionConnect } from '../../../actions/website';
import { DEFAULT_ERROR_MESSAGE_TIMEOUT_SECONDS } from '../../../constants/constants';
import API from '../../../libs/api';
import ErrorHandler from '../../../libs/errors';
import ExternalAnchor from '../../common/ExternalAnchor';
import { BRAND_IDENTITIES } from '../../modules/foundation/hooks/use-branding/useBranding.constants';

import './BusinessPlanPage.scss';

const mapDispatchToProps = (dispatch) => ({
  initializeWebsiteSubscription: (payload) =>
    dispatch(initializeWebsiteSubscriptionConnect(payload)),
});

const mapStateToProps = (state) => ({
  business: _get(state, 'business.core.value'),
  websiteId: _get(state, 'website.core.value.id'),
  websiteSubscription: _get(state, 'website.subscription'),
  brandIdentity: _get(state, 'business.core.value.brand_identity'),
});

const propTypes = {
  location: PropTypes.shape({}).isRequired,
};

function BusinessPlanPage({
  business,
  websiteId,
  websiteSubscription,
  initializeWebsiteSubscription,
  brandIdentity,
}) {
  const {
    available_plans: availablePlans,
    collection_cadence: collectionCadence,
    subscription_items: subscriptionItems,
  } = websiteSubscription;

  const [isMonthly, setIsMonthly] = useState(collectionCadence === MONTHLY_CADENCE);
  const activePlan = getActivePlan(availablePlans);
  const activePlanName = _get(activePlan, 'priceName');
  const currentPrice = _get(activePlan, isMonthly ? 'priceIds.monthly' : 'priceIds.annually');
  const [newPlanName, setNewPlanName] = useState(activePlanName);
  const [newPrice, setNewPrice] = useState(currentPrice);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const displayablePlansMap = {};
  availablePlans.forEach((plan, i) => {
    const displayablePlan = toDisplayablePlan(plan, i);
    displayablePlansMap[displayablePlan.priceName] = displayablePlan;
  });

  function onCancel() {
    setShowConfirmationModal(false);
    setIsMonthly(collectionCadence === MONTHLY_CADENCE);
  }

  function handleError(error) {
    ErrorHandler.capture(error);
    const errorMessage = _get(error, 'response.data.message', error.message);

    setSubmissionError(errorMessage);
    setTimeout(() => setSubmissionError(null), DEFAULT_ERROR_MESSAGE_TIMEOUT_SECONDS * 500);
  }

  async function onSelectPlanConfirm() {
    setIsSubmitting(true);

    try {
      const cadence = isMonthly ? MONTHLY_CADENCE : ANNUAL_CADENCE;

      const { data } = await API.updateWebsiteSubscription(websiteId, {
        priceId: newPrice,
        plan: newPlanName,
        cadence,
      });
      const { subscription_data: subscriptionData } = data;

      setIsSubmitting(false);
      setShowConfirmationModal(false);
      initializeWebsiteSubscription(subscriptionData);
    } catch (e) {
      handleError(e);
      setIsSubmitting(false);
    }
  }

  function setPlan(planName) {
    setNewPlanName(planName);
    setShowConfirmationModal(true);
  }

  function getPlan(planName) {
    return displayablePlansMap[planName];
  }

  function onSelectPlan(planName) {
    const plan = getPlan(planName);
    const planPrice = _get(plan, 'priceIds.annually');

    if (!isCustomPlan(plan) && plan && planPrice) {
      setPlan(planName);
      setNewPrice(planPrice);
    }
  }

  function getButtonText(plan) {
    if (plan.active) {
      return CURRENT_PLAN_BUTTON;
    }

    return SELECT_BUTTON;
  }

  function getButtonColor(plan) {
    const { active: planActive } = plan;

    if (planActive) return ACTIVE_BUTTON_COLOR;
    return CTA_BUTTON_COLOR;
  }

  function getPricingURL() {
    if (brandIdentity === BRAND_IDENTITIES.GLAMMATIC) {
      return GLAMMATIC_PRICING_URL;
    }

    return PRICING_URL;
  }

  function handleCardClick(e, target) {
    const { name, price } = target;

    setIsMonthly(name === 'monthlyCard');
    setNewPrice(price);
  }

  function getModalContent() {
    const plan = getPlan(newPlanName);
    if (!plan) return null;

    const { annualPrice, monthlyPrice } = plan;
    const annualPriceId = _get(plan, 'priceIds.annually');
    const monthlyPriceId = _get(plan, 'priceIds.monthly');
    const formattedAnnualPrice = getPrice(annualPrice);
    const formattedMonthlyPrice = getPrice(monthlyPrice);

    return (
      <Container>
        <Card.Group centered>
          <Card
            name="annualCard"
            price={annualPriceId}
            onClick={handleCardClick}
            className={!isMonthly ? 'active-price-card' : 'inactive-price-card'}
          >
            <Card.Content>
              <Card.Header>{formattedAnnualPrice}</Card.Header>
              <Card.Meta className="annual-card-meta">Billed Annually</Card.Meta>
              <Card.Description />
              <Label color="blue" attached="top right" className="best-value-card-ribbon">
                Save 15%
              </Label>
            </Card.Content>
          </Card>
          <Card
            name="monthlyCard"
            price={monthlyPriceId}
            onClick={handleCardClick}
            className={isMonthly ? 'active-price-card' : 'inactive-price-card'}
            disabled
          >
            <Card.Content>
              <Card.Header>{formattedMonthlyPrice} /mo</Card.Header>
              <Card.Meta className="monthly-card-meta">Billed Monthly</Card.Meta>
            </Card.Content>
          </Card>
        </Card.Group>
        <h2>
          You have selected the {newPlanName} {isMonthly ? 'Monthly' : 'Annual'} Plan
        </h2>
        <h3>
          You will be charged{' '}
          {isMonthly
            ? `${formattedMonthlyPrice} per month.`
            : `${formattedAnnualPrice} once per year.`}
        </h3>
        <p>
          Clicking on confirm will immediately enable features based on your chosen plan. Your final
          itemized invoice will be sent to your email address.
        </p>
      </Container>
    );
  }

  const collectionMethod = _get(business, 'collection_method');
  if (collectionMethod === CLOVER_COLLECTION_METHOD) {
    return <CloverPlanForm />;
  }

  const invalidSubscriptionState =
    !availablePlans || !availablePlans.length || !subscriptionItems || !subscriptionItems.length;
  if (invalidSubscriptionState) {
    return <SubscriptionsNotFound brandIdentity={brandIdentity} />;
  }

  const pricingURL = getPricingURL();

  return (
    <BusinessPlan>
      <Modal
        open={showConfirmationModal}
        dimmer="inverted"
        className="change-plan-confirmation-modal"
        size="small"
        closeOnDimmerClick={false}
        closeOnEscape={false}
      >
        <Modal.Content>
          <Modal.Description>{getModalContent()}</Modal.Description>
          {submissionError && (
            <Message
              error
              header="There was an issue with your plan change"
              content={submissionError}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            content="Confirm"
            onClick={onSelectPlanConfirm}
            disabled={isSubmitting}
            loading={isSubmitting}
            primary
          />
        </Modal.Actions>
      </Modal>
      <Segment className="business-plan" loading={showConfirmationModal}>
        {activePlanName && (
          <h2>
            You are currently on the {activePlanName} {collectionCadence} plan
          </h2>
        )}

        <BusinessPlan.BusinessPlanBody>
          {Object.values(displayablePlansMap).map((plan) => {
            return (
              <BusinessPlan.BusinessPlanBodyCard
                // TODO apply the following rule
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...plan}
                key={plan.priceName}
                onSelectPlan={onSelectPlan}
                highlight={plan.active}
                buttonText={getButtonText(plan)}
                buttonColor={getButtonColor(plan)}
                brandIdentity={brandIdentity}
                hideButton={brandIdentity === BRAND_IDENTITIES.GLAMMATIC && !plan.active}
              />
            );
          })}
        </BusinessPlan.BusinessPlanBody>
        <Message
          header="Need more info?"
          content={
            <>
              Learn more about our plans at{' '}
              <ExternalAnchor href={pricingURL}>{pricingURL}</ExternalAnchor>
            </>
          }
        />
      </Segment>
    </BusinessPlan>
  );
}

BusinessPlanPage.protoTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPlanPage);
