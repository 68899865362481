import React, { useContext } from 'react';

import { Button } from 'semantic-ui-react';

import _find from 'lodash/find';
import { useSelector } from 'react-redux';

import { HtmlElementPageContext } from '../../../../context';
import { useHtmlElements } from '../../../../hooks';
import { selectHtmlElements } from '../../../../selectors/html-elements';
import { validateHtmlElement } from '../../../../services';

import './ValidateButton.scss';

const propTypes = {};

const defaultProps = {};

export default function ValidateButton() {
  const context = useContext(HtmlElementPageContext);
  const { saveHtmlElement } = useHtmlElements();
  const {
    itemEditingId,
    isSavingItemToDb,
    removeDirtyId,
    setErrorMessage,
    setIsSavingItemToDb,
    setItemEditingId,
  } = context;
  const htmlElements = useSelector(selectHtmlElements);

  async function onClick() {
    setIsSavingItemToDb(true);

    try {
      const itemState = _find(htmlElements, ({ id, localId }) => itemEditingId === (id || localId));
      const item = {
        ...itemState,
      };
      validateHtmlElement(item);
      await saveHtmlElement(item);

      const { id, localId } = item;
      setErrorMessage(null);
      removeDirtyId(id || localId);
      setItemEditingId(null);
    } catch (error) {
      setIsSavingItemToDb(false);
      setErrorMessage(error.message);
    } finally {
      setIsSavingItemToDb(false);
    }
  }

  return (
    <Button
      className="validate-html-element-script secondary-navy"
      onClick={onClick}
      loading={isSavingItemToDb}
    >
      Validate and Save
    </Button>
  );
}

ValidateButton.propTypes = propTypes;
ValidateButton.defaultProps = defaultProps;
