import _isNil from 'lodash/isNil';
import moment from 'moment';

import { DATE_UTC_FORMAT } from './constants';

const DateUtils = {
  format(date, outputFormat, inputFormat = undefined) {
    if (_isNil(date)) {
      return '';
    }
    if (outputFormat && date) {
      return moment(date, inputFormat).format(outputFormat);
    }
    return date;
  },

  parse(dateString) {
    return moment(dateString);
  },

  getCurrentDate({ format } = {}) {
    return this.format(new Date(), format);
  },

  toUtc(date, { format = DATE_UTC_FORMAT } = {}) {
    if (!date) {
      return '';
    }
    return this.format(this.parse(date).utc(), format);
  },

  monthsDifference(date1, date2) {
    return this.parse(date1).diff(this.parse(date2), 'months', true);
  },

  daysDifference(date1, date2) {
    return this.parse(date1).diff(this.parse(date2), 'days', true);
  },

  convertMinsToHrsMins(timeInMins) {
    const mins = Number(timeInMins);
    if (!mins) {
      return '00:00';
    }
    // Convert Mins to HH:MM
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = String(h).padStart(2, '0');
    m = String(m).padStart(2, '0');
    return `${h}:${m}`;
  },

  convertTimeToMinutes(hourMinutes) {
    // Convert HH:MM time format to minutes
    if (hourMinutes) {
      const splitTime = hourMinutes.split(':');
      return Number(splitTime[0]) * 60 + Number(splitTime[1]);
    }
    return 0;
  },

  addDays(date, amountDays, { format } = {}) {
    return this.format(this.parse(date).add(amountDays, 'days'), format);
  },
};

export default DateUtils;
