import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Input, Button, Confirm } from 'semantic-ui-react';

import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import { useSelector, useDispatch } from 'react-redux';

import {
  SETTINGS_OPTIONS,
  CATEGORY_LONG_DESCRIPTION_MAX_LENGTH,
  CATEGORY_CATALOG_OBJECT_TYPE,
  CATEGORY_LONG_DESCRIPTION_MIN_LENGTH,
  CATEGORY_SHORT_DESCRIPTION_MAX_LENGTH,
  CATEGORY_SHORT_DESCRIPTION_MIN_LENGTH,
  CATEGORY_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS,
  CATEGORY_SHORT_DESCRIPTION_PLACEHOLDER,
  CATEGORY_LONG_DESCRIPTION_PLACEHOLDER,
} from './EditMenuCategory.constants';
import { getDescriptionRequirements, getTitleRequirements } from './EditMenuCategory.utils';
import {
  updateMenuCategory,
  deleteMenuCategory,
  flagMenuSaved,
} from '../../../../../actions/business';
import API from '../../../../../libs/api';
import { sortItemsByList } from '../../../../../libs/array';
import { getErrorMessage } from '../../../../../libs/errors';
import { objectToCamelCase, objectToSnakeCase } from '../../../../../libs/format';
import {
  selectBusiness,
  selectBusinessBrandIdentity,
  selectBusinessFiles,
  selectBusinessName,
} from '../../../../../selectors/business';
import HelpTooltip from '../../../../common/HelpTooltip';
import CatalogObjectDescriptionInput from '../../../../fields/CatalogObjectDescriptionInput';
import { CatalogCategoryPropType } from '../../../../modules/catalog/proptypes/catalog';
import FileSelectionWell from '../../../../modules/files/components/FileSelectionWell';
import { SOURCE_TYPE_MENU } from '../../../../modules/files/constants/sources';
import { CATEGORY_PAGE_TYPE } from '../../../../modules/pages/constants';
import SeoMetadataFields from '../../../../modules/seo/components/SeoMetadataFields';
import { createSeoTitlePlaceholder } from '../../../../modules/seo/components/SeoMetadataFields/components/SeoTitle/SeoTitle.utils';
import ProductDescriptionPageWidget from '../../../../modules/website-component-interaction/ProductDescriptionPageWidget';
import WebsiteComponentInteractionContainer from '../../../../modules/website-component-interaction/WebsiteComponentInteractionContainer';
import {
  filterClickthruInteractions,
  filterNonClickthruInteractions,
} from '../../../../modules/website-component-interaction/utils';
import { InventoryConsumer, InventoryContext } from '../../../MenuForm/Menu.context';
import { getMenuNameFromBrandIdentity } from '../../../MenuForm/MenuForm.utils';
import EditMenuModal from '../../common/EditMenuModal';
import SettingsWidget from '../../common/SettingsWidget/SettingsWidget';
import { IS_FEATURED_FIELD } from '../../libs/menu-constants';
import {
  areCtasEnabledForRecipe,
  getBusinessMenuSettings,
  isFeaturedFeatureEnabled,
  isClickTroughUrlEnabledForBusiness,
  isMultipleImagesSupported,
  isDetailedDescriptionEnabled,
  isAdvancedSeoSupported,
} from '../../libs/menu-helpers';

import './EditMenuCategory.scss';

const propTypes = {
  scheduleId: PropTypes.number.isRequired,
  scheduleIndex: PropTypes.number.isRequired,

  categoryIndex: PropTypes.number,
  initialCategory: CatalogCategoryPropType,

  trigger: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {
  categoryIndex: null,
  initialCategory: {},
  open: false,
};

export default function EditMenuCategory({
  scheduleId,
  scheduleIndex,
  categoryIndex,
  initialCategory,
  trigger,
  open,
  onOpen,
  onClose,
}) {
  const dispatch = useDispatch();

  const business = useSelector(selectBusiness);
  const businessName = useSelector(selectBusinessName);
  const brandIdentity = useSelector(selectBusinessBrandIdentity);
  const menuEntityName = getMenuNameFromBrandIdentity(brandIdentity);
  const allSchedules = useSelector((state) => _get(state, 'business.menu.value.schedules'));
  const businessFiles = useSelector(selectBusinessFiles);

  const { activePatch } = useContext(InventoryContext);

  const [formData, setFormData] = useState(objectToCamelCase(_cloneDeep(initialCategory || {})));

  const [errorMessage, setErrorMessage] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  function resetFormData() {
    setFormData(objectToCamelCase(_cloneDeep(initialCategory || {})));
    setErrorMessage('');
  }

  useEffect(() => {
    resetFormData();
  }, [initialCategory]);

  const { id: businessId, type: businessType } = business;

  const {
    id: categoryId,
    localId: categoryLocalId,
    name = '',
    description = '',
    fullDescription = '',
    interactions = [],
    isFeatured = false,
    files = [],
    seoTitle = '',
    seoDescription = '',
  } = formData || {};
  const isNew = !categoryId;

  const areCtasEnabled = areCtasEnabledForRecipe(activePatch);
  const isClickTroughUrlEnabled = isClickTroughUrlEnabledForBusiness(business, activePatch);
  const isFeaturedEnabled = isFeaturedFeatureEnabled(activePatch);
  const isMultipleImagesEnabled = isMultipleImagesSupported(activePatch);
  const shouldShowDetailedDescription = isDetailedDescriptionEnabled(activePatch);
  const settingsOptions = getBusinessMenuSettings(business, SETTINGS_OPTIONS, {
    allowFeaturedSetting: isFeaturedEnabled,
  });
  const categoryData = {
    [IS_FEATURED_FIELD]: isFeatured,
  };
  const { url: pdpUrl } = _head(filterClickthruInteractions(interactions)) || {};

  const categoryBusinessFiles = sortItemsByList(businessFiles, files, 'id');

  const showSeoMetadataField = isAdvancedSeoSupported(activePatch);

  function validateInputs() {
    if (!name) return 'Please enter a category name.';
    return true;
  }

  function closeModal() {
    resetFormData();
    onClose();
  }

  async function onSaveNewCategory() {
    const inputErrorMessage = validateInputs();
    if (!_isEmpty(inputErrorMessage)) {
      setErrorMessage(inputErrorMessage);
      return;
    }

    setSaveLoading(true);
    try {
      let scheduleCategories = _get(allSchedules, `[${scheduleIndex}].categories`, []);
      const scheduleCategoryIndex = _findIndex(scheduleCategories, (id) => id === categoryLocalId);

      const payload = objectToSnakeCase({
        name,
        description,
        fullDescription,
        interactions,
        files,
        order: scheduleCategoryIndex,
        seoTitle,
        seoDescription,
      });

      const { data: createdCategory } = await API.createMenuCategory(
        businessId,
        businessType,
        payload,
      );

      scheduleCategories = [
        ...scheduleCategories.slice(0, scheduleCategoryIndex),
        createdCategory.id,
        ...scheduleCategories.slice(scheduleCategoryIndex + 1, scheduleCategories.length),
      ];

      const { data: createdSchedule } = await API.updateMenuScheduleCategories(
        businessId,
        businessType,
        scheduleId,
        scheduleCategories,
      );

      dispatch(
        updateMenuCategory({
          index: categoryIndex,
          values: createdCategory,
          scheduleIndex,
          categories: createdSchedule.categories,
        }),
      );
      dispatch(flagMenuSaved(true));
      closeModal();
    } catch (e) {
      setErrorMessage(getErrorMessage(e, 'There was an unknown error creating the category.'));
    }

    setSaveLoading(false);
  }

  async function onSaveCategory() {
    const inputErrorMessage = validateInputs();
    if (!_isEmpty(inputErrorMessage)) {
      setErrorMessage(inputErrorMessage);
      return;
    }

    setSaveLoading(true);

    const payload = objectToSnakeCase({
      name,
      description,
      fullDescription,
      isFeatured,
      interactions,
      files,
      seoTitle,
      seoDescription,
    });

    try {
      const { data: updatedCategory } = await API.updateMenuCategory(
        businessId,
        businessType,
        categoryId,
        payload,
      );

      dispatch(
        updateMenuCategory({
          index: categoryIndex,
          values: { ...updatedCategory, index: categoryIndex },
        }),
      );
      dispatch(flagMenuSaved(true));
      closeModal();
    } catch (e) {
      setErrorMessage(getErrorMessage(e, 'There was an unknown error saving the category.'));
    }
    setSaveLoading(false);
  }

  async function onDeleteCategory() {
    setDeleteLoading(true);

    try {
      await API.deleteMenuCategory(businessId, businessType, categoryId);
      dispatch(deleteMenuCategory({ index: categoryIndex, scheduleIndex, categoryId }));
      dispatch(flagMenuSaved(true));
      closeModal();
    } catch (e) {
      setErrorMessage(getErrorMessage(e, 'There was an unknown error deleting the category.'));
    }
    setDeleteLoading(false);
  }

  function onDeleteNewCategory() {
    dispatch(
      deleteMenuCategory({
        index: categoryIndex,
        scheduleIndex,
        categoryId: categoryId || categoryLocalId,
      }),
    );
    closeModal();
  }

  function updateMenuCategoryState(payload = {}) {
    setFormData((prevFormData) => ({ ...prevFormData, ...objectToCamelCase(payload) }));
  }

  function onUpdateWebsiteComponentInteractions(firstNonClickthroughInteractions) {
    const originalNonClickthroughInteractions = filterNonClickthruInteractions(interactions);
    const nonClickthroughInteractions = [
      ...firstNonClickthroughInteractions,
      ...originalNonClickthroughInteractions.slice(firstNonClickthroughInteractions.length),
    ];
    const clickthroughInteractions = filterClickthruInteractions(interactions);

    updateMenuCategoryState({
      interactions: [...nonClickthroughInteractions, ...clickthroughInteractions],
    });
  }

  function onUpdateFiles(newFiles) {
    const fileIds = newFiles.map((file) => (_isNumber(file) ? file : file.id));
    updateMenuCategoryState({ files: fileIds });
  }

  function onUpdateClickthruInteractions({ values: { interactions: updatedInteractions = [] } }) {
    const nonClickthroughInteractions = filterNonClickthruInteractions(interactions);

    updateMenuCategoryState({
      interactions: [...nonClickthroughInteractions, ...updatedInteractions],
    });
  }

  function onSeoMetadataChange(e, { name: fieldName, value }) {
    updateMenuCategoryState({ [fieldName]: value });
  }

  return (
    <InventoryConsumer>
      {({ entityName, copies: { categoryDescription } }) => (
        <EditMenuModal
          open={open}
          openModal={onOpen}
          closeModal={() => {
            if (!categoryId) {
              onDeleteNewCategory();
            }
            closeModal();
          }}
          className="edit-menu-schedule"
          trigger={trigger}
          newItemTitle="Create Category"
          existingItemTitle="Edit Category"
          isNewItem={isNew}
          titleExample="Breakfast"
          description={categoryDescription}
          content={
            <>
              <h2>
                <span className="red asterisk">Name</span>
                <HelpTooltip
                  title="Category Name"
                  content="Name this appropriately for the Items encompassed in this category. Examples are Salads, Sandwiches, Dessert..."
                />
              </h2>
              <Input
                defaultValue={name}
                placeholder="e.g. Sandwiches"
                onChange={(e, { value }) => updateMenuCategoryState({ name: value })}
              />
              <h2>
                {shouldShowDetailedDescription && 'Short '}Description
                <HelpTooltip
                  title={`${shouldShowDetailedDescription ? 'Short' : ''} Description`}
                  content={`Provide a concise summary of the ${entityName}. This description will be used to highlight ${entityName} and for alternative text descriptions. Keep it within 200 characters for optimal display.`}
                />
              </h2>
              <CatalogObjectDescriptionInput
                field={{
                  name: 'description',
                  placeholder: CATEGORY_SHORT_DESCRIPTION_PLACEHOLDER,
                }}
                catalogObject={{
                  name,
                  type: CATEGORY_CATALOG_OBJECT_TYPE,
                  description,
                }}
                prompt={{
                  minOutputLength: CATEGORY_SHORT_DESCRIPTION_MIN_LENGTH,
                  maxOutputLength: CATEGORY_SHORT_DESCRIPTION_MAX_LENGTH,
                  additionalRequirements: CATEGORY_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS,
                }}
                onChange={(e, { value }) => updateMenuCategoryState({ description: value })}
              />
              {shouldShowDetailedDescription && (
                <>
                  <h2>
                    Detailed Description
                    <HelpTooltip
                      title="Detailed Description"
                      content={`Provide a detailed and comprehensive description of the ${entityName} here. This text will appear on detail pages and will help improve SEO and provide full information about the ${entityName}. Ensure to include relevant keywords and phrases related to the service.`}
                    />
                  </h2>
                  <CatalogObjectDescriptionInput
                    field={{
                      name: 'fullDescription',
                      placeholder: CATEGORY_LONG_DESCRIPTION_PLACEHOLDER,
                    }}
                    catalogObject={{
                      name,
                      type: CATEGORY_CATALOG_OBJECT_TYPE,
                      description: fullDescription,
                    }}
                    prompt={{
                      minOutputLength: CATEGORY_LONG_DESCRIPTION_MIN_LENGTH,
                      maxOutputLength: CATEGORY_LONG_DESCRIPTION_MAX_LENGTH,
                      additionalRequirements: '',
                    }}
                    onChange={(e, { value }) => updateMenuCategoryState({ fullDescription: value })}
                  />
                </>
              )}

              {areCtasEnabled && (
                <>
                  <h2>
                    Buttons
                    <HelpTooltip
                      title="Buttons"
                      content="You can configure up to two buttons to appear on the item's full product details page."
                    />
                  </h2>
                  <WebsiteComponentInteractionContainer
                    interactions={filterNonClickthruInteractions(interactions).map(
                      objectToSnakeCase,
                    )}
                    onUpdate={onUpdateWebsiteComponentInteractions}
                  />
                </>
              )}

              {isClickTroughUrlEnabled && (
                <ProductDescriptionPageWidget
                  value={pdpUrl}
                  onChange={onUpdateClickthruInteractions}
                />
              )}

              {isMultipleImagesEnabled && (
                <>
                  <h2>
                    Images
                    <HelpTooltip
                      title="Images"
                      content="Optionally add images for the Category. If multiple images are selected, a carousel will be shown."
                    />
                  </h2>
                  <FileSelectionWell
                    files={categoryBusinessFiles}
                    onUpdateFiles={onUpdateFiles}
                    uploadSourceType={SOURCE_TYPE_MENU}
                  />
                </>
              )}

              {!isNew && (
                <SettingsWidget
                  data={categoryData}
                  onChange={(e, { name: fieldName, checked }) =>
                    updateMenuCategoryState({ [fieldName]: checked })
                  }
                  options={settingsOptions}
                />
              )}

              {showSeoMetadataField && (
                <>
                  <h2>SEO Information</h2>
                  <SeoMetadataFields
                    onChange={onSeoMetadataChange}
                    pageType={CATEGORY_PAGE_TYPE}
                    header=""
                  >
                    <SeoMetadataFields.SeoTitle
                      showWarnings
                      title={seoTitle}
                      additionalRequirements={getTitleRequirements({ name })}
                      placeholder={createSeoTitlePlaceholder(
                        `${menuEntityName} - ${name}`,
                        businessName,
                      )}
                    />
                    <SeoMetadataFields.SeoDescription
                      showWarnings
                      description={seoDescription}
                      additionalRequirements={getDescriptionRequirements({ name, description })}
                      placeholder={description}
                    />
                  </SeoMetadataFields>
                </>
              )}
            </>
          }
          actions={
            <>
              {errorMessage && <div className="menu-error-message">{errorMessage}</div>}
              <Button
                color={isNew ? null : 'red'}
                onClick={() => setDeleteConfirmationOpen(true)}
                content={isNew ? 'Cancel' : 'Delete'}
              />
              <Confirm
                dimmer="inverted"
                className="delete-confirmation"
                content={`Are you sure you want to ${isNew ? 'cancel' : 'delete this category'}?`}
                confirmButton={{
                  content: `${isNew ? 'Confirm' : 'Delete'}`,
                  loading: deleteLoading,
                }}
                open={deleteConfirmationOpen}
                onCancel={() => setDeleteConfirmationOpen(false)}
                onConfirm={isNew ? onDeleteNewCategory : onDeleteCategory}
              />
              <Button
                onClick={isNew ? onSaveNewCategory : onSaveCategory}
                className="action"
                loading={saveLoading}
                content="Save"
              />
            </>
          }
        />
      )}
    </InventoryConsumer>
  );
}

EditMenuCategory.propTypes = propTypes;
EditMenuCategory.defaultProps = defaultProps;
