import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isValidUser } from '../../../libs/auth';
import { getLeadSourceFromUser, getLeadChannelFromUser } from '../../../libs/lead-source';
import LoginBanner from '../LoginBanner';
import SignUpForm from '../SignUpForm';

import './SignUpPage.scss';

const mapStateToProps = ({ user }) => ({
  user: _get(user, 'core.value'),
});

const propTypes = {
  leadSource: PropTypes.string,
  authRequired: PropTypes.bool,
};

const defaultProps = {
  leadSource: '',
  authRequired: true,
};

function SignUpPage({ user, userRole, leadSource: initialLeadSource, authRequired }) {
  const navigate = useNavigate();
  const leadSource = initialLeadSource || getLeadSourceFromUser(user);
  const leadChannel = getLeadChannelFromUser(user, userRole);

  // Check if auth is required and check if valid user
  if (authRequired && !isValidUser(user)) {
    navigate('/');
    return null;
  }

  return (
    <div className="sign-up-page-container">
      <LoginBanner
        backActions={
          <Button basic onClick={() => navigate('/')}>
            Go Back
          </Button>
        }
      />
      <div>
        <SignUpForm leadChannel={leadChannel} leadSource={leadSource} />
      </div>
    </div>
  );
}

SignUpPage.propTypes = propTypes;
SignUpPage.defaultProps = defaultProps;

export default connect(mapStateToProps)(SignUpPage);
