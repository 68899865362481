import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { getIsMoney } from './PriceOverrideToggle.utils';
import ErrorHandler from '../../../../../../../../../libs/errors/errors';
import PriceField from '../../../../../../../../fields/PriceField/PriceField';
import {
  CUSTOM_PRICE_LABEL,
  INITIAL_PRICE_OPTION,
  PRICE_TYPE_OPTIONS,
} from '../../TeamMemberServicesTable.constants';

import './PriceOverrideToggle.scss';

const propTypes = {
  priceType: PropTypes.oneOf(PRICE_TYPE_OPTIONS).isRequired,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  priceDisplay: PropTypes.string.isRequired,
  priceOverride: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceDisplayOverride: PropTypes.string,
  onUpdate: PropTypes.func,
};

const defaultProps = {
  priceOverride: 0,
  priceDisplayOverride: '',
  onUpdate: () => null,
};

export default function PriceOverrideToggle({
  priceType,
  price,
  priceDisplay,
  priceOverride,
  priceDisplayOverride,
  onUpdate: onUpdateProp,
}) {
  const isInitialPriceType = priceType === INITIAL_PRICE_OPTION;
  const isCustomPriceLabelType = priceType === CUSTOM_PRICE_LABEL;

  const isMoney = getIsMoney(priceType, { priceDisplay });

  const [loading, setLoading] = useState(false);

  const priceValue = isInitialPriceType ? price : priceOverride;
  const labelValue = isInitialPriceType ? priceDisplay : priceDisplayOverride;

  async function onChange(e, { value: newPrice }) {
    try {
      setLoading(true);
      if (isInitialPriceType) {
        return;
      }
      if (onUpdateProp) {
        const payload = isCustomPriceLabelType
          ? { priceOverride: 0, priceDisplayOverride: newPrice }
          : { priceOverride: newPrice, priceDisplayOverride: '' };
        await onUpdateProp(payload);
      }
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="price-override-toggle-container">
      <PriceField
        onChange={onChange}
        priceValue={priceValue}
        labelValue={labelValue}
        loading={loading}
        isMoney={isMoney}
        disabled={isInitialPriceType}
      />
    </div>
  );
}

PriceOverrideToggle.propTypes = propTypes;
PriceOverrideToggle.defaultProps = defaultProps;
