import React from 'react';

import EmailTriggerList from '../../modules/emails/components/EmailTriggerList/EmailTriggerList';
import { EMAIL_TRIGGERS } from '../../modules/emails/components/EmailTriggerList/EmailTriggerList.constants';

export default function EmailTriggersPage() {
  return (
    <div>
      <h1>What would you like to send?</h1>
      <EmailTriggerList emailTriggers={EMAIL_TRIGGERS} />
    </div>
  );
}
