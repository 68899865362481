import PropTypes from 'prop-types';

export const CuratedHeroComponentConfigurationPropType = PropTypes.shape({
  component: PropTypes.string,
  componentId: PropTypes.string,
  sectionLabel: PropTypes.string,
  contentTypes: PropTypes.arrayOf(PropTypes.string),
  contentSettings: PropTypes.shape({
    image: PropTypes.shape({
      minFilesAllowed: PropTypes.number,
      maxFilesAllowed: PropTypes.number,
    }),
    text: PropTypes.shape({
      widgetType: PropTypes.string,
      widgetExtraProps: PropTypes.shape({}),
    }),
    cta: PropTypes.shape({
      maxCtasAllowed: PropTypes.number,
    }),
  }),
});
