import Validate from '../../../../../../libs/validate';

export const FORM_DESCRIPTIONS = {
  tags: 'Tags are a way for you to label contacts to make them easier to find. You can choose existing tags or create new ones here.',
};

export const FORM_TITLES = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phone: 'Phone Number',
  tags: 'Tags',
};

export const FORM_PLACEHOLDERS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'customer@gmail.com',
  phone: '(123) 456-7890',
};

export const FORM_INVALID_FIELDS = {
  firstName: Validate.getErrorMessage('defined'),
  lastName: Validate.getErrorMessage('defined'),
  email: Validate.getErrorMessage('email'),
  phone: Validate.getErrorMessage('phone'),
};
