function getChildrenText(child) {
  if (typeof child === 'string') {
    return child;
  }
  if (!child || !child.props) {
    return null;
  }
  const { props } = child;
  return typeof props.children === 'string' ? props.children : null;
}

export { getChildrenText };
