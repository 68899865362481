import React, { useState } from 'react';

import { Button } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import API from '../../../../../libs/api';
import { selectBusinessType, selectBusinessId } from '../../../../../selectors';
import ConfirmModal from '../../../../common/ConfirmModal';
import { CONFIRMATION_MESSAGE } from '../EmailTriggerList/EmailTriggerList.constants';

function EmailTriggerButton({ name: emailName }) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const businessType = useSelector(selectBusinessType);
  const businessId = useSelector(selectBusinessId);

  function onCancel() {
    setOpen(false);
  }

  function onClick() {
    setOpen(true);
  }

  async function onConfirm() {
    setIsLoading(true);

    try {
      await API.sendEmailTrigger(emailName, businessType, businessId);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  }

  return (
    <div>
      <Button onClick={onClick}>Send</Button>
      <ConfirmModal
        open={open}
        onCancel={onCancel}
        onConfirm={onConfirm}
        content={CONFIRMATION_MESSAGE}
        confirmButton={
          <Button primary disabled={isLoading} loading={isLoading}>
            Yes
          </Button>
        }
      />
    </div>
  );
}

export default EmailTriggerButton;
