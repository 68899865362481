import React, { useContext } from 'react';

import { Form, Radio } from 'semantic-ui-react';

import HelpTooltip from '../../../../common/HelpTooltip';
import {
  DISMISSAL_TYPE_LOCAL,
  DISMISSAL_TYPE_SESSION,
  DISPLAY_SETTINGS_HELP,
} from '../../constants';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

import './AnnouncementDismissalType.scss';

export default function AnnouncementDismissalType() {
  const { dismissalType, setValue } = useContext(AnnouncementFormContext);

  return (
    <>
      <Form.Group grouped className="announcement-dismissal-type">
        <div className="form-label">
          Display Settings
          <HelpTooltip
            title="Display Settings"
            description={DISPLAY_SETTINGS_HELP}
            position="top left"
          />
        </div>

        <Form.Field>
          <Radio
            label="Show Every Website Visit"
            value={DISMISSAL_TYPE_SESSION}
            checked={dismissalType === DISMISSAL_TYPE_SESSION}
            onChange={(e, { value }) => setValue('dismissalType', value)}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Show Once Per Visitor"
            value={DISMISSAL_TYPE_LOCAL}
            checked={dismissalType === DISMISSAL_TYPE_LOCAL}
            onChange={(e, { value }) => setValue('dismissalType', value)}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
}
