import React, { useContext, useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import _cloneDeep from 'lodash/cloneDeep';

import { getNewTeamMemberOrder } from './CreateTeamMemberModal.utils';
import API from '../../../../../../../libs/api';
import { multiCaseGet } from '../../../../../../../libs/format';
import { camelToSnakeCase } from '../../../../../../../libs/strings';
import { TeamsSectionContext } from '../../TeamMembersTable.context';
import EditTeamMemberModal from '../EditTeamMemberModal';

export default function CreateTeamMemberModal() {
  const [showModal, setShowModal] = useState(false);
  const [currentMember, setCurrentMember] = useState({ show: true });

  const { business, setTeamMembers, teamMembers, allLocations } = useContext(TeamsSectionContext);

  function onChange(e, { name, value }) {
    setCurrentMember((previousFormData) => ({
      ...previousFormData,
      [camelToSnakeCase(name)]: value,
    }));
  }

  function closeModal() {
    setShowModal(false);
    setCurrentMember({ show: true });
  }

  async function onSave() {
    const { image, ...memberWithoutImage } = _cloneDeep(currentMember);
    const businessInfo = multiCaseGet(business, 'businessInfo');
    const order = getNewTeamMemberOrder(teamMembers);
    const payload = {
      social_media_accounts: [],
      hours: [],
      locations: [],
      ...memberWithoutImage,
      business_info: businessInfo,
      order,
    };
    const { data } = await API.createTeamMember(payload);

    if (image && typeof image === 'object') {
      const imagePayload = new FormData();
      imagePayload.append('image', image.file || '');
      await API.updateTeamMemberAttribute(data.id, imagePayload);
    }

    setTeamMembers((previousTeamMembers) => [...previousTeamMembers, { ...data, image }]);
    closeModal();
  }

  return (
    <>
      <EditTeamMemberModal
        open={showModal}
        onClose={closeModal}
        teamMember={currentMember}
        onSave={onSave}
        onChange={onChange}
        allLocations={allLocations}
        reset
      />
      <Button
        floated="right"
        id="add-team-member-modal-button"
        icon
        labelPosition="left"
        primary
        size="small"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <Icon name="user" /> Add Member
      </Button>
    </>
  );
}
