import React from 'react';

import { getImageUrl } from './DeckItem.utils';
import Dots from '../../../../../../common/sortables/Dots';

import './DeckItem.scss';

function DeckItem({ file }) {
  const imageUrl = getImageUrl(file, { width: 200 });
  const style = {
    backgroundImage: `url(${imageUrl})`,
  };
  return (
    <div className="deck-item" style={style}>
      <Dots size="5px" />
    </div>
  );
}

export default DeckItem;
