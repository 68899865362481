import _isNumber from 'lodash/isNumber';

export function formatPrice(price) {
  if (price.startsWith('-')) {
    return `-$${price.slice(1)}`;
  }
  return `+$${price}`;
}

export function formatAppliedToString(appliedTo) {
  if (!_isNumber(appliedTo)) {
    return '';
  }
  if (appliedTo === 1) {
    return 'Applied to 1 item';
  }
  return `Applied to ${appliedTo} items`;
}
