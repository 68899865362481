import React from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { getDescriptionRequirements, getTitleRequirements } from './SeoConfiguration.utils';
import { selectBusinessName } from '../../../../../selectors/business';
import { BLOG_POST_PAGE_TYPE } from '../../../../modules/pages/constants';
import SeoMetadataFields from '../../../../modules/seo/components/SeoMetadataFields';
import { createSeoTitlePlaceholder } from '../../../../modules/seo/components/SeoMetadataFields/components/SeoTitle/SeoTitle.utils';

import './SeoConfiguration.scss';

const propTypes = {
  onUpdate: PropTypes.func.isRequired,
  seoTitle: PropTypes.string.isRequired,
  seoDescription: PropTypes.string.isRequired,
  title: PropTypes.string,
  summary: PropTypes.string,
};

const defaultProps = {
  title: '',
  summary: '',
};

export default function SeoConfiguration({ onUpdate, seoTitle, seoDescription, title, summary }) {
  function onSeoMetadataChange(e, { name }) {
    onUpdate(e, name);
  }
  const businessName = useSelector(selectBusinessName);
  const titleAdditionalRequirements = getTitleRequirements({ title });
  const descriptionAdditionalRequirements = getDescriptionRequirements({ title, summary });

  return (
    <div className="seo-configuration">
      <SeoMetadataFields onChange={onSeoMetadataChange} pageType={BLOG_POST_PAGE_TYPE}>
        <SeoMetadataFields.SeoTitle
          showWarnings
          title={seoTitle}
          additionalRequirements={titleAdditionalRequirements}
          placeholder={createSeoTitlePlaceholder(title, businessName)}
        />
        <SeoMetadataFields.SeoDescription
          showWarnings
          description={seoDescription}
          additionalRequirements={descriptionAdditionalRequirements}
          placeholder={summary}
        />
      </SeoMetadataFields>
    </div>
  );
}

SeoConfiguration.propTypes = propTypes;
SeoConfiguration.defaultProps = defaultProps;
