import React from 'react';

import PropTypes from 'prop-types';
import {
  ListItem,
  ListContent,
  ListDescription,
  ListHeader,
  Button,
  Label,
  Popup,
  Progress,
  Icon,
} from 'semantic-ui-react';

import {
  FILE_UPLOAD_SUCCESS_STATUS,
  FILE_UPLOAD_ERROR_STATUS,
} from 'components/modules/files/constants/upload';
import {
  getFormattedLocalFileSize,
  exceedsMaxFileSize,
} from 'components/modules/files/services/size';
import { BusinessFilePropType, LocalFilePropType } from 'components/modules/files/types/files';

import './FileListItem.scss';

const propTypes = {
  file: PropTypes.oneOfType([BusinessFilePropType, LocalFilePropType]),
  onDeleteFile: PropTypes.func,
  onEditFile: PropTypes.func,
};

const defaultProps = {
  file: {},
  onDeleteFile: () => {},
  onEditFile: () => {},
};

export default function FileListItem({ file, onDeleteFile, onEditFile }) {
  const fileTooLarge = exceedsMaxFileSize(file);

  const { uploadProgress, uploadStatus, uploadStatusMessage } = file;
  const showProgress = uploadProgress && uploadProgress < 100;
  const showSuccess = uploadStatus === FILE_UPLOAD_SUCCESS_STATUS;
  const showError = uploadStatus === FILE_UPLOAD_ERROR_STATUS;

  return (
    <ListItem className="file-list-item">
      <div className="file-list-item-image" style={{ background: `url(${file.url})` }}>
        {fileTooLarge && (
          <Popup
            content="File is too large to upload"
            trigger={<Label color="red" corner="left" icon="warning sign" />}
          />
        )}
      </div>
      <ListContent>
        <ListHeader as="a" onClick={onEditFile} disabled={uploadProgress}>
          {file.name || file.filename}
        </ListHeader>
        <ListDescription>
          <Label size="tiny">{getFormattedLocalFileSize(file)}</Label>
        </ListDescription>
      </ListContent>
      <ListContent>
        {showSuccess && (
          <Label color="green" size="small" className="status-icon" basic>
            <Icon name="check circle outline" />
            {uploadStatusMessage}
          </Label>
        )}
        {showError && (
          <Label color="red" size="small" className="status-icon" basic label={uploadStatusMessage}>
            <Icon name="times circle outline" />
            {uploadStatusMessage}
          </Label>
        )}
        {showProgress && (
          <Progress
            percent={uploadProgress}
            size="small"
            className="file-list-item-progress"
            error={uploadStatus === FILE_UPLOAD_ERROR_STATUS}
            success={uploadStatus === FILE_UPLOAD_SUCCESS_STATUS}
            color="green"
          >
            {uploadStatusMessage}
          </Progress>
        )}
        {!uploadProgress && (
          <>
            <Button basic color="red" onClick={onDeleteFile}>
              Delete
            </Button>
            <Button basic onClick={onEditFile}>
              Edit
            </Button>
          </>
        )}
      </ListContent>
    </ListItem>
  );
}

FileListItem.propTypes = propTypes;
FileListItem.defaultProps = defaultProps;
