import React from 'react';

import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import Utils from '../../libs/utils';

const WithLoading = (WrappedComponent) => {
  const WithLoadingComponent = ({ isLoading, loaderProps, ...passThroughProps }) => {
    if (!isLoading) {
      return <WrappedComponent {...passThroughProps} />;
    }
    return <Loader {...loaderProps} />;
  };

  WithLoadingComponent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    loaderProps: PropTypes.shape({}),
  };
  WithLoadingComponent.defaultProps = {
    loaderProps: {
      active: true,
      inline: 'centered',
    },
  };

  WithLoadingComponent.displayName = `WithLoadingComponent(${Utils.getDisplayName(
    WrappedComponent,
  )})`;

  return WithLoadingComponent;
};

export default WithLoading;
