import React from 'react';

const defaultContext = {
  selectedOrderIds: [],
  selectOrderId: null,
  deselectOrderId: null,
  isOrderIdSelected: null,
};

export const SelectedOrdersContext = React.createContext(defaultContext);
export const SelectedOrdersContextProvider = SelectedOrdersContext.Provider;
