import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { ADVANCED_SEO_FEATURES_RECIPES } from './EditPageModal.constants';
import {
  getDescriptionRequirements,
  getFormErrors,
  getInitialData,
  getModalHeader,
  getNavigationPriority,
  getPageTileOptions,
  getSubmitButtonText,
  getTitleRequirements,
  isAsButtonDisabled,
} from './EditPageModal.utils';
import { flagBusinessSaved } from '../../../../../actions/business';
import ErrorHandler from '../../../../../libs/errors/errors';
import { selectBusinessName } from '../../../../../selectors/business';
import CloseableModal from '../../../../common/CloseableModal';
import UrlField from '../../../../common/UrlField/UrlField';
import useRecipe from '../../../foundation/hooks/use-recipe';
import { isCustomPage } from '../../../pages/services/pages';
import { PagePropType } from '../../../pages/types';
import SeoMetadataFields from '../../../seo/components/SeoMetadataFields';
import { createSeoTitlePlaceholder } from '../../../seo/components/SeoMetadataFields/components/SeoTitle/SeoTitle.utils';
import { NavigationContext } from '../../context/Navigation.context';
import DeletePageModal from '../DeletePageModal';

import './EditPageModal.scss';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  page: PagePropType,
  external: PropTypes.bool,
  add: PropTypes.bool,
};
const defaultProps = {
  external: false,
  add: false,
  page: null,
};

export default function EditPageModal({ open, onClose: onCloseProp, page, external, add }) {
  const dispatch = useDispatch();

  const { navigation } = useContext(NavigationContext);
  const {
    all: pages,
    buttonPages,
    nonButtonPages,
    addExternalPage,
    updatePage,
    reOrderNavigationPriority,
    getPageUrl,
  } = navigation;

  const { websiteUsesRecipe } = useRecipe();
  const businessName = useSelector(selectBusinessName);

  const initialData = getInitialData(add, page);
  const { asButton: initialAsButton } = initialData;
  const [formData, setFormData] = useState(initialData);
  const [formErrors, setFormErrors] = useState({});
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const { pageType } = page || {};
  const { title, seoTitle, asButton, url, description } = formData || {};

  const advancedSeoFeaturesEnabled =
    websiteUsesRecipe(ADVANCED_SEO_FEATURES_RECIPES) || isCustomPage(page);

  function reset() {
    setFormData(initialData);
    setFormErrors({});
    setTouched(false);
  }
  useEffect(() => {
    reset();
  }, [open]);

  useEffect(() => {
    setFormErrors(getFormErrors(formData, external));
  }, [formData]);

  function onChange(event, { name, value }) {
    setFormData({ ...formData, [name]: value });
  }

  function onChangeCheckbox(event, { name, checked }) {
    setFormData({ ...formData, [name]: checked });
  }

  function onClose() {
    reset();
    onCloseProp();
  }

  async function onSubmit() {
    setTouched(true);
    if (!_isEmpty(formErrors)) {
      return;
    }

    try {
      setLoading(true);

      const metadata = asButton ? { as: 'Button' } : {};

      const navigationPriority = getNavigationPriority(
        page,
        initialAsButton,
        asButton,
        pages,
        nonButtonPages,
      );

      const payload = {
        title,
        url,
        metadata,
        description,
        navigationPriority,
        seoTitle,
      };

      if (external && add) {
        await addExternalPage(payload);
      } else if (!add && !_isEmpty(page)) {
        await updatePage(page, payload);
        if (asButton !== initialAsButton) {
          await reOrderNavigationPriority(navigationPriority, { avoidIds: [page.id] });
        }
      }

      setLoading(false);
      dispatch(flagBusinessSaved(true));
      onClose();
    } catch (e) {
      setLoading(false);
      ErrorHandler.capture(e);
    }
  }

  function getFieldError(field) {
    if (!touched) {
      return '';
    }
    return formErrors[field] || '';
  }

  const titleOptions = getPageTileOptions(title, pages);
  const header = getModalHeader(external, add);
  const validUrl = !getFieldError('url');

  const disableAsButtonButton = isAsButtonDisabled(buttonPages, page, initialAsButton);
  const disableDeleteButton = loading || add || !external;

  return (
    <CloseableModal
      open={open}
      onClose={onClose}
      className="navigation-edit-page-modal"
      header={header}
      size="small"
      onOpen={reset}
    >
      <Modal.Content scrolling>
        <Form>
          <Form.Field
            label="Link Title"
            name="title"
            value={title}
            placeholder="Select a title"
            onChange={onChange}
            error={!!getFieldError('title')}
            required
            control={add ? Form.Select : Form.Input}
            {...(add ? { options: titleOptions, search: true, allowAdditions: true } : {})}
          />

          <Form.Checkbox
            className="secondary-navy"
            label="Render as a button on the navigation bar"
            name="asButton"
            checked={asButton}
            onChange={onChangeCheckbox}
            disabled={disableAsButtonButton}
          />

          {!external && advancedSeoFeaturesEnabled && (
            <SeoMetadataFields onChange={onChange} pageType={pageType}>
              <SeoMetadataFields.SeoTitle
                showWarnings
                title={seoTitle}
                additionalRequirements={getTitleRequirements({ title })}
                placeholder={createSeoTitlePlaceholder(title, businessName)}
              />
              <SeoMetadataFields.SeoDescription
                showWarnings
                description={description}
                additionalRequirements={getDescriptionRequirements({ title })}
                name="description"
              />
            </SeoMetadataFields>
          )}

          <Form.Field
            error={!validUrl}
            required
            label="URL"
            value={external ? url : getPageUrl(page).href}
            onChange={onChange}
            control={UrlField}
            unwrapped
            className="url-dark-navy"
            disabled={!external}
          />

          {!external && !advancedSeoFeaturesEnabled && (
            <Form.TextArea
              label="Description (optional)"
              name="description"
              value={description}
              onChange={onChange}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <DeletePageModal page={page} disabled={disableDeleteButton} />
        <Button
          content="CANCEL"
          className="action-button cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          content={getSubmitButtonText(add)}
          className="add secondary-navy"
          onClick={onSubmit}
          disabled={loading}
          loading={loading}
        />
      </Modal.Actions>
    </CloseableModal>
  );
}

EditPageModal.propTypes = propTypes;
EditPageModal.defaultProps = defaultProps;
