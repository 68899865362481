import React, { useState } from 'react';

import { Loader } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import CuratedHeroForm from './components/CuratedHeroForm';
import CuratedHeroHeaderSettings from './components/CuratedHeroHeaderSettings/CuratedHeroHeaderSettings';
import { CuratedHeroProvider } from '../../../../modules/custom-pages/contexts/CuratedHero.context';
import useRecipe from '../../../../modules/foundation/hooks/use-recipe/use-recipe';
import useCuratedHero from '../../../../modules/hero/hooks/use-curated-hero/useCuratedHero';

import './CuratedHeroTab.scss';

const propTypes = {};

const defaultProps = {};

export default function CuratedHeroTab() {
  const { activeRecipeName: recipeName } = useRecipe();
  const [isFormTouched, setIsFormTouched] = useState(false);

  const {
    name,
    onChangeName,
    enabled,
    onChangeEnabled,
    getComponentConfigurationSettings,
    getComponentConfiguration,
    updateComponentConfiguration,
    isLoading: isLoadingComponentConfigurations,
    isSubmitting,
    saveCuratedHero,
    childConfigurations,
  } = useCuratedHero(recipeName);

  const componentConfigurationSettings = getComponentConfigurationSettings();

  async function onSave() {
    await saveCuratedHero();
    setIsFormTouched(false);
  }

  function updateHeroComponentConfiguration(id, path, value) {
    updateComponentConfiguration(id, path, value);
    setIsFormTouched(true);
  }

  function onCuratedHeroOptionChange(newName) {
    onChangeName(newName);
    setIsFormTouched(true);
  }

  function onCuratedHeroEnabledChange(newEnabled) {
    onChangeEnabled(newEnabled);
    setIsFormTouched(true);
  }

  const context = {
    recipeName,
    name,
    onChangeName: onCuratedHeroOptionChange,
    enabled,
    onChangeEnabled: onCuratedHeroEnabledChange,
    getComponentConfiguration,
    childConfigurations,
    updateComponentConfiguration: updateHeroComponentConfiguration,
    isSubmitting,
    isFormTouched,
  };

  return (
    <CuratedHeroProvider value={context}>
      <div className="curated-hero-tab">
        <CuratedHeroHeaderSettings />
        {isLoadingComponentConfigurations && <Loader active />}
        {!isLoadingComponentConfigurations && !_isNil(name) && !_isEmpty(name) && (
          <CuratedHeroForm heroComponentOptions={componentConfigurationSettings} onSave={onSave} />
        )}
      </div>
    </CuratedHeroProvider>
  );
}

CuratedHeroTab.propTypes = propTypes;
CuratedHeroTab.defaultProps = defaultProps;
