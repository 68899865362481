/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable import/no-webpack-loader-syntax */

import 'ace-builds';

const ace = require('ace-builds/src-min-noconflict/ace');

export function initializeAce() {
  ace.config.setModuleUrl(
    'ace/mode/css',
    require('file-loader?esModule=false!ace-builds/src-noconflict/mode-css.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/html',
    require('file-loader?esModule=false!ace-builds/src-noconflict/mode-html.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/javascript',
    require('file-loader?esModule=false!ace-builds/src-noconflict/mode-javascript.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/json',
    require('file-loader?esModule=false!ace-builds/src-noconflict/mode-json.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/jsx',
    require('file-loader?esModule=false!ace-builds/src-noconflict/mode-jsx.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/markdown',
    require('file-loader?esModule=false!ace-builds/src-noconflict/mode-markdown.js'),
  );

  ace.config.setModuleUrl(
    'ace/theme/solarized_dark-css',
    require('file-loader?esModule=false!ace-builds/src-noconflict/theme-solarized_dark-css.js'),
  );
  ace.config.setModuleUrl(
    'ace/theme/solarized_dark',
    require('file-loader?esModule=false!ace-builds/src-noconflict/theme-solarized_dark.js'),
  );
  ace.config.setModuleUrl(
    'ace/theme/solarized_light-css',
    require('file-loader?esModule=false!ace-builds/src-noconflict/theme-solarized_light-css.js'),
  );
  ace.config.setModuleUrl(
    'ace/theme/solarized_light',
    require('file-loader?esModule=false!ace-builds/src-noconflict/theme-solarized_light.js'),
  );

  ace.config.setModuleUrl(
    'ace/mode/css_worker',
    require('file-loader?esModule=false!ace-builds/src-noconflict/worker-css.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/html_worker',
    require('file-loader?esModule=false!ace-builds/src-noconflict/worker-html.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/javascript_worker',
    require('file-loader?esModule=false!ace-builds/src-noconflict/worker-javascript.js'),
  );
  ace.config.setModuleUrl(
    'ace/mode/json_worker',
    require('file-loader?esModule=false!ace-builds/src-noconflict/worker-json.js'),
  );
}
