import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

function TeamMemberLocationField({ currentLocations, availableLocations, onUpdateLocations }) {
  function getOptions() {
    return (availableLocations || [])
      .filter((tag) => !!tag)
      .map((tag) => ({ key: tag, text: tag, value: tag }));
  }

  function handleChange(e, { value: selectedLocations }) {
    onUpdateLocations(selectedLocations);
  }

  function search(options, query) {
    const regex = new RegExp(query, 'i');
    return options.filter((opt) => regex.test(opt.text));
  }

  const options = getOptions();

  return (
    <Dropdown
      allowAdditions
      fluid
      multiple
      selection
      search={search}
      options={options}
      value={currentLocations}
      placeholder="Enter a location"
      onChange={handleChange}
    />
  );
}

TeamMemberLocationField.propTypes = {
  currentLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableLocations: PropTypes.arrayOf(PropTypes.string),
  onUpdateLocations: PropTypes.func,
};

TeamMemberLocationField.defaultProps = {
  availableLocations: [],
  onUpdateLocations: () => null,
};

export default TeamMemberLocationField;
