import PropTypes from 'prop-types';

export const reportsCustomerPropType = PropTypes.shape({
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  total_spent: PropTypes.string.isRequired,
  total_refunded: PropTypes.string,
  tips_total: PropTypes.string.isRequired,
  taxes_totals: PropTypes.string.isRequired,
  subtotals: PropTypes.string.isRequired,
  fees_total: PropTypes.string.isRequired,
  avg_total: PropTypes.string.isRequired,
  total_orders: PropTypes.number.isRequired,
});
