import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Accordion, Button, Grid, Icon } from 'semantic-ui-react';

import Markdown from 'markdown-to-jsx';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import Dots from '../../../../common/sortables/Dots';
import { CatalogCategoryPropType } from '../../../../modules/catalog/proptypes/catalog';
import EditMenuCategory from '../../category/EditMenuCategory';
import MenuCategory from '../../category/MenuCategory';

import './SortableScheduleCategory.scss';

const DragHandle = SortableHandle(() => <Dots size="5px" />);

const SortableMenuCategory = SortableElement(({ children }) => children);

const propTypes = {
  category: CatalogCategoryPropType.isRequired,
  localIndex: PropTypes.number.isRequired,
  scheduleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scheduleIndex: PropTypes.number.isRequired,
  toggleAccordionIndexOpen: PropTypes.func.isRequired,
  showDragHandle: PropTypes.bool,
  active: PropTypes.bool,
};

const defaultProps = {
  showDragHandle: true,
  active: false,
};

export default function SortableScheduleCategory({
  category,
  localIndex,
  scheduleId,
  scheduleIndex,
  toggleAccordionIndexOpen,
  showDragHandle,
  active,
}) {
  const { id: categoryId } = category;

  const isNew = !categoryId;
  const [open, setOpen] = useState(!categoryId);
  const finalOpen = isNew || open;

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  function onTitleClick(e, { index }) {
    toggleAccordionIndexOpen(index);
  }

  return (
    <SortableMenuCategory key={`sortable_category_${category.id}`} index={localIndex}>
      <Accordion.Title
        className="sortable-schedule-category"
        active={active}
        index={localIndex}
        onClick={onTitleClick}
      >
        <Grid verticalAlign="middle">
          <Grid.Column width={1}>
            {showDragHandle && <DragHandle />}
            {!showDragHandle && <Icon name="dropdown" size="big" />}
          </Grid.Column>
          <Grid.Column width={14}>
            <h3 className="category-name">{category.name}</h3>
            {category.description && (
              <Markdown className="category-description markdown">
                {category.description || ''}
              </Markdown>
            )}
          </Grid.Column>
          <Grid.Column width={1} onClick={(e) => e.stopPropagation()}>
            <>
              {finalOpen && (
                <EditMenuCategory
                  initialCategory={category}
                  categoryIndex={category.globalIndex}
                  scheduleId={scheduleId}
                  scheduleIndex={scheduleIndex}
                  open={finalOpen}
                  onClose={closeModal}
                  onOpen={openModal}
                />
              )}
              <Button onClick={openModal} className="edit">
                Edit
              </Button>
            </>
          </Grid.Column>
        </Grid>
      </Accordion.Title>
      <Accordion.Content active={active}>
        <MenuCategory
          key={`${scheduleId} - ${category.id || category.localId}`}
          categoryId={category.id}
          categoryIndex={category.globalIndex}
        />
      </Accordion.Content>
    </SortableMenuCategory>
  );
}

SortableScheduleCategory.propTypes = propTypes;
SortableScheduleCategory.defaultProps = defaultProps;
