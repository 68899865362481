import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Accordion, Header, Icon } from 'semantic-ui-react';

import LocationSettingForm from './components/LocationSettingForm';

import './LocationSetting.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  locationIndex: PropTypes.number.isRequired,
};

function LocationSetting({ title, locationIndex }) {
  const [activeIndex, setActiveIndex] = useState(locationIndex);

  function handleClick(e, titleProps) {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  }

  return (
    <div className="location-setting-container">
      <Accordion.Title
        active={activeIndex === locationIndex}
        index={locationIndex}
        onClick={handleClick}
        className="accordion-title-container"
      >
        <Header as="h4" className="accordion-title">
          {title}
        </Header>
        <Icon name="minus circle" className="accordion-delete" size="large" />
      </Accordion.Title>
      <Accordion.Content
        className="accordion-content-container"
        active={activeIndex === locationIndex}
      >
        <LocationSettingForm locationIndex={locationIndex} />
      </Accordion.Content>
    </div>
  );
}

LocationSetting.propTypes = propTypes;

export default LocationSetting;
