import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox, Form } from 'semantic-ui-react';

import { SHOW_OPT_IN_KEY, REQUIRED_KEY, OPT_IN_KEY } from './FormOptIn.constants';
import { getConsentSettingsConfig } from './FormOptIn.utils';
import HelpTooltip from '../../../../../../../../common/HelpTooltip';

import './FormOptIn.scss';

const propTypes = {
  show: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  show: true,
  required: false,
};

export default function FormOptIn({ show, required, onChange: onChangeProp }) {
  function onChange(name, checked) {
    const consentSettings = getConsentSettingsConfig(name, checked, show, required);
    onChangeProp(OPT_IN_KEY, consentSettings);
  }

  return (
    <div className="form-element opt-in">
      <label htmlFor="form-element-opt-in-input">Mailing List Opt-In</label>
      <HelpTooltip
        title="Mailing List Opt-In"
        description="This will allow you to add a checkbox to your form that allows users to opt-in to your mailing list."
      />
      <Form.Group widths="equal" className="opt-in-field-container">
        <Form.Field>
          <Checkbox
            id="form-element-opt-in-input"
            toggle
            checked={show}
            label="Show"
            onChange={(e, { checked }) => onChange(SHOW_OPT_IN_KEY, checked)}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            id="form-element-opt-in-required"
            checked={!show ? false : required}
            onChange={(e, { checked }) => onChange(REQUIRED_KEY, checked)}
            label="Require"
            toggle
            disabled={!show}
          />
        </Form.Field>
      </Form.Group>
    </div>
  );
}

FormOptIn.propTypes = propTypes;
FormOptIn.defaultProps = defaultProps;
