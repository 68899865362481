import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import Dots from '../../../../../../common/sortables/Dots';
import { isHomePage } from '../../../../../pages/services/pages';
import { PagePropType } from '../../../../../pages/types/page';
import NavigationItemEdit from '../NavigationItemEdit';
import NavigationItemTitle from '../NavigationItemTitle';
import NavigationItemUrlCheck from '../NavigationItemUrlCheck';

import './NavigationItem.scss';

const propTypes = {
  page: PagePropType.isRequired,
  hideDragHandle: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};
const defaultProps = {
  hideDragHandle: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

const DragHandle = SortableHandle(() => <Dots size="5px" />);

export default function NavigationItem({ page, hideDragHandle, onMouseEnter, onMouseLeave }) {
  const { description } = page;

  const isHome = isHomePage(page);

  return (
    <div
      className="navigation-item"
      onMouseEnter={onMouseEnter}
      onTouchStart={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchEnd={onMouseLeave}
    >
      <Grid columns={4} stackable>
        <Grid.Column width={1} textAlign="center" verticalAlign="middle">
          {!isHome && !hideDragHandle && <DragHandle />}
        </Grid.Column>
        <Grid.Column width={6} className="title-column" verticalAlign="middle">
          <NavigationItemTitle page={page} />
        </Grid.Column>
        <Grid.Column width={6} className="description-column" verticalAlign="middle">
          <div className="description"> {description}</div>
        </Grid.Column>
        <Grid.Column width={2} textAlign="right" verticalAlign="middle">
          <NavigationItemUrlCheck page={page} />
        </Grid.Column>
        <Grid.Column width={1} textAlign="center" verticalAlign="middle">
          <NavigationItemEdit page={page} />
        </Grid.Column>
      </Grid>
    </div>
  );
}

NavigationItem.propTypes = propTypes;
NavigationItem.defaultProps = defaultProps;

const SortableNavigationItem = SortableElement(NavigationItem);
export { SortableNavigationItem };
