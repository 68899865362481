import _get from 'lodash/get';

import {
  FULFILLMENT_TYPE_INSTORE,
  FULFILLMENT_TYPE_SHIPPING,
} from '../../../../../../../constants/fulfillments';
import {
  ACCEPT_ORDERS_UNTIL,
  MINIMUM_FULFILLMENT_PRICE,
  TURNAROUND_TIME,
  ORDER_TURNAROUND_TIME,
  TURNAROUND_TIME_UNIT,
  DISCOUNTS,
} from '../../OrderSettings.constants';

const FULFILLMENT_TYPE_TO_EXCLUDED_FIELDS_MAP = {
  [FULFILLMENT_TYPE_INSTORE]: [
    MINIMUM_FULFILLMENT_PRICE,
    TURNAROUND_TIME,
    TURNAROUND_TIME_UNIT,
    DISCOUNTS,
  ],
  [FULFILLMENT_TYPE_SHIPPING]: [
    MINIMUM_FULFILLMENT_PRICE,
    ORDER_TURNAROUND_TIME,
    ACCEPT_ORDERS_UNTIL,
  ],
};

export function showFieldForFulfillmentType(fulfillmentType, fieldName) {
  const excludedFieldsForType = _get(FULFILLMENT_TYPE_TO_EXCLUDED_FIELDS_MAP, fulfillmentType, []);
  return !excludedFieldsForType.includes(fieldName);
}
