export const FILTER_OPTIONS = [
  {
    key: 'live_website',
    label: 'Live Websites',
    defaultValue: false,
  },
  {
    key: 'preview_website',
    label: 'Preview Websites',
    defaultValue: false,
  },
  {
    key: 'next_website',
    label: 'Next Website Versions Only',
    defaultValue: false,
  },
  {
    key: 'v_1',
    label: 'Version 1 Websites',
    defaultValue: true,
  },
  {
    key: 'v_legacy',
    label: 'Legacy Websites',
    defaultValue: true,
  },
];
