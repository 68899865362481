import { DEFAULT_PAGE_NAME, DEFAULT_PAGE_SLUG, DEFAULT_PAGE_LAYOUT } from './constants';
import { PAGE_ELEMENT_TYPE } from '../../../../constants/types';
import {
  translateChildren,
  lookupElementType,
  lookupElementTypeByComponentName,
} from '../utils/children';
import { createPage } from '../utils/pages';

export function createPageElement(data = {}, context = {}) {
  const {
    name = DEFAULT_PAGE_NAME,
    layout = DEFAULT_PAGE_LAYOUT,
    slug = DEFAULT_PAGE_SLUG,
    components = [],
    style = '',
  } = data;
  const { recipeStateToSnippet } = context;

  const pageComponents = translateChildren(components, recipeStateToSnippet, lookupElementType, {
    context,
  });

  return createPage(name, slug, layout, pageComponents, style);
}

export function createPageState(recipeSnippet = {}, context = {}) {
  const {
    name = DEFAULT_PAGE_NAME,
    layout = DEFAULT_PAGE_LAYOUT,
    slug = DEFAULT_PAGE_SLUG,
    components = [],
    style = '',
  } = recipeSnippet || {};
  const { recipeSnippetToState } = context;

  const type = PAGE_ELEMENT_TYPE;

  const componentStates = translateChildren(
    components,
    recipeSnippetToState,
    lookupElementTypeByComponentName,
    { context },
  );

  return {
    type,
    name,
    layout,
    slug,
    components: componentStates,
    style,
  };
}

export function createPageTemplateState(data = {}, context = {}) {
  const { recipeStateToTemplateState } = context;
  const { components = [], ...restPage } = data;

  const componentStates = translateChildren(
    components,
    recipeStateToTemplateState,
    lookupElementType,
    { context },
  );

  return {
    ...restPage,
    components: componentStates,
  };
}
