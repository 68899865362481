export const SEMANTIC_BUTTON_PROPS = [
  'active',
  'as',
  'basic',
  'circular',
  'className',
  'content',
  'disabled',
  'fluid',
  'icon',
  'label',
  'labelPosition',
  'loading',
  'onClick',
  'onMouseEnter',
  'onMouseLeave',
  'primary',
  'role',
  'secondary',
  'size',
  'tabIndex',
  'type',
];

export const SEMANTIC_MENU_PROPS = [
  'text',
  'vertical',
  'compact',
  'fitted',
  'borderless',
  'fluid',
  'className',
  'as',
  'activeIndex',
  'pagination',
  'secondary',
  'floated',
];

export const SEMANTIC_MENU_ITEM_PROPS = [
  'active',
  'as',
  'className',
  'content',
  'disabled',
  'header',
  'icon',
  'index',
  'link',
  'onClick',
  'position',
];

export const SEMANTIC_GRID_COLUMN_PROPS = [
  'children',
  'className',
  'color',
  'computer',
  'floated',
  'largeScreen',
  'mobile',
  'only',
  'stretched',
  'tablet',
  'textAlign',
  'verticalAlign',
  'widescreen',
  'width',
];

export const SEMANTIC_INPUT_PROPS = [
  'children',
  'className',
  'disabled',
  'error',
  'fluid',
  'focus',
  'icon',
  'iconPosition',
  'label',
  'labelPosition',
  'onChange',
  'size',
  'name',
  'type',
  'tabIndex',
  'transparent',
];

export const SEMANTIC_ACCORDION_PROPS = ['children', 'fluid', 'inverted', 'styled', 'className'];
export const SEMANTIC_ACCORDION_CONTENT_PROPS = [
  'active',
  'as',
  'children',
  'content',
  'className',
];

export const SEMANTIC_ICON_PROPS = [
  'as',
  'bordered',
  'circular',
  'className',
  'color',
  'corner',
  'disabled',
  'fitted',
  'flipped',
  'inverted',
  'link',
  'loading',
  'name',
  'rotated',
  'size',
];

export const SEMANTIC_MESSAGE_PROPS = [
  'as',
  'attached',
  'children',
  'className',
  'color',
  'compact',
  'content',
  'error',
  'floating',
  'header',
  'hidden',
  'icon',
  'info',
  'list',
  'negative',
  'onDismiss',
  'positive',
  'size',
  'visible',
  'warning',
];

export const SEMANTIC_MODAL_PROPS = [
  'actions',
  'as',
  'basic',
  'centered',
  'children',
  'className',
  'closeIcon',
  'closeOnDimmerClick',
  'closeOnDocumentClick',
  'content',
  'confirmButton',
  'cancelButton',
  'defaultOpen',
  'dimmer',
  'eventPool',
  'header',
  'mountNode',
  'onActionClick',
  'onCancel',
  'onClose',
  'onConfirm',
  'onMount',
  'onOpen',
  'onUnmount',
  'open',
  'size',
  'style',
  'trigger',
  'animation',
];
