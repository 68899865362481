/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from 'react';

import { Breadcrumb, Dropdown } from 'semantic-ui-react';

import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';

import { PAGE_NODE_INDEX } from './RecipeNavigation.constants';
import {
  mapNodeToComponent,
  getRootNavigationOptions,
  getChildNavigationOptions,
  getActiveRecipeNavigation,
} from './RecipeNavigation.util';
import NodeControls from './components/NodeControls';
import { PAGES_KEY, UNRESOLVED_INDEX } from '../../constants';
import { RecipeContext } from '../../context/RecipeContext';
import { getPageIndexByName } from '../../services/recipe-utils';

import './RecipeNavigation.scss';

const RecipeNavigation = () => {
  const [activeRootObjectIndex, setActiveRootObjectIndex] = useState(PAGE_NODE_INDEX);
  const [activeChildObjectIndex, setActiveChildObjectIndex] = useState(0);
  const [selectedNodePath, setSelectedNodePath] = useState([]);
  const { recipe, setActiveNode } = useContext(RecipeContext);
  const rootNavigationOptions = getRootNavigationOptions(recipe);
  let childKey = null;
  let rootKey = null;

  function handleRootNavigationChange(key) {
    const index = _findIndex(rootNavigationOptions, { value: key });

    if (index === UNRESOLVED_INDEX) {
      return;
    }

    setActiveRootObjectIndex(index);
    setActiveChildObjectIndex(0);
  }

  function handleChildNavigationChange(childNavigationOptions, key) {
    const index = _findIndex(childNavigationOptions, { value: key });

    if (index === UNRESOLVED_INDEX) {
      return;
    }

    setActiveChildObjectIndex(index);
  }

  function activateRecipePartRootNode() {
    setActiveNode({
      objectKey: childKey,
      parentId: recipe[rootKey][childKey].__id__,
      value: recipe[rootKey][childKey],
    });
  }

  useEffect(() => {
    if (!_isEmpty(recipe)) {
      activateRecipePartRootNode();
    }
  }, [activeRootObjectIndex, activeChildObjectIndex]);

  function getActiveNavigationNode(childNavigationOptions) {
    rootKey = rootNavigationOptions[activeRootObjectIndex].value;

    if (rootNavigationOptions[activeRootObjectIndex].value === PAGES_KEY) {
      childKey =
        getPageIndexByName(recipe, childNavigationOptions[activeChildObjectIndex].value) + 1; // +1 because metadata is in index 0
    } else {
      childKey = childNavigationOptions[activeChildObjectIndex].value;
    }

    return getActiveRecipeNavigation(recipe, rootKey, childKey);
  }

  function handleSelectedNodePath(e, { result: { value } }) {
    if (value) {
      setSelectedNodePath(value.split('.'));
    } else {
      setSelectedNodePath([]);
    }
  }

  if (_isEmpty(recipe) || _isEmpty(rootNavigationOptions)) {
    return null;
  }

  const childNavigationOptions = getChildNavigationOptions(
    recipe,
    rootNavigationOptions[activeRootObjectIndex].value,
  );

  const activeNavigationNode = getActiveNavigationNode(childNavigationOptions);

  return (
    <div className="recipe-navigation-container">
      <div className="recipe-breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Section className="breadcrumb-section root-navigation" link>
            <Dropdown
              inline
              options={rootNavigationOptions}
              onChange={(e) => handleRootNavigationChange(e.target.textContent)}
              defaultValue={rootNavigationOptions[activeRootObjectIndex].value}
            />
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section className="breadcrumb-section child-navigation" active>
            <Dropdown
              key={childNavigationOptions[activeChildObjectIndex].value}
              inline
              options={childNavigationOptions}
              onChange={(e) =>
                handleChildNavigationChange(childNavigationOptions, e.target.textContent)
              }
              defaultValue={childNavigationOptions[activeChildObjectIndex].value}
            />
          </Breadcrumb.Section>
        </Breadcrumb>
      </div>

      <NodeControls
        recipePartial={activeNavigationNode}
        selectedNodePath={selectedNodePath}
        setSelectedNodePath={handleSelectedNodePath}
      />

      {mapNodeToComponent(activeNavigationNode, selectedNodePath)}
    </div>
  );
};

export default RecipeNavigation;
