import React, { useContext, useEffect } from 'react';

import { Grid } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import useAsyncEffect from '../../../foundation/hooks/use-async-effect';
import { TypographyContext } from '../../contexts/Typography.context';
import { filterFontsByCategories, getGoogleFonts, searchFonts } from '../../services/font';
import CategorySelector from '../CategorySelector/CategorySelector';
import FamilyBreakdown from '../FamilyBreakdown';
import FontWeightSlider from '../FontWeightSlider';
import ResetControlsButton from '../ResetControlsButton';
import SearchFonts from '../SearchFonts';
import SentenceInput from '../SentenceInput';
import SortMechanismSelector from '../SortMechanismSelector';

import './TypographyControls.scss';

const propTypes = {};

const defaultProps = {};

export default function TypographyControls() {
  const { setFonts, allFonts, setAllFonts, searchQuery, selectedFont, categories, sort } =
    useContext(TypographyContext);

  useAsyncEffect(async () => {
    const googleFonts = await getGoogleFonts({ sort });
    setAllFonts(googleFonts);
  }, [sort]);

  useEffect(() => {
    let filteredFonts = searchFonts(allFonts, searchQuery);
    filteredFonts = filterFontsByCategories(filteredFonts, categories);
    setFonts(filteredFonts);
  }, [searchQuery, categories, allFonts]);

  if (_isEmpty(allFonts)) {
    return null;
  }

  return (
    <div className="typography-controls">
      <Grid className="first-controls" stackable>
        {_isEmpty(selectedFont) && (
          <Grid.Column width={5}>
            <SearchFonts />
          </Grid.Column>
        )}
        <Grid.Column width={5}>
          <SentenceInput />
        </Grid.Column>
        <Grid.Column width={5}>
          <FontWeightSlider />
        </Grid.Column>
        {_isEmpty(selectedFont) && (
          <Grid.Column width={1}>
            <ResetControlsButton />
          </Grid.Column>
        )}
      </Grid>

      {_isEmpty(selectedFont) && (
        <Grid className="second-controls" stackable>
          <Grid.Column width={3}>
            <FamilyBreakdown />
          </Grid.Column>
          <Grid.Column width={4}>
            <CategorySelector />
          </Grid.Column>
          <Grid.Column width={9} className="sort-column">
            <SortMechanismSelector />
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
}

TypographyControls.propTypes = propTypes;
TypographyControls.defaultProps = defaultProps;
