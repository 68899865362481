import { NEW_VALUE_PREFIX } from './FormDestinationEmail.constants';
import Validate from '../../../../../../../../../libs/validate';

export function dataToOptions(data) {
  return data.map(({ destinationEmail }) => ({
    title: destinationEmail,
  }));
}

export function isValidEmail(value) {
  return Validate.validate('email', value);
}

export function isAnOption(value, options) {
  return options.some(({ title }) => title === value);
}

export function newValueToOption(value) {
  return { title: `${NEW_VALUE_PREFIX} ${value}` };
}

export function optionToValue(option) {
  const { title } = option;
  return title.replace(new RegExp(`^${NEW_VALUE_PREFIX} `), '');
}
