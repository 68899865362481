import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const CustomerContactSearch = ({ filterValue, onFilterCustomer, loading }) => {
  return (
    <Input
      placeholder="Search customers ..."
      value={filterValue}
      onChange={onFilterCustomer}
      loading={loading}
    />
  );
};

export default CustomerContactSearch;

CustomerContactSearch.propTypes = {
  filterValue: PropTypes.string,
  onFilterCustomer: PropTypes.func,
  loading: PropTypes.bool,
};

CustomerContactSearch.defaultProps = {
  filterValue: '',
  onFilterCustomer: () => null,
  loading: false,
};
