import BBotLogo from '../static/logos/Bbot_Text_Logo.png';
import ChowNowLogo from '../static/logos/chownow-logo.png';
import CloverLogo from '../static/logos/clover-logo.png';
import ZupplerLogo from '../static/logos/zuppler-logo.jpeg';

export const leadSourceLogoMap = {
  Clover: CloverLogo,
  BBot: BBotLogo,
  ChowNow: ChowNowLogo,
  Zuppler: ZupplerLogo,
};

export const leadSourceLogoHeightMap = {
  Clover: '50px',
  BBot: '50px',
  ChowNow: '50px',
  Zuppler: '50px',
};

export const leadSourceSlugMap = {
  Clover: 'clover',
  BBot: 'bbot',
  ChowNow: 'chownow',
  Zuppler: 'zuppler',
};

export const leadSourceWithRegistration = ['Clover', 'BBot', 'ChowNow', 'Zuppler'];
export const leadSourceWithSummaryPage = ['Clover'];
