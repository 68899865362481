import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { RECIPE_NAME_LABEL, RECIPE_NAME_PLACEHOLDER } from './RecipeNameSelectField.constants';
import { useRecipes } from './RecipeNameSelectField.hooks';
import { createRecipeNameDropdownOptions } from './RecipeNameSelectField.utils';

const propTypes = {
  onRecipeSelected: PropTypes.func.isRequired,
  recipeName: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

const defaultProps = {
  recipeName: '',
  required: false,
  label: RECIPE_NAME_LABEL,
  placeholder: RECIPE_NAME_PLACEHOLDER,
};

export default function RecipeNameSelectField({
  onRecipeSelected,
  recipeName,
  required,
  label,
  placeholder,
}) {
  const { recipes, isLoading } = useRecipes();

  return (
    <Form.Dropdown
      required={required}
      label={label}
      placeholder={placeholder}
      loading={isLoading}
      onChange={onRecipeSelected}
      value={recipeName}
      options={createRecipeNameDropdownOptions(recipes)}
      fluid
      search
      selection
    />
  );
}

RecipeNameSelectField.propTypes = propTypes;
RecipeNameSelectField.defaultProps = defaultProps;
