import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const htmlElementsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id < b.id,
  selectId: (entity) => entity.id || entity.localId,
});

const initialState = htmlElementsAdapter.getInitialState();

const htmlElementsSlice = createSlice({
  name: 'htmlElements',
  initialState,
  reducers: {
    addOneHtmlElement: (state, action) => {
      htmlElementsAdapter.addOne(state, {
        ...action.payload,
      });
    },
    addManyHtmlElements: htmlElementsAdapter.addMany,
    updateOneHtmlElement: htmlElementsAdapter.updateOne,
    removeHtmlElement: htmlElementsAdapter.removeOne,
    setAllHtmlElements: htmlElementsAdapter.setAll,
    handleChangeHtmlElement: (state, action) => {
      const { htmlElement, updates } = action.payload;
      if (htmlElement) {
        if (htmlElement.id || htmlElement.localId) {
          htmlElementsAdapter.updateOne(state, {
            id: htmlElement.id || htmlElement.localId,
            changes: { ...updates },
          });
        } else {
          const localId = uuid();
          htmlElementsAdapter.addOne(state, {
            localId,
            ...updates,
          });
        }
      }
    },
    handleSaveHtmlElement: (state, action) => {
      const { item, data } = action.payload;
      if (item.localId) {
        htmlElementsAdapter.removeOne(state, item.localId);
        htmlElementsAdapter.addOne(state, data);
      } else {
        htmlElementsAdapter.updateOne(state, { id: item.id, changes: data });
      }
    },
  },
});

export const {
  addOneHtmlElement,
  addManyHtmlElements,
  setAllHtmlElements,
  updateOneHtmlElement,
  removeHtmlElement,
  handleChangeHtmlElement,
  handleSaveHtmlElement,
} = htmlElementsSlice.actions;

export default htmlElementsSlice.reducer;

export const {
  selectAll: selectAllHtmlElements,
  selectById: selectHtmlElementById,
  selectIds: selectHtmlElementIds,
} = htmlElementsAdapter.getSelectors((state) => state.htmlElements);
