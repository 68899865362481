import React from 'react';

import PropTypes from 'prop-types';
import { Select, Table } from 'semantic-ui-react';

import { MAPPINGS_HELP_TEXT } from './FormContactMappingSelectors.constants';
import {
  getContactMappingSelectOptions,
  isFieldInContactMapping,
} from './FormContactMappingSelectors.utils';
import HelpTooltip from '../../../../../../../../common/HelpTooltip';
import { INTERNAL_FORM_FIELD_TYPES } from '../../../../../../constants/form';
import {
  FormBlockContactMappingPropType,
  FormFieldPropType,
} from '../../../../../../types/form-block.types';

import './FormContactMappingSelectors.scss';

const propTypes = {
  fields: PropTypes.arrayOf(FormFieldPropType).isRequired,
  contactMapping: FormBlockContactMappingPropType.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function FormContactMappingSelectors({
  fields,
  contactMapping,
  onChange: onChangeProp,
}) {
  const { email = '', name = '', phone = '' } = contactMapping || {};

  function onChange(field, newValue) {
    const newContactMapping = { ...contactMapping, [field]: newValue };
    const newFields = fields.map((currentField) => ({
      ...currentField,
      isFieldContactMapped: isFieldInContactMapping(currentField, newContactMapping),
    }));
    onChangeProp(newFields, newContactMapping);
  }

  const emailOptions = getContactMappingSelectOptions(fields, INTERNAL_FORM_FIELD_TYPES.Email);
  const phoneOptions = getContactMappingSelectOptions(
    fields,
    INTERNAL_FORM_FIELD_TYPES.PhoneNumber,
  );
  const nameOptions = getContactMappingSelectOptions(fields, INTERNAL_FORM_FIELD_TYPES.ShortText);

  return (
    <div className="form-element contact-mapping">
      <label htmlFor="form-element-contact-mapping-input">Contact Mapping</label>
      <HelpTooltip title="Contact Mapping" description={MAPPINGS_HELP_TEXT} />

      <Table className="selectors-container" celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={6}>Contact Field</Table.HeaderCell>
            <Table.HeaderCell width={10}>Form Field</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Name</Table.Cell>
            <Table.Cell textAlign="center">
              <Select
                placeholder="Select name field"
                options={nameOptions}
                value={name}
                onChange={(e, { value: newValue }) => onChange('name', newValue)}
                clearable
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Email</Table.Cell>
            <Table.Cell textAlign="center">
              <Select
                placeholder="Select email field"
                options={emailOptions}
                value={email}
                onChange={(e, { value: newValue }) => onChange('email', newValue)}
                clearable
              />
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Phone</Table.Cell>
            <Table.Cell textAlign="center">
              <Select
                placeholder="Select phone field"
                options={phoneOptions}
                value={phone}
                onChange={(e, { value: newValue }) => onChange('phone', newValue)}
                clearable
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

FormContactMappingSelectors.propTypes = propTypes;
FormContactMappingSelectors.defaultProps = defaultProps;
