import React from 'react';

import { Tab } from 'semantic-ui-react';

import RecipeDirectories from '../RecipeDirectories';

import './RecipeControlPanes.scss';

const panes = [
  {
    menuItem: 'Directories',
    render: () => (
      <Tab.Pane attached={false}>
        <RecipeDirectories />
      </Tab.Pane>
    ),
  },
];

function RecipeControlPanes() {
  return (
    <div className="recipe-control-panes">
      <Tab panes={panes} defaultActiveIndex={0} menu={{ secondary: true, pointing: true }} basic />
    </div>
  );
}

export default RecipeControlPanes;
