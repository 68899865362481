import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Button, Container } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import ConfirmationPage from './ConfirmationPage';
import ZendeskWidget from './ZendeskWidget';
import { zendeskOpen } from './ZendeskWidget/ZendeskWidget.utils';
import API from '../../libs/api';
import { isValidUser } from '../../libs/auth';
import LoginBanner from '../core/LoginBanner';
import WithRouter from '../modules/foundation/components/WithRouter';

const SUCCESS_STATUS = 'success';
const FAILURE_STATUS = 'failure';
const NOTIFIED_STATUS = 'notified';

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  user: _get(state, 'user.core.value', {}),
});

class SummaryPage extends Component {
  getStatus() {
    const { location } = this.props;
    const { status = FAILURE_STATUS } = API.parseSearch(_get(location, 'search', ''));
    return status;
  }

  getContent() {
    const { leadSource } = this.props;
    const status = this.getStatus();

    const contentMap = {
      [['Clover', SUCCESS_STATUS]]: (
        <Container>
          You will need to login to continue editing your website, importing your menu, and setting
          your business hours.
        </Container>
      ),
      [['Clover', FAILURE_STATUS]]: (
        <Container>
          We were unable to install your clover marketplace installation. This was either because of
          a missing Clover ID or because your account was setup incorrectly.
        </Container>
      ),
      [['Clover', NOTIFIED_STATUS]]: (
        <Container>
          Our sales team will reach out to you with a link to preview your new website within 1
          business day.
        </Container>
      ),
    };

    if ([leadSource, status] in contentMap) {
      return contentMap[[leadSource, status]];
    }
    return this.goHome();
  }

  getSubject() {
    const { leadSource } = this.props;
    const status = this.getStatus();

    const subjectMap = {
      [['Clover', SUCCESS_STATUS]]: 'Clover Marketplace App Installed',
      [['Clover', FAILURE_STATUS]]: 'Clover Marketplace App Install Failed',
      [['Clover', NOTIFIED_STATUS]]: 'Your Website is Being Generated',
    };

    if ([leadSource, status] in subjectMap) {
      return subjectMap[[leadSource, status]];
    }
    return this.goHome();
  }

  getIcon() {
    const { leadSource } = this.props;
    const success = this.getStatus();

    const subjectMap = {
      [['Clover', SUCCESS_STATUS]]: 'check',
      [['Clover', FAILURE_STATUS]]: 'ban',
      [['Clover', NOTIFIED_STATUS]]: 'mail',
    };

    if ([leadSource, success] in subjectMap) {
      return subjectMap[[leadSource, success]];
    }
    return this.goHome();
  }

  getActions() {
    const { leadSource, navigate } = this.props;
    const status = this.getStatus();

    const subjectMap = {
      [['Clover', SUCCESS_STATUS]]: (
        <Button primary onClick={() => navigate('/')}>
          Login Now
        </Button>
      ),
      [['Clover', FAILURE_STATUS]]: (
        <Button primary onClick={zendeskOpen}>
          Contact Support
        </Button>
      ),
    };

    if ([leadSource, status] in subjectMap) return subjectMap[[leadSource, status]];
    return <></>;
  }

  goHome() {
    const { navigate } = this.props;
    return navigate('/');
  }

  render() {
    const { user, navigate } = this.props;
    const status = this.getStatus();

    if (isValidUser(user) && status === SUCCESS_STATUS) {
      navigate('/');
    }

    return (
      <div className="login-page">
        <LoginBanner />
        <div className="login-form-container">
          <div className="login-form">
            <ConfirmationPage
              icon={this.getIcon()}
              subject={this.getSubject()}
              content={this.getContent()}
              actionContent={this.getActions()}
            />
          </div>
        </div>
        <ZendeskWidget />
      </div>
    );
  }
}

SummaryPage.propTypes = {
  leadSource: PropTypes.string,
};

SummaryPage.defaultProps = {
  leadSource: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(SummaryPage));
