import React from 'react';

import { Menu, Tab } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';
import { SortableHandle } from 'react-sortable-hoc';

import PlusIcon from './common/PlusIcon';
import { loadMenu } from './libs/menu-helpers';
import MenuSchedule from './schedule/MenuSchedule';
import {
  initializeMenu as initializeMenuConnect,
  addMenuSchedule as addMenuScheduleConnect,
  updateMenuSchedule as updateMenuScheduleConnect,
  initializeBusinessFiles as initializeBusinessFilesConnect,
} from '../../../actions/business';
import API from '../../../libs/api';
import ErrorHandler from '../../../libs/errors/errors';
import Utils from '../../../libs/utils';
import Dots from '../../common/sortables/Dots';
import SortableTab from '../../common/sortables/SortableTab';

import './MenuEditor.scss';

const DragHandle = SortableHandle(() => <Dots size="5px" />);

class MenuEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeSchedule: 0 };

    this.onScheduleChange = this.onScheduleChange.bind(this);
    this.createNewSchedule = this.createNewSchedule.bind(this);
    this.onSortSchedules = this.onSortSchedules.bind(this);
  }

  async componentDidMount() {
    await this.getMenu();
    await this.getBusinessFiles();
  }

  onScheduleChange(e, { activeIndex }) {
    this.setState({ activeSchedule: activeIndex });
  }

  async onSortSchedules({ oldIndex, newIndex }) {
    const { updateMenuSchedule } = this.props;
    const { activeSchedule } = this.state;
    const schedules = this.getSchedules();
    const sortedSchedules = Utils.moveListItem(schedules, oldIndex, newIndex, true);
    const schedulesToUpdate = Utils.itemsToUpdateOrder(sortedSchedules);

    schedulesToUpdate.forEach((schedule) => {
      updateMenuSchedule({
        index: schedule.globalIndex,
        values: schedule,
      });
    });
    if (activeSchedule === oldIndex) {
      this.setState({ activeSchedule: newIndex });
    }
    return this.updateSchedules(schedulesToUpdate);
  }

  getSchedules() {
    const { schedules } = this.props;

    return schedules
      .map((schedule, idx) => ({ ...schedule, globalIndex: idx }))
      .sort(Utils.sortByOrder);
  }

  async getMenu() {
    const { businessType, businessId, initializeMenu } = this.props;

    return loadMenu({
      businessType,
      businessId,
      successCb: initializeMenu,
    });
  }

  async getBusinessFiles() {
    const { businessId, businessType, initializeBusinessFiles } = this.props;
    try {
      const { data: businessFilesData } = await API.getBusinessFiles(businessType, businessId);
      initializeBusinessFiles(businessFilesData);
    } catch (e) {
      ErrorHandler.capture(e);
    }
  }

  getCreateNewPane() {
    const { entityName } = this.context;
    return {
      menuItem: (
        <Menu.Item onClick={this.createNewSchedule} key="newMenuSchedule">
          <PlusIcon style={{ width: '1.75em', marginRight: '10px' }} />
          Add {entityName}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane key="newMenuSchedule">Create a new {entityName} to start adding items.</Tab.Pane>
      ),
    };
  }

  async updateSchedules(schedules) {
    const { businessId, businessType } = this.props;
    const promises = schedules.map((schedule) =>
      API.updateMenuSchedule(businessId, businessType, schedule.id, { order: schedule.order }),
    );
    return Promise.all(promises);
  }

  createNewSchedule() {
    const { addMenuSchedule, schedules } = this.props;
    const order = schedules.length;

    addMenuSchedule({ order });
    this.onScheduleChange(null, { activeIndex: schedules.length });
  }

  renderNoSchedulesTabs() {
    const { activeSchedule } = this.state;
    const panes = [this.getCreateNewPane()];

    return <Tab panes={panes} activeIndex={activeSchedule} />;
  }

  renderSchedulesTabs() {
    const { activeSchedule } = this.state;
    const schedules = this.getSchedules();

    const panes = schedules.map(({ name: scheduleName, id: scheduleId, localId, globalIndex }) => ({
      menuItem: (
        <Menu.Item key={`menu_schedule_${scheduleId || localId}`}>
          <DragHandle />
          <h3>{scheduleName.toUpperCase()}</h3>
        </Menu.Item>
      ),
      render: () => (
        <MenuSchedule
          key={`menu_schedule_${scheduleId || localId}`}
          index={globalIndex}
          id={scheduleId}
        />
      ),
    }));

    return (
      <SortableTab
        className="menu-editor-container"
        useDragHandle
        panes={[...panes, this.getCreateNewPane()]}
        activeIndex={activeSchedule}
        onSortEnd={this.onSortSchedules}
        onTabChange={this.onScheduleChange}
      />
    );
  }

  render() {
    const { schedules } = this.props;

    return schedules.length > 0 ? this.renderSchedulesTabs() : this.renderNoSchedulesTabs();
  }
}

const mapDispatchToProps = (dispatch) => ({
  initializeMenu: (payload) => dispatch(initializeMenuConnect(payload)),
  addMenuSchedule: (payload) => dispatch(addMenuScheduleConnect(payload)),
  updateMenuSchedule: (payload) => dispatch(updateMenuScheduleConnect(payload)),
  initializeBusinessFiles: (payload) => dispatch(initializeBusinessFilesConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  openModalIndex: _get(business, 'menu.value.openModalIndex'),
  schedules: _get(business, 'menu.value.schedules', []),
  businessType: _get(business, 'core.value.type'),
  businessId: _get(business, 'core.value.id'),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuEditor);
