import React, { useContext } from 'react';

import { Button, Header } from 'semantic-ui-react';

import { HtmlElementPageContext } from '../../context';

import './HtmlElementsErrorMessage.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsErrorMessage() {
  const { errorMessage, setErrorMessage } = useContext(HtmlElementPageContext);

  if (!errorMessage) {
    return null;
  }

  return (
    <div className="html-elements-error-message">
      <Header>
        <span>{errorMessage}</span>
      </Header>
      <Button circular icon="close" size="large" onClick={() => setErrorMessage(null)} />
    </div>
  );
}

HtmlElementsErrorMessage.propTypes = propTypes;
HtmlElementsErrorMessage.defaultProps = defaultProps;
