import React, { useContext } from 'react';

import { objectToCamelCase, objectToSnakeCase } from '../../../../../libs/format';
import WebsiteComponentInteractionContainer from '../../../website-component-interaction/WebsiteComponentInteractionContainer';
import { BEHAVIOR_CLOSE_WINDOW } from '../../../website-component-interaction/constants';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

export default function AnnouncementInteractions() {
  const { websiteComponentInteractions = [], setValue } = useContext(AnnouncementFormContext);

  return (
    <WebsiteComponentInteractionContainer
      interactions={websiteComponentInteractions.map(objectToSnakeCase)}
      onUpdate={(interactions) =>
        setValue('websiteComponentInteractions', objectToCamelCase(interactions))
      }
      extraBehaviors={[BEHAVIOR_CLOSE_WINDOW]}
    />
  );
}
