import PropTypes from 'prop-types';

import {
  RESTAURANT_BUSINESS_TYPE,
  GENERIC_BUSINESS_TYPE,
  MERCHANT_BUSINESS_TYPE,
} from '../constants/constants';

export const LocationPropType = PropTypes.shape({
  id: PropTypes.number,
  location_name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
});

export const OperatingHourPropType = PropTypes.shape({
  id: PropTypes.number,
  day: PropTypes.number,
  location: PropTypes.number,
  open: PropTypes.string,
  close: PropTypes.string,
  // only for merchants
  hour_group: PropTypes.number,
});

export const BusinessPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf([RESTAURANT_BUSINESS_TYPE, GENERIC_BUSINESS_TYPE, MERCHANT_BUSINESS_TYPE]),
  name: PropTypes.string,
  category: PropTypes.string,
});

export const ChildrenPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.element,
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.func,
]);

export const StylePropType = PropTypes.oneOfType([PropTypes.object]);

export const ObjectPropType = PropTypes.oneOfType([PropTypes.object]);

export const UserPropType = PropTypes.shape({
  id: PropTypes.number,
  groups: PropTypes.arrayOf(PropTypes.string),
  user_permissions: PropTypes.arrayOf(PropTypes.string),
  last_login: PropTypes.string,
  is_superuser: PropTypes.bool,
  username: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  is_staff: PropTypes.bool,
  is_active: PropTypes.bool,
  date_joined: PropTypes.string,
  avatar: PropTypes.string,
  phone_number: PropTypes.string,
  email_notification_opt_out: PropTypes.bool,
  change_password: PropTypes.bool,
  businessesCount: PropTypes.number,
});

export const ReactReferencePropTypes = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
]);
