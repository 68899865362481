export const DISMISSAL_TYPE_SESSION = 'session';
export const DISMISSAL_TYPE_LOCAL = 'local';
export const ANNOUNCEMENT_TYPE_BANNER_AND_POPUP = 'bannerAndPopup';
export const ANNOUNCEMENT_TYPE_BANNER = 'banner';
export const ANNOUNCEMENT_TYPE_POPUP = 'popup';
export const IMAGE_POSITION_LEFT = 'left';
export const IMAGE_POSITION_RIGHT = 'right';
export const IMAGE_POSITION_TOP = 'top';
export const IMAGE_POSITION_BOTTOM = 'bottom';
export const IMAGE_POSITION_BACKGROUND = 'background';
export const BANNER_POSITION_TOP = 'top';
export const BANNER_POSITION_RIGHT = 'right';
export const INITIAL_ANNOUNCEMENT_FORM_STATE = {
  startTime: '',
  endTime: '',
  referenceId: '',
  bannerEnabled: false,
  bannerBackgroundColor: '',
  bannerButtonText: 'See More',
  bannerPosition: BANNER_POSITION_TOP,
  popupEnabled: false,
  dismissalType: DISMISSAL_TYPE_SESSION,
  delay: null,
  duration: null,
  imagePosition: IMAGE_POSITION_TOP,
};
export const ANNOUNCEMENT_FAST_ID = 'Announcement';
export const CUSTOM_FORM_FAST_ID = 'CustomForm';
export const DISPLAY_SETTINGS_HELP =
  '"Show Every Website Visit" means that the popup will show everytime a user visits the website. "Show Once Per Session" means that the popup will show once the first time a user visit the website and then it won\'t be shown again';
