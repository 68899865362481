import React from 'react';

import { withExternalProperties } from './ExternalAnchor.utils';
import { ChildrenPropType } from '../../../libs/proptypes';

const propTypes = {
  children: ChildrenPropType,
};

const defaultProps = {
  children: null,
};

export default function ExternalAnchor({ children, ...rest }) {
  const props = withExternalProperties(rest);

  // TODO apply the following rule
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <a {...props}>{children}</a>;
}

ExternalAnchor.propTypes = propTypes;
ExternalAnchor.defaultProps = defaultProps;
