export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: payload,
      };

    case 'SET_ACTIVE_PRODUCT':
      return {
        ...state,
        activeProduct: payload,
      };

    default:
      return state;
  }
};
