import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import _get from 'lodash/get';

import { normalizeUrl, stripProtocol } from './UrlField.utils';
import { StylePropType } from '../../../libs/proptypes';
import Validate from '../../../libs/validate';
import Field from '../Field';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  error: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  style: StylePropType,
};

const defaultProps = {
  value: '',
  title: '',
  type: 'text',
  placeholder: '',
  name: 'url',
  label: 'https://',
  style: {},
  error: undefined,
};

export default function UrlField({
  value,
  onChange: onChangeProp,
  error,
  title,
  placeholder,
  name,
  label,
  style,
  type,
  onValidate,
  disabled,
  minLength,
  maxLength,
  pattern,
  unwrapped,
  ...rest
}) {
  const isControlled = error !== undefined;

  const inputRef = useRef(null);

  const [currentError, setCurrentError] = useState(error);
  const [cursorPosition, setCursorPosition] = useState(0);

  useEffect(() => {
    const input = _get(inputRef, 'current.inputRef.current');

    if (input) {
      input.focus();
      input.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);

  function onChange(e) {
    const target = _get(e, 'target', {});
    const newValue = _get(target, 'value', '');
    const selectionStart = _get(target, 'selectionStart');

    const normalizedValue = normalizeUrl(newValue);
    const isValid = Validate.validate(Validate.TYPES.LINK, normalizedValue);

    if (!isControlled) {
      setCurrentError(!isValid);
    }

    const newCursorPosition = selectionStart;
    const newTarget = { ...target, value: normalizedValue, name };

    if (onChangeProp) {
      onChangeProp({ ...e, target: newTarget }, newTarget);
    }

    setCursorPosition(newCursorPosition);
  }

  const UrlInput = (
    <Input
      label={label}
      type={type}
      value={stripProtocol(value)}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onValidate}
      name={name}
      disabled={disabled}
      pattern={pattern}
      minLength={minLength}
      maxLength={maxLength}
      error={currentError}
      ref={inputRef}
    />
  );

  if (unwrapped) {
    return UrlInput;
  }

  return <Field {...rest}>{UrlInput}</Field>;
}

UrlField.propTypes = propTypes;
UrlField.defaultProps = defaultProps;

UrlField.propTypes = propTypes;
UrlField.defaultProps = defaultProps;
