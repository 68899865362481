/* eslint-disable no-underscore-dangle */
/* eslint-disable no-useless-escape */
import _ from 'lodash';

const Validate = {
  TYPES: {
    EMAIL: 'email',
    LINK: 'link',
    PHONE: 'phone',
    DEFINED: 'defined',
    INTEGER: 'integer',
    CUSTOM: 'custom',
    URL_WITHOUT_PROTOCOL: 'urlNoProtocol',
    NO_SPECIAL_CHARACTERS: 'noSpecialCharacters',
    SOCIAL_MEDIA_USERNAME: 'socialMediaUsername',
  },

  REGEX: {
    EMAIL:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    LINK: /^(http(s?)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http(s?)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})?$/,
    PHONE: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    INTEGER: /^\d+$/,
    URL_WITHOUT_PROTOCOL:
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
    NO_SPECIAL_CHARACTERS: /^[a-zA-Z0-9]{4,10}$/,
    HEX_COLOR: /^#([0-9A-Fa-f]{3}){1,2}$/,
  },

  validate(type, value) {
    const types = Validate.TYPES;
    if (type === types.EMAIL) return Validate._validateEmail(value);
    if (type === types.LINK) return Validate._validateLink(value);
    if (type === types.PHONE) return Validate._validatePhone(value);
    if (type === types.DEFINED) return Validate._validateDefinement(value);
    if (type === types.INTEGER) return Validate._validateInteger(value);
    if (type === types.URL_WITHOUT_PROTOCOL) return Validate._validateURLNOProtocol(value);
    if (type === types.NO_SPECIAL_CHARACTERS) return Validate._validateNoSpecialChars(value);
    return true;
  },

  validateAllSections(state) {
    return [Validate.validateBusinessProfile(state)].every((val) => val === true);
  },

  validateBusinessProfile(state) {
    const {
      business: { locations },
    } = state;
    const location = _.get(locations, 'value[0]', {});
    if (!location) return false;

    return true;
  },

  getErrorMessage(type) {
    const types = Validate.TYPES;

    if (type === types.EMAIL) return 'Invalid Email';
    if (type === types.LINK) return 'Invalid Link';
    if (type === types.PHONE) return 'Invalid Phone Number';
    if (type === types.DEFINED) return 'This field is required';
    if (type === types.URL_WITHOUT_PROTOCOL) return 'Invalid URL';
    if (type === types.SOCIAL_MEDIA_USERNAME) return 'Invalid username';
    return '';
  },

  getErrorMessageWithName(type, name) {
    const types = Validate.TYPES;
    if (type === types.EMAIL) return `${name} is an invalid email.`;
    if (type === types.LINK) return `${name} is an invalid link.`;
    if (type === types.PHONE) return `${name} is an invalid phone number.`;
    if (type === types.DEFINED) return `${name} is required.`;
    if (type === types.INTEGER) return `${name} must be an integer.`;
    if (type === types.CUSTOM) return `${name} is invalid.`;
    if (type === types.URL_WITHOUT_PROTOCOL) return `${name} requires a protocol.`;
    return '';
  },

  _validateEmail(value) {
    return Validate.REGEX.EMAIL.test(value);
  },

  _validateLink(value) {
    return Validate.REGEX.LINK.test(value) && !!value;
  },

  _validateURLNOProtocol(value) {
    return Validate.REGEX.URL_WITHOUT_PROTOCOL.test(value);
  },

  _validateNoSpecialChars(value) {
    return Validate.REGEX.NO_SPECIAL_CHARACTERS.test(value);
  },

  _validatePhone(value) {
    return Validate.REGEX.PHONE.test(value);
  },

  _validateDefinement(value) {
    if (_.isObjectLike(value)) return Object.keys(value).length > 0;
    if (_.isArray(value) || _.isString(value)) return value.length > 0;
    if (_.isString(value)) return value.length > 0;
    if (_.isNumber(value)) return true;
    return value === true;
  },

  _validateInteger(value) {
    return Validate.REGEX.INTEGER.test(value);
  },

  inputValidationRules: {
    Website: {
      '/core/reviews': {
        'Reviewers Name': {
          getValue: (state, index) => _.get(state, `business.reviews.value.${index}.author`),
          rules: [
            'custom', // Validate.TYPES.CUSTOM,
          ],
          isArray: true,
          validate: (state, index) => {
            // Only if text exists
            const text = _.get(state, `business.reviews.value.${index}.text`);
            if (text) {
              return _.get(state, `business.reviews.value.${index}.author`);
            }
            return true;
          },
        },
        Review: {
          getValue: (state, index) => _.get(state, `business.reviews.value.${index}.text`),
          rules: [
            'custom', // Validate.TYPES.CUSTOM,
          ],
          isArray: true,
          validate: (state, index) => {
            // Only if author exists
            const text = _.get(state, `business.reviews.value.${index}.author`);
            if (text) {
              return _.get(state, `business.reviews.value.${index}.text`);
            }
            return true;
          },
        },
      },
      '/core/locations': {
        'Location Email': {
          getValue: (state, index) => _.get(state, `business.locations.value.${index}.email`),
          rules: [
            'email', // Validate.TYPES.EMAIL,
          ],
          isArray: true,
        },
      },
      '/core/socialmedia': {
        'Social Media': {
          getValue: (state, index) => _.get(state, `business.socialMedia.value.${index}.link`),
          rules: [
            'link', // Validate.TYPES.LINK,
          ],
          isArray: true,
        },
        'Social Media type': {
          getValue: (state, index) => _.get(state, `business.socialMedia.value.${index}.type`),
          rules: [
            'custom', // Validate.TYPES.CUSTOM,
          ],
          isArray: true,
          validate: (state, index) => {
            // Only if link exists
            const link = _.get(state, `business.socialMedia.value.${index}.link`);
            if (link) {
              return _.get(state, `business.socialMedia.value.${index}.type`);
            }
            return true;
          },
        },
      },
      '/core/profile': {
        'Business Name': {
          getValue: (state) => _.get(state, 'business.core.value.name'),
          rules: [
            'defined', // Validate.TYPES.DEFINED,
          ],
        },
      },
      '/core/external': {
        'Online Ordering': {
          getValue: (state) => _.get(state, 'business.core.value.online_order_link'),
          rules: [
            'link', // Validate.TYPES.LINK,
          ],
        },
        Catering: {
          getValue: (state) => _.get(state, 'business.core.value.catering_link'),
          rules: [
            'link', // Validate.TYPES.LINK,
          ],
        },
        Reservations: {
          getValue: (state) => _.get(state, 'business.core.value.reservation_link'),
          rules: [
            'link', // Validate.TYPES.LINK,
          ],
        },
      },
    },
  },

  inputRequiredAndNotEmpty(inputValue, validationForInput) {
    return (
      inputValue ||
      validationForInput.rules.includes(Validate.TYPES.DEFINED) ||
      validationForInput.rules.includes(Validate.TYPES.CUSTOM)
    );
  },

  getValidationErrorMessage(state, route, input, index) {
    const validationForInput = _.get(Validate.inputValidationRules, `Website.${route}.${input}`);

    if (validationForInput) {
      const inputValue = validationForInput.getValue(state, index);

      // If it's not empty, or it's required to be defined, continue
      if (Validate.inputRequiredAndNotEmpty(inputValue, validationForInput)) {
        for (let i = 0; i < (validationForInput.rules || []).length; i += 1) {
          const validationRule = validationForInput.rules[i];

          let isValid = true;

          if (validationRule === Validate.TYPES.CUSTOM) {
            isValid = validationForInput.validate(state, index);
          } else {
            isValid = Validate.validate(validationRule, inputValue);
          }

          if (!isValid) {
            return Validate.getErrorMessageWithName(validationRule, input);
          }
        }
      }
    }

    // No error
    return null;
  },

  explodeRouteInput(routeAndInput) {
    const parts = routeAndInput.match(/^([a-z\/]+).([\w\s]+)[.]*([0-9]*)$/i);
    return [parts[1], parts[2], parts[3]];
  },

  isHexColor(str) {
    return Validate.REGEX.HEX_COLOR.test(str);
  },
};

export default Validate;
