import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { getFormErrors, getInitialData, isAsButtonDisabled } from './EditPageGroupModal.utils';
import ErrorHandler from '../../../../../libs/errors/errors';
import CloseableModal from '../../../../common/CloseableModal/CloseableModal';
import { PagePropType } from '../../../pages/types/page';
import { NavigationContext } from '../../context/Navigation.context';
import DeletePageGroupModal from '../DeletePageGroupModal';
import { getNavigationPriority } from '../EditPageModal/EditPageModal.utils';

import './EditPageGroupModal.scss';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  page: PagePropType,
  add: PropTypes.bool,
};

const defaultProps = {
  add: false,
  page: null,
};

export default function EditPageGroupModal({ open, onClose: onCloseProp, page, add }) {
  const { navigationGroup, navigation, isButtonGroupingSupported } = useContext(NavigationContext);

  const { reOrderNavigationPriority } = navigation;
  const { all: pages, addGroup, buttonPages, nonButtonPages, updateGroup } = navigationGroup;

  const initialData = getInitialData(page);

  const { asButton: initialAsButton } = initialData;
  const [formData, setFormData] = useState(initialData);
  const [formErrors, setFormErrors] = useState({});
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, asButton } = formData || {};

  function reset() {
    setFormData(initialData);
    setFormErrors({});
    setTouched(false);
  }

  useEffect(reset, [open]);

  useEffect(() => {
    setFormErrors(getFormErrors(formData));
  }, [formData]);

  function onChange(event, { name: fieldName, value }) {
    setFormData({ ...formData, [fieldName]: value });
  }

  function onChangeCheckbox(event, { name: fieldName, checked }) {
    setFormData({ ...formData, [fieldName]: checked });
  }

  function onClose() {
    reset();
    onCloseProp();
  }

  async function onSubmit() {
    setTouched(true);
    if (!_isEmpty(formErrors)) {
      return;
    }

    try {
      setLoading(true);

      const navigationPriority = getNavigationPriority(
        page,
        initialAsButton,
        asButton,
        pages,
        nonButtonPages,
      );

      if (add) {
        const payload = { name, navigationPriority, asButton };
        await addGroup(payload);
      } else {
        const payload = { name, title: name, asButton, navigationPriority };
        await updateGroup(page, payload);
        if (asButton !== initialAsButton) {
          const { pages: childPages = [] } = page;
          const childPagesIds = childPages.map(({ id }) => id);
          const increaseFactor = Math.max(1, childPagesIds.length);

          await reOrderNavigationPriority(navigationPriority, {
            avoidIds: childPagesIds,
            increaseFactor,
          });
        }
      }

      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
      ErrorHandler.capture(e);
    }
  }

  function getFieldError(field) {
    if (!touched) {
      return '';
    }
    return formErrors[field] || '';
  }

  const disableDeleteButton = loading || add;
  const disableAsButtonButton = isAsButtonDisabled(buttonPages);

  return (
    <CloseableModal
      open={open}
      onClose={onClose}
      className="navigation-edit-page-group-modal"
      header={add ? 'Add Link Group' : 'Edit Link Group'}
      size="tiny"
      onOpen={reset}
    >
      <Modal.Content>
        <Form>
          <Form.Input
            label="Link Title"
            name="name"
            value={name}
            placeholder="Enter a title"
            onChange={onChange}
            error={!!getFieldError('name')}
            required
          />

          {isButtonGroupingSupported && (
            <Form.Checkbox
              className="secondary-navy"
              label="Render as a button group on the navigation bar"
              name="asButton"
              checked={asButton}
              onChange={onChangeCheckbox}
              disabled={disableAsButtonButton}
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <DeletePageGroupModal page={page} disabled={disableDeleteButton} />
        <Button
          content="CANCEL"
          className="action-button cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          content={add ? 'ADD' : 'SAVE'}
          className="secondary-navy add"
          onClick={onSubmit}
          disabled={loading}
          loading={loading}
        />
      </Modal.Actions>
    </CloseableModal>
  );
}

EditPageGroupModal.propTypes = propTypes;
EditPageGroupModal.defaultProps = defaultProps;
