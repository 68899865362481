export const DEFAULT_MAX_WIDTH = 2000;
export const DEFAULT_MAX_HEIGHT = 2000;

export const DESKTOP_DEVICE = 'desktop';

export const MODE_TYPE_FIT = `fit`;
export const MODE_TYPE_CROP = `crop`;
export const MODE_TYPE_FILL = `fill`;
export const MODE_TYPE_STRETCH = `stretch`;
export const MODE_TYPES = [MODE_TYPE_FIT, MODE_TYPE_CROP, MODE_TYPE_STRETCH];

export const FILL_TYPE_SOLID = `solid`;
export const FILL_TYPE_BLUR = `blur`;
export const FILL_TYPE_BACKGROUND = `background`;
export const FILL_TYPES = [FILL_TYPE_SOLID, FILL_TYPE_BLUR, FILL_TYPE_BACKGROUND];

export const CROP_TYPE_ENTROPY = 'entropy';
export const CROP_TYPE_SMART = 'smart';
export const CROP_TYPE_TOP = 'top';
export const CROP_TYPE_TOPLEFT = 'topleft';
export const CROP_TYPE_LEFT = 'left';
export const CROP_TYPE_BOTTOMLEFT = 'bottomleft';
export const CROP_TYPE_BOTTOM = 'bottom';
export const CROP_TYPE_BOTTOMRIGHT = 'bottomright';
export const CROP_TYPE_RIGHT = 'right';
export const CROP_TYPE_TOPRIGHT = 'topright';
export const CROP_TYPE_FACES = 'faces';
export const CROP_TYPE_CENTER = 'center';
export const CROP_TYPE_FOCALPOINT = 'focalpoint';
export const CROP_TYPES = [
  CROP_TYPE_ENTROPY,
  CROP_TYPE_SMART,
  CROP_TYPE_TOP,
  CROP_TYPE_TOPLEFT,
  CROP_TYPE_LEFT,
  CROP_TYPE_BOTTOMLEFT,
  CROP_TYPE_BOTTOM,
  CROP_TYPE_BOTTOMRIGHT,
  CROP_TYPE_RIGHT,
  CROP_TYPE_TOPRIGHT,
  CROP_TYPE_FACES,
  CROP_TYPE_CENTER,
  CROP_TYPE_FOCALPOINT,
];

export const MASK_TYPE_ORIGINAL = 'original';
export const MASK_TYPE_ELLIPSE = 'ellipse';
export const MASK_TYPE_CORNERS = 'corners';
export const MASK_TYPES = [MASK_TYPE_ORIGINAL, MASK_TYPE_ELLIPSE, MASK_TYPE_CORNERS];

export const ASPECT_RATIO_TYPE_11 = `1:1`;
export const ASPECT_RATIO_TYPE_32 = `3:2`;
export const ASPECT_RATIO_TYPE_34 = `3:4`;
export const ASPECT_RATIO_TYPE_43 = `4:3`;
export const ASPECT_RATIO_TYPE_23 = `2:3`;
export const ASPECT_RATIO_TYPE_52 = `5:2`;
export const ASPECT_RATIO_TYPE_169 = `16:9`;
export const ASPECT_RATIO_TYPE_916 = `9:16`;
export const ASPECT_RATIO_TYPES = [
  ASPECT_RATIO_TYPE_11,
  ASPECT_RATIO_TYPE_32,
  ASPECT_RATIO_TYPE_34,
  ASPECT_RATIO_TYPE_43,
  ASPECT_RATIO_TYPE_23,
  ASPECT_RATIO_TYPE_52,
  ASPECT_RATIO_TYPE_169,
  ASPECT_RATIO_TYPE_916,
];
