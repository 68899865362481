import React from 'react';

import PropTypes from 'prop-types';
import { Button, Divider, Icon } from 'semantic-ui-react';

import './AddCategoryButton.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

function AddCategoryButton({ onClick }) {
  return (
    <Divider horizontal>
      <Button className="add-category-button" onClick={onClick}>
        <Icon name="plus" />
        New category
      </Button>
    </Divider>
  );
}

AddCategoryButton.propTypes = propTypes;

export default AddCategoryButton;
