import _get from 'lodash/get';

import {
  leadSourceLogoMap,
  leadSourceLogoHeightMap,
  leadSourceSlugMap,
} from '../constants/partners';

export function logoFromLeadSource(leadSource) {
  return _get(leadSourceLogoMap, leadSource, null);
}

export function heightFromLeadSource(leadSource) {
  return _get(leadSourceLogoHeightMap, leadSource, null);
}

export function registrationSlugFromLeadSource(leadSource) {
  return _get(leadSourceSlugMap, leadSource, null);
}
