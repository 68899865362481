import Utils from '../../../../../libs/utils';
import { BUTTON_PROPERTY } from '../../../pages/constants';
import { extractGroupNameFromCollection } from '../../../pages/services/page-groups';
import {
  getMinNavigationPriority,
  getNextNavigationPriority,
  isButtonPage,
} from '../../../pages/services/pages';

export function fillIndex(pages, { startIndex = 0 } = {}) {
  return pages.map((page, i) => {
    return { ...page, index: i + startIndex };
  });
}

export function sortPagesOnIndex(pages, oldIndex, newIndex, useNavigation, useNavigationGroup) {
  const { updatePage } = useNavigation;
  const { updateGroup } = useNavigationGroup;

  const sortedPages = Utils.moveListItem(pages, oldIndex, newIndex, true);

  let i = 0;
  sortedPages.forEach((page) => {
    const { isGroup, pages: groupPages = [] } = page;

    if (!isGroup) {
      if (page.navigationPriority !== i) {
        updatePage(page, { navigationPriority: i }, { updateRemote: false });
      }
    } else {
      updateGroup(page, { navigationPriority: i }, { updateRemote: false });
    }
    i += Math.max(1, groupPages.length);
  });
}

export function togglePageToButtons(
  pages,
  oldIndex,
  movedToButtons,
  useNavigation,
  useNavigationGroup,
) {
  const { updatePage } = useNavigation;
  const { updateGroup } = useNavigationGroup;

  const page = pages[oldIndex];
  const { metadata, isGroup } = page;

  if (isGroup) {
    updateGroup(page, { asButton: movedToButtons }, { updateRemote: false });
  } else {
    updatePage(
      page,
      { metadata: { ...metadata, as: movedToButtons ? BUTTON_PROPERTY : null } },
      { updateRemote: false },
    );
  }
}

export function dragPageIntoGroup(
  pages,
  focusedGroup,
  oldIndex,
  useNavigation,
  useNavigationGroup,
) {
  const { updatePage, reOrderNavigationPriority } = useNavigation;
  const { getGroupByName } = useNavigationGroup;

  const groupName = extractGroupNameFromCollection(focusedGroup);
  const group = getGroupByName(groupName);

  const navigationPriority = getNextNavigationPriority([group]);
  const page = pages[oldIndex];
  const { isGroup } = page;
  if (!isGroup) {
    updatePage(page, { navigationPriority, groupName }, { updateRemote: false });
    reOrderNavigationPriority(navigationPriority, { updateRemote: false, avoidIds: [page.id] });
  }
}

export function dragPageOutOfGroup(
  initialFocusedGroup,
  oldIndex,
  useNavigation,
  useNavigationGroup,
) {
  const { updatePage, reOrderNavigationPriority } = useNavigation;
  const { nonButtonPages, buttonPages, getGroupByName } = useNavigationGroup;

  const pages = [...nonButtonPages, ...buttonPages];

  const groupName = extractGroupNameFromCollection(initialFocusedGroup);
  const group = getGroupByName(groupName);
  const { pages: childPages = [] } = group || {};
  const page = childPages[oldIndex];

  if (page) {
    const navigationPriority = isButtonPage(page)
      ? getNextNavigationPriority(pages)
      : getNextNavigationPriority(nonButtonPages);
    updatePage(page, { navigationPriority, groupName: '' }, { updateRemote: false });
    reOrderNavigationPriority(navigationPriority, { updateRemote: false, avoidIds: [page.id] });
  }
}

export function dragPageWithinAGroup(
  initialFocusedGroup,
  oldIndex,
  newIndex,
  useNavigation,
  useNavigationGroup,
) {
  const { updatePage } = useNavigation;
  const { getGroupByName } = useNavigationGroup;

  const groupName = extractGroupNameFromCollection(initialFocusedGroup);
  const group = getGroupByName(groupName);
  const { pages: childPages = [] } = group || {};

  let navigationPriority = getMinNavigationPriority(childPages);

  const sortedChildPages = Utils.moveListItem(childPages, oldIndex, newIndex, true);
  sortedChildPages.forEach((page) => {
    updatePage(page, { navigationPriority }, { updateRemote: false });
    navigationPriority += 1;
  });
}
