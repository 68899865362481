import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const OptionHeaderItem = ({ icon, text, onClick }) => {
  return <Dropdown.Item icon={icon} text={text} onClick={onClick} />;
};

OptionHeaderItem.propTypes = propTypes;

export default OptionHeaderItem;
