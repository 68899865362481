import React, { useState } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import EditStyleRuleModal from '../EditStyleRuleModal';

import './AddStyleRuleButton.scss';

const propTypes = {};
const defaultProps = {};

export default function AddStyleRuleButton() {
  const [open, setOpen] = useState(false);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  return (
    <EditStyleRuleModal
      add
      onClose={closeModal}
      open={open}
      trigger={
        <Button className="add-style-rule-button action-button" onClick={openModal}>
          <Icon name="add" /> New Style
        </Button>
      }
    />
  );
}

AddStyleRuleButton.propTypes = propTypes;
AddStyleRuleButton.defaultProps = defaultProps;
