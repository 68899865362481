import _find from 'lodash/find';
import _get from 'lodash/get';

import { multiCaseGet, objectToCamelCase } from '../libs/format';

export function selectActiveWebsitePatch(websitePatches = []) {
  return _find(websitePatches, ({ active }) => active);
}

export function selectActiveRecipe(websitePatches = []) {
  const activePatch = selectActiveWebsitePatch(websitePatches);
  return multiCaseGet(activePatch, 'baseRecipe');
}

export function selectActiveRecipeName(websitePatches = []) {
  const activePatch = selectActiveWebsitePatch(websitePatches);
  return _get(activePatch, 'base_recipe.name') || _get(activePatch, 'baseRecipe.name');
}

export function selectWebsitePatches(state) {
  return objectToCamelCase(_get(state, 'website.patches.value', []));
}

export function selectWebsiteId(state) {
  return _get(state, 'website.core.value.id');
}

export function selectWebsite(state) {
  return objectToCamelCase(_get(state, 'website.core.value'));
}

export function selectLayouts(state) {
  return objectToCamelCase(_get(state, 'website.layouts.value'));
}

export function selectWebsitePages(state) {
  return objectToCamelCase(_get(state, 'website.pages.value')) || [];
}

export function selectWebsiteContentNodes(state) {
  const websitePages = selectWebsitePages(state);

  return websitePages.reduce((allPagesContentNodes, { components = [] }) => {
    const allPageContentNodes = components.reduce((pageContentNodes, { contentNodes = [] }) => {
      return [...pageContentNodes, ...contentNodes];
    }, []);

    return [...allPagesContentNodes, ...allPageContentNodes];
  }, []);
}

export function selectDomains(state) {
  return objectToCamelCase(_get(state, 'website.domains.value'));
}

export function selectPrimaryDomain(state) {
  const domains = _get(state, 'website.domains.value', []);

  const primaryDomain = domains.find((domain) => multiCaseGet(domain, 'primaryDomain')) || {};
  return objectToCamelCase(primaryDomain);
}

export function selectIsNextWebsite(state) {
  return _get(state, 'website.isNextWebsite', false);
}

export function selectWebsiteStyleRules(state) {
  return _get(state, 'website.styleRules.value', []);
}

export function selectWebsiteColorPaletteId(state) {
  return _get(state, 'website.colorPalette.value.id', null);
}
