import React from 'react';

import { Icon, Label } from 'semantic-ui-react';

import './ContentNoticeLabel.scss';

const propTypes = {};

const defaultProps = {};

export default function ContentNoticeLabel() {
  return (
    <Label attached="top right" className="content-notice-label">
      <Icon name="warning sign" /> Needs Content
    </Label>
  );
}

ContentNoticeLabel.propTypes = propTypes;
ContentNoticeLabel.defaultProps = defaultProps;
