import React, { useContext } from 'react';

import { Form, Input } from 'semantic-ui-react';

import _get from 'lodash/get';
import _range from 'lodash/range';

import { MAX_WIDTH } from '../../../../constants/tile';
import { getTotalNumberOfRows, getTileIndex, getTileId } from '../../../../services/tile';
import { CustomHeroFormContext } from '../../CustomHeroForm.context';

import './TileWidthInputs.scss';

const propTypes = {};

const defaultProps = {};

export default function TileWidthInputs() {
  const { customHero, setTileWidth } = useContext(CustomHeroFormContext);
  const { tiles = [], tilesPerRow } = customHero;
  const numberOfTiles = tiles.length;

  const numberOfRows = getTotalNumberOfRows(numberOfTiles, tilesPerRow);

  function getTile(tileIndex) {
    const tile = tiles[tileIndex];
    return tile;
  }

  function getTileWidth(tileIndex) {
    const tile = getTile(tileIndex);

    return _get(tile, 'componentConfiguration.data.width');
  }

  function onWidthChange(tileIndex, newWidth) {
    if (newWidth < 1 || newWidth > MAX_WIDTH - 1) {
      return;
    }
    setTileWidth({ tileIndex }, { width: newWidth });
  }

  return (
    <Form.Group className="tile-width-inputs">
      {_range(numberOfRows).map((rowIndex) => {
        return (
          <div key={`row-${rowIndex}`} className="row-widths">
            <span className="row-number label">Row {rowIndex + 1}</span>
            {_range(tilesPerRow).map((columnIndex) => {
              const tileIndex = getTileIndex(rowIndex, columnIndex, tilesPerRow);
              const tile = getTile(tileIndex);
              const tileId = getTileId(tile);
              return (
                <div key={tileId} className="column-widths">
                  <span className="label">Col {tileIndex + 1} Width </span>
                  <Input
                    value={getTileWidth(tileIndex)}
                    type="number"
                    min={1}
                    max={MAX_WIDTH - 1}
                    onChange={(e, { value }) => {
                      onWidthChange(tileIndex, +value);
                    }}
                    disabled={tilesPerRow === 1}
                  />
                </div>
              );
            })}
          </div>
        );
      })}
    </Form.Group>
  );
}

TileWidthInputs.propTypes = propTypes;
TileWidthInputs.defaultProps = defaultProps;
