import React from 'react';

import PropTypes from 'prop-types';
import { Radio, Button } from 'semantic-ui-react';

import { faChevronUp, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FormFieldToolbar.scss';

const propTypes = {
  moveDownDisabled: PropTypes.bool,
  moveUpDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  onRequiredChange: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onMoveFieldUp: PropTypes.func.isRequired,
  onMoveFieldDown: PropTypes.func.isRequired,
};

const defaultProps = {
  moveDownDisabled: false,
  moveUpDisabled: false,
  isRequired: false,
};

export default function FormFieldToolbar({
  moveDownDisabled,
  moveUpDisabled,
  isRequired,
  onRequiredChange,
  onDeleteField,
  onMoveFieldUp,
  onMoveFieldDown,
}) {
  return (
    <div className="form-field-toolbar">
      <Button basic className="move-up" disabled={moveUpDisabled} onClick={onMoveFieldUp}>
        <FontAwesomeIcon className="icon" icon={faChevronUp} />
        Move Up
      </Button>
      <Button basic className="move-down" disabled={moveDownDisabled} onClick={onMoveFieldDown}>
        <FontAwesomeIcon className="icon" icon={faChevronDown} />
        Move Down
      </Button>
      <Radio
        toggle
        label="Required"
        className="required"
        checked={isRequired}
        onChange={(e, { checked }) => onRequiredChange(checked)}
      />
      <Button basic className="delete" onClick={onDeleteField}>
        <FontAwesomeIcon className="icon" icon={faTrash} />
      </Button>
    </div>
  );
}

FormFieldToolbar.propTypes = propTypes;
FormFieldToolbar.defaultProps = defaultProps;
