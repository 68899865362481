import { getProviderService, getProviderServiceSetting } from '../utils';

const TOAST_PROVIDER = 'Toast';
const TOAST_SERVICE_TYPE = 'Merchant';

export function getToastProviderService(providerServices) {
  return getProviderService(providerServices, TOAST_PROVIDER, TOAST_SERVICE_TYPE);
}

export function getToastProviderServiceSetting(providerServiceSettings) {
  return getProviderServiceSetting(providerServiceSettings, TOAST_PROVIDER, TOAST_SERVICE_TYPE);
}
