import {
  IMAGE_COMPONENT_NAME,
  CAROUSEL_IMAGE_COMPONENT_NAME,
  PAGINATED_GRID_IMAGE_COMPONENT_NAME,
} from '../../../../../../constants/types';

export const IMAGE_WIDGET_TYPES = [
  IMAGE_COMPONENT_NAME,
  CAROUSEL_IMAGE_COMPONENT_NAME,
  PAGINATED_GRID_IMAGE_COMPONENT_NAME,
];

export const IMAGE_WIDGET_DISPLAY_NAMES = {
  [IMAGE_COMPONENT_NAME]: 'Single Image',
  [CAROUSEL_IMAGE_COMPONENT_NAME]: 'Carousel',
  [PAGINATED_GRID_IMAGE_COMPONENT_NAME]: 'Paginated Grid',
};
