import React from 'react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { getCloverProviderServiceSetting } from './CloverIntegration.utils';
import CloverMerchantForm from './CloverMerchantForm';
import {
  initializeProviderServiceSettings as initializeProviderServiceSettingsConnect,
  chooseMenuSource as chooseMenuSourceConnect,
} from '../../../../actions/business';
import { updateWebsitePages as updateWebsitePagesConnect } from '../../../../actions/website';
import API from '../../../../libs/api';
import ErrorHandler from '../../../../libs/errors';
import WithRouter from '../../../modules/foundation/components/WithRouter/WithRouter';
import { EXTERNAL_PAGE_TYPE } from '../../../modules/pages/constants';

import '../styles/CloverIntegration.scss';

const mapDispatchToProps = (dispatch) => ({
  initializeProviderServiceSettings: (payload) =>
    dispatch(initializeProviderServiceSettingsConnect(payload)),
  updateWebsitePages: (payload) => dispatch(updateWebsitePagesConnect(payload)),
  chooseMenuSource: (payload) => dispatch(chooseMenuSourceConnect(payload)),
});

const mapStateToProps = ({ business, user, website }) => ({
  user: _get(user, 'core.value'),
  businessId: _get(business, 'core.value.id'),
  websiteId: _get(website, 'core.value.id'),
  businessType: _get(business, 'core.value.type'),
  providerServices: _get(business, 'provider_services.value'),
  providerServiceSettings: _get(business, 'provider_service_settings.value'),
});

class CloverIntegration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      importingMenu: false,
      errorMessage: '',
    };
  }

  handleError = (e) => {
    const message = _get(e, 'response.data.message', e.message);
    this.setState({
      errorMessage: message,
    });
  };

  onAuthorize = async () => {
    const { businessId, businessType } = this.props;

    try {
      const response = await API.getCloverAuth(businessId, businessType);
      const cloverUrl = _get(response, 'data.url');

      if (cloverUrl) {
        window.location.replace(cloverUrl);
      }
    } catch (e) {
      this.handleError(e);
    }
  };

  onImportMenu = async () => {
    const { businessId, businessType, history } = this.props;

    this.setState({
      importingMenu: true,
      errorMessage: '',
    });

    try {
      await API.getCloverMenu(businessType, businessId);
      history.push('/core/menu');
    } catch (e) {
      this.handleError(e);
    } finally {
      this.setState({
        importingMenu: false,
      });
    }
  };

  toggleSubmitting = () => {
    const { submitting } = this.state;

    this.setState({
      submitting: !submitting,
    });
  };

  refreshData = async () => {
    const { businessType, businessId } = this.props;
    const { data } = await API.getBusinessInitializationData(businessType, businessId);

    this.refreshProviderServiceSettings(data);
    this.refreshPages(data);
    this.refreshBusinessData(data);
  };

  refreshPages = (data) => {
    const { updateWebsitePages } = this.props;
    const pages = _get(data, 'website.pages');
    const filteredPages = pages.filter((page) => page.page_type !== EXTERNAL_PAGE_TYPE);

    updateWebsitePages(filteredPages);
  };

  refreshBusinessData = (data) => {
    const { chooseMenuSource } = this.props;
    const { menu_source_type: value } = data;

    chooseMenuSource({ value });
  };

  refreshProviderServiceSettings = (data) => {
    const { initializeProviderServiceSettings } = this.props;
    const providerServiceSettings = _get(data, 'provider_service_settings');

    initializeProviderServiceSettings(providerServiceSettings);
  };

  onSubmitSettings = async (statusData) => {
    const { businessId, businessType, providerServiceSettings } = this.props;
    const providerServiceSetting = getCloverProviderServiceSetting(providerServiceSettings);
    const { id: settingId } = providerServiceSetting;

    this.toggleSubmitting();
    try {
      await API.updateCloverMerchant(businessId, businessType, settingId, {
        ...providerServiceSetting,
        status_data: statusData,
      });

      await this.refreshData();
      this.toggleSubmitting();
    } catch (e) {
      this.toggleSubmitting();
      ErrorHandler.capture(e);
    }
  };

  render() {
    const { providerServiceSettings, showTitle = true } = this.props;
    const { importingMenu, errorMessage, submitting } = this.state;
    const serviceSetting = getCloverProviderServiceSetting(providerServiceSettings);

    return (
      <CloverMerchantForm
        showTitle={showTitle}
        serviceSetting={serviceSetting}
        importingMenu={importingMenu}
        isSubmitting={submitting}
        errorMessage={errorMessage}
        onAuthorize={this.onAuthorize}
        onSubmitSettings={this.onSubmitSettings}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(CloverIntegration));
