import React from 'react';

import PropTypes from 'prop-types';
import { Label, List, Image } from 'semantic-ui-react';

import _get from 'lodash/get';

import { STACK_STATUS_LIVE } from '../../../../../constants/constants';
import { isAdminUser } from '../../../../../libs/auth';
import { getBusinessCategoryLabels } from '../../BusinessSelectForm.utils';
import BusinessLabel from '../BusinessLabel';

const propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  colorClass: PropTypes.string,
};

const defaultProps = {
  icon: '',
  label: '',
  colorClass: '',
};

export default function BusinessListItem({
  user,
  isAdmin,
  business,
  website,
  index,
  listItemStyle,
  handleBusinessClick,
}) {
  const {
    id,
    name,
    logo,
    processing,
    category: businessCategory,
    brand_identity: brandIdentity,
  } = business;

  function getWorkspaceVersionLabel() {
    if (!isAdminUser(user)) {
      return null;
    }

    const labelMap = {
      v_legacy: 'legacy',
      v_1: 'v1',
    };
    const content = labelMap[_get(website, 'workspace_version')];
    if (!content) {
      return null;
    }

    return <Label>{content}</Label>;
  }

  function getStackStatusLabel(stackStatus) {
    if (!stackStatus) return null;

    if (stackStatus === STACK_STATUS_LIVE) {
      return <Label color="green" content={stackStatus} />;
    }
    return <Label content={stackStatus} />;
  }

  function getStackStatus() {
    if (!isAdminUser(user) || !website) {
      return null;
    }

    const stackStatus = _get(website, 'stack_status');
    return getStackStatusLabel(stackStatus);
  }

  return (
    <List.Item
      className="list-item"
      id={id}
      key={id}
      index={index}
      onClick={handleBusinessClick}
      style={listItemStyle}
      disabled={processing}
    >
      <div>
        {logo ? (
          <Image avatar src={logo} />
        ) : (
          <List.Icon name="image outline" size="large" verticalAlign="middle" />
        )}
      </div>
      <div>
        <List.Content>
          <List.Header as="a">{name}</List.Header>
          {isAdmin && (
            <List.Header>
              ID: {id} | Stack: {_get(website, 'cloudformation_stack_name')}
            </List.Header>
          )}
          {isAdmin && (
            <List.Item style={{ paddingTop: '0.3rem' }}>
              <Label size="small" content={brandIdentity} />
              <BusinessLabel size="small" {...getBusinessCategoryLabels(businessCategory)} />
            </List.Item>
          )}
        </List.Content>
      </div>
      <div className="business-extra-info">
        {getWorkspaceVersionLabel(user, business)}
        {getStackStatus(user, business)}
      </div>
    </List.Item>
  );
}

BusinessListItem.propTypes = propTypes;
BusinessListItem.defaultProps = defaultProps;
