import { useState } from 'react';

import _isArray from 'lodash/isArray';

import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors/errors';

export default function useFetch({ initialFetching = false } = {}) {
  const [fetching, setFetching] = useState(initialFetching);
  const [errorMessage, setErrorMessage] = useState('');

  async function fetch(fetchFunctions = [], { throwError = true } = {}) {
    const finalFunctions = _isArray(fetchFunctions) ? fetchFunctions : [fetchFunctions];

    try {
      setFetching(true);
      await Promise.all(finalFunctions.map((fetchFunction) => fetchFunction()));
    } catch (error) {
      ErrorHandler.capture(error);
      setErrorMessage(getErrorMessage(error));
      if (throwError) {
        throw error;
      }
    } finally {
      setFetching(false);
    }
  }

  function withFetch(fetchFunction, { throwError = true } = {}) {
    return async () => fetch([fetchFunction], { throwError });
  }

  return { fetch, errorMessage, fetching, withFetch };
}
