import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Container, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import CustomDomainForm from './components/CustomDomainForm';
import DomainSearchForm from './components/DomainSearchForm';
import { initializeDomains as initializeDomainsConnect } from '../../../actions/website';
import API from '../../../libs/api';
import ErrorHandler, { getErrorMessage } from '../../../libs/errors';
import Validate from '../../../libs/validate';
import { InlineFieldContainer } from '../../common';
import FormContainer from '../../common/FormContainer';
import IntegrationMessage from '../Integrations/IntegrationMessage';

import './DomainSelection.scss';

const propTypes = {
  canSkip: PropTypes.bool,
};

const defaultProps = {
  canSkip: false,
};

const mapDispatchToProps = (dispatch) => ({
  initializeDomains: (payload) => dispatch(initializeDomainsConnect(payload)),
});

const mapStateToProps = ({ website }) => ({
  website: _get(website, 'core.value', {}),
});

function DomainSelection({ website, initializeDomains, canSkip, onSkip }) {
  const [domainName, setDomainName] = useState('');
  const [selectedDomainType, setSelectedDomainType] = useState('');
  const [isValidDomain, setIsValidDomain] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [renderDomainSearch, setRenderDomainSearch] = useState(true);

  async function handleDomainChange(domain, domainType) {
    setDomainName(domain);
    setSelectedDomainType(domainType);
    setIsValidDomain(Validate.validate(Validate.TYPES.LINK, domain));
  }

  async function toggleDomainSearch(renderDomain) {
    setRenderDomainSearch(renderDomain);
    return handleDomainChange('', '');
  }

  async function onDomainConfirmed() {
    try {
      setSubmitting(true);
      setErrorMessage(null);

      await API.purchaseDomain({
        website_id: website.id,
        domain: domainName,
        transfer_preview_to_live: true,
        domain_type: selectedDomainType,
      });

      const result = await API.getDomains(website.id);
      const { data } = result;

      initializeDomains(data);
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
      <InlineFieldContainer enableDelete={false} className="domain-selection">
        <Segment attached="top" loading={submitting} disabled={submitting}>
          <DomainSearchForm
            show={renderDomainSearch}
            domainName={domainName}
            handleDomainChange={handleDomainChange}
          >
            <Container textAlign="center" className="domain-selection-toggle">
              <Button
                className="switch-form-button blue-color"
                onClick={() => toggleDomainSearch(false)}
                content="I already own a domain to connect"
                basic
              />
            </Container>
          </DomainSearchForm>
          <CustomDomainForm
            show={!renderDomainSearch}
            domainName={domainName}
            handleDomainChange={handleDomainChange}
          >
            <Container textAlign="center" className="domain-selection-toggle">
              <Button
                className="switch-form-button blue-color"
                onClick={() => toggleDomainSearch(true)}
                content="I want to search for a new domain"
                basic
              />
            </Container>
          </CustomDomainForm>
          <IntegrationMessage
            header={errorMessage}
            isError
            onDismiss={() => setErrorMessage(null)}
            className="error-message-container"
          />
        </Segment>
        <Segment attached="bottom">
          <Container textAlign="center">
            <Button
              size="big"
              content="Save and Continue"
              disabled={!isValidDomain || submitting}
              onClick={onDomainConfirmed}
              primary
            />
            {canSkip && (
              <Button size="big" content="Skip" onClick={() => onSkip('domainSelection')} />
            )}
          </Container>
        </Segment>
      </InlineFieldContainer>
    </FormContainer>
  );
}

DomainSelection.propTypes = propTypes;
DomainSelection.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(DomainSelection);
