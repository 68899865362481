import React from 'react';

import { Segment, Loader } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { createNotifications as createNotificationsConnect } from '../../actions/notificatons';
import { createUser as createUserConnect } from '../../actions/user';
import API from '../../libs/api';
import GoogleAnalytics from '../../libs/google-analytics';
import Storage from '../../libs/storage';
import WithRouter from '../modules/foundation/components/WithRouter';

class MagicLinkAuth extends React.Component {
  async componentDidMount() {
    await this.loginUser();
  }

  async loginUser() {
    const { location, createNotifications, createUser, navigate } = this.props;

    if (!location) {
      const errorMessage = 'No query parameters provided';
      navigate('/login/', { state: { errorMessage } });
    }
    const { search } = location;
    const {
      fisherman_auth_token: fishermanAuthToken,
      success_path: successPath,
      fail_path: failPath,
    } = API.parseSearch(search);

    try {
      const result = await API.verifyMagicLink(fishermanAuthToken);
      const { data } = result;
      const { user, expiration_time: expirationTime } = data;
      Storage.saveTokenExpirationTime(expirationTime);

      /* Initialize local state modules */
      GoogleAnalytics.identifyUser(user.id);
      createUser(user);
      createNotifications();

      navigate(successPath);
    } catch (e) {
      const errorMessage = _get(e, 'response.data.non_field_error');
      navigate(failPath, { state: { errorMessage } });
    }
  }

  render() {
    const { loginMessage = 'Logging you in' } = this.props;

    const style = {
      position: 'absolute',
      top: '35%',
      left: '40%',
      width: '300px',
      height: '100px',
      padding: '20px',
    };

    return (
      <div style={style}>
        <Segment style={{ padding: '100px' }}>
          <Loader size="massive" active>
            <h4>{loginMessage}</h4>
          </Loader>
        </Segment>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createUser: (payload) => dispatch(createUserConnect(payload)),
  createNotifications: (payload) => dispatch(createNotificationsConnect(payload)),
});

const mapStateToProps = ({ user }) => ({
  user: _get(user, 'core.value', {}),
});

export default WithRouter(connect(mapStateToProps, mapDispatchToProps)(MagicLinkAuth));
