export function getDefaultWritingActions({ onWriteDefault } = {}) {
  return [
    {
      title: 'Default',
      actions: [
        {
          title: 'Use Default',
          icon: 'pencil square',
          onClick: onWriteDefault,
        },
      ],
    },
  ];
}

export function getWritingAssistantActions({ onMakeSuggestion } = {}) {
  return [
    {
      title: 'Write with AI',
      actions: [
        {
          title: 'Make a suggestion',
          icon: 'pencil',
          onClick: onMakeSuggestion,
        },
      ],
    },
  ];
}
