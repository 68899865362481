export async function identifyLaunchDarklyUser(user, ldClient, { clear = false } = {}) {
  if (!ldClient || !user) {
    return Promise.resolve();
  }

  const ldClientUserContext = ldClient.getContext();
  if (!ldClientUserContext) {
    return Promise.resolve();
  }
  const { anonymous, key } = ldClientUserContext;

  // Don't do anything if the current user is already set correctly
  if ((!anonymous || key === user.id) && !clear) {
    return Promise.resolve();
  }

  const launchDarklyUser = {
    email: user.email,
    anonymous: false,
    key: user.username,
  };

  return new Promise((resolve) => {
    ldClient.identify(launchDarklyUser, null, () => resolve());
  });
}
