import React from 'react';

import PropTypes from 'prop-types';

import _isNil from 'lodash/isNil';
import { DateTimeInput } from 'semantic-ui-calendar-react';

import Field from './Field';
import DateUtils from '../../libs/date-utils/date-utils';

const propTypes = {
  externalDateTimeFormat: PropTypes.string,
  datetime: PropTypes.string,
  dateTimeFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  timeFormat: PropTypes.string,
  title: PropTypes.string,
  clearable: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
};

const defaultProps = {
  externalDateTimeFormat: '',
  datetime: '',
  dateTimeFormat: 'YYYY-MM-DDThh:mm',
  placeholder: 'Date',
  className: '',
  timeFormat: 'AMPM',
  title: '',
  clearable: true,
  minDate: '',
};

export default function DateTimeField({
  datetime,
  placeholder,
  className,
  onChange,
  title,
  timeFormat,
  dateTimeFormat,
  externalDateTimeFormat,
  disableMinute,
  clearable,
  minDate,
}) {
  const getExternalDateTimeFormat = () => {
    return externalDateTimeFormat || dateTimeFormat;
  };

  const formatDateTime = (value, fromInternalToExternal = false) => {
    const externalFormat = getExternalDateTimeFormat();
    if (_isNil(value) || value === '') {
      return '';
    }
    if (dateTimeFormat === externalFormat) {
      return DateUtils.format(value, dateTimeFormat);
    }
    if (fromInternalToExternal) {
      return DateUtils.format(value, externalFormat, dateTimeFormat);
    }
    return DateUtils.format(value, dateTimeFormat, externalFormat);
  };

  const handleChange = (e, { value, ...extraData }) => {
    const target = { ...extraData, value: formatDateTime(value, true) };
    onChange({ ...e, target: { ...e.target, ...target } }, target);
  };

  return (
    <Field title={title} className={className}>
      <DateTimeInput
        name="time"
        timeFormat={timeFormat}
        icon={false}
        placeholder={placeholder}
        value={formatDateTime(datetime)}
        iconPosition="left"
        onChange={handleChange}
        dateTimeFormat={dateTimeFormat}
        disableMinute={disableMinute}
        clearable={clearable}
        minDate={minDate}
      />
    </Field>
  );
}

DateTimeField.propTypes = propTypes;
DateTimeField.defaultProps = defaultProps;
