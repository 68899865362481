import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';

import { SketchPicker } from 'react-color';

import { ColorPropTypes } from './ColorPicker.propTypes';
import { getColorString, getColorValue } from './ColorPicker.utils';

import './ColorPicker.scss';

const propTypes = {
  color: ColorPropTypes.isRequired,
  onSelect: PropTypes.func.isRequired,
  disableAlpha: PropTypes.bool,
  withPopUpWrapper: PropTypes.bool,
};

const defaultProps = {
  disableAlpha: false,
  withPopUpWrapper: false,
};

export default function ColorPicker({ color, onSelect, withPopUpWrapper, disableAlpha }) {
  const [displayPicker, setDisplayPicker] = useState(false);

  function onChangeComplete(pickedColor) {
    onSelect(pickedColor);
  }

  function onPopupTrigger(event) {
    event.preventDefault();
    setDisplayPicker(true);
  }

  const backgroundColor = getColorString(color);

  const pickerTrigger = (
    <Button className="color-picker-trigger" onClick={onPopupTrigger}>
      <div className="color-picker-color" style={{ backgroundColor }} />
    </Button>
  );

  const colorPicker = (
    <SketchPicker
      onChangeComplete={onChangeComplete}
      color={getColorValue(color)}
      width="auto"
      disableAlpha={disableAlpha}
    />
  );

  if (withPopUpWrapper) {
    return (
      <Popup
        size="huge"
        wide
        content={displayPicker ? colorPicker : null}
        on="click"
        pinned
        position="right center"
        trigger={pickerTrigger}
      />
    );
  }

  return colorPicker;
}

ColorPicker.propTypes = propTypes;
ColorPicker.defaultProps = defaultProps;
