import { DEFAULT_GRID_CLASS_NAME } from './constants';
import { GRID_ELEMENT_TYPE, GRID_COMPONENT_NAME } from '../../../../constants/types';
import {
  lookupElementType,
  lookupElementTypeByComponentName,
  translateChildren,
} from '../utils/children';
import { addComponentChildren, createSemanticComponent, getComponentId } from '../utils/components';
import {
  addStaticPropIfDefined,
  createComponentBaseProps,
  createFinalClassName,
  extractComponentBaseProps,
  getStaticPropValue,
} from '../utils/props';

export function createGridElement(data = {}, context = {}) {
  const { id, className, style, stackable = true, rows = [] } = data;
  const { recipeStateToSnippet } = context;

  const finalClassName = createFinalClassName(className, DEFAULT_GRID_CLASS_NAME);

  let element = createSemanticComponent(
    GRID_COMPONENT_NAME,
    id,
    createComponentBaseProps(finalClassName, style),
  );

  element = addStaticPropIfDefined(element, 'stackable', stackable);

  const rowSnippets = translateChildren(rows, recipeStateToSnippet, lookupElementType, { context });

  return addComponentChildren(element, rowSnippets);
}

export function createGridState(recipeSnippet = {}, context = {}) {
  const { props, children = [] } = recipeSnippet || {};
  const { recipeSnippetToState } = context;

  const type = GRID_ELEMENT_TYPE;
  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  const stackable = getStaticPropValue(props, 'stackable');

  const rows = translateChildren(children, recipeSnippetToState, lookupElementTypeByComponentName, {
    context,
  });

  return {
    type,
    id,
    className,
    style,
    stackable,
    rows,
  };
}

export function createGridTemplateState(data = {}, context = {}) {
  const { recipeStateToTemplateState } = context;
  const { rows = [], ...restColumn } = data;

  const rowStates = translateChildren(rows, recipeStateToTemplateState, lookupElementType, {
    context,
  });

  return {
    ...restColumn,
    rows: rowStates,
  };
}
