import React, { useContext } from 'react';

import { Button, Icon, Popup, Checkbox } from 'semantic-ui-react';

import { getLabel } from './CategorySelector.utils';
import { toggleListValue } from '../../../foundation/state-management';
import { DEFAULT_CATEGORIES } from '../../constants';
import { TypographyContext } from '../../contexts/Typography.context';

import './CategorySelector.scss';

const propTypes = {};
const defaultProps = {};

export default function CategorySelector() {
  const { categories, setCategories } = useContext(TypographyContext);

  function onReset() {
    setCategories(DEFAULT_CATEGORIES);
  }

  function onChange(category) {
    toggleListValue(setCategories, category);
  }

  function getContent() {
    return (
      <div className="category-selector-popup-content-container">
        {DEFAULT_CATEGORIES.map((category) => (
          <Checkbox
            key={category}
            label={getLabel(category)}
            checked={categories.includes(category)}
            onChange={() => {
              onChange(category);
            }}
          />
        ))}
        <div className="reset-button-container">
          <Button content="Reset" onClick={onReset} />
        </div>
      </div>
    );
  }

  return (
    <div className="category-selector">
      <Popup
        trigger={
          <Button icon className="trigger">
            Categories <Icon name="chevron down" />
          </Button>
        }
        position="bottom left"
        on="click"
        content={getContent()}
      />
    </div>
  );
}

CategorySelector.propTypes = propTypes;
CategorySelector.defaultProps = defaultProps;
