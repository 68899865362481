import { TOOLBAR_VALUES } from '../MarkdownField/MarkdownField.constants';

export const MARKDOWN_OPTIONS = {
  toolbar: [
    TOOLBAR_VALUES.bold,
    TOOLBAR_VALUES.italic,
    TOOLBAR_VALUES.strikethrough,
    TOOLBAR_VALUES.separator,
    TOOLBAR_VALUES.unorderedList,
    TOOLBAR_VALUES.orderedList,
    TOOLBAR_VALUES.separator,
    TOOLBAR_VALUES.link,
    TOOLBAR_VALUES.separator,
    TOOLBAR_VALUES.preview,
    TOOLBAR_VALUES.sideBySide,
  ],
};

export const PROMPT_NAME = 'CatalogObjectDescriptionPrompt';
