import React from 'react';

import { Segment, Container, Image, Checkbox, Table } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { updateProviderServiceSetting as updateProviderServiceSettingConnect } from '../../../../actions/business';
import { PROFESSIONAL_PLAN_NAME } from '../../../../constants/auth';
import { SUPPORT_EMAIL, STACK_STATUS_LIVE } from '../../../../constants/constants';
import API from '../../../../libs/api';
import { hasFeatureSet } from '../../../../libs/auth';
import ErrorHandler from '../../../../libs/errors';
import { InlineFieldContainer } from '../../../common';
import FormContainer from '../../../common/FormContainer';

import UserWayLogo from '../../../../static/logos/userway_logo.png';

const mapDispatchToProps = (dispatch) => ({
  updateProviderServiceSetting: (payload) => dispatch(updateProviderServiceSettingConnect(payload)),
});

const mapStateToProps = ({ business, user, website }) => ({
  user: _get(user, 'core.value'),
  business: _get(business, 'core.value'),
  featureSets: _get(website, 'subscription.feature_sets'),
  stackStatus: _get(website, 'core.value.stack_status'),
  providerServiceSettings: _get(business, 'provider_service_settings.value'),
});

class UserWayIntegration extends React.Component {
  constructor(props) {
    super(props);

    this.getUserWaySetting = this.getUserWaySetting.bind(this);
    this.handleSettingToggle = this.handleSettingToggle.bind(this);
  }

  async handleSettingToggle(e, target) {
    const { checked } = target;
    const { business, updateProviderServiceSetting } = this.props;
    const userWaySetting = this.getUserWaySetting();

    const { id: businessId, type: businessType } = business;

    try {
      await API.updateProviderServiceSettings(businessId, businessType, userWaySetting.id, {
        ...userWaySetting,
        status_data: {
          ...userWaySetting.status_data,
          enabled: checked,
        },
      });

      const updatedProviderServiceSettingPayload = {
        index: userWaySetting.globalIndex,
        field: 'status_data',
        fieldValue: {
          enabled: checked,
        },
        bypassTouch: true,
      };

      updateProviderServiceSetting(updatedProviderServiceSettingPayload);
    } catch (error) {
      ErrorHandler.capture(error);
    }
  }

  /**
   * Used to get provider service settings that can be configured here
   */
  getUserWaySetting() {
    const { providerServiceSettings = [] } = this.props;

    return providerServiceSettings
      .map((pss, index) => ({ ...pss, globalIndex: index }))
      .find((pss) => pss.provider === 'UserWay' && pss.service_type === 'ADA');
  }

  getIntegrationForm(serviceSetting) {
    return (
      <Segment>
        <Table>
          <Table.Header>
            <Table.HeaderCell>Global Settings</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Enabled</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Add Userway ADA Widget to Website</Table.Cell>
              <Table.Cell textAlign="right">
                <Checkbox
                  name="website"
                  checked={serviceSetting.status_data.enabled}
                  onChange={this.handleSettingToggle}
                  toggle
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    );
  }

  render() {
    const { featureSets, stackStatus } = this.props;
    const serviceSetting = this.getUserWaySetting();

    if (!hasFeatureSet(featureSets, PROFESSIONAL_PLAN_NAME)) return null;
    if (stackStatus !== STACK_STATUS_LIVE) return null;

    return (
      <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
        <InlineFieldContainer title={<h2>UserWay</h2>} enableDelete={false}>
          <Segment.Group>
            <Segment>
              <Container>
                <Container textAlign="center">
                  <Image
                    src={UserWayLogo}
                    style={{
                      display: 'inline',
                      margin: '30px',
                      width: '200px',
                    }}
                  />
                </Container>
                {serviceSetting ? (
                  <>
                    <Container>
                      The UserWay web accessibility widget is an add-on for websites to help improve
                      their compliance with accessibility standards. Enable it here and it will be
                      added to your site on the next publish.
                    </Container>
                    {this.getIntegrationForm(serviceSetting)}
                  </>
                ) : (
                  <Container>
                    The UserWay web accessibility widget is an add-on for websites to help improve
                    their compliance with accessibility standards. This feature has not been
                    provisioned on your account, please contact {SUPPORT_EMAIL} to enable this
                    feature.
                  </Container>
                )}
              </Container>
            </Segment>
          </Segment.Group>
        </InlineFieldContainer>
      </FormContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserWayIntegration);
