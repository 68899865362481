import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import {
  createColumnState,
  createRowState,
  createPageFromSettings,
  elementHasRequiredValues,
  getNodeStatesList,
  getGridUpdatedClassName,
} from './utils';
import { ELEMENT_TYPE, AUTO_ADJUSTED_COLUMN_SIZE_MODE } from '../../constants/types';
import { REQUIRED_ELEMENT_FIELDS } from '../../constants/validation';
import useRecipeNodeManager from '../use-recipe-node-manager';
import useRecipeElementTranslator from '../use-recipe-node-translator';

export default function useRecipeNodePage({ page, node: initialNode }) {
  const {
    recipeSnippetToState,
    recipeStateToSnippet,
    recipeStateToTemplateState,
    recipeStateToSnippetTemplate,
  } = useRecipeElementTranslator({ page });

  const {
    node,
    getNode,
    setNode,
    getParentNode,
    updateNode,
    addNode,
    addNodeAbove,
    addNodeBelow,
    removeNode,
  } = useRecipeNodeManager(initialNode);

  const pageNode = node;
  const pageState = recipeSnippetToState(ELEMENT_TYPE.Page, node);

  function getCurrentNode() {
    return node;
  }

  /* *********** Rows *********** */
  function initializeRowState(state) {
    return createRowState(recipeSnippetToState, state);
  }

  function rowOperation(nodeId, operation, state = {}) {
    const newRow = recipeStateToSnippet(ELEMENT_TYPE.Row, initializeRowState(state));
    return operation(nodeId, newRow);
  }

  function addRowAbove(siblingNodeId, state = {}) {
    const defaultState = { ...state, columnSizingMode: AUTO_ADJUSTED_COLUMN_SIZE_MODE };
    return rowOperation(siblingNodeId, addNodeAbove, defaultState);
  }

  function addRowBelow(siblingNodeId, state = {}) {
    const defaultState = { ...state, columnSizingMode: AUTO_ADJUSTED_COLUMN_SIZE_MODE };
    return rowOperation(siblingNodeId, addNodeBelow, defaultState);
  }

  function updateRow(nodeId, state) {
    return rowOperation(nodeId, updateNode, state);
  }

  function ensureRowUpToDate(rowNodeId, referenceNode) {
    const row = getNode(rowNodeId, referenceNode);
    const rowState = recipeSnippetToState(ELEMENT_TYPE.Row, row);
    const { id: rowId } = rowState;
    const updatedRow = updateRow(rowId, rowState);
    return updatedRow;
  }

  function ensureParentRowUpToDate(columnNodeId, referenceNode) {
    const row = getParentNode(columnNodeId, referenceNode) || {};
    const { id: rowId } = row;
    if (_isEmpty(rowId)) {
      return referenceNode;
    }
    return ensureRowUpToDate(rowId, referenceNode);
  }

  /* *********** Columns *********** */
  function getColumnRowState(nodeId) {
    const row = getParentNode(nodeId);
    return recipeSnippetToState(ELEMENT_TYPE.Row, row);
  }

  function initializeColumnState(state, nodeId) {
    const row = getColumnRowState(nodeId);
    return createColumnState(recipeSnippetToState, row, state);
  }

  function columnOperation(nodeId, operation, state = {}) {
    const newColumn = recipeStateToSnippet(
      ELEMENT_TYPE.Column,
      initializeColumnState(state, nodeId),
    );
    return operation(nodeId, newColumn);
  }

  function addColumnToRight(siblingNodeId, state = {}) {
    const newNode = columnOperation(siblingNodeId, addNodeBelow, state);
    return ensureParentRowUpToDate(siblingNodeId, newNode);
  }

  function addColumnToLeft(siblingNodeId, state = {}) {
    const newNode = columnOperation(siblingNodeId, addNodeAbove, state);
    return ensureParentRowUpToDate(siblingNodeId, newNode);
  }

  function removeColumn(nodeId) {
    const row = getParentNode(nodeId);
    const { id: rowParentId } = row;
    const newNode = removeNode(nodeId);
    return ensureRowUpToDate(rowParentId, newNode);
  }

  function updateColumn(nodeId, state = {}) {
    return columnOperation(nodeId, updateNode, state);
  }

  /* *********** Blocks *********** */
  function blockOperation(nodeId, operation, type, state = {}) {
    const newBlock = recipeStateToSnippet(type, state);
    return operation(nodeId, newBlock);
  }

  function createBlock(parentNodeId, type, state = {}) {
    return blockOperation(parentNodeId, addNode, type, state);
  }

  function updateBlock(nodeId, type, state) {
    return blockOperation(nodeId, updateNode, type, state);
  }

  /* *********** Pages *********** */

  function createPage(rows, columns, pageConfig, style, className) {
    const nextPageState = createPageFromSettings(
      { rows, columns, pageConfig, style, className },
      recipeSnippetToState,
    );
    const nextPage = recipeStateToSnippet(ELEMENT_TYPE.Page, nextPageState);
    setNode(nextPage);
    return nextPage;
  }

  function updatePage(state) {
    const nextPage = recipeStateToSnippet(ELEMENT_TYPE.Page, state);
    setNode(nextPage);

    return nextPage;
  }

  function createTemplatePage() {
    const templateState = recipeStateToTemplateState(ELEMENT_TYPE.Page, pageState);

    return recipeStateToSnippetTemplate(ELEMENT_TYPE.Page, templateState);
  }

  function updateGridClassName(oldClassName, newClassName) {
    const updatedClassNames = getGridUpdatedClassName(pageNode, oldClassName, newClassName);
    const newNode = _cloneDeep(pageNode);
    _set(newNode, 'components.1.props.0.resolve', updatedClassNames);
    setNode(newNode);
    return newNode;
  }

  /* *********** Validation *********** */
  function isValidElement(element) {
    return elementHasRequiredValues(element, REQUIRED_ELEMENT_FIELDS);
  }

  function isValidPage() {
    const allNodes = getNodeStatesList(pageState);

    return allNodes.every((nodeState) => isValidElement(nodeState));
  }

  return {
    pageNode,
    pageState,
    setNode,
    removeNode,
    getColumnRowState,
    addColumnToRight,
    addColumnToLeft,
    removeColumn,
    updateColumn,
    getCurrentNode,
    addRowAbove,
    addRowBelow,
    updateRow,
    createBlock,
    updateBlock,
    createPage,
    updateGridClassName,
    updatePage,
    createTemplatePage,
    isValidElement,
    isValidPage,
  };
}
