import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form, FormField, Icon } from 'semantic-ui-react';

import OrderCutoffSwitchModal from './components/OrderCutoffSwitchModal';
import { ORDER_CUTOFF_SWITCH } from '../../../../OrderSettings.constants';

import './OrderCutoffSwitchField.scss';

const propTypes = {
  handleOnChangeForm: PropTypes.func.isRequired,
  initialValue: PropTypes.bool.isRequired,
};

export default function OrderCutoffSwitchField({ handleOnChangeForm, initialValue }) {
  const [isOpen, setIsOpen] = useState(false);
  const [active, setIsActive] = useState(initialValue);

  function handleOnChange(e, { checked }) {
    if (checked) {
      setIsOpen(true);
    } else {
      setIsActive(false);
      handleOnChangeForm(e, { checked: false, name: ORDER_CUTOFF_SWITCH });
    }
  }

  function onClose() {
    setIsOpen(false);
  }

  function handleConfirmation(e) {
    const isValid = true;
    const checked = true;
    const name = ORDER_CUTOFF_SWITCH;
    setIsActive(checked);

    handleOnChangeForm(e, { checked, name }, isValid);
    onClose();
  }

  useEffect(() => {
    setIsActive(initialValue);
  }, [initialValue]);

  return (
    <div className="order-cutoff-switch">
      <FormField className="order-cutoff-switch-form-field">
        <span>
          <Icon name="warning sign" />
          USE THIS OPTION WITH CAUTION!
        </span>
        <Form.Checkbox
          checked={active}
          onChange={handleOnChange}
          name={ORDER_CUTOFF_SWITCH}
          label="Stop accepting all orders"
        />
        <OrderCutoffSwitchModal
          handleConfirmation={handleConfirmation}
          onClose={onClose}
          isOpen={isOpen}
        />
      </FormField>
    </div>
  );
}

OrderCutoffSwitchField.propTypes = propTypes;
