import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { getOptions } from './BusinessSubtypeField.utils';

import './BusinessSubtypeField.scss';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  leadSource: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
};

const defaultProps = {
  label: 'Business Type',
  placeholder: 'What does your business specialize in?',
  leadSource: '',
  value: '',
  required: true,
};

export default function BusinessSubtypeField({
  label,
  placeholder,
  onChange,
  leadSource,
  value,
  required,
}) {
  return (
    <Form.Select
      className="business-subtype-field-select"
      required={required}
      value={value}
      placeholder={placeholder}
      fluid
      search
      selection
      label={label}
      options={getOptions(leadSource)}
      onChange={onChange}
    />
  );
}

BusinessSubtypeField.propTypes = propTypes;
BusinessSubtypeField.defaultProps = defaultProps;
