import { TEXT_CONTENT_TYPE } from '../components/modules/hero/hooks/use-custom-hero-manager/services/constants';

export function createComponentConfigurationContentPayload({
  interactions = [],
  contentNodes = [],
  files = [],
} = {}) {
  const interactionsPayload = interactions.map(
    ({ id, displayType, behaviorType, url, enabled = true, style, label, order }) => ({
      id,
      display_type: displayType,
      behavior_type: behaviorType,
      url,
      enabled,
      style,
      label,
      order,
    }),
  );

  const contentNodesPayload = contentNodes.map(
    ({
      id,
      contentName,
      content = '',
      order,
      contentType = TEXT_CONTENT_TYPE,
      required = false,
      hidden = false,
      metadata = {},
      maxLength,
    }) => ({
      id,
      content_name: contentName,
      content,
      order,
      content_type: contentType,
      required,
      hidden,
      metadata,
      max_length: maxLength,
    }),
  );

  const filesPayload = files.map(({ id, order, metadata = {} }) => ({
    id,
    order,
    metadata,
  }));

  return {
    interactions: interactionsPayload,
    contentNodes: contentNodesPayload,
    files: filesPayload,
  };
}
