import React from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  FIELD_CREATED_AT_LABEL,
  FIELD_CREATED_AT_NAME,
  FIELD_ID_LABEL,
  FIELD_ID_NAME,
  FORM_HEADER_CONTACT_INFO,
  FORM_HEADER_LOCATION_INFO,
  FORM_HEADER_OTHER_FIELDS,
  FORM_HEADER_SUBMISSION_INFO,
} from './CustomerSubmissionsModal.constants';
import CustomerSubmissionsModalFileCardGroup from './components/CustomerSubmissionsModalFileCardGroup';
import CustomerSubmissionsModalTable from './components/CustomerSubmissionsModalTable';
import CloseableModal from '../../../../common/CloseableModal';
import CustomerSubmissionPropType from '../../types/form-submission';
import { formatDatetime } from '../CustomerSubmissionsTableBody/CustomerSubmissionsTableBody.utils';
import {
  CONTACT_EMAIL_FIELD,
  CONTACT_NAME_FIELD,
  CONTACT_PHONE_FIELD,
} from '../CustomerSubmissionsTableRow/CustomerSubmissionsTableRow.constants';

import './CustomerSubmissionsModal.css';

const propTypes = {
  open: PropTypes.bool.isRequired,
  submission: CustomerSubmissionPropType.isRequired,
  setOpen: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function CustomerSubmissionsModal({ open, submission, setOpen }) {
  const submissionId = _get(submission, 'id', '');
  const contactData = _get(submission, 'contactFields', {});
  const fileData = _get(submission, 'fileFields', {});
  const eventData = _get(submission, 'eventFields', {});
  const locationData = _get(submission, 'location', {});

  function handleClose() {
    setOpen(false);
  }

  return (
    <CloseableModal
      className="customer-submissions-modal"
      header="Customer Submission"
      open={open}
      onClose={handleClose}
    >
      <Modal.Content scrolling>
        <CustomerSubmissionsModalTable
          data={[
            {
              name: FIELD_ID_NAME,
              label: FIELD_ID_LABEL,
              value: submission.id,
            },
            {
              name: FIELD_CREATED_AT_NAME,
              label: FIELD_CREATED_AT_LABEL,
              value: formatDatetime(submission.createdAt),
            },
          ]}
          headerText={FORM_HEADER_SUBMISSION_INFO}
        />

        {!_isEmpty(locationData) && (
          <CustomerSubmissionsModalTable
            headerText={FORM_HEADER_LOCATION_INFO}
            data={[
              {
                name: 'displayName',
                label: 'Display Name',
                value: locationData.displayName,
              },
              {
                name: 'streetAddress',
                label: 'Street Address',
                value: locationData.streetAddress,
              },
            ]}
          />
        )}

        <CustomerSubmissionsModalTable
          headerText={FORM_HEADER_CONTACT_INFO}
          data={[
            {
              name: 'name',
              label: 'Name',
              value: _get(contactData, CONTACT_NAME_FIELD, ''),
            },
            {
              name: 'email',
              label: 'Email',
              value: _get(contactData, CONTACT_EMAIL_FIELD, ''),
            },
            {
              name: 'phone',
              label: 'Phone',
              value: _get(contactData, CONTACT_PHONE_FIELD, ''),
            },
          ]}
        />

        <CustomerSubmissionsModalTable headerText={FORM_HEADER_OTHER_FIELDS} data={eventData} />
        <CustomerSubmissionsModalFileCardGroup submissionId={submissionId} fileData={fileData} />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" className="action" onClick={handleClose} />
      </Modal.Actions>
    </CloseableModal>
  );
}

CustomerSubmissionsModal.propTypes = propTypes;
CustomerSubmissionsModal.defaultProps = defaultProps;
