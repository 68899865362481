import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Label } from 'semantic-ui-react';

import _toNumber from 'lodash/toNumber';

import { calculateStepString } from './PercentField.utils';
import { isValidPercent } from '../../../libs/percent';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  placeholder: PropTypes.string,
  decimalPrecision: PropTypes.oneOf([0, 1, 2, 3, 4]),
  fluid: PropTypes.bool,
};

const defaultProps = {
  initialValue: 0,
  name: '',
  placeholder: '',
  decimalPrecision: 2,
  fluid: false,
};

function PercentField({ onChange, initialValue, name, placeholder, decimalPrecision, fluid }) {
  const parsedInitialPercent = _toNumber(initialValue);
  const [percent, setPercent] = useState(
    isValidPercent(parsedInitialPercent, decimalPrecision) ? parsedInitialPercent : 0,
  );

  function handleInputChange(e, { name: fieldName, value }) {
    if (!isValidPercent(_toNumber(value), decimalPrecision)) {
      return;
    }
    setPercent(value);
    onChange(e, { name: fieldName, value });
  }

  return (
    <Form.Input
      className="percent-field"
      fluid={fluid}
      value={percent}
      type="number"
      step={calculateStepString(decimalPrecision)}
      name={name}
      placeholder={placeholder}
      onChange={handleInputChange}
      min={0}
      max={100}
      labelPosition="right"
    >
      <input />
      <Label>%</Label>
    </Form.Input>
  );
}

PercentField.propTypes = propTypes;
PercentField.defaultProps = defaultProps;

export default PercentField;
