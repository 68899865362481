import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Message } from 'semantic-ui-react';

import _isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import WoflowFilesUploadModal from './components/WoflowFilesUploadModal/WoflowFilesUploadModal';
import WoflowMenuJobsTable from './components/WoflowMenuJobsTable';
import API from '../../../../libs/api';
import ErrorHandler, { getErrorMessage } from '../../../../libs/errors';
import { getWoflowJobsData, isWoflowJob } from '../../../../libs/menu/woflow';
import { selectBusinessType, selectBusinessId } from '../../../../selectors/business';
import { selectAllProviderServiceSettings } from '../../../../selectors/provider-service-settings';

import './WoflowMenuActions.scss';
import '../../../../styles/core/forms/menu.scss';
import '../MenuFiles/MenuFiles.scss';

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
const defaultProps = {
  open: null,
  onClose: null,
};

function WoflowMenuActions({ open, onClose, jobDestination }) {
  const providerServiceSettings = useSelector(selectAllProviderServiceSettings).filter(isWoflowJob);
  const businessType = useSelector(selectBusinessType);
  const businessId = useSelector(selectBusinessId);
  const defaultMessage = { message: null, error: false, success: false };
  const [displayMessage, setDisplayMessage] = useState(defaultMessage);
  const [loading, setLoading] = useState(false);
  const [isHandled, setIsHandled] = useState(!_isNil(open));

  useEffect(() => {
    setIsHandled(!_isNil(open));
  }, [open]);

  function handleMessageDismiss() {
    setDisplayMessage(defaultMessage);
  }

  function handleMessage({ message, error, success }) {
    setDisplayMessage({ message, error, success });
  }

  function handleSuccessMessage(msg) {
    handleMessage({ message: msg, error: false, success: true });
  }

  function handleErrorMessage(msg) {
    handleMessage({ message: msg, error: true, success: false });
  }

  async function handleApproveJob(jobId) {
    try {
      setLoading(true);
      setDisplayMessage(defaultMessage);
      await API.approveWoflowMenu(businessType, businessId, jobId);
    } catch (e) {
      ErrorHandler.capture(e);
      handleErrorMessage(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  const jobsData = getWoflowJobsData(providerServiceSettings);
  return (
    <div className="woflow-menu-actions menu-item-container">
      <div className="menu-item">
        <div className="menu-item-content">
          <div>
            <span>
              <WoflowFilesUploadModal
                handleSuccessMessage={handleSuccessMessage}
                isOpen={isHandled ? open : undefined}
                onOpen={() => {}}
                trigger={isHandled ? undefined : <Button>Upload Menu Files</Button>}
                onClose={isHandled ? onClose : undefined}
                jobDestination={jobDestination}
              />
            </span>
          </div>
          {displayMessage.message && (
            <div className="woflow-error-message">
              <Message
                error={displayMessage.error}
                success={displayMessage.success}
                header={displayMessage.message}
                onDismiss={handleMessageDismiss}
              />
            </div>
          )}
          <WoflowMenuJobsTable
            jobsData={jobsData}
            handleApproveMenu={handleApproveJob}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

WoflowMenuActions.propTypes = propTypes;
WoflowMenuActions.defaultProps = defaultProps;
export default WoflowMenuActions;
