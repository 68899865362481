import { SQUARE_PROVIDER, SQUARE_SERVICE_TYPE } from './SquareIntegration.constants';
import { getProviderService, getProviderServiceSetting } from '../utils';

export function getSquareProviderService(providerServices) {
  return getProviderService(providerServices, SQUARE_PROVIDER, SQUARE_SERVICE_TYPE);
}

export function getSquareProviderServiceSetting(providerServiceSettings) {
  return getProviderServiceSetting(providerServiceSettings, SQUARE_PROVIDER, SQUARE_SERVICE_TYPE);
}

export function formatHeaderMessage(str) {
  return str
    .replace(/[^a-zA-Z\s]/g, ' ') // Remove non-alphabetic characters (excluding spaces)
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .trim(); // Remove leading and trailing spaces
}
