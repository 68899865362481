import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const propTypes = {
  title: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  setEnabled: PropTypes.func.isRequired,
};

function EnabledToggle({ title, enabled, setEnabled }) {
  return (
    <Checkbox
      className="website-component-interaction-enabled-toggle"
      toggle
      checked={enabled}
      onChange={(e, data) => setEnabled(data.checked)}
      label={title}
    />
  );
}

EnabledToggle.propTypes = propTypes;
export default EnabledToggle;
