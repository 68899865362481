import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import CustomerReportsTableBody from './components/CustomerReportsTableBody';
import { reportsCustomerPropType } from './components/CustomerReportsTableBody/CustomerReportsTableBody.proptypes';
import CustomerReportsTableFooter from './components/CustomerReportsTableFooter';
import CustomerReportsTableHeader from './components/CustomerReportsTableHeader';

import './CustomerReportsTable.scss';

const propTypes = {
  customers: PropTypes.arrayOf(reportsCustomerPropType).isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

const defaultProps = {};

function CustomerReportsTable({ customers, onPageChange, currentPage, totalPages }) {
  return (
    <div className="customer-reports-table">
      <Table sortable compact celled singleLine>
        <CustomerReportsTableHeader />
        <CustomerReportsTableBody customers={customers} />
        <CustomerReportsTableFooter
          onPageChange={onPageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Table>
    </div>
  );
}

CustomerReportsTable.propTypes = propTypes;
CustomerReportsTable.defaultProps = defaultProps;

export default CustomerReportsTable;
