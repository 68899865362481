import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Popup } from 'semantic-ui-react';

import _startCase from 'lodash/startCase';

import { ColorPropType } from './ColorColumns.proptypes';

import styles from './ColorColumns.module.css';

const propTypes = {
  colors: PropTypes.arrayOf(ColorPropType).isRequired,
};

function ColorColumns({ colors }) {
  return (
    <Grid
      className={styles.colorContainer}
      columns={colors.length}
      textAlign="center"
      verticalAlign="middle"
    >
      <Grid.Row className={styles.colorRow}>
        {colors.map(({ color, role }) => (
          <Popup
            content={`#${color}`}
            key={role}
            header={_startCase(role)}
            basic
            trigger={
              <Grid.Column
                key={color}
                className={styles.colorCell}
                style={{ backgroundColor: `#${color}` }}
              />
            }
          />
        ))}
      </Grid.Row>
    </Grid>
  );
}

ColorColumns.propTypes = propTypes;
export default ColorColumns;
