import React from 'react';

import useBranding from '../../../modules/foundation/hooks/use-branding';

function MainNavigationLogo() {
  const { brandLogoURL, brandName } = useBranding();
  return (
    <>
      <img
        className="main-navigation-logo"
        src={brandLogoURL}
        alt={`${brandName} Logo`}
        style={{ height: '36px' }}
      />
    </>
  );
}

export default MainNavigationLogo;
