import React from 'react';

import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import _chunk from 'lodash/chunk';
import _orderBy from 'lodash/orderBy';

import { ChildrenPropType } from 'libs/proptypes';

import GridViewColumn from './components/GridViewColumn';

import './GridView.scss';

const propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func.isRequired,
  getItemExtraParams: PropTypes.func,
  columns: PropTypes.number,
  isFileDisabled: PropTypes.func,
  isFileSelected: PropTypes.func,
  actionsComponentRight: ChildrenPropType,
};

const defaultProps = {
  files: [],
  getItemExtraParams: () => undefined,
  columns: 4,
  isFileDisabled: () => false,
  isFileSelected: () => false,
  actionsComponentRight: null,
};

export default function GridView({
  files,
  onClick,
  getItemExtraParams,
  isFileDisabled,
  isFileSelected,
  columns,
  showSourceTags,
  showFileNames,
  actionsComponentRight,
}) {
  const orderedFiles = _orderBy(files, ['createdAt'], ['desc']);
  const chunkedFiles = _chunk(orderedFiles, columns);

  const renderRow = (someFiles) => {
    return (
      <Grid.Row columns={columns} stretched key={`row_${someFiles[0].id}`}>
        {someFiles.map((record) => (
          <GridViewColumn
            key={`file_${record.id}`}
            record={record}
            onClick={onClick}
            isFileDisabled={isFileDisabled}
            isFileSelected={isFileSelected}
            getItemExtraParams={getItemExtraParams}
            showSourceTags={showSourceTags}
            showFileNames={showFileNames}
            actionsComponentRight={actionsComponentRight}
          />
        ))}
      </Grid.Row>
    );
  };

  return (
    <Grid padded stackable columns={columns} className="files-grid-view">
      {chunkedFiles.map((chunkOfFiles) => renderRow(chunkOfFiles))}
    </Grid>
  );
}

GridView.propTypes = propTypes;
GridView.defaultProps = defaultProps;
