import React from 'react';

import { HtmlElementsList, HtmlElementsToolBar } from './components';

import './HtmlElementsContainer.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsContainer() {
  return (
    <div>
      <HtmlElementsToolBar />
      <HtmlElementsList />
    </div>
  );
}

HtmlElementsContainer.propTypes = propTypes;
HtmlElementsContainer.defaultProps = defaultProps;
