import _get from 'lodash/get';

import { BEHAVIOR_INTERNAL_LINK, BEHAVIOR_LABEL } from '../constants';
import { ensureSlashes, getPageUrl } from '../utils';

export function getDropdownText(behavior, url, availablePages) {
  if (behavior === BEHAVIOR_INTERNAL_LINK) {
    const page = availablePages.find(
      (p) => getPageUrl(p) === url || ensureSlashes(getPageUrl(p)) === url,
    );
    const pageTitle = _get(page, 'title') || _get(page, 'page_type');

    return pageTitle ? `Visit "${pageTitle}" page` : '';
  }

  return BEHAVIOR_LABEL[behavior] || behavior;
}
