/* eslint-disable react/sort-comp */
import React from 'react';

import { Input, Radio, Button } from 'semantic-ui-react';

import { connect } from 'react-redux';

import MenuScheduleAvailabilityPicker from './MenuScheduleAvailabilityPicker';
import {
  updateMenuSchedule as updateMenuScheduleConnect,
  deleteMenuSchedule as deleteMenuScheduleConnect,
} from '../../../../actions/business';
import API from '../../../../libs/api';
import { InventoryConsumer } from '../../MenuForm/Menu.context';
import EditMenuModal from '../common/EditMenuModal';

class EditMenuSchedule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
      saveLoading: false,
      deleteLoading: false,
    };

    this.onSaveNewSchedule = this.onSaveNewSchedule.bind(this);
    this.onSaveSchedule = this.onSaveSchedule.bind(this);
    this.onDeleteSchedule = this.onDeleteSchedule.bind(this);
    this.onDeleteNewMenuSchedule = this.onDeleteNewMenuSchedule.bind(this);
  }

  isCustomAvailability() {
    const { availableDays = [] } = this.props;
    return !(availableDays.length === 1 && availableDays[0] === 'ALL');
  }

  removeAllBusinessHoursAvailability() {
    const { availableDays } = this.props;

    const index = availableDays.indexOf('ALL');

    if (index > -1) {
      return [...availableDays.slice(0, index), ...availableDays.slice(index + 1, availableDays)];
    }

    return availableDays;
  }

  setErrorMessage(errorMessage, clear = true) {
    this.setState({ errorMessage });

    if (clear) {
      setTimeout(() => this.setErrorMessage('', false), 5000);
    }
  }

  validateInputs() {
    const { name } = this.props;
    const { entityName } = this.context;

    if (!name) return `Please enter a ${entityName} name.`;
    return true;
  }

  setLoading(type, value) {
    this.setState({ [type]: value });
  }

  async onSaveNewSchedule() {
    const { entityName } = this.context;
    const {
      name,
      availableDays,
      startTime,
      endTime,
      business,
      index,
      updateMenuSchedule,
      onClose,
    } = this.props;

    const { id: businessId, type: businessType } = business;

    if (this.validateInputs() !== true) {
      this.setErrorMessage(this.validateInputs());
      return;
    }

    this.setLoading('saveLoading', true);

    try {
      const { data } = await API.createMenuSchedule(businessId, businessType, {
        name,
        available_days: availableDays,
        start_time: startTime,
        end_time: endTime,
        order: index,
      });

      const fieldsToSave = { ...data, index };
      updateMenuSchedule({ index, values: fieldsToSave });
      onClose();
    } catch (e) {
      this.setErrorMessage(`There was an error creating the ${entityName}.`);
    }

    this.setLoading('saveLoading', false);
  }

  async onSaveSchedule() {
    const { entityName } = this.context;
    const {
      name,
      availableDays,
      startTime,
      endTime,
      business,
      index,
      id,
      updateMenuSchedule,
      onClose,
    } = this.props;

    const { id: businessId, type: businessType } = business;

    if (this.validateInputs() !== true) {
      this.setErrorMessage(this.validateInputs());
      return;
    }

    this.setLoading('saveLoading', true);

    try {
      const { data } = await API.updateMenuSchedule(businessId, businessType, id, {
        name,
        available_days: availableDays,
        start_time: startTime,
        end_time: endTime,
      });

      const fieldsToSave = { ...data, index };
      updateMenuSchedule({ index, values: fieldsToSave });
      onClose();
    } catch (e) {
      this.setErrorMessage(`There was an error saving the ${entityName}.`);
    }

    this.setLoading('saveLoading', false);
  }

  async onDeleteSchedule() {
    const { entityName } = this.context;
    const { id, business, index, deleteMenuSchedule, onClose } = this.props;
    const { id: businessId, type: businessType } = business;

    this.setLoading('deleteLoading', true);

    try {
      await API.deleteMenuSchedule(businessId, businessType, id);
      deleteMenuSchedule(index);
      onClose();
    } catch (e) {
      this.setErrorMessage(`There was an error deleting the ${entityName}.`);
    }

    this.setLoading('deleteLoading', false);
  }

  onDeleteNewMenuSchedule() {
    const { index, deleteMenuSchedule, onClose } = this.props;

    deleteMenuSchedule(index);
    onClose();
  }

  render() {
    const {
      id,
      trigger,
      name,
      availableDays,
      startTime,
      endTime,
      isNew,
      index,
      updateMenuSchedule,
      open,
      onClose,
      onOpen,
    } = this.props;
    const { errorMessage, saveLoading, deleteLoading } = this.state;

    return (
      <InventoryConsumer>
        {({ entityName, copies: { entityDescription } }) => (
          <EditMenuModal
            open={open}
            openModal={onOpen}
            closeModal={() => {
              if (!id) {
                this.onDeleteNewMenuSchedule();
              }
              onClose();
            }}
            className="edit-menu-schedule"
            trigger={trigger}
            newItemTitle={`Create ${entityName}`}
            existingItemTitle={`Edit ${entityName}`}
            subtitle={entityDescription}
            isNewItem={isNew}
            titleExample="Breakfast"
            content={
              <>
                <h2 className="red asterisk">Name</h2>
                <Input
                  defaultValue={name}
                  placeholder="i.e. Lunch"
                  onChange={(e, { value }) =>
                    updateMenuSchedule({
                      index,
                      values: { name: value },
                    })
                  }
                />
                <h2>Availability</h2>
                <div>Customers can order from this {entityName} during these times:</div>
                <br />
                <div>
                  <Radio
                    label="All Business Hours"
                    checked={!this.isCustomAvailability()}
                    onChange={() =>
                      updateMenuSchedule({
                        index,
                        values: { available_days: ['ALL'] },
                      })
                    }
                  />
                </div>
                <div>
                  <Radio
                    label="Custom"
                    checked={this.isCustomAvailability()}
                    onChange={() =>
                      updateMenuSchedule({
                        index,
                        values: {
                          available_days: this.removeAllBusinessHoursAvailability(),
                        },
                      })
                    }
                  />
                  <MenuScheduleAvailabilityPicker
                    isCustomAvailability={this.isCustomAvailability()}
                    availableDays={availableDays || []}
                    startTime={startTime}
                    endTime={endTime}
                    updateMenuSchedule={updateMenuSchedule}
                    index={index}
                  />
                </div>
              </>
            }
            actions={
              <>
                {errorMessage && <div className="menu-error-message">{errorMessage}</div>}
                {!isNew && (
                  <Button
                    color="red"
                    onClick={this.onDeleteSchedule}
                    loading={deleteLoading}
                    content="Delete"
                  />
                )}
                <Button
                  className="action"
                  onClick={isNew ? this.onSaveNewSchedule : this.onSaveSchedule}
                  loading={saveLoading}
                  content="Save"
                />
              </>
            }
          />
        )}
      </InventoryConsumer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateMenuSchedule: (payload) => dispatch(updateMenuScheduleConnect(payload)),
  deleteMenuSchedule: (payload) => dispatch(deleteMenuScheduleConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  openModalIndex: business.menu.value.openModalIndex,
  business: business.core.value,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMenuSchedule);
