export const CATALOG_OBJECT_TYPE_CATEGORY = 'CATEGORY';
export const CATALOG_OBJECT_TYPE_ITEM = 'ITEM';
export const CATALOG_OBJECT_TYPE_TAX = 'TAX';
export const CATALOG_OBJECT_TYPE_IMAGE = 'IMAGE';
export const CATALOG_OBJECT_TYPE_ITEM_VARIATION = 'ITEM_VAR';
export const CATALOG_OBJECT_TYPE_MODIFIER_LIST = 'MOD_LIST';
export const CATALOG_OBJECT_TYPE_MODIFIER = 'MOD';

export const SELECTION_TYPE_SINGLE = 'SINGLE';
export const SELECTION_TYPE_MULTIPLE = 'MULTIPLE';
export const PRICING_TYPE_FIXED = 'FIXED';
export const PRICING_TYPE_VARIABLE = 'VARIABLE';
export const BUSINESS_TYPE_MERCHANTS = 'merchants';

export const CATALOG_MIN_ORDINAL = 'aaaaaa';
export const CATALOG_MAX_ORDINAL = 'zzzzzz';

export const FETCHING_CATALOG_ERROR_MESSAGE =
  'An unexpected error occurred while fetching the catalog. Please try refreshing the page and contact customer support if the error persists.';

export const EMPTY_CATALOG_ITEM = {
  item_data: {
    category: '',
    images: [],
    taxes: [],
    modifier_lists: [],
    name: '',
    description: '',
    ordinal: 0,
    visible: true,
    available: true,
  },
  type: CATALOG_OBJECT_TYPE_ITEM,
};

export const EMPTY_CATALOG_TAX = {
  tax_data: {
    name: '',
    rate: '0',
    ordinal: 0,
  },
  type: CATALOG_OBJECT_TYPE_TAX,
};
