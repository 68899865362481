import { DEFAULT_OPTIONS, OPTIONS_BY_LEAD_SOURCE } from './BusinessSubtypeField.constants';

export function getOptions(leadSource = '') {
  const options = OPTIONS_BY_LEAD_SOURCE[leadSource || ''] || DEFAULT_OPTIONS;

  const keys = Object.keys(options).map((category) => {
    const subcategories = options[category].map((subcategory) => {
      const key = `${category}:${subcategory}`;

      return {
        key,
        value: key,
        text: subcategory,
      };
    });

    const key = `${category}:`;
    return [
      {
        key,
        value: key,
        text: category,
        disabled: true,
      },
      ...subcategories,
    ];
  });

  return keys.flat();
}
