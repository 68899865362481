import { createSlice } from '@reduxjs/toolkit';

import {
  buildGenericAdapter,
  upsertEntity,
  upsertEntityWrapper,
  replaceLocalEntity,
  replaceLocalEntityWrapper,
} from '../../../../libs/slices';

const ENTITY_NAME = 'customPage';

const customPagesAdapter = buildGenericAdapter();

const initialState = customPagesAdapter.getInitialState();

const customPagesSlice = createSlice({
  name: 'customPages',
  initialState,
  reducers: {
    addOneCustomPage: customPagesAdapter.addOne,
    addManyCustomPages: customPagesAdapter.addMany,
    updateOneCustomPage: customPagesAdapter.updateOne,
    removeOneCustomPage: customPagesAdapter.removeOne,
    setAllCustomPages: customPagesAdapter.setAll,
    upsertCustomPage: upsertEntityWrapper(upsertEntity, customPagesAdapter, ENTITY_NAME),
    replaceLocalCustomPage: replaceLocalEntityWrapper(
      replaceLocalEntity,
      customPagesAdapter,
      ENTITY_NAME,
    ),
  },
});

export const {
  addOneCustomPage,
  addManyCustomPages,
  setAllCustomPages,
  updateOneCustomPage,
  removeOneCustomPage,
  upsertCustomPage,
  replaceLocalCustomPage,
} = customPagesSlice.actions;

export default customPagesSlice.reducer;

export const {
  selectAll: selectAllCustomPages,
  selectById: selectCustomPageById,
  selectIds: selectCustomPageIds,
} = customPagesAdapter.getSelectors((state) => state.customPages);
