import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Header, Input } from 'semantic-ui-react';

import {
  closingTimeName,
  manualDurationName,
  closingTimeValue,
  ACCEPT_ORDERS_UNTIL_LABEL,
} from './OrderDurationField.constants';
import OrderCutoffSwitchField from './components/OrderCutoffSwitchField';
import DateUtils from '../../../../../../../libs/date-utils';
import { ACCEPT_ORDERS_UNTIL } from '../../OrderSettings.constants';

import './OrderDurationField.scss';

const propTypes = {
  handleOnChangeForm: PropTypes.func.isRequired,
  initialCutOffSwitch: PropTypes.bool,
  initialTime: PropTypes.string,
};

const defaultProps = {
  initialCutOffSwitch: false,
  initialTime: '00:00',
};

export default function OrderDurationField({
  handleOnChangeForm,
  initialTime,
  initialCutOffSwitch,
}) {
  const initialTimeParsed = DateUtils.convertTimeToMinutes(initialTime);
  const [duration, setDuration] = useState(initialTimeParsed || 0);
  const [durationType, setDurationType] = useState(closingTimeName);

  useEffect(() => {
    const timeParsed = DateUtils.convertTimeToMinutes(initialTime);
    const defaultDurationType = timeParsed > 0 ? manualDurationName : closingTimeName;
    setDuration(timeParsed);
    setDurationType(defaultDurationType);
  }, []);

  function handleDurationTypeChange(e, { value }) {
    setDurationType(value);
    if (closingTimeName === value) {
      const timeValue = DateUtils.convertMinsToHrsMins(closingTimeValue);
      handleOnChangeForm(e, { value: timeValue, name: ACCEPT_ORDERS_UNTIL });
      setDuration(closingTimeValue);
    }
  }

  function handleOnChangeDuration(e, { name, value }) {
    const numberValue = +value;
    if (numberValue < 0 || value.includes('.')) {
      return;
    }
    const timeValue = DateUtils.convertMinsToHrsMins(value);
    handleOnChangeForm(e, { value: timeValue, name });
    setDuration(numberValue.toString());
  }

  return (
    <div className="order-duration duration-form-fields">
      <div className="order-time">
        <Form.Field className="form-field">
          <Header>Accept orders until...</Header>
          <Form.Radio
            label="Closing time"
            name="order-closing-time"
            value={closingTimeName}
            checked={durationType === closingTimeName}
            onChange={handleDurationTypeChange}
          />
        </Form.Field>
        <Form.Field className="form-field">
          <Form.Radio
            label="Specific duration of time before closing"
            value={manualDurationName}
            name="order-closing-time"
            onChange={handleDurationTypeChange}
            checked={durationType === manualDurationName}
          />
        </Form.Field>
        <Form.Field id="accepts-orders-until-time" className="form-field">
          <label htmlFor="accepts-orders-until-time">Time</label>
          <Input
            disabled={durationType === closingTimeName}
            onChange={handleOnChangeDuration}
            name={ACCEPT_ORDERS_UNTIL}
            label={ACCEPT_ORDERS_UNTIL_LABEL}
            labelPosition="right"
            value={duration}
            type="number"
          />
        </Form.Field>
      </div>
      <OrderCutoffSwitchField
        initialValue={initialCutOffSwitch}
        handleOnChangeForm={handleOnChangeForm}
      />
    </div>
  );
}

OrderDurationField.propTypes = propTypes;
OrderDurationField.defaultProps = defaultProps;
