import _isEmpty from 'lodash/isEmpty';
import _set from 'lodash/set';

import {
  DEFAULT_EMAIL_LABEL,
  DEFAULT_NAME_LABEL,
  DEFAULT_PHONE_LABEL,
} from './components/InsertFormField/components/SelectFormFieldType/SelectFormFieldType.constants';
import { CHANNEL_CONTACT } from '../../../../../../../constants/custom-forms';
import API from '../../../../../../../libs/api';
import {
  FORM_BLOCK_COMPONENT_NAME,
  FORM_FIELD_ELEMENT_TYPE,
  LEGACY_FORM_BLOCK_ELEMENT_TYPE,
  FORM_BLOCK_ELEMENT_TYPE,
} from '../../../../constants/types';
import { createFieldElement } from '../../../../hooks/use-recipe-node-translator/generators/form-block';
import {
  generateFieldName,
  transformLegacyFieldIntoNew,
} from '../../../../hooks/use-recipe-node-translator/generators/form-block/fields';

export function isLegacyFormBlock(element) {
  const { type } = element;
  return type === LEGACY_FORM_BLOCK_ELEMENT_TYPE;
}

export function transformLegacyFormBlockToNew(element) {
  const { id, localId, businessId, businessType, title = '', fields = [] } = element || {};

  return {
    id,
    localId,
    type: FORM_BLOCK_ELEMENT_TYPE,
    businessId,
    businessType,
    header: title,
    fields,
    consentSettings: {
      show: true,
      required: false,
    },
    emailSubject: title,
    channel: CHANNEL_CONTACT,
    contactMapping: {
      name: undefined,
      email: undefined,
      phone: undefined,
    },
  };
}

export function getFormBlock(element, { extraData = {} } = {}) {
  if (isLegacyFormBlock(element)) {
    return { ...transformLegacyFormBlockToNew(element), ...(extraData || {}) };
  }
  return { ...element, ...(extraData || {}) };
}

export function getFormConfigurationDataPayload(element) {
  const {
    fields = [],
    header = '',
    consentSettings = {
      show: true,
      required: false,
    },
    emailSubject = '',
    channel = '',
    contactMapping = {},
    destinationEmail = [],
  } = element;

  const fieldElements = (fields || [])
    .map(createFieldElement)
    .filter(Boolean)
    .map(transformLegacyFieldIntoNew);

  return {
    dynamicFormFields: fieldElements,
    channel,
    header,
    consentSettings,
    emailSubject,
    contactMapping,
    destinationEmail,
  };
}

export async function updateFormConfiguration(componentConfigurationId, element) {
  const data = getFormConfigurationDataPayload(element);
  const componentConfigurationData = { id: componentConfigurationId, data };

  return API.updateCustomPageContent(componentConfigurationData);
}

export async function createFormComponentConfiguration(pageId, element) {
  const data = getFormConfigurationDataPayload(element);

  const payload = {
    content_node_configurations: [],
    business_file_configurations: [],
    component: FORM_BLOCK_COMPONENT_NAME,
    page: pageId,
    data,
  };
  const { data: results } = await API.createCustomPageContent(payload);

  return results[0];
}

export function updateFormElementFromSave(element, result) {
  const {
    fast_id: componentName,
    component_identifier: componentIdentifier,
    id: componentConfigurationId,
  } = result;

  return {
    ...element,
    componentName,
    componentIdentifier,
    componentConfigurationId,
  };
}

export function updateFieldValue(element, options = {}) {
  const { fields = [] } = element;
  const { fieldIndex, fieldKey, fieldValue, isFieldContactMapped } = options;
  const oldField = fields[fieldIndex];

  if (!oldField || !fieldKey) {
    return fields;
  }

  let nextField = _set(oldField, fieldKey.split('.'), fieldValue);
  nextField = _set(nextField, 'isFieldContactMapped', isFieldContactMapped);

  return [...fields.slice(0, fieldIndex), nextField, ...fields.slice(fieldIndex + 1)];
}

export function moveField(element, options = {}) {
  const { fields = [] } = element;
  const { fieldIndex, direction } = options;

  const nextIndex = fieldIndex + direction;
  const canMoveUp = nextIndex < 0;
  const canMoveDown = nextIndex > fields.length - 1;

  if (canMoveUp || canMoveDown) {
    return fields;
  }

  return [
    ...fields.slice(0, fieldIndex + (direction === -1 ? -1 : 0)),
    fields[direction === 1 ? nextIndex : fieldIndex],
    fields[direction === 1 ? fieldIndex : nextIndex],
    ...fields.slice(fieldIndex + (direction === -1 ? 1 : 2)),
  ];
}

export function deleteField(element, options = {}) {
  const { fields = [] } = element;
  const { fieldIndex } = options;

  return [...fields.slice(0, fieldIndex), ...fields.slice(fieldIndex + 1)];
}

export function insertField(element, onCreateElement, options = {}) {
  const { fields = [] } = element;
  const { fieldType, fieldIndex, initialData = {} } = options;

  const payload = { ...initialData, type: fieldType };
  const nextField = onCreateElement(FORM_FIELD_ELEMENT_TYPE, { value: payload });

  return [...fields.slice(0, fieldIndex), nextField, ...fields.slice(fieldIndex)];
}

export function createContactMapping(element, label) {
  const { contactMapping = {} } = element || {};
  const mapping = {
    email: DEFAULT_EMAIL_LABEL,
    phone: DEFAULT_PHONE_LABEL,
    name: DEFAULT_NAME_LABEL,
  };
  let added = false;

  const updatedMapping = Object.keys(mapping)
    .map((prop) => {
      if (_isEmpty(contactMapping[prop]) && label === mapping[prop]) {
        added = true;
        return { [prop]: generateFieldName(label) };
      }
      return {};
    })
    .reduce((acc, obj) => ({ ...acc, ...obj }), {});

  return [{ ...contactMapping, ...updatedMapping }, added];
}

export function deleteContactMapping(element, fieldIndex) {
  const { fields = [], contactMapping = {} } = element || {};
  const field = fields[fieldIndex];
  const { label, isFieldContactMapped } = field;
  const snakeCaseLabel = generateFieldName(label);

  if (!isFieldContactMapped) {
    return contactMapping;
  }

  return Object.keys(contactMapping).reduce((acc, prop) => {
    if (contactMapping[prop] !== snakeCaseLabel) {
      acc[prop] = contactMapping[prop];
    }
    return acc;
  }, {});
}

export function updateContactMapping(element, fieldKey, fieldIndex, newFieldValue) {
  const { fields = [], contactMapping: previousContactMapping = {} } = element || {};
  const field = fields[fieldIndex];
  const { label: previousLabel, isFieldContactMapped } = field;
  const previousSnakeCaseLabel = generateFieldName(previousLabel);
  const newSnakeCaseLabel = generateFieldName(newFieldValue);

  if (!isFieldContactMapped) {
    return [previousContactMapping, isFieldContactMapped];
  }
  if (fieldKey !== 'label') {
    return [previousContactMapping, isFieldContactMapped];
  }

  let isNewFieldContactMapped = true;
  const newContactMapping = Object.keys(previousContactMapping).reduce((acc, prop) => {
    if (previousContactMapping[prop] === previousSnakeCaseLabel) {
      isNewFieldContactMapped = !_isEmpty(newSnakeCaseLabel);
      return { ...acc, [prop]: generateFieldName(newSnakeCaseLabel) };
    }
    return { ...acc, [prop]: previousContactMapping[prop] };
  }, {});

  return [newContactMapping, isNewFieldContactMapped];
}

export function areFieldsValid(fields = []) {
  if (_isEmpty(fields)) {
    return false;
  }

  const uniqueLabels = new Set();

  const allValid = fields.every(({ label = '' }) => {
    if (_isEmpty(label)) {
      return false;
    }

    const lowerCasedLabel = label.toLowerCase();
    if (uniqueLabels.has(lowerCasedLabel)) {
      return false;
    }
    uniqueLabels.add(lowerCasedLabel);
    return true;
  });

  return allValid;
}
