import React from 'react';

import { Segment } from 'semantic-ui-react';

import './EmptyFileUploads.scss';

function EmptyFileUploads() {
  return (
    <div className="woflow-modal-empty-file-uploads">
      <Segment className="dark-gray" />
      <Segment className="gray" content="No files or URLs have been uploaded yet" />
      <Segment className="light-gray" />
    </div>
  );
}

export default EmptyFileUploads;
