import _isEmpty from 'lodash/isEmpty';

import {
  DYNAMIC_TILES_COMPONENT,
  DYNAMIC_TILES_TILE_COMPONENT,
} from '../../../modules/hero/constants/custom-hero';
import { hasHeroOverrideForPage } from '../../../modules/hero/services/custom-hero';
import { filterComponentConfigurations } from '../../../modules/pages/services/components';
import { HERO_ELEMENT } from '../ImagesPage.constants';
import {
  isImageComponentConfiguration,
  getPageComponentConfigurationSettings,
} from '../ImagesPage.utils';

export function getPagesWithImageComponentConfigurations(pages, configurationSettings) {
  return pages
    .map((page) => {
      const hasHeroOverride = hasHeroOverrideForPage(page);
      const { page_type: pageType } = page;

      const pageConfigurationSettings = getPageComponentConfigurationSettings(
        configurationSettings,
        pageType,
      );
      const { components: componentConfigurations } = page;
      const finalComponentConfigurations = filterComponentConfigurations(componentConfigurations, [
        {
          paramsFilter: [{ fast_id: HERO_ELEMENT }],
          shouldApplyFilters: hasHeroOverride,
          negate: true,
        },
        {
          paramsFilter: [
            { fast_id: DYNAMIC_TILES_COMPONENT },
            { fast_id: DYNAMIC_TILES_TILE_COMPONENT },
          ],
          negate: true,
        },
        {
          paramsFilter: [
            (componentConfiguration) =>
              isImageComponentConfiguration(componentConfiguration, pageConfigurationSettings),
          ],
        },
      ]);
      return { page, componentConfigurations: finalComponentConfigurations };
    })
    .filter(({ componentConfigurations }) => !_isEmpty(componentConfigurations));
}
