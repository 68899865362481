import React from 'react';

import PropTypes from 'prop-types';
import { Icon, Modal, Step, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { updateOnboardingState as updateOnboardingStateConnect } from '../../../../actions/business';
import DomainSelection from '../../DomainSelection';
import OnboardingLoginBanner from '../OnboardingLoginBanner';
import { hasDomains } from '../OnboardingWizard/OnboardingWizard.utils';

const mapDispatchToProps = (dispatch) => ({
  updateOnboardingState: (payload) => dispatch(updateOnboardingStateConnect(payload)),
});

const mapStateToProps = (state) => ({
  domains: _get(state, 'website.domains.value', []),
  onboardingState: _get(state, 'business.core.value.onboardingState', {}),
});

const propTypes = {
  domains: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onboardingState: PropTypes.shape({}).isRequired,
  updateOnboardingState: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  canSkip: PropTypes.bool,
};

const defaultProps = {
  isOpen: false,
  canSkip: false,
};

function SkippableDomainSelection({
  domains,
  onboardingState,
  updateOnboardingState,
  isOpen,
  canSkip,
}) {
  function getCurrentStep() {
    const isMissingDomains = !hasDomains(domains);

    if (isMissingDomains) return 1;
    return 0;
  }

  function skipOnboarding() {
    return _get(onboardingState, 'domainSelection', false);
  }

  function handleSkip(stepName) {
    updateOnboardingState({
      ...onboardingState,
      [stepName]: true,
    });
  }

  const currentStep = getCurrentStep();

  if (skipOnboarding()) return null;

  return (
    <Modal
      basic
      open={isOpen}
      className="onboarding-modal"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="fullscreen"
    >
      <OnboardingLoginBanner />
      <Modal.Content scrolling>
        <Step.Group attached="top" stackable="tablet">
          <Step active={currentStep === 1}>
            <Icon name="window maximize outline" />
            <Step.Content>
              <Step.Title>Domain Selection</Step.Title>
              <Step.Description>Choose a Domain for this Website</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        {currentStep === 1 && (
          <Segment attached className="content-segment">
            <DomainSelection onSkip={handleSkip} canSkip={canSkip} />
          </Segment>
        )}
      </Modal.Content>
    </Modal>
  );
}

SkippableDomainSelection.propTypes = propTypes;
SkippableDomainSelection.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(SkippableDomainSelection);
