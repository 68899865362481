import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import {
  DEFAULT_CONFIRMATION_MESSAGE,
  DEFAULT_CONFIRMATION_BUTTON_TEXT,
} from './SignUpFormConfirmation.constants';
import { getConfirmationMessage } from './SignupFormConfirmation.utils';
import { selectUserRoleForBusiness } from '../../../../../selectors/business';

import './SignUpFormConfirmation.scss';

const propTypes = {
  confirmationMessage: PropTypes.string,
  confirmationButtonText: PropTypes.string,
  onConfirmationAction: PropTypes.func,
  secondaryActions: PropTypes.element,
};

const defaultProps = {
  confirmationMessage: DEFAULT_CONFIRMATION_MESSAGE,
  confirmationButtonText: DEFAULT_CONFIRMATION_BUTTON_TEXT,
  onConfirmationAction: null,
  secondaryActions: null,
};

function SignUpFormConfirmation({
  confirmationMessage,
  confirmationButtonText,
  onConfirmationAction,
  secondaryActions,
  user,
  userRole,
}) {
  return (
    <div className="sign-up-form-confirmation">
      <h3>{getConfirmationMessage(user, userRole, confirmationMessage)}</h3>
      {secondaryActions}
      {onConfirmationAction && (
        <Button size="large" onClick={onConfirmationAction}>
          {confirmationButtonText}
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = ({ business, user }) => ({
  user: _get(user, 'core.value'),
  userRole: selectUserRoleForBusiness({ business }),
});

SignUpFormConfirmation.propTypes = propTypes;
SignUpFormConfirmation.defaultProps = defaultProps;

export default connect(mapStateToProps)(SignUpFormConfirmation);
