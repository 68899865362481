import React from 'react';

import PropTypes from 'prop-types';
import { Table, Checkbox } from 'semantic-ui-react';

import { TAX_LABEL_APPLIED, TAX_LABEL_UNAPPLIED } from './ItemTaxForm.constants';
import { formatPercent } from '../../../../../../../libs/percent';
import { CatalogTaxPropType } from '../../../proptypes';

const propTypes = {
  availableTaxes: PropTypes.arrayOf(CatalogTaxPropType).isRequired,
  appliedTaxes: PropTypes.arrayOf(CatalogTaxPropType).isRequired,
  onToggleTax: PropTypes.func.isRequired,
};

function ItemTaxForm({ availableTaxes, appliedTaxes, onToggleTax }) {
  function isTaxApplied(tax) {
    return appliedTaxes.some((appliedTax) => tax.object_id === appliedTax.object_id);
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Tax Name</Table.HeaderCell>
          <Table.HeaderCell>Tax Rate</Table.HeaderCell>
          <Table.HeaderCell>Apply Tax</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {availableTaxes.map((tax) => (
          <Table.Row key={tax.object_id}>
            <Table.Cell>{tax.tax_data.name}</Table.Cell>
            <Table.Cell>{formatPercent(tax.tax_data.rate, 4)}</Table.Cell>
            <Table.Cell>
              <Checkbox
                toggle
                label={isTaxApplied(tax) ? TAX_LABEL_APPLIED : TAX_LABEL_UNAPPLIED}
                onChange={(e, data) => onToggleTax(data.checked, tax)}
                checked={isTaxApplied(tax)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

ItemTaxForm.propTypes = propTypes;

export default ItemTaxForm;
