import React, { useContext } from 'react';

import { Icon } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { TypographyContext } from '../../contexts/Typography.context';

import './GoBack.scss';

const propTypes = {};
const defaultProps = {};

export default function GoBack() {
  const { selectedFont, setSelectedFont } = useContext(TypographyContext);
  if (_isEmpty(selectedFont)) {
    return null;
  }

  const { family } = selectedFont;

  function onClick() {
    setSelectedFont({});
  }

  return (
    <div className="typography-page-go-back">
      <Icon name="arrow left" onClick={onClick} /> <span>{family}</span>
    </div>
  );
}

GoBack.propTypes = propTypes;
GoBack.defaultProps = defaultProps;
