import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';

import API from '../../../../libs/api';
import { capitalize } from '../../../../libs/strings/strings';
import { getStyleRuleConfiguration } from '../../style-rules/services/configuration';
import {
  DEFAULT_BOLD_FONT_WEIGHT,
  DEFAULT_FONT_WEIGHT,
  GOOGLE_FONTS_API,
  ITALIC,
  REGULAR_VARIANT,
} from '../constants';

function encodeFamilyForUrl(value) {
  return value.replace(/['"]+/g, '').split(' ').join('+');
}

export async function getGoogleFonts({ sort = 'popularity' } = {}) {
  const response = await API.getGoogleFonts({ sort });
  return response.data.items;
}

export function getFontFamily(font) {
  if (_isEmpty(font)) {
    return '';
  }
  let [fontFamily] = font.split(',');
  [fontFamily] = fontFamily.split('(');
  fontFamily = fontFamily.replace(/['"]+/g, '');
  return fontFamily.trim();
}

export function getRecipeDefaultHeaderFont(styleRulesConfiguration) {
  const rule = getStyleRuleConfiguration(styleRulesConfiguration, 'headerFont');
  return _get(rule, 'defaultValue', '');
}

export function getRecipeDefaultBodyFont(styleRulesConfiguration) {
  const rule = getStyleRuleConfiguration(styleRulesConfiguration, 'pageFont');
  return _get(rule, 'defaultValue', '');
}

export function isItalicFontVariant(variantName) {
  return variantName.includes(ITALIC);
}

export function getFontVariantWeight(variantName) {
  if (variantName === ITALIC) {
    return DEFAULT_FONT_WEIGHT;
  }
  if (variantName === REGULAR_VARIANT) {
    return DEFAULT_FONT_WEIGHT;
  }

  const weight = `${variantName}`.replace(ITALIC, '');
  return weight;
}

export function getFontVariantStyle(variantName) {
  if (isItalicFontVariant(variantName)) {
    return ITALIC;
  }
  return 'normal';
}

export function getFontVariantDisplay(variantName) {
  if (variantName === ITALIC) {
    return capitalize(ITALIC);
  }

  const NAMES = {
    100: 'Thin 100',
    200: 'ExtraLight 200',
    300: 'Light 300',
    regular: 'Regular 400',
    400: 'Regular 400',
    500: 'Medium 500',
    600: 'SemiBold 600',
    700: 'Bold 700',
    800: 'ExtraBold 800',
    900: 'Black 900',
  };

  const isItalic = isItalicFontVariant(variantName);
  const variant = `${variantName}`.replace(ITALIC, '');
  let displayVariant = NAMES[variant] || '';

  if (isItalic) {
    displayVariant += ` ${capitalize(ITALIC)}`;
  }

  return displayVariant;
}

export function getFontURL(family, { variant = REGULAR_VARIANT, text = '' } = {}) {
  const weight = getFontVariantWeight(variant);
  const familyParam = `${getFontFamily(family)}:wght@${weight}`;

  const url = new URL(GOOGLE_FONTS_API);
  const search = new URLSearchParams('');
  search.append('display', 'swap');
  search.append('family', familyParam);
  if (!_isEmpty(text)) {
    search.append('text', text);
  }

  url.search = search;
  return url.toString();
}

export function sortFontVariantsByWeight(variants) {
  const sortedVariants = [...variants].sort((first, second) => {
    const firstWeight = +getFontVariantWeight(first);
    const SecondWeight = +getFontVariantWeight(second);
    return firstWeight - SecondWeight;
  });
  return sortedVariants;
}

export function familyToStyleRule(family) {
  let result = family;
  if (result[0] !== "'") {
    result = `'${result}`;
  }
  if (result[result.length - 1] !== "'") {
    result = `${result}'`;
  }
  return result;
}

function getFontRequestParams({ name: fontName, selectedWeights = [] }) {
  if (_isEmpty(fontName) || _isEmpty(selectedWeights)) {
    return '';
  }

  const weights = selectedWeights.map((weight) => getFontVariantWeight(weight));
  const params = sortFontVariantsByWeight([...new Set(weights)]).join(',');

  return `${encodeFamilyForUrl(fontName)}:${params}`;
}

export function getGoogleFontRequest(headerFont, bodyFont) {
  const headerParams = getFontRequestParams(headerFont);
  const bodyParams = getFontRequestParams(bodyFont);

  const request = [headerParams, bodyParams].filter((params) => !!params).join('|');

  if (_isEmpty(request)) {
    return '';
  }

  return `'${request}&display=swap'`;
}

export function searchFonts(fonts, query) {
  if (_isEmpty(query)) {
    return fonts;
  }
  return fonts.filter((font) => font.family.toLowerCase().includes(query.toLowerCase()));
}

export function filterFontsByCategories(fonts, categories) {
  return fonts.filter((font) => {
    const { category } = font;
    return (categories || []).includes(category);
  });
}

export function getWeightsFromGoogleFontRequest(fontName, googleFontRequest) {
  if (_isEmpty(googleFontRequest || _isEmpty(fontName))) {
    return [];
  }

  const request = googleFontRequest.replace(/'/g, '');

  let fontRequest =
    request.split('|').find((font) => font.startsWith(encodeFamilyForUrl(fontName))) || '';

  [fontRequest] = (fontRequest || '').split('&');
  const [, weights] = (fontRequest || '').split(':');

  if (_isEmpty(weights)) {
    return [];
  }

  const finalWeights = (weights || '').split(',') || [];
  return [...new Set(finalWeights)];
}

export function getDefaultSelectedWeights(variants) {
  if (_isEmpty(variants)) {
    return [];
  }
  if (variants.includes(DEFAULT_BOLD_FONT_WEIGHT)) {
    return [REGULAR_VARIANT, DEFAULT_BOLD_FONT_WEIGHT];
  }
  return [REGULAR_VARIANT];
}

export function areWeightsEqual(variants1, variants2) {
  const weights1 = sortFontVariantsByWeight(
    variants1.map((variant) => getFontVariantWeight(variant)),
  );
  const weights2 = sortFontVariantsByWeight(
    variants2.map((variant) => getFontVariantWeight(variant)),
  );

  return _isEqual(weights1, weights2);
}
