import React, { useState } from 'react';

import { Icon, Modal, Button } from 'semantic-ui-react';

import { DESTINATION_CATALOG } from '../../../../../constants/menu';
import CloseableModal from '../../../../common/CloseableModal/CloseableModal';
import WoflowMenuActions from '../../../../core/menu/WoflowMenuActions/WoflowMenuActions';

import './ImportWithWoflowModal.scss';

function ImportWithWoflowModal(props) {
  const [menuActionModalOpen, setMenuActionModalOpen] = useState(false);

  return (
    <CloseableModal
      className="import-with-woflow-modal"
      header={
        <div className="header-with-button">
          <span>Import with Woflow</span>
          <Button className="upload-woflow" onClick={() => setMenuActionModalOpen(true)}>
            <Icon name="upload" />
            UPLOAD MENU FILES
          </Button>
        </div>
      }
      {...props}
    >
      <Modal.Content>
        <WoflowMenuActions
          open={menuActionModalOpen}
          onClose={() => setMenuActionModalOpen(false)}
          jobDestination={DESTINATION_CATALOG}
        />
      </Modal.Content>
    </CloseableModal>
  );
}

export default ImportWithWoflowModal;
