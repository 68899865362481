import React from 'react';

import PropTypes from 'prop-types';
import { Input, Form } from 'semantic-ui-react';

const InputField = ({
  value,
  type = 'text',
  onChange,
  placeholder,
  onValidate,
  name,
  disabled,
  label,
  error,
  minLength,
  maxLength,
  pattern,
  width,
}) => (
  <Form.Field
    width={width}
    style={{
      padding: '0 0 0 0',
    }}
    error={error}
  >
    <Input
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={() => onValidate(value)}
      name={name}
      disabled={disabled}
      pattern={pattern}
      minLength={minLength}
      maxLength={maxLength}
    />
  </Form.Field>
);

InputField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

InputField.defaultProps = {
  value: '',
  placeholder: '',
};

export default InputField;
