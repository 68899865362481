import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Input } from 'semantic-ui-react';

import ChannelSelector from './components/ChannelSelector';

import './SubmissionsSearchBar.scss';

const propTypes = {
  filterValue: PropTypes.string,
  setFilterValue: PropTypes.func.isRequired,
  queryParams: PropTypes.shape({}),
  setQueryParam: PropTypes.func.isRequired,
};

const defaultProps = {
  filterValue: '',
  queryParams: {},
};

function SubmissionsSearchBar({ filterValue, setFilterValue, setQueryParam }) {
  function setChannels(newChannels) {
    setQueryParam('channels', newChannels.join(','));
  }

  return (
    <Grid className="submissions-search-bar">
      <Grid.Row>
        <Grid.Column width="4">
          <Input
            placeholder="Search submissions..."
            value={filterValue}
            onChange={(e, { value }) => setFilterValue(value)}
          />
        </Grid.Column>
        <Grid.Column width="4">
          <ChannelSelector onChange={setChannels} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

SubmissionsSearchBar.propTypes = propTypes;
SubmissionsSearchBar.defaultProps = defaultProps;

export default SubmissionsSearchBar;
