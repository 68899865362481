import _get from 'lodash/get';
import moment from 'moment';

import Currency from './currency';

const RESPONSE_MAP = {
  month: 'Billing Monthly - ',
  year: 'Billing Annually - ',
};

export function getFormattedAmount(amount) {
  const currencyValue = amount / 100;
  return Currency.format(currencyValue);
}

export function getFormattedTime(utcDateTime) {
  return moment(utcDateTime).format('MMMM D, YYYY');
}

export function getSubscriptionInterval(recurringInterval) {
  return _get(RESPONSE_MAP, recurringInterval, '');
}
