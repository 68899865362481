import React from 'react';

import { List, Icon } from 'semantic-ui-react';

import { EmailTriggersPropType } from './EmailTriggerList.propTypes';
import EmailTriggerButton from '../EmailTriggerButton/EmailTriggerButton';

function EmailTriggerList({ emailTriggers }) {
  return (
    <List divided verticalAlign="middle">
      {emailTriggers.map(({ name, iconName, triggerText }) => (
        <List.Item>
          <List.Content floated="right">
            <EmailTriggerButton name={name}>Send</EmailTriggerButton>
          </List.Content>
          <Icon name={iconName} size="large" />
          <List.Content verticalAlign="middle">{triggerText}</List.Content>
        </List.Item>
      ))}
    </List>
  );
}

EmailTriggerList.propTypes = {
  emailTriggers: EmailTriggersPropType.isRequired,
};

export default EmailTriggerList;
