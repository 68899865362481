import React, { useContext } from 'react';

import { Checkbox, Select } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { getOptions } from './CuratedHeroHeaderSettings.utils';
import { CuratedHeroContext } from '../../../../../../modules/custom-pages/contexts/CuratedHero.context';

import './CuratedHeroHeaderSettings.scss';

const propTypes = {};

const defaultProps = {};

export default function CuratedHeroHeaderSettings() {
  const { name, onChangeName, enabled, onChangeEnabled, recipeName } =
    useContext(CuratedHeroContext);

  const options = getOptions(recipeName);

  function onCuratedHeroNameChange(e, { value }) {
    onChangeName(value);
  }

  function onCuratedHeroEnabledChange(e, { checked }) {
    onChangeEnabled(checked);
  }

  // add tooltip

  return (
    <div className="curated-hero-header-settings">
      {_isEmpty(options) && (
        <p className="no-options">{`Not curated hero options configured for ${recipeName}`} </p>
      )}
      {!_isEmpty(options) && (
        <>
          <Select
            selection
            options={options}
            value={name}
            onChange={onCuratedHeroNameChange}
            placeholder="Select Curated Hero"
            className="name-selector"
          />
          <Checkbox
            label="Enable Curated Hero"
            checked={enabled}
            onChange={onCuratedHeroEnabledChange}
            toggle
          />
        </>
      )}
    </div>
  );
}

CuratedHeroHeaderSettings.propTypes = propTypes;
CuratedHeroHeaderSettings.defaultProps = defaultProps;
