import PropTypes from 'prop-types';

import { PageComponentType } from './components';
import { ObjectPropType } from '../../../../libs/proptypes';

export const PagePropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string.isRequired,
  pageType: PropTypes.string.isRequired,
  description: PropTypes.string,
  slug: PropTypes.string,
  url: PropTypes.string,
  groupName: PropTypes.string,
  navigationPage: PropTypes.bool,
  navigationPriority: PropTypes.number,
  serpText: PropTypes.string,
  shortName: PropTypes.string,
  components: PropTypes.arrayOf(PageComponentType),
  metadata: ObjectPropType,
  isGroup: PropTypes.bool,
});
