import React from 'react';

import DesktopSideNavigation from './components/DesktopSideNavigation';
import MobileSideNavigation from './components/MobileSideNavigation';
import useResponsive from '../../../modules/foundation/hooks/use-responsive';

import './SideNavigation.scss';

export default function SideNavigation({ rootRoutePath }) {
  const { isMobile } = useResponsive();

  return (
    <>
      <DesktopSideNavigation rootRoutePath={rootRoutePath} />
      {isMobile && <MobileSideNavigation rootRoutePath={rootRoutePath} />}
    </>
  );
}
