import { useState } from 'react';

import _get from 'lodash/get';

import { createNewItemVariationData } from './CatalogItemForm.utils';
import { CATALOG_MAX_ORDINAL, CATALOG_MIN_ORDINAL } from '../constants';
import { findCatalogObjectIndex, updateCatalogObjectData } from '../utils';

export function useItemVariations(initialVariations) {
  const [itemVariations, setItemVariations] = useState(initialVariations);
  const [itemVariationsToRemove, setItemVariationsToRemove] = useState([]);

  function resetVariations(variations = []) {
    setItemVariations(variations);
    setItemVariationsToRemove([]);
  }

  function addVariation(itemObjectId) {
    // Don't use a default name if there is at least one variation
    const defaultName = itemVariations.length > 0 ? '' : undefined;
    setItemVariations((prevVariations) => {
      const prevOrdinal =
        itemVariations.length === 0
          ? CATALOG_MIN_ORDINAL
          : _get(prevVariations, `[${prevVariations.length - 1}].item_variation_data.ordinal`);
      const newVariation = createNewItemVariationData(itemObjectId, defaultName, {
        previousOrdinal: prevOrdinal,
        nextOrdinal: CATALOG_MAX_ORDINAL,
      });

      return [...prevVariations, newVariation];
    });
  }

  function updateVariation(variation, data) {
    setItemVariations((prevVariations) => {
      const variationIndex = findCatalogObjectIndex(prevVariations, variation);
      if (variationIndex === -1) {
        return prevVariations;
      }
      const updatedVariation = updateCatalogObjectData(
        prevVariations[variationIndex],
        'item_variation_data',
        data,
      );
      return [
        ...prevVariations.slice(0, variationIndex),
        updatedVariation,
        ...prevVariations.slice(variationIndex + 1),
      ];
    });
  }

  function changeVariation(variation, fieldName, value) {
    updateVariation(variation, { [fieldName]: value });
  }

  function removeVariation(variation) {
    setItemVariations((prevVariations) => {
      const variationIndex = findCatalogObjectIndex(prevVariations, variation);
      if (variationIndex === -1) {
        return prevVariations;
      }
      if (prevVariations[variationIndex].object_id) {
        setItemVariationsToRemove((prevItemsToRemove) => [
          ...prevItemsToRemove,
          prevVariations[variationIndex],
        ]);
      }

      return [
        ...prevVariations.slice(0, variationIndex),
        ...prevVariations.slice(variationIndex + 1),
      ];
    });
  }

  return {
    itemVariations,
    addVariation,
    changeVariation,
    updateVariation,
    removeVariation,
    itemVariationsToRemove,
    resetVariations,
  };
}
