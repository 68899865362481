import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { filterOrderSettingsByFulfillmentType } from './OrderSettings.utils';

const orderSettingsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id < b.id,
  selectId: (entity) => entity.id || entity.localId,
});

const initialState = orderSettingsAdapter.getInitialState();

const orderSettingsSlice = createSlice({
  name: 'orderSettings',
  initialState,
  reducers: {
    addOneOrderSetting: orderSettingsAdapter.addOne,
    addManyOrderSettings: orderSettingsAdapter.addMany,
    updateOneOrderSetting: orderSettingsAdapter.updateOne,
    removeOneOrderSetting: orderSettingsAdapter.removeOne,
    setAllOrderSettings: orderSettingsAdapter.setAll,
    handleChangeOrderSetting: (state, action) => {
      const { orderSetting, data, defaultData } = action.payload;
      if (orderSetting.id || orderSetting.localId) {
        orderSettingsAdapter.updateOne(state, {
          id: orderSetting.id || orderSetting.localId,
          changes: { ...data },
        });
      } else {
        const localId = uuid();
        orderSettingsAdapter.addOne(state, {
          localId,
          ...defaultData,
          ...data,
        });
      }
    },
    handleSaveOrderSetting: (state, action) => {
      const { orderSetting, data } = action.payload;

      if (orderSetting.localId) {
        orderSettingsAdapter.removeOne(state, orderSetting.localId);
        orderSettingsAdapter.addOne(state, data);
      } else {
        orderSettingsAdapter.updateOne(state, { id: orderSetting.id, changes: data });
      }
    },
  },
});

export const {
  addOneOrderSetting,
  addManyOrderSettings,
  setAllOrderSettings,
  updateOneOrderSetting,
  removeOneOrderSetting,
  handleChangeOrderSetting,
  handleSaveOrderSetting,
} = orderSettingsSlice.actions;

export default orderSettingsSlice.reducer;

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllOrderSettings,
  selectById: selectOrderSettingById,
  selectIds: selectOrderSettingIds,
  // Pass in a selector that returns the posts slice of state
} = orderSettingsAdapter.getSelectors((state) => state.orderSettings);

export const selectOrderSettingByFulfillmentType = createSelector(
  [selectAllOrderSettings, (state, fulfillmentType) => fulfillmentType],
  filterOrderSettingsByFulfillmentType,
);

export const selectOrderSettingByParams = createSelector(
  [selectAllOrderSettings, (state, params) => params],
  (
    orderSettings,
    {
      location = undefined,
      product = undefined,
      fulfillmentType = undefined,
      providerName = undefined,
    },
  ) => {
    return orderSettings.filter((setting) => {
      return (
        (fulfillmentType === undefined || setting?.method?.type === fulfillmentType) &&
        (providerName === undefined || setting?.method?.provider === providerName) &&
        (product === undefined || setting.product === product) &&
        (location === undefined || setting.location === location)
      );
    });
  },
);
