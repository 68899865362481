import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { getErrors, getOptions, shouldHideColorPicker } from './EditStyleRuleModal.utils';
import Validate from '../../../../../libs/validate';
import { selectActiveRecipe, selectWebsitePatches } from '../../../../../selectors/website';
import CloseableModal from '../../../../common/CloseableModal';
import ColorPicker from '../../../colors/components/ColorPaletteSelection/components/ColorPicker/ColorPicker';
import {
  constructColorFromString,
  getColorString,
} from '../../../colors/components/ColorPaletteSelection/components/ColorPicker/ColorPicker.utils';
import useStyleRule from '../../hooks/useStyleRule/useStyleRule';
import { selectStyleRules } from '../../selectors';
import { getStyleRuleConfiguration, isColorRule } from '../../services/configuration';
import { formatStyleRuleValue } from '../../services/format';

import './StyleRulesModal.scss';

const propTypes = {
  initialName: PropTypes.string,
  add: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
};

const defaultProps = {
  initialName: '',
  add: false,
  trigger: null,
  onClose: () => null,
};

export default function EditStyleRuleModal({ initialName, add, open, trigger, onClose }) {
  const patches = useSelector(selectWebsitePatches);
  const { styleRulesConfiguration = [] } = selectActiveRecipe(patches);
  const styleRules = useSelector(selectStyleRules);

  const { value: initialValue, deleteRule: deleteInitialRule } = useStyleRule(
    formatStyleRuleValue(initialName),
  );

  const [name, setName] = useState(formatStyleRuleValue(initialName));
  const [value, setValue] = useState(initialValue || '');
  const [color, setColor] = useState(constructColorFromString(initialValue || ''));
  const [fieldErrors, setFieldErrors] = useState({});

  const { setValue: updateStyleRule } = useStyleRule(formatStyleRuleValue(name));

  function resetValues() {
    setName('');
    setValue('');
  }

  function setValues() {
    if (!add) {
      setName(formatStyleRuleValue(initialName));
      setValue(initialValue);
    }
  }

  function clearErrors() {
    setFieldErrors({});
  }

  function setErrors(errors) {
    setFieldErrors(errors);
    setTimeout(() => clearErrors(), 4000);
  }

  function onSave() {
    const errors = getErrors(name, value);

    if (!_isEmpty(errors)) {
      setErrors(errors);
      return;
    }

    updateStyleRule(value);
    if (!add && formatStyleRuleValue(name) !== formatStyleRuleValue(initialName)) {
      deleteInitialRule();
    }

    resetValues();
    clearErrors();
    onClose();
  }

  function onUpdateName(e, { value: newName }) {
    setName(newName);
  }

  function onUpdateValue(e, { value: newValue }) {
    if (Validate.isHexColor(newValue)) {
      setColor(constructColorFromString(newValue));
    }
    setValue(newValue);
  }

  function getFieldError(errorName) {
    return !!fieldErrors[errorName];
  }

  const styleRuleConfiguration = getStyleRuleConfiguration(styleRulesConfiguration, name);
  const isColor = isColorRule(name, styleRuleConfiguration);
  const options = getOptions(name, styleRules, styleRulesConfiguration);
  const hideColorPicker = shouldHideColorPicker(value);

  return (
    <CloseableModal
      className="edit-style-rule-modal"
      open={open}
      onOpen={add ? resetValues : setValues}
      trigger={trigger}
      onClose={onClose}
      header={add ? 'Create Style Rule' : 'Edit Style Rule'}
    >
      <Modal.Content>
        <Form>
          <Form.Select
            fluid
            label="Style Rule Name"
            search
            selection
            required
            value={name}
            allowAdditions
            placeholder="Select A Rule"
            onChange={onUpdateName}
            error={getFieldError('name')}
            options={options}
          />

          {isColor ? (
            <Grid>
              {!hideColorPicker && (
                <Grid.Column floated="left" width={5}>
                  <Form.Input label="Color" fluid required error={getFieldError('value')}>
                    <ColorPicker
                      withPopUpWrapper
                      color={color}
                      onSelect={(newColor) => {
                        onUpdateValue(newColor, { value: getColorString(newColor) });
                        setColor(newColor);
                      }}
                    />
                  </Form.Input>
                </Grid.Column>
              )}
              <Grid.Column floated="right" width={5}>
                <Form.Input
                  fluid
                  label="Style Rule Value"
                  value={value}
                  placeholder="#000000"
                  onChange={onUpdateValue}
                  error={getFieldError('value')}
                  required
                />
              </Grid.Column>
            </Grid>
          ) : (
            <Form.Input
              fluid
              label="Style Rule Value"
              value={value}
              placeholder="#000000"
              onChange={onUpdateValue}
              error={getFieldError('value')}
              required
            />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button className="action" onClick={onSave} content="Save" />
      </Modal.Actions>
    </CloseableModal>
  );
}

EditStyleRuleModal.propTypes = propTypes;
EditStyleRuleModal.defaultProps = defaultProps;
