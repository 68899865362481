import { ALL_LOCATIONS_VALUE, EMPTY_LOCATION_OPTION_LABEL } from './LocationSelect.constants';
import { getLocationDisplay } from '../../../../../libs/location';

export function getLocationOptions(locations, { hideAllLocationsOption } = {}) {
  const allLocationsOption = {
    key: ALL_LOCATIONS_VALUE,
    value: ALL_LOCATIONS_VALUE,
    text: EMPTY_LOCATION_OPTION_LABEL,
  };

  const locationOptions = locations.map((location) => ({
    key: location.id,
    value: location.id,
    text: getLocationDisplay(location),
  }));

  if (hideAllLocationsOption) {
    return locationOptions;
  }

  return [allLocationsOption, ...locationOptions];
}
