import React from 'react';

import PropTypes from 'prop-types';

import PlusIcon from '../common/PlusIcon';

const propTypes = {
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => null,
};

export default function AddMenuItem({ onClick }) {
  return (
    <button type="button" className="add-menu-item" onClick={onClick}>
      <PlusIcon />
      <h5>Create Item</h5>
    </button>
  );
}

AddMenuItem.propTypes = propTypes;
AddMenuItem.defaultProps = defaultProps;
