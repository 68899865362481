import React from 'react';

import PropTypes from 'prop-types';

import './EnterAddressManuallyPrompt.scss';

const propTypes = {
  onSelectNoResults: PropTypes.func.isRequired,
};

export default function EnterAddressManuallyPrompt({ onSelectNoResults }) {
  return (
    <div className="enter-address-manually-prompt">
      Try adding a city, state, or zip code or{' '}
      <span role="button" tabIndex={-1} onKeyDown={onSelectNoResults} onClick={onSelectNoResults}>
        enter your information manually.
      </span>
    </div>
  );
}

EnterAddressManuallyPrompt.propTypes = propTypes;
