import React, { useContext } from 'react';

import { Input } from 'semantic-ui-react';

import { getFontSizeValue } from './FontWeightSlider.constants';
import { TypographyContext } from '../../contexts/Typography.context';

import './FontWeightSlider.scss';

const propTypes = {};
const defaultProps = {};

export default function FontWeightSlider() {
  const { fontSize, setFontSize } = useContext(TypographyContext);

  function onChange(event, { value: newValue }) {
    setFontSize(`${newValue}px`);
  }

  return (
    <Input
      label={fontSize}
      className="font-weight-slider"
      onChange={onChange}
      value={getFontSizeValue(fontSize)}
      type="range"
      min={12}
      max={99}
    />
  );
}

FontWeightSlider.propTypes = propTypes;
FontWeightSlider.defaultProps = defaultProps;
