import React from 'react';

import { Button, Label, Table } from 'semantic-ui-react';

import { validateRecipe } from '../utils/validation';

export default class SchemaValidation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      validationErrors: [],
      showValidTrueLabel: false,
    };

    this.checkSchema = this.checkSchema.bind(this);
  }

  componentDidMount() {
    this.checkSchema();
  }

  checkSchema() {
    const { recipe } = this.props;

    this.setState({ loading: true }, () => {
      this.setState({
        loading: false,
        validationErrors: validateRecipe(recipe),
      });
    });
  }

  render() {
    const { showValidTrueLabel, validationErrors, loading } = this.state;

    return (
      <div>
        {showValidTrueLabel && <Label color="green">Is valid!</Label>}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data Path</Table.HeaderCell>
              <Table.HeaderCell>Schema Path</Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
              <Table.HeaderCell>Params</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {validationErrors.length > 0 &&
              validationErrors.map(({ dataPath, schemaPath, message, params }) => (
                <Table.Row>
                  <Table.Cell>{dataPath}</Table.Cell>
                  <Table.Cell>{schemaPath}</Table.Cell>
                  <Table.Cell>{message}</Table.Cell>
                  <Table.Cell>{JSON.stringify(params, null, 2)}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="1">
                <Label color={validationErrors.length === 0 ? 'green' : 'red'}>
                  {validationErrors.length === 0 ? 'Valid Patch' : 'Invalid Patch'}
                </Label>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan="3">
                <Button loading={loading} floated="right" primary onClick={this.checkSchema}>
                  Validate
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
