import { useEffect } from 'react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { zendeskIdentify, zendeskAvailable, createZendeskScript } from './ZendeskWidget.utils';
import { selectBusiness } from '../../../selectors';
import { selectUser } from '../../../selectors/user';

import './ZendeskWidget.scss';

const propTypes = {};
const defaultProps = {};

// https://developer.zendesk.com/api-reference/widget-messaging/introduction/
export default function ZendeskWidget() {
  const user = useSelector(selectUser) || {};
  const business = useSelector(selectBusiness) || {};

  const { id: userId } = user;
  const { id: businessId } = business;

  function identifyUser() {
    if (zendeskAvailable() && !_isEmpty(user)) {
      zendeskIdentify(user, business);
    }
  }

  useEffect(() => {
    createZendeskScript({
      onLoad: () => {
        setTimeout(() => {
          identifyUser();
        }, 2000);
      },
    });
  }, []);

  useEffect(() => {
    if (zendeskAvailable()) {
      identifyUser();
    }
  }, [userId, businessId]);

  return null;
}

ZendeskWidget.propTypes = propTypes;
ZendeskWidget.defaultProps = defaultProps;
