import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { DELIVERY_LABEL } from './AcceptableFulfillmentTypes.constants';
import {
  FULFILLMENT_TYPE_INSTORE,
  FULFILLMENT_TYPE_SHIPPING,
  FULFILLMENT_TYPE_TO_LABEL,
} from '../../../../../../../constants/fulfillments';

import './AcceptableFulfillmentTypes.scss';

const propTypes = {
  selectedTypes: PropTypes.arrayOf(PropTypes.string),
  toggleType: PropTypes.func,
};
const defaultProps = {
  selectedTypes: [],
  toggleType: () => null,
};

function AcceptableFulfillmentTypes({ selectedTypes, toggleType }) {
  return (
    <section className="acceptable-fulfillment-types">
      <h2>Acceptable Fulfillment Types</h2>
      <Form>
        <Form.Group>
          <Form.Checkbox
            onChange={() => toggleType(FULFILLMENT_TYPE_INSTORE)}
            checked={selectedTypes.includes(FULFILLMENT_TYPE_INSTORE)}
            label={FULFILLMENT_TYPE_TO_LABEL[FULFILLMENT_TYPE_INSTORE]}
          />
          <Form.Checkbox
            onChange={() => toggleType(FULFILLMENT_TYPE_SHIPPING)}
            checked={selectedTypes.includes(FULFILLMENT_TYPE_SHIPPING)}
            label={FULFILLMENT_TYPE_TO_LABEL[FULFILLMENT_TYPE_SHIPPING]}
          />
          <Form.Checkbox disabled label={DELIVERY_LABEL} />
        </Form.Group>
      </Form>
    </section>
  );
}

AcceptableFulfillmentTypes.propTypes = propTypes;
AcceptableFulfillmentTypes.defaultProps = defaultProps;
export default AcceptableFulfillmentTypes;
