import React, { useMemo, useState } from 'react';

import { Icon, Grid, Checkbox, Segment, Form, Table, Select } from 'semantic-ui-react';

import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _snakeCase from 'lodash/snakeCase';
import { useSelector, useDispatch } from 'react-redux';

import {
  MAX_ALLOWED_IMAGES,
  FEATURED_IMAGE_FIELD,
  LOCATION_IMAGES_FIELD,
} from './Location.constants';
import {
  formatPhoneNumber,
  areLocationImagesSupported,
  getFeaturedBusinessFile,
  getLocationBusinessFiles,
  isLocationSeoMetadataSupported,
  getSeoMetadataConfiguration,
} from './Location.utils';
import { InlineFieldContainer, InputField, PhoneField } from '../..';
import { updateLocation as updateLocationConnect } from '../../../../actions/business';
import {
  validateOnBlur as validateOnBlurConnect,
  inputHasError,
} from '../../../../actions/validation-errors';
import API from '../../../../libs/api';
import { sortItemsByList } from '../../../../libs/array';
import { isAdminUser } from '../../../../libs/auth';
import { getLocationDisplay } from '../../../../libs/location';
import { selectTimezones } from '../../../../selectors';
import {
  selectBusinessId,
  selectBusinessName,
  selectBusinessType,
  selectIsSingleLocation,
} from '../../../../selectors/business';
import { selectUser } from '../../../../selectors/user';
import FileSelectionWell from '../../../modules/files/components/FileSelectionWell';
import FilesManagementModalProvider from '../../../modules/files/components/FilesManagementModalProvider';
import { SOURCE_TYPE_DEFAULT } from '../../../modules/files/constants/sources';
import { useBusinessFiles } from '../../../modules/files/hooks';
import useRecipe from '../../../modules/foundation/hooks/use-recipe';
import { LOCATION_PAGE } from '../../../modules/pages/constants';
import SeoMetadataFields from '../../../modules/seo/components/SeoMetadataFields/SeoMetadataFields';
import { createSeoTitlePlaceholder } from '../../../modules/seo/components/SeoMetadataFields/components/SeoTitle/SeoTitle.utils';
import AddPhotoButton from '../../../pages/ecommerce/catalog/AddPhotoButton';
import EditPhotoButton from '../../../pages/ecommerce/catalog/EditPhotoButton';
import AddressWithVerification from '../../AddressWithVerification';
import Hours from '../Hours';

import './Location.scss';

function Location({
  location,
  index,
  onUpdateLocation,
  onDeleteLocation,
  onUpdateLocationHour,
  onDeleteLocationHour,
  onCopyLocationHours,
  onAddLocationHour,
  onMakePrimary,
  onMakeLegal,
}) {
  const {
    featured_image: featuredImageId,
    location_images: locationImages = [],
    primary_location: primaryLocation,
    legal_location: legalLocation,
    location_name: locationName = '',
    phone_number: phoneNumber = '',
    email = '',
    seo_title: seoTitle = '',
    seo_description: seoDescription = '',
  } = location;

  const locationImagesIds = locationImages.map(({ id }) => id);

  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [localFiles, setLocalFiles] = useState([]);

  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const businessName = useSelector(selectBusinessName);
  const timezones = useSelector(selectTimezones);
  const isSingleLocation = useSelector(selectIsSingleLocation);
  const user = useSelector(selectUser);
  const isAdmin = isAdminUser(user);
  const { businessFiles, loading: loadingFiles } = useBusinessFiles({
    fileIds: [featuredImageId, ...locationImagesIds],
  });
  const { activePatch } = useRecipe();

  const dispatch = useDispatch();
  const validateOnBlur = (payload) => dispatch(validateOnBlurConnect(payload));
  const updateLocation = (payload) => dispatch(updateLocationConnect(payload));

  async function handleAddressChange(data, bypassTouch) {
    const { id: locationId } = location;

    if (data.verified_address) {
      await API.updateLocation(businessId, businessType, locationId, data);
    }

    onUpdateLocation(data, bypassTouch);
  }

  function handleInputChange(e, target) {
    const { name, value } = target;
    onUpdateLocation({ [name]: value });
  }

  function handleTimezoneChange(field, fieldValue) {
    const data = {
      field,
      fieldValue,
      index,
    };

    updateLocation(data);
  }

  function renderLocationAddress() {
    const {
      id,
      street,
      city,
      state,
      zip_code: zipCode,
      verified_address: verifiedAddress,
    } = location;

    const getLabel = (text) => (
      <label>
        <h4 style={{ display: 'inline-block' }}>{text}</h4>
      </label>
    );

    return (
      <Form>
        <AddressWithVerification
          street={street}
          city={city}
          state={state}
          zip_code={zipCode}
          onChange={handleAddressChange}
          addressVerified={verifiedAddress}
          invalidateOnChange={false}
          getLabel={getLabel}
          callOnChangeAlways
          disableVerify={_isNil(id)}
        />
      </Form>
    );
  }

  function getLocationForm() {
    return (
      <InlineFieldContainer
        title={<h2>{locationName || `Location ${index + 1}`}</h2>}
        enableDelete={isAdmin}
        onDelete={async () => {
          await onDeleteLocation(index, location);
        }}
        confirmDelete
      >
        <InputField
          title="Location Name"
          style={{ width: '50%' }}
          value={locationName}
          placeholder="Main Store"
          name="location_name"
          onChange={handleInputChange}
        />
        <PhoneField
          title="Phone Number"
          style={{ width: '50%' }}
          value={formatPhoneNumber(phoneNumber)}
          name="phone_number"
          placeholder="(123) 456-7890"
          onChange={handleInputChange}
        />
        <InputField
          title="Location Email"
          style={{ width: '50%' }}
          value={email}
          name="email"
          placeholder="email@gmail.com"
          onChange={handleInputChange}
          error={inputHasError(`/core/locations.Location Email.${index}`)}
          onValidate={() =>
            validateOnBlur({
              routeAndInput: `/core/locations.Location Email.${index}`,
            })
          }
        />
        {renderLocationAddress()}
        <Grid>
          <Grid.Column width={8}>
            <Checkbox
              label="Primary Location"
              checked={primaryLocation}
              name="primary_location"
              onChange={onMakePrimary}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Checkbox
              label="Legal Address (DBA)"
              checked={legalLocation}
              name="legal_location"
              onChange={onMakeLegal}
            />
          </Grid.Column>
        </Grid>
      </InlineFieldContainer>
    );
  }

  function formatTimezones() {
    return timezones.map((timezone) => {
      return {
        key: timezone,
        value: timezone,
        text: timezone.replace('_', ' '),
      };
    });
  }

  function getTimezoneForm() {
    return (
      <Table compact celled definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={4}>Time Zone</Table.Cell>
            <Table.Cell width={12}>
              <Select
                fluid
                name="timezone"
                placeholder="Select your timezone"
                options={formatTimezones()}
                onChange={(e, { value, name }) => handleTimezoneChange(name, value)}
                value={location.timezone}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }

  function openFileModal(field) {
    const fieldFiles = field === FEATURED_IMAGE_FIELD ? [location[field]] : location[field];
    const fileIdKey = field === FEATURED_IMAGE_FIELD ? '' : 'id';
    const fieldBusinessFiles = sortItemsByList(
      businessFiles || [],
      fieldFiles || [],
      'id',
      fileIdKey,
    );

    setLocalFiles(fieldBusinessFiles);
    setFileModalOpen(true);
  }

  function onUpdateLocalFiles(field, newImages) {
    const imagesPayload = newImages.map(({ id }, order) => {
      if (field === FEATURED_IMAGE_FIELD) {
        return id;
      }
      return { id, order };
    });

    if (field === FEATURED_IMAGE_FIELD) {
      onUpdateLocation({ [FEATURED_IMAGE_FIELD]: _head(imagesPayload) || null });
    } else {
      onUpdateLocation({ [LOCATION_IMAGES_FIELD]: imagesPayload });
    }
  }

  function onSeoMetadataChange(e, { name, value }) {
    const snakeCaseValue = _snakeCase(name);
    onUpdateLocation({ [snakeCaseValue]: value });
  }

  const showLocationImageFields =
    areLocationImagesSupported(activePatch) && !isSingleLocation && !loadingFiles;
  const featuredBusinessFileUrl = useMemo(() => {
    const { url, file } = getFeaturedBusinessFile(businessFiles, featuredImageId) || {};
    return url || file;
  }, [featuredImageId]);
  const locationImagesBusinessFiles = useMemo(
    () => getLocationBusinessFiles(businessFiles, locationImages),
    [locationImagesIds],
  );

  const showSeoMetadataFields = isLocationSeoMetadataSupported(activePatch) && !isSingleLocation;
  const { additionalRequirements, descriptionPlaceholder } = getSeoMetadataConfiguration(location);
  const titlePlaceholder = createSeoTitlePlaceholder(
    `${getLocationDisplay(location)} Location`,
    businessName,
  );

  return (
    <Segment className="location-form" loading={loadingFiles}>
      {getLocationForm()}
      {getTimezoneForm()}
      <Hours
        location={location}
        onUpdateHour={onUpdateLocationHour}
        onDeleteHour={onDeleteLocationHour}
        onAddHour={onAddLocationHour}
        onCopyHours={onCopyLocationHours}
      />
      {showLocationImageFields && (
        <>
          <InlineFieldContainer
            title={<h3>Featured Image</h3>}
            enableDelete={false}
            alignItems="flex-start"
          >
            {!_isEmpty(featuredBusinessFileUrl) ? (
              <EditPhotoButton
                className="photo-button"
                onClick={() => openFileModal(FEATURED_IMAGE_FIELD)}
                imageUrl={featuredBusinessFileUrl}
              >
                <Icon name="pencil" />
              </EditPhotoButton>
            ) : (
              <AddPhotoButton
                className="photo-button"
                onClick={() => openFileModal(FEATURED_IMAGE_FIELD)}
              />
            )}
            {fileModalOpen && (
              <FilesManagementModalProvider
                open={fileModalOpen}
                setOpen={setFileModalOpen}
                initialSelectedFiles={localFiles}
                minFilesAllowed={null}
                maxFilesAllowed={1}
                onSubmit={(images) => onUpdateLocalFiles(FEATURED_IMAGE_FIELD, images)}
                uploadSourceType={SOURCE_TYPE_DEFAULT}
              />
            )}
          </InlineFieldContainer>
          <InlineFieldContainer
            title={<h3>Location Images</h3>}
            enableDelete={false}
            alignItems="flex-start"
          >
            <FileSelectionWell
              files={locationImagesBusinessFiles}
              onUpdateFiles={(images) => onUpdateLocalFiles(LOCATION_IMAGES_FIELD, images)}
              uploadSourceType={SOURCE_TYPE_DEFAULT}
              maxAllowedImages={MAX_ALLOWED_IMAGES}
            />
          </InlineFieldContainer>
        </>
      )}
      {showSeoMetadataFields && (
        <SeoMetadataFields onChange={onSeoMetadataChange} pageType={LOCATION_PAGE}>
          <SeoMetadataFields.SeoTitle
            showWarnings
            title={seoTitle}
            additionalRequirements={additionalRequirements}
            placeholder={titlePlaceholder}
          />
          <SeoMetadataFields.SeoDescription
            showWarnings
            description={seoDescription}
            additionalRequirements={additionalRequirements}
            placeholder={descriptionPlaceholder}
          />
        </SeoMetadataFields>
      )}
    </Segment>
  );
}

export default Location;
