import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';

import { getBaseURLNoSlash } from '../../../../../../../constants/urls';
import API from '../../../../../../../libs/api';

export function getOrderCustomerReportsDownloadUrl(filters) {
  const paramsParser = new URLSearchParams({
    format: 'csv',
    ..._omitBy(filters, _isEmpty),
  });

  return `${getBaseURLNoSlash()}${API.getOrderCustomerReportsUrl()}?${paramsParser.toString()}`;
}
