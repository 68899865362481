import React from 'react';

import { Dropdown, Icon, Tab, Loader, Label, Button, Segment } from 'semantic-ui-react';

import _ from 'lodash';
import _get from 'lodash/get';
import Inspector from 'react-json-inspector';
import { connect } from 'react-redux';
import semver from 'semver';

import NodeControls from './components/NodeControls';
import SchemaValidation from './components/SchemaValidation';
import { RecipeContextProvider } from './components/context/RecipeContext';
import ModalCreatePatch from './components/modals/ModalCreatePatch';
import getComponentForRecipeObject from './components/types/get-component-for-recipe-object';
import {
  addNodeMetadata,
  getLayoutNames,
  resortArray,
  insertValueIntoArray,
  insertKeyValueIntoObject,
  updateValueInObject,
  deleteValueFromObject,
  getParentName,
  removeNodeMetadata,
  getObjectPathById,
  sortPatchesByVersion,
} from './utils/recipe-utils';
import { validateRecipe, valueAtPathIsValid, pathInValidationErrors } from './utils/validation';
import API from '../../../libs/api';
import ErrorHandler from '../../../libs/errors';

import './DevelopersRecipesEditForm.scss';
// eslint-disable-next-line import/imports-first
import 'react-json-inspector/json-inspector.css';

class DevelopersRecipesEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      patchesData: [],

      selectedPatchData: {},
      selectedPatch: null,
      selectedNodePath: [],
      baseRecipe: null,
      recipe: null,
      validationErrors: [],

      noPatchesFound: false,
      isFetchingPatches: true,
      isLoadingPatchedRecipe: true,
      isCreatingPatch: false,
      isSavingPatch: false,

      displayingCreatePatchModal: false,
    };

    this.getTabPanes = this.getTabPanes.bind(this);
    this.updateAndValidateRecipe = this.updateAndValidateRecipe.bind(this);
    this.createPatch = this.createPatch.bind(this);
    this.savePatch = this.savePatch.bind(this);
    this.getEditorTab = this.getEditorTab.bind(this);
    this.startDataFetch = this.startDataFetch.bind(this);
    this.getPatchesData = this.getPatchesData.bind(this);
    this.getRecipeTools = this.getRecipeTools.bind(this);
    this.setSelectedPatch = this.setSelectedPatch.bind(this);
    this.getProposedNextPatchVersion = this.getProposedNextPatchVersion.bind(this);
    this.toggleCreatePatchModal = this.toggleCreatePatchModal.bind(this);
    this.setSelectedNodePath = this.setSelectedNodePath.bind(this);
  }

  componentDidMount() {
    this.startDataFetch();
  }

  getCurrentPatchSelectionValue() {
    const { selectedPatchData = {} } = this.state;

    const foundValues = this.getPatchSelectionOptions().filter(
      ({ value }) => value === selectedPatchData.id,
    );

    if (foundValues.length) {
      return foundValues[0];
    }

    return {};
  }

  getPatchSelectionOptions() {
    const { patchesData = [] } = this.state;

    return patchesData.map(
      ({ id, patch, patch_version: patchVersion, active, draft, description }) => ({
        content: (
          <>
            <div>
              <Label>{patchVersion}</Label>
              {(active || draft) && (
                <Label color={active ? 'green' : 'grey'}>{active ? 'Active' : 'Draft'}</Label>
              )}
            </div>
            <div>
              <h5>{patch.split('/').pop()}</h5>
              {description && <p>{description}</p>}
            </div>
          </>
        ),
        text: patch.split('/').pop(),
        key: id,
        value: id,
      }),
    );
  }

  getTabPanes() {
    const { recipe, selectedPatch, baseRecipe } = this.state;

    return [
      {
        menuItem: 'Recipe',
        render: this.getEditorTab,
      },
      {
        menuItem: 'Base Recipe',
        render: () => (
          <Segment>
            <Inspector data={baseRecipe} />
          </Segment>
        ),
      },
      {
        menuItem: 'Patch',
        render: () => (
          <Segment>
            <Inspector data={selectedPatch} />
          </Segment>
        ),
      },
      {
        menuItem: 'Recipe JSON',
        render: () => (
          <Segment>
            <Inspector data={recipe} />
          </Segment>
        ),
      },
      {
        menuItem: 'Validation Errors',
        render: () => (
          <Segment>
            <SchemaValidation recipe={recipe} />
          </Segment>
        ),
      },
    ];
  }

  getRecipeTools() {
    const { recipe, validationErrors } = this.state;

    return {
      recipe,
      layouts: getLayoutNames(recipe),
      getParentName: (parentId) => getParentName(recipe, parentId),
      getParentNode: (parentId) => {
        const { found, path } = getObjectPathById(recipe, parentId);

        if (!found) {
          return null;
        }

        if (!path.length) {
          return recipe;
        }

        return _.get(recipe, path);
      },
      deleteValueFromObject: (ky, parentId) => {
        const newRecipe = deleteValueFromObject(recipe, ky, parentId);
        this.updateAndValidateRecipe(addNodeMetadata(newRecipe));
      },
      updateValueInObject: (ky, value, parentId) => {
        const newRecipe = updateValueInObject(recipe, ky, value, parentId);
        this.updateAndValidateRecipe(addNodeMetadata(newRecipe));
      },
      insertKeyValueIntoObject: (ky, value, parentId) => {
        const newRecipe = insertKeyValueIntoObject(recipe, ky, value, parentId);
        this.updateAndValidateRecipe(addNodeMetadata(newRecipe));
      },
      insertValueIntoArray: (ky, value, parentId) => {
        const newRecipe = insertValueIntoArray(recipe, ky, value, parentId);
        this.updateAndValidateRecipe(addNodeMetadata(newRecipe));
      },
      resortArray: (key, parentId, oldIndex, newIndex) => {
        const newRecipe = resortArray(
          recipe,
          key,
          parentId,
          oldIndex + 1, // The id is in index 0
          newIndex + 1,
        );
        this.setState({ recipe: newRecipe });
      },
      isValid: (parentId) => {
        const { path, found } = getObjectPathById(recipe, parentId);

        if (!found || path.length === 0) {
          return true;
        }

        return valueAtPathIsValid(path.join('.'), validationErrors);
      },
      nodeInInvalidPath(parentId, key) {
        const { path } = getObjectPathById(recipe, parentId);
        const nodePath = [...path, key];

        return pathInValidationErrors(nodePath.join('.'), validationErrors);
      },
    };
  }

  getEditorTab() {
    const { recipe, selectedNodePath } = this.state;

    return (
      <RecipeContextProvider value={this.getRecipeTools()}>
        <Segment>
          <NodeControls
            selectedNodePath={selectedNodePath}
            recipe={recipe}
            setSelectedNodePath={this.setSelectedNodePath}
          />
          {Object.keys(recipe).map((ky, i) =>
            getComponentForRecipeObject(ky, null, recipe, i, {
              selectedNodePath,
              path: [ky],
            }),
          )}
        </Segment>
      </RecipeContextProvider>
    );
  }

  getProposedNextPatchVersion() {
    const { baseRecipe, patchesData } = this.state;
    if (baseRecipe) {
      const { patch_version: version } = sortPatchesByVersion(patchesData)[0];
      return semver.inc(version, 'patch');
    }

    return null;
  }

  setSelectedNodePath(e, { result: { value } }) {
    this.setState({ selectedNodePath: value.split('.') });
  }

  async getPatchesData(websiteId) {
    this.setState({ isFetchingPatches: true });

    try {
      const { data } = await API.getPatches(websiteId);
      return data;
    } catch (e) {
      ErrorHandler.capture(e);
      return [];
    } finally {
      this.setState({ isFetchingPatches: false });
    }
  }

  async setSelectedPatch(e, { value }) {
    const { patchesData } = this.state;
    const selectedPatchData = patchesData.find((p) => p.id === value);

    this.setState({
      isLoadingPatchedRecipe: true,
      selectedPatchData,
    });

    try {
      const {
        data: { recipe, patch, base_recipe: baseRecipe },
      } = await API.getPatchFiles(selectedPatchData.id);

      this.setState({
        baseRecipe,
        selectedPatch: patch,
        recipe: addNodeMetadata(recipe),
      });
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      this.setState({
        isLoadingPatchedRecipe: false,
      });
    }
  }

  clearState() {
    this.setState({
      patchesData: [],
      selectedPatchData: {},
      selectedPatch: null,
      baseRecipe: null,
      recipe: null,
      noPatchesFound: false,
      isFetchingPatches: true,
      isLoadingPatchedRecipe: false,
      isCreatingPatch: false,
      displayingCreatePatchModal: false,
    });
  }

  updateAndValidateRecipe(recipe) {
    const validationErrors = validateRecipe(recipe);

    this.setState({ recipe, validationErrors });
  }

  async startDataFetch(id = null) {
    this.clearState();
    const { websiteId } = this.props;
    const patchesData = await this.getPatchesData(websiteId);
    let selectedPatchData = patchesData.find((p) => p.active);

    if (!_.isNil(id)) {
      selectedPatchData = patchesData.find((p) => p.id === id);
    }
    const noPatchesFound = patchesData.length === 0;

    this.setState(
      {
        patchesData: sortPatchesByVersion(patchesData),
        selectedPatchData,
        noPatchesFound,
      },
      () => {
        if (!noPatchesFound) {
          this.setSelectedPatch(null, { value: selectedPatchData.id });
        }
      },
    );
  }

  toggleCreatePatchModal() {
    const { displayingCreatePatchModal } = this.state;
    this.setState({ displayingCreatePatchModal: !displayingCreatePatchModal });
  }

  async createPatch(description, patchVersion, active, draft) {
    const {
      recipe,
      selectedPatchData: { base_recipe: baseRecipe, website: websiteId, theme },
    } = this.state;

    this.setState({ isCreatingPatch: true }, async () => {
      try {
        const { data: patch } = await API.getRecipeDiff(baseRecipe.id, removeNodeMetadata(recipe));
        const patchAsFile = new Blob([JSON.stringify(patch)], {
          type: 'text/json',
        });
        const { data: patchObj } = await API.createPatch({
          websiteId,
          baseRecipeId: baseRecipe.id,
          patch: patchAsFile,
          description,
          patchVersion,
          themeId: theme.id,
          active,
          draft,
        });
        this.startDataFetch(patchObj.id);
      } catch (e) {
        ErrorHandler.capture(e);
      } finally {
        this.setState({ isCreatingPatch: false });
      }
    });
  }

  async savePatch(patchId, description, active, draft) {
    const {
      recipe,
      selectedPatchData: { base_recipe: baseRecipe, website: websiteId },
    } = this.state;

    this.setState({ isSavingPatch: true }, async () => {
      try {
        const { data: patch } = await API.getRecipeDiff(baseRecipe.id, removeNodeMetadata(recipe));
        const patchAsFile = new Blob([JSON.stringify(patch)], {
          type: 'text/json',
        });
        await API.updatePatch(websiteId, patchId, {
          patch: patchAsFile,
          description,
          active,
          draft,
        });
        this.startDataFetch(patchId);
      } catch (e) {
        ErrorHandler.capture(e);
      } finally {
        this.setState({ isSavingPatch: false });
      }
    });
  }

  render() {
    const {
      recipe,
      isFetchingPatches,
      isSavingPatch,
      noPatchesFound,
      validationErrors,
      selectedPatchData = {},
      isLoadingPatchedRecipe,
      displayingCreatePatchModal,
      isCreatingPatch,
    } = this.state;

    const proposedNextPatchVersion = this.getProposedNextPatchVersion();

    return (
      <div id="developer-recipes-wrapper">
        <div id="developer-recipes-header">
          <div>
            {noPatchesFound ? (
              <Label color="red">No patches found!</Label>
            ) : (
              <div>
                <div className="developer-recipes-header-patch-selection">
                  <Dropdown
                    className="developers-recipe-search-dropdown"
                    selection
                    placeholder="Select a patch"
                    value={this.getCurrentPatchSelectionValue().value}
                    onChange={this.setSelectedPatch}
                    disabled={noPatchesFound}
                    loading={isFetchingPatches}
                    error={noPatchesFound}
                    options={this.getPatchSelectionOptions()}
                  />
                  {selectedPatchData && (
                    <>
                      <Label>
                        Patch {selectedPatchData && `v${selectedPatchData.patch_version}`}
                      </Label>
                      {selectedPatchData.active && <Label color="green">Active</Label>}
                      {selectedPatchData.draft && <Label color="grey">Draft</Label>}
                    </>
                  )}
                </div>
                {selectedPatchData && selectedPatchData.description && (
                  <p className="developers-recipe-patch-description">
                    Description: {selectedPatchData.description}
                  </p>
                )}
              </div>
            )}
          </div>
          <div>
            <Button.Group color="grey" inverted className="patch-toolbar">
              <Button icon>
                <Icon name="download" />
              </Button>
              <Button>Delete</Button>
            </Button.Group>
            <ModalCreatePatch
              open={displayingCreatePatchModal}
              close={this.toggleCreatePatchModal}
              version={
                selectedPatchData.draft ? selectedPatchData.patch_version : proposedNextPatchVersion
              }
              description={selectedPatchData.description}
              active={selectedPatchData.active}
              draft={selectedPatchData.draft}
              patchId={selectedPatchData.id}
              createPatch={this.createPatch}
              isCreatingPatch={isCreatingPatch}
              savePatch={this.savePatch}
              isSavingPatch={isSavingPatch}
              saveMode={selectedPatchData && selectedPatchData.draft}
              trigger={
                <Button
                  disabled={validationErrors.length > 0}
                  as="div"
                  labelPosition="right"
                  onClick={this.toggleCreatePatchModal}
                >
                  <Button color="grey">
                    <Icon className="code-branch" />
                    {selectedPatchData && !selectedPatchData.draft
                      ? 'Create Patch Draft'
                      : 'Save Patch'}
                  </Button>
                  <Label color="grey" basic>
                    {isLoadingPatchedRecipe && <Loader size="mini" active inline />}
                    {!isLoadingPatchedRecipe &&
                      `v${
                        selectedPatchData && selectedPatchData.draft
                          ? selectedPatchData.patch_version
                          : this.getProposedNextPatchVersion()
                      }`}
                  </Label>
                </Button>
              }
            />
          </div>
        </div>
        {isLoadingPatchedRecipe && (
          <Loader size="small" active inline style={{ marginTop: '20px' }} />
        )}
        {recipe && !isLoadingPatchedRecipe && (
          <>
            <Tab
              menu={{ fluid: true, vertical: true }}
              menuPosition="left"
              className="developers-recipe-edit-tabs"
              panes={this.getTabPanes()}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  websiteId: _get(state, 'website.core.value.id'),
});

export default connect(mapStateToProps)(DevelopersRecipesEditForm);
