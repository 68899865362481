import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_WEIGHTS } from '../../constants';
import { getWeightsFromGoogleFontRequest } from '../../services/font';

export function getFinalFont(font, defaultFont) {
  const finalFont = (font || defaultFont || '').replace(/'/g, '');

  return finalFont;
}

export function getFinalFontWithWeights(font, defaultFont, googleFontRequest) {
  const finalFont = getFinalFont(font, defaultFont);
  if (_isEmpty(finalFont)) {
    return finalFont || '';
  }

  const variants = getWeightsFromGoogleFontRequest(finalFont, googleFontRequest);

  if (!_isEmpty(variants)) {
    const plural = variants.length > 1 ? 's' : '';
    return `${finalFont} (${variants.length} weight${plural})`;
  }
  return finalFont;
}

export function getFontsForGoogleFontRequest(
  isHeader,
  family,
  headerFont,
  pageFont,
  defaultHeaderFont,
  defaultPageFont,
) {
  const finalHeaderFont = isHeader ? family : getFinalFont(headerFont, defaultHeaderFont);
  const finalPageFont = isHeader ? getFinalFont(pageFont, defaultPageFont) : family;
  return [finalHeaderFont, finalPageFont];
}

export function getWeightsForGoogleFontRequest(
  isHeader,
  selectedWeights,
  headerFont,
  pageFont,
  googleFontRequest,
) {
  const headerWeights = isHeader
    ? selectedWeights
    : getWeightsFromGoogleFontRequest(headerFont, googleFontRequest);

  const pageWeights = isHeader
    ? getWeightsFromGoogleFontRequest(pageFont, googleFontRequest)
    : selectedWeights;

  if (pageFont === headerFont) {
    return isHeader ? [headerWeights, []] : [pageWeights];
  }

  return [
    !_isEmpty(headerWeights) ? headerWeights : DEFAULT_WEIGHTS,
    !_isEmpty(pageWeights) ? pageWeights : DEFAULT_WEIGHTS,
  ];
}
