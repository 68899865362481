import {
  SALES_GROUP_LEAD_SOURCE_MAP,
  INBOUND_LEAD_SOURCE,
  INTERNAL_SIGN_UP_LEAD_CHANEL,
  REP_SIGN_UP_LEAD_CHANNEL,
  SIGN_UP_LEAD_CHANNEL,
} from './constants';
import { ROLE_SALES, SALES_GROUPS } from '../../constants/auth';
import { isAuthorizedForRole, isValidUser, isAdminUser, isUserInGroup } from '../auth';

export function getLeadSourceFromUser(user) {
  if (!isValidUser(user) || isAdminUser(user)) {
    return INBOUND_LEAD_SOURCE;
  }

  const authorizations = SALES_GROUPS.map((group) => ({
    group,
    authorized: isUserInGroup(user, group),
  })).filter(({ authorized }) => authorized);

  if (authorizations.length) {
    return SALES_GROUP_LEAD_SOURCE_MAP[authorizations[0].group] || '';
  }

  return INBOUND_LEAD_SOURCE;
}

export function getLeadChannelFromUser(user, userRole) {
  if (!isValidUser(user)) {
    return SIGN_UP_LEAD_CHANNEL;
  }

  if (isAdminUser(user)) {
    return INTERNAL_SIGN_UP_LEAD_CHANEL;
  }

  if (isAuthorizedForRole(userRole, ROLE_SALES)) {
    return REP_SIGN_UP_LEAD_CHANNEL;
  }

  return SIGN_UP_LEAD_CHANNEL;
}
