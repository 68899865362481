import React, { useContext } from 'react';

import { Select } from 'semantic-ui-react';

import { getSortOptions } from './SortMechanismSelector.utils';
import { TypographyContext } from '../../contexts/Typography.context';

import './SortMechanismSelector.scss';

const propTypes = {};
const defaultProps = {};

export default function SortMechanismSelector() {
  const { sort, setSort } = useContext(TypographyContext);

  function onChange(event, { value }) {
    setSort(value);
  }

  return (
    <Select
      className="sort-mechanism-selector"
      value={sort}
      options={getSortOptions()}
      onChange={onChange}
      icon="sort content ascending"
    />
  );
}

SortMechanismSelector.propTypes = propTypes;
SortMechanismSelector.defaultProps = defaultProps;
