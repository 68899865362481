import React from 'react';

import { Divider } from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { isAnnouncementFormSupported } from './BusinessInfo.utils';
import {
  updateBusinessName,
  updateBusinessLogo,
  updateBusinessAbout,
} from '../../../actions/business';
import { validateOnBlur, inputHasError } from '../../../actions/validation-errors';
import { selectWebsiteContentNodes } from '../../../selectors/website';
import { InlineFieldContainer, InputField } from '../../common';
import FormContainer from '../../common/FormContainer';
import ImagePlaceholderField from '../../common/ImagePlaceholderField';
import MarkdownField from '../../fields/MarkdownField';
import AnnouncementForm from '../../modules/announcement/components/AnnouncementForm';
import useRecipe from '../../modules/foundation/hooks/use-recipe';
import LayoutComponentList from '../ImagesPage/LayoutComponentList';

import './BusinessInfo.scss';

export default function BusinessInfo() {
  const dispatch = useDispatch();
  const contentNodes = useSelector(selectWebsiteContentNodes);
  const businessName = useSelector((state) => _get(state, 'business.core.value.name'));
  const businessLogo = useSelector((state) => _get(state, 'business.core.value.logo'));
  const about = useSelector((state) => _get(state, 'business.core.value.about[0]'));
  const aboutMarkdown = useSelector((state) => _get(state, 'business.core.value.about_markdown'));
  const layouts = useSelector((state) => _get(state, 'website.layouts.value'));
  const { announcementForm: announcementFormEnabled } = useFlags();
  const { activePatch } = useRecipe();
  const shouldShowAnnouncementForm =
    !_isEmpty(layouts) && announcementFormEnabled && isAnnouncementFormSupported(activePatch);
  const shouldShowMessageDisplay = !_isEmpty(layouts);

  function handleNameChange(e, target) {
    const { value } = target;

    dispatch(updateBusinessName({ value }));
  }

  function handleAboutChange(value) {
    dispatch(updateBusinessAbout({ value, index: 0 }));
  }

  function handleImageChange(e) {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);

    const image = { url, source: 'LOCAL', file };

    dispatch(updateBusinessLogo({ field: 'logo', fieldValue: image }));
  }

  function handleImageDelete() {
    dispatch(
      updateBusinessLogo({
        field: 'logo',
        fieldValue: { url: '', source: 'LOCAL', file: '' },
      }),
    );
  }

  return (
    <div className="business-info">
      <FormContainer loadedKeyPath={['business', 'core']}>
        <InlineFieldContainer title={<h2>Business Name</h2>} enableDelete={false}>
          <InputField
            style={{ width: '50%' }}
            value={businessName}
            placeholder="My Business"
            onChange={handleNameChange}
            error={inputHasError('/core/profile.Business Name')}
            onValidate={() =>
              dispatch(validateOnBlur({ routeAndInput: '/core/profile.Business Name' }))
            }
          />
        </InlineFieldContainer>
        <InlineFieldContainer title={<h2>Logo</h2>} enableDelete={false} alignItems="flex-start">
          <ImagePlaceholderField
            url={typeof businessLogo === 'string' ? businessLogo : businessLogo && businessLogo.url}
            onChange={handleImageChange}
            onDelete={handleImageDelete}
            enableDelete
          />
        </InlineFieldContainer>
        {_isEmpty(contentNodes) && (
          <InlineFieldContainer title={<h2>About</h2>} enableDelete={false}>
            <MarkdownField value={aboutMarkdown || about} onChange={handleAboutChange} />
          </InlineFieldContainer>
        )}
        {shouldShowMessageDisplay && <LayoutComponentList layouts={layouts} />}
        {shouldShowAnnouncementForm && (
          <>
            <Divider />
            <AnnouncementForm />
          </>
        )}
      </FormContainer>
    </div>
  );
}
