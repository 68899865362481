import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNul from 'lodash/isNil';

import ErrorHandler, { getErrorMessage } from '../../../../../../../libs/errors/errors';
import CloseableModal from '../../../../../../common/CloseableModal';
import ConfirmModal from '../../../../../../common/ConfirmModal';
import MarkdownField from '../../../../../../fields/MarkdownField';
import { DismissibleMessage } from '../../../../../utils/components';
import useTeam from '../../../../hooks/use-team';
import { TeamPropType } from '../../../types/team';

import './CreateTeamModal.scss';

const propTypes = {
  initialFormData: TeamPropType,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPostSave: PropTypes.func,
  onPostDelete: PropTypes.func,
  trigger: PropTypes.node,
};

const defaultProps = {
  initialFormData: {},
  onPostSave: null,
  onPostDelete: null,
  trigger: null,
};

export default function CreateTeamModal({
  initialFormData,
  open,
  onClose: onCloseProp,
  onPostSave,
  onPostDelete,
  trigger,
}) {
  const { addTeam, updateTeam, deleteTeam } = useTeam();
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [formData, setFormData] = useState({ ...(initialFormData || {}) });

  const { id: teamId, name = '', description = '' } = formData || {};

  const existing = !_isNul(teamId);

  function isNameValid() {
    return !_isEmpty(name);
  }

  function getFormValidationMessage() {
    if (!isNameValid()) {
      return 'Name is required';
    }
    return '';
  }

  useEffect(() => {
    setErrorMessage(getFormValidationMessage());
  }, [formData]);

  function resetFormValues() {
    setLoading(false);
    setErrorMessage('');
    setTouched(false);
    setFormData({});
  }

  function onClose() {
    resetFormValues();
    if (onCloseProp) {
      onCloseProp();
    }
  }

  function onInputChange(e, { value, name: inputName }) {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [inputName]: value,
    }));
  }

  function onDescriptionChange(newDescription) {
    setFormData((previousFormData) => ({
      ...previousFormData,
      description: newDescription,
    }));
  }

  async function onSave() {
    setTouched(true);

    const formErrorMessage = getFormValidationMessage();
    if (!_isEmpty(formErrorMessage)) {
      setErrorMessage(formErrorMessage);
      return;
    }

    try {
      setLoading(true);
      if (!existing) {
        await addTeam({ name, description });
      } else {
        await updateTeam(teamId, { name, description });
      }

      if (onPostSave) {
        await onPostSave(formData);
      }

      onClose();
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  function displayDeleteConfirmModal() {
    setShowDeleteConfirmModal(true);
  }

  function hideDeleteConfirmModal() {
    setShowDeleteConfirmModal(false);
  }

  async function onDeleteConfirm() {
    try {
      setLoading(true);

      await deleteTeam(teamId);

      if (onPostDelete) {
        await onPostDelete(formData);
      }

      onClose();
    } catch (e) {
      ErrorHandler.capture(e);
      hideDeleteConfirmModal();
      setErrorMessage(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  return (
    <CloseableModal
      className="create-team-modal"
      open={open}
      onClose={onClose}
      trigger={trigger}
      header={existing ? 'Edit Team' : 'Create Team'}
      size="large"
    >
      <Modal.Content>
        <Form>
          <Form.Input
            label="Name"
            value={name || ''}
            placeholder="Name"
            className="name"
            name="name"
            required
            onChange={onInputChange}
            error={touched && !isNameValid()}
          />

          <MarkdownField
            value={description}
            onChange={onDescriptionChange}
            options={{
              placeholder: 'Enter a team description here',
              minHeight: '200px',
            }}
          />

          {!_isEmpty(errorMessage) && touched && (
            <DismissibleMessage initialVisible error content={errorMessage} />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {existing && (
          <Button
            className="delete-red"
            content="Delete"
            onClick={displayDeleteConfirmModal}
            disabled={loading}
          />
        )}
        <Button
          className="action-button-gray"
          content="Cancel"
          onClick={onClose}
          disabled={loading}
        />
        <Button
          className="secondary-navy"
          content="Save"
          onClick={onSave}
          disabled={loading}
          loading={loading}
        />
      </Modal.Actions>

      <ConfirmModal
        open={showDeleteConfirmModal}
        onClose={hideDeleteConfirmModal}
        header="Delete Team"
        content={`Are you sure you want to remove team: ${name}. Team members that currently belong to this team will not be deleted, although their association to this team will be removed.`}
        onCancel={hideDeleteConfirmModal}
        onConfirm={onDeleteConfirm}
        className="delete-team-modal"
        confirmButton={
          <Button
            className="delete-red"
            content="Yes"
            disabled={loading}
            loading={loading}
            primary={false}
          />
        }
        cancelButton={<Button className="action-button-gray" content="Cancel" disabled={loading} />}
      />
    </CloseableModal>
  );
}

CreateTeamModal.propTypes = propTypes;
CreateTeamModal.defaultProps = defaultProps;
