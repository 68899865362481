import React from 'react';

import { Form } from 'semantic-ui-react';

export default function FormInput({ value, name, label, handleUpdate }) {
  return (
    <Form.Input
      required
      onChange={handleUpdate}
      value={value}
      name={name}
      label={label}
      placeholder={label}
    />
  );
}
