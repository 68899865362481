import React from 'react';

import { Segment, Form, Button, Header, Message } from 'semantic-ui-react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { DEFAULT_ERROR_MESSAGE_TIMEOUT_SECONDS } from '../../constants/constants';
import API from '../../libs/api';
import { identifyUsername } from '../../libs/auth';
import Validate from '../../libs/validate';
import ConfirmationPage from '../common/ConfirmationPage';

class ForgotForm extends React.Component {
  static validateEmail(value) {
    return Validate.REGEX.EMAIL.test(value);
  }

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errorMessage: '',
      infoMessage: '',
      loading: false,
      renderConfirmation: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  handleChange({ target: { name, value } }) {
    this.setState({
      [name]: value,
    });
  }

  setFormMessage(messageField, message, resetEventually = true) {
    if (resetEventually) {
      this.setState({ [messageField]: message }, () =>
        setTimeout(
          () => this.setFormMessage(messageField, '', false),
          DEFAULT_ERROR_MESSAGE_TIMEOUT_SECONDS * 1000,
        ),
      );
    }

    this.setState({ [messageField]: message });
  }

  resetForm() {
    this.setState({
      email: '',
    });
  }

  toggleLoading(cb = null) {
    this.setState(
      ({ loading }) => ({ loading: !loading }),
      () => cb && cb(),
    );
  }

  validateParams() {
    const { email } = this.state;

    if (email.length === 0) {
      this.setFormMessage('errorMessage', 'Please enter your email');
      return false;
    }

    return true;
  }

  async submit() {
    const { email } = this.state;
    const paramsAreValid = this.validateParams();

    if (paramsAreValid) {
      identifyUsername(email);

      this.toggleLoading(async () => {
        try {
          // Login
          const loginResult = await API.resetPassword(email);

          // Get result
          const { data } = loginResult;
          const { detail } = data;

          if (detail) {
            this.setFormMessage('infoMessage', detail);
          }

          this.toggleConfirmation(true);
        } catch (e) {
          this.setFormMessage('infoMessage', 'Password email has been sent');
        } finally {
          this.toggleLoading();
          this.resetForm();
        }
      });
    }
  }

  toggleConfirmation(isVisible) {
    this.setState({
      renderConfirmation: isVisible,
    });
  }

  renderConfirmation() {
    return (
      <div className="forgot-form">
        <ConfirmationPage
          icon="mail outline"
          subject="Check your email"
          content={
            <p>
              We just emailed you a link that will allow you to reset your password.
              <br />
              Please check your inbox and click on the link.
            </p>
          }
          actionContent={
            <div>
              <p>Didn&apos;t receive the email?</p>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                onClick={() => this.toggleConfirmation(false)}
                onKeyDown={() => this.toggleConfirmation(false)}
                role="button"
                tabIndex={-1}
                className="forgot-password"
              >
                <span>Please re-enter your email address</span>
              </a>
            </div>
          }
        />
      </div>
    );
  }

  render() {
    const { email, errorMessage, infoMessage, loading, renderConfirmation } = this.state;

    if (renderConfirmation) {
      return this.renderConfirmation();
    }

    return (
      <div className="forgot-form">
        <Segment>
          <Form
            onSubmit={() => this.submit()}
            error={errorMessage.length !== 0}
            success={infoMessage.length !== 0}
            loading={loading}
          >
            <Header as="h2" textAlign="center">
              Forgot Password
            </Header>
            <Form.Field>
              <span>
                Enter your email address below, and we will email instructions for setting a new
                one.
              </span>
            </Form.Field>
            <Form.Input
              onChange={this.handleChange}
              error={errorMessage.length !== 0}
              placeholder="Email"
              name="email"
              label="Email"
              value={email}
            />
            <Form.Field>
              <Link to="/login" className="login">
                <span>Return to Login</span>
              </Link>
            </Form.Field>
            <Button
              disabled={!email || !ForgotForm.validateEmail(email)}
              type="submit"
              primary
              fluid
            >
              Reset My Password
            </Button>
            <Message error header={errorMessage} />
            <Message success header={infoMessage} />
          </Form>
        </Segment>
      </div>
    );
  }
}

export default connect()(ForgotForm);
