import React from 'react';

import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';

import { DATE_TIME_FORMAT } from './constants';
import DateUtils from '../../../../../../libs/date-utils';

import './WoflowMenuJobsTable.scss';

const propTypes = {
  jobsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      jobId: PropTypes.string,
      status: PropTypes.string,
      fileCount: PropTypes.number,
      createdAt: PropTypes.string,
      completedAt: PropTypes.string,
    }),
  ).isRequired,
  handleApproveMenu: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

function WoflowMenuJobsTable({ jobsData, handleApproveMenu, loading }) {
  return (
    <Table className="woflow-menu-jobs-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>s/n</Table.HeaderCell>
          <Table.HeaderCell>Job Id</Table.HeaderCell>
          <Table.HeaderCell>No of Files</Table.HeaderCell>
          <Table.HeaderCell>Created At</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Completed At</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body className="table-body">
        {jobsData.map((jobData, index) => {
          const { id, jobId, status, fileCount, createdAt, completedAt } = jobData;
          const disabled = loading || status !== 'Complete';
          return (
            <Table.Row key={id}>
              <Table.Cell>
                <span>{index + 1}</span>
              </Table.Cell>
              <Table.Cell>
                <span>{jobId}</span>
              </Table.Cell>
              <Table.Cell>
                <span>{fileCount}</span>
              </Table.Cell>
              <Table.Cell>
                <span>{DateUtils.format(createdAt, DATE_TIME_FORMAT)}</span>
              </Table.Cell>
              <Table.Cell>
                <span>{status}</span>
              </Table.Cell>
              <Table.Cell>
                <span>{DateUtils.format(completedAt, DATE_TIME_FORMAT)}</span>
              </Table.Cell>
              <Table.Cell>
                <Button disabled={disabled} onClick={() => handleApproveMenu(jobId)}>
                  Approve
                </Button>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}

WoflowMenuJobsTable.propTypes = propTypes;
WoflowMenuJobsTable.defaultProps = defaultProps;

export default WoflowMenuJobsTable;
