import React from 'react';

import { Label, Icon, Table, Header, Checkbox, Input } from 'semantic-ui-react';

import _startCase from 'lodash/startCase';
import { useDispatch } from 'react-redux';

import { isCustomReview, getIconName } from './ReviewsTableRow.utils';
import { inputHasError, validateOnBlur } from '../../../../../actions/validation-errors';
import { TextareaField } from '../../../../common';

function ReviewsTableRow({
  onSelect,
  selected,
  onToggleApproval,
  onInputChange,
  review,
  reviewIndex,
  className,
}) {
  const dispatch = useDispatch();
  const { author, source, text, to_display: toDisplay } = review;
  return (
    <Table.Row className={className}>
      <Table.Cell>
        <Checkbox onChange={onSelect} checked={selected} />
      </Table.Cell>
      <Table.Cell>
        <Header as="h4">
          {isCustomReview(source) ? (
            <Input
              value={author}
              placeholder="Reviewer's Name"
              name="author"
              onChange={(e) => onInputChange(e, 'author', reviewIndex)}
              error={inputHasError(`/core/reviews.Reviewers Name.${reviewIndex}`)}
            />
          ) : (
            _startCase(author.toLowerCase())
          )}
        </Header>
      </Table.Cell>
      <Table.Cell singleLine>
        <Label>
          {!isCustomReview(source) && <Icon name={getIconName(source)} />} {source.toLowerCase()}
        </Label>
      </Table.Cell>
      <Table.Cell>
        {isCustomReview(source) ? (
          <TextareaField
            style={{ width: '100%', margin: 0 }}
            value={text}
            placeholder="Comment"
            disabled={!isCustomReview(source)}
            onChange={(e) => onInputChange(e, 'text', reviewIndex)}
            error={inputHasError(`/core/reviews.Review.${reviewIndex}`)}
            onValidate={() =>
              dispatch(
                validateOnBlur({
                  routeAndInput: `/core/reviews.Review.${reviewIndex}`,
                }),
              )
            }
          />
        ) : (
          text
        )}
      </Table.Cell>
      <Table.Cell horizontalAlign="center" verticalAlign="middle">
        <Checkbox toggle checked={toDisplay} onClick={onToggleApproval} />
      </Table.Cell>
    </Table.Row>
  );
}

export default ReviewsTableRow;
