import Validate from '../../../../../../../libs/validate';

export const FORM_DESCRIPTIONS = {
  role: 'Position this person has on your business. For example: "Manager" or "Front Desk".',
  description:
    'Description of this person: What value this person bring to the business? What background this person has?',
  phone: 'Staff member phone number',
  email: 'Staff member email address',
  locations: 'Locations this staff member is available at',
  teams: 'Teams this staff member is part of',
  hours: 'Availability of staff member',
  social: 'Social media accounts for staff member',
  interactions: 'Configure up to two buttons to appear in the staff member details',
  associatedItemVariations: 'Services this staff member is associated with',
};

export const FORM_TITLES = {
  name: 'Name',
  role: 'Role',
  phone: 'Phone',
  email: 'Email',
  description: 'Description',
  show: 'Display',
  locations: 'Locations',
  teams: 'Teams',
  hours: 'Availability',
  social: 'Social',
  socialAdd: '',
  socialDetail: '',
  interactions: 'Buttons',
  associatedItemVariations: 'Services',
};

export const FORM_PLACEHOLDERS = {
  name: 'Name',
  role: 'Role',
  description: 'Description',
  locations: 'Locations',
  teams: 'Teams',
  hours: 'Availability',
  social: 'Social',
  associatedItemVariations: 'Services',
};

export const FORM_INVALID_FIELDS = {
  name: Validate.getErrorMessage('defined'),
  email: Validate.getErrorMessage('email'),
  phone: Validate.getErrorMessage('phone'),
  social: Validate.getErrorMessage('socialMediaUsername'),
  socialDetail: Validate.getErrorMessage('socialMediaUsername'),
};
