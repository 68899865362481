import PropTypes from 'prop-types';

import { EmptyBlockPropType } from './empty-block.types';
import { LegacyFormBlockPropType, FormBlockPropType } from './form-block.types';
import { ImageBlockPropType } from './image-block.types';
import { JsonBlockPropType } from './json.types';
import { TextBlockPropType } from './text-block.types';
import {
  TEXT_BLOCK_ELEMENT_TYPE,
  IMAGE_BLOCK_ELEMENT_TYPE,
  LEGACY_FORM_BLOCK_ELEMENT_TYPE,
  EMPTY_BLOCK_ELEMENT_TYPE,
  JSON_BLOCK_ELEMENT_TYPE,
  FORM_BLOCK_ELEMENT_TYPE,
} from '../constants/types';

export const ElementBlockPropType = PropTypes.oneOfType([
  TextBlockPropType,
  ImageBlockPropType,
  LegacyFormBlockPropType,
  EmptyBlockPropType,
]);

export const ElementBlockStatePropType = PropTypes.shape({
  [TEXT_BLOCK_ELEMENT_TYPE]: TextBlockPropType,
  [IMAGE_BLOCK_ELEMENT_TYPE]: ImageBlockPropType,
  [LEGACY_FORM_BLOCK_ELEMENT_TYPE]: LegacyFormBlockPropType,
  [FORM_BLOCK_ELEMENT_TYPE]: FormBlockPropType,
  [EMPTY_BLOCK_ELEMENT_TYPE]: EmptyBlockPropType,
  [JSON_BLOCK_ELEMENT_TYPE]: JsonBlockPropType,
});
