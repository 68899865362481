import _find from 'lodash/find';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { objectToCamelCase } from '../../../../../libs/format';
import {
  MARKDOWN_CONTENT_TYPE,
  TEXT_CONTENT_TYPE,
} from '../../../component-configuration/constants/content';
import { createComponentConfiguration } from '../../../component-configuration/services/component-configuration';
import { createContentNodeConfiguration } from '../../../component-configuration/services/content-node-configuration';
import { createDefaultInteraction } from '../../../website-component-interaction/WebsiteComponentInteractionContainer/WebsiteComponentInteractionContainer.utils';
import { CURATED_HERO_COMPONENT } from '../../constants/component';
import {
  CONTENT_TYPE_CTA,
  CONTENT_TYPE_SETTINGS_KEY,
  CONTENT_TYPE_TEXT,
  CURATED_HERO_SETTINGS,
  RECIPE_CURATED_HERO_OPTIONS,
  WIDGET_TYPE_INPUT,
  WIDGET_TYPE_MARKDOWN,
} from '../../constants/curated-hero';

export function getCuratedHeroSettings(componentConfiguration) {
  const { enabled, data = {} } = componentConfiguration || {};
  const { name } = data;

  return { enabled, name };
}

export function getCuratedHeroNameOptions(recipeName) {
  return RECIPE_CURATED_HERO_OPTIONS[recipeName] || [];
}

export function getComponentConfigurationSettings(name) {
  const curatedHeroOptions = CURATED_HERO_SETTINGS[name];

  if (_isEmpty(curatedHeroOptions)) {
    return [];
  }

  const { componentConfigurations = [] } = curatedHeroOptions;

  return componentConfigurations;
}

export function getComponentConfigurationSetting(name, componentConfiguration) {
  const componentConfigurations = getComponentConfigurationSettings(name);

  if (_isEmpty(componentConfigurations)) {
    return {};
  }

  return _find(componentConfigurations, {
    componentId: componentConfiguration.componentIdentifier,
  });
}

export function getComponentConfiguration(setting, componentConfigurations) {
  return _find(
    componentConfigurations,
    (componentConfiguration) => componentConfiguration.componentIdentifier === setting.componentId,
  );
}

export function getInitialConfiguration(initialParentConfiguration, pageId) {
  return !_isEmpty(initialParentConfiguration) && !_isNil(initialParentConfiguration)
    ? initialParentConfiguration
    : createComponentConfiguration({
        fastId: CURATED_HERO_COMPONENT,
        page: pageId,
        enabled: false,
      });
}

export function getInitialConfigurationsForSetting(curatedHeroName, pageId) {
  const componentConfigurationSettings = getComponentConfigurationSettings(curatedHeroName);
  const componentConfigurations = componentConfigurationSettings.map((setting) => {
    const {
      contentTypes,
      component: fastId,
      componentId: componentIdentifier,
      contentSettings,
    } = setting;
    const interactions = [];
    const contentNodes = [];

    contentTypes.forEach((contentType) => {
      const contentTypeSettings = _get(
        contentSettings,
        _get(CONTENT_TYPE_SETTINGS_KEY, contentType),
        {},
      );
      if (contentType === CONTENT_TYPE_TEXT) {
        const { widgetType = WIDGET_TYPE_INPUT } = contentTypeSettings;
        const contentNodeContentType =
          widgetType === WIDGET_TYPE_MARKDOWN ? MARKDOWN_CONTENT_TYPE : TEXT_CONTENT_TYPE;
        contentNodes.push(createContentNodeConfiguration({ contentType: contentNodeContentType }));
      }
      if (contentType === CONTENT_TYPE_CTA) {
        const { maxCtasAllowed = 1 } = contentTypeSettings;
        Array.from({ length: maxCtasAllowed }, () =>
          objectToCamelCase(createDefaultInteraction()),
        ).forEach((interaction) => interactions.push(interaction));
      }
    });
    return createComponentConfiguration({
      page: pageId,
      componentIdentifier,
      fastId,
      interactions,
      contentNodes,
    });
  });

  return componentConfigurations;
}

export function getParentAndChildConfigurations(componentConfigurations) {
  const parentConfiguration = componentConfigurations.filter(
    ({ componentIdentifier, childConfigurations }) =>
      !_isEmpty(childConfigurations) || _isEmpty(componentIdentifier),
  );
  let childConfigurations = componentConfigurations.filter(
    ({ componentIdentifier, childConfigurations: relatedConfigurations }) =>
      _isEmpty(relatedConfigurations) && !_isEmpty(componentIdentifier),
  );
  const finalParentConfiguration = _head(parentConfiguration) || null;

  if (!_isNil(finalParentConfiguration) && _isEmpty(childConfigurations)) {
    const pageId = _get(finalParentConfiguration, 'page');
    const heroName = _get(finalParentConfiguration, 'data.name');
    childConfigurations = getInitialConfigurationsForSetting(heroName, pageId);
  }

  return [finalParentConfiguration, childConfigurations];
}
