import FormFieldSelectOptions from './components/FormFieldSelectOptions';
import { INTERNAL_FORM_FIELD_TYPES } from '../../../../../../constants/form';

export const UPLOAD_FILE_NOTICE_HEADER = 'Please Be Advised';
export const UPLOAD_FILE_NOTICE =
  'This form is not intended for collecting sensitive or medical information, including PII or HIPAA-protected data. We scan uploads for viruses but cannot guarantee total security. Ensure your use complies with privacy and security regulations. For handling sensitive information, please contact our support team.';

export const FormFieldWidgets = {
  [INTERNAL_FORM_FIELD_TYPES.SingleChoice]: [FormFieldSelectOptions],
  [INTERNAL_FORM_FIELD_TYPES.MultipleChoice]: [FormFieldSelectOptions],
};
