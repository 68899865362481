import { useState } from 'react';

function isImageData(imageData) {
  return (imageData || '').startsWith('data:');
}

export function useImageEditor(imageUrl, imageEditorRef) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialImageEditorData, setInitialImageEditorData] = useState(imageUrl);
  const [imageEditorData, setImageEditorData] = useState(imageUrl);

  function reset() {
    setImageEditorData(initialImageEditorData);
  }

  async function getImageFromImageEditor(options) {
    if (imageEditorRef.current) {
      return imageEditorRef.current.getImage(options);
    }
    return null;
  }

  async function getCurrentImage(options) {
    return isInitialized ? getImageFromImageEditor(options) : imageEditorData;
  }

  function initialize() {
    setIsInitialized(async (prevIsInitialized) => {
      if (!prevIsInitialized) {
        const latestImage = await getImageFromImageEditor();
        setImageEditorData((prevImageEditorData) => {
          if (!isImageData(prevImageEditorData)) {
            setInitialImageEditorData(latestImage);
          }
          return latestImage;
        });
      }
      return true;
    });
  }

  async function getTouched(currentImage = null) {
    const currentImageEditorData = currentImage || (await getCurrentImage());
    return isImageData(currentImageEditorData) && currentImageEditorData !== initialImageEditorData;
  }

  return {
    getCurrentImage,
    initialize,
    reset,
    getTouched,
  };
}
