import cssValidator from 'w3c-css-validator';

export async function isValidCss(updatedValue) {
  try {
    const { valid } = await cssValidator.validateText(updatedValue);
    return valid;
  } catch (e) {
    return false;
  }
}
