import _isEmpty from 'lodash/isEmpty';

import { DATE_DISPLAY_FORMAT, SIMPLE_DATE_FORMAT } from '../../../../../libs/date-utils/constants';
import DateUtils from '../../../../../libs/date-utils/date-utils';
import { destinationMap } from '../../BlogContentFormModal.constants';

export function getPublishDateDisplay(publishedAt) {
  if (_isEmpty(publishedAt)) {
    return 'Not published';
  }

  return DateUtils.format(publishedAt, DATE_DISPLAY_FORMAT);
}

export function getDestinationOptions() {
  return Object.entries(destinationMap).map(([key, value]) => {
    return {
      key,
      value: key,
      text: value,
    };
  });
}

export function getDestinationValue(destinationOptions, destination) {
  if (_isEmpty(destination)) {
    return null;
  }

  return getDestinationOptions().find(
    ({ key: currentDestination }) => currentDestination === destination,
  )?.value;
}

export function getAuthorOptions(authors) {
  return authors.map(({ user: { id, email } }) => ({
    key: id,
    value: id,
    text: email,
  }));
}

export function getAuthor(authorOptions, authorId) {
  const author = authorOptions.find(({ value }) => value === authorId);
  if (author) {
    return { email: author.text, id: authorId };
  }
  return {};
}

export function getAuthorValue(authorOptions, author) {
  if (_isEmpty(author)) {
    return null;
  }

  const { email } = author;

  return authorOptions.find(({ text: currentEmail }) => currentEmail === email)?.value;
}

export function createMinSchedulingDate() {
  let futureDate = DateUtils.getCurrentDate({ format: SIMPLE_DATE_FORMAT });
  futureDate = DateUtils.addDays(futureDate, 1);
  return futureDate;
}
