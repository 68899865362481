import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { ObjectPropType } from '../../../../../../libs/proptypes';
import RecipeProptypes from '../../../Recipe.propTypes';
import RecipeTagsInputField from '../RecipeTagsInputField';

const propTypes = {
  initialValues: RecipeProptypes.isRequired,
  fieldErrors: ObjectPropType,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  fieldErrors: {},
};

const RecipeMetaEditForm = ({ initialValues, onChange, fieldErrors }) => {
  const { name, schemaVersion, recipeVersion, tags } = initialValues;
  const {
    name: errorName,
    recipeVersion: errorsRecipeVersion,
    schemaVersion: errorSchemaeVersion,
  } = fieldErrors;
  return (
    <Form>
      <Form.Input
        label="Name"
        name="name"
        value={name}
        placeholder="Name"
        onChange={(e) => onChange('name', e.target.value)}
        error={errorName}
      />

      <Form.Input
        label="Recipe Version"
        name="recipeVersion"
        value={recipeVersion}
        placeholder="0.0.0"
        readOnly
        onChange={(e) => onChange('recipeVersion', e.target.value)}
        error={errorsRecipeVersion}
      />

      <Form.Input
        label="Schema Version"
        name="schemaVersion"
        value={schemaVersion}
        placeholder="0.0.0"
        readOnly
        onChange={(e) => onChange('schemaVersion', e.target.value)}
        error={errorSchemaeVersion}
      />

      <RecipeTagsInputField
        label="Tags"
        tags={tags}
        onChange={(updatedTags) => onChange('tags', updatedTags)}
        id="tags"
      />
    </Form>
  );
};

RecipeMetaEditForm.propTypes = propTypes;

RecipeMetaEditForm.defaultProps = defaultProps;

export default RecipeMetaEditForm;
