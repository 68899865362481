import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { reportsCustomerPropType } from './CustomerReportsTableBody.proptypes';
import Currency from '../../../../../../../../../libs/currency';

import './CustomerReportsTableBody.scss';

const propTypes = {
  customers: PropTypes.arrayOf(reportsCustomerPropType).isRequired,
};

function CustomerReportsTableBody({ customers }) {
  return (
    <Table.Body className="customer-reports-table-body">
      {customers.map(
        ({
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          total_spent: totalSpent,
          total_refunded: totalRefunded,
          tips_total: tipsTotal,
          taxes_total: taxesTotals,
          subtotals,
          fulfillment_fees_total: fulfillmentFeesTotal,
          avg_total: avgTotal,
          total_orders: totalOrders,
        }) => {
          return (
            <Table.Row key={email}>
              <Table.Cell>{firstName}</Table.Cell>
              <Table.Cell>{lastName}</Table.Cell>
              <Table.Cell>{email}</Table.Cell>
              <Table.Cell>{phone}</Table.Cell>
              <Table.Cell>{Currency.format(totalSpent)}</Table.Cell>
              <Table.Cell>{Currency.format(totalRefunded)}</Table.Cell>
              <Table.Cell>{Currency.format(tipsTotal)}</Table.Cell>
              <Table.Cell>{Currency.format(taxesTotals)}</Table.Cell>
              <Table.Cell>{Currency.format(fulfillmentFeesTotal)}</Table.Cell>
              <Table.Cell>{Currency.format(subtotals)}</Table.Cell>
              <Table.Cell>{Currency.format(avgTotal)}</Table.Cell>
              <Table.Cell>{totalOrders}</Table.Cell>
            </Table.Row>
          );
        },
      )}
    </Table.Body>
  );
}

CustomerReportsTableBody.propTypes = propTypes;

export default CustomerReportsTableBody;
