import { objectToCamelCase } from '../../../../../../libs/format';
import { ensureInteractions } from '../../../../website-component-interaction/WebsiteComponentInteractionContainer/WebsiteComponentInteractionContainer.utils';

export function setInteractionsOrder(interactions) {
  return ensureInteractions(interactions).map((interaction, i) => ({
    ...objectToCamelCase(interaction),
    order: i,
  }));
}

export function setNewInteractions(tile, newWebsiteInteractions = []) {
  const websiteInteractions = setInteractionsOrder(newWebsiteInteractions);

  return { ...tile, interactions: websiteInteractions };
}
