import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { AddFormFieldButton, SelectFormFieldType } from './components';

import './InsertFormField.scss';

const propTypes = {
  onSelectFieldType: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function InsertFormField({ onSelectFieldType }) {
  const [showSelectFormType, setShowSelectFormType] = useState(false);

  function onSelectFieldTypeInternal(fieldType, { initialData }) {
    setShowSelectFormType(false);
    onSelectFieldType(fieldType, { initialData });
  }

  if (showSelectFormType) {
    return (
      <SelectFormFieldType
        onSelectFieldType={onSelectFieldTypeInternal}
        onCancelSelect={() => setShowSelectFormType(false)}
      />
    );
  }

  return <AddFormFieldButton onClick={() => setShowSelectFormType(true)} />;
}

InsertFormField.propTypes = propTypes;
InsertFormField.defaultProps = defaultProps;
