import React from 'react';

import PropTypes from 'prop-types';

import EditorHeader from './components/EditorHeader';

import './editor.scss';

const Editor = ({ children }) => {
  return <div className="recipe-editor">{children}</div>;
};

Editor.propTypes = {
  children: PropTypes.node.isRequired,
};

Editor.Header = EditorHeader;
Editor.View = ({ children }) => <div className="editor">{children}</div>;
Editor.Actions = ({ children }) => <div className="editor-action">{children}</div>;

export default Editor;
