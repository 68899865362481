import StateUtils from '../libs/state';

export default (state = {}, action) => {
  const { type, payload } = action;
  const { features } = state;

  switch (type) {
    case 'CREATE_NOTIFICATIONS':
      return {
        ...state,
        features: [],
      };

    case 'CREATE_FEATURE_NOTIFICATION':
      if (features) {
        return {
          ...state,
          features: StateUtils.addOrRemoveFromList(payload, features),
        };
      }

      return { ...state, features: [payload] };

    case 'DELETE_FEATURE_NOTIFICATION':
      if (!payload) return state;

      return {
        ...state,
        features: StateUtils.removeFromList(payload, features),
      };

    default:
      return state;
  }
};
