import _get from 'lodash/get';
import _isObject from 'lodash/isObject';

import { FONT_AWESOME_DEFAULT_PREFIX } from './Icon.constants';

export const iconLibrary = {
  FontAwesome: 'FontAwesome',
  SemanticUI: 'SemanticUI',
};

export const iconNameLibraryMap = {
  tiktok: iconLibrary.FontAwesome,
  plus: iconLibrary.FontAwesome,
  'arrow-left': iconLibrary.FontAwesome,
};

export const iconClassMap = {
  tiktok: ['fab', 'tiktok'],
};

export function getLibraryFromIconName(iconName = '') {
  if (_isObject(iconName)) {
    return iconLibrary.FontAwesome;
  }
  return _get(iconNameLibraryMap, iconName.toLowerCase(), iconLibrary.SemanticUI);
}

export function getIconClassFromIconName(iconName = '') {
  return _get(iconClassMap, iconName.toLowerCase(), [
    FONT_AWESOME_DEFAULT_PREFIX,
    iconName.toLowerCase(),
  ]);
}

export function getContainerClassNames(className) {
  return ['custom-component-fa-icon', className || ''].filter(Boolean).join(' ');
}
