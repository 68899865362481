import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Menu, Icon, Loader, Popup } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { ActionsWidgetSectionsType } from '../../types/actions';

import './ActionsWidget.scss';

const propTypes = {
  actionSections: ActionsWidgetSectionsType.isRequired,
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

const defaultProps = {};

export default function ActionsWidget({ actionSections, trigger }) {
  const [actionsOpen, setActionsOpen] = useState(false);
  const [loadingActionTitle, setLoadingActionTitle] = useState('');

  function toggleActionsOpen(nextValue) {
    if (loadingActionTitle) return;

    setActionsOpen(nextValue);
  }

  function onActionClick(e, target, actionTitle, actionHandler) {
    return async () => {
      try {
        setLoadingActionTitle(actionTitle);
        await actionHandler(e, target);
        setActionsOpen(false);
      } finally {
        setLoadingActionTitle('');
      }
    };
  }

  const displayActions = actionsOpen || loadingActionTitle;

  if (_isEmpty(actionSections)) return null;

  return (
    <>
      <Popup
        trigger={trigger()}
        className="actions-widget-popup"
        flowing
        hoverable
        basic
        position="bottom left"
        open={displayActions}
        onOpen={() => toggleActionsOpen(true)}
        onClose={() => toggleActionsOpen(false)}
      >
        <Menu vertical className="content-node-actions-menu">
          {(actionSections || []).map(({ title, actions = [] }) => (
            <Menu.Item key={title}>
              <Menu.Header>{title}</Menu.Header>
              <Menu.Menu>
                {actions.map(({ title: actionTitle, icon, onClick }) => {
                  return (
                    <Menu.Item
                      key={actionTitle}
                      onClick={async (e, target) => {
                        await onActionClick(e, target, actionTitle, onClick)();
                      }}
                      name={actionTitle}
                      className="content-node-action"
                      disabled={!!loadingActionTitle}
                    >
                      <Icon name={icon} />
                      {actionTitle}
                      {loadingActionTitle === actionTitle && <Loader size="mini" />}
                    </Menu.Item>
                  );
                })}
              </Menu.Menu>
            </Menu.Item>
          ))}
        </Menu>
      </Popup>
    </>
  );
}

ActionsWidget.propTypes = propTypes;
ActionsWidget.defaultProps = defaultProps;
