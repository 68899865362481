import _isNil from 'lodash/isNil';

import { generateLocalId } from '../../../../libs/slices';
import { TEXT_CONTENT_TYPE } from '../constants/content';

export function createContentComponentConfiguration({
  contentNodeConfigurationId,
  contentNodeId,
  contentName,
  content = '',
  order = 1,
  contentType = TEXT_CONTENT_TYPE,
} = {}) {
  return {
    contentNodeConfigurationId,
    contentNodeId,
    contentName,
    contentType,
    content,
    order,
  };
}

export function createContentNodeConfiguration({
  contentNodeConfigurationId = null,
  id = null, // content node id
  content = '',
  contentType = TEXT_CONTENT_TYPE,
  contentName = '',
  maxLength = null,
  order = 0,
  metadata = {},
} = {}) {
  const contentNode = {
    contentNodeConfigurationId,
    content,
    contentType,
    contentName,
    maxLength,
    order,
    metadata,
  };

  if (_isNil(id)) {
    contentNode.localId = generateLocalId();
  } else {
    contentNode.id = id;
  }

  return contentNode;
}
