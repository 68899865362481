import ErrorHandler from './errors';

const Utils = {
  fileNameFromURL(index, url, defaultName = 'File') {
    const matches = url.match(/\/([^/?#]+)[^/]*$/);
    if (matches.length) {
      return matches[1];
    }
    return `${defaultName} ${index + 1}`;
  },

  getDisplayName(Component) {
    return Component.displayName || Component.name || 'Component';
  },

  /**
   * url: full url text (with protocol and host) or absolute URL (starting with /)
   */
  addQueryParam(url, paramName, paramValue) {
    let urlToParse = url;
    let parsedUrl = null;
    let sliceIndex = 0;
    if (url === '') {
      return url;
    }
    if (url.startsWith('/')) {
      // if it is an absolute path, add protocol and host
      const protoAndHost = `${window.location.protocol}//${window.location.host}`;
      sliceIndex = protoAndHost.length;
      urlToParse = `${protoAndHost}${url}`;
    }
    try {
      parsedUrl = new URL(urlToParse);
    } catch (e) {
      if (e instanceof TypeError) {
        ErrorHandler.capture(e);
        return url;
      }
      throw e;
    }
    parsedUrl.searchParams.set(paramName, paramValue);
    return parsedUrl.href.slice(sliceIndex);
  },

  urlIsBlob(url) {
    return url && (url.startsWith('blob') || url.startsWith('data'));
  },

  addImageQueryParam(url, paramName, paramValue, avoidBlob = true) {
    if (avoidBlob && this.urlIsBlob(url)) {
      return url;
    }
    return this.addQueryParam(url, paramName, paramValue);
  },

  moveListItem(list, oldIndex, newIndex, copy = false) {
    const newList = copy ? [...list] : list;
    const itemToMove = newList.splice(oldIndex, 1)[0];
    newList.splice(newIndex, 0, itemToMove);
    return newList;
  },

  itemsToUpdateOrder(items) {
    /**
     * This method filters items with their order != from their index
     * and set their order to match the index.
     */
    return items.reduce((filtered, currentItem, idx) => {
      if (currentItem.order !== idx) {
        filtered.push({ ...currentItem, order: idx });
      }
      return filtered;
    }, []);
  },

  sortByOrder(obj1, obj2) {
    return (obj1.order || 0) - (obj2.order || 0);
  },

  objectToQueryParams(obj) {
    const filteredEntries = Object.entries(obj).filter(([, value]) => !!value);

    return new URLSearchParams(filteredEntries).toString();
  },
};

export default Utils;
