import React, { Component } from 'react';

import { Button, Modal, Select } from 'semantic-ui-react';

import AddJson from './AddJson';
import {
  getPartial,
  getPartialTypes,
  getPartialOptions,
  getTemplate,
  getTemplateTypes,
  getTemplateOptions,
} from './ModalNew.utils';
import CloseableModal from '../../../../common/CloseableModal';
import { RecipeContextConsumer } from '../context/RecipeContext';

class ModalNew extends Component {
  static getOptions() {
    return [
      {
        key: 'partial',
        value: 'partial',
        text: 'Partial',
      },
      {
        key: 'templateobject',
        value: 'templateobject',
        text: 'Template Object',
      },
    ];
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedType: null,
      selectedPartialName: null,
      value: null,
    };

    this.save = this.save.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onSelectedType = this.onSelectedType.bind(this);
    this.addPartial = this.addPartial.bind(this);
    this.addTemplate = this.addTemplate.bind(this);
  }

  onSelectedType(event, data) {
    this.setState({ selectedType: data.value });
  }

  onValueChange(value) {
    this.setState({
      value,
    });
  }

  getTemplateDropdown() {
    const { ky } = this.props;
    const templateTypes = getTemplateTypes(ky);
    const templateOptions = getTemplateOptions(templateTypes);

    if (templateOptions.length === 0) {
      return <p style={{ marginTop: '40px' }}>No valid templates!</p>;
    }

    return (
      <Select
        options={templateOptions.map(({ name, path, value }) => ({
          key: value,
          value: path,
          text: name,
        }))}
        placeholder="Select a template"
        fluid
        style={{ marginTop: '20px' }}
        onChange={(e, data) => {
          this.addTemplate(data.value);
        }}
      />
    );
  }

  getPartialDropdown() {
    const { ky } = this.props;
    const partialTypes = getPartialTypes(ky);
    const partialOptions = getPartialOptions(partialTypes);

    if (partialOptions.length === 0) {
      return <p style={{ marginTop: '40px' }}>No valid partials!</p>;
    }

    return (
      <Select
        options={partialOptions.map(({ name, path, value }) => ({
          key: value,
          value: path,
          text: name,
        }))}
        placeholder="Select a partial"
        fluid
        style={{ marginTop: '20px' }}
        onChange={(e, data) => {
          this.addPartial(data.value);
        }}
      />
    );
  }

  getSelectedTypeOptions() {
    const { value, selectedType } = this.state;

    if (selectedType === 'partial') {
      return this.getPartialDropdown();
    }
    if (selectedType === 'templateobject') {
      return this.getTemplateDropdown();
    }
    if (selectedType === 'rawjsoninput') {
      return <AddJson value={value} />;
    }
    return undefined;
  }

  addPartial(partialName) {
    this.setState({ selectedPartialName: partialName });
  }

  addTemplate(templateName) {
    this.setState({ selectedTemplateName: templateName });
  }

  save(insertMethod) {
    const { selectedType, selectedPartialName, selectedTemplateName } = this.state;
    const { ky, parentId, close } = this.props;

    if (selectedType === 'partial') {
      const partial = getPartial(selectedPartialName);
      insertMethod(ky, partial, parentId);
    } else if (selectedType === 'templateobject') {
      const template = getTemplate(selectedTemplateName);
      insertMethod(ky, template, parentId);
    }

    close();
  }

  render() {
    const { open, close, type } = this.props;
    const selectedTypeOptions = this.getSelectedTypeOptions();

    return (
      <CloseableModal
        dimmer="inverted"
        open={open}
        closeOnDimmerClick
        className="developer-modal"
        onClose={close}
        header="New..."
      >
        <Modal.Content>
          <Select
            placeholder="Select a type"
            options={ModalNew.getOptions()}
            onChange={(e, d) => this.onSelectedType(e, d)}
          />
          {selectedTypeOptions}
        </Modal.Content>
        <Modal.Actions>
          <RecipeContextConsumer>
            {(value) => {
              const { insertKeyValueIntoObject, insertValueIntoArray } = value;
              let insertMethod = insertKeyValueIntoObject;
              if (type === 'array') {
                insertMethod = insertValueIntoArray;
              }
              return (
                <Button className="action" content="Save" onClick={() => this.save(insertMethod)} />
              );
            }}
          </RecipeContextConsumer>
        </Modal.Actions>
      </CloseableModal>
    );
  }
}

export default ModalNew;
