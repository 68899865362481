/* eslint-disable no-case-declarations */
import _get from 'lodash/get';
import _pull from 'lodash/pull';
import { v4 as uuid } from 'uuid';

import operatinghours from './operatinghours';
import StateUtils from '../libs/state';
import MenuStateUtils from '../libs/state/menu';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'CREATE_BUSINESS':
      return {
        ...state,
        core: StateUtils.createDefaultObject(StateUtils.types.OBJECT, {
          ...payload,
        }),
        socialMedia: StateUtils.createDefaultObject(StateUtils.types.LIST),
        contactForm: StateUtils.createDefaultObject(
          StateUtils.types.OBJECT,
          StateUtils.createDefaultType('CONTACT_FORM'),
        ),
        team: StateUtils.createDefaultObject(StateUtils.types.LIST),
        locations: StateUtils.createDefaultObject(StateUtils.types.LIST),
        taxes: StateUtils.createDefaultObject(StateUtils.types.LIST),
        provider_services: StateUtils.createDefaultObject(StateUtils.types.LIST),
        provider_service_settings: StateUtils.createDefaultObject(StateUtils.types.LIST),
        events: StateUtils.createDefaultObject(StateUtils.types.LIST),
        galleryImages: StateUtils.createDefaultObject(StateUtils.types.LIST),
        businessFiles: StateUtils.createDefaultObject(StateUtils.types.LIST),
        timezones: StateUtils.createDefaultObject(StateUtils.types.LIST),
        specials: StateUtils.createDefaultObject(StateUtils.types.LIST),
        reviews: StateUtils.createDefaultObject(StateUtils.types.LIST),
        menu: StateUtils.createDefaultObject(
          StateUtils.types.OBJECT,
          StateUtils.createDefaultType('MENU'),
        ),
        subscriptionItems: StateUtils.createDefaultObject(StateUtils.types.LIST),
        remoteItemUpdated: false,
      };

    case 'RESET_BUSINESS':
      return {
        core: StateUtils.createDefaultObject(StateUtils.types.OBJECT, {}),
        socialMedia: StateUtils.createDefaultObject(StateUtils.types.LIST),
        contactForm: StateUtils.createDefaultObject(
          StateUtils.types.OBJECT,
          StateUtils.createDefaultType('CONTACT_FORM'),
        ),
        team: StateUtils.createDefaultObject(StateUtils.types.LIST),
        locations: StateUtils.createDefaultObject(StateUtils.types.LIST),
        taxes: StateUtils.createDefaultObject(StateUtils.types.LIST),
        provider_services: StateUtils.createDefaultObject(StateUtils.types.LIST),
        provider_service_settings: StateUtils.createDefaultObject(StateUtils.types.LIST),
        events: StateUtils.createDefaultObject(StateUtils.types.LIST),
        galleryImages: StateUtils.createDefaultObject(StateUtils.types.LIST),
        businessFiles: StateUtils.createDefaultObject(StateUtils.types.LIST),
        timezones: StateUtils.createDefaultObject(StateUtils.types.LIST),
        specials: StateUtils.createDefaultObject(StateUtils.types.LIST),
        reviews: StateUtils.createDefaultObject(StateUtils.types.LIST),
        menu: StateUtils.createDefaultObject(
          StateUtils.types.OBJECT,
          StateUtils.createDefaultType('MENU'),
        ),
        subscriptionItems: StateUtils.createDefaultObject(StateUtils.types.LIST),
      };

    case 'CREATE_BUSINESS_CORE':
      return StateUtils.flagAsLoaded(
        ['core'],
        StateUtils.updateObject(
          state,
          ['core', 'value'],
          payload.logo
            ? { ...state.core.value, ...payload }
            : {
                ...state.core.value,
                ...payload,
                logo: StateUtils.createDefaultType('IMAGE'),
              },
        ),
      );

    case 'FLAG_BUSINESS_SAVED':
      return StateUtils.updateObject(state, ['core', 'value', '__saved'], payload, 0);

    case 'UPDATE_BUSINESS_NAME':
      return StateUtils.updateObject(state, ['core', 'value', 'name'], payload.value, 1);

    case 'UPDATE_BUSINESS_LEGAL_NAME':
      return StateUtils.updateObject(state, ['core', 'value', 'legal_name'], payload.value, 1);

    case 'UPDATE_BUSINESS_TYPE':
      return StateUtils.updateObject(state, ['core', 'value', 'type'], payload.value, 1);

    case 'UPDATE_BUSINESS_SUBTYPE':
      return StateUtils.updateObject(
        state,
        ['core', 'value', 'subtypes'],
        StateUtils.replaceListItem(state.core.value.subtypes, payload.index, payload.value),
        1,
      );

    case 'ADD_BUSINESS_SUBTYPE':
      return StateUtils.addItem(state, ['core', 'value', 'subtypes'], payload.value);

    case 'DELETE_BUSINESS_SUBTYPE':
      return StateUtils.updateObject(
        state,
        ['core', 'value', 'subtypes'],
        StateUtils.removeFromList(payload, state.core.value.subtypes),
      );

    case 'UPDATE_BUSINESS_PERSONALITIES':
      return StateUtils.updateObject(
        state,
        ['core', 'value', 'personality_traits'],
        StateUtils.addOrRemoveFromList(payload, state.core.value.personality_traits),
        1,
      );

    case 'INITIALIZE_BUSINESS_SOCIAL_MEDIA':
      return StateUtils.flagAsLoaded(
        ['socialMedia'],
        StateUtils.updateObject(state, ['socialMedia', 'value'], StateUtils.createList(payload)),
      );

    case 'UPDATE_BUSINESS_SOCIAL_MEDIA':
      return StateUtils.updateListItem(state, ['socialMedia', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'ADD_BUSINESS_SOCIAL_MEDIA':
      return StateUtils.addItem(
        state,
        ['socialMedia', 'value'],
        StateUtils.createDefaultType('SOCIAL_MEDIA'),
      );

    case 'DELETE_BUSINESS_SOCIAL_MEDIA':
      return StateUtils.deleteItem(state, ['socialMedia', 'value'], payload);

    case 'UPDATE_BUSINESS_ABOUT':
      return StateUtils.updateObject(state, ['core', 'value', 'about_markdown'], payload.value, 1);

    case 'INITIALIZE_BUSINESS_TEAM':
      return StateUtils.initializeList(state, payload, 'team', 'TEAM_MEMBER', true);

    case 'UPDATE_BUSINESS_TEAM_MEMBER':
      return StateUtils.updateListItem(state, ['team', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'ADD_BUSINESS_TEAM_MEMBER':
      return StateUtils.addItem(
        state,
        ['team', 'value'],
        StateUtils.createDefaultType('TEAM_MEMBER'),
      );

    case 'DELETE_BUSINESS_TEAM_MEMBER':
      return StateUtils.deleteItem(state, ['team', 'value'], payload);

    case 'UPDATE_BUSINESS_LOGO':
      return StateUtils.updateObject(
        state,
        ['core', 'value', payload.field],
        payload.fieldValue,
        1,
      );

    case 'UPDATE_PREP_TIME':
      return StateUtils.updateObject(
        state,
        ['core', 'value', 'default_prep_time'],
        payload.value,
        1,
      );

    case 'UPDATE_ONLINE_ORDER':
      return StateUtils.updateObject(
        state,
        ['core', 'value', 'online_order_link'],
        payload.value,
        1,
      );

    case 'UPDATE_RESERVATIONS':
      return StateUtils.updateObject(
        state,
        ['core', 'value', 'reservation_link'],
        payload.value,
        1,
      );

    case 'UPDATE_CATERING':
      return StateUtils.updateObject(state, ['core', 'value', 'catering_link'], payload.value, 1);

    case 'INITIALIZE_EVENTS':
      return StateUtils.initializeList(state, payload, 'events', 'EVENT');

    case 'UPDATE_EVENT':
      return StateUtils.updateListItem(state, ['events', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'ADD_EVENT':
      return StateUtils.addItem(state, ['events', 'value'], StateUtils.createDefaultType('EVENT'));

    case 'DELETE_EVENT':
      return StateUtils.deleteItem(state, ['events', 'value'], payload);

    case 'INITIALIZE_GALLERY_IMAGES':
      return StateUtils.initializeList(state, payload, 'galleryImages', 'IMAGE', true);

    case 'UPDATE_GALLERY_IMAGE':
      return StateUtils.updateListItem(state, ['galleryImages', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'ADD_GALLERY_IMAGE':
      const newImage = {
        ...StateUtils.createDefaultType('IMAGE'),
        ...payload,
      };

      return StateUtils.addItem(state, ['galleryImages', 'value'], newImage, false);

    case 'DELETE_GALLERY_IMAGE':
      return StateUtils.deleteItem(state, ['galleryImages', 'value'], payload);

    case 'INITIALIZE_BUSINESS_FILES':
      return StateUtils.initializeList(state, payload, 'businessFiles', 'FILE', true);

    case 'UPDATE_BUSINESS_FILE':
      if (payload.fields) {
        return StateUtils.updateListItem(
          state,
          ['businessFiles', 'value'],
          payload.index,
          payload.fields,
          true,
        );
      }

      return StateUtils.updateListItem(
        state,
        ['businessFiles', 'value'],
        payload.index,
        { [payload.field]: payload.fieldValue },
        true,
      );

    case 'ADD_BUSINESS_FILE':
      const newBusinessFile = {
        ...StateUtils.createDefaultType('FILE'),
        ...payload,
      };

      return StateUtils.addItem(state, ['businessFiles', 'value'], newBusinessFile, true);

    case 'DELETE_BUSINESS_FILE':
      return StateUtils.deleteItem(state, ['businessFiles', 'value'], payload, true);

    case 'INITIALIZE_TIMEZONES':
      return StateUtils.formatTimezones(state, payload);

    case 'INITIALIZE_LOCATIONS':
      return StateUtils.flagAsLoaded(
        ['locations'],
        StateUtils.updateObject(state, ['locations', 'value'], StateUtils.createList(payload)),
      );

    case 'ADD_LOCATION':
      const addLocation = StateUtils.createDefaultType('LOCATION');
      const newLocationHours = [1, 2, 3, 4, 5, 6, 7].map((day) => {
        return StateUtils.createDefaultType('HOURS_OF_OPERATION', { day, localId: uuid() });
      });

      return StateUtils.addItem(state, ['locations', 'value'], {
        ...addLocation,
        hours: newLocationHours,
      });

    case 'DELETE_LOCATION':
      return StateUtils.deleteItem(state, ['locations', 'value'], payload, true);

    case 'UPDATE_LOCATION':
      if (payload.fields) {
        return StateUtils.updateListItem(
          state,
          ['locations', 'value'],
          payload.index,
          payload.fields,
          payload.bypassTouch || false,
        );
      }

      return StateUtils.updateListItem(
        state,
        ['locations', 'value'],
        payload.index,
        {
          [payload.field]: payload.fieldValue,
        },
        payload.bypassTouch || false,
      );

    case 'INITIALIZE_PROVIDER_SERVICES':
      return StateUtils.initializeList(
        state,
        payload,
        'provider_services',
        'PROVIDER_SERVICE',
        true,
      );

    case 'INITIALIZE_PROVIDER_SERVICE_SETTINGS':
      return StateUtils.initializeList(
        state,
        payload,
        'provider_service_settings',
        'PROVIDER_SERVICE_SETTING',
        true,
      );

    case 'INITIALIZE_SUBSCRIPTION_ITEMS':
      return StateUtils.initializeList(
        state,
        payload,
        'subscriptionItems',
        'SUBSCRIPTION_ITEM',
        true,
      );

    case 'INITIALIZE_LOCATION_TAXES':
      return StateUtils.initializeList(state, payload, 'taxes', 'TAX', true);

    case 'UPDATE_LOCATION_TAX':
      const { taxIndex } = payload;
      return StateUtils.updateListItem(
        state,
        ['taxes', 'value'],
        taxIndex,
        {
          [payload.field]: payload.fieldValue,
        },
        payload.bypassTouch || false,
      );

    case 'ADD_LOCATION_TAX':
      const {
        location: { id: location },
      } = payload;

      const defaultTax = {
        ...StateUtils.createDefaultType('TAX'),
        tax_label: `Tax ${state.taxes.value.length + 1}`,
      };

      return StateUtils.addItem(state, ['taxes', 'value'], {
        ...defaultTax,
        location,
      });

    case 'DELETE_LOCATION_TAX':
      const removedTax = state.taxes.value[payload];
      const withDeletedTax = StateUtils.deleteItem(state, ['taxes', 'value'], payload);

      return StateUtils.updateObject(
        withDeletedTax,
        ['menu', 'value', 'items'],
        state.menu.value.items.map(({ taxes, ...rest }) => ({
          ...rest,
          taxes: taxes.filter(({ id }) => id !== removedTax.id),
        })),
        0,
      );

    case 'INITIALIZE_SPECIALS':
      return StateUtils.initializeList(state, payload, 'specials', 'SPECIAL', true);

    case 'UPDATE_SPECIAL':
      return StateUtils.updateListItem(state, ['specials', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'ADD_SPECIAL':
      return StateUtils.addItem(
        state,
        ['specials', 'value'],
        StateUtils.createDefaultType('SPECIAL'),
      );

    case 'DELETE_SPECIAL':
      return StateUtils.deleteItem(state, ['specials', 'value'], payload);

    case 'INITIALIZE_REVIEWS':
      return StateUtils.initializeList(state, payload, 'reviews', 'REVIEW', true);

    case 'ADD_BUSINESS_REVIEW':
      return StateUtils.addItem(
        state,
        ['reviews', 'value'],
        StateUtils.createDefaultType('REVIEW', { localId: uuid() }),
      );

    case 'UPDATE_REVIEW':
      return StateUtils.updateListItem(state, ['reviews', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'DELETE_REVIEW':
      return StateUtils.deleteItem(state, ['reviews', 'value'], payload);

    case 'INITIALIZE_MENU':
      return StateUtils.formatMenu(state, payload);

    case 'FLAG_MENU_SAVED':
      return StateUtils.updateObject(state, ['menu', 'value', '__saved'], payload, 0);

    case 'UPDATE_ONBOARDING_STATE':
      return StateUtils.updateObject(state, ['core', 'value', 'onboardingState'], payload);

    case 'ADD_MENU_SCHEDULE':
      return StateUtils.addItem(
        state,
        ['menu', 'value', 'schedules'],
        {
          ...StateUtils.createDefaultType('MENU_SCHEDULE'),
          order: payload.order,
        },
        true,
      );

    case 'UPDATE_MENU_SCHEDULE':
      return StateUtils.updateListItem(
        state,
        ['menu', 'value', 'schedules'],
        payload.index,
        payload.values,
      );

    case 'DELETE_MENU_SCHEDULE':
      return StateUtils.deleteItem(state, ['menu', 'value', 'schedules'], payload, true);

    case 'ADD_MENU_CATEGORY':
      const localCategoryId = uuid();
      const withMenuCategory = StateUtils.insertItem(
        state,
        ['menu', 'value', 'categories'],
        {
          ...StateUtils.createDefaultType('MENU_CATEGORY'),
          localId: localCategoryId,
        },
        payload.categoryIndex,
        true,
      );

      const scheduleCategories = _get(
        withMenuCategory,
        `menu.value.schedules[${payload.scheduleIndex}].categories`,
        [],
      );

      return StateUtils.updateListItem(
        withMenuCategory,
        ['menu', 'value', 'schedules'],
        payload.scheduleIndex,
        {
          categories: [
            ...scheduleCategories.slice(0, payload.categoryIndex),
            localCategoryId,
            ...scheduleCategories.slice(payload.categoryIndex, scheduleCategories.length),
          ],
        },
        true,
      );

    case 'UPDATE_MENU_CATEGORY':
      const withUpdatedMenuCategory = StateUtils.updateListItem(
        state,
        ['menu', 'value', 'categories'],
        payload.index,
        payload.values,
      );

      if (payload.categories) {
        return StateUtils.updateListItem(
          withUpdatedMenuCategory,
          ['menu', 'value', 'schedules'],
          payload.scheduleIndex,
          { categories: payload.categories },
          true,
        );
      }

      return withUpdatedMenuCategory;

    case 'DELETE_MENU_CATEGORY':
      const withDeletedCategory = StateUtils.deleteItem(
        state,
        ['menu', 'value', 'categories'],
        payload.index,
        true,
      );

      const currentCategories = _get(
        withDeletedCategory,
        `menu.value.schedules[${payload.scheduleIndex}].categories`,
      );
      return StateUtils.updateListItem(
        withDeletedCategory,
        ['menu', 'value', 'schedules'],
        payload.scheduleIndex,
        {
          categories: currentCategories.filter((id) => id !== payload.categoryId),
        },
        true,
      );

    case 'ADD_MENU_ITEM':
      const localItemId = uuid();

      const defaultTaxes = state.taxes.value.filter(({ id }) => id).map(({ id }) => id);

      const withMenuItem = StateUtils.addItem(
        state,
        ['menu', 'value', 'items'],
        {
          ...StateUtils.createDefaultType('MENU_ITEM', { taxes: defaultTaxes }),
          localId: localItemId,
        },
        true,
      );

      return StateUtils.updateListItem(
        withMenuItem,
        ['menu', 'value', 'categories'],
        payload.categoryIndex,
        {
          items: [
            ...StateUtils.getObject(withMenuItem, [
              'menu',
              'value',
              'categories',
              payload.categoryIndex,
              'items',
            ]),
            localItemId,
          ],
        },
        true,
      );

    case 'UPDATE_MENU_ITEM':
      const withUpdatedMenuItem = StateUtils.updateListItem(
        state,
        ['menu', 'value', 'items'],
        payload.index,
        payload.values,
      );

      if (payload.items) {
        return StateUtils.updateListItem(
          withUpdatedMenuItem,
          ['menu', 'value', 'categories'],
          payload.categoryIndex,
          { items: payload.items },
          true,
        );
      }

      return withUpdatedMenuItem;

    case 'DELETE_MENU_ITEM':
      const withDeletedItem = StateUtils.deleteItem(
        state,
        ['menu', 'value', 'items'],
        payload.index,
        true,
      );

      return StateUtils.updateListItem(
        withDeletedItem,
        ['menu', 'value', 'categories'],
        payload.categoryIndex,
        {
          items: StateUtils.getObject(withDeletedItem, [
            'menu',
            'value',
            'categories',
            payload.categoryIndex,
            'items',
          ]).filter((itemId) => itemId !== payload.itemId),
        },
        true,
      );

    case 'ADD_MENU_MODIFIER_SET':
      const localModifierSetId = uuid();
      const withModifierSet = StateUtils.addItem(
        state,
        ['menu', 'value', 'modifier_sets'],
        {
          ...StateUtils.createDefaultType('MENU_ITEM_MODIFIER_SET'),
          localId: localModifierSetId,
        },
        true,
      );

      return StateUtils.updateListItem(
        withModifierSet,
        ['menu', 'value', 'items'],
        payload.itemIndex,
        {
          modifier_sets: [
            ...StateUtils.getObject(withModifierSet, [
              'menu',
              'value',
              'items',
              payload.itemIndex,
              'modifier_sets',
            ]),
            localModifierSetId,
          ],
        },
        true,
      );

    case 'LINK_ITEM_MODIFIER_SET':
      return StateUtils.updateListItem(
        state,
        ['menu', 'value', 'items'],
        payload.itemIndex,
        { modifier_sets: payload.itemModifierSets },
        true,
      );

    case 'REMOVE_ITEM_MODIFIER_SET':
      return StateUtils.updateListItem(
        state,
        ['menu', 'value', 'items'],
        payload.itemIndex,
        { modifier_sets: payload.itemModifierSets },
        true,
      );

    case 'LINK_ITEM_TAX':
      return StateUtils.updateListItem(
        state,
        ['menu', 'value', 'items'],
        payload.itemIndex,
        { taxes: payload.itemTaxes },
        true,
      );

    case 'UPDATE_MENU_MODIFIER_SET':
      const withUpdatedMenuModifierSet = StateUtils.updateListItem(
        state,
        ['menu', 'value', 'modifier_sets'],
        payload.index,
        payload.values,
      );

      if (payload.modifierSets) {
        return StateUtils.updateListItem(
          withUpdatedMenuModifierSet,
          ['menu', 'value', 'items'],
          payload.itemIndex,
          {
            modifier_sets: payload.modifierSets,
          },
          true,
        );
      }

      return withUpdatedMenuModifierSet;

    case 'DELETE_MENU_MODIFIER_SET':
      const withDeletedModifierSet = StateUtils.deleteItem(
        state,
        ['menu', 'value', 'modifier_sets'],
        payload.index,
        true,
      );

      return StateUtils.updateListItem(
        withDeletedModifierSet,
        ['menu', 'value', 'items'],
        payload.itemIndex,
        {
          modifier_sets: StateUtils.getObject(withDeletedModifierSet, [
            'menu',
            'value',
            'items',
            payload.itemIndex,
            'modifier_sets',
          ]).filter((modifierSet) => modifierSet !== payload.modifierSetId),
        },
        true,
      );

    case 'ADD_MENU_MODIFIER':
      const localModifierId = uuid();
      const withModifier = StateUtils.addItem(
        state,
        ['menu', 'value', 'modifiers'],
        {
          ...StateUtils.createDefaultType('MENU_ITEM_MODIFIER'),
          localId: localModifierId,
        },
        true,
      );

      return StateUtils.updateListItem(
        withModifier,
        ['menu', 'value', 'modifier_sets'],
        payload.modifierSetIndex,
        {
          modifiers: [
            ...StateUtils.getObject(withModifier, [
              'menu',
              'value',
              'modifier_sets',
              payload.modifierSetIndex,
              'modifiers',
            ]),
            localModifierId,
          ],
        },
        true,
      );

    case 'UPDATE_MENU_MODIFIER':
      return StateUtils.updateListItem(
        state,
        ['menu', 'value', 'modifiers'],
        payload.index,
        payload.clearDirty
          ? { ...payload.values, dirty: false }
          : { ...payload.values, dirty: true },
      );

    case 'DELETE_MENU_MODIFIER':
      return MenuStateUtils.deleteMenuEntity(state, 'modifiers', 'modifier_sets', payload);

    case 'CHOOSE_MENU_SOURCE':
      if (payload.bypassTouch) {
        return StateUtils.updateObject(
          state,
          ['core', 'value', 'menu_source_type'],
          payload.value,
          0,
        );
      }

      return StateUtils.updateObject(
        state,
        ['core', 'value', 'menu_source_type'],
        payload.value,
        1,
      );

    case 'UPDATE_MENU_LINK':
      return StateUtils.updateObject(state, ['core', 'value', 'menu_link'], payload.value, 1);

    case 'ADD_MENU_FILE':
      return StateUtils.addItem(state, ['galleryImages', 'value'], {
        ...StateUtils.createDefaultType('IMAGE'),
        tags: ['menu'],
      });

    case 'UPDATE_MENU_FILE':
      return StateUtils.updateListItem(state, ['galleryImages', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'DELETE_MENU_FILE':
      return StateUtils.deleteItem(state, ['galleryImages', 'value'], payload);

    case 'CLEAR_BUSINESS_TOUCHED_FIELD':
      if (payload.stateType === 'LIST') {
        let fieldsToUpdate = { _touchedFields: [] };

        if (payload.stateKeyPath[0] === 'locations') {
          // Save into state the response from backend
          fieldsToUpdate = {
            ...fieldsToUpdate,
            ...payload.data,
          };
        }
        return StateUtils.updateListItem(
          state,
          [payload.stateKeyPath[0], 'value'],
          payload.stateKeyPath[1],
          fieldsToUpdate,
          true,
        );
      }
      return {
        ...state,
        [payload.stateKeyPath[0]]: {
          ...state[payload.stateKeyPath[0]],
          _touchedFields: [],
        },
      };

    case 'REMOVE_BUSINESS_ADDED_ITEM':
      let addItemState = state;

      if (payload.stateKeyPath[0] === 'menu_categories') {
        // 1. Remove _newItemIds
        const category = state.menu_categories.value[payload.stateKeyPath[1]];
        const categoryInternalId = category.internal_id;
        const remoteId = payload.data.id;
        const updatedMenuItems = state.menu_items.value.map(
          ({ category_id: categoryId, ...rest }) => {
            if (categoryId === categoryInternalId) return { ...rest, category_id: remoteId };
            return { category_id: categoryId, ...rest };
          },
        );
        addItemState = StateUtils.updateObject(
          addItemState,
          ['menu_items', 'value'],
          updatedMenuItems,
        );
      }

      const { _newItemIds } = state[payload.stateKeyPath[0]];
      const withRemovedIds = {
        ...addItemState,
        [payload.stateKeyPath[0]]: {
          ...addItemState[payload.stateKeyPath[0]],
          _newItemIds: _pull(_newItemIds, payload.stateKeyPath[1]),
        },
      };

      return StateUtils.updateListItem(
        withRemovedIds,
        [payload.stateKeyPath[0], 'value'],
        payload.stateKeyPath[1],
        { ...payload.data, _touchedFields: [] },
        true,
      );

    case 'REMOVE_BUSINESS_DELETED_ITEM':
      const { _deletedItemIds } = state[payload.stateKeyPath[0]];
      return {
        ...state,
        [payload.stateKeyPath[0]]: {
          ...state[payload.stateKeyPath[0]],
          _deletedItemIds: _pull(_deletedItemIds, payload.stateKeyPath[1]),
        },
      };

    case 'DELETE_PROVIDER_SERVICE_SETTING':
      if (payload.fields) {
        return StateUtils.deleteItem(
          state,
          ['provider_service_settings', 'value'],
          _get(payload, 'fields.index'),
          _get(payload, 'fields.ignoreTouch', false),
        );
      }

      return StateUtils.deleteItem(state, ['provider_service_settings', 'value'], payload);

    case 'ADD_NEW_PROVIDER_SERVICE_SETTING':
      const localServiceId = uuid();
      return StateUtils.addItem(state, ['provider_service_settings', 'value'], {
        ...StateUtils.createDefaultType('PROVIDER_SERVICE_SETTING'),
        ...payload,
        localId: localServiceId,
      });

    case 'UPDATE_PROVIDER_SERVICE_SETTING':
      if (payload.fields) {
        return StateUtils.updateListItem(
          state,
          ['provider_service_settings', 'value'],
          payload.index,
          payload.fields,
          payload.bypassTouch,
        );
      }

      return StateUtils.updateListItem(
        state,
        ['provider_service_settings', 'value'],
        payload.index,
        { [payload.field]: payload.fieldValue },
        payload.bypassTouch,
      );

    // TODO review why this is replacing all provider service settings
    case 'REPLACE_PROVIDER_SERVICE_SETTING':
      const payloadCreate = payload.map((settings) => {
        let settingsForState = { ...settings };
        if (!settingsForState.localId) {
          settingsForState = {
            ...StateUtils.createDefaultType('PROVIDER_SERVICE_SETTING'),
            ...settingsForState,
            localId: uuid(),
          };
        }
        return settingsForState;
      });

      return StateUtils.updateObject(state, ['provider_service_settings', 'value'], payloadCreate);

    case 'UPDATE_REMOTE_ITEM_UPDATED':
      return { ...state, remoteItemUpdated: payload };

    default:
      return operatinghours(state, action);
  }
};
