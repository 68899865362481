import React, { useState } from 'react';

import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { CustomPageTemplateItem, CustomPageTemplateSearch } from './components';
import useDebounce from '../../../../../hooks/useDebounce';
import { getErrorMessage } from '../../../../../libs/errors';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';
import { useCustomPageTemplates } from '../../hooks';
import { CustomPageTemplatesPropType } from '../../types/custom-page-template.type';

import './CustomPageTemplateList.scss';

const propTypes = {
  templates: CustomPageTemplatesPropType,
  onSelectTemplate: PropTypes.func.isRequired,
  search: PropTypes.bool,
};

const defaultProps = {
  templates: [],
  search: false,
};

export default function CustomPageTemplateList({ templates, onSelectTemplate, search }) {
  const { getCustomPageTemplates } = useCustomPageTemplates();

  const [templateResults, setTemplateResults] = useState(templates);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const debouncedQuery = useDebounce(query, 300);

  async function getTemplates() {
    setSelectedTemplate(null);
    if (!_isEmpty(templates)) {
      return;
    }

    try {
      setLoading(true);
      const nextTemplates = await getCustomPageTemplates(debouncedQuery);

      setTemplateResults(nextTemplates);
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  useAsyncEffect(getTemplates, [debouncedQuery]);

  function handleSelectTemplate(template) {
    setSelectedTemplate(template.id);
    onSelectTemplate(template);
  }

  return (
    <div className="custom-page-template-list">
      {search && (
        <CustomPageTemplateSearch
          query={query}
          onQueryChange={setQuery}
          loading={loading}
          error={error}
        />
      )}
      {templateResults.map((template) => {
        const { id } = template;

        return (
          <CustomPageTemplateItem
            key={id}
            template={template}
            onSelectTemplate={handleSelectTemplate}
            selected={selectedTemplate === id}
          />
        );
      })}
    </div>
  );
}

CustomPageTemplateList.propTypes = propTypes;
CustomPageTemplateList.defaultProps = defaultProps;
