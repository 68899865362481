import PropTypes from 'prop-types';

const CustomerSubmissionPropType = PropTypes.shape({
  id: PropTypes.number,
  createdAt: PropTypes.string,
  location: PropTypes.shape({}),
  contact: PropTypes.shape({}),
  eventData: PropTypes.shape({
    fieldValues: PropTypes.shape({}),
  }),
  fileFields: PropTypes.shape({}),
});

export default CustomerSubmissionPropType;
