/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { INTERACTION_STYLE_LABEL, INTERACTION_STYLE_PRIMARY } from '../constants';

import './StyleSelector.scss';

const propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  setStyle: PropTypes.func.isRequired,
};

const defaultProps = {
  value: INTERACTION_STYLE_PRIMARY,
  disabled: false,
};

function StyleSelector({ value, setStyle, disabled }) {
  function createStyleTypeHandler(type) {
    return () => setStyle(type);
  }

  return (
    <Form.Group className="website-component-interaction-style-selector">
      <label>Style:</label>
      {Object.keys(INTERACTION_STYLE_LABEL).map((key) => (
        <Form.Radio
          disabled={disabled}
          key={key}
          label={INTERACTION_STYLE_LABEL[key]}
          value={key}
          checked={value === key}
          onClick={createStyleTypeHandler(key)}
        />
      ))}
    </Form.Group>
  );
}

StyleSelector.propTypes = propTypes;
StyleSelector.defaultProps = defaultProps;
export default StyleSelector;
