import _get from 'lodash/get';

import ErrorHandler from '../../../../../libs/errors/errors';
import { calculateTilesPerRow } from '../../../../modules/hero/services/tile';
import { HOME_PAGE_TYPE } from '../../../../modules/pages/constants';

export function getHomePage(pages) {
  return pages.find((page) => page.pageType === HOME_PAGE_TYPE);
}

export function componentConfigurationToTile(componentConfiguration) {
  const {
    id,
    fastId,
    files = [],
    contentNodes = [],
    interactions = [],
    childConfigurations,
    ...componentConfigurationRest
  } = componentConfiguration;

  return {
    componentConfiguration: {
      componentConfigurationId: id,
      componentName: fastId,
      ...componentConfigurationRest,
    },
    images: files.map(({ id: businessFileId, ...rest }) => ({ businessFileId, ...rest })),
    contentNodes: contentNodes.map(({ id: contentNodeId, ...rest }) => ({
      contentNodeId,
      ...rest,
    })),
    interactions,
  };
}

export function convertDynamicTilesToCustomHero(dynamicTiles, page) {
  const customHeroComponentConfiguration = componentConfigurationToTile(dynamicTiles);

  const tiles = _get(dynamicTiles, 'childConfigurations', []).map(componentConfigurationToTile);
  const tilesPerRow = calculateTilesPerRow(tiles);

  return {
    ...customHeroComponentConfiguration,
    page,
    tiles,
    tilesPerRow,
  };
}

export async function loadDataWrapper(dataLoaderCallbacks, setLoading) {
  setLoading(true);
  try {
    await Promise.all(dataLoaderCallbacks.map((callback) => callback()));
  } catch (error) {
    ErrorHandler.capture(error);
  } finally {
    setLoading(false);
  }
}
