import React from 'react';

import PropTypes from 'prop-types';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

import { MERCHANT_BUSINESS_TYPE } from '../../../../../../constants/constants';
import SectionSelect from '../../../SectionSelect';
import SideNavigationItems from '../SideNavigationContent';

import './DesktopSideNavigation.scss';

const propTypes = {
  rootRoutePath: PropTypes.string.isRequired,
};

export default function DesktopSideNavigation({ rootRoutePath }) {
  const { eCommerce: eCommerceEnabled } = useFlags();
  const businessType = useSelector((state) => state.business.core.value.type);
  const showProductSelector = eCommerceEnabled && businessType === MERCHANT_BUSINESS_TYPE;

  return (
    <div className="desktop-side-navigation">
      {showProductSelector && <SectionSelect />}
      <SideNavigationItems rootRoutePath={rootRoutePath} />
    </div>
  );
}

DesktopSideNavigation.propTypes = propTypes;
