import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import snakeCase from 'lodash/snakeCase';

import {
  INTERNAL_FORM_FIELD_TYPES,
  RECIPE_FORM_FIELD_TYPES,
  FIELD_TYPE_TO_WIDGET,
  FIELD_WIDGET_TO_TYPE,
  FIELD_TYPE_TO_INPUT_TYPE,
} from '../../../../constants/form';
import { createCollectionValue } from '../utils/props';

export function generateFieldName(label) {
  return snakeCase(label);
}

export function flattenFastShapeFields(field) {
  const { value } = field;

  if (_isNil(value)) {
    return field;
  }

  const { options = [] } = value;

  if (!_isEmpty(options)) {
    const newOptions = options.value.map(({ value: optionValue }) => optionValue);
    return { ...value, options: newOptions };
  }
  return value;
}

export function transformLegacyFieldIntoNew(field) {
  const flattenField = flattenFastShapeFields(field);

  const { type, label, displayLabel, ...rest } = flattenField;

  // we return an array of one field to support the grouping shape
  return [{ ...rest, label: displayLabel, name: label, widgetType: type }];
}

export function transformNewFieldIntoLegacy(field) {
  const flattenField = _isArray(field) ? field[0] : field;

  const { widgetType, label, name, ...rest } = flattenField;

  return { type: widgetType, label: name, displayLabel: label, ...rest };
}

function formatSelectFieldOptions(options = []) {
  return options.map((option) =>
    createCollectionValue({
      label: option,
      value: option,
    }),
  );
}

function createBaseField(component = {}, fieldType) {
  const { label, required, placeholder, isFieldContactMapped } = component;
  const name = generateFieldName(label);

  return createCollectionValue({
    type: FIELD_TYPE_TO_WIDGET[fieldType] || fieldType,
    inputType: FIELD_TYPE_TO_INPUT_TYPE[fieldType] || fieldType,
    required: required || false,
    label: name,
    displayLabel: label,
    placeholder: placeholder || '',
    isFieldContactMapped: isFieldContactMapped || false,
  });
}

export function createEmailField(component = {}) {
  return createBaseField(component, RECIPE_FORM_FIELD_TYPES.Email);
}

export function createPhoneNumberField(component = {}) {
  return createBaseField(component, RECIPE_FORM_FIELD_TYPES.PhoneNumber);
}

export function createShortTextField(component = {}) {
  return createBaseField(component, RECIPE_FORM_FIELD_TYPES.ShortText);
}

export function createLongTextField(component = {}) {
  return createBaseField(component, RECIPE_FORM_FIELD_TYPES.LongText);
}

export function createSingleSelectField(component = {}) {
  const { settings = {} } = component;
  const { choices = [] } = settings;
  const options = formatSelectFieldOptions(choices);

  const baseField = createBaseField(component, RECIPE_FORM_FIELD_TYPES.SingleChoice);
  return {
    ...baseField,
    value: {
      ...baseField.value,
      options: createCollectionValue(options),
    },
  };
}

export function createMultipleSelectField(component = {}) {
  const { settings = {} } = component;
  const { choices = [] } = settings;
  const options = formatSelectFieldOptions(choices);

  const baseField = createBaseField(component, RECIPE_FORM_FIELD_TYPES.MultipleChoice);
  return {
    ...baseField,
    value: {
      ...baseField.value,
      options: createCollectionValue(options),
    },
  };
}

export function createFileUploadFile(component = {}) {
  return createBaseField(component, RECIPE_FORM_FIELD_TYPES.FileUpload);
}

export function createBaseFieldState(field = {}, type) {
  const settings = {};
  const { value = {} } = field;
  const {
    required,
    displayLabel = '',
    placeholder = '',
    isFieldContactMapped = false,
  } = !_isEmpty(value) ? value : field;

  return {
    type: FIELD_WIDGET_TO_TYPE[type] || type,
    required: !!required,
    placeholder,
    label: displayLabel,
    settings,
    isFieldContactMapped,
  };
}

export function createEmailFieldState(field = {}) {
  return createBaseFieldState(field, INTERNAL_FORM_FIELD_TYPES.Email);
}

export function createPhoneNumberFieldState(field = {}) {
  return createBaseFieldState(field, INTERNAL_FORM_FIELD_TYPES.PhoneNumber);
}

export function createTextFieldState(field = {}) {
  return createBaseFieldState(field, INTERNAL_FORM_FIELD_TYPES.ShortText);
}

export function createLongTextFieldState(field = {}) {
  return createBaseFieldState(field, INTERNAL_FORM_FIELD_TYPES.LongText);
}

function getFieldChoicesState(field = {}) {
  const { value = {} } = field;
  const { options } = !_isEmpty(value) ? value : field;
  const { value: optionsValues = [] } = options || {};

  if (_isEmpty(options) && _isEmpty(optionsValues)) {
    return [];
  }

  const choices = !_isEmpty(optionsValues)
    ? optionsValues.map(({ value: optionValues }) => optionValues.value)
    : options.map && options.map(({ value: optionValue }) => optionValue);

  return choices || [];
}

export function createSingleSelectFieldState(field = {}) {
  const baseState = createBaseFieldState(field, INTERNAL_FORM_FIELD_TYPES.SingleChoice);

  const choices = getFieldChoicesState(field);
  const settings = { choices };

  return { ...baseState, settings };
}

export function createMultiSelectFieldState(field = {}) {
  const baseState = createBaseFieldState(field, INTERNAL_FORM_FIELD_TYPES.MultipleChoice);

  const choices = getFieldChoicesState(field);
  const settings = { choices };

  return { ...baseState, settings };
}

export function createFileUploadFieldState(field = {}) {
  return createBaseFieldState(field, INTERNAL_FORM_FIELD_TYPES.FileUpload);
}
