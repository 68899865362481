import _keys from 'lodash/keys';

import { SEMVER_REGX } from '../../../PartialPage/PartialPage.constants';

export function validatePartialForm(fields) {
  const fieldErrors = {};
  _keys(fields).map((fieldName) => {
    if (!fields[fieldName]) {
      fieldErrors[fieldName] = `Please enter a partial ${fieldName}`;
    }

    if (fieldName === 'schemaVersion' && fields[fieldName]) {
      if (!SEMVER_REGX.test(fields[fieldName])) {
        fieldErrors[fieldName] = 'Not a valid version';
      }
    }
    return null;
  });

  return fieldErrors;
}
