import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import _isNil from 'lodash/isNil';

import onUpdate from '../../../hooks/useOnUpdate';
import useSemanticUiProps from '../../../hooks/useSemanticUiProps';
import { getPriceInputValue, isValidPrice, parsePrice } from '../../../libs/price';

import './PriceField.scss';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  priceInitialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelInitialValue: PropTypes.string,
  priceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelValue: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isMoney: PropTypes.bool,
  allowNegativePrices: PropTypes.bool,
};

const defaultProps = {
  priceInitialValue: 0,
  labelInitialValue: '',
  priceValue: undefined,
  labelValue: undefined,
  name: '',
  placeholder: '',
  isMoney: true,
  allowNegativePrices: false,
};

function PriceField({
  onChange: onChangeProp,
  priceInitialValue,
  labelInitialValue,
  priceValue: priceProp,
  labelValue: labelProp,
  name,
  placeholder,
  isMoney,
  allowNegativePrices,
  ...rest
}) {
  const isControlled = !_isNil(priceProp) && !_isNil(labelProp);

  const [price, setPrice] = useState(priceInitialValue);
  const [priceLabel, setPriceLabel] = useState(labelInitialValue || `${price}`);

  const finalPrice = isControlled ? priceProp : price;
  const finalPriceLabel = isControlled ? labelProp : priceLabel;

  const { getInputProps } = useSemanticUiProps(rest);

  function getCallbackValue(value) {
    if (isMoney) {
      return parsePrice(value);
    }
    return value;
  }

  function onChange(e, { name: fieldName, value }) {
    if (!isControlled) {
      if (isMoney) {
        setPrice(value);
      } else {
        setPriceLabel(value);
      }
    }

    const target = { name: fieldName, value: getCallbackValue(value) };
    onChangeProp({ ...e, target }, target);
  }

  function onMoneyChange(e, { name: fieldName, value }) {
    if (!isValidPrice(value, { allowNegativePrices })) {
      return;
    }
    onChange(e, { name: fieldName, value });
  }

  onUpdate(() => {
    const newPrice = isMoney ? getPriceInputValue(finalPrice) : `${finalPriceLabel}`;

    onChange({}, { name, value: newPrice });
  }, [isMoney]);

  const baseProps = { ...getInputProps(rest), name, placeholder };

  if (isMoney) {
    return (
      <Form.Input
        {...baseProps}
        className="price-field number"
        value={getPriceInputValue(finalPrice)}
        type="number"
        step="0.01"
        onChange={onMoneyChange}
        min={null}
      />
    );
  }
  return (
    <Form.Input
      {...baseProps}
      className="price-field string"
      value={finalPriceLabel}
      onChange={onChange}
    />
  );
}

PriceField.propTypes = propTypes;
PriceField.defaultProps = defaultProps;

export default PriceField;
