import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import clsx from 'clsx';

import ActionsInput from 'components/modules/content/components/ActionsInput';
import WritingAssistantButton from 'components/modules/content/components/WritingAssistantButton';
import { getWritingAssistantActions } from 'components/modules/content/services/prompts';
import useSemanticUiProps from 'hooks/useSemanticUiProps';
import ErrorHandler from 'libs/errors/errors';

const propTypes = {
  className: PropTypes.string,
  onSuggest: PropTypes.func,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showActions: PropTypes.bool,
};

const defaultProps = {
  className: '',
  onSuggest: () => Promise.resolve(''),
  showActions: true,
};

export default function SuggestionInput({
  className,
  onSuggest,
  value,
  onChange,
  showActions,
  ...rest
}) {
  const [loading, setLoading] = useState(false);

  const semanticUiProps = useSemanticUiProps(rest);
  const inputProps = semanticUiProps.getInputProps(rest);

  async function onMakeSuggestion() {
    try {
      setLoading(true);
      const aiGeneratedValue = await onSuggest();
      onChange(aiGeneratedValue);
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }

  const finalClassName = clsx('suggestion-input', className && className);

  return (
    <ActionsInput className={finalClassName}>
      <ActionsInput.Input
        input={Input}
        onChange={(e, { value: inputValue }) => onChange(inputValue)}
        loading={loading}
        value={value}
        {...inputProps}
      />

      {showActions && (
        <ActionsInput.Actions
          trigger={WritingAssistantButton}
          actionSections={getWritingAssistantActions({ onMakeSuggestion })}
          adminOnly
        />
      )}
    </ActionsInput>
  );
}

SuggestionInput.propTypes = propTypes;
SuggestionInput.defaultProps = defaultProps;
