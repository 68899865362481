import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { List, Button, Header } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import {
  AMOUNT_TO_SHOW_INCREMENT,
  DEFAULT_AMOUNT_OF_DOMAINS_TO_SHOW,
} from './DomainList.constants';

import './DomainList.scss';

const propTypes = {
  onDomainClick: PropTypes.func.isRequired,
  domains: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDomain: PropTypes.string,
};

const defaultProps = {
  domains: [],
  selectedDomain: '',
};

export default function DomainList({ domains, onDomainClick, selectedDomain }) {
  const [count, setCount] = useState(DEFAULT_AMOUNT_OF_DOMAINS_TO_SHOW);

  function showMoreResults() {
    setCount((previousCount) => Math.min(domains.length, previousCount + AMOUNT_TO_SHOW_INCREMENT));
  }

  function handleDomainClick(domain) {
    if (domain.Availability === 'AVAILABLE') {
      onDomainClick(domain.DomainName);
    }
  }

  function getClassName(domain) {
    let className = '';
    if (domain.Availability !== 'AVAILABLE') className += 'domain-unavailable';
    if (domain.DomainName === selectedDomain) className += 'domain-selected';
    return className;
  }

  if (_isEmpty(domains)) return null;

  return (
    <div className="domain-list-container">
      <Header as="h3">Suggested results:</Header>

      <List className="domain-list" divided verticalAlign="middle">
        {domains.slice(0, count).map((domain) => (
          <List.Item
            className={getClassName(domain)}
            key={domain.DomainName}
            onClick={() => handleDomainClick(domain)}
          >
            <List.Content>
              <p className="result-label">{domain.DomainName}</p>
            </List.Content>
            {domain.Availability === 'AVAILABLE' ? (
              <List.Content>
                <p className="status-label">FREE</p>
              </List.Content>
            ) : (
              <List.Content>
                <p className="status-label">Unavailable</p>
              </List.Content>
            )}
          </List.Item>
        ))}
      </List>

      {count < domains.length && (
        <Button onClick={showMoreResults} className="load-more-results-button">
          Load more results
        </Button>
      )}
    </div>
  );
}

DomainList.propTypes = propTypes;
DomainList.defaultProps = defaultProps;
