import React from 'react';

import { Header, Icon, Grid, Segment, Select } from 'semantic-ui-react';

import { withTheme } from '@rjsf/core';
// https://react-jsonschema-form.readthedocs.io/en/latest/usage/validation/
import { Theme as SemanticUITheme } from '@rjsf/semantic-ui';
import _ from 'lodash';
import { connect } from 'react-redux';

import { updateProviderServiceSetting as updateProviderServiceSettingSetting } from '../../actions/business';

import '../../styles/core/components/integration-form.scss';

// JSON schema form
const Form = withTheme(SemanticUITheme);

class IntegrationForm extends React.Component {
  static formatServiceSetting(data) {
    const selected = data.options.filter((option) => option.value === data.value)[0];

    return {
      provider_service: selected.id,
      provider: selected.provider,
      service_type: selected.service_type,
      service_data: {},
      status_data: {},
      service_data_schema: selected.service_data_schema,
    };
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSelectProvider = this.handleSelectProvider.bind(this);
    this.getAvailableOptions = this.getAvailableOptions.bind(this);
  }

  handleSelectProvider(e, target) {
    const { updateProviderServiceSetting, index } = this.props;
    const serviceSetting = IntegrationForm.formatServiceSetting(target);

    updateProviderServiceSetting({
      index,
      fields: serviceSetting,
    });
  }

  handleDelete() {
    const { provider, service_type: serviceType, handleDelete } = this.props;

    handleDelete(provider, serviceType);
  }

  handleChange(e) {
    const { handleChange } = this.props;
    const { formData } = e;

    handleChange(formData);
  }

  getSchema() {
    const { providerServiceSettings, provider, service_type: serviceType } = this.props;

    const providerService = _.find(providerServiceSettings, (integration) => {
      const sameProvider = integration.provider === provider;
      const sameType = integration.service_type === serviceType;
      return sameProvider && sameType;
    });

    return _.get(providerService, 'service_data_schema', {});
  }

  getAvailableOptions() {
    const { availableProviders = [] } = this.props;

    return availableProviders.map((ps) => {
      return {
        ...ps,
        text: `${ps.provider} (${ps.service_type})`,
        value: ps.id,
      };
    });
  }

  render() {
    const {
      id,
      localId,
      service_data: serviceData,
      provider,
      service_type: serviceType,
    } = this.props;

    const schema = this.getSchema();

    if (localId && !id && !provider && !serviceType) {
      return (
        <Segment>
          <Header as="h3">Select a new integration</Header>
          <Select
            placeholder="Provider"
            options={this.getAvailableOptions()}
            onChange={this.handleSelectProvider}
          />
        </Segment>
      );
    }

    if (!schema) return null;

    return (
      <Segment className="integration-form">
        <Grid>
          <Grid.Column width={14}>
            <Form
              schema={schema}
              formData={serviceData}
              onChange={this.handleChange}
              liveValidate
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Header as="h3" textAlign="center">
              <Icon style={{ cursor: 'pointer' }} name="delete" onClick={this.handleDelete} />
            </Header>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateProviderServiceSetting: (payload) => dispatch(updateProviderServiceSettingSetting(payload)),
});

const mapStateToProps = ({ business }) => ({
  providerServiceSettings: _.get(business, 'provider_service_settings.value'),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationForm);
