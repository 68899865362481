import { useState, useEffect } from 'react';

import { traverseNode, reconstructNode, getChildren, getChildrenKey, getNodeId } from './utils';

export default function useRecipeNodeManager(initialNode) {
  const [node, setNode] = useState(initialNode);

  function getNode(nodeId, referenceNode = null) {
    const finalNode = referenceNode || node;
    return traverseNode(nodeId, finalNode, [], (foundNode) => foundNode);
  }

  function getPath(nodeId, referenceNode = null) {
    const finalNode = referenceNode || node;
    return traverseNode(nodeId, finalNode, [], (foundNode, path) => [...path, nodeId]) || [];
  }

  function getParentNode(nodeId, referenceNode = null) {
    const finalNode = referenceNode || node;
    const path = getPath(nodeId, finalNode);
    const parentPath = path.slice(0, -1);
    const parentNodeId = parentPath[parentPath.length - 1];

    return getNode(parentNodeId, finalNode);
  }

  function updateNode(nodeId, newNode) {
    const updatedNode = reconstructNode(nodeId, node, () => {
      return newNode;
    });

    setNode(updatedNode);
    return updatedNode;
  }

  function addNode(nodeParentId, childNode) {
    const updatedNode = reconstructNode(nodeParentId, node, (parentNode) => {
      const childrenKey = getChildrenKey(parentNode);

      return {
        ...parentNode,
        [childrenKey]: [...(parentNode[childrenKey] || []), childNode],
      };
    });

    setNode(updatedNode);
    return updatedNode;
  }

  function insertNode(nodeParentId, childNode, index) {
    const updatedNode = reconstructNode(nodeParentId, node, (parentNode) => {
      const childrenKey = getChildrenKey(parentNode);

      return {
        ...parentNode,
        [childrenKey]: [
          ...parentNode[childrenKey].slice(0, index),
          childNode,
          ...parentNode[childrenKey].slice(index),
        ],
      };
    });

    setNode(updatedNode);
    return updatedNode;
  }

  function removeNode(nodeId) {
    const path = getPath(nodeId);
    const parentPath = path.slice(0, -1);
    const parentNodeId = parentPath[parentPath.length - 1];

    const updatedNode = reconstructNode(parentNodeId, node, (parentNode) => {
      const childrenKey = getChildrenKey(parentNode);

      return {
        ...parentNode,
        [childrenKey]: parentNode[childrenKey].filter((child) => getNodeId(child) !== nodeId),
      };
    });

    setNode(updatedNode);
    return updatedNode;
  }

  function addAdjacentNode(nodeId, childNode, increment) {
    const path = getPath(nodeId);
    const parentPath = path.slice(0, -1);
    const parentNodeId = parentPath[parentPath.length - 1];
    const parentNode = getNode(parentNodeId);

    if (!parentNode) {
      return node;
    }

    const index = getChildren(parentNode).findIndex((child) => getNodeId(child) === nodeId);

    return insertNode(parentNodeId, childNode, index + increment);
  }

  function addNodeAbove(nodeId, childNode) {
    return addAdjacentNode(nodeId, childNode, 0);
  }

  function addNodeBelow(nodeId, childNode) {
    return addAdjacentNode(nodeId, childNode, 1);
  }

  useEffect(() => setNode(initialNode), [initialNode]);

  return {
    node,
    getNode,
    setNode,
    getPath,
    getParentNode,
    updateNode,
    addNode,
    insertNode,
    addNodeAbove,
    addNodeBelow,
    removeNode,
  };
}
