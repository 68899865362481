export const DEFAULT_BUTTON_TEXT = 'Connect';
export const NO_INTEGRATION_DESCRIPTION =
  'Click “Connect” and follow the instructions to integrate your e-commerce website with Stripe. ' +
  'You can use an existing Stripe Account or create one during the Onboarding process.';

export const INCOMPLETE_INTEGRATION_DESCRIPTION =
  'The Stripe Onboarding process was started but not completed. ' +
  'Click “Resume” to complete the integration setup.';

export const REJECTED_INTEGRATION_DESCRIPTION =
  'There was an issue with your Stripe onboarding process. Click “Resolve Issue” to complete your setup process.';

export const DESCRIPTION_BY_STEP = {
  0: NO_INTEGRATION_DESCRIPTION,
  1: INCOMPLETE_INTEGRATION_DESCRIPTION,
  2: REJECTED_INTEGRATION_DESCRIPTION,
};

export const BUTTON_TEXT_BY_STEP = {
  0: DEFAULT_BUTTON_TEXT,
  1: 'Resume',
  2: 'Resolve Issue',
};
