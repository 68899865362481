import {
  FULFILLMENT_PROVIDER_SHIPPO,
  FULFILLMENT_PROVIDER_TO_LABEL,
  FULFILLMENT_TYPE_SHIPPING,
  FULFILLMENT_TYPE_TO_LABEL,
  SERVICE_SETTINGS_COMPLETE,
} from '../../../../../constants/fulfillments';
import API from '../../../../../libs/api';

export async function saveOrderSettings(orderSettings) {
  if (orderSettings?.id) {
    const { id, ...payload } = orderSettings;
    const { data } = await API.updateOrderSettings(payload, id);
    return data;
  }

  const { data } = await API.createOrderSettings(orderSettings);
  return data;
}

export function filterOrderSettingsByFulfillmentType(orderSettings, fulfillmentType) {
  return orderSettings.filter((setting) => setting?.method?.type === fulfillmentType);
}

export function isOrderSettingsAvailable(orderSettingList, fulfillmentType) {
  return filterOrderSettingsByFulfillmentType(orderSettingList, fulfillmentType).some(
    (setting) => setting?.method?.type === fulfillmentType,
  );
}

export function filterShippoShippingSettings(providerServiceSettings) {
  return providerServiceSettings.filter(
    (settings) =>
      settings.provider === FULFILLMENT_PROVIDER_TO_LABEL[FULFILLMENT_PROVIDER_SHIPPO] &&
      settings.service_type === FULFILLMENT_TYPE_TO_LABEL[FULFILLMENT_TYPE_SHIPPING] &&
      settings.status === SERVICE_SETTINGS_COMPLETE,
  );
}
