import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

const defaultProps = {
  multiple: false,
  accept: undefined,
};

function InputFile({ onChange, children, multiple, accept }) {
  const fileInput = useRef(null);
  const dropRef = useRef(null);
  const [className, setClassName] = useState('');

  function onClick(event) {
    if (event) {
      event.preventDefault();
    }
    fileInput.current.click();
  }

  function handleDragOver(event) {
    event.preventDefault();
    event.stopPropagation();

    setClassName('drag-over');
  }

  function handleDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();

    setClassName('');
  }

  function handleDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    setClassName('');

    const allFiles = event.dataTransfer.files;

    if (!_isEmpty(allFiles)) {
      onChange({ target: { files: allFiles } });
      event.dataTransfer.clearData();
    }
  }

  const widgetProps = {
    className,
    onClick,
    dropRef,
    handleDrop,
    handleDragOver,
    handleDragLeave,
  };

  return (
    <>
      {children(widgetProps)}
      <input
        ref={fileInput}
        type="file"
        hidden
        onChange={onChange}
        accept={accept}
        multiple={multiple}
      />
    </>
  );
}

InputFile.propTypes = propTypes;
InputFile.defaultProps = defaultProps;

export default InputFile;
