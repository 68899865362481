import React from 'react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import Location from './Location';
import { AddItemField } from '..';
import {
  addLocation as addLocationConnect,
  updateLocation as updateLocationConnect,
  updateLocationHours as updateLocationHoursConnect,
  updateLocationHourById as updateLocationHourByIdConnect,
  removeLocationHourById as removeLocationHourByIdConnect,
  copyLocationHours as copyLocationHoursConnect,
  addLocationHours as addLocationHoursConnect,
  deleteLocation as deleteLocationConnect,
} from '../../../actions/business';
import API from '../../../libs/api';
import { isAdminUser } from '../../../libs/auth';
import ErrorHandler from '../../../libs/errors';

import './Locations.scss';

class Locations extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpdateLocation = this.handleUpdateLocation.bind(this);
    this.handleDeleteLocationHour = this.handleDeleteLocationHour.bind(this);
    this.handleAddLocationHours = this.handleAddLocationHours.bind(this);
    this.handleCopyLocationHours = this.handleCopyLocationHours.bind(this);
    this.handleDeleteLocation = this.handleDeleteLocation.bind(this);
    this.togglePrimary = this.togglePrimary.bind(this);
    this.toggleLegal = this.toggleLegal.bind(this);
  }

  // TODO: remove bypassTouch from child components
  handleUpdateLocation(locationIndex, payload, bypassTouch) {
    const { updateLocation } = this.props;
    updateLocation({
      index: locationIndex,
      fields: payload,
      bypassTouch: bypassTouch || false,
    });
  }

  async handleDeleteLocation(locationIndex, location) {
    const { deleteLocation, businessType, businessId } = this.props;
    if (location.id) {
      try {
        await API.deleteLocation(businessId, businessType, location.id);
        deleteLocation(locationIndex);
      } catch (e) {
        ErrorHandler.capture(e);
        throw e;
      }
    } else {
      deleteLocation(locationIndex);
    }
  }

  handleUpdateLocationHour(locationIndex, hourId, payload) {
    const { updateLocationHourById } = this.props;
    updateLocationHourById({ ...payload, index: locationIndex, hourId });
  }

  handleDeleteLocationHour(locationIndex, hourId) {
    const { removeLocationHourById } = this.props;
    removeLocationHourById({ index: locationIndex, hourId });
  }

  handleAddLocationHours(locationIndex, day) {
    const { addLocationHours } = this.props;
    addLocationHours({ index: locationIndex, day });
  }

  handleCopyLocationHours(locationIndex, sourceDay, targetDays) {
    const { copyLocationHours } = this.props;
    copyLocationHours({ locationIndex, sourceDay, targetDays });
  }

  togglePrimary(locationIndex) {
    const { locations, updateLocation } = this.props;
    locations.forEach((location, index) => {
      const isPrimary = locationIndex !== index ? false : !location.primary_location;
      updateLocation({
        index,
        fields: { primary_location: isPrimary },
        bypassTouch: false,
      });
    });
  }

  toggleLegal(locationIndex) {
    const { locations, updateLocation } = this.props;
    locations.forEach((location, index) => {
      const isLegal = locationIndex !== index ? false : !location.legal_location;
      updateLocation({
        index,
        fields: { legal_location: isLegal },
        bypassTouch: false,
      });
    });
  }

  render() {
    const { locations, addLocation, user } = this.props;
    const canAddLocation = isAdminUser(user);

    return (
      <div className="locations">
        {canAddLocation && <AddItemField description="Add Location" onAdd={addLocation} />}
        {locations.map((location, index) => (
          <Location
            location={location}
            key={`${location.id || location.localId}`}
            index={index}
            onUpdateLocation={(data, bypassTouch) =>
              this.handleUpdateLocation(index, data, bypassTouch)
            }
            onDeleteLocation={this.handleDeleteLocation}
            onUpdateLocationHour={(hourId, payload) =>
              this.handleUpdateLocationHour(index, hourId, payload)
            }
            onDeleteLocationHour={(hourId) => this.handleDeleteLocationHour(index, hourId)}
            onCopyLocationHours={(sourceDay, targetDays) =>
              this.handleCopyLocationHours(index, sourceDay, targetDays)
            }
            onAddLocationHour={(day) => this.handleAddLocationHours(index, day)}
            onMakePrimary={() => this.togglePrimary(index)}
            onMakeLegal={() => this.toggleLegal(index)}
          />
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addLocation: (payload) => dispatch(addLocationConnect(payload)),
  deleteLocation: (payload) => dispatch(deleteLocationConnect(payload)),
  updateLocation: (payload) => dispatch(updateLocationConnect(payload)),
  addLocationHours: (payload) => dispatch(addLocationHoursConnect(payload)),
  updateLocationHours: (payload) => dispatch(updateLocationHoursConnect(payload)),
  updateLocationHourById: (payload) => dispatch(updateLocationHourByIdConnect(payload)),
  removeLocationHourById: (payload) => dispatch(removeLocationHourByIdConnect(payload)),
  copyLocationHours: (payload) => dispatch(copyLocationHoursConnect(payload)),
});

const mapStateToProps = ({ business, user }) => ({
  user: _get(user, 'core.value'),
  locationsLoaded: _get(business, 'locations._loaded'),
  businessType: _get(business, 'core.value.type'),
  businessId: _get(business, 'core.value.id'),
  locations: _get(business, 'locations.value'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
