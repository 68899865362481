import React from 'react';

import { Input, Button, Grid, Confirm } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import {
  updateMenuModifierSet as updateMenuModifierSetConnect,
  deleteMenuModifierSet as deleteMenuModifierSetConnect,
  updateMenuModifier as updateMenuModifierConnect,
  addMenuModifier as addMenuModifierConnect,
  deleteMenuModifier as deleteMenuModifierConnect,
  flagMenuSaved as flagMenuSavedConnect,
} from '../../../../actions/business';
import API from '../../../../libs/api';
import HelpTooltip from '../../../common/HelpTooltip';
import PriceField from '../../../fields/PriceField';
import ControlledModal from '../common/ControlledModal';
import EditMenuModal from '../common/EditMenuModal';
import PriceLabelToggle from '../common/PriceLabelToggle/PriceLabelToggle';
import AddModifier from '../modifier/AddModifier';
import DeleteModifier from '../modifier/DeleteModifier';

class EditMenuModifierSet extends ControlledModal {
  constructor(props) {
    super(props);

    const { modifiers } = props;

    const initialIsMoney = _isEmpty(_get(modifiers, '[0].price_display', ''));

    this.state = {
      errorMessage: '',
      saveLoading: false,
      deleteLoading: false,
      open: props.initialOpen || false,
      deleteConfirmationOpen: false,
      isMoney: initialIsMoney,
    };

    this.onSaveNewModifierSet = this.onSaveNewModifierSet.bind(this);
    this.onSaveModifierSet = this.onSaveModifierSet.bind(this);
    this.onDeleteNewModifierSet = this.onDeleteNewModifierSet.bind(this);
    this.onDeleteModifierSet = this.onDeleteModifierSet.bind(this);
    this.onSaveNewModifier = this.onSaveNewModifier.bind(this);
    this.onSaveModifier = this.onSaveModifier.bind(this);
    this.onDeleteNewModifier = this.onDeleteNewModifier.bind(this);
    this.onDeleteModifier = this.onDeleteModifier.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleDeleteConfirmation = this.toggleDeleteConfirmation.bind(this);
    this.updateModifierSet = this.updateModifierSet.bind(this);
    this.onPriceToggle = this.onPriceToggle.bind(this);
  }

  closeModal() {
    this.setState({ open: false });
  }

  setErrorMessage(errorMessage, clear = true) {
    this.setState({ errorMessage });

    if (clear) {
      setTimeout(() => this.setErrorMessage('', false), 5000);
    }
  }

  validateInputs(modifiers) {
    const { name } = this.props;

    if (!name) return 'Please enter a modifier set name.';

    const modifierErrors = modifiers.map(({ name: modifierName }) => !!modifierName);

    if (modifierErrors.includes(false)) {
      return 'Please enter a modifier name.';
    }

    return true;
  }

  setLoading(type, value) {
    this.setState({ [type]: value });
  }

  toggleDeleteConfirmation(value) {
    this.setState({ deleteConfirmationOpen: value });
  }

  async onSaveNewModifierSet() {
    const {
      modifiers,
      name,
      description,
      minAllowed,
      maxAllowed,
      business,
      index,
      itemId,
      localId,
      itemIndex,
      items,
      updateMenuModifierSet,
      flagMenuSaved,
    } = this.props;

    const { id: businessId, type: businessType } = business;
    const item = items[itemIndex];

    const newModifiersToBeSaved = modifiers ? modifiers.filter(({ id }) => !id) : [];

    const validation = this.validateInputs(newModifiersToBeSaved);

    if (validation !== true) {
      this.setErrorMessage(validation);
      return;
    }

    this.setLoading('saveLoading', true);
    try {
      const modifierIds = await Promise.all(
        newModifiersToBeSaved.map((m) => this.onSaveNewModifier(m)),
      );

      const filteredModifierIds = modifierIds.filter((val) => val);

      const { data } = await API.createMenuModifierSet(businessId, businessType, {
        name,
        description,
        min_allowed: minAllowed,
        max_allowed: maxAllowed,
        modifiers: filteredModifierIds,
      });

      const newModifierSets = [
        ...new Set([...item.modifier_sets.filter((id) => typeof id !== 'string'), data.id]),
      ];

      const { data: modifierSetItem } = await API.addMenuItemModifierSet(
        businessId,
        businessType,
        itemId,
        newModifierSets,
      );

      updateMenuModifierSet({
        index,
        values: data,
        itemIndex,
        localModifierSetId: localId,
        modifierSets: modifierSetItem.modifier_sets,
      });

      this.setLoading('saveLoading', false);
      this.closeModal();

      flagMenuSaved(true);
    } catch (e) {
      this.setErrorMessage('There was an error saving modifier set');
    }
  }

  async onSaveModifierSet() {
    const {
      modifiers,
      name,
      id,
      description,
      minAllowed,
      maxAllowed,
      business,
      index,
      updateMenuModifierSet,
      flagMenuSaved,
    } = this.props;

    const { id: businessId, type: businessType } = business;

    const newModifiersToBeSaved = modifiers
      ? modifiers.filter(({ id: modifierId }) => !modifierId)
      : [];

    const existingModifierToBeSaved = modifiers
      ? modifiers.filter(({ dirty, id: modifierId }) => modifierId && dirty)
      : [];

    const existingModifiers = modifiers ? modifiers.filter(({ id: modifierId }) => modifierId) : [];

    const validation = this.validateInputs([
      ...newModifiersToBeSaved,
      ...existingModifierToBeSaved,
    ]);

    if (validation !== true) {
      this.setErrorMessage(validation);
      return;
    }

    this.setLoading('saveLoading', true);
    try {
      const modifierIds = await Promise.all(
        newModifiersToBeSaved.map((m) => this.onSaveNewModifier(m)),
      );

      const filteredModifierIds = modifierIds.filter((val) => val);

      const modifiersToBeSaved = [
        ...existingModifiers.map(({ id: modifierId }) => modifierId),
        ...filteredModifierIds,
      ];

      const { data } = await API.updateMenuModifierSet(businessId, businessType, id, {
        name,
        description,
        min_allowed: minAllowed,
        max_allowed: maxAllowed,
        modifiers: modifiersToBeSaved,
      });

      updateMenuModifierSet({
        index,
        values: data,
      });

      await Promise.all(existingModifierToBeSaved.map((m) => this.onSaveModifier(m)));

      this.setLoading('saveLoading', false);
      this.closeModal();

      flagMenuSaved(true);
    } catch (e) {
      this.setErrorMessage('There was an error saving modifier set');
      this.setLoading('saveLoading', false);
    }
  }

  onDeleteNewModifierSet() {
    const { index, itemIndex, modifiers, localId, deleteMenuModifierSet } = this.props;

    try {
      deleteMenuModifierSet({
        index,
        itemIndex,
        modifierSetId: localId,
      });

      modifiers.forEach(({ localId: modifierLocalId, index: modifierIndex }) => {
        this.onDeleteNewModifier(modifierIndex, modifierLocalId);
      });
    } catch (e) {
      this.setErrorMessage('There was an error deleting the modifier set');
    }
  }

  async onDeleteModifierSet() {
    const { business, index, itemIndex, modifiers, id, deleteMenuModifierSet, flagMenuSaved } =
      this.props;

    const { id: businessId, type: businessType } = business;

    this.setLoading('deleteLoading', true);
    try {
      await API.deleteMenuModifierSet(businessId, businessType, id);

      deleteMenuModifierSet({
        index,
        itemIndex,
        modifierSetId: id,
      });

      await Promise.all(
        modifiers.map(({ index: modifierIndex, id: modifierId }) =>
          this.onDeleteModifier(modifierIndex, modifierId),
        ),
      );

      flagMenuSaved(true);
    } catch (e) {
      this.setErrorMessage('There was an error deleting the modifier set');
    }
    this.setLoading('deleteLoading', false);
  }

  async onSaveNewModifier(modifier) {
    const { business, updateMenuModifier } = this.props;
    const { id: businessId, type: businessType } = business;

    try {
      const { index: modifierIndex, ...rest } = modifier;
      const { data } = await API.createMenuModifier(businessId, businessType, rest);

      updateMenuModifier({
        index: modifierIndex,
        values: data,
        clearDirty: true,
      });

      return data.id;
    } catch (e) {
      this.setErrorMessage('There was an error saving modifier');
      return null;
    }
  }

  async onSaveModifier(modifier) {
    const { business, updateMenuModifier } = this.props;
    const { id: businessId, type: businessType } = business;

    try {
      const { index: modifierIndex, id, ...rest } = modifier;
      const { data } = await API.updateMenuModifier(businessId, businessType, id, rest);

      updateMenuModifier({
        index: modifierIndex,
        values: data,
        clearDirty: true,
      });
    } catch (e) {
      this.setErrorMessage('There was an error saving modifier');
    }
  }

  onDeleteNewModifier(index, localId) {
    const { index: modifierSetIndex, deleteMenuModifier } = this.props;

    deleteMenuModifier({
      entityIndex: index,
      entityId: localId,
      entityRelationIndex: modifierSetIndex,
    });
  }

  async onDeleteModifier(index, id) {
    const { index: modifierSetIndex, business, deleteMenuModifier } = this.props;
    const { id: businessId, type: businessType } = business;

    try {
      await API.deleteMenuModifier(businessId, businessType, id);

      deleteMenuModifier({
        entityIndex: index,
        entityId: id,
        entityRelationIndex: modifierSetIndex,
      });
    } catch (e) {
      this.setErrorMessage('There was an error deleting the modifier');
    }
  }

  updateModifierSet(e, target, index) {
    const { minAllowed, maxAllowed, updateMenuModifierSet } = this.props;
    const { name, value } = target;
    const numericVal = parseInt(value, 10);

    if (name === 'min_allowed') {
      if (Number.isNaN(numericVal)) return;
      if (numericVal < 0) return;
      if (minAllowed && maxAllowed && numericVal > parseInt(maxAllowed, 10)) return;
    } else {
      if (value && Number.isNaN(numericVal)) return;
      if (numericVal < 0) return;
      if (minAllowed && maxAllowed && numericVal < parseInt(minAllowed, 10)) {
        updateMenuModifierSet({
          index,
          values: { [name]: minAllowed },
        });
        return;
      }
    }

    updateMenuModifierSet({
      index,
      values: { [name]: numericVal.toString() },
    });
  }

  onPriceToggle(e, { checked }) {
    const { modifiers, updateMenuModifier } = this.props;

    modifiers.forEach(({ index }) => {
      if (!checked) {
        updateMenuModifier({ index, values: { price_display: '' } });
      }
    });
    this.setState({ isMoney: !checked });
  }

  render() {
    const {
      id,
      name,
      trigger,
      modifiers = [],
      isNew,
      index,
      updateMenuModifierSet,
      addMenuModifier,
      updateMenuModifier,
    } = this.props;
    const { errorMessage, saveLoading, deleteLoading, open, deleteConfirmationOpen, isMoney } =
      this.state;

    return (
      <EditMenuModal
        open={open}
        openModal={this.openModal}
        closeModal={() => {
          if (!id) {
            this.onDeleteNewModifierSet();
          }
          this.closeModal();
        }}
        className="edit-menu-schedule edit-menu-modifier-set"
        trigger={trigger}
        newItemTitle="Create Modifier Set"
        existingItemTitle="Edit Modifier Set"
        description='Modifiers are how you modify an item, like "Sides" or "Sauces" and can have different prices'
        isNewItem={isNew}
        content={
          <>
            <h2>
              <span className="red asterisk">Modifier Set Name</span>
              <HelpTooltip
                title="Modifier Set Name"
                content="Enter the name that the Customers should see when selecting the contained Modifiers. Examples are Side Choice, Dressings, Toppings..."
              />
            </h2>
            <Input
              defaultValue={name || ''}
              placeholder="e.g. Side Options"
              onChange={(e, { value }) =>
                updateMenuModifierSet({
                  index,
                  values: { name: value },
                })
              }
            />
            <div className="menu-modifier-set-modifiers">
              <Grid>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <h2>
                      Modifiers
                      <HelpTooltip
                        title="Modifiers"
                        content="Create your Modifiers contained within this Modifier Set. You may optionally set prices for any or all of the Modifiers if they have an additional price. Not that any changes (addition, removal, pricing) are reflected anywhere this Modifier Set is used."
                      />
                    </h2>
                  </Grid.Column>
                  <Grid.Column width={6} textAlign="right">
                    <h2>Prices</h2>
                    <PriceLabelToggle defaultIsMoney={isMoney} onChange={this.onPriceToggle} />
                  </Grid.Column>
                </Grid.Row>
                {modifiers.map(
                  ({
                    name: modifierName,
                    price,
                    price_display: priceDisplay,
                    index: modifierIndex,
                    id: modifierId,
                    localId,
                  }) => (
                    <Grid.Row key={`modifier_${modifierId || localId}`}>
                      <Grid.Column width={12}>
                        <DeleteModifier
                          onClick={
                            id
                              ? () => this.onDeleteModifier(modifierIndex, modifierId)
                              : () => this.onDeleteNewModifier(modifierIndex, localId)
                          }
                        />
                        <Input
                          className="red asterisk after-big"
                          defaultValue={modifierName}
                          placeholder="Modifier Name"
                          onChange={(e, { value }) =>
                            updateMenuModifier({
                              index: modifierIndex,
                              values: { name: value },
                            })
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={4} textAlign="right" verticalAlign="middle">
                        <PriceField
                          priceInitialValue={price}
                          labelInitialValue={priceDisplay}
                          placeholder="0.00"
                          isMoney={isMoney}
                          onChange={(e, { value }) =>
                            updateMenuModifier({
                              index: modifierIndex,
                              values: { [isMoney ? 'price' : 'price_display']: value },
                            })
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  ),
                )}
              </Grid>
              <AddModifier onClick={() => addMenuModifier({ modifierSetIndex: index })} />
            </div>
            <div style={{ color: 'red', marginTop: '1rem', paddingRight: '50%' }}>
              *Any edits to modifiers sets will be reflected across all items that have this
              associated modifier set.
            </div>
          </>
        }
        actions={
          <>
            {errorMessage && <div className="menu-error-message">{errorMessage}</div>}
            {!isNew && (
              <>
                <Button
                  className=""
                  onClick={() => this.toggleDeleteConfirmation(true)}
                  loading={deleteLoading}
                  color="red"
                  content="Delete"
                />
                <Confirm
                  dimmer="inverted"
                  className="delete-confirmation"
                  content="Are you sure you want to delete this modifier set?"
                  confirmButton={{
                    content: 'Delete',
                    loading: deleteLoading,
                  }}
                  open={deleteConfirmationOpen}
                  onCancel={() => this.toggleDeleteConfirmation(false)}
                  onConfirm={this.onDeleteModifierSet}
                />{' '}
              </>
            )}
            <Button
              className="action"
              onClick={isNew ? this.onSaveNewModifierSet : this.onSaveModifierSet}
              loading={saveLoading}
              content="Save"
            />
          </>
        }
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateMenuModifierSet: (payload) => dispatch(updateMenuModifierSetConnect(payload)),
  deleteMenuModifierSet: (payload) => dispatch(deleteMenuModifierSetConnect(payload)),
  updateMenuModifier: (payload) => dispatch(updateMenuModifierConnect(payload)),
  addMenuModifier: (payload) => dispatch(addMenuModifierConnect(payload)),
  deleteMenuModifier: (payload) => dispatch(deleteMenuModifierConnect(payload)),
  flagMenuSaved: (payload) => dispatch(flagMenuSavedConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  business: _get(business, 'core.value'),
  items: _get(business, 'menu.value.items'),
  modifierSets: _get(business, 'menu.value.modifier_sets'),
  website: _get(business, 'core.value.website'),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMenuModifierSet);
