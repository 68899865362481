import React from 'react';

import PropTypes from 'prop-types';
import { Table, Checkbox } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import HelpTooltip from '../../../../common/HelpTooltip';

import './SettingsWidget.scss';

const propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.shape().isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

const defaultProps = {
  onChange: null,
  options: [],
};

export default function SettingsWidget({ onChange, data, options }) {
  if (_isEmpty(options)) {
    return null;
  }
  return (
    <div className="settings-widget">
      <h2>
        <span>Settings</span>
      </h2>
      <Table>
        <Table.Body>
          {options.map(({ text, name, description }) => (
            <Table.Row key={name}>
              <Table.Cell>
                <>
                  {text}{' '}
                  <HelpTooltip title={text} position="right center" description={description} />
                </>
              </Table.Cell>

              <Table.Cell textAlign="right">
                <Checkbox name={name} checked={data[name]} onChange={onChange} toggle />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

SettingsWidget.propTypes = propTypes;
SettingsWidget.defaultProps = defaultProps;
