import React from 'react';

import PropTypes from 'prop-types';
import { Table, Checkbox } from 'semantic-ui-react';

import { FACEBOOK_FIELDS } from './FacebookIntegrationSettings.constants';
import { FacebookSettingField } from './FacebookIntegrationSettings.proptypes';

import './FacebookIntegrationSettings.scss';

const propTypes = {
  fields: PropTypes.arrayOf(FacebookSettingField),
  checkedFields: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

const defaultProps = {
  fields: FACEBOOK_FIELDS,
  checkedFields: [],
  onChange: null,
};

export default function FacebookIntegrationSettings({ fields, onChange, checkedFields }) {
  return (
    <Table className="facebook-integration-settings-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Field</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Sync</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {fields.map(({ text, value }) => (
          <Table.Row key={text}>
            <Table.Cell className="field-name-table-cell">{text}</Table.Cell>
            <Table.Cell textAlign="right">
              <Checkbox
                name={value}
                onChange={onChange}
                toggle
                checked={checkedFields.includes(value)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

FacebookIntegrationSettings.propTypes = propTypes;
FacebookIntegrationSettings.defaultProps = defaultProps;
