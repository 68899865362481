import React from 'react';

import { Header } from 'semantic-ui-react';

import { menuItems } from './SettingsContainer.constants';
import SideMenuNavigation from '../../common/SideMenuNavigation';

import './SettingsContainer.scss';

export default function SettingsContainer() {
  return (
    <div className="settings-container-ui">
      <Header as="h1" className="ecomm-section-header">
        Settings
      </Header>
      <SideMenuNavigation menuItems={menuItems} />
    </div>
  );
}
