import React, { useState } from 'react';

import { Message, Popup } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { DISABLED_TOGGLE_TOOLTIP_MESSAGE, TOGGLE_LABEL } from './ReviewSubmissionToggle.constants';
import { getCustomerReviewComponent } from './ReviewSubmissionToggle.utils';
import Toggle from './components/Toggle/Toggle';
import API from '../../../../../libs/api';
import ErrorHandler from '../../../../../libs/errors';
import useAsyncEffect from '../../../../modules/foundation/hooks/use-async-effect';

function ReviewSubmissionToggle() {
  const [reviewsAllowed, setReviewsAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateError, setUpdateError] = useState(false);

  const websiteComponents = useSelector((state) => state.website.layouts.value[0].components);
  const websiteId = useSelector((state) => state.website.core.value.id);
  const customerReviewComponent = getCustomerReviewComponent(websiteComponents);

  const errorMessage = (
    <Message negative onDismiss={() => setUpdateError(false)}>
      <Message.Header>Could not update reviews!</Message.Header>
      <p>There was an error when trying to update your preferences.</p>
    </Message>
  );

  useAsyncEffect(async () => {
    if (customerReviewComponent) {
      try {
        const { data } = await API.getComponentConfiguration(websiteId, customerReviewComponent.id);
        setReviewsAllowed(data.enabled);
      } catch (e) {
        ErrorHandler.capture(e);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  async function toggleHandler(checked) {
    if (customerReviewComponent) {
      try {
        setLoading(true);
        const { data } = await API.updateComponentConfiguration(
          websiteId,
          customerReviewComponent.id,
          {
            enabled: checked,
          },
        );

        setReviewsAllowed(data.enabled);
      } catch (e) {
        setUpdateError(true);
        ErrorHandler.capture(e);
      } finally {
        setLoading(false);
      }
    }
  }

  if (customerReviewComponent && !loading) {
    return (
      <>
        {updateError && errorMessage}
        <Toggle checked={reviewsAllowed} toggleLabel={TOGGLE_LABEL} onToggle={toggleHandler} />
      </>
    );
  }
  return (
    <Popup
      className="disabledPopup"
      position="top right"
      content={!customerReviewComponent ? DISABLED_TOGGLE_TOOLTIP_MESSAGE : 'Loading'}
      trigger={<Toggle disabled toggleLabel={TOGGLE_LABEL} />}
    />
  );
}

export default ReviewSubmissionToggle;
