import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';

import { validateRecipeForm } from './RecipeMetaEditModal.utils';
import API from '../../../../../libs/api';
import { diff } from '../../../../../libs/objects';
import { transformObjectKeyToSnakeCase } from '../../../../../libs/strings';
import CloseableModal from '../../../../common/CloseableModal';
import RecipeProptypes from '../../Recipe.propTypes';
import {
  MESSAGE_TIMEOUT_SECONDS,
  RECIPE_FIELDS_DEFAULT_SHAPE,
} from '../../RecipeManagerPage/RecipeManager.constants';
import RecipeMetaEditForm from '../form/RecipeMetaEditForm';

const propTypes = {
  initialValues: RecipeProptypes.isRequired,
  header: PropTypes.string.isRequired,
  trigger: PropTypes.element.isRequired,
  onEditSuccess: PropTypes.func.isRequired,
  onEditError: PropTypes.func.isRequired,
};

const RecipeMetaEditModal = ({ header, trigger, initialValues, onEditSuccess, onEditError }) => {
  const [fields, setFields] = useState(RECIPE_FIELDS_DEFAULT_SHAPE);
  const [fieldErrors, setFieldErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [modalEditOpened, setModalEditOpened] = useState(false);

  function setInitialFormValues() {
    if (!initialValues) return;
    const { name, recipeVersion, schemaVersion, tags } = initialValues;
    setFields({
      name,
      schemaVersion,
      recipeVersion,
      tags,
    });
  }

  function clearFieldErrors() {
    if (!_isEmpty(fieldErrors)) setTimeout(() => setFieldErrors({}), MESSAGE_TIMEOUT_SECONDS);
  }

  useEffect(() => {
    if (initialValues) setInitialFormValues();
  }, [initialValues]);

  useEffect(() => {
    clearFieldErrors();
  }, [fieldErrors]);

  function resetFormValues() {
    setFields(RECIPE_FIELDS_DEFAULT_SHAPE);
    setFieldErrors({});
  }

  function handleCancel() {
    resetFormValues();
    setModalEditOpened(false);
  }

  function handleInputChange(name, value) {
    const { [name]: tmp, ...rest } = fields;
    setFields({
      [name]: value,
      ...rest,
    });
  }

  async function saveRecipeMeta(updatedRecipeMeta) {
    setIsSaving(true);
    try {
      const payload = transformObjectKeyToSnakeCase(updatedRecipeMeta);
      const { status } = await API.updateRecipeMeta(initialValues.id, payload);
      if (status === 200) {
        onEditSuccess();
      }
    } catch (error) {
      const err = _get(error, 'response.statusText') || error;
      onEditError(err);
    } finally {
      setIsSaving(false);
      setModalEditOpened(false);
    }
  }

  function handleSaveMeta() {
    const errors = validateRecipeForm(fields);
    if (_keys(errors).length) {
      setFieldErrors(errors);
      return;
    }
    const updatedValues = diff(initialValues, fields);
    saveRecipeMeta(updatedValues);
  }

  function onModalOpen() {
    setModalEditOpened(true);
    setInitialFormValues();
  }

  return (
    <CloseableModal
      open={modalEditOpened}
      onOpen={onModalOpen}
      size="small"
      onClose={handleCancel}
      trigger={trigger}
      dimmer="inverted"
      header={header}
    >
      <Modal.Content>
        <RecipeMetaEditForm
          onChange={handleInputChange}
          fieldErrors={fieldErrors}
          initialValues={fields}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handleSaveMeta} loading={isSaving} disabled={isSaving}>
          Save
        </Button>
      </Modal.Actions>
    </CloseableModal>
  );
};

export default RecipeMetaEditModal;

RecipeMetaEditModal.propTypes = propTypes;
