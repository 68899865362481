import React from 'react';

import { Grid } from 'semantic-ui-react';

import { getOrderReportsDownloadUrl } from './OrderReportsHeader.utils';
import OrderReportsFilter from './components/OrderReportsFilter';
import OrderReportsDownload from '../../../../common/ReportsDownload/ReportsDownload';

function OrderReportsHeader({ filters, onChangeFilter }) {
  return (
    <Grid.Row verticalAlign="middle">
      <OrderReportsFilter filters={filters} onChangeFilter={onChangeFilter} />
      <OrderReportsDownload downloadUrl={getOrderReportsDownloadUrl(filters)} />
    </Grid.Row>
  );
}

export default OrderReportsHeader;
