import { useEffect, useState } from 'react';

import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { ASCENDING, DESCENDING, FIRST_PAGE } from './use-server-table-controls.constants';
import useDebounce from '../../../../../hooks/useDebounce';
import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors/errors';
import { multiCaseGet } from '../../../../../libs/format';

export default function useServerTableControls(fetchFunction, dependencies = []) {
  const [data, setData] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
  const [totalPages, setTotalPages] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [previousSearchValue, setPreviousSearchValue] = useState('');
  const [sortedColumn, setSortedColumn] = useState('');
  const [sortDirection, setSortDirection] = useState(ASCENDING);

  async function fetch() {
    try {
      setLoading(true);
      const resetToFirstPage = previousSearchValue !== debouncedSearchValue;

      const response = await fetchFunction({
        searchValue: debouncedSearchValue,
        sortedColumn,
        sortDirection,
        currentPage: resetToFirstPage ? FIRST_PAGE : currentPage,
      });

      if (_isNil(response)) {
        return;
      }

      const results = _get(response, 'results');
      const resultTotalPages = multiCaseGet(response, 'totalPages');

      if (results && resultTotalPages) {
        setData(results || []);
        setTotalPages(resultTotalPages);
        setErrorMessage('');

        if (resetToFirstPage) {
          setCurrentPage(FIRST_PAGE);
        }
      }
      setPreviousSearchValue(debouncedSearchValue);
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [...(dependencies || []), currentPage, sortedColumn, sortDirection, debouncedSearchValue]);

  function reset() {
    setCurrentPage(FIRST_PAGE);
    setTotalPages(0);
    setData([]);
    setLoading(false);
    setErrorMessage('');
    setSearchValue('');
    setSortedColumn('');
    setSortDirection(ASCENDING);
  }

  function onSort(newColumn) {
    if (sortedColumn !== newColumn) {
      setSortedColumn(newColumn);
      setSortDirection(ASCENDING);
    } else {
      const newDirection = sortDirection === ASCENDING ? DESCENDING : ASCENDING;
      setSortDirection(newDirection);
    }
  }

  return {
    fetchData: fetch,
    data,
    setData,
    errorMessage,
    loading,
    searchValue,
    setCurrentPage,
    currentPage,
    totalPages,
    sortedColumn,
    sortDirection,
    setSearchValue,
    onSort,
    reset,
  };
}
