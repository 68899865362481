import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import Markdown from 'markdown-to-jsx';
import { SortableHandle } from 'react-sortable-hoc';

import Dots from '../../../../../../../../common/sortables/Dots';
import { TeamPropType } from '../../../../../types/team';
import EditTeamButton from '../../../EditTeamButton';

import './TeamsTableRow.scss';

const propTypes = {
  team: TeamPropType.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

const DragHandle = SortableHandle(({ disabled }) => <Dots disabled={disabled} />);

export default function TeamsTableRow({ team, loading }) {
  const { name = '', description = '' } = team || {};

  return (
    <Table.Row className="teams-table-row">
      <Table.Cell>
        <div className="drag-handle-container">
          <DragHandle disabled={loading} />
          <span>{name}</span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <Markdown className="description markdown">{description}</Markdown>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <EditTeamButton team={team} disabled={loading} />
      </Table.Cell>
    </Table.Row>
  );
}

TeamsTableRow.propTypes = propTypes;
TeamsTableRow.defaultProps = defaultProps;
