import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Message, Loader } from 'semantic-ui-react';

import _debounce from 'lodash/debounce';
import _isObject from 'lodash/isObject';
import AceEditor from 'react-ace';

import { ObjectPropType } from '../../../../../../libs/proptypes';
import CloseableModal from '../../../../../common/CloseableModal';
import { editorUpdate } from '../../../../recipes-editor-v2/components/RecipeEditor/components/Editor/components/AceEditorWrapper/AceEditorWrapper.utils';

import './PartialJsonEditor.scss';

const propTypes = {
  open: PropTypes.bool,
  isSaving: PropTypes.bool,
  trigger: PropTypes.element,
  mode: PropTypes.string,
  isLoading: PropTypes.bool,
  partialInitialValue: ObjectPropType,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

const defaultProps = {
  open: undefined,
  isSaving: false,
  trigger: null,
  mode: 'json',
  isLoading: false,
  partialInitialValue: null,
};

const PartialJsonEditor = ({
  open,
  isSaving,
  trigger,
  onCancel,
  onSave,
  mode,
  isLoading,
  partialInitialValue,
}) => {
  const [partialState, setPartialState] = useState({
    value: {},
    parsingError: false,
  });

  useEffect(() => {
    setPartialState({ value: partialInitialValue, parsingError: false });
  }, [partialInitialValue]);

  async function handleEditorUpdate(updatedValue) {
    const obj = await editorUpdate(mode, updatedValue);

    setPartialState({
      value: obj.value,
      parsingError: obj.parseError,
    });
  }

  function getEditorValue() {
    const { value, parsingError } = partialState;
    const editorValue = {
      json: () => (parsingError ? value : JSON.stringify(value, null, 2)),
      css: () => (_isObject(value) ? '' : value),
    };
    return editorValue[mode]();
  }

  const handleCancel = () => {
    setPartialState({ value: {}, parsingError: false });
    onCancel();
  };

  const { value, parsingError } = partialState;

  return (
    <CloseableModal
      className="developer-modal raw-json-edit"
      onClose={handleCancel}
      open={open}
      size="small"
      trigger={trigger}
      dimmer="inverted"
      header={`${mode} Editor`}
    >
      <Modal.Content>
        <AceEditor
          placeholder="Code Editor"
          mode={mode}
          theme="solarized_light"
          name="Edit Raw JSON"
          onChange={_debounce(handleEditorUpdate, 1000)}
          fontSize={14}
          showPrintMargin
          showGutter
          highlightActiveLine
          style={{ width: '100%', height: '100%' }}
          value={getEditorValue()}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
        {isLoading && <Loader />}
      </Modal.Content>
      <Modal.Actions>
        {parsingError && (
          <Message
            error
            header="Parsing Error"
            list={[
              'Make sure syntax is correct',
              'Remove any trailing commas',
              'All keys and strings must be double quotes',
            ]}
          />
        )}
        <Button
          loading={isSaving}
          primary
          onClick={() => {
            if (!parsingError) {
              onSave(value);
            }
          }}
        >
          Save
        </Button>
      </Modal.Actions>
    </CloseableModal>
  );
};

export default PartialJsonEditor;

PartialJsonEditor.propTypes = propTypes;
PartialJsonEditor.defaultProps = defaultProps;
