export const toastMenuVisibilityChannels = [
  { key: 'kiosk', value: 'Kiosk' },
  { key: 'ordering_partners', value: 'Ordering Partners' },
  { key: 'pos', value: 'Point of Sale' },
  { key: 'toast_online_ordering', value: 'Toast Online Ordering' },
];

export const defaultToastMenuVisibilityChannels = {
  kiosk: true,
  ordering_partners: true,
  pos: true,
  toast_online_ordering: true,
};
