import PropTypes from 'prop-types';

import { WebsiteComponentInteractionConfigurationPropType } from '../../website-component-interaction/proptypes';

export const ComponentConfigurationPropType = {
  componentConfigurationId: PropTypes.number,
  componentName: PropTypes.string, // same as fastId
  componentIdentifier: PropTypes.string,
  order: PropTypes.number,
};

export const TileComponentConfigurationPropType = PropTypes.shape({
  ...ComponentConfigurationPropType, // DynamicTile.Tile,
  data: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // from 1 to 16
    overlayClassName: PropTypes.string,
    className: PropTypes.string,
    showOnHover: PropTypes.bool,
  }),
});

export const TileImagePropType = PropTypes.shape({
  businessFileConfigurationId: PropTypes.number,
  businessFileId: PropTypes.number,
  url: PropTypes.string,
  order: PropTypes.number,
});

export const TileContentPropType = PropTypes.shape({
  contentNodeConfigurationId: PropTypes.number,
  contentNodeId: PropTypes.number,
  contentName: PropTypes.string,
  content: PropTypes.string,
  contentType: PropTypes.string,
});

export const TilePropType = PropTypes.shape({
  componentConfiguration: TileComponentConfigurationPropType,

  images: PropTypes.arrayOf(TileImagePropType),

  contentNodes: PropTypes.arrayOf(TileContentPropType),

  interactions: PropTypes.arrayOf(WebsiteComponentInteractionConfigurationPropType),
});
