import { useState, useEffect } from 'react';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  createDynamicTilesIfNotExists,
  createOrUpdateDynamicTilesTile,
  deleteCustomHero,
} from './services/api';
import {
  createCustomHero,
  setNumberOfTiles,
  setNewTileWidth,
  getComponentConfigurationIdsToRemove,
} from './services/hero';
import { setNewInteractions } from './services/interactions';
import {
  setImage as setNewImage,
  setClassName as setNewClassName,
  setTileContent as setNewTileContent,
  setShowOnHover as setNewShowOnHover,
  setSubheader as setNewSubheader,
  setImages as setNewImages,
} from './services/tile';
import { setItemInList } from './services/utils';

export default function useCustomHeroManager(pageProp, initialCustomHero) {
  const [previousCustomHero, setPreviousCustomHero] = useState(_cloneDeep(initialCustomHero || {}));
  const [customHero, setCustomHero] = useState(createCustomHero(pageProp, initialCustomHero));
  const [activeTileIndex, setActiveTileIndex] = useState(0);

  function resetToDefault() {
    setCustomHero(createCustomHero(pageProp, initialCustomHero));
  }

  function resetToEmpty() {
    setActiveTileIndex(0);
    setPreviousCustomHero(createCustomHero(pageProp, {}));
    setCustomHero(createCustomHero(pageProp, {}));
  }

  useEffect(() => {
    resetToDefault();
    setPreviousCustomHero(_cloneDeep(initialCustomHero));
  }, [initialCustomHero]);

  function setLayout({ numberOfTiles, tilesPerRow } = {}) {
    setCustomHero((prevCustomHero) => {
      const [newTiles, newTilesPerRow] = setNumberOfTiles(prevCustomHero, {
        numberOfTiles,
        tilesPerRow,
      });
      if (activeTileIndex > numberOfTiles) {
        setActiveTileIndex(numberOfTiles - 1);
      }
      return { ...prevCustomHero, tiles: newTiles, tilesPerRow: newTilesPerRow };
    });
  }

  function setTileWidth({ tileIndex }, { width } = {}) {
    setCustomHero((prevCustomHero) => {
      const newTiles = setNewTileWidth(prevCustomHero, { tileIndex, width });
      return { ...prevCustomHero, tiles: newTiles };
    });
  }

  function updateTile({ tileIndex }, updateFunction) {
    setCustomHero((prevCustomHero) => {
      const { tiles = [] } = prevCustomHero;
      const tile = tiles[tileIndex];
      if (_isEmpty(tile)) {
        throw new Error('Tile not found');
      }
      const newTile = updateFunction(tile);
      return { ...prevCustomHero, tiles: setItemInList(tiles, tileIndex, newTile) };
    });
  }

  function setShowOnHover({ tileIndex }, { showOnHover } = {}) {
    updateTile({ tileIndex }, (tile) => setNewShowOnHover(tile, { showOnHover }));
  }

  function setClassName({ tileIndex }, { className } = {}) {
    updateTile({ tileIndex }, (tile) => setNewClassName(tile, { className }));
  }

  function setTileContent({ tileIndex }, newContent = {}) {
    updateTile({ tileIndex }, (tile) => setNewTileContent(tile, newContent));
  }

  function setSubheader({ tileIndex }, newSubHeader = {}) {
    updateTile({ tileIndex }, (tile) => setNewSubheader(tile, newSubHeader));
  }

  function setImage({ tileIndex }, image = {}) {
    updateTile({ tileIndex }, (tile) => setNewImage(tile, image));
  }

  function setImages({ tileIndex }, newImages = []) {
    updateTile({ tileIndex }, (tile) => setNewImages(tile, newImages));
  }

  function setWebsiteInteractions({ tileIndex }, newWebsiteInteractions) {
    updateTile({ tileIndex }, (tile) => setNewInteractions(tile, newWebsiteInteractions));
  }

  async function saveCustomHero() {
    const { tilesToRemove, customHeroToRemove } = getComponentConfigurationIdsToRemove(
      previousCustomHero,
      customHero,
    );

    const { page = {}, tiles = [] } = customHero || {};
    const { id: pageId } = page;

    const parentComponentConfigurationId = await createDynamicTilesIfNotExists(customHero);
    await createOrUpdateDynamicTilesTile(pageId, parentComponentConfigurationId, tiles);

    await deleteCustomHero(customHeroToRemove, tilesToRemove);
  }

  async function removeCustomHero() {
    const customHeroIdToRemove = _get(
      customHero,
      'componentConfiguration.componentConfigurationId',
    );

    await deleteCustomHero(customHeroIdToRemove);
    resetToEmpty();
  }

  return {
    customHero,
    setCustomHero,
    resetToDefault,
    saveCustomHero,
    removeCustomHero,
    setLayout,
    setTileWidth,
    setShowOnHover,
    setTileContent,
    setSubheader,
    setClassName,
    setImage,
    setImages,
    setWebsiteInteractions,
    activeTileIndex,
    setActiveTileIndex,
  };
}
