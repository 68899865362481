import React from 'react';

import PropTypes from 'prop-types';

import InputFormGroup from '../../../../../../common/InputFormGroup';
import {
  FORM_DESCRIPTIONS,
  FORM_INVALID_FIELDS,
  FORM_PLACEHOLDERS,
  FORM_TITLES,
} from '../CustomerEditModal.constants';

const propTypes = {
  keyName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.bool,
  customInput: PropTypes.node,
};

const defaultProps = {
  value: '',
  name: null,
  onChange: null,
  error: false,
  customInput: null,
};

export default function CustomerEditFormGroup({
  keyName,
  value,
  onChange,
  name,
  error,
  customInput,
}) {
  const helpMessage = FORM_DESCRIPTIONS[keyName];
  const title = FORM_TITLES[keyName];
  const placeholder = FORM_PLACEHOLDERS[keyName];
  const errorMessage = error ? FORM_INVALID_FIELDS[keyName] : '';

  return (
    <InputFormGroup
      title={title}
      name={name}
      onChange={onChange}
      value={value}
      customInput={customInput}
      errorMessage={errorMessage}
      placeholder={placeholder}
      helpMessage={helpMessage}
    />
  );
}

CustomerEditFormGroup.propTypes = propTypes;
CustomerEditFormGroup.defaultProps = defaultProps;
