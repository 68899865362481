import React from 'react';

import PropTypes from 'prop-types';
import { Select } from 'semantic-ui-react';

import _find from 'lodash/find';
import _isNumber from 'lodash/isNumber';
import { useDispatch, useSelector } from 'react-redux';

import { LOCATION_SELECT_PLACEHOLDER, ALL_LOCATIONS_VALUE } from './LocationSelect.constants';
import { getLocationOptions } from './LocationSelect.utils';
import { setActiveLocation } from '../../../../../actions/ecommerce';
import { LocationPropType } from '../../../../../libs/proptypes';
import { selectActiveLocation, selectBusinessLocations } from '../../../../../selectors';

import './LocationSelect.scss';

const propTypes = {
  locations: PropTypes.arrayOf(LocationPropType),
  activeLocation: LocationPropType,
  onChangeLocation: PropTypes.func,
  global: PropTypes.bool,
  hideAllLocationsOption: PropTypes.bool,
};

const defaultProps = {
  locations: [],
  activeLocation: null,
  onChangeLocation: () => null,
  global: true,
  hideAllLocationsOption: false,
};

const LocationSelect = ({
  activeLocation,
  locations,
  onChangeLocation,
  global,
  hideAllLocationsOption,
}) => {
  const dispatch = useDispatch();
  const globalLocations = useSelector(selectBusinessLocations);
  const globalActiveLocation = useSelector(selectActiveLocation);
  const finalLocations = global ? globalLocations : locations;
  const finalActiveLocation = global ? globalActiveLocation : activeLocation;
  const onChangeHandler = global
    ? (payload) => dispatch(setActiveLocation(payload))
    : onChangeLocation;
  const locationOptions = getLocationOptions(finalLocations, { hideAllLocationsOption });

  function onChangeLocationWrapper(e, { value: targetLocationId }) {
    const location = _find(finalLocations, ({ id }) => id === targetLocationId);
    onChangeHandler(location);
  }

  function isAllLocations() {
    if (_isNumber(finalActiveLocation)) {
      return false;
    }
    return Object.keys(finalActiveLocation).length === 0;
  }

  function getValue() {
    if (isAllLocations()) {
      return ALL_LOCATIONS_VALUE;
    }
    if (_isNumber(finalActiveLocation)) {
      return finalActiveLocation;
    }
    return finalActiveLocation.id;
  }

  return (
    <Select
      placeholder={LOCATION_SELECT_PLACEHOLDER}
      value={getValue()}
      onChange={onChangeLocationWrapper}
      options={locationOptions}
      className="location-select"
    />
  );
};

LocationSelect.propTypes = propTypes;
LocationSelect.defaultProps = defaultProps;

export default LocationSelect;
