import React from 'react';

import { Header } from 'semantic-ui-react';

import StripeConnectOnboarding from '../../../../core/Integrations/Stripe/StripeConnectOnboarding';

import './PaymentSettings.scss';

export default function PaymentSettings() {
  return (
    <div className="e-commerce-payment-settings">
      <Header>Payment Settings</Header>
      <StripeConnectOnboarding />
    </div>
  );
}
