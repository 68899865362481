import React from 'react';

import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

const propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.string,
  isError: PropTypes.bool,
  onDismiss: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
};

const defaultProps = {
  content: '',
  isError: false,
  onDismiss: null,
  className: '',
  icon: null,
};

export default function IntegrationMessage({
  header,
  content,
  isError,
  onDismiss,
  className,
  icon,
}) {
  if (_isEmpty(header)) {
    return null;
  }

  return (
    <Message
      negative={isError}
      onDismiss={onDismiss}
      className={className}
      icon={icon}
      header={header}
      content={content}
    />
  );
}

IntegrationMessage.propTypes = propTypes;
IntegrationMessage.defaultProps = defaultProps;
