import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { wrapActionChangeHandlers } from './Actions.utils';
import { isAdminUser } from '../../../../../../../libs/auth';
import { selectUser } from '../../../../../../../selectors/user';
import { ActionsWidgetSectionsType } from '../../../../types/actions';
import ActionsWidget from '../../../ActionsWidget';
import { ActionsInputContext } from '../../ActionsInput.context';

const propTypes = {
  actionSections: ActionsWidgetSectionsType.isRequired,
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  adminOnly: PropTypes.bool,
};

const defaultProps = {
  adminOnly: false,
};

export default function Actions({ actionSections, trigger, adminOnly }) {
  const user = useSelector(selectUser);
  const isAdmin = isAdminUser(user);

  const { input } = useContext(ActionsInputContext);

  if (adminOnly && !isAdmin) {
    return null;
  }

  const wrappedActionSections = wrapActionChangeHandlers(actionSections, input);

  return <ActionsWidget actionSections={wrappedActionSections} trigger={trigger} />;
}

Actions.propTypes = propTypes;
Actions.defaultProps = defaultProps;
