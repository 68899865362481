import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import { Button, Divider, Header, Icon, Modal } from 'semantic-ui-react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import {
  STAGE_INITIAL,
  STAGE_CUSTOM_PAGE,
  STAGE_SELECT_TEMPLATE,
} from './NewCustomPageModal.constants';
import { getGeneratedClassName } from './NewCustomPageModal.utils';
import CustomPageTemplateForm from './components/CustomPageTemplateForm';
import { getErrorMessage } from '../../../../../libs/errors';
import CloseableModal from '../../../../common/CloseableModal';
import DismissibleMessage from '../../../utils/components/DismissibleMessage';
import { CustomPagesContext } from '../../contexts/CustomPages.context';
import useCustomPageForm from '../../hooks/use-custom-page-form';
import useCustomPages from '../../hooks/use-custom-pages/useCustomPages';
import CustomPageConfigForm from '../CustomPageConfigForm';
import CustomPageTemplateList from '../CustomPageTemplateList';
import { STATUS_DRAFT } from '../ItemStatusDropdown/ItemStatusDropdown.constants';

import './NewCustomPageModal.scss';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  trigger: PropTypes.node,
};

const defaultProps = {
  trigger: null,
};

function NewCustomPageModal({ open, onClose, trigger }) {
  const [stage, setStage] = useState(STAGE_INITIAL);
  const [loadingCustomPage, setLoadingCustomPage] = useState(false);
  const [error, setError] = useState(null);
  const { setActivePageId, createPage } = useContext(CustomPagesContext);
  const { customPageData, setCustomPageField, resetCustomPageForm } = useCustomPageForm();

  const { createOrUpdateCustomPage } = useCustomPages();

  function handleClose() {
    resetCustomPageForm();
    setStage(STAGE_INITIAL);
    onClose();
  }

  function goBack() {
    setStage(STAGE_INITIAL);
    resetCustomPageForm();
  }

  async function onSelectedTemplate(template) {
    setCustomPageField('node', template.node);
  }

  async function onSubmitFromTemplate() {
    setLoadingCustomPage(true);
    const { title, node } = customPageData;
    const page = { title };

    let customPage = null;
    try {
      customPage = await createOrUpdateCustomPage(page, { node }, { status: STATUS_DRAFT });
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      if (customPage) {
        setActivePageId(customPage.id);
        handleClose();
      }
      setLoadingCustomPage(false);
    }
  }

  async function onSubmitCustomWebsiteNode() {
    setLoadingCustomPage(true);
    const {
      title,
      numCols,
      numRows,
      paddingCols,
      paddingRows,
      status = STATUS_DRAFT,
      style,
      className,
    } = customPageData;
    const page = { title };
    const rows = { count: numRows, style: { padding: paddingRows } };
    const cols = { count: numCols, style: { padding: paddingCols } };
    const finalClassName = _isEmpty(className) ? getGeneratedClassName(title) : className;
    const defaultNode = createPage(rows, cols, page, style, finalClassName);
    let customPage = null;
    try {
      customPage = await createOrUpdateCustomPage(page, { node: defaultNode }, { status });
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      if (customPage) {
        setActivePageId(customPage.id);
        handleClose();
      }
      setLoadingCustomPage(false);
    }
  }

  function onSubmit() {
    if (stage === STAGE_CUSTOM_PAGE) {
      onSubmitCustomWebsiteNode();
    }
    if (stage === STAGE_SELECT_TEMPLATE) {
      onSubmitFromTemplate();
    }
  }

  function getModalTitle() {
    if (stage === STAGE_CUSTOM_PAGE) {
      return 'Start with blank canvas';
    }
    if (stage === STAGE_SELECT_TEMPLATE) {
      return 'Use a page template';
    }
    return 'New Custom Page';
  }

  return (
    <CloseableModal
      className="new-custom-page-modal"
      open={open}
      onClose={handleClose}
      header={getModalTitle()}
      trigger={trigger}
      size="tiny"
    >
      <Modal.Content className={clsx({ fluid: stage === STAGE_SELECT_TEMPLATE })}>
        {stage === STAGE_INITIAL && (
          <>
            <p>How would you like to start this custom page?</p>
            <div className="select-buttons">
              <Button
                onClick={() => {
                  setStage(STAGE_CUSTOM_PAGE);
                }}
              >
                <Icon name="hashtag" />
                Start with a blank canvas
              </Button>
              <Button
                onClick={() => {
                  setStage(STAGE_SELECT_TEMPLATE);
                }}
              >
                <Icon name="clone outline" />
                Use a page template
              </Button>
            </div>
          </>
        )}
        {stage === STAGE_SELECT_TEMPLATE && (
          <>
            <CustomPageTemplateForm
              className="template-page-title"
              onUpdate={setCustomPageField}
              {...customPageData}
            />
            <Divider horizontal>
              <Header as="h4">
                <Icon name="clone outline" />
                Template
              </Header>
            </Divider>
            <CustomPageTemplateList search onSelectTemplate={onSelectedTemplate} />
          </>
        )}
        {stage === STAGE_CUSTOM_PAGE && (
          <CustomPageConfigForm
            loading={loadingCustomPage}
            onUpdate={setCustomPageField}
            disableStatus
            {...customPageData}
          />
        )}
      </Modal.Content>
      <Modal.Actions className={clsx({ hidden: stage === STAGE_INITIAL })}>
        {error && <DismissibleMessage initialVisible error content={error} />}
        {stage !== STAGE_INITIAL && (
          <div className="modal-action-buttons">
            <Button basic onClick={goBack} className="back">
              Back
            </Button>
            <Button basic loading={loadingCustomPage} onClick={onSubmit} className="save">
              Save
            </Button>
          </div>
        )}
      </Modal.Actions>
    </CloseableModal>
  );
}

NewCustomPageModal.propTypes = propTypes;
NewCustomPageModal.defaultProps = defaultProps;

export default NewCustomPageModal;
