import React, { useState } from 'react';

import { Icon, Table, Form, Button } from 'semantic-ui-react';

import _toNumber from 'lodash/toNumber';

import { CONFIRMATION_DELETE_MESSAGE } from './TaxForm.constants';
import ConfirmModal from '../../../../../../common/ConfirmModal';
import PercentField from '../../../../../../fields/PercentField';

function TaxForm({ tax, onChange, onRemove }) {
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] = useState(false);

  function handlePercentFieldChange(e, { name, value }) {
    const newValue = (_toNumber(value) / 100).toFixed(6);
    onChange(tax, name, newValue);
  }

  function removeTaxItemHandler() {
    setIsConfirmDeleteModalOpened(false);
    onRemove(tax);
  }

  return (
    <Table.Row className="tax-form">
      <Table.Cell>
        <Form.Input
          fluid
          name="name"
          value={tax.tax_data.name}
          onChange={(e, { name, value }) => onChange(tax, name, value)}
        />
      </Table.Cell>
      <Table.Cell>
        <PercentField
          fluid
          name="rate"
          initialValue={(tax.tax_data.rate * 100).toFixed(4)}
          onChange={handlePercentFieldChange}
          decimalPrecision={4}
        />
      </Table.Cell>
      <Table.Cell>
        <ConfirmModal
          onOpen={() => setIsConfirmDeleteModalOpened(true)}
          open={isConfirmDeleteModalOpened}
          trigger={
            <Button className="remove-button">
              <Icon name="trash alternate outline" size="large" />
            </Button>
          }
          header="Delete Tax"
          content={CONFIRMATION_DELETE_MESSAGE}
          confirmButton={<Button>Delete</Button>}
          onConfirm={() => removeTaxItemHandler(tax)}
          onCancel={() => setIsConfirmDeleteModalOpened(false)}
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default TaxForm;
