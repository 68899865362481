import React from 'react';

import { Checkbox, Form } from 'semantic-ui-react';

import './Toggle.scss';

function Toggle({ checked, toggleLabel, disabled, onToggle, ...rest }) {
  return (
    <Form {...rest}>
      <Form.Field className="review-checkbox">
        <label className="checkbox-label">{toggleLabel}</label>
        {!disabled ? (
          <Checkbox checked={checked} toggle onChange={(e, data) => onToggle(data.checked)} />
        ) : (
          <Checkbox defaultChecked toggle disabled />
        )}
      </Form.Field>
    </Form>
  );
}

export default Toggle;
