import { IMAGE_UPLOAD_MAX_SIZE, MAX_FILENAME_LENGTH } from './SearchBar.constants';

export function getMaxSizeExceededFiles(files = [], { maxFileSize = IMAGE_UPLOAD_MAX_SIZE }) {
  return files.filter((file) => file.size > maxFileSize).map(({ name }) => name);
}

export function getMaxFilenameLengthExceededFiles(
  files = [],
  { maxFilenameLength = MAX_FILENAME_LENGTH },
) {
  return files.filter((file) => file.name.length > maxFilenameLength).map(({ name }) => name);
}
