import { FileManagementModalStep } from '../../FilesManagementModal.constants';

export function getSourceOptions() {
  return [
    {
      key: FileManagementModalStep.LOCAL_IMAGE_UPLOAD,
      value: FileManagementModalStep.LOCAL_IMAGE_UPLOAD,
      text: 'From my computer',
    },
    {
      key: FileManagementModalStep.STOCK_IMAGE_SEARCH,
      value: FileManagementModalStep.STOCK_IMAGE_SEARCH,
      text: 'From stock imagery',
    },
  ];
}
