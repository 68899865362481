import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import FileList from 'components/modules/files/components/FileList';
import FileUploadMode from 'components/modules/files/components/FileUploadMode';
import FileUploadWell from 'components/modules/files/components/FileUploadWell';
import {
  UPLOAD_SELECTION_MODE,
  STOCK_IMAGE_UPLOAD_MODE,
  LOCAL_FILE_UPLOAD_MODE,
  FILE_LIST_MODE,
  EDIT_FILE_MODE,
} from 'components/modules/files/constants/mode';
import { useLocalFiles } from 'components/modules/files/hooks';

import { SOURCE_TYPE_STOCK } from '../../constants/sources';
import { dataUriToFile, downloadImage, fileToBase64Url } from '../../services/files';
import { useImageEditor } from '../FileEditorModal/FileEditorModal.hooks';
import ImageEditor from '../ImageEditor';
import StockImagesPicker from '../StockImagesPicker';

import './FileUploadManager.scss';

const propTypes = {
  onSaveFiles: PropTypes.func,
  onPostUpload: PropTypes.func,
};

const defaultProps = {
  onSaveFiles: null,
  onPostUpload: null,
};

export default function FileUploadManager({ onSaveFiles, onPostUpload }) {
  const imageEditorRef = useRef(null);

  const [displayMode, setDisplayMode] = useState(UPLOAD_SELECTION_MODE);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState('');
  const [isSavingEditFile, setIsSavingEditFile] = useState(false);

  const {
    files,
    loading,
    uploaded,
    uploadLocalFiles,
    deleteLocalFile,
    clearLocalFiles,
    setLocalFile,
    updateFileUploadProgress,
    saveLocalFiles,
  } = useLocalFiles({ allowedTypes: ['image'] });

  const {
    getCurrentImage,
    initialize: initializeImageEditor,
    reset: resetImageEditor,
    getTouched: isImageEditorTouched,
  } = useImageEditor(selectedFileUrl, imageEditorRef);

  useEffect(() => {
    initializeImageEditor();
  }, [selectedFileUrl, imageEditorRef]);

  function onFilesUploaded({ target: { files: newFiles = [] } } = {}) {
    uploadLocalFiles(newFiles);

    setDisplayMode(FILE_LIST_MODE);
  }

  function onViewLocalFiles() {
    setDisplayMode(FILE_LIST_MODE);
  }

  function onAddMoreFiles() {
    setDisplayMode(UPLOAD_SELECTION_MODE);
  }

  function onClearFiles() {
    clearLocalFiles();

    setDisplayMode(UPLOAD_SELECTION_MODE);
  }

  function onViewUploadOptions() {
    setDisplayMode(UPLOAD_SELECTION_MODE);
  }

  async function onEditFileMode(index) {
    const selectedFile = files[index];
    const base64Url = await fileToBase64Url(selectedFile.file);
    setSelectedFileUrl(base64Url);
    setSelectedFileIndex(index);
    setDisplayMode(EDIT_FILE_MODE);
  }

  function onGoBackFromEditFileMode() {
    resetImageEditor();
    setSelectedFileUrl('');
    setSelectedFileIndex(null);
    setDisplayMode(FILE_LIST_MODE);
  }

  async function onSaveEditFile() {
    setIsSavingEditFile(true);
    const imageAsDataUrl = await getCurrentImage();
    if (await isImageEditorTouched(imageAsDataUrl)) {
      const selectedFile = files[selectedFileIndex];
      const newFile = dataUriToFile(imageAsDataUrl, selectedFile.name);
      setLocalFile(selectedFileIndex, newFile);
    }
    setIsSavingEditFile(false);
    onGoBackFromEditFileMode();
  }

  async function onStockFilesUploaded(urls) {
    let stockFiles = await Promise.all(urls.map((url) => downloadImage(url)));
    stockFiles = stockFiles.map((file) => ({ file, source: SOURCE_TYPE_STOCK }));
    const event = { target: { files: stockFiles } };

    onFilesUploaded(event, event.target);
  }

  async function onSaveFilesWrapper() {
    const saveHandler = onSaveFiles || saveLocalFiles;

    await saveHandler({ files, updateFileUploadProgress });
  }

  let content = (
    <>
      <FileList
        files={files}
        onDeleteFile={deleteLocalFile}
        onClearFiles={clearLocalFiles}
        onSaveFiles={saveLocalFiles}
        onEditFile={onEditFileMode}
      />
      <div className="file-actions">
        <Button
          onClick={onClearFiles}
          icon="trash"
          disabled={loading || _isEmpty(files)}
          content="Clear Images"
          className="delete-red"
        />
        <Button
          onClick={onAddMoreFiles}
          className="action-button-gray"
          icon="add"
          disabled={loading}
          content="Add More Images"
        />
        <Button
          className="secondary-navy"
          icon={uploaded ? false : 'upload'}
          content={uploaded ? 'Ok' : 'Upload Images'}
          onClick={uploaded ? onPostUpload : onSaveFilesWrapper}
          disabled={loading || _isEmpty(files)}
          loading={loading}
        />
      </div>
    </>
  );

  if (displayMode === UPLOAD_SELECTION_MODE) {
    content = (
      <>
        <FileUploadMode onSelectMode={setDisplayMode} />
        {!_isEmpty(files) && (
          <Button basic onClick={onViewLocalFiles} size="large">
            View Uploaded Files
          </Button>
        )}
      </>
    );
  }

  if (displayMode === LOCAL_FILE_UPLOAD_MODE) {
    content = (
      <>
        <Button
          onClick={onViewUploadOptions}
          labelPosition="left"
          icon="left chevron"
          content="Upload Options"
          basic
          className="upload-options-button"
        />
        <FileUploadWell onUploadFiles={onFilesUploaded} />
      </>
    );
  }

  if (displayMode === STOCK_IMAGE_UPLOAD_MODE) {
    content = (
      <>
        <Button
          onClick={onViewUploadOptions}
          labelPosition="left"
          icon="left chevron"
          content="Upload Options"
          basic
          className="upload-options-button"
        />
        <StockImagesPicker
          onSave={onStockFilesUploaded}
          saveButtonProps={{ content: 'Continue' }}
        />
      </>
    );
  }

  if (displayMode === EDIT_FILE_MODE) {
    content = (
      <div className="edit-file-container">
        <ImageEditor ref={imageEditorRef} imageUrl={selectedFileUrl} />
        <div className="actions">
          <Button
            onClick={onGoBackFromEditFileMode}
            labelPosition="left"
            icon="left chevron"
            content="Go Back"
            basic
            className="view-local-files-button"
            loading={isSavingEditFile}
            disabled={isSavingEditFile}
          />
          <Button
            onClick={onSaveEditFile}
            primary
            content="Save"
            loading={isSavingEditFile}
            disabled={isSavingEditFile}
          />
        </div>
      </div>
    );
  }

  return <div className="file-upload-manager">{content}</div>;
}

FileUploadManager.propTypes = propTypes;
FileUploadManager.defaultProps = defaultProps;
