import _isEmpty from 'lodash/isEmpty';

import { COMPONENT_NAME_BY_ELEMENT_TYPE } from '../../../../constants/types';

export function translateChildren(children, generatorFunc, typeLookupFunc, { context = {} } = {}) {
  if (_isEmpty(children) || !generatorFunc || !typeLookupFunc) {
    return [];
  }

  return children
    .map((child) => {
      const elementType = typeLookupFunc(child);

      if (!elementType) {
        return null;
      }

      return generatorFunc(elementType, child, { context });
    })
    .filter(Boolean);
}

export function lookupElementType(element) {
  const { type } = element || {};

  return type;
}

export function lookupElementTypeByComponentName(component) {
  const { name: componentName } = component || {};

  return COMPONENT_NAME_BY_ELEMENT_TYPE[componentName];
}
