import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import GoogleAnalytics from '../../libs/google-analytics';

export default function LocationListener({ children }) {
  const location = useLocation();

  useEffect(() => {
    GoogleAnalytics.pageView(location.pathname + location.search);
  }, [location]);

  return children;
}
