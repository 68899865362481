import API from '../../../../libs/api';

export function getOptions(values) {
  if (!values && values.length === 0) {
    return [];
  }
  return values.map((value) => ({
    key: value,
    value,
    text: value,
  }));
}

export function updatePartial(partialId, recipePartials = [], updatedPartial = {}) {
  return recipePartials.map((partial) => {
    if (partialId === partial.id) {
      return {
        ...partial,
        ...updatedPartial,
      };
    }
    return partial;
  });
}

export async function getPartialFileContent(partialId, onSuccess, onError) {
  if (!partialId) return;
  try {
    const resp = await API.getRecipePartialFile(partialId);
    const {
      data: { partial },
    } = resp;
    onSuccess(partial);
  } catch (error) {
    onError(error);
  }
}
