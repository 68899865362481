import React from 'react';

import 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { BrowserRouter, Route, Routes as RoutesDom, Navigate } from 'react-router-dom';

import DashboardRoute from './DashboardRoute';
import LocationListener from './LocationListener';
import { HELP_CENTER_URL } from '../../constants/constants';
import { leadSourceWithRegistration, leadSourceWithSummaryPage } from '../../constants/partners';
import { SOCIAL_AUTH_CALLBACK_PATH } from '../../constants/urls';
import { registrationSlugFromLeadSource } from '../../libs/partners';
import Routing from '../../libs/routing';
import SummaryPage from '../common/SummaryPage';
import RegistrationPage from '../common/registration/RegistrationPage';
import NotFound from '../core/404';
import CreatePasswordPage from '../core/CreatePasswordPage/CreatePasswordPage';
import ForgotConfirmPage from '../core/ForgotConfirmPage';
import ForgotPage from '../core/ForgotPage';
import GMBPage from '../core/GMBPage';
import LoginPage from '../core/LoginPage';
import MagicLinkAuth from '../core/MagicLinkAuth';
import WaaSPage from '../core/SignUpPage/SignUpPage';
import Redirect from '../modules/foundation/components/Redirect/Redirect';
import MaintenanceWindowPage from '../pages/MaintenanceWindowPage';

function getRoute(
  rootRoutePath,
  {
    completePath,
    component,
    hasToolbar,
    roles,
    permissions,
    youtubeId,
    categories,
    featureFlags,
    featureSets,
    shouldDisplay,
    recipeExclusionList,
  },
) {
  return (
    <Route
      path={completePath}
      element={
        <DashboardRoute
          path={completePath}
          component={component}
          rootRoutePath={rootRoutePath}
          hasToolbar={hasToolbar}
          roles={roles}
          permissions={permissions}
          youtubeId={youtubeId}
          categories={categories}
          featureFlags={featureFlags}
          featureSets={featureSets}
          shouldDisplay={shouldDisplay}
          recipeExclusionList={recipeExclusionList}
        />
      }
    />
  );
}

function getRoutes(rootRoutePath, routes) {
  return routes.map((route) => getRoute(rootRoutePath, route));
}

function getDashboardRoutes(rootRoutePath) {
  const routes = Routing.getFlattenedRoutes([rootRoutePath]);
  return getRoutes(rootRoutePath, routes);
}

function getAllDashboardRoutes() {
  return Routing.getRoutes().map(({ path: rootRoutePath }) => getDashboardRoutes(rootRoutePath));
}

export default function Routes() {
  const { maintenanceWindow } = useFlags();

  if (maintenanceWindow) {
    return (
      <BrowserRouter>
        <MaintenanceWindowPage />
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <LocationListener>
        <RoutesDom>
          {getAllDashboardRoutes()}
          <Route path="/" element={<LoginPage />} />
          <Route path="/login/" element={<LoginPage />} />
          <Route path={SOCIAL_AUTH_CALLBACK_PATH} element={<LoginPage />} />
          <Route path="/help/" element={<Redirect url={HELP_CENTER_URL} />} />
          <Route path="/login/forgot/" element={<ForgotPage />} />
          <Route path="/shift4/sign-up/" element={<WaaSPage />} />
          <Route path="/internal/sign-up/" element={<WaaSPage />} />
          <Route path="/internal-experimental/sign-up/" element={<WaaSPage />} />
          <Route path="/ezcater/reps/sign-up/" element={<WaaSPage />} />
          <Route path="/chownow/reps/sign-up/" element={<WaaSPage />} />
          <Route path="/clover/reps/sign-up/" element={<WaaSPage />} />
          <Route path="/bbot/reps/sign-up/" element={<WaaSPage />} />
          <Route path="/toast/reps/sign-up/" element={<WaaSPage />} />
          <Route path="/zuppler/reps/sign-up/" element={<WaaSPage />} />
          <Route path="/sign-up/" element={<WaaSPage />} />
          <Route path="/billing/" element={<Navigate to="/account/billing" />} />
          {leadSourceWithRegistration.map((leadSource) => (
            <Route
              key={leadSource}
              path={`/${registrationSlugFromLeadSource(leadSource)}/reps/register/`}
              element={<RegistrationPage leadSource={leadSource} />}
            />
          ))}
          {leadSourceWithSummaryPage.map((leadSource) => (
            <Route
              key={leadSource}
              path={`/${registrationSlugFromLeadSource(leadSource)}/install/`}
              element={<SummaryPage leadSource={leadSource} />}
            />
          ))}
          <Route path="/gmb" element={<GMBPage />} />
          <Route path="/auth" element={<MagicLinkAuth />} />
          <Route path="/login/forgot/confirm/:uid/:token" element={<ForgotConfirmPage />} />
          <Route path="/create-password" element={<CreatePasswordPage />} />
          <Route element={<NotFound />} />
        </RoutesDom>
      </LocationListener>
    </BrowserRouter>
  );
}
