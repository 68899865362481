import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Segment, Button, Container, Image, Form } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import {
  INTEGRATION_PROVIDER,
  INTEGRATION_PROVIDER_SERVICE_TYPE,
  DEFAULT_SERVICE_DATA,
  ACCOUNT_ID_KEY,
} from './DaySmart.constants';
import { isDaySmartIntegrationAllowed } from './DaySmart.utils';
import {
  updateProviderServiceSetting as updateProviderServiceSettingConnect,
  addNewProviderServiceSetting as addNewProviderServiceSettingConnect,
  deleteProviderServiceSetting as deleteProviderServiceSettingConnect,
} from '../../../../actions/business';
import API from '../../../../libs/api';
import { BusinessPropType } from '../../../../libs/proptypes';
import { InlineFieldContainer } from '../../../common';
import FormContainer from '../../../common/FormContainer';
import FormInput from '../components/FormInput';
import { getProviderService, getProviderServiceSettingById } from '../utils';

import DaySmartLogo from '../../../../static/logos/daysmart.png';

import './DaySmart.scss';

const mapDispatchToProps = (dispatch) => ({
  addNewProviderServiceSetting: (payload) => dispatch(addNewProviderServiceSettingConnect(payload)),
  deleteProviderServiceSetting: (payload) => dispatch(deleteProviderServiceSettingConnect(payload)),
  updateProviderServiceSetting: (payload) => dispatch(updateProviderServiceSettingConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  business: _get(business, 'core.value'),
  providerServices: _get(business, 'provider_services.value'),
  providerServiceSettings: _get(business, 'provider_service_settings.value'),
  businessLeadSource: _get(business, 'core.value.lead_source'),
});

const propTypes = {
  business: BusinessPropType.isRequired,
  providerServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      provider: PropTypes.string,
      service_type: PropTypes.string,
    }),
  ),
  providerServiceSettings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      provider_service: PropTypes.number,
    }),
  ),
};

const defaultProps = {
  providerServices: [],
  providerServiceSettings: [],
};

function DaysmartIntegration(props) {
  const { providerServices, providerServiceSettings, businessLeadSource } = props;

  const daysmartService = getProviderService(
    providerServices,
    INTEGRATION_PROVIDER,
    INTEGRATION_PROVIDER_SERVICE_TYPE,
  );

  if (!daysmartService || !isDaySmartIntegrationAllowed(businessLeadSource)) {
    return null;
  }

  const daysmartSetting = getProviderServiceSettingById(
    providerServiceSettings,
    daysmartService.id,
  );
  const daysmartServiceData = daysmartSetting ? daysmartSetting.service_data : DEFAULT_SERVICE_DATA;

  const [serviceData, setServiceData] = useState(daysmartServiceData);
  const accountId = _get(serviceData, ACCOUNT_ID_KEY);

  async function addIntegration() {
    const { addNewProviderServiceSetting } = props;

    addNewProviderServiceSetting({
      provider_service: daysmartService.id,
      provider: daysmartService.provider,
      service_type: daysmartService.service_type,
      service_data_schema: daysmartService.service_data_schema,
      service_data: {},
      status_data: {},
    });
  }

  function handleUpdate(e, { value, name }) {
    setServiceData({
      ...serviceData,
      [name]: value,
    });
  }

  async function removeIntegration() {
    const { business, deleteProviderServiceSetting } = props;
    const { id: businessId, type: businessType } = business;
    await API.deleteProviderServiceSettings(businessId, businessType, daysmartSetting.id);
    deleteProviderServiceSetting({
      fields: {
        index: daysmartSetting.globalIndex,
        ignoreTouch: true,
      },
    });
    setServiceData({});
  }

  useEffect(() => {
    const { updateProviderServiceSetting } = props;
    if (!daysmartSetting) return;

    const updatedProviderServiceSettingPayload = {
      index: daysmartSetting.globalIndex,
      field: 'service_data',
      fieldValue: { ...serviceData },
    };

    updateProviderServiceSetting(updatedProviderServiceSettingPayload);
  }, [serviceData]);

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
      <InlineFieldContainer title={<h2>DaySmart</h2>} enableDelete={false}>
        <Segment.Group>
          <Segment>
            <Container>
              <Container textAlign="center">
                <Image src={DaySmartLogo} className="daysmart-integration-logo" />
              </Container>
            </Container>
          </Segment>
          {daysmartSetting ? (
            <>
              <Segment attached="top">
                <Form>
                  <FormInput
                    value={accountId}
                    name={ACCOUNT_ID_KEY}
                    label="Account ID"
                    handleUpdate={handleUpdate}
                  />
                </Form>
              </Segment>
              {daysmartSetting.id && (
                <Segment attached="bottom">
                  <Container textAlign="center">
                    <Button size="big" onClick={removeIntegration} negative>
                      Remove Integration
                    </Button>
                  </Container>
                </Segment>
              )}
            </>
          ) : (
            <Segment attached="top">
              <Container textAlign="center">
                <Button size="big" onClick={addIntegration} primary>
                  Setup Integration
                </Button>
              </Container>
            </Segment>
          )}
        </Segment.Group>
      </InlineFieldContainer>
    </FormContainer>
  );
}

DaysmartIntegration.propTypes = propTypes;
DaysmartIntegration.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(DaysmartIntegration);
