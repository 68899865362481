import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

import { objectToCamelCase } from '../../../../libs/format';

export const componentConfigurationAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id < b.id,
});

const initialState = componentConfigurationAdapter.getInitialState();
const componentConfigurationSelectors = componentConfigurationAdapter.getSelectors(
  (state) => state.componentConfigurations,
);

const componentConfigurationSlice = createSlice({
  name: 'componentConfigurations',
  initialState,
  reducers: {
    updateOneComponentConfiguration: (state, action) => {
      const { id, changes } = action.payload;
      const newChanges = objectToCamelCase(changes);
      componentConfigurationAdapter.updateOne(state, { id, changes: newChanges });
    },
    upsertChildComponentConfiguration: (state, action) => {
      const { id, childComponentConfiguration } = action.payload;
      const { childConfigurations } = componentConfigurationSelectors.selectById(
        { componentConfigurations: state },
        id,
      );
      let wasAdded = false;
      const newChildConfigurations = childConfigurations.map((childConfig) => {
        if (childConfig.id === childComponentConfiguration.id) {
          wasAdded = true;
          return objectToCamelCase(childComponentConfiguration);
        }
        return childConfig;
      });
      if (!wasAdded) {
        newChildConfigurations.push(objectToCamelCase(childComponentConfiguration));
      }
      componentConfigurationAdapter.updateOne(state, {
        id,
        changes: { childConfigurations: newChildConfigurations },
      });
    },
    removeOneComponentConfiguration: componentConfigurationAdapter.removeOne,
    setAllComponentConfigurations: (state, action) => {
      const componentConfigurations = objectToCamelCase(action.payload);
      componentConfigurationAdapter.setAll(state, componentConfigurations);
    },
    addOneComponentConfiguration: (state, action) => {
      const componentConfiguration = objectToCamelCase(action.payload);
      componentConfigurationAdapter.addOne(state, componentConfiguration);
    },
  },
});

export const {
  addOneComponentConfiguration,
  addManyComponentConfigurations,
  updateOneComponentConfiguration,
  removeOneComponentConfiguration,
  setAllComponentConfigurations,
  upsertChildComponentConfiguration,
} = componentConfigurationSlice.actions;

export default componentConfigurationSlice.reducer;
