export function isCustomReview(source) {
  if (!source) {
    return false;
  }

  return source.toLowerCase() === 'custom';
}

export function getIconName(source = '') {
  if (source.toLowerCase() === 'website') return 'globe';
  return source;
}
