import {
  BUSINESS_TYPE_COPIES,
  ENTITY_NAME_BY_BUSINESS_TYPE,
  MENU_ENTITY_NAME,
  SERVICES_ENTITY_NAME,
  SOURCE_TYPE_WITH_QR_CODE,
} from './MenuForm.constants';
import { BRAND_IDENTITIES } from '../../modules/foundation/hooks/use-branding/useBranding.constants';

export function createInitialMenuContext(businessCategory = '') {
  const loweredBusinessCategory = businessCategory.toLowerCase();

  return {
    entityName:
      ENTITY_NAME_BY_BUSINESS_TYPE[loweredBusinessCategory] || ENTITY_NAME_BY_BUSINESS_TYPE.default,
    copies: BUSINESS_TYPE_COPIES[loweredBusinessCategory] || BUSINESS_TYPE_COPIES.default,
  };
}

export function shouldDisplayQRCode(menuSourceType) {
  const sourceType = menuSourceType.toLowerCase();
  return SOURCE_TYPE_WITH_QR_CODE.includes(sourceType);
}

export function getMenuNameFromBrandIdentity(brandIdentity) {
  return brandIdentity === BRAND_IDENTITIES.GLAMMATIC ? SERVICES_ENTITY_NAME : MENU_ENTITY_NAME;
}
