import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { List, Button, Dropdown, Input } from 'semantic-ui-react';

import _filter from 'lodash/filter';
import _get from 'lodash/get';

import ModifierSetToggle from '../../../CatalogModifiers/components/ModifierSetToggle';
import { BUSINESS_TYPE_MERCHANTS } from '../../../constants';
import {
  CatalogItemModifierListInfoPropType,
  CatalogModifierListPropType,
  CatalogModifierPropType,
} from '../../../proptypes';
import { getCatalogObjectId } from '../../../utils';

import './ItemModifierSetForm.scss';

const CatalogModifierSetPropType = PropTypes.shape({
  itemModifierListInfo: CatalogItemModifierListInfoPropType,
  modifierList: CatalogModifierListPropType.isRequired,
  modifiers: PropTypes.arrayOf(CatalogModifierPropType),
});

const propTypes = {
  modifierSets: PropTypes.arrayOf(CatalogModifierSetPropType),
  searchableModifierSets: PropTypes.arrayOf(CatalogModifierSetPropType),
  businessType: PropTypes.string,
  businessId: PropTypes.number.isRequired,
  onCreateNewSet: PropTypes.func,
  onAddExistingSet: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
};

const defaultProps = {
  modifierSets: [],
  searchableModifierSets: [],
  businessType: BUSINESS_TYPE_MERCHANTS,
  onCreateNewSet: () => null,
  onAddExistingSet: () => null,
  onSave: () => null,
  onDelete: () => null,
};

function ItemModifierSetForm({
  modifierSets,
  searchableModifierSets,
  businessType,
  businessId,
  onCreateNewSet,
  onAddExistingSet,
  onSave,
  onDelete,
}) {
  const [searchText, setSearchText] = useState('');
  const [filteredModifierSets, setFilteredModifierSets] = useState(searchableModifierSets);

  useEffect(() => {
    setSearchText('');
    setFilteredModifierSets(searchableModifierSets);
  }, [searchableModifierSets]);

  function handleSearchboxChange(e, { value }) {
    setSearchText(value);
    const searchExpression = new RegExp(`^${value}`, 'i');
    setFilteredModifierSets(
      _filter(searchableModifierSets, (modifierSet) =>
        searchExpression.test(_get(modifierSet, 'modifierList.modifier_list_data.name')),
      ),
    );
  }

  function handleSearchboxKeyDown(e) {
    if (e.code === 'Space') {
      e.stopPropagation();
    }
  }

  return (
    <div className="item-modifier-set-form">
      <div className="buttons-container">
        <Button className="create-new-set-button" onClick={onCreateNewSet}>
          Create New Set
        </Button>
        <Dropdown
          text="ADD EXISTING SET"
          className="add-existing-set-dropdown"
          disabled={searchableModifierSets.length === 0}
        >
          <Dropdown.Menu>
            <Dropdown.Header
              className="add-existing-set-searchbox"
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                className="searchbox-input"
                icon="search"
                placeholder="Search for an existing set"
                value={searchText}
                onChange={handleSearchboxChange}
                onKeyDown={handleSearchboxKeyDown}
              />
            </Dropdown.Header>
            {filteredModifierSets.map((modifierSet) => (
              <Dropdown.Item
                text={_get(modifierSet, 'modifierList.modifier_list_data.name')}
                onClick={() => onAddExistingSet(modifierSet)}
                key={_get(modifierSet, 'modifierList.object_id')}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <List className="modifier-set-list">
        {modifierSets.map((modifierSet) => (
          <List.Item key={getCatalogObjectId(modifierSet.modifierList)}>
            <ModifierSetToggle
              initialItemModifierListInfo={modifierSet.itemModifierListInfo}
              initialModifierList={modifierSet.modifierList}
              initialModifiers={modifierSet.modifiers}
              businessType={businessType}
              businessId={businessId}
              onSave={onSave}
              onDelete={onDelete}
              isNestedForm
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
}

ItemModifierSetForm.propTypes = propTypes;
ItemModifierSetForm.defaultProps = defaultProps;

export default ItemModifierSetForm;
