import React from 'react';

import PropTypes from 'prop-types';
import { Divider, Button } from 'semantic-ui-react';

import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './AddFormFieldButton.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function AddFormFieldButton({ onClick }) {
  return (
    <Divider horizontal className="add-form-field-button">
      <Button basic onClick={onClick}>
        <FontAwesomeIcon className="icon" icon={faArrowsUpDown} />
        Insert Field
      </Button>
    </Divider>
  );
}

AddFormFieldButton.propTypes = propTypes;
AddFormFieldButton.defaultProps = defaultProps;
