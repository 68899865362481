import { useState } from 'react';

import { useSelector } from 'react-redux';

import API from '../../../../../libs/api';
import { selectWebsiteId } from '../../../../../selectors/website';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';

export default function useSubmissionChannels() {
  const websiteId = useSelector(selectWebsiteId);
  const [channels, setChannels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const data = await API.getSubmissionChannels({ website: websiteId });
      setChannels(data);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    channels,
    isLoading,
  };
}
