import React, { useState } from 'react';

import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-cycle
import getRecipeNodeNavigationDisplayComponent from '../../../services/get-node-navigation-display-component';
import { nodeInPath } from '../../../services/recipe-utils';
import ObjectOptionsHeader from '../../ObjectOptionsHeader';
import { isNodePathSelected } from '../types.utils';

import '../recipe-type.scss';

const propTypes = {
  recipeObjectKey: PropTypes.string.isRequired,
  recipePart: PropTypes.shape().isRequired,
  path: PropTypes.arrayOf(PropTypes.string),
  parentId: PropTypes.string.isRequired,
  selectedNodePath: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  path: [],
  selectedNodePath: [],
};

/**
 * This component is used for recipe nodes of type object
 *
 * @param  {string}  recipeObjectKey  Recipe Object key
 * @param  {object}  recipePart Value of the node object
 * @param  {string}  parentId  Id of the parent node
 * @param  {array}   path  Path of the current recipe node
 * @param  {array}   selectedNodePath  Path of node searched by the user
 */

const RecipeObject = ({ recipeObjectKey, recipePart, parentId, path, selectedNodePath }) => {
  const [isOpen, setIsOpen] = useState(false);

  function isOptionsHeaderOpen() {
    return nodeInPath(selectedNodePath, path) || isOpen;
  }

  return (
    <div className="recipe-object">
      <ObjectOptionsHeader
        recipeObjectKey={recipeObjectKey}
        recipePart={recipePart}
        isOpen={isOptionsHeaderOpen()}
        parentId={parentId}
        toggleOpen={() => setIsOpen(!isOpen)}
        isNodePathSelected={isNodePathSelected(selectedNodePath, path)}
      />

      {isOptionsHeaderOpen() && (
        <div className="recipe-indented-group">
          {Object.keys(recipePart).map((childKey, i) =>
            getRecipeNodeNavigationDisplayComponent(childKey, null, recipePart, i, {
              selectedNodePath,
              path: [...path, childKey],
            }),
          )}
        </div>
      )}
    </div>
  );
};

RecipeObject.propTypes = propTypes;
RecipeObject.defaultProps = defaultProps;

export default RecipeObject;
