import { SHOW_OPT_IN_KEY, REQUIRED_KEY } from './FormOptIn.constants';

export function getConsentSettingsConfig(name, checked, showValue, requiredValue) {
  if (name === SHOW_OPT_IN_KEY && checked === false) {
    return {
      show: false,
      required: false,
    };
  }

  return {
    show: name === SHOW_OPT_IN_KEY ? checked : showValue,
    required: name === REQUIRED_KEY ? checked : requiredValue,
  };
}
