import React from 'react';

import { Button, Grid, Input, Pagination, Table, Message, Label } from 'semantic-ui-react';

import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';

import BlogContentFormModal from './BlogContentFormModal';
import { DRAFT } from './BlogContentFormModal/BlogContentFormModal.constants';
import API from '../../libs/api';

class BlogListForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      totalPages: 0,
      blogposts: [],
      loading: false,
      dataError: undefined,
      filterValue: '',
      sortedColumn: '',
      directionSort: 'ascending',

      displayingEditModal: false,
      blogPostBeingEdited: undefined,
    };

    this.getBlogPosts = this.getBlogPosts.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onPublish = this.onPublish.bind(this);
    this.onUnpublish = this.onUnpublish.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount() {
    this.getBlogPosts();
  }

  onPageChange = (e, { activePage }) => {
    this.setState({ currentPage: activePage }, this.getBlogPosts);
  };

  onFilterChange = (e, { value }) => {
    this.setState({ filterValue: value }, this.getBlogPosts);
  };

  onSave() {
    this.getBlogPosts();
  }

  onPublish() {
    this.getBlogPosts();
  }

  onUnpublish() {
    this.getBlogPosts();
  }

  onDelete() {
    this.getBlogPosts();
  }

  setLoading = (loading) => {
    this.setState({ loading });
  };

  getBlogPosts = async () => {
    this.setLoading(true);

    const { directionSort, sortedColumn, currentPage, filterValue } = this.state;
    const { business } = this.props;

    const { id: businessId, type: businessType } = business;

    const sortDirection = directionSort === 'ascending' ? '' : '-';
    const ordering = sortedColumn ? `${sortDirection}${sortedColumn}` : '';

    try {
      const { data } = await API.getBlogPosts(businessId, businessType, {
        page: currentPage,
        filter: filterValue,
        ordering,
      });

      if (data.results && data.total_pages) {
        this.setState({
          blogposts: data.results,
          totalPages: data.total_pages,
          dataError: null,
        });
      }
    } catch (error) {
      if (error.response) {
        this.setState({
          dataError: error.response.data.detail,
        });
      } else {
        this.setState({
          dataError: 'There was an unexpected error getting your blog posts.',
        });
      }
    } finally {
      this.setLoading(false);
    }
  };

  getBlogPostStatus = (blogpost) => {
    if (blogpost.status === DRAFT) {
      return <Label>Draft</Label>;
    }
    return <Label color="green">Published</Label>;
  };

  handleSort = (clickedColumn) => () => {
    const { sortedColumn, directionSort } = this.state;
    if (sortedColumn !== clickedColumn) {
      this.setState(
        {
          sortedColumn: clickedColumn,
          directionSort: 'ascending',
        },
        this.getBlogPosts,
      );
    } else {
      const newDirection = directionSort === 'ascending' ? 'descending' : 'ascending';
      this.setState(
        {
          directionSort: newDirection,
        },
        this.getBlogPosts,
      );
    }
  };

  displayBlogpost(blogpost) {
    this.setState({
      blogPostBeingEdited: blogpost,
      displayingEditModal: true,
    });
  }

  closeBlogpostModal() {
    this.setState({
      blogPostBeingEdited: null,
      displayingEditModal: false,
    });
    // TODO refresh list?
  }

  render() {
    const { business } = this.props;
    const {
      blogposts,
      dataError,
      loading,
      filterValue,
      sortedColumn,
      directionSort,
      currentPage,
      totalPages,
      displayingEditModal,
      blogPostBeingEdited,
    } = this.state;

    return (
      <>
        {displayingEditModal && (
          <BlogContentFormModal
            blogpost={blogPostBeingEdited}
            onSave={this.onSave}
            onPublish={this.onPublish}
            onUnpublish={this.onUnpublish}
            onDelete={this.onDelete}
            closeModal={() => this.closeBlogpostModal()}
            business={business}
          />
        )}

        <Grid>
          <Grid.Row verticalAlign="middle">
            <Grid.Column width="12">
              <Input
                style={{ width: '100%' }}
                placeholder="Search blog posts"
                icon="search"
                iconPosition="left"
                value={filterValue}
                onChange={this.onFilterChange}
                loading={loading}
              />
            </Grid.Column>
            <Grid.Column width="4" textAlign="right">
              <Button
                primary
                content="New Post"
                icon="write"
                labelPosition="left"
                onClick={() => this.displayBlogpost(null)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Table sortable compact celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                width={8}
                sorted={sortedColumn === 'title' ? directionSort : null}
                onClick={this.handleSort('title')}
              >
                Title
              </Table.HeaderCell>
              <Table.HeaderCell
                width={4}
                sorted={sortedColumn === 'published_at' ? directionSort : null}
                onClick={this.handleSort('published_at')}
              >
                Publish Date
              </Table.HeaderCell>
              <Table.HeaderCell
                width={4}
                sorted={sortedColumn === 'status' ? directionSort : null}
                onClick={this.handleSort('status')}
              >
                Status
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {blogposts.map((blogpost) => {
              return (
                <Table.Row key={blogpost.id}>
                  <Table.Cell>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={() => this.displayBlogpost(blogpost)}
                      role="button"
                      onKeyDown={() => this.displayBlogpost(blogpost)}
                      tabIndex={-1}
                    >
                      {blogpost.title}
                    </a>
                  </Table.Cell>
                  <Table.Cell>
                    {blogpost.published_at &&
                      `${moment(blogpost.published_at).format('MM/DD/YYYY @ hh:mm A')}`}
                  </Table.Cell>
                  <Table.Cell textAlign="center">{this.getBlogPostStatus(blogpost)}</Table.Cell>
                </Table.Row>
              );
            })}
            {blogposts.length === 0 && (
              <Table.Row>
                <Table.Cell textAlign="center" colSpan="3">
                  <p>No Blog Posts Found</p>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          onPageChange={this.onPageChange}
          activePage={currentPage}
          totalPages={totalPages}
        />

        {dataError && <Message header={dataError} negative />}
      </>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ business }) => ({
  business: _.get(business, 'core.value'),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogListForm);
