/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Form, Input } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { getDropdownText } from './WebsiteComponentInteractionWidget.utils';
import { selectWebsitePages } from '../../../../selectors/website';
import URISchemeFormField from '../../fields/components/URISchemeField';
import BehaviorSelector from '../BehaviorSelector';
import EnabledToggle from '../EnabledToggle';
import StyleSelector from '../StyleSelector';
import { BEHAVIOR_EXTERNAL_LINK } from '../constants';
import { InternalWebsiteComponentInteractionPropType } from '../proptypes';

import './WebsiteComponentInteractionWidget.scss';

const propTypes = {
  websiteComponentInteraction: InternalWebsiteComponentInteractionPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  extraBehaviors: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  extraBehaviors: [],
};

function WebsiteComponentInteractionWidget({
  websiteComponentInteraction,
  title,
  onChange,
  extraBehaviors,
}) {
  const pages = useSelector(selectWebsitePages);

  const { url, enabled, style, label, behavior_type: behaviorType } = websiteComponentInteraction;
  const [formData, setFormData] = useState({
    url,
    enabled,
    style,
    label,
    behaviorType,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      url,
      enabled,
      style,
      label,
      behaviorType,
    }));
  }, [url, enabled, style, label, behaviorType]);

  function onLocalChange(changes) {
    setFormData((prevData) => ({ ...prevData, ...changes }));
    onChange(changes);
  }

  function onSelectBehavior({ behavior, url: selectedUrl, displayType }) {
    onLocalChange({
      behavior_type: behavior,
      url: selectedUrl,
      display_type: displayType,
    });
  }

  function onChangeField(fieldName) {
    return (value) => onLocalChange({ [fieldName]: value });
  }

  function onChangeFieldData(fieldName) {
    return (e, data) => {
      onLocalChange({ [fieldName]: data.value });
    };
  }

  return (
    <div className="ui form website-component-interaction-widget">
      <div className="website-component-interaction-widget-row">
        <EnabledToggle
          enabled={formData.enabled}
          setEnabled={onChangeField('enabled')}
          title={title}
        />
      </div>
      <div className="website-component-interaction-widget-row">
        <StyleSelector
          disabled={!formData.enabled}
          value={formData.style}
          setStyle={onChangeField('style')}
        />
      </div>
      <div className="website-component-interaction-widget-row">
        <Form.Group className="behavior-group">
          <Form.Field>
            <label>Button Label</label>
            <Input
              value={formData.label}
              disabled={!formData.enabled}
              onChange={onChangeFieldData('label')}
            />
          </Form.Field>
          <Form.Field>
            <label>Button Behavior</label>
            <BehaviorSelector
              disabled={!formData.enabled}
              text={getDropdownText(behaviorType, url, pages)}
              value={formData.behaviorType}
              url={formData.url}
              onSelect={onSelectBehavior}
              extraBehaviors={extraBehaviors}
            />
          </Form.Field>
        </Form.Group>
      </div>
      {behaviorType === BEHAVIOR_EXTERNAL_LINK && (
        <div className="website-component-interaction-widget-row">
          <Form.Field>
            <URISchemeFormField
              placeholder="example.com"
              value={formData.url}
              onChange={onChangeFieldData('url')}
              disabled={!formData.enabled}
            />
          </Form.Field>
        </div>
      )}
    </div>
  );
}

WebsiteComponentInteractionWidget.propTypes = propTypes;
WebsiteComponentInteractionWidget.defaultProps = defaultProps;

export default WebsiteComponentInteractionWidget;
