import React from 'react';

import { Grid } from 'semantic-ui-react';

import { AUTO_ADJUSTED_COLUMN_SIZE_MODE } from '../../../../constants/types';
import { RowPropType } from '../../../../types/row.types';
import CustomPageGridColumn from '../CustomPageGridColumn';

import './CustomPageGridRow.scss';

const propTypes = { row: RowPropType.isRequired };

const defaultProps = {};

export default function CustomPageGridRow({ row }) {
  const { stretched, textAlign, columns = [], columnSizingMode } = row;

  const numColumns = columnSizingMode === AUTO_ADJUSTED_COLUMN_SIZE_MODE ? columns.length : null;

  return (
    <Grid.Row
      stretched={stretched}
      columns={numColumns}
      textAlign={textAlign}
      className="custom-page-grid-row"
    >
      {columns.map((column) => (
        <CustomPageGridColumn key={column.id} row={row} column={column} />
      ))}
    </Grid.Row>
  );
}

CustomPageGridRow.propTypes = propTypes;
CustomPageGridRow.defaultProps = defaultProps;
