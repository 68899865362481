import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import Field from './Field';
import { StylePropType } from '../../libs/proptypes';

const InputField = ({
  value,
  type = 'text',
  onChange,
  placeholder,
  onValidate,
  name,
  disabled,
  label,
  error,
  minLength,
  maxLength,
  pattern,
  ...rest
}) => (
  <Field {...rest}>
    <Input
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onValidate}
      name={name}
      disabled={disabled}
      error={error}
      pattern={pattern}
      minLength={minLength}
      maxLength={maxLength}
    />
  </Field>
);

InputField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  style: StylePropType,
};

InputField.defaultProps = {
  value: '',
  title: '',
  placeholder: '',
  style: {},
};

export default InputField;
