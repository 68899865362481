import React from 'react';

import LoginBanner from './LoginBanner';

import Alt404Logo from '../../static/logos/FishermanIconLogo.png';

import '../../styles/core/pages/404.scss';

export default () => (
  <div className="not-found">
    <LoginBanner />
    <div>
      <img src={Alt404Logo} alt="Fisherman Logo" />
      <h1>Sorry, this page could not be found</h1>
      <a href="/login">
        <button type="button">Login</button>
      </a>
    </div>
  </div>
);
