import React from 'react';

import { Button, Form, Input } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import withCopyToClipboard from 'components/common/CopyToClipboard';
import { createQueryParams } from 'components/modules/files/services/gumlet';
import { Icon } from 'components/modules/foundation/components';

import { DESKTOP_DEVICE } from '../../ImageQueryParamBuilder.constants';
import { ImageQueryParamBuilderFormDataPropType } from '../../ImageQueryParamBuilder.types';

import './QueryParamsDisplay.scss';

const propTypes = { formData: ImageQueryParamBuilderFormDataPropType.isRequired };

const defaultProps = {};

const CopyToClipboard = withCopyToClipboard(Button);

export default function QueryParamsDisplay({ formData }) {
  const activeDevice = DESKTOP_DEVICE;

  const activeFormData = _get(formData, activeDevice, {});
  const queryParams = createQueryParams(activeFormData) || '';

  return (
    <>
      <Form.Input label="Current Query Params" className="query-params-display">
        <Input value={queryParams} />
        <CopyToClipboard icon value={queryParams} disabled={_isEmpty(queryParams)}>
          <Icon name="copy" />
        </CopyToClipboard>
      </Form.Input>
    </>
  );
}

QueryParamsDisplay.propTypes = propTypes;
QueryParamsDisplay.defaultProps = defaultProps;
