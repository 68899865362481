import { BRAND_IDENTITIES } from './useBranding.constants';
import { GLAMMATIC_SUPPORT_EMAIL, SUPPORT_EMAIL } from '../../../../../constants/constants';

export function getSupportEmail(brandIdentity) {
  if (brandIdentity === BRAND_IDENTITIES.GLAMMATIC) {
    return GLAMMATIC_SUPPORT_EMAIL;
  }

  return SUPPORT_EMAIL;
}
