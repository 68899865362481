import _isEmpty from 'lodash/isEmpty';
import _isNul from 'lodash/isNull';

import { TILE_CONTENT_NAME, SUBHEADER_CONTENT_NAME, MARKDOWN_CONTENT_TYPE } from './constants';
import { createImageComponentConfiguration } from './image';
import { setInteractionsOrder } from './interactions';
import { generateId, setItemInList } from './utils';
import { createContentComponentConfiguration } from '../../../../component-configuration/services/content-node-configuration';
import { DYNAMIC_TILES_TILE_COMPONENT } from '../../../constants/custom-hero';
import { DEFAULT_WIDTH } from '../../../constants/tile';
import { getContentIndexByContentName } from '../../../services/tile';

function createDefaultContent() {
  return [
    createContentComponentConfiguration({
      contentName: TILE_CONTENT_NAME,
      order: 1,
      contentType: MARKDOWN_CONTENT_TYPE,
    }),
  ];
}

function createDefaultImages() {
  return [];
}

export function createTile({
  componentConfiguration = {},
  images = [],
  contentNodes = [],
  interactions: websiteInteractionsParam = [],
  order: orderParam = 1,
  width: widthParam = DEFAULT_WIDTH,
} = {}) {
  const {
    componentName = DYNAMIC_TILES_TILE_COMPONENT,
    componentConfigurationId,
    componentIdentifier = generateId(),
    data = {},
    order = orderParam,
  } = componentConfiguration;

  const { className = '', overlayClassName = '', width = widthParam, showOnHover = false } = data;

  const websiteInteractions = setInteractionsOrder(websiteInteractionsParam);

  return {
    componentConfiguration: {
      componentName,
      componentConfigurationId,
      componentIdentifier,
      order,
      data: { className, overlayClassName, width, showOnHover },
    },
    images: _isEmpty(images) ? createDefaultImages() : images,
    contentNodes: _isEmpty(contentNodes) ? createDefaultContent() : contentNodes,
    interactions: websiteInteractions,
  };
}

export function setWidth(tile, { width } = {}) {
  const { componentConfiguration = {} } = tile;
  const { data: previousData = [] } = componentConfiguration;

  return {
    ...tile,
    componentConfiguration: { ...componentConfiguration, data: { ...previousData, width } },
  };
}

export function setOrder(tile, { order } = {}) {
  const { componentConfiguration = {} } = tile;

  return { ...tile, componentConfiguration: { ...componentConfiguration, order } };
}

export function setTileContent(tile, { contentNodeConfigurationId, contentNodeId, content } = {}) {
  const { contentNodes: previousContentNodes = [] } = tile;
  const index = getContentIndexByContentName(previousContentNodes, TILE_CONTENT_NAME);

  const newContentConfiguration = createContentComponentConfiguration({
    contentNodeConfigurationId,
    contentNodeId,
    contentName: TILE_CONTENT_NAME,
    content,
    order: index,
    contentType: MARKDOWN_CONTENT_TYPE,
  });

  return {
    ...tile,
    contentNodes: setItemInList(previousContentNodes, index, newContentConfiguration),
  };
}

export function setSubheader(tile, { contentNodeConfigurationId, contentNodeId, content } = {}) {
  const { contentNodes: previousContentNodes = [] } = tile;
  const index = getContentIndexByContentName(previousContentNodes, SUBHEADER_CONTENT_NAME);

  const newSubHeaderConfiguration = createContentComponentConfiguration({
    contentNodeConfigurationId,
    contentNodeId,
    contentName: SUBHEADER_CONTENT_NAME,
    content,
    order: index,
  });

  return {
    ...tile,
    contentNodes: setItemInList(previousContentNodes, index, newSubHeaderConfiguration),
  };
}

export function setClassName(tile, { className = '' } = {}) {
  const { componentConfiguration = {} } = tile;
  const { data: previousData = [] } = componentConfiguration;

  return {
    ...tile,
    componentConfiguration: { ...componentConfiguration, data: { ...previousData, className } },
  };
}

export function setShowOnHover(tile, { showOnHover } = {}) {
  const { componentConfiguration = {} } = tile;
  const { data: previousData = [] } = componentConfiguration;

  return {
    ...tile,
    componentConfiguration: {
      ...componentConfiguration,
      data: { ...previousData, showOnHover: !!showOnHover },
    },
  };
}

export function setImage(tile, { businessFileConfigurationId, businessFileId, url = '' } = {}) {
  const { images: previousImages = [] } = tile;

  const backgroundImageIndex = 0;
  const previousImage = previousImages[0];

  const newImage = createImageComponentConfiguration({
    ...previousImage,
    businessFileConfigurationId,
    businessFileId,
    url,
  });

  return { ...tile, images: setItemInList(previousImages, backgroundImageIndex, newImage) };
}

export function setImages(tile, images = []) {
  const newImages = images
    .map(({ businessFileConfigurationId, businessFileId, url = '' }, index) => {
      if (_isNul(businessFileId)) {
        return false;
      }

      return createImageComponentConfiguration({
        businessFileConfigurationId,
        businessFileId,
        url,
        order: index,
      });
    })
    .filter(Boolean);

  return { ...tile, images: newImages };
}
