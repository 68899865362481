import React from 'react';

import { Card } from 'semantic-ui-react';

import { SortableElement } from 'react-sortable-hoc';

import { MENU_FILE_TYPES } from '../../../../../../constants/constants';
import DocumentModal from '../../../../../common/DocumentModal';
import { InventoryConsumer } from '../../../../MenuForm/Menu.context';
import DraggableFileField from '../../../common/DraggableFileField';

const SortableMenuCardGroup = SortableElement(
  ({ menuItem, handleChange, handleDelete, handleEditFilename }) => {
    const { filename, url, file, globalIndex } = menuItem;

    return (
      <InventoryConsumer>
        {({ entityName }) => (
          <DocumentModal
            url={url || file}
            trigger={
              <Card.Group>
                <DraggableFileField
                  url={url || file}
                  onChangeFilename={(e) => handleEditFilename(e, globalIndex)}
                  onChange={(e) => handleChange(e, globalIndex)}
                  onDelete={() => handleDelete(globalIndex)}
                  accept={MENU_FILE_TYPES}
                  title={filename}
                  placeholder={`${entityName} Name`}
                  enableDelete
                  multiple
                />
              </Card.Group>
            }
          />
        )}
      </InventoryConsumer>
    );
  },
);

export default SortableMenuCardGroup;
