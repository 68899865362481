import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Table, Icon, Button } from 'semantic-ui-react';

import { CONFIRMATION_DELETE_MESSAGE } from './ItemVariationForm.constants';
import { keysToSnakeCase } from '../../../../../../../libs/strings/strings';
import ConfirmModal from '../../../../../../common/ConfirmModal';
import PriceField from '../../../../../../fields/PriceField/PriceField';
import { CatalogItemVariationPropType } from '../../../proptypes';
import ItemVariationShippingInfoForm from '../ItemVariationShippingInfoForm';

const propTypes = {
  variation: CatalogItemVariationPropType.isRequired,
  variationsCount: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isShippingEnabled: PropTypes.bool,
};

const defaultProps = {
  isShippingEnabled: true,
};
function ItemVariationForm({ variation, variationsCount, onChange, onRemove, isShippingEnabled }) {
  const {
    length,
    width,
    height,
    distance_unit: distanceUnit,
    weight,
    mass_unit: massUnit,
    virtual,
    fragile,
  } = variation.item_variation_data;
  const fulfillmentDetails = {
    length,
    width,
    height,
    distanceUnit,
    weight,
    massUnit,
    virtual,
    fragile,
  };

  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] = useState(false);

  function onChangeVariationData(variationObj, variationData) {
    const snakeCaseData = keysToSnakeCase(variationData);
    onChange(variationObj, snakeCaseData);
  }

  function removeItemVariationHandler() {
    setIsConfirmDeleteModalOpened(false);
    onRemove(variation);
  }

  return (
    <>
      <Table.Row key={variation.object_id || variation.localId}>
        {variationsCount > 1 && (
          <Table.Cell>
            <Form.Input
              name="name"
              placeholder="Name"
              value={variation.item_variation_data.name}
              onChange={(e, { name, value }) => onChangeVariationData(variation, { [name]: value })}
            />
          </Table.Cell>
        )}
        <Table.Cell>
          <PriceField
            name="price"
            placeholder="0"
            priceInitialValue={variation.item_variation_data.price}
            onChange={(e, { name, value }) => onChangeVariationData(variation, { [name]: value })}
          />
        </Table.Cell>
        <Table.Cell>
          <ConfirmModal
            onOpen={() => setIsConfirmDeleteModalOpened(true)}
            open={isConfirmDeleteModalOpened}
            trigger={
              <Icon
                disabled={variationsCount <= 1}
                className="remove-icon"
                name="trash alternate outline"
              />
            }
            header="Delete Variation"
            content={CONFIRMATION_DELETE_MESSAGE}
            confirmButton={<Button>Delete</Button>}
            onConfirm={() => removeItemVariationHandler()}
            onCancel={() => setIsConfirmDeleteModalOpened(false)}
          />
        </Table.Cell>
      </Table.Row>
      {isShippingEnabled && (
        <Table.Row>
          <Table.Cell colSpan={variationsCount > 0 ? 3 : 2}>
            <ItemVariationShippingInfoForm
              isNew={!variation.object_id}
              onChange={(data) => onChangeVariationData(variation, data)}
              {...fulfillmentDetails}
              length={length}
            />
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
}

ItemVariationForm.propTypes = propTypes;
ItemVariationForm.defaultProps = defaultProps;

export default ItemVariationForm;
