import PropTypes from 'prop-types';

export const ColorPropTypes = PropTypes.shape({
  hex: PropTypes.string,
  rgb: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
});
