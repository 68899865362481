import {
  BUSINESS_OWNER_GROUP_NAME,
  CHOWNOW_SALES_GROUP_NAME,
  EZCATER_SALES_GROUP_NAME,
  CLOVER_SALES_GROUP_NAME,
  BBOT_SALES_GROUP_NAME,
  ZUPPLER_SALES_GROUP_NAME,
  DAY_SMART_SALES_GROUP_NAME,
} from './auth';
import { isAdminUser, isUserInGroup } from '../libs/auth';

const groupNameToGroupDisplay = {
  [BUSINESS_OWNER_GROUP_NAME]: 'Business Owner',
  [CHOWNOW_SALES_GROUP_NAME]: 'ChowNow Rep',
  [EZCATER_SALES_GROUP_NAME]: 'ezCater Rep',
  [CLOVER_SALES_GROUP_NAME]: 'Clover Rep',
  [BBOT_SALES_GROUP_NAME]: 'BBot Rep',
  [ZUPPLER_SALES_GROUP_NAME]: 'Zuppler Rep',
  [DAY_SMART_SALES_GROUP_NAME]: 'DaySmart Rep',
};

const defaultGroups = [BUSINESS_OWNER_GROUP_NAME];

const getUserGroups = (user) => {
  return Object.keys(groupNameToGroupDisplay).filter(
    (groupName) => isAdminUser(user) || isUserInGroup(user, groupName),
  );
};

const getUserGroupsForOptions = (user) => {
  return [...defaultGroups, ...getUserGroups(user)];
};

const getUserGroupOptions = (user) => {
  return getUserGroupsForOptions(user).map((role) => ({
    key: role,
    value: role,
    text: groupNameToGroupDisplay[role],
  }));
};

export { groupNameToGroupDisplay, getUserGroupOptions, getUserGroups };
