import React, { useContext, useState, useEffect } from 'react';

import { Icon, Button } from 'semantic-ui-react';

import _cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { removeTeamMember, setTeamMember } from './TeamMemberActions.utils';
import API from '../../../../../../../libs/api';
import ErrorHandler from '../../../../../../../libs/errors';
import { camelToSnakeCase } from '../../../../../../../libs/strings';
import ConfirmModal from '../../../../../../common/ConfirmModal';
import { selectTeams } from '../../../../selectors/team';
import { TeamsSectionContext } from '../../TeamMembersTable.context';
import TeamMemberPropType from '../../TeamMembersTable.propTypes';
import EditTeamMemberModal from '../EditTeamMemberModal';

import './TeamMemberActions.scss';

const propTypes = {
  teamMember: TeamMemberPropType.isRequired,
};

export default function TeamMemberActions({ teamMember }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [currentMember, setCurrentMember] = useState(_cloneDeep(teamMember));

  const { setTeamMembers, teamMembers } = useContext(TeamsSectionContext);
  const allTeams = useSelector(selectTeams);

  useEffect(() => {
    setCurrentMember(_cloneDeep(teamMember));
  }, [teamMember]);

  const { name: teamMemberName } = currentMember;

  function onChange(e, { name, value }) {
    setCurrentMember((previousFormData) => ({
      ...previousFormData,
      [camelToSnakeCase(name)]: value,
    }));
  }

  function closeEditModal() {
    setShowEditModal(false);
  }

  function closeDeleteModal() {
    setShowDeleteModal(false);
  }

  async function onTeamMemberUpdate() {
    const { image, teams, ...memberWithoutImage } = _cloneDeep(currentMember);

    if (image && typeof image === 'object') {
      const imagePayload = new FormData();
      imagePayload.append('image', image.file || '');
      await API.updateTeamMemberAttribute(currentMember.id, imagePayload);
    }

    const filteredTeams = teams.filter(({ team: teamId }) => allTeams.find((t) => t.id === teamId));
    const finalTeamMember = { ...memberWithoutImage, teams: filteredTeams };

    const { data } = await API.updateTeamMember(currentMember.id, finalTeamMember);
    setTeamMembers(setTeamMember(teamMembers, currentMember.id, data));
    closeEditModal();
  }

  async function onTeamMemberDelete() {
    try {
      setIsSaving(true);
      await API.deleteTeamMember(currentMember.id);
      setTeamMembers(removeTeamMember(teamMembers, currentMember.id));
      closeDeleteModal();
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <div className="team-member-actions-container">
      <EditTeamMemberModal
        open={showEditModal}
        onClose={closeEditModal}
        teamMember={currentMember}
        onSave={onTeamMemberUpdate}
        onChange={onChange}
        setShowDeleteModal={setShowDeleteModal}
      />
      <ConfirmModal
        header="Delete Team Member"
        content={`Are you sure you want to remove Team Member ${teamMemberName}?`}
        open={showDeleteModal}
        onCancel={closeDeleteModal}
        onConfirm={onTeamMemberDelete}
        confirmButton={
          <Button className="red" primary={false} disabled={isSaving}>
            Yes
          </Button>
        }
        cancelButton="Cancel"
      />
      <div className="buttons-container">
        <Icon
          link
          name="edit"
          onClick={() => {
            setShowEditModal(true);
          }}
        />
        <Icon
          link
          name="trash"
          color="red"
          onClick={() => {
            setShowDeleteModal(true);
          }}
        />
      </div>
    </div>
  );
}

TeamMemberActions.propTypes = propTypes;
