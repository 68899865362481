import _isEmpty from 'lodash/isEmpty';

import { sortByNavigationPriority } from './pages';
import { PAGE_GROUP_COLLECTION_PREFIX } from '../constants';

export function belongsToGroup(page, groupName) {
  const { groupName: pageGroupName } = page;
  if (!_isEmpty(groupName)) {
    return pageGroupName === groupName;
  }
  return !_isEmpty(pageGroupName);
}

export function getGroupPages(pages) {
  return sortByNavigationPriority(pages.filter((page) => belongsToGroup(page)));
}

export function removeGroupPages(pages) {
  return pages.filter((page) => !belongsToGroup(page));
}

export function getPagesForGroup(pages, groupName) {
  return pages.filter((page) => belongsToGroup(page, groupName));
}

export function fillPagesForGroups(groups, pages) {
  return groups.map((group) => {
    const { name } = group;
    const pagesForGroup = getPagesForGroup(pages, name);
    return { ...group, pages: pagesForGroup };
  });
}

export function isGroupCollectionName(collection) {
  if (_isEmpty(collection)) {
    return false;
  }
  return collection.startsWith(PAGE_GROUP_COLLECTION_PREFIX);
}

export function extractGroupNameFromCollection(collection) {
  return collection.replace(PAGE_GROUP_COLLECTION_PREFIX, '');
}

export function buildGroupCollectionName(groupName) {
  return PAGE_GROUP_COLLECTION_PREFIX + groupName;
}
