import { useState } from 'react';

import { useDispatch } from 'react-redux';

import API from '../../../../libs/api';
import { objectToCamelCase } from '../../../../libs/format';
import useAsyncEffect from '../../foundation/hooks/use-async-effect';
import {
  setAllComponentConfigurations,
  updateOneComponentConfiguration,
  addOneComponentConfiguration,
  removeOneComponentConfiguration,
  upsertChildComponentConfiguration,
} from '../slices/ComponentConfiguration.slice';

export default function useComponentConfigurations(websiteId, layoutId, pageId = null) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const data = await API.getComponentConfigurations(websiteId);
      dispatch(setAllComponentConfigurations(data));
    } catch (error) {
      setAllComponentConfigurations([]);
    } finally {
      setIsLoading(false);
    }
  }, [websiteId]);

  async function upsertComponentConfiguration(payload) {
    setIsSubmitting(true);

    const {
      id: componentConfigurationId,
      interactions,
      contentNodes,
      files,
      fastId,
      pageId: givenPageId,
      ...componentConfigurationData
    } = payload;

    let savedData = {};

    try {
      if (componentConfigurationId) {
        const { data } = await API.updateComponentConfigurationWithData(
          componentConfigurationId,
          componentConfigurationData,
          { interactions, contentNodes, files },
        );
        savedData = objectToCamelCase(data);
        dispatch(
          updateOneComponentConfiguration({
            id: componentConfigurationId,
            changes: savedData,
          }),
        );
      } else {
        const { data } = await API.createComponentConfigurationWithData(
          givenPageId || pageId,
          layoutId,
          fastId,
          componentConfigurationData,
          { interactions, contentNodes, files },
        );
        savedData = objectToCamelCase(data);
        dispatch(addOneComponentConfiguration(savedData));
        const { parentComponentConfigurationId = null } = componentConfigurationData;
        if (parentComponentConfigurationId) {
          const actionPayload = {
            id: parentComponentConfigurationId,
            childComponentConfiguration: savedData,
          };
          dispatch(upsertChildComponentConfiguration(actionPayload));
        }
      }
    } finally {
      setIsSubmitting(false);
    }
    return savedData;
  }

  async function deleteComponentConfiguration(componentConfigurationId) {
    setIsSubmitting(true);

    try {
      await API.deleteComponentConfiguration(componentConfigurationId);
      dispatch(removeOneComponentConfiguration({ id: componentConfigurationId }));
    } finally {
      setIsSubmitting(false);
    }
  }

  return {
    isLoading,
    isSubmitting,
    upsertComponentConfiguration,
    deleteComponentConfiguration,
  };
}
