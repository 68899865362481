import { useSelector } from 'react-redux';

import { getWebsiteUrl } from '../../libs/website';
import { selectBusiness } from '../../selectors';
import { selectDomains, selectIsNextWebsite, selectWebsite } from '../../selectors/website';

function useWebsiteURL() {
  const business = useSelector(selectBusiness);
  const website = useSelector(selectWebsite);
  const domains = useSelector(selectDomains);
  const isNextWebsite = useSelector(selectIsNextWebsite);

  function getBaseWebsiteURL() {
    return getWebsiteUrl(business, website, domains, isNextWebsite);
  }

  function createWebsiteURL(slug) {
    const websiteUrl = getBaseWebsiteURL();
    return `${websiteUrl}${slug}`;
  }

  return {
    getBaseWebsiteURL,
    createWebsiteURL,
  };
}

export default useWebsiteURL;
