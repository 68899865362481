import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { multiCaseGet } from './format/case';
import { STACK_STATUS_LIVE, STACK_STATUS_PREVIEW, V_LEGACY_KEY } from '../constants/constants';

export function getBusinessWebsite(business, isNextWebsite) {
  const websiteKey = isNextWebsite ? 'next_website' : 'website';

  return _get(business, websiteKey);
}

export function getWebsiteUrl(business, website, domains = [], isNextWebsite = false) {
  const stackName = multiCaseGet(website, 'cloudformationStackName');
  const stackStatus = multiCaseGet(website, 'stackStatus');

  if (isNextWebsite) {
    return `${process.env.REACT_APP_WEBSITE_UPGRADE_URL}/${stackName}/`;
  }

  if (stackStatus === STACK_STATUS_PREVIEW) {
    return `${process.env.REACT_APP_WEBSITE_PREVIEW_URL}/${stackName}/`;
  }

  if (stackStatus === STACK_STATUS_LIVE) {
    const domainsWithUrl = domains.filter(({ url }) => !_isEmpty(url));

    if (!_isEmpty(domainsWithUrl)) {
      const primaryDomain = domains.sort((domain) => {
        const isPrimary = multiCaseGet(domain, 'primaryDomain');
        return isPrimary ? -1 : 1;
      })[0];

      if (!_isEmpty(primaryDomain.url)) {
        return `https://${primaryDomain.url}`.replace(/\/?$/, '/');
      }
    }

    return `${process.env.REACT_APP_WEBSITE_PREVIEW_URL}/${stackName}/`;
  }

  return null;
}

export function isLegacyWebsite(websiteVersion) {
  return websiteVersion === V_LEGACY_KEY;
}
