import React from 'react';

import PropTypes from 'prop-types';

import './RecipeToolbar.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function RecipeToolbar({ children }) {
  return <div className="recipe-toolbar">{children}</div>;
}

RecipeToolbar.propTypes = propTypes;

export default RecipeToolbar;
