import React from 'react';

import PropTypes from 'prop-types';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ children }) => <li>{children}</li>);

const SortableList = SortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const Sortable = ({ children }) => <>{children}</>;

SortableItem.propTypes = {
  children: PropTypes.node.isRequired,
};

SortableList.propTypes = {
  children: PropTypes.node.isRequired,
};

Sortable.propTypes = {
  children: PropTypes.node.isRequired,
};

Sortable.List = SortableList;
Sortable.Item = SortableItem;

export default Sortable;
