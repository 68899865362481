import React from 'react';

import PropTypes from 'prop-types';
import {
  Button,
  Icon,
  Modal,
  Header,
  Divider,
  Loader,
  List,
  Grid,
  Message,
} from 'semantic-ui-react';

import _get from 'lodash/get';

import { ERROR_MESSAGE_TITLE } from './SummaryDetails.constants';
import CustomerDetails from './components/CustomerDetails';
import FulfillmentDetails from './components/FulfillmentDetails';
import LineItemDetails from './components/LineItemDetails';
import SummaryDetails from './components/SummaryDetails';
import useOrder from '../../../../../hooks/useOrder';

import './OrderDetailsModal.scss';

const propTypes = {
  orderId: PropTypes.string.isRequired,
};

const defaultProps = {};

export default function OrderDetailsModal({ orderId }) {
  const { loading, order, errorMessage, fetchOrder } = useOrder(orderId, false);

  const {
    merchant_customer: customer = {},
    line_items: lineItems = [],
    sub_total: subtotal,
    tax_total: taxTotal,
    tip_total: tipTotal,
    service_charge_total: feeTotal,
    fulfillment_total: fulfillmentTotal,
    discount_total: discountTotal,
    fulfillment_recipient: fulfillmentRecipient,
    fulfillment,
    total,
  } = order;
  const { first_name: firstName, last_name: lastName, email, phone } = customer;
  const {
    first_name: fulfillmentFirstName = '',
    last_name: fulfillmentLastName = '',
    street_address: streetAddress = '',
    locality = '',
    administrative_area_level_1: state = '',
    postal_code: postalCode = '',
    country = '',
  } = fulfillmentRecipient || {};
  const fulfillmentType = _get(fulfillment, 'method.type');

  return (
    <Modal
      className="order-detail-modal"
      loading={loading}
      trigger={
        <Button className="order-detail-modal-trigger">
          <Icon size="large" name="file alternate outline" />
        </Button>
      }
      dimmer="inverted"
      onOpen={fetchOrder}
    >
      <Modal.Header>Order #{orderId.slice(-4)} Details</Modal.Header>
      <Modal.Content>
        {errorMessage && (
          <Message error>
            <Message.Header>{ERROR_MESSAGE_TITLE}</Message.Header>
            <Message.Content>{errorMessage}</Message.Content>
          </Message>
        )}
        {loading && <Loader active />}
        {!loading && !errorMessage && (
          <>
            <Grid columns={2} stackable>
              <Grid.Column>
                <CustomerDetails
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phoneNumber={phone}
                />
              </Grid.Column>
              <Grid.Column>
                <FulfillmentDetails
                  fulfillmentType={fulfillmentType}
                  customer={{ firstName: fulfillmentFirstName, lastName: fulfillmentLastName }}
                  address={{
                    streetAddress,
                    locality,
                    state,
                    postalCode,
                    country,
                  }}
                />
              </Grid.Column>
            </Grid>
            <Header as="h3">Items</Header>
            <List>
              {lineItems.map(
                ({
                  item_data: { name, photo: itemPhoto },
                  item_variation_data: { price, photo: itemVariationPhoto, modifiers },
                  quantity,
                  instructions,
                }) => (
                  <LineItemDetails
                    name={name}
                    quantity={quantity}
                    price={price}
                    instructions={instructions}
                    photo={itemVariationPhoto || itemPhoto}
                    modifiers={modifiers}
                  />
                ),
              )}
            </List>
            <Divider />
            <SummaryDetails
              subtotal={subtotal}
              taxes={taxTotal}
              tip={tipTotal}
              processingFees={feeTotal}
              fulfillmentFee={fulfillmentTotal}
              discounts={discountTotal}
              total={total}
            />
          </>
        )}
      </Modal.Content>
    </Modal>
  );
}

OrderDetailsModal.prototype = propTypes;
OrderDetailsModal.defaultProps = defaultProps;
