import _find from 'lodash/find';

import { WEBSITE_LOCATION_GLOBAL } from '../../../../../../../../constants';

const DISPLAY_WEBSITE_LOCATION_GLOBAL = 'Global';

export function getWebsiteLocationOptions(websitePages = []) {
  const options = [
    {
      key: WEBSITE_LOCATION_GLOBAL,
      text: DISPLAY_WEBSITE_LOCATION_GLOBAL,
      value: WEBSITE_LOCATION_GLOBAL,
    },
  ];
  if (websitePages) {
    options.push(
      ...websitePages.map(({ id, title }) => {
        return {
          key: id,
          text: title,
          value: id,
        };
      }),
    );
  }

  return options;
}

export function getWebsiteLocationValue(options, page, websiteLocation) {
  const key = websiteLocation === WEBSITE_LOCATION_GLOBAL ? websiteLocation : page;
  const { value } = _find(options, (option) => option.key === key);
  return value;
}

export function getWebsiteLocationTextFromValue(page, websiteLocation, websitePages = []) {
  if (websiteLocation === WEBSITE_LOCATION_GLOBAL) {
    return DISPLAY_WEBSITE_LOCATION_GLOBAL;
  }

  const { title } = _find(websitePages, (websitePage) => websitePage.id === page);
  return title;
}
