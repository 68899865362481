export const createUser = (payload) => (dispatch) => {
  dispatch({
    type: 'CREATE_USER',
    payload,
  });
};

export const resetUser = (payload) => (dispatch) => {
  dispatch({
    type: 'RESET_USER',
    payload,
  });
};

export const updateUser = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_USER',
    payload,
  });
};

export const clearUserTouchedField = (payload) => (dispatch) => {
  dispatch({
    type: 'CLEAR_USER_TOUCHED_FIELD',
    payload,
  });
};
