import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  CHANNEL_EMPTY_ERROR_MESSAGE,
  EMAIL_SUBJECT_EMPTY_ERROR_MESSAGE,
  FIELDS_NOT_UNIQUE_ERROR_MESSAGE,
  HEADER_EMPTY_ERROR_MESSAGE,
} from './EditFormBlock.constants';
import { EditFormBlockContextProvider } from './EditFormBlock.context';
import {
  moveField,
  deleteField,
  insertField,
  updateFieldValue,
  createFormComponentConfiguration,
  updateFormElementFromSave,
  getFormBlock,
  isLegacyFormBlock,
  updateFormConfiguration,
  areFieldsValid,
  createContactMapping,
  deleteContactMapping,
  updateContactMapping,
} from './EditFormBlock.utils';
import {
  FormDestinationEmail,
  FormHeader,
  FormFieldList,
  FormOptIn,
  FormChannel,
  FormEmailSubject,
  FormContactMappingSelectors,
} from './components';
import { MERCHANT_BUSINESS_TYPE } from '../../../../../../../constants/constants';
import { selectBusinessType } from '../../../../../../../selectors';
import { selectBusinessInfoId } from '../../../../../../../selectors/business';
import { selectPrimaryLocationId } from '../../../../../../../selectors/locations';
import { selectWebsiteId } from '../../../../../../../selectors/website';
import { useLatestRefValue } from '../../../../../utils/hooks';
import {
  FORM_BLOCK_ELEMENT_TYPE,
  LEGACY_FORM_BLOCK_ELEMENT_TYPE,
} from '../../../../constants/types';
import { useRecipeNodeTranslator } from '../../../../hooks';
import { FormBlockPropType, LegacyFormBlockPropType } from '../../../../types/form-block.types';
import { EditPageElementModalContext } from '../../EditPageElementModal.context';

import './EditFormBlock.scss';

const propTypes = {
  element: PropTypes.oneOfType([LegacyFormBlockPropType, FormBlockPropType]).isRequired,
};

const defaultProps = {};

export default function EditFormBlock({ element }) {
  const businessType = useSelector(selectBusinessType);
  const businessInfoId = useSelector(selectBusinessInfoId);
  const websiteId = useSelector(selectWebsiteId);
  const locationId = useSelector(selectPrimaryLocationId);

  const {
    header = '',
    title: legacyHeader = '',
    channel = '',
    fields = [],
    emailSubject = '',
    consentSettings = {
      show: true,
      required: false,
    },
    contactMapping = {},
    destinationEmail = [],
  } = element;

  const {
    onUpdateElementState,
    page,
    registerCallback,
    addValidationError,
    clearValidationErrors,
  } = useContext(EditPageElementModalContext);
  const { recipeSnippetToState } = useRecipeNodeTranslator({ page });

  const isMerchant = businessType === MERCHANT_BUSINESS_TYPE;
  const latestElementRef = useLatestRefValue(element);
  const legacy = isLegacyFormBlock(element);
  const finalHeader = legacy ? legacyHeader : header;

  const context = { legacy };

  function onUpdateElementAttribute(attributeName, attributeValue) {
    let currentElement = latestElementRef.current;

    currentElement = isMerchant ? getFormBlock(currentElement) : currentElement;

    onUpdateElementState(FORM_BLOCK_ELEMENT_TYPE, {
      ...currentElement,
      [attributeName]: attributeValue,
    });
  }

  function onUpdateElementAttributes(payload) {
    let currentElement = latestElementRef.current;

    currentElement = isMerchant ? getFormBlock(currentElement) : currentElement;

    onUpdateElementState(FORM_BLOCK_ELEMENT_TYPE, {
      ...currentElement,
      ...payload,
    });
  }

  function onUpdateElementFields(nextFields, nextContactMapping = {}) {
    onUpdateElementAttributes({ fields: nextFields, contactMapping: nextContactMapping });
  }

  function onFormHeaderChange(nextTitle) {
    if (legacy && !isMerchant) {
      onUpdateElementAttribute('title', nextTitle);
    } else {
      onUpdateElementAttribute('header', nextTitle);
    }
  }

  function onInsertField(fieldIndex, fieldType, { initialData = {} } = {}) {
    const currentElement = latestElementRef.current;
    const { displayLabel } = initialData;

    const [newContactMapping, isFieldContactMapped] = createContactMapping(
      currentElement,
      displayLabel,
    );
    const newFields = insertField(currentElement, recipeSnippetToState, {
      fieldType,
      fieldIndex,
      initialData: { ...(initialData || {}), isFieldContactMapped },
    });
    onUpdateElementFields(newFields, newContactMapping);
  }

  function onUpdateFieldValue(fieldIndex, fieldKey, fieldValue) {
    const currentElement = latestElementRef.current;

    const [newContactMapping, newIsFieldContactMapped] = updateContactMapping(
      currentElement,
      fieldKey,
      fieldIndex,
      fieldValue,
    );
    const newFields = updateFieldValue(currentElement, {
      fieldIndex,
      fieldKey,
      fieldValue,
      isFieldContactMapped: newIsFieldContactMapped,
    });
    onUpdateElementFields(newFields, newContactMapping);
  }

  function onDeleteField(fieldIndex) {
    const currentElement = latestElementRef.current;

    const newFields = deleteField(currentElement, { fieldIndex });
    const newContactMapping = deleteContactMapping(currentElement, fieldIndex);
    onUpdateElementFields(newFields, newContactMapping);
  }

  function onMoveField(fieldIndex, direction) {
    const currentElement = latestElementRef.current;

    onUpdateElementFields(
      moveField(currentElement, { fieldIndex, direction }),
      currentElement.contactMapping,
    );
  }

  async function onSaveContent() {
    let currentElement = latestElementRef.current;

    if (!isMerchant) {
      return currentElement;
    }

    currentElement = getFormBlock(currentElement, {
      extraData: { businessInfoId, websiteId, locationId },
    });

    const { id: pageId } = page;
    const { componentConfigurationId } = currentElement;
    if (componentConfigurationId) {
      await updateFormConfiguration(componentConfigurationId, currentElement);
      return currentElement;
    }
    const result = await createFormComponentConfiguration(pageId, currentElement);
    return updateFormElementFromSave(currentElement, result);
  }

  useEffect(() => {
    registerCallback(FORM_BLOCK_ELEMENT_TYPE, onSaveContent);
    registerCallback(LEGACY_FORM_BLOCK_ELEMENT_TYPE, onSaveContent);
  }, []);

  useEffect(() => {
    clearValidationErrors();
    if (_isEmpty(finalHeader)) {
      addValidationError(HEADER_EMPTY_ERROR_MESSAGE);
    }
    if (!legacy) {
      if (_isEmpty(emailSubject)) {
        addValidationError(EMAIL_SUBJECT_EMPTY_ERROR_MESSAGE);
      }
      if (_isEmpty(channel)) {
        addValidationError(CHANNEL_EMPTY_ERROR_MESSAGE);
      }
    }
    if (!areFieldsValid(fields)) {
      addValidationError(FIELDS_NOT_UNIQUE_ERROR_MESSAGE);
    }
  }, [fields, finalHeader, emailSubject, channel]);

  const { show: showOptIn, required: optInRequired } = consentSettings;

  return (
    <EditFormBlockContextProvider value={context}>
      <div className="edit-form-block">
        <FormHeader value={finalHeader} onChange={onFormHeaderChange} markdown={!legacy} />
        {!legacy && <FormEmailSubject value={emailSubject} onChange={onUpdateElementAttribute} />}
        {!legacy && <FormChannel value={channel} onChange={onUpdateElementAttribute} />}
        {!legacy && (
          <FormOptIn
            show={showOptIn}
            required={optInRequired}
            onChange={onUpdateElementAttribute}
          />
        )}
        <FormFieldList
          fields={fields}
          onInsertField={onInsertField}
          onUpdateFieldValue={onUpdateFieldValue}
          onDeleteField={onDeleteField}
          onMoveField={onMoveField}
        />
        {!legacy && (
          <>
            <FormContactMappingSelectors
              fields={fields}
              contactMapping={contactMapping}
              onChange={onUpdateElementFields}
            />
            <FormDestinationEmail
              destinationEmail={destinationEmail}
              onChange={(value) => onUpdateElementAttribute('destinationEmail', value)}
            />
          </>
        )}
      </div>
    </EditFormBlockContextProvider>
  );
}

EditFormBlock.propTypes = propTypes;
EditFormBlock.defaultProps = defaultProps;
