import React from 'react';

import { Modal, Embed } from 'semantic-ui-react';

import Magnifier from 'react-magnifier';

import CloseableModal from './CloseableModal/CloseableModal';

import '../../styles/core/forms/magnifier-modal.scss';

export default class DocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState(({ open }) => ({ open: !open }));
  }

  isFileImage() {
    const { url } = this.props;
    if (!url) {
      return false;
    }

    const extension = url.toLowerCase().slice(url.length - 4);
    return ['.png', '.jpg', 'jpeg', '.gif'].includes(extension);
  }

  renderPDF() {
    const { url } = this.props;
    return <Embed url={url} active />;
  }

  renderMagnifier() {
    const { url } = this.props;
    return <Magnifier src={url} height={400} />;
  }

  render() {
    const { open } = this.state;
    return (
      <CloseableModal
        className="magnifier-modal"
        {...this.props}
        onClose={this.toggleModal}
        onOpen={this.toggleModal}
        open={open}
      >
        <Modal.Content image>
          <Modal.Description>
            {this.isFileImage() ? this.renderMagnifier() : this.renderPDF()}
          </Modal.Description>
        </Modal.Content>
      </CloseableModal>
    );
  }
}
