import React, { useState } from 'react';

import _isEmpty from 'lodash/isEmpty';

import { LOADING_MESSAGE, NO_TEAMS_MESSAGE } from './TeamsTab.constants';
import { getErrorMessage } from '../../../../../libs/errors';
import ErrorHandler from '../../../../../libs/errors/errors';
import LoadingMessage from '../../../../modules/foundation/components/LoadingMessage';
import useAsyncEffect from '../../../../modules/foundation/hooks/use-async-effect';
import AddTeamButton from '../../../../modules/team/components/TeamsTable/components/AddTeamButton/AddTeamButton';
import TeamsTable from '../../../../modules/team/components/TeamsTable/components/TeamsTable/TeamsTable';
import { TeamsContextProvider } from '../../../../modules/team/contexts/Teams.context';
import useTeam from '../../../../modules/team/hooks/use-team';
import { DismissibleMessage } from '../../../../modules/utils/components';

import './TeamsTab.scss';

const propTypes = {};

const defaultProps = {};

export default function TeamsTab() {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { teams, refreshTeams } = useTeam();

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await refreshTeams();
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const context = {
    teams,
    refreshTeams,
  };

  return (
    <TeamsContextProvider value={context}>
      <div className="teams-tab">
        {loading && <LoadingMessage message={LOADING_MESSAGE} />}
        {!loading && (
          <div className="main-content">
            {_isEmpty(teams) && <div className="empty-message">{NO_TEAMS_MESSAGE}</div>}
            <div className="table-container">
              <TeamsTable />
            </div>
            <div className="actions-container">
              <AddTeamButton />
            </div>
          </div>
        )}
        {!_isEmpty(errorMessage) && (
          <DismissibleMessage error initialVisible>
            {errorMessage}
          </DismissibleMessage>
        )}
      </div>
    </TeamsContextProvider>
  );
}

TeamsTab.propTypes = propTypes;
TeamsTab.defaultProps = defaultProps;
