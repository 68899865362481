import React from 'react';

import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { getComponentSetting } from 'components/modules/component-configuration/services/component-configuration';
import useRecipe from 'components/modules/foundation/hooks/use-recipe';

import { CURATED_HERO_COMPONENT } from '../../../hero/constants/component';
import {
  DYNAMIC_TILES_COMPONENT,
  DYNAMIC_TILES_TILE_COMPONENT,
  HERO_DEFAULT_COMPONENT_IDENTIFIERS,
} from '../../../hero/constants/custom-hero';
import { hasHeroOverrideForPage } from '../../../hero/services/custom-hero';
import { filterComponentConfigurations } from '../../../pages/services/components';
import { PagePropType } from '../../../pages/types';
import { PageComponentSettingType } from '../../../pages/types/components';
import { getPageContentNodeComponents } from '../../services/content-node';
import PageContentNode from '../PageContentNode';

import './PageContentNodes.scss';

const propTypes = {
  page: PagePropType.isRequired,
  settings: PropTypes.arrayOf(PageComponentSettingType).isRequired,
  onSave: PropTypes.func,
};

const defaultProps = {
  onSave: () => null,
};

export default function PageContentNodes({ page, settings, onSave }) {
  const { activeRecipeName } = useRecipe();

  const componentConfigurations = getPageContentNodeComponents(page);
  const isUsingCustomHero = hasHeroOverrideForPage(page);
  const { pageType } = page;

  const filteredComponentConfigurations = filterComponentConfigurations(componentConfigurations, [
    {
      paramsFilter: _get(HERO_DEFAULT_COMPONENT_IDENTIFIERS, activeRecipeName, []).map(
        (componentIdentifier) => ({
          componentIdentifier,
        }),
      ),
      shouldApplyFilters: isUsingCustomHero,
      negate: true,
    },
    {
      paramsFilter: [{ fastId: DYNAMIC_TILES_COMPONENT }, { fastId: DYNAMIC_TILES_TILE_COMPONENT }],
      negate: true,
    },
    {
      paramsFilter: [{ fastId: CURATED_HERO_COMPONENT }],
      negate: true,
    },
  ]);

  async function handleSave(contentNode, newContent, { component }) {
    if (onSave) {
      await onSave(contentNode, newContent, { component, page });
    }
  }

  return (
    <div className="page-content-nodes">
      {_isEmpty(filteredComponentConfigurations) && (
        <p className="no-content">No content can be configured for this page</p>
      )}
      {filteredComponentConfigurations.map((component) => {
        const setting = getComponentSetting(component, settings, { pageType });

        return (
          <PageContentNode
            key={component.id}
            component={component}
            setting={setting}
            onSave={handleSave}
          />
        );
      })}
    </div>
  );
}

PageContentNodes.propTypes = propTypes;
PageContentNodes.defaultProps = defaultProps;
