import React from 'react';

import PropTypes from 'prop-types';
import { Popup, Button, Header } from 'semantic-ui-react';

import { Link } from 'react-router-dom';

import PlanNavigationPopupDescription from './PlanNavigationPopupDescription';
import { getChildrenText } from '../../../../../libs/react-children';

import './PlanNavigationPopup.scss';

export default function PlanNavigationPopup({ title, children, requiredFeatureSets }) {
  return (
    <>
      <Popup
        className="plan-navigation-gating-popup"
        hoverable
        position="right center"
        trigger={children}
      >
        {title && <Header as="h1">{getChildrenText(title)}</Header>}

        <PlanNavigationPopupDescription featureSets={requiredFeatureSets} />

        <div>
          <Button
            as={Link}
            to={{
              pathname: '/account/billing',
              state: { highlightPlans: requiredFeatureSets },
            }}
            color="blue"
          >
            Upgrade
          </Button>
        </div>
      </Popup>
    </>
  );
}

PlanNavigationPopup.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  requiredFeatureSets: PropTypes.arrayOf(PropTypes.string),
};

PlanNavigationPopup.defaultProps = {
  requiredFeatureSets: [],
};
