import React from 'react';

import { Segment, Button, Container, Image } from 'semantic-ui-react';

import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import { connect } from 'react-redux';

import {
  updateProviderServiceSetting as updateProviderServiceSettingConnect,
  deleteProviderServiceSetting as deleteProviderServiceSettingConnect,
  addNewProviderServiceSetting as addNewProviderServiceSettingConnect,
} from '../../../../actions/business';
import { CHOWNOW_SALES_GROUP_NAME, ROLE_SALES } from '../../../../constants/auth';
import { isAdminUser, isAuthorizedForRole, isUserInGroup } from '../../../../libs/auth';
import { selectUserRoleForBusiness } from '../../../../selectors/business';
import { InlineFieldContainer } from '../../../common';
import FormContainer from '../../../common/FormContainer';
import IntegrationForm from '../../IntegrationForm';

import ChowNowLogo from '../../../../static/logos/chownow-logo.png';

const mapDispatchToProps = (dispatch) => ({
  addNewProviderServiceSetting: (payload) => dispatch(addNewProviderServiceSettingConnect(payload)),
  deleteProviderServiceSetting: (payload) => dispatch(deleteProviderServiceSettingConnect(payload)),
  updateProviderServiceSetting: (payload) => dispatch(updateProviderServiceSettingConnect(payload)),
});

const mapStateToProps = ({ business, user }) => ({
  user: _get(user, 'core.value'),
  userRole: selectUserRoleForBusiness({ business }),
  providerServices: _get(business, 'provider_services.value'),
  providerServiceSettings: _get(business, 'provider_service_settings.value'),
});

class ChowNowIntegration extends React.Component {
  constructor(props) {
    super(props);

    this.addIntegration = this.addIntegration.bind(this);
    this.getChowNowProviders = this.getChowNowProviders.bind(this);
    this.getChowNowSetting = this.getChowNowSetting.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleCreate(providerServiceSetting) {
    const { addNewProviderServiceSetting } = this.props;
    addNewProviderServiceSetting(providerServiceSetting);
  }

  handleChange(integration, formData, index) {
    const { updateProviderServiceSetting } = this.props;
    const updatedProviderServiceSettingPayload = {
      index,
      field: 'service_data',
      fieldValue: formData,
    };

    updateProviderServiceSetting(updatedProviderServiceSettingPayload);
  }

  handleDelete(provider, serviceType) {
    const { providerServiceSettings, deleteProviderServiceSetting } = this.props;

    const settingIndex = _findIndex(
      providerServiceSettings,
      (integration) =>
        integration.provider === provider && integration.service_type === serviceType,
    );

    if (settingIndex >= 0) deleteProviderServiceSetting(settingIndex);
  }

  getChowNowProviders() {
    const { providerServices = [], providerServiceSettings = [] } = this.props;
    return providerServices.filter((ps) => {
      if (ps.provider !== 'ChowNow') return false;

      const index = _findIndex(providerServiceSettings, (integration) => {
        const sameProvider = integration.provider === ps.provider;
        const sameType = integration.service_type === ps.service_type;
        return sameProvider && sameType;
      });

      return index === -1 && _get(ps, 'service_data_schema.properties');
    });
  }

  getChowNowProviderService() {
    const { providerServices = [] } = this.props;
    return providerServices.find((ps) => {
      return ps.provider === 'ChowNow' && ps.service_type === 'Online Ordering';
    });
  }

  /**
   * Used to get provider service settings that can be configured here
   */
  getChowNowSetting() {
    const { providerServiceSettings = [] } = this.props;

    return providerServiceSettings
      .map((pss, index) => ({ ...pss, globalIndex: index }))
      .find((pss) => !pss.provider || pss.provider === 'ChowNow');
  }

  getIntegrationForm(serviceSetting, availableProviders) {
    if (!serviceSetting || !availableProviders) return null;

    return (
      <IntegrationForm
        {...serviceSetting}
        key={serviceSetting.id || serviceSetting.localId}
        index={serviceSetting.globalIndex}
        availableProviders={availableProviders}
        handleChange={(data) => this.handleChange(serviceSetting, data, serviceSetting.globalIndex)}
        handleDelete={this.handleDelete}
      />
    );
  }

  addIntegration() {
    const service = this.getChowNowProviderService();

    this.handleCreate({
      provider_service: service.id,
      provider: service.provider,
      service_type: service.service_type,
      service_data: {},
      status_data: {},
      service_data_schema: service.service_data_schema,
    });
  }

  render() {
    const { user, userRole } = this.props;
    const showChowNow =
      (isAuthorizedForRole(userRole, ROLE_SALES) && isUserInGroup(CHOWNOW_SALES_GROUP_NAME)) ||
      isAdminUser(user);
    const chownowProvider = this.getChowNowProviderService();
    const serviceSetting = this.getChowNowSetting();
    const availableProviders = this.getChowNowProviders();

    if (!showChowNow || !chownowProvider) return null;

    return (
      <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
        <InlineFieldContainer title={<h2>ChowNow</h2>} enableDelete={false}>
          <Segment.Group>
            <Segment>
              <Container>
                <Container textAlign="center">
                  <Image
                    src={ChowNowLogo}
                    style={{
                      display: 'inline',
                      margin: '20px',
                      height: '50px',
                    }}
                  />
                </Container>
                {this.getIntegrationForm(serviceSetting, availableProviders)}
              </Container>
            </Segment>
            {!!availableProviders.length && (
              <Segment attached="top">
                <Container textAlign="center">
                  <Button size="big" onClick={this.addIntegration} primary>
                    Setup Integration
                  </Button>
                </Container>
              </Segment>
            )}
          </Segment.Group>
        </InlineFieldContainer>
      </FormContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChowNowIntegration);
