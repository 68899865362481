import PropTypes from 'prop-types';

const PartialProptypes = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  schemaVersion: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

export default PartialProptypes;
