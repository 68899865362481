import React, { useEffect, useState } from 'react';

import { Form, Icon, Loader } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import { SHIPPING_PROVIDER_LOGO_PATH } from './OrderSettings.constants';
import { setAllOrderSettings } from './OrderSettings.slice';
import { filterShippoShippingSettings, isOrderSettingsAvailable } from './OrderSettings.utils';
import AcceptableFulfillmentTypes from './components/AcceptableFulfillmentTypes';
import FulfillmentConfiguration from './components/FulfillmentConfiguration';
import OrderSettingForm from './components/OrderSettingForm';
import ProviderButton from './components/ProviderButton';
import {
  deleteProviderServiceSetting,
  initializeProviderServiceSettings,
} from '../../../../../actions/business';
import {
  FULFILLMENT_PROVIDER_SHIPPO,
  FULFILLMENT_PROVIDER_TO_LABEL,
  FULFILLMENT_TYPE_INSTORE,
  FULFILLMENT_TYPE_SHIPPING,
  FULFILLMENT_PROVIDER_MERCHANT,
} from '../../../../../constants/fulfillments';
import API from '../../../../../libs/api';
import {
  getProviderConnectMethod,
  getProviderDisconnectMethod,
} from '../../../../../libs/provider-service-settings';
import {
  selectBusinessId,
  selectBusinessType,
  selectActiveProduct,
  selectAllProviderServiceSettings,
} from '../../../../../selectors';
import ButtonSubmit from '../../../../common/ButtonSubmit';
import useAsyncEffect from '../../../../modules/foundation/hooks/use-async-effect';
import LocationSelect from '../../common/LocationSelect/LocationSelect';
import { useProviders } from '../OrderSettings.hooks';

import './OrderSettings.scss';

export default function OrderSettings() {
  const dispatch = useDispatch();
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);

  const activeProduct = useSelector(selectActiveProduct);

  const [acceptableFulfillmentTypes, setAcceptableFulfillmentTypes] = useState([]);

  // Provider buttons
  const providerServiceSettings = useSelector(selectAllProviderServiceSettings);
  const [shippoShippingSettings, setShippoShippingSettings] = useState(undefined);

  const { isProviderSelected, toggleProvider, setProviders } = useProviders([]);

  // Loading status
  const [loadingOrderSettings, setLoadingOrderSettings] = useState(true);

  // Accordions
  const [accordionOpen, setAccordionOpen] = useState({
    [FULFILLMENT_TYPE_INSTORE]: false,
    [FULFILLMENT_TYPE_SHIPPING]: false,
  });

  function toggleAccordionOpen(name) {
    setAccordionOpen((prevAccordionOpen) => ({
      ...prevAccordionOpen,
      [name]: !prevAccordionOpen[name],
    }));
  }

  function openAccordionAndCloseOthers(name) {
    setAccordionOpen((prevAccordionOpen) => {
      return Object.keys(prevAccordionOpen).reduce((newAccordionOpen, accordionName) => {
        return {
          ...newAccordionOpen,
          [accordionName]: accordionName === name,
        };
      }, {});
    });
  }

  useAsyncEffect(async () => {
    setAcceptableFulfillmentTypes([]);
    setLoadingOrderSettings(true);
    try {
      const { data: orderSettingsData } = await API.getOrderSettings({ product: activeProduct.id });
      const { data: providerServiceSettingsData } = await API.getProviderServiceSettings(
        businessId,
        businessType,
      );

      dispatch(initializeProviderServiceSettings(providerServiceSettingsData));
      const initialAcceptableFulfillmentTypes = [];
      if (isOrderSettingsAvailable(orderSettingsData, FULFILLMENT_TYPE_INSTORE)) {
        initialAcceptableFulfillmentTypes.push(FULFILLMENT_TYPE_INSTORE);
        toggleAccordionOpen(FULFILLMENT_TYPE_INSTORE);
      }
      if (isOrderSettingsAvailable(orderSettingsData, FULFILLMENT_TYPE_SHIPPING)) {
        initialAcceptableFulfillmentTypes.push(FULFILLMENT_TYPE_SHIPPING);
        toggleAccordionOpen(FULFILLMENT_TYPE_SHIPPING);
      }
      setAcceptableFulfillmentTypes(initialAcceptableFulfillmentTypes);
      dispatch(setAllOrderSettings(orderSettingsData));
    } catch (e) {
      dispatch(setAllOrderSettings([]));
    } finally {
      setLoadingOrderSettings(false);
    }
  }, []);

  useEffect(() => {
    const shippoShippingSettingsData = filterShippoShippingSettings(providerServiceSettings);
    const shippoShippingSettingsExist = !_isEmpty(shippoShippingSettingsData);

    if (shippoShippingSettingsExist) {
      setProviders((prevProviders) => [...prevProviders, FULFILLMENT_PROVIDER_SHIPPO]);
      setShippoShippingSettings(shippoShippingSettingsData);
      setAcceptableFulfillmentTypes((prevFulfillmentTypes) => [
        ...prevFulfillmentTypes,
        FULFILLMENT_TYPE_SHIPPING,
      ]);
    } else {
      setShippoShippingSettings(undefined);
    }
  }, [providerServiceSettings]);

  function fulfillmentTypeIsSelected(fulfillmentType) {
    return acceptableFulfillmentTypes.includes(fulfillmentType);
  }

  function toggleFulfillmentType(fulfillmentType) {
    if (acceptableFulfillmentTypes.includes(fulfillmentType)) {
      setAcceptableFulfillmentTypes((prevFulfillmentTypes) =>
        prevFulfillmentTypes.filter((type) => type !== fulfillmentType),
      );
    } else {
      setAcceptableFulfillmentTypes((prevFulfillmentTypes) => [
        ...prevFulfillmentTypes,
        fulfillmentType,
      ]);
      openAccordionAndCloseOthers(fulfillmentType);
    }
  }

  async function handleConnect(shippingProvider) {
    const providerConnect = getProviderConnectMethod(shippingProvider, {
      businessType,
      businessId,
    });
    providerConnect();
  }

  async function handleDisconnect(shippingProvider) {
    const disconnectServiceSettings = (settings) =>
      dispatch(deleteProviderServiceSetting(settings.globalIndex));
    const providerDisconnect = getProviderDisconnectMethod(
      shippingProvider,
      disconnectServiceSettings,
      shippoShippingSettings,
      {
        businessType,
        businessId,
      },
    );
    providerDisconnect();
  }

  const isLoading = !activeProduct || loadingOrderSettings;

  return (
    <div className="order-settings-form">
      <LocationSelect global />
      {isLoading ? (
        <Loader active />
      ) : (
        <>
          <AcceptableFulfillmentTypes
            selectedTypes={acceptableFulfillmentTypes}
            toggleType={toggleFulfillmentType}
          />
          {fulfillmentTypeIsSelected(FULFILLMENT_TYPE_INSTORE) && (
            <FulfillmentConfiguration>
              <FulfillmentConfiguration.Title
                active={accordionOpen[FULFILLMENT_TYPE_INSTORE]}
                onClick={() => toggleAccordionOpen(FULFILLMENT_TYPE_INSTORE)}
              >
                Pickup Configuration
                <Icon
                  className="accordion-status"
                  name={`${accordionOpen[FULFILLMENT_TYPE_INSTORE] ? 'minus' : 'plus'} circle`}
                />
              </FulfillmentConfiguration.Title>
              <FulfillmentConfiguration.Content active={accordionOpen[FULFILLMENT_TYPE_INSTORE]}>
                <OrderSettingForm
                  provider={FULFILLMENT_PROVIDER_MERCHANT}
                  type={FULFILLMENT_TYPE_INSTORE}
                />
              </FulfillmentConfiguration.Content>
            </FulfillmentConfiguration>
          )}
          {fulfillmentTypeIsSelected(FULFILLMENT_TYPE_SHIPPING) && (
            <FulfillmentConfiguration>
              <FulfillmentConfiguration.Title
                active={accordionOpen[FULFILLMENT_TYPE_SHIPPING]}
                onClick={() => toggleAccordionOpen(FULFILLMENT_TYPE_SHIPPING)}
              >
                Shipping Configuration
                <Icon
                  className="accordion-status"
                  name={`${accordionOpen[FULFILLMENT_TYPE_SHIPPING] ? 'minus' : 'plus'} circle`}
                />
              </FulfillmentConfiguration.Title>
              <FulfillmentConfiguration.Content active={accordionOpen[FULFILLMENT_TYPE_SHIPPING]}>
                <div className="shipping-providers-buttons">
                  <Form.Group>
                    <ProviderButton
                      logo={SHIPPING_PROVIDER_LOGO_PATH[FULFILLMENT_PROVIDER_SHIPPO]}
                      providerName={FULFILLMENT_PROVIDER_TO_LABEL[FULFILLMENT_PROVIDER_SHIPPO]}
                      isSelected={isProviderSelected(FULFILLMENT_PROVIDER_SHIPPO)}
                      onClick={() => toggleProvider(FULFILLMENT_PROVIDER_SHIPPO)}
                    />
                  </Form.Group>
                  {isProviderSelected(FULFILLMENT_PROVIDER_SHIPPO) &&
                    _isEmpty(shippoShippingSettings) && (
                      <ButtonSubmit
                        className="shipping-button"
                        content="Connect"
                        onClick={() => handleConnect(FULFILLMENT_PROVIDER_SHIPPO)}
                      />
                    )}
                  {isProviderSelected(FULFILLMENT_PROVIDER_SHIPPO) &&
                    !_isEmpty(shippoShippingSettings) && (
                      <ButtonSubmit
                        className="shipping-button"
                        content="Disconnect"
                        onClick={() => handleDisconnect(FULFILLMENT_PROVIDER_SHIPPO)}
                      />
                    )}
                  {isProviderSelected(FULFILLMENT_PROVIDER_SHIPPO) && (
                    <OrderSettingForm
                      provider={FULFILLMENT_PROVIDER_SHIPPO}
                      type={FULFILLMENT_TYPE_SHIPPING}
                    />
                  )}
                </div>
              </FulfillmentConfiguration.Content>
            </FulfillmentConfiguration>
          )}
        </>
      )}
    </div>
  );
}
