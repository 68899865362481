import React from 'react';

import PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import CustomerSubmissionsModalTableRow from './CustomerSubmissionsModalTableRow';

const propTypes = {
  headerText: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired,
  showFieldType: PropTypes.bool,
};

const defaultProps = {
  showFieldType: false,
};

export default function CustomerSubmissionsModalTable({ headerText, showFieldType, data }) {
  if (_isEmpty(data)) {
    return null;
  }

  return (
    <>
      {headerText && <Header as="h2">{headerText}</Header>}
      <Table celled striped>
        {showFieldType && (
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Field</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>
          {data.map(({ name, label, value, inputType, widgetType }) => {
            return (
              <CustomerSubmissionsModalTableRow
                fieldName={name}
                fieldLabel={label}
                fieldValue={value}
                fieldType={inputType}
                widgetType={widgetType}
                showFieldType={showFieldType}
              />
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
}

CustomerSubmissionsModalTable.propTypes = propTypes;
CustomerSubmissionsModalTable.defaultProps = defaultProps;
