import React from 'react';

import { Grid } from 'semantic-ui-react';

import {
  AddHtmlElementButton,
  HtmlElementsFilterButton,
  HtmlElementsFilterModal,
} from './components';

import './HtmlElementsToolBar.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsToolBar() {
  return (
    <Grid className="html-elements-toolbar" stackable columns={2}>
      <Grid.Column>
        <AddHtmlElementButton />
      </Grid.Column>
      <Grid.Column className="html-elements-toolbar-filter-column">
        <HtmlElementsFilterButton />
        <HtmlElementsFilterModal />
      </Grid.Column>
    </Grid>
  );
}

HtmlElementsToolBar.propTypes = propTypes;
HtmlElementsToolBar.defaultProps = defaultProps;
