import React from 'react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { updateUser as updateUserConnect } from '../../../actions/user';
import { isValidUser, hasToChangePassword } from '../../../libs/auth';
import WithRouter from '../../modules/foundation/components/WithRouter';
import ForgotConfirmForm from '../ForgotConfirmForm';
import LoginBanner from '../LoginBanner';

import './CreatePasswordPage.scss';
import '../../../styles/core/pages/login-forgot-confirm.scss';

class CreatePasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onSkip = this.onSkip.bind(this);
  }

  onSkip() {
    const { navigate, updateUser } = this.props;
    updateUser({
      field: 'skip_change_password',
      value: true,
      bypassTouch: true,
    });
    navigate('/');
  }

  onSuccess(data) {
    const { navigate, updateUser } = this.props;
    updateUser({
      field: 'change_password',
      value: data.change_password,
      bypassTouch: true,
    });
    navigate('/');
  }

  render() {
    const { user, navigate } = this.props;
    if (!isValidUser(user) || !hasToChangePassword(user)) {
      navigate('/');
    }

    return (
      <div className="create-password-form forgot-confirm-page">
        <LoginBanner />
        <div className="login-form-container">
          <ForgotConfirmForm isFirstSignIn onSuccess={this.onSuccess} onSkip={this.onSkip} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (payload) => dispatch(updateUserConnect(payload)),
});

const mapStateToProps = ({ user }) => ({
  user: _get(user, 'core.value', {}),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(CreatePasswordPage));
