import React from 'react';

import { Button, Input, Item } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import withCopyToClipboard from '../../common/CopyToClipboard/CopyToClipboard';
import useRecipe from '../../modules/foundation/hooks/use-recipe';

import './SupportInfo.scss';

const CopyToClipboard = withCopyToClipboard(Button);

function getAction(actionType, value, disabled = false) {
  if (actionType === 'link') {
    return (
      <Button
        type="submit"
        icon="external alternate"
        onClick={() => window.open(value)}
        disabled={disabled}
      />
    );
  }

  return <CopyToClipboard icon="copy" value={value} disabled={disabled} />;
}

function getStripeURL(stripeCustomerId) {
  if (process.env.REACT_APP_FISHERMAN_ENV === 'production') {
    return `https://dashboard.stripe.com/customers/${stripeCustomerId}`;
  }
  return `https://dashboard.stripe.com/test/customers/${stripeCustomerId}`;
}

const SupportInfo = ({ business, website }) => {
  const { activePatch } = useRecipe();
  const {
    baseRecipe: { name: recipeName, schemaVersion, recipeVersion },
    patchVersion,
  } = activePatch;

  const businessInfoData = [
    {
      label: 'ID',
      value: business.id,
      disabled: true,
    },
    {
      label: 'Product Type',
      value: business.product_type,
      disabled: true,
    },
    {
      label: 'Stripe Customer ID',
      value: business.stripe_customer_id,
    },
    {
      label: 'Stripe Customer URL',
      value: getStripeURL(business.stripe_customer_id),
      actionType: 'link',
      disabled: true,
    },
    {
      label: 'Stripe Subscription Plan',
      value: business.stripe_subscription_plan,
    },
    {
      label: 'Lead From',
      value: business.lead_from,
      disabled: true,
    },
  ];

  const websiteInfoData = [
    {
      label: 'Current Recipe',
      value: recipeName,
      disabled: true,
    },
    {
      label: 'Recipe, Schema & Patch Versions',
      value: `${recipeVersion}, ${schemaVersion}, ${patchVersion}`,
      disabled: true,
    },
    {
      label: 'Cloudformation Stack Name',
      value: website.cloudformation_stack_name,
      disabled: true,
    },
    {
      label: 'Website Version',
      value: website.workspace_version,
      disabled: true,
    },
    {
      label: 'Stack Status',
      value: website.stack_status,
      disabled: true,
    },
    {
      label: 'Stack Type',
      value: website.stack_type,
      disabled: true,
    },
    {
      label: 'Active',
      value: website.active ? 'Yes' : 'No',
      disabled: true,
    },
    {
      label: 'Google Analytics Account ID',
      value: website.google_analytics_account_id,
    },
  ];

  return (
    <div className="support-info">
      <h1>Business Info</h1>
      <Item.Group>
        {businessInfoData.map((info) => (
          <Item
            key={info.label}
            header={info.label}
            description={
              <Item.Description>
                <Input
                  actionPosition="right"
                  input={<Input disabled={info.disabled} />}
                  defaultValue={info.value}
                  action
                >
                  <input disabled={info.disabled} />
                  {getAction(info.actionType, info.value)}
                </Input>
              </Item.Description>
            }
          />
        ))}
      </Item.Group>

      <h1>Website Info</h1>
      <Item.Group>
        {websiteInfoData.map((info) => (
          <Item
            key={info.label}
            header={info.label}
            description={
              <Item.Description>
                <Input input={<Input />} defaultValue={info.value} action>
                  <input disabled={info.disabled} />
                  {getAction(info.actionType, info.value)}
                </Input>
              </Item.Description>
            }
          />
        ))}
      </Item.Group>
    </div>
  );
};

const mapStateToProps = (state) => ({
  business: _get(state, 'business.core.value'),
  website: _get(state, 'website.core.value'),
});

export default connect(mapStateToProps)(SupportInfo);
