import React from 'react';

import PropTypes from 'prop-types';

const propTypes = {
  categoryContent: PropTypes.node.isRequired,
  resultsContent: PropTypes.node.isRequired,
};

const NodeResultLayout = ({ categoryContent, resultsContent }) => (
  <div className="search-results-container">
    <h3 className="name">{categoryContent}</h3>
    <div className="results">{resultsContent}</div>
  </div>
);

NodeResultLayout.propTypes = propTypes;

export default NodeResultLayout;
