import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { initializeStyleRules } from '../../../../../actions/website';
import API from '../../../../../libs/api';
import { selectWebsiteId } from '../../../../../selectors/website';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';
import { selectStyleRules } from '../../selectors';

export default function useStyleRules() {
  const [loading, setLoading] = useState(false);

  const styleRules = useSelector(selectStyleRules);
  const websiteId = useSelector(selectWebsiteId);
  const dispatch = useDispatch();

  async function getStyleRules() {
    try {
      setLoading(true);
      const { data: newStyleRules } = await API.getStyleRules(websiteId);
      dispatch(initializeStyleRules(newStyleRules));
    } finally {
      setLoading(false);
    }
  }

  useAsyncEffect(getStyleRules, []);

  return { styleRules, loading };
}
