import React from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

const propTypes = {};
const defaultProps = {};

// taken from https://reactrouter.com/en/main/start/faq#what-happened-to-withrouter-i-need-it
// this component should not be used, and hooks must be preferred over a HOC approach
export default function WithRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const router = { location, navigate, params };

    return <Component {...props} {...router} />;
  }

  return ComponentWithRouterProp;
}

WithRouter.propTypes = propTypes;
WithRouter.defaultProps = defaultProps;
