export const setProducts = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_PRODUCTS',
    payload,
  });
};

export const setActiveProduct = (payload) => (dispatch) => {
  dispatch({
    type: 'SET_ACTIVE_PRODUCT',
    payload,
  });
};
