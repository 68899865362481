export const HELP_TOOLTIP_MESSAGE =
  "A brief 160-character summary, enhance search visibility and click-through rates by accurately presenting the page's content in search results. If left empty, the default placeholder value is used.";

export const MAX_LENGTH = 300;
export const MAX_INPUT_LENGTH_WARNING = 170;
export const MIN_INPUT_LENGTH_WARNING = 70;
export const LENGTH_BUFFER = 6;

export const DESCRIPTION_TOO_SHORT_MESSAGE = `Your description is too short according to best practices. Min length is ${MIN_INPUT_LENGTH_WARNING} characters.`;
export const DESCRIPTION_TOO_LONG_MESSAGE = `Your description is too long according to best practices. Max length is ${MAX_INPUT_LENGTH_WARNING} characters.`;

export const HIDE_WARNING_TIMEOUT = 8000;
export const DEFAULT_PLACEHOLDER = 'SEO Description';
export const DEFAULT_NAME = 'seoDescription';
