import { useDispatch } from 'react-redux';

import API from '../../../../../libs/api';
import { PAGE_LOCATION_OPTIONS, USER_FRIENDLY_FIELD_MAP } from '../../constants';
import { handleChangeHtmlElement, removeHtmlElement } from '../../slices/html-elements';

export default function useHtmlElements() {
  const dispatch = useDispatch();

  async function deleteItem(item) {
    const { id, localId } = item;

    dispatch(removeHtmlElement(id || localId));

    if (id) {
      await API.deleteHtmlElement(id);
    }
  }

  async function saveHtmlElement(item) {
    try {
      const {
        id,
        name,
        createdBy,
        contents,
        enabled,
        domLocation,
        websiteLocation,
        page,
        website,
      } = item;
      const payload = {
        id,
        website,
        created_by: createdBy,
        name,
        contents,
        enabled,
        dom_location: domLocation,
        website_location: websiteLocation,
        page: websiteLocation === PAGE_LOCATION_OPTIONS.Global ? null : page,
      };
      await API.saveHtmlElement(website, payload);
    } catch (e) {
      if (e.response.data) {
        const combinedMessage = Object.keys(e.response.data)
          .map((field) => {
            const message = e.response.data[field];
            const userFriendlyField = USER_FRIENDLY_FIELD_MAP[field];
            throw new Error(`${userFriendlyField}: ${message}`);
          })
          .join('\n');
        throw new Error(combinedMessage);
      } else {
        throw new Error(e.message);
      }
    }
  }

  function saveItemState(originalItem, updatedItem) {
    dispatch(
      handleChangeHtmlElement({
        htmlElement: originalItem,
        updates: {
          ...originalItem,
          ...updatedItem,
        },
      }),
    );
  }

  function undoItemState(originalItem) {
    dispatch(
      handleChangeHtmlElement({
        htmlElement: originalItem,
        updates: {
          ...originalItem,
        },
      }),
    );
  }

  return {
    deleteItem,
    saveHtmlElement,
    saveItemState,
    undoItemState,
  };
}
