import React, { useContext } from 'react';

import { Form } from 'semantic-ui-react';

import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

export default function BannerButtonText() {
  const { bannerButtonText, popupEnabled, setValue } = useContext(AnnouncementFormContext);

  return (
    <Form.Input
      disabled={!popupEnabled}
      label="Button Label"
      value={bannerButtonText}
      onChange={(e, { value }) => setValue('bannerButtonText', value)}
    />
  );
}
