import React from 'react';

import PropTypes from 'prop-types';

import {
  ensureInteractions,
  getInteractionTitle,
} from './WebsiteComponentInteractionContainer.utils';
import WebsiteComponentInteractionWidget from '../WebsiteComponentInteractionWidget';
import { InternalWebsiteComponentInteractionPropType } from '../proptypes';

import './WebsiteComponentInteractionContainer.scss';

const propTypes = {
  onUpdate: PropTypes.func.isRequired,
  interactions: PropTypes.arrayOf(InternalWebsiteComponentInteractionPropType),
  extraBehaviors: PropTypes.arrayOf(PropTypes.string),
  maxAllowedInteractions: PropTypes.number,
};

const defaultProps = {
  interactions: [],
  extraBehaviors: [],
  maxAllowedInteractions: 2,
};

function WebsiteComponentInteractionContainer({
  interactions,
  onUpdate,
  extraBehaviors,
  maxAllowedInteractions,
}) {
  const neededInteractions = ensureInteractions(interactions, maxAllowedInteractions);

  function onUpdateInteraction({ index, changes }) {
    if (neededInteractions.length <= index) {
      const newInteraction = changes;
      onUpdate([...neededInteractions, newInteraction]);
    } else {
      const updatedInteractions = neededInteractions.map((interaction, i) => {
        if (i === index) {
          return { ...interaction, ...changes };
        }
        return interaction;
      });
      onUpdate(updatedInteractions);
    }
  }

  return (
    <div className="website-component-interaction-container">
      {neededInteractions.map((interaction, index) => (
        <WebsiteComponentInteractionWidget
          key={interaction.id || interaction.localId || interaction.local_id}
          websiteComponentInteraction={interaction}
          title={getInteractionTitle(interaction, index + 1)}
          onChange={(changes) => onUpdateInteraction({ index, changes })}
          extraBehaviors={extraBehaviors}
        />
      ))}
    </div>
  );
}

WebsiteComponentInteractionContainer.propTypes = propTypes;
WebsiteComponentInteractionContainer.defaultProps = defaultProps;

export default WebsiteComponentInteractionContainer;
