import React from 'react';

import clsx from 'clsx';

import './Dots.scss';

export default function Dots({
  size = '5px',
  columnGap = '0.4em',
  rowGap = '0.8em',
  style,
  disabled = false,
}) {
  return (
    <div
      className={clsx({ 'dot-container': true, disabled })}
      style={{ fontSize: size, columnGap, rowGap, ...style }}
    >
      <div className="dot" name="circle" />
      <div className="dot" name="circle" />
      <div className="dot" name="circle" />
      <div className="dot" name="circle" />
      <div className="dot" name="circle" />
      <div className="dot" name="circle" />
    </div>
  );
}
