import _get from 'lodash/get';

import { DEFAULT_IMAGE_BLOCK_CLASS_NAME, IMAGE_BLOCK_PAGE_CONTENT_QUERY } from './constants';
import { IMAGE_BLOCK_ELEMENT_TYPE, IMAGE_COMPONENT_NAME } from '../../../../constants/types';
import { createFishermanComponent, getComponentId } from '../utils/components';
import { getFunctionArgValue } from '../utils/functions';
import {
  createFinalClassName,
  getCollectionPropValue,
  getComponentClassName,
  createProp,
  createComponentBaseProps,
} from '../utils/props';
import { createPageComponentQuery, getComponentFiles } from '../utils/queries';

export function createSingleImageBlockElement(data = {}, context = {}) {
  const { id, style, className, componentName, componentIdentifier } = data;
  const { page = {}, isTemplate = false } = context;
  const { id: pageId } = page || {};

  const finalClassName = createFinalClassName(className, DEFAULT_IMAGE_BLOCK_CLASS_NAME);
  const finalPageId = isTemplate ? null : pageId;

  return createFishermanComponent(IMAGE_COMPONENT_NAME, id, [
    ...createComponentBaseProps(finalClassName, style),
    createProp(
      'src',
      {
        type: 'FUNCTION',
        value: {
          name: 'getComponentFiles',
          requires: '../utils/utils',
          importType: 'OBJECT',
          args: [
            {
              name: 'components',
              value: createPageComponentQuery(finalPageId, IMAGE_BLOCK_PAGE_CONTENT_QUERY),
            },
            {
              name: 'componentName',
              value: componentName,
            },
            {
              name: 'componentIdentifier',
              value: componentIdentifier || null,
            },
            {
              name: 'numToSelect',
              value: 1,
            },
          ],
        },
      },
      true,
    ),
  ]);
}

export function createSingleImageBlockState(recipeSnippet = {}, context = {}) {
  const { props } = recipeSnippet || {};
  const { state, page = {} } = context;
  const { id: pageId } = page;

  const type = IMAGE_BLOCK_ELEMENT_TYPE;
  const widgetType = IMAGE_COMPONENT_NAME;
  const id = getComponentId(recipeSnippet);
  const className = getComponentClassName(props, DEFAULT_IMAGE_BLOCK_CLASS_NAME);
  const style = getCollectionPropValue(props, 'style') || {};

  const imagesFunc = getCollectionPropValue(props, 'src');
  const componentName = getFunctionArgValue(imagesFunc, 'componentName');
  const componentIdentifier = getFunctionArgValue(imagesFunc, 'componentIdentifier');
  const contentNodeOptions = {
    componentName,
    componentIdentifier,
    pageId,
  };
  const [component, files] = getComponentFiles(contentNodeOptions, state);
  const componentConfigurationId = _get(component, 'id', null);

  return {
    id,
    className,
    style,
    type,
    widgetType,
    componentConfigurationId,
    componentName,
    componentIdentifier,
    images: files,
  };
}
