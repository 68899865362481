import React, { useContext } from 'react';

import { Form } from 'semantic-ui-react';

import MarkdownField from '../../../../fields/MarkdownField';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

export default function PopupContent() {
  const { content, setValue } = useContext(AnnouncementFormContext);

  return (
    <Form.Field>
      <label htmlFor="popup-content">Content</label>
      <MarkdownField
        id="popup-content"
        value={content}
        onChange={(value) => setValue('content', value)}
      />
    </Form.Field>
  );
}
