import React from 'react';

import PropTypes from 'prop-types';
import { Pagination, Table } from 'semantic-ui-react';

const propTypes = {
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

function CustomerReportsTableFooter({ onPageChange, currentPage, totalPages }) {
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan="12">
          <Pagination
            onPageChange={onPageChange}
            activePage={currentPage}
            totalPages={totalPages}
          />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
}

CustomerReportsTableFooter.propTypes = propTypes;

export default CustomerReportsTableFooter;
