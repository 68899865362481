import React, { useEffect, useContext } from 'react';

import { Sidebar, Menu, Segment, Icon } from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { MERCHANT_BUSINESS_TYPE } from '../../../../../../constants/constants';
import NavigationContext from '../../../../../routes/DashboardRoute/DashboardRoute.context';
import SectionSelect from '../../../SectionSelect';
import SideNavigationItems from '../SideNavigationContent';

import './MobileSideNavigation.scss';

export default function MobileSideNavigation({ rootRoutePath }) {
  const location = useLocation();
  const { eCommerce: eCommerceEnabled } = useFlags();
  const { showMobileNavigation, setShowMobileNavigation } = useContext(NavigationContext);
  const businessType = useSelector((state) => state.business.core.value.type);
  const showProductSelector = eCommerceEnabled && businessType === MERCHANT_BUSINESS_TYPE;

  function hideNavigation() {
    setShowMobileNavigation(false);
  }

  useEffect(() => {
    hideNavigation();
  }, [location]);

  return (
    <div className="mobile-side-navigation">
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="overlay"
          onHide={hideNavigation}
          vertical
          visible={showMobileNavigation}
        >
          <>
            <div className="back-arrow-container">
              <Icon name="angle left" size="big" onClick={hideNavigation} />
            </div>
            {showProductSelector && <SectionSelect />}
            <SideNavigationItems rootRoutePath={rootRoutePath} />
          </>
        </Sidebar>
      </Sidebar.Pushable>
    </div>
  );
}
