import React, { Component } from 'react';

import { Dropdown } from 'semantic-ui-react';

import _ from 'lodash';

import { typeRequiredKeys } from '../utils/types';

export default class NodeTypeFilter extends Component {
  static getOptions() {
    return _.keys(typeRequiredKeys).map((key) => ({
      key,
      text: _.startCase(key),
      value: key,
    }));
  }

  render() {
    const { selectedTypes, onFilterValueChange } = this.props;

    return (
      <Dropdown
        placeholder="Select Node Type(s)"
        multiple
        selection
        onChange={onFilterValueChange}
        value={selectedTypes}
        options={NodeTypeFilter.getOptions()}
      />
    );
  }
}
