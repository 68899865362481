import { useState, useEffect, useRef } from 'react';

import _chunk from 'lodash/chunk';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { FIRST_PAGE } from './use-pagination.constants';

const defaultOptions = {
  defaultActivePage: 1,
  carousel: false,
  continuous: false,
  autoScroll: false,
  stepSize: null,
  grouping: 1,
  autoScrollInterval: 5000, // milliseconds
};

export default function usePagination(items, pageSize, options = {}) {
  const finalOptions = { ...defaultOptions, ...options };
  const {
    defaultActivePage,
    carousel,
    continuous,
    autoScroll,
    autoScrollInterval,
    stepSize,
    grouping,
  } = finalOptions;

  const isCustomStep = !_isNil(stepSize);
  const chunkSize = isCustomStep ? Math.min(stepSize, pageSize) : pageSize;
  const groupedItems = _chunk(items, grouping);
  const totalPages = _chunk(groupedItems, chunkSize).length;

  const [activePage, setActivePage] = useState(defaultActivePage);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(totalPages > 1 ? autoScroll : false);
  const autoScrollTimer = useRef(null);

  function buildItem(item) {
    if (grouping !== 1 || isCustomStep) {
      return item;
    }
    return item.map((element) => element[0]);
  }

  function getActivePageItems() {
    const longItems = [...groupedItems, ...groupedItems, ...groupedItems];
    const finalItems = continuous ? longItems : groupedItems;
    const pageChunks = _chunk(finalItems, chunkSize);
    const currentIndex = activePage - 1;

    if (currentIndex > totalPages || activePage < 1) {
      return [];
    }

    if (currentIndex > pageChunks.length - 1) {
      return [];
    }

    if (!isCustomStep || chunkSize === pageSize) {
      return buildItem(pageChunks[currentIndex]);
    }

    const start = chunkSize * currentIndex;
    return _flatten(pageChunks).slice(start, start + pageSize);
  }

  function onPageChange(e, { activePage: nextActivePage }) {
    setActivePage(nextActivePage);
  }

  function onPreviousPage() {
    setActivePage((previousActivePage) => {
      if (carousel && previousActivePage === FIRST_PAGE) {
        return totalPages;
      }
      if (!carousel && previousActivePage === FIRST_PAGE) {
        return previousActivePage;
      }
      return previousActivePage - 1;
    });
  }

  function onNextPage() {
    setActivePage((previousActivePage) => {
      if (carousel && previousActivePage >= totalPages) {
        return FIRST_PAGE;
      }
      if (!carousel && previousActivePage >= totalPages) {
        return previousActivePage;
      }
      return previousActivePage + 1;
    });
  }

  function clearAutoScrollTimerInterval() {
    if (autoScrollTimer.current) {
      clearInterval(autoScrollTimer.current);
      autoScrollTimer.current = null;
    }
  }

  function startAutoScrollTimerInterval() {
    clearAutoScrollTimerInterval();
    if (autoScrollEnabled) {
      autoScrollTimer.current = setInterval(onNextPage, autoScrollInterval);
    }
  }

  function onPreviousPageWithReset() {
    startAutoScrollTimerInterval();
    onPreviousPage();
  }

  function onNextPageWithReset() {
    startAutoScrollTimerInterval();
    onNextPage();
  }

  function onPageChangeWithReset(...params) {
    startAutoScrollTimerInterval();
    onPageChange(...params);
  }

  function playAutoScroll() {
    setAutoScrollEnabled(true);
  }

  function pauseAutoScroll() {
    setAutoScrollEnabled(false);
  }

  useEffect(() => {
    startAutoScrollTimerInterval();
    return () => clearAutoScrollTimerInterval();
  }, [autoScrollEnabled, autoScrollInterval, totalPages]);

  useEffect(() => {
    if (!_isEmpty(items)) {
      setActivePage(FIRST_PAGE);
    }
  }, [(items || []).length]);

  return {
    activePage,
    autoScrollEnabled,
    onPageChange: onPageChangeWithReset,
    onPreviousPage: onPreviousPageWithReset,
    onNextPage: onNextPageWithReset,
    getActivePageItems,
    playAutoScroll,
    pauseAutoScroll,
    totalPages,
  };
}
