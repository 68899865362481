import React, { useState } from 'react';

import { Input, Form, Header, Message, Divider } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { ORDER_SETTINGS_SAVE_ERROR } from './OrderSettingForm.constants';
import { showFieldForFulfillmentType } from './OrderSettingForm.utils';
import OrderDiscounts from './components/OrderDiscounts';
import { FULFILLMENT_TYPE_TO_LABEL } from '../../../../../../../constants/fulfillments';
import useEntityList from '../../../../../../../hooks/useEntityList';
import API from '../../../../../../../libs/api';
import { selectActiveLocation, selectActiveProduct } from '../../../../../../../selectors';
import { selectPrimaryLocation } from '../../../../../../../selectors/locations';
import ButtonSubmit from '../../../../../../common/ButtonSubmit';
import PriceField from '../../../../../../fields/PriceField/PriceField';
import {
  ACCEPT_ORDERS_UNTIL,
  DISCOUNTS,
  FLAT_FULFILLMENT_PRICE,
  MINIMUM_FULFILLMENT_PRICE,
  ORDER_TURNAROUND_TIME,
  TIME_UNIT_DAYS,
  TURNAROUND_TIME,
  TURNAROUND_TIME_UNIT,
} from '../../OrderSettings.constants';
import {
  selectOrderSettingByParams,
  handleChangeOrderSetting,
  handleSaveOrderSetting,
} from '../../OrderSettings.slice';
import OrderDurationField from '../OrderDurationField';
import OrderNotificationEmailField from '../OrderNotificationEmailField';
import OrderReceiptField from '../OrderReceiptField';
import OrderTurnaroundTimeField from '../OrderTurnaroundTimeField';

import './OrderSettingForm.scss';

function OrderSettingForm({ provider, type }) {
  const dispatch = useDispatch();
  const activeProduct = useSelector(selectActiveProduct);
  const activeLocation = useSelector(selectActiveLocation);
  const mainLocation = useSelector(selectPrimaryLocation);
  const orderSettings = useSelector((state) =>
    selectOrderSettingByParams(state, {
      providerName: provider,
      fulfillmentType: type,
      product: activeProduct.id,
      location: _isEmpty(activeLocation) ? mainLocation.id : activeLocation.id,
    }),
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormDirty, setIsFormDirty] = useState(false);

  function getDefaultOrderSettingsData() {
    return {
      method: { provider, type },
      product: activeProduct.id,
      location: _isEmpty(activeLocation) ? mainLocation.id : activeLocation.id,
    };
  }

  const orderSetting =
    orderSettings && orderSettings.length ? orderSettings[0] : getDefaultOrderSettingsData();

  const {
    entities: discounts,
    appendEntity: addDiscount,
    editEntity: editDiscount,
    removeEntity: removeDiscount,
  } = useEntityList(orderSetting.discounts);

  function handleDiscountChange(cb) {
    return (...callbackArgs) => {
      setIsFormDirty(true);
      cb(...callbackArgs);
    };
  }

  function handleOnChangeForm(e, { name: fieldName, value }) {
    setIsFormDirty(true);
    dispatch(
      handleChangeOrderSetting({
        orderSetting,
        data: { [fieldName]: value },
        defaultData: getDefaultOrderSettingsData(),
      }),
    );
  }

  function handleOnChangeTurnaroundTime(value) {
    setIsFormDirty(true);
    dispatch(
      handleChangeOrderSetting({
        orderSetting,
        data: { [TURNAROUND_TIME]: value, [TURNAROUND_TIME_UNIT]: TIME_UNIT_DAYS },
        defaultData: getDefaultOrderSettingsData(),
      }),
    );
  }

  async function handleSave(event) {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(null);
    const orderSettingToSave = {
      ...orderSetting,
      discounts,
    };
    try {
      const orderSettingsData = await API.saveOrderSettings(orderSettingToSave);
      dispatch(
        handleSaveOrderSetting({
          orderSetting,
          data: orderSettingsData,
        }),
      );
    } catch (e) {
      setErrorMessage(ORDER_SETTINGS_SAVE_ERROR);
    } finally {
      setIsFormDirty(false);
      setIsSubmitting(false);
    }
  }

  return (
    <Form>
      <Header as="h2">Where will notifications be sent to?</Header>
      <OrderNotificationEmailField
        initialValue={orderSetting.order_notification_emails}
        handleOnchangeForm={handleOnChangeForm}
      />
      <OrderReceiptField
        initialValue={orderSetting.order_receipt_message}
        handleOnChangeForm={handleOnChangeForm}
      />
      {showFieldForFulfillmentType(type, ORDER_TURNAROUND_TIME) && (
        <OrderTurnaroundTimeField
          initialValue={orderSetting.order_turnaround_time}
          handleOnChangeForm={handleOnChangeForm}
        />
      )}
      {showFieldForFulfillmentType(type, MINIMUM_FULFILLMENT_PRICE) && (
        <Form.Field id="minimum_fulfillment_price">
          <label htmlFor="minimum_fulfillment_price">
            Minimum {FULFILLMENT_TYPE_TO_LABEL[type]} Price
          </label>
          <PriceField
            priceInitialValue={orderSetting[MINIMUM_FULFILLMENT_PRICE]}
            name={MINIMUM_FULFILLMENT_PRICE}
            onChange={handleOnChangeForm}
          />
        </Form.Field>
      )}
      {showFieldForFulfillmentType(type, FLAT_FULFILLMENT_PRICE) && (
        <>
          <Form.Field id="flat_fulfillment_price">
            <label htmlFor="flat_fulfillment_price">
              Flat {FULFILLMENT_TYPE_TO_LABEL[type]} Price
            </label>
            <PriceField
              priceInitialValue={orderSetting[FLAT_FULFILLMENT_PRICE]}
              name={FLAT_FULFILLMENT_PRICE}
              onChange={handleOnChangeForm}
            />
            <p>This is the base price added if there is no discount</p>
          </Form.Field>
        </>
      )}
      {showFieldForFulfillmentType(type, DISCOUNTS) && (
        <>
          <Divider />
          <Header as="h2">Will any discounts be available?</Header>

          <OrderDiscounts
            discounts={discounts}
            addDiscount={handleDiscountChange(addDiscount)}
            editDiscount={handleDiscountChange(editDiscount)}
            removeDiscount={handleDiscountChange(removeDiscount)}
          />
          <Divider />
        </>
      )}
      {showFieldForFulfillmentType(type, TURNAROUND_TIME) && (
        <Form.Field id="turnaround-time" className="turnaround-time form-field">
          <label htmlFor="turnaround-time">Turnaround Time</label>
          <Input
            name={TURNAROUND_TIME}
            onChange={(e, { value }) => handleOnChangeTurnaroundTime(value)}
            label={{ basic: true, content: 'days' }}
            labelPosition="right"
            value={orderSetting.turnaround_time}
            min={0}
            type="number"
          />
          <p>Time that appears on the customer receipt</p>
        </Form.Field>
      )}
      {showFieldForFulfillmentType(type, ACCEPT_ORDERS_UNTIL) && (
        <OrderDurationField
          initialTime={orderSetting.accepts_orders_until}
          initialCutOffSwitch={orderSetting.order_cutoff_switch}
          handleOnChangeForm={handleOnChangeForm}
        />
      )}
      <Message hidden={!errorMessage} visible={errorMessage} content={errorMessage} negative />
      <Form.Field>
        <ButtonSubmit
          className="submission-button"
          onClick={handleSave}
          loading={isSubmitting}
          disabled={!isFormDirty || isSubmitting}
        >
          Save
        </ButtonSubmit>
      </Form.Field>
    </Form>
  );
}

export default OrderSettingForm;
