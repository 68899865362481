import {
  FULFILLMENT_TYPE_INSTORE,
  FULFILLMENT_STATUS_RECEIVED,
} from '../../../../../../../constants/fulfillments';
import { ORDER_STATUS_CLOSED } from '../../../../../../../constants/order-status';

export function getPickupOrderFilters({ productId, locationId }) {
  return {
    product: productId,
    location: locationId,
    status: ORDER_STATUS_CLOSED,
    fulfillment_status: FULFILLMENT_STATUS_RECEIVED,
    fulfillment_type: FULFILLMENT_TYPE_INSTORE,
  };
}
