import React, { useRef } from 'react';

import PropTypes from 'prop-types';

const IMAGE_FILE_TYPES = 'image/png, image/jpeg';

const propTypes = {
  children: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

const defaultProps = {
  multiple: false,
};

function ImageField({ onChange, children, multiple }) {
  const imageField = useRef(null);

  function handleOnClick() {
    imageField.current.click();
  }

  function handleOnChange(e) {
    const data = Array.from(e.target.files).map((file) => ({
      file,
      url: window.URL.createObjectURL(file),
    }));
    onChange(e, data);
  }

  return (
    <>
      {children(handleOnClick)}
      <input
        style={{ display: 'none' }}
        ref={imageField}
        type="file"
        onChange={handleOnChange}
        accept={IMAGE_FILE_TYPES}
        multiple={multiple}
      />
    </>
  );
}

ImageField.propTypes = propTypes;
ImageField.defaultProps = defaultProps;

export default ImageField;
