import PropTypes from 'prop-types';

import { BaseBlockType, ComponentConfigurationBlockType } from './common.types';
import {
  IMAGE_COMPONENT_NAME,
  CAROUSEL_IMAGE_COMPONENT_NAME,
  PAGINATED_GRID_IMAGE_COMPONENT_NAME,
} from '../constants/types';

export const ImagePropType = PropTypes.shape({
  id: PropTypes.number,
  file: PropTypes.string,
  filename: PropTypes.string,
});

export const ImageBlockPropType = PropTypes.shape({
  ...BaseBlockType,
  ...ComponentConfigurationBlockType,
  widgetType: PropTypes.oneOf([
    IMAGE_COMPONENT_NAME,
    CAROUSEL_IMAGE_COMPONENT_NAME,
    PAGINATED_GRID_IMAGE_COMPONENT_NAME,
  ]),
  images: PropTypes.arrayOf(ImagePropType),
});
