import { createSlice } from '@reduxjs/toolkit';

import { objectToCamelCase } from '../../../../libs/format';
import { buildGenericAdapter } from '../../../../libs/slices';

const redirectAdapter = buildGenericAdapter();

const initialState = redirectAdapter.getInitialState();

const redirectsSlice = createSlice({
  name: 'redirects',
  initialState,
  reducers: {
    updateOneRedirect: (state, action) => {
      const { id, changes } = action.payload;
      const newChanges = objectToCamelCase(changes);
      redirectAdapter.updateOne(state, { id, changes: newChanges });
    },
    removeOneRedirect: redirectAdapter.removeOne,
    setAllRedirects: (state, action) => {
      const redirects = objectToCamelCase(action.payload);
      redirectAdapter.setAll(state, redirects);
    },
    addOneRedirect: (state, action) => {
      const redirect = objectToCamelCase(action.payload);
      redirectAdapter.addOne(state, redirect);
    },
  },
});

export const { addOneRedirect, setAllRedirects, updateOneRedirect, removeOneRedirect } =
  redirectsSlice.actions;

export default redirectsSlice.reducer;

export const {
  selectAll: selectAllRedirects,
  selectById: selectRedirectById,
  selectIds: selectRedirectIds,
} = redirectAdapter.getSelectors((state) => state.redirects);
