import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import './Redirect.scss';

const propTypes = { url: PropTypes.string.isRequired };
const defaultProps = {};

export default function Redirect({ url }) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5>Redirecting...</h5>;
}

Redirect.propTypes = propTypes;
Redirect.defaultProps = defaultProps;
