import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import './CustomerSearch.scss';

const propTypes = {
  filterValue: PropTypes.string.isRequired,
  onFilterCustomer: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

function CustomerSearch({ filterValue, onFilterCustomer, loading }) {
  return (
    <Input
      placeholder="Search customers..."
      value={filterValue}
      onChange={onFilterCustomer}
      loading={loading}
      className="customer-search-input"
    />
  );
}

CustomerSearch.propTypes = propTypes;
CustomerSearch.defaultProps = defaultProps;

export default CustomerSearch;
