import React from 'react';

import PropTypes from 'prop-types';
import { Header, Icon, Segment, Button } from 'semantic-ui-react';

import {
  GLAMMATIC_MAIL_TO_SUPPORT_EMAIL,
  GLAMMATIC_SUPPORT_EMAIL,
  MAIL_TO_SUPPORT_EMAIL,
  SUPPORT_EMAIL,
} from '../../../constants/constants';
import ExternalAnchor from '../../common/ExternalAnchor';
import { zendeskOpen } from '../../common/ZendeskWidget/ZendeskWidget.utils';
import { BRAND_IDENTITIES } from '../../modules/foundation/hooks/use-branding/useBranding.constants';

const propTypes = {
  brandIdentity: PropTypes.string.isRequired,
};

export default function SubscriptionsNotFound({ brandIdentity }) {
  function getBrandedParagraph() {
    if (brandIdentity === BRAND_IDENTITIES.GLAMMATIC) {
      return (
        <p>
          Please email{' '}
          <ExternalAnchor href={GLAMMATIC_MAIL_TO_SUPPORT_EMAIL}>
            {GLAMMATIC_SUPPORT_EMAIL}
          </ExternalAnchor>{' '}
          or start a chat session by clicking on the button below
        </p>
      );
    }
    return (
      <p>
        Please email <ExternalAnchor href={MAIL_TO_SUPPORT_EMAIL}>{SUPPORT_EMAIL}</ExternalAnchor>{' '}
        or start a chat session by clicking on the button below
      </p>
    );
  }

  return (
    <Segment placeholder textAlign="center">
      <Header icon>
        <Icon name="payment" />
        Unable to display Subscription Options
      </Header>
      {getBrandedParagraph()}
      <Button onClick={zendeskOpen}>Open Chat</Button>
    </Segment>
  );
}

SubscriptionsNotFound.propTypes = propTypes;
