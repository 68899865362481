import React from 'react';

import { Button, Container, Image, List, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { updateOnboardingState as updateOnboardingStateConnect } from '../../../../actions/business';
import { InlineFieldContainer } from '../../../common';
import FormContainer from '../../../common/FormContainer';

import FishermanLogo from '../../../../static/logos/FishermanTextLogo.svg';
import CloverLogo from '../../../../static/logos/clover-logo.png';

import './CloverMerchantForm.scss';

const mapDispatchToProps = (dispatch) => ({
  updateOnboardingState: (payload) => dispatch(updateOnboardingStateConnect(payload)),
});

const mapStateToProps = ({ business, user }) => ({
  user: _get(user, 'core.value'),
  businessId: _get(business, 'core.value.id'),
  businessType: _get(business, 'core.value.type'),
  businessCollectionMethod: _get(business, 'core.value.collection_method'),
  providerServices: _get(business, 'provider_services.value'),
  providerServiceSettings: _get(business, 'provider_service_settings.value'),
  onboardingState: _get(business, 'core.value.onboardingState', {}),
});

const CloverMerchantForm = (props) => {
  const { showTitle, canSkip, updateOnboardingState, onboardingState } = props;
  const onboardingStateKey = 'domainSelection';

  function handleSkip(stepName) {
    updateOnboardingState({
      ...onboardingState,
      [stepName]: true,
    });
  }

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
      <InlineFieldContainer title={showTitle && <h2>Clover</h2>} enableDelete={false}>
        <Segment.Group>
          <Segment>
            <Container textAlign="center">
              <div className="clover-onboarding-partnership-logos">
                <div className="brand-logo">
                  <Image src={CloverLogo} />
                </div>
                <div className="brand-logo">
                  <Image src={FishermanLogo} />
                </div>
              </div>
            </Container>
          </Segment>
          <Segment attached>
            <List ordered className="clover-onboarding-free-form">
              <List.Item>
                You can edit your Photos, Hours, Branding, and other content using the tabs on the
                left. Check out our <a href="https://gofisherman.zendesk.com/hc">Help Center</a> if
                you have any questions
              </List.Item>
              <List.Item>
                Connect your Google Business Profile in the Integrations tab. If you don’t already
                have a profile setup, you can follow{' '}
                <a href="https://support.google.com/business/answer/2911778">these instructions</a>{' '}
                and come back
              </List.Item>
              <List.Item>
                Sign up for a{' '}
                <a href="https://calendly.com/d/crn-9tk-3qs/fisherman-support-call">
                  15-minute onboarding
                </a>{' '}
                call with one of our web specialists
              </List.Item>
              <List.Item>
                If you’d like to connect a custom domain, have our Support team make website changes
                for you, or access other features, you can upgrade your plan in the Clover
                Marketplace
              </List.Item>
            </List>
          </Segment>
          <Segment attached="bottom">
            <Container textAlign="center">
              {canSkip && (
                <Button
                  size="big"
                  content="Continue"
                  onClick={() => handleSkip(onboardingStateKey)}
                  primary
                />
              )}
            </Container>
          </Segment>
        </Segment.Group>
      </InlineFieldContainer>
    </FormContainer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CloverMerchantForm);
