import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  LEAD_SOURCES,
  LEAD_SOURCES_ALTERNATIVE_NAME_MAP,
} from '../../../libs/lead-source/constants';
import Validate from '../../../libs/validate';

export function wrapFieldCallback(func) {
  return (event, { value, checked, type } = {}) => {
    const finalValue = type === 'checkbox' ? checked : value || event.target.value;
    return func(finalValue);
  };
}

function getLeadSourceText(leadSource) {
  return _get(LEAD_SOURCES_ALTERNATIVE_NAME_MAP, leadSource, leadSource);
}

export function getLeadSourceOptions() {
  return LEAD_SOURCES.map((source) => ({
    key: source,
    text: getLeadSourceText(source),
    value: source,
  }));
}

export function getFormValidationMessage(formData = {}, useManualAddress = false) {
  const { businessName, businessSubtype, businessOwnerPhone, placeId, businessOwnerEmail } =
    formData;
  if (_isEmpty(businessName)) {
    return 'The Business Name field is required';
  }
  if (_isEmpty(businessSubtype)) {
    return 'The Business Type field is required';
  }
  if (!_isEmpty(businessOwnerPhone) && !Validate.validate('phone', businessOwnerPhone)) {
    return 'The Business Owner Mobile Phone is invalid';
  }

  if (_isEmpty(businessOwnerPhone) && _isEmpty(businessOwnerEmail)) {
    return 'Please provide either a phone or email';
  }

  if (!useManualAddress && _isEmpty(placeId)) {
    return 'Make sure to select a valid Business Name';
  }
  return '';
}
