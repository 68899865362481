import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Accordion, Button, Icon, List, Message } from 'semantic-ui-react';

import {
  EDIT_BUTTON_HELP_MESSAGE,
  COLLAPSE_BUTTON_HELP_MESSAGE,
  EXPAND_BUTTON_HELP_MESSAGE,
  CONFIRMATION_DELETE_MESSAGE,
} from './ModifierSetDetail.constants';
import { formatPrice, formatAppliedToString } from './ModifierSetDetail.utils';
import ConfirmModal from '../../../../../../common/ConfirmModal';
import {
  CatalogItemModifierListInfoPropType,
  CatalogModifierListPropType,
  CatalogModifierPropType,
} from '../../../proptypes';
import {
  SERVER_ERROR_MESSAGE_DELETING,
  DELETE_BUTTON_HELP_MESSAGE,
} from '../../CatalogModifiers.constants';
import {
  getModifierListSelectionRange,
  createSelectionRangeDescription,
} from '../../CatalogModifiers.utils';

import './ModifierSetDetail.scss';

const propTypes = {
  itemModifierListInfo: CatalogItemModifierListInfoPropType,
  modifierList: CatalogModifierListPropType.isRequired,
  modifiers: PropTypes.arrayOf(CatalogModifierPropType).isRequired,
  itemsAppliedTo: PropTypes.number,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

const defaultProps = {
  itemModifierListInfo: null,
  itemsAppliedTo: null,
  onDelete: () => null,
  onEdit: () => null,
};

function ModifierSetDetail({
  itemModifierListInfo,
  modifierList,
  modifiers,
  itemsAppliedTo,
  onDelete,
  onEdit,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState('');
  const [isBeingDeleted, setIsBeingDeleted] = useState(false);
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] = useState(false);

  const minMaxHeaderString = createSelectionRangeDescription(
    getModifierListSelectionRange(modifierList, itemModifierListInfo),
  );

  function toggleExpanded() {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  }

  async function handleDelete() {
    setServerErrorMessage('');
    setIsBeingDeleted(true);
    try {
      // NOTE: the logic for deleting occuring in the parent component since it is common to both the form and the detail
      await onDelete(modifierList);
    } catch (err) {
      setServerErrorMessage(SERVER_ERROR_MESSAGE_DELETING);
      setIsBeingDeleted(false);
    }
    setIsConfirmDeleteModalOpened(false);
  }

  return (
    <section className="modifier-set-detail-component">
      <Accordion>
        <Accordion.Title active className="accordion-title">
          <div className="accordion-title-content">
            <div className="header-content">
              <h1>{modifierList.modifier_list_data.name}</h1>
              <h2>{minMaxHeaderString}</h2>
            </div>
            <div className="header-extra-text">{formatAppliedToString(itemsAppliedTo)}</div>
            <div className="buttons">
              <ConfirmModal
                onOpen={() => setIsConfirmDeleteModalOpened(true)}
                open={isConfirmDeleteModalOpened}
                trigger={
                  <Button className="accordion-button delete-button" loading={isBeingDeleted}>
                    <Icon name="trash alternate outline" title={DELETE_BUTTON_HELP_MESSAGE} />
                  </Button>
                }
                header="Delete Set"
                content={CONFIRMATION_DELETE_MESSAGE}
                confirmButton={<Button>Delete</Button>}
                onConfirm={handleDelete}
                onCancel={() => setIsConfirmDeleteModalOpened(false)}
              />
              <Button
                className="accordion-button edit-button"
                onClick={onEdit}
                disabled={isBeingDeleted}
              >
                <Icon name="pencil" title={EDIT_BUTTON_HELP_MESSAGE} />
              </Button>
              <Button
                className="accordion-button expand-button"
                onClick={toggleExpanded}
                disabled={isBeingDeleted}
              >
                {isExpanded ? (
                  <Icon name="minus circle" title={COLLAPSE_BUTTON_HELP_MESSAGE} />
                ) : (
                  <Icon name="plus circle" title={EXPAND_BUTTON_HELP_MESSAGE} />
                )}
              </Button>
            </div>
          </div>
          <div className="accordion-title-message">
            {serverErrorMessage && (
              <Message
                className="modifer-set-detail-error-message"
                error
                header={serverErrorMessage}
              />
            )}
          </div>
        </Accordion.Title>
        <Accordion.Content active={isExpanded}>
          <List className="modifiers-list">
            {modifiers.map((modifier) => (
              <List.Item key={modifier.object_id} className="modifier-info">
                <span>{modifier.modifier_data.name}</span>
                <span className="spacer" />
                <span>{formatPrice(modifier.modifier_data.price)}</span>
              </List.Item>
            ))}
          </List>
        </Accordion.Content>
      </Accordion>
    </section>
  );
}

ModifierSetDetail.propTypes = propTypes;
ModifierSetDetail.defaultProps = defaultProps;

export default ModifierSetDetail;
