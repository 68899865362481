/* eslint-disable no-case-declarations */

import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import StateUtils from '../libs/state';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'CREATE_WEBSITE':
      return {
        ...state,
        core: StateUtils.createDefaultObject(StateUtils.types.OBJECT, { ...payload }, true),
        pages: StateUtils.createDefaultObject(StateUtils.types.LIST),
        layouts: StateUtils.createDefaultObject(StateUtils.types.LIST),
        styleRules: StateUtils.createDefaultObject(StateUtils.types.LIST),
        colorPalette: StateUtils.createDefaultObject(
          StateUtils.types.OBJECT,
          StateUtils.createDefaultType('COLOR_PALETTE'),
        ),
        domains: StateUtils.createDefaultObject(StateUtils.types.LIST),
        props: StateUtils.createDefaultObject(StateUtils.types.LIST),
        publications: StateUtils.createDefaultObject(StateUtils.types.LIST),
        patches: StateUtils.createDefaultObject(StateUtils.types.LIST),
        websiteUsers: StateUtils.createDefaultObject(StateUtils.types.LIST),
      };

    case 'RESET_WEBSITE':
      return {
        core: StateUtils.createDefaultObject(StateUtils.types.OBJECT, {}, false),
        pages: StateUtils.createDefaultObject(StateUtils.types.LIST),
        layouts: StateUtils.createDefaultObject(StateUtils.types.LIST),
        styleRules: StateUtils.createDefaultObject(StateUtils.types.LIST),
        colorPalette: StateUtils.createDefaultObject(StateUtils.types.OBJECT, {}, false),
        domains: StateUtils.createDefaultObject(StateUtils.types.LIST),
        props: StateUtils.createDefaultObject(StateUtils.types.LIST),
        publications: StateUtils.createDefaultObject(StateUtils.types.LIST),
        patches: StateUtils.createDefaultObject(StateUtils.types.LIST),
        websiteUsers: StateUtils.createDefaultObject(StateUtils.types.LIST),
      };

    case 'UPDATE_WEBSITE_FEATURE':
      const feature = payload;
      const currentFeatures = state.core.value.features;
      const newFeatures = StateUtils.addOrRemoveFromList(feature, currentFeatures);
      const newState = StateUtils.updateObject(state, ['core', 'value', 'features'], newFeatures);
      const touchedObject = StateUtils.touchObject(newState.core, 'features');
      return StateUtils.updateObject(state, ['core'], touchedObject);

    case 'INITIALIZE_LAYOUTS':
      return StateUtils.initializeList(state, payload, 'layouts', null);

    case 'UPDATE_WEBSITE_LAYOUTS':
      return StateUtils.updateObject(state, ['layouts'], StateUtils.createList(payload));

    case 'UPDATE_WEBSITE_LAYOUT_COMPONENT':
      const currentLayout = { ...state.layouts.value[payload.layoutIndex] };
      const currentComponent = {
        ...currentLayout.components[payload.index],
        data: payload.data,
      };
      currentLayout.components[payload.index] = currentComponent;

      return StateUtils.updateListItem(state, ['layouts', 'value'], payload.layoutIndex, {
        ...currentLayout,
        components: currentLayout.components,
      });

    case 'INITIALIZE_PAGES':
      return StateUtils.initializeList(state, payload, 'pages', null);

    case 'UPDATE_WEBSITE_PAGES':
      return StateUtils.updateObject(state, ['pages', 'value'], StateUtils.createList(payload));

    case 'UPDATE_WEBSITE_PAGE':
      return StateUtils.updateListItem(state, ['pages', 'value'], payload.index, {
        ...payload.data,
      });
    case 'ADD_WEBSITE_PAGE':
      return StateUtils.addItem(state, ['pages', 'value'], {
        ...StateUtils.createDefaultType('PAGE'),
        ...payload,
        localId: uuid(),
      });
    case 'REMOVE_WEBSITE_PAGE':
      return StateUtils.deleteItem(state, ['pages', 'value'], payload.index);

    case 'INITIALIZE_PATCHES':
      return StateUtils.initializeList(state, payload, 'patches', null);

    case 'SET_COLOR_PALETTE': {
      const updatedState = StateUtils.updateObject(state, ['colorPalette', 'value'], payload);
      const touchedColorPalette = StateUtils.touchObject(updatedState.colorPalette, 'id');
      return StateUtils.updateObject(state, ['colorPalette'], touchedColorPalette);
    }

    case 'INITIALIZE_STYLE_RULES':
      return StateUtils.initializeList(state, payload, 'styleRules', null);

    case 'UPDATE_STYLE_RULE':
      return StateUtils.updateListItem(state, ['styleRules', 'value'], payload.index, {
        [payload.field]: payload.fieldValue,
      });

    case 'ADD_STYLE_RULE': {
      const values = payload || {};
      const additionalValues = {
        ...values,
        _touchedFields: Object.keys(values),
      };
      return StateUtils.addItem(state, ['styleRules', 'value'], {
        localId: uuid(),
        ...StateUtils.createDefaultType('STYLE_RULE', additionalValues),
      });
    }

    case 'DELETE_STYLE_RULE':
      return StateUtils.deleteItem(state, ['styleRules', 'value'], payload);

    case 'INITIALIZE_PUBLICATIONS':
      return {
        ...state,
        publications: payload,
      };

    case 'INITIALIZE_WEBSITE_USERS':
      return {
        ...state,
        websiteUsers: payload,
      };

    case 'INITIALIZE_WEBSITE_SUBSCRIPTION':
      return {
        ...state,
        subscription: payload,
      };

    case 'ADD_WEBSITE_USER':
      return StateUtils.insertItem(state, ['websiteUsers'], payload.value, payload.index, true);

    case 'REMOVE_WEBSITE_USER':
      return StateUtils.deleteItem(state, ['websiteUsers'], payload.index, true);

    case 'UPDATE_WEBSITE_USER':
      return state;

    case 'UPDATE_WEBSITE_FONTS':
      return StateUtils.updateListItem(
        state,
        ['styleRules', 'value'],
        StateUtils.getItemIndex(state.styleRules.value, 'name', payload.name),
        { value: payload.value },
      );

    case 'CLEAR_WEBSITE_TOUCHED_FIELD':
      if (payload.stateType === 'LIST') {
        return StateUtils.updateListItem(
          state,
          [payload.stateKeyPath[0], 'value'],
          payload.stateKeyPath[1],
          { _touchedFields: [] },
          true,
        );
      }
      return {
        ...state,
        [payload.stateKeyPath[0]]: {
          ...state[payload.stateKeyPath[0]],
          _touchedFields: [],
        },
      };

    case 'REMOVE_WEBSITE_ADDED_ITEM':
      if (payload.stateType === 'LIST') {
        const { _newItemIds } = state[payload.stateKeyPath[0]];
        const withRemovedIds = {
          ...state,
          [payload.stateKeyPath[0]]: {
            ...state[payload.stateKeyPath[0]],
            _newItemIds: _.pull(_newItemIds, payload.stateKeyPath[1]),
          },
        };

        return StateUtils.updateListItem(
          withRemovedIds,
          [payload.stateKeyPath[0], 'value'],
          payload.stateKeyPath[1],
          { ...payload.data, _touchedFields: [] },
          true,
        );
      }
      return { ...state };

    case 'REMOVE_WEBSITE_DELETED_ITEM':
      const { _deletedItemIds } = state[payload.stateKeyPath[0]];
      return {
        ...state,
        [payload.stateKeyPath[0]]: {
          ...state[payload.stateKeyPath[0]],
          _deletedItemIds: _.pull(_deletedItemIds, payload.stateKeyPath[1]),
        },
      };

    case 'INITIALIZE_PROPS':
      const [addedProps, props] = StateUtils.formatProps(payload);
      const withProps = StateUtils.flagAsLoaded(
        ['props'],
        StateUtils.updateObject(state, ['props', 'value'], props),
      );

      return {
        ...withProps,
        props: {
          ...withProps.props,
          _newItemIds: addedProps,
        },
      };

    case 'UPDATE_CONTACT_FORM_PROP':
      return StateUtils.updateListItem(
        state,
        ['props', 'value'],
        StateUtils.getItemIndex(state.props.value, 'prop_name', payload.field),
        { prop_value: payload.value },
      );

    case 'ADD_CONTACT_FORM_FIELD':
      const withAddedField = [
        ...JSON.parse(
          state.props.value.filter(({ prop_name: propName }) => propName === 'fields')[0]
            .prop_value,
        ),
        StateUtils.createDefaultType(StateUtils.defaults.FORM_FIELD),
      ];

      return StateUtils.updateListItem(
        state,
        ['props', 'value'],
        StateUtils.getItemIndex(state.props.value, 'prop_name', 'fields'),
        { prop_value: JSON.stringify(withAddedField) },
      );

    case 'DELETE_CONTACT_FORM_FIELD':
      const initialFields = JSON.parse(
        state.props.value.filter(({ prop_name: propName }) => propName === 'fields')[0].prop_value,
      );
      const withDeletedField = [
        ...initialFields.slice(0, payload),
        ...initialFields.slice(payload + 1, initialFields.length),
      ];

      return StateUtils.updateListItem(
        state,
        ['props', 'value'],
        StateUtils.getItemIndex(state.props.value, 'prop_name', 'fields'),
        { prop_value: JSON.stringify(withDeletedField) },
      );

    case 'INITIALIZE_DOMAINS':
      return StateUtils.initializeList(state, payload, 'domains', null);

    case 'SET_IS_NEXT_WEBSITE':
      return { ...state, isNextWebsite: payload };

    default:
      return state;
  }
};
