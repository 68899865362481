import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import {
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
} from '../../../../constants/recipes';
import { multiCaseGet } from '../../../../libs/format';
import { hasAnyRecipe } from '../../foundation/hooks/use-recipe/use-recipe.utils';
import { CURATED_HERO_COMPONENT } from '../constants/component';
import { DYNAMIC_TILES_COMPONENT } from '../constants/custom-hero';

export function isCustomHeroSupported(patch) {
  return hasAnyRecipe(patch, [
    RECIPE_NAME_CUTILIER,
    RECIPE_NAME_MUSE,
    RECIPE_NAME_HIBISCUS,
    RECIPE_NAME_CALENDULA,
  ]);
}

export function hasHeroOverrideForPage(page) {
  const heroComponentConfiguration = _get(page, 'components', []).find((component) => {
    const enabled = multiCaseGet(component, ['enabled']);
    if (!enabled) {
      return false;
    }

    const componentName = multiCaseGet(component, ['fast_id']);
    const componentIdentifier = multiCaseGet(component, ['component_identifier']);

    if (componentName === DYNAMIC_TILES_COMPONENT) {
      return true;
    }

    if (componentName === CURATED_HERO_COMPONENT) {
      return _isEmpty(componentIdentifier);
    }
    return false;
  });
  return !!heroComponentConfiguration;
}
