import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import { authRedirectFor } from '../../../libs/auth';
import { capitalize } from '../../../libs/strings';

import './SocialLoginButton.scss';

const propTypes = {
  socialNetworkName: PropTypes.oneOf(['google', 'apple']).isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => null,
};

const socialNetworkNameToIcon = {
  google: 'google plus g',
  apple: 'apple',
};

function SocialLoginButton({ socialNetworkName, onClick }) {
  const [isRedirecting, setIsRedirecting] = useState(false);

  function internalOnClick(event) {
    event.preventDefault();
    setIsRedirecting(true);
    onClick(event);
    authRedirectFor(socialNetworkName);
  }

  return (
    <Button
      fluid
      loading={isRedirecting}
      className={`social-login-button ${socialNetworkName}`}
      onClick={internalOnClick}
    >
      <Icon name={socialNetworkNameToIcon[socialNetworkName]} /> Sign In With{' '}
      {capitalize(socialNetworkName)}
    </Button>
  );
}

SocialLoginButton.propTypes = propTypes;
SocialLoginButton.defaultProps = defaultProps;

export default SocialLoginButton;
