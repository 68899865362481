export const FIELD_CREATED_AT_NAME = 'createdAt';
export const FIELD_CREATED_AT_LABEL = 'Submitted On';
export const FIELD_ID_NAME = 'id';
export const FIELD_ID_LABEL = 'Submission ID';

export const FORM_HEADER_CONTACT_INFO = 'Contact Info';

export const FORM_HEADER_SUBMISSION_INFO = 'Submission Info';

export const FORM_HEADER_LOCATION_INFO = 'Location Info';

export const FORM_HEADER_OTHER_FIELDS = 'Custom Fields';
export const NOT_PROVIDED_VALUE = 'Not Provided';

export const FILE_ICON_FOR_IMAGE = 'file image outline';
export const FILE_ICON_FOR_PDF = 'file pdf outline';
export const FILE_ICON_FOR_VIDEO = 'file video outline';
export const FILE_ICON_DEFAULT = 'file outline';

export const MESSAGE_DELAY = 4;
