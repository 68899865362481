import { ELEMENT_TYPE_ICON, ELEMENT_TYPE_LABEL } from './CustomPageElement.constants';
import {
  AUTO_ADJUSTED_COLUMN_SIZE_MODE,
  EMPTY_BLOCK_ELEMENT_TYPE,
  MAX_COLUMN_WIDTHS_COUNT,
} from '../../constants/types';

export function getElementDisplayOptions(segment) {
  const { elements = [] } = segment;

  return elements.map(({ id, type }) => {
    return {
      id,
      icon: ELEMENT_TYPE_ICON[type] || ELEMENT_TYPE_ICON[EMPTY_BLOCK_ELEMENT_TYPE],
      label: ELEMENT_TYPE_LABEL[type] || ELEMENT_TYPE_LABEL[EMPTY_BLOCK_ELEMENT_TYPE],
    };
  });
}

export function canAddMoreColumns(columns = []) {
  const totalWidths = columns
    .map(({ width }) => width)
    .filter(Boolean)
    .reduce((acc, width) => acc + width, 0);

  return totalWidths < MAX_COLUMN_WIDTHS_COUNT;
}

export function getColumnMaxWidth(row, column) {
  const { columnSizingMode, columns = [] } = row;
  const { id: columnId } = column;

  if (columnSizingMode === AUTO_ADJUSTED_COLUMN_SIZE_MODE) {
    return null;
  }

  const otherColumns = columns.filter(({ id }) => id !== columnId);
  const otherColumnWidths = otherColumns.map(({ width }) => width || 0);
  const otherColumnsTotalWidth = otherColumnWidths.reduce((acc, width) => acc + width, 0);

  return MAX_COLUMN_WIDTHS_COUNT - otherColumnsTotalWidth;
}
