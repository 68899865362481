import { generateLocalId } from '../../../../libs/slices';
import { DEFAULT_INTERACTION } from '../constants';

export function createDefaultInteraction() {
  return {
    ...DEFAULT_INTERACTION,
    localId: generateLocalId(),
  };
}

export function ensureInteractions(initialInteractions = [], desiredLength = 2) {
  const actualObjects = (initialInteractions || []).slice(0, desiredLength);
  const numberOfEmptyObjects = desiredLength - actualObjects.length;
  const emptyObjects = Array.from({ length: numberOfEmptyObjects }, createDefaultInteraction);
  return [...actualObjects, ...emptyObjects];
}

export function getInteractionTitle(interaction, defaultLabel) {
  const { label } = interaction;
  if (label) {
    return `Enable "${label}" Button`;
  }
  return `Enable Button ${defaultLabel}`;
}
