import React, { Component } from 'react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import ChowNowIntegration from './ChowNow/ChowNowIntegration';
import CloverIntegration from './Clover/CloverIntegration';
import DaySmartIntegration from './DaySmart/DaySmart';
import FacebookIntegration from './FacebookIntegration';
import GoogleMyBusinessContainer from './GoogleMyBusiness/GoogleMyBusiness';
import SquareIntegration from './SquareIntegration/SquareIntegration';
import ToastIntegration from './Toast/ToastIntegration';
import UserWayIntegration from './UserWay/UserWayIntegration';
import ZupplerIntegration from './Zuppler/Zuppler';
import { initializeProviderServiceSettings as initializeProviderServiceSettingsConnect } from '../../../actions/business';
import API from '../../../libs/api';

import './Integrations.scss';

const mapDispatchToProps = (dispatch) => ({
  initializeProviderServiceSettings: (payload) =>
    dispatch(initializeProviderServiceSettingsConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  business: _get(business, 'core.value'),
});

class Integrations extends Component {
  async componentDidMount() {
    return this.refreshProviderServiceSettings();
  }

  async refreshProviderServiceSettings() {
    const { initializeProviderServiceSettings, business } = this.props;
    const { id: businessId, type: businessType } = business;

    const response = await API.getProviderServiceSettings(businessId, businessType);
    initializeProviderServiceSettings(response.data);
  }

  render() {
    return (
      <div className="integrations">
        <ToastIntegration />
        <CloverIntegration />
        <ZupplerIntegration />
        <DaySmartIntegration />
        <ChowNowIntegration />
        <SquareIntegration />
        <GoogleMyBusinessContainer />
        <UserWayIntegration />
        <FacebookIntegration />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
