import React, { Component } from 'react';

import { Divider } from 'semantic-ui-react';

import { connect } from 'react-redux';

import { MAX_SOCIAL_MEDIA_LENGTH } from './SocialMediaForm.constants';
import {
  addBusinessSocialMedia as addBusinessSocialMediaConnect,
  deleteBusinessSocialMedia as deleteBusinessSocialMediaConnect,
  updateBusinessSocialMedia as updateBusinessSocialMediaConnect,
} from '../../../actions/business';
import {
  validateOnBlur as validateOnBlurConnect,
  inputHasError,
} from '../../../actions/validation-errors';
import Options from '../../../constants/options';
import { AddItemField, DropdownField, InlineFieldContainer } from '../../common';
import FormContainer from '../../common/FormContainer';
import UrlField from '../../common/UrlField';

import './SocialMediaForm.scss';

const mapDispatchToProps = (dispatch) => ({
  updateBusinessSocialMedia: (payload) => dispatch(updateBusinessSocialMediaConnect(payload)),
  addBusinessSocialMedia: (payload) => dispatch(addBusinessSocialMediaConnect(payload)),
  deleteBusinessSocialMedia: (payload) => dispatch(deleteBusinessSocialMediaConnect(payload)),
  validateOnBlur: (payload) => dispatch(validateOnBlurConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  businessType: business.core.value.type,
  businessId: business.core.value.id,
  // eslint-disable-next-line no-underscore-dangle
  loaded: business.socialMedia._loaded,
  socialMediaValues: business.socialMedia.value,
});

class SocialMediaForm extends Component {
  constructor(props) {
    super(props);

    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  handleLinkChange(index) {
    const { updateBusinessSocialMedia } = this.props;

    return (e) => {
      const targetValue = e.target.value;
      updateBusinessSocialMedia({
        fieldValue: targetValue,
        field: 'link',
        index,
      });
    };
  }

  handleTypeChange(index) {
    const { updateBusinessSocialMedia } = this.props;

    return (value) => {
      return updateBusinessSocialMedia({
        fieldValue: value,
        field: 'type',
        index,
      });
    };
  }

  render() {
    const { validateOnBlur, socialMediaValues, addBusinessSocialMedia, deleteBusinessSocialMedia } =
      this.props;
    const types = socialMediaValues.map(({ type }) => type);

    return (
      <FormContainer loadedKeyPath={['business', 'socialMedia']}>
        {socialMediaValues.map(({ type, link }, index) => (
          <InlineFieldContainer
            key={type}
            title={type}
            enableDelete
            onDelete={() => deleteBusinessSocialMedia(index)}
            className="social-media"
          >
            {!type && (
              <DropdownField
                placeholder="Type"
                value={type}
                onChange={this.handleTypeChange(index)}
                options={
                  type.length > 0
                    ? Options.SOCIAL_MEDIA
                    : Options.SOCIAL_MEDIA.filter((o) => !types.includes(o))
                }
                disabled={type}
                fluid={false}
                error={inputHasError(`/core/socialmedia.Social Media type.${index}`)}
                onValidate={() =>
                  validateOnBlur({
                    routeAndInput: `/core/socialmedia.Social Media type.${index}`,
                  })
                }
              />
            )}
            <UrlField
              style={{ width: '100%' }}
              required
              value={link}
              onChange={this.handleLinkChange(index)}
              error={inputHasError(`/core/socialmedia.Social Media.${index}`)}
              onValidate={() =>
                validateOnBlur({
                  routeAndInput: `/core/socialmedia.Social Media.${index}`,
                })
              }
              maxLength={MAX_SOCIAL_MEDIA_LENGTH}
            />
            {index < socialMediaValues.length - 1 && <Divider />}
          </InlineFieldContainer>
        ))}
        {socialMediaValues && socialMediaValues.length < Options.SOCIAL_MEDIA.length && (
          <AddItemField description="Add Social Media" onAdd={addBusinessSocialMedia} />
        )}
      </FormContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaForm);
