import React from 'react';

import PropTypes from 'prop-types';

import './BusinessPlanLine.scss';

export default function BusinessPlanLine(props) {
  const { children } = props;

  return <div className="business-plan-line-container">{children}</div>;
}

BusinessPlanLine.propTypes = {
  children: PropTypes.node.isRequired,
};
