import { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

export default function useEntityList(initialList = [], idFieldName = 'id') {
  const [entities, setEntities] = useState(
    initialList.map((entity) => ({ ...entity, key: entity[idFieldName] })),
  );

  function appendEntity() {
    setEntities((prevEntities) => [...prevEntities, { key: uuidv4() }]);
  }

  function editEntity(entityIndex, data) {
    setEntities((prevEntities) => [
      ...prevEntities.slice(0, entityIndex),
      { ...prevEntities[entityIndex], ...data },
      ...prevEntities.slice(entityIndex + 1),
    ]);
  }

  function removeEntity(entityIndex) {
    setEntities((prevEntities) => [
      ...prevEntities.slice(0, entityIndex),
      ...prevEntities.slice(entityIndex + 1),
    ]);
  }

  return { entities, appendEntity, editEntity, removeEntity };
}
