import React from 'react';

import PropTypes from 'prop-types';
import { List, Segment, Header } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import FileListItem from 'components/modules/files/components/FileList/components/FileListItem';
import { BusinessFilePropType, LocalFilePropType } from 'components/modules/files/types/files';

import './FileList.scss';

const propTypes = {
  files: PropTypes.arrayOf(PropTypes.oneOfType([BusinessFilePropType, LocalFilePropType])),
  onDeleteFile: PropTypes.func,
  onEditFile: PropTypes.func,
};

const defaultProps = {
  files: [],
  onDeleteFile: () => {},
  onEditFile: () => {},
};

export default function FileList({
  files,
  onDeleteFile: onDeleteFileProp,
  onEditFile: onEditFileProp,
}) {
  if (_isEmpty(files)) {
    return null;
  }

  async function onDeleteFile(index) {
    if (onEditFileProp) {
      await onDeleteFileProp(index);
    }
  }

  async function onEditFile(index) {
    if (onDeleteFileProp) {
      await onEditFileProp(index);
    }
  }

  return (
    <Segment placeholder>
      <Header>{`${files.length} file(s) uploaded`}</Header>
      <List className="file-list">
        {files.map((file, index) => (
          <FileListItem
            key={file.id || file.url}
            file={file}
            onDeleteFile={async () => onDeleteFile(index)}
            onEditFile={async () => onEditFile(index)}
          />
        ))}
      </List>
    </Segment>
  );
}

FileList.propTypes = propTypes;
FileList.defaultProps = defaultProps;
