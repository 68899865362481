import { useDispatch, useSelector } from 'react-redux';

import API from '../../../../../libs/api';
import { selectWebsite } from '../../../../../selectors/website';
import { selectCustomPages } from '../../selectors/custom-pages';
import {
  setAllCustomPages,
  addOneCustomPage,
  updateOneCustomPage,
  removeOneCustomPage,
} from '../../slices/custom-pages';

export default function useCustomPages() {
  const dispatch = useDispatch();

  const customPages = useSelector(selectCustomPages);
  const website = useSelector(selectWebsite);
  const { id: websiteId } = website || {};

  async function refreshCustomPages() {
    const { data: nodes } = await API.getCustomPages(websiteId);

    dispatch(setAllCustomPages(nodes));
  }

  async function createOrUpdateCustomPage(page, recipeNode, pageAssociation = {}) {
    const { id: pageId } = page;
    if (!pageId) {
      const { data: customPage } = await API.createCustomPage(websiteId, page, recipeNode);
      dispatch(addOneCustomPage(customPage));
      return customPage;
    }
    const { node } = recipeNode;
    const { data: customPage } = await API.updateCustomPage(pageAssociation, { page, node });
    const { id } = customPage;
    dispatch(updateOneCustomPage({ id, changes: customPage }));
    return customPage;
  }

  async function updateCustomPageStatus(pageAssociationId, pageId, newStatus) {
    const newPageAssociation = { id: pageAssociationId, status: newStatus };

    await createOrUpdateCustomPage({ id: pageId }, {}, newPageAssociation);
  }

  async function removeCustomPage(pageAssociationId) {
    await API.removeCustomPage(pageAssociationId);
    dispatch(removeOneCustomPage(pageAssociationId));
  }

  return {
    customPages,
    refreshCustomPages,
    createOrUpdateCustomPage,
    updateCustomPageStatus,
    removeCustomPage,
  };
}
