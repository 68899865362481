import React, { useContext } from 'react';

import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { selectEntityId } from '../../../../../../../libs/slices';
import { GenericComponentConfigurationPropType } from '../../../../../../modules/component-configuration/proptypes';
import { CuratedHeroContext } from '../../../../../../modules/custom-pages/contexts/CuratedHero.context';
import FileSelectionWell from '../../../../../../modules/files/components/FileSelectionWell';

const propTypes = {
  componentConfiguration: GenericComponentConfigurationPropType.isRequired,
  settings: PropTypes.shape({
    maxFilesAllowed: PropTypes.number,
  }),
};
const defaultProps = {
  settings: { maxCtasAllowed: 1 },
};

export default function CuratedHeroImagesInput({ componentConfiguration, settings }) {
  const { updateComponentConfiguration } = useContext(CuratedHeroContext);
  const { files = [] } = componentConfiguration || {};
  const { maxFilesAllowed = 1 } = settings;

  function updateFiles(selectedFiles) {
    const componentConfigurationId = selectEntityId(componentConfiguration);
    updateComponentConfiguration(componentConfigurationId, 'files', selectedFiles);
  }

  if (_isEmpty(componentConfiguration)) {
    return null;
  }
  return (
    <div className="form-item">
      <FileSelectionWell
        files={files}
        maxAllowedImages={maxFilesAllowed}
        onUpdateFiles={updateFiles}
      />
    </div>
  );
}

CuratedHeroImagesInput.propTypes = propTypes;
CuratedHeroImagesInput.defaultProps = defaultProps;
