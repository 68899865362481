import React from 'react';

import { Button, Segment, Image, Container, Message } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { GMB_AUTH_ERROR_KEY } from './GoogleMyBusiness.constants';
import {
  hasGoogleMyBusinessToken,
  getErrorHeaderAndMessage,
  getGoogleMyBusinessAccountId,
  getLocationSettingForGMBLocation,
} from './GoogleMyBusiness.utils';
import GMBAccountList from './components/GMBAccountList';
import GMBAccountSettings from './components/GMBAccountSettings';
import GMBLocationList from './components/GMBLocationList';
import GMBSteps from './components/GMBSteps';
import {
  deleteProviderServiceSetting as deleteProviderServiceSettingConnect,
  initializeProviderServiceSettings as initializeProviderServiceSettingsConnect,
} from '../../../../actions/business';
import API from '../../../../libs/api';
import { isAuthorizedForSaleViews } from '../../../../libs/auth';
import ErrorHandler from '../../../../libs/errors';
import { selectUserRoleForBusiness } from '../../../../selectors/business';
import { InlineFieldContainer } from '../../../common';
import FormContainer from '../../../common/FormContainer';
import WithRouter from '../../../modules/foundation/components/WithRouter';

import GMBLogo from '../../../../static/logos/GMB-logo.png';

import '../styles/GoogleMyBusiness.scss';

const mapDispatchToProps = (dispatch) => ({
  deleteProviderServiceSetting: (payload) => dispatch(deleteProviderServiceSettingConnect(payload)),
  initializeProviderServiceSettings: (payload) =>
    dispatch(initializeProviderServiceSettingsConnect(payload)),
});

const mapStateToProps = ({ business, user }) => ({
  user: _get(user, 'core.value'),
  userRole: selectUserRoleForBusiness({ business }),
  business: _get(business, 'core.value'),
  fishermanLocations: _get(business, 'locations.value'),
  providerServiceSettings: _get(business, 'provider_service_settings.value', []),
});

class GoogleMyBusinessContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: null,
      loading: true,
      locations: null,
      error: null,
    };

    this.handleAccountSelect = this.handleAccountSelect.bind(this);
    this.handleLocationsAssociation = this.handleLocationsAssociation.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
    this.getGoogleMyBusinessAuth = this.getGoogleMyBusinessAuth.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.renderAuthorize = this.renderAuthorize.bind(this);
    this.renderSelectAccount = this.renderSelectAccount.bind(this);
    this.renderSelectLocation = this.renderSelectLocation.bind(this);
    this.renderGMBSettings = this.renderGMBSettings.bind(this);
    this.handleSettingToggle = this.handleSettingToggle.bind(this);
    this.handleLocationSettingToggle = this.handleLocationSettingToggle.bind(this);
  }

  async componentDidMount() {
    return this.refreshGMB();
  }

  async componentDidUpdate(prevProps) {
    const { providerServiceSettings: currentProviderServiceSettings } = this.props;
    const { providerServiceSettings: prevProviderServiceSettings } = prevProps;
    if (
      hasGoogleMyBusinessToken(prevProviderServiceSettings) !==
      hasGoogleMyBusinessToken(currentProviderServiceSettings)
    ) {
      await this.refreshGMB();
    }
  }

  static getServiceSettingToggleUpdate(serviceSetting, name, value) {
    const { service_data: serviceData = {} } = serviceSetting;
    const { sync_settings: syncSettings = {} } = serviceData;

    return GoogleMyBusinessContainer.getServiceSettingUpdate(serviceSetting, {
      ...serviceData,
      sync_settings: {
        ...syncSettings,
        [name]: value,
      },
    });
  }

  static getServiceSettingUpdate(serviceSetting, data) {
    const { service_data: serviceData = {} } = serviceSetting;

    return {
      ...serviceSetting,
      service_data: {
        ...serviceData,
        ...data,
      },
    };
  }

  handleError(error) {
    ErrorHandler.capture(error);
    this.setState({ error });
  }

  async handleBusinessLocationUpdate(data) {
    const {
      business: { type: businessType, id: businessId },
    } = this.props;

    try {
      await API.addGoogleMyBusinessLocation(businessType, businessId, data);
      await this.refreshProviderServiceSettings();
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleBusinessProfileUpdate(update) {
    const {
      business: { type: businessType, id: businessId },
    } = this.props;
    const serviceSetting = this.getGoogleProfileServiceSetting();
    const data = GoogleMyBusinessContainer.getServiceSettingUpdate(serviceSetting, update);

    try {
      await API.updateGoogleMyBusinessProfile(businessType, businessId, serviceSetting.id, data);
      await this.refreshProviderServiceSettings();
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleAccountSelect(data) {
    await this.handleBusinessProfileUpdate(data);
  }

  async handleLocationsAssociation(data) {
    await this.handleBusinessLocationUpdate(data);
  }

  async handleDisconnect() {
    const {
      business: { type: businessType, id: businessId },
    } = this.props;

    this.setState({ loading: true });
    try {
      await API.disconnectGoogleMyBusiness(businessType, businessId);
      await this.refreshProviderServiceSettings();
      this.clearError();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleAccountIssue() {
    this.setState({ loading: true });
    try {
      await this.refreshProviderServiceSettings();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleSettingToggle(e, target) {
    const { name, checked } = target;
    const {
      business: { type: businessType, id: businessId },
    } = this.props;
    const serviceSetting = this.getGoogleProfileServiceSetting();
    const { id: serviceId } = serviceSetting;
    const data = GoogleMyBusinessContainer.getServiceSettingToggleUpdate(
      serviceSetting,
      name,
      checked,
    );

    try {
      await API.updateGoogleMyBusinessProfile(businessType, businessId, serviceId, data);
      await this.refreshProviderServiceSettings();
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleLocationSettingToggle(associatedLocation, target) {
    const { name, checked } = target;
    const {
      business: { type: businessType, id: businessId },
    } = this.props;
    const locationSettings = this.getGoogleLocationServiceSettings();
    const serviceSetting = getLocationSettingForGMBLocation(locationSettings, associatedLocation);
    const { id: serviceId } = serviceSetting;
    const data = GoogleMyBusinessContainer.getServiceSettingToggleUpdate(
      serviceSetting,
      name,
      checked,
    );

    try {
      await API.updateGoogleMyBusinessLocation(businessType, businessId, serviceId, data);
      await this.refreshProviderServiceSettings();
    } catch (error) {
      this.handleError(error);
    }
  }

  async handleSingleAccount(account) {
    const { autoSelectingAccounts } = this.state;
    if (
      this.getCurrentStep() === 2 &&
      !this.hasGoogleProfileServiceSetting() &&
      !autoSelectingAccounts
    ) {
      this.setState({ autoSelectingAccounts: true });
      await this.handleAccountSelect({ gmb_account_id: account.account_id });
    }
  }

  async getGoogleMyBusinessAuth() {
    const {
      business: { type: businessType, id: businessId },
    } = this.props;

    try {
      await API.getGoogleMyBusinessAuth(businessType, businessId).then((response) => {
        if (response.data.url) {
          window.location.assign(response.data.url);
        }
      });
      this.clearError();
    } catch (error) {
      this.handleError(error);
    }
  }

  getAssociatedAccount(accounts) {
    if (accounts) {
      const locationServiceSettings = this.getGoogleLocationServiceSettings().find(
        (serviceSettings) => !!serviceSettings.service_data.gmb_account_id,
      );
      if (locationServiceSettings) {
        const selectedAccountId = locationServiceSettings.service_data.gmb_account_id;
        return accounts.find((account) => account.account_id === selectedAccountId);
      }
    }

    return {};
  }

  getAssociatedLocations() {
    const { locations } = this.state;
    if (locations) {
      const gmbLocationIds = new Set(
        this.getGoogleLocationServiceSettings()
          .filter((serviceSettings) => !!serviceSettings.service_data.gmb_location_id)
          .map((serviceSettings) => serviceSettings.service_data.gmb_location_id),
      );
      return locations.filter((location) => gmbLocationIds.has(location.location_id));
    }
    return null;
  }

  static async getGoogleMyBusinessAccounts(businessType, businessId) {
    const response = await API.getGoogleMyBusinessAccounts(businessType, businessId);

    return response.data;
  }

  async getGoogleMyBusinessLocations(accounts) {
    if (this.hasGoogleProfileServiceSetting() && accounts && accounts.length) {
      const {
        business: { type: businessType, id: businessId },
      } = this.props;

      const locationResponses = await Promise.all(
        accounts.map((account) =>
          API.getGoogleMyBusinessLocations(businessType, businessId, account.account_id),
        ),
      );

      const locations = locationResponses.reduce(
        (data, response) => data.concat(response.data),
        [],
      );

      return locations;
    }
    return null;
  }

  getGoogleLocationServiceSettings() {
    const { providerServiceSettings = [] } = this.props;
    return providerServiceSettings.filter(
      (serviceSettings) =>
        _get(serviceSettings, 'provider') === 'Google' &&
        _get(serviceSettings, 'service_type') === 'Location',
    );
  }

  getGoogleProfileServiceSetting() {
    const { providerServiceSettings = [] } = this.props;
    return providerServiceSettings.find(
      (serviceSetting) =>
        _get(serviceSetting, 'provider') === 'Google' &&
        _get(serviceSetting, 'service_type') === 'Profile',
    );
  }

  getCurrentStep() {
    const { providerServiceSettings = [] } = this.props;
    if (!hasGoogleMyBusinessToken(providerServiceSettings)) {
      return 1;
    }
    if (!this.hasGoogleProfileServiceSetting()) {
      return 2;
    }
    if (!this.hasGoogleMyBusinessLocation()) {
      return 3;
    }
    return 4;
  }

  clearError() {
    this.setState({ error: null });
  }

  async refreshProviderServiceSettings() {
    const { initializeProviderServiceSettings, business } = this.props;

    try {
      const response = await API.getProviderServiceSettings(business.id, business.type);
      initializeProviderServiceSettings(response.data);
      await this.refreshGMB();
    } catch (e) {
      ErrorHandler.capture(e);
      this.handleError(e);
    }
  }

  hasGoogleProfileServiceSetting() {
    const { providerServiceSettings = [] } = this.props;
    return providerServiceSettings.some(
      (serviceSettings) =>
        _get(serviceSettings, 'provider') === 'Google' &&
        _get(serviceSettings, 'service_type') === 'Profile' &&
        _get(serviceSettings, 'service_data') &&
        _get(serviceSettings, 'service_data.gmb_account_id'),
    );
  }

  hasGoogleMyBusinessLocation() {
    return this.getGoogleLocationServiceSettings().some(
      (locationServiceSettings) =>
        _get(locationServiceSettings, 'service_data.gmb_location_id') !== null &&
        _get(locationServiceSettings, 'service_data.gmb_location_id') !== undefined,
    );
  }

  async refreshGMB() {
    const { providerServiceSettings = [] } = this.props;
    if (hasGoogleMyBusinessToken(providerServiceSettings)) {
      const {
        business: { type: businessType, id: businessId },
      } = this.props;

      this.setState({
        loading: true,
      });

      try {
        let accounts = await GoogleMyBusinessContainer.getGoogleMyBusinessAccounts(
          businessType,
          businessId,
        );

        if (this.hasGoogleProfileServiceSetting()) {
          const serviceSetting = this.getGoogleProfileServiceSetting();
          accounts = accounts.filter(
            (account) => account.account_id === serviceSetting.service_data.gmb_account_id,
          );
        }
        const locations = await this.getGoogleMyBusinessLocations(accounts);

        this.setState({
          accounts,
          locations,
        });
      } catch (error) {
        await this.handleAccountIssue();
        this.handleError(error);
      } finally {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  errorSegment() {
    const { error } = this.state;
    if (error) {
      return (
        <Segment attached="bottom">
          <Message negative>
            <Message.Header>Issue Configuring GMB</Message.Header>
            {error.response?.status === 404 && (
              <p>
                There was an error with authentication. Please try again and in case of failure,
                please contact support.
              </p>
            )}
            {error.response?.status !== 404 && (
              <p>There was a {error.message}, please try again later.</p>
            )}
          </Message>
        </Segment>
      );
    }
    return null;
  }

  loadingSegment() {
    const { error } = this.state;

    if (error) {
      return this.errorSegment();
    }
    return <Segment attached="bottom" padded="very" loading />;
  }

  renderStep(step) {
    switch (step) {
      case 1:
        return this.renderAuthorize();
      case 2:
        return this.renderSelectAccount();
      case 3:
        return this.renderSelectLocation();
      case 4:
        return this.renderGMBSettings();
      default:
        throw Error('Unknown step');
    }
  }

  renderAuthorize() {
    const { loading } = this.state;
    const { location } = this.props;

    if (loading) return this.loadingSegment();

    const urlSearchParams = new URLSearchParams(location.search);
    let authError = null;

    if (urlSearchParams.has(GMB_AUTH_ERROR_KEY)) {
      const [authErrorHeader, authErrorMsg] = getErrorHeaderAndMessage(
        urlSearchParams.get(GMB_AUTH_ERROR_KEY),
      );

      authError = <Message negative header={authErrorHeader} content={authErrorMsg} />;
    }

    return (
      <>
        <Segment attached="bottom">
          {authError}
          <Message>
            <Message.Header>Connect Fisherman to Google</Message.Header>
            <p>
              By connecting your Fisherman website to Google, we can automatically update critical
              information about your business as you update it. To enable this functionality, you
              will need to authorize Fisherman to manage your business listing.
            </p>
          </Message>
          <Container textAlign="center">
            <Button size="big" primary onClick={this.getGoogleMyBusinessAuth}>
              Authorize Fisherman
            </Button>
          </Container>
        </Segment>
        {this.errorSegment()}
      </>
    );
  }

  renderSelectAccount() {
    const { accounts = [], loading } = this.state;

    if (accounts && accounts.length === 1) {
      this.handleSingleAccount(accounts[0]);
      return this.loadingSegment();
    }

    if (loading) return this.loadingSegment();

    return <GMBAccountList accounts={accounts} onAccountSelect={this.handleAccountSelect} />;
  }

  renderSelectLocation() {
    const { fishermanLocations, providerServiceSettings = [] } = this.props;
    const { locations, loading } = this.state;

    if (!locations || !fishermanLocations || loading) {
      return this.loadingSegment();
    }
    return (
      <GMBLocationList
        gmbAccountId={getGoogleMyBusinessAccountId(providerServiceSettings)}
        gmbLocations={locations}
        fishermanLocations={fishermanLocations}
        handleDisconnect={this.handleDisconnect}
        onLocationsAssociation={this.handleLocationsAssociation}
      />
    );
  }

  renderGMBSettings() {
    const { loading } = this.state;
    const associatedLocations = this.getAssociatedLocations();
    const profileSetting = this.getGoogleProfileServiceSetting();
    const locationSettings = this.getGoogleLocationServiceSettings();

    return (
      <GMBAccountSettings
        loading={loading}
        associatedLocations={associatedLocations}
        profileSetting={profileSetting}
        locationSettings={locationSettings}
        handleDisconnect={this.handleDisconnect}
        handleGlobalSettingChange={this.handleSettingToggle}
        handleLocationSettingChange={this.handleLocationSettingToggle}
      />
    );
  }

  renderIntro() {
    const currentStep = this.getCurrentStep();

    if (currentStep > 3) return null;

    return (
      <Segment attached="top">
        <Container textAlign="center">
          <Image
            src={GMBLogo}
            style={{
              display: 'inline',
              margin: 0,
              height: '100px',
            }}
          />
        </Container>

        <Container>
          Google Business Profile is a free and easy-to-use tool for businesses and organizations to
          manage their online presence across Google, including Search and Maps. By verifying and
          editing your business information, you can both help customers find you and tell them the
          story of your business.
        </Container>
      </Segment>
    );
  }

  render() {
    const { userRole } = this.props;

    const currentStep = this.getCurrentStep();
    const isSalesAccount = isAuthorizedForSaleViews(userRole);

    if (isSalesAccount) return null;

    return (
      <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
        <InlineFieldContainer title={<h2>Google Business Profile</h2>} enableDelete={false}>
          {this.renderIntro()}
          <Segment attached>
            <GMBSteps currentStep={currentStep} />
          </Segment>
          {this.renderStep(currentStep)}
        </InlineFieldContainer>
      </FormContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(GoogleMyBusinessContainer));
