import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Accordion } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';

import AddModifierSet from './AddModifierSet';
import MenuItemModifierSet from './MenuItemModifierSet';
import { addMenuModifierSet } from '../../../../actions/business';
import { selectBusinessModifierSets } from '../../../../selectors/catalog';
import { CatalogItemPropType } from '../../../modules/catalog/proptypes/catalog';

const propTypes = {
  itemIndex: PropTypes.number.isRequired,
  item: CatalogItemPropType,

  onItemModifierSetsChange: PropTypes.func,
};

const defaultProps = {
  item: {},

  onItemModifierSetsChange: () => null,
};

export default function MenuItemModifierSets({ itemIndex, item, onItemModifierSetsChange }) {
  const dispatch = useDispatch();

  const allModifierSets = useSelector(selectBusinessModifierSets);

  const { id: itemId, modifierSets: itemModifierSetIds = [] } = item || {};

  function getItemModifierSets() {
    const indexedModifierSets = allModifierSets.map((modifierSet, index) => ({
      ...modifierSet,
      index,
    }));

    const orderedModifierSets = itemModifierSetIds.map((itemModifierSetId) =>
      indexedModifierSets.find((modifierSet) =>
        [modifierSet.id, modifierSet.localId].includes(itemModifierSetId),
      ),
    );

    return [
      ...orderedModifierSets,
      ...allModifierSets.filter((id) => typeof id === 'string'),
    ].filter(Boolean);
  }

  const itemModifierSets = getItemModifierSets();

  const [openModifierSetIndex, setOpenModifierSetIndex] = useState(null);

  function handleModifierSetClick(index) {
    if (index === openModifierSetIndex) {
      setOpenModifierSetIndex(null);
    } else {
      setOpenModifierSetIndex(index);
    }
  }

  function onAddMenuModifierSet() {
    dispatch(addMenuModifierSet({ itemIndex }));
  }

  function onLinkItemModifierSet(modifierSetId) {
    const newItemModifierSets = [
      ...new Set([...itemModifierSetIds.filter((id) => typeof id !== 'string'), modifierSetId]),
    ];

    onItemModifierSetsChange(newItemModifierSets);
  }

  function onRemoveMenuItemModifier(modifierSetId) {
    const newItemModifierSets = itemModifierSetIds.filter((id) => id !== modifierSetId);

    onItemModifierSetsChange(newItemModifierSets);
  }

  return (
    <div>
      <Accordion>
        {itemModifierSets.map(
          ({ name, description, minAllowed, maxAllowed, index, id, localId }, localIndex) => (
            <MenuItemModifierSet
              key={`modifier_set_${id || localId}`}
              name={name}
              item
              description={description}
              minAllowed={minAllowed}
              maxAllowed={maxAllowed}
              onRemoveMenuItemModifier={onRemoveMenuItemModifier}
              active={localIndex === openModifierSetIndex}
              onClick={() => handleModifierSetClick(localIndex)}
              id={id}
              localId={localId}
              index={index}
              itemIndex={itemIndex}
              itemId={itemId}
            />
          ),
        )}
      </Accordion>
      <AddModifierSet
        itemId={itemId}
        allModifierSets={allModifierSets}
        itemModifierSets={itemModifierSets}
        onAddNewSet={onAddMenuModifierSet}
        onAddExistingSet={onLinkItemModifierSet}
        showCreateNew={!!itemId}
      />
    </div>
  );
}

MenuItemModifierSets.propTypes = propTypes;
MenuItemModifierSets.defaultProps = defaultProps;
