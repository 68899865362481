import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { NO_RESULTS_MESSAGE } from './CustomerSubmissionsTableBody.constants';
import CustomerSubmissionPropType from '../../types/form-submission';
import CustomerSubmissionsTableRow from '../CustomerSubmissionsTableRow';

const propTypes = {
  submissions: PropTypes.arrayOf(CustomerSubmissionPropType),
  onDetailsButtonClick: PropTypes.func.isRequired,
  columns: PropTypes.number.isRequired,
};

const defaultProps = {
  submissions: [],
};

const CustomerSubmissionsTableBody = ({ submissions, onDetailsButtonClick, columns }) => {
  if (_isEmpty(submissions)) {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={columns} textAlign="center">
            {NO_RESULTS_MESSAGE}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }

  return (
    <Table.Body>
      {submissions.map((submission) => (
        <CustomerSubmissionsTableRow
          key={submission.id}
          submission={submission}
          onDetailsButtonClick={onDetailsButtonClick}
        />
      ))}
    </Table.Body>
  );
};

CustomerSubmissionsTableBody.propTypes = propTypes;
CustomerSubmissionsTableBody.defaultProps = defaultProps;

export default CustomerSubmissionsTableBody;
