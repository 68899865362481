export function getRecipeOptions(values) {
  if (!values && values.length === 0) {
    return [];
  }
  return values.map((value) => ({
    key: value,
    value,
    text: value,
  }));
}
