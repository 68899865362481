import { useSelector } from 'react-redux';

import {
  getRecipeSnippetGeneratorFunction,
  getRecipeStateGeneratorFunction,
  getRecipeStateToTemplateStateFunction,
} from './generators';

export default function useRecipeNodeTranslator(initialContext = {}) {
  const state = useSelector((nextState) => nextState);

  function recipeStateToSnippet(elementType, data = {}, { context = {} } = {}) {
    const generatorFunction = getRecipeSnippetGeneratorFunction(elementType);

    const finalContext = {
      ...initialContext,
      isTemplate: false,
      state,
      elementType,
      recipeStateToSnippet,
      ...context,
    };

    return generatorFunction(data, finalContext);
  }

  function recipeStateToSnippetTemplate(elementType, data = {}) {
    const generatorFunction = getRecipeSnippetGeneratorFunction(elementType);

    const context = {
      ...initialContext,
      isTemplate: true,
      state,
      elementType,
      recipeStateToSnippet,
    };

    return generatorFunction(data, context);
  }

  function recipeSnippetToState(elementType, recipeSnippet = {}) {
    const generatorFunction = getRecipeStateGeneratorFunction(elementType);
    const context = {
      ...initialContext,
      isTemplate: false,
      state,
      elementType,
      recipeSnippetToState,
    };

    return generatorFunction(recipeSnippet, context);
  }

  function recipeStateToTemplateState(elementType, data = {}) {
    const generatorFunction = getRecipeStateToTemplateStateFunction(elementType);
    const context = {
      ...initialContext,
      isTemplate: true,
      state,
      elementType,
      recipeStateToTemplateState,
    };

    return generatorFunction(data, context);
  }

  return {
    recipeStateToSnippet,
    recipeStateToSnippetTemplate,
    recipeSnippetToState,
    recipeStateToTemplateState,
  };
}
