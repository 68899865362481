import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _isObject from 'lodash/isObject';
import _map from 'lodash/map';
import _merge from 'lodash/merge';
import _sortBy from 'lodash/sortBy';
import { v4 as uuidv4 } from 'uuid';

import {
  CATALOG_OBJECT_TYPE_ITEM,
  CATALOG_OBJECT_TYPE_MODIFIER_LIST,
  CATALOG_OBJECT_TYPE_MODIFIER,
  CATALOG_MAX_ORDINAL,
} from './constants';

export function getCatalogObjectId(obj) {
  return _isObject(obj) ? obj.object_id || obj.localId : null;
}

export function findCatalogObjectIndex(objList, obj) {
  return objList.findIndex(
    (objInList) =>
      objInList.type === obj.type && getCatalogObjectId(objInList) === getCatalogObjectId(obj),
  );
}

export function filterCatalogObjectsByIds(objList = [], idList = []) {
  return objList.filter((obj) => idList.includes(getCatalogObjectId(obj)));
}

export function updateCatalogObject(catalogObject, newCatalogObjectData) {
  return _merge({}, catalogObject, newCatalogObjectData);
}

export function updateCatalogObjectData(catalogObject, dataKey, data) {
  if (!(dataKey in catalogObject)) {
    throw Error(`${dataKey} is not available in object`);
  }
  const newObjectData = { [dataKey]: data };
  return updateCatalogObject(catalogObject, newObjectData);
}

export function updateCatalogObjectField(catalogObject, dataKey, fieldName, value) {
  return updateCatalogObjectData(catalogObject, dataKey, { [fieldName]: value });
}

export function filterCatalogObjectsByType(objList, type) {
  return objList.filter((obj) => obj.type === type);
}

/**
 *
 * @param {string} objectType type of the catalog objects that will be filtered
 * @param {*} dataKey name of the key where object data is in
 * @param {*} valueKey name of the key in object data (inside dataKey) used to filter by
 * @param {*} value value used to filter objects that matches it
 * @returns catalog objects filtered by type and value of a given key
 */
export function filterObjectsByRelatedId(objList, objectType, dataKey, valueKey, value) {
  return filterCatalogObjectsByType(objList, objectType).filter(
    (obj) => obj[dataKey][valueKey] === value,
  );
}

/**
 *
 * @param {catalog object array} objList
 * @param {string} dataKey name of the property where ordinal property lives in
 * @returns
 */
export function sortCatalogObjects(objList) {
  function objOrdinal(obj) {
    const dataKey = Object.keys(obj).find((key) => key.endsWith('_data'));
    return _get(obj, `[${dataKey}].ordinal`, CATALOG_MAX_ORDINAL);
  }
  return _sortBy(objList, ['type', objOrdinal]);
}

/**
 * Object containing all the information for a modifier set
 * @typedef {Object} ModifierSet
 * @property {(Object|null)} itemModifierListInfo
 * @property {Object} modifierList
 * @property {Array} modifiers
 * @property {number} itemsAppliedTo
 */

/**
 *
 * @param {catalog object array} objList
 * @param {string} [itemObjectId] - item ID, if provided only modifier sets linked to the item will be returned
 * @returns {Array.<ModifierSet>}
 */
// TODO: unit test this function
export function extractModifierSetsFromCatalog(objList, itemObjectId = null) {
  let modifierLists = [];
  let item = null;
  if (itemObjectId) {
    item = _find(objList, (obj) => getCatalogObjectId(obj) === itemObjectId);
    const modifierListIds = _map(
      _get(item, 'item_data.modifier_lists', []),
      'modifier_list__object_id',
    );
    modifierLists = filterCatalogObjectsByIds(objList, modifierListIds);
  } else {
    modifierLists = filterCatalogObjectsByType(objList, CATALOG_OBJECT_TYPE_MODIFIER_LIST);
  }
  const items = filterCatalogObjectsByType(objList, CATALOG_OBJECT_TYPE_ITEM);
  const modifierSets = modifierLists.map((modifierList) => {
    const modifiers = filterObjectsByRelatedId(
      objList,
      CATALOG_OBJECT_TYPE_MODIFIER,
      'modifier_data',
      'modifier_list',
      modifierList.object_id,
    );
    let itemModifierListInfo = null;
    if (item) {
      const relationData = _find(_get(item, 'item_data.modifier_lists', []), {
        modifier_list__object_id: modifierList.object_id,
      });
      itemModifierListInfo = {
        item: itemObjectId,
        modifier_list: modifierList.object_id,
        min_selected_modifiers: _get(relationData, 'min_selected_modifiers', null),
        max_selected_modifiers: _get(relationData, 'max_selected_modifiers', null),
      };
    }
    const itemsAppliedTo = _filter(items, (catalogItem) => {
      const itemModifierListIds = _map(
        _get(catalogItem, 'item_data.modifier_lists', []),
        'modifier_list__object_id',
      );
      return _includes(itemModifierListIds, modifierList.object_id);
    });
    return {
      itemModifierListInfo,
      modifierList,
      modifiers,
      itemsAppliedTo: itemsAppliedTo.length,
    };
  });
  return _sortBy(modifierSets, ['modifierList.object_id', 'modifierList.localId']);
}

export function createNewModifierSet(itemObjectId = null, isNewItem = false) {
  let itemModifierListInfo = null;
  if (itemObjectId) {
    itemModifierListInfo = {
      item: itemObjectId,
    };
  }
  if (isNewItem) {
    itemModifierListInfo = {
      item: null,
    };
  }
  return {
    itemModifierListInfo,
    modifierList: {
      localId: uuidv4(),
      modifier_list_data: {
        name: '',
      },
      type: CATALOG_OBJECT_TYPE_MODIFIER_LIST,
    },
    modifiers: [],
    itemsAppliedTo: 0,
  };
}
