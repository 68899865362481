import React from 'react';

import { Modal } from 'semantic-ui-react';

import CloseableModal from '../../../common/CloseableModal/CloseableModal';

export default class EditMenuModal extends React.Component {
  cloneTriggerWithProps() {
    const { trigger, openModal, onPreOpenModal } = this.props;
    if (trigger) {
      return React.cloneElement(trigger, {
        onClick: (e) => {
          const triggerOnClick = trigger.props.onClick;

          if (triggerOnClick) {
            triggerOnClick(e);
          }

          if (onPreOpenModal) {
            onPreOpenModal();
          }

          openModal();
        },
      });
    }
    return null;
  }

  render() {
    const {
      open,
      closeModal,
      isNewItem,
      content,
      actions,
      className,
      newItemTitle,
      existingItemTitle,
      subtitle,
      description,
    } = this.props;

    const header = (
      <>
        {isNewItem ? <h1>{newItemTitle}</h1> : <h1>{existingItemTitle}</h1>}
        {subtitle ? <h4 className="menu-edit-modal-subtitle">{subtitle}</h4> : null}
      </>
    );

    return (
      <CloseableModal
        className={`menu-edit-modal ${className}`}
        trigger={this.cloneTriggerWithProps()}
        open={open}
        onClose={closeModal}
        header={header}
      >
        <Modal.Content scrolling>
          {description && <Modal.Description>{description}</Modal.Description>}
          {content}
        </Modal.Content>
        <Modal.Actions>{actions}</Modal.Actions>
      </CloseableModal>
    );
  }
}
