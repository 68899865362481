import React, { useContext } from 'react';

import { Segment, Header, Checkbox, Form } from 'semantic-ui-react';

import TagsField from '../../../fields/components/TagsField';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';
import AnnouncementFormFieldsSettings from '../AnnouncementFormFieldsSettings';

export default function AnnouncementCustomForm() {
  const {
    formEnabled = false,
    submitButtonText = '',
    subscriptionTags = [],
    setValue,
  } = useContext(AnnouncementFormContext);
  return (
    <Segment>
      <Header as="h2">Subscription Form</Header>
      <Checkbox
        toggle
        label="Enable Subscription Form"
        checked={formEnabled}
        onChange={(e, { checked }) => setValue('formEnabled', checked)}
      />
      <Segment className="no-padding" basic disabled={!formEnabled}>
        <Form.Field>
          <label htmlFor="button-submission-text">Button Submission Text</label>
          <Form.Input
            id="button-submission-text"
            placeholder="Submit"
            value={submitButtonText}
            onChange={(e, { value }) => setValue('submitButtonText', value)}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="subscription-tags">Subscription Tags</label>
          <TagsField
            id="subscription-tags"
            currentTags={subscriptionTags}
            availableTags={subscriptionTags}
            onUpdate={(tags) => setValue('subscriptionTags', tags)}
          />
        </Form.Field>
        <AnnouncementFormFieldsSettings />
      </Segment>
    </Segment>
  );
}
