import React from 'react';

import { Grid } from 'semantic-ui-react';

import { getOrderCustomerReportsDownloadUrl } from './CustomerReportsHeader.utils';
import CustomerReportsDowload from '../../../../common/ReportsDownload';
import CustomerSearch from '../CustomerSearch';

import './CustomerReportsHeader.scss';

function CustomerReportsHeader({ filterValue, onFilterCustomer, loading }) {
  return (
    <Grid.Row className="customer-reports-header-row">
      <CustomerSearch
        filterValue={filterValue}
        onFilterCustomer={onFilterCustomer}
        loading={loading}
      />
      <CustomerReportsDowload
        downloadUrl={getOrderCustomerReportsDownloadUrl({ query: filterValue })}
      />
    </Grid.Row>
  );
}

export default CustomerReportsHeader;
