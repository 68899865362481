const options = {
  SOCIAL_MEDIA: [
    'Google',
    'Facebook',
    'Yelp',
    'Instagram',
    'Youtube',
    'Twitter',
    'WhatsApp',
    'LinkedIn',
    'Tripadvisor',
    'Pinterest',
    'TikTok',
  ],
  SOCIAL_MEDIA_PROFILE_URL_TEMPLATE: {
    Google: 'https://google.com/<username>',
    Facebook: 'https://facebook.com/<username>',
    Yelp: 'https://yelp.com/biz/<username>',
    Instagram: 'https://instagram.com/<username>',
    Youtube: 'https://youtube.com/<username>',
    Twitter: 'https://twitter.com/<username>',
    WhatsApp: 'https://wa.me/<username>',
    LinkedIn: 'https://linkedin.com/in/<username>',
    Tripadvisor: 'https://tripadvisor.com/<username>',
    Pinterest: 'https://pinterest.com/<username>',
    TikTok: 'https://tiktok.com/@<username>',
  },
};

export default options;
