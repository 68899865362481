import React from 'react';

import PropTypes from 'prop-types';
import { Table, Button } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { NO_RESULTS_MESSAGE } from './CustomerContactsTableBody.constants';
import { capitalizeAll } from '../../../../../libs/strings';
import TagsField from '../../../fields/components/TagsField';
import CustomerContactPropType from '../../types/customer';

const propTypes = {
  customers: PropTypes.arrayOf(CustomerContactPropType),
  onUpdateCustomer: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  columns: PropTypes.number.isRequired,
  availableTags: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  customers: [],
  availableTags: [],
};

function CustomerContactsTableBody({
  customers,
  onUpdateCustomer,
  onEditButtonClick,
  columns,
  availableTags,
}) {
  function handleUpdateTags(customerId, data) {
    onUpdateCustomer(customerId, data);
  }

  if (_isEmpty(customers)) {
    return (
      <Table.Body>
        <Table.Row>
          <Table.Cell colSpan={columns} textAlign="center">
            {NO_RESULTS_MESSAGE}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }

  return (
    <Table.Body>
      {customers.map((customer) => {
        return (
          <Table.Row key={customer.id}>
            <Table.Cell>{capitalizeAll(customer.first_name)}</Table.Cell>
            <Table.Cell>{capitalizeAll(customer.last_name)}</Table.Cell>
            <Table.Cell>{customer.email}</Table.Cell>
            <Table.Cell>{customer.phone}</Table.Cell>
            <Table.Cell>
              <TagsField
                currentTags={customer.tags}
                availableTags={availableTags}
                onUpdate={(tags) => handleUpdateTags(customer.id, { tags })}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button
                basic
                content="Edit"
                onClick={() => {
                  onEditButtonClick(customer);
                }}
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );
}

CustomerContactsTableBody.propTypes = propTypes;
CustomerContactsTableBody.defaultProps = defaultProps;

export default CustomerContactsTableBody;
