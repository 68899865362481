import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import { getComponentSetting } from 'components/modules/component-configuration/services/component-configuration';
import { DISPLAYABLE_TITLE_BY_GENERATED_PAGE_TYPE } from 'components/modules/pages/constants';
import { capitalizeAll } from 'libs/strings';

export function createGeneratedPageTitle(generatedPageType, businessCategory) {
  return _get(
    DISPLAYABLE_TITLE_BY_GENERATED_PAGE_TYPE,
    `${generatedPageType}.${businessCategory}`,
    capitalizeAll(generatedPageType),
  );
}

export function createGeneratedPage(generatedPageType, components = [], { businessCategory } = {}) {
  const title = createGeneratedPageTitle(generatedPageType, businessCategory);
  const component = _get(components, '[0]', {});
  const pageType = _get(component, 'pageType', '');

  const finalComponents = components.map(({ pageType: currentPageType, ...rest }) => {
    return rest;
  });

  return {
    id: uuid(),
    title,
    pageType,
    generatedPageType,
    components: finalComponents,
    url: '',
    slug: '',
    groupName: '',
    description: '',
  };
}

export function extractGeneratedPages(pages = [], settings = [], { businessCategory } = {}) {
  const generatedPageComponents = {};

  let newPages = pages.map((page) => {
    const { components = [], pageType } = page;

    const nonGeneratedComponents = components.filter((component) => {
      const setting = getComponentSetting(component, settings, { pageType });
      const { generatedPageType } = setting;
      const isGeneratedPage = !_isEmpty(generatedPageType);

      if (isGeneratedPage) {
        generatedPageComponents[generatedPageType] = [
          ...(generatedPageComponents[generatedPageType] || []),
          { ...component, pageType },
        ];
        return false;
      }
      return true;
    });

    return { ...page, components: nonGeneratedComponents };
  });

  // generate synthetic generated pages and add them to the pages array
  Object.keys(generatedPageComponents).forEach((generatedPageType) => {
    const components = generatedPageComponents[generatedPageType];
    const generatedPage = createGeneratedPage(generatedPageType, components, { businessCategory });
    newPages = [...newPages, generatedPage];
  });

  return newPages;
}
