import React, { useContext } from 'react';

import { List } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import FontVariantItem from './components/FontVariantItem';
import { TypographyContext } from '../../contexts/Typography.context';
import { sortFontVariantsByWeight } from '../../services/font';

import './FontVariantsList.scss';

const propTypes = {};
const defaultProps = {};

export default function FontVariantsList() {
  const { sentence, selectedFont } = useContext(TypographyContext);
  if (_isEmpty(selectedFont)) {
    return null;
  }

  const { variants, family } = selectedFont;
  if (_isEmpty(variants)) {
    return null;
  }

  const sortedVariants = sortFontVariantsByWeight(variants);

  return (
    <List className="font-variants-list">
      {sortedVariants.map((variant) => (
        <FontVariantItem family={family} variant={variant} sentence={sentence} />
      ))}
    </List>
  );
}

FontVariantsList.propTypes = propTypes;
FontVariantsList.defaultProps = defaultProps;
