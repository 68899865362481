import { useState } from 'react';

import _clone from 'lodash/clone';
import _get from 'lodash/get';

import { FETCHING_CATALOG_ERROR_MESSAGE } from './constants';
import { getCatalogObjectId, findCatalogObjectIndex, sortCatalogObjects } from './utils';
import API from '../../../../libs/api';
import useAsyncEffect from '../../../modules/foundation/hooks/use-async-effect';

export function useCatalogItemLibrary(businessType, businessId, merchantProductId = null) {
  const [catalog, setCatalog] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState(true);
  const [fetchingCatalogErrorMessage, setFetchingCatalogErrorMessage] = useState('');

  /**
   *
   * @param {catalog object} newObject
   * @param {catalog object} existingObject
   *
   * This method adds a catalog object to the state catalog after the given
   * object (at the beginning if object is null).
   */
  function addObjectAfter(newObject, existingObject = null) {
    setCatalog((prevCatalog) => {
      if (existingObject === null) {
        return [newObject, ...prevCatalog];
      }
      const existingObjectIndex = findCatalogObjectIndex(prevCatalog, existingObject);
      const newObjectIndex = existingObjectIndex + 1;
      return [
        ...prevCatalog.slice(0, newObjectIndex),
        newObject,
        ...prevCatalog.slice(newObjectIndex),
      ];
    });
  }

  /**
   *
   * @param {catalog object category} category
   *
   * This method removes the given category from state catalog
   * if category is empty (local category without name and description)
   */
  function removeEmptyCategory(category) {
    const categoryIsEmpty =
      category.localId &&
      !_get(category, 'category_data.name') &&
      !_get(category, 'category_data.description');

    if (categoryIsEmpty) {
      setCatalog((prevCatalog) => {
        const categoryIndex = findCatalogObjectIndex(prevCatalog, category);

        if (categoryIndex !== -1 && category.localId) {
          return [...prevCatalog.slice(0, categoryIndex), ...prevCatalog.slice(categoryIndex + 1)];
        }

        return prevCatalog;
      });
    }
  }

  function addItem(itemData) {
    setCatalog((prevCatalog) => [...prevCatalog, itemData]);
  }

  /**
   *
   * @param {catalog object category} category
   * This method calls the API to save the given category and
   * save the result in the state catalog to update the local
   * category
   */
  async function saveCategory(category) {
    const { data: savedCategory } = await API.saveCatalogObject(businessType, businessId, {
      ...category,
      merchant_product: merchantProductId,
    });
    setCatalog((prevCatalog) => {
      const categoryIndex = findCatalogObjectIndex(prevCatalog, category);
      return [
        ...prevCatalog.slice(0, categoryIndex),
        savedCategory,
        ...prevCatalog.slice(categoryIndex + 1),
      ];
    });
  }

  async function deleteCategory(category) {
    const { object_id: objectId } = category;

    await API.removeCatalogObject(businessType, businessId, objectId);
    setCatalog((prevCatalog) => {
      const categoryIndex = findCatalogObjectIndex(prevCatalog, category);
      return [...prevCatalog.slice(0, categoryIndex), ...prevCatalog.slice(categoryIndex + 1)];
    });
  }

  function updateItem(itemData) {
    setCatalog((prevCatalog) => {
      const itemIndex = findCatalogObjectIndex(prevCatalog, itemData);
      const newCatalog = _clone(prevCatalog);
      newCatalog[itemIndex] = itemData;
      return newCatalog;
    });
  }

  function deleteItem(itemData) {
    setCatalog((prevCatalog) => {
      return prevCatalog.filter((obj) => getCatalogObjectId(obj) !== getCatalogObjectId(itemData));
    });
  }

  async function reloadCatalog() {
    setLoadingCatalog(true);
    setFetchingCatalogErrorMessage(null);
    try {
      const { data } = await API.getCatalogObjects(businessType, businessId, {
        merchant_product: merchantProductId,
      });
      const sortedCatalog = sortCatalogObjects(data);
      setCatalog(sortedCatalog);
    } catch (err) {
      setFetchingCatalogErrorMessage(FETCHING_CATALOG_ERROR_MESSAGE);
    }
    setLoadingCatalog(false);
  }

  useAsyncEffect(async () => {
    await reloadCatalog();
  }, [businessType, businessId]);

  return {
    catalog,
    loadingCatalog,
    fetchingCatalogErrorMessage,
    addObjectAfter,
    removeEmptyCategory,
    addItem,
    saveCategory,
    deleteCategory,
    updateItem,
    deleteItem,
    reloadCatalog,
  };
}
