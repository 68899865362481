export function getDefaultLocation(locations) {
  if (!locations) return undefined;
  return locations[0];
}

export function updateCustomer(customers = [], customerId, data = {}) {
  return customers.map((customer) => {
    if (customer.id === customerId) {
      return {
        ...customer,
        ...data,
      };
    }
    return customer;
  });
}
