import _ from 'lodash';

import Validate from '../libs/validate';
import store from '../store/store';

export const createInputErrors = () => (dispatch) => {
  dispatch({
    type: 'CREATE_INPUT_ERRORS',
  });
};

export const validateOnBlur = (payload) => (dispatch) => {
  const { routeAndInput } = payload;
  const [route, input, index] = Validate.explodeRouteInput(routeAndInput);
  const state = store.getState();
  const validationResultsMessage = Validate.getValidationErrorMessage(state, route, input, index);

  if (validationResultsMessage) {
    dispatch({
      type: 'ADD_INPUT_ERROR',
      payload: {
        route,
        input,
        index,
        message: validationResultsMessage,
      },
    });
    return false;
  }
  dispatch({
    type: 'CLEAR_INPUT_ERROR',
    payload: {
      route,
      input,
      index,
    },
  });
  return true;
};

export const inputHasError = (routeAndInput) => {
  const state = store.getState();
  const {
    inputerrors: { inputErrors },
  } = state;
  const [route, input, index] = Validate.explodeRouteInput(routeAndInput);
  const pos = _.findIndex(_.get(inputErrors, route, []), (obj) => {
    if (index) {
      return obj.input === input && obj.index === index;
    }
    return obj.input === input;
  });
  return pos > -1;
};
