import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import PartialProptypes from '../../../Partial.propTypes';
import { OBJECT_TYPE_NAMES } from '../../../PartialPage/PartialPage.constants';
import PartialTypeSelect from '../../PartialTypeSelect';
import PartialTagsInputField from '../PartialTagsInputField';

import './PartialForm.scss';

const PartialForm = ({ initialValues, onChange, fieldErrors }) => {
  const formInitialValues = initialValues || {};
  const { name, schemaVersion, type, tags, description } = formInitialValues;

  return (
    <Form>
      <Form.Input
        label="Name"
        name="name"
        value={name}
        placeholder="Name"
        onChange={(e) => onChange('name', e.target.value)}
        error={fieldErrors.name}
      />

      <PartialTypeSelect
        id="type"
        label="Object Type"
        search
        selection
        selectedType={type}
        onChange={(value) => onChange('type', value)}
        error={fieldErrors.type}
        filters={OBJECT_TYPE_NAMES}
      />

      <Form.Input
        label="Schema Version"
        name="schemaVersion"
        value={schemaVersion}
        placeholder="0.0.0"
        readOnly
        onChange={(e) => onChange('schemaVersion', e.target.value)}
        error={fieldErrors.schemaVersion}
      />

      <PartialTagsInputField
        label="Tags"
        tags={tags}
        onChange={(updatedTags) => onChange('tags', updatedTags)}
        id="tags"
      />

      <Form.TextArea
        label="Description"
        name="description"
        value={description}
        placeholder="Description"
        onChange={(e) => onChange('description', e.target.value)}
        error={fieldErrors.description}
      />
    </Form>
  );
};

PartialForm.propTypes = {
  initialValues: PartialProptypes,
  onChange: PropTypes.func.isRequired,
};

PartialForm.defaultProps = {
  initialValues: undefined,
};

export default PartialForm;
