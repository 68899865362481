/* eslint-disable no-underscore-dangle */

import axios from 'axios';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import { v4 as uuid } from 'uuid';

import { createComponentConfigurationContentPayload } from './api.utils';
import ErrorHandler from './errors';
import { objectToCamelCase, objectToSnakeCase } from './format';
import { removeNilValues } from './object';
import Storage from './storage';
import {
  CATALOG_OBJECT_TYPE_ITEM,
  CATALOG_OBJECT_TYPE_TAX,
  CATALOG_OBJECT_TYPE_IMAGE,
  CATALOG_OBJECT_TYPE_MODIFIER_LIST,
} from '../components/pages/ecommerce/catalog/constants';

function getBackendClient() {
  const token = Storage.getJWTToken();

  if (token) {
    return axios.create({
      baseURL: process.env.REACT_APP_FISHERMAN_API_PATH,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axios.create({
    baseURL: process.env.REACT_APP_FISHERMAN_API_PATH,
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken',
    withCredentials: true,
    withXSRFToken: true,
  });
}

const API = {
  defaultClient: axios,
  backendClient: getBackendClient(),

  async get(path, config = {}, errorHandler = this.handleAndLogError) {
    return API.backendClient.get(path, config).catch(errorHandler);
  },

  async post(path, data, config = {}, errorHandler = this.handleAndLogError) {
    const payload = API._createPayload(data);
    const headers = API._createHeaders(data);

    return API.backendClient
      .post(path, payload, { headers: { ...headers }, ...config })
      .catch(errorHandler);
  },

  async patch(path, data, config = {}, errorHandler = this.handleAndLogError) {
    const payload = API._createPayload(data);
    const headers = API._createHeaders(data);

    return API.backendClient
      .patch(path, payload, { headers: { ...headers }, ...config })
      .catch(errorHandler);
  },

  async put(path, data, config = {}, errorHandler = this.handleAndLogError) {
    const payload = API._createPayload(data);
    const headers = API._createHeaders(data);

    return API.backendClient
      .put(path, payload, { headers: { ...headers }, ...config })
      .catch(errorHandler);
  },

  async delete(path, config = {}, errorHandler = this.handleAndLogError) {
    return API.backendClient.delete(path, config).catch(errorHandler);
  },

  isForbiddenError(error) {
    return error && error.response && error.response.status === 403;
  },

  isUnauthorizedError(error) {
    return error && error.response && error.response.status === 401;
  },

  isConflictError(error) {
    return error && error.response && error.response.status === 409;
  },

  async handleConflictError(error) {
    if (API.isConflictError(error)) {
      throw error;
    } else {
      return this.handleAndLogError(error);
    }
  },

  async handleAndLogError(error) {
    if (API.isForbiddenError(error) || API.isUnauthorizedError(error)) {
      try {
        await API.logOutUser();
      } catch (loginError) {
        ErrorHandler.capture(loginError);
      } finally {
        Storage.destroy();
      }
      throw error;
    } else {
      ErrorHandler.capture(error);
      throw error;
    }
  },

  getBusinessTypePath(businessType = '') {
    return `${businessType}s`;
  },

  async searchBusiness(value) {
    const params = {
      input: value,
      session_token: uuid(),
    };

    try {
      const { data: results } = await API.get(`businesses/search/autocomplete/`, {
        params,
      });

      return results;
    } catch (e) {
      return [];
    }
  },

  async getPlaceDetails(placeId) {
    const params = {
      place_id: placeId,
    };

    try {
      const { data } = await API.get(`businesses/search/place/details/`, {
        params,
      });

      return data;
    } catch (e) {
      return {};
    }
  },

  async searchCuratedStockImages(query, page = 1) {
    if (query) {
      try {
        const { data } = await API.get(`/stock-images/?query=${query}&page=${page}`);
        return data;
      } catch (e) {
        ErrorHandler.capture(e);
        return [];
      }
    }

    return [];
  },

  async saveCuratedStockImages(businessType, businessId, urls) {
    try {
      const payload = {
        business_type: businessType,
        business_id: businessId,
        urls: [...urls],
      };
      const { data } = await API.post('/stock-images/', payload);
      return data;
    } catch (e) {
      ErrorHandler.capture(e);
      return [];
    }
  },

  parseSearch(search) {
    if (search) {
      if (search.startsWith('?')) {
        return Object.fromEntries(new URLSearchParams(search.slice(1)));
      }
      return Object.fromEntries(new URLSearchParams(search));
    }
    return {};
  },

  encodeObj(obj) {
    return new URLSearchParams(obj).toString();
  },

  login(username, password) {
    return API.post('/auth/dashboard/login/', {
      username,
      password,
    });
  },

  socialLogin() {
    return API.get('/auth/dashboard/social/login/');
  },

  getSocialToken(providerName) {
    return API.get(`/auth/social_token?provider_name=${providerName}`);
  },

  facebookIntegrationSync(businessId, businessType, { fields = [] } = {}) {
    const payload = { fields };
    return API.post(
      `/integrations/facebook/${API.getBusinessTypePath(businessType)}/${businessId}/sync/`,
      payload,
    );
  },

  getFacebookIntegrationTokenInfo(businessId, businessType) {
    return API.get(
      `/integrations/facebook/${API.getBusinessTypePath(businessType)}/${businessId}/token-info/`,
    );
  },

  getFacebookIntegrationSettings(businessId, businessType) {
    return API.get(
      `/integrations/facebook/${API.getBusinessTypePath(businessType)}/${businessId}/settings/`,
    );
  },

  async getStripeConnectLink(businessId, businessType) {
    const businessTypePath = API.getBusinessTypePath(businessType);
    return API.get(`/integrations/stripe/accounts/${businessTypePath}/${businessId}/connect/`);
  },

  async getStripeConnectSettings(businessId, businessType) {
    const businessTypePath = API.getBusinessTypePath(businessType);
    return API.get(`/integrations/stripe/accounts/${businessTypePath}/${businessId}/settings/`);
  },

  getStripeSession(websiteId, redirectUrl = null) {
    if (redirectUrl) {
      return API.get(
        `/auth/dashboard/billing/get-stripe-session/?websiteId=${websiteId}&redirectUrl=${redirectUrl}`,
      );
    }

    return API.get(`/auth/dashboard/billing/get-stripe-session/?websiteId=${websiteId}`);
  },

  loginMagicLink(email) {
    const branding = process.env.REACT_APP_BRANDING;
    return API.post('/auth/dashboard/magic-link-login/', {
      email,
      ...(branding ? { branding } : {}),
    });
  },

  verifyMagicLink(fishermanAuthToken) {
    const params = {
      fisherman_auth_token: fishermanAuthToken,
    };

    return API.get(`/auth/dashboard/check-magic-link/?${API.encodeObj(params)}`);
  },

  changePassword(oldPassword, newPassword1, newPassword2) {
    return API.post('/auth/dashboard/password/change/', {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2,
    });
  },

  createPassword(newPassword1, newPassword2) {
    return API.post('/auth/dashboard/password/create/', {
      new_password1: newPassword1,
      new_password2: newPassword2,
    });
  },

  resetPassword(email, branding = 'core') {
    return API.post('/auth/dashboard/password/reset/', {
      email,
      branding,
    });
  },

  confirmPasswordReset(newPassword1, newPassword2, uid, token) {
    return API.post('/auth/dashboard/password/reset/confirm/', {
      new_password1: newPassword1,
      new_password2: newPassword2,
      uid,
      token,
    });
  },

  refreshToken() {
    return API.post('/auth/token-refresh/');
  },

  logOutUser() {
    return API.post('/auth/dashboard/logout/');
  },

  submitSignup(payload) {
    return API.post('/businesses/sign-up/', payload);
  },

  createPatch({
    websiteId,
    baseRecipeId,
    patch,
    description,
    patchVersion,
    themeId,
    active,
    draft,
  }) {
    return API.post(`/websites/${websiteId}/patches/`, {
      patch,
      website: websiteId,
      base_recipe: baseRecipeId,
      description,
      patch_version: patchVersion,
      theme: themeId,
      active,
      draft,
    });
  },

  updatePatch(websiteId, patchId, payload) {
    return API.patch(`/websites/${websiteId}/patches/${patchId}/`, payload);
  },

  getRecipes(params) {
    return API.get(`/websites/recipes/`, { params });
  },

  updateRecipeMeta(recipeId, payload) {
    return API.patch(`/websites/recipes/${recipeId}/`, payload);
  },

  getRecipeDiff(recipeId, recipe) {
    return API.post(`/websites/recipes/${recipeId}/diff/`, recipe);
  },

  getPatchFiles(patchId) {
    return API.get(`/websites/patch/${patchId}/files/`);
  },

  getPatches(websiteId) {
    return API.get(`/websites/${websiteId}/patches/`);
  },

  async addUserToWebsite(id, payload) {
    return API.post(`websites/${id}/users/`, payload);
  },

  async removeUserFromWebsite(id, websiteUserId) {
    return API.delete(`websites/${id}/users/${websiteUserId}/`);
  },

  getUserBusinesses(query, page, productType, filters = {}) {
    const pageSize = 5;

    const queryParams = {
      ...filters,
      page,
      query,
      page_size: pageSize,
      productType,
    };

    return API.get(`/businesses/list/?${API.constructQueryParams(queryParams)}`);
  },

  updateWebsiteSubscription(websiteId, payload) {
    return API.patch(`/websites/${websiteId}/subscription/`, payload);
  },

  async getWebsite(websiteId) {
    return API.get(`websites/${websiteId}/`);
  },

  getWebsiteDetails(websiteId) {
    return API.get(`/websites/${websiteId}/details/`);
  },

  getWebsiteRecipe(websiteId) {
    return API.get(`/websites/${websiteId}/recipe/`);
  },

  getPublications(websiteId) {
    return API.get(`/websites/${websiteId}/publications/`);
  },

  getPages(websiteId) {
    return API.get(`/websites/${websiteId}/pages/`);
  },

  savePage(websiteId, payload) {
    return API.post(`/websites/${websiteId}/pages/`, payload);
  },

  updatePage(websiteId, pageId, payload) {
    return API.patch(`/websites/${websiteId}/pages/${pageId}/`, payload);
  },

  deletePage(websiteId, pageId) {
    return API.delete(`/websites/${websiteId}/pages/${pageId}/`);
  },

  getRecipeNodeTemplates(query = '') {
    return API.get(`/websites/recipe-node-templates/`, { params: { query } });
  },

  createRecipeNodeTemplate(payload) {
    return API.post(`/websites/recipe-node-templates/`, payload);
  },

  getCustomPages(websiteId) {
    return API.get(`/websites/custom-pages/?website=${websiteId}`);
  },

  createCustomPage(websiteId, page, recipeNode) {
    const { node } = recipeNode;
    const payload = { website: websiteId, ...page, node };

    return API.post(`/websites/custom-pages/`, payload);
  },

  updateCustomPage(pageAssociation, { page, node } = {}) {
    let payload = {};

    const { id } = pageAssociation;

    if (pageAssociation) {
      payload = { ...payload, ...pageAssociation };
    }
    if (page) {
      payload = { ...payload, page };
    }
    if (node) {
      payload = { ...payload, node };
    }

    return API.patch(`/websites/custom-pages/${id}/`, payload);
  },

  removeCustomPage(pageAssociationId) {
    return API.delete(`/websites/custom-pages/${pageAssociationId}/`);
  },

  updatePageComponentConfiguration(websiteId, pageId, componentConfigId, payload) {
    return API.post(
      `/websites/${websiteId}/pages/${pageId}/configurations/${componentConfigId}/`,
      payload,
    );
  },

  createCustomPageContent(payload) {
    return API.post(`/websites/custom-page-content/`, payload);
  },

  updateCustomPageContent(
    componentConfigurationData,
    contentNodeOptions = [],
    businessFileOptions = [],
  ) {
    const payload = {
      ...componentConfigurationData,
      content_node_configurations:
        _isEmpty(contentNodeOptions) || !_isArray(contentNodeOptions) ? [] : contentNodeOptions,
      business_file_configurations:
        _isEmpty(businessFileOptions) || !_isArray(businessFileOptions) ? [] : businessFileOptions,
    };

    return API.patch(`/websites/custom-page-content/`, payload);
  },

  removeCustomPageContent(componentConfigurationId) {
    return API.delete(`/websites/custom-page-content/${componentConfigurationId}/`);
  },

  getContentNodes(merchantProductId) {
    return API.get(`/content/?merchant_product=${merchantProductId}`);
  },

  updateContentNodeContent(nodeId, newContent, componentConfigurations) {
    return API.patch(`/content/${nodeId}/`, {
      content: newContent,
      component_configurations: componentConfigurations,
    });
  },

  createPageComponentConfiguration(websiteId, pageId, payload) {
    return API.post(`/websites/${websiteId}/pages/${pageId}/configurations/`, payload);
  },

  getDomains(websiteId) {
    return API.get(`/websites/${websiteId}/domains/`);
  },

  getProviderServiceSettings(businessId, businessType) {
    return API.get(`/integrations/${API.getBusinessTypePath(businessType)}/${businessId}/`);
  },

  updateProviderServiceSettings(businessId, businessType, providerServiceSettingId, payload) {
    return API.patch(
      `/integrations/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/${providerServiceSettingId}/`,
      payload,
    );
  },

  deleteProviderServiceSettings(businessId, businessType, providerServiceSettingId) {
    return API.delete(
      `/integrations/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/${providerServiceSettingId}/`,
    );
  },

  getTimezones(businessId, businessType) {
    return API.get(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/locations/timezones/`,
    );
  },

  getCustomerContacts(businessId, businessType, locationId, params) {
    return API.get(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/locations/${locationId}/contacts/`,
      {
        params,
      },
    );
  },

  createCustomerContact(businessId, businessType, locationId, customer) {
    const url = `/businesses/${API.getBusinessTypePath(
      businessType,
    )}/${businessId}/locations/${locationId}/contacts/`;

    return API.post(url, { ...customer, location: locationId, merchant: businessId });
  },

  updateCustomerContact(businessId, businessType, locationId, customerContactId, data) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/locations/${locationId}/contacts/${customerContactId}/`,
      data,
    );
  },

  getCustomerContactsReportUrl(businessId, businessType, locationId) {
    return `/businesses/${API.getBusinessTypePath(
      businessType,
    )}/${businessId}/locations/${locationId}/contacts/`;
  },

  getBlogPosts(businessId, businessType, params) {
    return API.get(`/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/post/`, {
      params,
    });
  },

  saveBlogPost(businessId, businessType, payload) {
    const { id } = payload;

    if (id) {
      return API.patch(
        `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/post/${id}/`,
        payload,
      );
    }

    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/post/`,
      payload,
    );
  },

  deleteBlogPost(businessId, businessType, id) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/post/${id}/`,
    );
  },

  async createBusiness(businessType, payload) {
    const { data } = await API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/`,
      payload,
    );

    return data;
  },

  async createBusinessFile(businessType, businessId, payload, { progressTracker = null } = {}) {
    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/files/`,
      payload,
      { onUploadProgress: progressTracker },
    );
  },

  async deleteBusinessFile(businessType, businessId, fileId) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/files/${fileId}/`,
    );
  },

  async updateBusinessFile(businessType, businessId, fileId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/files/${fileId}/`,
      payload,
    );
  },

  updateBusinessFileConfiguration(businessFileConfigurationId, payload) {
    return API.patch(
      `/businesses/business-file-configuration/${businessFileConfigurationId}/`,
      payload,
    );
  },

  async duplicateBusinessFile(fileId, payload) {
    return API.post(`/businesses/business-file/${fileId}/duplicate/`, payload);
  },

  async updateBusiness(businessId, businessType, payload) {
    try {
      const { data } = await API.patch(
        `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/`,
        payload,
      );
      return data;
    } catch (e) {
      return false;
    }
  },

  deleteLocation(businessId, businessType, locationId) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/locations/${locationId}/`,
    );
  },

  updateLocation(businessId, businessType, locationId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/locations/${locationId}/`,
      payload,
    );
  },

  createPublication(websiteId, triggerDataRefresh = true, nextWebsite = false) {
    const queryParams = {
      update_website: triggerDataRefresh ? 1 : 0,
      next_website: nextWebsite ? 1 : 0,
    };
    const queryParamsString = API.constructQueryParams(queryParams);

    return API.post(
      `/websites/${websiteId}/publications/?${queryParamsString}`,
      {},
      this.handleConflictError,
    );
  },

  searchDomains(domain = '') {
    return API.get(`/domains/search/details/?domain=${domain}`);
  },

  purchaseDomain(payload = {}) {
    return API.post('/domains/purchase/', payload);
  },

  _hasFile(data) {
    if (_isObject(data)) {
      const payloadKeys = Object.keys(data);
      return (
        payloadKeys.includes('patch') ||
        payloadKeys.includes('file') ||
        payloadKeys.includes('image') ||
        payloadKeys.includes('logo') ||
        payloadKeys.includes('avatar') ||
        payloadKeys.includes('hero_image') ||
        payloadKeys.includes('partial')
      );
    }

    return false;
  },

  _getFile(data) {
    if (_isObject(data)) {
      const payloadKeys = Object.keys(data);

      if (payloadKeys.includes('patch')) return ['patch', data.patch];
      if (payloadKeys.includes('file')) return ['file', data.file];
      if (payloadKeys.includes('image')) return ['image', data.image.file];
      if (payloadKeys.includes('logo')) return ['logo', data.logo.file];
      if (payloadKeys.includes('avatar')) return ['avatar', data.avatar.file];
      if (payloadKeys.includes('hero_image')) return ['hero_image', data.hero_image.file];
      if (payloadKeys.includes('partial')) return ['partial', data.partial];
    }

    return [];
  },

  _isFileKey(key) {
    return (
      key === 'patch' ||
      key === 'file' ||
      key === 'image' ||
      key === 'logo' ||
      key === 'avatar' ||
      key === 'hero_image' ||
      key === 'url' ||
      key === 'partial'
    );
  },

  _createHeaders(data) {
    if (API._hasFile(data)) {
      return { 'Content-Type': 'multipart/form-data' };
    }

    return {};
  },

  _createPayload(data) {
    if (_isObject(data) && API._hasFile(data)) {
      const formData = new FormData();
      const [key, file] = API._getFile(data);
      const payloadKeys = Object.keys(data);

      if (key) {
        formData.append(key, file);
      }

      payloadKeys.forEach((payloadKey) => {
        if (!API._isFileKey(payloadKey)) {
          formData.append(payloadKey, data[payloadKey]);
        }
      });

      return formData;
    }

    return data;
  },

  updateLocationTax(businessType, businessId, locationId, taxId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/locations/${locationId}/taxes/${taxId}/`,
      payload,
    );
  },

  getLocationTaxes(businessType, businessId, locationId) {
    return API.get(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/locations/${locationId}/taxes/`,
    );
  },

  uploadMenuJsonFile(businessId, businessType, payload) {
    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/load/`,
      payload,
    );
  },

  getBusinessFiles(businessType, businessId, params = {}) {
    return API.get(`/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/files/`, {
      params,
    });
  },

  getMenu(businessType, businessId) {
    return API.get(`/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/`);
  },

  createMenuSchedule(businessId, businessType, payload) {
    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/schedules/`,
      payload,
    );
  },

  updateMenuSchedule(businessId, businessType, scheduleId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/schedules/${scheduleId}/`,
      payload,
    );
  },

  deleteMenuSchedule(businessId, businessType, scheduleId) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/schedules/${scheduleId}/`,
    );
  },

  createMenuCategory(businessId, businessType, payload) {
    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/categories/`,
      payload,
    );
  },

  updateMenuCategory(businessId, businessType, categoryId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/categories/${categoryId}/`,
      payload,
    );
  },

  deleteMenuCategory(businessId, businessType, categoryId) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/categories/${categoryId}/`,
    );
  },

  updateMenuScheduleCategories(businessId, businessType, scheduleId, scheduleCategories) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/schedules/${scheduleId}/`,
      {
        categories: scheduleCategories,
      },
    );
  },

  createMenuItem(businessId, businessType, payload) {
    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/items/`,
      payload,
    );
  },

  updateMenuItem(businessId, businessType, itemId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/items/${itemId}/`,
      payload,
    );
  },

  deleteMenuItem(businessId, businessType, itemId) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/items/${itemId}/`,
    );
  },

  addMenuCategoryItem(businessId, businessType, categoryId, categoryItems) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/categories/${categoryId}/`,
      {
        items: categoryItems,
      },
    );
  },

  createMenuModifierSet(businessId, businessType, payload) {
    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/modifiersets/`,
      payload,
    );
  },

  updateMenuModifierSet(businessId, businessType, modifierSetId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/modifiersets/${modifierSetId}/`,
      payload,
    );
  },

  deleteMenuModifierSet(businessId, businessType, modifierSetId) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/modifiersets/${modifierSetId}/`,
    );
  },

  addMenuItemModifierSet(businessId, businessType, itemId, itemModifierSets) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/items/${itemId}/`,
      {
        modifier_sets: itemModifierSets,
      },
    );
  },

  createMenuModifier(businessId, businessType, payload) {
    return API.post(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/menu/modifiers/`,
      payload,
    );
  },

  updateMenuModifier(businessId, businessType, modifierId, payload) {
    return API.patch(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/modifiers/${modifierId}/`,
      payload,
    );
  },

  deleteMenuModifier(businessId, businessType, modifierId) {
    return API.delete(
      `/businesses/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/menu/modifiers/${modifierId}/`,
    );
  },

  getFetchOrdersQueryParams(filters) {
    if (filters === null) return {};
    const {
      fromDatetime,
      toDatetime,
      showSubtotals,
      showTotalTaxes,
      showTotalTips,
      showTotals,
      showTotalRefunded,
      showNetTotal,
      fulfillmentStatus,
    } = filters;
    const queryParams = {};
    if (fromDatetime) queryParams.from_date = fromDatetime;
    if (fulfillmentStatus) queryParams.fulfillment_status = fulfillmentStatus;
    if (toDatetime) queryParams.to_date = toDatetime;
    if (showTotalTaxes) queryParams.tax = 1;
    if (showTotalTips) queryParams.tip = 1;
    if (showTotals) queryParams.total = 1;
    if (showSubtotals) queryParams.subtotal = 1;
    if (showTotalRefunded) queryParams.refunded = 1;
    if (showNetTotal) queryParams.net_total = 1;
    return queryParams;
  },

  updateLocationOrder(orderId, payload) {
    return API.patch(`/ordering/orders/${orderId}/`, payload);
  },

  verifyAddress({ address, city, state, zip }) {
    return API.get(`/businesses/address/verify/`, {
      params: { address, city, state, zip },
    });
  },

  verifyTimezone({ street, city, state, zip_code: zip }) {
    return API.get(`/businesses/address/verify/timezone/`, {
      params: { street, city, state, zip },
    });
  },

  refundOrder(orderId, total) {
    return API.post(`/ordering/orders/${orderId}/refund/`, {
      amount: { total },
    });
  },

  getGoogleMyBusinessAuth(businessType, businessId) {
    return API.get(
      `/integrations/gmb/${API.getBusinessTypePath(businessType)}/${businessId}/oauth/`,
    );
  },

  getGoogleMyBusinessAccounts(businessType, businessId) {
    return API.get(
      `/integrations/gmb/${API.getBusinessTypePath(businessType)}/${businessId}/accounts/`,
    );
  },

  getGoogleMyBusinessLocations(businessType, businessId, accountId) {
    return API.get(
      `/integrations/gmb/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/accounts/${accountId}/locations/`,
    );
  },

  updateGoogleMyBusinessProfile(businessType, businessId, settingId, payload) {
    return API.patch(
      `/integrations/gmb/${API.getBusinessTypePath(businessType)}/${businessId}/${settingId}/`,
      payload,
    );
  },

  addGoogleMyBusinessLocation(businessType, businessId, payload) {
    return API.post(
      `/integrations/gmb/${API.getBusinessTypePath(businessType)}/${businessId}/location/`,
      payload,
    );
  },

  updateGoogleMyBusinessLocation(businessType, businessId, settingId, payload) {
    return API.patch(
      `/integrations/gmb/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/location/${settingId}/`,
      payload,
    );
  },

  disconnectGoogleMyBusiness(businessType, businessId) {
    return API.post(
      `/integrations/gmb/${API.getBusinessTypePath(businessType)}/${businessId}/oauth/cancel/`,
      {},
    );
  },

  async getSquareAuthUrl(businessId) {
    return API.get(`/integrations/square/${businessId}/oauth/`);
  },

  async disconnectSquare(businessId) {
    return API.delete(`/integrations/square/${businessId}/`);
  },

  async getSquareMerchantInfo(businessId) {
    return API.get(`/integrations/square/${businessId}/merchant/`);
  },

  async getSquareMerchantLocationsInfo(businessId) {
    return API.get(`/integrations/square/${businessId}/locations/`);
  },

  getCloverAuth(businessId, businessType) {
    return API.get(
      `/integrations/clover/oauth/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/authorize/`,
    );
  },

  getCloverMenu(businessType, businessId) {
    return API.get(
      `/integrations/clover/${API.getBusinessTypePath(businessType)}/${businessId}/menu/import/`,
    );
  },

  updateCloverMerchant(businessId, businessType, settingId, payload) {
    return API.post(
      `/integrations/clover/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/merchant/${settingId}/`,
      payload,
    );
  },

  createToastMerchant(businessId, businessType, payload) {
    return API.post(
      `/integrations/toast/${API.getBusinessTypePath(businessType)}/${businessId}/merchant/`,
      payload,
    );
  },

  updateToastMerchant(businessId, businessType, settingId, payload) {
    return API.post(
      `/integrations/toast/${API.getBusinessTypePath(
        businessType,
      )}/${businessId}/merchant/${settingId}/`,
      payload,
    );
  },

  getBusinessInitializationData(businessType, businessId) {
    return API.get(
      `/businesses/${API.getBusinessTypePath(businessType)}/${businessId}/initialization/`,
    );
  },

  async getHtmlElements(websiteId) {
    return API.get(`/websites/${websiteId}/html-elements/`);
  },

  async deleteHtmlElement(id) {
    return API.delete(`/websites/html-elements/${id}/`);
  },

  async saveHtmlElement(websiteId, item) {
    const { id } = item;
    return id
      ? API.patch(`/websites/html-elements/${id}/`, item)
      : API.post(`/websites/${websiteId}/html-elements/`, item);
  },

  async registerUser(payload) {
    return API.post('auth/register/', payload);
  },

  constructQueryParams(params) {
    if (_isObject(params)) {
      const cleanedParamKeys = Object.keys(params).filter((key) => !_isNil(params[key]));

      return cleanedParamKeys.reduce((paramsString, paramKey, index) => {
        const encodedParam = encodeURIComponent(params[paramKey]);
        const nextParam = `${paramKey}=${encodedParam}`;
        return index === cleanedParamKeys.length - 1
          ? `${paramsString}${nextParam}`
          : `${paramsString}${nextParam}&`;
      }, '');
    }

    return '';
  },

  async getWebsiteAnalyticsEmbed(websiteId) {
    return API.get(`/websites/${websiteId}/analytics/embed/`);
  },

  async getStyleRules(websiteId) {
    return API.get(`/websites/${websiteId}/stylerules/`);
  },

  async createStyleRule(websiteId, payload) {
    return API.post(`/websites/${websiteId}/stylerules/`, payload);
  },

  async updateStyleRule(websiteId, styleRuleId, payload) {
    return API.patch(`/websites/${websiteId}/stylerules/${styleRuleId}/`, payload);
  },

  async deleteStyleRule(websiteId, styleRuleId) {
    return API.delete(`/websites/${websiteId}/stylerules/${styleRuleId}/`);
  },

  async getWebsiteColorPalettes(websiteId, params = {}) {
    return API.get(`/websites/${websiteId}/color-palettes/`, { params: objectToSnakeCase(params) });
  },

  async getColorPalettes(params = {}) {
    return API.get(`/websites/color-palettes/`, { params: objectToSnakeCase(params) });
  },

  async getPaletteParadigms() {
    return API.get(`/websites/color-palettes/paradigms/`);
  },

  async generatePalette({ red, green, blue, name, paradigm, website }) {
    return API.post(`/websites/color-palettes/generate/`, {
      red,
      green,
      blue,
      name,
      paradigm,
      website,
    });
  },

  async getPendingSurveyRequests(websiteId) {
    const queryParams = API.constructQueryParams({
      website_id: websiteId,
    });

    return API.get(`/surveys/pending-survey-requests/?${queryParams}`);
  },

  async postSurveyRequestAnswers(surveyRequestId, answers) {
    const payload = { survey_request_id: surveyRequestId, answers };
    return API.post('surveys/answers/', payload);
  },

  getRecipePartialFile(partialId) {
    return API.get(`/websites/partials/${partialId}/file/`);
  },

  getRecipePartials(params) {
    return API.get('/websites/partials/', { params });
  },

  createRecipePartial(payload) {
    return API.post('/websites/partials/', payload);
  },

  updateRecipePartial(partialId, payload) {
    return API.patch(`/websites/partials/${partialId}/`, payload);
  },

  async getGalleryImages(businessType, businessId) {
    return API.get(`businesses/${businessType}/${businessId}/images/`);
  },

  async updateGalleryImage(businessId, businessType, imageId, payload) {
    try {
      const { data } = await API.patch(
        `businesses/${API.getBusinessTypePath(businessType)}/${businessId}/images/${imageId}/`,
        payload,
      );
      return data;
    } catch (e) {
      return false;
    }
  },

  async getTeams(businessInfoId) {
    return API.get(`businesses/teams/?business_info=${businessInfoId}`);
  },

  async addTeam(businessInfoId, { name, description, order } = {}) {
    const payload = {
      business_info: businessInfoId,
      name: name || '',
      description: description || '',
      order: order || 0,
    };
    return API.post(`businesses/teams/`, payload);
  },

  async updateTeam(teamId, { name, description, order } = {}) {
    const payload = removeNilValues({ name, description, order });
    return API.patch(`businesses/teams/${teamId}/`, payload);
  },

  async deleteTeam(teamId) {
    return API.delete(`businesses/teams/${teamId}`);
  },

  async createTeamMember(payload) {
    return API.post(`businesses/team-members/`, objectToSnakeCase(payload));
  },

  async getBusinessTypeLocations({ businessType, businessId } = {}) {
    return API.get(`businesses/${businessType}/${businessId}/locations`);
  },

  async getTeamMembers({ businessType, businessId }) {
    return API.get(`businesses/${businessType}/${businessId}/team-members/`);
  },

  async deleteTeamMember(teamMemberId) {
    return API.delete(`businesses/team-members/${teamMemberId}/`);
  },

  async updateTeamMember(teamMemberId, payload) {
    return API.put(`businesses/team-members/${teamMemberId}/`, objectToSnakeCase(payload));
  },

  async updateTeamMemberAttribute(teamMemberId, payload) {
    return API.patch(`businesses/team-members/${teamMemberId}/`, objectToSnakeCase(payload));
  },

  async collectBusinessInfo(params) {
    return API.get('businesses/collect/', { params });
  },

  async addGalleryImage(businessType, businessId, payload) {
    const { data } = await API.post(
      `businesses/${API.getBusinessTypePath(businessType)}/${businessId}/images/`,
      payload,
    );
    return data;
  },

  async createFormSubmission(id, businessType, payload) {
    try {
      const { data } = await API.post(
        `businesses/${API.getBusinessTypePath(businessType)}/${id}/submissions/`,
        payload,
      );
      return data;
    } catch (e) {
      return false;
    }
  },

  async updateFormSubmission(
    businessId,
    businessType,
    formId,
    payload,
    createWebsite = 0,
    useAutomatedImageSelection = 0,
  ) {
    try {
      const rawQueryParams = {
        create_website: createWebsite,
        use_automated_image_selection: useAutomatedImageSelection,
      };
      const queryParams = API.constructQueryParams(rawQueryParams);

      const { data } = await API.patch(
        `businesses/${API.getBusinessTypePath(
          businessType,
        )}/${businessId}/submissions/${formId}/?${queryParams}`,
        payload,
      );
      return data;
    } catch (e) {
      return false;
    }
  },

  async getStockImages(query, orientation) {
    const baseUrl = 'https://api.unsplash.com';
    const querySearch = `${baseUrl}/search/photos?`;
    const accessID = process.env.REACT_APP_UNSPLASH_ACCESS_ID;
    const url = `${querySearch}page=1&query=${query}&orientation=${orientation}&client_id=${accessID}`;

    try {
      const {
        data: { results },
      } = await API.defaultClient.get(url).catch(this.handleAndLogError);
      return results.map(({ urls }) => urls.regular);
    } catch (e) {
      ErrorHandler.capture(e);
      return [];
    }
  },

  getGoogleFonts({ sort = 'popularity' } = {}) {
    // sort can be trending, date, alpha, style, or popularity
    const baseUrl = 'https://www.googleapis.com/webfonts/v1/webfonts';
    const key = process.env.REACT_APP_GOOGLE_FONTS_API_KEY;

    return API.defaultClient
      .get(`${baseUrl}?key=${key}&sort=${sort}`)
      .catch(this.handleAndLogError);
  },

  async getComponentConfiguration(websiteId, componentId) {
    return API.get(`/websites/${websiteId}/configurations/${componentId}/`);
  },

  async updateComponentConfiguration(websiteId, componentId, payload) {
    return API.patch(`/websites/${websiteId}/configurations/${componentId}/`, payload);
  },

  async createComponentConfigurationWithData(
    pageId,
    layoutId,
    componentFastId,
    { componentIdentifier, parentComponentConfigurationId, order, enabled = true, data = {} } = {},
    { interactions = [], contentNodes = [], files = [] } = {},
  ) {
    const {
      interactions: interactionsPayload,
      contentNodes: contentNodesPayload,
      files: filesPayload,
    } = createComponentConfigurationContentPayload({
      interactions,
      contentNodes,
      files,
    });

    const payload = {
      page: pageId,
      layout: layoutId,
      component: componentFastId,
      component_identifier: componentIdentifier,
      parent_configuration: parentComponentConfigurationId,
      order,
      enabled,
      data,
      interactions: interactionsPayload,
      content_nodes: contentNodesPayload,
      files: filesPayload,
    };

    return API.post(`/websites/component-configurations/`, payload);
  },

  async updateComponentConfigurationWithData(
    componentConfigurationId,
    { componentIdentifier, parentComponentConfigurationId, order, enabled = true, data = {} } = {},
    { interactions = [], contentNodes = [], files = [] } = {},
  ) {
    const {
      interactions: interactionsPayload,
      contentNodes: contentNodesPayload,
      files: filesPayload,
    } = createComponentConfigurationContentPayload({
      interactions,
      contentNodes,
      files,
    });

    const payload = {
      component_identifier: componentIdentifier,
      parent_configuration: parentComponentConfigurationId,
      order,
      enabled,
      data,
      interactions: interactionsPayload,
      content_nodes: contentNodesPayload,
      files: filesPayload,
    };

    return API.patch(`/websites/component-configurations/${componentConfigurationId}/`, payload);
  },

  async deleteComponentConfiguration(componentConfigurationId) {
    return API.delete(`/websites/component-configurations/${componentConfigurationId}/`);
  },

  async createOrderSettings(payload) {
    return API.post(`/order-settings/`, payload);
  },

  async getOrderSettings(params) {
    return API.get(`/order-settings/`, { params });
  },

  async updateOrderSettings(payload, orderSettingId) {
    return API.patch(`/order-settings/${orderSettingId}/`, payload);
  },

  async saveOrderSettings(orderSettings) {
    if (orderSettings?.id) {
      const { id, ...payload } = orderSettings;
      const { data } = await this.updateOrderSettings(payload, id);
      return data;
    }

    const { data } = await this.createOrderSettings(orderSettings);
    return data;
  },

  createWoflowMenuJob(businessType, businessId, payload) {
    return API.post(
      `/integrations/woflow/${API.getBusinessTypePath(businessType)}/${businessId}/jobs/`,
      payload,
    );
  },

  getWoflowMenuStatus(businessType, businessId, jobId) {
    return API.get(
      `/integrations/woflow/${API.getBusinessTypePath(businessType)}/${businessId}/jobs/${jobId}/`,
    );
  },

  approveWoflowMenu(businessType, businessId, jobId) {
    return API.patch(
      `/integrations/woflow/${API.getBusinessTypePath(businessType)}/${businessId}/jobs/${jobId}/`,
    );
  },

  deleteGalleryImage(businessType, businessId, imageId) {
    return API.delete(
      `businesses/${API.getBusinessTypePath(businessType)}/${businessId}/images/${imageId}/`,
    );
  },

  getMerchantLocationHourGroups(locationId) {
    return API.get(`/hour-groups/`, { params: { location_id: locationId } }).then(
      (response) => response.data,
    );
  },

  createMerchantLocationHourGroups(locationId, groupName) {
    return API.post(`/hour-groups/`, { location: locationId, group_name: groupName }).then(
      (response) => response.data,
    );
  },

  batchProcessMerchantLocationHourGroups(hours) {
    return API.post(`/operating-hours/batch/`, hours).then((response) => response.data || []);
  },

  getMerchantProduct(params = {}) {
    return API.get(`/merchants/products/`, { params })
      .then((response) => response.data)
      .catch(() => []);
  },

  getOrderReports(params = {}) {
    return API.get(`/orders/reports/`, { params }).then((response) => response.data);
  },

  getOrderReportsUrl() {
    return `/orders/reports/`;
  },

  getOrderCustomerReportsUrl() {
    return `/orders/customers/reports/`;
  },

  async updateOrderFulfillmentStatus(orderId, fulfillmentStatus) {
    return API.patch(`/orders/${orderId}/fulfillment/status/`, {
      fulfillment_status: fulfillmentStatus,
    }).then(({ data }) => data);
  },

  getCatalogObjects(businessType, businessId, params = {}) {
    return API.get(`/${businessType}/${businessId}/catalog/`, { params });
  },

  removeCatalogObject(businessType, businessId, objectId) {
    return API.delete(`/${businessType}/${businessId}/catalog/${objectId}/`);
  },

  saveCatalogObject(businessType, businessId, payload) {
    return API.post(`/${businessType}/${businessId}/catalog/`, payload);
  },

  saveRelation(
    relationType,
    enableDisableRelationType,
    relationObjectIds,
    enableRelationObjectIds,
    disableRelationObjectIds,
    shouldUpdateFromObjects = true,
    relationData = null,
  ) {
    const data = {
      relation_type: relationType,
      enable_disable_relation_type: enableDisableRelationType,
      relation_object_ids: relationObjectIds,
      enable_relation_object_ids: enableRelationObjectIds,
      disable_relation_object_ids: disableRelationObjectIds,
      update_from_objects: shouldUpdateFromObjects,
    };
    if (_isObject(relationData)) {
      data.relation_data = relationData;
    }
    return API.post(`/catalog/relations/`, data);
  },

  saveItemModifierListInfo(
    itemId,
    modifierListsToEnable,
    modifierListsToDisable = [],
    relationData = null,
    shouldUpdateFromObjects = true,
  ) {
    return API.saveRelation(
      CATALOG_OBJECT_TYPE_ITEM,
      CATALOG_OBJECT_TYPE_MODIFIER_LIST,
      [itemId],
      modifierListsToEnable,
      modifierListsToDisable,
      shouldUpdateFromObjects,
      relationData,
    );
  },

  saveItemTaxes(itemId, taxesIdToEnable, taxesIdToDisable = [], shouldUpdateFromObjects = true) {
    return API.saveRelation(
      CATALOG_OBJECT_TYPE_ITEM,
      CATALOG_OBJECT_TYPE_TAX,
      [itemId],
      taxesIdToEnable,
      taxesIdToDisable,
      shouldUpdateFromObjects,
    );
  },

  saveCatalogImage(businessType, businessId, payload) {
    return API.post(`/${businessType}/${businessId}/catalog/images/`, payload);
  },

  saveItemImages(itemId, imagesIdToEnable, imagesIdToDisable = [], shouldUpdateFromObjects = true) {
    return API.saveRelation(
      CATALOG_OBJECT_TYPE_ITEM,
      CATALOG_OBJECT_TYPE_IMAGE,
      [itemId],
      imagesIdToEnable,
      imagesIdToDisable,
      shouldUpdateFromObjects,
    );
  },

  getOrderCustomerReports(params = {}) {
    return API.get(`/orders/customers/reports/`, { params }).then((response) => response.data);
  },

  getOrder(orderId) {
    return API.get(`/orders/dashboard/${orderId}/`).then((response) => response.data);
  },

  getShippoAuthenticateURL(businessType, businessId) {
    const url = new URL(`${process.env.REACT_APP_FISHERMAN_API_PATH}/shippo/authenticate/`);
    url.searchParams.append('business_type', businessType);
    url.searchParams.append('business_id', businessId);
    return url.href;
  },

  async getLeads({ leadType = '', hasMerchant = false, query = '' } = {}) {
    const params = {
      lead_type: leadType,
      has_merchant: hasMerchant,
      query,
    };

    return API.get(`/leads/`, { params }).then((response) => response.data);
  },

  async getLead(leadId) {
    return API.get(`/leads/${leadId}/`).then((response) => response.data);
  },

  async textCompletion(businessType, businessId, promptClass, params = {}) {
    const queryParams = objectToSnakeCase({
      ...params,
      business_type: businessType,
      business_id: businessId,
      prompt_class: promptClass,
    });
    return API.get(`/text-completion/`, { params: queryParams }).then((response) => response.data);
  },

  async sendEmailTrigger(emailName, businessType, businessId) {
    const payload = {
      business_type: businessType,
      email_name: emailName,
      business_id: businessId,
    };
    return API.put(`/email-trigger/`, payload);
  },

  async getComponentConfigurations(websiteId) {
    return API.get(`/websites/component-configurations/`, { params: { website: websiteId } }).then(
      (response) => response.data,
    );
  },

  async getWebsiteEvents(params = {}) {
    const queryParams = objectToSnakeCase(params);
    return API.get('/events/website/', { params: queryParams }).then(({ data }) =>
      objectToCamelCase(data),
    );
  },

  async getCustomerTags(businessId) {
    return API.get(`/merchants/${businessId}/customer-tags/`).then(({ data }) =>
      objectToCamelCase(data),
    );
  },

  async getSubmissionChannels(params = {}) {
    return API.get(`/events/channels/`, { params }).then(({ data }) => objectToCamelCase(data));
  },

  async getWebsiteEventStorageUrl(websiteEventId, fileUUID) {
    return API.get(`/events/website/${websiteEventId}/files/${fileUUID}/`).then(({ data }) =>
      objectToCamelCase(data),
    );
  },

  async getDestinationEmails(merchantId) {
    return API.get(`merchants/${merchantId}/destination-emails/`).then(({ data }) =>
      objectToCamelCase(data),
    );
  },

  async addDestinationEmail(merchantId, email) {
    return API.post(`merchants/${merchantId}/destination-emails/`, {
      destination_email: email,
    }).then(({ data }) => objectToCamelCase(data));
  },

  async createRedirect(domainId, { oldPath = '', newPath = '', description = '' } = {}) {
    const payload = {
      domain: domainId,
      old_path: oldPath,
      new_path: newPath,
      description,
    };

    return API.post(`/websites/redirects/`, payload).then(({ data }) => objectToCamelCase(data));
  },

  async updateRedirect(redirectId, payload = {}) {
    return API.patch(`/websites/redirects/${redirectId}/`, objectToSnakeCase(payload)).then(
      ({ data }) => objectToCamelCase(data),
    );
  },

  async getRedirects({ domain } = {}) {
    const params = { domain };
    return API.get(`websites/redirects/`, { params }).then(({ data }) => objectToCamelCase(data));
  },

  async deleteRedirect(redirectId) {
    return API.delete(`websites/redirects/${redirectId}/`);
  },
};

export default API;
