import React from 'react';

import { Container, Image, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import { getToastProviderService, getToastProviderServiceSetting } from './ToastIntegration.utils';
import ToastConfirmationForm from './components/ToastConfirmationForm';
import ToastInstallForm from './components/ToastIntallForm';
import ToastSettingsForm from './components/ToastSettingsForm';
import { FormContainer, InlineFieldContainer } from '../../../common';

import ToastLogo from '../../../../static/logos/toast_logo.png';

import './ToastIntegration.scss';

function ToastIntegration() {
  const providerServices = useSelector((state) => state.business.provider_services.value);
  const providerServiceSettings = useSelector(
    (state) => state.business.provider_service_settings.value,
  );
  const providerService = getToastProviderService(providerServices);
  const providerServiceSetting = getToastProviderServiceSetting(providerServiceSettings);

  function isValidated() {
    const status = _get(providerServiceSetting, 'status');
    return status === 'Complete';
  }

  function getToastForm() {
    if (!providerServiceSetting) return <ToastInstallForm />;
    if (!isValidated()) return <ToastConfirmationForm />;
    return <ToastSettingsForm />;
  }

  if (!providerService) return null;

  return (
    <div className="toast-integration">
      <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
        <InlineFieldContainer title={<h2>Toast</h2>} enableDelete={false}>
          <Segment.Group>
            <Segment>
              <Container>
                <Container textAlign="center">
                  <Image src={ToastLogo} className="toast-logo" />
                </Container>
              </Container>
            </Segment>
            {getToastForm()}
          </Segment.Group>
        </InlineFieldContainer>
      </FormContainer>
    </div>
  );
}

export default ToastIntegration;
