import React from 'react';

import PropTypes from 'prop-types';
import { Header, Table } from 'semantic-ui-react';

import Currency from '../../../../../../../libs/currency';

import './OrderReportsSummary.scss';

const propTypes = {
  subtotal: PropTypes.string.isRequired,
  taxes: PropTypes.string.isRequired,
  tips: PropTypes.string.isRequired,
  fulfillmentFees: PropTypes.string.isRequired,
  grandTotal: PropTypes.string.isRequired,
  refunds: PropTypes.string.isRequired,
  netTotal: PropTypes.string.isRequired,
};

function OrderReportsSummary({
  subtotal,
  taxes,
  tips,
  fulfillmentFees,
  grandTotal,
  refunds,
  netTotal,
}) {
  return (
    <div className="order-reports-summary">
      <Header as="h5">Summary</Header>
      <Table compact celled singleLine>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Subtotal</Table.Cell>
            <Table.Cell>{Currency.format(subtotal)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Taxes</Table.Cell>
            <Table.Cell>{Currency.format(taxes)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Tips</Table.Cell>
            <Table.Cell>{Currency.format(tips)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Fulfillment Fees</Table.Cell>
            <Table.Cell>{Currency.format(fulfillmentFees)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Grand Total</Table.Cell>
            <Table.Cell>{Currency.format(grandTotal)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Refunds</Table.Cell>
            <Table.Cell>({Currency.format(refunds)})</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Net Total</Table.Cell>
            <Table.Cell>{Currency.format(netTotal)}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
}

OrderReportsSummary.propTypes = propTypes;

export default OrderReportsSummary;
