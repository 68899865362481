import _keys from 'lodash/keys';
import semver from 'semver';

import { SCHEMA_VERSION_KEY, RECIPE_VERSION_KEY } from './RecipeMetaEditModal.constants';

export function validateRecipeForm(fields) {
  return _keys(fields).reduce((fieldErrors, fieldName) => {
    const value = fields[fieldName];

    if (!value) {
      return { ...fieldErrors, [fieldName]: `Please enter a recipe ${fieldName}` };
    }

    if ((fieldName === SCHEMA_VERSION_KEY || fieldName === RECIPE_VERSION_KEY) && value) {
      if (!semver.valid(value)) {
        return { ...fieldErrors, [fieldName]: `Please enter a valid version` };
      }
    }

    return fieldErrors;
  }, {});
}
