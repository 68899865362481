import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const PartialTableFooter = ({ pagination, action }) => {
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell>{pagination}</Table.HeaderCell>
        <Table.HeaderCell colSpan="5">{action}</Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  );
};

PartialTableFooter.propTypes = {
  pagination: PropTypes.element,
  action: PropTypes.element,
};

PartialTableFooter.defaultProps = {
  pagination: undefined,
  action: undefined,
};

export default PartialTableFooter;
