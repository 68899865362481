import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { FORM_HEADER_PLACEHOLDER } from './FormHeader.constants';
import MarkdownField from '../../../../../../../../fields/MarkdownField';

import './FormHeader.scss';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  markdown: PropTypes.bool,
};

const defaultProps = {
  markdown: true,
  value: '',
};

export default function FormHeader({ value, onChange, markdown }) {
  return (
    <div className="form-element header">
      <label htmlFor="form-element-header-input">Header*</label>
      {!markdown && (
        <Input
          id="form-element-header-input"
          fluid
          value={value}
          onChange={(e, { value: newValue }) => onChange(newValue)}
          placeholder={FORM_HEADER_PLACEHOLDER}
        />
      )}
      {markdown && (
        <MarkdownField
          id="text-block-content"
          placeholder={FORM_HEADER_PLACEHOLDER}
          value={value}
          required
          onChange={onChange}
          options={{
            autoDisplaySideBySidePreview: false,
            placeholder: FORM_HEADER_PLACEHOLDER,
            minHeight: '100px',
          }}
        />
      )}
    </div>
  );
}

FormHeader.propTypes = propTypes;
FormHeader.defaultProps = defaultProps;
