import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import { Button, Menu, Message, Modal, Tab } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { deleteBusinessFile, flagBusinessSaved, updateBusinessFile } from 'actions/business';
import CloseableModal from 'components/common/CloseableModal';
import ConfirmModal from 'components/common/ConfirmModal';
import ImageEditor from 'components/modules/files/components/ImageEditor';
import { METADATA_KEY_GUMLET } from 'components/modules/files/constants/business-file-configuration';
import {
  exceedsMaxFileSize,
  getLocalFileSize,
  getScaleFactor,
} from 'components/modules/files/services/size';
import {
  BusinessFilePropType,
  BusinessFileConfigurationPropType,
} from 'components/modules/files/types/files';
import useAsyncEffect from 'components/modules/foundation/hooks/use-async-effect';
import API from 'libs/api';
import { getErrorMessage } from 'libs/errors';
import ErrorHandler from 'libs/errors/errors';
import { objectToCamelCase } from 'libs/format';
import { selectBusinessId, selectBusinessType } from 'selectors';

import {
  TAB_IMAGE_DETAILS_SCALE_FACTOR,
  TAB_INDEX_IMAGE_DETAILS,
  TAB_INDEX_IMAGE_EDITOR,
} from './FileEditorModal.constants';
import { useImageEditor } from './FileEditorModal.hooks';
import { getActiveIndex } from './FileEditorModal.utils';
import DisplaySettingsForm from './components/DisplaySettingsForm';
import FileDetailsForm from './components/FileDetailsForm';
import { dataUriToFile } from '../../services/files';

import './FileEditorModal.scss';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  businessFile: BusinessFilePropType.isRequired,
  businessFileConfiguration: BusinessFileConfigurationPropType,
  initialActiveIndex: PropTypes.number,
  onPostSave: PropTypes.func,
  onPostDelete: PropTypes.func,
};

const defaultProps = {
  businessFileConfiguration: {},
  initialActiveIndex: 0,
  onPostSave: () => {},
  onPostDelete: () => {},
};

export default function FileEditorModal({
  open,
  onClose,
  businessFile: initialBusinessFile,
  businessFileConfiguration: initialBusinessFileConfiguration,
  initialActiveIndex,
  onPostSave,
  onPostDelete,
}) {
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const dispatch = useDispatch();

  const {
    id: initialBusinessFileId,
    file: initialFile,
    index: initialBusinessFileIndex,
  } = initialBusinessFile;
  const { id: initialBusinessFileConfigurationId } = initialBusinessFileConfiguration;
  const initialBusinessFileUrl = initialFile;

  const imageEditorRef = useRef(null);
  const [businessFileData, setBusinessFileData] = useState({});
  const [businessFileDataModified, setBusinessFileDataModified] = useState(false);

  const [businessFileConfigurationData, setBusinessFileConfigurationData] = useState({});
  const [businessFileConfigurationDataModified, setBusinessFileConfigurationDataModified] =
    useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showSaveLargeFileConfirmation, setShowSaveLargeFileConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(
    getActiveIndex(initialBusinessFileConfiguration, initialActiveIndex),
  );
  const [editorImageUrl, setEditorImageUrl] = useState(initialBusinessFileUrl);

  const {
    getCurrentImage,
    initialize: initializeImageEditor,
    reset: resetImageEditor,
    getTouched: isImageEditorTouched,
  } = useImageEditor(initialBusinessFileUrl, imageEditorRef);

  function initializeBusinessFile(initialData) {
    const { id, url, file, description, filename } = initialData;

    setBusinessFileData({
      id,
      url,
      file,
      description,
      filename,
    });
  }

  function initializeBusinessFileConfiguration(initialData) {
    setBusinessFileConfigurationData(objectToCamelCase({ ...(initialData || {}) }));
  }

  function onImageQueryParamsChange(e, { value: newValue }) {
    setBusinessFileConfigurationData((previousBusinessFileConfigurationData) => {
      return {
        ...previousBusinessFileConfigurationData,
        metadata: {
          ...(previousBusinessFileConfigurationData.metadata || {}),
          [METADATA_KEY_GUMLET]: newValue,
        },
      };
    });
    setBusinessFileConfigurationDataModified(true);
  }

  function reset() {
    initializeBusinessFile(initialBusinessFile);
    initializeBusinessFileConfiguration(initialBusinessFileConfiguration);
    setError(null);
    setShowDeleteConfirmation(false);
    setIsDeleting(false);
    setIsSaving(false);
    setBusinessFileDataModified(false);
    setBusinessFileConfigurationDataModified(false);
  }

  useEffect(() => {
    initializeBusinessFile(initialBusinessFile);
  }, [initialBusinessFileId]);

  useEffect(() => {
    initializeBusinessFileConfiguration(initialBusinessFileConfiguration);
  }, [initialBusinessFileConfigurationId]);

  useAsyncEffect(async () => {
    if (activeIndex === TAB_INDEX_IMAGE_DETAILS) {
      const currentImage = await getCurrentImage({ multiplier: TAB_IMAGE_DETAILS_SCALE_FACTOR });
      setEditorImageUrl(currentImage);
    }
  }, [activeIndex]);

  function onTabChange(e, { activeIndex: newActiveIndex }) {
    const finalActiveIndex = getActiveIndex(initialBusinessFileConfiguration, newActiveIndex);
    setActiveIndex(finalActiveIndex);
    initializeImageEditor();
  }

  function onChange(fieldName, value) {
    setBusinessFileData((prevBusinessFileData) => ({
      ...prevBusinessFileData,
      [fieldName]: value,
    }));
    setBusinessFileDataModified(true);
  }

  function onCancel() {
    reset();
    resetImageEditor();
    onClose();
  }

  async function onDeleteFile() {
    setIsDeleting(true);

    try {
      await API.deleteBusinessFile(businessType, businessId, initialBusinessFileId);
      if (onPostDelete) {
        await onPostDelete(initialBusinessFile);
      }

      dispatch(deleteBusinessFile(initialBusinessFileIndex));
      dispatch(flagBusinessSaved(true));

      setShowDeleteConfirmation(false);
      onClose();
    } catch (e) {
      ErrorHandler.capture(e);
      setError(getErrorMessage(e));
    } finally {
      setIsDeleting(false);
    }
  }

  async function saveBusinessFile(localFile = null) {
    const editorTouched = await isImageEditorTouched();
    if (initialBusinessFileId && (businessFileDataModified || editorTouched)) {
      const { filename, description } = businessFileData;
      const businessFilePayload = { filename, description };

      if (localFile) {
        businessFilePayload.file = localFile;
      }

      const { data } = await API.updateBusinessFile(
        businessType,
        businessId,
        initialBusinessFileId,
        businessFilePayload,
      );
      dispatch(updateBusinessFile({ index: initialBusinessFileIndex, fields: data }));
    }
  }

  async function saveBusinessFileConfiguration() {
    const { id: businessFileConfigurationId, metadata = {} } = businessFileConfigurationData;
    if (businessFileConfigurationId && businessFileConfigurationDataModified) {
      const businessFileConfigurationPayload = { metadata };

      await API.updateBusinessFileConfiguration(
        businessFileConfigurationId,
        businessFileConfigurationPayload,
      );
    }
  }

  async function onSave({ reduceFile = false } = {}) {
    try {
      setIsSaving(true);
      const { filename = 'file' } = businessFileData;

      let localFile = null;
      const imageAsDataUrl = await getCurrentImage();
      if (await isImageEditorTouched(imageAsDataUrl)) {
        localFile = dataUriToFile(imageAsDataUrl, filename);
      }
      if (exceedsMaxFileSize(localFile) && !reduceFile) {
        setShowSaveLargeFileConfirmation(true);
        setIsSaving(false);
        return;
      }

      if (exceedsMaxFileSize(localFile) && reduceFile) {
        const fileSize = getLocalFileSize(localFile);
        const scaleFactor = getScaleFactor(fileSize);
        const scaledImageDataUrl = await getCurrentImage({ multiplier: scaleFactor });
        localFile = dataUriToFile(scaledImageDataUrl, filename);
      }

      await saveBusinessFile(localFile);
      await saveBusinessFileConfiguration();

      if (onPostSave) {
        await onPostSave();
      }

      dispatch(flagBusinessSaved(true));
      onClose();
    } catch (e) {
      ErrorHandler.capture(e);
      setError(getErrorMessage(e));
    } finally {
      setIsSaving(false);
    }
  }

  async function handleSave() {
    await onSave();
  }

  function onCancelLargeFileSave() {
    setActiveIndex(TAB_INDEX_IMAGE_EDITOR);
    setShowSaveLargeFileConfirmation(false);
  }

  async function onConfirmLargeFileSave() {
    await onSave({ reduceFile: true });
  }

  const { description, filename } = businessFileData;
  const isPlacementEdition = !_isEmpty(initialBusinessFileConfiguration);

  const panes = [
    {
      menuItem: (
        <Menu.Item key="menu-item-details" disabled={isPlacementEdition}>
          Details
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane key="file-details-form-pane">
          <FileDetailsForm
            url={editorImageUrl}
            onChange={onChange}
            description={description}
            filename={filename}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="menu-item-image-editor" disabled={isPlacementEdition}>
          Image Editor
        </Menu.Item>
      ),
      pane: (
        <Tab.Pane key="image-editor-pane">
          <ImageEditor ref={imageEditorRef} imageUrl={initialBusinessFileUrl} />
        </Tab.Pane>
      ),
    },
  ];

  if (isPlacementEdition) {
    panes.push({
      menuItem: 'Display Settings',
      pane: (
        <Tab.Pane key="display-settings-form-pane">
          <DisplaySettingsForm
            businessFile={businessFileData}
            businessFileConfiguration={businessFileConfigurationData}
            onChange={onImageQueryParamsChange}
          />
        </Tab.Pane>
      ),
    });
  }

  return (
    <CloseableModal
      className="file-editor-modal"
      open={open}
      onClose={onClose}
      header="Edit File"
      size="fullscreen"
    >
      <Modal.Content scrolling className="business-file-modal-content">
        <Tab
          menu={{ attached: 'top' }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          renderActiveOnly={false}
        />
      </Modal.Content>
      <Modal.Actions>
        {error && (
          <Message className="file-editor-modal-error" negative>
            {error}
          </Message>
        )}
        {!isPlacementEdition && (
          <Button
            basic
            disabled={isSaving}
            negative
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Delete
          </Button>
        )}
        <Button basic disabled={isSaving} onClick={onCancel}>
          Cancel
        </Button>
        <Button primary onClick={handleSave} loading={isSaving} disabled={isSaving}>
          Save
        </Button>
      </Modal.Actions>
      <ConfirmModal
        open={showDeleteConfirmation}
        header="Delete this File?"
        dimmer="inverted"
        confirmButton={
          <Button negative loading={isDeleting} disabled={isDeleting}>
            Delete
          </Button>
        }
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={onDeleteFile}
      />
      <ConfirmModal
        open={showSaveLargeFileConfirmation}
        header="File Is Too Large"
        content="The file size exceeds the limit. If you continue, the file will be downsized before saving. Alternatively, you can return to the image editor without saving."
        dimmer="inverted"
        confirmButton={
          <Button loading={isSaving} disabled={isSaving}>
            Save Image
          </Button>
        }
        cancelButton={
          <Button negative loading={isSaving} disabled={isSaving}>
            Return To Editor
          </Button>
        }
        onCancel={onCancelLargeFileSave}
        onConfirm={onConfirmLargeFileSave}
      />
    </CloseableModal>
  );
}

FileEditorModal.propTypes = propTypes;
FileEditorModal.defaultProps = defaultProps;
