export const CUSTOM_PRICE = "Let's Talk";
export const MONTHLY_PLAN_CADENCE_DESCRIPTION = '/mo, billed monthly';
export const ANNUAL_PLAN_CADENCE_DESCRIPTION = '/mo, billed annually';
export const CTA_BUTTON_COLOR = 'blue';
export const ACTIVE_BUTTON_COLOR = 'green';
export const MONTHLY_CADENCE = 'Monthly';
export const ANNUAL_CADENCE = 'Annual';
export const CLOVER_COLLECTION_METHOD = 'CloverMarketplace';
export const CHAT_ONLY_BUTTON = 'Contact Us';
export const CURRENT_PLAN_BUTTON = 'Change Plan Cadence';
export const SELECT_BUTTON = 'Select';

export const PRICING_URL = 'https://www.gofisherman.com/pricing/';
export const GLAMMATIC_PRICING_URL = 'https://www.glammatic.com/pricing/';
