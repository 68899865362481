import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import useStyleRule from '../../../style-rules/hooks/useStyleRule';
import { TypographyContext } from '../../contexts/Typography.context';
import {
  getFontFamily,
  familyToStyleRule,
  getWeightsFromGoogleFontRequest,
  areWeightsEqual,
} from '../../services/font';

import './ApplyFontButton.scss';

const propTypes = {
  rule: PropTypes.string.isRequired,
  onApply: PropTypes.func,
};
const defaultProps = {
  onApply: null,
};

export default function ApplyFontButton({ rule: ruleName, onApply: onApplyProp }) {
  const { value: rule, setValue: setRule } = useStyleRule(ruleName);
  const { value: googleFontRequest } = useStyleRule('googleFontRequest');

  const { selectedFont } = useContext(TypographyContext);
  if (_isEmpty(selectedFont)) {
    return null;
  }

  const { family, selectedWeights } = selectedFont;

  function onApply() {
    setRule(familyToStyleRule(family));
    if (onApplyProp) {
      onApplyProp({ ruleName, family });
    }
  }

  function isNewFontVariantsSelected() {
    if (getFontFamily(rule) !== getFontFamily(family)) {
      return true;
    }

    const previousWeights = getWeightsFromGoogleFontRequest(family, googleFontRequest);
    return !areWeightsEqual(previousWeights, selectedWeights);
  }

  const disabled = !isNewFontVariantsSelected() || _isEmpty(selectedWeights);

  return (
    <Button className="apply-font-button" content="APPLY" onClick={onApply} disabled={disabled} />
  );
}

ApplyFontButton.propTypes = propTypes;
ApplyFontButton.defaultProps = defaultProps;
