import React, { Component } from 'react';

import { Button, Modal, Message } from 'semantic-ui-react';

import _ from 'lodash';
import AceEditor from 'react-ace';

import CloseableModal from '../../../../common/CloseableModal';
import { removeNodeMetadata } from '../../utils/recipe-utils';
import { RecipeContextConsumer } from '../context/RecipeContext';

class ModalEditRaw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      localRecipePart: {},
      parsingError: false,
    };

    this.setValues = this.setValues.bind(this);
    this.onRecipePartChange = _.debounce(this.onRecipePartChange.bind(this), 1000);
    this.saveRecipePart = this.saveRecipePart.bind(this);
  }

  onRecipePartChange(value) {
    try {
      const parsedJSON = JSON.parse(value);
      this.setState({
        localRecipePart: parsedJSON,
        parsingError: false,
      });
    } catch (e) {
      this.setState({
        localRecipePart: value,
        parsingError: true,
      });
    }
  }

  setValues() {
    const { recipePart } = this.props;

    this.setState({ localRecipePart: removeNodeMetadata(recipePart) });
  }

  saveRecipePart(updateMethod) {
    const { ky, parentId, close } = this.props;
    const { localRecipePart, parsingError } = this.state;

    if (parsingError) {
      return;
    }

    updateMethod(ky, localRecipePart, parentId);
    close();
  }

  render() {
    const { trigger, close, open } = this.props;
    const { localRecipePart, parsingError } = this.state;

    return (
      <RecipeContextConsumer>
        {({ updateValueInObject }) => (
          <CloseableModal
            trigger={trigger}
            open={open}
            onOpen={this.setValues}
            onClose={close}
            className="developer-modal raw-json-edit"
            header="Edit Raw JSON"
          >
            <Modal.Content>
              <AceEditor
                mode="json"
                theme="solarized_light"
                name="Edit Raw JSON"
                onChange={this.onRecipePartChange}
                fontSize={14}
                showPrintMargin
                showGutter
                highlightActiveLine
                value={parsingError ? localRecipePart : JSON.stringify(localRecipePart, null, 2)}
                style={{ width: '100%', height: '100%' }}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
              />
            </Modal.Content>
            <Modal.Actions>
              {parsingError && (
                <Message
                  error
                  header=" Error parsing raw JSON"
                  list={[
                    'Make sure your JSON syntax is correct',
                    'Remove any trailing commas',
                    'All keys and strings must be double quotes',
                  ]}
                />
              )}
              <Button
                disabled={parsingError}
                className="action"
                content="Save"
                onClick={() => this.saveRecipePart(updateValueInObject)}
              />
            </Modal.Actions>
          </CloseableModal>
        )}
      </RecipeContextConsumer>
    );
  }
}

export default ModalEditRaw;
