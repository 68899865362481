import React, { useState, useEffect } from 'react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import {
  hasWebsiteDisabled,
  isCloverMarketplaceBusiness,
  getCloverProviderServiceSetting,
  hasCloverStatusData,
  hasDomains,
  isBusinessOwner,
  isZupplerSales,
  isZupplerBusiness,
  isDaySmartBusiness,
  getGMBProviderServiceSetting,
} from './OnboardingWizard.utils';
import { FREE_PLAN_NAME } from '../../../../constants/auth';
import { hasFeatureSet, isAdminUser } from '../../../../libs/auth';
import { selectUserRoleForBusiness } from '../../../../selectors/business';
import { selectFeatureSets } from '../../../../selectors/subscriptions';
import BillingIssueModal from '../BillingIssueModal/BillingIssueModal';
import CloverMerchantModal from '../CloverMerchantModal/CloverMerchantModal';
import SkippableDomainSelection from '../SkippableDomainSelection';

import './OnboardingWizard.scss';

export default function OnboardingWizard() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const state = useSelector((stateConnect) => stateConnect);
  const featureSets = useSelector(selectFeatureSets);

  const user = _get(state, 'user.core.value', {});
  const userRole = useSelector(selectUserRoleForBusiness);
  const domains = _get(state, 'website.domains.value', []);
  const business = _get(state, 'business.core.value', {});
  const website = _get(state, 'website.core.value', {});
  const onboardingState = _get(state, 'business.core.value.onboardingState', false);
  const providerServiceSettings = _get(state, 'business.provider_service_settings.value');
  const isAdmin = isAdminUser(user);
  const isMissingDomains = !hasDomains(domains);
  const onboardingStateKey = 'domainSelection';

  function showBillingIssueModal() {
    if (isAdmin) return false;

    return hasWebsiteDisabled(business, website, user);
  }

  function showCloverMerchantOnboarding() {
    if (isAdmin) return false;
    if (!isBusinessOwner(userRole)) return false;

    const shouldSkip = _get(onboardingState, onboardingStateKey, false);
    if (shouldSkip) return false;

    const isFreeTier = hasFeatureSet(featureSets, FREE_PLAN_NAME);
    const serviceSetting = getCloverProviderServiceSetting(providerServiceSettings);
    const googleSetting = getGMBProviderServiceSetting(providerServiceSettings);
    const isMissingCloverSettings = !hasCloverStatusData(serviceSetting);
    const isMissingGoogleSettings = !googleSetting;
    const needsModalSteps =
      isMissingCloverSettings ||
      (!isMissingCloverSettings && isMissingDomains) ||
      (isFreeTier && (isMissingCloverSettings || isMissingGoogleSettings));

    return isCloverMarketplaceBusiness(business) && needsModalSteps;
  }

  function showZupplerOnboarding() {
    if (isAdmin) return false;
    if (!isZupplerSales(user, userRole)) return false;

    return isZupplerBusiness(business) && isMissingDomains;
  }

  function showDaySmartOnboarding() {
    if (!isAdmin) return false;

    return isDaySmartBusiness(business) && isMissingDomains;
  }

  const orderedStepSets = [
    showBillingIssueModal,
    showCloverMerchantOnboarding,
    showZupplerOnboarding,
    showDaySmartOnboarding,
  ];
  const stepSetsToDisplay = orderedStepSets.filter((f) => f());

  const stepSetMap = {
    [showBillingIssueModal]: <BillingIssueModal isOpen={mounted} />,
    [showCloverMerchantOnboarding]: <CloverMerchantModal isOpen={mounted} />,
    [showZupplerOnboarding]: <SkippableDomainSelection isOpen={mounted} canSkip />,
    [showDaySmartOnboarding]: <SkippableDomainSelection isOpen={mounted} canSkip />,
  };

  return _get(stepSetMap, _get(stepSetsToDisplay, '[0]'), <></>);
}
