/* eslint-disable no-underscore-dangle */
const fieldToPath = {
  getPath(stateName, field, method, params) {
    return fieldToPath.getPaths(stateName)[field](method, params);
  },

  getPaths(stateName) {
    if (stateName === 'business') {
      return {
        core: (_, params) => fieldToPath._getBaseBusinessPath(params),
        socialMedia: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'social', params),
        team: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'teammembers', params),
        events: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'events', params),
        galleryImages: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'images', params),
        businessFiles: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'files', params),
        reviews: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'reviews', params),
        locations: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'locations', params),
        taxes: (method, params) => {
          return fieldToPath._getAttributePath(
            stateName,
            method,
            `locations/${params.locationId}/taxes`,
            params,
          );
        },
        specials: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'specials', params),
        menu_categories: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'menu/categories', params),
        menu_items: (method, params) =>
          fieldToPath._getAttributePath(
            stateName,
            method,
            `menu/categories/${params.itemIds[0]}/items`,
            { ...params, itemIds: [params.itemIds[1]] },
          ),
        provider_service_settings: (method, params) =>
          fieldToPath._getAttributePath(
            'provider_service_settings',
            method,
            'provider_service_settings',
            params,
          ),
      };
    }
    if (stateName === 'website') {
      return {
        core: (_, params) => fieldToPath._getBaseWebsitePath(params),
        styleRules: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'stylerules', params),
        colorPalette: (method, params) => {
          return fieldToPath._getAttributePath(stateName, method, 'update-style-rules', params);
        },

        props: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'props', params),
        layouts: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'layouts', params),
        pages: (method, params) =>
          fieldToPath._getAttributePath(stateName, method, 'pages', params),
      };
    }
    if (stateName === 'user') {
      return {
        core: (_, params) => fieldToPath._getBaseUserPath(params),
      };
    }
    return undefined;
  },

  _getBaseBusinessPath({ businessType, businessId }) {
    return `/businesses/${businessType}s/${businessId}/`;
  },

  _getBaseWebsitePath({ websiteId }) {
    return `/websites/${websiteId}/`;
  },

  _getBaseIntegrationPath({ businessType, businessId }) {
    return `/integrations/${businessType}s/${businessId}`;
  },

  _getBaseUserPath({ userId }) {
    return `auth/users/${userId}/`;
  },

  _getAttributePath(type, method, subpath, params) {
    if (method === 'POST') return fieldToPath._getAttributeBasePath(type, subpath, params);
    return fieldToPath._getAttributeItemPath(type, subpath, params);
  },

  _getAttributeBasePath(type, subpath, params) {
    if (type === 'business') return `${fieldToPath._getBaseBusinessPath(params)}${subpath}/`;
    if (type === 'website') return `${fieldToPath._getBaseWebsitePath(params)}${subpath}/`;
    if (type === 'provider_service_settings')
      return `${fieldToPath._getBaseIntegrationPath(params)}/`;
    return undefined;
  },

  _getAttributeItemPath(type, subpath, { itemIds, ...rest }) {
    return `${fieldToPath._getAttributeBasePath(type, subpath, rest)}${itemIds[0]}/`;
  },
};

export default fieldToPath;
