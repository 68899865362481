import React from 'react';

import { Table } from 'semantic-ui-react';

function CustomerReportsTableHeader() {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>First</Table.HeaderCell>
        <Table.HeaderCell>Last</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Phone</Table.HeaderCell>
        <Table.HeaderCell>Total Spent</Table.HeaderCell>
        <Table.HeaderCell>Total Refunded</Table.HeaderCell>
        <Table.HeaderCell>Tips Total</Table.HeaderCell>
        <Table.HeaderCell>Taxes Total</Table.HeaderCell>
        <Table.HeaderCell>Fulfillment Fees Total</Table.HeaderCell>
        <Table.HeaderCell>Subtotals</Table.HeaderCell>
        <Table.HeaderCell>Avg. Total</Table.HeaderCell>
        <Table.HeaderCell># Orders</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}

export default CustomerReportsTableHeader;
