import React from 'react';

import PlusIcon from '../common/PlusIcon';

const AddMenuCategory = ({ onClick }) => (
  <button type="button" className="add-menu-category" onClick={onClick}>
    <PlusIcon />
    <h4>Create Category</h4>
  </button>
);

export default AddMenuCategory;
