import _get from 'lodash/get';
import _isFinite from 'lodash/isFinite';
import _isNaN from 'lodash/isNaN';
import _isNumber from 'lodash/isNumber';
import _toNumber from 'lodash/toNumber';
import { v4 as uuidv4 } from 'uuid';

import API from '../../../../../libs/api';
import { CATALOG_OBJECT_TYPE_MODIFIER_LIST, CATALOG_OBJECT_TYPE_MODIFIER } from '../constants';

export function createNewModifier(modifierListId, extraData = {}) {
  const { ordinal, previousOrdinal, nextOrdinal } = extraData;
  return {
    localId: uuidv4(),
    modifier_data: {
      modifier_list: modifierListId,
      name: '',
      price: '0.00',
      ordinal,
      previous_ordinal: previousOrdinal,
      next_ordinal: nextOrdinal,
    },
    type: CATALOG_OBJECT_TYPE_MODIFIER,
  };
}

export function getModifierListSelectionRange(modifierList, itemModifierListInfo = null) {
  let minSelectedModifiers = _get(
    modifierList,
    'modifier_list_data.default_min_selected_modifiers',
  );
  let maxSelectedModifiers = _get(
    modifierList,
    'modifier_list_data.default_max_selected_modifiers',
  );
  if (itemModifierListInfo) {
    minSelectedModifiers = _get(
      itemModifierListInfo,
      'min_selected_modifiers',
      minSelectedModifiers,
    );
    maxSelectedModifiers = _get(
      itemModifierListInfo,
      'max_selected_modifiers',
      maxSelectedModifiers,
    );
  }
  return {
    minSelectedModifiers,
    maxSelectedModifiers,
  };
}

export function castModifierListSelectionRange({ minSelectedModifiers, maxSelectedModifiers }) {
  let castedMinSelectedModifiers = null;
  if (minSelectedModifiers !== '' && minSelectedModifiers !== null) {
    castedMinSelectedModifiers = _toNumber(minSelectedModifiers);
  }
  let castedMaxSelectedModifiers = null;
  if (maxSelectedModifiers !== '' && maxSelectedModifiers !== null) {
    castedMaxSelectedModifiers = _toNumber(maxSelectedModifiers);
  }
  return {
    minSelectedModifiers: castedMinSelectedModifiers,
    maxSelectedModifiers: castedMaxSelectedModifiers,
  };
}

export function createSelectionRangeDescription({ minSelectedModifiers, maxSelectedModifiers }) {
  if (_isFinite(minSelectedModifiers) && _isFinite(maxSelectedModifiers)) {
    return `Minimum: ${minSelectedModifiers} // Maximum: ${maxSelectedModifiers}`;
  }
  if (_isFinite(minSelectedModifiers)) {
    return `Minimum: ${minSelectedModifiers}`;
  }
  if (_isFinite(maxSelectedModifiers)) {
    return `Maximum: ${maxSelectedModifiers}`;
  }
  return '';
}

export async function saveModifierList(
  businessType,
  businessId,
  merchantProduct,
  modifierListObjectId,
  name,
  defaultMinSelectedModifiers = null,
  defaultMaxSelectedModifiers = null,
) {
  const modifierListPayload = {
    modifier_list_data: {
      name,
    },
    type: CATALOG_OBJECT_TYPE_MODIFIER_LIST,
  };
  if (modifierListObjectId) {
    modifierListPayload.object_id = modifierListObjectId;
  }
  if (_isNumber(defaultMinSelectedModifiers) && !_isNaN(defaultMinSelectedModifiers)) {
    modifierListPayload.modifier_list_data.default_min_selected_modifiers =
      defaultMinSelectedModifiers;
  }
  if (_isNumber(defaultMaxSelectedModifiers) && !_isNaN(defaultMaxSelectedModifiers)) {
    modifierListPayload.modifier_list_data.default_max_selected_modifiers =
      defaultMaxSelectedModifiers;
  }

  const { data } = await API.saveCatalogObject(businessType, businessId, {
    ...modifierListPayload,
    merchant_product: merchantProduct.id,
  });

  return data;
}

export async function saveModifier(
  businessType,
  businessId,
  merchantProduct,
  modifierObjectId,
  modifierListObjectId,
  name,
  price,
  ordinal,
  previousOrdinal,
  nextOrdinal,
) {
  const modifierPayload = {
    modifier_data: {
      name,
      price,
      ordinal,
      previous_ordinal: previousOrdinal,
      next_ordinal: nextOrdinal,
      modifier_list: modifierListObjectId,
    },
    type: CATALOG_OBJECT_TYPE_MODIFIER,
  };
  if (modifierObjectId) {
    modifierPayload.object_id = modifierObjectId;
  }

  const { data } = await API.saveCatalogObject(businessType, businessId, {
    ...modifierPayload,
    merchant_product: merchantProduct.id,
  });

  return data;
}
