import React from 'react';

export default class ControlledModal extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ open: true });
  }

  closeModal() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ open: false });
  }

  render() {
    return <div />;
  }
}
