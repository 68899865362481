import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Grid, Message } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _keys from 'lodash/keys';

import { validatePartialForm } from './RecipePartialModal.utils';
import { diff } from '../../../../../../libs/objects';
import CloseableModal from '../../../../../common/CloseableModal';
import PartialProptypes from '../../../Partial.propTypes';
import {
  MESSAGE_TIMEOUT_SECONDS,
  PARTIAL_FIELDS_DEFAULT_SHAPE,
  CSS_KEY,
  JSON_KEY,
} from '../../../PartialPage/PartialPage.constants';
import PartialFileInput from '../../form/PartialFileInput';
import PartialForm from '../../form/PartialForm';
import PartialJsonEditor from '../PartialJsonEditor';

const RecipePartialModal = ({
  open,
  header,
  trigger,
  onCancel,
  onSave,
  isSaving,
  isNewPartial,
  initialValues,
}) => {
  const [fields, setFields] = useState(PARTIAL_FIELDS_DEFAULT_SHAPE);
  const [partialFile, setPartialFile] = useState();
  const [partialJson, setPartialJson] = useState();
  const [modalEditRawOpened, setModalEditRawOpened] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [uploadFileError, setUploadFileError] = useState();

  function setInitialFormValues() {
    if (!initialValues) return;
    const { name, schemaVersion, type, tags, description } = initialValues;
    setFields({
      name,
      schemaVersion,
      type,
      tags,
      description,
    });
  }

  useEffect(() => {
    if (!_isEmpty(fieldErrors)) setTimeout(() => setFieldErrors({}), MESSAGE_TIMEOUT_SECONDS);

    if (initialValues) setInitialFormValues();
  }, [fieldErrors, initialValues]);

  const resetFormValues = () => {
    setFields(PARTIAL_FIELDS_DEFAULT_SHAPE);
    setPartialJson(null);
    setPartialFile(null);
    setFieldErrors({});
    setUploadFileError('');
  };

  const handleCancel = () => {
    resetFormValues();
    onCancel();
  };

  const handleModalOpen = () => {
    if (isNewPartial) {
      resetFormValues();
    } else {
      setInitialFormValues();
    }
  };

  function handleInputChange(name, value) {
    const { [name]: tmp, ...rest } = fields;

    setFields({
      [name]: value,
      ...rest,
    });
  }

  const isPartialSet = () => partialJson || partialFile;

  const handleSaveNewPartial = () => {
    let payload = {};
    const { name, schemaVersion, type, description, tags } = fields;
    const partial = partialFile || partialJson;
    const errors = validatePartialForm({
      name,
      schemaVersion,
      type,
      description,
    });

    if (_keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    payload = { name, schemaVersion, type, description, partial };
    if (!_isEmpty(fields.tags)) payload.tags = tags;
    onSave(payload);
  };

  const handleSavePartial = () => {
    const { name, schemaVersion, type, description } = fields;
    const errors = validatePartialForm({
      name,
      schemaVersion,
      type,
      description,
    });

    if (_keys(errors).length) {
      setFieldErrors(errors);
      return;
    }
    const updatedValues = diff(initialValues, fields);
    onSave(updatedValues);
  };

  function handleSetRawPartialJson(content) {
    setPartialJson(
      new Blob([JSON.stringify(content)], {
        type: 'text/json',
      }),
    );
    setModalEditRawOpened(false);
  }

  function handleFileUploadError(err) {
    setUploadFileError(err);
    setTimeout(() => setUploadFileError(''), MESSAGE_TIMEOUT_SECONDS);
  }

  function renderEditModalActions() {
    return (
      <>
        <Button primary onClick={handleSavePartial} loading={isSaving} disabled={isSaving}>
          Save
        </Button>
      </>
    );
  }

  function getEditorMode() {
    return fields.type.toLowerCase() === CSS_KEY ? CSS_KEY : JSON_KEY;
  }

  function renderNewPartialActions() {
    const partialDefined = isPartialSet();
    return (
      <Grid>
        <Grid.Column textAlign="left" width="8" />
        <Grid.Column textAlign="right" width="8">
          {!partialDefined && (
            <Button.Group>
              <PartialFileInput
                onChange={(file) => setPartialFile(file)}
                onError={(err) => handleFileUploadError(err)}
              />
              <Button.Or />
              <PartialJsonEditor
                open={modalEditRawOpened}
                header="Edit Raw"
                mode={getEditorMode()}
                onSave={handleSetRawPartialJson}
                onCancel={handleCancel}
                trigger={
                  <Button onClick={() => setModalEditRawOpened(true)} primary>
                    Insert Code
                  </Button>
                }
              />
            </Button.Group>
          )}

          {partialDefined && (
            <Button primary onClick={handleSaveNewPartial} loading={isSaving} disabled={isSaving}>
              Save
            </Button>
          )}
        </Grid.Column>
      </Grid>
    );
  }

  return (
    <CloseableModal
      open={open}
      onOpen={handleModalOpen}
      size="small"
      onClose={handleCancel}
      trigger={trigger}
      dimmer="inverted"
      header={header}
    >
      <Modal.Content>
        <PartialForm
          onChange={handleInputChange}
          fieldErrors={fieldErrors}
          initialValues={fields}
        />
      </Modal.Content>
      <Modal.Actions>
        {uploadFileError && <Message error list={[uploadFileError]} />}
        {isNewPartial ? renderNewPartialActions() : renderEditModalActions()}
      </Modal.Actions>
    </CloseableModal>
  );
};

export default RecipePartialModal;

RecipePartialModal.propTypes = {
  open: PropTypes.bool,
  isNewPartial: PropTypes.bool,
  initialValues: PartialProptypes,
  header: PropTypes.string,
  isSaving: PropTypes.bool,
  trigger: PropTypes.element,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

RecipePartialModal.defaultProps = {
  open: undefined,
  isNewPartial: true,
  initialValues: null,
  header: undefined,
  trigger: undefined,
  isSaving: false,
};
