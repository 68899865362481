import { METADATA_KEY_GUMLET } from 'components/modules/files/constants/business-file-configuration';
import { PLACEHOLDER_MAX_SIZE } from 'components/modules/files/constants/placeholder-images';
import { createQueryParams } from 'components/modules/files/services/gumlet';
import Utils from 'libs/utils';

function getPlaceholderImageDimensions({ width, height }) {
  let finalWidth = width;
  let finalHeight = height;

  if (!!width && !!height) {
    // Keep aspect ratio with a max value of PLACEHOLDER_MAX_SIZE in the biggest dimension
    const maxDimension = Math.max(width, height);
    if (maxDimension > PLACEHOLDER_MAX_SIZE) {
      if (width > height) {
        finalWidth = PLACEHOLDER_MAX_SIZE;
        finalHeight = Math.round((height / width) * PLACEHOLDER_MAX_SIZE);
      } else {
        finalHeight = PLACEHOLDER_MAX_SIZE;
        finalWidth = Math.round((width / height) * PLACEHOLDER_MAX_SIZE);
      }
    }
  } else if (width) {
    finalWidth = Math.min(width, PLACEHOLDER_MAX_SIZE);
  } else if (height) {
    finalHeight = Math.min(height, PLACEHOLDER_MAX_SIZE);
  } else {
    finalWidth = PLACEHOLDER_MAX_SIZE;
  }
  return { width: finalWidth, height: finalHeight };
}

function getResizedImageUrlFromGumletQueryParams(url, gumletMetadata) {
  const { desktop = {} } = gumletMetadata;
  const { width, height, ...rest } = desktop;
  const { width: finalWidth, height: finalHeight } = getPlaceholderImageDimensions({
    width,
    height,
  });
  const queryParams = createQueryParams({ width: finalWidth, height: finalHeight, ...rest });
  return queryParams ? `${url}${queryParams}` : url;
}

function getResizedImageUrl(validUrl, metadata) {
  if (Object.keys(metadata).includes(METADATA_KEY_GUMLET)) {
    return getResizedImageUrlFromGumletQueryParams(validUrl, metadata[METADATA_KEY_GUMLET]);
  }
  return Utils.addImageQueryParam(validUrl, 'width', PLACEHOLDER_MAX_SIZE);
}

export function getPlaceholderImage({ file = '', url = '', metadata = {} }) {
  const validUrl = !file || file.startsWith('/') ? url : file;
  return getResizedImageUrl(validUrl || '', metadata || {});
}
