import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import PartialTableBody from './PartialTableBody';
import PartialTableFooter from './PartialTableFooter';
import PartialTableHeader from './PartialTableHeader';
import PartialTableItem from './PartialTableItem';

const PartialTable = ({ children }) => {
  return (
    <>
      <Table padded striped>
        {children}
      </Table>
    </>
  );
};

PartialTable.propTypes = {
  children: PropTypes.node,
};

PartialTable.defaultProps = {
  children: undefined,
};

PartialTable.Header = PartialTableHeader;
PartialTable.Body = PartialTableBody;
PartialTable.Item = PartialTableItem;
PartialTable.Footer = PartialTableFooter;

export default PartialTable;
