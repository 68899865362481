import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Checkbox, Modal } from 'semantic-ui-react';

import _ from 'lodash';

import ErrorHandler from '../../../libs/errors';
import CloseableModal from '../CloseableModal/CloseableModal';

import './AddressVerificationModal.scss';

const AddressVerificationModal = ({ isOpen, candidates, onSelect, onSubmit, onClose, index }) => {
  const [loading, setLoading] = useState(false);
  const handleSelect = (e, { value }) => onSelect(value);
  const handleSubmit = async () => {
    setLoading(true);

    try {
      const candidate = candidates[index];
      onSubmit({ ...candidate });
      onClose();
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  };

  const formCandidates = candidates.map((candidate) => {
    const { address, city, state, zip, id } = candidate;
    const label = `${_.startCase(address.toLowerCase())}, ${_.startCase(
      city.toLowerCase(),
    )}, ${state}, ${zip}`;

    return (
      <Form.Field key={candidate.id}>
        <Checkbox
          radio
          label={label}
          name="checkboxCandidates"
          value={id}
          checked={index === id}
          onChange={handleSelect}
        />
      </Form.Field>
    );
  });

  return (
    <CloseableModal
      className="address-verification-modal"
      open={isOpen}
      onClose={onClose}
      size="small"
      header="Select the correct address"
    >
      <Modal.Content scrolling>
        <Form>{formCandidates}</Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={index === null || loading}
          onClick={handleSubmit}
          loading={loading}
          content="Choose Selected"
          className="action"
        />
      </Modal.Actions>
    </CloseableModal>
  );
};

AddressVerificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.string,
      city: PropTypes.string,
      id: PropTypes.number,
      state: PropTypes.string,
      zip: PropTypes.string,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AddressVerificationModal.defaultProps = {
  candidates: [],
};

export default AddressVerificationModal;
