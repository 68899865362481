import API from '../../../../../../../libs/api';
import { IMAGE_COMPONENT_NAME } from '../../../../constants/types';

export async function updateImageConfiguration(componentConfigurationId, images, widgetType) {
  const componentConfigurationData = { id: componentConfigurationId, component: widgetType };
  const contentNodeOptions = [];
  const businessFileOptions = images.map(({ id, businessFileConfigurationId }, index) => ({
    id: businessFileConfigurationId,
    business_file: id,
    order: index,
  }));

  const { data: results } = await API.updateCustomPageContent(
    componentConfigurationData,
    contentNodeOptions,
    businessFileOptions,
  );

  return results[0];
}

export async function createImageConfiguration(images, widgetType, pageId) {
  const businessFileOptions = images.map(({ id }, index) => ({
    business_file: id,
    order: index,
  }));

  const payload = {
    content_node_configurations: [],
    business_file_configurations: businessFileOptions,
    component: widgetType,
    page: pageId,
  };

  const { data: results } = await API.createCustomPageContent(payload);

  return results[0];
}

export function updateImageElementFromSave(currentElement, result) {
  const {
    fast_id: componentName,
    component_identifier: componentIdentifier,
    id: newComponentConfigurationId,
  } = result;

  return {
    ...currentElement,
    widgetType: componentName,
    componentName,
    componentIdentifier,
    componentConfigurationId: newComponentConfigurationId,
  };
}

export function isSingleImageWidgetType(widgetType) {
  return [IMAGE_COMPONENT_NAME].includes(widgetType);
}
