import { createContext } from 'react';

export const EditPageElementModalContext = createContext({
  businessId: null,
  businessType: null,
  websiteId: null,
  page: {},
  registerCallback: () => null,
  onUpdateElementState: () => null,
});

export const EditPageElementModalContextProvider = EditPageElementModalContext.Provider;
export const EditPageElementModalContextConsumer = EditPageElementModalContext.Consumer;
