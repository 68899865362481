import API from '../../../../../../libs/api';
import { isValidUrl, isCSVFile } from '../../WoflowMenuActions.utils';

export function validateUrls(fileUrls) {
  return fileUrls.reduce((previousValue, currentValue) => {
    return previousValue && isValidUrl(currentValue);
  }, true);
}

export async function postAddFile(businessId, businessType, file) {
  const fileName = file.name;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('filename', fileName);
  let response;
  if (isCSVFile(fileName)) {
    response = await API.createBusinessFile(businessType, businessId, formData);
    const {
      data: { id, file: url, filename },
    } = response;
    return { id, url, filename };
  }
  formData.append('tags', ['woflow']);
  const { id, file: url, filename } = await API.addGalleryImage(businessType, businessId, formData);
  return { id, url, filename };
}

export async function postDeleteFile(businessType, businessId, file) {
  if (isCSVFile(file.filename)) {
    await API.deleteBusinessFile(businessType, businessId, file.id);
  } else {
    await API.deleteGalleryImage(businessType, businessId, file.id);
  }
}
