import React from 'react';

import PropTypes from 'prop-types';
import { Card, Image, Button, Icon } from 'semantic-ui-react';

import { IMAGE_FILE_TYPES } from '../../constants/constants';
import Utils from '../../libs/utils';

class ImagePlaceholderField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileInput: null,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.setFileInput = this.setFileInput.bind(this);
  }

  handleUpdate(e, target) {
    const { onChange } = this.props;
    onChange(e, target || e.target);
  }

  handleDelete() {
    const { onDelete } = this.props;
    onDelete();
  }

  getFileField() {
    const { multiple = false } = this.props;

    return (
      <input
        ref={this.setFileInput}
        hidden
        type="file"
        onChange={this.handleUpdate}
        accept={IMAGE_FILE_TYPES}
        multiple={multiple}
      />
    );
  }

  getDoubleButtons() {
    const { enableDelete } = this.props;
    const { fileInput } = this.state;

    return (
      <div className="ui two buttons">
        <Button basic color="green" onClick={() => fileInput.click()}>
          <Icon name="edit" />
        </Button>
        {enableDelete && (
          <Button basic color="red" onClick={this.handleDelete}>
            <Icon name="delete" />
          </Button>
        )}
      </div>
    );
  }

  getSingleButton() {
    const { fileInput } = this.state;

    return (
      <Button
        basic
        color="green"
        fluid
        icon="plus"
        onClick={() => fileInput.click()}
        style={{ height: '100%' }}
      />
    );
  }

  setFileInput(e) {
    this.setState({
      fileInput: e,
    });
  }

  render() {
    const { url, title, onClick, className } = this.props;

    if (url) {
      return (
        <Card raised={false} className={className}>
          {url.toLowerCase().endsWith('.pdf') ? (
            <div
              onClick={onClick}
              role="button"
              onKeyDown={onClick}
              tabIndex={-1}
              style={{
                width: 'auto',
                height: '200px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                cursor: onClick ? 'pointer' : 'auto',
              }}
            >
              <Icon name="file pdf outline" size="huge" disabled />
            </div>
          ) : (
            <Image
              onClick={onClick}
              size="small"
              src={Utils.addImageQueryParam(url, 'width', 500)}
              style={{
                width: 'auto',
                height: '200px',
                objectFit: 'cover',
                cursor: onClick ? 'pointer' : 'auto',
              }}
            />
          )}
          {title && (
            <Card.Content>
              <Card.Description
                style={{
                  wordBreak: 'break-all',
                }}
              >
                {title}
              </Card.Description>
            </Card.Content>
          )}
          <Card.Content extra>
            {this.getDoubleButtons()}
            {this.getFileField()}
          </Card.Content>
        </Card>
      );
    }
    return (
      <Card raised={false}>
        <Card.Content onClick={onClick}>
          <div
            style={{
              width: 'auto',
              height: '200px',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Icon name="image outline" size="huge" disabled />
          </div>
        </Card.Content>
        <Card.Content extra>
          {this.getSingleButton()}
          {this.getFileField()}
        </Card.Content>
      </Card>
    );
  }
}

ImagePlaceholderField.propTypes = {
  url: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  enableDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
};

ImagePlaceholderField.defaultProps = {
  url: '',
  title: '',
  enableDelete: false,
  onDelete: () => null,
  onClick: null,
};

export default ImagePlaceholderField;
