import React from 'react';

import { Form, Grid, TextArea } from 'semantic-ui-react';

export default (props) => {
  const { value = '', onValueChange } = props;

  return (
    <Grid>
      <Grid.Column width="8">
        <Form>
          <TextArea value={value} onChange={(e) => onValueChange(e.target.value)} />
        </Form>
      </Grid.Column>
    </Grid>
  );
};
