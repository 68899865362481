import React from 'react';

import { Table, Label, Icon } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { generateHelpContent } from './StyleRulesTableRow.utils';
import Validate from '../../../../../../../libs/validate';
import { selectActiveRecipe, selectWebsitePatches } from '../../../../../../../selectors/website';
import HelpTooltip from '../../../../../../common/HelpTooltip';
import { getStyleRuleConfiguration, isColorRule } from '../../../../services/configuration';
import { formatStyleRuleName } from '../../../../services/format';
import { StyleRulePropType } from '../../../../types';
import EditStyleRulePopup from '../../../EditStyleRulePopup';

import './StyleRulesTableRow.scss';

const propTypes = {
  styleRule: StyleRulePropType.isRequired,
};

const defaultProps = {};

export default function StyleRulesTableRow({ styleRule }) {
  const patches = useSelector(selectWebsitePatches);
  const { styleRulesConfiguration } = selectActiveRecipe(patches);
  const { name, value, elementType } = styleRule;

  const styleRuleConfiguration = getStyleRuleConfiguration(styleRulesConfiguration, name) || {};

  const displayName = formatStyleRuleName(name);
  const isColor = isColorRule(name, styleRuleConfiguration) && Validate.isHexColor(value);

  return (
    <Table.Row className="style-rules-table-row">
      <Table.Cell className="name">
        <>
          {displayName}
          {!_isEmpty(styleRuleConfiguration) && (
            <HelpTooltip
              title={displayName}
              description={generateHelpContent(styleRuleConfiguration)}
              trigger={<Icon name="question circle" />}
              position="right center"
            />
          )}
        </>
      </Table.Cell>
      <Table.Cell className="value">
        <div>
          {isColor && <div className="color" style={{ backgroundColor: value }} />}
          {value}
        </div>
      </Table.Cell>
      <Table.Cell>
        <Label content={elementType} />
      </Table.Cell>
      <Table.Cell className="actions">
        <EditStyleRulePopup styleRule={styleRule} />
      </Table.Cell>
    </Table.Row>
  );
}

StyleRulesTableRow.propTypes = propTypes;
StyleRulesTableRow.defaultProps = defaultProps;
