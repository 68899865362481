import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { FORM_FIELD_LABEL_PLACEHOLDER } from './FormFieldLabel.constants';

import './FormFieldLabel.scss';

const propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  label: '',
  disabled: false,
};

export default function FormFieldLabel({ label, onChange, disabled }) {
  return (
    <div className="form-field-label">
      <label htmlFor="form-field-label-input">Label*</label>
      <Input
        id="form-field-label-input"
        fluid
        value={label}
        onChange={(e, { value }) => onChange(value)}
        placeholder={FORM_FIELD_LABEL_PLACEHOLDER}
        disabled={disabled}
      />
    </div>
  );
}

FormFieldLabel.propTypes = propTypes;
FormFieldLabel.defaultProps = defaultProps;
