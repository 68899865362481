import React, { useState, useContext, useEffect } from 'react';

import { Button } from 'semantic-ui-react';

import { PARSING_ERROR_MESSAGE } from './RecipeEditor.constants';
import Editor from './components/Editor';
import AceEditorWrapper from './components/Editor/components/AceEditorWrapper';
import { RecipeContext } from '../../context/RecipeContext';
import { removeNodeMetadata } from '../../services/recipe-utils';

import './recipe-editor.scss';

const RecipeEditor = () => {
  const { activeNode, editorMode, updateValueInObject } = useContext(RecipeContext);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [editorState, setEditorState] = useState({
    value: {},
    parseError: false,
  });

  function editorSaveHandler() {
    const { objectKey, parentId } = activeNode;
    const { value, parseError } = editorState;

    if (parseError) {
      return;
    }

    updateValueInObject(objectKey, value, parentId);
    setIsReadOnly(true);
  }

  function setInitialEditorStateValues() {
    if (activeNode) {
      setEditorState({
        value: removeNodeMetadata(activeNode.value),
        parseError: false,
      });
    }
  }

  function handleToogleLock() {
    if (!isReadOnly) {
      setInitialEditorStateValues();
    }
    setIsReadOnly(!isReadOnly);
  }

  useEffect(() => {
    setInitialEditorStateValues();
    setIsReadOnly(true);
  }, [activeNode]);

  const { value, parseError } = editorState;

  const renderParsingErrorMessage = () =>
    editorState.parseError && <span className="error-message">{PARSING_ERROR_MESSAGE}</span>;

  return (
    <Editor>
      <Editor.Header
        isLocked={isReadOnly}
        message={renderParsingErrorMessage()}
        onToogleLock={handleToogleLock}
        mode={editorMode}
      />
      <Editor.View>
        <AceEditorWrapper
          key={activeNode.objectKey}
          onEditorChange={(editorUpdate) =>
            setEditorState({ value: editorUpdate.value, parseError: editorUpdate.parseError })
          }
          activeNode={value}
          mode={editorMode}
          readOnly={isReadOnly}
        />
      </Editor.View>
      <Editor.Actions>
        <Button
          inverted
          color="grey"
          disabled={isReadOnly || parseError}
          onClick={editorSaveHandler}
          className="btn-save"
        >
          Save
        </Button>
      </Editor.Actions>
    </Editor>
  );
};

export default RecipeEditor;
