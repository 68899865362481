import React from 'react';

import { Form } from 'semantic-ui-react';

import _get from 'lodash/get';

import MenuTypeFormGroup from './MenuTypeFormGroup';
import FormInput from '../../components/FormInput';
import {
  DATA_CHANNEL_URL_KEY,
  DATA_INTEGRATION_KEY,
  DATA_RESTAURANT_KEY,
} from '../Zuppler.constants';

export default function SingleRestaurantForm({ serviceData, handleUpdate }) {
  const dataChannelURL = _get(serviceData, DATA_CHANNEL_URL_KEY);
  const dataIntegration = _get(serviceData, DATA_INTEGRATION_KEY);
  const dataRestaurantId = _get(serviceData, DATA_RESTAURANT_KEY);

  return (
    <Form>
      <MenuTypeFormGroup serviceData={serviceData} handleUpdate={handleUpdate} />
      <FormInput
        value={dataChannelURL}
        name={DATA_CHANNEL_URL_KEY}
        label="data-channel-url"
        handleUpdate={handleUpdate}
      />
      <FormInput
        value={dataIntegration}
        name={DATA_INTEGRATION_KEY}
        label="data-integration"
        handleUpdate={handleUpdate}
      />
      <FormInput
        value={dataRestaurantId}
        name={DATA_RESTAURANT_KEY}
        label="data-restaurant-id"
        handleUpdate={handleUpdate}
      />
    </Form>
  );
}
