export function calculateUnitPriceWithQuantity(unitPrice, quantity) {
  return unitPrice * quantity;
}

export function formatModifiers(modifiers) {
  if (!modifiers) {
    return '';
  }

  return modifiers.map((modifier) => modifier.name).join(', ');
}
