import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import './NewCustomPageButton.scss';

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function NewCustomPageButton({ onClick }) {
  return (
    <Button basic onClick={onClick} className="create-custom-page-button">
      Add Custom Page
    </Button>
  );
}

NewCustomPageButton.propTypes = propTypes;
NewCustomPageButton.defaultProps = defaultProps;
