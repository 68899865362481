import React, { useContext } from 'react';

import { Form } from 'semantic-ui-react';

import { DATETIME_DISPLAY_FORMAT, DATETIME_EXTERNAL_FORMAT } from './AnnouncementDates.constants';
import { DateTimeField } from '../../../../common';
import { AnnouncementFormContext } from '../../context/AnnouncementForm.context';

export default function AnnouncementDates() {
  const { startTime, endTime, setValue } = useContext(AnnouncementFormContext);
  return (
    <Form.Group widths="equal">
      <Form.Field>
        <div className="form-label">Start Date</div>
        <DateTimeField
          placeholder="Start"
          datetime={startTime}
          dateTimeFormat={DATETIME_DISPLAY_FORMAT}
          externalDateTimeFormat={DATETIME_EXTERNAL_FORMAT}
          onChange={(e, { value }) => setValue('startTime', value)}
          disableMinute
        />
      </Form.Field>
      <Form.Field>
        <div className="form-label">End Date</div>
        <DateTimeField
          placeholder="End"
          datetime={endTime}
          dateTimeFormat={DATETIME_DISPLAY_FORMAT}
          externalDateTimeFormat={DATETIME_EXTERNAL_FORMAT}
          onChange={(e, { value }) => setValue('endTime', value)}
          disableMinute
        />
      </Form.Field>
    </Form.Group>
  );
}
