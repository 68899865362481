import React, { useState, useContext } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import {
  composeButtonClasses,
  composeButtonText,
  getAppliedFilterCount,
} from './HtmlElementsFilterButton.utils';
import { selectWebsitePages } from '../../../../../../../../../selectors/website';
import { HtmlElementPageContext } from '../../../../../../context';
import HtmlElementsFilterModal from '../HtmlElementsFilterModal/HtmlElementsFilterModal';

import './HtmlElementsFilterButton.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsFilterButton() {
  const websitePages = useSelector(selectWebsitePages);
  const {
    filter: { placement, pages },
  } = useContext(HtmlElementPageContext);
  const [open, setOpen] = useState(false);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  const filterCount = getAppliedFilterCount(placement, pages, websitePages);
  const finalClasses = composeButtonClasses(filterCount);
  const buttonText = composeButtonText(filterCount);

  return (
    <HtmlElementsFilterModal
      open={open}
      onClose={closeModal}
      trigger={
        <Button className={finalClasses} onClick={openModal}>
          <Icon name="filter" />
          <span>{buttonText}</span>
          <Icon name="chevron down" />
        </Button>
      }
    />
  );
}

HtmlElementsFilterButton.propTypes = propTypes;
HtmlElementsFilterButton.defaultProps = defaultProps;
