import PropTypes from 'prop-types';

import {
  MODE_TYPES,
  FILL_TYPES,
  CROP_TYPES,
  MASK_TYPES,
  ASPECT_RATIO_TYPES,
} from './ImageQueryParamBuilder.constants';

export const ImageQueryParamBuilderFormDataPropType = PropTypes.shape({
  desktop: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mode: PropTypes.oneOf(MODE_TYPES),
    fillType: PropTypes.oneOf([...FILL_TYPES, '']),
    cropType: PropTypes.oneOf([...CROP_TYPES, '']),
    mask: PropTypes.oneOf(MASK_TYPES),
    aspectRatio: PropTypes.oneOf(ASPECT_RATIO_TYPES),
  }),
});
