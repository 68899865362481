import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import './BusinessPlanBillingToggle.scss';

export default function BusinessPlanBillingToggle({ isMonthly, onBillingPlanChange }) {
  return (
    <div className="business-plan-billing-toggle-container">
      <span className={!isMonthly ? 'billing-toggle-opacity' : undefined}>
        <b>Monthly</b>
      </span>
      <Checkbox toggle checked={!isMonthly} onChange={onBillingPlanChange} />
      <span className={isMonthly ? 'billing-toggle-opacity' : undefined}>
        <b>Yearly</b>
      </span>
    </div>
  );
}

BusinessPlanBillingToggle.propTypes = {
  isMonthly: PropTypes.bool,
  onBillingPlanChange: PropTypes.func.isRequired,
};

BusinessPlanBillingToggle.defaultProps = {
  isMonthly: true,
};
