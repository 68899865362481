import React from 'react';

import PropTypes from 'prop-types';
import { Segment, Header, Icon } from 'semantic-ui-react';

import InputFile from 'components/common/InputFile';

import './FileUploadWell.scss';

const propTypes = {
  onUploadFiles: PropTypes.func.isRequired,
  accept: PropTypes.string,
};

const defaultProps = {
  accept: 'image/*',
};

function FileUploadWell({ onUploadFiles, accept }) {
  return (
    <InputFile multiple onChange={onUploadFiles} accept={accept}>
      {({ onClick, className, dropRef, handleDrop, handleDragOver, handleDragLeave }) => (
        <Segment
          placeholder
          className={`file-upload-well ${className}`}
          onClick={onClick}
          ref={dropRef}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <Header icon>
            <Icon name="upload" />
            Drop a file here to upload or click to browse.
          </Header>
        </Segment>
      )}
    </InputFile>
  );
}

FileUploadWell.propTypes = propTypes;
FileUploadWell.defaultProps = defaultProps;

export default FileUploadWell;
