export function isDigitalMenuConfigured(menu) {
  const { schedules = [], categories = [], items = [] } = menu;

  return !!(schedules.length && categories.length && items.length);
}

export function isFileMenuConfigured(menuFiles) {
  return !!(menuFiles && menuFiles.length);
}

export function isMenuFile({ tags = [] }) {
  if (!tags) return false;
  return tags.includes('menu');
}
