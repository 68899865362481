import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { searchTagOptions } from './PartialTagsInputField.utils';
import { getOptions } from '../../../PartialPage/PartialPage.utils';

const PartialTagsInputField = ({ label, tags, onChange }) => {
  const options = getOptions(tags);

  return (
    <Form.Dropdown
      allowAdditions
      label={label}
      fluid
      multiple
      selection
      search={searchTagOptions}
      options={options}
      value={tags}
      placeholder="Enter a tag"
      onChange={(e, { value: selectedTags }) => onChange(selectedTags)}
    />
  );
};

PartialTagsInputField.propTypes = {
  label: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

PartialTagsInputField.defaultProps = {
  label: undefined,
  tags: [],
};

export default PartialTagsInputField;
