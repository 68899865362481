import React from 'react';

import PropTypes from 'prop-types';
import { Modal, Button, Form, Divider, Confirm } from 'semantic-ui-react';

import ImageCard from 'components/fields/ImageCard';

import InputField from './InputField';
import { getFileUrl } from './utils';
import CloseableModal from '../../common/CloseableModal';
import HelpTooltip from '../../common/HelpTooltip';
import { ImageAltTextField } from '../../modules/seo/components';

import './FileModal.scss';

const propTypes = {
  headerTitle: PropTypes.string,
  open: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSaving: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
};

const defaultProps = {
  headerTitle: 'Edit File',
  open: false,
  isDeleting: false,
  isSaving: false,
};

export default class FileModal extends React.Component {
  constructor(props) {
    super(props);
    const { selectedFile } = this.props;

    this.fileInput = React.createRef();
    this.state = {
      fileData: selectedFile,
      confirmOpen: false,
      errors: {
        filename: false,
        description: false,
      },
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleChange(e, target) {
    const { fileData } = this.state;
    const { name, value } = target;

    await this.setState({
      fileData: {
        ...fileData,
        [name]: value,
      },
    });

    this.validateForm();
  }

  async handleSave() {
    const { onSave } = this.props;
    const { fileData = {} } = this.state;

    if (this.hasFormError()) return;

    await onSave(fileData);
  }

  handleOnDelete() {
    this.setState({ confirmOpen: true });
  }

  handleCancel() {
    this.setState({ confirmOpen: false });
  }

  handleConfirmDelete() {
    const { onDelete } = this.props;
    const { fileData = {} } = this.state;
    onDelete(fileData);
  }

  getButtons() {
    const { isSaving, isDeleting } = this.props;
    const disableButtons = isDeleting || isSaving;

    return (
      <>
        <Button
          className="business-file-btn"
          onClick={this.handleOnDelete}
          loading={isDeleting}
          disabled={!this.canDelete() || disableButtons}
          content="Delete"
        />
        <Button
          className="business-file-btn"
          onClick={this.handleSave}
          loading={isSaving}
          disabled={!this.canSave() || disableButtons}
          content="Save"
        />
      </>
    );
  }

  validateForm() {
    const { fileData } = this.state;
    const { filename } = fileData;
    const errors = {
      filename: false,
      description: false,
    };

    if (!filename) errors.filename = true;

    this.setState({ errors });
  }

  canSave() {
    const { fileData = {} } = this.state;
    const { id } = fileData;
    return !!id && !this.hasFormError();
  }

  canDelete() {
    const { fileData = {} } = this.state;
    const { id } = fileData;
    return !!id;
  }

  hasFormError() {
    return this.hasFieldError('description') || this.hasFieldError('filename');
  }

  hasFieldError(fieldName) {
    const { errors } = this.state;
    return errors[fieldName];
  }

  render() {
    const { open, headerTitle, onCloseModal } = this.props;
    const { fileData = {}, confirmOpen } = this.state;
    const { file = '', filename = '', description = '' } = fileData;
    const fileUrl = getFileUrl(file);

    return (
      <CloseableModal
        open={open}
        onClose={onCloseModal}
        className="business-file-modal"
        size="small"
        header={headerTitle}
      >
        <div className="business-file-modal-wrapper">
          <Modal.Content className="business-file-modal-content">
            <Form error={this.hasFormError()} className="business-file-modal-content-form">
              <Form.Group className="business-file-modal-content-form-group">
                <ImageCard
                  fileInput={this.fileInput}
                  url={fileUrl}
                  style={{
                    height: 'auto',
                    objectFit: 'fill',
                  }}
                />
              </Form.Group>

              <Divider />
              <div style={{ padding: '0 20px' }}>
                <Form.Field
                  error={this.hasFieldError('description')}
                  className="alt-text-file-field"
                >
                  <ImageAltTextField
                    altText={description}
                    imageUrl={fileUrl}
                    onChangeAltText={this.handleChange}
                    title={<label htmlFor="file-description-input">Alternative Text</label>}
                    name="description"
                  />
                </Form.Field>
                <Form.Group inline>
                  <Form.Field width={5}>
                    <label htmlFor="file-name-input">File Name</label>
                    <HelpTooltip title="File name" description="The name of this file." />
                  </Form.Field>
                  <InputField
                    id="file-name-input"
                    width={11}
                    name="filename"
                    value={filename}
                    error={this.hasFieldError('filename')}
                    placeholder="Add Filename"
                    onChange={this.handleChange}
                    onValidate={() => true}
                    validationType="noSpecialCharacters"
                  />
                </Form.Group>
              </div>
            </Form>
            <Confirm
              open={confirmOpen}
              header="Delete this File?"
              dimmer="inverted"
              confirmButton={<Button negative>Delete</Button>}
              onCancel={this.handleCancel}
              onConfirm={this.handleConfirmDelete}
            />
          </Modal.Content>
        </div>
        <Modal.Actions>{this.getButtons()}</Modal.Actions>
      </CloseableModal>
    );
  }
}

FileModal.propTypes = propTypes;
FileModal.defaultProps = defaultProps;
