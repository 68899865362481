import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { ORDER_RECEIPT_MESSAGE } from '../../OrderSettings.constants';

import './OrderReceiptField.scss';

const propTypes = {
  handleOnChangeForm: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
};

const defaultProps = {
  initialValue: '',
};

export default function OrderReceiptField({ handleOnChangeForm, initialValue }) {
  const [receiptValue, setReceiptValue] = useState(initialValue);

  function handleOrderReceiptField(e, { value, name }) {
    handleOnChangeForm(e, { value, name });
    setReceiptValue(value);
  }

  useEffect(() => {
    setReceiptValue(initialValue);
  }, [initialValue]);

  return (
    <Form.Field id="order-receipt" className="order-receipt form-field">
      <label htmlFor="order-receipt">Order Receipt Message</label>
      <Form.TextArea
        value={receiptValue}
        name={ORDER_RECEIPT_MESSAGE}
        onChange={handleOrderReceiptField}
        placeholder="Thank you for ordering. You will be emailed a receipt shortly."
      />
    </Form.Field>
  );
}

OrderReceiptField.propTypes = propTypes;
OrderReceiptField.defaultProps = defaultProps;
