import { DOM_LOCATION_BODY, DOM_LOCATION_HEADER } from '../../../../../../../../constants';

const DISPLAY_DOM_LOCATION_HEADER = 'Head';
const DISPLAY_DOM_LOCATION_BODY = 'Body';

export function getDomLocationOptions() {
  return [
    { key: DOM_LOCATION_HEADER, text: DISPLAY_DOM_LOCATION_HEADER, value: DOM_LOCATION_HEADER },
    { key: DOM_LOCATION_BODY, text: DISPLAY_DOM_LOCATION_BODY, value: DOM_LOCATION_BODY },
  ];
}

export function getDomLocationTextFromValue(value) {
  const textValueMap = {
    [DOM_LOCATION_HEADER]: DISPLAY_DOM_LOCATION_HEADER,
    [DOM_LOCATION_BODY]: DISPLAY_DOM_LOCATION_BODY,
  };
  return textValueMap[value];
}
