import React from 'react';

import { Form, Radio } from 'semantic-ui-react';

import _get from 'lodash/get';

import { MULTIPLE_RESTAURANT_KEY } from '../Zuppler.constants';

export default function MenuTypeFormGroup({ serviceData, handleUpdate }) {
  const isMultipleRestaurant = _get(serviceData, MULTIPLE_RESTAURANT_KEY, false);

  return (
    <Form.Group grouped>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>Menu Type</label>
      <Form.Field>
        <Radio
          name="restaurantType"
          label="Single Restaurant"
          checked={!isMultipleRestaurant}
          onChange={(e) => handleUpdate(e, { value: false, name: MULTIPLE_RESTAURANT_KEY })}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          name="restaurantType"
          label="Multiple Restaurants"
          checked={isMultipleRestaurant}
          onChange={(e) => handleUpdate(e, { value: true, name: MULTIPLE_RESTAURANT_KEY })}
        />
      </Form.Field>
    </Form.Group>
  );
}
