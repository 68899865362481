import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import CreateTeamModal from '../CreateTeamModal';

import './AddTeamButton.scss';

const propTypes = {
  onPostSave: PropTypes.func,
};

const defaultProps = {
  onPostSave: null,
};

export default function AddTeamButton({ onPostSave }) {
  const [showModal, setShowModal] = useState(false);

  function displayModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <>
      <CreateTeamModal open={showModal} onClose={closeModal} onPostSave={onPostSave} />
      <Button
        content="Add team"
        className="add-team-button secondary-navy"
        onClick={displayModal}
      />
    </>
  );
}

AddTeamButton.propTypes = propTypes;
AddTeamButton.defaultProps = defaultProps;
