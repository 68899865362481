import {
  IS_SPECIAL_FIELD,
  IS_FEATURED_FIELD,
  CTAS_RECIPES,
  CLICK_THROUGH_URL_RECIPES,
  MULTIPLE_IMAGES_RECIPES,
  DETAILED_DESCRIPTION_RECIPES,
  ADVANCED_SEO_RECIPES,
} from './menu-constants';
import { SUPPORTED_FEATURED_CATALOG_OBJECT_RECIPES } from '../../../../constants/recipes';
import API from '../../../../libs/api';
import { businessIsRestaurant } from '../../../../libs/business';
import { hasAnyRecipe } from '../../../modules/foundation/hooks/use-recipe/use-recipe.utils';

export async function loadMenu({ businessType, businessId, successCb }) {
  const result = await API.getMenu(businessType, businessId);
  const { data } = result;
  successCb(data);
}

export function areCtasEnabledForRecipe(patch) {
  return hasAnyRecipe(patch, CTAS_RECIPES);
}

export function isClickTroughUrlEnabledForBusiness(business, patch) {
  if (businessIsRestaurant(business)) {
    return false;
  }

  return hasAnyRecipe(patch, CLICK_THROUGH_URL_RECIPES);
}

export function isWebsiteComponentInteractionEnabled(patch) {
  return hasAnyRecipe(patch, CLICK_THROUGH_URL_RECIPES);
}

export function isFeaturedFeatureEnabled(patch) {
  return hasAnyRecipe(patch, SUPPORTED_FEATURED_CATALOG_OBJECT_RECIPES);
}

export function isMultipleImagesSupported(patch) {
  return hasAnyRecipe(patch, MULTIPLE_IMAGES_RECIPES);
}

export function isDetailedDescriptionEnabled(patch) {
  return hasAnyRecipe(patch, DETAILED_DESCRIPTION_RECIPES);
}

export function isAdvancedSeoSupported(patch) {
  return hasAnyRecipe(patch, ADVANCED_SEO_RECIPES);
}

export function getBusinessMenuSettings(
  business,
  settingOptions,
  { allowFeaturedSetting = false },
) {
  let finalOptions = settingOptions;
  if (!businessIsRestaurant(business)) {
    finalOptions = finalOptions.filter((option) => option.name !== IS_SPECIAL_FIELD);
  }
  if (!allowFeaturedSetting) {
    finalOptions = finalOptions.filter((option) => option.name !== IS_FEATURED_FIELD);
  }
  return finalOptions;
}
