import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { STATUS_OPTIONS } from './ItemStatusDropdown.constants';
import { getStatusClassName } from './ItemStatusDropdown.utils';

import './ItemStatusDropdown.scss';

const propTypes = {
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
  disabled: false,
};

export default function ItemStatusDropdown({ status, onChange, loading, disabled }) {
  if (_isEmpty(status)) {
    return null;
  }

  const finalClassName = getStatusClassName(status);

  return (
    <Dropdown
      className={finalClassName}
      defaultValue={status}
      inline
      options={STATUS_OPTIONS}
      onChange={onChange}
      loading={loading}
      disabled={disabled}
    />
  );
}

ItemStatusDropdown.propTypes = propTypes;
ItemStatusDropdown.defaultProps = defaultProps;
