import PropTypes from 'prop-types';

export const BusinessFilePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  file: PropTypes.string.isRequired,
  s3File: PropTypes.string,
  filename: PropTypes.string,
  description: PropTypes.string,
  source: PropTypes.string,
});

export const LocalFilePropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  file: PropTypes.shape({}),
});

export const BusinessFileConfigurationPropType = PropTypes.shape({
  id: PropTypes.number,
  order: PropTypes.number,
  metadata: PropTypes.shape({}),
});
