import React from 'react';

import { Form, Input, Label } from 'semantic-ui-react';

import debounce from 'lodash/debounce';
import _get from 'lodash/get';
import { HuePicker } from 'react-color';
import { connect } from 'react-redux';

import { getRecipeSubTitleAvailability } from './MessageDisplayForm.utils';
import { updateWebsiteLayoutComponent as updateWebsiteLayoutComponentConnect } from '../../../actions/website';
import { isAdminUser } from '../../../libs/auth';
import { DateTimeField } from '../../common';
import MarkdownField from '../../fields/MarkdownField';
import { DEFAULT_OPTIONS } from '../../fields/MarkdownField/MarkdownField.constants';
import { MarkdownImagePlugin } from '../../fields/MarkdownField/components';

import '../../../styles/core/components/message-display-form.scss';

const datetimeDisplayFormat = 'MM/DD/YYYY hh:mm A';
const datetimeExternalFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

class MessageDisplayForm extends React.Component {
  constructor(props) {
    super(props);

    const componentData = _get(props, 'component.data') || {};
    const {
      subject = '',
      subTitle = '',
      content = '',
      modalDelay = 0,
      bannerBackgroundColor = '',
      startTime,
      endTime,
    } = componentData;
    this.state = {
      subject,
      subTitle,
      editorState: content,
      bannerBackgroundColor: bannerBackgroundColor || this.getPrimaryColor(),
      content,
      modalDelay,
      startTime,
      endTime,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelayChange = this.handleDelayChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.updateReduxState = this.updateReduxState.bind(this);
    this.updateReduxState = debounce(this.updateReduxState, 500);
  }

  handleDelayChange(e, target) {
    const { value: delayInSeconds } = target;
    this.setState({
      modalDelay: 1000 * parseInt(delayInSeconds, 10),
    });

    this.updateReduxState();
  }

  handleColorChange(e) {
    const { hex: bannerBackgroundColor } = e;
    this.setState({ bannerBackgroundColor });

    this.updateReduxState();
  }

  handleContentChange = (editorState) => {
    this.setState({ editorState }, () => {
      this.setState({
        content: editorState,
        editorState,
      });

      this.updateReduxState();
    });
  };

  handleUpdate(e, target) {
    const { value, name } = target;

    this.setState({
      [name]: value,
    });

    this.updateReduxState();
  }

  handleDateChange(name, value) {
    this.setState({
      [name]: value,
    });

    this.updateReduxState();
  }

  getDelayInSeconds() {
    const { modalDelay } = this.state;

    if (Number.isNaN(modalDelay)) {
      return 0;
    }

    return parseInt(modalDelay, 10) / 1000;
  }

  getPrimaryColor() {
    const { styleRules } = this.props;

    if (styleRules && styleRules.length) {
      const primaryColorStyle = styleRules.find(
        (rule) => rule.name === 'primaryColor' && rule.element_type === 'global',
      );
      if (primaryColorStyle) {
        return primaryColorStyle.value;
      }
    }

    return '';
  }

  updateReduxState() {
    const { index, layoutIndex, updateWebsiteLayoutComponent } = this.props;
    const {
      subject = '',
      subTitle = '',
      content = '',
      bannerBackgroundColor = '',
      startTime = '',
      endTime = '',
      modalDelay,
    } = this.state;

    updateWebsiteLayoutComponent({
      data: {
        subject,
        subTitle,
        content,
        bannerBackgroundColor,
        modalDelay,
        startTime,
        endTime,
      },
      index,
      layoutIndex,
    });
  }

  render() {
    const {
      subject = '',
      subTitle = '',
      editorState,
      bannerBackgroundColor = '',
      startTime,
      endTime,
    } = this.state;
    const { websitePatches, user } = this.props;
    const modalDelay = this.getDelayInSeconds();
    const isSubTitleAvailable = getRecipeSubTitleAvailability(websitePatches);
    const isAdmin = isAdminUser(user);

    return (
      <Form className="message-display-form">
        <Form.Input
          fluid
          label="Subject"
          name="subject"
          value={subject}
          onChange={this.handleUpdate}
          placeholder="An important announcement..."
        />
        {isSubTitleAvailable && (
          <Form.Input
            fluid
            label="Subtitle"
            name="subTitle"
            value={subTitle}
            onChange={this.handleUpdate}
            placeholder="And some additional info..."
          />
        )}
        <Form.Field>
          <label htmlFor="message-display-content-input">Content</label>
          <MarkdownField
            id="message-display-content-input"
            onChange={this.handleContentChange}
            value={editorState}
            options={DEFAULT_OPTIONS}
            toolbarPlugins={isAdmin ? [MarkdownImagePlugin] : []}
          />
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <DateTimeField
              title="Start Date"
              placeholder="Start"
              datetime={startTime}
              dateTimeFormat={datetimeDisplayFormat}
              externalDateTimeFormat={datetimeExternalFormat}
              onChange={(e, { value }) => this.handleDateChange('startTime', value)}
              disableMinute
            />
          </Form.Field>
          <Form.Field>
            <DateTimeField
              title="End Date"
              placeholder="End"
              datetime={endTime}
              dateTimeFormat={datetimeDisplayFormat}
              externalDateTimeFormat={datetimeExternalFormat}
              onChange={(e, { value }) => this.handleDateChange('endTime', value)}
              disableMinute
            />
          </Form.Field>
        </Form.Group>

        <>
          <Form.Group>
            <Form.Input label="Banner Color" width={4}>
              <Input
                label={<Label style={{ backgroundColor: bannerBackgroundColor }} />}
                labelPosition="left"
                value={bannerBackgroundColor}
                name="bannerBackgroundColor"
                onChange={this.handleUpdate}
              />
            </Form.Input>
            <Form.Field width={12}>
              <label htmlFor="color-picker-span">Color Picker</label>
              <span id="color-picker-span" className="color-span">
                <HuePicker
                  width="100%"
                  color={bannerBackgroundColor}
                  onChange={this.handleColorChange}
                />
              </span>
            </Form.Field>
          </Form.Group>
          <Form.Input label="Message Delay">
            <Label basic pointing="right" style={{ textAlign: 'center' }} width={4}>
              {modalDelay === 0 ? <>Delay Disabled</> : <>{modalDelay} Seconds</>}
            </Label>
            <Input
              min={0}
              max={30}
              width={12}
              value={modalDelay}
              onChange={this.handleDelayChange}
              type="range"
            />
          </Form.Input>
        </>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateWebsiteLayoutComponent: (payload) => dispatch(updateWebsiteLayoutComponentConnect(payload)),
});

const mapStateToProps = ({ website, user }) => ({
  user: _get(user, 'core.value'),
  styleRules: _get(website, 'styleRules.value'),
  websitePatches: _get(website, 'patches.value'),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageDisplayForm);
