import React from 'react';

import {
  Table,
  Button,
  Icon,
  Label,
  Modal,
  Form,
  Select,
  Message,
  Confirm,
  Dropdown,
} from 'semantic-ui-react';

import _get from 'lodash/get';
import moment from 'moment';

import { BUSINESS_OWNER_GROUP_NAME } from '../../constants/auth';
import { groupNameToGroupDisplay, getUserGroups } from '../../constants/user';
import { isAdminUser } from '../../libs/auth';
import Validate from '../../libs/validate';
import CloseableModal from '../common/CloseableModal';

import '../../styles/core/pages/user-management.scss';

const externalDateTimeFormat = 'MM/DD/YYYY';
const internalDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';

const actionOptions = [
  { key: null, value: null, text: null },
  { key: 'removeUser', value: 'removeUser', text: 'Remove User' },
];

const roleLabelMap = {
  ...groupNameToGroupDisplay,
  admin: 'Fisherman Admin',
};

class WebsiteUsersForm extends React.Component {
  static getGroupLabels(user) {
    if (isAdminUser(user)) {
      return [roleLabelMap.admin];
    }
    return getUserGroups(user).map((role) => roleLabelMap[role]);
  }

  static getFormatedDateTime(dateTime) {
    if (!dateTime) return '';
    return moment(dateTime, internalDateTimeFormat).format(externalDateTimeFormat);
  }

  constructor(props) {
    super(props);

    this.state = {
      addUserModal: false,
      configureUserModal: false,
      modalLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      role: BUSINESS_OWNER_GROUP_NAME,
      fieldValidations: {
        firstName: false,
        lastName: false,
        email: false,
        role: false,
      },
    };

    this.toggleModalOpen = this.toggleModalOpen.bind(this);
    this.handleAddUser = this.handleAddUser.bind(this);
    this.isValid = this.isValid.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleConfigureClick = this.handleConfigureClick.bind(this);
    this.handleRemoveCancel = this.handleRemoveCancel.bind(this);
    this.handleRemoveConfirm = this.handleRemoveConfirm.bind(this);
  }

  handleChange(e) {
    const { value, name } = e.target;

    this.setState(
      {
        [name]: value,
      },
      this.validateForm,
    );
  }

  handleSelectChange(e, target) {
    const { value, name } = target;

    this.setState(
      {
        [name]: value,
      },
      this.validateForm,
    );
  }

  handleError(e) {
    const errorMessage = _get(e, 'response.data.message', e.message);

    return this.setState({
      errorMessage,
    });
  }

  async handleAddUser() {
    const { onAddUser } = this.props;
    const { firstName, lastName, email, role } = this.state;

    this.setState({
      submitting: true,
    });

    if (this.isValid()) {
      try {
        await onAddUser(firstName, lastName, email, role);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.setState({
          submitting: false,
        });
      }

      this.setState({
        addUserModal: false,
        modalLoading: false,
        errorMessage: null,
        firstName: '',
        lastName: '',
        email: '',
        role: BUSINESS_OWNER_GROUP_NAME,
      });
    }
  }

  handleConfigureClick(e, target) {
    const { websiteUsers } = this.props;
    const { value, websiteUser: id } = target;

    const websiteUser = websiteUsers.find((wUser) => {
      return wUser.id === id;
    });

    if (websiteUser) {
      if (value === 'removeUser') {
        this.setState({
          selectedUser: websiteUser,
        });

        this.toggleModalOpen('removeUserModal');
      }
    }
  }

  handleRemoveCancel() {
    this.setState({
      removeUserModal: false,
      selectedUser: null,
    });
  }

  async handleRemoveConfirm() {
    const { onRemoveUser } = this.props;
    const { selectedUser } = this.state;

    try {
      await onRemoveUser(selectedUser);
    } catch (e) {
      this.handleError(e);
    } finally {
      this.setState({
        removeUserModal: false,
      });
    }
  }

  toggleModalOpen(variable = null) {
    if (variable) {
      this.setState((previousState) => {
        return {
          [variable]: !previousState[variable],
        };
      });
    }
  }

  validateForm() {
    const { firstName, lastName, email, role } = this.state;
    const fieldValidations = {
      firstName: Validate.validate('defined', firstName),
      lastName: Validate.validate('defined', lastName),
      email: Validate.validate('email', email),
      role: Validate.validate('defined', role),
    };

    this.setState({ fieldValidations });
  }

  isValid() {
    const { fieldValidations } = this.state;
    return Object.keys(fieldValidations).reduce((acc = true, key) => {
      return acc && fieldValidations[key];
    });
  }

  render() {
    const { user, websiteUsers = [], roleOptions } = this.props;
    const {
      addUserModal,
      removeUserModal,
      firstName,
      lastName,
      email,
      role,
      errorMessage,
      submitting,
    } = this.state;

    return (
      <Table compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Registration Date</Table.HeaderCell>
            <Table.HeaderCell>E-mail address</Table.HeaderCell>
            <Table.HeaderCell>User Roles</Table.HeaderCell>
            {isAdminUser(user) && <Table.HeaderCell>Action</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {websiteUsers.map((websiteUser) => {
            if (!websiteUser || !websiteUser.user) return null;

            return (
              <Table.Row>
                <Table.Cell>
                  {websiteUser.user.first_name} {websiteUser.user.last_name}
                </Table.Cell>
                <Table.Cell>
                  {WebsiteUsersForm.getFormatedDateTime(websiteUser.user.date_joined)}
                </Table.Cell>
                <Table.Cell>{websiteUser.user.email}</Table.Cell>
                <Table.Cell>
                  {WebsiteUsersForm.getGroupLabels(websiteUser.user).map((group) => (
                    <Label>{group}</Label>
                  ))}
                </Table.Cell>
                {isAdminUser(user) && (
                  <Table.Cell textAlign="center">
                    <Dropdown
                      className="icon"
                      text="Configure"
                      icon="configure"
                      options={actionOptions}
                      websiteUser={websiteUser.id}
                      onChange={this.handleConfigureClick}
                      value={null}
                      button
                      floating
                      labeled
                      primary
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="5">
              <Button
                floated="right"
                icon
                labelPosition="left"
                primary
                size="small"
                onClick={() => this.toggleModalOpen('addUserModal')}
                loading={addUserModal}
              >
                <Icon name="user" /> Add User
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>

        <Confirm
          className="user-management-confirm"
          open={removeUserModal}
          dimmer="inverted"
          size="mini"
          header="Remove User"
          onCancel={this.handleRemoveCancel}
          onConfirm={this.handleRemoveConfirm}
        />

        <CloseableModal
          className="user-management-modal"
          open={addUserModal}
          loading={submitting}
          header="Add User"
          onClose={() => this.toggleModalOpen('addUserModal')}
        >
          <Modal.Content>
            <Form error>
              <Form.Group widths="equal">
                <Form.Field fluid>
                  <label htmlFor="first-name-input">First Name</label>
                  <input
                    id="first-name-input"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field fluid>
                  <label htmlFor="last-name-input">Last Name</label>
                  <input
                    id="last-name"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={this.handleChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field fluid>
                  <label htmlFor="email-input">Email</label>
                  <input
                    id="email-input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field fluid>
                  <label htmlFor="role-input">Role</label>
                  <Select
                    id="role-input"
                    name="role"
                    value={role}
                    options={roleOptions}
                    onChange={this.handleSelectChange}
                  />
                </Form.Field>
              </Form.Group>
              {errorMessage && <Message error header="Error" content={errorMessage} />}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={!this.isValid() || submitting}
              loading={submitting}
              onClick={this.handleAddUser}
              content="Add User"
              className="action"
            />
          </Modal.Actions>
        </CloseableModal>
      </Table>
    );
  }
}

export default WebsiteUsersForm;
