import React from 'react';

import PropTypes from 'prop-types';
import { Menu, Tab } from 'semantic-ui-react';

import {
  ELEMENT_TYPE_DISPLAY_NAME,
  ELEMENT_TYPE_EDIT_COMPONENT,
  ELEMENT_COMPONENT_NOT_FOUND_MESSAGE,
  ELEMENT_TYPE_DISPLAY_ORDER,
} from './EditElementTabs.constants';
import { getActiveTabIndex } from './EditElementTabs.utils';
import { ElementBlockStatePropType } from '../../../../types/element.types';

import './EditElementTabs.scss';

const propTypes = {
  elementStates: ElementBlockStatePropType.isRequired,
  activeElementType: PropTypes.string.isRequired,
  onUpdateActiveElementType: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function EditElementTabs({
  elementStates,
  activeElementType,
  onUpdateActiveElementType,
}) {
  function getElementComponent(type) {
    const ElementComponent = ELEMENT_TYPE_EDIT_COMPONENT[type];

    if (!ElementComponent) {
      return <div>{ELEMENT_COMPONENT_NOT_FOUND_MESSAGE}</div>;
    }

    const props = { element: elementStates[type] };

    return <ElementComponent {...props} />;
  }

  const elementTabs = ELEMENT_TYPE_DISPLAY_ORDER.map((elementType) => ({
    menuItem: (
      <Menu.Item onClick={() => onUpdateActiveElementType(elementType)} key={elementType}>
        {ELEMENT_TYPE_DISPLAY_NAME[elementType]}
      </Menu.Item>
    ),
    render: () => <Tab.Pane key={elementType}>{getElementComponent(elementType)}</Tab.Pane>,
  }));

  return (
    <Tab
      className="edit-element-tabs"
      panes={elementTabs}
      activeIndex={getActiveTabIndex(ELEMENT_TYPE_DISPLAY_ORDER, activeElementType)}
    />
  );
}

EditElementTabs.propTypes = propTypes;
EditElementTabs.defaultProps = defaultProps;
