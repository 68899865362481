const readonlyFields = [
  {
    key: 'version',
    path: '',
  },
  {
    key: 'name',
    path: '',
  },
];

export default readonlyFields;
