import * as Sentry from '@sentry/react';
import LogRocketLibrary from 'logrocket';

import { LOGROCKET_DASHBOARD_ID } from './logrocket.constants';
import { removeFromBody } from './logrocket.utils';

const environment = process.env.REACT_APP_FISHERMAN_ENV;
let sessionUrl;
export default class LogRocket {
  static isTest() {
    return environment !== 'production';
  }

  static isEnabled() {
    return ['staging', 'production', 'sandbox'].includes(environment);
  }

  static init(id = LOGROCKET_DASHBOARD_ID) {
    if (this.isEnabled()) {
      LogRocketLibrary.init(id, {
        network: {
          requestSanitizer: (request) => {
            const cleanedRequest = removeFromBody(request, 'password');
            return cleanedRequest;
          },
        },
      });

      LogRocketLibrary.getSessionURL((sessionURL) => {
        sessionUrl = sessionURL;
        Sentry.withScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  }

  static identify(identifier, params = {}) {
    const finalIdentifier = this.isTest() ? `${identifier} [TEST]` : identifier;
    LogRocketLibrary.identify(finalIdentifier, params);
  }

  static captureException(error, extra = {}) {
    LogRocketLibrary.captureException(error, extra);
  }

  static sessionUrl() {
    return LogRocketLibrary.sessionURL || sessionUrl;
  }
}
