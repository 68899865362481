import _get from 'lodash/get';

import { FILE_SIZE_MB_DIVISOR, MAX_FILE_SIZE, SCALING_BUFFER } from '../constants/size';

export function getLocalFileSize(file) {
  return _get(file, 'size', 0);
}

export function getFormattedLocalFileSize(file) {
  const size = getLocalFileSize(file);
  if (!size) return '';

  return `${(size / FILE_SIZE_MB_DIVISOR).toFixed(2)} MB`;
}

export function exceedsMaxFileSize(file) {
  return getLocalFileSize(file) > MAX_FILE_SIZE;
}

export function getScaleFactor(imageSize, maxAllowedSize = MAX_FILE_SIZE) {
  return Math.sqrt(maxAllowedSize / imageSize - SCALING_BUFFER);
}
