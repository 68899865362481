import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { MAX_WIDTH_VALUE, MIN_WIDTH_VALUE } from './WidthInput.constants';

import './WidthInput.scss';

const propTypes = {
  width: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxAllowedWidth: PropTypes.number,
};

const defaultProps = {
  width: 0,
  disabled: false,
  maxAllowedWidth: MAX_WIDTH_VALUE,
};

export default function WidthInput({ width, onChange, disabled, maxAllowedWidth }) {
  function onChangeInternal(e, { value }) {
    const valueAsNumber = Number(value);

    if (valueAsNumber > maxAllowedWidth || valueAsNumber < MIN_WIDTH_VALUE) {
      return onChange(width);
    }

    return onChange(valueAsNumber);
  }

  return (
    <Input
      value={width}
      onChange={onChangeInternal}
      disabled={disabled}
      className="element-cell-width-input"
      label="/16 cols"
      labelPosition="right"
      type="number"
      min={MIN_WIDTH_VALUE}
      max={maxAllowedWidth}
      fluid
    />
  );
}

WidthInput.propTypes = propTypes;
WidthInput.defaultProps = defaultProps;
