import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import _times from 'lodash/times';

import { DEFAULT_COLUMN_PADDING, DEFAULT_ROW_PADDING } from '../../constants/page';
import {
  AUTO_ADJUSTED_COLUMN_SIZE_MODE,
  ELEMENT_TYPE,
  FIXED_COLUMN_SIZE_MODE,
  MAX_COLUMN_WIDTHS_COUNT,
  TYPE_CHILDREN_KEYS,
} from '../../constants/types';

export function calculateDefaultColumnWidth(columnSizingMode, columns = []) {
  if (columnSizingMode !== FIXED_COLUMN_SIZE_MODE || _isEmpty(columns)) {
    return columnSizingMode === FIXED_COLUMN_SIZE_MODE ? MAX_COLUMN_WIDTHS_COUNT : null;
  }

  const totalWidths = columns.reduce((total, { width }) => total + (width || 0), 0);
  return totalWidths === 0 ? Math.floor(16 / columns.length) : 16 - totalWidths;
}

export function removeColumnWidths(columns = []) {
  return _isEmpty(columns) ? [] : columns.map(({ width, ...restColumn }) => restColumn);
}

export function ensureDefaultPadding(state, defaultPadding) {
  const { style = {} } = state || {};
  const { padding } = style;

  return { ...state, style: { ...style, padding: padding || defaultPadding } };
}

export function createColumnState(stateCreator, row, state = {}) {
  const baseColumn = stateCreator(ELEMENT_TYPE.Column, {});
  const { segments = [], width } = state;
  const { columnSizingMode, columns } = row;
  const defaultWidth = calculateDefaultColumnWidth(columnSizingMode, columns);

  let nextState =
    columnSizingMode === FIXED_COLUMN_SIZE_MODE
      ? { ...state, width: width || defaultWidth }
      : state;

  nextState = ensureDefaultPadding(nextState, DEFAULT_COLUMN_PADDING);

  return {
    ...baseColumn,
    ...nextState,
    segments: _isEmpty(segments) ? [stateCreator(ELEMENT_TYPE.Segment, {})] : segments,
  };
}

export function createRowState(stateCreator, state = {}) {
  const baseRow = stateCreator(ELEMENT_TYPE.Row, {});
  const { columnSizingMode, columns = [] } = state;

  let nextColumns = columns.map((column) => createColumnState(stateCreator, state, column));
  if (columnSizingMode === AUTO_ADJUSTED_COLUMN_SIZE_MODE) {
    nextColumns = removeColumnWidths(nextColumns);
  }

  const nextState = ensureDefaultPadding(state, DEFAULT_ROW_PADDING);

  return {
    ...baseRow,
    ...nextState,
    columns: _isEmpty(nextColumns) ? [createColumnState(stateCreator, {})] : nextColumns,
  };
}

export function createPageFromSettings(options, stateCreator) {
  const { rows = {}, columns = {}, pageConfig = {}, style, className } = options;
  const { count: rowCount = 1, style: rowStyle } = rows;
  const { count: columnCount = 1, style: columnStyle } = columns;
  const { title } = pageConfig;

  const rowStates = _times(rowCount, () => {
    const rowConfig = { columnSizingMode: AUTO_ADJUSTED_COLUMN_SIZE_MODE };
    const columnStates = _times(columnCount, () =>
      createColumnState(stateCreator, rowConfig, { style: columnStyle }),
    );

    const rowState = {
      columns: columnStates,
      columnSizingMode: AUTO_ADJUSTED_COLUMN_SIZE_MODE,
      style: rowStyle,
    };

    return createRowState(stateCreator, rowState);
  });

  return {
    ...stateCreator(ELEMENT_TYPE.Page, {}),
    name: title,
    components: [
      {
        ...stateCreator(ELEMENT_TYPE.Grid, { props: [{ name: 'className', resolve: className }] }),
        rows: rowStates,
      },
    ],
    style,
  };
}

export function elementHasRequiredValues(element, requiredFieldsMap = {}) {
  const { type } = element || {};

  if (!type) {
    return false;
  }

  const requiredFields = requiredFieldsMap[type] || [];

  if (_isEmpty(requiredFields)) {
    return true;
  }

  return requiredFields.every((field) => {
    const value = element[field];

    return _isObject(value) ? !_isEmpty(value) : !_isNil(value);
  });
}

export function getNodeStatesList(node) {
  if (_isEmpty(node)) {
    return [];
  }

  const { type } = node || {};
  const childrenKeys = TYPE_CHILDREN_KEYS[type] || [];

  return childrenKeys.reduce(
    (currentNodeStates, key) => {
      const children = node[key] || [];

      const allChildNodeStates = children.reduce((currentChildNodeStates, child) => {
        const childStates = getNodeStatesList(child);
        return [...currentChildNodeStates, ...childStates];
      }, []);

      return [...currentNodeStates, ...allChildNodeStates];
    },
    [node],
  );
}

export function getGridUpdatedClassName(node, oldClassName, newClassName) {
  const regex = new RegExp(`\\b${oldClassName}\\b`);
  const classnames = _get(node, 'components.1.props.0.resolve');
  return classnames.replace(regex, newClassName);
}
