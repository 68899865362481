import React, { useContext } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import {
  DEFAULT_CATEGORIES,
  DEFAULT_SORT,
  DEFAULT_FONT_SIZE,
  DEFAULT_SENTENCE,
} from '../../constants';
import { TypographyContext } from '../../contexts/Typography.context';

import './ResetControlsButton.scss';

const propTypes = {};
const defaultProps = {};

export default function ResetControlsButton() {
  const { setSearchQuery, setSentence, setFontSize, setCategories, setSort } =
    useContext(TypographyContext);

  function onReset() {
    setSearchQuery('');
    setSentence(DEFAULT_SENTENCE);
    setFontSize(DEFAULT_FONT_SIZE);
    setCategories(DEFAULT_CATEGORIES);
    setSort(DEFAULT_SORT);
  }

  return (
    <Button className="reset-controls-button" onClick={onReset} icon>
      <Icon name="undo alternate" size="large" />
    </Button>
  );
}

ResetControlsButton.propTypes = propTypes;
ResetControlsButton.defaultProps = defaultProps;
