export function addChoice(choices) {
  return [...choices, ''];
}

export function deleteChoice(choices, options = {}) {
  const { choiceIndex } = options;

  return choices.filter((choice, index) => index !== choiceIndex);
}

export function updateChoice(choices, options = {}) {
  const { choiceIndex, nextChoiceValue } = options;

  return choices.map((choice, index) => (index === choiceIndex ? nextChoiceValue : choice));
}

export function moveChoice(choices, options = {}) {
  const { choiceIndex, direction } = options;

  const nextIndex = choiceIndex + direction;
  const canMoveUp = nextIndex < 0;
  const canMoveDown = nextIndex > choices.length - 1;

  if (canMoveUp || canMoveDown) {
    return choices;
  }

  return [
    ...choices.slice(0, choiceIndex + (direction === -1 ? -1 : 0)),
    choices[direction === 1 ? nextIndex : choiceIndex],
    choices[direction === 1 ? choiceIndex : nextIndex],
    ...choices.slice(choiceIndex + (direction === -1 ? 1 : 2)),
  ];
}
