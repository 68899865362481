import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { getSourceOptions } from './SourceDropdown.utils';
import { IMAGE_FILE_TYPES } from '../../../../../constants/constants';
import useErrorMessages from '../../../../../hooks/useErrorMessages';
import { FileManagementModalStep } from '../../FilesManagementModal.constants';
import { FilesManagementModalContext } from '../../FilesManagementModal.context';
import {
  IMAGE_UPLOAD_MAX_SIZE,
  MAX_SIZE_EXCEEDED_MESSAGE,
  MAX_FILENAME_LENGTH_EXCEEDED_MESSAGE,
} from '../SearchBar/SearchBar.constants';
import {
  getMaxSizeExceededFiles,
  getMaxFilenameLengthExceededFiles,
} from '../SearchBar/SearchBar.utils';

import './SourceDropdown.scss';

const propTypes = {
  initialError: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  maxFileSize: PropTypes.number,
};

const defaultProps = {
  initialError: null,
  maxFileSize: IMAGE_UPLOAD_MAX_SIZE,
};

export default function SourceDropdown({ initialError, onUpload, maxFileSize }) {
  const [, setErrorMessage] = useErrorMessages(initialError);
  const { selectedImageSource, setSelectedImageSource, uploadInput } = useContext(
    FilesManagementModalContext,
  );

  function onChange(value) {
    setSelectedImageSource(value);
    if (value === FileManagementModalStep.LOCAL_IMAGE_UPLOAD) {
      uploadInput.current.click();
      setSelectedImageSource(FileManagementModalStep.IMAGE_SELECT);
      return;
    }

    setSelectedImageSource(value);
  }

  async function handleUpload(e) {
    try {
      setErrorMessage(null);
      const files = Array.from(_get(e, 'target.files', []) || []);
      const invalidFilesPerSize = getMaxSizeExceededFiles(files, {
        maxFileSize,
      });
      const invalidFilesPerName = getMaxFilenameLengthExceededFiles(files, {
        maxFileSize,
      });
      if (_isEmpty([...invalidFilesPerSize, ...invalidFilesPerName])) {
        await onUpload(files);
      } else {
        if (!_isEmpty(invalidFilesPerSize)) {
          setErrorMessage(MAX_SIZE_EXCEEDED_MESSAGE, invalidFilesPerSize);
        }
        if (!_isEmpty(invalidFilesPerName)) {
          setErrorMessage(MAX_FILENAME_LENGTH_EXCEEDED_MESSAGE, invalidFilesPerName);
        }
      }
    } finally {
      e.target.value = null;
    }
  }

  return (
    <>
      <Dropdown selection className="source-dropdown" placeholder="Add More Images">
        <Dropdown.Menu>
          {getSourceOptions().map(({ key, value, text }) => (
            <Dropdown.Item
              key={key}
              active={selectedImageSource === value}
              onClick={() => onChange(value)}
            >
              {text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <input
        ref={uploadInput}
        hidden
        multiple
        type="file"
        onChange={handleUpload}
        accept={IMAGE_FILE_TYPES}
        maxLength={2}
      />
    </>
  );
}

SourceDropdown.propTypes = propTypes;
SourceDropdown.defaultProps = defaultProps;
