import _isBoolean from 'lodash/isBoolean';
import _isNaN from 'lodash/isNaN';
import _isNull from 'lodash/isNull';
import _isNumber from 'lodash/isNumber';
import _toString from 'lodash/toString';

export function isValidPercent(percent, decimalPrecision) {
  if (!_isNumber(percent) || _isNaN(percent)) {
    return false;
  }
  if (percent < 0 || percent > 100) {
    return false;
  }
  const percentString = _toString(percent);
  if (
    percentString.split('.').length > 2 ||
    (percentString.split('.').length === 2 && percentString.split('.')[1].length > decimalPrecision)
  ) {
    return false;
  }
  return true;
}

export function formatPercent(decimalPercent, decimalPrecision = 2) {
  const invalidPercentString = '';
  if (_isBoolean(decimalPercent) || _isNull(decimalPercent)) {
    return invalidPercentString;
  }
  const percentageFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: decimalPrecision,
  });
  const returnValue = _toString(percentageFormatter.format(decimalPercent));
  return returnValue === 'NaN%' ? invalidPercentString : returnValue;
}
