/* eslint-disable no-unused-vars */
import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { FIELD_TYPES, TEXT_FIELD_TYPE, FIELD_TYPE_COMPONENTS } from './MultiTextField.constants';
import { ObjectPropType } from '../../../../../libs/proptypes';

import './MultiTextField.css';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string.isRequired, ObjectPropType]),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  fieldType: PropTypes.oneOf(FIELD_TYPES),
  maxLength: PropTypes.number,
};

const defaultProps = {
  value: undefined,
  className: '',
  placeholder: '',
  defaultValue: undefined,
  fieldType: TEXT_FIELD_TYPE,
  maxLength: undefined,
};

export default function MultiTextField({
  value,
  onChange,
  placeholder,
  fieldType,
  className,
  defaultValue,
  ...rest
}) {
  const Component = FIELD_TYPE_COMPONENTS[fieldType];

  if (!Component) {
    return null;
  }

  const finalClassName = clsx({ 'multi-text-field': true, [className]: !!className });

  return (
    <Component
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      className={finalClassName}
      {...rest}
    />
  );
}

MultiTextField.propTypes = propTypes;
MultiTextField.defaultProps = defaultProps;
