import { CUSTOM_PAGE_TEMPLATE_NODE_TYPE } from './constants';
import API from '../../../../../libs/api';
import { isTemplateNodePageType } from '../../services/template';

export default function useCustomPageTemplates() {
  async function getCustomPageTemplates(query = '') {
    const { data: templates } = await API.getRecipeNodeTemplates(query);

    return templates.filter(isTemplateNodePageType);
  }

  async function createCustomPageTemplate(options = {}) {
    const { name, description, node } = options;

    const templatePayload = {
      name,
      description,
      node,
      type: CUSTOM_PAGE_TEMPLATE_NODE_TYPE,
    };
    const { data: template } = await API.createRecipeNodeTemplate(templatePayload);

    return template;
  }

  return { getCustomPageTemplates, createCustomPageTemplate };
}
