import React, { useState, useContext } from 'react';

import { Segment, Icon, Popup, Modal } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { ACTION_NAMES } from './CustomPageElement.constants';
import {
  getElementDisplayOptions,
  canAddMoreColumns,
  getColumnMaxWidth,
} from './CustomPageElement.utils';
import { ContentNoticeLabel } from './components';
import API from '../../../../../libs/api';
import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors';
import CloseableModal from '../../../../common/CloseableModal';
import { AUTO_ADJUSTED_COLUMN_SIZE_MODE } from '../../constants/types';
import { CustomPagesContext } from '../../contexts/CustomPages.context';
import {
  getComponentConfigurationIdsFromColumn,
  getComponentConfigurationIdsFromRow,
} from '../../services/page-state';
import { ColumnSegmentPropType, ColumnPropType } from '../../types/column.types';
import { RowPropType } from '../../types/row.types';
import EditGridElementModal from '../EditGridElementModal';
import EditPageElementModal from '../EditPageElementModal';
import EditPageElementOptions from '../EditPageElementOptions';

import './CustomPageElement.scss';

const propTypes = {
  segment: ColumnSegmentPropType.isRequired,
  row: RowPropType.isRequired,
  column: ColumnPropType.isRequired,
};

const defaultProps = {};

export default function CustomPageElement({ segment, row, column }) {
  const { elements } = segment;
  const {
    getCurrentNode,
    setNode,
    updateBlock,
    activePage,
    updateColumn,
    updateRow,
    addColumnToLeft,
    addColumnToRight,
    removeColumn,
    addRowAbove,
    addRowBelow,
    savePageNode,
    removeNode,
    isValidElement,
  } = useContext(CustomPagesContext);
  const { page } = activePage;
  const { columns, columnSizingMode } = row;

  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const [showGridColumnModal, setShowGridColumnModal] = useState(false);
  const [showGridRowModal, setShowGridRowModal] = useState(false);
  const [showElementContentModal, setShowElementContentModal] = useState(false);
  const [loadingActionName, setLoadingActionName] = useState('');
  const [actionError, setActionError] = useState('');

  function openOptionsMenu() {
    setIsOptionsMenuOpen(true);
  }

  function closeOptionsMenu() {
    if (loadingActionName) return;

    setIsOptionsMenuOpen(false);
  }

  function toggleGridColumnModal() {
    setShowGridColumnModal((prevShowGridColumnModal) => !prevShowGridColumnModal);
  }

  function toggleGridRowModal() {
    setShowGridRowModal((prevShowGridRowModal) => !prevShowGridRowModal);
  }

  function toggleElementContentModal() {
    setShowElementContentModal((prevShowElementContentModal) => !prevShowElementContentModal);
  }

  async function performNodeOperation({
    updateFunc,
    updateFuncArgs = [],
    actionName = '',
    onPostSave = () => null,
    closeOptionsModal = false,
  }) {
    if (actionName) {
      setLoadingActionName(actionName);
    }

    const prevNode = getCurrentNode();

    try {
      const updatedNode = updateFunc(...updateFuncArgs);
      await savePageNode(updatedNode);
    } catch (e) {
      ErrorHandler.capture(e);
      setActionError(getErrorMessage(e));
      setNode(prevNode);
    }

    if (actionName) {
      setLoadingActionName('');
    }

    await onPostSave();
    if (closeOptionsModal) {
      setIsOptionsMenuOpen(false);
    }
  }

  async function onElementContentSave(nextElement) {
    const { id: elementId } = elements[0];
    const { type } = nextElement;

    await performNodeOperation({
      updateFunc: updateBlock,
      updateFuncArgs: [elementId, type, nextElement],
      onPostSave: () => setShowElementContentModal(false),
    });
  }

  async function onElementContentDelete() {
    const { id, segments, ...restColumn } = column;

    await performNodeOperation({
      updateFunc: updateColumn,
      updateFuncArgs: [id, { ...restColumn, id }],
      onPostSave: () => setShowElementContentModal(false),
    });
  }

  async function onElementColumnSave(nextColumn) {
    const { id } = nextColumn;

    await performNodeOperation({
      updateFunc: updateColumn,
      updateFuncArgs: [id, nextColumn],
      onPostSave: () => setShowGridColumnModal(false),
    });
  }

  async function onElementRowSave(nextRow) {
    const { id } = nextRow;

    await performNodeOperation({
      updateFunc: updateRow,
      updateFuncArgs: [id, nextRow],
      onPostSave: () => setShowGridRowModal(false),
    });
  }

  async function onAddColumnLeft() {
    const { id } = column;

    await performNodeOperation({
      updateFunc: addColumnToLeft,
      updateFuncArgs: [id],
      actionName: ACTION_NAMES.onAddColumnLeft,
      closeOptionsModal: true,
    });
  }

  async function onAddColumnRight() {
    const { id } = column;

    await performNodeOperation({
      updateFunc: addColumnToRight,
      updateFuncArgs: [id],
      actionName: ACTION_NAMES.onAddColumnRight,
      closeOptionsModal: true,
    });
  }

  async function onDeleteColumn() {
    const { id } = column;

    const deleteColumnContent = async () => {
      const ids = getComponentConfigurationIdsFromColumn(column);

      await Promise.all(ids.map((currentId) => API.removeCustomPageContent(currentId)));
    };

    await performNodeOperation({
      updateFunc: removeColumn,
      updateFuncArgs: [id],
      actionName: ACTION_NAMES.onDeleteColumn,
      onPostSave: deleteColumnContent,
      closeOptionsModal: true,
    });
  }

  async function onAddRowAbove() {
    const { id } = row;

    await performNodeOperation({
      updateFunc: addRowAbove,
      updateFuncArgs: [id],
      actionName: ACTION_NAMES.onAddRowAbove,
      closeOptionsModal: true,
    });
  }

  async function onAddRowBelow() {
    const { id } = row;

    await performNodeOperation({
      updateFunc: addRowBelow,
      updateFuncArgs: [id],
      actionName: ACTION_NAMES.onAddRowBelow,
      closeOptionsModal: true,
    });
  }

  async function onDeleteRow() {
    const { id } = row;

    const deleteRowContent = async () => {
      const ids = getComponentConfigurationIdsFromRow(row);
      await Promise.all(ids.map((currentId) => API.removeCustomPageContent(currentId)));
    };

    await performNodeOperation({
      updateFunc: removeNode,
      updateFuncArgs: [id],
      actionName: ACTION_NAMES.onDeleteRow,
      onPostSave: deleteRowContent,
      closeOptionsModal: true,
    });
  }

  function isValidSegment() {
    if (_isEmpty(elements)) return false;

    const element = elements[0];

    return isValidElement(element);
  }

  const maxColumnWidth = getColumnMaxWidth(row, column);
  const isAutoAdjustMode = columnSizingMode === AUTO_ADJUSTED_COLUMN_SIZE_MODE;

  return (
    <div className="custom-page-element">
      <Segment onMouseLeave={closeOptionsMenu}>
        {!isValidSegment() && <ContentNoticeLabel />}
        {getElementDisplayOptions(segment).map(({ id, icon, label }) => (
          <Popup
            key={id}
            className="element-options-popup"
            trigger={
              <div key={id} onMouseEnter={openOptionsMenu} className="element-display">
                <Icon name={icon} key={icon} size="huge" />
                <div>{label}</div>
              </div>
            }
            open={isOptionsMenuOpen}
            position="right center"
            basic
          >
            <EditPageElementOptions
              onEditElementContent={toggleElementContentModal}
              onEditElementColumn={toggleGridColumnModal}
              onEditElementRow={toggleGridRowModal}
              onSplitColumn={{
                onAddColumnLeft,
                onAddColumnRight,
                onAddRowAbove,
                onAddRowBelow,
              }}
              onDelete={{
                onDeleteColumn,
                onDeleteRow,
              }}
              disabled={{
                onAddColumnLeft: !canAddMoreColumns(columns),
                onAddColumnRight: !canAddMoreColumns(columns),
                onAddRowAbove: false,
                onAddRowBelow: false,
                onDeleteColumn: false,
                onDeleteRow: false,
              }}
              loading={{
                onAddColumnLeft: loadingActionName === ACTION_NAMES.onAddColumnLeft,
                onAddColumnRight: loadingActionName === ACTION_NAMES.onAddColumnRight,
                onAddRowAbove: loadingActionName === ACTION_NAMES.onAddRowAbove,
                onAddRowBelow: loadingActionName === ACTION_NAMES.onAddRowBelow,
                onDeleteColumn: loadingActionName === ACTION_NAMES.onDeleteColumn,
                onDeleteRow: loadingActionName === ACTION_NAMES.onDeleteRow,
              }}
            />
          </Popup>
        ))}
      </Segment>
      {showElementContentModal && (
        <EditPageElementModal
          open
          element={elements[0]}
          page={page}
          onCancel={toggleElementContentModal}
          onDelete={onElementContentDelete}
          onSave={onElementContentSave}
        />
      )}
      {showGridColumnModal && (
        <EditGridElementModal
          open
          element={column}
          onCancel={toggleGridColumnModal}
          onSave={onElementColumnSave}
          editWidthSettings={{ disabled: isAutoAdjustMode, maxColumnWidth }}
        />
      )}
      {showGridRowModal && (
        <EditGridElementModal
          open
          element={row}
          onCancel={toggleGridRowModal}
          onSave={onElementRowSave}
        />
      )}
      {actionError && (
        <CloseableModal
          className="custom-page-element-error-modal"
          open
          header="Error Updating Custom Page"
          onClose={() => setActionError('')}
          dimmer="inverted"
          size="mini"
        >
          <Modal.Content>{actionError}</Modal.Content>
        </CloseableModal>
      )}
    </div>
  );
}

CustomPageElement.propTypes = propTypes;
CustomPageElement.defaultProps = defaultProps;
