import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Checkbox, List } from 'semantic-ui-react';

import { isItemChecked } from './FontVariantItem.utils';
import { TypographyContext } from '../../../../contexts/Typography.context';
import { getFontVariantDisplay, isItalicFontVariant } from '../../../../services/font';
import FontVariantText from '../../../FontVariantText';

import './FontVariantItem.scss';

const propTypes = {
  family: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  sentence: PropTypes.string.isRequired,
};
const defaultProps = {};

export default function FontVariantItem({ family, variant, sentence }) {
  const { selectedFont, setSelectedFont, fontSize } = useContext(TypographyContext);
  const displayVariant = getFontVariantDisplay(variant);

  const { selectedWeights = [] } = selectedFont;

  function onVariantToggle(e, { checked }) {
    setSelectedFont((previousSelectedFont) => {
      const newSelectedWeights = checked
        ? [...selectedWeights, variant]
        : selectedWeights.filter((v) => v !== variant);
      return { ...previousSelectedFont, selectedWeights: newSelectedWeights };
    });
  }

  const isItalic = isItalicFontVariant(variant);
  const checked = isItemChecked(variant, selectedWeights);

  return (
    <List.Item className="font-variant-item">
      <div className="variant">
        <Checkbox
          className="secondary-navy"
          checked={checked}
          onChange={onVariantToggle}
          disabled={isItalic}
        />
        <span>{displayVariant}</span>
      </div>
      <div className="sentence">
        <FontVariantText family={family} variant={variant} text={sentence} size={fontSize} />
      </div>
    </List.Item>
  );
}

FontVariantItem.propTypes = propTypes;
FontVariantItem.defaultProps = defaultProps;
