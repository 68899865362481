import {
  TEXT_BLOCK_ELEMENT_TYPE,
  IMAGE_BLOCK_ELEMENT_TYPE,
  EMPTY_BLOCK_ELEMENT_TYPE,
  JSON_BLOCK_ELEMENT_TYPE,
  FORM_BLOCK_ELEMENT_TYPE,
} from '../../constants/types';

export const SUPPORTED_ELEMENT_TYPES = [
  TEXT_BLOCK_ELEMENT_TYPE,
  IMAGE_BLOCK_ELEMENT_TYPE,
  FORM_BLOCK_ELEMENT_TYPE,
  EMPTY_BLOCK_ELEMENT_TYPE,
  JSON_BLOCK_ELEMENT_TYPE,
];

export const DEFAULT_ERROR_STATE = {
  header: '',
  message: '',
};

export const DEFAULT_SAVE_ERROR_HEADER = 'Error Saving Element';
export const DEFAULT_DELETE_ERROR_HEADER = 'Error Deleting Element';

export const EMPTY_BLOCK_MESSAGE_HEADER = 'Select Block Type';
export const EMPTY_BLOCK_MESSAGE_DESCRIPTION =
  'Select a block type above to get started on creating page content.';
