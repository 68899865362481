import React, { Component } from 'react';

import { Search } from 'semantic-ui-react';

import _ from 'lodash';

import { searchNodes } from '../utils/search';

export default class NodeSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      results: [],
      searchQuery: '',
    };

    this.getSearchResults = this.getSearchResults.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    this.getSearchResults();
  }

  onSearchChange(e, { value }) {
    this.setState({ searchQuery: value }, this.getSearchResults);
  }

  getSearchResults() {
    const { recipe, selectedTypeFilters } = this.props;
    const { searchQuery = '' } = this.state;

    this.setState({ loading: true }, () => {
      this.setState({
        results: searchNodes(recipe, searchQuery, selectedTypeFilters),
        loading: false,
      });
    });
  }

  getOptions() {
    const { results } = this.state;

    const groupedResults = _.groupBy(
      results.map(({ __path__, __label__, __type__ }) => ({
        title: __label__,
        text: __label__,
        description: __path__,
        category: __type__,
        key: __path__,
        value: __path__,
      })),
      'category',
    );

    return _.keys(groupedResults).reduce(
      (categories, key) => ({
        ...categories,
        [key]: { name: key, results: groupedResults[key] },
      }),
      {},
    );
  }

  render() {
    const { onResultSelect } = this.props;
    const { loading } = this.state;

    return (
      <Search
        category
        aligned="right"
        placeholder="Search Nodes"
        loading={loading}
        onResultSelect={onResultSelect}
        onSearchChange={_.debounce(this.onSearchChange, 500, {
          leading: true,
        })}
        results={this.getOptions()}
      />
    );
  }
}
