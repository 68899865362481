export const DEFAULT_SCHEMA_VERSION = '0.0.0';

export const SEMVER_REGX = /([0-9]+)\.([0-9]+)\.([0-9]+)/;

export const RECIPE_TABLE_HEADER_NAMES = [
  'Recipe Name',
  'Recipe Version',
  'Schema Version',
  'Tags',
];

export const RECIPE_FIELDS_DEFAULT_SHAPE = {
  name: '',
  schemaVersion: DEFAULT_SCHEMA_VERSION,
  recipeVersion: DEFAULT_SCHEMA_VERSION,
  tags: [],
};

export const MESSAGE_TIMEOUT_SECONDS = 4000;
export const DEF_ERROR_MESSAGE = 'An unexpected error has occurred';
