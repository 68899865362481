import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Header, Select } from 'semantic-ui-react';

import clsx from 'clsx';

import {
  DISTANCE_UNIT_OPTIONS,
  MASS_UNIT_OPTIONS,
  SEE_DETAILS_BUTTON_CONTENT,
} from './ItemVariationShippingInfoForm.constants';
import { FulfillmentDetails } from '../../../proptypes';

import './ItemVariationShippingInfoForm.scss';

const propTypes = PropTypes.shape({
  isNew: PropTypes.bool,
  ...FulfillmentDetails,
});

const defaultProps = {
  isNew: false,
  length: '',
  width: '',
  height: '',
  distanceUnit: '',
  weight: '',
  massUnit: '',
  virtual: false,
  fragile: false,
};

function ItemVariationShippingInfoForm({ isNew, onChange, ...shippingProps }) {
  const [showShippingDetails, setShowShippingDetails] = useState(false);
  const [variationData, setVariationData] = useState({ ...shippingProps });
  const { length, width, height, distanceUnit, weight, massUnit, virtual, fragile } = shippingProps;

  useEffect(
    () => setVariationData({ ...shippingProps }),
    [length, width, height, distanceUnit, weight, massUnit, virtual, fragile],
  );

  function onSetData(e, { name, value }) {
    setVariationData((prevData) => ({ ...prevData, [name]: value }));
  }

  function onClose() {
    setShowShippingDetails(false);
    setVariationData({ ...shippingProps });
  }

  function onSave() {
    setShowShippingDetails(false);
    onChange(variationData);
  }

  return (
    <div className="item-variation-shipping-info">
      <Button
        className={clsx('button-toggle-form', { hidden: showShippingDetails })}
        content={SEE_DETAILS_BUTTON_CONTENT}
        onClick={() => setShowShippingDetails(true)}
      />
      <div className={clsx('item-variation-shipping-info-form', { hidden: !showShippingDetails })}>
        <Header as="h4">Shipping Details</Header>
        <div>
          <Form.Group widths="equal">
            <Form.Input
              name="length"
              placeholder="Length"
              label="Length"
              value={variationData.length}
              onChange={onSetData}
            />
            <Form.Input
              name="width"
              placeholder="Width"
              label="Width"
              value={variationData.width}
              onChange={onSetData}
            />
            <Form.Input
              name="height"
              placeholder="Height"
              label="Height"
              value={variationData.height}
              onChange={onSetData}
            />
            <Form.Input label="Distance Unit">
              <Select
                name="distanceUnit"
                placeholder="Distance Unit"
                options={DISTANCE_UNIT_OPTIONS}
                value={variationData.distanceUnit}
                onChange={onSetData}
              />
            </Form.Input>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              name="weight"
              placeholder="Weight"
              label="Weight"
              value={variationData.weight}
              onChange={onSetData}
            />
            <Form.Input label="Mass Unit">
              <Select
                name="massUnit"
                placeholder="Mass Unit"
                options={MASS_UNIT_OPTIONS}
                value={variationData.massUnit}
                onChange={onSetData}
              />
            </Form.Input>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Checkbox
              label="Is Virtual?"
              checked={variationData.virtual}
              onChange={(e, data) => onSetData(e, { name: 'virtual', value: data.checked })}
            />
            <Form.Checkbox
              label="Is Fragile?"
              checked={variationData.fragile}
              onChange={(e, data) => onSetData(e, { name: 'fragile', value: data.checked })}
            />
          </Form.Group>
        </div>
        <div className="form-buttons">
          <Button className="cancel-button" content="Cancel" onClick={onClose} />
          <Button className="save-button" content="Save" onClick={onSave} />
        </div>
      </div>
    </div>
  );
}

ItemVariationShippingInfoForm.propTypes = propTypes;
ItemVariationShippingInfoForm.defaultProps = defaultProps;

export default ItemVariationShippingInfoForm;
