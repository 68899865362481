import {
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
} from '../../../../constants/recipes';
import { hasAnyRecipe } from '../../foundation/hooks/use-recipe';

export const TEAM_MEMBER_TEAM_FEATURES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const TEAM_MEMBER_SERVICES_FEATURES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];

export function areTeamsSupported(patch) {
  return hasAnyRecipe(patch, TEAM_MEMBER_TEAM_FEATURES);
}
