import React from 'react';

import { Accordion, Icon, List } from 'semantic-ui-react';

import { connect } from 'react-redux';

import EditMenuItemModifierSet from './EditMenuItemModifierSet';

class MenuItemModifierSet extends React.Component {
  onRemoveMenuItemModifier(e, id) {
    const { onRemoveMenuItemModifier: onRemoveMenuItemModifierProp } = this.props;

    e.stopPropagation();
    onRemoveMenuItemModifierProp(id);
  }

  getModifierSetModifiers() {
    const { modifierSets, modifiers, index } = this.props;
    const modifierSet = modifierSets[index];

    if (modifierSet) {
      const { modifiers: modifierSetModifierIds = [] } = modifierSet;

      return modifiers
        .map((modifier, modifierIndex) => ({ ...modifier, index: modifierIndex }))
        .filter(
          ({ id, localId }) =>
            modifierSetModifierIds.includes(id) || modifierSetModifierIds.includes(localId),
        );
    }
    return [];
  }

  render() {
    const {
      name,
      description,
      minAllowed,
      maxAllowed,
      active,
      onClick,
      id,
      localId,
      itemIndex,
      itemId,
      index,
    } = this.props;
    const showMin = !Number.isNaN(parseInt(minAllowed, 10)) && parseInt(minAllowed, 10) > 0;
    const showMax = !Number.isNaN(parseInt(maxAllowed, 10)) && parseInt(maxAllowed, 10) > 0;

    return (
      <>
        <Accordion.Title className="modifier-set-title" active={active} onClick={onClick}>
          <Icon name="dropdown" />
          <div>
            <span>{name}</span>
            {showMin && <span className="limit-tag">Min: {minAllowed}</span>}
            {showMax && <span className="limit-tag">Max: {maxAllowed}</span>}
            <span
              onClick={(e) => this.onRemoveMenuItemModifier(e, id)}
              style={{ marginRight: '1rem', textDecoration: 'underline' }}
              role="button"
              tabIndex={-1}
              onKeyDown={(e) => this.onRemoveMenuItemModifier(e, id)}
            >
              Remove
            </span>
            <EditMenuItemModifierSet
              trigger={<span>Edit</span>}
              name={name}
              description={description}
              minAllowed={minAllowed}
              maxAllowed={maxAllowed}
              isNew={!id}
              id={id}
              index={index}
              localId={localId}
              initialOpen={!id}
              itemIndex={itemIndex}
              itemId={itemId}
              modifiers={this.getModifierSetModifiers() || []}
            />
          </div>
        </Accordion.Title>
        <Accordion.Content active={active} className="modifier-set-modifiers">
          <List>
            {this.getModifierSetModifiers().map(
              ({
                name: modifierName,
                price,
                price_display: priceDisplay,
                id: modifierId,
                localId: modifierLocalId,
              }) => (
                <List.Item key={modifierId || modifierLocalId}>
                  <List.Content>{modifierName}</List.Content>
                  <List.Content floated="right">{priceDisplay || `+$${price}`}</List.Content>
                </List.Item>
              ),
            )}
          </List>
        </Accordion.Content>
      </>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = ({ business }) => ({
  modifiers: business.menu.value.modifiers,
  modifierSets: business.menu.value.modifier_sets,
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemModifierSet);
