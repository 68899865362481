import {
  STYLE_RULES_TABLE_COLUMN_NAMES_MAP,
  STYLE_RULES_TABLE_COLUMN_WIDTHS_MAP,
  STYLE_RULES_TABLE_COLUMNS_FIELDS,
  STYLE_RULES_TABLE_COLUMN_TO_DISPLAY_NAME_MAP,
  STYLE_RULES_TABLE_COLUMN_TO_CLASS_NAME_MAP,
} from './StyleRulesTable.constants';

export function getStyleRulesTableColumnConfigs() {
  return STYLE_RULES_TABLE_COLUMNS_FIELDS.reduce((acc, field) => {
    return [
      ...acc,
      {
        name: STYLE_RULES_TABLE_COLUMN_NAMES_MAP[field],
        width: STYLE_RULES_TABLE_COLUMN_WIDTHS_MAP[field],
        showName: STYLE_RULES_TABLE_COLUMN_TO_DISPLAY_NAME_MAP[field],
        className: STYLE_RULES_TABLE_COLUMN_TO_CLASS_NAME_MAP[field] || '',
      },
    ];
  }, []);
}
