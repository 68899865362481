import React from 'react';

import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';

import clsx from 'clsx';

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  isSelected: false,
  className: '',
};

function SelectableCard({ onSelect, isSelected, className, ...cardProps }) {
  return (
    <Card
      {...cardProps}
      className={clsx(className, 'selectable', isSelected && 'selected')}
      onClick={onSelect}
    />
  );
}

SelectableCard.propTypes = propTypes;
SelectableCard.defaultProps = defaultProps;

export default SelectableCard;
