import _isEmpty from 'lodash/isEmpty';

import {
  createMultipleImagesBlockElement,
  createMultipleImagesBlockState,
} from './multiple-images';
import { createSingleImageBlockElement, createSingleImageBlockState } from './single-image';
import { EMPTY_BLOCK_ELEMENT_TYPE, IMAGE_COMPONENT_NAME } from '../../../../constants/types';
import { getComponentName } from '../utils/components';

export function createImageBlockElement(data = {}, context = {}) {
  const { widgetType } = data;

  if (_isEmpty(widgetType)) {
    const { recipeStateToSnippet } = context;
    return recipeStateToSnippet(EMPTY_BLOCK_ELEMENT_TYPE, {}, { context });
  }

  return widgetType === IMAGE_COMPONENT_NAME
    ? createSingleImageBlockElement(data, context)
    : createMultipleImagesBlockElement(data, context);
}

export function createImageBlockState(recipeSnippet = {}, context = {}) {
  const componentName = getComponentName(recipeSnippet);

  if (!componentName) {
    return createSingleImageBlockState(recipeSnippet, context);
  }

  return componentName === IMAGE_COMPONENT_NAME
    ? createSingleImageBlockState(recipeSnippet, context)
    : createMultipleImagesBlockState(recipeSnippet, context);
}

export function createImageBlockTemplateState(data = {}) {
  const { componentIdentifier, componentConfigurationId, images, ...restImageBlock } = data;

  return restImageBlock;
}
