import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

import { CATALOG_OBJECT_TYPE_CATEGORY } from '../constants';

export function newCategoryData({ previousOrdinal = undefined, nextOrdinal = undefined }) {
  let categoryData = {
    images: [],
    time_periods: [],
    name: '',
    description: '',
  };
  if (previousOrdinal) {
    categoryData = {
      ...categoryData,
      previous_ordinal: previousOrdinal,
    };
  }
  if (nextOrdinal) {
    categoryData = {
      ...categoryData,
      next_ordinal: nextOrdinal,
    };
  }

  return {
    localId: uuidv4(),
    category_data: categoryData,
    type: CATALOG_OBJECT_TYPE_CATEGORY,
  };
}

export function getOrdinalsForCategory(category, categoryIndex, categories) {
  return {
    previousOrdinal: category.category_data.ordinal,
    nextOrdinal: _get(categories, `[${categoryIndex + 1}].category_data.ordinal`),
  };
}
