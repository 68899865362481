import _toNumber from 'lodash/toNumber';

export function removePriceLeadingZero(price) {
  if (!price) return price;
  return Number(parseFloat(price)).toFixed(price.toString().split('.')[1]?.length);
}

export function isGreaterThanTwoDecimalPlaces(num) {
  return String(num).split('.')[1]?.length > 2;
}

export function getPriceInputValue(price) {
  if (!price) return '0';
  if (isGreaterThanTwoDecimalPlaces(price)) return String(price).slice(0, -1);
  const convertedPrice = removePriceLeadingZero(price);
  const parsedPrice = _toNumber(convertedPrice);
  if (parsedPrice >= 0) {
    return `${convertedPrice}`;
  }
  return '0';
}

export function parsePrice(price) {
  const parsedPrice = _toNumber(price);
  if (parsedPrice >= 0) {
    return parsedPrice;
  }
  return 0;
}

export function isValidPrice(value, { allowNegativePrices = false } = {}) {
  const parsedPrice = _toNumber(value);
  if (Number.isNaN(parsedPrice)) return false;
  if (!allowNegativePrices && parsedPrice < 0) return false;
  if (
    value.split('.').length > 2 ||
    (value.split('.').length === 2 && value.split('.')[1].length > 2)
  ) {
    return false;
  }
  return true;
}
