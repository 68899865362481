import _isNil from 'lodash/isNil';

import {
  ROW_COMPONENT_NAME,
  ROW_ELEMENT_TYPE,
  AUTO_ADJUSTED_COLUMN_SIZE_MODE,
  FIXED_COLUMN_SIZE_MODE,
} from '../../../../constants/types';
import {
  lookupElementType,
  lookupElementTypeByComponentName,
  translateChildren,
} from '../utils/children';
import { addComponentChildren, createSemanticComponent, getComponentId } from '../utils/components';
import {
  addStaticPropIfDefined,
  createComponentBaseProps,
  extractComponentBaseProps,
  getStaticPropValue,
} from '../utils/props';

export function createRowElement(data = {}, context = {}) {
  const {
    id,
    className,
    style,
    columnSizingMode,
    stretched,
    textAlign,
    verticalAlign,
    columns = [],
  } = data;
  const { recipeStateToSnippet } = context;

  let element = createSemanticComponent(
    ROW_COMPONENT_NAME,
    id,
    createComponentBaseProps(className, style),
  );

  element = addStaticPropIfDefined(element, 'stretched', stretched);
  element = addStaticPropIfDefined(element, 'textAlign', textAlign);
  element = addStaticPropIfDefined(element, 'verticalAlign', verticalAlign);

  if (columnSizingMode === AUTO_ADJUSTED_COLUMN_SIZE_MODE) {
    element = addStaticPropIfDefined(element, 'columns', columns.length);
  }

  const columnSnippets = translateChildren(columns, recipeStateToSnippet, lookupElementType, {
    context,
  });

  return addComponentChildren(element, columnSnippets);
}

export function createRowState(recipeSnippet = {}, context = {}) {
  const { props, children = [] } = recipeSnippet || {};
  const { recipeSnippetToState } = context;

  const type = ROW_ELEMENT_TYPE;
  const { className, style } = extractComponentBaseProps(recipeSnippet);
  const id = getComponentId(recipeSnippet);

  const numColumns = getStaticPropValue(props, 'columns');
  const stretched = getStaticPropValue(props, 'stretched');
  const textAlign = getStaticPropValue(props, 'textAlign');
  const verticalAlign = getStaticPropValue(props, 'verticalAlign');
  const columnSizingMode = _isNil(numColumns)
    ? FIXED_COLUMN_SIZE_MODE
    : AUTO_ADJUSTED_COLUMN_SIZE_MODE;

  const columns = translateChildren(
    children,
    recipeSnippetToState,
    lookupElementTypeByComponentName,
    { context },
  );

  return {
    type,
    id,
    className,
    style,
    columnSizingMode,
    stretched,
    textAlign,
    verticalAlign,
    columns,
  };
}

export function createRowTemplateState(data = {}, context = {}) {
  const { recipeStateToTemplateState } = context;
  const { columns = [], ...restColumn } = data;

  const columnStates = translateChildren(columns, recipeStateToTemplateState, lookupElementType, {
    context,
  });

  return {
    ...restColumn,
    columns: columnStates,
  };
}
