import { useEffect, useState } from 'react';

import _isEmpty from 'lodash/isEmpty';

export default function useAnchor() {
  const [anchorState, setAnchorState] = useState('');
  const [loaded, setLoaded] = useState(false);

  function getAnchorFromLocation() {
    const hasWindowObj = typeof window !== 'undefined';
    if (!hasWindowObj) {
      return '';
    }
    const { hash } = window.location;
    if (_isEmpty(hash)) {
      return '';
    }
    if (hash[0] === '#') {
      return decodeURI(hash.substring(1));
    }
    return decodeURI(hash);
  }

  function setAnchor(newAnchor = '') {
    const hasWindowObj = typeof window !== 'undefined';
    if (!hasWindowObj) {
      return;
    }
    setAnchorState(newAnchor);
    window.location.hash = encodeURI(newAnchor);
  }

  useEffect(() => {
    setAnchorState(getAnchorFromLocation());
    setLoaded(true);
  }, []);

  return { setAnchor, anchor: anchorState, loaded };
}
