import {
  HAIR_CATEGORY,
  RESTAURANT_CATEGORY,
  SALON_CATEGORY,
  SPA_CATEGORY,
  TATTOO_PARLOR_CATEGORY,
} from 'constants/constants';

export const EXTERNAL_PAGE_TYPE = 'External';
export const CUSTOM_PAGE_TYPE = 'RecipeNode';
export const INTERNAL_PAGE_TYPE = 'Internal';
export const RECIPE_NODE_PAGE_TYPE = 'RecipeNode';

export const HOME_PAGE_TYPE = 'Home';
export const CONTACT_PAGE_TYPE = 'Contact';
export const LOCATIONS_PAGE_TYPE = 'Locations';
export const LOCATION_PAGE = 'Location';
export const MENU_PAGE_TYPE = 'Menu';
export const ORDER_ONLINE_PAGE_TYPE = 'Order Online';
export const CATERING_PAGE_TYPE = 'Catering';
export const ABOUT_PAGE_TYPE = 'About';
export const RESERVATIONS_PAGE_TYPE = 'Reservations';
export const NEWS_PAGE_TYPE = 'News';
export const BLOG_PAGE_TYPE = 'Blog';
export const BLOG_POST_PAGE_TYPE = 'Blog Post';
export const SERVICES_PAGE_TYPE = 'Services';
export const CATEGORY_PAGE_TYPE = 'Category Catalog';
export const ITEM_PAGE_TYPE = 'Item Catalog';
export const BOOK_NOW_PAGE_TYPE = 'Book Now';
export const TEAM_PAGE_TYPE = 'Team';
export const TEAM_MEMBER_PAGE_TYPE = 'Team Member';

export const BUTTON_PROPERTY = 'Button';

export const EXTERNAL_PAGE_TITLE_OPTIONS = [
  ORDER_ONLINE_PAGE_TYPE,
  RESERVATIONS_PAGE_TYPE,
  CATERING_PAGE_TYPE,
  BOOK_NOW_PAGE_TYPE,
];

export const PAGE_TITLE_OPTIONS = [
  HOME_PAGE_TYPE,
  CONTACT_PAGE_TYPE,
  LOCATIONS_PAGE_TYPE,
  MENU_PAGE_TYPE,
  ORDER_ONLINE_PAGE_TYPE,
  CATERING_PAGE_TYPE,
  'Custom',
  ABOUT_PAGE_TYPE,
  RESERVATIONS_PAGE_TYPE,
  'Gallery',
  NEWS_PAGE_TYPE,
  BLOG_PAGE_TYPE,
  EXTERNAL_PAGE_TYPE,
];

export const PAGE_GROUP_COLLECTION_PREFIX = 'PGRP_';

export const DISPLAYABLE_TITLE_BY_GENERATED_PAGE_TYPE = {
  CATALOG_CATEGORY: {
    [RESTAURANT_CATEGORY]: 'Menu Category',
    [SALON_CATEGORY]: 'Service Category',
    [SPA_CATEGORY]: 'Service Category',
    [TATTOO_PARLOR_CATEGORY]: 'Service Category',
    [HAIR_CATEGORY]: 'Service Category',
  },
  CATALOG_ITEM: {
    [RESTAURANT_CATEGORY]: 'Menu Item',
    [SALON_CATEGORY]: 'Service Item',
    [SPA_CATEGORY]: 'Service Item',
    [TATTOO_PARLOR_CATEGORY]: 'Service Item',
    [HAIR_CATEGORY]: 'Service Item',
  },
};
