import React from 'react';

import { Icon, Modal, Step, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import { FREE_PLAN_NAME } from '../../../../constants/auth';
import { hasFeatureSet } from '../../../../libs/auth';
import { selectFeatureSets } from '../../../../selectors/subscriptions';
import DomainSelection from '../../DomainSelection';
import CloverFreeForm from '../../Integrations/Clover/CloverFreeForm';
import CloverIntegration from '../../Integrations/Clover/CloverIntegration';
import OnboardingLoginBanner from '../OnboardingLoginBanner';
import {
  hasCloverStatusData,
  hasDomains,
  getCloverProviderServiceSetting,
} from '../OnboardingWizard/OnboardingWizard.utils';

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  domains: _get(state, 'website.domains.value', []),
  providerServices: _get(state, 'business.provider_services.value'),
  featureSets: selectFeatureSets(state),
  providerServiceSettings: _get(state, 'business.provider_service_settings.value'),
});

function getCloverIntegration() {
  return <CloverIntegration showTitle={false} />;
}

function getFreeForm() {
  return <CloverFreeForm canSkip />;
}

function getDomainSelection() {
  return <DomainSelection />;
}

class CloverMerchantModal extends React.Component {
  getCurrentStep = () => {
    const { domains, providerServiceSettings } = this.props;
    const serviceSetting = getCloverProviderServiceSetting(providerServiceSettings);

    const isMissingSettings = !hasCloverStatusData(serviceSetting);
    const isMissingDomains = !hasDomains(domains);
    const isMissingGoogle = true;

    if (isMissingSettings) return 1;
    if (isMissingDomains || isMissingGoogle) return 2;
    return 0;
  };

  getModalContent = () => {
    const { featureSets } = this.props;
    const currentStep = this.getCurrentStep();
    const isFreeTier = hasFeatureSet(featureSets, FREE_PLAN_NAME);

    if (isFreeTier) {
      return (
        <>
          <Step.Group attached="top" stackable="tablet">
            <Step active={currentStep === 1} completed={currentStep > 1}>
              <Icon name="settings" />
              <Step.Content>
                <Step.Title>Connect to Clover</Step.Title>
                <Step.Description>Install Fisherman App on Clover App Marketplace</Step.Description>
              </Step.Content>
            </Step>
            <Step active={currentStep === 2} completed={currentStep > 1}>
              <Icon name="check" />
              <Step.Content>
                <Step.Title>Welcome to Fisherman!</Step.Title>
                <Step.Description>
                  Follow this list to make sure you’re set up for website success!
                </Step.Description>
              </Step.Content>
            </Step>
          </Step.Group>
          <Segment attached className="content-segment">
            {currentStep === 1 && getCloverIntegration()}
            {currentStep === 2 && getFreeForm()}
          </Segment>
        </>
      );
    }

    return (
      <>
        <Step.Group attached="top" stackable="tablet">
          <Step active={currentStep === 1} completed={currentStep > 1}>
            <Icon name="settings" />
            <Step.Content>
              <Step.Title>Connect to Clover</Step.Title>
              <Step.Description>Install Fisherman App on Clover App Marketplace</Step.Description>
            </Step.Content>
          </Step>
          <Step active={currentStep === 2} completed={currentStep > 2}>
            <Icon name="window maximize outline" />
            <Step.Content>
              <Step.Title>Domain Selection</Step.Title>
              <Step.Description>Choose a Domain for your Website</Step.Description>
            </Step.Content>
          </Step>
        </Step.Group>
        <Segment attached className="content-segment">
          {currentStep === 1 && getCloverIntegration()}
          {currentStep === 2 && getDomainSelection()}
        </Segment>
      </>
    );
  };

  render() {
    const { isOpen = false } = this.props;
    const currentStep = this.getCurrentStep();

    if (currentStep === 0) return null;

    return (
      <Modal
        basic
        open={isOpen}
        className="onboarding-modal"
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size="fullscreen"
      >
        <OnboardingLoginBanner />
        <Modal.Content scrolling>{this.getModalContent()}</Modal.Content>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloverMerchantModal);
