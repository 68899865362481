import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { multiCaseGet } from 'libs/format';

import { generateLocalId } from '../../../../libs/slices';

export function createComponentConfiguration({
  id = null,
  files = [],
  interactions = [],
  contentNodes = [],
  order = 0,
  data = {},
  componentIdentifier = '',
  fastId = '',
  enabled = true,
  childConfigurations = [],
  page = null,
  layout = null,
  metadata = {},
}) {
  const componentConfiguration = {
    id,
    files,
    interactions,
    contentNodes,
    order,
    data,
    componentIdentifier,
    fastId,
    enabled,
    childConfigurations,
    page,
    layout,
    metadata,
  };

  if (_isNil(id)) {
    componentConfiguration.localId = generateLocalId();
  }

  return componentConfiguration;
}

export function getComponentSetting(component, settings, { pageType = null } = {}) {
  const componentName = multiCaseGet(component, 'fastId');
  const componentIdentifier = multiCaseGet(component, 'componentIdentifier');

  const componentSettings = settings.filter((setting) => {
    const settingsComponentName = multiCaseGet(setting, 'component.fastId');
    const settingsComponentIdentifier = multiCaseGet(setting, 'componentIdentifier');

    const isMatch =
      settingsComponentName === componentName &&
      settingsComponentIdentifier === componentIdentifier;

    if (!_isEmpty(pageType)) {
      const settingsPageType = multiCaseGet(setting, 'pageType');
      return isMatch && settingsPageType === pageType;
    }

    return isMatch;
  });

  if (componentSettings.length) {
    return componentSettings[0];
  }

  return {};
}
