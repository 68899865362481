import React, { useState, useEffect } from 'react';

import { Dropdown, Input } from 'semantic-ui-react';

import _debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';

import { selectWebsitePages } from '../../../../../../selectors/website';
import { getPageUrl } from '../../../utils';

import './InternalLinkOptions.scss';

function InternalLinkOptions({ onSelect }) {
  const pages = useSelector(selectWebsitePages);
  const [query, setQuery] = useState('');
  const [filteredPages, setFilteredPages] = useState(pages);

  useEffect(() => {
    const filtered = (pages || []).filter((page) =>
      page.title.toLowerCase().includes(query.toLowerCase()),
    );
    setFilteredPages(filtered);
  }, [query]);

  function onSearchChange(e, { value }) {
    setQuery(value);
  }
  const onSearchChangeDebounce = _debounce(onSearchChange, 200);

  function handleSelect(e, { value: selectedUrl }) {
    onSelect(e, selectedUrl);
  }

  return (
    <>
      <Input
        className="internal-link-options-search"
        icon="search"
        name="search"
        placeholder="Search by name"
        onChange={onSearchChangeDebounce}
      />
      {filteredPages &&
        filteredPages.map((page) => (
          <Dropdown.Item
            key={page.id}
            text={page.title}
            value={getPageUrl(page)}
            onClick={handleSelect}
          />
        ))}
    </>
  );
}

export default InternalLinkOptions;
