import { getProviderService, getProviderServiceSetting } from '../utils';

const CLOVER_PROVIDER = 'Clover';
const CLOVER_SERVICE_TYPE = 'Merchant';

export function getCloverProviderService(providerServices) {
  return getProviderService(providerServices, CLOVER_PROVIDER, CLOVER_SERVICE_TYPE);
}

export function getCloverProviderServiceSetting(providerServiceSettings) {
  return getProviderServiceSetting(providerServiceSettings, CLOVER_PROVIDER, CLOVER_SERVICE_TYPE);
}
