export const MENU_ENTITY_NAME = 'Menu';
const MENU_FILE_OPTION_NAME = 'Menu Files (PDFs or Images)';
const MENU_ITEMS_OPTION_NAME = 'Digital Menu';
const MENU_BOTH_OPTION_NAME = 'Menu Files & Digital Menu';
const MENU_INTEGRATION_OPTION_NAME = 'Integration';
const MENU_NONE_OPTION_NAME = 'None';
const MENU_ENTITY_DESCRIPTION =
  'A Menu is a way to organize availability "Lunch" or "Dinner", or preparation, like "Takeout" or "Catering".';
const MENU_ITEM_DESCRIPTION =
  'Items are the things you sell in your restaurant, like "Turkey Club" or "Cheeseburger".';
const MENU_CATEGORY_DESCRIPTION =
  'A Category is a way to group different types of food, like "Sandwiches" or "Soups"';

export const SERVICES_ENTITY_NAME = 'Services';
const SERVICES_FILE_OPTION_NAME = 'Services Files (PDFs or Images)';
const SERVICES_ITEMS_OPTION_NAME = 'List of Services';
const SERVICES_BOTH_OPTION_NAME = 'Services Files & List of Services';
const SERVICES_INTEGRATION_OPTION_NAME = 'Integration';
const SERVICES_NONE_OPTION_NAME = 'None';
const SERVICES_ENTITY_DESCRIPTION = '';
const SERVICES_ITEM_DESCRIPTION = '';
const SERVICES_CATEGORY_DESCRIPTION = '';

const DEFAULT_ENTITY_NAME = 'Inventory';
const DEFAULT_FILE_OPTION_NAME = 'Inventory Files (PDFs or Images)';
const DEFAULT_ITEMS_OPTION_NAME = 'List of Inventory';
const DEFAULT_BOTH_OPTION_NAME = 'Inventory Files & List of Inventory';
const DEFAULT_INTEGRATION_OPTION_NAME = 'Integration';
const DEFAULT_NONE_OPTION_NAME = 'None';
const DEFAULT_ENTITY_DESCRIPTION = '';
const DEFAULT_ITEM_DESCRIPTION = 'Items are the things you sell in your business.';
const DEFAULT_CATEGORY_DESCRIPTION = 'A Category is a way to group different types of items';

const SOURCE_TYPE_FILES = 'files';
const SOURCE_TYPE_FILES_AND_ITEMS = 'filesanditems';
const SOURCE_TYPE_ITEMS = 'items';

export const ENTITY_NAME_BY_BUSINESS_TYPE = {
  restaurant: MENU_ENTITY_NAME,
  salon: SERVICES_ENTITY_NAME,
  spa: SERVICES_ENTITY_NAME,
  'hair care': SERVICES_ENTITY_NAME,
  business: SERVICES_ENTITY_NAME,
  entertainment: SERVICES_ENTITY_NAME,
  'tattoo parlor': SERVICES_ENTITY_NAME,
  default: DEFAULT_ENTITY_NAME,
};

export const BUSINESS_TYPE_COPIES = {
  restaurant: {
    filesOptionsName: MENU_FILE_OPTION_NAME,
    itemsOptionsName: MENU_ITEMS_OPTION_NAME,
    bothOptionsName: MENU_BOTH_OPTION_NAME,
    integrationOptionName: MENU_INTEGRATION_OPTION_NAME,
    noneOptionsName: MENU_NONE_OPTION_NAME,
    entityDescription: MENU_ENTITY_DESCRIPTION,
    itemDescription: MENU_ITEM_DESCRIPTION,
    categoryDescription: MENU_CATEGORY_DESCRIPTION,
  },
  salon: {
    filesOptionsName: SERVICES_FILE_OPTION_NAME,
    itemsOptionsName: SERVICES_ITEMS_OPTION_NAME,
    bothOptionsName: SERVICES_BOTH_OPTION_NAME,
    integrationOptionName: SERVICES_INTEGRATION_OPTION_NAME,
    noneOptionsName: SERVICES_NONE_OPTION_NAME,
    entityDescription: SERVICES_ENTITY_DESCRIPTION,
    itemDescription: SERVICES_ITEM_DESCRIPTION,
    categoryDescription: SERVICES_CATEGORY_DESCRIPTION,
  },
  spa: {
    filesOptionsName: SERVICES_FILE_OPTION_NAME,
    itemsOptionsName: SERVICES_ITEMS_OPTION_NAME,
    bothOptionsName: SERVICES_BOTH_OPTION_NAME,
    integrationOptionName: SERVICES_INTEGRATION_OPTION_NAME,
    noneOptionsName: SERVICES_NONE_OPTION_NAME,
    entityDescription: SERVICES_ENTITY_DESCRIPTION,
    itemDescription: SERVICES_ITEM_DESCRIPTION,
    categoryDescription: SERVICES_CATEGORY_DESCRIPTION,
  },
  'hair care': {
    filesOptionsName: SERVICES_FILE_OPTION_NAME,
    itemsOptionsName: SERVICES_ITEMS_OPTION_NAME,
    bothOptionsName: SERVICES_BOTH_OPTION_NAME,
    integrationOptionName: SERVICES_INTEGRATION_OPTION_NAME,
    noneOptionsName: SERVICES_NONE_OPTION_NAME,
    entityDescription: SERVICES_ENTITY_DESCRIPTION,
    itemDescription: SERVICES_ITEM_DESCRIPTION,
    categoryDescription: SERVICES_CATEGORY_DESCRIPTION,
  },
  business: {
    filesOptionsName: SERVICES_FILE_OPTION_NAME,
    itemsOptionsName: SERVICES_ITEMS_OPTION_NAME,
    bothOptionsName: SERVICES_BOTH_OPTION_NAME,
    integrationOptionName: SERVICES_INTEGRATION_OPTION_NAME,
    noneOptionsName: SERVICES_NONE_OPTION_NAME,
    entityDescription: SERVICES_ENTITY_DESCRIPTION,
    itemDescription: SERVICES_ITEM_DESCRIPTION,
    categoryDescription: SERVICES_CATEGORY_DESCRIPTION,
  },
  entertainment: {
    filesOptionsName: SERVICES_FILE_OPTION_NAME,
    itemsOptionsName: SERVICES_ITEMS_OPTION_NAME,
    bothOptionsName: SERVICES_BOTH_OPTION_NAME,
    integrationOptionName: SERVICES_INTEGRATION_OPTION_NAME,
    noneOptionsName: SERVICES_NONE_OPTION_NAME,
    entityDescription: SERVICES_ENTITY_DESCRIPTION,
    itemDescription: SERVICES_ITEM_DESCRIPTION,
    categoryDescription: SERVICES_CATEGORY_DESCRIPTION,
  },
  'tattoo parlor': {
    filesOptionsName: SERVICES_FILE_OPTION_NAME,
    itemsOptionsName: SERVICES_ITEMS_OPTION_NAME,
    bothOptionsName: SERVICES_BOTH_OPTION_NAME,
    integrationOptionName: SERVICES_INTEGRATION_OPTION_NAME,
    noneOptionsName: SERVICES_NONE_OPTION_NAME,
    entityDescription: SERVICES_ENTITY_DESCRIPTION,
    itemDescription: SERVICES_ITEM_DESCRIPTION,
    categoryDescription: SERVICES_CATEGORY_DESCRIPTION,
  },
  default: {
    filesOptionsName: DEFAULT_FILE_OPTION_NAME,
    itemsOptionsName: DEFAULT_ITEMS_OPTION_NAME,
    bothOptionsName: DEFAULT_BOTH_OPTION_NAME,
    integrationOptionName: DEFAULT_INTEGRATION_OPTION_NAME,
    noneOptionsName: DEFAULT_NONE_OPTION_NAME,
    entityDescription: DEFAULT_ENTITY_DESCRIPTION,
    itemDescription: DEFAULT_ITEM_DESCRIPTION,
    categoryDescription: DEFAULT_CATEGORY_DESCRIPTION,
  },
};

export const SOURCE_TYPE_WITH_QR_CODE = [
  SOURCE_TYPE_FILES,
  SOURCE_TYPE_FILES_AND_ITEMS,
  SOURCE_TYPE_ITEMS,
];
