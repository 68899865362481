import React from 'react';

import { Button } from 'semantic-ui-react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logOut } from '../../../../libs/auth';
import LoginBanner from '../../LoginBanner';

export default function OnboardingLoginBanner() {
  const navigate = useNavigate();
  const state = useSelector((stateConnect) => stateConnect);

  const businessesCount = _get(state, 'user.core.value.businessesCount', {});

  return (
    <LoginBanner
      backActions={
        businessesCount <= 1 ? (
          <Button basic color="red" onClick={() => logOut(navigate, '/')}>
            Logout
          </Button>
        ) : (
          <Button basic onClick={() => navigate('/')}>
            Go Back
          </Button>
        )
      }
    />
  );
}
