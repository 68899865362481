import React from 'react';

import { Header, Icon, Segment, Button } from 'semantic-ui-react';

import { launchCloverMarketplace } from './CloverPlanForm.utils';
import { zendeskOpen } from '../../common/ZendeskWidget/ZendeskWidget.utils';

export default () => {
  return (
    <Segment placeholder textAlign="center">
      <Header icon>
        <Icon name="payment" />
        Your business has a Clover Subscription
      </Header>
      <p>
        You may manage your subscription tier and billing by going to the Clover Marketplace. You
        can get there by clicking on the button below.
      </p>
      <Button onClick={launchCloverMarketplace} primary>
        Launch Clover Marketplace
      </Button>
      <Button onClick={zendeskOpen}>Open Chat</Button>
    </Segment>
  );
};
