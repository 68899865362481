import React from 'react';

import { Loader } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';

import { extractGeneratedPages } from 'components/modules/content/services/page';
import { selectBusinessCategory } from 'selectors/business';

import { flagBusinessSaved } from '../../../actions/business';
import API from '../../../libs/api';
import { PageContentNodes } from '../../modules/content/components';
import { PageSideNavigation } from '../../modules/pages/components';
import { INTERNAL_PAGE_TYPE } from '../../modules/pages/constants';
import usePages from '../../modules/pages/hooks/use-pages';

import './ContentPage.scss';

export default function ContentPage() {
  const dispatch = useDispatch();

  const businessCategory = useSelector(selectBusinessCategory);

  const { pages, settings, refreshPages } = usePages({ pageType: INTERNAL_PAGE_TYPE });

  const pagesWithGeneratedPages = extractGeneratedPages(pages, settings, { businessCategory });

  async function onSaveContentNode(contentNode, newContent, { component }) {
    await API.updateContentNodeContent(contentNode.id, newContent, [component.id]);
    await refreshPages();
    dispatch(flagBusinessSaved(true));
  }

  function getPageContentNodesComponent(props) {
    return <PageContentNodes {...props} onSave={onSaveContentNode} />;
  }

  if (_isEmpty(pagesWithGeneratedPages) || _isEmpty(settings)) {
    return <Loader active />;
  }

  return (
    <PageSideNavigation
      pages={pagesWithGeneratedPages}
      settings={settings}
      displayComponent={getPageContentNodesComponent}
    />
  );
}
