import React, { useContext } from 'react';

import { Grid } from 'semantic-ui-react';

import { HtmlElementPageContext } from '../../context/HtmlElements.context';
import HtmlElementsActionBar from '../HtmlElementsActionBar';
import HtmlElementsCodeEditor from '../HtmlElementsCodeEditor';
import HtmlElementsContainer from '../HtmlElementsContainer';

import './HtmlElementsGrid.scss';

const propTypes = {};

const defaultProps = {};

export default function HtmlElementsGrid() {
  const { setItemEditingId, setItem, setErrorMessage } = useContext(HtmlElementPageContext);

  function onClick(e) {
    e.stopPropagation();
    setItemEditingId(null);
    setItem(null);
    setErrorMessage('');
  }

  return (
    <>
      <Grid stackable className="html-elements-grid">
        <Grid.Row>
          <Grid.Column className="html-elements-list-column" width={8} onClick={onClick}>
            <HtmlElementsContainer />
          </Grid.Column>
          <Grid.Column className="html-elements-editor-column" width={8}>
            <HtmlElementsCodeEditor />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <HtmlElementsActionBar />
    </>
  );
}

HtmlElementsGrid.propTypes = propTypes;
HtmlElementsGrid.defaultProps = defaultProps;
