import React, { useState, useEffect } from 'react';

import { Button, Grid } from 'semantic-ui-react';

import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import { LOADING_MESSAGE } from './CustomHeroTab.constants';
import {
  convertDynamicTilesToCustomHero,
  getHomePage,
  loadDataWrapper,
} from './CustomHeroTab.utils';
import { initializeBusinessFiles, flagBusinessSaved } from '../../../../../actions/business';
import API from '../../../../../libs/api';
import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors/errors';
import { selectBusinessId, selectBusinessType } from '../../../../../selectors';
import { selectBusinessFiles } from '../../../../../selectors/business';
import { selectWebsiteId, selectWebsitePages } from '../../../../../selectors/website';
import ConfirmModal from '../../../../common/ConfirmModal';
import { selectComponentConfigurationsByParams } from '../../../../modules/component-configuration/selectors/component-configuration';
import { setAllComponentConfigurations } from '../../../../modules/component-configuration/slices/ComponentConfiguration.slice';
import { CustomHeroProvider } from '../../../../modules/custom-pages/contexts/CustomHero.context';
import LoadingMessage from '../../../../modules/foundation/components/LoadingMessage';
import useAsyncEffect from '../../../../modules/foundation/hooks/use-async-effect';
import CustomHeroForm from '../../../../modules/hero/components/CustomHeroForm';
import { CustomHeroFormProvider } from '../../../../modules/hero/components/CustomHeroForm/CustomHeroForm.context';
import TemplateLayout from '../../../../modules/hero/components/TemplateLayout';
import { DYNAMIC_TILES_COMPONENT } from '../../../../modules/hero/constants/custom-hero';
import useCustomHeroManager from '../../../../modules/hero/hooks/use-custom-hero-manager';

import './CustomHeroTab.scss';

const propTypes = {};

const defaultProps = {};

export default function CustomHeroTab() {
  const dispatch = useDispatch();
  const websiteId = useSelector(selectWebsiteId);
  const businessType = useSelector(selectBusinessType);
  const businessId = useSelector(selectBusinessId);
  const businessFiles = useSelector(selectBusinessFiles);
  const dynamicTilesComponentConfigurations = useSelector((state) =>
    selectComponentConfigurationsByParams(state, { fastId: DYNAMIC_TILES_COMPONENT }),
  );
  const pages = useSelector(selectWebsitePages);

  const [activePage, setActivePage] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialCustomHero, setInitialCustomHero] = useState({});

  useEffect(() => {
    const homePage = getHomePage(pages);
    setActivePage(homePage);
  }, [pages]);

  useEffect(() => {
    const activePageTilesComponent =
      dynamicTilesComponentConfigurations.find(
        (componentConfiguration) => componentConfiguration.page === activePage.id,
      ) || {};

    const activePageCustomHero = convertDynamicTilesToCustomHero(
      activePageTilesComponent,
      activePage,
    );
    setInitialCustomHero(activePageCustomHero);
  }, [activePage]);

  async function loadComponentConfigurations() {
    const data = await API.getComponentConfigurations(websiteId);
    dispatch(setAllComponentConfigurations(data));
  }

  async function loadBusinessFiles(forceRefresh = false) {
    if (!forceRefresh && businessFiles.length) return;
    const { data: businessFilesData } = await API.getBusinessFiles(businessType, businessId);
    dispatch(initializeBusinessFiles(businessFilesData));
  }

  useAsyncEffect(async () => {
    await loadDataWrapper([loadBusinessFiles, loadComponentConfigurations], setLoading);
  }, []);

  async function reloadData() {
    await loadDataWrapper([loadComponentConfigurations], setLoading);
  }

  async function onHandleEvent(callback) {
    setErrorMessage('');
    try {
      setIsSaving(true);
      await callback();
      await reloadData();
      dispatch(flagBusinessSaved(true));
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
      ErrorHandler.capture(error);
    } finally {
      setIsSaving(false);
    }
  }

  const context = {
    activePage,
    setActivePage,
  };

  const customHeroManager = useCustomHeroManager(activePage, initialCustomHero);
  const { customHero, saveCustomHero, removeCustomHero } = customHeroManager;

  return (
    <CustomHeroProvider value={context}>
      <div className="custom-hero-tab">
        {loading && <LoadingMessage message={LOADING_MESSAGE} />}
        {!loading && (
          <CustomHeroFormProvider value={customHeroManager}>
            <Grid className="custom-hero-container" stackable reversed="mobile vertically">
              <Grid.Column width="8">
                <CustomHeroForm errorMessage={errorMessage} />
                <div className="custom-hero-form-actions">
                  <Button
                    className="secondary-navy submit"
                    content="Save"
                    type="submit"
                    onClick={() => onHandleEvent(saveCustomHero)}
                    disabled={isSaving}
                    loading={isSaving}
                    floated="right"
                  />
                  {_get(customHero, 'componentConfiguration.componentConfigurationId') && (
                    <Button
                      className="submit delete-red"
                      content="Delete"
                      type="submit"
                      onClick={() => setShowDeleteModal(true)}
                      disabled={isSaving}
                      loading={isSaving}
                    />
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width="8">
                <TemplateLayout showTriggerEdit />
              </Grid.Column>
            </Grid>
          </CustomHeroFormProvider>
        )}
        {showDeleteModal && (
          <ConfirmModal
            header="Delete Custom Hero"
            content="Are you sure you want to remove your custom hero for Home page?"
            open={showDeleteModal}
            onCancel={() => setShowDeleteModal(false)}
            onConfirm={() => {
              onHandleEvent(removeCustomHero);
              setShowDeleteModal(false);
            }}
            cancelButton="Cancel"
          />
        )}
      </div>
    </CustomHeroProvider>
  );
}

CustomHeroTab.propTypes = propTypes;
CustomHeroTab.defaultProps = defaultProps;
