import React from 'react';

import { Table } from 'semantic-ui-react';

import CreateTeamMemberModal from '../CreateTeamMemberModal';
import TeamsTableBody from '../TeamsTableBody';
import TeamsTableHeader from '../TeamsTableHeader';

import './TeamsTable.scss';

export default function TeamsTable() {
  return (
    <Table celled compact className="teams-table">
      <TeamsTableHeader />
      <TeamsTableBody />
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell colSpan="9">
            <CreateTeamMemberModal />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}
