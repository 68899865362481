import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Input, Button, Confirm, Header } from 'semantic-ui-react';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _isEmpty from 'lodash/isEmpty';
import _isNumber from 'lodash/isNumber';
import { useDispatch, useSelector } from 'react-redux';

import {
  BUTTONS_TOOLTIP_DESCRIPTION,
  SETTINGS_OPTIONS,
  ITEM_LONG_DESCRIPTION_MAX_LENGTH,
  ITEM_CATALOG_OBJECT_TYPE,
  ITEM_LONG_DESCRIPTION_MIN_LENGTH,
  ITEM_SHORT_DESCRIPTION_MAX_LENGTH,
  ITEM_SHORT_DESCRIPTION_MIN_LENGTH,
  ITEM_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS,
  ITEM_SHORT_DESCRIPTION_PLACEHOLDER,
  ITEM_SHORT_DESCRIPTION_TOOL_TIP,
  ITEM_LONG_DESCRIPTION_PLACEHOLDER,
  ITEM_LONG_DESCRIPTION_TOOL_TIP,
} from './EditMenuItem.constants';
import {
  getDescriptionRequirements,
  getTitleRequirements,
  isImageUrlEmpty,
} from './EditMenuItem.utils';
import { updateMenuItem, deleteMenuItem, flagMenuSaved } from '../../../../../actions/business';
import API from '../../../../../libs/api';
import { sortItemsByList } from '../../../../../libs/array';
import { getErrorMessage } from '../../../../../libs/errors';
import ErrorHandler from '../../../../../libs/errors/errors';
import { objectToCamelCase, objectToSnakeCase } from '../../../../../libs/format/case';
import {
  selectBusiness,
  selectBusinessFiles,
  selectBusinessName,
} from '../../../../../selectors/business';
import { ImagePlaceholderField } from '../../../../common';
import HelpTooltip from '../../../../common/HelpTooltip';
import CatalogObjectDescriptionInput from '../../../../fields/CatalogObjectDescriptionInput';
import { CatalogItemPropType } from '../../../../modules/catalog/proptypes/catalog';
import FileSelectionWell from '../../../../modules/files/components/FileSelectionWell';
import { SOURCE_TYPE_MENU } from '../../../../modules/files/constants/sources';
import { ITEM_PAGE_TYPE } from '../../../../modules/pages/constants';
import { ImageAltTextField } from '../../../../modules/seo/components';
import SeoMetadataFields from '../../../../modules/seo/components/SeoMetadataFields';
import { createSeoTitlePlaceholder } from '../../../../modules/seo/components/SeoMetadataFields/components/SeoTitle/SeoTitle.utils';
import ProductDescriptionPageWidget from '../../../../modules/website-component-interaction/ProductDescriptionPageWidget';
import WebsiteComponentInteractionContainer from '../../../../modules/website-component-interaction/WebsiteComponentInteractionContainer';
import {
  filterClickthruInteractions,
  filterNonClickthruInteractions,
} from '../../../../modules/website-component-interaction/utils';
import { InventoryConsumer, InventoryContext } from '../../../MenuForm/Menu.context';
import EditMenuModal from '../../common/EditMenuModal';
import SettingsWidget from '../../common/SettingsWidget/SettingsWidget';
import {
  areCtasEnabledForRecipe,
  isFeaturedFeatureEnabled,
  getBusinessMenuSettings,
  isMultipleImagesSupported,
  isClickTroughUrlEnabledForBusiness,
  isDetailedDescriptionEnabled,
  isAdvancedSeoSupported,
} from '../../libs/menu-helpers';
import MenuItemModifierSets from '../../modifier-set/MenuItemModifierSets';
import MenuItemVariants from '../../variant/MenuItemVariants';

const propTypes = {
  categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  categoryIndex: PropTypes.number.isRequired,

  itemIndex: PropTypes.number.isRequired,
  initialItem: CatalogItemPropType,

  onCloseModal: PropTypes.func,
  open: PropTypes.bool,
};

const defaultProps = {
  initialItem: {},
  onCloseModal: null,
  open: false,
};

export default function EditMenuItem({
  categoryId,
  categoryIndex,
  itemIndex,
  initialItem,
  onCloseModal,
  open,
}) {
  const dispatch = useDispatch();

  const business = useSelector(selectBusiness);
  const businessName = useSelector(selectBusinessName);
  const items = useSelector((state) => _get(state, 'business.menu.value.items'));
  const categories = useSelector((state) => _get(state, 'business.menu.value.categories'));
  const businessFiles = useSelector(selectBusinessFiles);

  const { activePatch } = useContext(InventoryContext);

  const [errorMessages, setErrorMessages] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [formData, setFormData] = useState(_cloneDeep(initialItem || {}));

  function resetFormData() {
    setFormData(_cloneDeep(initialItem || {}));
    setErrorMessages([]);
  }

  useEffect(() => {
    resetFormData();
  }, [initialItem]);

  const { id: businessId, type: businessType } = business;

  const category = categories[categoryIndex];
  const {
    id: itemId,
    localId: itemLocalId,
    name = '',

    description = '',
    fullDescription = '',
    image,
    imageAltText = '',
    interactions = [],
    files = [],

    isFeatured = false,
    visible = true,
    available = true,
    isSpecial = false,

    variations: itemVariations = [],
    modifierSets: itemModifierSets = [],

    seoTitle = '',
    seoDescription = '',
  } = formData || {};

  const isNew = !itemId;

  function closeModal() {
    resetFormData();
    if (onCloseModal) {
      onCloseModal();
    }
  }

  function validateForm() {
    const response = { formIsValid: false, errors: [] };

    if (_isEmpty(name)) {
      response.errors.push('Please enter an item name.');
    }
    if (_isEmpty(itemVariations)) {
      response.errors.push('Please include at least one variation');
    } else {
      itemVariations.forEach((variation) => {
        if (!variation.name) {
          response.errors.push('Please enter a name for each variation.');
        }
      });
    }

    response.formIsValid = _isEmpty(response.errors);
    return response;
  }

  function updateMenuItemState(payload = {}) {
    setFormData((prevFormData) => ({ ...prevFormData, ...objectToCamelCase(payload) }));
  }

  async function onSaveNewItem() {
    const { formIsValid, errors } = validateForm();
    if (!formIsValid) {
      setErrorMessages(errors);
      return;
    }

    setSaveLoading(true);
    try {
      const payload = objectToSnakeCase({
        name,
        description,
        variations: itemVariations,
        modifier_sets: itemModifierSets,
        order: items.length,
        interactions,
        isFeatured,
        fullDescription,
        files,
        seoTitle,
        seoDescription,
        imageAltText,
      });

      const { data: createdItem } = await API.createMenuItem(businessId, businessType, payload);

      let imageData = {};
      if (!_isEmpty(image) && image.file) {
        const imagePayload = new FormData();
        imagePayload.append('image', image.file);
        const { data: imageDataResponse } = await API.updateMenuItem(
          businessId,
          businessType,
          createdItem.id,
          imagePayload,
        );
        imageData = imageDataResponse;
      }

      const newCategoryItems = [
        ...new Set([...category.items.filter((id) => id !== itemLocalId), createdItem.id]),
      ];
      const { data: categoryItem } = await API.addMenuCategoryItem(
        businessId,
        businessType,
        categoryId,
        newCategoryItems,
      );

      dispatch(
        updateMenuItem({
          index: itemIndex,
          values: { ...createdItem, ...imageData, index: itemIndex },
          categoryIndex,
          localItemId: itemLocalId,
          items: categoryItem.items,
        }),
      );

      updateMenuItemState({ ...createdItem, ...imageData, index: itemIndex });
      closeModal();

      dispatch(flagMenuSaved(true));
    } catch (e) {
      setErrorMessages(['There was an error creating the item. ', getErrorMessage(e)]);
    }

    setSaveLoading(false);
  }

  async function onSaveItem() {
    const { formIsValid, errors } = validateForm();
    if (!formIsValid) {
      setErrorMessages(errors);
      return;
    }

    setSaveLoading(true);
    try {
      const payload = objectToSnakeCase({
        name,
        description,
        variations: itemVariations.map((variation) => ({
          ...variation,
          menuItem: itemId,
        })),
        modifierSets: itemModifierSets,
        visible,
        available,
        isSpecial,
        isFeatured,
        fullDescription,
        interactions,
        files,
        seoTitle,
        seoDescription,
        imageAltText,
      });

      if (image && typeof image === 'object') {
        const imagePayload = new FormData();
        // make sure that if you want to remove the image, to send an empty string ''
        imagePayload.append('image', image.file || '');
        await API.updateMenuItem(businessId, businessType, itemId, imagePayload);
      }

      const { data: updatedItem } = await API.updateMenuItem(
        businessId,
        businessType,
        itemId,
        payload,
      );
      dispatch(updateMenuItem({ index: itemIndex, values: { ...updatedItem, index: itemIndex } }));
      updateMenuItemState({ ...updatedItem, index: itemIndex });

      closeModal();

      dispatch(flagMenuSaved(true));
    } catch (e) {
      ErrorHandler.capture(e);
      const errorMessage = getErrorMessage(e);
      setErrorMessages([errorMessage]);
    } finally {
      setSaveLoading(false);
    }
  }

  async function onDeleteItem() {
    setDeleteLoading(true);

    try {
      await API.deleteMenuItem(businessId, businessType, itemId);
      dispatch(deleteMenuItem({ index: itemIndex, categoryIndex, itemId }));
      closeModal();

      dispatch(flagMenuSaved(true));
    } catch (e) {
      setErrorMessages(['There was an error deleting the item.']);
    }

    setDeleteLoading(false);
  }

  function onDeleteNewItem() {
    dispatch(deleteMenuItem({ index: itemIndex, categoryIndex, itemId: itemId || itemLocalId }));
    closeModal();
  }

  function onDeleteLocalImage() {
    updateMenuItemState({ image: { url: null, file: null } });
  }

  function onUpdateLocalImage(e) {
    const file = e.target.files[0];
    const url = window.URL.createObjectURL(file);

    const payload = { image: { url, file } };
    setFormData((prevFormData) => ({ ...prevFormData, ...payload }));
  }

  function onChangeAltText(e, { value }) {
    updateMenuItemState({ imageAltText: value });
  }

  function onVariationsChange(variations) {
    updateMenuItemState({ variations });
  }

  function handleChange(e, { name: currentName, value, checked, type }) {
    updateMenuItemState({ [currentName]: type === 'checkbox' ? checked : value });
  }

  function onUpdateWebsiteComponentInteractions(firstNonClickthroughInteractions) {
    const originalNonClickthroughInteractions = filterNonClickthruInteractions(interactions);
    const nonClickthroughInteractions = [
      ...firstNonClickthroughInteractions,
      ...originalNonClickthroughInteractions.slice(firstNonClickthroughInteractions.length),
    ];
    const clickthroughInteractions = filterClickthruInteractions(interactions);

    updateMenuItemState({
      interactions: [...nonClickthroughInteractions, ...clickthroughInteractions],
    });
  }

  function onUpdateFiles(newFiles = []) {
    const fileIds = newFiles.map((file) => (_isNumber(file) ? file : file.id));
    updateMenuItemState({ files: fileIds });
  }

  function onUpdateModifierSets(newModifierSets) {
    const modifierSetIds = newModifierSets.map((modifierSet) =>
      _isNumber(modifierSet) ? modifierSet : modifierSet.id,
    );
    updateMenuItemState({ modifierSets: modifierSetIds });
  }

  function onUpdateClickthruInteractions({ values: { interactions: updatedInteractions = [] } }) {
    const nonClickthroughInteractions = filterNonClickthruInteractions(interactions);

    updateMenuItemState({
      interactions: [...nonClickthroughInteractions, ...updatedInteractions],
    });
  }

  function onSeoMetadataChange(e, { name: fieldName, value }) {
    updateMenuItemState({ [fieldName]: value });
  }

  const { url: pdpUrl } = _head(filterClickthruInteractions(interactions)) || {};
  const areCtasEnabled = areCtasEnabledForRecipe(activePatch);
  const isClickTroughUrlEnabled = isClickTroughUrlEnabledForBusiness(business, activePatch);
  const isFeaturedEnabled = isFeaturedFeatureEnabled(activePatch);
  const isMultipleImagesEnabled = isMultipleImagesSupported(activePatch);
  const shouldShowDetailedDescription = isDetailedDescriptionEnabled(activePatch);
  const settingsOptions = getBusinessMenuSettings(business, SETTINGS_OPTIONS, {
    allowFeaturedSetting: isFeaturedEnabled,
  });

  const itemBusinessFiles = sortItemsByList(businessFiles, files || [], 'id');

  const showSeoMetadataField = isAdvancedSeoSupported(activePatch);

  return (
    <InventoryConsumer>
      {({ copies: { itemDescription } }) => (
        <EditMenuModal
          open={open}
          closeModal={() => {
            if (isNew) {
              onDeleteNewItem();
            }
            closeModal();
          }}
          className="edit-menu-schedule edit-menu-item"
          description={itemDescription}
          isNewItem={isNew}
          newItemTitle="Create Item"
          existingItemTitle="Edit Item"
          content={
            <>
              <h2>
                <span className="red asterisk">Name</span>
                <HelpTooltip
                  title="Item Name"
                  content="Enter the name of the Item that the Customer will order."
                />
              </h2>
              <Input
                name="name"
                defaultValue={name}
                placeholder="e.g. Turkey Club"
                onChange={handleChange}
              />
              <h2>
                {shouldShowDetailedDescription && 'Short '}Description
                <HelpTooltip
                  title={`${shouldShowDetailedDescription ? 'Short' : ''} Description`}
                  content={ITEM_SHORT_DESCRIPTION_TOOL_TIP}
                />
              </h2>
              <div className="item-description-ai-container">
                <CatalogObjectDescriptionInput
                  field={{
                    name: 'description',
                    placeholder: ITEM_SHORT_DESCRIPTION_PLACEHOLDER,
                  }}
                  catalogObject={{
                    name,
                    type: ITEM_CATALOG_OBJECT_TYPE,
                    parentName: category.name,
                    description,
                    prices: itemVariations,
                  }}
                  prompt={{
                    minOutputLength: ITEM_SHORT_DESCRIPTION_MIN_LENGTH,
                    maxOutputLength: ITEM_SHORT_DESCRIPTION_MAX_LENGTH,
                    additionalRequirements: ITEM_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS,
                  }}
                  onChange={handleChange}
                />
              </div>

              {shouldShowDetailedDescription && (
                <>
                  <h2>
                    Detailed Description
                    <HelpTooltip
                      title="Detailed Description"
                      content={ITEM_LONG_DESCRIPTION_TOOL_TIP}
                    />
                  </h2>
                  <div className="item-description-ai-container">
                    <CatalogObjectDescriptionInput
                      field={{
                        name: 'fullDescription',
                        placeholder: ITEM_LONG_DESCRIPTION_PLACEHOLDER,
                      }}
                      catalogObject={{
                        name,
                        type: ITEM_CATALOG_OBJECT_TYPE,
                        parentName: category.name,
                        description: fullDescription,
                        prices: itemVariations,
                      }}
                      prompt={{
                        minOutputLength: ITEM_LONG_DESCRIPTION_MIN_LENGTH,
                        maxOutputLength: ITEM_LONG_DESCRIPTION_MAX_LENGTH,
                        additionalRequirements: '',
                      }}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
              {isClickTroughUrlEnabled && (
                <ProductDescriptionPageWidget
                  value={pdpUrl}
                  onChange={onUpdateClickthruInteractions}
                />
              )}

              {!isMultipleImagesEnabled && (
                <>
                  <h2>
                    Image
                    <HelpTooltip
                      title="Item Image"
                      content="Optionally add an image for the Item. This will be shown to Customers when ordering."
                    />
                  </h2>
                  <ImagePlaceholderField
                    url={typeof image === 'object' && image !== null ? image.url : image}
                    enableDelete
                    onDelete={onDeleteLocalImage}
                    onChange={onUpdateLocalImage}
                  />
                  <ImageAltTextField
                    altText={imageAltText}
                    imageUrl={typeof image === 'object' && image !== null ? image.url : image}
                    onChangeAltText={onChangeAltText}
                    title={<Header>Image Alt Text</Header>}
                    fluid
                    disabled={isImageUrlEmpty(image)}
                  />
                </>
              )}

              {isMultipleImagesEnabled && (
                <>
                  <h2>
                    Images
                    <HelpTooltip
                      title="Images"
                      content="Optionally add images for the Item. If multiple images are selected, a carousel will be shown."
                    />
                  </h2>
                  <FileSelectionWell
                    files={itemBusinessFiles}
                    onUpdateFiles={onUpdateFiles}
                    uploadSourceType={SOURCE_TYPE_MENU}
                  />
                </>
              )}

              {areCtasEnabled && (
                <>
                  <h2>
                    Buttons
                    <HelpTooltip title="Buttons" content={BUTTONS_TOOLTIP_DESCRIPTION} />
                  </h2>
                  <WebsiteComponentInteractionContainer
                    interactions={filterNonClickthruInteractions(interactions).map(
                      objectToSnakeCase,
                    )}
                    onUpdate={onUpdateWebsiteComponentInteractions}
                  />
                </>
              )}
              <h2>
                Price and Variants
                <HelpTooltip
                  title="Item Price and Variants"
                  content="Set the price for the Item. If you wish to include variations of this item you may do so. Examples of variations are Small, Medium, Large which can all carry different Item pricing. The modifier pricing is the same across all variations."
                />
              </h2>
              <p>
                Variants are the different types of your item, like &apos;Small&apos; and
                &apos;Large&apos; and can have different prices
              </p>
              <MenuItemVariants variations={itemVariations || []} onChange={onVariationsChange} />
              <h2>
                Modifier Sets
                <HelpTooltip
                  title="Item Modifier Sets"
                  content="Assign or create new Modifier Sets to show the Customer when ordering this Item."
                />
              </h2>
              <MenuItemModifierSets
                itemIndex={itemIndex}
                item={formData}
                onItemModifierSetsChange={onUpdateModifierSets}
              />

              {!isNew && (
                <SettingsWidget data={formData} onChange={handleChange} options={settingsOptions} />
              )}

              {showSeoMetadataField && (
                <>
                  <h2>SEO Information</h2>
                  <SeoMetadataFields
                    onChange={onSeoMetadataChange}
                    pageType={ITEM_PAGE_TYPE}
                    header=""
                  >
                    <SeoMetadataFields.SeoTitle
                      showWarnings
                      title={seoTitle}
                      additionalRequirements={getTitleRequirements({ name })}
                      placeholder={createSeoTitlePlaceholder(name, businessName)}
                    />
                    <SeoMetadataFields.SeoDescription
                      showWarnings
                      description={seoDescription}
                      additionalRequirements={getDescriptionRequirements({ name, description })}
                      placeholder={description}
                    />
                  </SeoMetadataFields>
                </>
              )}
            </>
          }
          actions={
            <>
              {!_isEmpty(errorMessages) &&
                errorMessages.map((errorMessage) => (
                  <div key={errorMessage} className="menu-error-message">
                    {errorMessage}
                  </div>
                ))}
              <Button color={isNew ? null : 'red'} onClick={() => setDeleteConfirmationOpen(true)}>
                {isNew ? 'Cancel' : 'Delete'}
              </Button>
              <Confirm
                dimmer="inverted"
                className="delete-confirmation"
                content={`Are you sure you want to ${isNew ? 'cancel' : 'delete this item'}?`}
                confirmButton={{
                  content: `${isNew ? 'Confirm' : 'Delete'}`,
                  loading: deleteLoading,
                }}
                open={deleteConfirmationOpen}
                onCancel={() => setDeleteConfirmationOpen(false)}
                onConfirm={isNew ? onDeleteNewItem : onDeleteItem}
              />
              <Button
                className="action"
                onClick={isNew ? onSaveNewItem : onSaveItem}
                loading={saveLoading}
                content="Save"
              />
            </>
          }
        />
      )}
    </InventoryConsumer>
  );
}

EditMenuItem.propTypes = propTypes;
EditMenuItem.defaultProps = defaultProps;
