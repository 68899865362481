import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

import RecipeActionsItem from './RecipeActionsItem';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const RecipeActions = ({ children }) => {
  return (
    <Dropdown icon={<Icon name="ellipsis horizontal" size="large" />} floating direction="left">
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

RecipeActions.propTypes = propTypes;

RecipeActions.Item = RecipeActionsItem;

RecipeActions.defaultProps = defaultProps;

export default RecipeActions;
