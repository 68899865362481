import React, { useContext } from 'react';

import { Button, Form } from 'semantic-ui-react';

import { isValidHeroTileLayout } from '../../../../services/tile';
import { CustomHeroFormContext } from '../../CustomHeroForm.context';

import './TilesPerRow.scss';

const propTypes = {};

const defaultProps = {};

export default function TilesPerRow() {
  const { customHero, setLayout } = useContext(CustomHeroFormContext);
  const { tiles = [], tilesPerRow } = customHero;
  const numberOfTiles = tiles.length;

  const availableTilesPerRow = [1, 2];

  function onClick(newTilesPerRow) {
    setLayout({ tilesPerRow: newTilesPerRow, numberOfTiles });
  }

  return (
    <Form.Group className="number-of-tiles">
      <label htmlFor="number-of-tiles">Tiles per Row</label>
      <Button.Group id="number-of-tiles">
        {availableTilesPerRow.map((currentTilesPerRow) => (
          <Button
            key={currentTilesPerRow}
            active={tilesPerRow === currentTilesPerRow}
            onClick={() => onClick(currentTilesPerRow)}
            disabled={!isValidHeroTileLayout(numberOfTiles, currentTilesPerRow)}
          >
            {currentTilesPerRow}
          </Button>
        ))}
      </Button.Group>
    </Form.Group>
  );
}

TilesPerRow.propTypes = propTypes;
TilesPerRow.defaultProps = defaultProps;
