import React from 'react';

import PropTypes from 'prop-types';

import { DEFAULT_CONTENT, DEFAULT_HEADER } from './SaveChangesConfirm.constants';
import ConfirmModal from '../ConfirmModal';

import './SaveChangesConfirm.scss';

const propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

const defaultProps = {
  open: false,
};

export default function SaveChangesConfirm({ open, onCancel, onConfirm }) {
  return (
    <ConfirmModal
      header={DEFAULT_HEADER}
      content={DEFAULT_CONTENT}
      open={open}
      onCancel={onCancel}
      onConfirm={onConfirm}
      className="save-changes-confirm-modal"
      confirmButton="Yes"
      cancelButton="Cancel"
      size="tiny"
      closeOnEscape
      closeOnDimmerClick={false}
    />
  );
}

SaveChangesConfirm.propTypes = propTypes;
SaveChangesConfirm.defaultProps = defaultProps;
