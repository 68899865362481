import PropTypes from 'prop-types';

import {
  CATALOG_OBJECT_TYPE_CATEGORY,
  CATALOG_OBJECT_TYPE_ITEM,
  CATALOG_OBJECT_TYPE_TAX,
  CATALOG_OBJECT_TYPE_MODIFIER_LIST,
  CATALOG_OBJECT_TYPE_MODIFIER,
  SELECTION_TYPE_SINGLE,
  SELECTION_TYPE_MULTIPLE,
  CATALOG_OBJECT_TYPE_IMAGE,
  CATALOG_OBJECT_TYPE_ITEM_VARIATION,
  PRICING_TYPE_FIXED,
  PRICING_TYPE_VARIABLE,
} from './constants';

export const CatalogTaxPropType = PropTypes.shape({
  object_id: PropTypes.string,
  tax_data: PropTypes.shape({
    name: PropTypes.string,
    calculation_phase: PropTypes.oneOf(['SUBTOTAL', 'TOTAL']),
    inclusion_type: PropTypes.oneOf(['EXCLUSIVE', 'INCLUSIVE']),
    rate: PropTypes.string,
  }),
  version: PropTypes.number,
  type: PropTypes.oneOf([CATALOG_OBJECT_TYPE_TAX]),
});

export const CatalogItemPropType = PropTypes.shape({
  object_id: PropTypes.string,
  item_data: PropTypes.shape({
    category: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    taxes: PropTypes.arrayOf(PropTypes.string),
    modifier_lists: PropTypes.arrayOf(
      PropTypes.shape({
        modifier_list__object_id: PropTypes.string,
        min_selected_modifiers: PropTypes.number,
        max_selected_modifiers: PropTypes.number,
      }),
    ),
    name: PropTypes.string,
    description: PropTypes.string,
    ordinal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    available: PropTypes.bool,
  }),
  version: PropTypes.number,
  type: PropTypes.oneOf([CATALOG_OBJECT_TYPE_ITEM]),
});

export const FulfillmentDetails = {
  length: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  weight: PropTypes.string,
  virtual: PropTypes.bool,
  fragile: PropTypes.bool,
};

export const FulfillmentDetailsPropType = PropTypes.shape({
  ...FulfillmentDetails,
  distanceUnit: PropTypes.string,
  massUnit: PropTypes.string,
});

export const CatalogItemVariationPropType = PropTypes.shape({
  object_id: PropTypes.string,
  item_variation_data: PropTypes.shape({
    item: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    ordinal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pricing_type: PropTypes.oneOf([PRICING_TYPE_FIXED, PRICING_TYPE_VARIABLE]),
    price: PropTypes.string,
    // ...FulfillmentDetails,
    distance_unit: PropTypes.string,
    mass_unit: PropTypes.string,
  }),
  version: PropTypes.number,
  type: PropTypes.oneOf([CATALOG_OBJECT_TYPE_ITEM_VARIATION]),
});

export const CatalogCategoryPropType = PropTypes.shape({
  object_id: PropTypes.string,
  category_data: PropTypes.shape({
    parent_category: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    time_periods: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    description: PropTypes.string,
    ordinal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  version: PropTypes.number,
  type: PropTypes.oneOf([CATALOG_OBJECT_TYPE_CATEGORY]),
});

export const CatalogImagePropType = PropTypes.shape({
  object_id: PropTypes.string,
  image_data: PropTypes.shape({
    name: PropTypes.string,
    caption: PropTypes.string,
    url: PropTypes.string,
  }),
  version: PropTypes.number,
  type: PropTypes.oneOf([CATALOG_OBJECT_TYPE_IMAGE]),
});

export const CatalogModifierPropType = PropTypes.shape({
  object_id: PropTypes.string,
  modifier_data: PropTypes.shape({
    modifier_list: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    ordinal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  version: PropTypes.number,
  type: PropTypes.oneOf([CATALOG_OBJECT_TYPE_MODIFIER]),
});

export const CatalogModifierListPropType = PropTypes.shape({
  object_id: PropTypes.string,
  modifier_list_data: PropTypes.shape({
    name: PropTypes.string,
    selection_type: PropTypes.oneOf([SELECTION_TYPE_SINGLE, SELECTION_TYPE_MULTIPLE]),
    default_min_selected_modifiers: PropTypes.number,
    default_max_selected_modifiers: PropTypes.number,
  }),
  version: PropTypes.number,
  type: PropTypes.oneOf([CATALOG_OBJECT_TYPE_MODIFIER_LIST]),
});

export const CatalogItemModifierListInfoPropType = PropTypes.shape({
  item: PropTypes.string,
  modifier_list: PropTypes.string,
  min_selected_modifiers: PropTypes.number,
  max_selected_modifiers: PropTypes.number,
});

export const CatalogObjectGenericPropType = PropTypes.oneOfType([
  CatalogTaxPropType,
  CatalogItemPropType,
  CatalogItemVariationPropType,
  CatalogCategoryPropType,
  CatalogImagePropType,
  CatalogModifierPropType,
  CatalogModifierListPropType,
  CatalogItemModifierListInfoPropType,
]);
