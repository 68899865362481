import { ELEMENT_TYPE } from './types';

export const REQUIRED_TEXT_BLOCK_FIELDS = [
  'componentIdentifier',
  'componentConfigurationId',
  'content',
  'contentName',
  'contentNodeId',
];

export const REQUIRED_IMAGE_BLOCK_FIELDS = [
  'componentIdentifier',
  'componentConfigurationId',
  'widgetType',
  'images',
];

export const REQUIRED_LEGACY_FORM_BLOCK_FIELDS = ['title', 'fields'];

export const REQUIRED_EMPTY_BLOCK_FIELDS = [];

export const REQUIRED_JSON_BLOCK_FIELDS = ['snippet'];

export const REQUIRED_FORM_BLOCK_FIELDS = [
  'businessInfoId',
  'businessId',
  'websiteId',
  'locationId',
  'componentIdentifier',
  'componentConfigurationId',
  'header',
  'channel',
  'fields',
  'emailSubject',
];

export const REQUIRED_ELEMENT_FIELDS = {
  [ELEMENT_TYPE.TextBlock]: REQUIRED_TEXT_BLOCK_FIELDS,
  [ELEMENT_TYPE.ImageBlock]: REQUIRED_IMAGE_BLOCK_FIELDS,
  [ELEMENT_TYPE.FormBlock]: REQUIRED_LEGACY_FORM_BLOCK_FIELDS,
  [ELEMENT_TYPE.EmptyBlock]: REQUIRED_EMPTY_BLOCK_FIELDS,
  [ELEMENT_TYPE.JsonBlock]: REQUIRED_JSON_BLOCK_FIELDS,
  [ELEMENT_TYPE.CustomFormBlock]: REQUIRED_FORM_BLOCK_FIELDS,
};
