import React from 'react';

import PropTypes from 'prop-types';
import { Form, Checkbox } from 'semantic-ui-react';

import _includes from 'lodash/includes';

import { PAGE_LOCATION_OPTIONS } from '../../../../../../../../constants';
import { PagesSelectionPagesPropType } from '../../../../../../../../types';

import './PagesSelection.scss';

const propTypes = {
  onUpdatePages: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  pages: PropTypes.arrayOf(PagesSelectionPagesPropType).isRequired,
};

const defaultTypes = {};

export default function PagesSelection({ onUpdatePages, values, pages }) {
  return (
    <>
      <Form.Field key={PAGE_LOCATION_OPTIONS.Global}>
        <Checkbox
          label="Global"
          value={PAGE_LOCATION_OPTIONS.Global}
          defaultChecked={_includes(values, PAGE_LOCATION_OPTIONS.Global)}
          onClick={() => onUpdatePages(PAGE_LOCATION_OPTIONS.Global)}
        />
      </Form.Field>
      {pages.map(({ id, title }) => (
        <Form.Field key={id}>
          <Checkbox
            label={title}
            value={id}
            defaultChecked={_includes(values, id)}
            onClick={() => onUpdatePages(id)}
          />
        </Form.Field>
      ))}
    </>
  );
}

PagesSelection.propTypes = propTypes;
PagesSelection.defaultTypes = defaultTypes;
