import React from 'react';

import { Loader, Message } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import TaxesForm from './components/TaxesForm';
import API from '../../../../../libs/api';
import { selectBusiness, selectActiveProduct } from '../../../../../selectors';
import { CATALOG_OBJECT_TYPE_TAX } from '../constants';
import { useCatalogItemLibrary } from '../hooks';
import { filterCatalogObjectsByType } from '../utils';

export default function CatalogTaxes() {
  const { id: businessId, type: businessType } = useSelector(selectBusiness);
  const currentActiveProduct = useSelector(selectActiveProduct);

  const { catalog, loadingCatalog, fetchingCatalogErrorMessage, addItem, deleteItem } =
    useCatalogItemLibrary(businessType, businessId, currentActiveProduct.id);
  const taxes = filterCatalogObjectsByType(catalog, CATALOG_OBJECT_TYPE_TAX);

  async function handleSubmit({ taxesToSave, taxesToRemove }) {
    const savedTaxesResponses = await Promise.all(
      taxesToSave.map((taxToSave) =>
        API.saveCatalogObject(businessType, businessId, {
          ...taxToSave,
          merchant_product: currentActiveProduct.id,
        }),
      ),
    );
    await Promise.all(
      taxesToRemove.map((taxToRemove) =>
        API.removeCatalogObject(businessType, businessId, taxToRemove.object_id),
      ),
    );

    [...taxesToSave, ...taxesToRemove].forEach((tax) => deleteItem(tax));
    savedTaxesResponses.forEach(({ data: tax }) => addItem(tax));
  }

  if (loadingCatalog) {
    return <Loader active />;
  }

  if (fetchingCatalogErrorMessage) {
    return <Message error header={fetchingCatalogErrorMessage} />;
  }

  return (
    <section>
      <TaxesForm initialTaxes={taxes} onSubmit={handleSubmit} />
    </section>
  );
}
