export function createImageComponentConfiguration({
  businessFileConfigurationId,
  businessFileId,
  url = '',
  order = 1,
  metadata = { linkedUrl: undefined },
} = {}) {
  return {
    businessFileConfigurationId,
    businessFileId,
    url,
    order,
    metadata,
  };
}
