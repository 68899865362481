import PropTypes from 'prop-types';

export const reportsOrderPropTypes = PropTypes.shape({
  uuid: PropTypes.string.isRequired,
  payment_channel_customer: PropTypes.shape({}),
  fulfillment_type: PropTypes.string.isRequired,
  fulfillment_status: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  closed_at: PropTypes.string,
  total: PropTypes.string.isRequired,
});
