import React from 'react';

import { Table } from 'semantic-ui-react';

function OrderReportsTableHeader() {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Order #</Table.HeaderCell>
        <Table.HeaderCell>Details</Table.HeaderCell>
        <Table.HeaderCell>Customer</Table.HeaderCell>
        <Table.HeaderCell>Phone</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Ordered At</Table.HeaderCell>
        <Table.HeaderCell>Total</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}

export default OrderReportsTableHeader;
