import React from 'react';

import { Button, Icon, Table, Pagination } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import {
  FULFILLMENT_STATUS_RECEIVED,
  FULFILLMENT_TYPE_SHIPPING,
} from '../../../../../../../constants/fulfillments';
import { ORDER_STATUS_CLOSED } from '../../../../../../../constants/order-status';
import useMerchantProducts from '../../../../../../../hooks/useMerchantProducts';
import { selectActiveLocation } from '../../../../../../../selectors';
import LocationSelect from '../../../../common/LocationSelect/LocationSelect';
import OrderReportsContainer from '../../../../common/OrderReportsContainer';
import OrdersTable from '../PickupOrders/components/OrdersTable';

import './ShippingOrders.scss';

const propTypes = {};

const defaultProps = {};

function ShippingOrders() {
  const { id: locationId } = useSelector(selectActiveLocation);
  const {
    activeProduct: { id: productId },
    loading,
  } = useMerchantProducts();

  const filters = {
    product: productId,
    location: locationId,
    fulfillment_type: FULFILLMENT_TYPE_SHIPPING,
    fulfillment_status: FULFILLMENT_STATUS_RECEIVED,
    status: ORDER_STATUS_CLOSED,
  };

  function visitShippingURL(url) {
    window.open(url, '_blank');
  }

  return (
    <>
      <OrderReportsContainer initialFilters={filters} loadingFilters={loading}>
        {({ orders, currentPage, totalPages, onPageChange }) => (
          <>
            <LocationSelect global />

            <OrdersTable
              orders={orders}
              extraHeaders={
                <>
                  <Table.HeaderCell />
                </>
              }
              extraTableCells={[
                (order) => (
                  <Button
                    className="external-shipping-button"
                    disabled={!order.fulfillment.provider_url}
                    onClick={() => visitShippingURL(order.fulfillment.provider_url)}
                  >
                    <Icon name="truck" size="large" />
                  </Button>
                ),
              ]}
            />
            {totalPages > 1 && (
              <Pagination
                onPageChange={onPageChange}
                activePage={currentPage}
                totalPages={totalPages}
              />
            )}
          </>
        )}
      </OrderReportsContainer>
    </>
  );
}

ShippingOrders.propTypes = propTypes;
ShippingOrders.defaultProps = defaultProps;

export default ShippingOrders;
