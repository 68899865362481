import React from 'react';

import PropTypes from 'prop-types';
import { Segment, Container, Message, Button } from 'semantic-ui-react';

import {
  DEFAULT_AUTHORIZE_BUTTON_TEXT,
  DEFAULT_BODY_TEXT,
} from './FacebookIntegrationAuthorize.constants';
import { authRedirectFor } from '../../../../../../libs/auth';
import { BusinessPropType } from '../../../../../../libs/proptypes';

import './FacebookIntegrationAuthorize.scss';

const propTypes = {
  buttonText: PropTypes.string,
  description: PropTypes.string,
  business: BusinessPropType.isRequired,
};

const defaultProps = {
  buttonText: DEFAULT_AUTHORIZE_BUTTON_TEXT,
  description: DEFAULT_BODY_TEXT,
};

export default function FacebookIntegrationAuthorize({ buttonText, description, business }) {
  const { id: businessId, type: businessType } = business;

  function connect(event) {
    event.preventDefault();
    authRedirectFor('facebook', { queryParams: `?auth_params=${businessType}_${businessId}` });
  }

  return (
    <Segment className="facebook-integration-authorize">
      <Message>
        <Message.Header>Connect Fisherman to Facebook</Message.Header>
        <p>{description}</p>
      </Message>
      <Container textAlign="center">
        <Button size="big" primary onClick={connect} content={buttonText} />
      </Container>
    </Segment>
  );
}

FacebookIntegrationAuthorize.propTypes = propTypes;
FacebookIntegrationAuthorize.defaultProps = defaultProps;
