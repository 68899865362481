import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Segment, Container, Header, Button } from 'semantic-ui-react';

import {
  DEFAULT_BUTTON_TEXT,
  NO_INTEGRATION_DESCRIPTION,
} from './StripeConnectOnboardingStart.constants';
import API from '../../../../../../libs/api';
import ErrorHandler from '../../../../../../libs/errors/errors';
import { BusinessPropType } from '../../../../../../libs/proptypes';

import './StripeConnectOnboardingStart.scss';

const propTypes = {
  business: BusinessPropType.isRequired,
  description: PropTypes.string,
  buttonContent: PropTypes.string,
};

const defaultProps = {
  description: NO_INTEGRATION_DESCRIPTION,
  buttonContent: DEFAULT_BUTTON_TEXT,
};

export default function StripeConnectOnboardingStart({ business, description, buttonContent }) {
  const [loading, setLoading] = useState(false);

  const { id: businessId, type: businessType } = business;

  async function connect(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data } = await API.getStripeConnectLink(businessId, businessType);
      const { url } = data;
      window.location.href = url;
    } catch (error) {
      ErrorHandler.capture(error);
      setLoading(false);
    }
  }

  return (
    <Segment className="stripe-connect-onboarding-start" attached>
      <div>
        <Header as="h4">
          <b>Connect Fisherman to Stripe</b>
        </Header>
        <p>{description}</p>
      </div>
      <Container textAlign="center">
        <Button
          size="big"
          primary
          onClick={connect}
          content={buttonContent}
          loading={loading}
          disabled={loading}
        />
      </Container>
    </Segment>
  );
}

StripeConnectOnboardingStart.propTypes = propTypes;
StripeConnectOnboardingStart.defaultProps = defaultProps;
