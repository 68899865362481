import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import OrderReportsTableBody from './components/OrderReportsTableBody';
import { reportsOrderPropTypes } from './components/OrderReportsTableBody/OrderReportsTableBody.proptypes';
import OrderReportsTableFooter from './components/OrderReportsTableFooter';
import OrderReportsTableHeader from './components/OrderReportsTableHeader';

import './OrderReportsTable.scss';

const propTypes = {
  orders: PropTypes.arrayOf(reportsOrderPropTypes).isRequired,
  onPageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

const defaultProps = {};

function OrderReportsTable({ orders, onPageChange, currentPage, totalPages }) {
  return (
    <div className="order-reports-table">
      <Table compact celled singleLine>
        <OrderReportsTableHeader />
        <OrderReportsTableBody orders={orders} />
        <OrderReportsTableFooter
          onPageChange={onPageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Table>
    </div>
  );
}

OrderReportsTable.propTypes = propTypes;
OrderReportsTable.defaultProps = defaultProps;
export default OrderReportsTable;
