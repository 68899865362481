import React from 'react';

import PropTypes from 'prop-types';

import BusinessPlanBillingToggle from './components/BusinessPlanBillingToggle';
import BusinessPlanBody from './components/BusinessPlanBody';
import BusinessPlanBodyCard from './components/BusinessPlanBodyCard';
import BusinessPlanLine from './components/BusinessPlanLine';

export default function BusinessPlan(props) {
  const { children } = props;
  return <>{children}</>;
}

BusinessPlan.propTypes = {
  children: PropTypes.node.isRequired,
};

BusinessPlan.BusinessPlanLine = BusinessPlanLine;
BusinessPlan.BusinessPlanBody = BusinessPlanBody;
BusinessPlan.BusinessPlanBodyCard = BusinessPlanBodyCard;
BusinessPlan.BusinessPlanBillingToggle = BusinessPlanBillingToggle;
