import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'semantic-ui-react';

import { faDiagramNext, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { ElementOptionDropdownItem } from './components';

import './EditPageElementOptions.scss';

const propTypes = {
  onEditElementContent: PropTypes.func.isRequired,
  onEditElementColumn: PropTypes.func.isRequired,
  onEditElementRow: PropTypes.func.isRequired,
  onSplitColumn: PropTypes.shape({
    onAddColumnLeft: PropTypes.func.isRequired,
    onAddColumnRight: PropTypes.func.isRequired,
    onAddRowAbove: PropTypes.func.isRequired,
    onAddRowBelow: PropTypes.func.isRequired,
  }).isRequired,
  onDelete: PropTypes.shape({
    onDeleteColumn: PropTypes.func.isRequired,
    onDeleteRow: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.shape({
    onAddColumnLeft: PropTypes.bool,
    onAddColumnRight: PropTypes.bool,
    onAddRowAbove: PropTypes.bool,
    onAddRowBelow: PropTypes.bool,
    onDeleteColumn: PropTypes.bool,
    onDeleteRow: PropTypes.bool,
  }),
  loading: PropTypes.shape({
    onAddColumnLeft: PropTypes.bool,
    onAddColumnRight: PropTypes.bool,
    onAddRowAbove: PropTypes.bool,
    onAddRowBelow: PropTypes.bool,
    onDeleteColumn: PropTypes.bool,
    onDeleteRow: PropTypes.bool,
  }),
};

const defaultProps = {
  disabled: {
    onAddColumnLeft: false,
    onAddColumnRight: false,
    onAddRowAbove: false,
    onAddRowBelow: false,
    onDeleteColumn: false,
    onDeleteRow: false,
  },
  loading: {
    onAddColumnLeft: false,
    onAddColumnRight: false,
    onAddRowAbove: false,
    onAddRowBelow: false,
    onDeleteColumn: false,
    onDeleteRow: false,
  },
};

export default function EditPageElementOptions({
  onEditElementContent,
  onEditElementColumn,
  onEditElementRow,
  onSplitColumn,
  onDelete,
  disabled,
  loading,
}) {
  const { onAddColumnLeft, onAddColumnRight, onAddRowAbove, onAddRowBelow } = onSplitColumn;
  const { onDeleteColumn, onDeleteRow } = onDelete;
  const {
    onAddColumnLeft: disabledAddColumnLeft = false,
    onAddColumnRight: disabledAddColumnRight = false,
    onAddRowAbove: disabledAddRowAbove = false,
    onAddRowBelow: disabledAddRowBelow = false,
    onDeleteColumn: disabledDeleteColumn = false,
    onDeleteRow: disabledDeleteRow = false,
  } = disabled;
  const {
    onAddColumnLeft: loadingAddColumnLeft = false,
    onAddColumnRight: loadingAddColumnRight = false,
    onAddRowAbove: loadingAddRowAbove = false,
    onAddRowBelow: loadingAddRowBelow = false,
    onDeleteColumn: loadingDeleteColumn = false,
    onDeleteRow: loadingDeleteRow = false,
  } = loading;

  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [deleteMenuOpen, setDeleteMenuOpen] = useState(false);

  function createAddActionHandler(action) {
    return async () => {
      setAddMenuOpen(true);
      await action();
      setAddMenuOpen(false);
    };
  }

  function createDeleteActionHandler(action) {
    return async () => {
      setDeleteMenuOpen(true);
      await action();
      setDeleteMenuOpen(false);
    };
  }

  return (
    <Menu vertical className="edit-page-element-options">
      <Menu.Item onClick={onEditElementContent}>Edit content</Menu.Item>
      <Menu.Item onClick={onEditElementColumn}>Edit column settings</Menu.Item>
      <Menu.Item onClick={onEditElementRow}>Edit row settings</Menu.Item>
      <Dropdown item text="Add cell" className="add-actions">
        <Dropdown.Menu open={addMenuOpen}>
          <ElementOptionDropdownItem
            onClick={createAddActionHandler(onAddColumnLeft)}
            className="add-column-left"
            disabled={disabledAddColumnLeft}
            loading={loadingAddColumnLeft}
            icon={faDiagramNext}
            label="Add column to the left"
          />
          <ElementOptionDropdownItem
            onClick={createAddActionHandler(onAddColumnRight)}
            className="add-column-right"
            disabled={disabledAddColumnRight}
            loading={loadingAddColumnRight}
            icon={faDiagramNext}
            label="Add column to the right"
          />
          <ElementOptionDropdownItem
            onClick={createAddActionHandler(onAddRowAbove)}
            className="add-row-above"
            disabled={disabledAddRowAbove}
            loading={loadingAddRowAbove}
            icon={faDiagramNext}
            label="Add row above"
          />
          <ElementOptionDropdownItem
            onClick={createAddActionHandler(onAddRowBelow)}
            className="add-row-below"
            disabled={disabledAddRowBelow}
            loading={loadingAddRowBelow}
            icon={faDiagramNext}
            label="Add row below"
          />
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown item text="Delete cell" className="delete-actions">
        <Dropdown.Menu open={deleteMenuOpen}>
          <ElementOptionDropdownItem
            onClick={createDeleteActionHandler(onDeleteColumn)}
            className="delete-column"
            disabled={disabledDeleteColumn}
            loading={loadingDeleteColumn}
            icon={faTrashCan}
            label="Delete column"
          />
          <ElementOptionDropdownItem
            onClick={createDeleteActionHandler(onDeleteRow)}
            className="delete-row"
            disabled={disabledDeleteRow}
            loading={loadingDeleteRow}
            icon={faTrashCan}
            label="Delete entire row"
          />
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
}

EditPageElementOptions.propTypes = propTypes;
EditPageElementOptions.defaultProps = defaultProps;
