import React from 'react';

import { Table } from 'semantic-ui-react';

function NoOrdersRow() {
  return (
    <Table.Row>
      <Table.Cell colSpan="9" textAlign="center">
        No Orders
      </Table.Cell>
    </Table.Row>
  );
}

export default NoOrdersRow;
