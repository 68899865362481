import React, { Component } from 'react';

import { Button, Form, Modal, TextArea } from 'semantic-ui-react';

import CloseableModal from '../../../../common/CloseableModal';
import { RecipeContextConsumer } from '../context/RecipeContext';

class ModalInsertHere extends Component {
  constructor(props) {
    super(props);

    this.state = {
      json: '',
    };

    this.insert = this.insert.bind(this);
  }

  insert(ky, value, parentId, insertMethod) {
    const { close } = this.props;

    try {
      const json = JSON.parse(value);
      insertMethod(ky, json, parentId);
      close();
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Error parsing JSON');
    }
  }

  render() {
    const { ky, parentId, close, open } = this.props;
    const { json } = this.state;

    return (
      <CloseableModal
        open={open}
        className="developer-modal"
        header="Insert here..."
        onClose={close}
      >
        <Modal.Content>
          <Form>
            <TextArea
              defaultValue={json}
              onChange={(e) => this.setState({ json: e.target.value })}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <RecipeContextConsumer>
            {(value) => {
              const { insertKeyValueIntoObject } = value;
              return (
                <>
                  <Button
                    className="action"
                    content="Insert"
                    onClick={() => this.insert(ky, json, parentId, insertKeyValueIntoObject)}
                  />
                </>
              );
            }}
          </RecipeContextConsumer>
        </Modal.Actions>
      </CloseableModal>
    );
  }
}

export default ModalInsertHere;
