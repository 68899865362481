import React from 'react';

import { Loader, Message } from 'semantic-ui-react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import {
  ANALYTICS_NOT_AVAILABLE_MESSAGE,
  EMBED_TITLE,
  EMBED_FRAME_BORDER,
} from './WebsiteAnalytics.constants';
import useAnalytics from './WebsiteAnalytics.hooks';
import WebsiteAnalyticsActionBar from './components/WebsiteAnalyticsActionBar';

import './WebsiteAnalytics.scss';

const propTypes = {};

const defaultProps = [];

export default function WebsiteAnalytics() {
  const user = useSelector((state) => _get(state, 'user.core.value'));
  const websiteId = useSelector((state) => _get(state, 'website.core.value.id'));
  const domains = useSelector((state) => _get(state, 'website.domains.value', []));
  const stackStatus = useSelector((state) => _get(state, 'website.core.value.stack_status'));

  const website = {
    id: websiteId,
    stackStatus,
    domains,
  };

  const { embedUrl, loaded, available, reason } = useAnalytics(website, user);

  if (!loaded) {
    return <Loader active inline="centered" />;
  }

  if (!available) {
    return <Message header={ANALYTICS_NOT_AVAILABLE_MESSAGE} content={reason} />;
  }

  return (
    <div className="website-analytics">
      <iframe title={EMBED_TITLE} src={embedUrl} frameBorder={EMBED_FRAME_BORDER} />
      <WebsiteAnalyticsActionBar show={loaded} />
    </div>
  );
}

WebsiteAnalytics.propTypes = propTypes;
WebsiteAnalytics.defaultProps = defaultProps;
