import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import { createTableRows } from './TeamMemberServicesTable.utils';
import TeamMemberServicesTableRow from './components/TeamMemberServicesTableRow';
import {
  selectBusinessCatalogCategories,
  selectBusinessCatalogItems,
} from '../../../../../../../selectors/catalog';
import {
  getCategoriesWithItems,
  getItemsForVariationIds,
} from '../../../../../catalog/services/catalog';
import { TeamMemberServiceVariationPropType } from '../../../types/team-member-service';

import './TeamMemberServicesTable.scss';

const propTypes = {
  services: PropTypes.arrayOf(TeamMemberServiceVariationPropType).isRequired,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

const defaultProps = {
  onDelete: () => null,
  onUpdate: () => null,
};

export default function TeamMemberServicesTable({
  services: teamMemberServiceItems,
  onDelete,
  onUpdate,
}) {
  const allCategories = useSelector(selectBusinessCatalogCategories);
  const allItems = useSelector(selectBusinessCatalogItems);

  if (_isEmpty(teamMemberServiceItems)) {
    return null;
  }

  const variationIds = teamMemberServiceItems.map(({ itemVariation }) => itemVariation);
  const items = getItemsForVariationIds(allItems, variationIds);
  const categoriesWithItems = getCategoriesWithItems(allCategories, items);
  const tableRows = createTableRows(teamMemberServiceItems, categoriesWithItems);

  return (
    <Table celled structured>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={3}>Category</Table.HeaderCell>
          <Table.HeaderCell width={3}>Name</Table.HeaderCell>
          <Table.HeaderCell width={3}>Variation</Table.HeaderCell>
          <Table.HeaderCell width={3}>Price Type</Table.HeaderCell>
          {!_isNil(onUpdate) && <Table.HeaderCell width={3}>Price</Table.HeaderCell>}
          {!_isNil(onDelete) && <Table.HeaderCell width={1} />}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {tableRows.map((row) => (
          <TeamMemberServicesTableRow
            key={row.id || row.itemVariation}
            row={row}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        ))}
      </Table.Body>
    </Table>
  );
}

TeamMemberServicesTable.propTypes = propTypes;
TeamMemberServicesTable.defaultProps = defaultProps;
