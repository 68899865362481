import { useState } from 'react';

import useAsyncEffect from '../../components/modules/foundation/hooks/use-async-effect';
import API from '../../libs/api';

function extractPrimaryColors(palettes) {
  return palettes.reduce((primaryColors, palette) => {
    const { id: paletteId, colors } = palette;

    const palettePrimaryColor = colors.find((color) => color.role === 'primaryColor');

    if (palettePrimaryColor) {
      return [...primaryColors, { ...palettePrimaryColor, paletteId }];
    }

    return primaryColors;
  }, []);
}

export default function useColorPalettes(websiteId = null, options = {}) {
  const { recipeName } = options;
  const [palettes, setPalettes] = useState([]);
  const [primaryColors, setPrimaryColors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [palettesParadigms, setPalettesParadigms] = useState([]);

  function generatePalette({ red, green, blue, name, paradigm, website }) {
    return API.generatePalette({ red, green, blue, name, paradigm, website }).then((response) => {
      setPalettes((prevPalettes) => [...prevPalettes, response.data]);
      return response;
    });
  }

  useAsyncEffect(async () => {
    setIsLoading(true);

    const colorPaletteParams = { recipeName };

    let colorPalettesPromise = API.getColorPalettes(colorPaletteParams);
    if (websiteId) {
      colorPalettesPromise = API.getWebsiteColorPalettes(websiteId, colorPaletteParams);
    }

    const paletteParadigmsPromise = API.getPaletteParadigms();

    Promise.all([colorPalettesPromise, paletteParadigmsPromise]).then(
      ([colorPaletteResponse, paletteParadigmsResponse]) => {
        const { data: palettesDataResponse } = colorPaletteResponse;
        const { data: paradigmsDataResponse } = paletteParadigmsResponse;

        setPalettes(palettesDataResponse);
        setPalettesParadigms(paradigmsDataResponse);
        setPrimaryColors(extractPrimaryColors(palettesDataResponse));
        setIsLoading(false);
      },
    );
  }, [recipeName]);

  return { palettes, primaryColors, isLoading, palettesParadigms, generatePalette };
}
