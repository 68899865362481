import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { sortTeamByIndex, updateTeamsOrder } from './TeamsTable.utils';
import TeamsTableRow from './components/TeamsTableRow/TeamsTableRow';
import ErrorHandler from '../../../../../../../libs/errors/errors';
import { onTableRowSortingStart } from '../../../../../../../libs/sorting';
import { TeamsContext } from '../../../../contexts/Teams.context';
import useTeam from '../../../../hooks/use-team';
import { TeamPropType } from '../../../types/team';

import './TeamsTable.scss';

const propTypes = {
  teams: PropTypes.arrayOf(TeamPropType),
};

const defaultProps = {
  teams: null,
};

const SortableTableContainer = SortableContainer(({ children }) => {
  return children;
});

const SortableTableRow = SortableElement(({ children }) => {
  return children;
});

export default function TeamsTable({ teams: teamsProp }) {
  const { teams: contextTeams } = useContext(TeamsContext);
  const { updateTeam, setTeams } = useTeam();

  const [loading, setLoading] = useState(false);

  const teams = teamsProp || contextTeams || [];

  if (_isEmpty(teams)) {
    return null;
  }

  async function onSortTeam({ oldIndex, newIndex }) {
    const sortedTeam = sortTeamByIndex(teams, oldIndex, newIndex);
    setTeams(sortedTeam);

    try {
      setLoading(true);
      await updateTeamsOrder(sortedTeam, updateTeam);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="teams-table-container">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Name</Table.HeaderCell>
            <Table.HeaderCell width={12}>Description</Table.HeaderCell>
            <Table.HeaderCell width={1} />
          </Table.Row>
        </Table.Header>

        <SortableTableContainer
          useDragHandle
          onSortStart={onTableRowSortingStart}
          onSortEnd={onSortTeam}
          helperClass="table-row-sorting"
        >
          <Table.Body>
            {teams.map((team, index) => {
              return (
                <SortableTableRow key={team.id} index={index}>
                  <TeamsTableRow key={team.id} team={team} loading={loading} />
                </SortableTableRow>
              );
            })}
          </Table.Body>
        </SortableTableContainer>
      </Table>
    </div>
  );
}

TeamsTable.propTypes = propTypes;
TeamsTable.defaultProps = defaultProps;
