import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import clsx from 'clsx';

import useSemanticUiProps from '../../../../../hooks/useSemanticUiProps';
import { ChildrenPropType } from '../../../../../libs/proptypes';

import './DismissibleMessage.scss';

const propTypes = {
  initialVisible: PropTypes.bool,
  delay: PropTypes.number, // in seconds
  children: ChildrenPropType,
  onDismiss: PropTypes.func,
};

const defaultProps = {
  initialVisible: false,
  delay: null,
  children: null,
  onDismiss: () => null,
};

export default function DismissibleMessage({
  initialVisible,
  delay,
  children,
  onDismiss: onDismissProp,
  ...rest
}) {
  const [visible, setVisible] = useState(initialVisible);
  const { getMessageProps } = useSemanticUiProps();

  function onDismiss() {
    setVisible(false);
    if (onDismissProp) {
      onDismissProp();
    }
  }

  function delayOnDismiss() {
    if (!delay) {
      return;
    }

    setTimeout(onDismiss, delay * 1000);
  }

  useEffect(delayOnDismiss, []);

  if (!visible) {
    return null;
  }

  const { className } = rest;
  const finalClassName = clsx(['dismissible-message', className]);

  return (
    <Message onDismiss={onDismiss} {...getMessageProps(rest)} className={finalClassName}>
      {children}
    </Message>
  );
}

DismissibleMessage.propTypes = propTypes;
DismissibleMessage.defaultProps = defaultProps;
