import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { FORM_FIELD_PLACEHOLDER_PLACEHOLDER } from './FormFieldPlaceholder.constants';

import './FormFieldPlaceholder.scss';

const propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  placeholder: '',
};

export default function FormFieldPlaceholder({ placeholder, onChange }) {
  return (
    <div className="form-field-placeholder">
      <label htmlFor="form-field-placeholder-input">Placeholder (Optional)</label>
      <Input
        id="form-field-placeholder-input"
        fluid
        value={placeholder}
        onChange={(e, { value }) => onChange(value)}
        placeholder={FORM_FIELD_PLACEHOLDER_PLACEHOLDER}
      />
    </div>
  );
}

FormFieldPlaceholder.propTypes = propTypes;
FormFieldPlaceholder.defaultProps = defaultProps;
