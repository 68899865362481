import React from 'react';

import TeamMembersTable from '../../../../modules/team/components/TeamMembersTable';

import './TeamMembersTab.scss';

const propTypes = {};

const defaultProps = {};

export default function TeamMembersTab() {
  return (
    <div className="team-member-tab">
      <TeamMembersTable />
    </div>
  );
}

TeamMembersTab.propTypes = propTypes;
TeamMembersTab.defaultProps = defaultProps;
