import React from 'react';

import { Pagination } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import { getPickupOrderFilters } from './PickupOrders.utils';
import OrdersTable from './components/OrdersTable';
import useMerchantProducts from '../../../../../../../hooks/useMerchantProducts';
import { selectActiveLocation } from '../../../../../../../selectors';
import LocationSelect from '../../../../common/LocationSelect/LocationSelect';
import OrderReportsContainer from '../../../../common/OrderReportsContainer';

const propTypes = {};

const defaultProps = {};

function PickupOrders() {
  const { id: locationId } = useSelector(selectActiveLocation);
  const {
    activeProduct: { id: productId },
    loading,
  } = useMerchantProducts();

  const initialFilters = getPickupOrderFilters({ productId, locationId });

  return (
    <OrderReportsContainer initialFilters={initialFilters} loadingFilters={loading}>
      {({ orders, currentPage, totalPages, onPageChange }) => {
        return (
          <>
            <LocationSelect global />

            <OrdersTable orders={orders} />
            {totalPages > 1 && (
              <Pagination
                onPageChange={onPageChange}
                activePage={currentPage}
                totalPages={totalPages}
              />
            )}
          </>
        );
      }}
    </OrderReportsContainer>
  );
}

PickupOrders.propTypes = propTypes;
PickupOrders.defaultProps = defaultProps;

export default PickupOrders;
