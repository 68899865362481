import React, { useState, useMemo } from 'react';

import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { DEFAULT_VARIANT } from './FontVariantText.constants';
import { ChildrenPropType } from '../../../../../libs/proptypes';
import {
  getFontURL,
  getFontFamily,
  getFontVariantWeight,
  getFontVariantStyle,
} from '../../services/font';

import './FontVariantText.scss';

const propTypes = {
  text: PropTypes.string,
  family: PropTypes.string.isRequired,
  variant: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: ChildrenPropType,
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
const defaultProps = {
  text: '',
  variant: DEFAULT_VARIANT,
  size: '16px',
  children: null,
  lineHeight: 'initial',
};

export default function FontVariantText({ text, family, variant, size, lineHeight, children }) {
  const [loaded, setLoaded] = useState(false);

  const styles = {
    fontFamily: `'${getFontFamily(family)}'`,
    fontSize: size,
    lineHeight,
    fontWeight: getFontVariantWeight(variant),
    fontStyle: getFontVariantStyle(variant),
  };

  const content = children || text || family || '';

  if (_isEmpty(content)) {
    return null;
  }

  function onStyleLoaded() {
    setLoaded(true);
  }

  const cachedScript = useMemo(() => {
    const googleFontUrl = getFontURL(family, { variant, text: '' });
    return (
      <link id={`load-${family}`} rel="stylesheet" href={googleFontUrl} onLoad={onStyleLoaded} />
    );
  }, [family, variant]);

  return (
    <div className="font-variant-text">
      {cachedScript}
      {loaded ? <span style={styles}>{content}</span> : <Loader active inline="centered" />}
    </div>
  );
}

FontVariantText.propTypes = propTypes;
FontVariantText.defaultProps = defaultProps;
