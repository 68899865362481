import React, { useContext, useEffect, useRef } from 'react';

import { Grid } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  getFinalFontWithWeights,
  getFontsForGoogleFontRequest,
  getWeightsForGoogleFontRequest,
} from './SelectedTypography.utils';
import Column from './components/Column';
import { selectWebsitePatches, selectActiveRecipe } from '../../../../../selectors/website';
import useStyleRule from '../../../style-rules/hooks/useStyleRule';
import { TypographyContext } from '../../contexts/Typography.context';
import {
  getRecipeDefaultHeaderFont,
  getRecipeDefaultBodyFont,
  getGoogleFontRequest,
} from '../../services/font';
import ApplyFontButton from '../ApplyFontButton/ApplyFontButton';

import './SelectedTypography.scss';

const propTypes = {};

const defaultProps = {};

export default function SelectedTypography() {
  const ref = useRef(null);

  const { selectedFont } = useContext(TypographyContext);
  const selectedFamily = _get(selectedFont, 'family', '');
  const selectedWeights = _get(selectedFont, 'selectedWeights', []);
  const patches = useSelector(selectWebsitePatches);
  const { styleRulesConfiguration } = selectActiveRecipe(patches);

  const { value: headerFont } = useStyleRule('headerFont');
  const { value: pageFont } = useStyleRule('pageFont');
  const { value: googleFontRequest, setValue: setGoogleFontRequest } =
    useStyleRule('googleFontRequest');

  const defaultHeaderFont = getRecipeDefaultHeaderFont(styleRulesConfiguration);
  const defaultPageFont = getRecipeDefaultBodyFont(styleRulesConfiguration);

  function saveGoogleFontRequest(newRequest) {
    if (_isEmpty(newRequest) || _isEmpty(selectedWeights)) {
      return;
    }
    setGoogleFontRequest(newRequest);
  }

  function onApply({ ruleName, family }) {
    const isHeader = ruleName === 'headerFont';

    const [finalHeaderFont, finalPageFont] = getFontsForGoogleFontRequest(
      isHeader,
      family,
      headerFont,
      pageFont,
      defaultHeaderFont,
      defaultPageFont,
    );

    const [headerWeights, pageWeights] = getWeightsForGoogleFontRequest(
      isHeader,
      selectedWeights,
      finalHeaderFont,
      finalPageFont,
      googleFontRequest,
    );

    const newRequest = getGoogleFontRequest(
      { name: finalHeaderFont, selectedWeights: headerWeights },
      { name: finalPageFont, selectedWeights: pageWeights },
    );
    saveGoogleFontRequest(newRequest);
  }

  useEffect(() => {
    if (!_isEmpty(selectedFamily)) {
      ref.current.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' });
    }
  }, [selectedFamily]);

  const finalHeaderFont = getFinalFontWithWeights(headerFont, defaultHeaderFont, googleFontRequest);
  const finalPageFont = getFinalFontWithWeights(pageFont, defaultPageFont, googleFontRequest);

  return (
    <div className="selected-typography" ref={ref}>
      <Grid columns={2}>
        <Column
          text="Header"
          font={finalHeaderFont}
          extra={<ApplyFontButton rule="headerFont" onApply={onApply} />}
        />
        <Column
          text="Body text"
          font={finalPageFont}
          extra={<ApplyFontButton rule="pageFont" onApply={onApply} />}
        />
      </Grid>
    </div>
  );
}

SelectedTypography.propTypes = propTypes;
SelectedTypography.defaultProps = defaultProps;
