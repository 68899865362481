import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import {
  DEFAULT_EXTERNAL_ANCHOR_REL,
  DEFAULT_EXTERNAL_ANCHOR_TARGET,
} from '../../../../../constants/constants';
import { getWebsiteUrl } from '../../../../../libs/website';
import { selectBusiness } from '../../../../../selectors/business';
import {
  selectWebsite,
  selectDomains,
  selectIsNextWebsite,
} from '../../../../../selectors/website';

import './PreviewWebsiteButton.scss';

const propTypes = {};

const defaultProps = {};

export default function PreviewWebsiteButton() {
  const business = useSelector(selectBusiness);
  const website = useSelector(selectWebsite);
  const domains = useSelector(selectDomains);
  const isNextWebsite = useSelector(selectIsNextWebsite);
  const url = getWebsiteUrl(business, website, domains, isNextWebsite);

  if (!url) {
    return null;
  }

  return (
    <Button
      as="a"
      href={url}
      target={DEFAULT_EXTERNAL_ANCHOR_TARGET}
      rel={DEFAULT_EXTERNAL_ANCHOR_REL}
      className="preview-website-button"
    >
      <Icon name="caret square right outline" />
      View Website
    </Button>
  );
}

PreviewWebsiteButton.propTypes = propTypes;
PreviewWebsiteButton.defaultProps = defaultProps;
