import * as Sentry from '@sentry/react';
import _isEmpty from 'lodash/isEmpty';

import LogRocket from './logrocket/logrocket';

function getTracesSampleRate(environment) {
  if (environment === 'production') {
    return 0.1;
  }
  if (environment === 'staging') {
    return 0.2;
  }
  return 1.0;
}

const tracingOrigins = [
  'api.gofisherman.com',
  'apitest.gofisherman.com',
  'api-sandbox.gofisherman.com',
  /^\//,
];

export function isSentryEnabled() {
  const environment = process.env.REACT_APP_FISHERMAN_ENV;
  return ['staging', 'production', 'sandbox'].includes(environment);
}

function setLogRocketInEvent(event) {
  const logRocketSession = LogRocket.sessionUrl();
  if (!_isEmpty(logRocketSession)) {
    // eslint-disable-next-line no-param-reassign
    event.extra.LogRocket = logRocketSession;
  }
  return event;
}

export default function initSentry() {
  const environment = process.env.REACT_APP_FISHERMAN_ENV;
  const sentryEnabled = isSentryEnabled();

  if (sentryEnabled) {
    Sentry.init({
      environment,
      dsn: 'https://c1b3c64ad1bf4d32b287b309675059fa@o270104.ingest.sentry.io/5706553',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: true,
        }),
      ],
      beforeSend(event) {
        return setLogRocketInEvent(event);
      },
      tracesSampleRate: getTracesSampleRate(environment),
      tracePropagationTargets: tracingOrigins,
    });
  }
}
