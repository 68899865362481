import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import FileUploadManager from 'components/modules/files/components/FileUploadManager';
import StockImagesPicker from 'components/modules/files/components/StockImagesPicker';

import { FileManagementModalStep } from './FilesManagementModal.constants';
import { FilesManagementModalProvider } from './FilesManagementModal.context';
import { filterFilesBySourceTag } from './FilesManagementModal.utils';
import BackButton from './components/BackButton';
import { SaveSelection, SaveSelectionHeader } from './components/SaveSelection';
import { StockSearchHeader } from './components/StockSearchHeader';
import CloseableModal from '../../common/CloseableModal';

import './FilesManagementModal.scss';

const propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onUpload: PropTypes.func,
  onSubmit: PropTypes.func,
  onFileClick: PropTypes.func.isRequired,
  onFileEdit: PropTypes.func,
  onFileSearch: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  searchValue: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  minFilesAllowed: PropTypes.number,
  maxFilesAllowed: PropTypes.number,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  isSubmitting: PropTypes.bool,
  setError: PropTypes.func,
  error: PropTypes.string,
  actionButtonText: PropTypes.string,
};

const defaultProps = {
  open: false,
  isLoading: false,
  onClose: () => {},
  onUpload: () => {},
  onSubmit: () => {},
  onFileEdit: null,
  searchValue: '',
  files: [],
  minFilesAllowed: null,
  maxFilesAllowed: null,
  selectedFiles: [],
  isSubmitting: false,
  setError: null,
  error: null,
  actionButtonText: 'Save',
};

export default function FilesManagementModal({
  open,
  isLoading,
  onClose,
  onUpload,
  onSubmit,
  onFileClick,
  onFileEdit,
  onFileSearch,
  isSearching,
  searchValue,
  files,
  minFilesAllowed,
  maxFilesAllowed,
  selectedFiles,
  isSubmitting,
  setError,
  error,
  actionButtonText,
}) {
  const { advancedImageEditing } = useFlags();

  const uploadInput = useRef(null);
  const [sourceFilter, setSourceFilter] = useState('All');
  const [searchActivated, setSearchActivated] = useState(false);
  const [activeTab, setActiveTab] = useState(FileManagementModalStep.IMAGE_SELECT);

  function onSourceFilterChange(event, { value }) {
    setSourceFilter(value);
  }

  function onSearchFocus() {
    setSearchActivated(true);
  }

  function onSearchBlur() {
    setSearchActivated(false);
  }

  function onUploadFiles() {
    setActiveTab(FileManagementModalStep.FILE_UPLOAD);
  }

  function onSelectFiles() {
    setActiveTab(FileManagementModalStep.IMAGE_SELECT);
  }

  const [selectedImageSource, setSelectedImageSource] = useState('');

  const disabledButtons = isSubmitting || isLoading;
  const filteredFiles = filterFilesBySourceTag(files, sourceFilter);

  const saveSelectionHeader = (
    <SaveSelectionHeader error={error} onUpload={onUpload} onUploadFiles={onUploadFiles} />
  );
  const saveSelectionBody = (
    <SaveSelection
      searchValue={searchValue}
      isSearching={isSearching}
      isLoading={isLoading}
      onFileSearch={onFileSearch}
      onUpload={onUpload}
      disabledButtons={disabledButtons}
      setError={setError}
      error={error}
      files={filteredFiles}
      onFileClick={onFileClick}
      onFileEdit={onFileEdit}
      minFilesAllowed={minFilesAllowed}
      maxFilesAllowed={maxFilesAllowed}
      selectedFiles={selectedFiles}
      showSourceTags={sourceFilter === 'All'}
      showFileNames={searchActivated}
      onSearchFocus={onSearchFocus}
      onSearchBlur={onSearchBlur}
      sourceFilter={sourceFilter}
      onSourceFilterChange={onSourceFilterChange}
    />
  );

  const stockSearchHeader = <StockSearchHeader setSelectedImageSource={setSelectedImageSource} />;
  const stockSearchBody = (
    <StockImagesPicker
      onPostSave={async () => {
        await onFileSearch();
        setSelectedImageSource(FileManagementModalStep.IMAGE_SELECT);
      }}
    />
  );

  const fileUploadManagerHeader = (
    <div className="file-upload-manager-header">
      <BackButton onClick={onSelectFiles} />
      <span>Upload Files</span>
    </div>
  );
  const fileUploadManagerBody = (
    <div className="file-upload-manager-container">
      <FileUploadManager onPostUpload={onSelectFiles} />
    </div>
  );

  function getContentHeaderAndBody() {
    const initialHeaderAndBody = [saveSelectionHeader, saveSelectionBody, true];

    if (advancedImageEditing && activeTab === FileManagementModalStep.FILE_UPLOAD) {
      return [fileUploadManagerHeader, fileUploadManagerBody, false];
    }
    if (selectedImageSource === FileManagementModalStep.STOCK_IMAGE_SEARCH) {
      return [stockSearchHeader, stockSearchBody, false];
    }

    return initialHeaderAndBody;
  }

  function handleClose() {
    setSelectedImageSource(FileManagementModalStep.IMAGE_SELECT);
    setActiveTab(FileManagementModalStep.IMAGE_SELECT);
    onClose();
  }

  const [header, body, displayActions] = getContentHeaderAndBody();

  const context = {
    selectedImageSource,
    setSelectedImageSource,
    uploadInput,
  };

  return (
    <FilesManagementModalProvider value={context}>
      <CloseableModal
        size={activeTab === FileManagementModalStep.FILE_UPLOAD ? 'fullscreen' : 'large'}
        className="file-management-modal"
        onClose={handleClose}
        open={open}
        header={header}
      >
        <Modal.Content scrolling>{body}</Modal.Content>
        {displayActions && (
          <Modal.Actions>
            <Button
              content={actionButtonText}
              loading={isSubmitting}
              disabled={disabledButtons}
              className="action"
              onClick={onSubmit}
            />
          </Modal.Actions>
        )}
      </CloseableModal>
    </FilesManagementModalProvider>
  );
}

FilesManagementModal.propTypes = propTypes;
FilesManagementModal.defaultProps = defaultProps;
