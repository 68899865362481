import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';

export function getTitleRequirements({ name } = {}) {
  return `Ensure you mention the item name: ${name}.`;
}

export function getDescriptionRequirements({ name, description } = {}) {
  return `Keep in mind that the category item name is: ${name}. And its description is: ${description}.`;
}

export function isImageUrlEmpty(image) {
  if (_isObject(image) && !_isNil(image)) {
    return _isEmpty(image?.url);
  }
  return _isEmpty(image);
}
