import React, { useState, useContext } from 'react';

import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'semantic-ui-react';

import { CREATE_TEMPLATE_ERROR_HEADER } from './CreateCustomPageTemplateModal.constants';
import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors';
import CloseableModal from '../../../../common/CloseableModal';
import { DismissibleMessage } from '../../../utils/components';
import { CustomPagesContext } from '../../contexts/CustomPages.context';
import { useCustomPageTemplates } from '../../hooks';

import './CreateCustomPageTemplateModal.scss';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function CreateCustomPageTemplateModal({ open, onClose }) {
  const { createTemplatePage } = useContext(CustomPagesContext);
  const { createCustomPageTemplate } = useCustomPageTemplates();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');

  function createChangeHandler(stateFunc) {
    return (e, { value }) => stateFunc(value);
  }

  function resetFormValues() {
    setLoading(false);
    setErrorMessage('');
    setTemplateName('');
    setTemplateDescription('');
  }

  function onCloseInternal() {
    resetFormValues();
    onClose();
  }

  async function onSave() {
    try {
      setLoading(true);

      const templateNode = createTemplatePage();
      const options = {
        name: templateName,
        description: templateDescription,
        node: templateNode,
      };
      await createCustomPageTemplate(options);

      onClose();
    } catch (e) {
      ErrorHandler.capture(e);
      setErrorMessage(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }

  const saveDisabled = !templateName || !templateDescription;

  return (
    <CloseableModal
      className="create-custom-page-template-modal"
      open={open}
      onClose={onCloseInternal}
      onOpen={resetFormValues}
      size="tiny"
      header="Create template"
    >
      <Modal.Content>
        <Form>
          <Form.Input
            label="Name"
            value={templateName}
            placeholder="e.g Catering Page"
            onChange={createChangeHandler(setTemplateName)}
          />
          <Form.TextArea
            label="Description"
            value={templateDescription}
            placeholder="e.g A catering page with a simple contact form."
            onChange={createChangeHandler(setTemplateDescription)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {errorMessage && (
          <DismissibleMessage
            initialVisible
            delay={8}
            error
            header={CREATE_TEMPLATE_ERROR_HEADER}
            content={errorMessage}
          />
        )}
        <Button className="cancel" basic onClick={onCloseInternal}>
          Cancel
        </Button>
        <Button className="save" basic onClick={onSave} disabled={saveDisabled} loading={loading}>
          Save
        </Button>
      </Modal.Actions>
    </CloseableModal>
  );
}

CreateCustomPageTemplateModal.propTypes = propTypes;
CreateCustomPageTemplateModal.defaultProps = defaultProps;
