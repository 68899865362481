import React, { useState } from 'react';

import { Accordion, Header, Loader } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import LocationSetting from './components/LocationSetting/LocationSetting';
import useMerchantProducts from '../../../../../hooks/useMerchantProducts';
import API from '../../../../../libs/api';
import { getLocationDisplay } from '../../../../../libs/location';
import { selectBusinessLocations } from '../../../../../selectors';
import useAsyncEffect from '../../../../modules/foundation/hooks/use-async-effect';
import OrderSettingsContext from '../OrderSettings.context';
import { useOrderSettings } from '../OrderSettings.hooks';

export default function LocationSettings() {
  const locations = useSelector(selectBusinessLocations);

  const [isLoadingSettings, setIsLoadingSettings] = useState(true);

  const { activeProduct, loading: loadingProducts } = useMerchantProducts();
  const { settings, setSettings, updateSettings, getSettingsForLocation, setMerchantProduct } =
    useOrderSettings(activeProduct);

  useAsyncEffect(async () => {
    setMerchantProduct(activeProduct);
    if (activeProduct && activeProduct.id) {
      setIsLoadingSettings(true);
      try {
        const { data } = await API.getOrderSettings({ product: activeProduct.id });
        setSettings(data);
      } catch (e) {
        setSettings([]);
      } finally {
        setIsLoadingSettings(false);
      }
    }
  }, [activeProduct]);

  if (loadingProducts || isLoadingSettings) {
    return <Loader active />;
  }

  return (
    <>
      <Header as="h4">Set your pickup hours for your locations.</Header>
      <OrderSettingsContext.Provider value={{ settings, updateSettings, getSettingsForLocation }}>
        <Accordion fluid>
          {locations.map((location, index) => (
            <LocationSetting
              title={getLocationDisplay(location)}
              key={`${location.id || location.localId}`}
              locationIndex={index}
            />
          ))}
        </Accordion>
      </OrderSettingsContext.Provider>
    </>
  );
}
