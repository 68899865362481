import React, { useContext } from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';

import { createDefaultItem } from './AddHtmlElementButton.utils';
import { selectWebsiteId } from '../../../../../../../../../selectors/website';
import { HtmlElementPageContext } from '../../../../../../context';
import { addOneHtmlElement } from '../../../../../../slices';

import './AddHtmlElementButton.scss';

const propTypes = {};

const defaultProps = {};

export default function AddHtmlElementButton() {
  const dispatch = useDispatch();
  const websiteId = useSelector(selectWebsiteId);
  const { setItemEditingId, addDirtyId } = useContext(HtmlElementPageContext);

  function onClick() {
    const item = createDefaultItem(websiteId);
    dispatch(addOneHtmlElement(item));

    const { localId } = item;
    setItemEditingId(localId);
    addDirtyId(localId);
  }

  return (
    <Button basic className="toolbar-add-html-element-button" onClick={onClick}>
      <Icon name="plus" />
      Add New Script
    </Button>
  );
}

AddHtmlElementButton.propTypes = propTypes;
AddHtmlElementButton.defaultProps = defaultProps;
