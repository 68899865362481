import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Card, CardGroup, Divider, Header, Icon, Message, Dimmer, Loader } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';

import {
  DEFAULT_EXTERNAL_ANCHOR_TARGET,
  DEFAULT_EXTERNAL_OPEN_FEATURES,
  MAIL_TO_SUPPORT_EMAIL,
  SUPPORT_EMAIL,
} from '../../../../../../constants/constants';
import API from '../../../../../../libs/api';
import ErrorHandler from '../../../../../../libs/errors';
import ExternalAnchor from '../../../../../common/ExternalAnchor';
import { DismissibleMessage } from '../../../../utils/components';
import { MESSAGE_DELAY } from '../CustomerSubmissionsModal.constants';
import { getFormattedFileSize, getIconNameForFile } from '../CustomerSubmissionsModal.utils';

import './CustomerSubmissionsModalFileCardGroup.scss';

const propTypes = {
  submissionId: PropTypes.number.isRequired,
  fileData: PropTypes.shape({}).isRequired,
  divider: PropTypes.bool,
};

const defaultProps = {
  divider: false,
};

export default function CustomerSubmissionsModalFileCardGroup({
  submissionId,
  fileData,
  divider = false,
}) {
  if (_isEmpty(fileData)) {
    return null;
  }

  const [loadingMap, setLoadingMap] = useState({});
  const [error, setError] = useState(null);

  function toggleLoadingMap(uuid, value) {
    setLoadingMap({
      ...loadingMap,
      [uuid]: value,
    });
  }

  async function onFileClick(uuid) {
    try {
      toggleLoadingMap(uuid, true);
      const { url } = await API.getWebsiteEventStorageUrl(submissionId, uuid);
      if (url) {
        window.open(url, DEFAULT_EXTERNAL_ANCHOR_TARGET, DEFAULT_EXTERNAL_OPEN_FEATURES);
      }
    } catch (e) {
      ErrorHandler.capture(e);
      setError(e);
    } finally {
      toggleLoadingMap(uuid, false);
    }

    return null;
  }

  function isLoading(uuid) {
    return loadingMap[uuid] || false;
  }

  return (
    <div className="customer-submission-file-card-group">
      {divider && <Divider />}
      <Header as="h2">File Uploads</Header>
      <Message>
        <b>Please Note:</b> All Download links are temporary to ensure user privacy. If you
        encounter any issues accessing a file after opening it, try again before contacting{' '}
        <ExternalAnchor href={MAIL_TO_SUPPORT_EMAIL}>{SUPPORT_EMAIL}</ExternalAnchor> for
        assistance.
      </Message>
      {error && (
        <DismissibleMessage
          content="Issue Downloading File"
          delay={MESSAGE_DELAY}
          initialVisible
          error
          onDismiss={() => setError(null)}
        />
      )}
      {Object.entries(fileData).map(([fieldName, fieldValue]) => (
        <>
          <Header as="h3">{_startCase(fieldName)}</Header>
          <CardGroup itemsPerRow={2}>
            {fieldValue.map(({ uuid, name, size, isImage, mimeType }) => (
              <Card key={uuid}>
                <Card.Content>
                  <Card.Header>{name}</Card.Header>
                  <Card.Meta>{getFormattedFileSize(size)}</Card.Meta>
                  <Card.Description />
                </Card.Content>
                <Card.Content extra>
                  {isLoading(uuid) && (
                    <>
                      <Loader active />
                      <Dimmer active inverted />
                    </>
                  )}

                  <ExternalAnchor onClick={() => onFileClick(uuid)}>
                    <Icon name="download" size="large" />
                    <Icon name={getIconNameForFile(isImage, mimeType)} size="large" />
                    {mimeType}
                  </ExternalAnchor>
                </Card.Content>
              </Card>
            ))}
          </CardGroup>
        </>
      ))}
    </div>
  );
}

CustomerSubmissionsModalFileCardGroup.propTypes = propTypes;
CustomerSubmissionsModalFileCardGroup.defaultProps = defaultProps;
