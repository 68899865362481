import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import { MAX_AMOUNT_PADDING, MIN_AMOUNT_PADDING, DEFAULT_LABEL } from './PaddingInput.constants';
import { ensurePaddingRange } from './PaddingInput.utils';

import './PaddingInput.scss';

const propTypes = {
  padding: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  minPaddingAllowed: PropTypes.number,
  maxPaddingAllowed: PropTypes.number,
};

const defaultProps = {
  name: '',
  padding: 0,
  label: DEFAULT_LABEL,
  minPaddingAllowed: MIN_AMOUNT_PADDING,
  maxPaddingAllowed: MAX_AMOUNT_PADDING,
};

export default function PaddingInput({
  padding,
  name,
  onChange,
  label,
  minPaddingAllowed,
  maxPaddingAllowed,
}) {
  function onChangeInternal(e, { value }) {
    const range = { min: minPaddingAllowed, max: maxPaddingAllowed };
    onChange(ensurePaddingRange(+value, range));
  }

  return (
    <div className="padding-input-container">
      <label htmlFor="padding-input-field">{label}</label>
      <Input
        id="padding-input-field"
        className="padding-input"
        name={name}
        type="number"
        min={minPaddingAllowed}
        max={maxPaddingAllowed}
        label="px"
        labelPosition="right"
        fluid
        value={padding}
        onChange={onChangeInternal}
      />
    </div>
  );
}

PaddingInput.propTypes = propTypes;
PaddingInput.defaultProps = defaultProps;
