function getCategoryRowSpan(categoryWithItems = {}, variationIds = []) {
  const { items: categoryItems = [] } = categoryWithItems;

  return categoryItems.reduce((acc, item) => {
    const { variations: itemVariations = [] } = item;
    const teamMemberItemVariations = itemVariations.filter(({ id }) => variationIds.includes(id));
    return acc + teamMemberItemVariations.length;
  }, 0);
}

export function createTableRows(teamMemberServiceItems = [], categoriesWithItems = []) {
  const variationIds = teamMemberServiceItems.map(({ itemVariation }) => itemVariation);
  const rows = [];

  const processedCategories = {};
  const processedItems = {};

  categoriesWithItems.forEach((category) => {
    const { items: categoryItems = [], name: categoryName } = category;
    const categoryRowSpan = getCategoryRowSpan(category, variationIds);

    categoryItems.forEach((item) => {
      const { variations: itemVariations = [], name: itemName } = item;
      const teamMemberItemVariations = itemVariations.filter(({ id }) => variationIds.includes(id));

      teamMemberItemVariations.forEach((variation) => {
        const { id: variationId, name: variationName, price, priceDisplay } = variation;
        const teamMemberServiceItem = teamMemberServiceItems.find(({ itemVariation }) => {
          return itemVariation === variationId;
        });

        const { id, priceOverride, priceDisplayOverride } = teamMemberServiceItem;
        const row = {
          id,
          itemVariation: variationId,
          variationName,
          price,
          priceDisplay,
          priceOverride,
          priceDisplayOverride,
        };

        if (!processedCategories[categoryName]) {
          row.categoryName = categoryName;
          row.categoryRowSpan = categoryRowSpan;
          processedCategories[categoryName] = true;
        }
        if (!processedItems[itemName]) {
          row.itemName = itemName;
          row.itemRowSpan = teamMemberItemVariations.length;
          processedItems[itemName] = true;
        }
        rows.push(row);
      });
    });
  });

  return rows;
}
