import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Card, Form, Grid, Header, Item, Label } from 'semantic-ui-react';

import _includes from 'lodash/includes';
import { useSelector } from 'react-redux';

import { LABEL_ACTIVE, LABEL_INACTIVE } from './HtmlElementCard.constants';
import { DomLocationDropdown, WebsiteLocationDropdown } from './components';
import ActiveIndicator from './components/ActiveIndicator/ActiveIndicator';
import { getDomLocationTextFromValue } from './components/DomLocationDropdown/DomLocationDropdown.utils';
import ItemHeader from './components/ItemHeader';
import { getWebsiteLocationTextFromValue } from './components/WebsiteLocationDropdown/WebsiteLocationDropdown.utils';
import { selectWebsitePages } from '../../../../../../../../../selectors/website';
import ConfirmModal from '../../../../../../../../common/ConfirmModal';
import useResponsive from '../../../../../../../foundation/hooks/use-responsive';
import { WEBSITE_LOCATION_GLOBAL, WEBSITE_LOCATION_PAGE } from '../../../../../../constants';
import { HtmlElementPageContext } from '../../../../../../context/HtmlElements.context';
import { useHtmlElements } from '../../../../../../hooks';
import { HtmlElementItemPropType } from '../../../../../../types';

import './HtmlElementCard.scss';

const propTypes = {
  item: HtmlElementItemPropType,
  onClickToEdit: PropTypes.func.isRequired,
};

const defaultProps = {
  item: {},
};

export default function HtmlElementCard({ item, onClickToEdit }) {
  const { isMobile } = useResponsive();
  const { deleteItem, isSaving } = useHtmlElements();
  const { setErrorMessage, setItem, itemEditingId, saveItemState, dirtyIds, addDirtyId } =
    useContext(HtmlElementPageContext);
  const { id, localId, name, enabled, domLocation, websiteLocation, page } = item;
  const websitePages = useSelector(selectWebsitePages);

  const isEditing = (id || localId) === itemEditingId;
  const isDirty = _includes(dirtyIds, id || localId);

  const [nameValue, setNameValue] = useState(name);
  const [enabledValue, setEnabledValue] = useState(enabled);
  const [domLocationValue, setDomLocationValue] = useState(domLocation);
  const [websiteLocationValue, setWebsiteLocationValue] = useState(websiteLocation);
  const [pageValue, setPageValue] = useState(page);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const localStateSetterMap = {
    name: setNameValue,
    enabled: setEnabledValue,
    domLocation: setDomLocationValue,
  };

  function onConfirmDelete(e) {
    e.stopPropagation();
    setShowDeleteModal(true);
  }
  function closeDeleteModal() {
    setShowDeleteModal(false);
  }

  function saveItem() {
    if (item) {
      const payload = {
        name: nameValue,
        enabled: enabledValue,
        domLocation: domLocationValue,
        websiteLocation: websiteLocationValue,
        page: pageValue,
      };
      saveItemState(item, payload);
    }
  }

  function onChange(e, { name: fieldName, value, checked }) {
    e.stopPropagation();
    addDirtyId(itemEditingId);
    if (fieldName === 'websiteLocation') {
      setWebsiteLocationValue(
        value === WEBSITE_LOCATION_GLOBAL ? WEBSITE_LOCATION_GLOBAL : WEBSITE_LOCATION_PAGE,
      );
      setPageValue(value === WEBSITE_LOCATION_GLOBAL ? null : value);
    } else {
      const setter = localStateSetterMap[fieldName];
      if (setter) {
        setter(value || checked);
      }
    }
  }

  function onClick(e) {
    e.stopPropagation();
    const exitEditor = itemEditingId === (id || localId);
    if (isEditing && exitEditor) {
      saveItem();
    }
    onClickToEdit(id || localId);
    setItem(item);
    setErrorMessage('');
  }

  function onDelete() {
    deleteItem(item);
  }

  useEffect(() => {
    if (item) {
      saveItem();
    }
  }, [itemEditingId]);

  useEffect(() => {
    saveItem();
  }, [nameValue, enabledValue, domLocationValue, websiteLocationValue, pageValue]);

  const extraPropertiesForEditingForm = isMobile
    ? {
        width: 16,
      }
    : {};

  return (
    <Item className="html-element-item" onClick={onClick}>
      <Card fluid className={`html-element-card ${isEditing ? 'active' : ''}`}>
        <Card.Content>
          <Form>
            <Card.Header>
              <ItemHeader
                isEditing={isEditing}
                isDirty={isDirty}
                name={nameValue}
                onChange={onChange}
              />
            </Card.Header>
            <Card.Description>
              {isEditing ? (
                <Grid columns="equal">
                  <Grid.Column
                    {...extraPropertiesForEditingForm}
                    className="item-dropdown-container"
                  >
                    <DomLocationDropdown onChange={onChange} value={domLocationValue} />
                    <WebsiteLocationDropdown
                      onChange={onChange}
                      page={pageValue}
                      websiteLocation={websiteLocationValue}
                    />
                  </Grid.Column>
                  <Grid.Column className="item-actions-container" textAlign="right">
                    <Form.Field>
                      <Form.Checkbox
                        name="enabled"
                        toggle
                        checked={enabledValue}
                        onChange={onChange}
                      />
                    </Form.Field>
                    <Header className="item-enabled-text" size="small">
                      {enabledValue ? LABEL_ACTIVE : LABEL_INACTIVE}
                    </Header>
                    <ConfirmModal
                      header="Delete Custom HTML"
                      content="Are you sure you want to delete this script?"
                      open={showDeleteModal}
                      onCancel={closeDeleteModal}
                      onConfirm={onDelete}
                      confirmButton={
                        <Button className="red" primary={false} disabled={isSaving}>
                          Yes
                        </Button>
                      }
                      cancelButton="Cancel"
                    />
                    <Button
                      className="delete-red"
                      icon="trash"
                      basic
                      color="red"
                      onClick={onConfirmDelete}
                    />
                  </Grid.Column>
                </Grid>
              ) : (
                <Grid columns="equal">
                  <Grid.Column className="html-element-card-locations">
                    <Label className="item-label-dom-location" basic>
                      {getDomLocationTextFromValue(domLocationValue)}
                    </Label>
                    <Label className="item-label-website-location">
                      {getWebsiteLocationTextFromValue(
                        pageValue,
                        websiteLocationValue,
                        websitePages,
                      )}
                    </Label>
                  </Grid.Column>
                  <Grid.Column floated="right" textAlign="right">
                    <ActiveIndicator enabled={enabledValue} />
                  </Grid.Column>
                </Grid>
              )}
            </Card.Description>
          </Form>
        </Card.Content>
      </Card>
    </Item>
  );
}

HtmlElementCard.propTypes = propTypes;
HtmlElementCard.defaultProps = defaultProps;
