import React from 'react';

import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import App from './App';
import { isSentryEnabled } from '../libs/sentry';

const Root = ({ store }) => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default Sentry.withProfiler(Root, { disabled: !isSentryEnabled() });
