import _isNil from 'lodash/isNil';

export function removeNilValues(payload) {
  return Object.keys(payload).reduce((acc, key) => {
    if (!_isNil(payload[key])) {
      acc[key] = payload[key];
    }
    return acc;
  }, {});
}
