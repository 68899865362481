import React from 'react';

import PropTypes from 'prop-types';

import Icon from '../../../../modules/foundation/components/Icon';

const propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

const defaultProps = {
  icon: null,
};

export default function SideNavigationItemLinkContent({ name, icon }) {
  if (icon) {
    return (
      <>
        <Icon name={icon} alt={name} />
        {name}
      </>
    );
  }
  return <>{name}</>;
}

SideNavigationItemLinkContent.propTypes = propTypes;
SideNavigationItemLinkContent.defaultProps = defaultProps;
