/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

import { initializeAce } from './components/modules/ace/services/initialize';
import Root from './containers/Root';
import GoogleAnalytics from './libs/google-analytics';
import LogRocket from './libs/logrocket/logrocket';
import initSentry from './libs/sentry';
import storage from './libs/storage';
import store from './store/store';

const APP_STORAGE = 'fisherman';

store.subscribe(() => {
  if (!storage.get('debug')) {
    storage.set(store.getState(), APP_STORAGE);
  }
});

LogRocket.init();
initSentry();
GoogleAnalytics.init();
initializeAce();

(async function initializeLaunchDarkly() {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    options: {
      bootstrap: 'localStorage',
    },
  });

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <LDProvider>
      <Root store={store} />
    </LDProvider>,
  );
})();
