import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import { CatalogCategoryPropType } from '../../../proptypes';

import './CategoryDetail.scss';

const propTypes = {
  category: CatalogCategoryPropType.isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: () => null,
};

function CategoryDetail({
  category: {
    category_data: { name, description },
  },
  onClick,
}) {
  return (
    <section className="category-detail">
      <p className="category-title">
        {name}{' '}
        {onClick && <Button className="edit-button" size="huge" onClick={onClick} icon="pencil" />}
      </p>
      <p className="category-body">{description}</p>
    </section>
  );
}

CategoryDetail.defaultProps = defaultProps;
CategoryDetail.propTypes = propTypes;
export default CategoryDetail;
