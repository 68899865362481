import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Segment, Button, Container, Image } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';

import {
  INTEGRATION_PROVIDER,
  INTEGRATION_PROVIDER_SERVICE_TYPE,
  INTEGRATION_COLLECTION_METHOD,
  MULTIPLE_RESTAURANT_KEY,
  DEFAULT_SERVICE_DATA,
} from './Zuppler.constants';
import MultipleRestaurantForm from './components/MultipleRestaurantForm';
import SingleRestaurantForm from './components/SingleRestaurantForm';
import {
  updateProviderServiceSetting as updateProviderServiceSettingConnect,
  addNewProviderServiceSetting as addNewProviderServiceSettingConnect,
  deleteProviderServiceSetting as deleteProviderServiceSettingConnect,
  chooseMenuSource as chooseMenuSourceConnect,
} from '../../../../actions/business';
import API from '../../../../libs/api';
import { BusinessPropType } from '../../../../libs/proptypes';
import { InlineFieldContainer } from '../../../common';
import FormContainer from '../../../common/FormContainer';
import { getProviderService, getProviderServiceSettingById } from '../utils';

import ZupplerLogo from '../../../../static/logos/zuppler-logo.jpeg';

const mapDispatchToProps = (dispatch) => ({
  addNewProviderServiceSetting: (payload) => dispatch(addNewProviderServiceSettingConnect(payload)),
  deleteProviderServiceSetting: (payload) => dispatch(deleteProviderServiceSettingConnect(payload)),
  updateProviderServiceSetting: (payload) => dispatch(updateProviderServiceSettingConnect(payload)),
  chooseMenuSource: (payload) => dispatch(chooseMenuSourceConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  business: _get(business, 'core.value'),
  collectionMethod: _get(business, 'core.value.collection_method'),
  providerServices: _get(business, 'provider_services.value'),
  providerServiceSettings: _get(business, 'provider_service_settings.value'),
});

const propTypes = {
  business: BusinessPropType.isRequired,
  collectionMethod: PropTypes.string.isRequired,
  providerServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      provider: PropTypes.string,
      service_type: PropTypes.string,
    }),
  ),
  providerServiceSettings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      provider_service: PropTypes.number,
    }),
  ),
};

const defaultProps = {
  providerServices: [],
  providerServiceSettings: [],
};

function ZupplerIntegration(props) {
  const { collectionMethod } = props;
  const { providerServices, providerServiceSettings } = props;

  const zupplerService = getProviderService(
    providerServices,
    INTEGRATION_PROVIDER,
    INTEGRATION_PROVIDER_SERVICE_TYPE,
  );
  if (!zupplerService || collectionMethod !== INTEGRATION_COLLECTION_METHOD) return null;
  const zupplerSetting = getProviderServiceSettingById(providerServiceSettings, zupplerService.id);
  const zupplerServiceData = zupplerSetting ? zupplerSetting.service_data : DEFAULT_SERVICE_DATA;

  const [serviceData, setServiceData] = useState(zupplerServiceData);
  const isMultipleRestaurant = _get(serviceData, MULTIPLE_RESTAURANT_KEY);

  async function addIntegration() {
    const { addNewProviderServiceSetting, chooseMenuSource } = props;

    addNewProviderServiceSetting({
      provider_service: zupplerService.id,
      provider: zupplerService.provider,
      service_type: zupplerService.service_type,
      service_data_schema: zupplerService.service_data_schema,
      service_data: {},
      status_data: {},
    });

    chooseMenuSource({ value: 'Integration' });
  }

  function handleUpdate(e, { value, name }) {
    setServiceData({
      ...serviceData,
      [name]: value,
    });
  }

  async function removeIntegration() {
    const { business, deleteProviderServiceSetting } = props;
    const { id: businessId, type: businessType } = business;

    await API.deleteProviderServiceSettings(businessId, businessType, zupplerSetting.id);
    deleteProviderServiceSetting({
      fields: {
        index: zupplerSetting.globalIndex,
        ignoreTouch: true,
      },
    });
    setServiceData({});
  }

  useEffect(() => {
    const { updateProviderServiceSetting } = props;
    if (!zupplerSetting) return;

    const updatedProviderServiceSettingPayload = {
      index: zupplerSetting.globalIndex,
      field: 'service_data',
      fieldValue: { ...serviceData },
    };

    updateProviderServiceSetting(updatedProviderServiceSettingPayload);
  }, [serviceData]);

  if (zupplerSetting) {
    return (
      <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
        <InlineFieldContainer title={<h2>Zuppler</h2>} enableDelete={false}>
          <Segment.Group>
            <Segment>
              <Container>
                <Container textAlign="center">
                  <Image
                    src={ZupplerLogo}
                    style={{
                      display: 'inline',
                      margin: '20px',
                      height: '50px',
                    }}
                  />
                </Container>
              </Container>
            </Segment>
            <Segment attached="top">
              <Container>
                {isMultipleRestaurant ? (
                  <MultipleRestaurantForm serviceData={serviceData} handleUpdate={handleUpdate} />
                ) : (
                  <SingleRestaurantForm serviceData={serviceData} handleUpdate={handleUpdate} />
                )}
              </Container>
            </Segment>
            {zupplerSetting.id && (
              <Segment attached="bottom">
                <Container textAlign="center">
                  <Button size="big" onClick={removeIntegration} negative>
                    Remove Integration
                  </Button>
                </Container>
              </Segment>
            )}
          </Segment.Group>
        </InlineFieldContainer>
      </FormContainer>
    );
  }

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']}>
      <InlineFieldContainer title={<h2>Zuppler</h2>} enableDelete={false}>
        <Segment.Group>
          <Segment>
            <Container>
              <Container textAlign="center">
                <Image
                  src={ZupplerLogo}
                  style={{
                    display: 'inline',
                    margin: '20px',
                    height: '50px',
                  }}
                />
              </Container>
            </Container>
          </Segment>
          <Segment attached="top">
            <Container textAlign="center">
              <Button size="big" onClick={addIntegration} primary>
                Setup Integration
              </Button>
            </Container>
          </Segment>
        </Segment.Group>
      </InlineFieldContainer>
    </FormContainer>
  );
}

ZupplerIntegration.propTypes = propTypes;
ZupplerIntegration.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ZupplerIntegration);
