import {
  DAY_SMART_LEAD_SOURCE,
  DAY_SMART_REFERRAL_LEAD_SOURCE,
} from '../../../../libs/lead-source/constants';

export const INTEGRATION_PROVIDER = 'DaySmart';
export const INTEGRATION_PROVIDER_SERVICE_TYPE = 'Merchant';
export const ACCOUNT_ID_KEY = 'daysmart_acc';
export const DEFAULT_SERVICE_DATA = {};
export const DAYSMART_LEAD_SOURCES_TO_ENABLE_INTEGRATION = [
  DAY_SMART_LEAD_SOURCE,
  DAY_SMART_REFERRAL_LEAD_SOURCE,
];
