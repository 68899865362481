import _find from 'lodash/find';

import { VALUE_KEY, recipeFieldsEditorMode, JSON_KEY } from './ObjectOptionsHeader.constants';

export function getOptionHeaderName(key, recipePartial) {
  return recipePartial && recipePartial.name ? recipePartial.name : key;
}

export function isStaticField(recipePartial) {
  if (!recipePartial) {
    return false;
  }

  return [VALUE_KEY].some(
    (recipeKey) =>
      Object.keys(recipePartial).includes(recipeKey) && typeof recipePartial.resolve !== 'object',
  );
}

export function getCssActiveClassName(isNodePathSelected) {
  return isNodePathSelected ? 'active' : '';
}

export function getCodeEditorMode(recipeObjectKey, parentId, getParentNode) {
  const { __type__ } = getParentNode(parentId);

  const fieldConfig = _find(
    recipeFieldsEditorMode,
    ({ nodeType, field }) => nodeType === __type__ && field === recipeObjectKey,
  );

  if (!fieldConfig) {
    return JSON_KEY;
  }

  const { editorMode } = fieldConfig;
  return editorMode;
}
