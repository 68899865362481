import React from 'react';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { connect } from 'react-redux';

import { getComponentSetting } from 'components/modules/component-configuration/services/component-configuration';
import { selectBusinessFiles } from 'selectors/business';

import {
  getPageComponent,
  getPageComponentConfigurationSettings,
  updatePageWithNewComponent,
  getSelectedFiles,
  associateSelectedFiles,
  updatePageComponentFiles,
  refreshPages,
  isLegacyWebsite,
} from './ImagesPage.utils';
import PageList from './PageList';
import {
  initializeBusinessFiles as initializeBusinessFilesConnect,
  updateBusinessFile as updateBusinessFileConnect,
  addBusinessFile as addBusinessFileConnect,
  deleteBusinessFile as deleteBusinessFileConnect,
  flagBusinessSaved as flagBusinessSavedConnect,
} from '../../../actions/business';
import { updateWebsitePages as updateWebsitePagesConnect } from '../../../actions/website';
import API from '../../../libs/api';
import { asyncDebounce } from '../../../libs/async';
import ErrorHandler from '../../../libs/errors';
import { FormContainer } from '../../common';
import WithLoading from '../../common/WithLoading';
import FileEditorModal from '../../modules/files/components/FileEditorModal/FileEditorModal';
import { CUSTOM_PAGE_TYPE, EXTERNAL_PAGE_TYPE } from '../../modules/pages/constants';
import FileModal from '../FileManagement/FileModal';
import FilesManagementModal from '../FilesManagementModal';

const LoadingPageList = WithLoading(PageList);

const mapDispatchToProps = (dispatch) => ({
  updateWebsitePages: (payload) => dispatch(updateWebsitePagesConnect(payload)),
  initializeBusinessFiles: (payload) => dispatch(initializeBusinessFilesConnect(payload)),
  updateBusinessFile: (payload) => dispatch(updateBusinessFileConnect(payload)),
  deleteBusinessFile: (payload) => dispatch(deleteBusinessFileConnect(payload)),
  addBusinessFile: (payload) => dispatch(addBusinessFileConnect(payload)),
  flagBusinessSaved: (payload) => dispatch(flagBusinessSavedConnect(payload)),
});

const mapStateToProps = ({ website, business }) => ({
  website: _get(website, 'core.value'),
  businessType: _get(business, 'core.value.type'),
  businessId: _get(business, 'core.value.id'),
  businessFiles: selectBusinessFiles({ business }),
});

class ImagesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingPages: true,
      pages: [],
      configurationSettings: [],
      currentPage: {},

      selectedFilesKey: null,
      currentMinFilesAllowed: null,
      currentMaxFilesAllowed: null,

      showFilesManagementModal: false,
      isFilesManagementModalSubmitting: false,
      showFileModal: false,
      fileModalError: null,
      isImageDeleting: false,
      isImageSaving: false,

      isSearching: false,
      isImageUploading: false,
      searchValue: '',
    };

    // Methods for business file edit modal
    this.getPages = this.getPages.bind(this);
    this.getRecipeConfiguration = this.getRecipeConfiguration.bind(this);
    this.onEditImagesClick = this.onEditImagesClick.bind(this);
    this.getFiles = asyncDebounce(this.getFiles.bind(this), 500);
    this.updateState = this.updateState.bind(this);
    this.handleFileSearch = this.handleFileSearch.bind(this);
    this.toggleSelectedFile = this.toggleSelectedFile.bind(this);
    this.closeSelectFilesModal = this.closeSelectFilesModal.bind(this);
    this.handleSelectFilesModalSubmit = this.handleSelectFilesModalSubmit.bind(this);
    this.setSelectedImageOrder = this.setSelectedImageOrder.bind(this);
    this.onFileUpload = this.onFileUpload.bind(this);
    this.setFileModalError = this.setFileModalError.bind(this);
    this.displayFileModal = this.displayFileModal.bind(this);
    this.saveFile = this.saveFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.loadPageData = this.loadPageData.bind(this);
  }

  async componentDidMount() {
    await this.loadPageData();
  }

  async handleSelectFilesModalSubmit() {
    const { currentPage, selectedFilesKey } = this.state;
    const { website, flagBusinessSaved } = this.props;

    try {
      this.setState({ isFilesManagementModalSubmitting: true });

      await associateSelectedFiles(website, currentPage, selectedFilesKey);
      await this.loadPageData();

      this.closeSelectFilesModal();
    } catch (e) {
      if (_get(e, 'response.status') === 400) {
        const errorData = _get(e, 'response.data');
        const { min_files: minFiles, max_files: maxFiles } = errorData;

        let message = '';
        if (minFiles) {
          message = `You must select at least ${minFiles} file(s)`;
        }

        if (maxFiles) {
          message = `You cannot select more than ${maxFiles} file(s)`;
        }

        this.setState({
          fileModalError: message,
        });
      }
    } finally {
      this.setState({ isFilesManagementModalSubmitting: false });
      flagBusinessSaved(true);
    }
  }

  async handleFileSearch(e, target) {
    const { value = '' } = target || {};
    this.setState({ searchValue: value, isSearching: true });
    await this.getFiles(value);
  }

  onEditImagesClick(page, componentKey) {
    const { website } = this.props;
    const { configurationSettings } = this.state;
    const { page_type: pageType } = page;

    if (isLegacyWebsite(website)) {
      this.setState({
        showFilesManagementModal: true,
        currentPage: page,
        selectedFilesKey: componentKey,
      });
      return;
    }

    const component = getPageComponent(page, componentKey);
    const pageConfigurationSettings = getPageComponentConfigurationSettings(
      configurationSettings,
      pageType,
    );
    const componentSetting = getComponentSetting(component, pageConfigurationSettings, {
      pageType,
    });

    const { min_files_allowed: minFilesAllowed, max_files_allowed: maxFilesAllowed } =
      componentSetting;

    this.setState({
      showFilesManagementModal: true,
      currentPage: page,
      selectedFilesKey: componentKey,
      currentMinFilesAllowed: minFilesAllowed,
      currentMaxFilesAllowed: maxFilesAllowed,
    });
  }

  async onFileUpload(files) {
    const { businessType, businessId } = this.props;
    this.setState({ isImageUploading: true });

    const filesPayload = files.map((file) => ({
      file,
      filename: file.name,
      description: '',
    }));

    try {
      await this.addFiles(businessType, businessId, filesPayload);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      this.setState({ isImageUploading: false });
    }
  }

  async getPages() {
    this.setState({ isLoadingPages: true });
    const { updateWebsitePages, website } = this.props;

    try {
      const response = await API.getPages(website.id);
      const { data } = response;

      const pages = data.filter(({ page_type: pageType }) => {
        return ![EXTERNAL_PAGE_TYPE, CUSTOM_PAGE_TYPE].includes(pageType);
      });

      // ensure currentPage is updated
      this.setState(({ currentPage }) => {
        return {
          currentPage: pages.find((page) => page.id === (currentPage || {}).id),
          isLoadingPages: false,
          pages,
        };
      });

      updateWebsitePages(data);
    } catch (e) {
      ErrorHandler.capture(e);
    }
  }

  async getRecipeConfiguration() {
    const { website } = this.props;

    if (isLegacyWebsite(website)) {
      return;
    }

    try {
      const { data } = await API.getWebsiteRecipe(website.id);
      const { component_configuration_settings: configurationSettings } = data;

      this.setState({ configurationSettings });
    } catch (e) {
      ErrorHandler.capture(e);
    }
  }

  setFileModalError(error) {
    this.setState({ fileModalError: error });
  }

  async setSelectedImageOrder({ oldIndex, newIndex, currentPage, componentKey }) {
    const { website, flagBusinessSaved } = this.props;

    return new Promise((resolve) => {
      this.setState(
        ({ pages }) => {
          const component = getPageComponent(currentPage, componentKey);
          const { files } = component;

          const newSelectedFiles = [...files];
          const movingFile = newSelectedFiles.splice(oldIndex, 1)[0];
          newSelectedFiles.splice(newIndex, 0, movingFile);

          const newComponent = { ...component, files: newSelectedFiles };
          const newPage = updatePageWithNewComponent(currentPage, newComponent);
          const newPages = refreshPages(pages, newPage);

          return { pages: newPages };
        },
        async () => {
          const { pages } = this.state;
          const newPage = pages.find((page) => page.id === currentPage.id);

          await associateSelectedFiles(website, newPage, componentKey);
          flagBusinessSaved(true);

          resolve();
        },
      );
    });
  }

  async getFiles(searchValue = '') {
    const { businessType, businessId, initializeBusinessFiles } = this.props;
    try {
      const { data } = await API.getBusinessFiles(businessType, businessId, {
        search: searchValue,
        order_by: '-created_at',
      });
      initializeBusinessFiles(data);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      this.setState({ isSearching: false });
    }
  }

  async loadPageData() {
    await Promise.all([this.getFiles(), this.getRecipeConfiguration(), this.getPages()]);
  }

  updateState(value) {
    this.setState(value);
  }

  toggleSelectedFile(businessFile) {
    this.setState(({ currentPage, selectedFilesKey, pages }) => {
      const selectedFiles = getSelectedFiles(currentPage, selectedFilesKey);

      const selectedFilesWithoutCurrent = selectedFiles.filter(
        (file) => file.id !== businessFile.id,
      );

      // if the file was not selected we add it to the state
      const isFileSelected = selectedFilesWithoutCurrent.length < selectedFiles.length;

      const newSelectedFiles = isFileSelected
        ? selectedFilesWithoutCurrent
        : [...selectedFiles, businessFile];

      const newPage = updatePageComponentFiles(currentPage, selectedFilesKey, newSelectedFiles);

      const newPages = refreshPages(pages, newPage);

      return { pages: newPages, currentPage: newPage };
    });
  }

  closeSelectFilesModal() {
    this.setState({ showFilesManagementModal: false, currentPage: {} });
  }

  async addFiles(type, businessId, filesPayload) {
    const { addBusinessFile } = this.props;

    const filesPayloadResults = await Promise.all(
      filesPayload.map((payload) => API.createBusinessFile(type, businessId, payload)),
    );
    return filesPayloadResults.map((result) => addBusinessFile(result.data));
  }

  displayFileModal(businessFile, showFileModal = true) {
    const { businessFiles } = this.props;
    const index =
      businessFile && businessFiles.findIndex((currentFile) => currentFile.id === businessFile.id);
    const selectedFile = index !== false && index >= 0 && { ...businessFiles[index], index };

    this.setState({ showFileModal, selectedFile });
  }

  async deleteFile(file) {
    const { businessType, businessId, deleteBusinessFile } = this.props;

    try {
      this.setState({ isImageDeleting: true });
      await API.deleteBusinessFile(businessType, businessId, file.id);
      deleteBusinessFile(file.index);

      await this.loadPageData();

      this.displayFileModal(null, false);
    } finally {
      this.setState({ isImageDeleting: false });
    }
  }

  async saveFile(file) {
    const { businessType, businessId, updateBusinessFile } = this.props;

    try {
      this.setState({ isImageSaving: true });

      const results = await API.updateBusinessFile(
        businessType,
        businessId,
        file.id,
        _pick(file, ['filename', 'description']),
      );
      updateBusinessFile({
        index: file.index,
        fields: results.data,
      });

      this.displayFileModal(null, false);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      this.setState({ isImageSaving: false });
    }
  }

  render() {
    const { businessFiles, flags } = this.props;
    const {
      pages,
      configurationSettings,
      showFilesManagementModal,
      isFilesManagementModalSubmitting,
      showFileModal,
      fileModalError,
      selectedFile,
      currentPage,
      currentMinFilesAllowed,
      currentMaxFilesAllowed,
      selectedFilesKey,
      searchValue,
      isSearching,
      isImageUploading,
      isImageDeleting,
      isImageSaving,
      isLoadingPages,
    } = this.state;

    const selectedFiles = getSelectedFiles(currentPage, selectedFilesKey);

    const { advancedImageEditing } = flags;

    return (
      <>
        <FormContainer className="websiteConfiguration" loadedKeyPath={['website', 'core']}>
          <LoadingPageList
            isLoading={isLoadingPages}
            pages={pages}
            configurationSettings={configurationSettings}
            onSortImages={this.setSelectedImageOrder}
            onEditImagesClick={this.onEditImagesClick}
            displayEditPageButton={false}
            onPostConfigurationSave={this.loadPageData}
          />

          <FilesManagementModal
            open={showFilesManagementModal}
            isLoading={isImageUploading}
            isSubmitting={isFilesManagementModalSubmitting}
            onClose={this.closeSelectFilesModal}
            files={businessFiles}
            selectedFiles={selectedFiles}
            minFilesAllowed={currentMinFilesAllowed}
            maxFilesAllowed={currentMaxFilesAllowed}
            onFileClick={this.toggleSelectedFile}
            onFileSearch={this.handleFileSearch}
            onFileEdit={this.displayFileModal}
            onSubmit={this.handleSelectFilesModalSubmit}
            searchValue={searchValue}
            isSearching={isSearching}
            onUpload={this.onFileUpload}
            setError={this.setFileModalError}
            error={fileModalError}
          />

          {showFileModal && !advancedImageEditing && (
            <FileModal
              open={showFileModal}
              selectedFile={selectedFile}
              onCloseModal={() => {
                this.displayFileModal(null, false);
              }}
              onSave={this.saveFile}
              onDelete={this.deleteFile}
              isSaving={isImageSaving}
              isDeleting={isImageDeleting}
            />
          )}

          {showFileModal && advancedImageEditing && selectedFile && (
            <FileEditorModal
              open={showFileModal}
              onClose={() => {
                this.displayFileModal(null, false);
              }}
              businessFile={selectedFile}
              onPostSave={this.loadPageData}
              onPostDelete={this.loadPageData}
            />
          )}
        </FormContainer>
      </>
    );
  }
}

export default withLDConsumer()(connect(mapStateToProps, mapDispatchToProps)(ImagesPage));
