import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { TextArea } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  DEFAULT_NAME,
  DEFAULT_PLACEHOLDER,
  HELP_TOOLTIP_MESSAGE,
  HIDE_WARNING_TIMEOUT,
  LENGTH_BUFFER,
  MAX_INPUT_LENGTH_WARNING,
  MAX_LENGTH,
} from './SeoDescription.constants';
import {
  getAiGeneratedSeoDescription,
  getWarningMessage,
  getWritingSections,
} from './SeoDescription.utils';
import ErrorHandler from '../../../../../../../libs/errors/errors';
import { formatLabel } from '../../../../../../../libs/strings/strings';
import { selectBusinessId, selectBusinessType } from '../../../../../../../selectors';
import HelpTooltip from '../../../../../../common/HelpTooltip';
import ActionsInput from '../../../../../content/components/ActionsInput';
import WritingAssistantButton from '../../../../../content/components/WritingAssistantButton';
import { SeoMetadataFieldsContext } from '../../SeoMetadata.context';

import './SeoDescription.scss';

const propTypes = {
  description: PropTypes.string,
  defaultDescription: PropTypes.string,
  placeholder: PropTypes.string,
  showWarnings: PropTypes.bool,
  required: PropTypes.bool,
  additionalRequirements: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  description: undefined,
  defaultDescription: '',
  placeholder: DEFAULT_PLACEHOLDER,
  showWarnings: false,
  required: false,
  additionalRequirements: '',
  name: DEFAULT_NAME,
};

export default function SeoDescription({
  description: descriptionProp,
  defaultDescription,
  placeholder,
  showWarnings: showWarningsProp,
  required,
  additionalRequirements,
  name,
}) {
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);

  const { onChange: onChangeContext, pageType } = useContext(SeoMetadataFieldsContext);

  const [showWarnings, setShowWarnings] = useState(true);
  const warningTimeoutId = useRef(null);

  const [value, setTitle] = useState(defaultDescription);
  const isControlled = descriptionProp !== undefined;
  const finalDescription = (isControlled ? descriptionProp : value) || '';

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    clearTimeout(warningTimeoutId.current);
    setShowWarnings(true);
    warningTimeoutId.current = setTimeout(() => {
      setShowWarnings(false);
    }, HIDE_WARNING_TIMEOUT);

    return () => {
      clearTimeout(warningTimeoutId.current);
    };
  }, [finalDescription]);

  function setNewDescription(newDescription) {
    if (!isControlled) {
      setTitle(newDescription || '');
    }
  }

  async function onMakeSuggestion() {
    try {
      setLoading(true);
      const params = {
        additionalRequirements,
        pageType,
        maxLength: MAX_INPUT_LENGTH_WARNING - LENGTH_BUFFER,
      };
      const { text_completion: generatedDescription } = await getAiGeneratedSeoDescription(
        businessId,
        businessType,
        params,
      );

      return generatedDescription;
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
    return '';
  }

  function onWriteDefault() {
    return '';
  }

  function onInputChange(e, target) {
    const { value: newDescription = '' } = target;
    setNewDescription(newDescription);
    const newTarget = { ...target, value: newDescription, name };
    onChangeContext({ ...e, target: newTarget }, newTarget);
  }

  return (
    <div className="seo-metadata seo-description">
      <div className="label-container">
        <label htmlFor="seo-metadata-seo-description">
          {formatLabel('SEO Description', required)}
        </label>
        <HelpTooltip title="SEO Title" content={HELP_TOOLTIP_MESSAGE} />
      </div>
      <ActionsInput>
        <ActionsInput.Input
          id="seo-metadata-seo-description"
          input={TextArea}
          onChange={onInputChange}
          placeholder={placeholder}
          disabled={loading}
          loading={loading}
          value={finalDescription}
          maxLength={MAX_LENGTH}
          required={required}
          rows={3}
        />
        <ActionsInput.Message
          hide={!showWarningsProp || !showWarnings || _isEmpty(finalDescription)}
          warning
          message={getWarningMessage(finalDescription)}
        />
        <ActionsInput.Actions
          trigger={WritingAssistantButton}
          actionSections={getWritingSections({
            onMakeSuggestion,
            placeholder,
            onWriteDefault,
          })}
          adminOnly
        />
      </ActionsInput>
    </div>
  );
}

SeoDescription.propTypes = propTypes;
SeoDescription.defaultProps = defaultProps;
