import React, { useEffect, useState } from 'react';

import { Button, List, Loader, Message } from 'semantic-ui-react';

import _clone from 'lodash/clone';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import _reject from 'lodash/reject';
import { useSelector } from 'react-redux';

import {
  NO_MODIFIER_MESSAGE_HEADER,
  NO_MODIFIER_MESSAGE_MESSAGE,
} from './CatalogModifiers.constants';
import ModifierSetToggle from './components/ModifierSetToggle';
import { selectBusiness, selectActiveProduct } from '../../../../../selectors';
import { useCatalogItemLibrary } from '../hooks';
import { getCatalogObjectId, createNewModifierSet, extractModifierSetsFromCatalog } from '../utils';

import './CatalogModifiers.scss';

export default function CatalogModifiers() {
  const { id: businessId, type: businessType } = useSelector(selectBusiness);
  const currentActiveProduct = useSelector(selectActiveProduct);
  const { catalog, loadingCatalog, fetchingCatalogErrorMessage } = useCatalogItemLibrary(
    businessType,
    businessId,
    currentActiveProduct.id,
  );
  const [modifierSets, setModifierSets] = useState([]);

  useEffect(() => {
    setModifierSets(extractModifierSetsFromCatalog(catalog));
  }, [catalog]);

  function handleCreateNewSet() {
    const newModifierSet = createNewModifierSet();
    setModifierSets((prevModifierSets) => [...prevModifierSets, newModifierSet]);
  }

  function handleSave(oldModifierListId, updatedModifierSet) {
    const newModifierSets = _clone(modifierSets);
    const indexToUpdate = _findIndex(
      newModifierSets,
      (modifierSet) => getCatalogObjectId(modifierSet.modifierList) === oldModifierListId,
    );
    newModifierSets[indexToUpdate] = updatedModifierSet;
    setModifierSets(newModifierSets);
  }

  function handleDelete(modifierList) {
    let newModifierSets = _clone(modifierSets);
    newModifierSets = _reject(
      newModifierSets,
      (modifierSet) =>
        getCatalogObjectId(modifierSet.modifierList) === getCatalogObjectId(modifierList),
    );
    setModifierSets(newModifierSets);
  }

  if (loadingCatalog) {
    return <Loader active />;
  }

  if (fetchingCatalogErrorMessage) {
    return <Message error header={fetchingCatalogErrorMessage} />;
  }

  return (
    <div className="catalog-modifiers">
      <Button className="create-new-set-button" onClick={handleCreateNewSet}>
        Create New Set
      </Button>
      {_isEmpty(modifierSets) && (
        <Message header={NO_MODIFIER_MESSAGE_HEADER} content={NO_MODIFIER_MESSAGE_MESSAGE} />
      )}
      <List className="modifier-set-list">
        {modifierSets.map((modifierSet) => (
          <List.Item key={getCatalogObjectId(modifierSet.modifierList)}>
            <ModifierSetToggle
              initialItemModifierListInfo={modifierSet.itemModifierListInfo}
              initialModifierList={modifierSet.modifierList}
              initialModifiers={modifierSet.modifiers}
              itemsAppliedTo={modifierSet.itemsAppliedTo}
              businessType={businessType}
              businessId={businessId}
              onSave={handleSave}
              onDelete={handleDelete}
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
}
