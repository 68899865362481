import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { IMAGE_WIDGET_TYPES, IMAGE_WIDGET_DISPLAY_NAMES } from './ImageWidgetSelector.constants';

import './ImageWidgetSelector.scss';

const propTypes = {
  selectedType: PropTypes.oneOf(IMAGE_WIDGET_TYPES).isRequired,
  onSelect: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function ImageWidgetSelector({ selectedType, onSelect }) {
  return (
    <Form className="image-widget-selector">
      <Form.Group inline>
        {IMAGE_WIDGET_TYPES.map((widgetType) => (
          <Form.Radio
            key={widgetType}
            label={IMAGE_WIDGET_DISPLAY_NAMES[widgetType]}
            value={selectedType}
            checked={selectedType === widgetType}
            onChange={() => onSelect(widgetType)}
          />
        ))}
      </Form.Group>
    </Form>
  );
}

ImageWidgetSelector.propTypes = propTypes;
ImageWidgetSelector.defaultProps = defaultProps;
