import PropTypes from 'prop-types';

export const CatalogItemVariationPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceDisplay: PropTypes.string,
});

export const CatalogItemPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  localId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  description: PropTypes.string,
  fullDescription: PropTypes.string,
  variations: PropTypes.arrayOf(CatalogItemVariationPropType),
});

export const CatalogCategoryPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  localId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  description: PropTypes.string,
  fullDescription: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
});

export const CatalogPropType = PropTypes.shape({
  schedules: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(CatalogCategoryPropType),
  items: PropTypes.arrayOf(CatalogItemPropType),
  modifierSets: PropTypes.arrayOf(PropTypes.shape({})),
  modifiers: PropTypes.arrayOf(PropTypes.shape({})),
});
