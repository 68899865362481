import React, { useEffect, useState } from 'react';

import { Grid, Segment, Message } from 'semantic-ui-react';

import { useSelector } from 'react-redux';

import {
  CUSTOM_REPORTS_ERROR_MESSAGE_HEADER,
  CUSTOM_REPORTS_NO_CUSTOMERS_HEADER,
  CUSTOM_REPORTS_NO_CUSTOMERS_MESSAGE,
} from './CustomerReports.constants';
import CustomerReportsHeader from './components/CustomerReportsHeader/CustomerReportsHeader';
import CustomerReportsTable from './components/CustomerReportsTable/CustomerReportsTable';
import useDebounce from '../../../../../hooks/useDebounce/use-debounce';
import useMerchantProducts from '../../../../../hooks/useMerchantProducts';
import useOrderCustomerReports from '../../../../../hooks/useOrderCustomerReports';
import { selectActiveLocation } from '../../../../../selectors';

import './CustomerReports.scss';

const propTypes = {};

const defaultProps = {};

function CustomerReports() {
  const activeLocation = useSelector(selectActiveLocation);
  const [searchQuery, setSearchQuery] = useState('');
  const searchQueryDebounced = useDebounce(searchQuery, 500);
  const {
    loading: productsLoading,
    activeProduct: { id: productId },
  } = useMerchantProducts();

  const { id: locationId } = activeLocation;
  const initialFilters = {
    query: searchQuery,
    location: locationId,
    product: productId,
  };

  const {
    customers,
    currentPage,
    totalPages,
    loading: reportsLoading,
    filters,
    errorMessage,
    setCurrentPage,
    setFilters,
  } = useOrderCustomerReports(initialFilters);
  const loading = reportsLoading || productsLoading;

  function onPageChange(e, { activePage }) {
    setCurrentPage(activePage);
  }

  function onSearchQueryChange(e, { value }) {
    setSearchQuery(value);
  }

  function hasCustomers() {
    return customers.length > 0;
  }

  function shouldDisplayNoCustomerMessage() {
    return !loading && !hasCustomers() && !errorMessage;
  }

  function shouldDisplayErrorMessage() {
    return !loading && errorMessage;
  }

  function shouldDisplayReports() {
    return !shouldDisplayNoCustomerMessage() && !shouldDisplayErrorMessage();
  }

  function onUpdateFilters() {
    const updatedFilters = {
      query: searchQueryDebounced,
      location: locationId,
    };
    setFilters({
      ...filters,
      ...updatedFilters,
    });
  }

  useEffect(onUpdateFilters, [locationId, productId, searchQueryDebounced]);

  return (
    <Segment loading={loading} className="customer-reports-container">
      <Grid>
        <CustomerReportsHeader
          filterValue={searchQuery}
          onFilterCustomer={onSearchQueryChange}
          loading={loading}
        />
        <Grid.Row>
          {shouldDisplayErrorMessage() && (
            <Message error>
              <Message.Header>{CUSTOM_REPORTS_ERROR_MESSAGE_HEADER}</Message.Header>
              <Message.Content>{errorMessage}</Message.Content>
            </Message>
          )}
          {shouldDisplayNoCustomerMessage() && (
            <Message>
              <Message.Header>{CUSTOM_REPORTS_NO_CUSTOMERS_HEADER}</Message.Header>
              <Message.Content>{CUSTOM_REPORTS_NO_CUSTOMERS_MESSAGE}</Message.Content>
            </Message>
          )}
          {shouldDisplayReports() && (
            <CustomerReportsTable
              customers={customers}
              locationId={locationId}
              onPageChange={onPageChange}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
}

CustomerReports.propTypes = propTypes;
CustomerReports.defaultProps = defaultProps;

export default CustomerReports;
