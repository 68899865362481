import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';

import API from './api';
import ErrorHandler from './errors';
import { multiCaseGet } from './format';
import LogRocket from './logrocket/logrocket';
import Storage from './storage';
import { BRAND_DATA } from '../components/modules/foundation/hooks/use-branding/useBranding.constants';
import {
  CHOWNOW_SALES_GROUP_NAME,
  EZCATER_SALES_GROUP_NAME,
  CLOVER_SALES_GROUP_NAME,
  BBOT_SALES_GROUP_NAME,
  TOAST_SALES_GROUP_NAME,
  DISABLED_STACK_STATUS,
  ZUPPLER_SALES_GROUP_NAME,
  DAY_SMART_SALES_GROUP_NAME,
  ROLE_SALES,
  BUSINESS_OWNER_GROUP_NAME,
  ROLE_BUSINESS_OWNER,
} from '../constants/auth';

export async function logOut(navigate, nextRoute = null) {
  Storage.destroy();

  try {
    await API.logOutUser();
  } catch (e) {
    ErrorHandler.capture(e);
  } finally {
    if (nextRoute) {
      navigate(nextRoute);
    }
  }
}

function hasData(obj) {
  if (!obj) return false;
  return Object.keys(obj).length !== 0 || obj.constructor !== Object;
}

export function hasPermission(user, permission) {
  if (!user || !user.user_permissions) return false;
  return user.user_permissions.includes(permission);
}

export function hasEveryPermission(user, permissions = []) {
  return permissions.every((permission) => hasPermission(user, permission));
}

export function isAuthorizedByIDRange(business, lowest, highest = null) {
  if (business && lowest) {
    return highest ? business.id <= highest && business.id >= lowest : business.id >= lowest;
  }
  return true;
}

export function isAdminUser(user) {
  if (!user || _isEmpty(user)) return false;

  return multiCaseGet(user, 'is_superuser');
}

export function isAuthorizedForRole(businessRole, expectedRole) {
  return businessRole === expectedRole;
}

export function isUserInGroup(user, expectedGroup) {
  return _get(user, 'groups', []).includes(expectedGroup);
}

export function saveTokenExpirationTime(expirationTime) {
  if (expirationTime) {
    Storage.saveTokenExpirationTime(expirationTime);
  } else {
    Storage.clearTokenExpirationTime();
  }
}

export function isValidUser(user) {
  return user && user.id && Storage.isValidAuthSession();
}

export function hasToChangePassword(user) {
  return !!user.change_password;
}

// Ensure that name is normalized to allow for better grouping of sessions
function getLogrocketName(user = {}, business = {}) {
  const { first_name: firstName, last_name: lastName } = user;
  const { id: businessId, name: businessName } = business;

  if (user.is_superuser) {
    return `Admin | ${businessName}`;
  }

  if (!businessId || !businessName) {
    return `${firstName} ${lastName}`;
  }

  return `${firstName} ${lastName} | ${businessName}`;
}

export function identifyUserAndBusiness(user, business = {}) {
  if (isValidUser(user)) {
    const { email } = user;
    const { id: businessId, name: businessName } = business;
    const logrocketParams = {
      name: getLogrocketName(user, business),
      email,
      brandingType: process.env.REACT_APP_BRANDING || 'core',
      businessId,
      businessName,
    };

    LogRocket.identify(email, logrocketParams);
  }
}

export function identifyUsername(username) {
  const params = {
    email: username,
    brandingType: process.env.REACT_APP_BRANDING || 'core',
  };

  LogRocket.identify(username, params);
}

export function isUserReady(user, website, business, notifications) {
  return (
    hasData(user) &&
    hasData(website) &&
    hasData(business) &&
    hasData(notifications) &&
    Storage.isValidAuthSession()
  );
}

export function signupPathFromRole(user, userRole) {
  if (isAdminUser(user)) {
    return '/internal/sign-up/';
  }

  if (user && isAuthorizedForRole(userRole, ROLE_SALES) && user.groups) {
    if (isUserInGroup(user, CHOWNOW_SALES_GROUP_NAME)) {
      return '/chownow/reps/sign-up/';
    }
    if (isUserInGroup(user, EZCATER_SALES_GROUP_NAME)) {
      return '/ezcater/reps/sign-up/';
    }
    if (isUserInGroup(user, CLOVER_SALES_GROUP_NAME)) {
      return '/clover/reps/sign-up/';
    }
    if (isUserInGroup(user, BBOT_SALES_GROUP_NAME)) {
      return '/bbot/reps/sign-up/';
    }
    if (isUserInGroup(user, TOAST_SALES_GROUP_NAME)) {
      return '/toast/reps/sign-up/';
    }
    if (isUserInGroup(user, ZUPPLER_SALES_GROUP_NAME)) {
      return '/zuppler/reps/sign-up/';
    }
  }

  return '/';
}

export function getRolesForWebsiteUsers(websiteUsers = []) {
  const allRoles = websiteUsers.map((websiteUser) => websiteUser.user.groups).flat(1);

  return _uniq(allRoles);
}

export function isAuthorizedForSaleViews(userRole) {
  return isAuthorizedForRole(userRole, ROLE_SALES);
}

export function isUserInAnyGroup(user, groupNames) {
  const userGroups = _get(user, 'groups', []);
  const groupNamesSet = new Set(groupNames || []);
  return userGroups.some((userGroup) => groupNamesSet.has(userGroup));
}

export function hasFeatureSet(websiteFeatureSets, featureSet) {
  return websiteFeatureSets.includes(featureSet);
}

export function hasWebsiteFeatureSet(websiteFeatureSets, featureSets) {
  if (!featureSets || !featureSets.length) return true;

  return featureSets.some((featureSet) => hasFeatureSet(websiteFeatureSets, featureSet));
}

/**
 *
 * @param {*} business
 * @param {*} statusContext
 * @returns true if the business has all the status context as the one passed in
 *
 * What is the status context? It is some JSON data coming from backend
 * telling what is the status for some properties of the business like if
 * the menu is updated or if business files were created.
 */
function isAuthorizedForStatusContext(business, statusContext) {
  const { status_context: businessStatusContext = {} } = business;

  return Object.keys(statusContext).every(
    (key) => businessStatusContext && statusContext[key] === businessStatusContext[key],
  );
}

export function isAuthorizedForWaaS(user) {
  if (!user) {
    return false;
  }

  return (
    isAdminUser(user) ||
    isUserInGroup(user, ZUPPLER_SALES_GROUP_NAME) ||
    isUserInGroup(user, DAY_SMART_SALES_GROUP_NAME)
  );
}

export function isAuthorizedForWebsiteStackType(website, type) {
  if (!website || !website.stack_type) return false;
  return website.stack_type === type;
}

export function isWebsiteDisabled(restaurant, website) {
  return website.stack_status === DISABLED_STACK_STATUS;
}

export function doesUserHaveBusinessRole(business, role) {
  return business?.user_role === role;
}

/**
 *
 * @param {*} user
 * @param {*} business
 * @param {*} groupNames
 * @param {*} permissions
 * @param {*} statusContext
 * @returns true in any of these cases
 *  1. user is admin
 *  2. user has all permissions
 *  3. user is in at least one of the groupNames or has the business owner role
 *  4. user is authorized for the status context
 */
export function isAuthorized(
  user,
  business,
  groupNames = [],
  permissions = [],
  statusContext = {},
) {
  if (!user || !business) return false;
  if (isAdminUser(user)) return true;
  if (!hasEveryPermission(user, permissions)) return false;

  let userHasBusinessRole = false;
  let filteredGroupNames = groupNames;
  if (groupNames && groupNames.includes(BUSINESS_OWNER_GROUP_NAME)) {
    filteredGroupNames = filteredGroupNames.filter(
      (groupName) => groupName !== BUSINESS_OWNER_GROUP_NAME,
    );
    userHasBusinessRole = doesUserHaveBusinessRole(business, ROLE_BUSINESS_OWNER);
  }

  const isAuthorizedPerGroup = filteredGroupNames.length
    ? isUserInAnyGroup(user, filteredGroupNames)
    : true;
  const isAuthorizedPerStatusContext = isAuthorizedForStatusContext(business, statusContext);

  return (isAuthorizedPerGroup || userHasBusinessRole) && isAuthorizedPerStatusContext;
}

export function showPlanGatingForWebsite(user, websiteFeatureSets, featureSets, brandName) {
  if (brandName === _get(BRAND_DATA, 'GLAM.brandName')) {
    return false;
  }

  if (!featureSets) return false;
  if (isAdminUser(user)) return false;
  return !hasWebsiteFeatureSet(websiteFeatureSets, featureSets);
}

export function authRedirectFor(providerName, { queryParams = '' } = {}) {
  const baseURL = process.env.REACT_APP_FISHERMAN_API_PATH;
  const redirectUrl = `${baseURL}/accounts/${providerName}/login/${queryParams}`;
  window.location.href = redirectUrl;
}
