import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import SourceDropdown from '../SourceDropdown';

import './SaveSelectionHeader.scss';

const propTypes = {
  error: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onUploadFiles: PropTypes.func.isRequired,
};

const defaultProps = {
  error: null,
};

export default function SaveSelectionHeader({ onUpload, onUploadFiles, error }) {
  const { advancedImageEditing } = useFlags();

  return (
    <div>
      <span className="header-title">Edit Images</span>
      {advancedImageEditing && (
        <Button onClick={onUploadFiles} content="Add More Images" className="action-button" />
      )}
      {!advancedImageEditing && <SourceDropdown initialError={error} onUpload={onUpload} />}
    </div>
  );
}

SaveSelectionHeader.propTypes = propTypes;
SaveSelectionHeader.defaultProps = defaultProps;
