export const SERVER_ERROR_MESSAGE_SAVING =
  'Failed to update modifier set. Please try again and contact customer support if the error persists.';
export const FORM_ERROR_MESSAGE_HEADER = 'Modifier Set Is Not Valid';
export const FORM_ERROR_MESSAGE_NAME = 'Name cannot be blank.';
export const FROM_ERROR_MESSAGE_MINIMUM = 'Minimum value cannot be greater than maximum value.';
export const FROM_ERROR_MESSAGE_MAXIMUM = 'Maximum value cannot be less than minimumn value.';

export const NAME_INPUT_HELP_MESSAGE = 'Enter the name of the modifier set.';
export const LIMITS_INPUT_HELP_MESSAGE =
  'Enter the minimum and maximum number of modifiers that can be applied to the item.';
export const MODIFIERS_INPUT_HELP_MESSAGE = 'Add and edit item modifiers.';

export const NAME_INPUT_PLACEHOLDER = 'Example: Side Options';
export const MODIFIER_NAME_INPUT_PLACEHOLDER = 'Modifier Name';
export const MODIFIER_PRICE_INPUT_PLACEHOLDER = 'Price';

export const FORM_DELETING_STATUS = 'DELETE';
export const FORM_SAVING_STATUS = 'SAVE';

export const CONFIRMATION_DELETE_MODIFIER_MESSAGE =
  'Are you sure you want to delete this modifier?';
export const CONFIRMATION_DELETE_MODIFIER_SET_MESSAGE =
  'Are you sure you want to delete this modifier set?';
