/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Container, Form, Icon, Message, Modal } from 'semantic-ui-react';

import _clone from 'lodash/clone';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _reject from 'lodash/reject';
import { useSelector } from 'react-redux';

import {
  CATALOG_ITEM_FORM_NEW_TITLE,
  CATALOG_ITEM_FORM_EDIT_TITLE,
  FORM_DELETING_STATUS,
  FORM_SAVING_STATUS,
  CONFIRMATION_DELETE_MESSAGE,
} from './CatalogItemForm.constants';
import { useItemVariations } from './CatalogItemForm.hooks';
import {
  getInitialItemVariations,
  saveCatalogItem,
  saveCatalogImage,
  saveItemTaxes,
  saveItemVariations,
  saveItemModifierSets,
  getSearchableModifierSets,
  getModifierSetsToDelete,
  getModifierSetsToCreateOrUpdate,
  handleCatalogItemFormError,
} from './CatalogItemForm.utils';
import ItemModifierSetForm from './components/ItemModifierSetForm';
import ItemTaxForm from './components/ItemTaxForm';
import ItemVariationFormList from './components/ItemVariationFormList';
import API from '../../../../../libs/api';
import { selectActiveProduct } from '../../../../../selectors';
import CloseableModal from '../../../../common/CloseableModal';
import ConfirmModal from '../../../../common/ConfirmModal';
import FormInputInline from '../../../../common/FormInputInline';
import ImageField from '../../../../common/ImageField';
import {
  ITEM_CATALOG_OBJECT_TYPE,
  ITEM_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS,
  ITEM_SHORT_DESCRIPTION_MAX_LENGTH,
  ITEM_SHORT_DESCRIPTION_MIN_LENGTH,
  ITEM_SHORT_DESCRIPTION_PLACEHOLDER,
} from '../../../../core/menu/item/EditMenuItem/EditMenuItem.constants';
import CatalogObjectDescriptionInput from '../../../../fields/CatalogObjectDescriptionInput';
import AddPhotoButton from '../AddPhotoButton';
import EditPhotoButton from '../EditPhotoButton';
import { BUSINESS_TYPE_MERCHANTS, EMPTY_CATALOG_ITEM } from '../constants';
import {
  CatalogTaxPropType,
  CatalogItemPropType,
  CatalogItemVariationPropType,
  CatalogImagePropType,
  CatalogObjectGenericPropType,
  CatalogCategoryPropType,
} from '../proptypes';
import {
  getCatalogObjectId,
  filterCatalogObjectsByIds,
  extractModifierSetsFromCatalog,
  createNewModifierSet,
} from '../utils';

import './CatalogItemForm.scss';

const propTypes = {
  initialData: CatalogItemPropType,
  catalog: PropTypes.arrayOf(CatalogObjectGenericPropType),
  taxes: PropTypes.arrayOf(CatalogTaxPropType),
  images: PropTypes.arrayOf(CatalogImagePropType),
  variations: PropTypes.arrayOf(CatalogItemVariationPropType),
  categoryCatalog: CatalogCategoryPropType.isRequired,
  businessType: PropTypes.string,
  businessId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

const defaultProps = {
  initialData: EMPTY_CATALOG_ITEM,
  catalog: [],
  taxes: [],
  images: [],
  variations: [],
  businessType: BUSINESS_TYPE_MERCHANTS,
  onSubmit: () => null,
  onDelete: () => null,
};

function CatalogItemForm({
  businessType,
  businessId,
  categoryCatalog,
  initialData,
  catalog,
  taxes,
  images,
  variations,
  onSubmit,
  onDelete,
  ...closeableModalProps
}) {
  const mainImageUrl = _get(images, '[0].image_data.url', '');
  const mainImageId = _get(images, '[0].object_id', null);

  const [itemObjectId, setItemObjectId] = useState(_get(initialData, 'object_id'));
  const [hasSavedItem, setHasSavedItem] = useState(false);
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [image, setImage] = useState({ file: null, url: mainImageUrl, objectId: mainImageId });
  const [itemTaxes, setItemTaxes] = useState([]);
  const [coreItemFields, setCoreItemFields] = useState({
    name: '',
    description: '',
    visible: '',
    available: true,
  });
  const currentActiveProduct = useSelector(selectActiveProduct);
  const {
    itemVariations,
    addVariation,
    updateVariation,
    removeVariation,
    itemVariationsToRemove,
    resetVariations,
  } = useItemVariations([]);

  const [initialModifierSets, setInitialModifierSets] = useState([]);
  const [modifierSets, setModifierSets] = useState([]);
  const [searchableModifierSets, setSearchableModifierSets] = useState([]);
  const [isConfirmDeleteModalOpened, setIsConfirmDeleteModalOpened] = useState(false);

  function setInitialItemData(data) {
    const {
      item_data: { name, description, visible, available, taxes: initialItemTaxesIds },
      object_id: initialItemObjectId,
    } = data;
    const initialItemTaxes = filterCatalogObjectsByIds(taxes, initialItemTaxesIds);

    setItemObjectId(initialItemObjectId);
    setItemTaxes(initialItemTaxes);
    setCoreItemFields({ name, description, visible, available });
    resetVariations(getInitialItemVariations(itemObjectId, variations));
    setImage({ file: null, url: mainImageUrl, objectId: mainImageId });
    setHasSavedItem(false);
    setServerError(null);
    setFormHasErrors(false);

    if (itemObjectId) {
      const itemModifierSets = extractModifierSetsFromCatalog(catalog, itemObjectId);
      setInitialModifierSets(itemModifierSets);
      setModifierSets(itemModifierSets);
    } else {
      setInitialModifierSets([]);
      setModifierSets([]);
    }
  }

  useEffect(() => {
    if (itemObjectId) {
      const itemModifierSets = extractModifierSetsFromCatalog(catalog, itemObjectId);
      setInitialModifierSets(itemModifierSets);
      setModifierSets(itemModifierSets);
    }
  }, [catalog]);

  useEffect(() => {
    const catalogModifierSets = extractModifierSetsFromCatalog(catalog);
    const itemModifierListIds = modifierSets.map((modifierSet) =>
      getCatalogObjectId(modifierSet.modifierList),
    );
    setSearchableModifierSets(getSearchableModifierSets(catalogModifierSets, itemModifierListIds));
  }, [modifierSets]);

  useEffect(() => {
    resetVariations(getInitialItemVariations(itemObjectId, variations));
  }, [variations]);

  useEffect(() => {
    const newImageUrl = _get(images, '[0].image_data.url', '');
    const newImageId = _get(images, '[0].object_id', null);
    setImage({ file: null, url: newImageUrl, objectId: newImageId });
  }, [images]);

  useEffect(() => {
    setInitialItemData(initialData);
  }, [initialData]);

  const isNewItem = !itemObjectId;
  const { onClose: onCloseModal } = closeableModalProps;

  function onCloseModalForm() {
    onCloseModal(hasSavedItem);
  }

  function isFormValid(formData) {
    return !!formData.name;
  }

  function validateForm(formData) {
    setFormHasErrors(!isFormValid(formData));
  }

  function onChangeMainField(e, { name: fieldName, value }) {
    setCoreItemFields((prevItemFields) => {
      const newItemFields = {
        ...prevItemFields,
        [fieldName]: value,
      };
      validateForm(newItemFields);
      return newItemFields;
    });
  }

  function toggleItemTax(shouldAdd, tax) {
    if (shouldAdd) {
      setItemTaxes((prevTaxes) => [...prevTaxes, tax]);
    } else {
      setItemTaxes((prevTaxes) =>
        prevTaxes.filter((prevTax) => prevTax.object_id !== tax.object_id),
      );
    }
  }

  async function handleSave() {
    validateForm(coreItemFields);
    const isValid = isFormValid(coreItemFields);
    if (!isValid) {
      return;
    }

    setIsSubmitting(FORM_SAVING_STATUS);

    let savedItemData = null;
    try {
      savedItemData = await saveCatalogItem(
        businessType,
        businessId,
        currentActiveProduct,
        initialData,
        itemObjectId,
        categoryCatalog.object_id,
        coreItemFields,
      );
      setHasSavedItem(true);
    } catch (e) {
      handleCatalogItemFormError(e, setServerError);
    }

    if (savedItemData) {
      // if we've already saved the item then we don't need to update the
      // "from" object of these relations to preserve catalog relation history
      const shouldUpdateFromObject = false;
      try {
        const savedImageData = await saveCatalogImage(
          businessType,
          businessId,
          currentActiveProduct,
          savedItemData.object_id,
          image,
          images,
          shouldUpdateFromObject,
        );

        if (savedImageData) {
          savedItemData.images = [savedImageData.object_id];
        }

        const relatedTaxesIds = await saveItemTaxes(
          savedItemData.object_id,
          initialData.item_data.taxes,
          itemTaxes.map((tax) => tax.object_id),
          shouldUpdateFromObject,
        );
        savedItemData.taxes = relatedTaxesIds;

        await saveItemVariations(
          businessType,
          businessId,
          currentActiveProduct,
          savedItemData.object_id,
          itemVariations,
          itemVariationsToRemove,
        );

        // update relations for any removed modifier lists
        const modifierSetsToDelete = getModifierSetsToDelete(modifierSets, initialModifierSets);
        if (modifierSetsToDelete.length > 0) {
          const modifierListIdsToDelete = _map(modifierSetsToDelete, 'modifierList.object_id');
          await API.saveItemModifierListInfo(
            savedItemData.object_id,
            [],
            modifierListIdsToDelete,
            null,
            shouldUpdateFromObject,
          );
        }

        // update relations for any added modifier lists or updated modifier list relations
        const modifierSetsToCreateOrUpdate = getModifierSetsToCreateOrUpdate(
          modifierSets,
          initialModifierSets,
        );
        await saveItemModifierSets(
          savedItemData.object_id,
          modifierSetsToCreateOrUpdate,
          shouldUpdateFromObject,
        );

        await onSubmit(savedItemData);
      } catch (e) {
        setItemObjectId(savedItemData.object_id);
        handleCatalogItemFormError(e, setServerError);
      }
    }

    setIsSubmitting(null);
  }

  async function handleDelete() {
    setIsSubmitting(FORM_DELETING_STATUS);
    await API.removeCatalogObject(businessType, businessId, itemObjectId);
    await onDelete(initialData);
    setIsSubmitting(null);
    setIsConfirmDeleteModalOpened(false);
  }

  function handleCreateNewModifierSet() {
    const newModifierSet = createNewModifierSet(itemObjectId, isNewItem);
    setModifierSets((prevModifierSets) => [...prevModifierSets, newModifierSet]);
  }

  function handleSaveModifierSet(oldModifierListId, updatedModifierSet) {
    const newModifierSets = _clone(modifierSets);
    const indexToUpdate = _findIndex(
      newModifierSets,
      (modifierSet) => getCatalogObjectId(modifierSet.modifierList) === oldModifierListId,
    );
    newModifierSets[indexToUpdate] = updatedModifierSet;
    setModifierSets(newModifierSets);
  }

  function handleDeleteModifierSet(modifierList) {
    let newModifierSets = _clone(modifierSets);
    newModifierSets = _reject(
      newModifierSets,
      (modifierSet) =>
        getCatalogObjectId(modifierSet.modifierList) === getCatalogObjectId(modifierList),
    );
    setModifierSets(newModifierSets);
  }

  function handleAddExistingModifierSet(modifierSet) {
    setModifierSets((prevModifierSets) => [...prevModifierSets, modifierSet]);
  }

  const modalHeader = (
    <>
      <div className="catalog-item-modal-title">
        {isNewItem ? CATALOG_ITEM_FORM_NEW_TITLE : CATALOG_ITEM_FORM_EDIT_TITLE}
      </div>
      <div className="catalog-item-button-container">
        {!isNewItem && (
          <ConfirmModal
            onOpen={() => setIsConfirmDeleteModalOpened(true)}
            open={isConfirmDeleteModalOpened}
            trigger={
              <Button
                basic
                negative
                disabled={isSubmitting === FORM_SAVING_STATUS}
                loading={isSubmitting === FORM_DELETING_STATUS}
                className="catalog-item-delete-button"
                content="Delete"
                icon="trash"
              />
            }
            header="Delete Item"
            content={CONFIRMATION_DELETE_MESSAGE}
            confirmButton={<Button>Delete</Button>}
            onConfirm={handleDelete}
            onCancel={() => setIsConfirmDeleteModalOpened(false)}
          />
        )}
        <Button
          disabled={isSubmitting === FORM_DELETING_STATUS}
          loading={isSubmitting === FORM_SAVING_STATUS}
          className="catalog-item-save-button"
          onClick={handleSave}
          content="Save"
          icon="save"
        />
      </div>
    </>
  );

  return (
    <CloseableModal
      {...closeableModalProps}
      onClose={onCloseModalForm}
      header={modalHeader}
      className="catalog-item-form-modal"
    >
      <Modal.Content>
        <Container className="catalog-item-form-container">
          <Form>
            <input hidden type="submit" onClick={handleSave} />
            <Message hidden={!serverError} negative content={serverError} />
            <FormInputInline
              title="Name"
              required
              helpMessage="Enter the name of the Item that the Customer will order."
              error={formHasErrors ? 'This field is required' : null}
            >
              {(fieldWidth) => (
                <Form.Input
                  error={formHasErrors}
                  width={fieldWidth}
                  name="name"
                  value={coreItemFields.name}
                  placeholder="Item Name"
                  onChange={onChangeMainField}
                />
              )}
            </FormInputInline>
            <FormInputInline
              title="Available"
              required
              helpMessage="Set this to Not Available to inform it is currently out of stock"
            >
              {(fieldWidth) => (
                <Form.Dropdown
                  width={fieldWidth}
                  name="available"
                  placeholder="Select Availability"
                  selection
                  value={coreItemFields.available}
                  options={[
                    { text: 'Available', value: true },
                    { text: 'Not Available', value: false },
                  ]}
                  onChange={onChangeMainField}
                />
              )}
            </FormInputInline>
            <FormInputInline
              title="Description"
              titleAlign="top"
              helpMessage="Optionally add a description about the Item. This could include pre-defined preparation methods or side choices if applicable."
            >
              {(fieldWidth) => (
                <Form.Field width={fieldWidth}>
                  <CatalogObjectDescriptionInput
                    field={{
                      name: 'description',
                      placeholder: ITEM_SHORT_DESCRIPTION_PLACEHOLDER,
                      width: fieldWidth,
                    }}
                    catalogObject={{
                      name: coreItemFields.name,
                      type: ITEM_CATALOG_OBJECT_TYPE,
                      parentName: categoryCatalog.category_data.name,
                      description: coreItemFields.description,
                      prices: itemVariations,
                    }}
                    prompt={{
                      minOutputLength: ITEM_SHORT_DESCRIPTION_MIN_LENGTH,
                      maxOutputLength: ITEM_SHORT_DESCRIPTION_MAX_LENGTH,
                      additionalRequirements: ITEM_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS,
                    }}
                    onChange={(e, { name, value }) => {
                      onChangeMainField(e, { name, value });
                    }}
                  />
                </Form.Field>
              )}
            </FormInputInline>
            <FormInputInline
              title="Visible"
              helpMessage="Set this to hidden if you want to remove this item from website"
            >
              {(fieldWidth) => (
                <Form.Dropdown
                  name="visible"
                  width={fieldWidth}
                  placeholder="Select Visibility"
                  selection
                  value={coreItemFields.visible}
                  options={[
                    { text: 'Visible', value: true },
                    { text: 'Hidden', value: false },
                  ]}
                  onChange={onChangeMainField}
                />
              )}
            </FormInputInline>
            <FormInputInline
              title="Image"
              helpMessage="Optionally add an image for the Item. This will be shown to Customers when ordering."
            >
              {(fieldWidth) => (
                <ImageField onChange={(e, data) => setImage({ ...data[0] })}>
                  {(onClick) => (
                    <Form.Field>
                      {image && image.url ? (
                        <EditPhotoButton
                          className="photo-button"
                          width={fieldWidth}
                          onClick={onClick}
                          imageUrl={image.url}
                        >
                          <Icon name="pencil" />
                        </EditPhotoButton>
                      ) : (
                        <AddPhotoButton
                          className="photo-button"
                          width={fieldWidth}
                          onClick={onClick}
                        />
                      )}
                    </Form.Field>
                  )}
                </ImageField>
              )}
            </FormInputInline>
            <FormInputInline title="Price &amp; Variants" titleAlign="top">
              {(fieldWidth) => (
                <Form.Field className="flex-column flex-align-items-start" width={fieldWidth}>
                  <ItemVariationFormList
                    variations={itemVariations}
                    onChange={updateVariation}
                    onAdd={() => addVariation(itemObjectId)}
                    onRemove={removeVariation}
                    showDefault
                  />
                </Form.Field>
              )}
            </FormInputInline>
            <FormInputInline title="Taxes" titleAlign="top">
              {(fieldWidth) => (
                <Form.Field width={fieldWidth}>
                  {taxes.length === 0 && <p>No taxes available</p>}
                  {taxes.length > 0 && (
                    <ItemTaxForm
                      availableTaxes={taxes}
                      appliedTaxes={itemTaxes}
                      onToggleTax={toggleItemTax}
                    />
                  )}
                </Form.Field>
              )}
            </FormInputInline>
            <FormInputInline title="Modifier Sets" titleAlign="top">
              {(fieldWidth) => (
                <Form.Field className="flex-column flex-align-items-start" width={fieldWidth}>
                  <ItemModifierSetForm
                    modifierSets={modifierSets}
                    searchableModifierSets={searchableModifierSets}
                    businessType={businessType}
                    businessId={businessId}
                    onCreateNewSet={handleCreateNewModifierSet}
                    onAddExistingSet={handleAddExistingModifierSet}
                    onSave={handleSaveModifierSet}
                    onDelete={handleDeleteModifierSet}
                  />
                </Form.Field>
              )}
            </FormInputInline>
          </Form>
        </Container>
      </Modal.Content>
    </CloseableModal>
  );
}

CatalogItemForm.propTypes = propTypes;
CatalogItemForm.defaultProps = defaultProps;

export default CatalogItemForm;
