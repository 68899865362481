import { createSelector } from '@reduxjs/toolkit';

import { componentConfigurationAdapter } from '../slices/ComponentConfiguration.slice';

export const {
  selectAll: selectComponentConfigurations,
  selectById: selectComponentConfigurationById,
} = componentConfigurationAdapter.getSelectors((state) => state.componentConfigurations);

export const selectComponentConfigurationsByPageType = createSelector(
  [selectComponentConfigurations, (state, pageType) => pageType],
  (componentConfigurations, pageType) =>
    componentConfigurations.filter(
      (componentConfiguration) => componentConfiguration.pageType === pageType,
    ),
);

export const selectComponentConfigurationsByFastId = createSelector(
  [selectComponentConfigurations, (state, fastId) => fastId],
  (componentConfigurations, fastId) =>
    componentConfigurations.filter(
      (componentConfiguration) => componentConfiguration.fastId === fastId,
    ),
);

export const selectComponentConfigurationsByParams = createSelector(
  [selectComponentConfigurations, (state, params) => params],
  (componentConfigurations, params) => {
    return componentConfigurations.filter((componentConfiguration) =>
      Object.keys(params).every((key) => componentConfiguration[key] === params[key]),
    );
  },
);
