import React from 'react';

import PropTypes from 'prop-types';
import { Select, Table } from 'semantic-ui-react';

const propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  timezones: PropTypes.arrayOf(PropTypes.string).isRequired,
  onTimezoneChange: PropTypes.func.isRequired,
};

function TimezoneField({ currentTimezone, timezones, onTimezoneChange }) {
  function handleTimezoneChange(fieldValue) {
    onTimezoneChange(fieldValue);
  }

  function formatTimezones() {
    return timezones.map((timezone) => {
      return {
        key: timezone,
        value: timezone,
        text: timezone.replace('_', ' '),
      };
    });
  }

  return (
    <Table compact celled definition>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={4}>Time Zone</Table.Cell>
          <Table.Cell width={12}>
            <Select
              fluid
              name="timezone"
              placeholder="Select your timezone"
              options={formatTimezones()}
              onChange={(e, { value }) => handleTimezoneChange(value)}
              value={currentTimezone}
              search
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

TimezoneField.propTypes = propTypes;

export default TimezoneField;
