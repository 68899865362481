import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Card, Image, Icon, Placeholder } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import _isNil from 'lodash/isNil';

import { isPdfFile } from 'components/modules/files/services/files';
import { ChildrenPropType } from 'libs/proptypes';

import FileSourceLabel from './components/FileSourceLabel';
import Utils from '../../../libs/utils';

import './ImageCard.scss';

const propTypes = {
  fileId: PropTypes.number,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  source: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.objectOf(PropTypes.string),
  actionsComponentLeft: ChildrenPropType,
  actionsComponentRight: ChildrenPropType,
  width: PropTypes.number,
  onLoad: PropTypes.func,
};

const defaultProps = {
  fileId: null,
  title: '',
  source: '',
  onClick: null,
  actionsComponentRight: null,
  style: {},
  actionsComponentLeft: null,
  width: 500,
  onLoad: () => null,
};

export default function ImageCard({
  fileId,
  url,
  title,
  source,
  onClick,
  style,
  showSourceTags,
  showFileNames,
  actionsComponentLeft,
  actionsComponentRight,
  width,
  onLoad,
}) {
  const [showRightActions, setShowRightActions] = useState(false);

  const pointerClass = onClick ? 'pointer' : '';
  const shouldDisplaySourceTag = showSourceTags && !showFileNames;
  const shouldShowFileName = !!title && showFileNames;

  let finalSrc = url;
  if (!_isNil(width)) {
    finalSrc = Utils.addImageQueryParam(finalSrc, 'width', width);
  }

  const record = { id: fileId };

  const urlIsEmpty = _isEmpty(url) || _isNil(url);
  const isAPdf = !urlIsEmpty && isPdfFile(finalSrc);
  const isAnImage = !urlIsEmpty && !isAPdf;

  function onShowRightActions() {
    setShowRightActions(true);
  }

  function onHideRightActions() {
    setShowRightActions(false);
  }

  return (
    <Card
      className="image-card"
      raised={false}
      fluid
      onClick={onClick}
      onMouseEnter={onShowRightActions}
      onMouseLeave={onHideRightActions}
    >
      {urlIsEmpty && (
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
      )}
      {isAPdf && (
        <div
          className={`pdf-file-container ${pointerClass}`}
          style={{
            cursor: onClick && 'pointer',
            ...style,
          }}
        >
          <Icon name="file pdf outline" size="huge" disabled />
        </div>
      )}
      {isAnImage && (
        <>
          <Image
            size="small"
            src={finalSrc}
            className={`image-file-container ${pointerClass}`}
            style={style}
            onLoad={onLoad}
          />
          {!_isNil(actionsComponentLeft) && (
            <div className="top-left">
              {_isFunction(actionsComponentLeft)
                ? actionsComponentLeft(record)
                : actionsComponentLeft}
            </div>
          )}
          {!_isNil(actionsComponentRight) && showRightActions && (
            <div className="top-right">
              {_isFunction(actionsComponentRight)
                ? actionsComponentRight(record)
                : actionsComponentRight}
            </div>
          )}
          {shouldDisplaySourceTag && <FileSourceLabel source={source} />}
          {shouldShowFileName && (
            <Card.Content className="file-name">
              <Card.Description>{title}</Card.Description>
            </Card.Content>
          )}
        </>
      )}
    </Card>
  );
}

ImageCard.propTypes = propTypes;
ImageCard.defaultProps = defaultProps;
