export const TEXT_BLOCK_ELEMENT_TYPE = 'TextBlock';
export const IMAGE_BLOCK_ELEMENT_TYPE = 'ImageBlock';
export const LEGACY_FORM_BLOCK_ELEMENT_TYPE = 'FormBlock'; // deprecated
export const FORM_BLOCK_ELEMENT_TYPE = 'CustomFormBlock';
export const FORM_FIELD_ELEMENT_TYPE = 'FormField';
export const JSON_BLOCK_ELEMENT_TYPE = 'JsonBlock';
export const PAGE_ELEMENT_TYPE = 'Page';
export const GRID_ELEMENT_TYPE = 'Grid';
export const ROW_ELEMENT_TYPE = 'Row';
export const COLUMN_ELEMENT_TYPE = 'Column';
export const EMPTY_BLOCK_ELEMENT_TYPE = 'EmptyBlock';
export const SEGMENT_ELEMENT_TYPE = 'Segment';

export const TEXT_BLOCK_COMPONENT_NAME = 'MarkdownContent';
export const IMAGE_COMPONENT_NAME = 'Image';
export const CAROUSEL_IMAGE_COMPONENT_NAME = 'BasicCarousel';
export const PAGINATED_GRID_IMAGE_COMPONENT_NAME = 'PaginatedGrid';
export const GRID_COMPONENT_NAME = 'Grid';
export const FORM_BLOCK_COMPONENT_NAME = 'CustomForm';
export const FORM_BLOCK_FAST_COMPONENT_NAME = 'DynamicForm';
export const LEGACY_FORM_BLOCK_COMPONENT_NAME = 'ContactForm'; // deprecated
export const ROW_COMPONENT_NAME = 'Grid.Row';
export const COLUMN_COMPONENT_NAME = 'Grid.Column';
export const EMPTY_BLOCK_COMPONENT_NAME = 'div';
export const SEGMENT_COMPONENT_NAME = 'Segment';
export const FRAGMENT_COMPONENT_NAME = 'React.Fragment';

export const FISHERMAN_COMPONENT_TYPE = 'FISHERMAN';
export const SEMANTIC_COMPONENT_TYPE = 'SEMANTIC';
export const JSX_COMPONENT_TYPE = 'JSX';

export const ELEMENT_TYPE = {
  [TEXT_BLOCK_ELEMENT_TYPE]: TEXT_BLOCK_ELEMENT_TYPE,
  [IMAGE_BLOCK_ELEMENT_TYPE]: IMAGE_BLOCK_ELEMENT_TYPE,
  [GRID_ELEMENT_TYPE]: GRID_ELEMENT_TYPE,
  [LEGACY_FORM_BLOCK_ELEMENT_TYPE]: LEGACY_FORM_BLOCK_ELEMENT_TYPE,
  [FORM_BLOCK_ELEMENT_TYPE]: FORM_BLOCK_ELEMENT_TYPE,
  [FORM_FIELD_ELEMENT_TYPE]: FORM_FIELD_ELEMENT_TYPE,
  [ROW_ELEMENT_TYPE]: ROW_ELEMENT_TYPE,
  [COLUMN_ELEMENT_TYPE]: COLUMN_ELEMENT_TYPE,
  [PAGE_ELEMENT_TYPE]: PAGE_ELEMENT_TYPE,
  [EMPTY_BLOCK_ELEMENT_TYPE]: EMPTY_BLOCK_ELEMENT_TYPE,
  [SEGMENT_ELEMENT_TYPE]: SEGMENT_ELEMENT_TYPE,
  [JSON_BLOCK_ELEMENT_TYPE]: JSON_BLOCK_ELEMENT_TYPE,
};

export const COMPONENT_NAME_BY_ELEMENT_TYPE = {
  [TEXT_BLOCK_COMPONENT_NAME]: TEXT_BLOCK_ELEMENT_TYPE,
  [IMAGE_COMPONENT_NAME]: IMAGE_BLOCK_ELEMENT_TYPE,
  [CAROUSEL_IMAGE_COMPONENT_NAME]: IMAGE_BLOCK_ELEMENT_TYPE,
  [PAGINATED_GRID_IMAGE_COMPONENT_NAME]: IMAGE_BLOCK_ELEMENT_TYPE,
  [GRID_COMPONENT_NAME]: GRID_ELEMENT_TYPE,
  [LEGACY_FORM_BLOCK_COMPONENT_NAME]: LEGACY_FORM_BLOCK_ELEMENT_TYPE,
  [FORM_BLOCK_COMPONENT_NAME]: FORM_BLOCK_ELEMENT_TYPE,
  [FORM_BLOCK_FAST_COMPONENT_NAME]: FORM_BLOCK_ELEMENT_TYPE,
  [ROW_COMPONENT_NAME]: ROW_ELEMENT_TYPE,
  [COLUMN_COMPONENT_NAME]: COLUMN_ELEMENT_TYPE,
  [EMPTY_BLOCK_COMPONENT_NAME]: EMPTY_BLOCK_ELEMENT_TYPE,
  [SEGMENT_COMPONENT_NAME]: SEGMENT_ELEMENT_TYPE,
  [FRAGMENT_COMPONENT_NAME]: JSON_BLOCK_ELEMENT_TYPE,
};

export const FIXED_COLUMN_SIZE_MODE = 'Fixed';
export const AUTO_ADJUSTED_COLUMN_SIZE_MODE = 'Auto-adjusted';
export const MAX_COLUMN_WIDTHS_COUNT = 16;

export const TYPE_CHILDREN_KEYS = {
  [TEXT_BLOCK_ELEMENT_TYPE]: [],
  [IMAGE_BLOCK_ELEMENT_TYPE]: [],
  [GRID_ELEMENT_TYPE]: ['rows'],
  [LEGACY_FORM_BLOCK_ELEMENT_TYPE]: [],
  [ROW_ELEMENT_TYPE]: ['columns'],
  [COLUMN_ELEMENT_TYPE]: ['segments'],
  [PAGE_ELEMENT_TYPE]: ['components'],
  [EMPTY_BLOCK_ELEMENT_TYPE]: [],
  [SEGMENT_ELEMENT_TYPE]: ['elements'],
  [JSON_BLOCK_ELEMENT_TYPE]: [],
};
