import _isEmpty from 'lodash/isEmpty';

export function getActivePage(pages, activePage) {
  if (_isEmpty(pages)) {
    return null;
  }
  if (!activePage) {
    return pages[0];
  }
  return pages.find((page) => page.title === activePage.title);
}
