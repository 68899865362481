import API from './api';
import { FULFILLMENT_PROVIDER_SHIPPO } from '../constants/fulfillments';

function shippoConnect({ businessType, businessId }) {
  const shippoAuthURL = API.getShippoAuthenticateURL(businessType, businessId);
  window.location.assign(shippoAuthURL);
}

const providerToMethod = {
  [FULFILLMENT_PROVIDER_SHIPPO]: shippoConnect,
};

export function getProviderConnectMethod(providerName, data) {
  const method = providerToMethod[providerName];
  return () => method(data);
}

function genericDisconnectMethod(serviceSettings, disconnectProviderSettings, data) {
  const { businessType, businessId } = data;
  serviceSettings.forEach(async (settings) => {
    await API.deleteProviderServiceSettings(businessId, businessType, settings.id);
    disconnectProviderSettings(settings);
  });
}

const providerToDisconnectMethod = {};

export function getProviderDisconnectMethod(
  providerName,
  disconnectProviderSettings,
  serviceSettings,
  data,
) {
  const disconnectMethod = providerToDisconnectMethod[providerName] || genericDisconnectMethod;
  return () => disconnectMethod(serviceSettings, disconnectProviderSettings, data);
}
