import React, { useState } from 'react';

import { Icon } from 'semantic-ui-react';

import { isExternalPage } from '../../../../../pages/services/pages';
import { PagePropType } from '../../../../../pages/types/page';
import EditPageGroupModal from '../../../EditPageGroupModal';
import EditPageModal from '../../../EditPageModal';

import './NavigationItemEdit.scss';

const propTypes = {
  page: PagePropType.isRequired,
};
const defaultProps = {};

export default function NavigationItemEdit({ page }) {
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  const isExternal = isExternalPage(page);

  const { isGroup } = page;
  const ComponentModal = isGroup ? EditPageGroupModal : EditPageModal;

  return (
    <div className="navigation-item-edit">
      {showModal && (
        <ComponentModal
          open={showModal}
          onClose={closeModal}
          page={page}
          external={isExternal}
          add={false}
        />
      )}
      <Icon name="pencil" onClick={openModal} />
    </div>
  );
}

NavigationItemEdit.propTypes = propTypes;
NavigationItemEdit.defaultProps = defaultProps;
