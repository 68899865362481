import React from 'react';

import PropTypes from 'prop-types';

import { TEST_DATA_DESCRIPTION, TEST_DATA_TITLE } from './WebsiteAnalyticsActionBar.constants';
import HelpTooltip from '../../../../common/HelpTooltip';

import './WebsiteAnalyticsActionBar.scss';

const environment = process.env.REACT_APP_FISHERMAN_ENV;

const propTypes = {
  show: PropTypes.bool,
};

const defaultProps = {
  show: true,
};

export default function WebsiteAnalyticsActionBar({ show }) {
  if (!show) {
    return null;
  }

  const isRealData = ['production'].includes(environment);

  return (
    <div className="action-bar-container">
      {!isRealData && (
        <HelpTooltip
          title={TEST_DATA_TITLE}
          description={TEST_DATA_DESCRIPTION}
          position="top left"
        />
      )}
    </div>
  );
}

WebsiteAnalyticsActionBar.propTypes = propTypes;
WebsiteAnalyticsActionBar.defaultProps = defaultProps;
