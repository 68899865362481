import Validate from '../../../../../libs/validate';
import store from '../../../../../store/store';

export function updateAllInputValidationAndReturnIfValid(validateOnBlur) {
  const state = store.getState();
  const rules = Validate.inputValidationRules.Website;
  const routes = Object.keys(rules);

  let allInputsValid = true;

  routes.forEach((route) => {
    const inputsForThisRoute = Object.keys(rules[route]);
    inputsForThisRoute.forEach((inputName) => {
      const inputObj = rules[route][inputName];
      if (inputObj.isArray) {
        // Cannot determine how many items are in the array, so check up to 5
        for (let i = 0; i < 5; i += 1) {
          const routeAndInput = `${route}.${inputName}.${i}`;
          // eslint-disable-next-line no-bitwise
          allInputsValid &= validateOnBlur({
            state,
            routeAndInput,
          });
        }
      } else {
        const routeAndInput = `${route}.${inputName}`;
        // eslint-disable-next-line no-bitwise
        allInputsValid &= validateOnBlur({
          state,
          routeAndInput,
        });
      }
    });
  });

  return allInputsValid;
}
