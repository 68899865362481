import _get from 'lodash/get';

import { PAGE_TYPE_EXTERNAL, BEHAVIOR_CLICKTHRU_TYPES, BEHAVIOR_CTA_TYPES } from './constants';
import { multiCaseGet } from '../../../libs/format/case';

export function filterWebsiteInteractionsByBehaviorTypes(interactions, behaviorTypes) {
  return (interactions || []).filter((interaction) => {
    const behaviorType = multiCaseGet(interaction, 'behaviorType');

    return behaviorTypes.includes(behaviorType);
  });
}

export function filterClickthruInteractions(interactions) {
  return filterWebsiteInteractionsByBehaviorTypes(interactions, BEHAVIOR_CLICKTHRU_TYPES);
}

export function filterNonClickthruInteractions(interactions) {
  return filterWebsiteInteractionsByBehaviorTypes(interactions, BEHAVIOR_CTA_TYPES);
}

export function ensureSlashes(relativeURL) {
  let url = relativeURL || '';
  if (!url.startsWith('/')) {
    url = `/${url}`;
  }
  if (!url.endsWith('/')) {
    url = `${url}/`;
  }
  return url;
}

export function getPageUrl(page) {
  const { url = '', slug = '' } = page || {};
  const pageType = _get(page, 'pageType', PAGE_TYPE_EXTERNAL);
  return pageType === PAGE_TYPE_EXTERNAL ? url : slug;
}
