export const VALUE_KEY = 'value';
export const RESOLVE_KEY = 'resolve';
export const SEMANTIC_COPY_ICON = 'copy';
export const SEMANTIC_PASTE_ICON = 'paste';
export const SEMANTIC_DELETE_ICON = 'delete';
export const JSON_KEY = 'json';

export const recipeFieldsEditorMode = [
  {
    nodeType: 'PAGE',
    field: 'style',
    editorMode: 'css',
  },
  {
    nodeType: 'LAYOUT',
    field: 'style',
    editorMode: 'css',
  },
];
