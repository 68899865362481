/* eslint-disable no-underscore-dangle */

import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

import {
  SEMANTIC_COPY_ICON,
  SEMANTIC_PASTE_ICON,
  SEMANTIC_DELETE_ICON,
} from './ObjectOptionsHeader.constants';
import {
  getOptionHeaderName,
  isStaticField,
  getCssActiveClassName,
  getCodeEditorMode,
} from './ObjectOptionsHeader.utils';
import OptionHeader from './components/OptionHeader';
import { RecipeContext } from '../../context/RecipeContext';
import { getObjectPathById, isMetadataOnlyNode } from '../../services/recipe-utils';
import ModalInsertHere from '../modals/ModalInsertHere';

import './ObjectOptionsHeader.scss';

const propTypes = {
  recipeObjectKey: PropTypes.string.isRequired,
  recipePart: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]).isRequired,
  parentId: PropTypes.string.isRequired,
  isObject: PropTypes.bool,
  toggleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  isNodePathSelected: PropTypes.bool,
};

const defaultProps = {
  toggleOpen: null,
  isOpen: false,
  isObject: true,
  isNodePathSelected: false,
};

const ObjectOptionsHeader = ({
  recipeObjectKey,
  recipePart,
  toggleOpen,
  isObject,
  isOpen,
  parentId,
  isNodePathSelected,
}) => {
  const [displayInsertModal, setDisplayInsertModal] = useState(false);
  const {
    setActiveNode,
    copyToClipboard,
    insertKeyValueIntoObject,
    deleteValueFromObject,
    recipe,
    setEditorMode,
    getParentNode,
  } = useContext(RecipeContext);

  function handleActiveNodeSelect() {
    const editorMode = getCodeEditorMode(recipeObjectKey, parentId, getParentNode);

    setEditorMode(editorMode);

    setActiveNode({
      objectKey: recipeObjectKey,
      parentId,
      value: recipePart,
    });

    if (toggleOpen && isObject) {
      toggleOpen();
    }
  }

  useEffect(() => {
    if (isNodePathSelected) {
      handleActiveNodeSelect();
    }
  }, []);

  function handleNewActiveNodeAfterDeletion(path, node) {
    // retrieving the last element of the path which is the parent key of the previous node
    const newObjectKey = path.slice(-1)[0];

    const newParent = _get(recipe, path.slice(0, -1)); // retrieving the new parent node
    let newParentId = newParent.__id__;

    if (_isArray(newParent)) {
      newParentId = newParent[0].__id__;
    }

    setActiveNode({
      objectKey: newObjectKey,
      parentId: newParentId,
      value: node,
    });
  }

  function handleDeleteNode() {
    const { found, path } = getObjectPathById(recipe, parentId);
    let parentNode = null;

    if (!found) {
      return;
    }

    parentNode = _get(recipe, path);

    if (isMetadataOnlyNode(parentNode)) {
      path.pop();
      parentNode = _get(recipe, path);
    }

    deleteValueFromObject(recipeObjectKey, parentId);
    handleNewActiveNodeAfterDeletion(path, parentNode);
  }

  useEffect(() => {
    if (isNodePathSelected) {
      handleActiveNodeSelect();
    }
  }, []);

  return (
    <>
      {!isStaticField(recipePart) && (
        <div className={`object-header ${getCssActiveClassName(isNodePathSelected)}`}>
          <div>
            <button
              onClick={() => handleActiveNodeSelect()}
              className="object-header-name"
              type="button"
            >
              <Icon name={isOpen ? 'caret down' : 'caret right'} />
              <span>{getOptionHeaderName(recipeObjectKey, recipePart)}</span>
            </button>
          </div>

          {isObject && (
            <div className="object-header-dropdown">
              <OptionHeader>
                <OptionHeader.Item
                  icon={SEMANTIC_COPY_ICON}
                  text="Copy to clipboard"
                  onClick={() => copyToClipboard(recipePart)}
                />
                <OptionHeader.Item
                  icon={SEMANTIC_PASTE_ICON}
                  text="Insert Here"
                  onClick={() => setDisplayInsertModal(true)}
                />
                <OptionHeader.Item
                  icon={SEMANTIC_DELETE_ICON}
                  text="Delete"
                  onClick={handleDeleteNode}
                />
              </OptionHeader>
            </div>
          )}
        </div>
      )}
      <ModalInsertHere
        open={displayInsertModal}
        onInsert={(node) => insertKeyValueIntoObject(recipeObjectKey, node, parentId)}
        onClose={() => setDisplayInsertModal(false)}
      />
    </>
  );
};

ObjectOptionsHeader.propTypes = propTypes;
ObjectOptionsHeader.defaultProps = defaultProps;

export default ObjectOptionsHeader;
