import PropTypes from 'prop-types';

import { LegacyFormBlockPropType, FormBlockPropType } from './form-block.types';
import { GridPropType } from './grid.types';
import { ImageBlockPropType } from './image-block.types';
import { TextBlockPropType } from './text-block.types';

export const PageElementPropType = PropTypes.shape({
  style: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  layout: PropTypes.string,
  slug: PropTypes.string,
  components: PropTypes.arrayOf(
    PropTypes.oneOfType([
      GridPropType,
      ImageBlockPropType,
      TextBlockPropType,
      LegacyFormBlockPropType,
      FormBlockPropType,
    ]),
  ),
});
