export const DISTANCE_UNIT_INCHES = 'INCHES';
export const DISTANCE_UNIT_CENTIMETERS = 'CENTIMETERS';
export const DISTANCE_UNIT_MILLIMETERS = 'MILLIMETERS';

export const MASS_UNIT_GRAMS = 'GRAMS';
export const MASS_UNIT_KILOGRAMS = 'KILOGRAMS';
export const MASS_UNIT_POUNDS = 'POUNDS';
export const MASS_UNIT_OUNCE = 'OUNCE';

export const SEE_DETAILS_BUTTON_CONTENT = 'SHIPPING DETAILS';

export const DISTANCE_UNIT_OPTIONS = [
  { key: DISTANCE_UNIT_INCHES, value: DISTANCE_UNIT_INCHES, text: 'Inches' },
  { key: DISTANCE_UNIT_CENTIMETERS, value: DISTANCE_UNIT_CENTIMETERS, text: 'Centimeters' },
  { key: DISTANCE_UNIT_MILLIMETERS, value: DISTANCE_UNIT_MILLIMETERS, text: 'Millimeters' },
];

export const MASS_UNIT_OPTIONS = [
  { key: MASS_UNIT_GRAMS, value: MASS_UNIT_GRAMS, text: 'Grams' },
  { key: MASS_UNIT_KILOGRAMS, value: MASS_UNIT_KILOGRAMS, text: 'Kilograms' },
  { key: MASS_UNIT_POUNDS, value: MASS_UNIT_POUNDS, text: 'Pounds' },
  { key: MASS_UNIT_OUNCE, value: MASS_UNIT_OUNCE, text: 'Ounce' },
];
