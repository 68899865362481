/* eslint-disable no-underscore-dangle */

import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isNumber from 'lodash/isNumber';
import _isObject from 'lodash/isObject';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import semver from 'semver';
import { v4 as uuidv4 } from 'uuid';

import { resolveType } from './types';
import readonlyFields from '../constants/readonlyfields';

function moveItemInArray(array, oldIndex, newIndex) {
  const item = array[oldIndex];
  array.splice(oldIndex, 1);
  array.splice(newIndex, 0, item);
}

export function getObjectPathById(obj, idToFind, path = []) {
  // First check this object or array's __id__
  const isArrayAndHasId = _isArray(obj) && obj.some((i) => i.__id__ === idToFind);
  const isObjectAndHasId = _isObject(obj) && obj.__id__ === idToFind;

  if (isArrayAndHasId) {
    // Find index of child that contains the ID
    const index = _findIndex(obj, (i) => i.__id__ === idToFind);
    path.push(index);
    return {
      found: true,
      path,
    };
  }
  if (isObjectAndHasId) {
    return {
      found: true,
      path,
    };
  }

  // If this object's __id__ doesn't match, look recursively.
  const objKeys = Object.keys(obj);
  for (let x = 0; x < objKeys.length; x += 1) {
    const key = objKeys[x];
    const childObj = obj[key];

    if (_isObject(childObj) || _isArray(childObj)) {
      const result = getObjectPathById(childObj, idToFind, path);
      if (result.found) {
        path.unshift(key);
        return {
          found: true,
          path,
        };
      }
    }
  }

  return {
    found: false,
    path,
  };
}

export function resortArray(fullRecipe, key, parentId, oldIndex, newIndex) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return fullRecipe;
  }

  path.pop();

  const array = _get(fullRecipe, path);
  moveItemInArray(array, oldIndex, newIndex);
  const newFullRecipe = _set(fullRecipe, path, array);
  return newFullRecipe;
}

export function isMetadataOnlyNode(node) {
  return Object.keys(node).length === 2 && node.__id__ && node.__type__;
}

export function isMetadataKey(key) {
  return ['__id__', '__type__'].includes(key);
}

export function removeNodeMetadata(recipe) {
  if (_isArray(recipe)) {
    return recipe
      .filter((element) => !isMetadataOnlyNode(element))
      .map((element) => removeNodeMetadata(element));
  }
  if (_isObject(recipe)) {
    return Object.keys(recipe).reduce((acc, key) => {
      if (!isMetadataKey(key)) {
        return { ...acc, [key]: removeNodeMetadata(recipe[key]) };
      }
      return acc;
    }, {});
  }

  return recipe;
}

export function addNodeMetadata(recipe, path = []) {
  // Don't add an ID in this node if there is one already present
  if (_isArray(recipe) && recipe.length > 0 && recipe[0].__id__) {
    return recipe.map((element) => addNodeMetadata(element, path));
  }
  if (_isObject(recipe) && Object.keys(recipe).includes('__id__')) {
    return Object.keys(recipe).reduce((acc, key) => {
      return { ...acc, [key]: addNodeMetadata(recipe[key], [...path, key]) };
    }, {});
  }

  if (_isArray(recipe)) {
    return [
      { __id__: uuidv4(), __type__: resolveType(recipe, path) },
      ...recipe.map((element) => addNodeMetadata(element, path)),
    ];
  }
  if (_isObject(recipe)) {
    return {
      __id__: uuidv4(),
      __type__: resolveType(recipe, path),
      ...Object.keys(recipe).reduce((acc, key) => {
        return { ...acc, [key]: addNodeMetadata(recipe[key], [...path, key]) };
      }, {}),
    };
  }

  return recipe;
}

export function getLayoutNames(recipe) {
  const layouts = _get(recipe, 'recipe.layouts');
  if (_isObject(layouts)) {
    return Object.keys(layouts)
      .filter((l) => l !== '__id__')
      .map((l) => {
        return {
          key: l,
          value: l,
          text: l,
        };
      });
  }
  return null;
}

function isObject(value) {
  return _isObject(value) && !_isArray(value);
}

function isArray(value) {
  return _isArray(value);
}

export function getValueType(value) {
  if (isObject(value)) {
    return 'object';
  }
  if (isArray(value)) {
    return 'array';
  }
  return typeof value;
}

export function nodeInPath(selectedPath, nodePath) {
  return selectedPath.slice(0, nodePath.length).join('.') === nodePath.join('.');
}

export function isReadonly(fullRecipe, key, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return false;
  }

  if (path.length === 0) {
    return readonlyFields.some((rof) => {
      return key === rof.key;
    });
  }

  const pathStr = path.toString();
  return readonlyFields.some((rof) => {
    return key === rof.key && pathStr === `recipe,${rof.path}`;
  });
}

export function getParentName(fullRecipe, parentId) {
  const { path } = getObjectPathById(fullRecipe, parentId);
  const lastItem = path[path.length - 1];
  if (_isNumber(lastItem)) {
    path.pop();
  }
  return path.pop();
}

export function deleteValueFromObject(fullRecipe, key, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return fullRecipe;
  }

  // To know how to delete this we need to know if the parent is an object
  // or an array. In case of arrays, the path is actually to index 0, which
  // only contains a __id__ property. Here we try to detect this.
  const objectAtPath = _get(fullRecipe, path);
  if (isMetadataOnlyNode(objectAtPath)) {
    path.length -= 1;
    const parentArray = _get(fullRecipe, path);
    parentArray.splice(parseInt(key, 10) + 1, 1);
    return fullRecipe;
  }

  _unset(fullRecipe, [...path, key]);
  return fullRecipe;
}

export function updateValueInObject(fullRecipe, key, value, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return fullRecipe;
  }

  if (path.length === 0) {
    return { ...fullRecipe, [key]: value };
  }

  const objectAtPath = _get(fullRecipe, path);
  if (isMetadataOnlyNode(objectAtPath)) {
    path.length -= 1;
    const parentArray = _get(fullRecipe, path);
    const newArray = [
      ...parentArray.slice(0, parseInt(key, 10) + 1),
      value,
      ...parentArray.slice(parseInt(key, 10) + 2),
    ];
    return _set(fullRecipe, path, newArray);
  }

  const obj = _get(fullRecipe, path);
  const newObj = { ...obj, [key]: value };
  _set(fullRecipe, path, newObj);
  return fullRecipe;
}

export function insertValueIntoArray(fullRecipe, ky, value, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return fullRecipe;
  }

  path.push(ky);
  const list = _get(fullRecipe, path);
  const newList = [...list, value];
  return _set(fullRecipe, path, newList);
}

export function insertKeyValueIntoObject(fullRecipe, ky, value, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return fullRecipe;
  }

  if (ky) {
    path.push(ky);
  }

  const obj = _get(fullRecipe, path);

  if (Array.isArray(obj)) {
    obj.push(value);
    return _set(fullRecipe, path, obj);
  }

  const newObj = { ...obj, ...value };
  return _set(fullRecipe, path, newObj);
}

export function getInputType(inputConfig) {
  if (typeof inputConfig === 'string') {
    return 'string';
  }
  if (typeof inputConfig === 'boolean') {
    return 'boolean';
  }
  if (Array.isArray(inputConfig)) {
    return 'array';
  }
  return 'object';
}

const representationResolvers = {
  string(key, value) {
    return { ky: key, type: 'string', uuid: uuidv4(), value };
  },
  boolean(key, value) {
    return { ky: key, type: 'boolean', uuid: uuidv4(), value };
  },
  array(key, value) {
    return {
      ky: key,
      type: 'array',
      uuid: uuidv4(),
      value: value.map((val, index) => representationResolvers.object(index, val)),
    };
  },
  object(key, value) {
    return {
      ky: key,
      type: 'object',
      uuid: uuidv4(),
      value: Object.keys(value).map((childKey) => {
        const childValue = value[childKey];
        return representationResolvers[getInputType(childValue)](childKey, childValue);
      }),
    };
  },
};

export function jsonToInternalRepresentation(inputJson) {
  return Object.keys(inputJson).map((ky) => {
    const value = inputJson[ky];
    const valueType = getInputType(value);
    return representationResolvers[valueType](ky, value);
  });
}

export function sortPatchesByVersion(patches) {
  return patches.sort(({ patch_version: versionA }, { patch_version: versionB }) => {
    if (semver.gt(versionA, versionB)) return -1;
    if (semver.lt(versionA, versionB)) return 1;
    return 0;
  });
}
