import { CUSTOMER_REVIEW_MODAL } from './ReviewSubmissionToggle.constants';

export function getCustomerReviewComponent(websiteComponents) {
  const filteredComponents = websiteComponents.filter(
    ({ fast_id: fastId }) => fastId === CUSTOMER_REVIEW_MODAL,
  );
  if (filteredComponents.length >= 1) {
    return filteredComponents[0];
  }
  return null;
}
