import React, { useState } from 'react';

import { Header } from 'semantic-ui-react';

import { menuItems } from './OrdersContainer.constants';
import { SelectedOrdersContextProvider } from './OrdersContainer.context';
import OrderActionsDropdown from './components/OrderActionsDropdown';
import SideMenuNavigation from '../../common/SideMenuNavigation';
import Toolbar from '../../common/Toolbar';

import './OrdersContainer.scss';

export default function OrdersContainer() {
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [refreshOrders, setRefreshOrders] = useState(false);

  function selectOrderId(orderId) {
    const nextOrderIds = [...selectedOrderIds, orderId];
    setSelectedOrderIds(nextOrderIds);
  }

  function deselectOrderId(orderId) {
    const nextOrderIds = selectedOrderIds.filter((id) => id !== orderId);
    setSelectedOrderIds(nextOrderIds);
  }

  function selectAllOrders(orders) {
    const orderIds = orders.map(({ uuid }) => uuid);
    setSelectedOrderIds(orderIds);
  }

  function isOrderIdSelected(orderId) {
    return selectedOrderIds.includes(orderId);
  }

  function onRefreshOrders() {
    setRefreshOrders(false);
  }

  const contextValue = {
    selectedOrderIds,
    refreshOrders,
    selectAllOrders,
    selectOrderId,
    deselectOrderId,
    isOrderIdSelected,
    setRefreshOrders,
    onRefreshOrders,
    setSelectedOrderIds,
  };

  return (
    <SelectedOrdersContextProvider value={contextValue}>
      <div className="orders-container-ui">
        <Header as="h1" className="ecomm-section-header">
          Orders
        </Header>
        <Toolbar>
          <Toolbar.Actions>
            {selectedOrderIds.length > 0 && <OrderActionsDropdown />}
          </Toolbar.Actions>
        </Toolbar>
        <SideMenuNavigation menuItems={menuItems} />
      </div>
    </SelectedOrdersContextProvider>
  );
}
