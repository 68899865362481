import { useState } from 'react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import { getErrorMessage } from 'libs/errors';

import {
  addBusinessFile,
  flagBusinessSaved,
  initializeBusinessFiles,
} from '../../../../../actions/business';
import API from '../../../../../libs/api';
import {
  selectBusinessType,
  selectBusinessId,
  selectBusinessFiles,
} from '../../../../../selectors/business';
import useAsyncEffect from '../../../foundation/hooks/use-async-effect';
import useFetch from '../../../foundation/hooks/use-fetch/use-fetch';

export default function useBusinessFiles({
  forceInitialFetch = false,
  initializeFiles = true,
  fileIds,
} = {}) {
  const businessType = useSelector(selectBusinessType);
  const businessId = useSelector(selectBusinessId);
  const businessFiles = useSelector((state) =>
    initializeFiles ? selectBusinessFiles(state, { fileIds }) : [],
  );

  const [creating, setCreating] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  const { fetching: loading, fetch, withFetch } = useFetch();

  async function fetchBusinessFiles(searchValue) {
    const params = { search: searchValue, order_by: '-created_at' };
    const { data } = await API.getBusinessFiles(businessType, businessId, params);
    dispatch(initializeBusinessFiles(data));
  }

  async function searchBusinessFiles(searchValue) {
    await fetch([() => fetchBusinessFiles(searchValue)], { throwError: false });
  }

  async function createBusinessFilesFromUrls(urls) {
    try {
      setCreating(true);
      const { business_files: newFiles } = await API.saveCuratedStockImages(
        businessType,
        businessId,
        urls,
      );

      newFiles.map((newFile) => dispatch(addBusinessFile(newFile)));
      dispatch(flagBusinessSaved(true));
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setCreating(false);
    }
  }

  async function duplicateBusinessFile(businessFileId) {
    try {
      setDuplicating(true);
      const { data: newFile } = await API.duplicateBusinessFile(businessFileId);
      dispatch(addBusinessFile(newFile));
      dispatch(flagBusinessSaved(true));
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setDuplicating(false);
    }
  }

  useAsyncEffect(async () => {
    if (!initializeFiles) {
      return;
    }

    const businessFilesAlreadyLoaded = !_isEmpty(businessFiles);
    if (businessFilesAlreadyLoaded && !forceInitialFetch) {
      return;
    }
    await fetch([fetchBusinessFiles], { throwError: false });
  }, []);

  return {
    businessFiles,
    loading,
    searchBusinessFiles,
    refreshBusinessFiles: withFetch(fetchBusinessFiles, { throwError: false }),
    createBusinessFilesFromUrls,
    duplicateBusinessFile,
    duplicating,
    creating,
    error,
  };
}
