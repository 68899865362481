import React from 'react';

import { Card } from 'semantic-ui-react';

import { SortableContainer } from 'react-sortable-hoc';

import SortableMenuCardGroup from './components/SortableMenuCardGroup/SortableMenuCardGroup';
import { MENU_FILE_TYPES } from '../../../../constants/constants';
import DraggableFileField from '../common/DraggableFileField';

const SortableMenuCard = SortableContainer(
  ({ menuItems, handleChange, handleDelete, handleAdd, handleEditFilename, loading }) => {
    return (
      <div className="menu-files-container">
        <Card.Group centered className="upload-menu-file" textAlign="center">
          <DraggableFileField
            onChange={handleAdd}
            onChangeFilename={() => false}
            title="New Upload"
            accept={MENU_FILE_TYPES}
            addOnly
            multiple
            loading={loading}
          />
        </Card.Group>
        {menuItems.map((menuItem, index) => (
          <SortableMenuCardGroup
            key={`card-group-${menuItem.globalIndex}`}
            index={index}
            menuItem={menuItem}
            handleChange={handleChange}
            handleDelete={handleDelete}
            handleEditFilename={handleEditFilename}
          />
        ))}
      </div>
    );
  },
);

export default SortableMenuCard;
