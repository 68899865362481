import React from 'react';

import PropTypes from 'prop-types';
import { Label, Container } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import GoogleAnalytics from '../../../../../../libs/google-analytics';
import Icon from '../../../../../modules/foundation/components/Icon';
import ExternalAnchor from '../../../../ExternalAnchor';
import PlanNavigationPopup from '../../PlanNavigationPopup';
import SideNavigationItemLinkContent from '../../SideNavigationItemLinkContent';

import './SideNavigationItem.scss';

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  featureNotifications: state.notifications.features,
});

const propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  redirectTo: PropTypes.string,
  showPlanGating: PropTypes.bool,
  icon: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  isHidden: PropTypes.bool,
};

const defaultProps = {
  path: '',
  redirectTo: '',
  showPlanGating: false,
  active: false,
  children: null,
  isHidden: false,
  icon: null,
};

const SideNavigationItem = ({
  name,
  path,
  active,
  children,
  featureNotifications,
  isHidden,
  icon,
  showPlanGating,
  requiredFeatureSets,
  redirectTo,
}) => {
  if (isHidden) return <></>;

  const linkContent = <SideNavigationItemLinkContent name={name} icon={icon} />;

  const LinkComponent = redirectTo ? ExternalAnchor : Link;

  return (
    <>
      <LinkComponent
        className={`side-navigation-item ${active && 'active-item'}`}
        href={redirectTo || ''}
        to={path}
        onClick={(e) => {
          if (showPlanGating) {
            e.preventDefault();
          }
          if (!_isEmpty(redirectTo)) {
            GoogleAnalytics.pageView(path || redirectTo);
          }
        }}
      >
        <Container>
          {showPlanGating && (
            <PlanNavigationPopup title={name} requiredFeatureSets={requiredFeatureSets}>
              <span>
                {linkContent}
                <Icon name="lock" alt={`${name}-plan-page-navigation`} />
              </span>
            </PlanNavigationPopup>
          )}
          {!showPlanGating && linkContent}
        </Container>

        {path.includes('content') && featureNotifications.length > 0 && (
          <Label circular color="red">
            {featureNotifications.length}
          </Label>
        )}
      </LinkComponent>
      {active && <div className="side-navigation-sub-items">{children}</div>}
    </>
  );
};

SideNavigationItem.propTypes = propTypes;
SideNavigationItem.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(SideNavigationItem);
