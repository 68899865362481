import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import { searchTagOptions } from './RecipeTagsInputField.utils';
import { getRecipeOptions } from '../../../RecipeManagerPage/RecipeManager.utils';

const propTypes = {
  label: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  label: undefined,
  tags: [],
};

const RecipeTagsInputField = ({ label, tags, onChange }) => {
  const options = getRecipeOptions(tags);

  return (
    <Form.Dropdown
      allowAdditions
      label={label}
      fluid
      multiple
      selection
      search={searchTagOptions}
      options={options}
      value={tags}
      placeholder="Enter a tag"
      onChange={(e, { value: selectedTags }) => onChange(selectedTags)}
    />
  );
};

RecipeTagsInputField.propTypes = propTypes;

RecipeTagsInputField.defaultProps = defaultProps;

export default RecipeTagsInputField;
