export const EMAIL_KEY = 'email';
export const ANNOUNCEMENT_FORM_FIELDS_DEFAULT_SETTINGS = [
  {
    name: 'email',
    enabled: true,
  },
  {
    name: 'name',
    enabled: false,
  },
  {
    name: 'phone',
    enabled: false,
  },
];

export const ANNOUNCEMENT_DEFAULT_FORM_FIELDS_MAP = {
  email: {
    label: 'email',
    name: 'email',
    placeholder: 'you@gmail.com',
    type: 'input',
    inputType: 'email',
    required: true,
  },
  name: {
    label: 'name',
    name: 'name',
    placeholder: 'John Doe',
    type: 'input',
    inputType: 'text',
    required: false,
  },
  phone: {
    label: 'phone',
    name: 'phone',
    placeholder: '123-456-7890',
    type: 'input',
    inputType: 'tel',
    required: false,
  },
};
