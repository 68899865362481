import React from 'react';

import PropTypes from 'prop-types';
import { Table, Pagination } from 'semantic-ui-react';

const propTypes = {
  tableHeader: PropTypes.element.isRequired,
  tableBody: PropTypes.element.isRequired,
  onPageChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  floated: PropTypes.oneOf(['left', 'right']),
};

const defaultProps = {
  floated: 'right',
};

const PaginatedTable = ({
  tableHeader,
  tableBody,
  onPageChange,
  activePage,
  totalPages,
  columns,
  floated,
}) => {
  return (
    <Table sortable compact celled>
      {tableHeader}
      {tableBody}
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan={columns}>
            <Pagination
              floated={floated}
              onPageChange={onPageChange}
              activePage={activePage}
              totalPages={totalPages}
            />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

PaginatedTable.propTypes = propTypes;
PaginatedTable.defaultProps = defaultProps;

export default PaginatedTable;
