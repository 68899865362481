import React from 'react';

import { Label } from 'semantic-ui-react';

import { GREEN_KEY, GREY_KEY } from './RecipePatchVersionDropdown.constants';
import { RECIPE_PATCH_STATUS } from '../../RecipesEditForm.constants';

export function getStatusBackgroundColor(status) {
  if (status === RECIPE_PATCH_STATUS.ACTIVE) {
    return GREEN_KEY;
  }

  if (status === RECIPE_PATCH_STATUS.DRAFT) {
    return GREY_KEY;
  }
  return null;
}

export function getRecipePatchSelectionOptions(recipePatchData) {
  if (!recipePatchData) return {};

  return recipePatchData.map(
    ({ id, patch, patch_version: patchVersion, active, draft, description }) => {
      let status = null;

      if (active) {
        status = RECIPE_PATCH_STATUS.ACTIVE;
      }

      if (draft) {
        status = RECIPE_PATCH_STATUS.DRAFT;
      }
      return {
        content: (
          <div className="recipe-patch-info">
            <div className="recipe-patch-status">
              {status && (
                <Label size="small" color={getStatusBackgroundColor(status)}>
                  {status}
                </Label>
              )}
              {patchVersion && <Label size="small">{patchVersion}</Label>}
            </div>
            {description && <div className="recipe-patch-description">{description}</div>}
          </div>
        ),
        text: patch.split('/').pop(),
        key: id,
        value: id,
      };
    },
  );
}

export function getCurrentPatchSelectionValue(currentRecipePatch, recipePatchData) {
  if (!currentRecipePatch) return {};

  const foundValues = getRecipePatchSelectionOptions(recipePatchData).filter(
    ({ value }) => value === currentRecipePatch.id,
  );

  if (foundValues.length) {
    return foundValues[0];
  }

  return {};
}
