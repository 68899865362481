/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */

import React from 'react';

import _isFunction from 'lodash/isFunction';

import { getValueType, isMetadataKey } from './recipe-utils';
import { STRING_KEY, STYLE_KEY } from '../RecipesEditForm.constants';
import RecipeArray from '../components/types/RecipeArray';
import RecipeObject from '../components/types/RecipeObject';
import RecipeStyle from '../components/types/RecipeStyle/RecipeStyle';

export default function getRecipeNodeNavigationDisplayComponent(
  recipeObjectKey,
  parentId,
  recipePart,
  index = null,
  additionalProps = {},
) {
  let type = null;
  const fieldTypesMap = {
    style: (props) => <RecipeStyle {...props} />,
    object: (props) => <RecipeObject {...props} />,
    array: (props) => <RecipeArray {...props} />,
  };

  if (isMetadataKey(recipeObjectKey)) {
    return null;
  }

  const valueType = getValueType(recipePart[recipeObjectKey]);

  type = valueType === STRING_KEY && recipeObjectKey === STYLE_KEY ? STYLE_KEY : valueType;

  const typeToComponentFunction = fieldTypesMap[type];
  const pId = parentId || recipePart.__id__;

  const props = {
    key: index,
    recipeObjectKey,
    recipePart: recipePart[recipeObjectKey],
    parentId: pId,
    ...additionalProps,
  };

  if (_isFunction(typeToComponentFunction)) {
    return typeToComponentFunction(props);
  }

  return null;
}
