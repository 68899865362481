import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';

import { USER_PRIVATE_DATA } from './logrocket.constants';

function safeJsonParse(body) {
  try {
    return JSON.parse(body);
  } catch (error) {
    return {};
  }
}

export function removeFromBody(request, key) {
  if (_isEmpty(request.body)) {
    return request;
  }

  if (_isString(request.body)) {
    const parsedBody = safeJsonParse(request.body);
    if (parsedBody[key]) {
      parsedBody[key] = USER_PRIVATE_DATA;
      request.body = JSON.stringify(parsedBody);
    }
  } else if (request.body[key]) {
    request.body[key] = USER_PRIVATE_DATA;
  }
  return request;
}
