import _isEmpty from 'lodash/isEmpty';

import { generateFieldName } from '../../../../../../hooks/use-recipe-node-translator/generators/form-block/fields';

export function getContactMappingSelectOptions(fields, targetType) {
  return fields
    .filter(({ type, label }) => {
      return type === targetType && !_isEmpty(label);
    })
    .filter((option, index, self) => {
      // remove duplicate options
      return self.findIndex(({ label }) => label === option.label) === index;
    })
    .map(({ label }) => ({
      key: generateFieldName(label),
      text: label,
      value: generateFieldName(label),
    }));
}

export function isFieldInContactMapping(field, contactMapping) {
  const { label } = field;
  const snakeCaseFieldLabel = generateFieldName(label);
  return Object.values(contactMapping)
    .filter((value) => !_isEmpty(value))
    .includes(snakeCaseFieldLabel);
}
