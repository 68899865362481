import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';

import { SortableNavigationGroupItem } from './components/NavigationGroupItem/NavigationGroupItem';
import NavigationItem, { SortableNavigationItem } from './components/NavigationItem/NavigationItem';
import { SortingContext } from '../../../foundation/context/sorting/SortingContext.context';
import { buildGroupCollectionName } from '../../../pages/services/page-groups';
import { isHomePage } from '../../../pages/services/pages';
import { PagePropType } from '../../../pages/types/page';
import { ACTION_BUTTONS_COLLECTION, MAX_AMOUNT_OF_BUTTONS } from '../../constants/constants';
import { NavigationContext } from '../../context/Navigation.context';

import './NavigationItemList.scss';

const propTypes = {
  pages: PropTypes.arrayOf(PagePropType).isRequired,
  collectionName: PropTypes.string.isRequired,
};
const defaultProps = {};

export default function NavigationItemList({ pages, collectionName: collection }) {
  const { navigationGroup, isButtonGroupingSupported } = useContext(NavigationContext);
  const {
    focusedCollection,
    setFocusedCollection,
    dragging,
    initialFocusedCollection,
    setInitialFocusedCollection,
    initialFocusedGroupCollection,
    initialFocusedGroupChildCollection,
  } = useContext(SortingContext);

  const title = _startCase(collection);
  const { buttonPages } = navigationGroup;

  function onHover() {
    if (focusedCollection !== collection) {
      setFocusedCollection(collection);
    }
    if (initialFocusedCollection !== collection && !dragging) {
      setInitialFocusedCollection(collection);
    }
  }

  const isButtonCollection = collection === ACTION_BUTTONS_COLLECTION;
  const isDraggingAGroup = !_isEmpty(initialFocusedGroupCollection);
  const isDraggingAGroupChild = !_isEmpty(initialFocusedGroupChildCollection);
  const droppingIntoButtonCollection = focusedCollection === ACTION_BUTTONS_COLLECTION;
  const droppingBetweenCollections = initialFocusedCollection !== focusedCollection;
  const droppingIntoMe = collection === focusedCollection;

  const isExceedingMaxAmountOfButtons =
    buttonPages.length >= MAX_AMOUNT_OF_BUTTONS &&
    droppingBetweenCollections &&
    droppingIntoButtonCollection &&
    isButtonCollection;

  const isDraggingGroupChildPagesBetweenCollections =
    isDraggingAGroupChild && droppingBetweenCollections && droppingIntoMe;

  const isDraggingGroupIntoButtonCollection =
    isDraggingAGroup && isButtonCollection && droppingIntoButtonCollection;

  let disableDrop = isExceedingMaxAmountOfButtons || isDraggingGroupChildPagesBetweenCollections;

  if (!isButtonGroupingSupported) {
    disableDrop = disableDrop || isDraggingGroupIntoButtonCollection;
  }

  disableDrop = disableDrop && dragging;

  const highlight = dragging && droppingIntoMe && !disableDrop;

  const finalClassName = clsx({
    'navigation-items-container': true,
    highlight,
    'no-drop': disableDrop,
  });

  return (
    <div className="navigation-item-list" onMouseEnter={onHover} onTouchStart={onHover}>
      {!_isEmpty(title) && <Header as="h2">{title}</Header>}

      <div className={finalClassName}>
        {pages.map((page) => {
          const isHome = isHomePage(page);
          const { isGroup, name, index, id } = page;
          if (!isGroup) {
            const Component = isHome ? NavigationItem : SortableNavigationItem;
            return <Component index={index} key={id} page={page} collection={collection} />;
          }
          const Component = SortableNavigationGroupItem;
          const groupCollection = buildGroupCollectionName(name);

          const sortingCollection =
            initialFocusedGroupCollection === groupCollection ? collection : groupCollection;

          return (
            <Component
              index={index}
              key={id}
              page={page}
              collection={sortingCollection}
              collectionName={groupCollection}
            />
          );
        })}
      </div>
    </div>
  );
}

NavigationItemList.propTypes = propTypes;
NavigationItemList.defaultProps = defaultProps;
