import LocationSettings from '../LocationSettings';
import OrderSettings from '../OrderSettings';
import PaymentSettings from '../PaymentSettings';

export const menuItems = [
  {
    name: 'FULFILLMENT',
    component: OrderSettings,
  },

  {
    name: 'PAYMENTS',
    component: PaymentSettings,
  },
  {
    name: 'LOCATIONS',
    component: LocationSettings,
  },
];
