import React from 'react';

import PropTypes from 'prop-types';
import { Header, Label, Popup } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import HelpTooltip from 'components/common/HelpTooltip';
import { PageComponentType, PageComponentSettingType } from 'components/modules/pages/types';
import { isAdminUser } from 'libs/auth';
import { selectUser } from 'selectors/user';

import { generateHelpText } from './PageContentNode.utils';
import ContentNodeForm from '../ContentNodeForm/ContentNodeForm';

import './PageContentNode.scss';

const propTypes = {
  component: PageComponentType.isRequired,
  setting: PageComponentSettingType.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

const defaultProps = {
  onSave: () => null,
  onCancel: () => null,
};

export default function PageContentNode({ component, setting, onSave, onCancel }) {
  const { displayName, helpText = '', contentApplicationOptions = {} } = setting || {};
  const { default: defaultValue = '' } = contentApplicationOptions || {};
  const { contentNodes = [], id: componentConfigurationId } = component;
  const isDefaultValue = contentNodes.every((node) => node.content === defaultValue);

  const user = useSelector(selectUser);
  const isAdmin = isAdminUser(user);

  async function handleSave(contentNode, newContent) {
    if (onSave) {
      await onSave(contentNode, newContent, { component });
    }
  }

  function getDisplayName(settingDisplayName, nodes) {
    if (settingDisplayName) {
      return settingDisplayName;
    }

    if (!_isEmpty(nodes)) {
      return (contentNodes[0].contentName || '').replace(/_/g, ' ');
    }

    return '';
  }

  const finalHelpText = generateHelpText(helpText, defaultValue);

  return (
    <div className="page-content-node">
      <div className="header-section">
        <Header as="h3" className="section-header">
          {getDisplayName(displayName, contentNodes)}
        </Header>
        {!_isEmpty(finalHelpText) && (
          <HelpTooltip title={displayName} description={finalHelpText} position="bottom left" />
        )}
        {isDefaultValue && isAdmin && (
          <Popup
            content={
              <div>
                <strong>Default Value Notice: </strong>
                This field contains a default value. To better personalize the customer&apos;s
                website, we encourage updating it with a customer-specific value.
              </div>
            }
            trigger={<Label color="grey">Default</Label>}
            position="top right"
          />
        )}
      </div>

      {contentNodes.map((node) => (
        <ContentNodeForm
          key={node.id}
          contentNode={node}
          componentConfigurationId={componentConfigurationId}
          setting={setting}
          onCancel={onCancel}
          onSave={handleSave}
        />
      ))}
    </div>
  );
}

PageContentNode.propTypes = propTypes;
PageContentNode.defaultProps = defaultProps;
