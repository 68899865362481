import React from 'react';

import PropTypes from 'prop-types';
import { Confirm } from 'semantic-ui-react';

import useSemanticUiProps from 'hooks/useSemanticUiProps';

import './ConfirmModal.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

export default function ConfirmModal({ className, ...rest }) {
  const { getModalProps } = useSemanticUiProps();

  return (
    <Confirm
      dimmer="inverted"
      className={`confirm-modal ${className}`}
      size="tiny"
      closeOnEscape
      closeOnDimmerClick={false}
      {...getModalProps(rest)}
    />
  );
}

ConfirmModal.propTypes = propTypes;
ConfirmModal.defaultProps = defaultProps;
