import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Message, Input, Search, List, ListItem, ListContent, Button } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import {
  INVALID_EMAIL_ERROR_MESSAGE,
  EMAIL_ADD_ERROR_MESSAGE,
  DEFAULT_DESTINATION_EMAIL_MESSAGE,
  ERROR_GETTING_DESTINATION_EMAILS_MESSAGE,
} from './FormDestinationEmail.constants';
import {
  dataToOptions,
  isValidEmail,
  isAnOption,
  newValueToOption,
  optionToValue,
} from './FormDestinationEmail.utils';
import API from '../../../../../../../../../libs/api';
import { selectBusinessId } from '../../../../../../../../../selectors';
import HelpTooltip from '../../../../../../../../common/HelpTooltip';
import useAsyncEffect from '../../../../../../../foundation/hooks/use-async-effect';

import './FormDestinationEmail.scss';

const propTypes = {
  destinationEmail: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
const defaultProps = {
  destinationEmail: [],
  onChange: () => null,
};

function FormDestinationEmail({ destinationEmail: formDestinationEmail, onChange }) {
  const businessId = useSelector(selectBusinessId);
  const [emailOptions, setEmailOptions] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(null);

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const data = await API.getDestinationEmails(businessId);
      setEmailOptions(dataToOptions(data));
    } catch (e) {
      setError(ERROR_GETTING_DESTINATION_EMAILS_MESSAGE);
    }
    setIsLoading(false);
  }, []);

  function onSearch(value) {
    setSearchValue(value);
    setIsSearching(true);
    setError(null);

    if (value.length < 1) {
      setSearchResults([]);
    } else {
      const searchOptions = emailOptions.filter(({ title }) => title.includes(value));

      if (!formDestinationEmail.includes(value) && !isAnOption(value, emailOptions)) {
        searchOptions.push(newValueToOption(value));
      }
      setSearchResults(searchOptions);
    }

    setIsSearching(false);
  }

  async function onSelect(option) {
    const value = optionToValue(option);
    if (!isValidEmail(value)) {
      setError(INVALID_EMAIL_ERROR_MESSAGE);
      return;
    }

    if (!isAnOption(value, emailOptions)) {
      try {
        await API.addDestinationEmail(businessId, value);
        setEmailOptions((prevEmailOptions) => [...prevEmailOptions, newValueToOption(value)]);
      } catch (e) {
        setError(EMAIL_ADD_ERROR_MESSAGE);
      }
    }

    const newFormDestinationEmail = formDestinationEmail.includes(value)
      ? formDestinationEmail
      : [...formDestinationEmail, value];
    onChange(newFormDestinationEmail);
    setSearchValue('');
    setSearchResults([]);
  }

  function onDelete(email) {
    onChange(formDestinationEmail.filter((destination) => destination !== email));
  }

  return (
    <div className="email-destination-form">
      <label htmlFor="search-input">Email Destinations</label>
      <HelpTooltip title="Email Destination" description="Emails" />

      <Search
        id="search-input"
        label="Email Destination"
        input={
          <Input
            disabled={isLoading}
            icon="search"
            iconPosition="left"
            type="email"
            autocomplete="off"
          />
        }
        placeholder="Search for email addresses..."
        value={searchValue}
        loading={isSearching}
        results={searchResults}
        onSearchChange={(e, { value }) => onSearch(value)}
        onResultSelect={(e, { result }) => onSelect(result)}
      />
      {_isEmpty(formDestinationEmail) && (
        <Message info content={DEFAULT_DESTINATION_EMAIL_MESSAGE} />
      )}
      {error && <Message error content={error} />}
      {!_isEmpty(formDestinationEmail) && (
        <List divided className="email-destinations">
          {formDestinationEmail.map((destinationEmail) => (
            <ListItem key={destinationEmail}>
              <ListContent className="email">{destinationEmail}</ListContent>
              <ListContent>
                <Button
                  color="red"
                  basic
                  size="mini"
                  icon="trash"
                  className="delete"
                  onClick={() => onDelete(destinationEmail)}
                />
              </ListContent>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}

FormDestinationEmail.propTypes = propTypes;
FormDestinationEmail.defaultProps = defaultProps;

export default FormDestinationEmail;
