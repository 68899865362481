import PropTypes from 'prop-types';

import { ContentNodeType } from '../../content/types/content';

export const PageComponentFileType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  file: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
});

export const PageComponentSettingType = PropTypes.shape({
  id: PropTypes.number.isRequired,
});

export const PageComponentType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  order: PropTypes.number.isRequired,
  fastId: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  enabled: PropTypes.bool,
  componentIdentifier: PropTypes.string,
  files: PropTypes.arrayOf(PageComponentFileType),
  contentNodes: PropTypes.arrayOf(ContentNodeType),
});
