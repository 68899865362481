import _isEmpty from 'lodash/isEmpty';

import { formatStyleRuleValue } from './format';

export function getStyleRuleConfiguration(configurations, styleRuleName) {
  function cleanName(name) {
    return name.replace('@', '');
  }
  return configurations.find((config) => cleanName(config.name) === cleanName(styleRuleName));
}

export function isColorRule(ruleName, styleRuleConfiguration) {
  if (ruleName.toLowerCase().includes('color')) {
    return true;
  }
  const { typeDisplay = '' } = styleRuleConfiguration || {};
  if (typeDisplay.toLowerCase().includes('color')) {
    return true;
  }
  return false;
}

export function getCategoriesForConfigurations(configurations) {
  return configurations.reduce((categories, { typeDisplay = '' }) => {
    if (!_isEmpty(typeDisplay) && !categories.includes(typeDisplay)) {
      return [...categories, typeDisplay];
    }
    return categories;
  }, []);
}

export function getRulesForCategory(configurations, category) {
  return configurations.filter(({ typeDisplay }) => typeDisplay === category);
}

export function groupConfigurationsByCategory(configurations, { existingStyleRules = [] } = {}) {
  const categories = getCategoriesForConfigurations(configurations);

  let grouping = {};
  categories.forEach((category) => {
    const rulesForCategory = getRulesForCategory(configurations, category);
    rulesForCategory.forEach(({ name }) => {
      const finalName = formatStyleRuleValue(name);
      if (!existingStyleRules.includes(finalName)) {
        grouping = { ...grouping, [category]: [...(grouping[category] || []), finalName] };
      }
    });
  });

  return grouping;
}
