import React, { Component } from 'react';

import { Checkbox } from 'semantic-ui-react';

import { connect } from 'react-redux';

import { updateUser as updateUserConnect } from '../../actions/user';
import { InlineFieldContainer, InputField, Field } from '../common';
import FormContainer from '../common/FormContainer';

class AccountInfoForm extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.toggleOptOut = this.toggleOptOut.bind(this);
  }

  handleChange(e, field) {
    const { updateUser } = this.props;
    const { value } = e.target;

    updateUser({ value, field });
  }

  toggleOptOut() {
    const { user, updateUser } = this.props;
    const { email_notification_opt_out: emailNotificationOptOut } = user;
    const value = !emailNotificationOptOut;
    updateUser({ value, field: 'email_notification_opt_out' });
  }

  render() {
    const { user } = this.props;
    const {
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      email,
      email_notification_opt_out: emailNotificationOptOut,
    } = user;

    return (
      <FormContainer loadedKeyPath={['user', 'core']}>
        <InlineFieldContainer title={<h2>Personal</h2>} enableDelete={false}>
          <div>
            <InputField
              title="First Name"
              value={firstName}
              placeholder="First Name"
              onChange={(e) => this.handleChange(e, 'first_name')}
            />
            <InputField
              title="Last Name"
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => this.handleChange(e, 'last_name')}
            />
          </div>
        </InlineFieldContainer>
        <InlineFieldContainer title={<h2>Contact</h2>} enableDelete={false}>
          <div>
            <InputField
              title="Phone Number"
              value={phoneNumber}
              placeholder="Phone Number"
              onChange={(e) => this.handleChange(e, 'phone_number')}
            />
            <InputField
              title="Email"
              value={email}
              placeholder="Email"
              onChange={(e) => this.handleChange(e, 'email')}
            />
          </div>
        </InlineFieldContainer>
        <InlineFieldContainer title={<h2>Email Notifications</h2>} enableDelete={false}>
          <div>
            <Field>
              <Checkbox
                label="Unsubscribe from email notifications"
                onChange={this.toggleOptOut}
                checked={emailNotificationOptOut}
              />
            </Field>
          </div>
        </InlineFieldContainer>
      </FormContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUser: (payload) => dispatch(updateUserConnect(payload)),
});

const mapStateToProps = ({ user }) => ({
  // eslint-disable-next-line no-underscore-dangle
  loaded: user.core._loaded,
  user: user.core.value,
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfoForm);
