import React, { Component } from 'react';

import { Dropdown } from 'semantic-ui-react';

import _cloneDeep from 'lodash/cloneDeep';

import { RecipeContextConsumer } from './context/RecipeContext';
import ModalEditRaw from './modals/ModalEditRaw';
import ModalInsertHere from './modals/ModalInsertHere';
import ModalNew from './modals/ModalNew';
import { removeNodeMetadata } from '../utils/recipe-utils';

class ObjectOptionsHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayingNewModal: false,
      displayingEditRawModal: false,
      displayingInsertHereModal: false,
    };

    this.openNewModal = this.openNewModal.bind(this);
    this.openEditRawModal = this.openEditRawModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.getName = this.getName.bind(this);
    this.deleteNode = this.deleteNode.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.getAdditionalInfo = this.getAdditionalInfo.bind(this);
    this.openInsertHereModal = this.openInsertHereModal.bind(this);
  }

  getName() {
    const { ky, recipePart } = this.props;
    if (recipePart && recipePart.name) {
      if (recipePart.name instanceof Object) {
        return 'Name (?)';
      }
      return recipePart.name;
    }
    return ky;
  }

  getAdditionalInfo() {
    const { isOpen } = this.props;
    return <span>{isOpen ? ' ↓' : ' →'}</span>;
  }

  copyToClipboard() {
    const { recipePart } = this.props;
    const cRecipe = _cloneDeep(recipePart);
    const str = JSON.stringify(removeNodeMetadata(cRecipe), null, 2);
    navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(str);
      } else {
        // eslint-disable-next-line no-console
        console.warn('Copy to clipboard not supported!');
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  deleteNode(ky, parentId, deleteValueFromObject) {
    deleteValueFromObject(ky, parentId);
  }

  closeModals() {
    this.setState({
      displayingNewModal: false,
      displayingEditRawModal: false,
      displayingInsertHereModal: false,
    });
  }

  openNewModal() {
    this.setState({
      displayingNewModal: true,
    });
  }

  openEditRawModal() {
    this.setState({
      displayingEditRawModal: true,
    });
  }

  openInsertHereModal() {
    this.setState({
      displayingInsertHereModal: true,
    });
  }

  render() {
    const { displayingNewModal, displayingEditRawModal, displayingInsertHereModal } = this.state;
    const { ky, parentId, recipePart, type, toggleOpen } = this.props;

    return (
      <RecipeContextConsumer>
        {({ nodeInInvalidPath, deleteValueFromObject }) => (
          <div
            className="objectheader"
            style={nodeInInvalidPath(parentId, ky) ? { background: '#ff00001f' } : {}}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" onClick={toggleOpen}>
              {this.getName()}
              {this.getAdditionalInfo()}
            </a>
            <div className="objectheaderdropdown">
              <Dropdown text="Options">
                <Dropdown.Menu>
                  <Dropdown.Item icon="add" text="New..." onClick={this.openNewModal} />
                  <Dropdown.Item
                    icon="copy"
                    text="Copy to clipboard..."
                    onClick={this.copyToClipboard}
                  />
                  <Dropdown.Item
                    icon="paste"
                    text="Insert here..."
                    onClick={this.openInsertHereModal}
                  />
                  <ModalEditRaw
                    open={displayingEditRawModal}
                    ky={ky}
                    recipePart={recipePart}
                    parentId={parentId}
                    close={this.closeModals}
                    trigger={
                      <Dropdown.Item
                        icon="edit"
                        text="Edit raw..."
                        onClick={this.openEditRawModal}
                      />
                    }
                  />
                  <Dropdown.Item
                    icon="delete"
                    text="Delete"
                    onClick={() => this.deleteNode(ky, parentId, deleteValueFromObject)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <ModalNew
              open={displayingNewModal}
              ky={ky}
              parentId={parentId}
              type={type}
              close={this.closeModals}
            />
            <ModalInsertHere
              open={displayingInsertHereModal}
              ky={ky}
              recipePart={recipePart}
              parentId={parentId}
              close={this.closeModals}
            />
          </div>
        )}
      </RecipeContextConsumer>
    );
  }
}

export default ObjectOptionsHeader;
