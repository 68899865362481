import { markdownStringToHtmlString } from 'components/modules/content/services/markdown';
import { validateHtmlElement } from 'components/modules/html-elements/services';

import { INVALID_HTML_TAGS, TOOLBAR_VALUES } from './MarkdownField.constants';

export function generateMarkdownFieldImageToolbar(buttonPlugins) {
  return [
    TOOLBAR_VALUES.bold,
    TOOLBAR_VALUES.italic,
    TOOLBAR_VALUES.heading2,
    TOOLBAR_VALUES.heading3,
    TOOLBAR_VALUES.separator,
    TOOLBAR_VALUES.quote,
    TOOLBAR_VALUES.unorderedList,
    TOOLBAR_VALUES.orderedList,
    TOOLBAR_VALUES.separator,
    TOOLBAR_VALUES.link,
    ...buttonPlugins,
    TOOLBAR_VALUES.separator,
    TOOLBAR_VALUES.preview,
    TOOLBAR_VALUES.sideBySide,
  ];
}

export function createToolbarPlugins(toolbarPlugins) {
  return toolbarPlugins.map((plugin) => {
    const [component, button] = plugin();
    return { component, button };
  });
}

export function getPluginButtons(plugins) {
  return plugins.map(({ button }) => button);
}

export function getPluginComponents(plugins) {
  return plugins.map(({ component }) => component);
}

export async function getMarkdownValidationMessage(markdownString = '') {
  try {
    const htmlString = await markdownStringToHtmlString(markdownString);
    validateHtmlElement({ content: htmlString });
  } catch (error) {
    return error.message;
  }

  const invalidTag = INVALID_HTML_TAGS.find(
    (tag) => markdownString.includes(`<${tag}>`) || markdownString.includes(`</${tag}>`),
  );
  if (invalidTag) {
    return `The tag <${invalidTag}> is not allowed.`;
  }

  return '';
}
