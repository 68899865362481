import React from 'react';

import { Menu } from 'semantic-ui-react';

import { SortableContainer } from 'react-sortable-hoc';

import SortableMenuItem from './SortableMenuItem';

const SortableMenu = SortableContainer((props) => <Menu {...props} />);
SortableMenu.Item = SortableMenuItem;

export default SortableMenu;
