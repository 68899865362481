import React, { useEffect, useState, useContext } from 'react';

import { Input } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import { NAME_FIELD_PLACEHOLDER } from './EditTextBlock.constants';
import {
  updateContentConfiguration,
  createContentConfiguration,
  updateContentElementFromSave,
} from './EditTextBlock.utils';
import MarkdownField from '../../../../../../fields/MarkdownField';
import { MarkdownImagePlugin } from '../../../../../../fields/MarkdownField/components';
import { useLatestRefValue } from '../../../../../utils/hooks';
import { TEXT_BLOCK_ELEMENT_TYPE } from '../../../../constants/types';
import { TextBlockPropType } from '../../../../types/text-block.types';
import { EditPageElementModalContext } from '../../EditPageElementModal.context';

import './EditTextBlock.scss';

const propTypes = {
  element: TextBlockPropType.isRequired,
};

const defaultProps = {};

export default function EditTextBlock({ element }) {
  const {
    page,
    registerCallback,
    onUpdateElementState,
    addValidationError,
    clearValidationErrors,
  } = useContext(EditPageElementModalContext);

  const { content, contentName } = element;
  const [name, setName] = useState(contentName);
  const [value, setValue] = useState(content);

  const latestElementRef = useLatestRefValue(element);
  const latestNameRef = useLatestRefValue(name);
  const latestValueRef = useLatestRefValue(value);

  function onChange(key, nextValue, stateSetter) {
    const currentElement = latestElementRef.current;
    const nextElement = {
      ...currentElement,
      [key]: nextValue,
    };

    stateSetter(nextValue);
    onUpdateElementState(TEXT_BLOCK_ELEMENT_TYPE, nextElement);
  }

  function onNameChange(nextValue) {
    onChange('contentName', nextValue, setName);
  }

  function onValueChange(nextValue) {
    onChange('content', nextValue, setValue);
  }

  async function onSaveContent() {
    const currentElement = latestElementRef.current;
    const currentName = latestNameRef.current;
    const currentValue = latestValueRef.current;
    const { componentConfigurationId, contentNodeConfigurationId } = currentElement;
    const { id: pageId } = page;
    if (contentNodeConfigurationId && componentConfigurationId) {
      await updateContentConfiguration(componentConfigurationId, contentNodeConfigurationId, {
        content: currentValue,
        contentName: currentName,
      });

      return currentElement;
    }

    const result = await createContentConfiguration(currentName, currentValue, pageId);
    return updateContentElementFromSave(currentElement, result);
  }

  useEffect(() => {
    clearValidationErrors();
    if (_isEmpty(name)) {
      addValidationError('Name must not be empty.');
    }
    if (_isEmpty(value)) {
      addValidationError('Content must not be empty.');
    }
  }, [name, value]);

  useEffect(() => {
    registerCallback(TEXT_BLOCK_ELEMENT_TYPE, onSaveContent);
  }, []);

  return (
    <div className="edit-text-block">
      <div className="text-block-field name">
        <label htmlFor="text-block-name">Name</label>
        <Input
          id="text-block-name"
          placeholder={NAME_FIELD_PLACEHOLDER}
          value={name || ''}
          maxLength={255}
          onChange={(e, { value: nextName }) => onNameChange(nextName)}
        />
      </div>
      <div className="text-block-field content">
        <label htmlFor="text-block-content">Content</label>
        <MarkdownField
          id="text-block-content"
          value={value}
          onChange={onValueChange}
          toolbarPlugins={[MarkdownImagePlugin]}
          options={{ autoDisplaySideBySidePreview: false }}
        />
      </div>
    </div>
  );
}

EditTextBlock.propTypes = propTypes;
EditTextBlock.defaultProps = defaultProps;
