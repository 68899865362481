import _isEmpty from 'lodash/isEmpty';

import { PROMPT_NAME } from './CatalogObjectDescriptionInput.constants';
import API from '../../../libs/api';

export async function getItemOrServiceDescriptionPrompt(businessType, businessId, params) {
  return API.textCompletion(businessType, businessId, PROMPT_NAME, params);
}

export function createPriceString(variations) {
  if (_isEmpty(variations)) {
    return '';
  }

  if (variations.length === 1) {
    const { price, priceDisplay } = variations[0];
    return priceDisplay || price;
  }

  return variations
    .map(({ name, price, priceDisplay }) => `${name} - ${priceDisplay || price}`)
    .join(' | ');
}
