import _isEmpty from 'lodash/isEmpty';

import {
  HTTP_URI_SCHEME,
  HTTP_URI_STARTS_WITH,
  HTTP_URI_STARTS_WITH_WWW,
  MAILTO_URI_SCHEME,
  MAILTO_URI_STARTS_WITH,
  TEL_URI_SCHEME,
  TEL_URI_STARTS_WITH,
} from './URISchemeField.constants';

export function stripScheme(uri) {
  if (typeof uri === 'string') {
    return uri.replace(/^(https?|mailto|tel)/, '').replace(/(:)|(\/\/)/g, '');
  }

  return uri;
}

export function getSchemeFromValue(uri) {
  if (_isEmpty(uri)) {
    return HTTP_URI_SCHEME;
  }
  if (uri.startsWith(MAILTO_URI_STARTS_WITH)) {
    return MAILTO_URI_SCHEME;
  }
  if (uri.startsWith(TEL_URI_STARTS_WITH)) {
    return TEL_URI_SCHEME;
  }
  if (uri.startsWith(HTTP_URI_STARTS_WITH) || uri.startsWith(HTTP_URI_STARTS_WITH_WWW)) {
    return HTTP_URI_SCHEME;
  }

  return null; // Return null if no known scheme is detected
}

export function splitSchemaAndURL(uri) {
  return { scheme: getSchemeFromValue(uri) || HTTP_URI_SCHEME, url: stripScheme(uri) };
}
