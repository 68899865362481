import _find from 'lodash/find';
import _get from 'lodash/get';

import { selectCustomPages } from '../../../../selectors/custom-pages';

export function createPageComponentQuery(pageId, contentQuery) {
  return {
    type: 'QUERY',
    value: {
      query: {
        fishermanBusinessWebsitePage: {
          __args: {
            _id: {
              eq: pageId,
            },
          },
          components: {
            fastId: true,
            componentIdentifier: true,
            order: true,
            ...contentQuery,
          },
        },
      },
      payload: 'fishermanBusinessWebsitePage.components',
    },
  };
}

export function getPageComponent(options, state) {
  const { pageId, componentName, componentIdentifier } = options;

  const pages = selectCustomPages(state).map(({ page }) => page);

  const page = _find(pages, { id: pageId });
  const components = _get(page, 'components', []);

  return _find(components, {
    componentIdentifier,
    fastId: componentName,
  });
}

export function getComponentContentNodes(options, state) {
  const component = getPageComponent(options, state);

  return [component, _get(component, 'contentNodes', [])];
}

export function getComponentFiles(options, state) {
  const component = getPageComponent(options, state);

  return [component, _get(component, 'files', [])];
}

export function getQueryArgValue(querySnippet, queryName, argName) {
  if (!querySnippet || !queryName || !argName) {
    return undefined;
  }

  const args = _get(querySnippet, `query.${queryName}.__args.${argName}`, {});
  return _get(args, 'eq', undefined);
}
