/* eslint-disable import/order */

import { shouldDisplayContentPage, shouldDisplayHelpCenter } from '../libs/routing/display';

// Core Components
import { ContentPage, HtmlElementsPage, CustomSettingsPage, TeamPage } from '../components/pages';
import Locations from '../components/common/location/Locations';
import AccountInfoForm from '../components/core/AccountInfoForm';
import BlogListForm from '../components/core/BlogListForm';
import BusinessInfo from '../components/core/BusinessInfo';
import ChangePasswordForm from '../components/core/ChangePasswordForm';
import ImagesPage from '../components/core/ImagesPage';
import Integrations from '../components/core/Integrations/Integrations';
import MenuForm from '../components/core/MenuForm';
import ReviewsForm from '../components/core/ReviewsForm';
import SocialMediaForm from '../components/core/SocialMediaForm';
import SupportInfo from '../components/core/SupportInfo';
import UserManagement from '../components/core/UserManagement';
import WebsiteAnalytics from '../components/core/WebsiteAnalytics';
import BusinessPlanPage from '../components/core/billing/BusinessPlanPage';
import {
  STANDARD_PLAN_NAME,
  PROFESSIONAL_PLAN_NAME,
  ADMIN_GROUP_NAME,
  BUSINESS_OWNER_GROUP_NAME,
  BBOT_SALES_GROUP_NAME,
  CHOWNOW_SALES_GROUP_NAME,
} from './auth';
import EmailTriggersPage from '../components/pages/EmailTriggersPage';

// Developers Components
import RecipeManager from '../components/developers/recipe-manager/RecipeManagerPage';
import RecipesEditForm from '../components/developers/recipes-editor-v2/RecipesEditForm';
import DevelopersPartialsPage from '../components/developers/partials/PartialPage';
import DevelopersRecipesEditForm from '../components/developers/recipes/DevelopersRecipesEditForm';
// Branding
import ColorPaletteSelection from '../components/modules/colors/components/ColorPaletteSelection';
import StyleRulesPage from '../components/pages/StyleRulesPage';
import YOUTUBE_IDS from './help-videos';
// eCommerce Components
import SettingsContainer from '../components/pages/ecommerce/settings/SettingsContainer';
import CatalogContainer from '../components/pages/ecommerce/catalog/CatalogContainer';
import OrdersContainer from '../components/pages/ecommerce/orders/OrdersContainer';
import ReportsContainer from '../components/pages/ecommerce/reports/ReportsContainer';
import {
  BUSINESS_CATEGORY,
  ENTERTAINMENT_CATEGORY,
  HAIR_CATEGORY,
  HELP_CENTER_URL,
  MERCHANT_BUSINESS_TYPE,
  RESTAURANT_CATEGORY,
  SALON_CATEGORY,
  SPA_CATEGORY,
  TATTOO_PARLOR_CATEGORY,
} from './constants';

import TypographyPage from '../components/pages/TypographyPage';
import NavigationPage from '../components/pages/NavigationPage';
import CustomersPage from '../components/pages/CustomersPage/CustomersPage';
import { RECIPE_NAME_URBANISTA } from './recipes';

const DEVELOPER_ROUTES = {
  path: 'developers',
  name: 'Developer',
  products: [],
  routes: [
    {
      path: 'recipes',
      name: 'Recipe',
      roles: [ADMIN_GROUP_NAME],
      routes: [
        {
          path: 'edit',
          name: 'Recipe Editor',
          component: DevelopersRecipesEditForm,
          roles: [],
          routes: [],
        },
        {
          path: 'partials',
          name: 'Partials',
          component: DevelopersPartialsPage,
          roles: [],
          routes: [],
        },
        {
          path: 'recipes-v2',
          name: 'Recipes Editor v2',
          hasToolbar: true,
          component: RecipesEditForm,
          roles: [],
          routes: [],
        },
        {
          path: 'recipe-manager',
          name: 'Recipe Manager',
          component: RecipeManager,
          roles: [],
          routes: [],
        },
      ],
    },
  ],
};

const ACCOUNT_ROUTES = {
  path: 'account',
  name: 'Account',
  products: ['Website'],
  routes: [
    {
      path: 'info',
      name: 'Account Info',
      component: AccountInfoForm,
      roles: [],
      routes: [],
    },
    {
      path: 'change-password',
      name: 'Security',
      hasToolbar: false,
      component: ChangePasswordForm,
      roles: [],
      routes: [],
    },
    {
      path: 'billing',
      name: 'Billing',
      component: BusinessPlanPage,
      icon: 'credit card',
      roles: [ADMIN_GROUP_NAME, BUSINESS_OWNER_GROUP_NAME],
      routes: [],
    },
  ],
};

const ADMIN_ROUTES = {
  path: 'admin',
  name: 'Admin',
  icon: 'wrench',
  roles: [
    BUSINESS_OWNER_GROUP_NAME,
    ADMIN_GROUP_NAME,
    BBOT_SALES_GROUP_NAME,
    CHOWNOW_SALES_GROUP_NAME,
  ],
  routes: [
    {
      path: 'users',
      name: 'User Management',
      component: UserManagement,
      roles: [
        BUSINESS_OWNER_GROUP_NAME,
        ADMIN_GROUP_NAME,
        BBOT_SALES_GROUP_NAME,
        CHOWNOW_SALES_GROUP_NAME,
      ],
    },
    {
      path: 'support',
      name: 'Support Info',
      component: SupportInfo,
      roles: [ADMIN_GROUP_NAME],
    },
    {
      path: 'email-triggers',
      name: 'Email Triggers',
      component: EmailTriggersPage,
      roles: [ADMIN_GROUP_NAME],
    },
  ],
};

const CORE_ROUTES = {
  path: 'core',
  name: 'Core',
  products: ['Website'],
  routes: [
    {
      path: 'profile',
      name: 'Business Info',
      component: BusinessInfo,
      icon: 'building',
      roles: [],
      routes: [],
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.BUSINESS_INFO,
      },
    },
    {
      path: 'locations',
      name: 'Hours/Location',
      component: Locations,
      icon: 'location arrow',
      roles: [],
      routes: [],
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.HOURS_LOCATION,
      },
    },
    {
      path: 'images',
      name: 'Images',
      component: ImagesPage,
      icon: 'image outline',
      routes: [],
      statusContext: {
        business_files_created: true,
      },
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.IMAGES,
      },
    },
    {
      path: 'content',
      name: 'Content',
      component: ContentPage,
      icon: 'write square',
      youtubeId: {},
      shouldDisplay: shouldDisplayContentPage,
    },
    {
      path: 'menu',
      name: {
        [SPA_CATEGORY]: 'Services',
        [SALON_CATEGORY]: 'Services',
        [BUSINESS_CATEGORY]: 'Services',
        [ENTERTAINMENT_CATEGORY]: 'Services',
        [TATTOO_PARLOR_CATEGORY]: 'Services',
        [RESTAURANT_CATEGORY]: 'Menu',
      },
      component: MenuForm,
      icon: {
        [SPA_CATEGORY]: 'clipboard list',
        [SALON_CATEGORY]: 'clipboard list',
        [BUSINESS_CATEGORY]: 'clipboard list',
        [ENTERTAINMENT_CATEGORY]: 'clipboard list',
        [TATTOO_PARLOR_CATEGORY]: 'clipboard list',
        [RESTAURANT_CATEGORY]: 'food',
      },
      roles: [],
      routes: [],
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.MENU,
      },
    },
    {
      path: 'navigation',
      name: 'Navigation',
      component: NavigationPage,
      icon: 'external',
      roles: [],
      routes: [],
      featureFlags: [],
    },
    {
      path: 'socialmedia',
      name: 'Social Media',
      component: SocialMediaForm,
      icon: 'chat',
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.SOCIAL_MEDIA,
      },
    },
    {
      path: 'branding',
      name: 'Branding',
      icon: 'paint brush',
      featureSets: [STANDARD_PLAN_NAME, PROFESSIONAL_PLAN_NAME],
      routes: [
        {
          path: 'color-palette',
          name: 'Color Palette',
          component: ColorPaletteSelection,
          roles: [],
        },
        {
          path: 'typography',
          name: 'Typography',
          component: TypographyPage,
          roles: [],
        },
        {
          path: 'style-rules',
          name: 'Style Rules',
          component: StyleRulesPage,
          roles: [ADMIN_GROUP_NAME],
          featureFlags: [],
        },
      ],
    },
    {
      path: 'reviews',
      name: 'Reviews',
      component: ReviewsForm,
      icon: 'star',
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.REVIEWS,
      },
    },
    {
      path: 'customercontacts',
      name: 'Customers',
      component: CustomersPage,
      icon: 'users',
      routes: [],
      featureSets: [PROFESSIONAL_PLAN_NAME],
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.CUSTOMERS,
      },
    },
    {
      path: 'blog',
      name: 'Blog Posts',
      component: BlogListForm,
      icon: 'write',
      routes: [],
      featureSets: [PROFESSIONAL_PLAN_NAME],
      youtubeId: {
        [RESTAURANT_CATEGORY]: YOUTUBE_IDS.BLOG_POSTS,
      },
      recipeExclusionList: [RECIPE_NAME_URBANISTA],
    },
    {
      path: 'staff',
      name: 'Staff',
      icon: 'users',
      categories: [SALON_CATEGORY, SPA_CATEGORY, HAIR_CATEGORY],
      component: TeamPage,
      recipeExclusionList: [RECIPE_NAME_URBANISTA],
    },
    {
      path: 'artists',
      name: 'Artists',
      icon: 'users',
      categories: [TATTOO_PARLOR_CATEGORY],
      component: TeamPage,
    },
    {
      path: 'analytics',
      name: 'Analytics',
      component: WebsiteAnalytics,
      icon: 'rocket',
      routes: [],
      roles: [],
      featureSets: [PROFESSIONAL_PLAN_NAME],
    },
    {
      path: 'html-scripts',
      name: 'Custom HTML',
      icon: 'code',
      component: HtmlElementsPage,
      roles: [ADMIN_GROUP_NAME],
    },
    {
      path: 'custom-settings',
      name: 'Custom Settings',
      icon: 'sticky note outline',
      component: CustomSettingsPage,
      roles: [ADMIN_GROUP_NAME],
      businessTypes: [MERCHANT_BUSINESS_TYPE],
      recipeExclusionList: [RECIPE_NAME_URBANISTA],
    },
    {
      path: 'integrations',
      name: 'Integrations',
      icon: 'plug',
      component: Integrations,
    },
    {
      path: 'help-center',
      name: 'Help Center',
      icon: 'help',
      component: null,
      redirectTo: HELP_CENTER_URL,
      shouldDisplay: shouldDisplayHelpCenter,
    },
    ADMIN_ROUTES,
  ],
};

const ECOMMERCE_ROUTES = {
  path: 'ecommerce',
  name: 'eCommerce',
  products: [],
  routes: [
    {
      path: 'settings',
      name: 'Settings',
      component: SettingsContainer,
      hasToolbar: false,
      roles: [],
      routes: [],
      featureFlags: ['eCommerce'],
    },
    {
      path: 'catalog',
      name: 'Catalog',
      component: CatalogContainer,
      hasToolbar: false,
      roles: [],
      routes: [],
      featureFlags: ['eCommerce'],
    },
    {
      path: 'orders',
      name: 'Orders',
      component: OrdersContainer,
      hasToolbar: false,
      roles: [],
      routes: [],
      featureFlags: ['eCommerce'],
    },
    {
      path: 'reports',
      name: 'Reports',
      component: ReportsContainer,
      hasToolbar: false,
      roles: [],
      routes: [],
      featureFlags: ['eCommerce'],
    },
  ],
};

const routes = [DEVELOPER_ROUTES, CORE_ROUTES, ECOMMERCE_ROUTES, ACCOUNT_ROUTES];

export default routes;

export const REDIRECT_QUERY_PARAM = 'next';
export const REDIRECT_LOGIN_ROUTE = '/login/';
export const REDIRECT_DEFAULT_ROUTE = '/core/profile/';
