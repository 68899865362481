import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import {
  interactionToCamelCase,
  interactionToSnakeCase,
} from './TileInteractionsConfiguration.utils';
import WebsiteComponentInteractionContainer from '../../../../../website-component-interaction/WebsiteComponentInteractionContainer';
import { getTileInteractions } from '../../../../services/tile';
import { TilePropType } from '../../../../types/Tile';
import { CustomHeroFormContext } from '../../CustomHeroForm.context';

import './TileInteractionsConfiguration.scss';

const propTypes = {
  tileIndex: PropTypes.number.isRequired,
  tile: TilePropType.isRequired,
};

const defaultProps = {};

export default function TileInteractionsConfiguration({ tileIndex, tile }) {
  const { setWebsiteInteractions } = useContext(CustomHeroFormContext);

  const interactions = getTileInteractions(tile).map(interactionToSnakeCase);

  function onInteractionsChange(newInteractions) {
    setWebsiteInteractions({ tileIndex }, newInteractions.map(interactionToCamelCase));
  }

  return (
    <div className="tile-interactions-configuration">
      <Form.Group>
        <label htmlFor="tile-interactions-group">Buttons</label>
        <p className="configure">Configure up to two buttons to appear.</p>
        <WebsiteComponentInteractionContainer
          id="tile-interactions-group"
          interactions={interactions}
          onUpdate={onInteractionsChange}
        />
      </Form.Group>
    </div>
  );
}

TileInteractionsConfiguration.propTypes = propTypes;
TileInteractionsConfiguration.defaultProps = defaultProps;
