import _isEmpty from 'lodash/isEmpty';

import Utils from '../../../../libs/utils';

export function getItemByIds(items = [], itemIds = []) {
  const categoryItemSet = new Set(itemIds);

  return items
    .map((item, index) => ({ ...item, globalIndex: index }))
    .filter(({ id, localId }) => categoryItemSet.has(id || localId))
    .sort(Utils.sortByOrder);
}

export function getCategoryItems(category = {}, items = []) {
  const { items: categoryItemIds = [] } = category;

  return getItemByIds(items, categoryItemIds);
}

export function getCategoriesWithItems(categories = [], items = []) {
  return categories
    .map((category) => {
      const categoryItems = getCategoryItems(category, items);
      if (_isEmpty(categoryItems)) {
        return null;
      }
      return {
        ...category,
        items: categoryItems,
      };
    })
    .filter(Boolean);
}

export function getItemsForVariationIds(items = [], variationIds = []) {
  return items.filter(({ variations }) => {
    return variations.some(({ id }) => variationIds.includes(id));
  });
}
