export function getCloverURL() {
  return (
    `${process.env.REACT_APP_CLOVER_MARKET_HOST}` +
    `/appmarket/apps/${process.env.REACT_APP_CLOVER_APP_ID}`
  );
}

export function launchCloverMarketplace() {
  const cloverUrl = getCloverURL();
  window.location.replace(cloverUrl);
}
