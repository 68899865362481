import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

import './InputField.css';

const propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  value: undefined,
  defaultValue: undefined,
  maxLength: undefined,
  placeholder: '',
  className: '',
  disabled: false,
};

export default function InputField({
  value,
  defaultValue,
  onChange,
  maxLength,
  placeholder,
  className,
  disabled,
}) {
  function onChangeWrapper(e, { value: nextValue }) {
    onChange(nextValue);
  }

  return (
    <Input
      value={value}
      onChange={onChangeWrapper}
      maxLength={maxLength}
      placeholder={placeholder}
      className={className}
      defaultValue={defaultValue}
      disabled={disabled}
    />
  );
}

InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;
