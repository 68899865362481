import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Message } from 'semantic-ui-react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import { useBusinessFiles } from 'components/modules/files/hooks';
import useSemanticUiProps from 'hooks/useSemanticUiProps';
import ErrorHandler from 'libs/errors/errors';
import { multiCaseGet } from 'libs/format';
import { ObjectPropType } from 'libs/proptypes';

import WithLoading from '../../../../../../common/WithLoading';
import GridView from '../../../../../../core/FileManagement/GridView';
import { StockImagesPickerContext } from '../../StockImagesPicker.context';

import './StockSearchResults.scss';

const propTypes = {
  onSave: PropTypes.func,
  onPostSave: PropTypes.func,
  saveButtonProps: ObjectPropType,
};

const defaultProps = {
  onSave: null,
  onPostSave: null,
  saveButtonProps: {},
};

const LoadingGridView = WithLoading(GridView);

export default function StockSearchResults({ onSave: onSaveProp, onPostSave, saveButtonProps }) {
  const { getButtonProps } = useSemanticUiProps();

  const { searched, onShowMore, stockImages, loading } = useContext(StockImagesPickerContext);

  const { createBusinessFilesFromUrls } = useBusinessFiles();

  const [selectedStockFiles, setSelectedStockFiles] = useState([]);
  const [saving, setSaving] = useState(false);

  const hasFiles = !_isEmpty(stockImages);

  function isStockFileSelected(stockFile) {
    const url = multiCaseGet(stockFile, 'stockUrl');
    return selectedStockFiles.includes(url);
  }

  function getItemExtraParams(stockFile) {
    const className = clsx({
      selected: isStockFileSelected(stockFile),
    });

    return { className };
  }

  function onStockFileClick(stockFile) {
    const url = multiCaseGet(stockFile, 'stockUrl');

    setSelectedStockFiles((previousSelectedStockFiles) => {
      if (isStockFileSelected(stockFile)) {
        return previousSelectedStockFiles.filter((selectedUrl) => selectedUrl !== url);
      }
      return [...previousSelectedStockFiles, url];
    });
  }

  async function onSave() {
    setSaving(true);
    try {
      if (onSaveProp) {
        await onSaveProp(selectedStockFiles);
      } else {
        await createBusinessFilesFromUrls(selectedStockFiles);
      }

      if (onPostSave) {
        await onPostSave();
      }
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="stock-search-results">
      {hasFiles && (
        <>
          <LoadingGridView
            files={stockImages}
            onClick={onStockFileClick}
            isFileDisabled={() => false}
            isFileSelected={isStockFileSelected}
            getItemExtraParams={getItemExtraParams}
          />

          <div className="show-more-container">
            <Button onClick={onShowMore} disabled={saving || loading} content="Show more" />
          </div>

          <div className="actions">
            <Button
              onClick={onSave}
              disabled={saving || loading || _isEmpty(selectedStockFiles)}
              loading={saving}
              primary
              content="Save"
              {...getButtonProps(saveButtonProps)}
            />
          </div>
        </>
      )}
      {!hasFiles && searched && (
        <Message>
          <Message.Header>No stock images found</Message.Header>
          <span>
            You can click on one of the suggested search terms below or enter a new search.
          </span>
        </Message>
      )}
    </div>
  );
}

StockSearchResults.propTypes = propTypes;
StockSearchResults.defaultProps = defaultProps;
