/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import useOrderReports from '../../../../../hooks/useOrderReports';
import { SelectedOrdersContext } from '../../orders/OrdersContainer/OrdersContainer.context';

import './OrderReportsContainer.scss';

const propTypes = {
  initialFilters: PropTypes.object,
  loadingFilters: PropTypes.bool,
};

const defaultProps = {
  initialFilters: {},
  loadingFilters: false,
};

export default function OrderReportsContainer({ initialFilters, loadingFilters, children }) {
  const { refreshOrders, onRefreshOrders } = useContext(SelectedOrdersContext) || {};

  const {
    orders,
    summary,
    currentPage,
    totalPages,
    loading,
    filters,
    errorMessage,
    setCurrentPage,
    setFilters,
    fetchReport,
  } = useOrderReports(initialFilters);

  function onChangeFilter(name, value) {
    setFilters({
      ...filters,
      [name]: value,
    });
  }

  function onPageChange(e, { activePage }) {
    setCurrentPage(activePage);
  }

  function onUpdateFilters() {
    setFilters(initialFilters);
  }

  function onRefreshOrdersInternal() {
    if (refreshOrders) {
      fetchReport();
    }
  }

  useEffect(onUpdateFilters, [...Object.values(initialFilters)]);

  if (onRefreshOrders) {
    useEffect(onRefreshOrdersInternal, [refreshOrders]);
  }

  return (
    <Segment loading={loading || loadingFilters} className="order-reports-container">
      {children({
        orders,
        summary,
        currentPage,
        totalPages,
        filters,
        errorMessage,
        onPageChange,
        onChangeFilter,
      })}
    </Segment>
  );
}

OrderReportsContainer.prototype = propTypes;
OrderReportsContainer.defaultProps = defaultProps;
