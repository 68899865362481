import React, { Component } from 'react';

import { Icon, Table, Button, Grid } from 'semantic-ui-react';

import clsx from 'clsx';
import _get from 'lodash/get';
import { connect } from 'react-redux';

import { didReviewsUpdate, areAllReviewsSelected, isSomeReviewSelected } from './ReviewsForm.utils';
import ReviewSubmissionToggle from './components/ReviewSubmissionToggle';
import ReviewsTableHeader from './components/ReviewsTableHeader';
import ReviewsTableRow from './components/ReviewsTableRow';
import {
  initializeReviews as initializeReviewsConnect,
  updateReview as updateReviewConnect,
  addBusinessReview as addBusinessReviewConnect,
  deleteReview as deleteReviewConnect,
} from '../../../actions/business';
import API from '../../../libs/api';
import FormContainer from '../../common/FormContainer';

import './ReviewsForm.scss';

class ReviewsForm extends Component {
  constructor(props) {
    super(props);

    this.handleToDisplayChange = this.handleToDisplayChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.deleteSelectedReviews = this.deleteSelectedReviews.bind(this);
    this.deselectAllReviews = this.deselectAllReviews.bind(this);
    this.selectAllReviews = this.selectAllReviews.bind(this);
    this.toggleSelectedReviews = this.toggleSelectedReviews.bind(this);
    this.isReviewSelected = this.isReviewSelected.bind(this);
    this.toggleReviewCheck = this.toggleReviewCheck.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.state = {
      checkedReviewIds: new Set(),
      isIndeterminate: false,
      isChecked: false,
    };
  }

  async componentDidMount() {
    const { loaded, initializeReviews, businessType, businessId } = this.props;
    if (!loaded) {
      const result = await API.get(`/businesses/${businessType}s/${businessId}/reviews/`);
      const { data } = result;
      initializeReviews(data);
    }
  }

  componentDidUpdate(prevProps) {
    const { reviews } = this.props;
    if (didReviewsUpdate(prevProps.reviews, reviews)) {
      this.initializeCheckedReviews();
    }
  }

  handleToDisplayChange(toDisplay, index) {
    const { updateReview } = this.props;
    updateReview({ field: 'to_display', fieldValue: toDisplay, index });
  }

  handleInputChange(e, target, index) {
    const { updateReview } = this.props;
    updateReview({ field: target, fieldValue: e.target.value, index });
  }

  onDelete() {
    this.deleteSelectedReviews();
  }

  initializeCheckedReviews() {
    this.setState({ checkedReviewIds: new Set(), isChecked: false, isIndeterminate: false });
  }

  selectAllReviews() {
    const { reviews } = this.props;
    this.setState({
      checkedReviewIds: new Set([...reviews.map(({ id, localId }) => id || localId)]),
      isChecked: true,
      isIndeterminate: false,
    });
  }

  deselectAllReviews() {
    this.setState({
      checkedReviewIds: new Set(),
      isChecked: false,
      isIndeterminate: false,
    });
  }

  isReviewSelected(reviewId) {
    const { checkedReviewIds } = this.state;
    return checkedReviewIds.has(reviewId);
  }

  toggleSelectedReviews(approve) {
    const { reviews } = this.props;

    reviews.forEach((review, index) => {
      if (this.isReviewSelected(review.id || review.localId)) {
        this.handleToDisplayChange(approve, index);
      }
    });
  }

  toggleReviewCheck(reviewId) {
    const { reviews } = this.props;
    this.setState((currentState) => {
      const { checkedReviewIds: currentCheckedReviewIds } = currentState;
      const checkedReviewIds = currentCheckedReviewIds.has(reviewId)
        ? new Set([...currentCheckedReviewIds].filter((id) => id !== reviewId))
        : new Set([...currentCheckedReviewIds, reviewId]);

      return {
        checkedReviewIds,
        isChecked: areAllReviewsSelected(reviews, checkedReviewIds),
        isIndeterminate:
          isSomeReviewSelected(checkedReviewIds) &&
          !areAllReviewsSelected(reviews, checkedReviewIds),
      };
    });
  }

  deleteSelectedReviews() {
    const { reviews, deleteReview } = this.props;

    reviews.forEach((review, index) => {
      if (this.isReviewSelected(review.id || review.localId)) {
        deleteReview(index);
      }
    });

    this.deselectAllReviews();
  }

  render() {
    const { reviews, addBusinessReview } = this.props;
    const { isIndeterminate, isChecked, checkedReviewIds } = this.state;

    return (
      <>
        <Grid stackable className="reviews-form-actions">
          <Grid.Column width={6}>
            <Button className="action-button" onClick={addBusinessReview} size="small">
              <Icon name="plus" /> Add Review
            </Button>
          </Grid.Column>
          <Grid.Column width={10}>
            <ReviewSubmissionToggle />
          </Grid.Column>
        </Grid>
        <FormContainer loadedKeyPath={['business', 'reviews']}>
          <Table className="reviews-table" celled padded verticalAlign="middle" compact>
            <ReviewsTableHeader
              isIndeterminate={isIndeterminate}
              isChecked={isChecked}
              selectAll={this.selectAllReviews}
              deselectAll={this.deselectAllReviews}
              onDelete={this.onDelete}
              approveReviews={() => this.toggleSelectedReviews(true)}
              disapproveReviews={() => this.toggleSelectedReviews(false)}
              amountSelected={checkedReviewIds.size}
            />

            <Table.Body>
              {reviews.map((review, index) => (
                <ReviewsTableRow
                  key={review.id || review.localId || index}
                  className={clsx({
                    'selected-row': this.isReviewSelected(review.id || review.localId),
                  })}
                  onSelect={() => this.toggleReviewCheck(review.id || review.localId)}
                  selected={this.isReviewSelected(review.id || review.localId)}
                  onToggleApproval={() => this.handleToDisplayChange(!review.to_display, index)}
                  onInputChange={this.handleInputChange}
                  review={review}
                  reviewIndex={index}
                />
              ))}
            </Table.Body>
          </Table>
        </FormContainer>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  initializeReviews: (payload) => dispatch(initializeReviewsConnect(payload)),
  updateReview: (payload) => dispatch(updateReviewConnect(payload)),
  addBusinessReview: (payload) => dispatch(addBusinessReviewConnect(payload)),
  deleteReview: (payload) => dispatch(deleteReviewConnect(payload)),
});

const mapStateToProps = ({ business }) => ({
  loaded: _get(business, 'reviews._loaded'),
  reviews: _get(business, 'reviews.value'),
  businessType: _get(business, 'core.value.type'),
  businessId: _get(business, 'core.value.id'),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsForm);
