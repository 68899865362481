import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';

import { DEFAULT_SENTENCE } from '../../constants';
import { TypographyContext } from '../../contexts/Typography.context';
import { getFontFamily } from '../../services/font';
import FontVariantText from '../FontVariantText/FontVariantText';

import './FontCard.scss';

const propTypes = {
  family: PropTypes.string.isRequired,
  sentence: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
};
const defaultProps = {
  sentence: DEFAULT_SENTENCE,
  onClick: null,
};

export default function FontCard({ family, sentence, variants, onClick }) {
  const { fontSize } = useContext(TypographyContext);

  const plural = variants.length > 1 ? 's' : '';

  return (
    <Card className="font-card" onClick={onClick}>
      <Card.Header>
        <div className="family">{getFontFamily(family)}</div>
        <div className="variants">
          {variants.length} style{plural}
        </div>
      </Card.Header>
      <Card.Description>
        <FontVariantText text={sentence} family={family} size={fontSize} />
      </Card.Description>
    </Card>
  );
}

FontCard.propTypes = propTypes;
FontCard.defaultProps = defaultProps;
