import React, { useState } from 'react';

import { Button, Checkbox, Container, Segment, Table } from 'semantic-ui-react';

import _get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';

import {
  defaultToastMenuVisibilityChannels,
  toastMenuVisibilityChannels,
} from './ToastSettings.constants';
import {
  initializeProviderServiceSettings,
  initializeLocations,
  deleteProviderServiceSetting,
  chooseMenuSource,
} from '../../../../../../actions/business';
import { updateWebsitePages } from '../../../../../../actions/website';
import API from '../../../../../../libs/api';
import { isAdminUser } from '../../../../../../libs/auth';
import ErrorHandler from '../../../../../../libs/errors';
import { selectBusinessBrandIdentity } from '../../../../../../selectors/business';
import { selectUser } from '../../../../../../selectors/user';
import AdminRequiredMessage from '../../../components/AdminRequiredMessage';
import { getToastProviderServiceSetting } from '../../ToastIntegration.utils';

const ToastSettingsForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser) || {};
  const isAdmin = isAdminUser(user);
  const brandIdentity = useSelector(selectBusinessBrandIdentity);
  const [hasChanged, setHasChanged] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const businessId = useSelector((state) => state.business.core.value.id);
  const businessType = useSelector((state) => state.business.core.value.type);
  const providerServiceSettings = useSelector(
    (state) => state.business.provider_service_settings.value,
  );
  const providerServiceSetting = getToastProviderServiceSetting(providerServiceSettings);
  const importHours = _get(providerServiceSetting, 'status_data.import_hours', true);
  const importMenu = _get(providerServiceSetting, 'status_data.import_menu', true);
  const importLocation = _get(providerServiceSetting, 'status_data.import_locations', true);
  const importMenuVisibilityData = _get(
    providerServiceSetting,
    'status_data.import_menu_visibility',
    defaultToastMenuVisibilityChannels,
  );
  const [hoursEnabled, setHoursEnabled] = useState(importHours);
  const [menuEnabled, setMenuEnabled] = useState(importMenu);
  const [importMenuVisibility, setImportMenuVisibility] = useState(importMenuVisibilityData);
  const [locationEnabled, setLocationEnabled] = useState(importLocation);

  async function refreshData() {
    const { data } = await API.getBusinessInitializationData(businessType, businessId);
    const locationData = _get(data, 'locations');
    const serviceSettingsPayload = _get(data, 'provider_service_settings');
    const menuSourceType = _get(data, 'menu_source_type');
    const websitePages = _get(data, 'website.pages');

    dispatch(initializeLocations(locationData));
    dispatch(initializeProviderServiceSettings(serviceSettingsPayload));
    dispatch(updateWebsitePages(websitePages));
    dispatch(chooseMenuSource({ value: menuSourceType, bypassTouch: true }));
  }

  async function onDelete() {
    try {
      await API.deleteProviderServiceSettings(businessId, businessType, providerServiceSetting.id);
      setSubmitting(false);

      dispatch(
        deleteProviderServiceSetting({
          fields: {
            index: providerServiceSetting.globalIndex,
            ignoreTouch: true,
          },
        }),
      );
    } catch (e) {
      setSubmitting(false);
      ErrorHandler.capture(e);
    }
  }

  async function onSubmit() {
    const payload = {
      import_hours: hoursEnabled,
      import_menu: menuEnabled,
      import_locations: locationEnabled,
      import_menu_visibility: importMenuVisibility,
    };

    setSubmitting(true);
    try {
      await API.updateToastMerchant(businessId, businessType, providerServiceSetting.id, payload);
      await refreshData();
      setHasChanged(false);
    } catch (e) {
      ErrorHandler.capture(e);
    } finally {
      setSubmitting(false);
    }
  }

  function handleLocationToggleChange(event, { checked }) {
    setHasChanged(true);

    setLocationEnabled(checked);
    setHoursEnabled(checked);
  }

  function getImportMenuVisibility(key) {
    return _get(importMenuVisibility, key, false);
  }

  function onImportMenuVisibilityChange(key, value) {
    const keyLowercase = key.toLowerCase();
    setImportMenuVisibility({ ...importMenuVisibility, [keyLowercase]: value });
  }

  function getFormSegment() {
    if (!isAdmin) {
      return (
        <Segment attached>
          <AdminRequiredMessage brandIdentity={brandIdentity} />
        </Segment>
      );
    }

    return (
      <Segment attached>
        <Table className="toast-settings-table" unstackable>
          <Table.Header>
            <Table.HeaderCell textAlign="left">Toast Sync Settings</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Enabled</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="left">Import Menu from Toast POS</Table.Cell>
              <Table.Cell textAlign="right">
                <Checkbox
                  name="menuEnabled"
                  checked={menuEnabled}
                  onChange={(_, { checked }) => {
                    setHasChanged(true);
                    setMenuEnabled(checked);
                  }}
                  toggle
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="left">Import Location data from Toast POS</Table.Cell>
              <Table.Cell textAlign="right">
                <Checkbox
                  name="locationEnabled"
                  checked={locationEnabled}
                  onChange={handleLocationToggleChange}
                  toggle
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="left">Import Hours from Toast POS</Table.Cell>
              <Table.Cell textAlign="right">
                <Checkbox
                  name="hoursEnabled"
                  checked={hoursEnabled}
                  onChange={(_, { checked }) => {
                    setHasChanged(true);
                    setHoursEnabled(checked);
                  }}
                  disabled={!locationEnabled}
                  toggle
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Table className="toast-menu-visibility-table" unstackable>
          <Table.Header>
            <Table.HeaderCell textAlign="left">Toast Menu Visibility Settings</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Enabled</Table.HeaderCell>
          </Table.Header>
          <Table.Body>
            {toastMenuVisibilityChannels.map(({ key, value }) => (
              <Table.Row key={key}>
                <Table.Cell textAlign="left">{value}</Table.Cell>
                <Table.Cell textAlign="right">
                  <Checkbox
                    name={`menuVisible${key}`}
                    checked={getImportMenuVisibility(key)}
                    onChange={(_, { checked }) => {
                      setHasChanged(true);
                      onImportMenuVisibilityChange(key, checked);
                    }}
                    toggle
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Container textAlign="center">
          <Button
            primary
            size="large"
            onClick={onSubmit}
            loading={submitting}
            disabled={submitting || !hasChanged}
          >
            Save and Import
          </Button>
        </Container>
      </Segment>
    );
  }

  return (
    <>
      {getFormSegment()}
      <Segment attached="bottom">
        <Container textAlign="center">
          <Button negative size="large" onClick={onDelete} disabled={submitting}>
            Uninstall
          </Button>
        </Container>
      </Segment>
    </>
  );
};

export default ToastSettingsForm;
