import PropTypes from 'prop-types';

export const TeamMemberServicesTablePropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variationName: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceDisplay: PropTypes.string,
  priceOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceDisplayOverride: PropTypes.string,
  categoryName: PropTypes.string,
  categoryRowSpan: PropTypes.number,
  itemName: PropTypes.string,
  itemRowSpan: PropTypes.number,
});
