import { v4 as uuid } from 'uuid';

import StateUtils from '.';

const MenuStateUtils = {
  addMenuEntity(state, entityName, entityRelationName, defaultEntityType, { entityRelationIndex }) {
    const localId = uuid();
    const withAddedEntity = StateUtils.addItem(
      state,
      ['menu', 'value', entityName],
      { ...StateUtils.createDefaultType(defaultEntityType), localId },
      true,
    );

    const singularEntityName = entityName.slice(0, entityName.length - 1);
    const updatedRelationValues = {
      [entityName]: [
        ...StateUtils.getObject(withAddedEntity, [
          'menu',
          'value',
          entityRelationName,
          entityRelationIndex,
          entityName,
        ]),
        { [singularEntityName]: localId },
      ],
    };

    return StateUtils.updateListItem(
      withAddedEntity,
      ['menu', 'value', 'modifier_sets'],
      entityRelationIndex,
      updatedRelationValues,
      true,
    );
  },

  updateMenuEntity(
    state,
    entityName,
    entityRelationName,
    { entityIndex, entityValues, localEntityId, entityRelationIndex, entityLink },
  ) {
    const withUpdatedEntity = StateUtils.updateListItem(
      state,
      ['menu', 'value', entityName],
      entityIndex,
      entityValues,
    );

    if (entityRelationIndex > -1) {
      const singularEntityName = entityName.slice(0, entityName.length - 1);
      const updatedRelationValues = {
        [entityName]: withUpdatedEntity.menu.value[entityRelationName][entityRelationIndex][
          entityName
        ].map((entity) => {
          if (entity[singularEntityName] === localEntityId) return entityLink;
          return entity;
        }),
      };

      return StateUtils.updateListItem(
        withUpdatedEntity,
        ['menu', 'value', entityRelationName],
        entityRelationIndex,
        updatedRelationValues,
        true,
      );
    }

    return withUpdatedEntity;
  },

  deleteMenuEntity(
    state,
    entityName,
    entityRelationName,
    { entityIndex, entityId, entityRelationIndex },
  ) {
    const withDeletedEntity = StateUtils.deleteItem(
      state,
      ['menu', 'value', entityName],
      entityIndex,
      true,
    );

    const singularEntityName = entityName.slice(0, entityName.length - 1);
    const updatedRelationValues = {
      [entityName]: StateUtils.getObject(withDeletedEntity, [
        'menu',
        'value',
        entityRelationName,
        entityRelationIndex,
        entityName,
      ]).filter((entity) => entity[singularEntityName] !== entityId),
    };

    return StateUtils.updateListItem(
      withDeletedEntity,
      ['menu', 'value', entityRelationName],
      entityRelationIndex,
      updatedRelationValues,
      true,
    );
  },
};

export default MenuStateUtils;
