import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Icon, Form, Input, TextArea, Message } from 'semantic-ui-react';

import { CatalogCategoryPropType } from '../../../proptypes';
import DeleteCategoryConfirmationButton from '../DeleteCategoryConfirmationButton';

import './CategoryForm.scss';

const propTypes = {
  category: CatalogCategoryPropType.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.bool,
};
const defaultProps = {
  isSubmitting: false,
  errors: false,
  onSubmit: () => {},
  onCancel: () => {},
  onDelete: () => {},
};

function CategoryForm({ category, onSubmit, onCancel, onDelete, isSubmitting, errors }) {
  const {
    category_data: { name, description },
    object_id: objectId,
  } = category;
  const [categoryName, setCategoryName] = useState(name);
  const [categoryDescription, setCategoryDescription] = useState(description);
  const [isTouched, setIsTouched] = useState(false);

  const isNewCategory = !objectId;

  function handleNameChange(e, { value }) {
    setCategoryName(value);
    setIsTouched(name !== value);
  }

  function handleDescriptionChange(e, { value }) {
    setCategoryDescription(value);
    setIsTouched(description !== value);
  }

  function handleCancel() {
    setCategoryName(name);
    setCategoryDescription(description);
    setIsTouched(false);
    onCancel();
  }

  function handleDelete() {
    onDelete(category);
  }

  function handleSubmit() {
    if (!isTouched) {
      handleCancel();
    } else {
      setIsTouched(false);
      onSubmit(categoryName, categoryDescription);
    }
  }

  return (
    <section>
      <Form className="category-form" error={errors}>
        <Form.Group className="category-form-top-row">
          <Form.Field
            disabled={isSubmitting}
            control={Input}
            label="Category Name"
            placeholder="Enter a category name..."
            className="category-form-input"
            value={categoryName}
            onChange={handleNameChange}
          />
        </Form.Group>
        <Form.Group className="category-form-bottom-row">
          <Form.Field
            disabled={isSubmitting}
            control={TextArea}
            label="Category Description"
            placeholder="Enter a category description..."
            className="category-form-input"
            value={categoryDescription}
            onChange={handleDescriptionChange}
          />
        </Form.Group>
        <Message error>You must provide a name to add a new Category</Message>
        <Form.Group className="category-form-buttons">
          <Form.Button
            type="button"
            disabled={isSubmitting}
            className="category-form-button cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Form.Button>
          <Form.Button
            type="submit"
            disabled={!isTouched}
            loading={isSubmitting}
            className="category-form-button save-button"
            onClick={handleSubmit}
          >
            <Icon name="save outline" title="Save changes" />
            Save
          </Form.Button>
          {!isNewCategory && <DeleteCategoryConfirmationButton onDeleteCategory={handleDelete} />}
        </Form.Group>
      </Form>
    </section>
  );
}

CategoryForm.propTypes = propTypes;
CategoryForm.defaultProps = defaultProps;

export default CategoryForm;
