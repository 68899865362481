import React, { useContext, useState } from 'react';

import useWebsiteURL from '../../../../../hooks/useWebsiteURL/useWebsiteURL';
import ExternalAnchor from '../../../../common/ExternalAnchor/ExternalAnchor';
import Icon from '../../../foundation/components/Icon';
import { CustomPagesContext } from '../../contexts/CustomPages.context';
import { CustomPagePropType } from '../../types/custom-page.type';
import CreateCustomPageTemplateModal from '../CreateCustomPageTemplateModal';
import EditCustomPageModal from '../EditCustomPageModal';
import { STATUS_PUBLISHED } from '../ItemStatusDropdown/ItemStatusDropdown.constants';

import './CustomPageToolbar.scss';

const propTypes = { customPage: CustomPagePropType.isRequired };

const defaultProps = {};

export default function CustomPageToolbar({ customPage }) {
  const { createWebsiteURL } = useWebsiteURL();
  const { page, status } = customPage || {};
  const { title, slug } = page || {};
  const pageUrl = createWebsiteURL(slug);
  const isPublished = status === STATUS_PUBLISHED;

  const { setActivePageId } = useContext(CustomPagesContext);
  const [showEditCustomPageModal, setShowEditCustomPageModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  function removeActivePage() {
    setActivePageId(null);
  }

  function closeEditPageModal() {
    setShowEditCustomPageModal(false);
  }

  function closeCreateTemplateModal() {
    setShowCreateTemplateModal(false);
  }

  function onEditPage() {
    setShowEditCustomPageModal(true);
  }

  function onCreateTemplate() {
    setShowEditCustomPageModal(false);
    setShowCreateTemplateModal(true);
  }

  return (
    <div className="custom-page-toolbar">
      <div className="first">
        <Icon className="back" name="arrow-left" onClick={removeActivePage} />
        <span className="title">{title}</span>
        <Icon className="edit" name="pencil" onClick={onEditPage} />
        {isPublished && (
          <ExternalAnchor href={pageUrl}>
            <Icon name="external" />
          </ExternalAnchor>
        )}
      </div>
      {showEditCustomPageModal && (
        <EditCustomPageModal
          customPage={customPage}
          open={showEditCustomPageModal}
          onClose={closeEditPageModal}
          onSaveAsTemplate={onCreateTemplate}
        />
      )}
      {showCreateTemplateModal && (
        <CreateCustomPageTemplateModal
          open={showCreateTemplateModal}
          onClose={closeCreateTemplateModal}
        />
      )}
    </div>
  );
}

CustomPageToolbar.propTypes = propTypes;
CustomPageToolbar.defaultProps = defaultProps;
