import React from 'react';

import { Message } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';

import LoadingMessage from 'components/modules/foundation/components/LoadingMessage';
import AddRedirectButton from 'components/modules/redirect/components/AddRedirectButton';
import RedirectTable from 'components/modules/redirect/components/RedirectTable';
import { RedirectProvider } from 'components/modules/redirect/contexts/Redirect.context';
import useRedirect from 'components/modules/redirect/hooks/use-redirect';
import { DismissibleMessage } from 'components/modules/utils/components';

import {
  DEFAULT_DISMISSIBLE_MESSAGE_DELAY,
  LOADING_MESSAGE,
  NO_ACTIVE_DOMAIN_MESSAGE,
} from './RedirectTab.constants';

import './RedirectTab.scss';

const propTypes = {};

const defaultProps = {};

export default function RedirectTab() {
  const { primaryDomain, redirects, loading, errorMessage } = useRedirect({ initialFetch: true });

  const context = { redirects };

  return (
    <RedirectProvider value={context}>
      <div className="redirect-tab">
        {loading && <LoadingMessage message={LOADING_MESSAGE} />}
        {!loading && !_isEmpty(primaryDomain) && (
          <div className="main-content">
            <RedirectTable />
            <div className="actions-container">
              <AddRedirectButton />
            </div>
          </div>
        )}
        {!loading && _isEmpty(primaryDomain) && (
          <Message className="no-active-domain" content={NO_ACTIVE_DOMAIN_MESSAGE} />
        )}
        {errorMessage && (
          <DismissibleMessage
            initialVisible
            delay={DEFAULT_DISMISSIBLE_MESSAGE_DELAY}
            error
            content={errorMessage}
          />
        )}
      </div>
    </RedirectProvider>
  );
}

RedirectTab.propTypes = propTypes;
RedirectTab.defaultProps = defaultProps;
