import React from 'react';

import PropTypes from 'prop-types';
import { Form, Radio } from 'semantic-ui-react';

import { DOM_LOCATION_OPTIONS } from '../../../../../../../../constants';

import './PlacementSelection.scss';

const propTypes = {
  onUpdatePlacement: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const defaultTypes = {};

export default function PlacementSelection({ onUpdatePlacement, value }) {
  return (
    <>
      <Form.Field>
        <Radio
          label="All"
          name="filter-selection-placement"
          value={DOM_LOCATION_OPTIONS.All}
          checked={value === DOM_LOCATION_OPTIONS.All}
          onChange={() => onUpdatePlacement(DOM_LOCATION_OPTIONS.All)}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label="Head"
          name="filter-selection-placement"
          value={DOM_LOCATION_OPTIONS.Header}
          checked={value === DOM_LOCATION_OPTIONS.Header}
          onChange={() => onUpdatePlacement(DOM_LOCATION_OPTIONS.Header)}
        />
      </Form.Field>
      <Form.Field>
        <Radio
          label="Body"
          name="filter-selection-placement"
          value={DOM_LOCATION_OPTIONS.Body}
          checked={value === DOM_LOCATION_OPTIONS.Body}
          onChange={() => onUpdatePlacement(DOM_LOCATION_OPTIONS.Body)}
        />
      </Form.Field>
    </>
  );
}

PlacementSelection.propTypes = propTypes;
PlacementSelection.defaultTypes = defaultTypes;
