import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

const propTypes = {
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  active: PropTypes.bool,
  defaultActive: PropTypes.bool,
};

const defaultProps = {
  label: '',
  active: false,
  defaultActive: false,
};

export default function BusinessSelectFilterOption({ onSelect, label, active, defaultActive }) {
  return (
    <Checkbox
      key={label}
      label={label}
      onChange={onSelect}
      checked={active}
      defaultChecked={defaultActive}
    />
  );
}

BusinessSelectFilterOption.propTypes = propTypes;
BusinessSelectFilterOption.defaultProps = defaultProps;
