import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { getStepNumber, getAuthorizeTexts } from './FacebookIntegration.utils';
import FacebookIntegrationAuthorize from './components/FacebookIntegrationAuthorize';
import FacebookIntegrationHeader from './components/FacebookIntegrationHeader';
import FacebookIntegrationSync from './components/FacebookIntegrationSync';
import API from '../../../../libs/api';
import { selectBusiness } from '../../../../selectors';
import { FormContainer, InlineFieldContainer } from '../../../common';
import useAsyncEffect from '../../../modules/foundation/hooks/use-async-effect';

import './FacebookIntegration.scss';

export default function FacebookIntegration() {
  const business = useSelector(selectBusiness);
  const { id: businessId, type: businessType } = business;

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState({});

  useAsyncEffect(async () => {
    const { data } = await API.getFacebookIntegrationTokenInfo(businessId, businessType);
    setLoading(false);
    setToken(data);
  }, []);

  const step = getStepNumber(token);
  const [authorizeButtonText, authorizeBodyText] = getAuthorizeTexts(token);

  return (
    <FormContainer loadedKeyPath={['business', 'provider_service_settings']} loading={loading}>
      <InlineFieldContainer title={<h2>Facebook</h2>} enableDelete={false}>
        <FacebookIntegrationHeader />
        {step === 0 && (
          <FacebookIntegrationAuthorize
            buttonText={authorizeButtonText}
            description={authorizeBodyText}
            business={business}
          />
        )}
        {step === 1 && <FacebookIntegrationSync business={business} />}
      </InlineFieldContainer>
    </FormContainer>
  );
}
