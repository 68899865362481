import React, { useState } from 'react';

import _isEmpty from 'lodash/isEmpty';

import {
  MAX_FILES_ALLOWED,
  MIN_FILES_ALLOWED,
  FILE_MODAL_ACTION_BUTTON_TEXT,
} from './MarkdownImagePlugin.constants';
import { generateImageMarkdown } from './MarkdownImagePlugin.utils';
import FilesManagementModalProvider from '../../../../modules/files/components/FilesManagementModalProvider';

export default function MarkdownImagePlugin() {
  const [open, setOpen] = useState(false);
  const [editor, setEditor] = useState(null);

  function insertMarkdownElement(codemirror, file) {
    const markdown = generateImageMarkdown(file);
    const pos = codemirror.getCursor();
    codemirror.setSelection(pos, pos);
    codemirror.replaceSelection(markdown);
  }

  function onToggleImagesModal() {
    setOpen((prevOpen) => !prevOpen);
  }

  function onSubmit(selectedFiles) {
    const { codemirror } = editor;

    if (!codemirror) {
      return;
    }

    if (!_isEmpty(selectedFiles)) {
      const { file } = selectedFiles[0];
      insertMarkdownElement(codemirror, file);
    }
  }

  const pluginButton = {
    name: 'website-image',
    title: 'Website Image',
    className: 'fa fa-image',
    action: (instance) => {
      setEditor(instance);
      onToggleImagesModal();
    },
  };

  const WebsiteImageModal = (
    <FilesManagementModalProvider
      key="files"
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      actionButtonText={FILE_MODAL_ACTION_BUTTON_TEXT}
      minFilesAllowed={MIN_FILES_ALLOWED}
      maxFilesAllowed={MAX_FILES_ALLOWED}
    />
  );

  return [WebsiteImageModal, pluginButton];
}
