import React, { useContext } from 'react';

import { Icon } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import MainNavigationDropdown from './MainNavigationDropdown';
import MainNavigationLogo from './MainNavigationLogo';
import Routing from '../../../../libs/routing';
import { PreviewWebsiteButton } from '../../../modules/buttons/components';
import NavigationContext from '../../../routes/DashboardRoute/DashboardRoute.context';
import ZendeskWidget from '../../ZendeskWidget';

import '../../../../styles/core/components/main-navigation.scss';

function MainNavigation({ website }) {
  const { setShowMobileNavigation } = useContext(NavigationContext);

  return (
    <div className="main-navigation">
      <ZendeskWidget />
      <div className="mobile-navigation-icon-container">
        <Icon
          name="angle right"
          size="big"
          onClick={() => {
            setShowMobileNavigation(true);
          }}
        />
      </div>
      <Link to={`${Routing.getHomeUrl(website)}`} className="main-navigation-logo-link">
        <MainNavigationLogo />
      </Link>
      <PreviewWebsiteButton />
      <MainNavigationDropdown />
    </div>
  );
}

const mapStateToProps = (state) => ({
  website: _get(state, 'business.core.value.website'),
});

export default connect(mapStateToProps)(MainNavigation);
