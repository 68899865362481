import React, { useState } from 'react';

import { Button, Table } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import MenuItemVariant from './MenuItemVariant';
import PriceField from '../../../fields/PriceField/PriceField';
import PriceLabelToggle from '../common/PriceLabelToggle';

import './MenuItemVariants.scss';

export default function MenuItemVariants({ onChange, variations }) {
  const initialIsMoney = _isEmpty(_get(variations, '[0].priceDisplay', ''));
  const [isMoney, setIsMoney] = useState(initialIsMoney);

  function handleVariantUpdate(index, name, value) {
    const newVariations = [...variations];

    let finalName = name;
    if (name === 'price' && !isMoney) {
      finalName = 'priceDisplay';
    }

    newVariations[index][finalName] = value;
    onChange(newVariations);
  }

  function onPriceToggle(e, { checked }) {
    variations.forEach((_, index) => {
      if (!checked) {
        handleVariantUpdate(index, 'priceDisplay', '');
      }
    });
    setIsMoney(!checked);
  }

  function handleDelete(index) {
    const newVariations = [...variations.slice(0, index), ...variations.slice(index + 1)].map(
      (variation, order) => ({ ...variation, order }),
    );

    onChange(newVariations);
  }

  function addVariation() {
    const newVariation = { name: '', price: 0, priceDisplay: '', order: variations.length };
    const newVariations = [...variations].concat(newVariation);
    onChange(newVariations);
  }

  const TogglePriceCheckbox = (
    <PriceLabelToggle defaultIsMoney={isMoney} onChange={onPriceToggle} />
  );

  if (variations.length === 1) {
    const { priceDisplay, price } = variations[0];

    return (
      <Table celled className="variant-table">
        <Table.Header colSpan="1">
          <Table.Row>
            <Table.HeaderCell>{TogglePriceCheckbox} </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>
              <PriceField
                name="price"
                priceInitialValue={price}
                labelInitialValue={priceDisplay}
                onChange={({ target: { name, value } }) => handleVariantUpdate(0, name, value)}
                isMoney={isMoney}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="2">
              <Button className="primary-action" floated="left" onClick={addVariation}>
                Add Variation
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }

  return (
    <Table celled className="variant-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={3}>{TogglePriceCheckbox}</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell width={8}>Variation</Table.HeaderCell>
          <Table.HeaderCell width={4}>{isMoney ? 'Price' : 'Label'}</Table.HeaderCell>
          <Table.HeaderCell width={2}> </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {variations.map((variation, index) => (
          <MenuItemVariant
            name={variation.name}
            price={variation.priceDisplay || variation.price}
            menuItem={variation.menu_item}
            index={index}
            onUpdate={handleVariantUpdate}
            onDelete={handleDelete}
            key={`${variation.id}-${variation.order}`}
            isMoney={isMoney}
          />
        ))}
      </Table.Body>

      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="4">
            <Button className="primary-action" floated="left" onClick={addVariation}>
              Add Variation
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}
