export const TEXT_FIELD_NAME = 'text';
export const EMAIL_FIELD_NAME = 'email';
export const PHONE_FIELD_NAME = 'tel';

export const INTERNAL_FORM_FIELD_TYPES = {
  MultipleChoice: 'MultipleChoice',
  SingleChoice: 'SingleChoice',
  ShortText: 'ShortText',
  LongText: 'LongText',
  Email: 'Email',
  PhoneNumber: 'PhoneNumber',
  FileUpload: 'FileUpload',
};

export const RECIPE_FORM_FIELD_TYPES = {
  MultipleChoice: 'multiSelect',
  SingleChoice: 'select',
  ShortText: 'text',
  LongText: 'textarea',
  Email: 'email',
  PhoneNumber: 'tel',
  FileUpload: 'fileUpload',
};

export const FIELD_TYPE_TO_WIDGET = {
  [RECIPE_FORM_FIELD_TYPES.ShortText]: 'input',
  [RECIPE_FORM_FIELD_TYPES.Email]: 'input',
  [RECIPE_FORM_FIELD_TYPES.PhoneNumber]: 'input',
};

export const FIELD_TYPE_TO_INPUT_TYPE = {
  [RECIPE_FORM_FIELD_TYPES.ShortText]: TEXT_FIELD_NAME,
  [RECIPE_FORM_FIELD_TYPES.Email]: EMAIL_FIELD_NAME,
  [RECIPE_FORM_FIELD_TYPES.PhoneNumber]: PHONE_FIELD_NAME,
};

export const INPUT_TYPE_TO_RECIPE_FIELD_TYPE = {
  [TEXT_FIELD_NAME]: RECIPE_FORM_FIELD_TYPES.ShortText,
  [EMAIL_FIELD_NAME]: RECIPE_FORM_FIELD_TYPES.Email,
  [PHONE_FIELD_NAME]: RECIPE_FORM_FIELD_TYPES.PhoneNumber,
};

export const FIELD_WIDGET_TO_TYPE = {
  input: RECIPE_FORM_FIELD_TYPES.ShortText,
};
