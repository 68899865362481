/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Grid, Header, Button, Icon } from 'semantic-ui-react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import { getTilesWidths } from '../../services/tile';
import { CustomHeroFormContext } from '../CustomHeroForm/CustomHeroForm.context';

import './TemplateLayout.scss';

const propTypes = {
  showTriggerEdit: PropTypes.bool,
};

const defaultProps = {
  showTriggerEdit: false,
};

export default function TemplateLayout({ showTriggerEdit }) {
  const { customHero, activeTileIndex, setActiveTileIndex } = useContext(CustomHeroFormContext);
  const { tiles = [] } = customHero;
  const widths = getTilesWidths(tiles);

  if (_isEmpty(widths)) {
    return null;
  }

  return (
    <Grid className="template-layout-preview">
      <Grid.Row className="title">
        <Grid.Column width={16}>Template Preview</Grid.Column>
      </Grid.Row>
      <Grid.Row className="template-layout-row">
        <Grid.Column>
          <Grid className="template-layout" textAlign="center">
            {widths.map((width, index) => (
              <Grid.Column
                key={`${width}_${index}`}
                width={width}
                className={clsx({ active: index === activeTileIndex })}
              >
                {showTriggerEdit && (
                  <Button className="trigger-button" onClick={() => setActiveTileIndex(index)}>
                    <Icon name="pencil" />
                    Tile {index + 1}
                  </Button>
                )}
                <Header as="div">Title</Header>
                <Button className="action-button">Button</Button>
              </Grid.Column>
            ))}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

TemplateLayout.propTypes = propTypes;
TemplateLayout.defaultProps = defaultProps;
