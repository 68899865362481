import React from 'react';

import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import CloseableModal from '../CloseableModal/CloseableModal';
import YoutubeEmbedded from '../YoutubeEmbedded';

import './YoutubeEmbeddedModal.scss';

const propTypes = {
  youtubeId: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const defaultProps = {
  open: false,
};

export default function YoutubeEmbeddedModal({ youtubeId, open, onClose }) {
  return (
    <CloseableModal
      open={open}
      onClose={onClose}
      className="video-embedded-modal"
      header="Learn more about this section"
    >
      <Modal.Content>
        <YoutubeEmbedded youtubeId={youtubeId} />
      </Modal.Content>
    </CloseableModal>
  );
}

YoutubeEmbeddedModal.propTypes = propTypes;
YoutubeEmbeddedModal.defaultProps = defaultProps;
