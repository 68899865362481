import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import Icon from '../../../../modules/foundation/components/Icon';

import './SelectedBox.scss';

const propTypes = {
  selected: PropTypes.bool,
};

const defaultProps = {
  selected: false,
};

export default function SelectedBox({ selected }) {
  const className = clsx('selected-box', { selected });

  return (
    <div className={className}>
      <div className="icon-container">{selected && <Icon name="check" />}</div>
    </div>
  );
}

SelectedBox.propTypes = propTypes;
SelectedBox.defaultProps = defaultProps;
