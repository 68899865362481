import React from 'react';

import PropTypes from 'prop-types';
import { Label } from 'semantic-ui-react';

import _get from 'lodash/get';

import AddPhotoButton from '../../../AddPhotoButton';
import EditPhotoButton from '../../../EditPhotoButton';
import {
  CatalogItemPropType,
  CatalogItemVariationPropType,
  CatalogImagePropType,
} from '../../../proptypes';

import './CatalogItem.scss';

const propTypes = {
  item: CatalogItemPropType.isRequired,
  variations: PropTypes.arrayOf(CatalogItemVariationPropType),
  images: PropTypes.arrayOf(CatalogImagePropType),
  onEdit: PropTypes.func,
};

const defaultProps = {
  variations: [],
  images: [],
  onEdit: () => null,
};

function CatalogItem({ item, variations, images, onEdit }) {
  const {
    item_data: { name, available = true },
  } = item;
  const price = _get(variations, '[0].item_variation_data.price');
  const imageUrl = _get(images, '[0].image_data.url');

  return (
    <article className="catalog-item-detail">
      {imageUrl && (
        <EditPhotoButton
          className="catalog-item-detail-button"
          onClick={onEdit}
          imageUrl={imageUrl}
        />
      )}
      {!imageUrl && <AddPhotoButton className="catalog-item-detail-button" onClick={onEdit} />}

      <span className="catalog-item-detail-name">{name}</span>
      <div className="catalog-item-detail-details">
        <span className="catalog-item-detail-price">{price ? `$${price}` : 'No Price'}</span>
        {!available && (
          <Label small className="catalog-item-detail-label" color="red" horizontal>
            Unavailable
          </Label>
        )}
      </div>
    </article>
  );
}

CatalogItem.propTypes = propTypes;
CatalogItem.defaultProps = defaultProps;
export default CatalogItem;
