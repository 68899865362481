import { DAY_SMART_LEAD_SOURCE } from '../../../libs/lead-source/constants';

export const OPTIONS_BY_LEAD_SOURCE = {
  [DAY_SMART_LEAD_SOURCE]: {
    'Hair Care': ['Barber Shop'],
    'Tattoo Parlor': ['Tattoo Parlor'],
    Spa: ['Spa'],
    Salon: ['Beauty Salon', 'Hair Salon', 'Nail Salon'],
  },
};

export const DEFAULT_OPTIONS = {
  Business: [
    'Accountant',
    'Auto Dealer',
    'Body Art Shop',
    'Clothing Store',
    'Convenience Store',
    'Daycare',
    'Dispensary',
    'Landscaping',
    'Lawyer',
    'Night Club',
    'Pet Care',
    'Plumber',
  ],
  Restaurant: [
    'BBQ',
    'Bagels',
    'Bakery',
    'Bar',
    'Bar & Grill',
    'Bistro',
    'Burgers',
    'Cafe',
    'Cajun & Creole',
    'Candy Store',
    'Caribbean',
    'Catering',
    'Chicken',
    'Chinese',
    'Cupcake Shop',
    'Deli',
    'Dessert Shop',
    'Diner',
    'Donut Shop',
    'Drive-in',
    'Family Style',
    'Fine Dining',
    'Florist',
    'Frozen Yogurt',
    'Hibachi',
    'Ice Cream',
    'Jamaican',
    'Japanese',
    'Korean',
    'Late Night',
    'Liquor Store',
    'Mediterranean',
    'New American',
    'Pastry Shop',
    'Pie Shop',
    'Pizza',
    'Pub',
    'Salad Bar',
    'Sandwich',
    'Seafood',
    'Smoothie & Juice Bar',
    'Soul Food',
    'Southern',
    'Southwestern',
    'Steakhouse',
    'Sushi',
    'Vegetarian & Vegan Restaurant',
    'Vietnamese',
    'Italian/Pasta',
    'Mexican',
    'Breakfast/Diner',
    'Indian',
    'Asian',
  ],
  'Hair Care': ['Barber Shop'],
  'Tattoo Parlor': ['Tattoo Parlor'],
  Spa: ['Spa'],
  Salon: ['Beauty Salon', 'Hair Salon', 'Nail Salon'],
};
