import { v4 as uuid } from 'uuid';

import {
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
} from '../../../../../../../constants/recipes';
import StateUtils from '../../../../../../../libs/state';

export const dayMapByValue = {
  A_MON: 1,
  B_TUE: 2,
  C_WED: 3,
  D_THU: 4,
  E_FRI: 5,
  F_SAT: 6,
  G_SUN: 7,
};

export const dayMapByDay = {
  1: 'A_MON',
  2: 'B_TUE',
  3: 'C_WED',
  4: 'D_THU',
  5: 'E_FRI',
  6: 'F_SAT',
  7: 'G_SUN',
};

export const emptyHours = [1, 2, 3, 4, 5, 6, 7].map((day) => {
  return StateUtils.createDefaultType('HOURS_OF_OPERATION', { day, id: null, localId: uuid() });
});

export const INTERACTIONS_RECIPES = [
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_MUSE,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const TEAM_MEMBER_MULTIPLE_IMAGES_RECIPES = [
  RECIPE_NAME_MUSE,
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
export const TEAM_MEMBER_ADVANCED_SEO_RECIPES = [
  RECIPE_NAME_MUSE,
  RECIPE_NAME_CUTILIER,
  RECIPE_NAME_HIBISCUS,
  RECIPE_NAME_CALENDULA,
];
