import React from 'react';

import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import {
  WOFLOW_UPLOAD_TYPE_FILE,
  WOFLOW_UPLOAD_TYPE_URL,
} from '../../WoflowFilesUploadModal.constants';

const propTypes = {
  onSelect: PropTypes.func.isRequired,
};

function WoflowUploadDropdown({ onSelect }) {
  function handleChange(event, { value }) {
    onSelect(event, value);
  }

  return (
    <Dropdown text="Upload..." labeled button icon="plus" className="icon action-button">
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={handleChange}
          text="From Computer"
          value={WOFLOW_UPLOAD_TYPE_FILE}
        />
        <Dropdown.Item
          onClick={handleChange}
          text="From URL import"
          value={WOFLOW_UPLOAD_TYPE_URL}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}

WoflowUploadDropdown.propTypes = propTypes;
export default WoflowUploadDropdown;
