import { useState } from 'react';

import API from 'libs/api';
import { getErrorMessage } from 'libs/errors';

export default function useStockFiles() {
  const [searchValue, setSearchValue] = useState('');
  const [searched, setSearched] = useState(false);
  const [stockImages, setStockImages] = useState([]);
  const [searchPage, setSearchPage] = useState(1);
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  async function onSearch({ showMore = false } = {}) {
    if (loading) {
      return;
    }
    setIsLoading(true);

    try {
      const finalSearchPage = !showMore ? 1 : searchPage + 1;
      const data = await API.searchCuratedStockImages(searchValue, finalSearchPage);
      setSearched(true);
      setStockImages((prevStockImages) => (showMore ? [...prevStockImages, ...data] : [...data]));
      setSearchPage(finalSearchPage);
    } catch (e) {
      setError(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  }

  async function onShowMore() {
    await onSearch({ showMore: true });
  }

  return {
    searchValue,
    searched,
    stockImages,
    loading,
    error,
    onSearch,
    onShowMore,
    setSearchValue,
  };
}
