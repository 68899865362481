import React from 'react';

import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

const propTypes = {
  q: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fluid: PropTypes.bool,
  loading: PropTypes.bool,
};

const defaultProps = {
  q: '',
  placeholder: '',
  fluid: false,
  loading: false,
};

function PartialSearch({ q, placeholder, onChange, fluid, loading }) {
  return (
    <Input
      onChange={onChange}
      value={q}
      placeholder={placeholder}
      fluid={fluid}
      loading={loading}
    />
  );
}

PartialSearch.propTypes = propTypes;

PartialSearch.defaultProps = defaultProps;

export default PartialSearch;
