import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { getContentNodeCompletion, getWritingActions } from './ContentNodeForm.utils';
import ErrorHandler, { getErrorMessage } from '../../../../../libs/errors/errors';
import { selectBusinessId, selectBusinessType } from '../../../../../selectors';
import { MultiTextField } from '../../../fields/components';
import { PageComponentSettingType } from '../../../pages/types';
import { ContentNodeType } from '../../types';
import ActionsInput from '../ActionsInput';
import WritingAssistantButton from '../WritingAssistantButton';

import './ContentNodeForm.scss';

const propTypes = {
  contentNode: ContentNodeType.isRequired,
  componentConfigurationId: PropTypes.number.isRequired,
  setting: PageComponentSettingType.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

const defaultProps = {
  onSave: () => null,
  onCancel: () => null,
};

export default function ContentNodeForm({
  contentNode,
  componentConfigurationId,
  setting,
  onCancel,
  onSave,
}) {
  const businessType = useSelector(selectBusinessType);
  const businessId = useSelector(selectBusinessId);

  const { content, contentType, maxLength } = contentNode;
  const { contentApplicationOptions = {} } = setting || {};
  const { default: defaultValue = '', required } = contentApplicationOptions || {};

  const [value, setValue] = useState(content || '');
  const [loading, setLoading] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [writingAssistantError, setWritingAssistantError] = useState('');

  const editing = value !== content;
  const saveDisabled = (required && _isEmpty(value)) || loading;

  function handleValueChange(event, target) {
    const newValue = _get(target, 'value', target || event);

    setValue(newValue);
  }

  function clearWritingAssistantError() {
    setWritingAssistantError('');
  }

  function onRestoreDefaultValue() {
    return defaultValue || '';
  }

  function onHideText() {
    return '';
  }

  async function onMakeSuggestion() {
    const params = {
      component_configuration_id: componentConfigurationId,
    };

    try {
      const { text_completion: textCompletion } = await getContentNodeCompletion(
        businessType,
        businessId,
        params,
      );

      return textCompletion;
    } catch (e) {
      ErrorHandler.capture(e);
      setWritingAssistantError(`There was an error generating a suggestion: ${getErrorMessage(e)}`);
      return '';
    }
  }

  useEffect(() => {
    if (content !== value) {
      setValue(content);
    }
  }, [content]);

  async function handleSave() {
    try {
      setLoading(true);
      if (onSave) {
        await onSave(contentNode, value);
      }
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleCancel() {
    try {
      setCanceling(true);
      if (onCancel) {
        await onCancel();
      }
      setValue(content || '');
    } catch (error) {
      ErrorHandler.capture(error);
    } finally {
      setCanceling(false);
    }
  }

  const actionSections = getWritingActions(
    { setting, value },
    {
      onHideText,
      onMakeSuggestion,
      onRestoreDefaultValue,
    },
  );

  return (
    <div className="content-node-form">
      {!canceling && !_isEmpty(actionSections) && (
        <ActionsInput className="catalog-object-description-input">
          <ActionsInput.Input
            input={MultiTextField}
            onChange={handleValueChange}
            value={value}
            fieldType={contentType.toLowerCase()}
            disabled={loading}
            maxLength={maxLength}
          />
          <ActionsInput.Message
            message={writingAssistantError}
            onDismiss={clearWritingAssistantError}
            delay={10}
            warning
          />
          <ActionsInput.Actions
            trigger={WritingAssistantButton}
            actionSections={actionSections}
            adminOnly
          />
        </ActionsInput>
      )}
      {!canceling && _isEmpty(actionSections) && (
        <MultiTextField
          onChange={handleValueChange}
          value={value}
          defaultValue={value}
          fieldType={contentType.toLowerCase()}
          disabled={loading}
          maxLength={maxLength}
        />
      )}
      {editing && (
        <div className="actions">
          <Button className="cancel" disabled={loading} content="CANCEL" onClick={handleCancel} />
          <Button
            className="save"
            disabled={saveDisabled}
            loading={loading}
            content="SAVE"
            icon="save"
            onClick={handleSave}
          />
        </div>
      )}
    </div>
  );
}

ContentNodeForm.propTypes = propTypes;
ContentNodeForm.defaultProps = defaultProps;
