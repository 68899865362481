import { REDIRECT_QUERY_PARAM } from '../../../constants/routes';

export function composeRedirectQuery(path) {
  return `?${REDIRECT_QUERY_PARAM}=${path}`;
}

export function getRedirectPath() {
  const {
    location: { search },
  } = document;
  const searchParams = new URLSearchParams(search);
  const next = searchParams.get(REDIRECT_QUERY_PARAM);
  return next;
}
