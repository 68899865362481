import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
  BRAND_DATA,
  BRAND_IDENTITIES,
  BRANDING_LOCAL_STORAGE_KEY,
  BRANDING_QUERY_PARAM,
} from './useBranding.constants';
import useQueryParams from '../../../../../hooks/useQueryParams';
import storage from '../../../../../libs/storage';
import { selectBusiness } from '../../../../../selectors';

/*
  useBranding

  Defines the appropriate logo and brand text, based on the following rules:

  1. Always use the branding defined for the website in the database. This is
     usually set during the onboarding process.
  2. If on a login page or non-website page, check whether a query param was
     given to define the branding. If so, use the cached value.
  3. If no cached value, default to the Fisherman logo and brand text.
*/
function useBranding() {
  const localStorageValue = storage.get(BRANDING_LOCAL_STORAGE_KEY);
  const business = useSelector(selectBusiness);
  const brandingQueryParam = useQueryParams(BRANDING_QUERY_PARAM);

  const setBrandingFromQueryParam = () => {
    storage.set(brandingQueryParam, BRANDING_LOCAL_STORAGE_KEY);
  };

  const getBrand = (brand) => {
    if (brand === BRAND_IDENTITIES.GLAMMATIC) {
      return BRAND_DATA[BRAND_IDENTITIES.GLAMMATIC];
    }
    return BRAND_DATA[BRAND_IDENTITIES.FISHERMAN];
  };

  useEffect(() => {
    if (brandingQueryParam) setBrandingFromQueryParam();
  }, []);

  // by default: use the branding that is specified for the website.
  if (business?.brandIdentity) return getBrand(business?.brandIdentity);

  // on the login page: check the brand identity that's cached to localStorage
  if (localStorageValue === BRAND_IDENTITIES.GLAMMATIC) {
    return getBrand(BRAND_IDENTITIES.GLAMMATIC);
  }
  // always default to Fisherman branding when unknown
  return getBrand(BRAND_IDENTITIES.FISHERMAN);
}

export default useBranding;
