import _get from 'lodash/get';
import _isNil from 'lodash/isNil';

import { getItemByIds } from '../components/modules/catalog/services/catalog';
import { objectToCamelCase } from '../libs/format';

export function selectBusinessModifierSets(state) {
  return objectToCamelCase(_get(state, 'business.menu.value.modifier_sets', []));
}

export function selectBusinessCatalogCategories(state) {
  return objectToCamelCase(_get(state, 'business.menu.value.categories', []));
}

export function selectBusinessCatalogSchedules(state) {
  return objectToCamelCase(_get(state, 'business.menu.value.schedules', []));
}

export function selectBusinessCatalogItems(state, { itemIds } = {}) {
  let items = _get(state, 'business.menu.value.items', []);

  if (!_isNil(itemIds)) {
    items = getItemByIds(items, itemIds);
  }

  return objectToCamelCase(items);
}
