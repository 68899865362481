import React, { useState, useEffect } from 'react';

import { Loader } from 'semantic-ui-react';

import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import _get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import NavigationContext from './DashboardRoute.context';
import { composeRedirectQuery } from './DashboardRoute.utils';
import { REDIRECT_DEFAULT_ROUTE, REDIRECT_LOGIN_ROUTE } from '../../../constants/routes';
import {
  isAuthorized,
  isUserReady,
  hasToChangePassword,
  identifyUserAndBusiness,
  hasWebsiteFeatureSet,
  isAdminUser,
} from '../../../libs/auth';
import { identifyLaunchDarklyUser } from '../../../libs/launch-darkly';
import Routing from '../../../libs/routing';
import { hasDisabledFeatureFlag } from '../../../libs/routing/flag';
import { selectFeatureSets } from '../../../selectors/subscriptions';
import MainContentContainer from '../../common/navigation/MainContentContainer';
import MainNavigation from '../../common/navigation/MainNavigation';
import SideNavigation from '../../common/navigation/SideNavigation';
import { getBusinessProperty } from '../../common/navigation/SideNavigation/components/SideNavigationContent/SideNavigationContent.utils';
import OnboardingWizard from '../../core/Onboarding/OnboardingWizard/OnboardingWizard';
import TokenValidator from '../../core/TokenValidator';
import useRecipe from '../../modules/foundation/hooks/use-recipe';

import '../../../styles/core/components/main-route.scss';

export default function DashboardRoute({
  component,
  path,
  rootRoutePath,
  hasToolbar = true,
  roles,
  permissions,
  statusContext,
  youtubeId,
  businessTypes,
  categories,
  featureFlags,
  featureSets,
  shouldDisplay,
  recipeExclusionList,
}) {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const websiteFeatureSets = useSelector(selectFeatureSets);
  const ldClient = useLDClient();
  const flags = useFlags();
  const { activeRecipeName } = useRecipe();

  const state = useSelector((storeState) => storeState || {});
  const business = _get(state, 'business.core.value', {});
  const user = _get(state, 'user.core.value', {});
  const website = _get(state, 'website.core.value', {});
  const notifications = _get(state, 'notifications', {});

  useEffect(() => {
    (async () => {
      await identifyLaunchDarklyUser(user, ldClient);
      await identifyUserAndBusiness(user, business);
      setLoading(false);
    })();
  }, []);

  // Ensure path is a legitimate path
  if (path !== location.pathname) {
    return <Navigate to={path} />;
  }

  if (hasToChangePassword(user) && !user.skip_change_password) {
    return <Navigate to="/create-password" />;
  }

  if (!isAuthorized(user, business, roles, permissions, statusContext)) {
    const { pathname } = location;
    const redirectQuery = composeRedirectQuery(pathname);
    return <Navigate to={`${REDIRECT_LOGIN_ROUTE}${redirectQuery}`} />;
  }

  if (isUserReady(user, website, business, notifications)) {
    const { category, type: businessType } = business;

    if (loading) {
      return <Loader active />;
    }

    if (!isAdminUser(user) && !hasWebsiteFeatureSet(websiteFeatureSets, featureSets)) {
      return <Navigate to={REDIRECT_DEFAULT_ROUTE} />;
    }

    if (hasDisabledFeatureFlag(flags, featureFlags)) {
      return <Navigate to={REDIRECT_DEFAULT_ROUTE} />;
    }

    if (!Routing.routeAllowsCategory({ categories }, category)) {
      return <Navigate to={REDIRECT_DEFAULT_ROUTE} />;
    }

    if (!Routing.routeAllowRecipe({ recipeExclusionList }, activeRecipeName)) {
      return <Navigate to={REDIRECT_DEFAULT_ROUTE} />;
    }

    if (!Routing.routeAllowsBusinessType({ businessTypes }, businessType)) {
      return <Navigate to={REDIRECT_DEFAULT_ROUTE} />;
    }

    if (!!shouldDisplay && !shouldDisplay(state)) {
      return <Navigate to={REDIRECT_DEFAULT_ROUTE} />;
    }

    const navigation = { showMobileNavigation, setShowMobileNavigation };
    const name = getBusinessProperty(Routing.getNameFromLocation(location) || '', category);
    const businessYoutubeId = getBusinessProperty(youtubeId, category, false);

    return (
      <NavigationContext.Provider value={navigation}>
        <TokenValidator />
        <MainNavigation />
        <SideNavigation location={location} rootRoutePath={rootRoutePath} />
        <MainContentContainer
          path={path}
          component={component}
          location={location}
          hasToolbar={hasToolbar}
          youtubeId={businessYoutubeId}
          name={name}
        />
        <OnboardingWizard />
      </NavigationContext.Provider>
    );
  }

  return <Navigate to={{ pathname: '/', state: { from: location } }} replace />;
}
