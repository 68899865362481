import React from 'react';

import {
  DEFAULT_CATEGORIES,
  DEFAULT_FONT_SIZE,
  DEFAULT_SENTENCE,
  DEFAULT_SORT,
} from '../constants';

export const TypographyContext = React.createContext({
  allFonts: [],
  setAllFonts: () => null,
  fonts: [],
  setFonts: () => null,
  selectedFont: {},
  searchQuery: '',
  setSearchQuery: () => null,
  sentence: DEFAULT_SENTENCE,
  setSentence: () => null,
  fontSize: DEFAULT_FONT_SIZE,
  setFontSize: () => null,
  categories: DEFAULT_CATEGORIES,
  setCategories: () => null,
  sort: DEFAULT_SORT,
  setSort: () => null,
});

export const TypographyProvider = TypographyContext.Provider;
