import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Modal, Button } from 'semantic-ui-react';

import { FILTER_OPTIONS } from './BusinessSelectFilter.constants';
import BusinessSelectFilterOption from './components/BusinessSelectFilterOption';
import CloseableModal from '../CloseableModal';

import './BusinessSelectFilter.scss';

const propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  selectedFilters: [],
};

export default function BusinessSelectFilter({ selectedFilters, onFilterChange }) {
  const [open, setOpen] = useState(false);
  return (
    <CloseableModal
      className="search-filter-modal"
      trigger={<Button icon="filter" content="Filter" primary labelPosition="right" />}
      open={open}
      size="mini"
      header="Select Search Filters"
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        {FILTER_OPTIONS.map(({ key, label, defaultValue }) => (
          <div key={key} className="filter-option-container">
            <BusinessSelectFilterOption
              onSelect={() => onFilterChange(key)}
              label={label}
              active={selectedFilters.includes(key)}
              defaultActive={defaultValue}
            />
          </div>
        ))}
      </Modal.Content>
    </CloseableModal>
  );
}

BusinessSelectFilter.propTypes = propTypes;
BusinessSelectFilter.defaultProps = defaultProps;
