import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';

import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { MAX_ALLOWED_IMAGES } from './FileSelectionWell.constants';
import { ReactReferencePropTypes } from '../../../../../libs/proptypes';
import ImagesDeck from '../../../images/components/ImagesDeck';
import { BusinessFilePropType } from '../../../images/types/business-file.types';
import { SOURCE_TYPE_DEFAULT } from '../../constants/sources';
import FilesManagementModalProvider from '../FilesManagementModalProvider';

import './FileSelectionWell.scss';

const propTypes = {
  initialFiles: PropTypes.arrayOf(BusinessFilePropType),
  files: PropTypes.arrayOf(BusinessFilePropType),
  onUpdateFiles: PropTypes.func.isRequired,
  uploadSourceType: PropTypes.string,
  maxAllowedImages: PropTypes.number,
  hideImagesDeck: PropTypes.bool,
  editButtonText: PropTypes.string,
  editButtonRef: ReactReferencePropTypes,
};

const defaultProps = {
  initialFiles: [],
  files: null,
  uploadSourceType: SOURCE_TYPE_DEFAULT,
  maxAllowedImages: MAX_ALLOWED_IMAGES,
  hideImagesDeck: false,
  editButtonText: 'Edit images',
  editButtonRef: null,
};

function FileSelectionWell({
  initialFiles,
  files,
  onUpdateFiles,
  uploadSourceType,
  maxAllowedImages,
  hideImagesDeck,
  editButtonText,
  editButtonRef,
}) {
  const isControlled = !_isNil(files);

  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [localFiles, setLocalFiles] = useState(isControlled ? files : initialFiles);

  async function onUpdateLocalFiles(newFiles) {
    setLocalFiles(newFiles);
    await onUpdateFiles(newFiles);
  }

  function onEditButtonClick() {
    setFileModalOpen(true);
  }

  useEffect(() => {
    if (isControlled) {
      setLocalFiles(files);
    }
  }, [files]);

  return (
    <div className="file-upload-well item-images">
      <div className="buttons">
        <Button icon={_isEmpty(editButtonText)} onClick={onEditButtonClick} ref={editButtonRef}>
          {editButtonText}
          <Icon name="pencil" />
        </Button>
      </div>
      {!hideImagesDeck && (
        <ImagesDeck files={localFiles} onUpdateFilesOrdering={onUpdateLocalFiles} />
      )}
      {fileModalOpen && (
        <FilesManagementModalProvider
          open={fileModalOpen}
          setOpen={setFileModalOpen}
          initialSelectedFiles={localFiles}
          minFilesAllowed={null}
          maxFilesAllowed={maxAllowedImages}
          onSubmit={onUpdateLocalFiles}
          uploadSourceType={uploadSourceType}
        />
      )}
    </div>
  );
}

FileSelectionWell.propTypes = propTypes;
FileSelectionWell.defaultProps = defaultProps;
export default FileSelectionWell;
