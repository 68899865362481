import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Table, Checkbox } from 'semantic-ui-react';

import { hasOrders } from './OrdersTableBody.utils';
import NoOrdersRow from './components/NoOrdersRow/NoOrdersRow';
import Currency from '../../../../../../../../../../../libs/currency';
import DateUtils from '../../../../../../../../../../../libs/date-utils';
import OrderDetailsModal from '../../../../../../../../common/OrderDetailsModal';
import { ORDER_AT_DATE_FORMAT } from '../../../../../../../../reports/OrderReports/OrderReports.constants';
import { SelectedOrdersContext } from '../../../../../../OrdersContainer.context';

import './OrdersTableBody.scss';

const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  extraTableCells: PropTypes.arrayOf(PropTypes.func),
};

const defaultProps = {
  extraTableCells: [],
};

function OrdersTableBody({ orders, extraTableCells }) {
  const { selectOrderId, deselectOrderId, isOrderIdSelected } = useContext(SelectedOrdersContext);

  if (!hasOrders(orders)) {
    return <NoOrdersRow />;
  }

  return (
    <Table.Body className="order-table-body">
      {orders.map((order) => {
        const { uuid, merchant_customer: merchantCustomer, closed_at: closedAt, total } = order;
        const {
          first_name: firstName = '',
          last_name: lastName = '',
          email = '',
          phone = '',
        } = merchantCustomer || {};
        const isSelected = isOrderIdSelected(uuid);
        const onSelectOrder = isSelected ? () => deselectOrderId(uuid) : () => selectOrderId(uuid);

        return (
          <Table.Row key={uuid}>
            <Table.Cell collapsing>
              <Checkbox checked={isSelected} onClick={onSelectOrder} />
            </Table.Cell>
            <Table.Cell>{uuid.slice(-4)}</Table.Cell>
            <Table.Cell>
              <OrderDetailsModal orderId={uuid} />
            </Table.Cell>
            <Table.Cell>{`${firstName} ${lastName}`}</Table.Cell>
            <Table.Cell>{phone}</Table.Cell>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell>{DateUtils.format(closedAt, ORDER_AT_DATE_FORMAT)}</Table.Cell>
            <Table.Cell>{Currency.format(total)}</Table.Cell>
            {extraTableCells.map((rowFunc) => (
              <Table.Cell>{rowFunc(order)}</Table.Cell>
            ))}
          </Table.Row>
        );
      })}
    </Table.Body>
  );
}

OrdersTableBody.propTypes = propTypes;
OrdersTableBody.defaultProps = defaultProps;

export default OrdersTableBody;
