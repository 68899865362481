import React from 'react';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import HelpTooltip from '../../../HelpTooltip';
import PlanNavigationPopup from '../../../navigation/SideNavigation/PlanNavigationPopup';

export default function InlineFieldContainerTitle({
  title,
  tooltipTitle,
  tooltipDescription,
  showPlanGating,
  requiredFeatureSets,
}) {
  const planNavigationPopup = (
    <PlanNavigationPopup title={title} requiredFeatureSets={requiredFeatureSets}>
      <Icon name="lock" />
    </PlanNavigationPopup>
  );

  const titleNode = typeof title === 'object' ? title : <h2>{title}</h2>;

  return (
    <>
      {React.cloneElement(titleNode, {}, [
        ...titleNode.props.children,
        showPlanGating && planNavigationPopup,
        tooltipTitle && tooltipDescription && (
          <HelpTooltip title={tooltipTitle} content={tooltipDescription} />
        ),
      ])}
    </>
  );
}

InlineFieldContainerTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltipTitle: PropTypes.string,
  tooltipDescription: PropTypes.string,
  showPlanGating: PropTypes.bool,
  requiredFeatureSets: PropTypes.arrayOf(PropTypes.string),
};

InlineFieldContainerTitle.defaultProps = {
  title: '',
  tooltipTitle: '',
  tooltipDescription: '',
  showPlanGating: false,
  requiredFeatureSets: [],
};
