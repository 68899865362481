import { IS_FEATURED_FIELD, IS_SPECIAL_FIELD } from '../../libs/menu-constants';

export const SETTINGS_OPTIONS = [
  {
    text: 'Show',
    name: 'visible',
    description:
      'Specifies if this item is shown. Set this toggle off if you want to hide it. ' +
      'You must publish your site after changing this setting.',
  },
  {
    text: 'Available',
    name: 'available',
    description:
      'Specifies if this item is currently available. ' +
      'Set this toggle off to indicate the item is not available. You must publish your site after changing this setting.',
  },
  {
    text: 'Special',
    name: IS_SPECIAL_FIELD,
    description:
      'Specifies if this item is included in your Specials. ' +
      'Please contact support if you do not see specials on your site. You must publish your site after changing this setting.',
  },
  {
    text: 'Featured',
    name: IS_FEATURED_FIELD,
    description: 'Featured items will be displayed with priority in the items list.',
  },
];

export const BUTTONS_TOOLTIP_DESCRIPTION =
  "You can configure up to two buttons to appear on the item's full product details page.";

export const ITEM_SHORT_DESCRIPTION_MIN_LENGTH = 100;
export const ITEM_SHORT_DESCRIPTION_MAX_LENGTH = 200;

export const ITEM_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS =
  'Ensure the description is no more than 2 sentences.';

export const ITEM_SHORT_DESCRIPTION_PLACEHOLDER = `Enter a brief overview (max. ${ITEM_SHORT_DESCRIPTION_MAX_LENGTH} characters)...`;

export const ITEM_SHORT_DESCRIPTION_TOOL_TIP = `Provide a concise summary of the item. This description will be used to highlight item and for alternative text descriptions. Keep it within ${ITEM_SHORT_DESCRIPTION_MAX_LENGTH} characters for optimal display.`;

export const ITEM_LONG_DESCRIPTION_MIN_LENGTH = 200;
export const ITEM_LONG_DESCRIPTION_MAX_LENGTH = 400;

export const ITEM_LONG_DESCRIPTION_PLACEHOLDER = `Provide a comprehensive description of this item...`;

export const ITEM_LONG_DESCRIPTION_TOOL_TIP = `Provide a detailed and comprehensive description of the item here. This text will appear on detail pages and will help improve SEO and provide full information about the item. Ensure to include relevant keywords and phrases related to the service.`;

export const ITEM_CATALOG_OBJECT_TYPE = 'Item';
