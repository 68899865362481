import FishermanLogo from '../../../../../static/logos/FishermanTextLogo.svg';
import GlammaticLogo from '../../../../../static/logos/GlammaticLogo.svg';

export const BRAND_IDENTITIES = {
  GLAMMATIC: 'GLAM',
  FISHERMAN: 'FISH',
};

export const GLAMMATIC_BRAND_NAME = 'Glammatic';
export const FISHERMAN_BRAND_NAME = 'Fisherman';

export const BRANDING_QUERY_PARAM = 'brand';
export const BRANDING_LOCAL_STORAGE_KEY = 'initialDashboardBranding';

export const BRAND_DATA = {
  [BRAND_IDENTITIES.GLAMMATIC]: {
    brandName: GLAMMATIC_BRAND_NAME,
    brandLogoURL: GlammaticLogo,
  },
  [BRAND_IDENTITIES.FISHERMAN]: {
    brandName: FISHERMAN_BRAND_NAME,
    brandLogoURL: FishermanLogo,
  },
};
