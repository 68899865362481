import React, { useState } from 'react';

import { Tab, Divider } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';

import EditMenuSchedule from './EditMenuSchedule';
import SortableScheduleCategories from './SortableScheduleCategories/SortableScheduleCategories';
import ViewMenuScheduleAvailability from './ViewMenuScheduleAvailability';
import { addMenuCategory, updateMenuCategory } from '../../../../actions/business';
import API from '../../../../libs/api';
import Utils from '../../../../libs/utils';
import { selectBusinessId, selectBusinessType } from '../../../../selectors';
import {
  selectBusinessCatalogCategories,
  selectBusinessCatalogSchedules,
} from '../../../../selectors/catalog';
import AddMenuCategory from '../category/AddMenuCategory';

export default function MenuSchedule({ index: scheduleIndex, id: scheduleId }) {
  const dispatch = useDispatch();

  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);
  const allCategories = useSelector(selectBusinessCatalogCategories);
  const allSchedules = useSelector(selectBusinessCatalogSchedules);

  const schedule = allSchedules[scheduleIndex] || {};
  let { categories: scheduleCategoryIds = [] } = schedule;
  scheduleCategoryIds = new Set(scheduleCategoryIds);

  const { availableDays = [], startTime, endTime, name = '' } = schedule;

  const isNew = !scheduleId;

  const [open, setOpen] = useState(isNew);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  function getScheduleCategories() {
    return allCategories
      .map((category, idx) => ({ ...category, globalIndex: idx }))
      .filter(
        (category) =>
          scheduleCategoryIds.has(category.id) || scheduleCategoryIds.has(category.localId),
      )
      .sort(Utils.sortByOrder);
  }

  const scheduleCategories = getScheduleCategories();

  async function updateCategoriesOrder(categories) {
    const promises = categories.map((category) =>
      API.updateMenuCategory(businessId, businessType, category.id, {
        order: category.order,
      }),
    );
    return Promise.all(promises);
  }

  async function onSortCategory({ oldIndex, newIndex }) {
    Utils.moveListItem(scheduleCategories, oldIndex, newIndex);
    const categoriesToUpdate = Utils.itemsToUpdateOrder(scheduleCategories);

    categoriesToUpdate.forEach((category) =>
      dispatch(
        updateMenuCategory({
          index: category.globalIndex,
          values: category,
        }),
      ),
    );

    return updateCategoriesOrder(categoriesToUpdate);
  }

  function onClickAddNewCategory() {
    dispatch(
      addMenuCategory({
        scheduleIndex,
        scheduleId,
        categoryIndex: scheduleCategories.length,
      }),
    );
  }

  return (
    <Tab.Pane className="menu-schedule-view">
      <ViewMenuScheduleAvailability
        availableDays={availableDays}
        startTime={startTime}
        endTime={endTime}
        name={name}
        index={scheduleIndex}
      />
      {open && (
        <EditMenuSchedule
          availableDays={availableDays}
          startTime={startTime}
          endTime={endTime}
          name={name}
          index={scheduleIndex}
          id={scheduleId}
          isNew={isNew}
          open={open}
          onOpen={openModal}
          onClose={closeModal}
        />
      )}
      <button type="button" onClick={openModal}>
        Edit
      </button>
      <Divider />
      <SortableScheduleCategories
        axis="y"
        lockAxis="y"
        onSortEnd={onSortCategory}
        categories={scheduleCategories}
        scheduleIndex={scheduleIndex}
        scheduleId={scheduleId}
      />
      <AddMenuCategory onClick={onClickAddNewCategory} />
    </Tab.Pane>
  );
}
