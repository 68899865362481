const YOUTUBE_IDS = {
  BUSINESS_INFO: 'yW5yIsXs7Og',
  HOURS_LOCATION: '0HHldtEOVKc',
  IMAGES: 'owdtLp1ei6A',
  MENU: 'lsgyrYoQgm0',
  SOCIAL_MEDIA: 'mqFOhdoDOzk',
  REVIEWS: '6kdRV9SMM3Y',
  BLOG_POSTS: 'KhC1kiLhBHs',
  CUSTOMERS: '2Mr0cUVDhpw',
};

export default YOUTUBE_IDS;
