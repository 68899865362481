export const createBusiness = (payload) => (dispatch) => {
  dispatch({
    type: 'CREATE_BUSINESS',
    payload,
  });
};

export const resetBusiness = (payload) => (dispatch) => {
  dispatch({
    type: 'RESET_BUSINESS',
    payload,
  });
};

export const createBusinessCore = (payload) => (dispatch) => {
  dispatch({
    type: 'CREATE_BUSINESS_CORE',
    payload,
  });
};

export const flagBusinessSaved = (payload) => (dispatch) => {
  dispatch({
    type: 'FLAG_BUSINESS_SAVED',
    payload,
  });
};

export const updateBusinessName = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_BUSINESS_NAME',
    payload,
  });
};

export const initializeBusinessSocialMedia = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_BUSINESS_SOCIAL_MEDIA',
    payload,
  });
};

export const updateBusinessSocialMedia = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_BUSINESS_SOCIAL_MEDIA',
    payload,
  });
};

export const addBusinessSocialMedia = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_BUSINESS_SOCIAL_MEDIA',
    payload,
  });
};

export const addLocation = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_LOCATION',
    payload,
  });
};

export const deleteLocation = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_LOCATION',
    payload,
  });
};

export const deleteBusinessSocialMedia = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_BUSINESS_SOCIAL_MEDIA',
    payload,
  });
};

export const updateBusinessAbout = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_BUSINESS_ABOUT',
    payload,
  });
};

export const updateBusinessLogo = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_BUSINESS_LOGO',
    payload,
  });
};

export const addLocationHours = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_LOCATION_HOURS',
    payload,
  });
};

export const copyLocationHours = (payload) => (dispatch) => {
  dispatch({
    type: 'COPY_LOCATION_HOURS',
    payload,
  });
};

export const replaceLocationHours = (payload) => (dispatch) => {
  dispatch({
    type: 'REPLACE_LOCATION_HOURS',
    payload,
  });
};

export const updateLocationHours = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOCATION_HOURS',
    payload,
  });
};

export const updateLocationHourById = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOCATION_HOUR_BY_ID',
    payload,
  });
};

export const removeLocationHourById = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_LOCATION_HOUR_BY_ID',
    payload,
  });
};

export const initializeGalleryImages = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_GALLERY_IMAGES',
    payload,
  });
};

export const updateGalleryImage = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_GALLERY_IMAGE',
    payload,
  });
};

export const addGalleryImage = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_GALLERY_IMAGE',
    payload,
  });
};

export const deleteGalleryImage = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_GALLERY_IMAGE',
    payload,
  });
};

export const initializeBusinessFiles = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_BUSINESS_FILES',
    payload,
  });
};

export const updateBusinessFile = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_BUSINESS_FILE',
    payload,
  });
};

export const addBusinessFile = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_BUSINESS_FILE',
    payload,
  });
};

export const deleteBusinessFile = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_BUSINESS_FILE',
    payload,
  });
};

export const initializeTimezones = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_TIMEZONES',
    payload,
  });
};

export const initializeLocations = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_LOCATIONS',
    payload,
  });
};

export const updateLocation = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_LOCATION',
    payload,
  });
};

export const initializeProviderServices = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_PROVIDER_SERVICES',
    payload,
  });
};

export const initializeProviderServiceSettings = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_PROVIDER_SERVICE_SETTINGS',
    payload,
  });
};

export const initializeLocationTaxes = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_LOCATION_TAXES',
    payload,
  });
};

export const initializeReviews = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_REVIEWS',
    payload,
  });
};

export const addBusinessReview = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_BUSINESS_REVIEW',
    payload,
  });
};

export const updateReview = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_REVIEW',
    payload,
  });
};

export const deleteReview = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_REVIEW',
    payload,
  });
};

export const initializeMenu = (payload) => (dispatch) => {
  dispatch({
    type: 'INITIALIZE_MENU',
    payload,
  });
};

export const flagMenuSaved = (payload) => (dispatch) => {
  dispatch({
    type: 'FLAG_MENU_SAVED',
    payload,
  });
};

export const updateOnboardingState = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_ONBOARDING_STATE',
    payload,
  });
};

export const addMenuSchedule = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_MENU_SCHEDULE',
    payload,
  });
};

export const updateMenuSchedule = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_MENU_SCHEDULE',
    payload,
  });
};

export const deleteMenuSchedule = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_MENU_SCHEDULE',
    payload,
  });
};

export const addMenuCategory = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_MENU_CATEGORY',
    payload,
  });
};

export const updateMenuCategory = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_MENU_CATEGORY',
    payload,
  });
};

export const deleteMenuCategory = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_MENU_CATEGORY',
    payload,
  });
};

export const addMenuItem = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_MENU_ITEM',
    payload,
  });
};

export const updateMenuItem = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_MENU_ITEM',
    payload,
  });
};

export const deleteMenuItem = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_MENU_ITEM',
    payload,
  });
};

export const addMenuModifierSet = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_MENU_MODIFIER_SET',
    payload,
  });
};

export const linkItemModifierSet = (payload) => (dispatch) => {
  dispatch({
    type: 'LINK_ITEM_MODIFIER_SET',
    payload,
  });
};

export const removeItemModifierSet = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_ITEM_MODIFIER_SET',
    payload,
  });
};

export const updateMenuModifierSet = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_MENU_MODIFIER_SET',
    payload,
  });
};

export const deleteMenuModifierSet = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_MENU_MODIFIER_SET',
    payload,
  });
};

export const addMenuModifier = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_MENU_MODIFIER',
    payload,
  });
};

export const updateMenuModifier = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_MENU_MODIFIER',
    payload,
  });
};

export const deleteMenuModifier = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_MENU_MODIFIER',
    payload,
  });
};

export const chooseMenuSource = (payload) => (dispatch) => {
  dispatch({
    type: 'CHOOSE_MENU_SOURCE',
    payload,
  });
};

export const clearBusinessTouchedFields = (payload) => (dispatch) => {
  dispatch({
    type: 'CLEAR_BUSINESS_TOUCHED_FIELD',
    payload,
  });
};

export const removeBusinessAddedItem = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_BUSINESS_ADDED_ITEM',
    payload,
  });
};

export const removeBusinessDeletedItem = (payload) => (dispatch) => {
  dispatch({
    type: 'REMOVE_BUSINESS_DELETED_ITEM',
    payload,
  });
};

export const deleteProviderServiceSetting = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_PROVIDER_SERVICE_SETTING',
    payload,
  });
};

export const addNewProviderServiceSetting = (payload) => (dispatch) => {
  dispatch({
    type: 'ADD_NEW_PROVIDER_SERVICE_SETTING',
    payload,
  });
};

export const updateProviderServiceSetting = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_PROVIDER_SERVICE_SETTING',
    payload,
  });
};

export const updateRemoteItemUpdated = (payload) => (dispatch) => {
  dispatch({
    type: 'UPDATE_REMOTE_ITEM_UPDATED',
    payload,
  });
};
