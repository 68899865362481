import * as Sentry from '@sentry/react';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';

import { DEFAULT_ERROR_MESSAGE } from './constants';
import LogRocket from '../logrocket/logrocket';

export function formatErrorMessage(message, depth = 0) {
  const prefix = ''.padStart(depth * 2, ' ');

  if (!_isObject(message)) {
    return prefix + message;
  }
  return Object.keys(message).reduce((fullMessage, messageKey) => {
    const messageValue = message[messageKey];
    let nextMessage = messageValue;
    if (Array.isArray(messageValue)) {
      if (messageValue.some(_isObject)) {
        nextMessage = messageValue
          .map((value) => `\n${prefix}${formatErrorMessage(value, depth + 1)}`)
          .filter((value) => !!value.trim())
          .join(' , ');
      } else {
        nextMessage = messageValue.join(' , ');
      }
    } else if (_isObject(messageValue)) {
      nextMessage = `\n${prefix}${formatErrorMessage(messageValue, depth + 1)}`;
    }

    const currentMessage =
      messageKey === '__all__' ? nextMessage : `${messageKey} - ${nextMessage}`;

    return `${fullMessage}${prefix}${currentMessage}\n`;
  }, '');
}

export function getErrorMessage(e, defaultMessage = DEFAULT_ERROR_MESSAGE) {
  return _get(e, 'response.status') >= 500
    ? defaultMessage
    : formatErrorMessage(
        _get(e, 'response.data', '') ||
          _get(e, 'response.data.message', '') ||
          _get(e, 'response.data.detail', '') ||
          _get(e, 'response.statusText', '') ||
          defaultMessage,
      );
}

export default class ErrorHandler {
  static capture(error, extra = {}) {
    LogRocket.captureException(error, extra);
    Sentry.captureException(error, extra);
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
