import _get from 'lodash/get';

import { GMB_AUTH_ERROR_ACCESS_DENIED } from './GoogleMyBusiness.constants';

export function locationCanUploadMenu(gmbLocation) {
  return _get(gmbLocation, 'metadata.canHaveFoodMenus', false);
}

export function hasGoogleMyBusinessToken(providerServiceSettings) {
  return providerServiceSettings.some(
    (serviceSettings) =>
      _get(serviceSettings, 'provider') === 'Google' &&
      _get(serviceSettings, 'service_type') === 'Profile' &&
      _get(serviceSettings, 'service_data') &&
      _get(serviceSettings, 'service_data.token_present'),
  );
}

export const getErrorHeaderAndMessage = (errorCode) => {
  let header = 'Google Business Profile authorization failed';
  let error =
    'There was an error when trying to authorize Fisherman access to your ' +
    'Google Business Profile account. Please, try again. If the problem persist ' +
    'contact our support team.';

  if (errorCode === GMB_AUTH_ERROR_ACCESS_DENIED) {
    header = 'Google Business Profile access permission was denied';
    error =
      'You need to give Fisherman permission to handle Google Business Profile to ' +
      'synchronize information from your business like hours, location and ' +
      'menu with Google Business Profile';
  }

  return [header, error];
};

export function getGoogleMyBusinessAccountId(providerServiceSettings) {
  const googleProfileSettings = providerServiceSettings.find(
    (serviceSettings) =>
      _get(serviceSettings, 'provider') === 'Google' &&
      _get(serviceSettings, 'service_type') === 'Profile' &&
      _get(serviceSettings, 'service_data') &&
      _get(serviceSettings, 'service_data.gmb_account_id'),
  );
  return _get(googleProfileSettings, 'service_data.gmb_account_id');
}

export function getLocationSettingForGMBLocation(locationSettings, gmbLocation) {
  const setting = locationSettings.find((locationSetting) => {
    return (
      locationSetting.service_data.gmb_account_id === gmbLocation.account_id &&
      locationSetting.service_data.gmb_location_id === gmbLocation.location_id
    );
  });
  return setting || {};
}
