import React, { useState } from 'react';

import _find from 'lodash/find';
import _pull from 'lodash/pull';
import { useSelector } from 'react-redux';

import { selectWebsitePages } from '../../../selectors/website';
import { HtmlElementsGrid } from '../../modules/html-elements/components';
import { PAGE_LOCATION_OPTIONS } from '../../modules/html-elements/constants';
import { HtmlElementPageProvider } from '../../modules/html-elements/context/HtmlElements.context';
import { useHtmlElements } from '../../modules/html-elements/hooks';

import './HtmlElementsPage.scss';

export default function HtmlElementsPage() {
  const { saveItemState, undoItemState } = useHtmlElements();
  const websitePages = useSelector(selectWebsitePages);
  const [errorMessage, setErrorMessage] = useState('');
  const [filter, setFilter] = useState({
    placement: '',
    pages: [PAGE_LOCATION_OPTIONS.Global, ...websitePages.map(({ id }) => id)],
  });
  const [isSavingItemToDb, setIsSavingItemToDb] = useState(false);
  const [dirtyIds, setDirtyIds] = useState([]);
  const [itemEditingId, setItemEditingId] = useState(null);
  const [item, setItem] = useState(null);

  function addDirtyId(id) {
    if (!_find(dirtyIds, id)) {
      setDirtyIds([...dirtyIds, id]);
    }
  }

  function removeDirtyId(id) {
    if (!_find(dirtyIds, id)) {
      setDirtyIds([..._pull(dirtyIds, id)]);
    }
  }

  const context = {
    errorMessage,
    setErrorMessage,
    filter,
    setFilter,
    itemEditingId,
    setItemEditingId,
    item,
    setItem,
    saveItemState,
    undoItemState,
    dirtyIds,
    addDirtyId,
    removeDirtyId,
    isSavingItemToDb,
    setIsSavingItemToDb,
  };

  return (
    <HtmlElementPageProvider value={context}>
      <HtmlElementsGrid />
    </HtmlElementPageProvider>
  );
}
