import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Grid, Icon, Accordion } from 'semantic-ui-react';

import clsx from 'clsx';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import { shouldHighlightItems } from './NavigationGroupItem.utils';
import Dots from '../../../../../../common/sortables/Dots';
import { SortingContext } from '../../../../../foundation/context/sorting/SortingContext.context';
import { PagePropType } from '../../../../../pages/types/page';
import { SortableNavigationItem } from '../NavigationItem/NavigationItem';
import NavigationItemEdit from '../NavigationItemEdit/NavigationItemEdit';
import NavigationItemTitle from '../NavigationItemTitle/NavigationItemTitle';

import './NavigationGroupItem.scss';

const DragHandle = SortableHandle(() => <Dots size="5px" />);

const propTypes = {
  page: PagePropType.isRequired,
  collectionName: PropTypes.string.isRequired,
};
const defaultProps = {};

export default function NavigationGroupItem({ page, collectionName }) {
  const sortingContext = useContext(SortingContext);
  const {
    focusedGroupCollection,
    setFocusedGroupCollection,
    initialFocusedGroupCollection,
    setInitialFocusedGroupCollection,
    initialFocusedGroupChildCollection,
    setInitialFocusedGroupChildCollection,
    setFocusedGroupChildCollection,
    dragging,
  } = sortingContext;

  const [accordionActive, setAccordionActive] = useState(true);

  function onHover() {
    if (focusedGroupCollection !== collectionName) {
      setFocusedGroupCollection(collectionName);
    }
    if (initialFocusedGroupCollection !== collectionName && !dragging) {
      setInitialFocusedGroupCollection(collectionName);
    }
  }

  function onHoverLeave() {
    setFocusedGroupCollection('');
    if (!dragging) {
      setInitialFocusedGroupCollection('');
    }
  }

  function onHoverChildPage(childCollectionName) {
    setFocusedGroupChildCollection(childCollectionName);
    if (initialFocusedGroupChildCollection !== childCollectionName && !dragging) {
      setInitialFocusedGroupChildCollection(childCollectionName);
    }
  }

  function onHoverLeaveChildPage() {
    setFocusedGroupChildCollection('');
    if (!dragging) {
      setInitialFocusedGroupChildCollection('');
    }
  }

  function toggleAccordion() {
    setAccordionActive((prevState) => !prevState);
  }
  const { pages = [] } = page;

  const highlightItems = shouldHighlightItems(collectionName, sortingContext);
  const finalItemsClassName = clsx({ 'items-container': true, highlight: highlightItems });

  const childCollectionName = `${collectionName}_childPagesGroup`;

  return (
    <div
      className="navigation-group-item"
      onMouseEnter={onHover}
      onTouchStart={onHover}
      onMouseLeave={onHoverLeave}
      onTouchEnd={onHoverLeave}
    >
      <Accordion>
        <Accordion.Title active={accordionActive}>
          <Grid columns={4} stackable>
            <Grid.Column width={1} textAlign="center" verticalAlign="middle">
              <DragHandle />
            </Grid.Column>
            <Grid.Column width={6} className="title-column" verticalAlign="middle">
              <NavigationItemTitle page={page} />
            </Grid.Column>
            <Grid.Column width={6} verticalAlign="middle" />
            <Grid.Column
              width={2}
              className="accordion-toggle"
              textAlign="right"
              verticalAlign="middle"
            >
              <div>
                <Icon
                  name={accordionActive ? 'minus circle' : 'plus circle'}
                  onClick={toggleAccordion}
                />
              </div>
            </Grid.Column>
            <Grid.Column width={1} textAlign="center" verticalAlign="middle">
              <NavigationItemEdit page={page} />
            </Grid.Column>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={accordionActive}>
          <div className={finalItemsClassName}>
            {pages.map((childPage, i) => (
              <SortableNavigationItem
                index={i}
                collection={childCollectionName}
                page={childPage}
                onMouseEnter={() => {
                  onHoverChildPage(childCollectionName);
                }}
                onMouseLeave={() => {
                  onHoverLeaveChildPage(childCollectionName);
                }}
              />
            ))}
          </div>
        </Accordion.Content>
      </Accordion>
    </div>
  );
}

NavigationGroupItem.propTypes = propTypes;
NavigationGroupItem.defaultProps = defaultProps;

const SortableNavigationGroupItem = SortableElement(NavigationGroupItem);
export { SortableNavigationGroupItem };
