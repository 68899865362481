import React from 'react';

import { Header } from 'semantic-ui-react';

import { menuItems } from './ReportsContainer.constants';
import LocationSelect from '../../common/LocationSelect/LocationSelect';
import SideMenuNavigation from '../../common/SideMenuNavigation';
import Toolbar from '../../common/Toolbar';

import './ReportsContainer.scss';

function ReportsContainer() {
  return (
    <div className="reports-container-ui">
      <Header as="h1" className="ecomm-section-header">
        Reports
      </Header>
      <Toolbar>
        <Toolbar.Actions>
          <LocationSelect global />
        </Toolbar.Actions>
      </Toolbar>
      <SideMenuNavigation menuItems={menuItems} />
    </div>
  );
}

export default ReportsContainer;
