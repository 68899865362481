import _isEmpty from 'lodash/isEmpty';
import _startCase from 'lodash/startCase';

import { MAX_AMOUNT_OF_BUTTONS } from '../../constants/constants';

export function getInitialData(page) {
  if (_isEmpty(page)) {
    return { name: '', asButton: false };
  }
  const { name, groupName, asButton } = page;
  return { name: name || groupName || '', asButton: !!asButton };
}

export function getFieldsToValidate() {
  return ['name'];
}

export function getFormErrors(formData) {
  const requiredFields = getFieldsToValidate();
  const errors = {};

  Object.keys(formData).forEach((key) => {
    const value = formData[key];

    if (!requiredFields.includes(key)) {
      return;
    }

    if (_isEmpty(value)) {
      errors[key] = `${_startCase(key)} is required.`;
    }
  });

  return errors;
}

export function isAsButtonDisabled(buttonPages) {
  return buttonPages.length >= MAX_AMOUNT_OF_BUTTONS;
}
