import PropTypes from 'prop-types';

const TeamMemberPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  role: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  show: PropTypes.bool,
});

export default TeamMemberPropType;
