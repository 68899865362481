import React from 'react';

import { Message, Button, Radio, List, Container, Segment } from 'semantic-ui-react';

class GMBAccountList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAccountId: null,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSelect(e, { value }) {
    this.setState({ selectedAccountId: value });
  }

  handleSubmit() {
    const { onAccountSelect } = this.props;
    const { selectedAccountId } = this.state;
    if (selectedAccountId !== null) {
      onAccountSelect({ gmb_account_id: selectedAccountId });
    }
  }

  render() {
    const { accounts } = this.props;
    const { selectedAccountId } = this.state;

    // If accounts have not been loaded yet
    if (!accounts) {
      return <Segment attached="bottom" padded="very" loading />;
    }

    // Display message if there are no accounts
    if (accounts && !accounts.length) {
      return (
        <Message>
          <Message.Header>Accounts Unavailable</Message.Header>
          <p>We could not find any accounts to link.</p>
        </Message>
      );
    }

    return (
      <Segment attached="bottom">
        <List selection divided size="huge" verticalAlign="middle">
          {accounts.map((accountData) => (
            <List.Item
              key={accountData.account_id}
              value={accountData.account_id}
              onClick={this.handleSelect}
            >
              <List.Content verticalAlign="middle" floated="right">
                <Radio
                  name="account"
                  value={accountData.account_id}
                  checked={accountData.account_id === selectedAccountId}
                />
              </List.Content>
              <List.Content float="right">
                <List.Header as="h4">{accountData.accountName}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
        <Container textAlign="right">
          <Button disabled={!selectedAccountId} onClick={this.handleSubmit} primary>
            Confirm
          </Button>
        </Container>
      </Segment>
    );
  }
}

export default GMBAccountList;
