import { IS_FEATURED_FIELD } from '../../libs/menu-constants';

export const SETTINGS_OPTIONS = [
  {
    text: 'Featured',
    name: IS_FEATURED_FIELD,
    description: 'Featured categories will be displayed with priority in the categories list.',
  },
];

export const CATEGORY_SHORT_DESCRIPTION_MIN_LENGTH = 100;
export const CATEGORY_SHORT_DESCRIPTION_MAX_LENGTH = 200;

export const CATEGORY_SHORT_DESCRIPTION_ADDITIONAL_REQUIREMENTS =
  'Ensure the description is no more than 2 sentences.';

export const CATEGORY_SHORT_DESCRIPTION_PLACEHOLDER = `Enter a brief overview (max. ${CATEGORY_SHORT_DESCRIPTION_MAX_LENGTH} characters)...`;

export const CATEGORY_LONG_DESCRIPTION_MIN_LENGTH = 200;
export const CATEGORY_LONG_DESCRIPTION_MAX_LENGTH = 400;

export const CATEGORY_LONG_DESCRIPTION_PLACEHOLDER = `'Provide a comprehensive description of this category...'`;

export const CATEGORY_CATALOG_OBJECT_TYPE = 'Category';
