import React from 'react';

import PropTypes from 'prop-types';
import { Icon, Message as SemanticUiMessage } from 'semantic-ui-react';

import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import { DismissibleMessage } from '../../../../../utils/components';

import './Message.scss';

const propTypes = {
  message: PropTypes.string.isRequired,
  onDismiss: PropTypes.func,
  hide: PropTypes.bool,
  warning: PropTypes.bool,
  delay: PropTypes.number,
};

const defaultProps = {
  onDismiss: () => {},
  hide: false,
  warning: false,
  delay: null,
};

export default function Message({ message, hide, warning, delay, onDismiss }) {
  const className = clsx({ message: true });

  if (hide || _isEmpty(message)) {
    return null;
  }

  return (
    <DismissibleMessage
      className={className}
      warning={warning}
      icon={warning}
      delay={delay}
      initialVisible
      onDismiss={onDismiss}
    >
      {warning && <Icon name="warning" />}
      <SemanticUiMessage.Content>{message}</SemanticUiMessage.Content>
    </DismissibleMessage>
  );
}

Message.propTypes = propTypes;
Message.defaultProps = defaultProps;
