import React from 'react';

import { Button, Input, Icon, Modal, Header, Form } from 'semantic-ui-react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { DRAFT, PUBLISHED, DESTINATION_BLOG } from './BlogContentFormModal.constants';
import ExtraDetails from './components/ExtraDetails/ExtraDetails';
import SeoConfiguration from './components/SeoConfiguration/SeoConfiguration';
import { updateRemoteItemUpdated as updateRemoteItemUpdatedConnect } from '../../../actions/business';
import { MERCHANT_BUSINESS_TYPE } from '../../../constants/constants';
import API from '../../../libs/api';
import { getErrorMessage } from '../../../libs/errors';
import CloseableModal from '../../common/CloseableModal/CloseableModal';
import InformationModal from '../../common/InformationModal';
import SaveChangesConfirm from '../../common/SaveChangesConfirm/SaveChangesConfirm';
import MarkdownField from '../../fields/MarkdownField';

import './BlogContentFormModal.scss';

class BlogContentFormModal extends React.Component {
  constructor(props) {
    super(props);
    const { blogpost } = props;

    this.state = {
      // RichEditor content
      editorState: _get(blogpost, 'content', ''),

      // If form is displaying extra details
      displayingMoreDetails: true,

      // Displaying alert modal and its text
      alertModalText: null,

      // Displaying delete confirm modal
      alertDeleteModal: null,

      // Displaying close without saving confirm modal
      alertCloseModal: null,

      // If input has changed
      inputHasChanged: false,

      // If the image was removed
      imageRemoved: false,

      // Blog post info
      id: _get(blogpost, 'id'),
      title: _get(blogpost, 'title'),
      summary: _get(blogpost, 'summary'),
      destination: _get(blogpost, 'destination', DESTINATION_BLOG),
      slug: _get(blogpost, 'slug'),
      status: _get(blogpost, 'status', DRAFT),
      heroImage: {
        file: null,
        url: _get(blogpost, 'hero_image'),
      },
      heroImageAltText: _get(blogpost, 'hero_image_alt_text'),
      publishedAt: _get(blogpost, 'published_at'),
      publishAt: _get(blogpost, 'publish_at'),
      visibleAt: _get(blogpost, 'visible_at'),
      author: _get(blogpost, 'author', {}),
      authorVisible: _get(blogpost, 'author_visible', true),
      dateVisible: _get(blogpost, 'date_visible', true),
      seoTitle: _get(blogpost, 'seo_title', ''),
      seoDescription: _get(blogpost, 'seo_description', ''),

      // What we're currently doing
      saving: false,
      publishing: false,
      unpublishing: false,
      deleting: false,
    };

    this.toggleDisplayingMoreDetails = this.toggleDisplayingMoreDetails.bind(this);
    this.saveArticleDataToState = this.saveArticleDataToState.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.delete = this.delete.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.saveOrPublish = this.saveOrPublish.bind(this);
    this.unpublish = this.unpublish.bind(this);
    this.clearLoading = this.clearLoading.bind(this);
    this.getSelectedImage = this.getSelectedImage.bind(this);
    this.removeSelectedImage = this.removeSelectedImage.bind(this);
    this.isPublished = this.isPublished.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
  }

  handleContentChange(editorState) {
    this.setState({ editorState, inputHasChanged: true });
  }

  getSelectedImage(e) {
    const files = Array.from(_get(e, 'target.files', []) || []);
    const heroImageFile = files[0];

    this.setState({
      heroImage: {
        file: heroImageFile,
        url: window.URL.createObjectURL(heroImageFile),
      },
      inputHasChanged: true,
    });
  }

  getEnabledActions() {
    const { id, inputHasChanged } = this.state;

    const isPublished = this.isPublished();
    const isLoading = this.isLoading();

    const canSave = inputHasChanged && !isLoading;
    const canPublish = !isPublished && !isLoading;
    const canUnpublish = isPublished && !isLoading;
    const canDelete = !!id && !isLoading;

    return {
      canSave,
      canPublish,
      canUnpublish,
      canDelete: !!canDelete,
    };
  }

  generateDeleteLink() {
    const { saving, publishing, unpublishing, deleting } = this.state;
    const { canDelete } = this.getEnabledActions();
    const enabled = canDelete && !saving && !publishing && !unpublishing && !deleting;
    const style = enabled ? { cursor: 'pointer' } : { color: 'lightgray' };
    const onClick = enabled ? this.showDeleteModal : null;
    return <Button style={style} onClick={onClick} content="Delete" />;
  }

  toggleDisplayingMoreDetails() {
    this.setState((prevState) => ({ displayingMoreDetails: !prevState.displayingMoreDetails }));
  }

  updateValue(event, which) {
    this.setState({
      inputHasChanged: true,
      [which]: event.target.value,
    });
  }

  async saveOrPublish(publish = false) {
    const { updateRemoteItemUpdated } = this.props;

    if (publish) {
      this.setState({ publishing: true });
    } else {
      this.setState({ saving: true });
    }

    const {
      id,
      title,
      editorState,
      summary,
      destination,
      status,
      heroImage,
      heroImageAltText,
      imageRemoved,
      author,
      authorVisible,
      publishAt,
      visibleAt,
      dateVisible,
      seoTitle,
      seoDescription,
    } = this.state;
    const { onPublish, onSave, business, closeModal } = this.props;

    const { id: businessId, type: businessType } = business;

    const markdownContent = editorState;

    if (!title || !markdownContent) {
      this.clearLoading();
      this.setState({
        alertModalText: 'Please ensure your post has a title and content!',
      });
      return;
    }

    const payload = {
      id,
      title,
      content: markdownContent,
      summary: summary || '',
      destination,
      status,
      hero_image: heroImage,
      hero_image_alt_text: heroImageAltText,
      author_visible: authorVisible,
      date_visible: dateVisible,
      seo_title: seoTitle,
      seo_description: seoDescription,
    };

    if (!_isEmpty(publishAt)) {
      payload.publish_at = publishAt;
    }

    if (!_isEmpty(visibleAt)) {
      payload.visible_at = visibleAt;
    }

    if (!_isEmpty(author)) {
      payload.author = author;
    }

    if (publish) {
      payload.status = PUBLISHED;
    }

    if (!heroImage.file && !heroImage.url && !imageRemoved) {
      delete payload.hero_image;
    } else if (!heroImage.file && !heroImage.url && imageRemoved) {
      payload.hero_image.file = '';
    } else if (!heroImage.file && heroImage.url) {
      delete payload.hero_image;
    }

    try {
      const { data } = await API.saveBlogPost(businessId, businessType, payload);

      this.saveArticleDataToState(data);

      if (publish) {
        onPublish();
      } else {
        onSave();
      }

      updateRemoteItemUpdated(true);

      closeModal();
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      this.setState({
        inputHasChanged: true,
        alertModalText: errorMessage,
      });
    } finally {
      this.clearLoading();
    }
  }

  async unpublish() {
    const { id } = this.state;
    const { onUnpublish, business, closeModal } = this.props;

    const { id: businessId, type: businessType } = business;

    this.setState({ unpublishing: true });

    const payload = { id, status: DRAFT };

    try {
      const { data } = await API.saveBlogPost(businessId, businessType, payload);
      this.saveArticleDataToState(data);
      closeModal();
    } catch (error) {
      this.setState({
        inputHasChanged: true,
        alertModalText: 'There was an unexpected error!',
      });
    } finally {
      this.clearLoading();
      this.setState({ inputHasChanged: false });
    }

    onUnpublish();
  }

  clearLoading() {
    this.setState({
      saving: false,
      publishing: false,
      unpublishing: false,
      deleting: false,
    });
  }

  saveArticleDataToState(data) {
    this.setState({
      id: data.id,
      title: data.title,
      editorState: data.content,
      summary: data.summary,
      destination: data.destination,
      slug: data.slug,
      status: data.status,
      heroImage: {
        file: null,
        url: data.hero_image,
      },
      publishedAt: data.published_at,
      publishAt: data.publish_at,
      visibleAt: data.visible_at,
      authorVisible: data.author_visible,
      dateVisible: data.date_visible,
    });
  }

  showDeleteModal() {
    this.setState({ alertDeleteModal: true });
  }

  async delete() {
    const { onDelete, business, closeModal } = this.props;
    const { id } = this.state;

    const { id: businessId, type: businessType } = business;

    this.setState({ deleting: true });
    await API.deleteBlogPost(businessId, businessType, id);
    onDelete();
    closeModal();
  }

  isPublished() {
    const { status } = this.state;
    return status === PUBLISHED;
  }

  isLoading() {
    const { saving, publishing, unpublishing, deleting } = this.state;

    return saving || publishing || unpublishing || deleting;
  }

  removeSelectedImage() {
    this.setState({
      heroImage: {
        file: null,
        url: null,
      },
      imageRemoved: true,
      inputHasChanged: true,
    });
  }

  renderButtons() {
    const { inputHasChanged, saving, publishing, unpublishing, publishAt } = this.state;
    const { canSave, canPublish, canUnpublish } = this.getEnabledActions();
    const published = this.isPublished();
    const canOnlyPublish = !canSave && canPublish;

    if (published) {
      return (
        <>
          {this.generateDeleteLink()}
          <Button
            content="Unpublish"
            onClick={this.unpublish}
            loading={unpublishing}
            disabled={!canUnpublish}
          />
          <Button
            primary
            content="Save"
            onClick={() => this.saveOrPublish(false)}
            disabled={!inputHasChanged || !canSave}
            loading={saving}
          />
        </>
      );
    }

    return (
      <>
        {this.generateDeleteLink()}
        <Button
          content={publishAt ? 'Schedule' : 'Save'}
          onClick={() => this.saveOrPublish(false)}
          disabled={!inputHasChanged || !canSave}
          loading={saving}
        />
        <Button
          primary
          content={canOnlyPublish ? 'Publish' : 'Save and Publish'}
          onClick={() => this.saveOrPublish(true)}
          disabled={!inputHasChanged && !canPublish}
          loading={publishing}
        />
      </>
    );
  }

  render() {
    const {
      id,
      title,
      destination,
      editorState,
      status,
      summary,
      slug,
      heroImage,
      heroImageAltText,
      author,
      publishedAt,
      publishAt,
      visibleAt,
      authorVisible,
      dateVisible,
      seoTitle,
      seoDescription,
      displayingMoreDetails,
      alertModalText,
      alertDeleteModal,
      alertCloseModal,
      inputHasChanged,
      saving,
      publishing,
      unpublishing,
      deleting,
    } = this.state;
    const { closeModal, business } = this.props;

    const { type: businessType } = business;
    const isMerchant = businessType === MERCHANT_BUSINESS_TYPE;

    let modalHeader = '';
    if (id) {
      modalHeader = 'Edit Blog Post';
    } else {
      modalHeader = 'Create Blog Post';
    }

    const closeIconDisabled = saving || publishing || unpublishing || deleting;

    const onClose = inputHasChanged
      ? () => this.setState({ alertCloseModal: true })
      : () => closeModal();

    return (
      <>
        <CloseableModal
          className="blog-content-form-modal"
          onClose={onClose}
          open
          header={modalHeader}
          closeIconDisabled={closeIconDisabled}
        >
          <Modal.Content scrolling>
            <Form>
              <Header>Title</Header>
              <Input
                size="large"
                fluid
                placeholder="Title..."
                value={title}
                onChange={(e) => this.updateValue(e, 'title')}
              />

              <Header>Content</Header>
              <MarkdownField
                style={{ minHeight: '300px' }}
                value={editorState}
                onChange={this.handleContentChange}
              />

              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={this.toggleDisplayingMoreDetails}
                style={{ display: 'block', margin: '30px 0 30px 0' }}
              >
                <Icon name={displayingMoreDetails ? 'caret down' : 'caret right'} />
                {displayingMoreDetails ? 'Hide Details...' : 'More Details...'}
              </a>
              {displayingMoreDetails && (
                <ExtraDetails
                  status={status}
                  summary={summary}
                  destination={destination}
                  slug={slug}
                  publishedAt={publishedAt}
                  publishAt={publishAt}
                  visibleAt={visibleAt}
                  heroImage={heroImage}
                  heroImageAltText={heroImageAltText}
                  author={author}
                  authorVisible={authorVisible}
                  dateVisible={dateVisible}
                  updateValue={this.updateValue}
                  getSelectedImage={this.getSelectedImage}
                  removeSelectedImage={this.removeSelectedImage}
                />
              )}

              {isMerchant && (
                <SeoConfiguration
                  onUpdate={this.updateValue}
                  seoTitle={seoTitle}
                  seoDescription={seoDescription}
                  title={`Blog - ${title}`}
                  summary={summary}
                />
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>{this.renderButtons()}</Modal.Actions>
        </CloseableModal>

        <InformationModal
          open={!!alertModalText}
          text={alertModalText}
          header="Input Error"
          onClose={() => this.setState({ alertModalText: null })}
        />

        <Modal size="tiny" open={alertDeleteModal} dimmer="inverted">
          <Modal.Content>Delete this Blog Post?</Modal.Content>
          <Modal.Actions>
            <Button content="Cancel" onClick={() => this.setState({ alertDeleteModal: null })} />
            <Button color="red" content="Delete" onClick={() => this.delete()} />
          </Modal.Actions>
        </Modal>

        <SaveChangesConfirm
          open={alertCloseModal}
          onCancel={() => this.setState({ alertCloseModal: null })}
          onConfirm={closeModal}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateRemoteItemUpdated: (payload) => dispatch(updateRemoteItemUpdatedConnect(payload)),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BlogContentFormModal);
