export const FULFILLMENT_STATUS_OPTIONS = [
  {
    value: 'ALL',
    text: 'All',
  },
  {
    value: 'PROPOSED',
    text: 'Proposed',
  },
  {
    value: 'RESERVED',
    text: 'Reserved',
  },
  {
    value: 'PREPARED',
    text: 'Prepared',
  },
  {
    value: 'COMPLETE',
    text: 'Complete',
  },
  {
    value: 'CANCELED',
    text: 'Canceled',
  },
  {
    value: 'FAILED',
    text: 'Failed',
  },
];
