/* eslint-disable no-underscore-dangle */

import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _set from 'lodash/set';
import _unset from 'lodash/unset';
import semver from 'semver';
import { v4 as uuidv4 } from 'uuid';

import { resolveType } from './types';
import { VALUE_TYPE_OBJECT, VALUE_TYPE_ARRAY } from './types/constants';
import { UNRESOLVED_INDEX } from '../constants';

function isObject(value) {
  return _isObject(value) && !_isArray(value);
}

function isArray(value) {
  return _isArray(value);
}

function moveItemInArray(array, oldIndex, newIndex) {
  const item = array[oldIndex];
  array.splice(oldIndex, 1);
  array.splice(newIndex, 0, item);
}

export function isMetadataKey(key) {
  return ['__id__', '__type__'].includes(key);
}

export function nodeInPath(selectedPath, nodePath) {
  return selectedPath.slice(0, nodePath.length).join('.') === nodePath.join('.');
}

export function getValueType(value) {
  if (isObject(value)) {
    return VALUE_TYPE_OBJECT;
  }
  if (isArray(value)) {
    return VALUE_TYPE_ARRAY;
  }
  return typeof value;
}

export function isMetadataOnlyNode(node) {
  return Object.keys(node).length === 2 && '__id__' in node && '__type__' in node;
}

export function removeNodeMetadata(recipe) {
  if (_isArray(recipe)) {
    return recipe
      .filter((element) => !isMetadataOnlyNode(element))
      .map((element) => removeNodeMetadata(element));
  }
  if (_isObject(recipe)) {
    return Object.keys(recipe).reduce((acc, key) => {
      if (!isMetadataKey(key)) {
        return { ...acc, [key]: removeNodeMetadata(recipe[key]) };
      }
      return acc;
    }, {});
  }

  return recipe;
}

export function addNodeMetadata(recipe, path = []) {
  // Don't add an ID in this node if there is one already present
  if (_isArray(recipe) && recipe.length > 0 && recipe[0].__id__) {
    return recipe.map((element) => addNodeMetadata(element, path));
  }
  if (_isObject(recipe) && Object.keys(recipe).includes('__id__')) {
    return Object.keys(recipe).reduce((acc, key) => {
      return { ...acc, [key]: addNodeMetadata(recipe[key], [...path, key]) };
    }, {});
  }

  if (_isArray(recipe)) {
    return [
      { __id__: uuidv4(), __type__: resolveType(recipe, path) },
      ...recipe.map((element) => addNodeMetadata(element, path)),
    ];
  }
  if (_isObject(recipe)) {
    return {
      __id__: uuidv4(),
      __type__: resolveType(recipe, path),
      ...Object.keys(recipe).reduce((acc, key) => {
        return { ...acc, [key]: addNodeMetadata(recipe[key], [...path, key]) };
      }, {}),
    };
  }

  return recipe;
}

export function getPageIndexByName(recipe, pageName) {
  if (!recipe || !pageName) {
    return UNRESOLVED_INDEX;
  }
  const cleanRecipe = removeNodeMetadata(recipe);
  return _findIndex(cleanRecipe.pages, { name: pageName });
}

export function getObjectPathById(obj, idToFind, path = []) {
  const isArrayAndHasId = _isArray(obj) && obj.some((i) => i.__id__ === idToFind);
  const isObjectAndHasId = _isObject(obj) && obj.__id__ === idToFind;

  if (isArrayAndHasId) {
    const index = _findIndex(obj, (i) => i.__id__ === idToFind);
    path.push(index);
    return {
      found: true,
      path,
    };
  }
  if (isObjectAndHasId) {
    return {
      found: true,
      path,
    };
  }

  const objKeys = Object.keys(obj);
  for (let x = 0; x < objKeys.length; x += 1) {
    const key = objKeys[x];
    const childObj = obj[key];

    if (_isObject(childObj) || _isArray(childObj)) {
      const result = getObjectPathById(childObj, idToFind, path);
      if (result.found) {
        path.unshift(key);
        return {
          found: true,
          path,
        };
      }
    }
  }

  return {
    found: false,
    path,
  };
}

export function resortArray(fullRecipe, key, parentId, oldIndex, newIndex) {
  const obj = getObjectPathById(fullRecipe, parentId);
  const { found } = obj;
  let { path } = obj;

  if (!found) {
    return fullRecipe;
  }

  path = [...path, key];
  const array = _get(fullRecipe, path);

  moveItemInArray(array, oldIndex, newIndex);
  const newFullRecipe = _set(fullRecipe, path, array);
  return newFullRecipe;
}

export function insertKeyValueIntoObject(fullRecipe, ky, value, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return fullRecipe;
  }

  if (ky) {
    path.push(ky);
  }

  const obj = _get(fullRecipe, path);

  if (Array.isArray(obj)) {
    obj.push(value);
    return _set(fullRecipe, path, obj);
  }

  const newObj = { ...obj, ...value };
  return _set(fullRecipe, path, newObj);
}

export function deleteValueFromObject(fullRecipe, key, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);

  if (!found) {
    return fullRecipe;
  }

  const objectAtPath = _get(fullRecipe, path);

  if (isMetadataOnlyNode(objectAtPath)) {
    path.length -= 1;
    const parentArray = _get(fullRecipe, path);
    parentArray.splice(parseInt(key, 10) + 1, 1);
    return fullRecipe;
  }

  _unset(fullRecipe, [...path, key]);
  return fullRecipe;
}

export function updateValueInObject(fullRecipe, key, value, parentId) {
  const { found, path } = getObjectPathById(fullRecipe, parentId);
  if (!found) {
    return fullRecipe;
  }

  if (path.length === 0) {
    return { ...fullRecipe, [key]: value };
  }

  const objectAtPath = _get(fullRecipe, path);
  if (isMetadataOnlyNode(objectAtPath)) {
    path.length -= 1;
    const parentArray = _get(fullRecipe, path);
    const newArray = [
      ...parentArray.slice(0, parseInt(key, 10) + 1),
      value,
      ...parentArray.slice(parseInt(key, 10) + 2),
    ];
    return _set(fullRecipe, path, newArray);
  }

  const obj = _get(fullRecipe, path);
  const newObj = { ...obj, [key]: value };
  _set(fullRecipe, path, newObj);
  return fullRecipe;
}

export function getParentNode(recipe, parentId) {
  const { found, path } = getObjectPathById(recipe, parentId);

  if (!found) {
    return null;
  }

  if (!path.length) {
    return recipe;
  }

  return _get(recipe, path);
}

export function sortPatchesByVersion(patches) {
  return patches.sort(({ patch_version: versionA }, { patch_version: versionB }) => {
    if (semver.gt(versionA, versionB)) return -1;
    if (semver.lt(versionA, versionB)) return 1;
    return 0;
  });
}
