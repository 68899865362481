import PropTypes from 'prop-types';

import { StylePropType } from '../../../../libs/proptypes';

export const BaseBlockType = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  localId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: StylePropType,
  className: PropTypes.string,
  type: PropTypes.string,
};

export const ComponentConfigurationBlockType = {
  componentName: PropTypes.string,
  componentIdentifier: PropTypes.string,
  componentConfigurationId: PropTypes.number,
};
