import _ from 'lodash';

export default (state = {}, action) => {
  const { type, payload } = action;
  const { route, input, index, message } = payload || {};
  const { inputErrors } = state;
  let errorsForRouteArray;

  switch (type) {
    case 'CREATE_INPUT_ERRORS':
      /*
       * inputErrors has the form
       *
       *  inputErrors = {
       *    'route': [
       *      {
       *        'input': 'input',
       *        'message': 'message',
       *      }
       *    ]
       *  }
       *
       */
      return {
        ...state,
        inputErrors: {},
      };

    case 'ADD_INPUT_ERROR':
      errorsForRouteArray = inputErrors[route];

      if (errorsForRouteArray) {
        // Property for this route exists.
        // For the given input and index, remove any existing errors
        _.remove(errorsForRouteArray, (obj) => {
          if (obj.index) {
            return obj.input === input && obj.index === index;
          }
          return obj.input === input;
        });
      } else {
        // Route doesn't have an error array. Create one here.
        inputErrors[route] = [];
        errorsForRouteArray = [];
      }

      inputErrors[route].push({
        input,
        message,
        index,
      });

      return {
        ...state,
        inputErrors,
      };

    case 'CLEAR_INPUT_ERROR':
      if (!payload) return state;

      errorsForRouteArray = inputErrors[route];
      if (errorsForRouteArray) {
        _.remove(errorsForRouteArray, (obj) => {
          if (obj.index) {
            return obj.input === input && obj.index === index;
          }
          return obj.input === input;
        });
      }

      return {
        ...state,
        inputErrors,
      };

    default:
      return state;
  }
};
