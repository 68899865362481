import React from 'react';

import { Button, Container, Form, Segment } from 'semantic-ui-react';

import _get from 'lodash/get';
import { useSelector } from 'react-redux';

import withCopyToClipboard from '../../../../../common/CopyToClipboard/CopyToClipboard';
import { zendeskOpen } from '../../../../../common/ZendeskWidget/ZendeskWidget.utils';
import { getToastProviderServiceSetting } from '../../ToastIntegration.utils';

const CopyToClipboard = withCopyToClipboard(Button);

const ToastIntegrationSettings = () => {
  const providerServiceSettings = useSelector(
    (state) => state.business.provider_service_settings.value,
  );
  const serviceSetting = getToastProviderServiceSetting(providerServiceSettings);
  const verificationCode = _get(serviceSetting, 'service_data.verification_code');
  const integrationsUrl = _get(serviceSetting, 'service_data.integrations_url');

  function launchToastMarketplace() {
    if (integrationsUrl) {
      window.open(integrationsUrl);
    }
  }

  return (
    <>
      <Segment attached>
        <Container textAlign="center" className="toast-merchant-segment-text">
          In order to complete the integration setup, copy either ID below, launch the Toast
          Marketplace, and paste the code in the Fisherman App tile
        </Container>
      </Segment>
      <Segment attached>
        <Container>
          <Form>
            <Form.Input label="Verification Code">
              <input value={verificationCode} />
              <CopyToClipboard icon="copy" value={verificationCode} />
            </Form.Input>
          </Form>
        </Container>
      </Segment>
      <Segment attached="bottom">
        <Container textAlign="center">
          <Button onClick={zendeskOpen}>Contact Support</Button>
          <Button primary onClick={launchToastMarketplace}>
            Launch Toast Marketplace
          </Button>
        </Container>
      </Segment>
    </>
  );
};

export default ToastIntegrationSettings;
