import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const propTypes = {
  isNestedForm: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  onSubmit: () => {},
};

function ModifierSetFormWrapper({ isNestedForm, onSubmit, children }) {
  function handleKeyDown(e) {
    if (e.code === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(e);
    }
  }

  return isNestedForm ? (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div className="modifier-set-form" onKeyDown={handleKeyDown} role="form">
      {children}
    </div>
  ) : (
    <Form className="modifier-set-form" onKeyDown={handleKeyDown}>
      {children}
    </Form>
  );
}

ModifierSetFormWrapper.propTypes = propTypes;
ModifierSetFormWrapper.defaultProps = defaultProps;

export default ModifierSetFormWrapper;
