import React from 'react';

import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

import ExternalAnchor from '../../../common/ExternalAnchor';
import { getSupportEmail } from '../../../modules/foundation/hooks/use-branding/useBranding.utils';

const propTypes = {
  brandIdentity: PropTypes.string.isRequired,
};
const defaultProps = {};

export default function AdminRequiredMessage({ brandIdentity }) {
  const supportEmail = getSupportEmail(brandIdentity);

  return (
    <>
      <Message
        header="Integration Ready"
        list={['Your integration is correctly configured and ready to import POS data']}
        success
        icon="check"
      />
      To ensure integrity of your current website please contact support at{' '}
      <ExternalAnchor href={supportEmail}>{supportEmail}</ExternalAnchor> when you are ready to
      initiate data loading.
    </>
  );
}

AdminRequiredMessage.propTypes = propTypes;
AdminRequiredMessage.defaultProps = defaultProps;
