import React from 'react';

import { Dropdown } from 'semantic-ui-react';

import _get from 'lodash/get';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { resetBusiness as resetBusinessConnect } from '../../../../actions/business';
import { resetUser as resetUserConnect } from '../../../../actions/user';
import { resetWebsite as resetWebsiteConnect } from '../../../../actions/website';
import { STACK_STATUS_LIVE, STACK_STATUS_PREVIEW } from '../../../../constants/constants';
import {
  logOut,
  signupPathFromRole,
  isAuthorizedForSaleViews,
  isAdminUser,
} from '../../../../libs/auth';
import Routing from '../../../../libs/routing';
import { selectUserRoleForBusiness } from '../../../../selectors/business';
import WithRouter from '../../../modules/foundation/components/WithRouter';

import './MainNavigationDropdown.scss';

const mapDispatchToProps = (dispatch) => ({
  resetUser: (payload) => dispatch(resetUserConnect(payload)),
  resetBusiness: (payload) => dispatch(resetBusinessConnect(payload)),
  resetWebsite: (payload) => dispatch(resetWebsiteConnect(payload)),
});

const mapStateToProps = (state) => ({
  user: _get(state, 'user.core.value'),
  userRole: selectUserRoleForBusiness(state),
  firstName: _get(state, 'user.core.value.first_name'),
  lastName: _get(state, 'user.core.value.last_name'),
  business: _get(state, 'business.core.value'),
  logo: _get(state, 'business.core.value.logo'),
  stripeCustomerId: _get(state, 'business.core.value.stripe_customer_id'),
  website: _get(state, 'website.core.value'),
  domains: _get(state, 'website.domains.value'),
  isNextWebsite: _get(state, 'website.isNextWebsite'),
});

class MainNavigationDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.signOut = this.signOut.bind(this);
    this.hasValidWebsite = this.hasValidWebsite.bind(this);
    this.hasDeveloperPermission = this.hasDeveloperPermission.bind(this);
    this.canChangeBusiness = this.canChangeBusiness.bind(this);
    this.canCreateWebsite = this.canCreateWebsite.bind(this);
    this.getCreateWebsitePath = this.getCreateWebsitePath.bind(this);
  }

  getCreateWebsitePath() {
    const { user, userRole } = this.props;
    return signupPathFromRole(user, userRole);
  }

  signOut() {
    const { navigate, resetUser, resetBusiness, resetWebsite } = this.props;
    resetUser();
    resetBusiness();
    resetWebsite();

    return logOut(navigate, '/');
  }

  hasValidWebsite() {
    const { website } = this.props;
    const { stack_status: stackStatus } = website;

    // Other options are 'Disabled' or 'Removed'
    return [STACK_STATUS_LIVE, STACK_STATUS_PREVIEW].includes(stackStatus);
  }

  hasDeveloperPermission() {
    const { user } = this.props;
    return isAdminUser(user);
  }

  hasCustomerId() {
    const { stripeCustomerId } = this.props;
    return !!stripeCustomerId;
  }

  canChangeBusiness() {
    const { user, userRole } = this.props;
    if (isAdminUser(user) || isAuthorizedForSaleViews(userRole)) return true;

    return user.businessesCount > 1;
  }

  canCreateWebsite() {
    return this.canChangeBusiness();
  }

  render() {
    const { website } = this.props;
    const createWebsitePath = this.getCreateWebsitePath();

    return (
      <Dropdown
        className="main-navigation-dropdown"
        direction="left"
        icon={{ name: 'bars', size: 'large' }}
        floating
        labeled
        button
      >
        <Dropdown.Menu>
          {this.hasDeveloperPermission() && (
            <Dropdown.Item text="Developer" as="a" href={Routing.getFirstRoute(['developers'])} />
          )}
          <Dropdown.Item text="Go Home" as={Link} to={Routing.getHomeUrl(website)} />
          {this.canChangeBusiness() && <Dropdown.Item text="Change Business" as={Link} to="/" />}
          {this.canCreateWebsite() && createWebsitePath && (
            <Dropdown.Item text="Create Website" as={Link} to={createWebsitePath} />
          )}
          <Dropdown.Item text="Account" as={Link} to="/account/info" />
          <Dropdown.Divider />
          <Dropdown.Item text="Sign Out" onClick={this.signOut} />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(MainNavigationDropdown));
