import { GRID_ELEMENT_TYPE } from '../constants/types';

export function filterGridComponents(components = []) {
  return components.filter((component) => {
    const { type } = component;
    return type === GRID_ELEMENT_TYPE;
  });
}

export function getComponentConfigurationIdsFromColumn(columnState) {
  const { segments = [] } = columnState || {};

  return segments
    .flatMap(({ elements = [] }) =>
      elements.map(({ componentConfigurationId }) => componentConfigurationId),
    )
    .filter(Boolean);
}

export function getComponentConfigurationIdsFromRow(rowState) {
  const { columns = [] } = rowState || {};

  return columns
    .flatMap((column) => getComponentConfigurationIdsFromColumn(column))
    .filter(Boolean);
}
