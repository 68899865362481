import StateUtils from '../libs/state';

export default (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'CREATE_USER':
      return {
        ...state,
        core: StateUtils.createDefaultObject(StateUtils.types.OBJECT, { ...payload }, true),
      };

    case 'RESET_USER':
      return {
        core: StateUtils.createDefaultObject(StateUtils.types.OBJECT, {}, false),
      };

    case 'UPDATE_USER':
      return StateUtils.updateObject(
        state,
        ['core', 'value', payload.field],
        payload.value,
        payload.bypassTouch ? 0 : 1,
      );

    case 'CLEAR_USER_TOUCHED_FIELD':
      return {
        ...state,
        core: { ...state.core, _touchedFields: [] },
      };

    default:
      return state;
  }
};
