import React from 'react';

import PropTypes from 'prop-types';

import _isEmpty from 'lodash/isEmpty';

import { FormFieldPropType } from '../../../../../../types/form-block.types';
import FormFieldContainer from '../FormFieldContainer';
import InsertFormField from '../InsertFormField';

import './FormFieldList.scss';

const propTypes = {
  fields: PropTypes.arrayOf(FormFieldPropType).isRequired,
  onInsertField: PropTypes.func.isRequired,
  onUpdateFieldValue: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onMoveField: PropTypes.func.isRequired,
};

const defaultProps = {};

export default function FormFieldList({
  fields,
  onInsertField,
  onUpdateFieldValue,
  onDeleteField,
  onMoveField,
}) {
  if (_isEmpty(fields)) {
    return (
      <div className="form-field-list">
        <InsertFormField
          onSelectFieldType={(fieldType, { initialData }) =>
            onInsertField(0, fieldType, { initialData })
          }
        />
      </div>
    );
  }

  return (
    <div className="form-field-list">
      <InsertFormField
        onSelectFieldType={(fieldType, { initialData }) =>
          onInsertField(0, fieldType, { initialData })
        }
      />
      {fields.map((field, index) => {
        const { id } = field;

        return (
          <div key={id} className="form-field-list_item">
            <FormFieldContainer
              field={field}
              onUpdateFieldValue={(fieldKey, fieldValue) =>
                onUpdateFieldValue(index, fieldKey, fieldValue)
              }
              onDeleteField={() => onDeleteField(index)}
              onMoveField={(direction) => onMoveField(index, direction)}
              moveDownDisabled={index === fields.length - 1}
              moveUpDisabled={index === 0}
            />
            <InsertFormField
              onSelectFieldType={(fieldType, { initialData }) =>
                onInsertField(index + 1, fieldType, { initialData })
              }
            />
          </div>
        );
      })}
    </div>
  );
}

FormFieldList.propTypes = propTypes;
FormFieldList.defaultProps = defaultProps;
