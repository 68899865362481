import { useState } from 'react';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';

import { addBusinessFile } from 'actions/business';
import {
  FILE_UPLOAD_ERROR_STATUS,
  FILE_UPLOAD_ERROR_MESSAGE,
  FILE_UPLOADING_MESSAGE,
  FILE_UPLOADING_STATUS,
  FILE_UPLOAD_SUCCESS_MESSAGE,
  FILE_UPLOAD_SUCCESS_STATUS,
  FILE_TOO_LARGE_MESSAGE,
} from 'components/modules/files/constants/upload';
import { createLocalFileObject, createFilePayload } from 'components/modules/files/services/files';
import { exceedsMaxFileSize } from 'components/modules/files/services/size';
import API from 'libs/api';
import { selectBusinessId, selectBusinessType } from 'selectors/business';

export default function useLocalFiles({ allowedTypes = [] } = {}) {
  const dispatch = useDispatch();
  const businessId = useSelector(selectBusinessId);
  const businessType = useSelector(selectBusinessType);

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  function uploadLocalFiles(newFiles = []) {
    const filesList = Array.from(newFiles)
      .filter((file) => {
        const type = _get(file, 'type', '') || _get(file, 'file.type', '');
        if (_isEmpty(allowedTypes)) {
          return true;
        }
        return allowedTypes.some((allowedType) => type.includes(allowedType));
      })
      .map(createLocalFileObject);

    if (!_isEmpty(filesList)) {
      setFiles((prevFiles) => {
        return [...prevFiles, ...filesList];
      });
    }
  }

  function deleteLocalFile(fileIndex) {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== fileIndex));
  }

  function clearLocalFiles() {
    setFiles([]);
  }

  function setLocalFile(fileIndex, file) {
    setFiles((prevFiles) => {
      return prevFiles.map((prevFile, i) => {
        if (i === fileIndex) {
          return createLocalFileObject(file);
        }
        return prevFile;
      });
    });
  }

  function updateFileUploadProgress(
    fileIndex,
    { uploadProgress, uploadStatus, uploadStatusMessage },
  ) {
    setFiles((prevFiles) => {
      return prevFiles.map((file, i) => {
        if (i === fileIndex) {
          return {
            ...file,
            uploadProgress,
            uploadStatus,
            uploadStatusMessage,
          };
        }

        return file;
      });
    });
  }

  function updateFileSuccessStatus(fileIndex) {
    updateFileUploadProgress(fileIndex, {
      uploadProgress: 100,
      uploadStatus: FILE_UPLOAD_SUCCESS_STATUS,
      uploadStatusMessage: FILE_UPLOAD_SUCCESS_MESSAGE,
    });
  }

  function updateFileErrorStatus(fileIndex) {
    updateFileUploadProgress(fileIndex, {
      uploadProgress: 100,
      uploadStatus: FILE_UPLOAD_ERROR_STATUS,
      uploadStatusMessage: FILE_UPLOAD_ERROR_MESSAGE,
    });
  }

  function updateFileUploadingStatus(fileIndex, progress) {
    updateFileUploadProgress(fileIndex, {
      uploadProgress: progress,
      uploadStatus: FILE_UPLOADING_STATUS,
      uploadStatusMessage: FILE_UPLOADING_MESSAGE,
    });
  }

  function updateFileTooLargeStatus(fileIndex) {
    updateFileUploadProgress(fileIndex, {
      uploadProgress: 100,
      uploadStatus: FILE_UPLOAD_ERROR_STATUS,
      uploadStatusMessage: FILE_TOO_LARGE_MESSAGE,
    });
  }

  function allFilesUploaded() {
    return files.every((file) => file.uploadProgress === 100);
  }

  async function saveLocalFiles() {
    setLoading(true);
    const fileUploadPromises = files.map(async (file, index) => {
      if (exceedsMaxFileSize(file)) {
        updateFileTooLargeStatus(index);
        return;
      }

      if (file.uploadProgress) {
        return;
      }

      function onTrackProgress(progressEvent) {
        const { loaded, total } = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);

        updateFileUploadingStatus(index, percentage);
      }

      try {
        const filePayload = createFilePayload(file);
        const { data } = await API.createBusinessFile(businessType, businessId, filePayload, {
          progressTracker: onTrackProgress,
        });
        dispatch(addBusinessFile(data));
        updateFileSuccessStatus(index);
      } catch {
        updateFileErrorStatus(index);
      }
    });

    await Promise.all(fileUploadPromises);
    setLoading(false);
  }

  const uploaded = allFilesUploaded() && !_isEmpty(files);

  return {
    files,
    loading,
    uploaded,
    uploadLocalFiles,
    deleteLocalFile,
    clearLocalFiles,
    setLocalFile,
    updateFileUploadProgress,
    saveLocalFiles,
  };
}
