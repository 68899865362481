import { useState, useCallback } from 'react';

import useAsyncEffect from '../../components/modules/foundation/hooks/use-async-effect';
import API from '../../libs/api';

export default function useOrder(orderId, fetchOnMount = true) {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const memoizedCallback = useCallback(
    async function fetchOrder() {
      try {
        setLoading(true);
        setErrorMessage('');
        const nextOrder = await API.getOrder(orderId);

        setOrder(nextOrder);
      } catch ({ message }) {
        setErrorMessage(message);
      } finally {
        setLoading(false);
      }
    },
    [orderId],
  );

  if (fetchOnMount) {
    useAsyncEffect(async () => {
      await memoizedCallback();
    }, [orderId]);
  }

  return {
    order,
    loading,
    errorMessage,
    fetchOrder: memoizedCallback,
  };
}
