export const createNotifications = (payload) => (dispatch) => {
  dispatch({
    type: 'CREATE_NOTIFICATIONS',
    payload,
  });
};

export const deleteFeatureNotification = (payload) => (dispatch) => {
  dispatch({
    type: 'DELETE_FEATURE_NOTIFICATION',
    payload,
  });
};
