import React from 'react';

import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

import { reportsOrderPropTypes } from './OrderReportsTableBody.proptypes';
import Currency from '../../../../../../../../../libs/currency';
import DateUtils from '../../../../../../../../../libs/date-utils';
import OrderDetailsModal from '../../../../../../common/OrderDetailsModal';
import { ORDER_AT_DATE_FORMAT } from '../../../../OrderReports.constants';

import './OrderReportsTableBody.scss';

const propTypes = {
  orders: PropTypes.arrayOf(reportsOrderPropTypes).isRequired,
};

function OrderReportsTableBody({ orders }) {
  return (
    <Table.Body className="order-reports-table-body">
      {orders.map(({ uuid, merchant_customer: merchantCustomer, closed_at: closedAt, total }) => {
        const {
          first_name: firstName = '',
          last_name: lastName = '',
          email = '',
          phone = '',
        } = merchantCustomer || {};

        return (
          <Table.Row key={uuid}>
            <Table.Cell>{uuid.slice(-4)}</Table.Cell>
            <Table.Cell>
              <OrderDetailsModal orderId={uuid} />
            </Table.Cell>
            <Table.Cell>{`${firstName} ${lastName}`}</Table.Cell>
            <Table.Cell>{phone}</Table.Cell>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell>{DateUtils.format(closedAt, ORDER_AT_DATE_FORMAT)}</Table.Cell>
            <Table.Cell>{Currency.format(total)}</Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );
}

OrderReportsTableBody.propTypes = propTypes;

export default OrderReportsTableBody;
