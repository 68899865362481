import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Checkbox, Table } from 'semantic-ui-react';

import { SelectedOrdersContext } from '../../../../../../OrdersContainer.context';

const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function OrdersTableHeader({ orders = [], extraHeaders = null }) {
  const { selectAllOrders, selectedOrderIds = [] } = useContext(SelectedOrdersContext);

  const allOrdersSelected = orders.length > 0 && selectedOrderIds.length === orders.length;
  const onSelectAllOrders = () => selectAllOrders(allOrdersSelected ? [] : orders);

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <Checkbox checked={allOrdersSelected} onClick={onSelectAllOrders} />
        </Table.HeaderCell>
        <Table.HeaderCell>Order #</Table.HeaderCell>
        <Table.HeaderCell>Details</Table.HeaderCell>
        <Table.HeaderCell>Customer</Table.HeaderCell>
        <Table.HeaderCell>Phone</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell>Ordered At</Table.HeaderCell>
        <Table.HeaderCell>Total</Table.HeaderCell>
        {extraHeaders}
      </Table.Row>
    </Table.Header>
  );
}

OrdersTableHeader.prototype = propTypes;

export default OrdersTableHeader;
