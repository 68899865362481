import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';

import routes from '../../constants/routes';
import store from '../../store/store';

const index = {
  getRoutes() {
    return routes;
  },

  getPathRoutes(rootRoutePath) {
    return rootRoutePath.reduce((acc, aPath) => {
      const paths = acc.filter(({ path }) => path === aPath);
      if (!_isEmpty(paths)) {
        return paths[0].routes;
      }
      return acc;
    }, index.getRoutes());
  },

  getPathRoute(routePath) {
    const pathRoutes = index.getPathRoutes(routePath.slice(0, routePath.length - 1));
    const lastPath = routePath[routePath.length - 1];
    return pathRoutes.filter(({ path }) => path === lastPath)[0];
  },

  getFlattenedRoutes(startPath) {
    const startPathRoutes = index.getPathRoutes(startPath);
    return startPathRoutes.reduce((acc, route) => {
      if (!route.routes || route.routes.length === 0) {
        return [
          ...acc,
          {
            ...route,
            completePath: index.createPath([...startPath, route.path]),
          },
        ];
      }
      return [...acc, ...index.getFlattenedRoutes([...startPath, route.path])];
    }, []);
  },

  getFirstRoute(routePath) {
    const route = index.getPathRoute(routePath);

    if (!route || !route.routes || (route.routes && route.routes.length === 0)) {
      return index.createPath(routePath);
    }

    return index.getFirstRoute([...routePath, route.routes[0].path]);
  },

  getNameFromLocation(location) {
    const pathSegments = index.getRoutePathFromLocation(location);
    const route = index.getPathRoute(pathSegments);

    return route && route.name;
  },

  getRoutePathFromLocation(location) {
    return location.pathname.split('/').slice(1);
  },

  createPath(routePath) {
    return routePath.reduce((acc, path) => {
      if (path) return `${acc}/${path}`;
      return undefined;
    }, '');
  },

  getHomeUrl(website) {
    const stackType = website && website.stack_type ? website.stack_type : '';

    if (stackType === 'Website') {
      return index.getFirstRoute(['core']);
    }

    return '/';
  },

  routeAllowsBusinessType(currentRoute, targetBusinessType) {
    const { businessTypes = [] } = currentRoute;

    return _isEmpty(businessTypes) || businessTypes.includes(targetBusinessType);
  },

  getBusinessTypeRoutes(currentRoutes, targetBusinessType) {
    return (currentRoutes || []).filter((route) =>
      this.routeAllowsBusinessType(route, targetBusinessType),
    );
  },

  routeAllowsCategory(currentRoute, targetCategory) {
    const { categories = [] } = currentRoute;

    return _isEmpty(categories) || categories.includes(targetCategory);
  },

  getRecipeRoutes(currentRoutes, targetRecipe) {
    return (currentRoutes || []).filter((route) => this.routeAllowRecipe(route, targetRecipe));
  },

  routeAllowRecipe(currentRoute, targetRecipe) {
    const { recipeExclusionList = [] } = currentRoute;

    return _isEmpty(recipeExclusionList) || !recipeExclusionList.includes(targetRecipe);
  },

  getCategoryRoutes(currentRoutes, targetCategory) {
    return (currentRoutes || []).filter((route) => this.routeAllowsCategory(route, targetCategory));
  },

  routeShouldDisplay(route, { state } = {}) {
    const { shouldDisplay } = route;

    if (!shouldDisplay || !_isFunction(shouldDisplay)) {
      return true;
    }

    return shouldDisplay(state || store.getState());
  },

  filterRoutesByDisplayRules(currentRoutes) {
    const state = store.getState();

    return currentRoutes.filter((route) => this.routeShouldDisplay(route, { state }));
  },
};

export default index;
