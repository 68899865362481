import { compiler } from 'markdown-to-jsx';
import * as ReactDOMServer from 'react-dom/server';

export function markdownStringToHtmlString(markdownString) {
  const jsx = compiler(markdownString, { wrapper: 'div', forceWrapper: true });

  const htmlContent = ReactDOMServer.renderToStaticMarkup(jsx);

  return htmlContent;
}
