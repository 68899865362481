import React from 'react';

import { Message, Icon } from 'semantic-ui-react';

import { StyleRulesTable } from '../../modules/style-rules/components';
import AddStyleRuleButton from '../../modules/style-rules/components/AddStyleRuleButton';
import useStyleRules from '../../modules/style-rules/hooks/useStyleRules';

import './StyleRulesPage.scss';

export default function StyleRulesPage() {
  const { styleRules, loading } = useStyleRules();

  if (loading) {
    return (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Loading...</Message.Header>
          We are getting your style rules for you.
        </Message.Content>
      </Message>
    );
  }

  return (
    <div className="style-rules-page">
      <div>
        <AddStyleRuleButton />
      </div>
      <StyleRulesTable styleRules={styleRules} />
    </div>
  );
}
