import React from 'react';

import AddExternalPage from '../AddExternalPage';
import AddPageGroup from '../AddPageGroup/AddPageGroup';

import './NavigationToolbar.scss';

const propTypes = {};
const defaultProps = {};

export default function NavigationToolbar() {
  return (
    <div className="navigation-toolbar">
      <AddPageGroup />
      <AddExternalPage />
    </div>
  );
}

NavigationToolbar.propTypes = propTypes;
NavigationToolbar.defaultProps = defaultProps;
