import React from 'react';

import { Form } from 'semantic-ui-react';

import _get from 'lodash/get';

import MenuTypeFormGroup from './MenuTypeFormGroup';
import FormInput from '../../components/FormInput';
import {
  DATA_CHANNEL_URL_KEY,
  DATA_LOCALE_KEY,
  DATA_PORTAL_SLICES_KEY,
} from '../Zuppler.constants';

export default function MultipleRestaurantForm({ serviceData, handleUpdate }) {
  const dataChannelURL = _get(serviceData, DATA_CHANNEL_URL_KEY);
  const dataLocale = _get(serviceData, DATA_LOCALE_KEY);
  const dataPortalSlices = _get(serviceData, DATA_PORTAL_SLICES_KEY);

  return (
    <Form>
      <MenuTypeFormGroup serviceData={serviceData} handleUpdate={handleUpdate} />
      <FormInput
        value={dataChannelURL}
        name={DATA_CHANNEL_URL_KEY}
        label="data-channel-url"
        handleUpdate={handleUpdate}
      />
      <FormInput
        value={dataLocale}
        name={DATA_LOCALE_KEY}
        label="data-locale"
        handleUpdate={handleUpdate}
      />
      <FormInput
        value={dataPortalSlices}
        name={DATA_PORTAL_SLICES_KEY}
        label="data-portal-slices"
        handleUpdate={handleUpdate}
      />
    </Form>
  );
}
