import React from 'react';

import { Loader } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';

import {
  copyLocationHours as copyLocationHoursConnect,
  updateLocationHourById as updateLocationHourByIdConnect,
  removeLocationHourById as removeLocationHourByIdConnect,
  addLocationHours as addLocationHoursConnect,
  updateLocation as updateLocationConnect,
} from '../../../../../../../../../actions/business';
import { selectTimezones, selectLocationByIndex } from '../../../../../../../../../selectors';
import Hours from '../../../../../../../../common/Hours/Hours';
import TimezoneField from '../../../../../../../../common/TimezoneField';

function LocationSettingHoursForm({ locationIndex, orderHourGroup, onEdit }) {
  const timezones = useSelector(selectTimezones);
  const location = useSelector(selectLocationByIndex(locationIndex));
  const { hours } = location;
  const { timezone } = location;

  const dispatch = useDispatch();
  const copyLocationHours = (payload) => dispatch(copyLocationHoursConnect(payload));
  const updateLocationHourById = (payload) => dispatch(updateLocationHourByIdConnect(payload));
  const removeLocationHourById = (payload) => dispatch(removeLocationHourByIdConnect(payload));
  const addLocationHours = (payload) => dispatch(addLocationHoursConnect(payload));
  const updateLocation = (payload) => dispatch(updateLocationConnect(payload));

  const orderHours = (hours || []).filter(
    (hour) => orderHourGroup && hour.hour_group === orderHourGroup.id,
  );

  function handleCopyHours(sourceDay, targetDays) {
    onEdit();
    copyLocationHours({ locationIndex, sourceDay, targetDays });
  }

  function handleAddHours(day) {
    onEdit();
    addLocationHours({
      index: locationIndex,
      day,
      hour_group: orderHourGroup.id,
      bypassTouch: true,
    });
  }

  function handleUpdateHour(hourId, payload) {
    onEdit();
    updateLocationHourById({ ...payload, index: locationIndex, hourId, bypassTouch: true });
  }

  function handleDeleteHour(hourId) {
    onEdit();
    removeLocationHourById({ index: locationIndex, hourId, bypassTouch: true });
  }

  function handleTimezoneChange(value) {
    const data = {
      field: 'timezone',
      fieldValue: value,
      index: locationIndex,
      bypassTouch: true,
    };

    updateLocation(data);
    onEdit();
  }

  if (!orderHourGroup) {
    return <Loader active />;
  }

  return (
    <>
      <TimezoneField
        currentTimezone={timezone}
        timezones={timezones}
        locationIndex={locationIndex}
        onTimezoneChange={handleTimezoneChange}
      />
      <Hours
        hours={orderHours}
        onCopyHours={(sourceDay, targetDays) => handleCopyHours(sourceDay, targetDays)}
        onAddHour={(day) => handleAddHours(day)}
        onDeleteHour={(hourId) => handleDeleteHour(hourId)}
        onUpdateHour={(hourId, payload) => handleUpdateHour(hourId, payload)}
        allowEmptyHours
      />
    </>
  );
}

export default LocationSettingHoursForm;
