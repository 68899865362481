import React, { useContext, useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Form, Grid, Header, Modal } from 'semantic-ui-react';

import _includes from 'lodash/includes';
import _pull from 'lodash/pull';
import { useSelector } from 'react-redux';

import { PagesSelection, PlacementSelection } from './components';
import { selectWebsitePages } from '../../../../../../../../../selectors/website';
import CloseableModal from '../../../../../../../../common/CloseableModal';
import { DOM_LOCATION_OPTIONS } from '../../../../../../constants';
import { HtmlElementPageContext } from '../../../../../../context';

import './HtmlElementsFilterModal.scss';

const propTypes = {
  open: PropTypes.bool,
  trigger: PropTypes.node,
  onClose: PropTypes.func,
};

const defaultProps = {
  open: false,
  trigger: null,
  onClose: () => {},
};

export default function HtmlElementsFilterModal({ open, trigger, onClose }) {
  const {
    filter: { placement, pages },
    setFilter,
  } = useContext(HtmlElementPageContext);
  const websitePages = useSelector(selectWebsitePages);

  const [placementValue, setPlacementValue] = useState(placement || DOM_LOCATION_OPTIONS.All);
  const [pagesValue, setPagesValue] = useState(pages || []);

  function onUpdatePlacement(value) {
    setPlacementValue(value);
  }

  function onUpdatePages(value) {
    if (_includes(pagesValue, value)) {
      setPagesValue(_pull(pagesValue, value));
    } else {
      setPagesValue([...pagesValue, value]);
    }
  }

  function onSaveFilter() {
    setFilter({
      placement: placementValue,
      pages: pagesValue,
    });
    onClose();
  }

  function resetValues() {
    setPlacementValue(placement);
    setPagesValue(pages);
  }

  return (
    <CloseableModal
      className="filter-html-elements-modal"
      open={open}
      onOpen={resetValues}
      trigger={trigger}
      onClose={onClose}
      header="Filters"
      size="tiny"
    >
      <Modal.Content>
        <Form>
          <Grid columns={2}>
            <Grid.Column>
              <Header className="filter-selection-header">Placement</Header>
              <PlacementSelection onUpdatePlacement={onUpdatePlacement} value={placementValue} />
            </Grid.Column>
            <Grid.Column>
              <Header className="filter-selection-header">Pages</Header>
              <PagesSelection
                onUpdatePages={onUpdatePages}
                values={pagesValue}
                pages={websitePages}
              />
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic className="filter-action-cancel" onClick={onClose} content="Cancel" />
        <Button className="filter-action-save" onClick={onSaveFilter} content="Save" />
      </Modal.Actions>
    </CloseableModal>
  );
}

HtmlElementsFilterModal.propTypes = propTypes;
HtmlElementsFilterModal.defaultProps = defaultProps;
