import cssValidator from 'w3c-css-validator';

export function jsonUpdateHandler(updatedValue) {
  let obj = {};
  try {
    const parsedJSON = JSON.parse(updatedValue);
    obj = { value: parsedJSON, parseError: false };
  } catch (e) {
    obj = { value: updatedValue, parseError: true };
  }

  return obj;
}

export async function cssUpdateHandler(updatedValue) {
  try {
    if (!updatedValue) {
      return { value: updatedValue, parseError: false };
    }

    const { valid } = await cssValidator.validateText(updatedValue);
    return { value: updatedValue, parseError: !valid };
  } catch (e) {
    return { value: updatedValue, parseError: true };
  }
}

export function editorUpdate(mode, updatedValue) {
  const editorUpdateHandler = {
    json: () => jsonUpdateHandler(updatedValue),
    css: () => cssUpdateHandler(updatedValue),
  };

  return editorUpdateHandler[mode]();
}
